import { baseURL } from 'config/baseURL';
import { get, patch } from '../utils.js';

const getEntitiesByClass = ({ classUri, query, start, limit }) => {
  const encodedClassUri = encodeURIComponent(classUri);

  const params = new URLSearchParams();
  params.set('query', query || '');
  params.set('start', start || 0);
  params.set('limit', limit || 20);

  return get(`${baseURL}knowledge-graph/classes/${encodedClassUri}/entities`, params);
};

const getEntity = uri => {
  return get(`${baseURL}knowledge-graph/entities/${encodeURIComponent(uri)}`);
};

const updateEntity = (uri, body) => {
  return patch(`${baseURL}knowledge-graph/entities/${encodeURIComponent(uri)}`, JSON.stringify(body));
};

export default { getEntitiesByClass, getEntity, updateEntity };
