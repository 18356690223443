import { makeStyles } from '@material-ui/core/styles';

import { signupStyles, defaultStyles } from '../defaultStyles';

const fadingTime = '0.21s';

const commonProps = {
  box: {
    pointerEvents: 'none',
    position: 'fixed',
    top: '20px',
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    margin: 'auto',
    width: 'max-content',
    minWidth: '288px',
    minHeight: '53px',
    padding: '6px 16px',
    fontSize: '14px',
    backgroundColor: 'rgb(49, 49, 49)',
    color: 'white !important',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.2)',
    alignItems: 'center',
    fontWeight: '300',
    lineHeight: '1.43',
    borderRadius: '4px',
    letterSpacing: '0.01071em',
    display: 'flex',
    zIndex: '999999',
    transition: `opacity ${fadingTime} ease-in-out, visibility ${fadingTime}`,
    opacity: 0,
    visibility: 'hidden'
  },
  show: {
    transition: `opacity ${fadingTime} ease-in-out, visibility ${fadingTime}`,
    opacity: 1,
    visibility: 'visible'
  }
};

export const useSignupStyles = makeStyles({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0)',
      animationTimingFunction: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'
    },
    '50%': {
      transform: 'rotate(180deg)',
      animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  box: {
    ...commonProps.box,
    fontFamily: signupStyles.fontFamily,
    '& > img': {
      maxWidth: '25px',
      maxHeight: '25px',
      animation: '$spin 1.3s infinite'
    }
  },
  show: {
    ...commonProps.show
  }
});

export const useDefaultStyles = makeStyles({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  box: {
    ...commonProps.box,
    fontFamily: defaultStyles.fontFamily,
    '& > img': {
      width: '25px',
      height: '25px',
      animation: '$spin 1s infinite'
    }
  },
  show: {
    ...commonProps.show
  }
});
