import { makeStyles } from '@material-ui/core/styles';

export const useButtonStyles = makeStyles({
  root: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginLeft: 1,
    minWidth: 0,
    width: 37,
    height: 37,
    fontSize: '12px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    }
  }
});

export const useMenuItemStyles = makeStyles({
  root: { fontSize: '14px' }
});

export const useMenuIconStyles = makeStyles({
  root: { minWidth: 30 }
});
