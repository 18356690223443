import { cloneElement, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSliderStyles } from './PanelSlider.styles';

/**
 * PanelSlider is a component that allows sliding between two or more views.
 * The views are passed as children to the component. Each child is a different view.
 * Each view receives in its props the functions "goToNextSlide" and "goToPrevSlide" that allow the user to navigate between the views.
 * Also, the "getSliderOptions" props is a callback from which the parent component can get the "goToNextSlide" and "goToPrevSlide" functions.
 * Example:
 * <PanelSlider getSliderOptions={({ goToPrevSlide, goToNextSlide }) => ... }>
 *    <View1 />
 *    <View2 />
 * </PanelSlider>
 */
const PanelSlider = ({ children, initialSlide, transitionTimeInSeconds, getSliderOptions }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(undefined);
  const styles = useSliderStyles();

  const stringifiedTransitionTime = `${transitionTimeInSeconds}s`;

  const childrenCount = useMemo(() => (children ? children.length : 0), [children]);

  const goToPrevSlide = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const goToNextSlide = () => {
    if (currentSlideIndex < children.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  useEffect(() => {
    if (childrenCount > 0) {
      const index0InitialSlide =
        initialSlide && initialSlide <= childrenCount && initialSlide >= 0 ? initialSlide - 1 : 0;
      setCurrentSlideIndex(index0InitialSlide);
    }
  }, [initialSlide, childrenCount]);

  useEffect(() => {
    if (getSliderOptions) {
      getSliderOptions({ goToNextSlide, goToPrevSlide });
    }
  }, [getSliderOptions]);

  return (
    <>
      <div className={styles.container}>
        {currentSlideIndex !== undefined &&
          children.map((content, index) => {
            const component = cloneElement(content, { goToNextSlide, goToPrevSlide });
            return (
              <div
                key={index}
                style={{
                  left: `${(index - currentSlideIndex) * 100}%`,
                  transition: `${stringifiedTransitionTime} ease-out`
                }}
                className={styles.content}
              >
                {component}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PanelSlider;

PanelSlider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  initialSlide: PropTypes.number,
  getSliderOptions: PropTypes.func,
  transitionTimeInSeconds: PropTypes.number
};

PanelSlider.defaultProps = {
  initialSlide: undefined,
  getSliderOptions: () => {},
  transitionTimeInSeconds: 0.3
};
