import { messageTypes } from 'store/modules/chat-messages';
import styles from './question-pills.scss';
import React from 'react';

const constructBoardInfoText = (boardInfo, boardName, numberOfWidgets, followUpState, t) => {
  const isQuestionOnSpecificWidget = followUpState.interpretationId !== undefined;
  if (isQuestionOnSpecificWidget) {
    return (
      <span>
        {t('follow-up-info-bar.widget', {
          widgetName: boardInfo.editingState.find(widget => widget.interpretationId === followUpState.interpretationId)
            ?.title
        })}
      </span>
    );
  } else {
    return <span>{t('follow-up-info-bar.board', { boardName: boardName, numberOfWidgets: numberOfWidgets })}</span>;
  }
};

// Component responsible for displaying the information about the message that is being followed-up on, located above the pills.
const FollowUpInfo = ({ boardInfo, followUpState, messages, t }) => {
  const referencedMessage = messages.find(msg => msg.id === followUpState?.messageId);
  let infoText;
  switch (referencedMessage?.type) {
    case messageTypes.VEEZOO_MULTI_ANSWER_MESSAGE: {
      const numberOfWidgets = referencedMessage.metaPartialAnswers.length;
      const boardName = referencedMessage.boardName;
      infoText = constructBoardInfoText(boardInfo, boardName, numberOfWidgets, followUpState, t);
      break;
    }
    case messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE: {
      const numberOfWidgets = referencedMessage.widgetsToPartialAnswersIdMap.length;
      const boardName = referencedMessage.boardName;
      infoText = constructBoardInfoText(boardInfo, boardName, numberOfWidgets, followUpState, t);
      break;
    }
    // Customer Selection behaves like an answer message
    case messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE:
    case messageTypes.VEEZOO_ANSWER_MESSAGE:
      infoText = <span>{t('follow-up-info-bar.answer', { answerName: referencedMessage.answer?.title })}</span>;
      break;
    default:
      // We should not get here, but if we do, display english message as fallback
      infoText = 'Referenced Message Not Found';
  }

  return <div className={styles.followUpInfo}>{infoText}</div>;
};

export default FollowUpInfo;
