import { studioURL } from 'config/baseURL';
import { get, put } from './utils.js';

const getSettings = () => get(`${studioURL}settings`);

const replaceSettings = settings => put(`${studioURL}settings`, settings);

export default {
  getSettings,
  replaceSettings
};
