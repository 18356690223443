import moment from 'moment';
import PropTypes from 'prop-types';

import { TIMEFORMAT } from 'config/constants';

import styles from './timestamp.scss';

const Timestamp = ({ time, timeFormat }) => (
  <time className={styles.time} dateTime={time}>
    {moment(time).format(timeFormat)}
  </time>
);

export default Timestamp;

Timestamp.propTypes = {
  time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  timeFormat: PropTypes.string
};

Timestamp.defaultProps = { timeFormat: TIMEFORMAT };
