import { baseURL } from 'config/baseURL';
import { get, post, patch } from './utils.js';

const shareAnswer = (partialAnswerId, visualizationType, visualizationOptions) =>
  post(`${baseURL}answers/shared`, {
    partialAnswerId: partialAnswerId,
    visualizationType: visualizationType,
    visualizationOptions: visualizationOptions
  });

const uploadSharingPreview = (sharedAnswerId, image) =>
  patch(`${baseURL}answers/shared/${sharedAnswerId}/image`, {
    base64Image: image
  });

const getSharedAnswer = sharedAnswerId =>
  get(`${baseURL}answers/shared/${sharedAnswerId}?sessionId=${window.veezoo.sessionId}`);

export default {
  shareAnswer,
  getSharedAnswer,
  uploadSharingPreview
};
