import React, { useCallback, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';

import Title from 'components/Signup/Title';
import Button from 'components/Signup/Button';
import PageLoader from 'components/Signup/PageLoader';

import { TextField, Select } from 'components/v3';

import services from 'services';

import { handleError } from 'services/utils';
import { trackEvent } from 'utils/eventTracking';
import { defaultLanguage, layouts } from 'config/constants';

import { getErrorInfoByFieldId } from 'utils/forms';

import styles from '../index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';

import useMediaQuery from 'utils/mediaQueries';

const validationRules = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(28, 'Name must contain a maximum of 28 characters.'),
  defaultLanguageId: yup.string().required('Language is required')
});

const defaultValues = knowledgeGraph => ({
  name: knowledgeGraph?.info?.default?.name || '',
  defaultLanguageId: knowledgeGraph?.defaultLanguageId || defaultLanguage
});

const ApplicationConnector = ({
  connector,
  dispatch,
  errorMessage,
  knowledgeGraph,
  languages,
  loading,
  setStep,
  withLoader
}) => {
  const isMobile = useMediaQuery();

  const formattedLanguages = useMemo(() => languages.map(({ data }) => ({ value: data.id, label: data.name })), [
    languages
  ]);

  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: defaultValues(knowledgeGraph),
    mode: 'onChange',
    resolver: yupResolver(validationRules)
  });

  const onSubmit = useCallback(
    async data => {
      const result = await withLoader(
        services.createKnowledgeGraphFromConnector(connector.id, data.defaultLanguageId, data.name),
        'Creating new Knowledge Graph...'
      );
      handleError(result, dispatch);
      if (!result.success) {
        const message = result?.response?.data?.data?.message;
        reset(data);
        trackEvent('Knowledge Graph Creation Failed', {
          knowledgeGraph: data,
          ...(message ? { error: { message } } : {})
        });
        errorMessage(message || 'There was an error while creating new Knowledge Graph.');
        return;
      }

      location.assign(result.data); // Let fivetran do its magic
    },
    [connector.id, dispatch, errorMessage, reset, withLoader]
  );

  return (
    <div className={signupStyles.centerBothDirectionChild}>
      <Title>
        Connect your <em>{connector.name}</em> data
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={clsx(signupStyles.mt_40, signupStyles.pageContent)}>
          {!loading.status && (
            <div className={signupStyles.pageContent}>
              <div className={signupStyles.sidedFormFields}>
                <div className={clsx(signupStyles.formField, signupStyles.mr_30)}>
                  <Controller
                    shrink
                    as={TextField}
                    name="name"
                    required
                    control={control}
                    label="Knowledge Graph Name"
                    disabled={loading.status || !!knowledgeGraph}
                    layout={layouts.signup}
                    data-test="newKnowledgeGraphName"
                    placeholder="Sales"
                    tooltip="Think of this as a project or dataset name. If your data is for instance about sales data, call it 'Sales'."
                    error={!!getErrorInfoByFieldId(errors, 'name')}
                    description={getErrorInfoByFieldId(errors, 'name')?.message || 'The name of the Knowledge Graph'}
                    highlighted={!!knowledgeGraph}
                  />
                </div>
                <div className={clsx(signupStyles.formField, styles.smaller)}>
                  <Controller
                    as={Select}
                    name="defaultLanguageId"
                    options={formattedLanguages}
                    control={control}
                    label="Language"
                    tooltip="Choose the language you will use to ask questions to Veezoo. This should be the same language as the one your data is in."
                    disabled={loading.status || !!knowledgeGraph}
                    error={!!getErrorInfoByFieldId(errors, 'defaultLanguageId')}
                    description={getErrorInfoByFieldId(errors, 'defaultLanguageId')?.message || 'The default language'}
                    data-test="newKnowledgeGraphDefaultLanguage"
                    highlighted={!!knowledgeGraph}
                    layout={layouts.signup}
                  />
                </div>
              </div>
              <div className={signupStyles.instructionsConnector}>
                <p>
                  To connect to {connector.name}, we will copy your data to a database managed by Veezoo using an
                  external service,
                  <a href="https://fivetran.com/" rel="noreferrer" target="_blank">
                    Fivetran
                  </a>
                  .<br />
                  Copying the data can take up to a few hours, depending on the size of the data.
                </p>
                <p>
                  After clicking <em>Next</em>, you will need to provide some details specific to {connector.name} and
                  authorize Veezoo to copy your data.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className={signupStyles.mt_25}>
          {!loading.status && (
            <>
              <Button
                type="button"
                size={isMobile ? 'mobile' : ''}
                color="light"
                rootClass={{ marginRight: 10 }}
                data-test="backButton"
                disabled={loading}
                onClick={() => setStep(step => step - 1)}
              >
                Back
              </Button>
              <Button
                type="submit"
                disabled={loading}
                size={isMobile ? 'mobile' : ''}
                color="dark"
                data-test="submitNewKnowledgeGraph"
              >
                Next
              </Button>
            </>
          )}
          {loading.status && <PageLoader message={loading.message} />}
        </div>
      </form>
    </div>
  );
};

export default memo(connect()(ApplicationConnector));
