import { forwardRef, useContext, useMemo } from 'react';
import { Q2QStateContext } from './Q2QStateContext';

import Q2QTooltip from 'components/tooltips/Q2QTooltip';

import { passageTypes } from 'config/constants';

import styles from './filter-pill-container.scss';
import classNames from 'classnames';

const PassageSpan = forwardRef(({ passage, onClick, text, testClass, ...rest }, ref) => {
  const q2qStateContext = useContext(Q2QStateContext);

  const hoveredClass = onClick ? styles.hoveredUnderlinedPassage : styles.hoveredPassage;
  const notHoveredClass = onClick ? styles.underlinedPassage : '';

  const id = passage.id;

  const shouldPreventHighlighting = useMemo(() => {
    switch (passage.type) {
      case passageTypes.COMPUTATION:
        return true;
      case passageTypes.RELATION:
        return true;
      default:
        return false;
    }
  }, [passage.type]);

  const hoverEvents = useMemo(() => {
    if (!id || shouldPreventHighlighting) return {};
    return {
      onMouseEnter: () => q2qStateContext.setHoveredId(id),
      onMouseLeave: () => q2qStateContext.setHoveredId(null)
    };
  }, [id, shouldPreventHighlighting, q2qStateContext]);

  const isHovered = !shouldPreventHighlighting && id && q2qStateContext.hoveredId === id;

  const shouldHighlightDescription = !onClick && passage.shouldHighlightDescription;
  const className = classNames(
    isHovered ? hoveredClass : notHoveredClass,
    shouldHighlightDescription && styles.highlightDescription
  );

  const passageSpan = (
    <>
      <span
        ref={ref}
        {...rest} // "rest" should be kept before "className". Otherwise, the CSS will be replaced and broken.
        className={className}
        {...(onClick ? { onClick } : {})}
        {...hoverEvents}
        data-testclass={testClass}
      >
        {text}
      </span>
    </>
  );

  return passage.description ? ( // We only want to display the tooltip if there's actually a description set as that's the main purpose of the tooltip.
    <Q2QTooltip title={passage.descriptionTitle || passage.text} description={passage.description}>
      <span>{passageSpan}</span>
    </Q2QTooltip>
  ) : (
    passageSpan
  );
});
export default PassageSpan;
