import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import UIAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ArrowIcon from 'svg/v2/chevron_down.svg';

import useStyles from './styles';

const Autocomplete = ({
  optionLabel,
  optionEqualityValue,
  options,
  value,
  onChange,
  error,
  placeholder,
  noOptionsText,
  fullWidth,
  ...props
}) => {
  const classes = useStyles({ error, fullWidth });

  return (
    <UIAutocomplete
      multiple
      value={value}
      onChange={onChange}
      classes={classes}
      options={options}
      getOptionLabel={option => option[optionLabel]}
      getOptionSelected={(option, value) => option[optionEqualityValue] === value[optionEqualityValue]}
      filterSelectedOptions
      popupIcon={<ArrowIcon />}
      renderInput={params => <TextField {...params} variant="outlined" placeholder={placeholder} />}
      noOptionsText={noOptionsText}
      {...props}
    />
  );
};

// We connect libraries to the component separately, because some libraries do not work properly with JEST.
// This way we can test the component without the library.
const ConnectedAutocomplete = props => {
  const { t } = useTranslation();
  return <Autocomplete placeholder={t('search')} noOptionsText={t('no-options')} {...props} />;
};

export default ConnectedAutocomplete;

Autocomplete.propTypes = {
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  optionEqualityValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.string
};

Autocomplete.defaultProps = {
  optionLabel: 'title',
  value: [],
  optionEqualityValue: 'id',
  error: false,
  fullWidth: false,
  placeholder: 'Search...',
  noOptionsText: 'No options'
};
