// This function takes a component...
import { Component } from 'react';
import { connect } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import { showCreateAccountModal } from 'store/modules/tutorial';
import { createAccountModalTriggerTypes } from 'components/tutorial/CreateAccountModal';

/**
 * Enhance a component with the function: restrictedFunctionality via its props.
 *
 * Use the function to check if the current user is from the open demo organization
 * If yes prompt him to creating an account
 */
function withRestrictedFunctionality(WrappedComponent) {
  // ...return new component...
  return class extends Component {
    constructor(props) {
      super(props);
    }

    @boundMethod
    handleRestrictedFunctionality(callback) {
      if (this.props.isOpenDemoUser) {
        this.props.dispatch(showCreateAccountModal(createAccountModalTriggerTypes.restrictedFunctionality));
      } else {
        return callback();
      }
    }

    render() {
      return <WrappedComponent restrictedFunctionality={this.handleRestrictedFunctionality} {...this.props} />;
    }
  };
}

export function mapStateToProps(state) {
  return {
    isOpenDemoUser: state.user.isOpenDemoUser
  };
}

export default WrappedComponent => connect(mapStateToProps)(withRestrictedFunctionality(WrappedComponent));
