import { createRef, Fragment, PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import boardStyles from '../board/mini-board.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import * as ReactDOM from 'react-dom';
import { MoreHoriz } from '@material-ui/icons';
import { connect } from 'react-redux';

import InputModal from 'components/modals/InputModal/InputModal';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';

import {
  acceptOrRejectSharedCustomerSelection,
  editCustomerSelection,
  removeCustomerSelection,
  stopSharingCustomerSelection
} from 'store/modules/customers-of-the-day';
import { showSharedCustomerSelectionModal } from 'store/modules/sharedModal';
import { changeEntryPoint } from 'store/modules/user';
import { ACCEPTED_STATUS, entryPointTypes } from 'config/constants';

class CustomerSelectionDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false,
      isRenaming: false
    };

    this.customerSelectionOptionsRef = createRef();
  }

  @boundMethod
  startRenaming() {
    this.setState({
      isRenaming: true
    });
  }

  @boundMethod
  stopRenaming() {
    this.setState({
      isRenaming: false
    });
  }

  @boundMethod
  remameCustomerSelection(newName) {
    this.stopRenaming();
    const editRequestJson = {
      name: newName,
      isInCustomersOfTheDay: this.props.isInCustomersOfTheDay,
      isInSidebar: this.props.isInSidebar
    };

    this.props.dispatch(editCustomerSelection(this.props.id, editRequestJson));
  }

  @boundMethod
  setDeletingState() {
    this.setState({
      isDeleting: true
    });
  }

  @boundMethod
  removeDeletingState() {
    this.setState({
      isDeleting: false
    });
  }

  @boundMethod
  deleteCustomerSelection() {
    this.props.dispatch(removeCustomerSelection(this.props.id));
    this.setState({
      isDeleting: false
    });
  }

  @boundMethod
  setRejectState() {
    this.setState({
      isRejecting: true
    });
  }

  @boundMethod
  removeRejectState() {
    this.setState({
      isRejecting: false
    });
  }

  @boundMethod
  rejectCustomerSelection(customerSelectionId, isAccepted) {
    this.props.dispatch(acceptOrRejectSharedCustomerSelection(customerSelectionId, isAccepted));
    this.setState({
      isRejecting: false
    });
  }

  @boundMethod
  displaySharedModal() {
    const customerSelectionId = this.props.id;
    this.props.dispatch(showSharedCustomerSelectionModal(customerSelectionId));
  }

  @boundMethod
  stopSharingCustomerSelection() {
    const customerSelectionId = this.props.id;
    this.props.dispatch(stopSharingCustomerSelection(customerSelectionId));
  }

  @boundMethod
  changeEntryPoint() {
    this.props.dispatch(changeEntryPoint(entryPointTypes.CUSTOMER_SELECTION, this.props.id));
  }

  render() {
    const {
      t,
      id,
      name,
      isShared,
      isOwned,
      userSharingStatus,
      disableShare,
      defaultEntryPoint,
      disableRename,
      disableDelete,
      useHorizontalToggle
    } = this.props;

    const { isDeleting, isRejecting, isRenaming } = this.state;

    const textSharedCustomerSelection = isShared ? t('sharing.update') : t('sharing.share');
    const sharedDropdownItem = (
      <Dropdown.Item eventKey="1" as="button" onClick={this.displaySharedModal}>
        {textSharedCustomerSelection}
      </Dropdown.Item>
    );

    const stopSharedDropdownItem = (
      <Dropdown.Item eventKey="1" as="button" onClick={this.stopSharingCustomerSelection}>
        {t('sharing.stop')}
      </Dropdown.Item>
    );

    const showEntrypointDivider = !disableRename || !disableDelete;

    const renameItem = (
      <Dropdown.Item data-test="renameCustomerSelection" as="button" onClick={this.startRenaming}>
        {t('rename')}
      </Dropdown.Item>
    );

    const deleteItem = (
      <Dropdown.Item data-test="deleteCustomerSelection" onClick={this.setDeletingState} as="button">
        {t('delete')}
      </Dropdown.Item>
    );

    const rejectSharedCustomerSelection = (
      <Dropdown.Item data-test="rejectSharedCustomerSelection" onClick={this.setRejectState} as="button">
        {t('delete')}
      </Dropdown.Item>
    );

    const entryPointItemAndDivider = (
      <Fragment>
        <Dropdown.Item
          as="button"
          role="menuitem"
          data-test="setAsEntrypoint"
          disabled={defaultEntryPoint?.id === id} // disable item if it's already the active entry point
          onClick={this.changeEntryPoint}
        >
          {t('dropdown.set-entrypoint')}
        </Dropdown.Item>
        {showEntrypointDivider && <Dropdown.Divider />}
      </Fragment>
    );

    const popperConfig = {
      modifiers: {
        hide: {
          enabled: false // set this to false because of warning appearing when we have disabled preventOverflow
        },
        preventOverflow: {
          enabled: false // set this to false because otherwise the positioning is off when we have an overflow container
        }
      }
    };

    // We portal the dropdown menu because the sidebar container has an overflow set
    // and this would cut-off the menu, if we would leave it in there.
    const dropDownMenu = ReactDOM.createPortal(
      <Dropdown.Menu
        role="menu"
        id="customer-selections-actions-menu"
        className={boardStyles.boardDropdownMenu}
        popperConfig={popperConfig}
      >
        {entryPointItemAndDivider}
        {disableShare || sharedDropdownItem}
        {disableShare || (isShared && stopSharedDropdownItem)}
        {disableRename || renameItem}
        {disableDelete || deleteItem}
        {!isOwned && userSharingStatus === ACCEPTED_STATUS && rejectSharedCustomerSelection}
      </Dropdown.Menu>,
      document.body
    );

    return (
      <Fragment>
        <div className={boardStyles.boardDropdown} ref={this.customerSelectionOptionsRef}>
          <Dropdown
            id="customer-selection-sactions"
            data-test="customerSelectionsContextMenu"
            alignRight={useHorizontalToggle} // horizontal toggle is used on the right side of screen, so lets' make sure the dropdown is not cut off
            // Blur Dropdown Toggle after selection, otherwise it would stay active, which does not seem reasonable...
            onSelect={() => document.activeElement.blur()}
            as={ButtonGroup}
          >
            <Dropdown.Toggle drop="bottom" split title={t('customer-selections.customer-selection-options')}>
              {useHorizontalToggle ? <MoreHoriz fontSize="large" /> : <span className="icon-dots">&nbsp;</span>}
            </Dropdown.Toggle>
            {dropDownMenu}
          </Dropdown>
        </div>
        <InputModal
          title={t('rename')}
          defaultText={name}
          open={isRenaming}
          onConfirm={this.remameCustomerSelection}
          onClose={this.stopRenaming}
        />
        <ConfirmationModal
          open={isDeleting}
          defaultText={t('dashboard-delete')}
          onConfirm={this.deleteCustomerSelection}
          onClose={this.removeDeletingState}
        />
        <ConfirmationModal
          open={isRejecting}
          defaultText={t('sharing.reject-selection')}
          onConfirm={() => this.rejectCustomerSelection(id, false)}
          onClose={this.removeRejectState}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  defaultEntryPoint: state.knowledgeGraphMeta.meta.defaultEntryPoint
});

export default connect(mapStateToProps)(CustomerSelectionDropdown);
