import React, { useRef, useMemo } from 'react';
import { FeatureGroup, Map, Marker, Tooltip, TileLayer, Popup } from 'react-leaflet';
import { withTranslation } from 'react-i18next';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import styles from './maps-chart.scss';
import './chart.scss';

// Added explicit icon because otherwise the transformations in webpack
// Would lead to errors...
// Start
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { parseNumber } from 'utils/numberFormat';

const icon = L.icon({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
// End

require('react-leaflet-markercluster/dist/styles.min.css');

const defaultCoordinates = {
  lat: 46.8182,
  lng: +8.2275,
  zoom: 8
};

const VeezooMapChart = props => {
  const mapRef = useRef();
  const groupRef = useRef();

  const positions = transformToPositions(props.answer.data);

  const positionMarkers = positions.map((position, index) => (
    <Marker key={`${position.title}_${index}`} position={[position.latitude, position.longitude]} icon={icon}>
      <Tooltip>{position.tooltip}</Tooltip>
      <Popup>{position.popup}</Popup>
    </Marker>
  ));

  const bounds = positions.map(position => [position.latitude, position.longitude]);

  const centerPosition = useMemo(() => {
    if (!bounds || bounds.length < 1) {
      return [defaultCoordinates.lat, defaultCoordinates.lng];
    }

    let lowestLat = null;
    let lowestLng = null;
    let highestLat = null;
    let highestLng = null;

    bounds.forEach(bound => {
      if (!lowestLat || bound[0] < lowestLat) lowestLat = bound[0];
      if (!highestLat || bound[0] > highestLat) highestLat = bound[0];
      if (!lowestLng || bound[1] < lowestLng) lowestLng = bound[1];
      if (!highestLng || bound[1] > highestLng) highestLng = bound[1];
    });

    const centerLat = (highestLat - lowestLat) / 2 + lowestLat;
    const centerLng = (highestLng - lowestLng) / 2 + lowestLng;

    return [centerLat, centerLng];
  }, [bounds]);

  return (
    <>
      <Map
        center={centerPosition}
        zoom={defaultCoordinates.zoom}
        scrollWheelZoom={false}
        maxZoom={19}
        boundsOptions={{ padding: [10, 10] }}
        className={styles.map}
        ref={mapRef}
        // bounds should only be set if there are at least two bounds. Otherwise it shouldn't be set.
        // If set with less than two bounds, empty array or null/undefined, it will throw an error.
        {...(bounds?.length > 0 ? { bounds } : {})}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FeatureGroup ref={groupRef}>
          <MarkerClusterGroup>{positionMarkers}</MarkerClusterGroup>
        </FeatureGroup>
      </Map>
    </>
  );
};

// we want to have this constant, otherwise shallow comparison breaks when new empty ones get created
const EmptyArray = [];

// Create Selector for Performance Reasons
// See: https://redux.js.org/recipes/computing-derived-data
function transformToPositions(positionsJson) {
  if (!positionsJson.columns) {
    // abort mission
    return EmptyArray;
  }

  let latCol = null;
  let longCol = null;

  positionsJson.columns.forEach(column => {
    if (column.type === 'latitude') latCol = column;
    if (column.type === 'longitude') longCol = column;
  });

  const markerCol =
    longCol && positionsJson.columns.find(column => column.identifier === longCol.targetColumnIdentifier);

  if (latCol && longCol && markerCol) {
    const usedCols = [latCol, longCol, markerCol];
    const otherColumns = positionsJson.columns.filter(column => !usedCols.includes(column));

    return positionsJson.data
      .map((row, rowIndex) => {
        const markerName =
          typeof row[markerCol.identifier] === 'string' ? row[markerCol.identifier] : row[markerCol.identifier].value;

        const otherInfo = otherColumns.map((column, column_index) => {
          const itemIdentifier = row[column.identifier]?.value || row[column.identifier];
          return itemIdentifier ? (
            <div key={`${column.identifier}_${column_index}`}>
              <span className={styles.popUpAttributeTitle}>
                {column.title.replace(' [' + markerCol.title + ']', '')}:
              </span>
              <span> {itemIdentifier.toString()}</span>
            </div>
          ) : null;
        });
        const popup = (
          <span key={`positionJson_${rowIndex}`}>
            <h3>
              {typeof row[markerCol.identifier] === 'string' ? (
                markerName
              ) : (
                <a href={row[markerCol.identifier].link}>{markerName}</a>
              )}
            </h3>
            {otherInfo}
          </span>
        );

        return {
          title: markerName,
          longitude: parseNumber(row[longCol.identifier]),
          latitude: parseNumber(row[latCol.identifier]),
          tooltip: markerName,
          popup: popup
        };
        // we only want to show markers when there actually is a long and a lat
      })
      .filter(data => !isNaN(data.latitude) && !isNaN(data.longitude));
  }
  return [];
}

export default withTranslation('veezoo')(VeezooMapChart);
