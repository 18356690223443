import { makeStyles } from '@material-ui/core/styles';

const commonClasses = {
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
};

export const useSignupStyles = makeStyles({
  ...commonClasses,
  root: {
    padding: '0',
    margin: '0 9px 0 0'
  },
  labelFont: {
    fontSize: ({ isMobile }) => (isMobile ? '12px' : '14px'),
    color: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'inherit')
  },
  colorPrimary: {
    '&&.Mui-checked': {
      color: 'var(--light-blue)'
    },
    '&& .MuiSvgIcon-root': {
      fontSize: ({ isMobile }) => (isMobile ? '17px' : '20px')
    }
  }
});

export const useVeezooStyles = makeStyles({
  ...commonClasses,
  root: {
    padding: '0',
    margin: ({ size }) => (size === 'small' ? '0 3px 0 0' : '0 9px 0 0')
  },
  labelFont: {
    fontSize: ({ isMobile, size }) => (isMobile || size === 'small' ? '12px' : '14px'),
    fontFamily: 'var(--font-family)',
    color: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'inherit')
  },
  colorPrimary: {
    '&&.Mui-checked': {
      color: 'var(--primary-color)'
    },
    '&& .MuiSvgIcon-root': {
      fontSize: ({ isMobile, size }) => (isMobile || size === 'small' ? '17px' : '20px')
    }
  }
});

export const useDefaultStyles = makeStyles({ ...commonClasses, root: {} });
