import React from 'react';

import UIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslation } from 'react-i18next';

import Button from 'components/v2/Button';

import CloseIcon from 'svg/v2/close.svg';

import useStyles from './styles';

const Dialog = ({ title, content, actions, open, hideCloseIcon, onClose, ...props }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <UIDialog classes={{ paper: styles.paper }} open={open} onClose={onClose} {...props}>
      {title && (
        <DialogTitle classes={{ root: styles.titleRoot }} disableTypography>
          {title}
          {!hideCloseIcon && (
            <button className={styles.closeButton} onClick={onClose} data-test="dialogCloseButton" title={t('close')}>
              <CloseIcon className={styles.closeIcon} />
            </button>
          )}
        </DialogTitle>
      )}
      <DialogContent classes={{ root: styles.contentRoot }}>{content}</DialogContent>
      {actions && (
        <DialogActions>
          {actions.map((action, index) => {
            return (
              <Button
                key={`action_${index}`}
                label={action.label}
                icon={action.icon}
                dark={action.color === 'dark'}
                fullWidth
                onClick={() => {
                  onClose();
                  action.onClick();
                }}
              />
            );
          })}
        </DialogActions>
      )}
    </UIDialog>
  );
};

export default Dialog;
