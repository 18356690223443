import { baseURL } from 'config/baseURL';
import { get, post, patch } from './utils.js';

const fetchWidgetById = id => get(`${baseURL}widgets/${id}?sessionId=${window.veezoo.sessionId}`);

const fetchWidgetsByBoardId = id => get(`${baseURL}boards/${id}/widgets`);

const addWidgetToBoard = (boardId, widget) => post(`${baseURL}boards/${boardId}/widgets`, widget);

const updateWidgetsOfBoard = (boardId, widgets) => patch(`${baseURL}boards/${boardId}/widgets`, widgets);

export default { fetchWidgetById, fetchWidgetsByBoardId, addWidgetToBoard, updateWidgetsOfBoard };
