import { baseURL } from 'config/baseURL';
import { headersPATCH, headersGET } from './../utils/HTTPheaders';
import { authFetch } from './../utils/authFetch';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const VALIDATE_TOKEN_REQUEST = 'VALIDATE_TOKEN_REQUEST';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';

export const resetPassword = (token, newPassword, email) => {
  return dispatch => {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    fetch(baseURL + 'user/password/reset', {
      method: 'PATCH',
      headers: headersPATCH,
      body: JSON.stringify({
        token: token,
        newPassword: newPassword
      }),
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS
        });
      } else {
        response.text().then(t =>
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            message: t
          })
        );
      }
    });
  };
};

export const validateToken = token => {
  return dispatch => {
    // Set the isFetchingAuthentication flag so the UI knows it should show a loader.
    dispatch({ type: VALIDATE_TOKEN_REQUEST });

    // Check if the user is authenticated by doing a dummy request.
    authFetch(
      baseURL + 'user/password/reset/' + token,
      {
        headers: headersGET,
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        response.ok
          ? response.text().then(t => dispatch({ type: VALIDATE_TOKEN_SUCCESS, message: t }))
          : response.text().then(t => dispatch({ type: VALIDATE_TOKEN_FAILURE, message: t }));
      })
      .catch(error => console.log('ERROR', error));
  };
};

// reducer
export const defaultPasswordResetState = {
  somethingWentWrong: false,
  isResettingPassword: false,
  isTokenValid: false,
  message: '',
  isPasswordChanged: false
};

export function passwordReset(state = defaultPasswordResetState, action) {
  switch (action.type) {
    case VALIDATE_TOKEN_REQUEST:
      return defaultPasswordResetState;

    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        somethingWentWrong: false,
        isTokenValid: true,
        message: action.message
      };

    case VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        somethingWentWrong: true,
        message: action.message
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResettingPassword: true
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        somethingWentWrong: true,
        isResettingPassword: false,
        message: action.message
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        somethingWentWrong: false,
        isResettingPassword: false,
        isPasswordChanged: true
      };

    default:
      return state;
  }
}
