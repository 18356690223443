import clsx from 'clsx';

import styles from './title.scss';

export default function Title({ icon, text, size }) {
  return (
    <div className={clsx(styles.title, size === 'large' ? styles.large : '')}>
      {icon && icon}
      <p>{text}</p>
    </div>
  );
}
