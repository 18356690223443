import { memo } from 'react';
import UISkeleton from '@material-ui/lab/Skeleton';

import styles from '../styles.scss';

export const DescriptionSkeleton = memo(() => <UISkeleton variant="rect" width={190} height={12} />);

export const ChipListSkeleton = memo(() => (
  <div className={styles.skeletonChips}>
    <UISkeleton variant="rect" width={80} height={12} />
    <UISkeleton variant="rect" width={100} height={12} />
    <UISkeleton variant="rect" width={60} height={12} />
    <UISkeleton variant="rect" width={80} height={12} />
    <div style={{ clear: 'both' }} />
  </div>
));
