import React, { memo } from 'react';
import { PropTypes } from 'prop-types';

import UIPopover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';

import { useStyles, useBackdropStyles } from './styles';

const PopoverPosition = ({ children, updatePositionRef, isEditing, x, y, open, ...props }) => {
  const classes = useStyles({ allowBackgroundInteraction: true });
  const backdropClasses = useBackdropStyles();

  return (
    <>
      <UIPopover
        action={updatePositionRef}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: y, left: x }}
        classes={classes}
        transitionDuration={0}
        {...props}
      >
        {/* MUI's Popover has its own Paper, but we don't use it here because we need external padding, and margin wouldn't fill our need */}
        <Paper>{children}</Paper>
      </UIPopover>
      <Backdrop className={backdropClasses.backdrop} open={open && isEditing} />
    </>
  );
};

export default memo(PopoverPosition);

PopoverPosition.propTypes = {
  children: PropTypes.any,
  updatePositionRef: PropTypes.any,
  isEditing: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  open: PropTypes.bool
};

PopoverPosition.defaultProps = {
  anchorOrigin: { vertical: 'center', horizontal: 'right' },
  transformOrigin: { vertical: 'center', horizontal: 'left' },
  isEditing: false,
  open: false
};
