import { studioURL } from 'config/baseURL';
import { del, get, post, put } from './utils.js';

const getTenants = () => get(`${studioURL}tenants`);

const createTenant = tenant => post(`${studioURL}tenants`, tenant);

const updateTenant = (id, tenant) => put(`${studioURL}tenants/${encodeURIComponent(id)}`, tenant);

const deleteTenant = id => del(`${studioURL}tenants/${encodeURIComponent(id)}`);

export default { getTenants, createTenant, updateTenant, deleteTenant };
