import React, { useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BooleanFilter from 'components/v2/BooleanFilter/BooleanFilter';

import Popover from '@material-ui/core/Popover';

import { booleanOptions, booleanConfirmMessage, booleanTitle } from 'utils/booleanFilterUtils';
import { modifyAnswer } from 'store/modules/chat-messages';

import { answerModificationTypes } from 'config/constants';

import PassageSpan from 'components/shared/PassageSpan';

import useStyles from 'styles/JSS/Popover';

const Q2QEditableBooleanPassage = ({ answerData, filter, username, dispatch }) => {
  const [anchor, setAnchor] = useState(null);

  const updatePositionRef = useRef(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const formattedFilter = useMemo(() => {
    const filterId = filter.booleanTypeLiteralFilter.booleanCandidateId;
    return {
      id: filterId,
      passages: filter.passages,
      value: filter.booleanTypeLiteralFilter.value || booleanOptions.UNSELECTED.value,
      valueNames: filter.booleanTypeLiteralFilter.valueNames,
      title: booleanTitle(filter, t)
    };
  }, [t, filter]);

  const handleSpanClick = event => {
    setAnchor(event.currentTarget);
  };

  const resetOptions = () => setAnchor(null);

  const onClose = () => resetOptions();

  const handleConfirm = async value => {
    const message = booleanConfirmMessage(value, formattedFilter, t);

    const payload = {
      username,
      message,
      answerData,
      id: formattedFilter.id,
      values: [value],
      modificationType: answerModificationTypes.boolean.value,
      t
    };

    await dispatch(modifyAnswer(payload));
  };

  const passageSpan = useMemo(() => {
    let resultSpan = [];
    filter.passages.forEach((passage, index) => {
      if (passage?.children?.length > 0) {
        passage.children.forEach((child, childIndex) => {
          const editable = child.id === formattedFilter.id;
          resultSpan.push(
            <PassageSpan
              passage={child}
              key={`boolean-passage-${index}-${childIndex}`}
              text={child.text}
              {...(editable ? { onClick: handleSpanClick, testClass: 'editableFilterPillSpan' } : {})}
            />
          );
        });
        return;
      }
      const editable = passage.id === formattedFilter.id;
      resultSpan.push(
        <PassageSpan
          passage={passage}
          key={`boolean-passage-${index}`}
          text={passage.text}
          {...(editable ? { onClick: handleSpanClick, testClass: 'editableFilterPillSpan' } : {})}
        />
      );
    });
    return resultSpan;
  }, [filter?.passages, formattedFilter?.id]);

  return (
    <>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        classes={{ paper: classes.paper }}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        action={updatePositionRef}
      >
        <BooleanFilter
          title={formattedFilter.title}
          initialValue={formattedFilter.value}
          valueNames={formattedFilter.valueNames}
          onClose={onClose}
          onConfirm={handleConfirm}
        />
      </Popover>
      {passageSpan}
    </>
  );
};

const mapStateToProps = state => ({ username: state.user.username });

export default connect(mapStateToProps)(Q2QEditableBooleanPassage);
