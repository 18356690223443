import React, { useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import uuid1 from 'uuid/v1';

import LoaderIcon from 'svg/loader.svg';

import InfinityIcon from 'images/infinity.png';

import { useDefaultStyles, useSignupStyles } from './FadeLoader.styles';

import { layouts } from 'config/constants';

const LoaderContext = React.createContext(null);

const defaultMessage = 'Loading...';
const defaultProcess = 'defaultProcess';

const LoaderWrapper = ({ children, layout }) => {
  const useStyles = layout === layouts.signup ? useSignupStyles : useDefaultStyles;
  const styles = useStyles();
  const [loading, setLoading] = useState({
    message: defaultMessage,
    processes: []
  });

  const showLoader = (message, process = defaultProcess) => {
    const formattedMessage = message && typeof message === 'string' ? message : defaultMessage;
    setLoading(prevLoading => ({
      message: formattedMessage,
      processes: [...prevLoading.processes, process]
    }));
  };

  const hideLoader = (process = defaultProcess) => {
    const formattedProcess = process && typeof process === 'string' ? process : defaultProcess;
    setLoading(prevLoading => ({
      ...prevLoading,
      processes: prevLoading.processes.filter(item => item !== formattedProcess)
    }));
  };

  const withLoader = useCallback(
    async (service, message = defaultMessage) => {
      const newProcess = uuid1();
      showLoader(message, newProcess);
      await service;
      hideLoader(newProcess);
      return service;
    },
    [showLoader, hideLoader]
  );

  return (
    <LoaderContext.Provider
      value={{
        loading: loading.processes.length > 0,
        showLoader,
        hideLoader,
        withLoader
      }}
    >
      <>
        <div className={clsx(styles.box, loading.processes.length > 0 ? styles.show : '')} data-test="loader">
          {layout === layouts.signup ? (
            <img src={InfinityIcon} alt="infinity" />
          ) : (
            <LoaderIcon className={styles.loading} />
          )}
          &nbsp;&nbsp;&nbsp;{loading.message}
        </div>
        {children}
      </>
    </LoaderContext.Provider>
  );
};

export default LoaderWrapper;

export const useLoader = () => {
  return useContext(LoaderContext);
};
