import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { boundMethod } from 'autobind-decorator';
import PropTypes from 'prop-types';

import styles from './discovery.scss';
import { discoveryToChat, fetchDiscoveryExample } from 'store/modules/discovery';
import AnswerMessage from '../message/AnswerMessage';
import LoadingMessage from '../message/LoadingMessage';
import DiscoveryAnswerFooter from '../message/DiscoveryAnswerFooter';

const propTypes = {
  discovery: PropTypes.object,
  username: PropTypes.string,
  selectedTopicIndex: PropTypes.number,
  showOverviewCallback: PropTypes.func,
  isMobile: PropTypes.bool,
  t: PropTypes.func
};

export class DiscoveryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedQuestionIndex: 0
    };
  }

  componentDidMount() {
    this.getDiscoveryExample();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.selectedQuestionIndex !== this.state.selectedQuestionIndex) {
      this.getDiscoveryExample();
    }
  }

  getExampleId(topicIndex, questionIndex) {
    return topicIndex + '-' + questionIndex;
  }

  getDiscoveryExample() {
    const { discovery, selectedTopicIndex, dispatch } = this.props;
    const { selectedQuestionIndex } = this.state;
    const entry = discovery.topics[selectedTopicIndex];
    const selectedQuestion = entry.questions.length > 0 && entry.questions[selectedQuestionIndex];

    if (selectedQuestion) {
      const exampleId = this.getExampleId(selectedTopicIndex, selectedQuestionIndex);
      dispatch(fetchDiscoveryExample(exampleId, selectedQuestion));
    }
  }

  @boundMethod
  handleQuestionClick(question, index) {
    this.setState({ selectedQuestionIndex: index });
  }

  @boundMethod
  transferToChat(selectedExample) {
    this.props.dispatch(discoveryToChat(selectedExample));
  }

  render() {
    const { discovery, t, selectedTopicIndex, showOverviewCallback } = this.props;
    const { selectedQuestionIndex } = this.state;
    const entry = discovery.topics[selectedTopicIndex];

    const requestedExampleId = this.getExampleId(selectedTopicIndex, selectedQuestionIndex);
    const selectedExample = discovery.fetchedExamples.find(fe => fe.exampleId === requestedExampleId);

    let previewExample, showInChatSpan;
    if (selectedExample && selectedExample.answer) {
      previewExample = (
        <AnswerMessage
          ref={node => {
            this[selectedExample.id] = node;
          }}
          key={selectedExample.id}
          {...selectedExample}
          customFooter={<DiscoveryAnswerFooter callback={() => this.transferToChat(selectedExample)} />}
          isMobile={this.props.isMobile}
        />
      );
      showInChatSpan = (
        <span>
          {' '}
          (
          <span onClick={() => this.transferToChat(selectedExample)} className={styles.hyperlink}>
            {t('show-in-chat')}
          </span>
          )
        </span>
      );
    } else {
      previewExample = <LoadingMessage />;
    }

    return (
      <div className={styles.topicContainer}>
        <h2 className={styles.title}>
          <span className={styles.linkedDiscoveryTitle} onClick={showOverviewCallback}>
            Discovery
          </span>
          <span>{' > ' + entry.title}</span>
        </h2>
        <div>
          <span className={styles.description}>{entry.description}</span>
          <p className={styles.examples}>{t('examples')}</p>
          <ul className={styles.exampleList} data-test="example-questions-list">
            {entry.questions.map((question, index) => {
              return (
                <li key={index} className={styles.exampleItem}>
                  <button
                    data-test="example-question-handle"
                    onClick={() => this.handleQuestionClick(question, index)}
                    className={selectedQuestionIndex === index ? styles.selectedExample : styles.example}
                  >
                    {question}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <p className={styles.examples}>
          {t('preview')} {showInChatSpan}
        </p>
        <div className={styles.previewExample}>{previewExample}</div>
      </div>
    );
  }
}

DiscoveryDetail.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    username: state.user.username
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(DiscoveryDetail));
