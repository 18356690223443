import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { addLocalMessage, fetchAnswerOfInterpretation, fetchMessage } from 'store/modules/chat-messages';
import { boundMethod } from 'autobind-decorator';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import EditIcon from '@material-ui/icons/Edit';

import { trackEvent } from 'utils/eventTracking';
import styles from './answer-message.scss';
import AnswerExplanation from 'components/shared/AnswerExplanation';
import AlternativeAnswers from 'components/message/AlternativeAnswers';
import { defaultAlternativeWarningThreshold } from 'store/modules/theme';
import Alert from '../../studio/components/Alert';

const propTypes = {
  isComplementary: PropTypes.bool,
  isAdmin: PropTypes.bool,
  filters: PropTypes.array,
  answerId: PropTypes.string,
  specialMessageText: PropTypes.string,
  specialMessageType: PropTypes.string,
  id: PropTypes.string,
  answerData: PropTypes.object,
  messages: PropTypes.array,
  alternativeInterpretations: PropTypes.array,
  parserAlternatives: PropTypes.array,
  confidence: PropTypes.number,
  alternativeWarningThreshold: PropTypes.number,
  customHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  structuredTitle: PropTypes.array,
  isMobile: PropTypes.bool,
  referenceTo: PropTypes.object,
  localQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export class AnswerMessageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlternativesButton: false,
      showAlternativeInterpretations: false,
      isInAdminMode: false
    };
  }

  @boundMethod
  toggleAlternativeInterpretations() {
    this.toggleAlternativeLogicFor(false);
  }

  @boundMethod
  toggleAdminMode() {
    this.toggleAlternativeLogicFor(true);
  }

  // This logic makes it possible to switch seamlessly between admin mode and user mode for the alternatives
  toggleAlternativeLogicFor(adminMode) {
    if (this.state.showAlternativeInterpretations) {
      // e.g. if you are in admin mode and toggled admin mode, don't show alternatives
      if (this.state.isInAdminMode === adminMode) {
        trackEvent('Alternative Answers Collapsed');
        this.setState({ showAlternativeInterpretations: false });
      } else {
        this.setState({ isInAdminMode: adminMode });
      }
    } else {
      trackEvent('Alternative Answers Expanded', { answer: { isAlert: this.shouldWeDisplayAlternativesWarning() } });
      this.setState({ isInAdminMode: adminMode, showAlternativeInterpretations: true });
    }
  }

  @boundMethod
  reAskNoFollowUp(localQuestionId) {
    this.props.dispatch(addLocalMessage('veezoo', this.props.t('follow-up-remove-answer')));

    const questionMessage = this.props.messages.find(msg => msg.id === localQuestionId); // find local message of this answer id
    const question = questionMessage.textAnswer; // eslint-disable-line
    const linkedEntities = questionMessage.linkedEntities; // eslint-disable-line

    // fetch and display the answer
    this.props.dispatch(
      fetchMessage(
        question,
        localQuestionId,
        {
          linkedEntities: linkedEntities,
          disableFollowUp: true
        },
        this.props.t
      )
    );
  }

  @boundMethod
  createFollowUpTag(referenceTo, localQuestionId, isMobile) {
    if (referenceTo) {
      return (
        <span key={-1} className={styles.followUpPill} data-test="followUpPill">
          <span>{isMobile ? 'FollowUp' : this.props.t('follow-up-question')}</span>
          <InfoTooltip text={this.props.t('follow-up-remove-tooltip')}>
            <button
              type="button"
              className={styles.cancelFollowUp}
              onClick={() => this.reAskNoFollowUp(localQuestionId)}
            >
              ✕
            </button>
          </InfoTooltip>
        </span>
      );
    } else {
      return null;
    }
  }

  @boundMethod
  handleDidYouMeanClick(interpretationId, answerId) {
    trackEvent('Did-You-Mean Selected', { interpretation: { id: interpretationId }, answer: { id: answerId } });
    this.props.dispatch(fetchAnswerOfInterpretation(interpretationId, answerId, ''));
  }

  shouldWeDisplayAlternativesWarning() {
    const { confidence, alternativeInterpretations, alternativeWarningThreshold } = this.props;
    // if the message does not even have an empty array, it means the message is not meant
    // to even offer alternative interpretations, therefore we disable it
    return (
      alternativeInterpretations &&
      // Check for a close match between chosen and other interpretations
      alternativeInterpretations.some(ai => Math.abs(ai.confidence - confidence) <= alternativeWarningThreshold) &&
      // we also disable it, if the shown interpretation is not the one with the highest confidence
      // which means, that it was already chosen as an alternative
      alternativeInterpretations.every(ai => ai.confidence < confidence)
    );
  }

  render() {
    const {
      structuredTitle,
      customHeader,
      filters,
      answerId,
      id,
      answerData,
      confidence,
      referenceTo,
      dispatch,
      alternativeWarningThreshold,
      localQuestionId,
      isComplementary,
      isAdmin,
      specialMessageText,
      specialMessageType,
      alternativeInterpretations,
      parserAlternatives,
      isMobile,
      t
    } = this.props;
    const { showAlternativeInterpretations, isInAdminMode } = this.state;

    const followUpTag = this.createFollowUpTag(referenceTo, localQuestionId, isMobile);
    const showAlternativesWarning = this.shouldWeDisplayAlternativesWarning();

    const mainFilters =
      filters &&
      filters.map(f => ({
        label: f,
        isGreyedOut: showAlternativeInterpretations
      }));

    const mainTitle = {
      title: structuredTitle,
      isGreyedOut: showAlternativeInterpretations
    };

    // Alternatives with specific patterns and short description of the difference (for common ambiguous questions)
    const orDidYouMeanAlternatives = parserAlternatives && (
      <div>
        {parserAlternatives.map(parserAlternative => {
          const { alternativeInterpretationId, descriptionOfAlternative, descriptionOfSelected } = parserAlternative;
          return (
            <Alert isCloseable inAnswerHeader={true} key={alternativeInterpretationId}>
              <span>
                {t('or-did-you-mean.start')}{' '}
                <span
                  className={styles.parserAlternativeLink}
                  onClick={() => this.handleDidYouMeanClick(alternativeInterpretationId, answerId)}
                  data-test="didYouMeanAlternative"
                >
                  {descriptionOfAlternative}
                </span>
                {descriptionOfSelected && (
                  <>
                    {' '}
                    {t('or-did-you-mean.instead-of')} <strong>{descriptionOfSelected}</strong>
                  </>
                )}
              </span>
            </Alert>
          );
        })}
      </div>
    );

    const alternativesBlock = (
      <AlternativeAnswers
        showAlternativeInterpretations={showAlternativeInterpretations}
        isInAdminMode={isInAdminMode}
        answerId={answerId}
        alternativeInterpretations={alternativeInterpretations}
        mainConfidence={confidence}
        mainTitle={structuredTitle}
        mainFilters={filters}
        confidenceDeltaThreshold={alternativeWarningThreshold}
      />
    );
    const hasAlternatives = alternativeInterpretations?.length > 0;

    const specialMessageElement = specialMessageText?.length > 0 && (
      <Alert severity={specialMessageType} isCloseable inAnswerHeader={true}>
        {specialMessageText}
      </Alert>
    );

    const checkAlternativesElement = showAlternativesWarning && !(parserAlternatives?.length > 0) && (
      <Alert severity="warning" isCloseable inAnswerHeader={true}>
        {t('did-you-mean.alert-start')} <EditIcon onClick={this.toggleAlternativeInterpretations} />{' '}
        {t('did-you-mean.alert-end')}
      </Alert>
    );

    return (
      <div
        className={styles.answerMessageHeader}
        data-answer-tutorial="title-with-filters"
        data-customer-selection-tutorial="title"
      >
        {specialMessageElement || checkAlternativesElement}
        {orDidYouMeanAlternatives}
        {customHeader}
        <AnswerExplanation
          titleObject={mainTitle}
          filterObjects={mainFilters}
          answerData={answerData}
          answerId={answerId}
          messageId={id}
          followUpTag={followUpTag}
          shouldAlertUser={showAlternativesWarning}
          hasAlternatives={hasAlternatives}
          isShowingAlternatives={showAlternativeInterpretations}
          titleClickCallback={isAdmin && hasAlternatives ? this.toggleAdminMode : undefined} // only have a title callback if admin
          alternativesButtonCallback={this.toggleAlternativeInterpretations}
          dispatch={dispatch}
          possibleToAddNewFilter={true}
        />
        {alternativesBlock}
        {isComplementary && <h4 className={styles.complementaryNoteAbove}>{t('complementary')}</h4>}
      </div>
    );
  }
}

AnswerMessageHeader.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    messages: state.chatMessages,
    specialMessageText: state.chatMessages.find(m => m.id === ownProps.id)?.specialMessageText,
    specialMessageType: state.chatMessages.find(m => m.id === ownProps.id)?.specialMessageType,
    isAdmin: state.user.isAdmin,
    alternativeWarningThreshold:
      state.theme.themeDetails.alternativeWarningThreshold || defaultAlternativeWarningThreshold
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(AnswerMessageHeader));
