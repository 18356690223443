import { baseURL } from 'config/baseURL';
import { headersGET } from './../utils/HTTPheaders';
import { authFetch } from './../utils/authFetch';

export const ANNOTATED_QUESTION_REQUEST = 'ANNOTATED_QUESTION_REQUEST';
export const ANNOTATED_QUESTION_SUCCESS = 'ANNOTATED_QUESTION_SUCCESS';
export const ANNOTATED_QUESTION_FAIL = 'ANNOTATED_QUESTION_FAIL';
export const ANNOTATED_QUESTION_CLEAR = 'ANNOTATED_QUESTION_CLEAR';

export const clearAnnotatedQuestion = () => {
  return {
    type: ANNOTATED_QUESTION_CLEAR
  };
};

export const fetchAnnotatedQuestion = (questionId, rawText) => {
  return dispatch => {
    dispatch({
      type: ANNOTATED_QUESTION_REQUEST,
      questionId: questionId
    });

    authFetch(
      baseURL +
        'annotations/questions?questionId=' +
        questionId +
        '&text=' +
        encodeURIComponent(rawText) +
        '&sessionId=' +
        window.veezoo.sessionId,
      {
        method: 'GET',
        headers: headersGET,
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: ANNOTATED_QUESTION_FAIL,
            questionId: questionId
          });
          throw Error(response.statusText);
        }
      })
      .then(json =>
        dispatch({
          type: ANNOTATED_QUESTION_SUCCESS,
          questionId: questionId,
          annotation: json
        })
      )
      .catch(error => console.log('ERROR', error));
  };
};

export const defaultAnnotatedQuestionState = {
  questionId: '',
  annotation: undefined
};

// reducer annotatedQuestion describes possibility to edit message
export function annotatedQuestion(state = defaultAnnotatedQuestionState, action) {
  switch (action.type) {
    case ANNOTATED_QUESTION_REQUEST:
      return {
        ...state,
        questionId: action.questionId
      };

    case ANNOTATED_QUESTION_FAIL:
      return {
        ...state,
        questionId: action.questionId
      };

    case ANNOTATED_QUESTION_SUCCESS:
      if (action.questionId === state.questionId) {
        return {
          ...state,
          questionId: action.questionId,
          annotation: action.annotation
        };
      } else {
        return state;
      }

    case ANNOTATED_QUESTION_CLEAR:
      return defaultAnnotatedQuestionState;

    default:
      return state;
  }
}
