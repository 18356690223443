import { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from 'components/v2/Button';
import PopoverButton from 'components/Popover/PopoverButton';
import MultiselectList from 'components/MultiselectList/MultiselectList';

import uuid1 from 'uuid/v1';
import services from 'services';
import { modifyTableColumnAnswer } from 'store/modules/chat-messages';

import styles from './modify-column-button.scss';
import useMediaQuery from 'utils/mediaQueries';

import { getSidebarNodes } from 'store/modules/graph/graph';

const initialState = {
  loading: false,
  modifications: [],
  existing: [],
  possible: []
};

const ModifyColumnButton = ({ answer, dispatch, nodes, sidebarNodeLayouts, t }) => {
  const [state, setState] = useState(initialState);
  const isMobile = useMediaQuery();
  const sidebarNodes = useMemo(() => getSidebarNodes(nodes, sidebarNodeLayouts), [nodes, sidebarNodeLayouts]);
  const options = [...state.existing.map(i => ({ ...i, selected: true })), ...state.possible];

  const fetchModifications = async () => {
    const { answerId, interpretationId } = answer;
    setState(prev => ({ ...prev, loading: true }));
    const result = await services.getTableAnswerColumnModifications(answerId, interpretationId);
    if (result.success) {
      const existing = result.data.existing.map(item => ({ ...item, uniqueId: uuid1() }));
      const possible = result.data.possible.map(item => ({ ...item, uniqueId: uuid1() }));

      setState(prev => ({ ...prev, existing, possible, options: [...existing, ...possible] }));
    }
    setState(prev => ({ ...prev, loading: false }));
  };

  const modifyColumns = async selected => {
    const columnsToAdd = state.possible.filter(possible => selected.some(item => item.uniqueId === possible.uniqueId));
    const columnsToRemove = state.existing.filter(
      existing => !selected.some(item => item.uniqueId === existing.uniqueId)
    );

    const modifications = [...columnsToAdd, ...columnsToRemove].map(({ uniqueId, text, value, ...rest }) => ({
      type: 'COLUMN_MOD',
      columnModification: rest
    }));

    const message = `${t('modifications.modify-columns')}: ${answer.title}`;
    const { answerId, partialAnswerId, interpretationId } = answer;
    const payload = {
      answerId,
      partialAnswerId,
      interpretationId,
      modifications
    };

    dispatch(modifyTableColumnAnswer(state.modifications, message, payload, t));
  };

  useEffect(() => {
    fetchModifications();
  }, []);

  // We use here the sidebar as a reference on how we should display the column options to the user in a 2-level hierarchy.
  const sidebarNodeInfos =
    sidebarNodes &&
    sidebarNodes
      .map(node => {
        const parentNode = {
          uri: node.uri,
          isChild: false
        };

        const childrenNodes = node.children.map(child => {
          return {
            uri: child.uri,
            relationUri: child.relationUri,
            isChild: true,
            parent: parentNode
          };
        });

        return [parentNode, ...childrenNodes];
      })
      .reduce((acc, curVal) => {
        return acc.concat(curVal);
      }, []);

  // Sorting function for the column options so that we get selected ones first (or those with many selected children)
  const compare = (a, b) => {
    const numberOfSelectedA = [a, ...(a.children || [])].filter(c => c.option?.selected).length;
    const numberOfSelectedB = [b, ...(b.children || [])].filter(c => c.option?.selected).length;

    return numberOfSelectedB - numberOfSelectedA;
  };

  // Retrieves the corresponding KG concept (in the sidebar) for a column option
  const findCorrespondingKGSidebarNode = option => {
    const parentNode = !!sidebarNodeInfos && sidebarNodeInfos.find(n => n.uri === option.link && !n.isChild);
    const childNodeWithRightParent =
      !!sidebarNodeInfos &&
      sidebarNodeInfos.find(
        n => (n.uri === option.link || n.relationUri === option.link) && n.isChild && n.parent.uri === option.parentLink
      );
    const childNodeWithAnyParent =
      !!sidebarNodeInfos &&
      sidebarNodeInfos.find(n => (n.uri === option.link || n.relationUri === option.link) && n.isChild);

    // prefer parent nodes
    return parentNode || childNodeWithRightParent || childNodeWithAnyParent;
  };

  const mapping = options.map(o => ({ sidebarNode: findCorrespondingKGSidebarNode(o), option: o }));
  const optionsWithoutSidebarNode = mapping.filter(m => !m.sidebarNode);

  const optionsWithSidebarNode =
    sidebarNodes &&
    sidebarNodes
      .map(s => {
        const childrenWithOptions = s.children
          .map(child => {
            const option = mapping.find(m => m.sidebarNode?.uri === child.uri && m.sidebarNode?.parent?.uri === s.uri)
              ?.option;

            return {
              ...child,
              option: option
            };
          })
          .filter(c => c.option);

        const parentOption = mapping.find(m => m.sidebarNode?.uri === s.uri && !m.sidebarNode?.isChild)?.option || {
          name: s.name,
          disabled: true
        }; // Always add a parent (even if disabled) if a child appears as a column option

        return {
          ...s,
          option: parentOption,
          children: childrenWithOptions
        };
      })
      .filter(s => s.children.length > 0 || !s.option?.disabled); // Only keep the KG sidebar concepts that appear as column modifications

  // combine those we could map to a sidebar node with those we couldn't, e.g. transformed values (average duration)
  const allOptions = sidebarNodes && [...optionsWithSidebarNode, ...optionsWithoutSidebarNode];
  // sort so we can get selected clusters at the top
  const sorted = allOptions?.sort(compare);
  // flatten the column options into an array, but still keeping the information if it's a child or not
  const flattened = sorted
    ?.map(s => [
      s.option,
      ...(s.children?.map(c => ({ ...c.option, isChild: true })) || []).sort((a, b) => !!b.selected - !!a.selected)
    ])
    .reduce((acc, curVal) => {
      return acc.concat(curVal);
    }, []);

  // if it's a child, then add padding so we get the sense of hierarchy
  const formattedOptions = flattened?.map(item => {
    const className = item.isChild && styles.paddedCheckbox;

    return {
      ...item,
      text: item.name,
      value: item.uniqueId,
      className: className
    };
  });
  const formattedSelectedOptions = state.existing.map(item => ({ ...item, text: item.name, value: item.uniqueId }));

  // to not take a lot of space in mobile, we don't show any text
  const formattedLabel = isMobile
    ? ''
    : t('modifications.x-of-y-columns', {
        x: state.existing.length || '-',
        y: options.length || '-'
      });

  return (
    <div className={styles.tableOptionsBar}>
      <PopoverButton
        button={
          <Button
            className={styles.customSize}
            label={formattedLabel}
            disabled={state.loading}
            loading={state.loading}
            showArrow
          />
        }
        allowBackgroundInteraction
        popoverClosingActionsFromChild={{ onConfirm: modifyColumns }}
        buttonProps={{ 'data-test': 'modifyColumnsButton' }}
      >
        <MultiselectList
          options={formattedOptions}
          selectedOptions={formattedSelectedOptions}
          hasPredefinedSelectedOptions
          confirmButtonText={t('apply')}
          cancelButtonText={t('cancel')}
          loading={state.loading}
          itemDataTest="selectColumnsItem"
        />
      </PopoverButton>
    </div>
  );
};

export function mapStateToProps(state, ownProps) {
  return {
    nodes: state.graph.nodes,
    sidebarNodeLayouts: state.graph.sidebarNodeLayouts
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(ModifyColumnButton));
