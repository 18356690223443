import { createTheme, makeStyles } from '@material-ui/core';

const borderRadius = 2;

export const getTimePickerTheme = ({ fullWidth, isWeek }) =>
  createTheme({
    overrides: {
      MuiPickersMonth: {
        root: { fontSize: 14 }
      },
      MuiPickersYear: {
        root: { fontSize: 14 },
        yearSelected: { fontSize: 18 }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: 'var(--primary-color)'
        }
      },
      MuiFormControl: {
        root: {
          width: () => (fullWidth ? '100%' : 'initial')
        }
      },
      MuiInputLabel: {
        root: {
          fontFamily: 'lexend',
          fontSize: '12px',
          fontWeight: 400
        },
        shrink: {
          transform: 'none !important',
          position: 'static'
        }
      },
      MuiOutlinedInput: {
        root: {
          border: 'solid thin var(--light-gray)',
          borderRadius
        },
        input: {
          color: 'var(--font-color)',
          fontFamily: 'lexend',
          fontSize: 14,
          padding: '8px 12px',
          lineHeight: 20
        },
        adornedEnd: {
          paddingRight: 0
        },
        notchedOutline: {
          borderColor: 'transparent',
          borderRadius,
          top: 0,
          '& > legend': {
            display: 'none'
          }
        }
      },
      MuiInputBase: {
        root: {
          width: '100%',
          marginTop: ({ label }) => (label ? 8 : 'initial'),
          '&$focused': {
            border: 'solid 0px transparent',
            '&>.MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--light-gray) !important',
              borderWidth: '1px !important'
            }
          },
          '&$disabled': {
            color: 'rgba(0, 0, 0, 0.18)'
          }
        }
      },
      MuiInputAdornment: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)'
        }
      },
      MuiFormHelperText: {
        root: {
          fontSize: 12,
          fontWeight: 400
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          padding: 0
        },
        dayLabel: {
          width: 50
        }
      },
      MuiPickersCalendar: {
        week: {
          borderTop: isWeek ? 'solid thin #eeeeee' : 'none',
          '&:hover': {
            backgroundColor: isWeek ? 'rgba(0, 0, 0, 0.04)' : 'transparent'
          }
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: 400,
          minHeight: 350
        },
        pickerViewLandscape: { padding: () => (isWeek ? '8px 8px 8px 16px' : '0 8px') }
      },
      MuiTypography: {
        body1: {
          fontSize: 14
        },
        body2: {
          fontFamily: 'Lexend',
          fontSize: 14,
          fontWeight: 300
        },
        subtitle1: {
          fontSize: 14
        },
        caption: {
          fontSize: 12
        }
      }
    }
  });

export const getTimePickerStyles = makeStyles(() => ({
  root: { width: 125 },
  icon: {
    width: 13,
    height: 13,
    cursor: 'pointer',
    '& path': { opacity: 0.55 }
  }
}));
