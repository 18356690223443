// This function takes a component...
import { withRouter } from 'react-router-dom';

/**
 * Add a flag called isEmbedded to the WrappedComponent's props.
 *
 * The information is taken from the URL query / params.
 * We use the react-router-dom's withRouter HOC to get access to the location object.
 *
 * Later could be extended with more information from the URL to steer the behaviour of the embedded version.
 *
 * For now this HOC also has functions to communicate with the parent window.
 */

export const embeddedMessageTypes = {
  boards: 'boards',
  openBoard: 'openBoard',
  setLanguage: 'setLanguage'
};

export function receiveMessageFromParentWindow(type, callback) {
  window.addEventListener('message', event => {
    const { type: messageType, ...data } = event.data;
    if (messageType === type) {
      callback(data);
    }
  });
}

export function postMessageToParentWindow(type, data) {
  if (window.parent !== window) {
    const message = { type: type, ...data };
    window.parent.postMessage(message, '*');
  }
}

export function withEmbedded(WrappedComponent) {
  return function Component(props) {
    const isEmbedded = props.location.search.includes('embedded=true');

    return <WrappedComponent isEmbedded={isEmbedded} {...props} />;
  };
}
export default WrappedComponent => withRouter(withEmbedded(WrappedComponent));
