import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/loaders/infinity-loading/Loading';

import styles from './index.module.scss';

const PageLoader = ({ message }) => {
  return (
    <div className={styles.container}>
      <Loading isLocal />
      <div className={styles.status}>{message}</div>
    </div>
  );
};

export default PageLoader;

PageLoader.propTypes = {
  message: PropTypes.string
};

PageLoader.defaultProps = {
  message: 'Loading...'
};
