import { baseURL } from 'config/baseURL';
import { post, postWithBlobResponse } from './utils.js';

const getTableAnswerColumnModifications = (answerId, interpretationId) =>
  post(`${baseURL}answers/column-modifications/${answerId}/${interpretationId}?sessionId=${window.veezoo.sessionId}`);

const getFilterTemplates = (answerId, interpretationId, candidateId, representedRelation) =>
  post(`${baseURL}answers/filter-templates`, {
    answerId,
    interpretationId,
    sessionId: window.veezoo.sessionId,
    candidateId,
    representedRelation
  });

const downloadAnswerAsExcel = (partialAnswerId, visualizationType) => {
  const body = {
    visualizationType,
    fileType: 'xlsx',
    sessionId: window.veezoo.sessionId
  };
  return postWithBlobResponse(`${baseURL}answers/partials/${partialAnswerId}/export`, body);
};

export default {
  getTableAnswerColumnModifications,
  getFilterTemplates,
  downloadAnswerAsExcel
};
