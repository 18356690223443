import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'components/v3';

const NumberField = forwardRef(({ layout, onChange: controlledOnChange, value: controlledValue, ...props }, ref) => {
  // We control the component internally, simulating an "uncontrolled" component because for uncontrolled components
  // it's not possible to limit the type of characters for an input, once we can't "control" it.
  const [uncontrolledValue, setUncontrolledValue] = useState('');

  const handleChange = event => {
    const innerValue = event.target.value;
    if (innerValue.match(/(^[0-9]+$|^$)/)) {
      if (controlledOnChange) {
        // We send back the whole "event" instead of the "innerValue" to keep convention from MUI that for
        // controlled components, the "onChange" usually returns the "event", like TextField, TextArea, Select, etc.
        controlledOnChange(event);
      } else {
        setUncontrolledValue(innerValue);
      }
    }
  };

  return (
    <TextField
      fullWidth
      ref={ref}
      margin="dense"
      layout={layout}
      value={controlledValue || uncontrolledValue}
      onChange={handleChange}
      {...props}
    />
  );
});

export default NumberField;

NumberField.propTypes = {
  layout: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

NumberField.defaultProps = {
  layout: 'default',
  onChange: undefined,
  value: undefined
};
