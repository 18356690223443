import { makeStyles } from '@material-ui/core';

const spinTime = '1s';

export default makeStyles({
  loading: {
    animation: `$spin ${spinTime} linear infinite`,
    width: ({ size }) => size,
    height: ({ size }) => size,
    fill: ({ color }) => color,
    '& path': {
      fill: ({ color }) => color
    }
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});
