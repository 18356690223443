import { getCurrentDateTimeProtocol } from 'utils/datetimeUtils';

export const openSupportMailWithFreshId = (htmlSupport, username) => {
  const defaultSubject = `[${getCurrentDateTimeProtocol()}] Veezoo: Support/Feedback - ${username}`;

  const formattedUrl = htmlSupport.replace(/\?/g, '&');
  const params = new URLSearchParams(formattedUrl);

  const to = formattedUrl ? [...params.entries()][0][0] : 'mailto:support@veezoo.com';
  const cc = params.get('cc');
  const bcc = params.get('bcc');
  const subject = params.get('subject');
  const body = params.get('body');

  const newSubject = `?subject=${subject || defaultSubject}`;
  const newCc = cc ? `&cc=${cc}` : '';
  const newBcc = bcc ? `&bcc=${bcc}` : '';
  const newBody = body ? `&body=${body}` : '';

  window.location.href = to + newSubject + newCc + newBcc + newBody;
};
