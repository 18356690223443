import Drawer from '@material-ui/core/Drawer';

import { makeStyles } from '@material-ui/core/styles';

import { SIDEBAR_EXPANDED_SIZE } from 'config/constants';

export const useStyles = makeStyles({
  paper: {
    width: '100%',
    maxWidth: SIDEBAR_EXPANDED_SIZE
  }
});

const MobileDrawer = ({ children, open, onClose }) => {
  const classes = useStyles();
  return (
    <Drawer anchor="left" classes={classes} open={open} onClose={onClose}>
      {children}
    </Drawer>
  );
};

export default MobileDrawer;
