export const booleanOptions = {
  TRUE: { value: 'TRUE', label: 'yes' },
  FALSE: { value: 'FALSE', label: 'no' },
  UNSELECTED: { value: 'UNSELECTED', label: 'yes-or-no' }
};

// "t" stands for the "translation" function and should be passed by params instead of imported inside this "utils" file.
// That is because "t" can only be called and used inside React components and, in case we need to use this function
// outside of react, we just don't pass "t" and the strings will be shown as they are, instead of trying to translate.
export const booleanConfirmMessage = (value, filter, t = item => item) =>
  `${filter.title}: ${booleanName(value, filter.valueNames, t)}`;

// Returns the name of the boolean value excluding the unselected value
const booleanTrueFalseName = (value, valueNames, t = item => item) =>
  valueNames?.[value] ?? t(booleanOptions[value].label);

// Returns the name of the boolean value (e.g. 'Yes or No' or with valueNames 'Active or Inactive')
export const booleanName = (value, valueNames, t = item => item) =>
  value === booleanOptions.UNSELECTED.value
    ? `${booleanTrueFalseName(booleanOptions.TRUE.value, valueNames, t)}  ${t('or')} ${booleanTrueFalseName(
        booleanOptions.FALSE.value,
        valueNames,
        t
      )}`
    : booleanTrueFalseName(value, valueNames, t);

// When true & false values are named, takes them (Active or Inactive), otherwise takes true name (Active) from the passage
export const booleanTitle = (filter, valueNames, t = item => item) =>
  filter.booleanTypeLiteralFilter.valueNames
    ? booleanName(booleanOptions.UNSELECTED.value, filter.booleanTypeLiteralFilter.valueNames, t)
    : filter.passages.find(passage => passage.id === filter.booleanTypeLiteralFilter.booleanCandidateId)?.text || '';
