import { makeStyles } from '@material-ui/core';

export default makeStyles({
  selectContainer: {
    marginLeft: 5,
    fontSize: 14
  },
  title: {
    marginTop: 18,
    marginBottom: 5,
    color: 'var(--dark-gray)'
  },
  nameWithParentClassNameContainer: {
    display: 'flex',
    marginLeft: 5,
    width: '75%',
    alignItems: 'center'
  },
  parentClassNameContainer: {
    fontSize: 14,
    fontWeight: 'initial'
  },
  rightElement: {
    marginLeft: 10
  },
  buttonContainer: {
    marginTop: 25
  },
  sharingElement: {
    paddingRight: 10,
    '& svg': {
      // needed for IE to have the correct icon width / height
      maxWidth: 32,
      maxHeight: 32
    }
  },
  form: {
    minWidth: 400
  }
});
