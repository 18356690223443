import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DatetimeFilter from 'components/v2/DatetimeFilter/DatetimeFilter';

import {
  defaultValues,
  formatGranularities,
  formatDateFilterInitialView,
  formatDateModificationPayload
} from 'utils/dateFilterUtils';

import { modifyAnswer } from 'store/modules/chat-messages';
import { dateComputations, answerModificationTypes } from 'config/constants';

const dateTypeNew = 'NEW';

// We iterate over the structuredFilters (or commonFiltersStructured) filtering the necessary passages and format
// these filters in a default object that is easier to understand, so that we can reuse it in other places inside this component.
const formatStructuredFilters = (answer, column) => {
  const filters = answer?.structuredFilters || answer?.commonFiltersStructured || [];

  const formattedFilters = {
    type: dateTypeNew,
    values: defaultValues,
    candidateId: column.identifier,
    computation: dateComputations.IN.value,
    passages: null
  };

  const filter = filters.find(
    filter =>
      filter?.outgoingDateFilterEdit && filter.outgoingDateFilterEdit?.id === column.dateFilterIdentifyingCandidate
  );

  if (filter) {
    formattedFilters.passages = filter.passages;
    formattedFilters.type = filter.outgoingDateFilterEdit.type;
    formattedFilters.candidateId = filter.outgoingDateFilterEdit.id;

    if ('outgoingFilterComputationEditInfos' in filter.outgoingDateFilterEdit) {
      formattedFilters.computation =
        filter.outgoingDateFilterEdit.outgoingFilterComputationEditInfos[0].computationName;
      formattedFilters.values = filter.outgoingDateFilterEdit.outgoingFilterComputationEditInfos.reduce(
        (total, current) => [...total, ...current.values],
        []
      );
    } else {
      formattedFilters.values = filter.outgoingDateFilterEdit.values;
      formattedFilters.computation = filter.outgoingDateFilterEdit.computationName;
    }
  }

  return formattedFilters;
};

const DateFilter = ({ answer, username, column, onConfirm, onClose, updatePositionRef, dispatch }) => {
  const { t } = useTranslation();

  const structuredFilters = useMemo(() => formatStructuredFilters(answer, column), [answer, column]);

  const granularities = useMemo(() => formatGranularities(column.granularities), [column.granularities]);

  const initialView = useMemo(() => formatDateFilterInitialView(structuredFilters?.values), [
    structuredFilters?.values
  ]);

  const handleConfirm = async data => {
    const dateFilterText = t('date-modifications.filterDate');

    const { message, payload } = formatDateModificationPayload(data, dateFilterText, t);

    const modificationPayload = {
      username,
      message,
      answerData: answer,
      linkedEntities: structuredFilters.passages,
      modificationType: answerModificationTypes.datetime.value,
      type: structuredFilters.type,
      id: structuredFilters.candidateId,
      representedCandidate: column.representedCandidate,
      representedRelation: column.representedRelation,
      computationName: data.selectedComputation,
      values: payload,
      t
    };

    await dispatch(modifyAnswer(modificationPayload));
    onConfirm();
  };

  return (
    <DatetimeFilter
      onConfirm={handleConfirm}
      onCancel={onClose}
      initialComputation={structuredFilters.computation}
      updatePositionRef={updatePositionRef}
      values={structuredFilters.values}
      views={granularities}
      initialView={initialView}
    />
  );
};

const mapStateToProps = state => ({ username: state.user.username });

export default connect(mapStateToProps)(DateFilter);
