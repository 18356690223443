export const styles = theme => ({
  '&': {
    lineHeight: '23px'
  },
  '& h2': {
    fontSize: 15,
    fontWeight: 500
  }
});

export default theme => ({
  root: {
    ...styles(theme)
  }
});
