import { makeStyles } from '@material-ui/core';

const borderRadius = '4px';

export default makeStyles({
  root: {
    marginRight: 20,
    padding: '0 10px',
    minWidth: 110,
    height: 36,
    borderColor: 'var(--light-gray)',
    borderRadius,
    fontWeight: 400,
    fontSize: 14,
    backgroundColor: 'transparent',
    '&:hover': {
      opacity: 0.7,
      backgroundColor: 'var(--lightest-gray)'
    },
    '&.Mui-selected': {
      borderColor: 'var(--primary-color)',
      backgroundColor: 'transparent',
      color: 'var(--font-color)',
      '&:hover': {
        opacity: 0.7,
        backgroundColor: 'var(--lightest-gray)'
      }
    }
  },
  error: {
    borderColor: 'red',
    color: 'red'
  },
  icon: {
    width: 18,
    height: 18,
    padding: 5,
    fill: 'var(--primary-color)',
    '& path': { fill: 'var(--primary-color)' }
  },
  iconError: {
    '& path': { fill: 'red' }
  },
  label: {
    justifyContent: 'space-evenly'
  },
  labelSpan: {
    padding: 5
  },
  iconLabel: { display: 'flex', alignItems: 'center', lineHeight: '10px' }
});

export const useToggleButtonGroupStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex !important'
  },

  groupedHorizontal: {
    '&:not(:first-child)': {
      borderLeft: 'solid thin var(--light-gray) !important',
      borderTopLeftRadius: `${borderRadius} !important`,
      borderBottomLeftRadius: `${borderRadius} !important`
    },
    '&:not(:last-child)': {
      borderLeft: 'solid thin var(--light-gray) !important',
      borderTopRightRadius: `${borderRadius} !important`,
      borderBottomRightRadius: `${borderRadius} !important`
    },
    '&.Mui-selected': {
      borderLeft: 'solid thin var(--primary-color) !important'
    },
    error: {
      border: 'solid thin red !important'
    }
  },
  groupedHorizontalWithError: {
    border: 'solid thin red !important',
    '& path': {
      fill: 'red'
    },
    '& span': {
      color: 'red'
    }
  }
});
