import { baseURL, studioURL } from 'config/baseURL';
import { get, post } from './utils.js';

const getIntegrations = () => get(`${studioURL}/integrations`);

const getDbtCloudDescriptions = (kgId, jobId) =>
  get(
    `${baseURL}studio/api/knowledge-graphs/${encodeURIComponent(
      kgId
    )}/integrations/dbt-cloud/descriptions/${encodeURIComponent(jobId)}`
  );

const updateDbtCloudDescriptions = (kgId, body) =>
  post(`${baseURL}studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/integrations/dbt-cloud/descriptions`, body);

const updateIntegration = (id, body) => post(`${studioURL}integrations/${encodeURIComponent(id)}`, body);

const exasolCallback = body => post(`${baseURL}exasol/callback`, body);

export default {
  getIntegrations,
  getDbtCloudDescriptions,
  updateDbtCloudDescriptions,
  updateIntegration,
  exasolCallback
};
