import { useContext, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import MultiselectList from 'components/MultiselectList/MultiselectList';
import { Q2QStateContext } from 'components/shared/Q2QStateContext';
import { modifyAnswer } from 'store/modules/chat-messages';

import services from 'services';
import { handleError } from 'services/utils';

import { passageTypes, answerModificationTypes } from 'config/constants';

import getEntityFromEntityUri from 'utils/getEntityFromEntityUri';

const initialOptions = {
  query: '',
  start: 0,
  limit: 100,
  next: true,
  entitiesSelected: []
};

const EntityFilter = ({ answer, column, onConfirm, onClose, user, dispatch, t }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [columnFilterOptions, setColumnFilterOptions] = useState(initialOptions);
  const [loading, setLoading] = useState(false);

  const formattedColumnFilterOptions = useMemo(() => {
    if (columnFilterOptions.entitiesSelected.length) {
      return columnFilterOptions.entitiesSelected.map(entity => ({ text: entity.name, value: entity.uri, ...entity }));
    }
    return [];
  }, [columnFilterOptions.entitiesSelected]);

  const q2qStateContext = useContext(Q2QStateContext);

  const getSelectedOptionsByColumn = column => {
    const filters =
      q2qStateContext.answerData?.structuredFilters || q2qStateContext.answerData?.commonFiltersStructured || [];

    let formattedEntities = [];

    filters.forEach(filter => {
      filter.passages.forEach(passage => {
        if (passage.type === passageTypes.ENTITY_UNION && passage.id === column.identifier) {
          passage.children.forEach(child => {
            if (child.type === passageTypes.ENTITY) {
              formattedEntities.push(child);
            }
          });
        }
      });
    });

    const newSelectedOptions = formattedEntities.map(entity => ({
      ...entity,
      value: getEntityFromEntityUri(entity.uri)
    }));

    setSelectedOptions(newSelectedOptions);
  };

  const getStringWithRelationFromPassages = (passages, t) => {
    let complement = '';
    passages.forEach((passage, index) => {
      if (index > 0) {
        if (index === passages.length - 1) {
          complement += ` ${t('or')} `;
        } else {
          complement += ', ';
        }
      }
      complement += passage.text;
    });
    return complement;
  };

  const getMessageForFilterUpdate = returedPassages => {
    let selectedColumnTitle = column.title;
    selectedColumnTitle = selectedColumnTitle.replace(' [' + answer.title + ']', '');
    if (returedPassages) {
      return (
        q2qStateContext.answerData.title +
        ` ${t('with')} ` +
        selectedColumnTitle +
        ' ' +
        getStringWithRelationFromPassages(returedPassages, t)
      );
    } else {
      return (
        t('modifications.remove-filter') +
        ': "' +
        q2qStateContext.answerData.title +
        ` ${t('with')} ` +
        selectedColumnTitle +
        '"'
      );
    }
  };

  const updateOptions = async (newOptions = initialOptions) => {
    if (!newOptions.next) return;

    setLoading(true);
    let result = await services.getEntitiesByClass({ classUri: column.classUri, ...newOptions });

    setLoading(false);
    handleError(result);

    setColumnFilterOptions({
      ...newOptions,
      next: !!result?.data?.next,
      entitiesSelected: result?.data?.results ? [...newOptions.entitiesSelected, ...result.data.results] : []
    });
  };

  const handleSearch = async search => {
    await updateOptions({ ...initialOptions, query: search });
  };

  const handleScrollToBottom = async () => {
    const newOptions = { ...columnFilterOptions, start: columnFilterOptions.start + columnFilterOptions.limit };
    await updateOptions(newOptions);
  };

  const handleConfirm = async returnedPassages => {
    const currentPassage = {
      id: column.identifier
    };

    const message = getMessageForFilterUpdate(returnedPassages);

    const payload = {
      username: user.username,
      message,
      answerData: answer,
      id: currentPassage.id,
      linkedEntities: returnedPassages,
      representedCandidate: column.representedCandidate,
      representedRelation: column.representedRelation,
      modificationType: answerModificationTypes.entity.value,
      t
    };

    await dispatch(modifyAnswer(payload));

    onConfirm();
  };

  const handleClose = () => {
    setColumnFilterOptions(initialOptions);
    onClose();
  };

  useEffect(() => {
    if (column) {
      getSelectedOptionsByColumn(column);
      updateOptions(initialOptions);
    }
  }, [column]);

  return (
    <MultiselectList
      allowEmptySelection
      showLimitTags
      options={formattedColumnFilterOptions}
      selectedOptions={selectedOptions}
      onConfirm={handleConfirm}
      onClose={handleClose}
      onScrollToBottom={handleScrollToBottom}
      asyncSearch={handleSearch}
      loading={loading}
      confirmButtonText={t('apply')}
      cancelButtonText={t('cancel')}
    />
  );
};

const mapStateToProps = state => ({ user: state.user });

export default withTranslation('veezoo')(connect(mapStateToProps)(EntityFilter));
