import { PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { messageTypes } from 'store/modules/chat-messages';
import { finishedTutorial } from 'store/modules/tutorial';
import Title from 'components/tutorial/Title';
import scrollElementIntoVisibleArea from 'utils/scroll-element-into-visible-area';
import { PeopleGroupIcon } from '../Icons';
import { tutorialNames } from 'config/constants';

const tutorialId = tutorialNames.CUSTOMER_SELECTION_TUTORIAL;

export class CustomerSelectionTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTutorial: false
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type } = tour;

    const element = document.querySelector(tour.step.target);
    scrollElementIntoVisibleArea(element);

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
    }
  }

  componentDidUpdate(prevProps) {
    // CustomerSelectionTutorial will run only once after message with type VEEZOO_CUSTOMER_SELECTION_MESSAGE
    if (
      !this.state.runTutorial &&
      this.props.hasCustomerSelectionMessage &&
      this.props.uncompletedTutorials.some(tut => tut.id === tutorialId)
    ) {
      this.setState({ runTutorial: true });
    }
  }

  render() {
    const { runTutorial } = this.state;
    const { t, generalOptions } = this.props;

    const steps = [
      // step 0
      {
        target: 'body',
        disableBeacon: true,
        placement: 'center',
        locale: { next: 'Start' },
        title: <Title text={t('tutorial.customer-selection.start-title')} icon={<PeopleGroupIcon />} />,
        content: t('tutorial.customer-selection.start-desc')
      },
      // step 1
      {
        target: '[data-display-customer-selection-tutorial] [data-customer-selection-tutorial="title"]',
        disableBeacon: true, // need to disable icon for every single step
        placement: 'top',
        title: t('tutorial.customer-selection.message-title'),
        content: t('tutorial.customer-selection.message-desc')
      },
      // step 2
      {
        target: '[data-customer-selection-tutorial="action"]',
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.customer-selection.action-title'),
        content: t('tutorial.customer-selection.action-desc')
      }
    ];

    // IMPORTANT! Correct scrolling to target element doesn't work with upgraded version 2.1.1
    return (
      <Joyride
        {...generalOptions}
        ref="joyride"
        steps={steps}
        run={runTutorial} // or some other boolean for when you want to start it
        callback={this.callback}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    hasCustomerSelectionMessage: state.chatMessages.some(
      m => m.type === messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE
    ),
    uncompletedTutorials: state.tutorial.uncompletedTutorials
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(CustomerSelectionTutorial));
