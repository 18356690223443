/**
 * Returns a promise that resolves when an element with the specified selector is present in the DOM.
 */
export const elementPresent = selector => {
  const check = callback => {
    const element = document.querySelector(selector);
    if (element) {
      callback(element);
    }
  };
  return new Promise(resolve => {
    // check element presence immediately
    check(element => resolve(element));
    // create observer to check element presence upon DOM mutations
    const observer = new MutationObserver((mutationRecords, observer) => {
      check(element => {
        resolve(element);
        observer.disconnect();
      });
    });
    // start observing
    observer.observe(document.documentElement, { childList: true, subtree: true });
  });
};

/**
 * Returns a promise that resolves when no element with the specified selector is present in the DOM.
 */
export const elementAbsent = selector => {
  const check = callback => {
    const element = document.querySelector(selector);
    if (!element) {
      callback();
    }
  };
  return new Promise(resolve => {
    // check element absence immediately
    check(resolve);
    // create observer to check element absence upon DOM mutations
    const observer = new MutationObserver((mutationRecords, observer) => {
      check(() => {
        resolve();
        observer.disconnect();
      });
    });
    // start observing
    observer.observe(document.documentElement, { childList: true, subtree: true });
  });
};

/**
 * Returns a ResizeObserver, which calls the provided `onResize` callback with the new dimensions of the observed ref.
 */
export const createResizeObserver = onResize => {
  // Check if ResizeObserver is not supported by the browser (IE, older versions of Safari, Chrome, Firefox, etc.)
  if (typeof ResizeObserver === 'undefined') {
    // Return a 'dummy' observer in this case
    return {
      observe: () => {},
      disconnect: () => {}
    };
  }
  return new ResizeObserver(entries => {
    entries.forEach(entry => {
      const rect = entry.target.getBoundingClientRect(); // includes padding
      onResize(rect);
    });
  });
};
