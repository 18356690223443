import { makeStyles } from '@material-ui/core/styles';

export const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 300
  },
  paper: {
    padding: 20
  }
}));
// We may do more with this in the future, but for now it's almost a copy of useTooltipStyles
// Could try to make it work via props but under too much time pressure right now, hence a new style.
export const useAutocompleteTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 300,
    marginLeft: -7
  },
  paper: {
    padding: 20
  }
}));

export const useStyles = makeStyles(() => ({
  root: {
    fontSize: 24,
    color: 'var(--light-blue)',
    verticalAlign: 'sub',
    marginLeft: '4px'
  }
}));
