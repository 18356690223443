import { withStyles, withTheme } from '@material-ui/core/styles';
import styles from './MarkdownHtml.styles';

const MarkdownHtml = ({ classes, html, ...props }) => {
  return (
    <div {...props}>
      <div className={classes.root} dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default withTheme(withStyles(styles)(MarkdownHtml));
