import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import clsx from 'clsx';

const Button = forwardRef(
  (
    {
      id,
      type,
      disabled,
      showOnlyIcon,
      onClick,
      onMouseDown,
      children,
      className,
      text,
      titleClassName,
      title,
      ...rest
    },
    ref
  ) => (
    <button
      id={id}
      type={type}
      className={`${className} ${showOnlyIcon ? 'show-only-icon' : ''}`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
      ref={ref}
      {...(!text && title ? { title } : {})}
      {...rest}
    >
      {children}
      {text ? <span className={clsx(titleClassName, disabled ? 'disabled' : '')}>{text}</span> : null}
    </button>
  )
);

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.any]),
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  className: PropTypes.string,
  showOnlyIcon: PropTypes.bool,
  titleClassName: PropTypes.string,
  text: PropTypes.any.isRequired,
  title: PropTypes.string
};

Button.defaultProps = {
  type: 'button',
  className: 'button-primary',
  titleClassName: 'text',
  disabled: false,
  showOnlyIcon: false,
  text: '',
  title: ''
};

export default Button;
