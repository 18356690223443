import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { boundMethod } from 'autobind-decorator';
import PropTypes from 'prop-types';

import { trackEvent } from 'utils/eventTracking';

import { askLocalQuestion } from 'store/modules/chat-messages';

import { studioRoutes } from 'components/app/routes';

import styles from './discovery.scss';
import DiscoveryDetail from './DiscoveryDetail';
import { List } from '@material-ui/core';
import { generateDiscoveryExamples } from 'store/modules/discovery';

import PageLoader from 'components/loaders/PageLoader';

import EditIcon from '@material-ui/icons/Edit';

import Button from 'components/v2/Button';

import withEmbedded from 'root/hocs/withEmbedded';

export class Discovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTopicIndex: -1
    };
  }

  componentDidMount() {
    if (
      !(this.props.discovery.topics && this.props.discovery.topics?.length > 0) &&
      this.props.shouldGenerateDiscoveryIfEmpty
    ) {
      this.props.dispatch(generateDiscoveryExamples(this.props.kgId));
    }
  }

  @boundMethod
  handleQuestionClick(question) {
    const otherParams = {
      isSuggested: true,
      disableFollowUp: true
    };

    this.props.dispatch(askLocalQuestion(this.props.username, question, otherParams, this.props.t));
  }

  @boundMethod
  setTopicIndex(index) {
    this.setState({ selectedTopicIndex: index });
  }

  redirectToStudio = () => {
    trackEvent('Jump to Studio Button Clicked', {
      kgId: this.props.kgMeta.id,
      filePath: this.props.discovery.filePath,
      vklQualifiedIdentifier: undefined
    });

    let link = `${studioRoutes.editor}?id=${this.props.kgMeta.id}&file=${this.props.discovery.filePath}`;
    window.open(link, '_blank');
  };
  createTile(title, index) {
    return (
      <div
        key={index}
        className={styles.linkStyle}
        onClick={() => this.setTopicIndex(index)}
        data-test="discoveryTopic"
      >
        <div className={styles.tile}>{title}</div>
      </div>
    );
  }

  overviewPage() {
    const { discovery } = this.props;
    const { topics } = discovery;
    const categories = topics.map(e => e.category).filter((cat, i, a) => a.indexOf(cat) === i); // unique categories

    const categoryContainers = categories.map((cat, i) => {
      const tiles = topics.filter(e => e.category === cat).map(e => this.createTile(e.title, topics.indexOf(e)));

      return (
        <Fragment key={'cat_' + i}>
          <div className={styles.tileCategoryContainer}>
            <h2 className={styles.tileCategoryTitle}>{cat}</h2>
            <div className={styles.tileContainer}>{tiles}</div>
          </div>
        </Fragment>
      );
    });

    return (
      <div className={styles.categoriesContainer}>
        <List disablePadding={true}>{categoryContainers}</List>
      </div>
    );
  }

  @boundMethod
  handleShowOverviewCallback() {
    this.setState({ selectedTopicIndex: -1 });
  }

  render() {
    const { discovery } = this.props;
    const { selectedTopicIndex } = this.state;

    let content;
    if (discovery.isGeneratingExamples) {
      content = (
        <div className={styles.loadingContainer}>
          <PageLoader message={this.props.t('generating-discovery')} />
        </div>
      );
    } else if (selectedTopicIndex === -1 || !(discovery.topics && discovery.topics[selectedTopicIndex])) {
      content = (
        <>
          {this.props.discovery.filePath && this.props.isSuperUserOrHigher && !this.props.kgMeta.isDemo && (
            // Position on the top right, and on top of topics in the overview page
            <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
              <Button
                label={this.props.t('edit-in-studio')}
                onClick={this.redirectToStudio}
                // if we are not allowed to see a knowledge graph, we render it but not visible
                // this is to prevent the buttons from jumping around
                style={{ visibility: this.props.hasKnowledgeGraphSupport ? 'visible' : 'hidden' }}
                iconPosition="right"
                icon={<EditIcon />}
              />
            </div>
          )}
          {this.overviewPage()}
        </>
      );
    } else {
      content = (
        <DiscoveryDetail
          discovery={discovery}
          selectedTopicIndex={selectedTopicIndex}
          showOverviewCallback={this.handleShowOverviewCallback}
          isMobile={this.props.isMobile}
        />
      );
    }

    return <div className={styles.discovery}>{content}</div>;
  }
}

Discovery.propTypes = {
  discovery: PropTypes.object,
  username: PropTypes.string,
  kgId: PropTypes.string,
  shouldGenerateDiscoveryIfEmpty: PropTypes.bool,
  isMobile: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  hasKnowledgeGraphSupport: PropTypes.bool,
  history: PropTypes.object,
  kgMeta: PropTypes.object,
  isSuperUserOrHigher: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = state => {
  return {
    discovery: state.discovery,
    username: state.user.username,
    kgId: state.knowledgeGraphMeta.meta.id,
    hasKnowledgeGraphSupport: state.knowledgeGraphMeta.meta.hasKnowledgeGraphSupport,
    kgMeta: state.knowledgeGraphMeta.meta,
    shouldGenerateDiscoveryIfEmpty: state.knowledgeGraphMeta.meta.hasWritePermission && state.user.isLlmParserEnabled,
    isSuperUserOrHigher: state.user.isSuperUser || state.user.isAdmin
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(withEmbedded(Discovery))));
