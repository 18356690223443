import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';
import Progress from 'studio/components/Progress';

import useMediaQuery from 'utils/mediaQueries';

import styles from './index.module.scss';
import TextField from 'studio/components/TextField';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Alert from 'studio/components/Alert';
import SampleRowsTable from 'components/TableImport/SampleRowsTable';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import services from 'services';
import _ from 'lodash';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useTreeStyles from 'components/Tree/Tree.styles';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';
import { pluralize as plrl } from 'studio/helpers';

import {
  useListItemStyles,
  useListItemTextStyles,
  useTypographyItemStyles,
  useCheckboxStyles,
  useHelpIconStyles,
  useListItemIconStyles
} from './TableImport.styles';

const formatSchemas = schemas => {
  return schemas.map(item => ({
    name: item.data.name,
    tables: undefined,
    isLoadingTables: false,
    numberOfImportedTables: item.data.numberOfImportedTables,
    expanded: false
  }));
};

const formatTables = tables => {
  return tables.map(item => ({
    name: item.data.name,
    alreadyImported: item.data.alreadyImported,
    numberOfImportedColumns: item.data.numberOfImportedColumns,
    columns: undefined
  }));
};

const formatColumns = (columns, checked) => {
  return columns.map(item => ({
    name: item.data.name,
    tableName: item.data.tableName,
    alreadyImported: item.data.alreadyImported || false,
    checked: checked || false,
    sampleValues: item.data.sampleValues
  }));
};

const getTotalNumberOfColumnsSelectedForImport = databaseObjects => {
  let total = 0;
  const tablesAndSchemas = databaseObjects
    .map(tableOrSchema => [...(tableOrSchema.tables || []), tableOrSchema])
    .flat();
  tablesAndSchemas.forEach(tableOrSchema => {
    total += tableOrSchema.columns?.filter(item => !!item.checked && !item.alreadyImported)?.length || 0;
  });
  return total;
};

const moreThanMaxColumnsSelected = (databaseObjects, oneStepImportLimit) =>
  getTotalNumberOfColumnsSelectedForImport(databaseObjects) > oneStepImportLimit;

const getColumnsText = table => {
  const imported = table.numberOfImportedColumns;
  const selected = table.columns?.filter(item => !!item.checked && !item.alreadyImported)?.length || 0;

  if (imported > 0) {
    const importedColumnsText = `${imported} ${plrl(imported, 'column')} imported`;
    if (selected < 1) return importedColumnsText;
    return importedColumnsText + `, ${selected} selected`;
  } else {
    if (selected < 1) return null;
    const total = table.columns.length;
    if (selected >= total) return 'All columns selected';
    return `${selected} ${plrl(selected, 'column')} selected`;
  }
};

const ColumnsList = ({ columns, checkColumn, checkboxColor, disabled }) => {
  const isMobile = useMediaQuery();
  const listItemClasses = useListItemStyles();
  const listItemTextClasses = useListItemTextStyles({ isMobile });
  const checkboxClasses = useCheckboxStyles({ isMobile, checkboxColor });
  const listItemIconClasses = useListItemIconStyles({ isMobile });

  return columns.map((column, index) => (
    <ListItem
      classes={listItemClasses}
      disabled={disabled || column.alreadyImported}
      dense
      button
      key={`list_item_${index}`}
      onClick={!disabled ? () => checkColumn(column) : () => {}}
    >
      <ListItemIcon classes={listItemIconClasses}>
        <Checkbox
          classes={checkboxClasses}
          edge="start"
          tabIndex={-1}
          disableRipple
          size="small"
          checked={column.alreadyImported || column.checked}
          onChange={() => checkColumn(column)}
          onClick={event => event.stopPropagation()}
          disabled={disabled || column.alreadyImported}
        />
      </ListItemIcon>
      <ListItemText primary={column.name} classes={listItemTextClasses} />
    </ListItem>
  ));
};

const CustomTreeItem = ({ id, disabled, selected, ...props }) => {
  // Use styles of our Tree component, but not the whole component, as it has some unnecessary features and dependencies
  //  (in particular the ContextMenu has to be provided)
  const treeItemClasses = useTreeStyles();
  return (
    <TreeItem
      nodeId={id}
      disabled={disabled}
      className={classNames({
        selected: selected,
        disabled: disabled
      })}
      classes={{
        // Use the same classes as our Tree component
        root: treeItemClasses.item,
        content: treeItemClasses.content,
        expanded: treeItemClasses.expanded,
        selected: treeItemClasses.selected,
        group: treeItemClasses.group,
        label: treeItemClasses.label
      }}
      {...props}
    />
  );
};

/**
 * Component that allows to select tables and columns from a database to import into a knowledge graph.
 *
 * Important: make sure the arguments are not changed unnecessarily to avoid a re-render of the outer component,
 * as otherwise we might enter an infinite loop due to the setImportFunction callback.
 * In particular, handleFetchingError and handleRootFetchingError should not be changed (use useCallback).
 *
 * @param useSchemas whether the DB supports / uses schemas
 * @param disabled whether the component should be disabled (e.g. because the import is currently running)
 * @param knowledgeGraph the knowledge graph to import the tables into
 * @param checkboxColor overwrite for the color of the checkbox when checked
 * @param setImportFunction callback that is called with the import function when the import is possible or undefined otherwise.
 *                          This allows the outer component to do the import and use the state inside.
 *                          It calls the import endpoint and handles the state inside after importing.
 * @param handleFetchingError callback taking the fetch result and a message that is called when an error occurs when querying the backend
 * @param handleRootFetchingError callback that is called when an error occurs when querying the backend for the root of the left panel
 *                                Root here means the schemas or tables at the root of the tree view on the left (and not nested tables).
 *                                This callback is always called after handleFetchingError, and allows to handle the error differently,
 *                                e.g. when the DB connection has not been set up correctly.
 * @param dataTest data-test attribute for the root element
 */
const TableImport = ({
  usesSchemas,
  disabled,
  knowledgeGraph,
  checkboxColor,
  setImportFunction,
  handleFetchingError,
  handleRootFetchingError,
  dataTest
}) => {
  const [oneStepImportLimit, setOneStepImportLimit] = useState(null);

  // List of schemas containing tables or directly a list of tables, with the tables also containing the columns
  const [databaseObjects, setDatabaseObjects] = useState([]);

  // Names of the schemas that have been expanded in the tree view on the left
  const [expandedSchemas, setExpandedSchemas] = useState([]);
  // Table that has been selected to import columns from
  // Contains an object of the form { schemaName: ..., tableName: ... } if a table is selected, schemaName is optional
  const [selectedTable, setSelectedTable] = useState(null);

  // Indicator whether root of left panel is being loaded (contains schemas and tables)
  const [loadingLeftPanel, setLoadingLeftPanel] = useState(true);
  // Indicator whether columns are being loaded for a table (in the right panel)
  const [loadingColumns, setLoadingColumns] = useState(false);

  // Search queries for tables/schemas and columns
  const [tableAndSchemaSearchQuery, setTableAndSchemaSearchQuery] = useState('');
  const [columnSearchQuery, setColumnSearchQuery] = useState('');

  // Excel KGs are handled specially, we only want to show the tables of a single schema
  const isExcel = knowledgeGraph.connectorId === 'excel';
  // Whether schemas should be shown in the left panel (Excel KGs have a fixed schema, so we don't show schemas for them)
  const showSchemasInLeftPanel = !isExcel && usesSchemas;
  // The schema name for Excel KGs is just the KG ID
  // Note that the user could change it in VKL after creation, but we only support for the original schema here
  const fixedSchemaName = isExcel ? knowledgeGraph.id : undefined;

  const isMobile = useMediaQuery();
  const itemTypographyClasses = useTypographyItemStyles();
  const checkboxClasses = useCheckboxStyles({ isMobile, checkboxColor });

  const LoaderContainer = () => (
    <div className={styles.loaderContainer}>
      <Progress />
    </div>
  );

  // Modifies a schema in the state
  const modifySchema = useCallback((schemaName, modifier) => {
    setDatabaseObjects(previous => previous.map(schema => (schema.name === schemaName ? modifier(schema) : schema)));
  }, []);

  // Modifies a table in the state
  const modifyTable = useCallback((schemaName, tableName, modifier) => {
    const mapTables = tables => tables?.map(table => (table.name === tableName ? modifier(table) : table));
    if (schemaName !== undefined) {
      modifySchema(schemaName, schema => ({ ...schema, tables: mapTables(schema.tables) }));
    } else {
      setDatabaseObjects(previous => mapTables(previous));
    }
  }, []);

  // Fetches the database tables (at the root or inside a schema)
  const fetchDatabaseTables = useCallback(
    async schemaName => {
      if (schemaName === undefined) {
        // Show loader only if loading tables at root of left panel
        setLoadingLeftPanel(true);
      } else {
        // Mark schema as being loaded, this is also indicated in the frontend
        modifySchema(schemaName, schema => ({ ...schema, isLoadingTables: true }));
      }

      const result = await services.getDatabaseTables(knowledgeGraph.id, fixedSchemaName || schemaName);

      if (schemaName === undefined) {
        // Hide loader again
        setLoadingLeftPanel(false);
      }

      if (result.success) {
        // Put tables at the root or inside a schema
        if (schemaName === undefined) {
          setDatabaseObjects(formatTables(result.data.data));
        } else {
          modifySchema(schemaName, schema => ({
            ...schema,
            isLoadingTables: false,
            tables: formatTables(result.data.data)
          }));
        }
      } else {
        const message = result?.response?.data?.data?.message || 'An error occurred while loading tables.';
        handleFetchingError(result, message);
        if (schemaName === undefined) {
          handleRootFetchingError?.();
        } else {
          modifySchema(schemaName, schema => ({ ...schema, isLoadingTables: false, tables: [] }));
        }
      }
    },
    [knowledgeGraph, handleFetchingError, handleRootFetchingError]
  );

  // Fetches the database schemas
  const fetchDatabaseSchemas = useCallback(async () => {
    setLoadingLeftPanel(true);
    const result = await services.getDatabaseSchemas(knowledgeGraph.id);
    setLoadingLeftPanel(false);
    if (result.success) {
      const schemas = result.data.data;
      setDatabaseObjects(formatSchemas(schemas));

      // Expand the schema that has already been imported if there is only one
      const alreadyImportedSchemas = schemas.filter(schema => schema.data.numberOfImportedTables > 0);
      let schemasToExpand = [];
      if (alreadyImportedSchemas.length === 1) {
        schemasToExpand = [alreadyImportedSchemas[0].data.name];
      }

      // Expand schema if there is only one with tables already imported
      setExpandedSchemas(previousExpandedSchemas => {
        const filteredExpandedSchemas = previousExpandedSchemas.filter(item =>
          schemas.some(schema => schema.data.name === item)
        );
        if (filteredExpandedSchemas.length === 0 && schemasToExpand.length === 1) {
          fetchDatabaseTables(schemasToExpand[0]);
          return schemasToExpand;
        } else {
          return filteredExpandedSchemas;
        }
      });
    } else {
      const message = result?.response?.data?.data?.message || 'An error occurred while loading schemas.';
      handleFetchingError(result, message);
      handleRootFetchingError?.();
    }
  }, [knowledgeGraph, handleFetchingError, handleRootFetchingError, fetchDatabaseTables]);

  // Fetches the database schemas or tables
  const fetchLeftPanelRoot = useCallback(async () => {
    if (showSchemasInLeftPanel === true) {
      fetchDatabaseSchemas();
    } else if (showSchemasInLeftPanel === false) {
      fetchDatabaseTables(undefined);
    }
  }, [fetchDatabaseSchemas, fetchDatabaseTables, showSchemasInLeftPanel]);

  // Fetches the import limit (the number of columns that can be imported in one step)
  const fetchOneStepImportLimit = useCallback(async () => {
    const result = await services.getImportConfiguration();
    if (result.success) {
      setOneStepImportLimit(result.data?.data?.maxColumnsPerImport);
    } else {
      const message = result?.response?.data?.data?.message || 'An error occurred while loading import limit.';
      handleFetchingError(result, message);
    }
  }, [knowledgeGraph, handleFetchingError]);

  // Fetches the database columns for a table
  const fetchDatabaseColumns = useCallback(
    async (schemaName, tableName, checked) => {
      setLoadingColumns(true);
      const result = await services.getDatabaseColumns(knowledgeGraph.id, fixedSchemaName || schemaName, tableName);
      setLoadingColumns(false);
      if (result.success) {
        modifyTable(schemaName, tableName, table => ({ ...table, columns: formatColumns(result.data.data, checked) }));
      } else {
        const message = result?.response?.data?.data?.message || 'An error occurred while loading columns.';
        handleFetchingError(result, message);
      }
    },
    [knowledgeGraph, handleFetchingError]
  );

  // Paths of the selected columns (expected by the API)
  const selectedColumnPaths = useMemo(() => {
    // Pairs of table paths and columns inside the table
    let tablePathsAndColumns;
    if (showSchemasInLeftPanel) {
      tablePathsAndColumns = databaseObjects
        .map(schema => schema.tables?.map(table => ({ path: [schema.name, table.name], columns: table.columns })) || [])
        .flat();
    } else {
      tablePathsAndColumns = databaseObjects.map(table => ({ path: [table.name], columns: table.columns }));
    }

    return tablePathsAndColumns
      .map(
        ({ path, columns }) =>
          columns
            ?.filter(item => !!item.checked && !item.alreadyImported)
            ?.map(item => _.compact([fixedSchemaName, ...path, item.name])) || []
      )
      .flat();
  }, [databaseObjects]);

  // Import is disabled if we are currently loading something, or no or too many columns are selected
  const importDisabled =
    loadingLeftPanel ||
    loadingColumns ||
    selectedColumnPaths.length === 0 ||
    moreThanMaxColumnsSelected(databaseObjects, oneStepImportLimit);

  // Calls the backend to import the selected tables/columns
  const importFunction = useCallback(async () => {
    const response = await services.importKnowledgeGraphTables(knowledgeGraph.id, selectedColumnPaths);
    if (response.success) {
      if (response.data.messages.some(item => item.isSuccess)) {
        // Reset state
        setSelectedTable(null);
        setExpandedSchemas([]);
        fetchLeftPanelRoot();
      }
    }
    return response;
  }, [selectedColumnPaths, knowledgeGraph, fetchLeftPanelRoot]);

  // Give outer component access to the import function and also notify it when the import is possible
  useEffect(() => {
    if (importDisabled) {
      setImportFunction(undefined);
    } else {
      // Need to wrap in a function as setting state will call passed functions with the previous state
      setImportFunction(() => importFunction);
    }
  }, [importDisabled, setImportFunction, importFunction]);

  // if the knowledge graph id changes, reset the selected table and expanded schemas
  useEffect(() => {
    setSelectedTable(null);
    setExpandedSchemas([]);
  }, [knowledgeGraph?.id]);

  // Fetch the import limit when the component is mounted
  useEffect(() => {
    fetchOneStepImportLimit();
  }, []);

  // Fetch the root of the left panel when the component is mounted or certain dependencies change (in particular the KG)
  useEffect(() => {
    fetchLeftPanelRoot();
  }, [fetchLeftPanelRoot]);

  // Filtered columns of the selected table
  const transformedColumns = useMemo(() => {
    if (!selectedTable) {
      return [];
    } else {
      let tables = showSchemasInLeftPanel
        ? databaseObjects.find(schema => schema.name === selectedTable.schemaName)?.tables || []
        : databaseObjects;
      const table = tables.find(table => table.name === selectedTable.tableName);

      return (
        table?.columns?.filter(column => column.name.toLowerCase().includes(columnSearchQuery.toLowerCase())) || []
      );
    }
  }, [databaseObjects, selectedTable, columnSearchQuery]);

  // Checks or unchecks a column in the right panel
  const checkColumn = useCallback(
    column => {
      modifyTable(selectedTable.schemaName, selectedTable.tableName, table => {
        return {
          ...table,
          columns: table.columns.map(item => {
            if (item.name === column.name) {
              return { ...item, checked: !item.checked && !item.alreadyImported };
            } else {
              return item;
            }
          })
        };
      });
    },
    [selectedTable]
  );

  // Selects the table given by schemaName and tableName and fetches its columns
  // Third argument checked allows to set the checked state of all columns of a table
  const selectTable = useCallback(
    (schemaName, table, checked = null) => {
      setSelectedTable({ schemaName, tableName: table.name });

      if (!table.columns) {
        return fetchDatabaseColumns(schemaName, table.name, checked || false);
      }

      if (checked !== null) {
        modifyTable(schemaName, table.name, table => ({
          ...table,
          columns: table.columns.map(item => ({ ...item, checked }))
        }));
      }
    },
    [fetchDatabaseColumns]
  );

  // Expands/collapse a schema in the left panel and fetches its tables if necessary
  const toggleSchema = useCallback(
    schemaName => {
      if (expandedSchemas.includes(schemaName)) {
        setExpandedSchemas(expandedSchemas.filter(item => item !== schemaName));
      } else {
        if (databaseObjects.find(schema => schema.name === schemaName)?.tables === undefined) {
          fetchDatabaseTables(schemaName);
        }
        setExpandedSchemas([...expandedSchemas, schemaName]);
      }
    },
    [expandedSchemas, fetchDatabaseTables]
  );

  // The text field for the schema/table search query
  const tableAndSchemaSearchBox = (
    <div className={styles.searchBoxContainer}>
      <TextField
        startIcon={<SearchRoundedIcon />}
        name="search tables"
        placeholder={showSchemasInLeftPanel ? 'Search schemas and tables' : 'Search tables'}
        label=""
        value={tableAndSchemaSearchQuery}
        margin="none"
        size="small"
        onChange={event => setTableAndSchemaSearchQuery(event.target.value)}
      />
    </div>
  );
  // The text field for the column search query
  const columnSearchBox = (
    <div className={styles.searchBoxContainer}>
      <TextField
        startIcon={<SearchRoundedIcon />}
        name="search columns"
        placeholder="Search columns"
        value={columnSearchQuery}
        margin="none"
        size="small"
        onChange={event => setColumnSearchQuery(event.target.value)}
      />
    </div>
  );

  function getSchemaTreeItemId(schemaName) {
    return `schema-${schemaName}`;
  }

  function getTableTreeItemId(schemaName, tableName) {
    return `table-${schemaName}-${tableName}`;
  }

  // Returns the tree item for a table, or undefined if the table does not match the search query
  const getTableTreeItem = (schemaName, table) => {
    if (table.name.toLowerCase().includes(tableAndSchemaSearchQuery.toLowerCase())) {
      const checkbox = (
        <Checkbox
          classes={checkboxClasses}
          edge="start"
          tabIndex={-1}
          disableRipple
          size="small"
          onClick={event => event.stopPropagation()}
          onChange={() => selectTable(schemaName, table, !getColumnsText(table))}
          checked={table.alreadyImported || !!getColumnsText(table)}
          disabled={disabled || table.alreadyImported}
          data-test="tableAllColumnsCheckbox"
          data-test-table-name={table.name}
        />
      );
      const content = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '10px' }}>
          {checkbox}
          <Typography variant="body1" classes={itemTypographyClasses}>
            {table.name}
          </Typography>
          <Typography variant="body2" classes={itemTypographyClasses}>
            {getColumnsText(table)}
          </Typography>
        </div>
      );
      return (
        <CustomTreeItem
          id={getTableTreeItemId(schemaName, table.name)}
          key={table.name}
          label={content}
          onClick={!disabled ? () => selectTable(schemaName, table) : () => {}}
          disabled={disabled}
          selected={selectedTable?.schemaName === schemaName && selectedTable?.tableName === table.name}
        />
      );
    } else {
      return undefined;
    }
  };

  // Returns the tree item for a schema, or undefined if the schema does not match the search query
  const getSchemaTreeItem = schema => {
    const queryMatchesSchemaOrTableInside =
      schema.name.toLowerCase().includes(tableAndSchemaSearchQuery.toLowerCase()) ||
      schema.tables?.some(table => table.name.toLowerCase().includes(tableAndSchemaSearchQuery.toLowerCase()));

    if (queryMatchesSchemaOrTableInside) {
      // Icon to use when the schema tables are being loaded
      const progress = schema.isLoadingTables && <CircularProgress size={10} color="inherit" />;

      // Note: different from stub that indicates tables haven't been loaded yet
      const noTablesStub = (
        <CustomTreeItem
          id={`stub-${schema.name}`}
          disabled={true}
          label={
            <Typography variant="body1" classes={itemTypographyClasses}>
              {schema.tables?.length === 0 ? 'No tables in this schema' : 'No table matches the search'}
            </Typography>
          }
        />
      );

      const tableItems = _.compact(schema.tables?.map(table => getTableTreeItem(schema.name, table)));
      return (
        <CustomTreeItem
          id={getSchemaTreeItemId(schema.name)}
          key={schema.name}
          label={
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '10px' }}>
              <Typography variant="body1" classes={itemTypographyClasses}>
                {schema.name}
              </Typography>
              <Typography variant="body2" classes={itemTypographyClasses}>
                {schema.numberOfImportedTables === 1
                  ? '1 table imported'
                  : schema.numberOfImportedTables > 0 && `${schema.numberOfImportedTables} tables imported`}
              </Typography>
            </div>
          }
          onClick={() => toggleSchema(schema.name)}
          onIconClick={() => toggleSchema(schema.name)}
          expandIcon={progress}
          collapseIcon={progress}
          disabled={disabled}
          data-test-schema-name={schema.name}
        >
          {schema.tables ? (tableItems.length === 0 ? noTablesStub : tableItems) : [<div key="stub" />] // This stub is necessary to indicate to the library that the schema has children
          }
        </CustomTreeItem>
      );
    } else {
      return undefined;
    }
  };

  let treeItems;
  if (showSchemasInLeftPanel) {
    // Put schemas with already imported tables first
    const [schemasWithImportedTables, schemasWithoutImportedTables] = _.partition(
      databaseObjects,
      schema => schema.numberOfImportedTables > 0
    );
    treeItems = _.compact(schemasWithImportedTables.map(schema => getSchemaTreeItem(schema)))
      .concat(
        schemasWithImportedTables.length > 0 && schemasWithoutImportedTables.length > 0
          ? [<Divider key="divider" />]
          : []
      )
      .concat(_.compact(schemasWithoutImportedTables.map(schema => getSchemaTreeItem(schema))));
  } else {
    treeItems = _.compact(databaseObjects.map(table => getTableTreeItem(undefined, table)));
  }

  const tableTree = (
    <TreeView
      expanded={expandedSchemas.map(getSchemaTreeItemId)}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {treeItems}
    </TreeView>
  );

  const moreThanMaxColumnsSelectedAlertMessage = (
    <Alert severity="warning">
      During a single import session you can select at most {oneStepImportLimit} columns. Please deselect some columns.
    </Alert>
  );

  const helpClasses = useHelpIconStyles(isMobile);
  const helpTooltip = (
    <InfoTooltip
      text="This is not a limit for your Knowledge Graph. You can always import more columns later."
      helpOverrideClasses={helpClasses}
    />
  );

  const totalNumberOfColumnsSelectedForImport = useMemo(
    () => getTotalNumberOfColumnsSelectedForImport(databaseObjects),
    [databaseObjects]
  );

  return (
    <div className={styles.importContainer} data-test={dataTest}>
      {moreThanMaxColumnsSelected(databaseObjects, oneStepImportLimit) && moreThanMaxColumnsSelectedAlertMessage}
      {oneStepImportLimit && (
        <div>
          <b>Selected Columns:</b> {totalNumberOfColumnsSelectedForImport} / {oneStepImportLimit} {helpTooltip}
        </div>
      )}
      <div className={styles.searchContainer}>
        {tableAndSchemaSearchBox}
        {columnSearchBox}
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.selector} data-test="tableSelector">
          {loadingLeftPanel ? <LoaderContainer /> : tableTree}
        </div>
        <div className={styles.selector}>
          {loadingColumns ? (
            <LoaderContainer />
          ) : (
            transformedColumns.length > 0 && (
              <ColumnsList
                checkboxColor={checkboxColor}
                columns={transformedColumns}
                checkColumn={checkColumn}
                disabled={disabled}
              />
            )
          )}
        </div>
      </div>

      {selectedTable && (
        <>
          <div>
            <b>Sample Rows</b>
          </div>
          <SampleRowsTable
            columns={transformedColumns}
            checkColumn={checkColumn}
            checkboxClasses={checkboxClasses}
            loadingColumns={loadingColumns}
          />
        </>
      )}
    </div>
  );
};

export default TableImport;
