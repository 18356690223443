import { makeStyles } from '@material-ui/core/styles';

const fontFamily = 'var(--font-family)';
const highlightedSignup = 'var(--light-blue)';

export const useSignupStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  input: {
    fontFamily,
    fontSize: ({ isMobile }) => (isMobile ? '16px' : '20px'),
    color: ({ highlighted }) => (highlighted ? highlightedSignup : 'inherit'),
    padding: '6px 0',
    lineHeight: ({ multiline }) => (multiline ? '22px' : 'inherit')
  },
  labelRoot: {
    fontFamily,
    fontSize: ({ isMobile }) => (isMobile ? '16px' : '20px'),
    whiteSpace: 'nowrap',
    '&&+.MuiInput-formControl': {
      marginTop: '16px'
    }
  },
  inputUnderline: {
    '&&.MuiInput-underline::before': {
      borderBottom: ({ disabled }) => (disabled ? 'solid thin var(--disabled-color)' : 'solid thin black')
    }
  },
  formHelperTextRoot: {
    fontSize: '12px',
    lineHeight: 'inherit'
  },
  formHelperTextContained: {
    display: 'flex',
    alignItems: 'center'
  },
  disabled: ({ disabled, highlighted }) => ({
    color: highlighted ? highlightedSignup : 'rgba(0, 0, 0, 0.18)',
    ...(disabled ? { disabled } : {})
  })
}));

export const useVeezooStyles = makeStyles(theme => ({
  input: ({ multiline, inline }) => ({
    fontFamily,
    fontSize: inline ? '12px' : '14px',
    padding: inline ? '5px 8px' : '8px 12px',
    color: 'inherit',
    lineHeight: '20px',
    ...(multiline ? {} : { height: inline ? '21px' : '32px', boxSizing: 'border-box' })
  }),
  labelRoot: {
    fontFamily,
    fontSize: '12px',
    fontWeight: 400
  },
  inputRoot: ({ inline }) => ({
    ...(inline ? {} : { marginTop: 4 }),
    ...(theme.isDark ? {} : { backgroundColor: 'white' })
  }),
  notchedOutline: ({ customClasses }) => ({
    top: 0,
    border: 'solid thin var(--light-gray)',
    borderRadius: 4,
    '& > legend': {
      display: 'none'
    },
    ...(customClasses?.notchedOutline ? customClasses.notchedOutline : {})
  }),
  formHelperTextRoot: {
    fontSize: '12px'
  },
  shrink: {
    transform: 'none !important',
    position: 'static'
  },
  disabled: ({ disabledWhenFilled }) => ({
    backgroundColor: 'transparent',
    ...(disabledWhenFilled ? { color: '#2e8dd5 !important' } : '')
  })
}));

export const useDefaultStyles = makeStyles(theme => ({
  input: {
    color: ({ highlighted }) => (highlighted ? theme.palette.primary.main : 'inherit')
  },
  inputRoot: {
    ...(theme.isDark ? {} : { backgroundColor: 'white' })
  },
  formHelperTextContained: ({ tooltip }) => ({
    ...(tooltip ? { marginLeft: 0 } : {}),
    display: 'flex',
    alignItems: 'center'
  }),
  labelShrink: {
    transform: 'translate(14px, -5px) scale(0.75) !important',
    display: 'flex',
    alignItems: 'center'
  },
  disabled: ({ highlighted }) => ({
    backgroundColor: 'transparent',
    ...(highlighted ? { color: theme.palette.primary.main } : {})
  })
}));
