import { PureComponent, Fragment } from 'react';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { boundMethod } from 'autobind-decorator';

import { completeOverviewTutorial, finishedTutorial } from 'store/modules/tutorial';

import Title from 'components/tutorial/Title';

import { withTranslation } from 'react-i18next';
import { PlayIcon } from 'components/Icons';
import { tutorialNames } from 'config/constants';
import { veezooRoutes } from 'components/app/routes';
import { withRouter } from 'react-router-dom';

const propTypes = {
  generalOptions: PropTypes.object,
  runOverviewTutorialByButton: PropTypes.bool,
  uncompletedTutorials: PropTypes.array,
  location: PropTypes.object.isRequired,
  isOnPremise: PropTypes.bool
};
const defaultProps = {
  generalOptions: {}
};

const tutorialId = tutorialNames.OVERVIEW_TUTORIAL;
const firstAnswerTutorialId = tutorialNames.FIRST_ANSWER_TUTORIAL;

export class OverviewTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tourCounter: 0,
      stepIndex: 0 // a controlled tour
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type, index } = tour;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // update state to advance the tour
      this.setState({
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
      });
    }

    if (type === EVENTS.TOUR_END || type === EVENTS.TOOLTIP_CLOSE) {
      // update user preferences with completed tour flag
      this.props.dispatch(completeOverviewTutorial());
      this.setState({
        tourCounter: this.state.tourCounter + 1,
        stepIndex: 0
      });
    }

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
    }
  }

  render() {
    const { tourCounter, stepIndex } = this.state;
    const { t, generalOptions, runOverviewTutorialByButton, uncompletedTutorials, isOnPremise, location } = this.props;
    const uncompleted = uncompletedTutorials.some(tut => tut.id === tutorialId);
    // This is mainly for when we added this tutorial, to not show it for users that already passed the first answer tutorial.
    const uncompletedFirstAnswer = uncompletedTutorials.some(tut => tut.id === firstAnswerTutorialId);
    // only run the overview for on-premise clients
    const shouldRunFirstTime = uncompleted && uncompletedFirstAnswer && isOnPremise;
    const isInChat = location.pathname === veezooRoutes.chat;
    const run = isInChat && (runOverviewTutorialByButton || shouldRunFirstTime);

    const steps = [
      // Step 1: Overview
      {
        target: 'body',
        disableBeacon: true,
        placement: 'center',
        title: <Title text={t('tutorial.overview.1-title')} icon={<PlayIcon />} />,
        content: t('tutorial.overview.1')
      },
      // Step 2: Chat
      {
        target: '[data-overview-tutorial="step-2"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.2-title'),
        content: t('tutorial.overview.2')
      },
      // Step 3: Customer Selections (optional)
      {
        target: '[data-overview-tutorial="customer-selections"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.customer-selections-title'),
        content: t('tutorial.overview.customer-selections-desc')
      },
      // Step 4: Customer of the Day (optional)
      {
        target: '[data-overview-tutorial="customers-of-the-day"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.cod-title'),
        content: t('tutorial.overview.cod-desc')
      },
      // Step 5: Boards
      {
        target: '[data-overview-tutorial="boards"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.boards-title'),
        content: t('tutorial.overview.boards-desc')
      },
      // Step 6: Add board
      {
        target: '[data-overview-tutorial="add-new-board"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.add-new-board-title'),
        content: t('tutorial.overview.add-new-board-desc')
      },
      // Step 7: Discovery
      {
        target: '[data-overview-tutorial="step-4"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.4-title'),
        content: t('tutorial.overview.4')
      },
      // Step 7b: Knowledge Graph
      {
        target: '[data-overview-tutorial="knowledge-graph"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.knowledge-graph-title'),
        content: t('tutorial.overview.knowledge-graph')
      },
      // Step 8: Studio (optional)
      {
        target: '[data-overview-tutorial="studio"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.studio-title'),
        content: t('tutorial.overview.studio-desc')
      },
      // Step 9: Admin (optional)
      {
        target: '[data-overview-tutorial="admin"]',
        disableBeacon: true,
        placement: 'right',
        title: t('tutorial.overview.admin-title'),
        content: t('tutorial.overview.admin-desc')
      },
      // Step 10: Question Suggestions
      {
        target: '[data-overview-tutorial="step-5"]',
        disableBeacon: true,
        title: t('tutorial.overview.5-title'),
        content: t('tutorial.overview.5')
      },
      // Step 11: Chatbox
      {
        target: '[data-overview-tutorial="step-6"]',
        disableBeacon: true,
        title: t('tutorial.overview.6-title'),
        content: t('tutorial.overview.6')
      }
    ];

    return (
      <Fragment>
        <Joyride
          {...generalOptions}
          ref="joyride"
          key={tourCounter}
          steps={steps}
          run={run}
          callback={this.callback}
          stepIndex={stepIndex}
        />
      </Fragment>
    );
  }
}

OverviewTutorial.propTypes = propTypes;
OverviewTutorial.defaultProps = defaultProps;

export function mapStateToProps(state) {
  return {
    runOverviewTutorialByButton: state.tutorial.runOverviewTutorial,
    uncompletedTutorials: state.tutorial.uncompletedTutorials,
    isOnPremise: state.network.isOnPremise
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(OverviewTutorial)));
