import { cloneElement, memo, useMemo, forwardRef } from 'react';

import UIButton from '@material-ui/core/Button';

import ArrowDownIcon from 'svg/v2/arrow_down.svg';
import LoadingIcon from 'svg/v2/loading.svg';

import useStyles from './styles';

export const iconPositions = { left: 'left', right: 'right' };

const Button = forwardRef(
  (
    {
      label,
      dark,
      secondary,
      bordered,
      onClick,
      showOnlyIcon,
      fullWidth,
      width,
      disabled,
      loading,
      icon: Icon,
      showArrow,
      noBorderRadius,
      classes,
      iconPosition = iconPositions.left,
      ...props
    },
    ref
  ) => {
    const styles = useStyles({
      fullWidth,
      width,
      showOnlyIcon,
      secondary,
      dark,
      noBorderRadius,
      bordered,
      hasIcon: Icon,
      iconPosition
    });

    const hasIcon = useMemo(() => Icon && cloneElement(Icon, { className: `${styles.icon}` }), [Icon]);

    const hasArrow = useMemo(() => showArrow && <ArrowDownIcon className={styles.arrowIcon} />, [showArrow]);

    return (
      <UIButton
        onClick={onClick}
        classes={{
          label: styles.label,
          root: dark ? styles.dark : styles.light,
          ...classes
        }}
        disabled={disabled}
        ref={ref}
        {...props}
      >
        {iconPosition == iconPositions.left && hasIcon}
        {label && !showOnlyIcon && <span className={hasIcon && styles.labelSpan}>{label}</span>}
        {loading ? (
          <LoadingIcon className={styles.loadingIcon} />
        ) : (
          (hasArrow || iconPosition == iconPositions.right) && hasIcon
        )}
      </UIButton>
    );
  }
);

export default memo(Button);
