import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    margin: '2px 2px 8px 2px',
    height: 23,
    fontSize: '1.11rem'
  },
  deleteIcon: {
    margin: '0 1px 0 -6px',
    width: 17,
    height: 17
  }
});
