import React, { useState, useEffect, useRef } from 'react';

const YouTubeVideo = ({ id, onUnmount }) => {
  // the video player
  const playerRef = useRef(null);
  // true when the video has loaded
  const [hasLoaded, setHasLoaded] = useState(false);
  // the start time of a playback time measurement
  const startRef = useRef(null);
  // accumulates the video playback time in seconds
  const playbackTimeRef = useRef(0);

  // measures video playback time
  const onPlayerStateChange = event => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      // when the video starts playing, set the start time to now
      startRef.current = Date.now();
    } else if (event.data === window.YT.PlayerState.PAUSED || event.data === window.YT.PlayerState.ENDED) {
      // when the video stops playing, add the measured time to the video playback time
      playbackTimeRef.current += (Date.now() - startRef.current) / 1000;
    }
  };

  // loads the specified video
  const loadVideo = id => {
    setHasLoaded(false);
    playerRef.current = new window.YT.Player('video', {
      videoId: id,
      events: {
        onReady: setHasLoaded(true),
        onStateChange: onPlayerStateChange
      }
    });
  };

  // loads the YouTube IFrame Player API
  const loadApi = callback => {
    // the API will call this function once the JavaScript has loaded
    window.onYouTubeIframeAPIReady = callback;

    // create script tag
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';

    // insert it before the first script tag
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  };

  // loads the API and the video
  useEffect(() => {
    window.YT
      ? // API already loaded: load the video
        loadVideo(id)
      : // API not loaded yet: load the API, then load the video
        loadApi(() => loadVideo(id));

    return () => {
      if (playerRef.current?.getPlayerState?.() === window.YT.PlayerState.PLAYING) {
        // if the video is currently playing, call the event handler explicitly to finish measuring time
        onPlayerStateChange({ data: window.YT.PlayerState.ENDED });
      }
      // pass the video playback time to the callback
      onUnmount?.(playbackTimeRef.current);
    };
  }, [id]);

  return (
    <div {...(hasLoaded ? { 'data-test': 'youTubeVideo' } : {})}>
      <div id="video" />
    </div>
  );
};

export default YouTubeVideo;
