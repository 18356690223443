import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import InfoTooltip from 'components/tooltips/InfoTooltip';
import Button from 'components/buttons/Button';
import LoadingIcon from 'components/loaders/LoadingIcon';

import withRestrictedFunctionality from 'root/hocs/withRestrictedFunctionality';

import useMediaQuery from 'utils/mediaQueries';

import downloadFileBlob from 'utils/downloadFileBlob';
import services from 'services';
import { trackEvent } from '../../utils/eventTracking';

const DownloadButton = ({ partialAnswerId, question, visualizationType, restrictedFunctionality, t }) => {
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery();

  const downloadFile = async (partialAnswerId, visualizationType) => {
    setLoading(true);
    const result = await services.downloadAnswerAsExcel(partialAnswerId, visualizationType);
    if (result.success) {
      downloadFileBlob(result, 'excel.xlsx');
    }
    setLoading(false);
    trackEvent('Download Excel clicked', { question: question, partialAnswerId: partialAnswerId });
  };

  return (
    <InfoTooltip text={t('tooltip.download')}>
      <Button
        className="button-secondary"
        text={t('answer-message.export-xls')}
        disabled={loading}
        showOnlyIcon={isMobile}
        onClick={() => restrictedFunctionality(() => downloadFile(partialAnswerId, visualizationType))}
      >
        {loading ? <LoadingIcon /> : <span className="icon-excel" />}
      </Button>
    </InfoTooltip>
  );
};

export default withTranslation('veezoo')(withRestrictedFunctionality(DownloadButton));
