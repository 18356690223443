import { baseURL } from 'config/baseURL';
import { headersGET } from './../../utils/HTTPheaders';
import { handleUnauthorized } from './../../utils/authFetch';

import { CHANGE_CLASS_ENTITY_SUCCESS } from './graphDetailedEntitiesInfo';

export const GRAPH_ITEM_ENTITIES_REQUEST = 'GRAPH_ITEM_ENTITIES_REQUEST';
export const GRAPH_ITEM_ENTITIES_SUCCESS = 'GRAPH_ITEM_ENTITIES_SUCCESS';
export const GRAPH_ITEM_ENTITIES_FAILURE = 'GRAPH_ITEM_ENTITIES_FAILURE';

export const CLOSE_ALL_ENTITIES = 'CLOSE_ALL_ENTITIES';

const ENTITIES_SEARCH_LIMIT = 27;

export const fetchGraphItemEntitiesInfo = (entities, scrollCount, searchQuery, type, scroll) => {
  return {
    type,
    entities,
    scrollCount,
    searchQuery,
    scroll
  };
};

export const fetchGraphItemEntities = (item, scrollCount, searchQuery, scroll) => {
  let search = searchQuery ? 'query=' + encodeURIComponent(searchQuery) : '';
  let start = scrollCount === 0 ? '&start=0' : '&start=' + ENTITIES_SEARCH_LIMIT * scrollCount;
  let limit = '&limit=' + ENTITIES_SEARCH_LIMIT;

  return dispatch => {
    dispatch(fetchGraphItemEntitiesInfo({}, scrollCount, searchQuery, GRAPH_ITEM_ENTITIES_REQUEST, scroll));

    fetch(baseURL + 'knowledge-graph/classes/' + encodeURIComponent(item.uri) + '/entities?' + search + start + limit, {
      headers: headersGET,
      credentials: 'include'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleUnauthorized(dispatch);
        } else {
          dispatch({
            type: GRAPH_ITEM_ENTITIES_FAILURE
          });
          throw Error(response.statusText);
        }
      })
      .then(result => {
        dispatch(fetchGraphItemEntitiesInfo(result, scrollCount, searchQuery, GRAPH_ITEM_ENTITIES_SUCCESS));
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const closeAllEntities = () => {
  return {
    type: CLOSE_ALL_ENTITIES
  };
};

// reducer which descrives grpah entitities (objects)
export const graphItemEntities = (state = {}, action) => {
  switch (action.type) {
    case GRAPH_ITEM_ENTITIES_REQUEST:
      return {
        ...state,
        isFetching: action.scroll
      };

    case GRAPH_ITEM_ENTITIES_SUCCESS:
      if (action.scrollCount === 0) {
        return action.entities;
      } else {
        let result = {};
        Object.assign(result, state);
        result.results = result.results.concat(action.entities.results);
        return {
          ...result,
          isFetching: false
        };
      }

    case GRAPH_ITEM_ENTITIES_FAILURE:
      return state || {};

    case CLOSE_ALL_ENTITIES:
      return {};

    case CHANGE_CLASS_ENTITY_SUCCESS: {
      let newResult = state?.results?.find(result => result.uri === action.entity.uri);
      if (newResult) {
        newResult.name = action.entity.name;
        let newState = state;
        newState.results = newState.results.map(result => (result.uri === newResult.uri ? newResult : result));
        return newState;
      }
      return state;
    }

    default:
      return state;
  }
};
