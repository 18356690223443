import { createRef, Fragment, PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import boardStyles from '../board/mini-board.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import * as ReactDOM from 'react-dom';
import { changeEntryPoint } from 'store/modules/user';
import { entryPointTypes } from 'config/constants';

export class CustomersOfTheDayDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.customersOfTheDayOptionsRef = createRef();
  }

  @boundMethod
  changeEntryPoint() {
    this.props.dispatch(changeEntryPoint(entryPointTypes.CUSTOMERS_OF_THE_DAY, undefined));
  }

  render() {
    const { t, activeEntryPoint } = this.props;

    const entryPointItem = (
      <Dropdown.Item
        as="button"
        role="menuitem"
        data-test="setAsEntrypoint"
        disabled={activeEntryPoint}
        onClick={this.changeEntryPoint}
      >
        {t('dropdown.set-entrypoint')}
      </Dropdown.Item>
    );

    const popperConfig = {
      modifiers: {
        hide: {
          enabled: false // set this to false because of warning appearing when we have disabled preventOverflow
        },
        preventOverflow: {
          enabled: false // set this to false because otherwise the positioning is off when we have an overflow container
        }
      }
    };

    // We portal the dropdown menu because the sidebar container has an overflow set
    // and this would cut-off the menu, if we would leave it in there.
    const dropDownMenu = ReactDOM.createPortal(
      <Dropdown.Menu
        role="menu"
        id="customers-of-the-day-actions-menu"
        className={boardStyles.boardDropdownMenu}
        popperConfig={popperConfig}
      >
        {entryPointItem}
      </Dropdown.Menu>,
      document.body
    );

    return (
      <Fragment>
        <div className={boardStyles.boardDropdown} ref={this.customersOfTheDayOptionsRef}>
          <Dropdown
            data-test="customersOfTheDayContextMenu"
            id="customers-of-the-day-actions"
            // Blur Dropdown Toggle after selection, otherwise it would stay active, which does not seem reasonable...
            onSelect={() => document.activeElement.blur()}
            as={ButtonGroup}
          >
            <Dropdown.Toggle drop="bottom" split>
              <span className="icon-dots">&nbsp;</span>
            </Dropdown.Toggle>
            {dropDownMenu}
          </Dropdown>
        </div>
      </Fragment>
    );
  }
}
