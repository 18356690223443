import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import BooleanFilterComponent from 'components/v2/BooleanFilter/BooleanFilter';
import { booleanOptions, booleanConfirmMessage, booleanTitle } from 'utils/booleanFilterUtils';
import { modifyAnswer } from 'store/modules/chat-messages';

import { answerModificationTypes } from 'config/constants';

const formattedStructuredFilter = (answer, column, t) => {
  const formattedFilter = {
    id: column.identifier,
    passages: undefined,
    value: booleanOptions.UNSELECTED.value,
    valueNames: undefined,
    title: column.title
  };

  const filter = answer?.structuredFilters?.find(
    filter =>
      filter.booleanTypeLiteralFilter && filter.booleanTypeLiteralFilter.booleanCandidateId === column.identifier
  );

  if (filter) {
    formattedFilter.id = filter.booleanTypeLiteralFilter.booleanCandidateId;
    formattedFilter.passages = filter.passages;
    formattedFilter.value = filter.booleanTypeLiteralFilter.value || booleanOptions.UNSELECTED.value;
    formattedFilter.valueNames = filter.booleanTypeLiteralFilter.valueNames;
    formattedFilter.title = booleanTitle(filter, t);
  }

  return formattedFilter;
};

const BooleanFilter = ({ booleanCandidateId, answer, column, username, dispatch, onClose }) => {
  const { t } = useTranslation();

  const structuredFilter = useMemo(() => formattedStructuredFilter(answer, column, t), [
    answer?.structuredFilters,
    booleanCandidateId
  ]);

  const handleConfirm = async value => {
    const message = booleanConfirmMessage(value, structuredFilter, t);

    const payload = {
      username,
      message,
      answerData: answer,
      id: booleanCandidateId,
      representedCandidate: column.representedCandidate,
      representedRelation: column.representedRelation,
      values: [value],
      modificationType: answerModificationTypes.boolean.value,
      t
    };

    await dispatch(modifyAnswer(payload));
  };

  return (
    <BooleanFilterComponent
      title={structuredFilter.title}
      initialValue={structuredFilter.value || booleanOptions.UNSELECTED.value}
      valueNames={structuredFilter.valueNames}
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

const mapStateToProps = state => ({ username: state.user.username });

export default connect(mapStateToProps)(BooleanFilter);
