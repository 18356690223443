import PropTypes from 'prop-types';

import BaseMessage from './BaseMessage';
import styles from './negative-feedback-message.scss';
import AlternativeAnswers from 'components/message/AlternativeAnswers';

const NegativeFeedbackMessage = ({ message, chatMessages, t }) => {
  const answerId = message.feedback.answerId;
  const answerMessage = chatMessages.find(chatMessage => chatMessage.id && chatMessage.answerId === answerId);
  const hasAlternatives = answerMessage?.answer?.alternativeInterpretations?.length > 0;

  const text = hasAlternatives ? t('thanks-for-feedback-alternatives') : t('thanks-for-feedback');

  const alternativesBlock = hasAlternatives && (
    <AlternativeAnswers
      answerId={answerId}
      showAlternativeInterpretations={true}
      confidenceDeltaThreshold={1.0} // we show everything we got
      alternativeInterpretations={answerMessage.answer.alternativeInterpretations}
      mainConfidence={answerMessage.answer.confidence}
      mainTitle={answerMessage.answer.title}
      mainFilters={answerMessage.answer.filters}
    />
  );

  const messageContent = (
    <div className={styles.wrapper}>
      <div className={styles.wrapper}>
        <span>{text}</span>
      </div>
      {alternativesBlock}
    </div>
  );

  return (
    <div key={message.id}>
      <BaseMessage
        key={message.id}
        showVeezooIcon={message.showVeezooIcon}
        content={messageContent}
        timestamp={message.timestamp}
      />
    </div>
  );
};

export default NegativeFeedbackMessage;

NegativeFeedbackMessage.propTypes = {
  message: PropTypes.object,
  chatMessages: PropTypes.array
};
