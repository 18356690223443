const fontLexend = 'var(--font-family)';

export const signupStyles = {
  fontFamily: fontLexend
};

export const veezooStyles = {
  fontFamily: fontLexend
};

// "Default" and "Studio" are the same, once Studio uses material-ui's default styles.
export const defaultStyles = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
};
