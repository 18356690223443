import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  header: {
    borderBottom: 'solid thin var(--accent-normal-color)',
    width: '100%',
    height: 35,
    position: 'relative'
  },
  backArrowButton: {
    width: 35,
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  backArrowIcon: {
    width: 16,
    height: 16,
    '& path': {
      fill: 'var(--colorful-button-primary-color)'
    }
  },
  showChildrenArrow: {
    width: 16,
    height: 16,
    margin: '0 20px',
    '& path': {
      fill: 'var(--colorful-button-primary-color)'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    boxSizing: 'border-box',
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: ({ customInput }) => (customInput ? 35 : 0)
  },
  customInput: {
    width: '100%',
    paddingLeft: 35,
    boxSizing: 'border-box',
    fontSize: '12px',
    padding: '8px 8px 8px 35px'
  },
  loadingContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: 10
  },
  list: {
    listStyleType: 'none',
    paddingLeft: 0,
    width: '100%',
    '& > li': {
      width: '100%',
      minHeight: 35,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 20px',
      boxSizing: 'border-box',
      fontSize: 14,
      '& > div': {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    }
  }
});

const accordionRoot = {
  width: '100%',
  minHeight: '0 !important',
  padding: '2px 0 0 !important',
  boxSizing: 'border-box',
  fontSize: 14
};

export const useAccordionStyles = makeStyles({
  root: {
    '&.Mui-expanded': {
      margin: 0,
      paddingTop: 2
    },
    ...accordionRoot,
    '& > .MuiAccordionSummary-root': {
      ...accordionRoot,
      '& > .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        margin: '0 !important',
        '&.Mui-expanded': {
          margin: '0 !important'
        }
      }
    },
    '& .MuiAccordionDetails-root': {
      padding: '4px 16px 8px',
      '& li': {
        padding: '5px'
      }
    },
    '&:before': {
      backgroundColor: 'transparent'
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  }
});

export const useDrawerStyles = makeStyles({
  paperClasses: {
    borderRadius: 0,
    height: '100%'
  }
});
