import { render } from 'react-dom';
import Root from './components/Root';

// Imports ClassNameSetup config to rename default CSS global class names for material-ui 5.
// This is necessary at the moment in order to avoid conflicts with material-ui 4  global class names.
// When a component is from material-ui 5, it will use "Mui5" instead of "Mui".
// As soon as we remove material-ui 4 from the project, this file can be removed.
import 'components/v3/Mui5ClassNameSetup';

import 'normalize.css'; // alternative to CSS reset: normalizes styles for a wide range of elements
import './styles/constants.css'; // import global config constants
import './styles/global/index.scss'; // import styles to global scope
// Component names displayed in the stack traces depend on the Function.name property.
// For support IE11 consider including a Function.name polyfill in your bundled application.
// needs only for production mode
import 'function.name-polyfill';

import Highcharts from 'highcharts';
import loadMap from 'highcharts/modules/map';
import loadTreeMap from 'highcharts/modules/treemap';
import loadDrillDown from 'highcharts/modules/drilldown';
import loadStock from 'highcharts/modules/stock';
import loadExporting from 'highcharts/modules/exporting';
import loadOfflineExporting from 'highcharts/modules/offline-exporting';
import loadExportData from 'highcharts/modules/export-data';
import loadAnnotations from 'highcharts/modules/annotations';
import loadSunburst from 'highcharts/modules/sunburst';
import loadRegression from 'highcharts-regression';
import { initializeWorldMap } from './plugins/highcharts/world';
import { initializeCantonMap } from './plugins/highcharts/ch-all';
import { initializePlzMap } from './plugins/highcharts/ch-plz-all';
import { initializeGemeindenMap } from './plugins/highcharts/ch-zh-gemeinden-all';
import { initializeUsMap } from './plugins/highcharts/us-all';
import { initializeBrazilMap } from './plugins/highcharts/br-all';
import { initializeGermanMap } from './plugins/highcharts/de-all';
import { initializeHighchartTheme } from './plugins/highcharts/theme';
import { handleExasolSignupVariables } from './startup/exasol';

// Initialize highcharts modules
loadExporting(Highcharts);
loadOfflineExporting(Highcharts);
loadExportData(Highcharts);
loadAnnotations(Highcharts);
loadMap(Highcharts);
loadStock(Highcharts);
loadTreeMap(Highcharts);
loadSunburst(Highcharts);
loadDrillDown(Highcharts);
loadRegression(Highcharts);
initializeHighchartTheme(Highcharts);
initializeWorldMap(Highcharts);
initializeCantonMap(Highcharts);
initializeGemeindenMap(Highcharts);
initializePlzMap(Highcharts);
initializeUsMap(Highcharts);
initializeBrazilMap(Highcharts);
initializeGermanMap(Highcharts);

// Make it top level available so we can still "eval" our charts
window.Highcharts = Highcharts;

handleExasolSignupVariables();

const renderApp = () => {
  render(<Root />, document.getElementById('root'));
};

renderApp();

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    renderApp();
  });
}
