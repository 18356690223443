import { get, post } from './utils.js';
import { studioURL } from 'config/baseURL';

const getDemoKnowledgeGraphs = () => get(`${studioURL}connectors/demo`);

const activateDemoKnowledgeGraph = id => post(`${studioURL}connectors/demo/${encodeURIComponent(id)}`);

const getConnectors = () => get(`${studioURL}connectors`);

const createKnowledgeGraphFromConnector = (applicationId, defaultLanguageId, name) => {
  const body = {
    languages: [defaultLanguageId],
    defaultLanguageId,
    info: {
      [defaultLanguageId]: { name }
    }
  };
  return post(`${studioURL}connectors/${applicationId}/fivetran`, body);
};

export default { getDemoKnowledgeGraphs, activateDemoKnowledgeGraph, getConnectors, createKnowledgeGraphFromConnector };
