import { makeStyles } from '@material-ui/core/styles';

import { veezooStyles } from '../defaultStyles';

const lightBlue = 'var(--light-blue)';
const lightGray = 'var(--light-gray)';
const darkGray = 'var(--dark-gray)';
const lightestGray = 'var(--lightest-gray)';
const primaryColor = 'var(--primary-color)';
const disabledVeezooBlue = 'var(--disabled-veezoo-blue)';

const signupRoot = {
  size: {
    mobile: {
      fontSize: '14px',
      padding: '0 9px',
      minWidth: '50px',
      fontWeight: '300',
      borderRadius: '4px'
    },
    small: {
      fontSize: '14px',
      padding: '0 9px',
      minWidth: '50px'
    },
    large: {
      fontSize: '16px',
      padding: '6 10px',
      minHeight: '50px'
    },
    default: {
      fontSize: '17px',
      fontWeight: '300',
      padding: '2px 20px',
      minWidth: '120px'
    },
    onlyFontLarge: {
      fontSize: '20px'
    }
  },
  color: {
    dark: {
      backgroundColor: lightBlue,
      color: 'white',
      border: `solid thin ${lightBlue}`,
      '&&:hover': {
        border: 'solid thin transparent',
        backgroundColor: primaryColor
      }
    },
    light: {
      backgroundColor: 'white',
      color: lightBlue,
      border: `solid thin ${lightBlue}`,
      '&&:hover': {
        backgroundColor: lightGray
      }
    },
    default: {
      backgroundColor: 'white',
      color: 'black',
      border: 'solid thin black',
      '&&:hover': {
        backgroundColor: lightGray
      }
    },
    simple: {
      color: 'black',
      '&&:hover': {
        backgroundColor: lightGray
      }
    }
  }
};

const veezooRoot = {
  size: {
    inputInline: {
      height: 32,
      maxHeight: '32px'
    },
    default: {}
  }
};

export const useSignupStyles = makeStyles({
  root: ({ size, mode, classes }) => ({
    '&&:disabled': {
      backgroundColor: disabledVeezooBlue,
      border: 'none',
      color: 'white'
    },
    borderRadius: '5px',
    textTransform: 'initial',
    ...(signupRoot.size?.[size] || signupRoot.size.default),
    ...(signupRoot.color?.[mode] || signupRoot.mode.default),
    ...(classes?.root || {})
  }),
  icon: ({ iconSize, classes }) => ({
    width: iconSize || 15,
    height: iconSize || 15,
    ...(classes?.icon || {})
  })
});

export const useVeezooStyles = ({
  size,
  mode,
  noBorders,
  fullWidth,
  iconOnly,
  reducedWidth,
  isToggleButton,
  selected,
  classes
}) => {
  /**
   * For some reason, spreading objects with validation on it from props inside makeStyles doesn't work, and the
   * final result brings a string with [object Object] concatenated inside the string
   * (returns something like "makeStyles-root-111 [object Object]").
   * So we have to do it outside.
   */
  const root = classes?.root || {};
  const rootHover = classes?.root?.['&:hover'] || {};
  const rootDisabled = classes?.root?.['&&:disabled'] || {};

  return makeStyles({
    root: {
      borderRadius: '4px',
      fontSize: '14px',
      fontFamily: veezooStyles.fontFamily,
      fontWeight: 400,
      textTransform: 'initial',
      minWidth: iconOnly || reducedWidth ? 32 : 110,
      width: fullWidth ? '100%' : 'initial',
      padding: noBorders ? '0 5px' : '10px 16px',
      height: 36,
      backgroundColor: noBorders ? 'transparent' : mode === 'dark' ? primaryColor : 'white',
      border: noBorders
        ? 'none'
        : `solid thin ${mode === 'dark' || (isToggleButton && selected) ? primaryColor : lightGray}`,
      color: noBorders
        ? primaryColor
        : mode === 'dark'
        ? 'white !important'
        : isToggleButton && !selected
        ? darkGray
        : primaryColor,
      ...(veezooRoot.size?.[size] || veezooRoot.size.default),
      ...root,
      '&:hover': {
        opacity: 0.7,
        backgroundColor: noBorders ? lightGray : mode === 'dark' ? primaryColor : lightestGray,
        color: noBorders ? primaryColor : mode === 'dark' ? 'white' : 'inherit',
        ...rootHover
      },
      '&&:disabled': {
        backgroundColor: disabledVeezooBlue,
        border: 'none',
        color: 'white',
        '& path': {
          fill: 'white'
        },
        ...rootDisabled
      }
    },
    label: ({ classes }) => ({
      justifyContent: 'space-evenly',
      ...(classes?.label || {})
    }),
    icon: ({ iconSize, classes }) => ({
      width: iconSize || 15,
      height: iconSize || 15,
      fill: mode === 'dark' ? 'white' : primaryColor,
      ...(classes?.icon || {})
    }),
    labelSpan: ({ classes }) => ({
      marginLeft: 7,
      ...(classes?.labelSpan || {})
    })
  })();
};

export const useDefaultStyles = makeStyles(() => ({
  icon: ({ iconSize, classes }) => ({
    width: iconSize || 15,
    height: iconSize || 15,
    ...(classes?.icon || {})
  })
}));
