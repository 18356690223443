import { baseURL, studioURL } from 'config/baseURL';
import { del, get, patch } from './utils.js';

const getUsers = () => get(`${studioURL}users`);

const getUser = id => get(`${studioURL}users/${encodeURIComponent(id)}`);

const getUserDeprecated = () => get(`${baseURL}user`);

const updateUserSettings = update => patch(`${baseURL}user/settings`, update);

const deleteUser = id => del(`${studioURL}users/${encodeURIComponent(id)}`);

export default { getUsers, getUser, getUserDeprecated, updateUserSettings, deleteUser };
