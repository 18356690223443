import React, { useCallback, useMemo } from 'react';

import { DropzoneAreaBase } from 'material-ui-dropzone';

import { trackEvent } from 'utils/eventTracking';

import { makeStyles } from '@material-ui/core/styles';

const defaultMaxFileSizeInBytes = 52428800;

const excelTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const formatNotAllowedForExcel = 'Format not allowed. Only allowed formats are ".xlsx" and ".csv"';

const imageTypes = ['image/jpeg', 'image/png', 'image/bmp'];
const formatNotAllowedForImage = 'Format not allowed. Only allowed formats are ".jpeg", ".png" and ".bmp';

const maxFileSizeInKb = sizeInBytes => Math.floor(sizeInBytes / 1024);
const maxFileSizeInMb = sizeInBytes => Math.floor(sizeInBytes / 1024 / 1024);

const useSnackbarStyles = makeStyles({
  root: {
    '& .MuiDropzoneSnackbar-message': {
      fontSize: '14px',
      display: 'table-cell',
      fontWeight: 100
    }
  }
});

const Dropzone = ({
  accept,
  error,
  filesLimit,
  dropzoneText,
  shouldTrack,
  knowledgeGraph,
  showPreviewsInDropzone,
  maxFileSizeInBytes,
  byteUnity,
  fullHeight,
  fileType,
  ...props
}) => {
  const useStyles = makeStyles({
    root: {
      ...(error ? { borderColor: 'red' } : {}),
      ...(fullHeight ? { height: '100%' } : {}),
      minHeight: 130,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .MuiDropzoneArea-icon': {
        color: 'var(--light-blue)'
      },
      '& .MuiDropzoneArea-text': {
        fontSize: '15px',
        marginTop: 21,
        marginBottom: 15,
        ...(error ? { color: 'red' } : {})
      }
    }
  });

  const useImagePreviewStyles = makeStyles({
    container: { width: '100%', margin: 0, display: 'block' },
    item: { width: '100%', maxWidth: '100%' }
  });

  const sizeInBytes = useMemo(() => maxFileSizeInBytes || defaultMaxFileSizeInBytes, [maxFileSizeInBytes]);

  const fileTooBigMessage = useMemo(() => {
    if (byteUnity?.toLowerCase() === 'kb') {
      return `File is too big. Max allowed size is ${maxFileSizeInKb(sizeInBytes)} Kb`;
    }
    return `File is too big. Max allowed size is ${maxFileSizeInMb(sizeInBytes)} Mb`;
  }, [byteUnity, sizeInBytes]);

  const onApprove = useCallback(
    fileInfo =>
      shouldTrack &&
      trackEvent('File Dropped', {
        file: {
          name: fileInfo[0].name,
          size: fileInfo[0].size,
          type: fileInfo[0].type
        },
        ...(knowledgeGraph ? { knowledgeGraph: { id: knowledgeGraph.id } } : {})
      }),
    [shouldTrack, knowledgeGraph]
  );

  const getRejectionMessage = useCallback(
    (fileInfo, allowedFormats, maxAllowedSize) => {
      let message = 'There is an error with the file you are trying to upload';
      if (!allowedFormats.some(format => format === fileInfo.type)) {
        message = fileType === 'image' ? formatNotAllowedForImage : formatNotAllowedForExcel;
      }
      if (fileInfo.size > maxAllowedSize) {
        message = fileTooBigMessage;
      }
      shouldTrack &&
        trackEvent('File Drop Failed', {
          file: {
            name: fileInfo.name,
            size: fileInfo.size,
            type: fileInfo.type
          },
          ...(knowledgeGraph ? { knowledgeGraph: { id: knowledgeGraph.id } } : {}),
          error: { message }
        });
      return message;
    },
    [shouldTrack, knowledgeGraph]
  );

  const classes = useStyles();
  const snackbarClasses = useSnackbarStyles();
  const imagePreviewClasses = useImagePreviewStyles();

  return (
    <DropzoneAreaBase
      classes={classes}
      maxFileSize={sizeInBytes}
      onAdd={onApprove}
      onDrop={onApprove}
      getDropRejectMessage={getRejectionMessage}
      filesLimit={filesLimit}
      dropzoneText={error ? 'File is required' : dropzoneText}
      showPreviewsInDropzone={showPreviewsInDropzone || false}
      disableRejectionFeedback
      dropzoneProps={{ accept, multiple: false }}
      acceptedFiles={fileType === 'image' ? imageTypes : excelTypes}
      alertSnackbarProps={{
        autoHideDuration: 999999,
        classes: snackbarClasses,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      }}
      previewGridClasses={imagePreviewClasses}
      {...props}
    />
  );
};

export default Dropzone;
