import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import UITooltip from '@material-ui/core/Tooltip';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { layouts } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';

import { useIconStyles, useTooltipStyles, useTooltipContainerStyles } from './styles';

const InfoTooltip = forwardRef(
  ({ children, text, layout, placement, clickableWhenMobile, helpOverrideClasses, size, ...rest }, ref) => {
    const isMobile = useMediaQuery();
    const helpClasses = helpOverrideClasses || useIconStyles({ isMobile, layout, size });
    const tooltipClasses = useTooltipStyles();
    const tooltipContainerClasses = useTooltipContainerStyles();

    const [open, setOpen] = useState(false);

    const tooltipComponent = (
      <UITooltip
        ref={ref}
        title={text}
        placement={placement}
        classes={tooltipClasses}
        arrow
        TransitionProps={{ timeout: 0 }}
        {...(clickableWhenMobile ? { open } : {})}
        {...rest}
      >
        {children || <HelpOutlineIcon classes={helpClasses} />}
      </UITooltip>
    );

    if (clickableWhenMobile)
      return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={isMobile ? tooltipContainerClasses.root : ''} onClick={() => setOpen(!open)}>
            {tooltipComponent}
          </div>
        </ClickAwayListener>
      );

    return tooltipComponent;
  }
);

export default InfoTooltip;

InfoTooltip.propTypes = {
  children: PropTypes.any,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  layout: PropTypes.string,
  placement: PropTypes.string,
  clickableWhenMobile: PropTypes.bool,
  helpOverrideClasses: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

InfoTooltip.defaultProps = {
  text: '',
  placement: 'top',
  clickableWhenMobile: false,
  layout: layouts.default
};
