import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

import CheckIcon from '@material-ui/icons/Check';
const PageLayout = ({ enableSignupSidebar, children }) => {
  const icon = (
    <CheckIcon
      style={{ color: 'white', background: '#48b02c', marginRight: '16px', borderRadius: '20px', padding: '3px' }}
    />
  );
  return (
    <>
      <div className={styles.container}>
        <div className={styles.mainContentContainer}>{children}</div>
        {enableSignupSidebar && (
          <div className={styles.gradientContainer}>
            <div className={styles.sidebarContentContainer}>
              <div>
                <div className={styles.sidebarTitle}>
                  Start now <br /> for free
                </div>
                <div>No Credit Card or Sales Call required.</div>
                <div>
                  <div className={styles.sidebarSubTitle}>{icon}Get Started in Seconds</div>
                  <div className={styles.sidebarSubTitleContent}>
                    Connect your data to Veezoo <br /> and start getting answers.
                  </div>
                </div>
                <div>
                  <div className={styles.sidebarSubTitle}>{icon}Simple for Business Users</div>
                  <div className={styles.sidebarSubTitleContent}>
                    Discover insights from data by literally <br /> just asking in plain English.
                  </div>
                </div>
                <div>
                  <div className={styles.sidebarSubTitle}>{icon}Powerful for Analysts</div>
                  <div className={styles.sidebarSubTitleContent}>
                    Model your data semantically with SQL <br /> to establish a shared language.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PageLayout;

PageLayout.propTypes = {
  children: PropTypes.any,
  enableSignupSidebar: PropTypes.bool
};
