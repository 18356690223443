import services from 'services';
import { trackEvent } from 'utils/eventTracking';

/**
 * This function is used to handle the exasol signup variables which are set by the backend when performing an exasol signup
 *
 * It expects the values to be set in the window.veezoo.exasolSignupVariables object.
 * The variables are prepared to be used throughout the signup process and put into the window.veezoo.signupVariables object.
 *
 * Note: we clear the variables as soon as possible to protect sensitive values in case of a cross site scripting attack
 *  (otherwise they would still be available in the global window object even in the chat, for example).
 * We use a global variable so that we can delete the values again for good,
 *  which wouldn't be easily possible when passing the values around in the different components
 */
export function handleExasolSignupVariables() {
  const exasolVariablesDefined = !!window.veezoo.exasolSignupVariables;
  const isOnExasolSignupPage = window.location.pathname === '/exasol/signup';

  if (exasolVariablesDefined && !isOnExasolSignupPage) {
    // Throw a fatal error
    delete window.veezoo.exasolSignupVariables; // clear the variables just in case
    throw new Error(
      'Exasol signup variables are defined but we are not on the signup page. This should not happen and indicates a potential leak of sensitive information!'
    );
  }

  // Warning: this check doesn't always work, because the user might be sent to the exasol page first,
  //  but then be redirected to a different page in case they are already logged in
  if (!exasolVariablesDefined && isOnExasolSignupPage) {
    throw new Error('Exasol signup variables need to be defined to access exasol/signup!');
  }

  if (exasolVariablesDefined) {
    trackEvent('Exasol Integration Initiated', { user: { email: window.veezoo.exasolSignupVariables.email } });

    // Variables used throughout signup
    const signupVariables = {
      // Variables to create a user
      signup: {
        email: window.veezoo.exasolSignupVariables.email,
        firstName: window.veezoo.exasolSignupVariables.firstName,
        lastName: window.veezoo.exasolSignupVariables.lastName
      },
      // Variables used to connect to a database
      database: {
        dbSystemId: 'exa',
        fields: {
          'knowledgeGraph.name': window.veezoo.exasolSignupVariables.dbName,
          host: window.veezoo.exasolSignupVariables.exaHost,
          port: window.veezoo.exasolSignupVariables.exaPort,
          username: window.veezoo.exasolSignupVariables.exaUserName,
          password: window.veezoo.exasolSignupVariables.exaPassword,
          schemaAsDatabase: window.veezoo.exasolSignupVariables.schema
        }
      },

      // Callback called after connecting to the database
      databaseCallback: getDatabaseCallback()
    };

    window.veezoo = { ...window.veezoo, signupVariables };

    delete window.veezoo.exasolSignupVariables;
  }
}

/** Returns a callback that notifies Exasol of the success of the integration (and deletes sensitive information) */
function getDatabaseCallback() {
  // Defined outside the callback body, so that it can be used in the callback, as the global variable is deleted afterward
  const exasolCallbackBody = {
    event: 'status',
    status: 'success',

    integrationId: 'veezoo',
    databaseId: window.veezoo.exasolSignupVariables.databaseId,
    clusterId: window.veezoo.exasolSignupVariables.clusterId,
    organizationId: window.veezoo.exasolSignupVariables.organizationId,

    url: 'https://app.veezoo.com'
  };

  return async () => {
    const response = await services.exasolCallback(exasolCallbackBody);

    if (response.success) {
      trackEvent('Exasol Integration Successful');
    } else {
      trackEvent('Exasol Integration Callback Error');
    }

    // We don't need the values anymore now, delete them
    delete window.veezoo.signupVariables;
  };
}
