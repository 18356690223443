import { baseURL } from 'config/baseURL';
import { headersGET } from './../../utils/HTTPheaders';
import { handleUnauthorized } from './../../utils/authFetch';

import { handleError } from 'services/utils';

import services from 'services';

import { GRAPH_ITEM_CLASS_FAILURE } from './graphItemClass';
import { fetchUserInformation } from '../user';

export const MEASURE_REQUEST = 'MEASURE_REQUEST';
export const MEASURE_SUCCESS = 'MEASURE_SUCCESS';
export const MEASURE_FAILURE = 'MEASURE_FAILURE';
export const EMPTY_MEASURE = 'EMPTY_MEASURE';

export const CHANGE_MEASURE_REQUEST = 'CHANGE_MEASURE_REQUEST';
export const CHANGE_MEASURE_SUCCESS = 'CHANGE_MEASURE_SUCCESS';
export const CHANGE_MEASURE_FAILURE = 'CHANGE_MEASURE_FAILURE';

export const fetchMeasureInfo = uri => {
  return dispatch => {
    dispatch({ type: GRAPH_ITEM_CLASS_FAILURE });

    dispatch({ type: MEASURE_REQUEST });

    fetch(baseURL + 'knowledge-graph/measures/' + encodeURIComponent(uri), {
      headers: headersGET,
      credentials: 'include'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleUnauthorized(dispatch);
        } else {
          dispatch({ type: MEASURE_FAILURE });
          throw Error(response.statusText);
        }
      })
      .then(result => {
        dispatch({ type: MEASURE_SUCCESS, measure: result });
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const removeMeasureInfo = () => {
  return {
    type: EMPTY_MEASURE
  };
};

export const updateMeasure = (uri, property, value) => {
  return async dispatch => {
    dispatch({ type: CHANGE_MEASURE_REQUEST, property });

    let body = { [property]: value };
    const result = await services.updateMeasure(uri, body);
    handleError(result, dispatch);
    if (!result.success) {
      return dispatch({ type: CHANGE_MEASURE_FAILURE, property });
    } else {
      dispatch({ type: CHANGE_MEASURE_SUCCESS, measure: result.data, property });
      // refresh the user information as updating a measure possibly adds a milestone
      dispatch(fetchUserInformation());
    }
  };
};

// reducer which describes particular measure displayed on graph wizard
export const measureInfo = (state = {}, action) => {
  switch (action.type) {
    case MEASURE_SUCCESS:
      return action.measure;

    case EMPTY_MEASURE:
      return {};

    case CHANGE_MEASURE_SUCCESS:
      return action.measure;

    default:
      return state;
  }
};
