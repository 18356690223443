import { makeStyles } from '@material-ui/core';

const border = 'solid thin var(--light-gray)';
const tableBorderRadius = '10px';

export const useViewStyles = makeStyles({
  browseBoardsListContainer: {
    marginTop: '10px',
    border,
    backgroundColor: 'white',
    borderRadius: tableBorderRadius
  },
  paginationContainer: {
    borderTop: border,
    borderBottomLeftRadius: tableBorderRadius,
    borderBottomRightRadius: tableBorderRadius,
    backgroundColor: 'var(--lightest-gray)',
    paddingRight: '10px'
  },
  loadingContainer: {
    display: 'flex',
    padding: '18px',
    justifyContent: 'center'
  }
});

const customCellInlineRow = {
  display: 'inline-block',
  fontSize: '12px',
  margin: '4px 0'
};

export const useStyles = makeStyles({
  filterFields: {
    marginTop: '15px'
  },
  customCelContainer: {
    padding: '10px 15px',
    position: 'relative',
    '&:hover $displayOnlyOnHover': {
      display: 'block !important'
    }
  },
  customCellTitle: {
    ...customCellInlineRow,
    fontSize: '14px',
    fontWeight: 600
  },
  customCellDescription: {
    ...customCellInlineRow,
    color: 'var(--normal-gray)'
  },
  customCellDetails: {
    ...customCellInlineRow,
    color: 'var(--normal-gray)',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  customCellTags: {
    ...customCellInlineRow,
    fontSize: '11px'
  },
  customCellButtonsContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    textAlign: 'right'
  },
  button: {
    display: 'inline-block',
    marginLeft: '10px'
  },
  displayOnlyOnHover: {
    display: 'none'
  },
  fieldContainer: ({ isMobile }) => ({
    width: '100%',
    ...(!isMobile ? { maxWidth: '225px' } : {})
  }),
  tagChip: {
    margin: '0 5px 0',
    width: 'min-content',
    display: 'inline-block'
  },
  subscribedIcon: {
    fill: 'green',
    width: 10,
    marginRight: 5
  },
  subscribedLabel: {
    fontWeight: '600',
    color: 'var(--success-color)'
  },
  sharerIcon: {
    fill: 'var(--normal-gray)',
    width: 11,
    marginRight: 5
  },
  sharerLabel: {
    fontWeight: '600'
  },
  subscribersIcon: {
    fill: 'var(--normal-gray)',
    width: 15,
    height: 15,
    marginRight: 5
  },
  dividerDot: {
    display: 'inline-block',
    margin: '0 7px',
    width: '5px',
    height: '8px',
    position: 'relative',
    boxSizing: 'border-box',
    border: 'solid 0px transparent',
    '& > span': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      borderRadius: '50%',
      backgroundColor: 'var(--normal-gray)',
      width: '2px',
      height: '2px',
      display: 'inline-block'
    }
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  },
  emptyBoard: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'var(--font-family)',
    fontSize: '16px',
    color: 'var(--darkest-gray)',
    border: 'solid thin var(--light-medium-gray)'
  },
  boardIcon: {
    width: '90px',
    height: '90px',
    '& path': {
      fill: 'var(--dark-gray)'
    }
  },
  errorIcon: {
    marginBottom: '10px'
  },
  left: ({ isMobile }) =>
    !isMobile
      ? {
          float: 'left',
          paddingRight: '5px'
        }
      : {},
  right: ({ isMobile }) =>
    !isMobile
      ? {
          float: 'right'
        }
      : {}
});
