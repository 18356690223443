import styles from './ShareModal.styles.scss';

const IconContainer = ({ icon: Icon }) => {
  return (
    <div className={styles.iconContainer}>
      <Icon className={styles.icon} />
    </div>
  );
};

export default IconContainer;
