import { baseURL } from 'config/baseURL';
import { get, post } from './utils.js';

const askModifiedAnswer = payload => {
  const fullPayload = {
    ...payload,
    sessionId: window.veezoo.sessionId
  };
  return post(`${baseURL}answers/edit`, JSON.stringify(fullPayload));
};

const getAnswerTotalRowCount = partialAnswerId =>
  get(`${baseURL}answers/result/metadata/${partialAnswerId}?sessionId=${window.veezoo.sessionId}`);

export default { askModifiedAnswer, getAnswerTotalRowCount };
