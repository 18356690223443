import { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { captureException } from 'store/modules/exception';

import styles from './error-boundary.scss';
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    // catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    // capture exceptions and send to api
    this.props.dispatch(
      captureException({
        stackTrace: error.toString() + errorInfo.componentStack.toString(),
        info: 'Browser version is reported as ' + navigator.appVersion + '; Location path is' + location.pathname
      })
    );
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children, t } = this.props;

    if (errorInfo) {
      return (
        <aside className={styles.errorBoundary}>
          <p className={styles.errorText}>{t('something-went-wrong')}</p>

          <details className={styles.errorDetails}>
            {error && <div className={styles.errorMessage}>{error.toString()}</div>}
            <br />
            {errorInfo.componentStack}
          </details>
        </aside>
      );
    }

    // If the boundary has no children, return null.
    return children || null;
  }
}

export default withTranslation('veezoo')(connect(null)(ErrorBoundary));
