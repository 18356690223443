import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { Button, LoadingCircle } from 'components/v3';

import { useDefaultStyles, useVeezooStyles } from './CustomModal.styles';

import CloseIcon from 'svg/v2/close.svg';

import { layouts } from 'config/constants';
import { withTranslation } from 'react-i18next';
import omit from 'lodash/omit';

const CustomModal = ({
  autoFocusOnConfirm,
  open,
  title,
  content,
  confirmLabel,
  confirmIcon,
  closeLabel,
  onConfirm,
  onClose,
  layout,
  disabled,
  disableConfirmButton,
  loading,
  hideButtons,
  customButtons,
  DialogTitleProps,
  DialogContentProps,
  DialogActionsProps,
  paperStyles,
  t,
  ...props
}) => {
  const useStyles = layout === layouts.veezoo ? useVeezooStyles : useDefaultStyles;

  const { classes: DialogTitlePropsClasses, ...DialogTitleOtherProps } = DialogTitleProps;
  const { classes: DialogContentPropsClasses, ...DialogContentOtherProps } = DialogContentProps;
  const { classes: DialogActionsPropsClasses } = DialogActionsProps;

  const styles = useStyles({ hideButtons, paper: paperStyles });

  const showDividers = layout !== layouts.veezoo && layout !== layouts.signup;

  const handleConfirm = async () => {
    // don't close the dialog if the 'onConfirm' callback returns false
    if ((await onConfirm()) !== false) {
      onClose();
    }
  };

  const handleClose = () => onClose();

  // remove props which would lead to a warning when passed along to 'Dialog'
  const dialogProps = omit(props, ['tReady']);

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: styles.paper }} {...dialogProps}>
      <DialogTitle
        classes={{ root: `${styles.titleRoot} ${DialogTitlePropsClasses?.root || ''}` }}
        disableTypography
        {...DialogTitleOtherProps}
      >
        {title}
        <div className={styles.closeButtonContainer}>
          {loading ? (
            <LoadingCircle size={11} color="var(--primary-color)" data-test="customModalLoading" />
          ) : (
            <button
              className={styles.closeButton}
              onClick={onClose}
              data-test="customModalCloseButton"
              title={t('close')}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          )}
        </div>
      </DialogTitle>
      <DialogContent
        classes={{ root: `${styles.contentRoot} ${DialogContentPropsClasses?.root || ''}` }}
        dividers={showDividers}
        {...DialogContentOtherProps}
      >
        {content}
      </DialogContent>
      {!hideButtons && (
        <DialogActions classes={{ root: `${styles.actionsContainer} ${DialogActionsPropsClasses?.root || ''}` }}>
          {customButtons || (
            <>
              <Button onClick={onClose} layout={layout}>
                {closeLabel || (onConfirm ? 'Cancel' : 'Close')}
              </Button>
              {onConfirm && (
                <Button
                  autoFocus={autoFocusOnConfirm}
                  onClick={handleConfirm}
                  layout={layout}
                  mode="dark"
                  disabled={disableConfirmButton || disabled || loading}
                  {...(confirmIcon && { icon: confirmIcon })}
                >
                  {confirmLabel}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withTranslation('veezoo')(CustomModal);

CustomModal.propTypes = {
  autoFocusOnConfirm: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  content: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmLabel: PropTypes.string,
  confirmIcon: PropTypes.any,
  closeLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  layout: PropTypes.string,
  disabled: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  hideButtons: PropTypes.bool,
  customButtons: PropTypes.any,
  loading: PropTypes.bool,
  DialogTitleProps: PropTypes.object,
  DialogContentProps: PropTypes.object,
  DialogActionsProps: PropTypes.object,
  paperStyles: PropTypes.object
};

CustomModal.defaultProps = {
  autoFocusOnConfirm: false,
  confirmLabel: 'Confirm',
  confirmIcon: null,
  onConfirm: null,
  closeLabel: null,
  title: null,
  layout: 'default',
  disabled: false,
  disableConfirmButton: false,
  hideButtons: false,
  customButtons: null,
  loading: false,
  DialogTitleProps: {},
  DialogContentProps: {},
  DialogActionsProps: {},
  paperStyles: {}
};
