import classnames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import './chart.scss';
import styles from './number-chart.scss';

export default function TwoNumberChart(props) {
  const verticalCenterClass = classnames({
    verticalCenter: props.isCentered,
    'comparison-chart': true
  });

  const chart = JSON.parse(props.chart);
  const { numbers } = chart;

  // Setting in the container the flag fluid to true solves overflow problems while printing
  return (
    <div className={verticalCenterClass}>
      <Container className="container-fluid verticalCenterCorrection" fluid={true}>
        <Row bsPrefix="flex-row" className="d-flex align-items-center">
          <Col xs={4} className="text-right col-width">
            <div className={styles.twoNumberStyle}>
              <div className="p-2">{numbers[1].label}</div>
            </div>
          </Col>
          <Col xs="auto">
            <div className={styles.numberFormat}>{numbers[1].value}</div>
          </Col>
        </Row>

        <Row bsPrefix="flex-row" className="d-flex align-items-center">
          <Col xs={4} className="text-right col-width">
            <div className={styles.twoNumberStyle}>
              <div className="p-2">{numbers[0].label}</div>
            </div>
          </Col>
          <Col xs="auto" className={styles.displayInline}>
            <div className={styles.numberFormat}>{numbers[0].value}</div>
            <div className={numbers[1].isPositive ? styles.positive : styles.negative}>
              <div className={styles.space}>{numbers[1].delta}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
