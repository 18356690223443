import { makeStyles } from '@material-ui/core';

const primaryColor = 'var(--primary-color)';

export default makeStyles({
  selectContainer: {
    marginTop: '15px'
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px'
  },
  buttonContainer: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'end',
    fontSize: '14px'
  },
  addBoardButton: {
    marginTop: '5px',
    border: 'solid thin transparent',
    backgroundColor: 'transparent',
    fontSize: 14,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7
    }
  },
  plusIcon: {
    width: 13,
    height: 13,
    marginRight: 4,
    marginLeft: 5,
    cursor: 'pointer',
    '& path': { fill: primaryColor }
  }
});
