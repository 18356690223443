import { cloneElement, useMemo, memo } from 'react';

import PropTypes from 'prop-types';

import { layouts } from 'config/constants';

import UIButton from '@material-ui/core/Button';
import { useSignupStyles, useVeezooStyles, useDefaultStyles } from './Button.styles';

const Button = ({
  children,
  classes, // The classes prop is used to override the default styles of the button.
  layout,
  onClick,
  size,
  mode,
  variant,
  disabled,
  isToggleButton,
  selected,
  fullWidth,
  color,
  iconOnly,
  reducedWidth,
  iconSize,
  noBorders,
  icon: Icon,
  ...rest
}) => {
  const useStyles = useMemo(() => {
    if (layout === layouts.signup) return useSignupStyles;
    if (layout === layouts.veezoo) return useVeezooStyles;
    return useDefaultStyles;
  }, [layout]);

  const styles = useStyles({
    fullWidth,
    size,
    mode,
    iconOnly,
    reducedWidth,
    iconSize,
    noBorders,
    isToggleButton,
    selected,
    classes
  });

  const hasIcon = useMemo(() => Icon && cloneElement(Icon, { className: `${styles.icon}` }), [Icon]);

  const customProps = useMemo(() => {
    if (layout === layouts.signup) return {};
    if (layout === layouts.veezoo) return {};
    return { variant, color };
  }, [layout, variant, color]);

  return (
    <UIButton
      classes={{ root: styles.root, label: styles.label }}
      onClick={onClick}
      disabled={disabled}
      {...customProps}
      {...rest}
    >
      {hasIcon}
      {!iconOnly && <span className={hasIcon && styles.labelSpan}>{children}</span>}
    </UIButton>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  layout: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  mode: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  noBorders: PropTypes.bool,
  isToggleButton: PropTypes.bool,
  selected: PropTypes.bool,
  icon: PropTypes.any,
  iconOnly: PropTypes.bool,
  reducedWidth: PropTypes.bool,
  color: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Button.defaultProps = {
  children: 'button',
  classes: {},
  fullWidth: false,
  layout: 'default',
  onClick: () => {},
  size: 'default',
  mode: 'default',
  variant: 'text',
  disabled: false,
  noBorders: false,
  isToggleButton: false,
  selected: false,
  iconOnly: false,
  reducedWidth: false,
  iconSize: 15
};

export default memo(Button);
