import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import UIButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const sizeMobile = {
  fontSize: '14px',
  padding: '0 9px',
  minWidth: '50px',
  fontWeight: '300',
  borderRadius: '4px'
};

const sizeSmall = {
  fontSize: '14px',
  padding: '0 9px',
  minWidth: '50px'
};

const sizeLarge = {
  fontSize: '16px',
  padding: '6 10px',
  minHeight: '50px'
};

const sizeDefault = {
  fontSize: '17px',
  fontWeight: '300',
  padding: '2px 20px',
  minWidth: '120px'
};

const sizeOnlyFontLarge = {
  fontSize: '20px'
};

const darkColorScheme = {
  backgroundColor: 'var(--light-blue)',
  color: 'white',
  border: `solid thin var(--light-blue)`,
  '&&:hover': {
    border: 'solid thin transparent',
    backgroundColor: 'var(--primary-color)'
  }
};

const lightColorScheme = {
  backgroundColor: 'white',
  color: 'var(--light-blue)',
  border: `solid thin var(--light-blue)`,
  '&&:hover': {
    backgroundColor: 'var(--light-gray)'
  }
};

const defaultColorScheme = {
  backgroundColor: 'white',
  color: 'black',
  border: 'solid thin black',
  '&&:hover': {
    backgroundColor: 'var(--light-gray)'
  }
};

const simpleColorScheme = {
  color: 'black',
  '&&:hover': {
    backgroundColor: 'var(--light-gray)'
  }
};

const Button = ({ children, onClick, size, color, type, disabled, rootClass, ...rest }) => {
  const sizeScheme = useMemo(() => {
    if (size === 'mobile') return sizeMobile;
    if (size === 'small') return sizeSmall;
    if (size === 'large') return sizeLarge;
    if (size === 'fontLarge') return sizeOnlyFontLarge;
    return sizeDefault;
  }, [size]);

  const colorScheme = useMemo(() => {
    if (color === 'dark') return darkColorScheme;
    if (color === 'light') return lightColorScheme;
    if (color === 'simple') return simpleColorScheme;
    return defaultColorScheme;
  }, [color]);

  const useStyles = makeStyles({
    root: {
      '&&:disabled': {
        backgroundColor: 'var(--disabled-veezoo-blue)',
        border: 'none',
        color: 'white'
      },
      borderRadius: '5px',
      textTransform: 'initial',
      ...sizeScheme,
      ...colorScheme,
      ...rootClass
    }
  });

  const buttonClasses = useStyles();

  return (
    <UIButton type={type} classes={buttonClasses} onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </UIButton>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  rootClass: PropTypes.object
};

Button.defaultProps = {
  children: 'button',
  onClick: () => {},
  size: 'default',
  color: 'default',
  type: 'submit',
  disabled: false,
  rootClass: {}
};

export default memo(Button);
