import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  paper: {
    backgroundColor: 'var(--primary-dark-color)',
    position: 'absolute',
    width: 240
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2
  }
});
