export const textSort = (a, b) => {
  a = a === null || a === undefined ? '' : a.toLowerCase();
  b = b === null || b === undefined ? '' : b.toLowerCase();
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

/**
 * Compares two arrays of strings to see if they are equal to each other.
 * Important notice that the arrays should contain only strings, and it doesn't
 * validate nested items (array inside array).
 *
 * The comparison checks if an item in the first array is present in the second, regardless of
 * the position of the index. Example: ['this', 'that', 'those'] and ['that', 'this', 'those'] are
 * considered equal and would return true.
 *
 * @param {array of strings} first
 * @param {array of strings} second
 * @returns boolean
 */
export const arraysContainSameStrings = (first, second) => {
  if (first.length !== second.length) return false;

  let areEqual = true;
  first.forEach(itemOfFirst => {
    if (!second.some(itemOfSecond => itemOfSecond === itemOfFirst)) {
      areEqual = false;
    }
  });
  return areEqual;
};

/**
 * Sorts the specified array of objects by the value returned when applying the specified value function to objects
 * from the array.
 *
 * @param array the array of objects to sort
 * @param f the function returning the value to sort by given an object from the array
 * @returns a new, sortedd array
 */
export const sortBy = (array, toValue) =>
  [...array].sort((objectA, objectB) => {
    const valueA = toValue(objectA);
    const valueB = toValue(objectB);
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  });

/**
 * Groups the specified array of objects by the value returned when applying the specified key function to objects from
 * the array.
 *
 * @param array the array of objects to group
 * @param toKey the function returning the key to group by given an object from the array
 * @returns an object containing the groups of array objects indexed by key
 */
export const groupBy = (array, toKey) => {
  return array.reduce((acc, element) => {
    const key = toKey(element);
    (acc[key] = acc[key] || []).push(element);
    return acc;
  }, {});
};

/**
 * Array filter method to deduplicate the elements of an array.
 *
 * Example: [1,2,2,3,1].filter(unique) => [1,2,3]
 */
export const unique = (value, index, self) => self.indexOf(value) === index;

/**
 * Partitions the specified array according to the specified function.
 *
 * Example: partition([1,2,3,4], n => n > 2) => [[3, 4], [1,2]]
 */
export const partition = (array, f) => [array.filter(element => f(element)), array.filter(element => !f(element))];
