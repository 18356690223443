import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import UIStepper from '@material-ui/core/Stepper';
import UIStep from '@material-ui/core/Step';
import UIStepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

import { makeStyles } from '@material-ui/core/styles';

import useMediaQuery from 'utils/mediaQueries';

const useConnectorStyles = makeStyles({
  alternativeLabel: ({ isMobile }) => ({
    top: 10,
    ...(isMobile
      ? { left: 'calc(-58% + 12.5px)', right: 'calc(41% + 11px)' }
      : { left: 'calc(-50% + 15px)', right: 'calc(50% + 15px)' })
  }),
  active: {
    '& $line': {
      borderColor: 'var(--light-blue)'
    }
  },
  completed: {
    '& $line': {
      borderColor: 'var(--light-blue)'
    }
  },
  line: {
    borderColor: 'var(--light-gray)',
    borderTopWidth: 3,
    borderRadius: 1
  }
});

const useIconStyles = makeStyles({
  root: {
    color: 'var(--light-gray)',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  base: {
    width: ({ isMobile }) => (isMobile ? 18 : 30),
    height: ({ isMobile }) => (isMobile ? 18 : 30),
    borderRadius: '50%',
    color: 'white',
    textAlign: 'center',
    fontSize: ({ isMobile }) => (isMobile ? '10px' : '18px'),
    paddingTop: '2px',
    boxSizing: 'border-box'
  },
  circle: {
    backgroundColor: 'var(--light-gray)'
  },
  active: {
    backgroundColor: 'var(--light-blue)'
  },
  completed: {
    backgroundColor: 'var(--light-blue)'
  }
});

const useStepperStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    margin: '0 -21px',
    padding: 0
  }
});

const StepIcon = props => {
  const isMobile = useMediaQuery();
  const iconClasses = useIconStyles({ isMobile });
  const { active, completed, icon } = props;

  const color = active ? 'active' : completed ? 'completed' : 'circle';

  return (
    <div className={iconClasses.root}>
      <div className={clsx(iconClasses.base, iconClasses[color])}>{icon}</div>
    </div>
  );
};

const Stepper = ({ step, totalSteps }) => {
  const isMobile = useMediaQuery();
  const connectorStyles = useConnectorStyles({ isMobile });
  const stepperClasses = useStepperStyles();
  return (
    <UIStepper
      alternativeLabel
      classes={stepperClasses}
      activeStep={step - 1}
      connector={<StepConnector classes={connectorStyles} />}
    >
      {Array.from({ length: totalSteps }).map((_, index) => (
        <UIStep key={`step_${index}`}>
          <UIStepLabel StepIconComponent={StepIcon} />
        </UIStep>
      ))}
    </UIStepper>
  );
};

export default Stepper;

Stepper.propTypes = {
  step: PropTypes.number,
  totalSteps: PropTypes.number
};

Stepper.defaultProps = {
  step: 1,
  totalSteps: 4
};
