import services from 'services';
import { handleError } from 'services/utils';

export const RECIPES_CLEAR_BAD_NAME_PROBLEM = 'RECIPES_CLEAR_BAD_NAME_PROBLEM';

export const RECIPES_BAD_NAME_PROBLEMS_REQUEST = 'RECIPES_BAD_NAME_PROBLEMS_REQUEST';
export const RECIPES_BAD_NAME_PROBLEMS_SUCCESS = 'RECIPES_BAD_NAME_PROBLEMS_SUCCESS';
export const RECIPES_BAD_NAME_PROBLEMS_FAILURE = 'RECIPES_BAD_NAME_PROBLEMS_FAILURE';

const problemTypes = { BAD_NAME: 'BAD_NAME' };
const recipeTypes = { AUTO_APPLY: 'AUTO_APPLY' };
const recipeActionType = { CHANGE_NAME: 'CHANGE_NAME' };

export const fetchBadNameProblems = language => {
  if (!language) {
    console.error('Did not provide a language when fetching bad name problems');
  } else {
    return async dispatch => {
      dispatch({ type: RECIPES_BAD_NAME_PROBLEMS_REQUEST });

      // NOTE: the (very general) recipes endpoint does not allow filtering for a language; we always get all BAD_NAME
      //  problems and filter for the requested language later
      const result = await services.getRecipes([problemTypes.BAD_NAME]);
      handleError(result, dispatch);

      if (!result.success) {
        return dispatch({ type: RECIPES_BAD_NAME_PROBLEMS_FAILURE });
      }

      dispatch({ type: RECIPES_BAD_NAME_PROBLEMS_SUCCESS, language, problems: result.data.problems });
    };
  }
};

export const clearBadNameProblem = conceptUri => {
  return dispatch => {
    dispatch({ type: RECIPES_CLEAR_BAD_NAME_PROBLEM, conceptUri });
  };
};

const getSuggestionsForBadNames = (allProblems, language) => {
  const badNameProblems = allProblems
    // we are only interested in BAD_NAME problems, and in the current selected language.
    .filter(problem => problem.type === problemTypes.BAD_NAME && problem.location.language === language)
    .map(problem => {
      // get all name change suggestions (this may be empty)
      const suggestions = problem.recipes
        // we are only interested in AUTO_APPLY recipes with action CHANGE_NAME
        .filter(recipe => recipe.type === recipeTypes.AUTO_APPLY && recipe.action.type === recipeActionType.CHANGE_NAME)
        .map(recipe => recipe.action.args.newName);

      return {
        conceptUri: problem.location.resource,
        suggestions
      };
    });

  // converts array of BAD_NAME problems into an object with conceptUri as key
  return badNameProblems.reduce((acc, problem) => {
    acc[problem.conceptUri] = problem.suggestions;
    return acc;
  }, {});
};

const removeKey = (obj, key) => {
  const newObj = { ...obj };
  delete newObj[key];
  return newObj;
};

// describe a recipes reducer
export const initRecipesState = {
  fetching: false,
  fetched: false,
  // maps URIs of concepts with bad names (in the currently selected language) to a list of suggestions
  suggestionsForBadNames: {}
};

export function recipes(state = initRecipesState, action) {
  switch (action.type) {
    case RECIPES_CLEAR_BAD_NAME_PROBLEM:
      return {
        ...state,
        suggestionsForBadNames: removeKey(state.suggestionsForBadNames, action.conceptUri)
      };

    case RECIPES_BAD_NAME_PROBLEMS_REQUEST:
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case RECIPES_BAD_NAME_PROBLEMS_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        suggestionsForBadNames: getSuggestionsForBadNames(action.problems, action.language)
      };

    case RECIPES_BAD_NAME_PROBLEMS_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    default:
      return state;
  }
}
