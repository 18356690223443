import { makeStyles } from '@material-ui/core/styles';

import UISkeleton from '@material-ui/lab/Skeleton';

const useSkeletonStyles = makeStyles({
  root: { backgroundColor: 'rgba(255, 255, 255, 0.2)' }
});

const Skeleton = ({ type, ...props }) => {
  const classes = useSkeletonStyles();
  return <UISkeleton variant="rect" classes={classes} {...props} />;
};

export default Skeleton;
