import PropTypes from 'prop-types';
import nameSplit from 'utils/nameSplit';

import useStyles from './Avatar.styles.js';

const Avatar = ({ username }) => {
  const styles = useStyles();
  const initials = nameSplit(username);
  return <div className={styles.container}>{initials}</div>;
};

export default Avatar;

Avatar.propTypes = {
  username: PropTypes.string.isRequired
};

Avatar.defaultProps = {
  username: 'Admin'
};
