import { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import Skeleton from '@material-ui/lab/Skeleton';

import { withStyles } from '@material-ui/core/styles';
import styles from './content-loader.scss';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    border: 'solid 1px var(--accent-light-color)',
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: 'inherit'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'var(--accent-light-color)'
  }
}))(LinearProgress);

// create Skeleton with var(--accent-light-color) background and no animation
const NoAnimationSkeleton = withStyles(theme => ({
  root: {
    backgroundColor: 'var(--accent-light-color)',
    animation: 'none'
  }
}))(Skeleton);

const ContentLoader = ({ t, isAnswerToQuestion }) => {
  const [progress, setProgress] = useState(0);
  // track time spent as well
  const [secondsPassed, setSecondsPassed] = useState(0);
  const intervalTimeInSeconds = 1;
  const intervalTimeInMs = intervalTimeInSeconds * 1000;
  const [loadingMessage, setLoadingMessage] = useState(t('loading-bar.default'));
  const containerRef = useRef(null);
  const containerHeight = containerRef?.current?.clientHeight;

  useEffect(() => {
    const timer = setInterval(() => {
      // we keep track of time to show different messages
      setSecondsPassed(oldTime => oldTime + intervalTimeInSeconds);
      setProgress(oldProgress => {
        if (oldProgress >= 99) {
          return 99;
        } else {
          // choose a random number between 3 and 12
          const k = Math.floor(Math.random() * 10) + 3;
          // what is missing to 100
          const missing = 100 - oldProgress;
          // how much we can add
          const add = Math.min(missing / k, 10);
          // add it
          return oldProgress + add;
        }
      });
    }, intervalTimeInMs);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setLoadingMessage(oldMessage => {
      if (secondsPassed > 240) {
        // shouldn't happen, because we expect the backend to timeout by then
        return t('loading-bar.default');
      } else if (secondsPassed > 200) {
        // start a countdown until the backend times out to manage user's expectations
        return t('loading-bar.almost-timeout', { seconds: 240 - secondsPassed });
      }
      // if the time is between 10 and 200 seconds, we show a random message every 10 seconds
      else if (secondsPassed >= 10) {
        const numberOfGeneralMessages = 3;
        const numberOfAnswerMessages = 2;
        const generalMessages = Array.from(Array(numberOfGeneralMessages).keys()).map(idx =>
          t('loading-bar.still-loading-' + idx)
        );
        const answerMessages = Array.from(Array(numberOfAnswerMessages).keys()).map(idx =>
          t('loading-bar.still-loading-answer-' + idx)
        );
        const messagesToChooseFrom = isAnswerToQuestion ? [...generalMessages, ...answerMessages] : generalMessages;

        if (secondsPassed % 10 === 0) {
          // take a random one
          return messagesToChooseFrom[Math.floor(Math.random() * messagesToChooseFrom.length)];
        } else {
          return oldMessage;
        }
      } else {
        return oldMessage;
      }
    });
  }, [secondsPassed]);

  // if the container is too small, we don't show the skeleton
  // if it is an answer to a question, we assume we have enough space until we get more information
  const heightNeeded = 255;
  const skeleton = (containerHeight >= heightNeeded || (!containerHeight && isAnswerToQuestion)) && (
    <>
      <div className={styles.line}>
        <NoAnimationSkeleton variant="circle" width="45%" height="100%" />
        <NoAnimationSkeleton variant="rect" width="45%" height="100%" />
      </div>
      <div className={styles.line}>
        <NoAnimationSkeleton variant="rect" width="100%" height="70%" />
      </div>
    </>
  );

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        {skeleton}
        <div className={styles.loading}>
          <BorderLinearProgress variant="determinate" thickness={10} value={progress} />
          <div className={styles.text}>{loadingMessage}</div>
        </div>
      </div>
    </>
  );
};

export default withTranslation('veezoo')(ContentLoader);
