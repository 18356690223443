import React from 'react';
import { CustomersOfTheDayDropdown } from 'components/customers/CustomersOfTheDayDropdown';
import { entryPointTypes } from 'config/constants';
import CustomerSelectionSidebarListItem from 'components/sidebar/selection/CustomerSelectionSidebarListItem';

import styles from 'components/sidebar/sidebar.scss';

export default function CustomerSelectionSidebarList(props) {
  const {
    sidebarCustomerSelections,
    isEnabledCustomersOfTheDay,
    shouldHighlightCoD,
    defaultEntryPoint,
    handleCustomersOfTheDay,
    handleCustomerSelections,
    t,
    dispatch,
    handleCustomerSelectionRoute
  } = props;

  return (
    <div className={styles.customerSelections} data-overview-tutorial="customer-selections">
      <h3 className={styles.title}>
        <span className={styles.action} onClick={handleCustomerSelectionRoute}>
          {t('customer-selections.title')}
        </span>
      </h3>

      <ul className={styles.list}>
        {isEnabledCustomersOfTheDay && (
          <li className={`${styles.item} ${styles.defaultListPadding}`} key="customersOfTheDay">
            <span className={styles.itemAction} data-overview-tutorial="customers-of-the-day">
              <span className="icon-star">&nbsp;</span>
              {shouldHighlightCoD && (
                <span className={styles.badge} data-test="codHighlight">
                  <span className={styles.dot} />
                </span>
              )}

              <div onClick={handleCustomersOfTheDay} className={styles.nameWrap}>
                <span
                  title={t('customers-of-the-day.title-short')}
                  data-test="codSidebarListing"
                  className={styles.name}
                >
                  {t('customers-of-the-day.title-short')}
                </span>
              </div>

              <CustomersOfTheDayDropdown
                t={t}
                dispatch={dispatch}
                activeEntryPoint={
                  defaultEntryPoint && defaultEntryPoint.entrypoint === entryPointTypes.CUSTOMERS_OF_THE_DAY
                }
              />
            </span>
          </li>
        )}

        {sidebarCustomerSelections.length > 0 &&
          sidebarCustomerSelections.map(selection => {
            return (
              <CustomerSelectionSidebarListItem
                key={selection.id}
                selection={selection}
                defaultEntryPoint={defaultEntryPoint}
                handleCustomerSelections={handleCustomerSelections}
                t={t}
                dispatch={dispatch}
              />
            );
          })}
      </ul>
    </div>
  );
}
