import { PureComponent, Fragment } from 'react';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { boundMethod } from 'autobind-decorator';

import { completeWhatCanIAskTutorial, finishedTutorial } from 'store/modules/tutorial';

import Title from 'components/tutorial/Title';

import { withTranslation } from 'react-i18next';
import { tutorialNames } from 'config/constants';
import { veezooRoutes } from 'components/app/routes';
import { withRouter } from 'react-router-dom';

const propTypes = {
  generalOptions: PropTypes.object,
  runWhatCanIAskTutorial: PropTypes.bool,
  location: PropTypes.object.isRequired
};
const defaultProps = {
  generalOptions: {}
};

const tutorialId = tutorialNames.WHAT_CAN_I_ASK_TUTORIAL;

export class WhatCanIAskTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tourCounter: 0,
      stepIndex: 0 // a controlled tour
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type, index } = tour;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // update state to advance the tour
      this.setState({
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
      });
    }

    if (type === EVENTS.TOUR_END || type === EVENTS.TOOLTIP_CLOSE) {
      // update user preferences with completed tour flag
      this.props.dispatch(completeWhatCanIAskTutorial());
      this.setState({
        tourCounter: this.state.tourCounter + 1,
        stepIndex: 0
      });
    }

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
    }
  }

  render() {
    const { tourCounter, stepIndex } = this.state;
    const { t, generalOptions, runWhatCanIAskTutorial, location } = this.props;
    const isInChat = location.pathname === veezooRoutes.chat;
    const run = isInChat && runWhatCanIAskTutorial;

    const steps = [
      {
        target: '[data-whatcaniask-tutorial="discovery-tab"]',
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'right',
        title: <Title size="large" text={t('tutorial.what-can-i-ask.discovery-title')} icon={<span>&#128075;</span>} />,
        content: t('tutorial.what-can-i-ask.discovery')
      },
      {
        target: '[data-whatcaniask-tutorial="message-composer"]',
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'top-start',
        title: <Title size="large" text={t('tutorial.first-access.1-title')} icon={<span>&#128075;</span>} />,
        content: t('tutorial.first-access.1')
      }
    ];

    return (
      <Fragment>
        <Joyride
          {...generalOptions}
          ref="joyride"
          key={tourCounter}
          steps={steps}
          run={run}
          callback={this.callback}
          stepIndex={stepIndex}
        />
      </Fragment>
    );
  }
}

WhatCanIAskTutorial.propTypes = propTypes;
WhatCanIAskTutorial.defaultProps = defaultProps;

export function mapStateToProps(state) {
  return {
    runWhatCanIAskTutorial: state.tutorial.runWhatCanIAskTutorial
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(WhatCanIAskTutorial)));
