import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import TimeIcon from 'svg/v2/clock-icon.svg';
import { getTimePickerTheme, getTimePickerStyles } from './styles';
import { isValidDate } from 'utils/datetimeUtils';

const Timepicker = ({ value, onChange, onEnterPress, fullWidth, error, title }) => {
  const timePickerClasses = getTimePickerStyles();

  const theme = () => getTimePickerTheme({ fullWidth });

  const handleKeyPress = event => (event.key === 'Enter' ? onEnterPress() : null);

  const hasError = useMemo(() => value && !isValidDate(value), [value]);

  return (
    <ThemeProvider theme={theme}>
      <KeyboardTimePicker
        classes={{ root: timePickerClasses.root }}
        autoOk
        ampm={false}
        error={hasError || error}
        inputVariant="outlined"
        variant="inline"
        onKeyPress={handleKeyPress}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{ 'aria-label': 'change time' }}
        data-test="keyboard-time-picker"
        placeholder="08:00"
        views={['hours', 'minutes']}
        format="HH:mm"
        inputProps={{ title }}
        keyboardIcon={<TimeIcon className={timePickerClasses.icon} />}
        mask="__:__"
      />
    </ThemeProvider>
  );
};

export default Timepicker;

Timepicker.propTypes = {
  value: PropTypes.any,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  t: PropTypes.func,
  onEnterPress: PropTypes.func,
  title: PropTypes.string
};

Timepicker.defaultProps = {
  title: 'Time field'
};
