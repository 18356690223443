import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import UITextField from '@material-ui/core/TextField';
import UIInputAdornment from '@material-ui/core/InputAdornment';

import { useSignupStyles, useVeezooStyles, useDefaultStyles } from './TextField.styles';

// TODO: When InfoTooltip is refactored to version 3, we should replace this import.
import InfoTooltip from 'components/tooltips/InfoTooltip';

import useMediaQuery from 'utils/mediaQueries';
import { layouts } from 'config/constants';

const TextField = forwardRef(
  (
    {
      name,
      startIcon,
      endIcon,
      label,
      description,
      value,
      onChange,
      disabledWhenFilled,
      disabled,
      highlighted, // font gets a light-blue color, we use it on Signup when KG already exists, giving a blue color to the KG name.
      layout,
      inline,
      shrink,
      required,
      tooltip,
      placeholder,
      multiline,
      title,
      customClasses,
      ...props
    },
    ref
  ) => {
    const customProps = useMemo(() => {
      const fields = {
        useStyles: useDefaultStyles,
        variant: 'outlined',
        margin: 'dense',
        autoComplete: ''
      };

      if (layout === layouts.signup) {
        fields.useStyles = useSignupStyles;
        fields.variant = 'standard';
        fields.margin = 'none';
        fields.autoComplete = 'new-password';
      }

      if (layout === layouts.veezoo) {
        fields.useStyles = useVeezooStyles;
        fields.variant = 'outlined';
        fields.margin = 'none';
        fields.autoComplete = 'new-password';
      }

      return fields;
    }, [layout, useSignupStyles, useVeezooStyles, useDefaultStyles]);

    const shouldShrink = useMemo(() => !!placeholder || !!tooltip || shrink, [
      placeholder,
      tooltip,
      layout,
      shrink,
      label
    ]);

    const iconFontSize = useMemo(() => {
      if (layout === layouts.signup) return '1.35rem';
      return '0.85rem';
    }, [layout]);

    const buildHelperText = useMemo(() => {
      const text = description ? <span>{description}</span> : <></>;
      const space = description && tooltip ? <>&nbsp;</> : '';
      const infoTooltip = tooltip ? (
        <InfoTooltip layout={layout} text={tooltip} size={iconFontSize} clickableWhenMobile={isMobile} />
      ) : (
        <></>
      );
      return (
        <>
          {infoTooltip}
          {space}
          {text}
        </>
      );
    }, [isMobile, layout, description, tooltip, iconFontSize]);

    const isMobile = useMediaQuery();
    const classes = customProps.useStyles({
      isMobile,
      disabled,
      disabledWhenFilled,
      multiline,
      inline,
      highlighted,
      tooltip,
      customClasses
    });

    const setAdornment = (icon, position) => <UIInputAdornment position={position}>{icon}</UIInputAdornment>;

    return (
      <UITextField
        {...(label
          ? {
              label: <>{`${label} ${required ? '*' : ''}`}</>
            }
          : {})}
        classes={{ root: classes.root }}
        fullWidth={!inline}
        InputProps={{
          startAdornment: !multiline && startIcon ? setAdornment(startIcon, 'start') : null,
          endAdornment: !multiline && endIcon ? setAdornment(endIcon, 'end') : null,
          classes: {
            root: classes.inputRoot,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
            underline: classes.inputUnderline,
            disabled: classes.disabled
          }
        }}
        name={name}
        disabled={disabled || disabledWhenFilled}
        variant={customProps.variant}
        margin={customProps.margin}
        multiline={multiline}
        inputProps={{ ref, title }}
        InputLabelProps={{
          classes: { root: classes.labelRoot, shrink: classes.shrink },
          ...(shouldShrink ? { shrink: shouldShrink } : {})
        }}
        FormHelperTextProps={{
          classes: { root: classes.formHelperTextRoot, contained: classes.formHelperTextContained }
        }}
        autoComplete={customProps.autoComplete}
        {...(description || tooltip ? { helperText: buildHelperText } : {})}
        {...(value === undefined ? {} : { value })}
        {...(onChange === undefined ? {} : { onChange })}
        {...(placeholder ? { placeholder } : {})}
        {...props}
      />
    );
  }
);

export default TextField;

TextField.propTypes = {
  name: PropTypes.string,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabledWhenFilled: PropTypes.bool,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  inline: PropTypes.bool,
  shrink: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  customClasses: PropTypes.object
};

TextField.defaultProps = {
  name: 'textfield',
  startIcon: null,
  endIcon: null,
  label: null,
  description: '',
  value: undefined,
  onChange: undefined,
  disabledWhenFilled: false,
  disabled: false,
  highlighted: false,
  layout: layouts.default,
  inline: false,
  shrink: false,
  required: false,
  multiline: false,
  tooltip: null,
  placeholder: null,
  title: 'Text Field',
  customClasses: {}
};
