import { studioURL } from 'config/baseURL';
import { get, post } from './utils.js';

const getDatabaseSystems = () => get(`${studioURL}database/systems`);

const getDatabaseConnection = id => get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/database`);

const getDatabaseSchemas = id => get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/database/schemas`);

const getDatabaseTables = (id, schemaName) => {
  if (schemaName) {
    return get(
      `${studioURL}knowledge-graphs/${encodeURIComponent(id)}/database/schemas/${encodeURIComponent(schemaName)}/tables`
    );
  } else {
    return get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/database/tables`);
  }
};

const getImportConfiguration = () => get(`${studioURL}import/configuration`);

const getPublicKey = () => get(`${studioURL}database/ssh-tunnel-pk`);

const testDatabaseConnectionForNewKg = body => post(`${studioURL}database/test`, body);

const testDatabaseConnectionForExistingKg = (id, body) =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/database/test`, body);

const getDatabaseColumns = (id, schemaName, name) => {
  if (schemaName) {
    return get(
      `${studioURL}knowledge-graphs/${encodeURIComponent(id)}/database/schemas/${encodeURIComponent(
        schemaName
      )}/tables/${encodeURIComponent(name)}/columns`
    );
  } else {
    return get(
      `${studioURL}knowledge-graphs/${encodeURIComponent(id)}/database/tables/${encodeURIComponent(name)}/columns`
    );
  }
};

export default {
  getDatabaseSystems,
  getDatabaseConnection,
  testDatabaseConnectionForExistingKg,
  testDatabaseConnectionForNewKg,
  getDatabaseSchemas,
  getDatabaseTables,
  getImportConfiguration,
  getPublicKey,
  getDatabaseColumns
};
