import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from 'components/buttons/Button';
import { parseSuggestionWithStyles } from 'utils/suggestionParser';

import LoaderIcon from 'svg/loader.svg';

import styles from './question-pills.scss';

const QuestionPill = ({ additionalClassName, suggestion, loading, onClick, iconClassName, t }) => (
  <Button
    type="button"
    className={`${styles.pill} ${!loading ? styles.clickablePill : ''} ${additionalClassName || ''}`}
    value={suggestion}
    data-test="questionPill"
    onClick={onClick}
  >
    {iconClassName && <span className={iconClassName} />}
    <div className={`text ${styles.textContainer} ${loading ? styles.textHidden : ''}`}>
      {loading && (
        <div className={styles.loaderContainer}>
          <LoaderIcon className={styles.loading} />
        </div>
      )}
      {parseSuggestionWithStyles(suggestion)}
    </div>
  </Button>
);

export default withTranslation('veezoo')(QuestionPill);

QuestionPill.propTypes = {
  loading: PropTypes.bool,
  noParsing: PropTypes.bool,
  t: PropTypes.func,
  additionalClassName: PropTypes.string, // additional styling for the pill apart from the standard pill design
  iconClassName: PropTypes.string,
  suggestion: PropTypes.object,
  onClick: PropTypes.func.isRequired
};

QuestionPill.defaultProps = {
  loading: false,
  noParsing: false
};
