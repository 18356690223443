import React, { createContext, memo, useCallback, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import PassageSelector from './PassageSelector';
import { modifyAnswer } from 'store/modules/chat-messages';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { answerModificationTypes } from 'config/constants';

// Provides state and editing popover for Q2Q title and pills, which can be put anywhere below this context
export const Q2QStateContext = createContext(undefined);

const initialOptions = {
  anchor: null,
  selectedFilter: null,
  editingPassage: null,
  selectedPassages: [],
  highlightedPill: null
};

const useStyles = makeStyles({ paper: { borderRadius: 6 } });

const getStringWithRelationFromPassages = (passages, t) => {
  let complement = '';
  passages.forEach((passage, index) => {
    if (index > 0) {
      if (index === passages.length - 1) {
        complement += ` ${t('or')} `;
      } else {
        complement += ', ';
      }
    }
    complement += passage.text;
  });
  return complement;
};

function Provider({ answerData, dispatch, username, t, children }) {
  const [options, setOptions] = useState(initialOptions);
  const [hoveredId, setHoveredId] = useState(null);

  const classes = useStyles();

  const resetOptions = () => setOptions(initialOptions);

  const value = { options, setOptions, resetOptions, hoveredId, setHoveredId, answerData };

  const onClose = () => resetOptions();

  const onConfirm = useCallback(
    async returnedPassages => {
      const { editingPassage, passages } = options;

      // Create a message to displayed to the user in the chat for the answer (as if the user asked this)
      // It takes the text for the whole filter or title (stored in passages) and
      //  replaces the modified passage with the new entities (of the form "A, B or C")
      const message = passages.reduce((acc, curr) => {
        if (curr.id !== editingPassage.id || curr.type !== editingPassage.type) return acc + curr.text;
        const editedPassageText = getStringWithRelationFromPassages(returnedPassages, t);
        return acc + (!editedPassageText && editingPassage.classText ? editingPassage.classText : editedPassageText);
      }, '');

      const payload = {
        username,
        message,
        answerData,
        id: editingPassage.id,
        linkedEntities: returnedPassages,
        modificationType: answerModificationTypes.entity.value,
        t
      };

      await dispatch(modifyAnswer(payload));
      resetOptions();
    },
    [dispatch, options, answerData, t]
  );

  return (
    <Q2QStateContext.Provider value={value}>
      {options.selectedPassages.length ? (
        <Popover
          open={!!options.anchor}
          anchorEl={options.anchor}
          classes={classes}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <PassageSelector passages={options.selectedPassages} onConfirm={onConfirm} onClose={onClose} t={t} />
        </Popover>
      ) : null}
      {children}
    </Q2QStateContext.Provider>
  );
}

Provider.propTypes = {
  username: PropTypes.string,
  answerData: PropTypes.object
};

const mapStateToProps = state => ({ username: state.user.username });

export const Q2QStateContextProvider = memo(withTranslation('veezoo')(connect(mapStateToProps)(Provider)));
