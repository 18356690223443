import { studioURL } from 'config/baseURL';
import { postFile } from './utils.js';

const oneStepImport = (id, file) => {
  let formData = new FormData();
  formData.append('file', file);
  return postFile(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/file-import/oneStepImport`, formData);
};

export default { oneStepImport };
