import services from 'services';

export const KNOWLEDGE_GRAPHS_REQUEST = 'KNOWLEDGE_GRAPHS_REQUEST';
export const KNOWLEDGE_GRAPHS_SUCCESS = 'KNOWLEDGE_GRAPHS_SUCCESS';
export const KNOWLEDGE_GRAPHS_FAILURE = 'KNOWLEDGE_GRAPHS_FAILURE';
export const SET_CURRENT_KNOWLEDGE_GRAPH = 'SET_CURRENT_KNOWLEDGE_GRAPH';

export const fetchKnowledgeGraphs = () => {
  return async dispatch => {
    dispatch({ type: KNOWLEDGE_GRAPHS_REQUEST });

    const result = await services.getKnowledgeGraphs();

    if (result.success) {
      dispatch({ type: KNOWLEDGE_GRAPHS_SUCCESS, knowledgeGraphs: result.data.data.map(kg => kg.data) });
      return result;
    }
    dispatch({ type: KNOWLEDGE_GRAPHS_FAILURE });
  };
};

export const setCurrentKnowledgeGraph = knowledgeGraph => {
  return dispatch => {
    dispatch({ type: SET_CURRENT_KNOWLEDGE_GRAPH, knowledgeGraph });
  };
};

export const knowledgeGraphs = (state = { isFetching: false, data: null }, action) => {
  switch (action.type) {
    case KNOWLEDGE_GRAPHS_REQUEST:
      return { ...state, isFetching: true };

    case KNOWLEDGE_GRAPHS_FAILURE:
      return { ...state, isFetching: false };

    case KNOWLEDGE_GRAPHS_SUCCESS:
      return { isFetching: false, data: action.knowledgeGraphs };

    default:
      return state;
  }
};

export const knowledgeGraph = (state = null, action) => {
  switch (action.type) {
    case SET_CURRENT_KNOWLEDGE_GRAPH:
      return { ...action.knowledgeGraph };

    default:
      return state;
  }
};
