import { useState, useMemo, useRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CustomModal, PanelSlider } from 'components/v3';
import ConnectedBrowseBoardsView from './BrowseBoardsView/ConnectedBrowseBoardsView';
import BoardPreview from './BrowseBoardsView/BoardPreview';

import BackArrowIcon from 'svg/back-arrow.svg';

import useStyles from './styles';

/**
 * Slider Container wraps the two views of the Browse Boards modal inside the "PanelSlider" component.
 * "PanelSlider" is responsible for the "sliding" animation between the two views.
 */
const SliderContainer = forwardRef(({ boards, publicBoard, setSelectedBoardId }, ref) => {
  return (
    <PanelSlider getSliderOptions={({ goToPrevSlide }) => (ref.current = { goToPrevSlide })}>
      <ConnectedBrowseBoardsView boards={boards} setSelectedBoardId={setSelectedBoardId} />
      <BoardPreview publicBoard={publicBoard} />
    </PanelSlider>
  );
});

const BrowseBoardsContainer = ({ open, onClose, publicBoardsList }) => {
  const newStyles = useStyles();
  const { t } = useTranslation();

  const sliderRef = useRef();

  const [selectedBoardId, setSelectedBoardId] = useState(undefined);

  const publicBoard = useMemo(
    () => selectedBoardId && publicBoardsList.find(publicBoard => publicBoard.id === selectedBoardId),
    [selectedBoardId, publicBoardsList]
  );

  const modalTitle = useMemo(() => {
    if (selectedBoardId) {
      return (
        <div className={newStyles.customTitle}>
          <div
            className={newStyles.backArrowIcon}
            onClick={() => {
              setSelectedBoardId(undefined);
              sliderRef.current.goToPrevSlide();
            }}
          >
            <BackArrowIcon />
          </div>
          <div className={newStyles.dividerDot} />
          <span>{publicBoard ? `${t('preview')}: ${publicBoard.name}` : t('browse-boards.loading-board')}</span>
        </div>
      );
    }

    return t('browse-boards.title');
  }, [publicBoard]);

  const handleClose = () => {
    setSelectedBoardId(undefined);
    onClose();
  };

  return (
    <CustomModal
      title={modalTitle}
      content={
        <SliderContainer
          publicBoard={publicBoard}
          selectedBoardId={selectedBoardId}
          setSelectedBoardId={setSelectedBoardId}
          ref={sliderRef}
        />
      }
      open={open}
      layout="veezoo"
      onClose={handleClose}
      maxWidth="lg"
      DialogTitleProps={{ classes: { root: newStyles.modalTitleRoot } }}
      DialogContentProps={{ classes: { root: newStyles.modalContentRoot } }}
      fullWidth
      hideButtons
      paperStyles={{ height: '90%', maxHeight: '655px' }}
      data-test="browseBoardsWindow"
    />
  );
};

const mapStateToProps = state => ({ publicBoardsList: state.board.publicBoardsList });

export default connect(mapStateToProps)(BrowseBoardsContainer);
