import PropTypes from 'prop-types';
import styles from './styles.scss';

const Title = ({ children, size, className, ...props }) => {
  const fontSize = size === 'small' ? styles.small : styles.medium;
  return (
    <h3 className={`${styles.title} ${fontSize} ${className}`} {...props}>
      {children}
    </h3>
  );
};

export default Title;

Title.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string
};

Title.defaultProps = {
  className: '',
  size: 'medium'
};
