import { get, post } from './utils.js';

import { studioURL } from 'config/baseURL';

const getPlans = () => get(`${studioURL}plans`);

const getCountries = () => get(`${studioURL}countries`);

const getSubscriptions = () => get(`${studioURL}subscriptions`);

const getSubscription = id => get(`${studioURL}subscriptions/${encodeURIComponent(id)}`);

const updateSubscription = (id, configuration, billingInfo, creditCard) => {
  const body = { configuration, billingInfo, creditCard };
  return post(`${studioURL}subscriptions/${encodeURIComponent(id)}`, body);
};

const updateBillingInfo = (id, billingInfo) =>
  post(`${studioURL}subscriptions/${encodeURIComponent(id)}/billing-info`, billingInfo);

const updateCreditCard = (id, creditCard) =>
  post(`${studioURL}subscriptions/${encodeURIComponent(id)}/credit-card`, creditCard);

const getSubscriptionUpdatePreview = (subscriptionId, configuration, countryCode) => {
  const params = new URLSearchParams();
  if (countryCode) {
    params.set('countryCode', countryCode);
  }
  return post(
    `${studioURL}subscriptions/${encodeURIComponent(subscriptionId)}/invoices/upcoming`,
    configuration,
    params
  );
};

const getInvoices = subscriptionId => get(`${studioURL}subscriptions/${encodeURIComponent(subscriptionId)}/invoices`);

export default {
  getPlans,
  getCountries,
  getSubscriptions,
  getSubscription,
  updateSubscription,
  updateBillingInfo,
  updateCreditCard,
  getSubscriptionUpdatePreview,
  getInvoices
};
