import _ from 'lodash';

export const findVisibleHighChartObject = messageId =>
  Highcharts.charts.find(x => x?.renderTo.id.includes(messageId) && x.renderTo.getBoundingClientRect().width > 0);

/**
 * Merges all the options applied at the various levels into one option object
 *
 * @param original (STRING) at one point in the future the backend could send visualizationOptions together with the visualization that should be applied after the baseOptions
 * @param custom (STRING) these are the options that are stored in the backend e.g. on the widget or shared answer level, i.e. custom user modifications
 * @param modifications (STRING) is an array of "modifiedVisualizations" (as in the redux state usually), i.e. modifications in the session
 * @param baseOptions (OBJECT) these are the options retrieved directly from the highcharts object after the chart was created
 * @returns {{}|*}
 */
export const mergeChartOptions = (original, custom, modifications, baseOptions) => {
  try {
    const originalOptions = original ? JSON.parse(original) : {};
    const customOptions = custom ? JSON.parse(custom) : {};
    // merge the original options with the custom options
    const mergedCustomOptions = _.merge({}, baseOptions, originalOptions, customOptions);

    // merge the merged options with the modifications from the session
    const mergedOptions = modifications.reduce((acc, cur) => {
      const parsedModifications = JSON.parse(cur.modifiedVisualizationOptions);
      // LLM generated modiciations get merged with previous ones
      if (cur.mergeWithPrevious) {
        return _.merge({}, acc, parsedModifications);
      }
      // if the modification was done via the code dialgoue it was done on the entire options
      // and basically overwriting _all_ the previous options
      return _.merge({}, parsedModifications);
    }, mergedCustomOptions);

    return mergedOptions;
  } catch (error) {
    console.error('Error merging visualization options', error);
    return {};
  }
};
