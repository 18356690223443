import { cloneElement, memo, useMemo } from 'react';

import UIToggleButton from '@material-ui/lab/ToggleButton';
import InfoTooltip from 'components/tooltips/InfoTooltip';

import useStyles from './styles';

const ToggleButton = ({ label, selected, error, onChange, value, tooltip, icon: Icon, ...props }) => {
  const styles = useStyles();

  const hasIcon = useMemo(
    () => Icon && cloneElement(Icon, { className: `${styles.icon} ${error ? styles.iconError : ''}` }),
    [Icon]
  );

  const content = useMemo(
    () => (
      <div className={styles.iconLabel}>
        {hasIcon}
        <span className={Icon && styles.labelSpan}>{label}</span>
      </div>
    ),
    [hasIcon, label]
  );

  return (
    <UIToggleButton
      onChange={onChange}
      selected={selected}
      value={value}
      classes={{ label: styles.label, root: `${styles.root} ${error ? styles.error : ''}` }}
      {...props}
    >
      {tooltip ? (
        <InfoTooltip title={tooltip} placement="top">
          {content}
        </InfoTooltip>
      ) : (
        content
      )}
    </UIToggleButton>
  );
};

export default memo(ToggleButton);
