import { studioURL } from 'config/baseURL';
import { get, patch, post } from './utils.js';

const testAnswers = kgId => post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/test`);

const getInterpretation = (kgId, id) =>
  get(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/${encodeURIComponent(id)}`);

const runInterpretation = (kgId, id, vql) => {
  const body = vql ? { vql } : null;
  return post(
    `${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/${encodeURIComponent(id)}/run`,
    body
  );
};

const updateInterpretation = (kgId, id, vql) => {
  const body = vql ? { vql } : null;
  return patch(
    `${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/${encodeURIComponent(id)}`,
    body
  );
};

export default {
  testAnswers,
  getInterpretation,
  runInterpretation,
  updateInterpretation
};
