import uuid1 from 'uuid/v1';
import debounce from 'lodash/debounce';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { boundMethod } from 'autobind-decorator';

import { editCustomerSelection, fetchCustomerSelectionAnswer } from 'store/modules/customers-of-the-day';
import { stopCustomerSelectionView } from 'store/modules/customer-selections';

import FilterPillContainer from 'components/shared/FilterPillContainer';
import Button from 'components/buttons/Button';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import CustomersOfTheDayButton from 'components/buttons/CustomersOfTheDayButton';

import styles from './customer-selections.scss';
import { Q2QStateContextProvider } from '../../shared/Q2QStateContext';

const propTypes = {
  customerSelections: PropTypes.array,
  t: PropTypes.func,
  isOpenCustomerSelectionRoute: PropTypes.bool
};

export class CustomerSelections extends Component {
  constructor(props) {
    super(props);

    // execute next handlers to fire with limit
    this.toggleSelectionFromSidebar = debounce(this.toggleSelectionFromSidebar.bind(this), 200);
    this.toggleCustomersOfTheDay = debounce(this.toggleCustomersOfTheDay.bind(this), 200);
  }

  toggleSelectionFromSidebar(selection) {
    const editRequestJson = {
      name: selection.name,
      isInCustomersOfTheDay: selection.isInCustomersOfTheDay, // this is required for patch method to send
      isInSidebar: !selection.isInSidebar
    };

    this.props.dispatch(editCustomerSelection(selection.id, editRequestJson));
  }

  toggleCustomersOfTheDay(selection) {
    const editRequestJson = {
      name: selection.name,
      isInCustomersOfTheDay: !selection.isInCustomersOfTheDay,
      isInSidebar: selection.isInSidebar
    };

    this.props.dispatch(editCustomerSelection(selection.id, editRequestJson));
  }

  @boundMethod
  openCustomerSelectionInChat(customerSelectionId) {
    if (this.props.isOpenCustomerSelectionRoute) {
      // switch from customer selection mode to /chat
      this.props.dispatch(stopCustomerSelectionView());
    }
    this.props.dispatch(fetchCustomerSelectionAnswer(customerSelectionId));
  }

  render() {
    const {
      customerSelections, // customer selection fetched already inside Sidebar - dispatch(getCustomerSelections())
      t
    } = this.props;

    return (
      <div className={styles.wrap}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>
            <span className="icon-people-group-message" />
            <span>{t('customer-selections.title')}</span>
          </h2>
          <p className={styles.subtitle}>{t('customer-selections.subtitle')}</p>
        </div>
        <ul className={styles.list}>
          {customerSelections.length > 0 &&
            customerSelections.map((selection, index) => {
              return (
                <Q2QStateContextProvider key={index}>
                  <li className={styles.item} key={uuid1() + selection.id}>
                    <div className={styles.content}>
                      <h3 className={styles.selectionName}>
                        <button
                          className={styles.selectionLink}
                          onClick={() => this.openCustomerSelectionInChat(selection.id)}
                        >
                          {selection.name}
                        </button>
                      </h3>

                      <FilterPillContainer
                        list={selection.filters && selection.filters.map(f => ({ label: f }))}
                        noDataMessage="customer-selections.no-data"
                      />

                      {!selection.isOwned && <aside className={styles.owned}>{t('customer-selections.shared')}</aside>}
                    </div>

                    <div className={styles.actions}>
                      <InfoTooltip
                        text={
                          selection.isInSidebar === true
                            ? t('tooltip.remove-customer-selection-from-sidebar')
                            : t('tooltip.add-customer-selection-to-sidebar')
                        }
                      >
                        <Button
                          titleClassName="invisibleTitle"
                          className={`${'button-secondary'} ${
                            selection.isInSidebar === true ? styles.displayMinus : styles.displayPlus
                          }`}
                          onClick={() => this.toggleSelectionFromSidebar(selection)}
                          text={
                            selection.isInSidebar === true
                              ? t('customer-selections.remove-from-sidebar')
                              : t('customer-selections.add-to-sidebar')
                          }
                        >
                          <span className="icon-toggle" />
                        </Button>
                      </InfoTooltip>

                      <CustomersOfTheDayButton
                        tooltipText={selection.isInCustomersOfTheDay}
                        titleClassName="invisibleTitle"
                        handleClick={() => this.toggleCustomersOfTheDay(selection)}
                      />
                    </div>
                  </li>
                </Q2QStateContextProvider>
              );
            })}
        </ul>
      </div>
    );
  }
}

CustomerSelections.propTypes = propTypes;

export function mapStateToProps(state) {
  return {
    customerSelections: state.customersOfTheDay.selections,
    isOpenCustomerSelectionRoute: state.customerSelections.isOpenCustomerSelectionRoute
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(CustomerSelections));
