import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import styles from 'components/sidebar/sidebar.scss';
import clsx from 'clsx';

const DiscoveryTab = ({ showDiscovery, disabled, kgMeta, sidebarIsMinimized, t }) => {
  const button = (
    <button
      className={clsx('button-secondary', showDiscovery ? styles.selected : '', styles.sidebarButton)}
      disabled={disabled}
    >
      <span className={clsx('icon-discovery', styles.opaque, disabled ? 'disabled' : '')} />
      {!sidebarIsMinimized && (
        <span className={clsx(styles.sidebarButtonLabel, styles.opaque, disabled ? 'disabled' : '')}>Discovery</span>
      )}
    </button>
  );

  if (disabled) return button;

  return (
    <Link
      data-overview-tutorial="step-4"
      className={styles.link}
      disabled={disabled}
      id="DiscoveryIconHook"
      data-whatcaniask-tutorial="discovery-tab"
      to={'/discovery' + (kgMeta?.id ? `?id=${kgMeta.id}` : '')}
    >
      <InfoTooltip text={t('tooltip.discovery')} placement="right">
        {button}
      </InfoTooltip>
    </Link>
  );
};

const mapStateToProps = state => ({ kgMeta: state.knowledgeGraphMeta.meta });

export default connect(mapStateToProps)(withTranslation('veezoo')(DiscoveryTab));
