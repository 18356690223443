import { baseURL, studioURL } from 'config/baseURL';
import { get, post, del } from './utils.js';

const getInvitations = () => get(`${studioURL}invitations`);

const getInvitation = id => get(`${baseURL}studio/api/invitations/${encodeURIComponent(id)}`);

const createInvitations = body => post(`${studioURL}invitations`, body);

const deleteInvitation = id => del(`${studioURL}invitations/${encodeURIComponent(id)}`);

export default { getInvitations, getInvitation, createInvitations, deleteInvitation };
