import { studioURL } from 'config/baseURL';
import { get } from './utils.js';

const getLanguages = () => get(`${studioURL}localization/languages`);

const getNumberFormats = () => get(`${studioURL}localization/number-formats`);

export default {
  getLanguages,
  getNumberFormats
};
