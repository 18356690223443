import React, { useState, useCallback, useEffect, memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import SidebarFooterSelect from 'components/Select/SidebarFooterSelect/SidebarFooterSelect';
import { KnowledgeGraphIcon } from 'components/Icons';

import { useTranslation } from 'react-i18next';

const KnowledgeGraphSelect = ({ knowledgeGraphs, meta, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (knowledgeGraphs && knowledgeGraphs.length && meta.id) {
      setValue(meta.id);
    }
  }, [knowledgeGraphs, meta.id]);

  const handleChange = useCallback(
    async selectedValue => {
      if (selectedValue !== value) {
        setValue(selectedValue);
        const params = new URLSearchParams(location.search);

        let newLocation = location.pathname;
        params.set('id', selectedValue);
        newLocation = newLocation + '?' + params.toString() + location.hash;
        history.push(newLocation);
      }
    },
    [value, location.pathname]
  );

  return (
    <div data-test="KnowledgeGraphSelectHook">
      <SidebarFooterSelect
        startIcon={<KnowledgeGraphIcon />}
        items={knowledgeGraphs}
        value={value}
        onChange={event => handleChange(event.target.value)}
        id="KnowledgeGraphSelectHook"
        title={t('sidebar.select-knowledge-graph')}
        {...props}
      />
    </div>
  );
};

export default memo(KnowledgeGraphSelect);
