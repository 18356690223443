import React, { PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';

import { BoltIcon } from '../Icons';
import Title from 'components/tutorial/Title';
import { finishedTutorial } from 'store/modules/tutorial';

import { withTranslation } from 'react-i18next';
import { tutorialNames } from 'config/constants';

const tutorialId = tutorialNames.SMART_ACTION_TUTORIAL;

export class SmartActionTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTutorialAction: undefined
    };
  }

  tutorialName(action) {
    return action.customTutorial ? tutorialId + '_' + action.id : tutorialId;
  }

  @boundMethod
  callback(tour) {
    const { action, type } = tour;

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      const smartAction = this.state.runTutorialAction;
      this.setState({ runTutorialAction: false });
      const tutorialName = this.tutorialName(smartAction);
      this.props.dispatch(finishedTutorial(tutorialName, isCompleted));
      if (tutorialName !== tutorialId) {
        // Set default tutorial as finished if we already ran a custom tutorial
        this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
      }
    }
  }

  componentDidUpdate(nextProps) {
    if (
      !this.state.runTutorialAction &&
      // There is a generic smart action tutorial present (Disable all smart action tutorials by removing this entry)
      this.props.tutorials &&
      this.props.tutorials.some(tut => tut.id === tutorialId)
    ) {
      const smartActions = this.props.chatMessages
        .map(m => m.answer && m.answer.actions)
        .reduce((acc, val) => acc.concat(val), []) // flatten
        .filter(a => a);

      // Either there is no tutorial defined or it is not completed by this user.
      const action = smartActions.find(
        a => !this.props.tutorials.some(tut => tut.id === this.tutorialName(a) && !tut.isUncompleted)
      );
      this.setState({ runTutorialAction: action });
    }
  }

  render() {
    const { t } = this.props;
    const smartAction = this.state.runTutorialAction;
    const id = smartAction && smartAction.id;
    const customTutorial = smartAction && smartAction.customTutorial;
    const steps = [
      {
        target: '[data-smartaction-tutorial~="' + id + '"]',
        disableBeacon: true,
        showProgress: false,
        spotlightClicks: false,
        locale: { last: t('close') },
        content: customTutorial || t('tutorial.smart-action.1-desc'),
        title: (
          <Title text={customTutorial ? smartAction.title : t('tutorial.smart-action.1-title')} icon={<BoltIcon />} />
        )
      }
    ];
    return (
      <Joyride
        {...this.props.generalOptions}
        ref="joyride"
        steps={steps}
        run={!!smartAction} // or some other boolean for when you want to start it
        callback={this.callback}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    chatMessages: state.chatMessages,
    tutorials: state.tutorial.tutorials
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(SmartActionTutorial));
