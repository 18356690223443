import { makeStyles } from '@material-ui/core/styles';

const containerHeight = { minHeight: 35, maxHeight: 70 };
const unsetPosition = { position: 'unset' };

export default makeStyles({
  root: {
    ...containerHeight,
    border: ({ error }) => `solid thin ${error ? 'red' : 'var(--light-gray)'}`,
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'unset'),
    borderRadius: 4,
    position: 'relative',
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root': {
      ...containerHeight,
      ...unsetPosition,
      padding: '3px 0 3px 3px',
      overflowY: 'auto'
    },
    '& .MuiChip-deleteIcon': {
      width: 15,
      height: 15,
      color: 'var(--primary-color)',
      opacity: '0.6'
    },
    '& .MuiTextField-root': {
      ...unsetPosition
    }
  },
  input: {
    fontSize: 12
  },
  tag: {
    margin: 3,
    height: 24,
    fontSize: 12,
    borderRadius: 15
  },
  endAdornment: {
    top: 0,
    position: 'absolute',
    bottom: 0,
    margin: 'auto',
    height: 20
  },
  popupIndicator: {
    width: '13.5px',
    height: '13.5px',
    fill: 'var(--primary-color)',
    '& > path': {
      fill: 'var(--primary-color)'
    }
  }
});
