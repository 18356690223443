import useStyles from './styles';
import { veezooUrls } from 'config/constants';
import Link from 'studio/components/Link';

const TroubleConnectingMessage = ({ blue }) => {
  const styles = useStyles();

  return (
    <div className={styles.supportMessage}>
      Having trouble connecting your data?{' '}
      <Link href={veezooUrls.slack} newWindow className={blue && styles.blue}>
        Join our Slack for support
      </Link>{' '}
      or{' '}
      <Link href={veezooUrls.contact} newWindow className={blue && styles.blue}>
        reach out to us!
      </Link>
    </div>
  );
};

export default TroubleConnectingMessage;
