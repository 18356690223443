import { memo } from 'react';
import UISkeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles.scss';

const backgroundColor = 'rgba(255, 255, 255, 0.2)';

const useStyles = makeStyles({
  title: {
    margin: '30px 0 15px',
    width: '100%',
    maxWidth: '120px',
    height: 20,
    backgroundColor
  },
  line: {
    margin: '20px 0 0 15px',
    width: '100%',
    maxWidth: '140px',
    height: 20,
    backgroundColor
  }
});

const Skeleton = memo(({ type }) => {
  const classes = useStyles();
  return <UISkeleton variant="rect" classes={{ root: classes[type] }} />;
});

export const SidebarLoader = memo(() => (
  <div className={styles.loading}>
    <Skeleton type="title" />
    <Skeleton type="line" />
    <Skeleton type="line" />
    <Skeleton type="line" />
    <Skeleton type="title" />
    <Skeleton type="line" />
    <Skeleton type="line" />
    <Skeleton type="line" />
    <Skeleton type="title" />
    <Skeleton type="line" />
    <Skeleton type="line" />
  </div>
));
