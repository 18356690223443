import { studioURL } from 'config/baseURL';
import { get, patch, post } from './utils.js';

const getTenantKnowledgeGraphs = id => get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/tenants`);

const createTenantKnowledgeGraph = (kgId, tenantId) =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/tenants/${encodeURIComponent(tenantId)}`);

const updateTenantKnowledgeGraph = (kgId, tenantId) =>
  patch(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/tenants/${encodeURIComponent(tenantId)}`);

export default {
  getTenantKnowledgeGraphs,
  createTenantKnowledgeGraph,
  updateTenantKnowledgeGraph
};
