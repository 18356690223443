import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ShareModal } from 'components/v3';
import GroupIcon from 'svg/people-group.svg';

import { shareBoard } from 'store/modules/board';
import { hideSharedBoardModal } from 'store/modules/sharedModal';

import { trackEvent } from 'utils/eventTracking';

import services from 'services';

const BoardShareModal = ({ open, currentUser, knowledgeGraph, currentBoard, dispatch }) => {
  const { t } = useTranslation();

  const [userList, setUserList] = useState({ users: [], loading: false });
  const [roleList, setRoleList] = useState({ roles: [], loading: false });

  const loading = useMemo(() => userList.loading || roleList.loading || currentBoard?.fetchingInfo, [
    userList,
    roleList,
    currentBoard?.fetchingInfo
  ]);

  const allUsersAndRoles = useMemo(() => [...userList.users, ...roleList.roles], [userList, roleList]);

  const sharedWithUsersAndRoles = useMemo(() => {
    if (currentBoard) {
      const userIds = currentBoard?.userIds || [];
      const roleIds = currentBoard?.roleIds || [];
      return [...userIds, ...roleIds];
    }
    return [];
  }, [currentBoard]);

  const handleConfirm = data => {
    const userIds = data.usersAndRoles.filter(selectedUser =>
      userList.users.some(user => user.value === selectedUser && user.type === 'user')
    );
    const roleIds = data.usersAndRoles.filter(selectedRole =>
      roleList.roles.some(role => role.value === selectedRole && role.type === 'role')
    );
    const payload = {
      roleIds,
      userIds,
      isSharedWithOrg: data.isSharedWithOrg,
      isEnabledByDefault: data.isEnabledByDefault
    };
    trackEvent('Board Sharing Updated', { boardId: currentBoard.id });
    dispatch(shareBoard({ data: payload, boardId: currentBoard.id }));
  };

  const handleClose = () => dispatch(hideSharedBoardModal());

  const fetchUsers = async () => {
    setUserList(prev => ({ ...prev, loading: true }));
    const response = await services.getKnowledgeGraphUsers(knowledgeGraph.id);
    if (response.success) {
      const users = response.data.data.map(user => ({ value: user.id, label: user.name, type: 'user' }));
      setUserList(prev => ({ ...prev, users }));
    }
    setUserList(prev => ({ ...prev, loading: false }));
  };

  const fetchRoles = async () => {
    setRoleList(prev => ({ ...prev, loading: true }));
    const response = await services.getRoles();
    if (response.success) {
      const roles = response.data.data.map(r => ({
        value: r.data.id,
        label: r.data.name,
        type: 'role',
        icon: GroupIcon
      }));
      setRoleList(prev => ({ ...prev, roles }));
    }
    setRoleList(prev => ({ ...prev, loading: false }));
  };

  useEffect(() => {
    if (knowledgeGraph && currentUser) {
      fetchUsers();
      if (currentUser.isSuperUser || currentUser.isAdmin) {
        fetchRoles();
      }
    }
  }, [knowledgeGraph, currentUser?.isSuperUser, currentUser?.isAdmin]);

  const title = currentBoard?.name || '';

  return (
    <ShareModal
      currentUser={currentUser}
      boardId={currentBoard?.id}
      boardSharingUrl={currentBoard?.boardSharingUrl}
      title={`${t('sharing.share') + (title ? ': ' + title : '')}`}
      allUsersAndRoles={allUsersAndRoles}
      open={open}
      sharedWithUsersAndRoles={sharedWithUsersAndRoles}
      isShared={currentBoard?.isSharedWithOthers}
      isSharedWithOrg={!!currentBoard?.isSharedWithOrg}
      isEnabledByDefault={!!currentBoard?.isEnabledByDefault}
      onConfirm={handleConfirm}
      onClose={handleClose}
      loading={loading}
      t={t}
    />
  );
};

const mapStateToProps = state => ({
  currentUser: state.user,
  knowledgeGraph: state.knowledgeGraph,
  currentBoard: state.board.boardsList.find(b => b.id === state.sharedModal.modalForId)
});

export default connect(mapStateToProps)(BoardShareModal);
