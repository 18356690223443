import { useMemo } from 'react';
import PropTypes from 'prop-types';

import UICheckbox from '@material-ui/core/Checkbox';

import { useSignupStyles, useDefaultStyles, useVeezooStyles } from './Checkbox.styles';

import useMediaQuery from 'utils/mediaQueries';

import { layouts } from 'config/constants';

const Checkbox = ({ label, checked, onChange, content, layout, disabled, size, ...props }) => {
  const useStyles = useMemo(() => {
    if (layout === layouts.signup) return useSignupStyles;
    if (layout === layouts.veezoo) return useVeezooStyles;
    return useDefaultStyles;
  }, [layout]);

  const isMobile = useMediaQuery();
  const styles = useStyles({ isMobile, size, disabled });

  const handleChange = () => !disabled && onChange(!checked);

  const formattedContent = useMemo(() => {
    if (typeof content === 'string') {
      return <span className={styles.labelFont}>{content}</span>;
    }
    return content;
  }, [content]);

  return (
    <div className={styles.container} onClick={handleChange}>
      <UICheckbox
        classes={{ root: styles.root, colorPrimary: styles.colorPrimary }}
        color="primary"
        checked={!!checked}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
      {formattedContent}
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  content: PropTypes.any,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  size: PropTypes.string
};

Checkbox.defaultProps = {
  label: '',
  checked: false,
  onChange: () => {},
  content: '',
  disabled: false,
  layout: layouts.default,
  size: 'medium'
};

Checkbox.defaultProps = {
  onChange: () => {}
};
