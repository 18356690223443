import { withTranslation } from 'react-i18next';

import styles from './answer-message-footer.scss';
import Button from '../buttons/Button';

function DiscoveryAnswerFooter(props) {
  const { callback, t } = props;

  const showInChatButton = (
    <Button className="button-secondary" onClick={callback} text={t('show-in-chat')}>
      <span className="icon-chat-grey" />
    </Button>
  );

  return (
    <div className={styles.buttonsContainer}>
      <ul className={styles.list}>
        <li className={styles.item}>{showInChatButton}</li>
      </ul>
    </div>
  );
}

export default withTranslation('veezoo')(DiscoveryAnswerFooter);
