import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import IconClose from 'svg/v2/close.svg';

import { useVeezooStyles } from './Chip.styles';

const Chip = forwardRef(
  (
    { label, onClose: closeClick, onClick: containerClick, allowHover, showTooltip, greyed, smaller, ...attributes },
    ref
  ) => {
    const styles = useVeezooStyles({ greyed, smaller });
    const onClose = event => {
      event.stopPropagation();
      closeClick();
    };

    const onClick = event => {
      event.stopPropagation();
      containerClick();
    };

    const chip = (
      <div
        ref={ref}
        className={clsx(
          styles.container,
          (allowHover || containerClick) && styles.allowHover,
          containerClick && styles.cursorPointer
        )}
        onClick={containerClick ? onClick : null}
        {...attributes}
      >
        <div className={styles.textContainer}>{label}</div>
        {closeClick && <IconClose className={styles.closeIcon} onClick={onClose} data-test="CloseChip" />}
      </div>
    );

    return showTooltip ? <InfoTooltip text={label}>{chip}</InfoTooltip> : chip;
  }
);

export default Chip;

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  allowHover: PropTypes.bool,
  greyed: PropTypes.bool,
  smaller: PropTypes.bool
};

Chip.defaultProps = {
  onClose: null,
  onClick: null,
  showTooltip: false,
  allowHover: false,
  greyed: false,
  smaller: false
};
