import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import UIAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Chip } from 'components/v3';

import ArrowIcon from 'svg/v2/chevron_down.svg';
import CloseIcon from 'svg/close2.svg';

import { useVeezooStyles } from './Autocomplete.styles';

const Autocomplete = ({
  open,
  options,
  value,
  onKeyPress,
  onChange,
  inputValue,
  onInputChange,
  renderOption,
  placeholder,
  multiple,
  limitTags,
  disabled,
  title,
  'data-test': dataTest,
  noOptionsText
}) => {
  const classes = useVeezooStyles();

  const selectedValues = useMemo(() => {
    const newValues = multiple
      ? options.filter(option => value.includes(option.value))
      : options.find(option => option.value === value);
    return newValues;
  }, [value, options, multiple]);

  const handleChange = useCallback(
    (event, oldValue) => {
      const newValue = multiple ? oldValue.map(option => option.value) : oldValue && oldValue.value;
      return onChange(event, newValue);
    },
    [onChange, multiple]
  );

  return (
    <div className={classes.container} data-test={dataTest}>
      <UIAutocomplete
        multiple={multiple}
        limitTags={limitTags}
        options={options}
        noOptionsText={noOptionsText}
        value={selectedValues || (multiple ? [] : null)}
        onChange={handleChange}
        classes={{
          popupIndicator: classes.popupIndicator,
          clearIndicator: classes.clearIndicator,
          endAdornment: classes.endAdornment,
          inputRoot: classes.inputRoot,
          input: classes.input,
          option: classes.option
        }}
        getOptionLabel={option => option.label}
        popupIcon={<ArrowIcon />}
        closeIcon={<CloseIcon />}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const propsValue = getTagProps({ index });
            return (
              <div key={`${propsValue['data-tag-index']}_${index}`} style={{ margin: 3 }}>
                <Chip label={option.label} onClose={propsValue.onDelete} greyed smaller />
              </div>
            );
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: classes.notchedOutline,
                underline: classes.inputUnderline,
                disabled: classes.disabled
              }
            }}
            inputProps={{ ...params.inputProps, title }}
            variant="outlined"
            placeholder={placeholder}
          />
        )}
        disabled={disabled}
        {...(open && { open })}
        {...(onInputChange && { inputValue, onInputChange })}
        {...(onKeyPress && { onKeyPress })}
        {...(renderOption && { renderOption })}
      />
    </div>
  );
};

export default Autocomplete;

Autocomplete.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  renderOption: PropTypes.func,
  limitTags: PropTypes.number,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  'data-test': PropTypes.string,
  noOptionsText: PropTypes.string,
  title: PropTypes.string
};

Autocomplete.defaultProps = {
  open: null,
  options: [],
  value: null,
  placeholder: 'Type...',
  onKeyPress: () => {},
  multiple: false,
  limitTags: 1,
  disabled: false,
  noOptionsText: 'No options',
  title: 'autocomplete'
};
