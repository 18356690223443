import LoaderIcon from 'svg/loader.svg';
import PropTypes from 'prop-types';

import useStyles from './styles';

const LoadingCircle = ({ size, color }) => {
  const styles = useStyles({ size, color });
  return <LoaderIcon className={styles.loading} />;
};

export default LoadingCircle;

LoadingCircle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string
};

LoadingCircle.defaultProps = {
  size: 18,
  color: 'var(--button-secondary-color)'
};
