import { post } from './utils';
import { baseURL } from '../config/baseURL';

const modifyVisualization = ({
  partialAnswerId,
  visualizationType,
  inputText,
  theme,
  currentVisualizationOptions,
  previousInputTexts
}) => {
  const fullPayload = {
    partialAnswerId,
    visualizationType,
    inputText,
    theme,
    currentVisualizationOptions,
    previousInputTexts,
    sessionId: window.veezoo.sessionId
  };
  return post(`${baseURL}answers/partials/${partialAnswerId}/visualizations/edit`, JSON.stringify(fullPayload));
};

export default { modifyVisualization };
