import Logo from './../logo/Logo';

import styles from './loading-message.scss';
import messageStyles from './message.scss';

export default function LoadingMessage(props) {
  const loadingMessage = (
    <div className={styles.loadingDots}>
      <span className={styles.dot}>.</span>
      <span className={styles.dot}>.</span>
      <span className={styles.dot}>.</span>
    </div>
  );

  const icon = (
    <div className={`${styles.icon} ${messageStyles.hideOnSmallScreens}`}>
      <Logo displayShortVersion />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        {icon}
        <div className={styles.baseMessage}>{loadingMessage}</div>
      </div>
    </div>
  );
}
