import { useMemo } from 'react';
import { Button } from 'components/v3';

import { useVeezooStyles, useDefaultStyles, useSignupStyles } from './ToggleButtonList.styles';
import { layouts } from 'config/constants';

const ToggleButtonList = ({ direction, justifyContent, layout, items, selectedValue, setSelectedValue }) => {
  const useStyles = useMemo(() => {
    if (layout === layouts.veezoo) return useVeezooStyles;
    if (layout === layouts.signup) return useSignupStyles;
    return useDefaultStyles;
  }, [layout]);

  const styles = useStyles({ direction, justifyContent });

  const handleClick = value => setSelectedValue(value);

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <div key={`item_${index}`}>
          <Button
            layout={layout}
            icon={item.icon}
            iconSize={item.iconSize}
            mode="light"
            isToggleButton
            selected={selectedValue === item.value}
            onClick={() => handleClick(item.value)}
          >
            {item.label}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default ToggleButtonList;
