import uuid1 from 'uuid/v1';

import { handleError } from 'services/utils';

import services from 'services';

import {
  addIncomingMessage,
  CHATMESSAGE_FAILURE,
  CHATMESSAGE_REQUEST,
  CHATMESSAGE_SUCCESS,
  messageTypes
} from './chat-messages';
import { hideSharedCustomerSelectionModal } from 'store/modules/sharedModal';
import { PENDING_STATUS, ACCEPTED_STATUS, REJECTED_STATUS } from 'config/constants';
import { KNOWLEDGE_GRAPH_META_SUCCESS } from './graph/knowledgeGraphMeta';

export const CUSTOMERS_OF_THE_DAY_REQUEST = 'CUSTOMERS_OF_THE_DAY_REQUEST';
export const CUSTOMERS_OF_THE_DAY_FAILURE = 'CUSTOMERS_OF_THE_DAY_FAILURE';
export const CUSTOMERS_OF_THE_DAY_SUCCESS = 'CUSTOMERS_OF_THE_DAY_SUCCESS';

export const CUSTOMERS_OF_THE_DAY_EXTEND_REQUEST = 'CUSTOMERS_OF_THE_DAY_EXTEND_REQUEST';
export const CUSTOMERS_OF_THE_DAY_EXTEND_SUCCESS = 'CUSTOMERS_OF_THE_DAY_EXTEND_SUCCESS';
export const CUSTOMERS_OF_THE_DAY_EXTEND_FAILURE = 'CUSTOMERS_OF_THE_DAY_EXTEND_FAILURE';

export const GET_SIMILAR_CUSTOMER_FAILURE = 'GET_SIMILAR_CUSTOMER_FAILURE';

export const CUSTOMER_SELECTIONS_REQUEST = 'CUSTOMER_SELECTIONS_REQUEST';
export const CUSTOMER_SELECTIONS_SUCCESS = 'CUSTOMER_SELECTIONS_SUCCESS';
export const CUSTOMER_SELECTIONS_FAILURE = 'CUSTOMER_SELECTIONS_FAILURE';

export const CUSTOMER_SELECTION_ANSWER_REQUEST = 'CUSTOMER_SELECTION_ANSWER_REQUEST';
export const CUSTOMER_SELECTION_ANSWER_SUCCESS = 'CUSTOMER_SELECTION_ANSWER_SUCCESS';
export const CUSTOMER_SELECTION_ANSWER_FAILURE = 'CUSTOMER_SELECTION_ANSWER_FAILURE';

export const SAVE_CUSTOMER_SELECTIONS_REQUEST = 'SAVE_CUSTOMER_SELECTIONS_REQUEST';
export const SAVE_CUSTOMER_SELECTIONS_SUCCESS = 'SAVE_CUSTOMER_SELECTIONS_SUCCESS';
export const SAVE_CUSTOMER_SELECTIONS_FAILURE = 'SAVE_CUSTOMER_SELECTIONS_FAILURE';

export const EDIT_CUSTOMER_SELECTIONS_REQUEST = 'EDIT_CUSTOMER_SELECTIONS_REQUEST';
export const EDIT_CUSTOMER_SELECTIONS_SUCCESS = 'EDIT_CUSTOMER_SELECTIONS_SUCCESS';
export const EDIT_CUSTOMER_SELECTIONS_FAILURE = 'EDIT_CUSTOMER_SELECTIONS_FAILURE';

export const REMOVE_CUSTOMER_SELECTIONS_REQUEST = 'REMOVE_CUSTOMER_SELECTIONS_REQUEST';
export const REMOVE_CUSTOMER_SELECTIONS_SUCCESS = 'REMOVE_CUSTOMER_SELECTIONS_SUCCESS';
export const REMOVE_CUSTOMER_SELECTIONS_FAILURE = 'REMOVE_CUSTOMER_SELECTIONS_FAILURE';

export const SHARED_OPTIONS_CUSTOMER_SELECTION_REQUEST = 'SHARED_OPTIONS_CUSTOMER_SELECTION_REQUEST';
export const SHARED_OPTIONS_CUSTOMER_SELECTION_SUCCESS = 'SHARED_OPTIONS_CUSTOMER_SELECTION_SUCCESS';
export const SHARED_OPTIONS_CUSTOMER_SELECTION_FAILURE = 'SHARED_OPTIONS_CUSTOMER_SELECTION_FAILURE';

export const STOP_SHARING_CUSTOMER_SELECTION_REQUEST = 'STOP_SHARING_CUSTOMER_SELECTION_REQUEST';
export const STOP_SHARING_CUSTOMER_SELECTION_SUCCESS = 'STOP_SHARING_CUSTOMER_SELECTION_SUCCESS';
export const STOP_SHARING_CUSTOMER_SELECTION_FAILURE = 'STOP_SHARING_CUSTOMER_SELECTION_FAILURE';

export const CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_REQUEST = 'CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_REQUEST';
export const CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_SUCCESS = 'CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_SUCCESS';
export const CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_FAILURE = 'CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_FAILURE';

export const TOGGLE_CUSTOMER_SELECTION_VIEW = 'TOGGLE_CUSTOMER_SELECTION_VIEW';
export const STOP_CUSTOMER_SELECTION_VIEW = 'STOP_CUSTOMER_SELECTION_VIEW';

export const fetchCustomersOfTheDay = () => {
  const requestId = uuid1(); // uniqueId for customers of the day

  return async dispatch => {
    dispatch({
      type: CHATMESSAGE_REQUEST,
      isCustomersOfTheDay: true,
      canReplaceLastMessage: true,
      requestId
    });

    dispatch({
      type: CUSTOMERS_OF_THE_DAY_REQUEST,
      isAlreadyFetchingCustomersOfTheDay: true
    });

    const result = await services.getCustomersOfTheDay();
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({
        type: CUSTOMERS_OF_THE_DAY_FAILURE,
        isAlreadyFetchingCustomersOfTheDay: false
      });
      const errorMessage = result?.response?.data || 'There was an error fetching widget';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({ type: CUSTOMERS_OF_THE_DAY_SUCCESS });
    dispatch({
      type: CHATMESSAGE_SUCCESS,
      message: {
        type: messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_MESSAGE,
        customersList: result.data,
        id: uuid1()
      },
      requestId
    });
  };
};

export const extendCustomersOfTheDay = () => {
  return async dispatch => {
    dispatch({
      type: CUSTOMERS_OF_THE_DAY_EXTEND_REQUEST
    });

    const result = await services.extendCustomersOfTheDay();
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: CUSTOMERS_OF_THE_DAY_EXTEND_FAILURE });
      const errorMessage = result?.response?.data || 'There was an error extending customers of the day';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({
      type: CUSTOMERS_OF_THE_DAY_EXTEND_SUCCESS,
      extendCustomersList: result.data
    });
  };
};

// get shared options: mode and roles for board
// this method is used when the user clicks on Find Similar in Customers of the Day
export function getSimilarCustomer(customerSelectionId) {
  return dispatch => {
    dispatch({
      type: CHATMESSAGE_REQUEST,
      requestId: customerSelectionId,
      customerSelectionId: customerSelectionId,
      canReplaceLastMessage: false
    });

    dispatch(fetchCustomerSelectionAnswer(customerSelectionId));
  };
}

export const fetchCustomerSelectionAnswer = customerSelectionId => {
  return async dispatch => {
    dispatch({
      type: CUSTOMER_SELECTION_ANSWER_REQUEST,
      customerSelectionId
    });

    dispatch({
      type: CHATMESSAGE_REQUEST,
      requestId: customerSelectionId,
      customerSelectionId,
      canReplaceLastMessage: true
    });

    const result = await services.getCustomerSelectionById(customerSelectionId);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({
        type: CHATMESSAGE_FAILURE,
        requestId: customerSelectionId
      });
      dispatch({
        type: GET_SIMILAR_CUSTOMER_FAILURE
      });
      dispatch({
        type: CUSTOMER_SELECTION_ANSWER_FAILURE,
        customerSelectionId
      });
      const errorMessage = result?.response?.data || 'There was an error fetching customer selection answer';
      console.log('ERROR', errorMessage);
      return;
    }

    let data = result.data;

    // add customer selection id to the message
    data.customerSelectionId = customerSelectionId;

    dispatch({
      type: CUSTOMER_SELECTION_ANSWER_SUCCESS,
      customerSelectionId
    });

    // for other messages like info (usually errors), don't change the type of the message,
    // so we can properly display it
    // we want to change the message type here so that we can display a different style with footer
    if (data.type === messageTypes.VEEZOO_ANSWER_MESSAGE) {
      data.type = messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE;
    }

    dispatch(addIncomingMessage(data, customerSelectionId, customerSelectionId));
  };
};

// define customer selection action creator
export function getCustomerSelections() {
  return async dispatch => {
    dispatch({ type: CUSTOMER_SELECTIONS_REQUEST });

    const result = await services.getCustomerSelections();
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: CUSTOMER_SELECTIONS_FAILURE });
      const errorMessage = result?.response?.data || 'There was an error fetching customer selections';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({
      type: CUSTOMER_SELECTIONS_SUCCESS,
      customerSelections: result.data
    });
  };
}

// TODO: move customer selections
export function saveCustomerSelection(data) {
  return async dispatch => {
    dispatch({
      type: SAVE_CUSTOMER_SELECTIONS_REQUEST,
      payload: data
    });

    const result = await services.saveCustomerSelection(data);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: SAVE_CUSTOMER_SELECTIONS_FAILURE });
      const errorMessage = result?.response?.data || 'There was an error saving customer selections';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({
      type: SAVE_CUSTOMER_SELECTIONS_SUCCESS,
      payload: result.data
    });
  };
}

export const removeCustomerSelection = id => {
  return async dispatch => {
    dispatch({
      type: REMOVE_CUSTOMER_SELECTIONS_REQUEST,
      boardId: id
    });

    const result = await services.deleteCustomerSelectionById(id);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({
        type: REMOVE_CUSTOMER_SELECTIONS_FAILURE,
        boardId: id
      });
      const errorMessage = result?.response?.data || 'There was an error deleting customer selections';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({
      type: REMOVE_CUSTOMER_SELECTIONS_SUCCESS,
      id
    });
  };
};

// TODO: move customer selections
export function editCustomerSelection(customerSelectionId, data) {
  return async dispatch => {
    dispatch({
      type: EDIT_CUSTOMER_SELECTIONS_REQUEST,
      payload: data
    });

    const result = await services.editCustomerSelection(customerSelectionId, data);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: EDIT_CUSTOMER_SELECTIONS_FAILURE });
      const errorMessage = result?.response?.data || 'There was an error editing customer selections';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({
      type: EDIT_CUSTOMER_SELECTIONS_SUCCESS,
      payload: { ...data, id: customerSelectionId }
    });
  };
}

export function getCustomerSelectionSharedInfo(customerSelectionId) {
  return async dispatch => {
    dispatch({
      type: SHARED_OPTIONS_CUSTOMER_SELECTION_REQUEST,
      id: customerSelectionId
    });

    const result = await services.getSharedCustomerSelectionInfo(customerSelectionId);
    handleError(result, dispatch);
    if (!result.success) {
      return dispatch({
        type: SHARED_OPTIONS_CUSTOMER_SELECTION_FAILURE,
        id: customerSelectionId
      });
    }

    dispatch({
      type: SHARED_OPTIONS_CUSTOMER_SELECTION_SUCCESS,
      customerSelection: result.data,
      customerSelectionId
    });
  };
}

export function saveSharedCustomerSelection(data) {
  return async dispatch => {
    const { shareableObject, roles, users } = data;
    dispatch({
      type: SHARED_OPTIONS_CUSTOMER_SELECTION_REQUEST,
      id: shareableObject.id
    });

    const saveEndpoint = shareableObject.isShared
      ? services.saveSharedCustomerSelections
      : services.updateSharedCustomerSelections;

    const body = {
      customerSelectionId: shareableObject.id,
      roles,
      userIds: users
    };

    const result = await saveEndpoint(shareableObject.id, body);

    handleError(result, dispatch);
    if (!result.success) {
      dispatch({
        type: SHARED_OPTIONS_CUSTOMER_SELECTION_FAILURE,
        id: shareableObject.id
      });
      return;
    }

    dispatch({
      type: SHARED_OPTIONS_CUSTOMER_SELECTION_SUCCESS,
      customerSelection: result.data,
      customerSelectionId: shareableObject.id
    });
    dispatch(hideSharedCustomerSelectionModal());
  };
}

export function stopSharingCustomerSelection(customerSelectionId) {
  return async dispatch => {
    dispatch({ type: STOP_SHARING_CUSTOMER_SELECTION_REQUEST });

    const result = await services.stopSharingCustomerSelection(customerSelectionId);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: STOP_SHARING_CUSTOMER_SELECTION_FAILURE });
      const errorMessage = result?.response?.data || 'There was an error stopping sharing customer selections';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({ type: STOP_SHARING_CUSTOMER_SELECTION_SUCCESS, id: customerSelectionId });
    dispatch(hideSharedCustomerSelectionModal());
  };
}

export function acceptOrRejectSharedCustomerSelection(customerSelectionId, isAccepted) {
  return async dispatch => {
    const userSharingStatus = isAccepted ? ACCEPTED_STATUS : REJECTED_STATUS;

    dispatch({ type: CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_REQUEST, customerSelectionId, userSharingStatus });

    const result = await services.acceptOrRejectSharedCustomerSelection(customerSelectionId, { userSharingStatus });
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({
        type: CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_FAILURE,
        customerSelectionId,
        userSharingStatus: PENDING_STATUS
      });
      const errorMessage = result?.response?.data || 'There was an error updating sharing status';
      console.log('ERROR', errorMessage);
      return;
    }

    dispatch({
      type: CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_SUCCESS,
      customerSelectionId,
      userSharingStatus
    });
  };
}

function updatePropertiesOfCustomerSelection(state, customerSelectionId, properties) {
  return {
    ...state,
    selections: state.selections.map(selection => {
      if (selection.id === customerSelectionId) {
        return { ...selection, ...properties };
      }
      return selection;
    })
  };
}

// describe a customers of the day reducer
export const initCustomersOfTheDayState = {
  selections: [],
  extendCustomersList: [],
  isLoadingExtendCustomersList: false,
  errorMessage: null,
  isEnabled: false,
  shouldHighlightInSidebar: false,
  isAlreadyFetchingCustomersOfTheDay: false
};

export function customersOfTheDay(state = initCustomersOfTheDayState, action) {
  switch (action.type) {
    case KNOWLEDGE_GRAPH_META_SUCCESS:
      return {
        ...state,
        isEnabled: action.meta.isCoDEnabled,
        shouldHighlightInSidebar: !action.meta.hasCoDExecution // COD means customers of the day
      };

    case CUSTOMERS_OF_THE_DAY_REQUEST:
      return {
        ...state,
        shouldHighlightInSidebar: false,
        extendCustomersList: [],
        isAlreadyFetchingCustomersOfTheDay: true
      };

    case CUSTOMERS_OF_THE_DAY_SUCCESS:
    case CUSTOMERS_OF_THE_DAY_FAILURE:
      return {
        ...state,
        shouldHighlightInSidebar: false,
        isAlreadyFetchingCustomersOfTheDay: false
      };

    // begin customers of the day extend
    case CUSTOMERS_OF_THE_DAY_EXTEND_REQUEST:
      return {
        ...state,
        isLoadingExtendCustomersList: true
      };

    case CUSTOMERS_OF_THE_DAY_EXTEND_SUCCESS:
      return {
        ...state,
        extendCustomersList: state.extendCustomersList.concat(action.extendCustomersList),
        isLoadingExtendCustomersList: false
      };

    case CUSTOMERS_OF_THE_DAY_EXTEND_FAILURE:
      return {
        isLoadingExtendCustomersList: false
      };
    // end customers of the day extend

    case CUSTOMER_SELECTIONS_REQUEST:
      return state;

    case CUSTOMER_SELECTIONS_SUCCESS:
      return {
        ...state,
        selections: action.customerSelections
      };

    case CUSTOMER_SELECTIONS_FAILURE:
      return state;

    case SAVE_CUSTOMER_SELECTIONS_REQUEST:
      return state;

    case SAVE_CUSTOMER_SELECTIONS_SUCCESS:
      return {
        ...state,
        selections: [...state.selections, action.payload]
      };

    case SAVE_CUSTOMER_SELECTIONS_FAILURE:
      return state;

    case EDIT_CUSTOMER_SELECTIONS_REQUEST:
      return state;

    case EDIT_CUSTOMER_SELECTIONS_SUCCESS: {
      const newSelections = [...state.selections];

      // We use a simple "for" here because we need to break the loop as soon as we find the desired selection,
      // in case it has too much items.
      // We can't use "map", "forEach" or "reduce" because they don't have "break" and it will always iterate over all items.
      for (let i = 0; i < newSelections.length; i++) {
        if (newSelections[i].id == action.payload.id) {
          newSelections[i] = { ...newSelections[i], ...action.payload };
          break;
        }
      }

      return {
        ...state,
        selections: newSelections
      };
    }

    case EDIT_CUSTOMER_SELECTIONS_FAILURE:
      return state;

    case REMOVE_CUSTOMER_SELECTIONS_REQUEST:
      return state;

    case REMOVE_CUSTOMER_SELECTIONS_SUCCESS: {
      const selections = state.selections.filter(selection => selection.id !== action.id);
      return {
        ...state,
        selections
      };
    }

    case REMOVE_CUSTOMER_SELECTIONS_FAILURE:
      return state;

    case CUSTOMER_SELECTION_ANSWER_REQUEST: {
      return updatePropertiesOfCustomerSelection(state, action.customerSelectionId, {
        isAlreadyFetchingCustomerSelection: true
      });
    }

    case CUSTOMER_SELECTION_ANSWER_SUCCESS:
    case CUSTOMER_SELECTION_ANSWER_FAILURE: {
      return updatePropertiesOfCustomerSelection(state, action.customerSelectionId, {
        isAlreadyFetchingCustomerSelection: false
      });
    }

    case CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_REQUEST: {
      return updatePropertiesOfCustomerSelection(state, action.customerSelectionId, {
        userSharingStatus: action.userSharingStatus
      });
    }

    case CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_SUCCESS: {
      return updatePropertiesOfCustomerSelection(state, action.customerSelectionId, {
        userSharingStatus: action.userSharingStatus
      });
    }

    case CHANGE_CUSTOMER_SELECTION_SHARING_STATUS_FAILURE: {
      return updatePropertiesOfCustomerSelection(state, action.customerSelectionId, {
        userSharingStatus: action.userSharingStatus
      });
    }

    case SHARED_OPTIONS_CUSTOMER_SELECTION_REQUEST: {
      const newSelections = state.selections.map(selection => {
        if (selection.id === action.id) {
          return { ...selection, fetchingInfo: true };
        }
        return selection;
      });

      return {
        ...state,
        selections: newSelections
      };
    }

    case SHARED_OPTIONS_CUSTOMER_SELECTION_SUCCESS: {
      const updatedProperties = {
        roles: action.customerSelection.roles,
        userIds: action.customerSelection.userIds,
        isShared: true,
        fetchingInfo: false
      };

      return updatePropertiesOfCustomerSelection(state, action.customerSelectionId, updatedProperties);
    }

    case SHARED_OPTIONS_CUSTOMER_SELECTION_FAILURE: {
      return updatePropertiesOfCustomerSelection(state, action.id, { fetchingInfo: false });
    }

    case STOP_SHARING_CUSTOMER_SELECTION_SUCCESS: {
      return updatePropertiesOfCustomerSelection(state, action.id, {
        roles: [],
        userIds: [],
        isShared: false
      });
    }

    default:
      return state;
  }
}
