import { memo, useEffect, useState } from 'react';
import SplitPane from 'react-split-pane';
import withEmbedded from 'root/hocs/withEmbedded';
import { SIDEBAR_MIN_SIZE, SIDEBAR_EXPANDED_SIZE, MOBILE_DEVICE } from 'config/constants';
import useLocalStorageState from 'use-local-storage-state';

export const LocalStorageSidebarSizeKey = 'veezoo.sidebar.size';

export const dispatchResizeEvent = () => {
  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    var evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
  } else {
    window.dispatchEvent(new Event('resize')); // eslint-disable-line
  }
};

export const initialSidebarSizeState = () => ({
  isMinimized: window.innerWidth < MOBILE_DEVICE,
  width: window.innerWidth < MOBILE_DEVICE ? SIDEBAR_MIN_SIZE : SIDEBAR_EXPANDED_SIZE
});

const SplitSidebarView = ({ children, isEmbedded, showKgSidebar }) => {
  // for the chat there must be always as much space as at the mobile device threshold
  // which means min_chat_size = MOBILE_DEVICE - SIDEBAR_EXPANDED_SIZE
  // so the max sidebar size is max(window.innerWidth - min_chat_size, SIDEBAR_EXPANDED_SIZE)
  const maxSidebarSize = Math.max(window.innerWidth - (MOBILE_DEVICE - SIDEBAR_EXPANDED_SIZE), SIDEBAR_EXPANDED_SIZE);
  const [isResizing, setIsResizing] = useState(false);

  const [sidebarSize, setSidebarSize] = useLocalStorageState(LocalStorageSidebarSizeKey, {
    defaultValue: initialSidebarSizeState(),
    storageSync: false
  });

  // if we e.g. load from local storage state and the sidebar size constraints are not met, we need to reset the size
  if (sidebarSize.width > maxSidebarSize && !sidebarSize.isMinimized) {
    setSidebarSize(initialSidebarSizeState());
    dispatchResizeEvent();
  }

  useEffect(() => {
    if (!isResizing) {
      dispatchResizeEvent();
    }
  }, [isResizing]);

  useEffect(() => {
    // If the KG sidebar is shown and we are minimized, expand the sidebar
    if (showKgSidebar && sidebarSize.isMinimized) {
      setSidebarSize({
        isMinimized: false,
        width: SIDEBAR_EXPANDED_SIZE
      });
    }
    dispatchResizeEvent();
  }, [showKgSidebar, sidebarSize.isMinimized]);

  return (
    <SplitPane
      className={sidebarSize.isMinimized ? 'sidebarShortVersion' : ''}
      allowResize={true}
      resizerStyle={{ width: '0' }}
      pane1Style={isEmbedded && { display: 'none' }} // Hide the sidebar in embedded mode
      pane2Style={{ overflow: 'hidden' }} // See comment on VZN-6332 why this was added.
      split="vertical"
      // when showing the KG sidebar, we don't allow the sidebar to be minimized, only expanded
      minSize={showKgSidebar ? SIDEBAR_EXPANDED_SIZE : SIDEBAR_MIN_SIZE}
      maxSize={maxSidebarSize}
      size={sidebarSize.width}
      onDragStarted={() => setIsResizing(true)}
      onChange={size => {
        setSidebarSize({
          isMinimized: size < SIDEBAR_EXPANDED_SIZE,
          width: size
        });
      }}
      onDragFinished={size => {
        setSidebarSize({
          isMinimized: size < SIDEBAR_EXPANDED_SIZE,
          // snap to min size if the size is less than the expanded size
          width: size < SIDEBAR_EXPANDED_SIZE ? SIDEBAR_MIN_SIZE : size
        });
        setIsResizing(false);
      }}
    >
      {children}
    </SplitPane>
  );
};

export default withEmbedded(memo(SplitSidebarView));
