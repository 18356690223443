import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';

import ArrowIcon from 'svg/v2/chevron_down.svg';

const useStyles = makeStyles({
  root: {
    fontSize: '12px',
    '& .MuiSelect-select': {
      padding: '9px 30px 10px 9px',
      border: 'solid thin var(--light-gray)',
      borderRadius: '4px',
      height: '32px',
      boxSizing: 'border-box'
    }
  },
  selectRoot: {
    marginRight: '8px'
  },
  caption: {
    fontSize: '12px'
  },
  actions: {
    marginLeft: 0
  },
  menuItem: {
    fontSize: '12px'
  },
  selectIcon: {
    right: '-5px',
    fontSize: '12px'
  }
});

const useIconStyles = makeStyles({
  root: {
    padding: '0',
    marginLeft: '8px',
    border: 'solid thin var(--light-gray)',
    borderRadius: '4px',
    width: '30px',
    height: '30px',
    boxSizing: 'border-box'
  },
  label: {
    '& .MuiSvgIcon-root': {
      fontSize: '20px'
    }
  },
  arrowIcon: {
    top: '1px',
    bottom: '0',
    right: '10px',
    margin: 'auto',
    width: '13.5px',
    height: '13.5px',
    justifyContent: 'flex-start',
    alignItems: 'end',
    '& > path': {
      fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
    }
  }
});

const useCustomStyles = makeStyles({
  capitalize: {
    textTransform: 'capitalize'
  }
});

const toZeroBasedIndex = page => page - 1;

const toOneBasedIndex = page => page + 1;

const defaultRowsPerPage = 5;

const Pagination = ({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onChangeRowsPerPage,
  onChangePage,
  next,
  previous,
  loading,
  t
}) => {
  const classes = useStyles();
  const iconClasses = useIconStyles();
  const customClasses = useCustomStyles();

  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => {
    if (rowsPerPage) {
      setCurrentRowsPerPage(rowsPerPage);
    }
  }, [rowsPerPage]);

  return (
    <TablePagination
      component="div"
      classes={classes}
      count={count}
      SelectProps={{
        classes: { icon: iconClasses.arrowIcon },
        IconComponent: ArrowIcon,
        inputProps: { title: t('rows') }
      }}
      page={toZeroBasedIndex(page)}
      onChangePage={!loading ? (_, page) => onChangePage(toOneBasedIndex(page)) : () => {}}
      rowsPerPage={currentRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) =>
        `${t('showing')} ${from} ${t('-')} ${to} ${t('of')} ${count} ${t('total-rows')}`
      }
      labelRowsPerPage={<span className={customClasses.capitalize}>{t('rows')}:</span>}
      nextIconButtonProps={{
        classes: { root: iconClasses.root, label: iconClasses.label },
        disabled: loading || !next
      }}
      backIconButtonProps={{
        classes: { root: iconClasses.root, label: iconClasses.label },
        disabled: loading || !previous
      }}
    />
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onChangeRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  next: PropTypes.any,
  previous: PropTypes.any,
  t: PropTypes.func
};

Pagination.defaultProps = {
  t: item => item
};

export default Pagination;
