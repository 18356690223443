export const addCommaThousandSeparator = myNumber => myNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Parses a number from the chart data.
 *
 * A number from the chart data is either:
 *   - a string representing positive or negative infinity
 *   - a number (in which case no parsing is actually needed)
 *
 * For any other value, `Number.NaN` is returned.
 */
export const parseNumber = value => {
  switch (value) {
    case '∞':
      return Number.POSITIVE_INFINITY;
    case '-∞':
      return Number.NEGATIVE_INFINITY;
    default:
      try {
        // parse the value
        const number = JSON.parse(value);
        // use 'isFinite' to check if it is a number, else return NaN
        return Number.isFinite(number) ? number : Number.NaN;
      } catch (error) {
        return Number.NaN;
      }
  }
};

// caches XSLT processors for different locales
const xsltProcessors = new Map();
// empty XML document used as the context node for the XSLT transformation: create once to avoid recreating
const xmlDocument = new DOMParser().parseFromString(null, 'text/xml');
// the marker to use to detect formatting errors
const marker = '$';
// escapes double quotes to prevent breaking out of XML attribute values
const escape = string => string.replace(/["]/g, '\u201D');

/**
 * Formats the specified number according to the specified number format.
 *
 * Note: the implementation makes use of an XSL transformation to format the number. The reason for this is that the
 * 'format-number' function allows to format a number given a pattern in the syntax of Java's DecimalFormat class (see
 * https://developer.mozilla.org/en-US/docs/Web/XPath/Functions/format-number). This should maximize compatibility with
 * the formatting of numbers in the backend.
 *
 * @param number the number to format
 * @param numberFormat the number format to use
 * @return the formatted number
 */
export const formatNumber = (number, numberFormat) => {
  if (number === Number.POSITIVE_INFINITY) {
    return '∞';
  } else if (number === Number.NEGATIVE_INFINITY) {
    return '-∞';
  } else if (Number.isNaN(number) || number === null || number === undefined) {
    return '—';
  }
  let formattedNumber;
  try {
    const locale = numberFormat.data.locale;
    if (!xsltProcessors.has(locale)) {
      const { localizedPattern, groupingSeparator, decimalSeparator } = numberFormat.data;
      // prefix the localized pattern with the marker to be able to detect formatting errors
      const markedLocalizedPattern = `${marker}${localizedPattern}`;
      const escapedMarkedLocalizedPattern = escape(markedLocalizedPattern);
      const escapedGroupingSeparator = escape(groupingSeparator);
      const escapedDecimalSeparator = escape(decimalSeparator);
      // the stylesheet to use to format the number, which defines:
      //   - a custom decimal format based on the format symbols from the number format
      //   - a template that formats the number using the custom decimal format and the localized pattern from the number format
      const stylesheetDocument = new DOMParser().parseFromString(
        `<?xml version="1.0" encoding="UTF-8" ?>
        <xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
          <xsl:param name="number" />
          <xsl:param name="localizedPattern" />
          <xsl:decimal-format
            name="decimal-format"
            grouping-separator="${escapedGroupingSeparator}"
            decimal-separator="${escapedDecimalSeparator}"
          />
          <xsl:template match="/">
            <xsl:value-of select="format-number($number, $localizedPattern, 'decimal-format')" />
          </xsl:template>
        </xsl:stylesheet>`,
        'text/xml'
      );
      const xsltProcessor = new XSLTProcessor();
      xsltProcessor.importStylesheet(stylesheetDocument);
      xsltProcessors.set(locale, xsltProcessor);
      xsltProcessor.setParameter(null, 'localizedPattern', escapedMarkedLocalizedPattern);
    }

    // get the XSLT processor for the specified locale
    const xsltProcessor = xsltProcessors.get(locale);
    // set the number to format as a parameter
    xsltProcessor.setParameter(null, 'number', number);
    // apply the transformation to format the number
    const result = xsltProcessor.transformToFragment(xmlDocument, document)?.textContent;
    // check if the result starts with the marker, in which case the transformation was successful
    if (result?.startsWith(marker)) {
      // remove the marker from the result to get the formatted number
      formattedNumber = result.slice(marker.length);
    }
  } catch (error) {
    console.warn(`Error formatting number ${number}: ${error.message}`);
  }

  return (
    formattedNumber ||
    // fall back to formatting the number using the number format's locale
    number?.toLocaleString?.(numberFormat?.data?.locale) ||
    // fall back to specified number itself
    number
  );
};

// expose the 'formatNumber' function s.t. it can be called from Highcharts' 'numberFormatter' function
window.veezoo = {
  ...window.veezoo,
  formatNumber: (number, locale, localizedPattern, groupingSeparator, decimalSeparator) => {
    const numberFormat = {
      data: {
        locale,
        localizedPattern,
        groupingSeparator,
        decimalSeparator
      }
    };
    return formatNumber(number, numberFormat);
  }
};
