import uuidv4 from 'uuid';

// Generate a single global session ID that is recreated every time the page is reloaded and stays the same otherwise
if (!window.veezoo?.sessionId) {
  window.veezoo = {
    ...window.veezoo,
    sessionId: uuidv4()
  };
}

export const headersGET = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest'
};

export const headersPOST = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest'
};

export const headersFilePOST = {
  // Content-Type is not being set here so that it can be automatically set by the browser without losing the 'boundary' part.
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest'
};

export const headersPUT = {
  ...headersPOST
};

export const headersDELETE = {
  'X-Requested-With': 'XMLHttpRequest'
};

export const headersPATCH = {
  ...headersPOST,
  'X-Requested-With': 'XMLHttpRequest'
};
