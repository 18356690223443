import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Joyride, { EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { boundMethod } from 'autobind-decorator';
import { withRouter } from 'react-router-dom';

import { finishedTutorial, completeFirstAccessTutorial } from 'store/modules/tutorial';

import Title from 'components/tutorial/Title';

import { tutorialNames } from 'config/constants';
import { veezooRoutes } from 'components/app/routes';

const propTypes = {
  generalOptions: PropTypes.object,
  uncompletedTutorials: PropTypes.array,
  hasFinishedFirstAccessTutorial: PropTypes.bool,
  location: PropTypes.object.isRequired,
  isOnPremise: PropTypes.bool,
  isOpenDemoUser: PropTypes.bool
};
const defaultProps = {
  generalOptions: {}
};

const tutorialId = tutorialNames.OVERVIEW_TUTORIAL;

export class FirstAccessTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tourCounter: 0,
      stepIndex: 0
    };
  }

  @boundMethod
  callback(tour) {
    const { type, index } = tour;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + 1 });
    }
    if (type === EVENTS.TOUR_END) {
      if (this.props.uncompletedTutorials.some(tut => tut.id === tutorialId)) {
        this.props.dispatch(finishedTutorial(tutorialNames.OVERVIEW_TUTORIAL, true));
      }
      if (!this.props.hasFinishedFirstAccessTutorial) {
        this.props.dispatch(completeFirstAccessTutorial());
      }
      this.setState({ tourCounter: this.state.tourCounter + 1, stepIndex: 0 });
    }
  }

  render() {
    const { tourCounter, stepIndex } = this.state;
    const {
      t,
      generalOptions,
      uncompletedTutorials,
      isOnPremise,
      location,
      isOpenDemoUser,
      hasFinishedFirstAccessTutorial
    } = this.props;
    const uncompleted = uncompletedTutorials.some(tut => tut.id === tutorialId);

    // We don't want to run it for:
    //   - open demo users (they have a different tutorial)
    //   - those that completed the Overview tutorial (overlapping with this one)
    //   - on-premise customers (they have other types of on-boardings)
    //   - those that just ran the first access tutorial (reducer state)
    const shouldRunFirstTime = !isOpenDemoUser && uncompleted && !isOnPremise && !hasFinishedFirstAccessTutorial;

    const isInChat = location.pathname === veezooRoutes.chat;
    const run = isInChat && shouldRunFirstTime;

    const modifiedOptions = {
      ...generalOptions,
      locale: {
        ...generalOptions.locale,
        last: t('tutorial.first-access.1-button')
      },
      styles: {
        ...generalOptions.styles,
        tooltipFooter: { marginTop: 5 },
        tooltip: { borderRadius: 15, marginLeft: 8 }
      }
    };

    const steps = [
      {
        target: '[data-overview-tutorial="message-composer"]',
        disableBeacon: true,
        placement: 'top-start',
        title: <Title size="large" text={t('tutorial.first-access.1-title')} icon={<span>&#128075;</span>} />,
        content: t('tutorial.first-access.1')
      }
    ];

    return (
      isInChat && (
        <Joyride
          {...modifiedOptions}
          ref="joyride"
          key={tourCounter}
          steps={steps}
          run={run}
          callback={this.callback}
          stepIndex={stepIndex}
        />
      )
    );
  }
}

FirstAccessTutorial.propTypes = propTypes;
FirstAccessTutorial.defaultProps = defaultProps;

export function mapStateToProps(state) {
  return {
    uncompletedTutorials: state.tutorial.uncompletedTutorials,
    hasFinishedFirstAccessTutorial: state.tutorial.hasFinishedFirstAccessTutorial,
    isOnPremise: state.network.isOnPremise,
    isOpenDemoUser: state.user.isOpenDemoUser
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(FirstAccessTutorial)));
