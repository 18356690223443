import UIToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { useToggleButtonGroupStyles } from './styles';

const ToggleButtonGroup = ({ children, error, ...props }) => {
  const styles = useToggleButtonGroupStyles();

  return (
    <UIToggleButtonGroup
      classes={{
        root: styles.root,
        groupedHorizontal: error ? styles.groupedHorizontalWithError : styles.groupedHorizontal
      }}
      {...props}
    >
      {children}
    </UIToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
