import { baseURL, studioURL } from 'config/baseURL';
import { get, put, post, del } from './utils.js';

const getKnowledgeGraph = id => get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}`);

const getKnowledgeGraphs = () => get(`${studioURL}knowledge-graphs`);

const createKnowledgeGraph = (defaultLanguageId, name) => {
  const body = {
    languages: [defaultLanguageId],
    defaultLanguageId: defaultLanguageId,
    info: {
      [defaultLanguageId]: {
        name: name
      }
    }
  };
  return post(`${studioURL}knowledge-graphs`, body);
};

const updateKnowledgeGraph = (id, body) => put(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}`, body);

const deleteKnowledgeGraph = id => del(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}`);

const switchToKnowledgeGraph = graph => post(`${baseURL}user/knowledge-graph`, JSON.stringify(graph));

const bindDatabaseToKnowledgeGraph = body =>
  put(`${studioURL}knowledge-graphs/${encodeURIComponent(body.id)}/database`, body);

const importKnowledgeGraphTables = (kgId, body) =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/import`, body);

const getKnowledgeGraphFiles = kgId => get(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/files`);

const getKnowledgeGraphFileContent = (kgId, fileId) =>
  get(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/files/${encodeURIComponent(fileId)}/content`);

const saveKnowledgeGraphFileContent = (kgId, fileId, body, headers = {}) =>
  put(
    `${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/files/${encodeURIComponent(fileId)}/content`,
    body,
    headers
  );

const createKnowledgeGraphFile = (kgId, type, identifier) => {
  const body = { type, identifier };
  return post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/files`, body);
};

const deleteKnowledgeGraphFile = (kgId, fileId) =>
  del(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/files/${encodeURIComponent(fileId)}`);

const resetKnowledgeGraphToMaster = kgId =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/git/reset`, {});

const reloadKnowledgeGraphFromFiles = kgId =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/reload-files`, {});

const pullKnowledgeGraphFromMaster = kgId =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/git/pull`, {});

const pullKnowledgeGraphFromRemote = kgId =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/git/pull-remote`);

const pushKnowledgeGraphToMaster = kgId =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/git/push`, {});

const isBackgroundSyncRunning = kgId =>
  get(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/is-background-sync-running`);

const makeKnowledgeGraphManaged = (kgId, fileId) =>
  post(`${studioURL}knowledge-graphs/${kgId}/files/${encodeURIComponent(fileId)}/make-managed`, null);

const saveDefinition = (name, definitionType, connectTo, returnResource, dateFormat, vql) =>
  post(`${baseURL}knowledge-graph/definition`, { name, definitionType, connectTo, returnResource, dateFormat, vql });

/**
 * Adds a 'parentUri' URL parameter to the given URL parameters if 'parentUri' is not 'undefined'.
 *
 * If the 'parentUri' is 'undefined' then no parameter is added (meaning no filtering by parent URI).
 * If the 'parentUri' is 'null' then an empty parameter is added (filtering to concepts without a parent).
 * If the 'parentUri' is a string then it is added as the parameter value (filtering to concepts with the given parent).
 */
function addParentUriUrlParameter(parentUri, params) {
  if (parentUri !== undefined) {
    // if 'parentUri' is 'null' then we fall back to the empty string to make the parameter empty
    params.set('parentUri', parentUri || '');
  }
}

/**
 * Retrieves concepts of a given type in a knowledge graph.
 *
 * @param kgId the ID of the knowledge graph to retrieve the concepts in
 * @param type the type of concepts to retrieve
 * @param parentUri either the URI of a concept to get the child concepts of, `null` to get the top-level concepts
 *                  (i.e. which have no parent class) or `undefined` to get all concepts
 */
const getKgConcepts = (kgId, type, parentUri) => {
  const params = new URLSearchParams();
  addParentUriUrlParameter(parentUri, params);
  return get(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/concepts/${type}`, params);
};

/**
 * Retrieves the concept of the given type in the knowledge graph.
 *
 * @param kgId the ID of the knowledge graph to retrieve the concept in
 * @param type the type of concept to retrieve
 * @param uri the URI of the concept to retrieve
 * @param parentUri either the URI of a concept to get the child concepts of, `null` to get the top-level concepts
 *                  (i.e. which have no parent class) or `undefined` to get all concepts
 */
const getKgConcept = (kgId, type, uri, parentUri) => {
  const params = new URLSearchParams();
  addParentUriUrlParameter(parentUri, params);
  return get(
    `${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/concepts/${type}/${encodeURIComponent(uri)}`,
    params
  );
};

/**
 * Deletes the concept of the given type in the knowledge graph.
 *
 * @param kgId the ID of the knowledge graph to delete the concept in
 * @param type the type of concept to delete
 * @param uri the URI of the concept to delete
 * @param parentUri either the URI of a concept to get the child concepts of, `null` to get the top-level concepts
 *                  (i.e. which have no parent class) or `undefined` to get all concepts
 * @param isPreview whether the deletion is a preview (i.e. the concept is not actually deleted, but affected files are returned)
 */
const deleteKgConcept = (kgId, type, uri, parentUri, isPreview) => {
  const params = new URLSearchParams();
  addParentUriUrlParameter(parentUri, params);
  if (isPreview) {
    params.set('isPreview', 'true');
  }
  return del(
    `${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/concepts/${type}/${encodeURIComponent(uri)}`,
    params
  );
};

const getKnowledgeGraphUsers = kgId => get(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/users`);

const getVersionTimestamp = id => get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/version-timestamp`);

export default {
  getKnowledgeGraph,
  getKnowledgeGraphs,
  createKnowledgeGraph,
  updateKnowledgeGraph,
  deleteKnowledgeGraph,
  switchToKnowledgeGraph,
  bindDatabaseToKnowledgeGraph,
  getKnowledgeGraphFiles,
  getKnowledgeGraphFileContent,
  saveKnowledgeGraphFileContent,
  createKnowledgeGraphFile,
  deleteKnowledgeGraphFile,
  resetKnowledgeGraphToMaster,
  pullKnowledgeGraphFromMaster,
  pullKnowledgeGraphFromRemote,
  pushKnowledgeGraphToMaster,
  reloadKnowledgeGraphFromFiles,
  importKnowledgeGraphTables,
  isBackgroundSyncRunning,
  makeKnowledgeGraphManaged,
  saveDefinition,
  getKgConcepts,
  getKgConcept,
  deleteKgConcept,
  getKnowledgeGraphUsers,
  getVersionTimestamp
};
