import { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import messageStyles from './answer-message.scss';
import styles from './message.scss';
import AnswerMessage from './AnswerMessage';
import AnswerMessageHeader from './AnswerMessageHeader';
import AcceptOrRejectSharingMessageFooter from './AcceptOrRejectSharingMessageFooter';
import { wasRejected, isAwaitingApproval } from './commonFunctions';
import BaseMessage from './BaseMessage';
import CustomerSelectionAnswerMessageFooter from './CustomerSelectionAnswerMessageFooter';
import { acceptOrRejectSharedCustomerSelection } from 'store/modules/customers-of-the-day';
import CustomerSelectionDropdown from 'components/customers/CustomerSelectionDropdown';
import Alert from 'studio/components/Alert';

class CustomerSelectionAnswerMessage extends Component {
  constructor(props) {
    super(props);

    this.customerSelectionMessageRef = createRef();
  }
  render() {
    const {
      message,
      customerSelectionId,
      isLoading,
      t,
      addToBoardCallback,
      customerSelections,
      scrollTo,
      dispatch
    } = this.props;

    // get the board to be displayed from the list of boards
    let customerSelection;
    if (customerSelections.length > 0) {
      customerSelection = customerSelections.find(function(cs) {
        return cs.id === customerSelectionId;
      });
    }

    // We do not want the board to be editable if we are expanded
    const customerSelectionHeader = customerSelection ? (
      <Alert inAnswerHeader={true} icon={false}>
        <div className={styles.flexChildrenSpaceBetween}>
          <span>
            <span className="icon-people-group-message icon-message-layout" />
            <span className={messageStyles.messageTitleWithIcon}>{customerSelection.name}</span>
          </span>
          <CustomerSelectionDropdown
            id={customerSelection.id}
            isShared={customerSelection.isShared}
            isOwned={customerSelection.isOwned}
            userSharingStatus={customerSelection.userSharingStatus}
            isInCustomersOfTheDay={customerSelection.isInCustomersOfTheDay}
            disableShare={!customerSelection.isOwned}
            disableRename={!customerSelection.isOwned}
            disableDelete={!customerSelection.isOwned}
            isInSidebar={customerSelection.isInSidebar}
            useHorizontalToggle={true}
            name={customerSelection.name}
            dispatch={dispatch}
            t={t}
          />
        </div>
      </Alert>
    ) : (
      '\u00A0'
    );

    const specialContent = (
      <div>
        <Fragment>
          <AnswerMessageHeader
            customHeader={customerSelectionHeader}
            structuredTitle={customerSelection?.structuredTitle}
            filters={customerSelection ? customerSelection.filters : []}
            answerData={message.answer}
            localQuestionId={message.id}
            isMobile={this.props.isMobile}
          />
          <span data-test="contentLoading">{t('loading')}</span>
        </Fragment>
        <div className={messageStyles.placeholder} />
      </div>
    );

    const selectionAwaitsApproval = isAwaitingApproval(customerSelection);
    const selectionWasRejected = wasRejected(customerSelection);

    const footer =
      message.answer && customerSelection ? (
        selectionAwaitsApproval || selectionWasRejected ? (
          <AcceptOrRejectSharingMessageFooter
            id={customerSelection.id}
            acceptOrRejectSharing={acceptOrRejectSharedCustomerSelection}
            acceptTooltipText={t('tooltip.accept-selection-button')}
            rejectTooltipText={t('tooltip.reject-selection-button')}
            alreadyRejected={selectionWasRejected}
          />
        ) : (
          <CustomerSelectionAnswerMessageFooter
            answerId={message.answer.answerId}
            interpretationId={message.answer.interpretationId}
            partialAnswerId={message.answer.partialAnswerId}
            messageId={message.id}
            customerSelection={customerSelection}
            targetRef={this.customerSelectionMessageRef}
          />
        )
      ) : null;

    const specialMsg = (
      <BaseMessage
        content={specialContent}
        showVeezooIcon={true}
        enableStandardFooter={true}
        isFollowUp={false}
        timestamp={message.timestamp}
      />
    );

    const rejectedAnswer =
      message.answer && customerSelection ? (
        <BaseMessage
          showVeezooIcon={true}
          key={message.id}
          customFooter={footer}
          timestamp={message.timestamp}
          content={
            <p className={messageStyles.summary}>
              {' '}
              {t('sharing.rejected-message-selection', {
                name: customerSelection.name
              })}
            </p>
          }
        />
      ) : null;

    const approvalOrRejectMessage = selectionAwaitsApproval ? t('sharing.approval-or-reject-selection-message') : null;

    return (
      <div
        data-test="customerSelectionAnswerMessage"
        data-display-customer-selection-tutorial={true}
        ref={this.customerSelectionMessageRef}
        className={messageStyles.messageAnswer}
      >
        {selectionWasRejected ? (
          rejectedAnswer
        ) : isLoading ? (
          specialMsg
        ) : (
          <AnswerMessage
            {...message}
            addToBoardCallback={addToBoardCallback}
            disableFooter={false}
            customFooter={footer}
            textAnswer={approvalOrRejectMessage}
            customHeader={customerSelectionHeader}
            scrollTo={scrollTo}
            timestamp={message.timestamp}
            isMobile={this.props.isMobile}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({ customerSelections: state.customersOfTheDay.selections });

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(CustomerSelectionAnswerMessage));
