import { studioURL } from 'config/baseURL';
import { get, post, put } from './utils.js';

const getSamlConfiguration = () => get(`${studioURL}saml/configuration`);

const replaceSamlConfiguration = samlConfiguration => put(`${studioURL}saml/configuration`, samlConfiguration);

const serviceProviderMetadataUrl = `${studioURL}saml/sp-metadata`;

const getServiceProviderMetadata = () => get(`${studioURL}saml/sp-metadata`);

const testSamlConfiguration = samlConfiguration => post(`${studioURL}saml/configuration/test`, samlConfiguration);

export default {
  getSamlConfiguration,
  replaceSamlConfiguration,
  serviceProviderMetadataUrl,
  getServiceProviderMetadata,
  testSamlConfiguration
};
