import { useMemo } from 'react';
import { connect } from 'react-redux';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import itLocale from 'date-fns/locale/it';
import ptLocale from 'date-fns/locale/pt-BR';

// We configure calendar to display first day of the week as "Monday";
const configLocale = locale => {
  if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
  }
};

configLocale(deLocale);
configLocale(enLocale);
configLocale(frLocale);
configLocale(itLocale);
configLocale(ptLocale);

const languageSchema = {
  'en-US': enLocale,
  'de-DE': deLocale,
  'fr-FR': frLocale,
  'it-IT': itLocale,
  'pt-BR': ptLocale
};

const PickersUtilsProvider = ({ children, language }) => {
  const currentLocale = useMemo(() => (language ? languageSchema[language] : enLocale), [language]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={currentLocale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = state => ({
  language: state.user.language
});

export default connect(mapStateToProps)(PickersUtilsProvider);
