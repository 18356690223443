import React, { forwardRef } from 'react';
import UITextField from '@material-ui/core/TextField';
import UIInputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import HelpButton from '../HelpButton';

const TextField = forwardRef(
  (
    {
      name,
      startIcon,
      endIcon,
      value,
      label,
      description,
      onChange,
      disabledWhenFilled,
      disabled,
      docsUrl,
      'data-test': dataTest,
      ...props
    },
    ref
  ) => {
    const useInputStyles = makeStyles(theme => ({
      root: {
        ...(theme.isDark ? {} : { backgroundColor: 'white' })
      },
      disabled: {
        backgroundColor: 'transparent',
        ...(disabledWhenFilled ? { color: theme.palette.primary.main } : '')
      }
    }));
    const useStyles = makeStyles(theme => ({
      helpButton: {
        marginTop: description ? -18 : 4,
        marginLeft: theme.spacing(1)
      }
    }));

    const inputClasses = useInputStyles();
    const classes = useStyles();

    const setAdornment = (icon, position) => {
      return <UIInputAdornment position={position}>{icon}</UIInputAdornment>;
    };

    const textfield = (
      <UITextField
        InputProps={{
          startAdornment: startIcon ? setAdornment(startIcon, 'start') : null,
          endAdornment: endIcon ? setAdornment(endIcon, 'end') : null,
          classes: inputClasses
        }}
        inputRef={ref}
        name={name}
        label={label}
        helperText={description}
        variant="outlined"
        margin="dense"
        fullWidth
        value={value || ''}
        onChange={onChange}
        autoComplete="new-password"
        disabled={disabled || disabledWhenFilled}
        // add the 'data-test' attribute, falling back to the name by default
        {...(dataTest || name ? { 'data-test': dataTest || name } : {})}
        {...props}
      />
    );

    return docsUrl ? (
      <Box display="flex" alignItems="center">
        {textfield}
        {!!docsUrl && (
          <div>
            <HelpButton docsUrl={docsUrl} className={classes.helpButton} />
          </div>
        )}
      </Box>
    ) : (
      textfield
    );
  }
);

export default TextField;
