import { makeStyles } from '@material-ui/core/styles';

const fontFamily = 'var(--font-family)';

export const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  }
}));

const helperIcons = {
  top: 'initial',
  marginRight: 4,
  justifyContent: 'flex-start',
  boxSizing: 'border-box',
  alignItems: 'end',
  padding: 0,
  '& > path': {
    fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
  }
};

const marginTop = 4;
const minHeight = 32;

export const useVeezooStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: minHeight + marginTop
  },
  input: {
    fontFamily,
    fontSize: '12px',
    color: 'inherit',
    lineHeight: '20px',
    boxSizing: 'border-box',
    padding: '9.5px 4px'
  },
  labelRoot: {
    fontFamily,
    fontSize: '12px',
    fontWeight: 400
  },
  inputRoot: {
    marginTop,
    padding: '3px 65px 3px 3px !important',
    minHeight,
    zIndex: 1,
    ...(theme.isDark ? {} : { backgroundColor: 'white' })
  },
  notchedOutline: {
    top: 0,
    border: 'solid thin var(--light-gray)',
    borderRadius: 4,
    '& > legend': {
      display: 'none'
    }
  },
  formHelperTextRoot: {
    fontSize: '12px'
  },
  shrink: {
    transform: 'none !important',
    position: 'static'
  },
  popupIndicator: {
    ...helperIcons,
    width: '13.5px',
    height: '10.5px'
  },
  clearIndicator: {
    ...helperIcons,
    width: '14.5px',
    height: '14.5px',
    padding: '3px',
    backgroundColor: 'var(--light-gray)'
  },
  endAdornment: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '13px !important',
    margin: 'auto',
    height: '14px'
  },
  option: {
    fontSize: '12px',
    fontFamily
  },
  disabled: ({ disabledWhenFilled }) => ({
    backgroundColor: 'transparent',
    ...(disabledWhenFilled ? { color: '#2e8dd5 !important' } : '')
  }),
  menuItemIcon: {
    display: 'inline-flex',
    width: '20px',
    height: '20px',
    verticalAlign: 'middle',
    marginRight: '7px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--light-medium-gray)',
    borderRadius: '50%',
    '& > svg': {
      maxWidth: '20px',
      maxHeight: '10px',
      fill: 'var(--primary-color)'
    }
  },
  userInitials: {
    backgroundColor: 'var(--primary-color)',
    color: 'white',
    fontSize: '8px'
  }
}));
