import React, { memo, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import AccountCircleIcon from '@material-ui/icons/AccountCircleRounded';
import { openSupportMailWithFreshId } from 'utils/supportMail';
import Button from 'components/buttons/Button';
import KnowledgeGraphSelect from 'components/sidebar/tab/KnowledgeGraphSelect';
import LanguageSelect from 'components/sidebar/tab/LanguageSelect/LanguageSelect';
import { studioRoutes, veezooRoutes } from 'components/app/routes';
import VeezooLogo from 'svg/veezoo-logo-complete-white.svg?url';
import { signout } from 'store/modules/network';
import useMediaQuery from 'utils/mediaQueries';
import styles from 'components/sidebar/sidebar.scss';
import VeezooAdminIcon from 'svg/preferences.svg';
import InfoTooltip from 'components/tooltips/InfoTooltip';

const SidebarFooter = ({
  showSettings,
  username,
  meta,
  t,
  knowledgeGraphs,
  languages,
  user,
  isOpenDemoUser,
  isSuperUserOrHigher,
  dispatch
}) => {
  const isMobile = useMediaQuery();

  // specifies whether the tooltip for the KG switcher is open
  // (this is needed to have more control such that we can close the tooltip when clicking on the switcher)
  const [kgSwitcherTooltipOpen, setKgSwitcherTooltipOpen] = useState(false);

  const hasNoUserCreatedKgs = knowledgeGraphs && knowledgeGraphs?.every(kg => !kg.isUserCreatedKg);
  const canConnectData = user?.isSuperUser || user?.isAdmin;

  const formattedKnowledgeGraphs = useMemo(() => {
    if (knowledgeGraphs?.length) {
      return knowledgeGraphs
        .filter(kg => kg.isDataReady !== false && !kg.isBeingPrepared) // We cannot switch to KGs where the data is not ready yet
        .map(kg => ({
          name: kg.info[user.language]?.name || kg.info.default.name,
          value: kg.id,
          isDevelopmentKg: !!kg.developmentKgForName
        }));
    }
    return [];
  }, [knowledgeGraphs, user]);

  const handleSignupClick = () => dispatch(signout());
  const connectDataButton = (
    <Button
      className={`button-secondary ${styles.signupButton}`}
      titleClassName={`text ${styles.ellipsisTitle}`}
      text={t('connect-your-data')}
    >
      <span className="icon-veezoo-logo" />
    </Button>
  );

  // if we are dealing here with an open demo user, we will lead him first to sign up
  // in the case of a user that only checked demo KGs so far, we will take him to Studio in the New KG section
  const connectDataLink = isOpenDemoUser ? (
    <Link className={styles.link} to={veezooRoutes.signup} onClick={handleSignupClick}>
      {connectDataButton}
    </Link>
  ) : (
    <a href={studioRoutes.new} target="_blank" rel="noopener noreferrer">
      {connectDataButton}
    </a>
  );

  // either the user is an open demo user or didn't create an own KG (but can actually connect datasets)
  const showConnectDataLink = isOpenDemoUser || (hasNoUserCreatedKgs && canConnectData);

  const KGListItem = useMemo(() => {
    if (formattedKnowledgeGraphs.length <= 1 && languages.length <= 1) return null;

    return (
      <>
        <div
          className={clsx(
            styles.KGSwitcherContainer,
            languages.length <= 1 ? styles.fullWidth : '',
            styles.expandedItem
          )}
        >
          <KnowledgeGraphSelect
            knowledgeGraphs={formattedKnowledgeGraphs}
            meta={meta}
            disabled={formattedKnowledgeGraphs.length <= 1}
          />
        </div>
        {languages.length > 1 && <LanguageSelect />}
      </>
    );
  }, [formattedKnowledgeGraphs, languages, meta]);

  const adminButton = (
    <Button
      className="button-secondary"
      titleClassName="text"
      text="Veezoo Admin"
      disabled={isMobile}
      data-test="chatAdminLink"
    >
      <VeezooAdminIcon />
      <div className={styles.rightIcon}>
        <span className="icon-new-window" />
      </div>
    </Button>
  );

  const developmentKgForName = knowledgeGraphs?.find(kg => kg.id === meta?.id)?.developmentKgForName;
  const kgSwitcherTooltipText = developmentKgForName
    ? `This KG is a development copy of '${developmentKgForName}'. It allows to work on the KG without disturbing live users.`
    : t('tooltip.kg-switcher');

  return (
    <div className={styles.footer}>
      <div className={styles.footerWrapper}>
        <ul className={styles.footerBar}>
          {showConnectDataLink && <li className={styles.footerItem}>{connectDataLink}</li>}
          {!isOpenDemoUser && !isMobile && (
            <li className={styles.footerItem}>
              <InfoTooltip text={t('tooltip.profile')} placement="right">
                <Link className={styles.link} to={veezooRoutes.settings}>
                  <Button
                    className={`button-secondary ${showSettings ? styles.selected : null}`}
                    titleClassName={`text ${styles.ellipsisTitle}`}
                    text={username}
                  >
                    <AccountCircleIcon data-test="profile" />
                  </Button>
                </Link>
              </InfoTooltip>
            </li>
          )}
          {isSuperUserOrHigher && (
            <li className={styles.footerItem}>
              <InfoTooltip text={t('tooltip.admin')} placement="right">
                {isMobile ? (
                  adminButton
                ) : (
                  <Link
                    className={styles.link}
                    to={veezooRoutes.admin}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-overview-tutorial="admin"
                  >
                    {adminButton}
                  </Link>
                )}
              </InfoTooltip>
            </li>
          )}
          {KGListItem && (
            <li className={styles.footerItem}>
              <InfoTooltip text={kgSwitcherTooltipText} placement="right" open={kgSwitcherTooltipOpen}>
                <div
                  className={styles.KGOptions}
                  onMouseEnter={() => setKgSwitcherTooltipOpen(true)}
                  onMouseLeave={() => setKgSwitcherTooltipOpen(false)}
                  onBlur={() => setKgSwitcherTooltipOpen(false)}
                >
                  {KGListItem}
                </div>
              </InfoTooltip>
            </li>
          )}
          {isMobile && (
            <li className={styles.footerItem}>
              <Button
                className={`button-secondary ${showSettings ? styles.selected : null}`}
                onClick={handleSignupClick}
                titleClassName={`text ${styles.ellipsisTitle}`}
                text="Logout"
              >
                <span className="icon-logout" data-test="logout" />
              </Button>
            </li>
          )}
          <li className={styles.footerItem}>
            <InfoTooltip text={t('tooltip.feedback')} placement="right">
              <Button
                className="button-secondary"
                titleClassName={`text ${styles.ellipsisTitle}`}
                text={t('sidebar.feedback')}
                onClick={() => openSupportMailWithFreshId(meta.htmlSupport, user.username)}
              >
                <span className="icon-support" />
              </Button>
            </InfoTooltip>
          </li>
        </ul>

        <p itemScope itemType="http://schema.org/Organization" className={styles.company}>
          <span>powered by&nbsp;</span>
          <a
            className={styles.footerVeezooLogoLink}
            href="https://www.veezoo.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <VeezooLogo style={{ height: '9px' }} />
          </a>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  knowledgeGraphs: state.knowledgeGraphs.data,
  user: state.user,
  languages: state.user.languages,
  isSuperUserOrHigher: state.user.isSuperUser || state.user.isAdmin,
  isOpenDemoUser: state.user.isOpenDemoUser
});

export default connect(mapStateToProps)(memo(SidebarFooter));
