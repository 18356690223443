import { makeStyles } from '@material-ui/core/styles';

const fontFamily = 'var(--font-family)';
const highlightedSignup = 'var(--light-blue)';

const commonStyles = {
  arrowIcon: {
    top: 'initial',
    width: '13.5px',
    height: '13.5px',
    marginRight: 10,
    justifyContent: 'flex-start',
    alignItems: 'end',
    '& > path': {
      fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
    }
  },
  labelForEmptyValue: {
    opacity: 0.5
  }
};

export const useSignupStyles = makeStyles(() => ({
  ...commonStyles,
  root: {
    width: '100%'
  },
  input: {
    fontFamily,
    fontSize: ({ isMobile }) => (isMobile ? '16px' : '20px'),
    color: ({ highlighted }) => (highlighted ? highlightedSignup : 'inherit'),
    padding: '6px 0',
    display: 'flex',
    alignItems: 'center'
  },
  labelRoot: {
    fontFamily,
    fontSize: ({ isMobile }) => (isMobile ? '16px' : '20px'),
    whiteSpace: 'nowrap',
    '&&+.MuiInput-formControl': {
      marginTop: '16px'
    }
  },
  inputUnderline: {
    '&&.MuiInput-underline::before': {
      borderBottom: ({ disabled }) => (disabled ? 'solid thin var(--disabled-color)' : 'solid thin black')
    }
  },
  formHelperTextRoot: {
    fontSize: '12px'
  },
  formHelperTextContained: {
    display: 'flex',
    alignItems: 'center'
  },
  disabled: ({ disabled, highlighted }) => ({
    color: highlighted ? highlightedSignup : 'rgba(0, 0, 0, 0.18)',
    ...(disabled ? { disabled } : {})
  }),
  menuProps: {
    '&& .MuiMenuItem-root': {
      fontSize: '16px',
      fontFamily
    }
  },
  menuItemUnselected: {
    opacity: 0.5
  }
}));

export const useVeezooStyles = makeStyles(theme => ({
  ...commonStyles,
  input: ({ noBorders }) => ({
    fontFamily,
    fontSize: '14px',
    padding: noBorders ? '0' : '8px 12px',
    color: 'inherit',
    lineHeight: '18px',
    height: noBorders ? '20px' : '32px',
    boxSizing: 'border-box',
    ...(theme.isDark || noBorders ? {} : { backgroundColor: 'white' }),
    '&:focus': {
      ...(noBorders ? { backgroundColor: 'transparent' } : {})
    }
  }),
  labelRoot: {
    fontFamily,
    fontSize: '12px',
    fontWeight: 400
  },
  inputRoot: ({ noBorders }) => ({
    marginTop: noBorders ? 0 : 4
  }),
  notchedOutline: ({ noBorders }) => ({
    top: 0,
    border: noBorders ? 'solid 0px transparent' : 'solid thin var(--light-gray)',
    borderRadius: 4,
    '& > legend': {
      display: 'none'
    }
  }),

  inputFocused: ({ noBorders }) =>
    noBorders
      ? {
          '& $notchedOutline': {
            borderColor: 'transparent !important',
            borderWidth: '0 !important'
          }
        }
      : {},

  shrink: {
    transform: 'none !important',
    position: 'static'
  },
  formHelperTextRoot: {
    fontSize: '12px'
  },
  menuProps: {
    '&& .MuiMenuItem-root': {
      fontSize: '12px',
      fontFamily
    }
  },
  menuItemUnselected: {
    opacity: 0.5
  },
  disabled: ({ disabledWhenFilled }) => ({
    backgroundColor: 'transparent',
    ...(disabledWhenFilled ? { color: '#2e8dd5 !important' } : '')
  })
}));

export const useDefaultStyles = makeStyles(theme => ({
  ...commonStyles,
  input: {
    color: ({ highlighted }) => (highlighted ? theme.palette.primary.main : 'inherit')
  },
  inputRoot: {
    ...(theme.isDark ? {} : { backgroundColor: 'white' })
  },
  formHelperTextContained: ({ tooltip }) => ({
    ...(tooltip ? { marginLeft: 0 } : {}),
    display: 'flex',
    alignItems: 'center'
  }),
  disabled: ({ highlighted }) => ({
    backgroundColor: 'transparent',
    ...(highlighted ? { color: theme.palette.primary.main } : '')
  })
}));
