import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/tooltips/InfoTooltip';
import Button from 'components/buttons/Button';

import useMediaQuery from 'utils/mediaQueries';

import { addBoardToList } from 'store/modules/board';
import withRestrictedFunctionality from 'root/hocs/withRestrictedFunctionality';

import InputModal from 'components/modals/InputModal/InputModal';

const SaveBoardButton = ({
  answerId,
  isCopyButton,
  originalBoardName,
  restrictedFunctionality,
  metaPartialAnswers,
  dispatch,
  t
}) => {
  const isMobile = useMediaQuery();

  const buttonText = isCopyButton ? t('save-as-copy') : t('save-as-board');
  const defaultBoardName =
    isCopyButton && originalBoardName ? `${originalBoardName} (${t('copy')})` : t('dashboard-new-board-name');

  const [open, setOpen] = useState(false);

  const showModal = () => restrictedFunctionality(() => setOpen(true));
  const closeModal = () => setOpen(false);

  const handleConfirm = text => {
    closeModal();
    const transformedMetaPartialAnswerWidgets = metaPartialAnswers.map(item => ({
      interpretationId: item.interpretationId,
      row: item.row,
      column: item.column,
      width: item.width,
      height: item.height,
      title: item.title,
      type: item.type,
      fromPartialAnswer: item.partialAnswerId
    }));

    dispatch(addBoardToList(text, transformedMetaPartialAnswerWidgets, answerId, false, t));
  };

  return (
    <>
      <InputModal
        title={t('dashboard-add-new')}
        defaultText={defaultBoardName}
        open={open}
        onConfirm={handleConfirm}
        onClose={closeModal}
      />

      <InfoTooltip text={buttonText}>
        <Button
          className="button-secondary"
          onClick={showModal}
          showOnlyIcon={isMobile}
          text={buttonText}
          data-test="saveAsNewBoardButton"
        >
          <span className="icon-add-dashboard" />
        </Button>
      </InfoTooltip>
    </>
  );
};

export default withTranslation('veezoo')(withRestrictedFunctionality(SaveBoardButton));

SaveBoardButton.propTypes = {
  metaPartialAnswers: PropTypes.array,
  isCopyButton: PropTypes.bool,
  dispatch: PropTypes.func,
  restrictedFunctionality: PropTypes.func,
  t: PropTypes.func,
  answerId: PropTypes.string,
  originalBoardName: PropTypes.string
};
