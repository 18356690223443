import amazonAthenaLight from './connectors/amazon-athena-light.png';
import amazonAthenaDark from './connectors/amazon-athena-dark.png';
import amazonRedshiftLight from './connectors/amazon-redshift-light.png';
import amazonRedshiftDark from './connectors/amazon-redshift-dark.png';
import bigquery from './connectors/bigquery.png';
import clickhouseLight from './connectors/clickhouse-light.png';
import clickhouseDark from './connectors/clickhouse-dark.png';
import databricksLight from './connectors/databricks-light.png';
import databricksDark from './connectors/databricks-dark.png';
import db2 from './connectors/db2.png';
import exaLight from './connectors/exasol-light.png';
import exaDark from './connectors/exasol-dark.png';
import excelLight from './connectors/excel-light.png';
import excelDark from './connectors/excel-dark.png';
import mysql from './connectors/mysql.png';
import noDbFound from './connectors/no_db_found.png';
import oracle from './connectors/oracle.png';
import postgreSqlLight from './connectors/postgresql-light.png';
import postgreSqlDark from './connectors/postgresql-dark.png';
import prestoLight from './connectors/presto-light.png';
import prestoDark from './connectors/presto-dark.png';
import sap from './connectors/sap.png';
import shopify from './connectors/shopify.png';
import snowflake from './connectors/snowflake.png';
import sqlServerLight from './connectors/sql-server-light.png';
import sqlServerDark from './connectors/sql-server-dark.png';
import trino from './connectors/trino-light.png';

const imageImport = (dbname, isDarkMode = false) => {
  switch (dbname) {
    case 'amazon-athena':
      return isDarkMode ? amazonAthenaDark : amazonAthenaLight;
    case 'amazon-redshift':
      return isDarkMode ? amazonRedshiftDark : amazonRedshiftLight;
    case 'bigquery':
      return bigquery;
    case 'clickhouse':
      return isDarkMode ? clickhouseDark : clickhouseLight;
    case 'databricks':
      return isDarkMode ? databricksDark : databricksLight;
    case 'db2':
      return db2;
    case 'exa':
      return isDarkMode ? exaDark : exaLight;
    case 'excel':
      return isDarkMode ? excelDark : excelLight;
    case 'mysql':
      return mysql;
    case 'oracle':
      return oracle;
    case 'postgresql':
      return isDarkMode ? postgreSqlDark : postgreSqlLight;
    case 'presto':
      return isDarkMode ? prestoDark : prestoLight;
    case 'sap':
      return sap;
    case 'shopify':
      return shopify;
    case 'snowflake':
      return snowflake;
    case 'sqlserver':
      return isDarkMode ? sqlServerDark : sqlServerLight;
    case 'trino':
      return trino;
    default:
      return noDbFound;
  }
};

export default imageImport;
