import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ShareModalForm from './ShareModalForm';
import Dialog from 'components/v2/Dialog';

import { hideSharedCustomerSelectionModal } from 'store/modules/sharedModal';

import withRestrictedFunctionality from 'root/hocs/withRestrictedFunctionality';

const ShareModal = ({ open, isAdmin, isSuperUser, knowledgeGraph, board, customerSelection, dispatch }) => {
  const closeSharedModal = () => dispatch(hideSharedCustomerSelectionModal());
  const { t } = useTranslation();

  const title = board?.name || customerSelection?.name;

  return (
    <Dialog
      title={`${t('sharing.share') + (title ? ': ' + title : '')}`}
      open={open}
      hideCloseIcon
      onClose={closeSharedModal}
      content={
        <ShareModalForm
          confirmButtonText={t('sharing.save')}
          isAdmin={isAdmin}
          isSuperUser={isSuperUser}
          knowledgeGraph={knowledgeGraph}
          board={board}
          customerSelection={customerSelection}
          dispatch={dispatch}
        />
      }
      data-test="shareDialog"
    />
  );
};

const mapStateToProps = state => ({
  knowledgeGraph: state.knowledgeGraph,
  board: state.board.boardsList.find(b => b.id === state.sharedModal.modalForId),
  customerSelection: state.customersOfTheDay.selections.find(cs => cs.id === state.sharedModal.modalForId),
  isSuperUser: state.user.isSuperUser,
  isAdmin: state.user.isAdmin
});

export default withRestrictedFunctionality(connect(mapStateToProps)(ShareModal));

ShareModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  knowledgeGraph: PropTypes.object,
  board: PropTypes.object,
  customerSelection: PropTypes.object,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func
};
