import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

/**
 * Function srolls target element into visible area of the browser window.
 * Return nothing
 *
 * @param {HTMLElement} targetElement
 * @param {Object} options
 * @returns {void}
 */

export default function scrollElementIntoVisibleArea(targetElement, options) {
  options = {
    // IMPORTANT! Using option behavior: 'smooth' will lead to uncorrect position for spotlights inside Tutorials
    block: 'nearest'
  };

  if (targetElement) {
    targetElement.scrollIntoView(options);
  }
}
