import BaseMessage from './BaseMessage';
import Icon from '../icons/Icon.js';
import React from 'react';
import AnswerMessageHeader from './AnswerMessageHeader';
import answerMessageStyles from './answer-message.scss';

/**
 * @componentName SmartActionMessage
 * @description This message is added to the chat when executing a smart action and contains an iframe of a given url.`
 */

const SmartActionMessage = ({ message, isMobile }) => {
  const messageContent = (
    <>
      <AnswerMessageHeader
        customHeader={
          <span>
            <Icon type="icon-bolt" fill="#000" />
            <span className={answerMessageStyles.messageTitleWithIcon}>{message.textAnswer}</span>
          </span>
        }
        isMobile={isMobile}
      />
      <iframe src={message.smartActionUrl} />
    </>
  );

  return <BaseMessage showVeezooIcon={message.showVeezooIcon} content={messageContent} timestamp={message.timestamp} />;
};

export default SmartActionMessage;
