import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

// This HOC is used to provide the width of the component to its children.
// I let ChatGPT generate the code for this HOC, it's based on react-grid-layout's standard WidthProvider.
// One difference is that we do not update width if it's 0
// The reason for VZN-10002 was that the width was being set to 0 when the component was not visible (e.g. when going into KG visual mode)
// when then going back to the chat the width was changed again and this led to a massive rerendering and slow performance
const CustomWidthProvider = ComposedComponent => {
  const CustomWidthProviderComponent = props => {
    const [width, setWidth] = useState(1280); // Default width
    const [cachedWidth, setCachedWidth] = useState(1280);
    const nodeRef = useRef(null);

    useEffect(() => {
      const element = findDOMNode(nodeRef.current);
      const updateWidth = () => {
        if (!element) {
          return;
        }

        const newWidth = element.offsetWidth || cachedWidth;

        if (newWidth !== width && newWidth !== 0) {
          setWidth(newWidth);
          setCachedWidth(newWidth);
        } else if (newWidth === 0) {
          // If width is 0, maintain the previous valid width
          setWidth(cachedWidth);
        }
      };

      const handleResize = () => {
        updateWidth();
      };

      window.addEventListener('resize', handleResize);
      updateWidth();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [width, cachedWidth]);

    return <ComposedComponent ref={nodeRef} {...props} width={width} />;
  };

  CustomWidthProviderComponent.propTypes = {
    measureBeforeMount: PropTypes.bool
  };

  return CustomWidthProviderComponent;
};

export default CustomWidthProvider;
