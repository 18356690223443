import { makeStyles } from '@material-ui/core';

const commonStyles = {
  root: {
    width: '100%'
  },
  inputRoot: {
    width: '100%'
  },
  inputLabelMargin: {
    marginTop: 8
  },
  labelRoot: {
    fontFamily: 'lexend',
    fontSize: '12px',
    fontWeight: 400
  },
  formHelperTextRoot: {
    fontSize: '12px',
    fontWeight: 400
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.18)'
  },
  focused: {
    border: 'solid 0px transparent',
    '&>.MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--light-gray) !important',
      borderWidth: '1px !important'
    }
  },
  notchedOutline: {
    top: 0,
    border: 'solid thin var(--light-gray)',
    // border: 'solid thin var(--light-gray)',
    borderRadius: 4,
    '& > legend': {
      display: 'none'
    }
  },
  multiline: {
    padding: 0
  },
  shrink: {
    transform: 'none !important',
    position: 'static'
  }
};

export const useDefaultStyles = makeStyles({
  ...commonStyles,
  input: {
    fontFamily: 'lexend',
    fontSize: '14px',
    padding: '8px 12px',
    color: 'inherit',
    lineHeight: '20px'
  },
  adornmentRoot: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
});

export const useTitleStyles = makeStyles({
  ...commonStyles,
  input: {
    padding: '2px 4px',
    marginBottom: 0,
    fontWeight: 400,
    fontSize: size => (size === 'small' ? 14 : 20),
    lineHeight: size => (size === 'small' ? 16 : 24),
    color: 'var(--font-color)'
  },
  adornmentRoot: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  adornedEnd: {
    paddingRight: 7
  }
});

export const useSearchEntitiesStyles = makeStyles({
  ...commonStyles,
  input: {
    padding: '8px 12px 8px 0',
    marginBottom: 0,
    fontWeight: 300,
    fontSize: '1.2rem',
    lineHeight: '1.9rem',
    color: 'white'
  },
  inputRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: 2,
    '&:hover': {
      border: 'solid 0px transparent'
    }
  },
  positionStart: {
    color: 'var(--secondary-color)'
  },
  adornedStart: {
    paddingLeft: 5
  },
  positionEnd: {
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--dark-gray)'
    }
  },
  notchedOutline: {
    borderColor: 'transparent !important'
  },
  focused: {
    '&>.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    }
  }
});
