import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const loadLocales = (options, url, data, callback) => {
  try {
    let locale = require('./locales/' + url + '/veezoo.json');
    callback(null, { status: '200', data: locale });
  } catch (e) {
    callback(e, { status: '404', data: {} });
  }
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    lng: 'en-US',

    // have a common namespace used around the full app
    ns: ['veezoo'],
    defaultNS: 'veezoo',

    backend: {
      loadPath: '{{lng}}',
      parse: data => data,
      request: loadLocales
    },

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    react: {
      useSuspense: false,
      defaultTransParent: 'div' // a valid react element - required before react 16
    }
  });

export default i18n;
