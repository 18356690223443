import { passageInfoStrategyTypes } from 'config/constants';

const defineEntitiesWithHighlight = (entities, highlighted) => {
  const { HIGHLIGHTED_PASSAGE_INFO } = passageInfoStrategyTypes;
  let initial = highlighted.inputOffset;
  const newEntitiesArray = [];

  const pushNewEntity = ({ offset = 0, length = 0, type = HIGHLIGHTED_PASSAGE_INFO, mutability = 'MUTABLE' }) =>
    newEntitiesArray.push({ offset, length, type, mutability });

  for (let character = highlighted.inputOffset; character <= highlighted.anchorOffset; character++) {
    if (character === highlighted.anchorOffset && initial !== highlighted.anchorOffset) {
      pushNewEntity({ offset: initial, length: character - initial });
    }

    const entityFound = entities.find(
      entity => character >= entity.offset && character <= entity.offset + entity.length
    );

    if (!entityFound) continue;

    if (entityFound.offset > highlighted.inputOffset) {
      pushNewEntity({ offset: initial, length: character - 1 - initial });
    }

    if (entityFound.length < highlighted.anchorOffset) {
      const newInitial = entityFound.offset + entityFound.length + 1;
      initial = newInitial;
      character = newInitial;
    }
  }

  return newEntitiesArray;
};

export default defineEntitiesWithHighlight;
