import { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Select, TextField } from 'components/v3';

import { browseBoardsSortingOptions, browseBoardsSubscribedOptions } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';

import { useStyles } from './BrowseBoards.styles';

const BrowseBoardsSearchBar = ({ values, onChange, t }) => {
  const isMobile = useMediaQuery();
  const styles = useStyles({ isMobile });

  const translatedSubscribedOptions = useMemo(
    () =>
      Object.values(browseBoardsSubscribedOptions).reduce((acc, option) => {
        if (option.value !== browseBoardsSubscribedOptions.ALL_BOARDS.value) {
          return [
            ...acc,
            {
              ...option,
              label: option.label(t)
            }
          ];
        }
        return acc;
      }, []),
    [t]
  );

  const sortingOptions = useMemo(
    () =>
      Object.values(browseBoardsSortingOptions).map(sortingOption => ({
        value: sortingOption.value,
        label: t(sortingOption.label)
      })),
    [t]
  );

  const handleChange = (event, field) => {
    const value = event.target.value;
    onChange({ ...values, [field]: value });
  };

  return (
    <>
      <TextField
        layout="veezoo"
        placeholder={t('browse-boards.search')}
        value={values.text}
        onChange={e => handleChange(e, 'text')}
        data-test="browseBoardsSearchInput"
        title={t('browse-boards.search')}
      />

      <div className={styles.filterFields}>
        <div className={clsx(styles.fieldContainer, styles.left)}>
          <Select
            layout="veezoo"
            value={values.subscribed}
            onChange={e => handleChange(e, 'subscribed')}
            placeholder={browseBoardsSubscribedOptions.ALL_BOARDS.label(t)}
            options={translatedSubscribedOptions}
            displayEmpty
          />
        </div>
        <div className={clsx(styles.fieldContainer, styles.right)}>
          <Select
            layout="veezoo"
            value={values.sorting}
            onChange={e => handleChange(e, 'sorting')}
            options={sortingOptions}
          />
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    </>
  );
};

export default BrowseBoardsSearchBar;

BrowseBoardsSearchBar.propTypes = {
  values: PropTypes.shape({
    text: PropTypes.string,
    subscribed: PropTypes.string,
    sorting: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired
};

BrowseBoardsSearchBar.defaultProps = {
  values: {
    text: '',
    subscribed: '',
    sorting: ''
  },
  t: text => text
};
