import CustomerSelectionDropdown from 'components/customers/CustomerSelectionDropdown';
import { isAwaitingApproval } from 'components/message/commonFunctions';

import styles from 'components/sidebar/sidebar.scss';

export default function CustomerSelectionSidebarListItem(props) {
  const { selection, defaultEntryPoint, handleCustomerSelections, t, dispatch } = props;

  return (
    <li className={`${styles.item} ${styles.defaultListPadding}`} key={selection.id}>
      <span className={styles.itemAction}>
        <span className="icon-people-group">&nbsp;</span>
        {isAwaitingApproval(selection) && (
          <span className={styles.badge}>
            <span className={styles.dot} />
          </span>
        )}
        <div className={styles.nameWrap} onClick={() => handleCustomerSelections(selection.id)}>
          <span className={styles.name} data-test="csSidebarListing">
            {selection.name}
          </span>
        </div>
        <CustomerSelectionDropdown
          id={selection.id}
          isShared={selection.isShared}
          isOwned={selection.isOwned}
          userSharingStatus={selection.userSharingStatus}
          isInCustomersOfTheDay={selection.isInCustomersOfTheDay}
          disableShare={!selection.isOwned}
          disableRename={!selection.isOwned}
          disableDelete={!selection.isOwned}
          defaultEntryPoint={defaultEntryPoint}
          isInSidebar={selection.isInSidebar}
          useHorizontalToggle={false}
          name={selection.name}
          dispatch={dispatch}
          t={t}
        />
      </span>
    </li>
  );
}
