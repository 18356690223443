import { makeStyles } from '@material-ui/core/styles';

const borderPadding = 25;
const listItemHoverColor = 'rgba(255, 255, 255, 0.1)';

const sidePaddingStyle = {
  paddingLeft: borderPadding,
  paddingRight: borderPadding,
  boxSizing: 'border-box'
};

export default makeStyles({
  container: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: borderPadding,
    paddingBottom: borderPadding,
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    marginBottom: 10
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    ...sidePaddingStyle
  },
  title: {
    fontSize: '1.6rem',
    lineHeight: '1.6rem',
    color: 'white'
  },
  closeIcon: {
    width: 15.6,
    height: 15.6,
    cursor: 'pointer',
    padding: 0,
    '& path': {
      fill: 'var(--secondary-color)'
    },
    '&:hover': {
      backgroundColor: listItemHoverColor,
      borderRadius: '50%'
    }
  },
  searchContainer: {
    ...sidePaddingStyle
  },
  entitiesCount: {
    display: 'block',
    marginTop: 6,
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '1.15rem',
    lineHeight: '1.6rem',
    fontWeight: 200,
    letterSpacing: '0.05rem',
    ...sidePaddingStyle
  },
  entitiesList: {
    marginTop: 12,
    marginBottom: 0,
    width: '100%',
    padding: 0,
    listStyleType: 'none'
  },
  entitiesListItem: {
    cursor: 'default',
    padding: `9px ${borderPadding + 12}px 9px ${borderPadding + 12}px`,
    boxSizing: 'border-box',
    '& > span': {
      color: 'white',
      fontSize: '1.2rem',
      lineHeight: '1.9rem',
      fontWeight: 300,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block'
    },
    '&:hover': {
      backgroundColor: listItemHoverColor
    }
  },
  entitieslistItemHover: {
    backgroundColor: listItemHoverColor
  },
  listContainer: {
    marginTop: 5,
    overflowY: 'auto',
    maxHeight: '100%',
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.3)',
      borderRadius: 100
    }
  },
  loadingContainer: {
    width: '100%',
    height: 28,
    padding: '0 0 5px',
    boxSizing: 'border-box',
    textAlign: 'center'
  },
  bottomMessage: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontStyle: 'italic',
    marginTop: 9,
    display: 'inline-block',
    fontSize: '1.1em'
  }
});
