import { CompositeDecorator, EditorState, Modifier } from 'draft-js';
import { passageInfoStrategyTypes } from 'config/constants';

function replaceTextUntilCursor(editorState, input) {
  // get the current content
  const contentState = editorState.getCurrentContent();

  // replace content between input offset and cursor
  let selectionState = editorState.getSelection();
  const cursorPosition = selectionState.getStartOffset();

  const rangeToReplace = selectionState.merge({
    anchorOffset: input.inputOffset,
    focusOffset: cursorPosition
  });

  // now replace the existing text with the new one
  // if there is a part of the suggestion that should only be shown but not added to the input, cut off that part (starting at its index)
  const cutOff =
    input.passageInfos.find(passage => passage.type === passageInfoStrategyTypes.ONLY_SHOW_TEXT_PASSAGE)?.offset -
    input.inputOffset;
  const text = (cutOff ? input.text.slice(0, cutOff) : input.text).trimEnd();
  const contentStateReplaced = Modifier.replaceText(contentState, rangeToReplace, text, undefined, undefined);

  // we want to add a space character at the end of the new input text after autocompletion, UNLESS both of the following conditions are met:
  // - the suggested input text ends with a quotation mark
  //     e.g. [contains "], where we wouldn't want a space to be included in the search string after the double quotes
  // - that quotation mark is alone, since an autocomplete suggestion could contain quotes, but we would still want a space after
  //     e.g. [Symphony No. 6, "Pathetique"], where the entity itself has quotes
  const quotationMarks = ['"', "'"];
  const lastChar = text.slice(-1);
  const shouldAppendSpace = !(quotationMarks.includes(lastChar) && text.split(lastChar).length - 1 === 1);
  const finalContentState = shouldAppendSpace
    ? Modifier.insertText(contentStateReplaced, contentStateReplaced.getSelectionAfter(), ' ', undefined, undefined)
    : contentStateReplaced;

  return EditorState.push(editorState, finalContentState);
}

export function removePassageInfos(editorState, passageInfos) {
  let contentState = editorState.getCurrentContent();
  let selectionStateBeforeUpdate = editorState.getSelection();
  const inputLength = contentState.getPlainText().length;

  passageInfos.forEach(passage => {
    const selectionState = editorState.getSelection();
    const entityRange = selectionState.merge({
      anchorOffset: passage.offset,
      focusOffset: Math.min(passage.offset + passage.length, inputLength)
    });

    // remove the passage
    contentState = Modifier.applyEntity(contentState, entityRange, null);
  });

  // put the selection state to what it was before removing entities
  contentState = Modifier.applyEntity(contentState, selectionStateBeforeUpdate, null);

  return EditorState.push(editorState, contentState);
}

export function applyPassageInfos(editorState, passageInfos) {
  let contentState = editorState.getCurrentContent();
  let selectionStateBeforeUpdate = editorState.getSelection();

  passageInfos
    .filter(passage => passage.type !== passageInfoStrategyTypes.ONLY_SHOW_TEXT_PASSAGE)
    .forEach(passage => {
      // create the entity (meta) information for the text that we will add to the editor
      const contentStateWithEntity = contentState.createEntity(
        'TOKEN',
        passage.mutability ? passage.mutability : 'IMMUTABLE',
        passage
      );
      // now get the entity key that we have just created above
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      const selectionState = editorState.getSelection();
      const entityRange = selectionState.merge({
        anchorOffset: passage.offset,
        focusOffset: passage.offset + passage.length
      });

      try {
        // apply the entity at the correct spot
        contentState = Modifier.applyEntity(contentStateWithEntity, entityRange, entityKey);
      } catch (e) {
        throw e;
      }
    });

  // put the selection state to what it was before styling
  contentState = Modifier.applyEntity(contentState, selectionStateBeforeUpdate, null);

  return EditorState.push(editorState, contentState);
}

export const replaceInput = (editorState, input) => {
  // first replace the text in the editor state with the one from the input
  let updatedEditorState = replaceTextUntilCursor(editorState, input);

  // and then apply stylings
  updatedEditorState = applyPassageInfos(updatedEditorState, input.passageInfos);

  return updatedEditorState;
};

const getEntityStrategy = type => {
  return (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      return contentState.getEntity(entityKey).getData().type === type;
    }, callback);
  };
};

export const decorator = (linkedComponent, unrecognizedComponent, notInDataComponent, HighlightedSpan) => {
  const {
    LINKED_PASSAGE_INFO,
    HIGHLIGHTED_PASSAGE_INFO,
    UNRECOGNIZED_WORD,
    CONCEPT_NOT_IN_DATA
  } = passageInfoStrategyTypes;
  return new CompositeDecorator([
    {
      strategy: getEntityStrategy(HIGHLIGHTED_PASSAGE_INFO),
      component: HighlightedSpan
    },
    {
      strategy: getEntityStrategy(LINKED_PASSAGE_INFO),
      component: linkedComponent
    },
    {
      strategy: getEntityStrategy(UNRECOGNIZED_WORD),
      component: unrecognizedComponent
    },
    {
      strategy: getEntityStrategy(CONCEPT_NOT_IN_DATA),
      component: notInDataComponent
    }
  ]);
};

/**
 * Input: A RawDraftContentState instance
 * Output: An array of linked entities as specified under in the
 *  suggestion model of the API
 */
export const getLinkedEntitiesFromRawContent = rawContent => {
  // assumption: 'blocks' array contains only a single block.
  return rawContent.blocks[0].entityRanges
    .filter(entity => rawContent.entityMap[entity.key].data.type === 'LinkedPassageInfo')
    .map(entity => {
      return {
        length: entity.length,
        offset: entity.offset,
        description: rawContent.entityMap[entity.key].data.description,
        id: rawContent.entityMap[entity.key].data.id
      };
    });
};

export const getEntitiesFromRawContent = rawContent =>
  rawContent.blocks[0].entityRanges.map(entity => {
    const type = rawContent.entityMap[entity.key].data.type;
    const { LINKED_PASSAGE_INFO } = passageInfoStrategyTypes;

    return {
      length: entity.length,
      offset: entity.offset,
      type: rawContent.entityMap[entity.key].data.type,
      ...(type === LINKED_PASSAGE_INFO
        ? {
            description: rawContent.entityMap[entity.key].data.description,
            id: rawContent.entityMap[entity.key].data.id
          }
        : {
            originalToken: { ...rawContent.entityMap[entity.key].data.originalToken },
            text: rawContent.entityMap[entity.key].data.text
          })
    };
  });
