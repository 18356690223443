import { formatNumber, parseNumber } from 'utils/numberFormat';
import styles from './number-column-statistics-footer.scss';

const calculateAverage = (data, rowIdentifier) => {
  let sum = 0;
  let count = 0;

  data.forEach(row => {
    const value = parseNumber(row[rowIdentifier]);

    if (!isNaN(value)) {
      sum += value;
      count++;
    }
  });

  return sum / count;
};

const calculateTotal = (data, rowIdentifier) => {
  return data.reduce((acc, row) => {
    const value = parseNumber(row[rowIdentifier]);
    if (!isNaN(value)) {
      return acc + value;
    }
    return acc;
  }, 0);
};

/** This component is used to display the average and total of a number column as a footer in the table plot. */
const NumberColumnStatisticsFooter = ({ data, rowIdentifier, numberFormat, isPivotTable, t }) => {
  const average = calculateAverage(data, rowIdentifier);
  const total = calculateTotal(data, rowIdentifier);
  // number formatted
  const averageFormatted = formatNumber(average, numberFormat);
  const totalFormatted = formatNumber(total, numberFormat);
  return isPivotTable ? (
    <strong>{totalFormatted}</strong>
  ) : (
    <div className={styles.container}>
      <div className={styles.label}>
        <div>{t('table.average') + ' '}</div>
        <div>{t('table.total') + ' '}</div>
      </div>
      <div className={styles.value}>
        <div>
          <strong>{averageFormatted}</strong>
        </div>
        <div>
          <strong>{totalFormatted}</strong>
        </div>
      </div>
    </div>
  );
};

export default NumberColumnStatisticsFooter;
