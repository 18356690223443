import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import UIChip from '@material-ui/core/Chip';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import { useStyles } from './styles';

import useMediaQuery from 'utils/mediaQueries';

const limit = 15;

const Chip = forwardRef(({ label, onDeleteClick, ...props }, ref) => {
  const classes = useStyles();
  const isMobile = useMediaQuery();

  const uiChip = (
    <UIChip
      ref={ref}
      classes={classes}
      label={label.length > limit ? `${label.substr(0, limit)}...` : label}
      onDelete={onDeleteClick}
      {...props}
    />
  );

  if (isMobile) return uiChip;

  return <InfoTooltip text={label}>{uiChip}</InfoTooltip>;
});

export default Chip;

Chip.propTypes = {
  label: PropTypes.string,
  onDeleteClick: PropTypes.func
};
