import { baseURL } from 'config/baseURL';
import { get, patch } from '../utils.js';

const fetchMeasure = uri => {
  return get(`${baseURL}knowledge-graph/measures/${encodeURIComponent(uri)}`);
};

const updateMeasure = (uri, body) => {
  return patch(`${baseURL}knowledge-graph/measures/${encodeURIComponent(uri)}`, JSON.stringify(body));
};

export default { fetchMeasure, updateMeasure };
