import { makeStyles } from '@material-ui/core';

export default makeStyles({
  paper: {
    minWidth: 360,
    padding: 24,
    boxSizing: 'border-box'
  },
  closeButton: {
    width: 36,
    height: 36,
    border: 'solid thin var(--light-gray)',
    borderRadius: 5,
    backgroundColor: 'white',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '& path': { fill: 'var(--primary-color)' },

    '&:hover': {
      backgroundColor: 'var(--lightest-gray)'
    }
  },
  closeIcon: {
    width: 9.5,
    height: 9.5
  },
  titleRoot: {
    padding: 0,
    fontFamily: 'lexend',
    fontWeight: '500',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contentRoot: {
    padding: '0 !important'
  }
});
