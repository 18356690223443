import { answerModificationTypes, templateModificationTypes } from 'config/constants';
import { modifyAnswer } from 'store/modules/chat-messages';
import TemplateFilterComponent from 'components/v2/TemplateFilter/TemplateFilter';
import { connect } from 'react-redux';

const TemplateFilter = ({ answer, column, username, onConfirm, onClose, dispatch }) => {
  const isEditOfExistingFilter = !!column.templateFilterComputationCandidate;

  // If there already exists a filter, use the filter's computation ID. Otherwise, use the class literal ID.
  const computationOrLiteralCandidateId = isEditOfExistingFilter
    ? column.templateFilterComputationCandidate
    : column.identifier;

  const handleConfirm = async (data, message) => {
    const payload = {
      username,
      answerData: answer,
      message,
      id: computationOrLiteralCandidateId,
      type: isEditOfExistingFilter ? templateModificationTypes.edit.value : templateModificationTypes.new.value,
      values: data,
      modificationType: answerModificationTypes.template.value,
      representedCandidate: column.representedCandidate,
      representedRelation: column.representedRelation
    };
    await dispatch(modifyAnswer(payload));
    onConfirm();
  };

  return (
    <TemplateFilterComponent
      onConfirm={handleConfirm}
      onCancel={onClose}
      answerId={answer.answerId}
      interpretationId={answer.interpretationId}
      candidateId={computationOrLiteralCandidateId}
      representedRelation={column.representedRelation}
    />
  );
};

const mapStateToProps = state => ({ username: state.user.username });

export default connect(mapStateToProps)(TemplateFilter);
