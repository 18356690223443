import { baseURL } from 'config/baseURL';
import { headersGET, headersPOST } from './../utils/HTTPheaders';
import { authFetch } from './../utils/authFetch';
import uuid1 from 'uuid/v1';

export const ANALYZE_REQUEST = 'ANALYZE_REQUEST';
export const ANALYZE_SUCCESS = 'ANALYZE_SUCCESS';
export const ANALYZE_FAILURE = 'ANALYZE_FAILURE';

export const SUGGESTIONLIST_MARK_REMOVE = 'SUGGESTIONLIST_MARK_REMOVE';
export const SUGGESTIONLIST_REMOVE = 'SUGGESTIONLIST_REMOVE';

export const RECENT_QUESTIONS_REQUEST = 'RECENT_QUESTIONS_REQUEST';
export const RECENT_QUESTIONS_SUCCESS = 'RECENT_QUESTIONS_SUCCESS';
export const RECENT_QUESTIONS_FAILURE = 'RECENT_QUESTIONS_FAILURE';

export const analyzeInput = (question, linkedPassages, followUpState) => {
  const id = uuid1();

  return dispatch => {
    dispatch({
      type: ANALYZE_REQUEST,
      id: id
    });

    authFetch(
      baseURL + 'analyze',
      {
        method: 'POST',
        headers: headersPOST,
        body: JSON.stringify({
          question: question,
          linkedEntities: linkedPassages,
          referenceTo: followUpState,
          sessionId: window.veezoo.sessionId
        }),
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: ANALYZE_FAILURE,
            id: id
          });
          throw Error(response.statusText);
        }
      })
      .then(json => {
        dispatch({
          type: ANALYZE_SUCCESS,
          ...json,
          id: id
        });
      })
      .catch(error => console.log('ERROR', error));
  };
};

/**
 * If suggestions are still being fetched, then set a flag so that
 * they can be removed on response.
 */
// TODO: why we even need to actions type
// const isFetchingSuggestionList = false; // TODO: define in network reducer as well
export const tryRemoveSuggestions = isFetchingSuggestionList => {
  if (isFetchingSuggestionList) {
    return {
      type: SUGGESTIONLIST_MARK_REMOVE
    };
  } else {
    return {
      type: SUGGESTIONLIST_REMOVE
    };
  }
};

export const fetchRecentQuestions = () => {
  return dispatch => {
    dispatch({
      type: RECENT_QUESTIONS_REQUEST
    });

    authFetch(
      baseURL + 'recent',
      {
        headers: headersGET,
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: RECENT_QUESTIONS_FAILURE
          });
          throw Error(response.statusText);
        }
      })
      .then(json => {
        dispatch({
          type: RECENT_QUESTIONS_SUCCESS,
          recent: json
        });
      })
      .catch(error => console.log('ERROR', error));
  };
};

// describe a reducer
const defaultAnalyzeState = {
  shouldRemove: false,
  selectedPassageInfos: [],
  suggestions: [],
  recentQuestions: [],
  analyzingInput: false,
  fetchingRecentQuestions: false,
  id: undefined
};

export const inputAnalysis = (state = defaultAnalyzeState, action) => {
  switch (action.type) {
    case ANALYZE_REQUEST:
      return {
        ...state,
        shouldRemove: false,
        analyzingInput: true,
        id: action.id
      };

    case ANALYZE_FAILURE: {
      return {
        ...state,
        analyzingInput: false,
        id: action.id
      };
    }

    case ANALYZE_SUCCESS:
      if (state.shouldRemove || state.id !== action.id) {
        return state;
      } else {
        return {
          ...state,
          shouldRemove: false,
          analyzingInput: false,
          selectedPassageInfos: action.autoCompleteResult.selectedPassageInfos,
          suggestions: action.autoCompleteResult.suggestions
        };
      }

    // These actions are called when we have an empty input, in that case we do not want
    // to have suggestions -nor- selectedPassageInfos.
    case SUGGESTIONLIST_MARK_REMOVE:
    case SUGGESTIONLIST_REMOVE:
      return {
        ...state,
        selectedPassageInfos: [],
        shouldRemove: true,
        suggestions: []
      };

    case RECENT_QUESTIONS_REQUEST: {
      return {
        ...state,
        fetchingRecentQuestions: true
      };
    }

    case RECENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        shouldRemove: false,
        fetchingRecentQuestions: false,
        recentQuestions: action.recent
      };

    case RECENT_QUESTIONS_FAILURE: {
      return {
        ...state,
        fetchingRecentQuestions: false
      };
    }

    default:
      return state;
  }
};
