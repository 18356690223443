import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { boundMethod } from 'autobind-decorator';
import { withRouter } from 'react-router-dom';

import { finishedTutorial } from 'store/modules/tutorial';

import Title from 'components/tutorial/Title';

import { tutorialNames } from 'config/constants';
import { veezooRoutes } from 'components/app/routes';
import { signout } from 'store/modules/network';

const propTypes = {
  generalOptions: PropTypes.object,
  createAccountModalTriggerType: PropTypes.string,
  showCreateAccountModal: PropTypes.bool,
  history: PropTypes.object
};
const defaultProps = {
  generalOptions: {}
};

export const createAccountModalTriggerTypes = {
  restrictedFunctionality: 'restrictedFunctionality',
  heavyUsage: 'heavyUsage'
};

const tutorialId = tutorialNames.CREATE_ACCOUNT_MODAL;

/**
 * CreateAccountModal is a component that is using the tutorial framework
 * to display a Call-to-action to push a user to create an account.
 * Could be triggered e.g. when trying to access specific functionality.
 *
 * In the future it might make sense to generalize the framework not to mix
 * tutorial and modal terminology and create possible confusion.
 */
export class CreateAccountModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tourCounter: 0,
      stepIndex: 0
    };
  }

  @boundMethod
  callback(tour) {
    const { type, index, action } = tour;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + 1 });
      // This means the "Close" i.e. "Create Account" Button was clicked
      if (ACTIONS.NEXT === action) {
        this.props.dispatch(signout());
        this.props.history.push(veezooRoutes.signup);
      }
    }
    if (type === EVENTS.TOUR_END) {
      this.props.dispatch(finishedTutorial(tutorialId, true));
      this.setState({ tourCounter: this.state.tourCounter + 1, stepIndex: 0 });
    }
  }

  createTutorialSteps(title, content) {
    return [
      {
        target: 'body',
        disableBeacon: true,
        placement: 'center',
        title: (
          <Title
            size="large"
            text={title}
            icon={
              <p>
                <span style={{ fontSize: '50px' }}>&#128640;</span>
              </p>
            }
          />
        ),
        content: content
      }
    ];
  }

  render() {
    const { tourCounter, stepIndex } = this.state;
    const { t, generalOptions, showCreateAccountModal, createAccountModalTriggerType } = this.props;

    // We run it as soon as someone triggered it
    const run = showCreateAccountModal;

    const modifiedOptions = {
      ...generalOptions,
      locale: {
        ...generalOptions.locale,
        last:
          createAccountModalTriggerType === createAccountModalTriggerTypes.restrictedFunctionality
            ? t('tutorial.signup-restricted-feature.1-button')
            : t('tutorial.signup-heavy-usage.1-button')
      },
      styles: {
        ...generalOptions.styles,
        tooltipFooter: {},
        tooltip: { borderRadius: 15, marginLeft: 8 }
      }
    };

    const restrictedFunctionalitySteps = this.createTutorialSteps(
      t('tutorial.signup-restricted-feature.1-title'),
      t('tutorial.signup-restricted-feature.1')
    );

    const heavyUsageSteps = this.createTutorialSteps(t('connect-your-data'), t('tutorial.signup-heavy-usage.1'));

    return (
      <Joyride
        {...modifiedOptions}
        ref="joyride"
        key={tourCounter}
        steps={
          createAccountModalTriggerType === createAccountModalTriggerTypes.restrictedFunctionality
            ? restrictedFunctionalitySteps
            : heavyUsageSteps
        }
        run={run}
        callback={this.callback}
        stepIndex={stepIndex}
      />
    );
  }
}

CreateAccountModal.propTypes = propTypes;
CreateAccountModal.defaultProps = defaultProps;

export function mapStateToProps(state) {
  return {
    showCreateAccountModal: state.tutorial.showCreateAccountModal,
    createAccountModalTriggerType: state.tutorial.createAccountModalTriggerType
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(CreateAccountModal)));
