import InfoTooltip from '../../tooltips/InfoTooltip';
import IconButton from '@material-ui/core/IconButton';
import EditFilterIcon from 'svg/v2/filter.svg';
import styles from './edit-filter-button.scss';
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useEditButtonStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0
  }
});

const EditFilterButton = ({ t, onClick }) => {
  const editButtonClasses = useEditButtonStyles();

  return (
    <InfoTooltip text={t('tooltip.add-new-filter')}>
      <IconButton classes={editButtonClasses} onClick={onClick}>
        <EditFilterIcon className={styles.FilterIcon} />
      </IconButton>
    </InfoTooltip>
  );
};

export default connect()(EditFilterButton);
