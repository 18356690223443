import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FirstAccessTutorial from './FirstAccessTutorial';
import CreateAccountModal from './CreateAccountModal';
import FirstAnswerTutorial from './FirstAnswerTutorial';
import AddWidgetTutorial from './AddWidgetTutorial';
import KnowledgeGraphTutorial from './KnowledgeGraphTutorial';
import OverviewTutorial from './OverviewTutorial';
import BookmarkTutorial from './BookmarkTutorial';
import CustomersOfTheDayTutorial from './CustomersOfTheDayTutorial';
import CustomerSelectionTutorial from './CustomerSelectionTutorial';
import AddToCustomerSelectionTutorial from './AddToCustomerSelectionTutorial';
import SmartActionTutorial from './SmartActionTutorial';
import WhatCanIAskTutorial from 'components/tutorial/WhatCanIAskTutorial';

const propTypes = {
  color: PropTypes.string
};
const defaultProps = {
  color: '#333'
};

export const GeneralOptions = (props, t) => {
  const defaultStyles = {
    options: {
      arrowColor: '#fff',
      backgroundColor: '#fff',
      beaconSize: 24,
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: props?.color || defaultProps?.color,
      spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
      textColor: '#333',
      width: undefined,
      zIndex: 1000000
    },
    buttonNext: {
      fontSize: '14px'
    },
    buttonBack: {
      fontSize: '14px'
    },
    buttonSkip: {
      fontSize: '14px'
    },
    buttonClose: {
      display: 'none'
    }
  };
  return {
    hideBackButton: false,
    showSkipButton: true,
    disableBeacon: true, // doesn't work with automatically don't display any icons for any Tutorials
    continuous: true,
    spotlightClicks: true,
    locale: {
      back: t('back'),
      close: t('close'),
      last: t('last'),
      next: t('next'),
      skip: t('skip')
    },
    styles: defaultStyles,
    floaterProps: {
      // we hide the arrow for now, because react-floater has issues, when the browser is zoomed in
      // this is related to: VZN-2552 and VZN-2722
      // re-evaluate if this is still needed once react-joyride updates.
      hideArrow: true,
      disableAnimation: true // Otherwise there is always an animation coming from the top left corner.
    },
    disableScrolling: false,
    spotlightPadding: 2,
    disableOverlayClicks: false,
    disableCloseOnEsc: false,
    disableOverlayClose: false,
    disableScrollParentFix: true // Leads to removed overflow in sidebar, see github.com/gilbarbara/react-joyride/issues/563
  };
};

// IMPORTANT! Correct scrolling to target element doesn't work with upgraded version for react-joyride version 2.1.1
export function Tutorial(props) {
  const generalOptions = GeneralOptions(props, props.t);

  return (
    <>
      <FirstAccessTutorial generalOptions={generalOptions} />
      <WhatCanIAskTutorial generalOptions={generalOptions} />
      <FirstAnswerTutorial generalOptions={generalOptions} />
      <KnowledgeGraphTutorial generalOptions={generalOptions} />
      <AddWidgetTutorial generalOptions={generalOptions} />
      <CreateAccountModal generalOptions={generalOptions} />
      <BookmarkTutorial generalOptions={generalOptions} />
      <OverviewTutorial generalOptions={generalOptions} />
      <CustomersOfTheDayTutorial generalOptions={generalOptions} />
      <CustomerSelectionTutorial generalOptions={generalOptions} />
      <AddToCustomerSelectionTutorial generalOptions={generalOptions} />
      <SmartActionTutorial generalOptions={generalOptions} />
    </>
  );
}

Tutorial.propTypes = propTypes;
Tutorial.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  return {
    color: state.theme.themeDetails.cssVars['--primary-color']
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(Tutorial));
