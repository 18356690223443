import { CustomModal } from 'components/v3';
import MonacoEditor from 'react-monaco-editor';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopyRounded';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { createResizeObserver } from 'utils/domObservers';

// the background color to use for the code block and editor
const backgroundColor = '#f5f5f5';
// the size of the editor's scrollbars
const scrollbarSize = 6;

const codeBlockStyles = makeStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 6,
    borderRadius: 4,
    backgroundColor: backgroundColor
  }
});

// returns the lines of the specified code
const getLines = code => (code || '').split(/\r\n|\r|\n/);

/**
 * Displays a read-only code block using Monaco editor.
 *
 * The code block automatically sizes / resizes according to the outside container's size, which can have a fixed or
 * dynamic height (e.g. with a maximum height). The outside container needs to set its 'overflow' property to 'hidden'.
 */
export const CodeBlock = ({
  code,
  language = 'plaintext',
  hideLineNumbers = false,
  monacoEditorOptions,
  editorProps
}) => {
  const classes = codeBlockStyles();

  // keeps track of the bottom margin to set for the root element which wraps the Monaco editor:
  //   - the margin and the editor's actual height add up to the editor's full content height
  //   - this enables correct resizing (when increasing the height) if the outside container has dynamic height
  const [marginBottom, setMarginBottom] = useState(0);
  // keeps track of the editor instance s.t. it can be used in the 'rootRef' callback
  const [editor, setEditor] = useState(null);
  // keeps track of the resize observer s.t. it can be disconnected when the component unloads
  const resizeObserverRef = useRef(null);

  const initialize = async (editor, monaco) => {
    // keep track of the editor instance for later use
    setEditor(editor);

    // create and set a theme to be able to change the editor's background color
    monaco.editor.defineTheme('code-dialog-theme', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': backgroundColor
      }
    });
    monaco.editor.setTheme('code-dialog-theme');
  };

  // reference callback for the root element:
  //   - creates a resize observer which resizes the editor based on the outside container's size
  //   - the reference callback is based on an example from the documentation at
  //     https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const rootRef = useCallback(
    rootNode => {
      // check that both the editor and the root node are available
      if (editor !== null && rootNode !== null) {
        // the outside container element
        const containerElement = rootNode.parentElement;
        // create a resize observer
        const resizeObserver = createResizeObserver(() => resizeEditor(editor, rootNode));
        resizeObserver.observe(containerElement);
        // keep track of the resize observer s.t. it can be disconnected when the component unloads
        resizeObserverRef.current = resizeObserver;
      }
    },
    [editor, code]
  );

  // returns the sum of the specified paddings of the specified element
  const getPadding = (element, ...directions) => {
    const style = getComputedStyle(element);
    return _.sum(directions.map(direction => parseFloat(style[`padding${_.capitalize(direction)}`])));
  };

  const resizeEditor = (editor, rootNode) => {
    // get the width and height of the outside container element
    const containerElement = rootNode.parentElement;
    let { width, height } = containerElement.getBoundingClientRect();

    // there might also be a subtitle (neighbor elment) of the root node with a height which needs to be subtracted
    // this case triggers for the modify visualization json dialogue
    const siblingElement = rootNode.previousElementSibling;
    if (siblingElement) {
      height -= siblingElement.getBoundingClientRect().height;
    }

    // subtract the paddings from the outside container and root elements
    width -= getPadding(containerElement, 'left', 'right') + getPadding(rootNode, 'left', 'right');
    height -= getPadding(containerElement, 'top', 'bottom') + getPadding(rootNode, 'top', 'bottom');

    // resize the editor
    editor.layout({ width: width, height: height });
    // calculate the bottom margin s.t. when added up to the height it results in the editor's full content height
    setMarginBottom(editor.getContentHeight() - height);
  };

  // disconnects the resize observer when the compoment unloads
  useEffect(() => {
    return () => resizeObserverRef.current?.disconnect();
  }, []);

  return (
    <div ref={rootRef} className={classes.root} style={{ marginBottom }}>
      <MonacoEditor
        className={classes.editor}
        language={language}
        value={code}
        options={{
          selectOnLineNumbers: true,
          scrollBeyondLastLine: false,
          readOnly: true,
          minimap: {
            enabled: false
          },
          // the minimum number of characters to use for the line numbers
          lineNumbersMinChars: getLines(code).length.toString().length,

          contextmenu: false,
          scrollbar: {
            useShadows: false,
            verticalScrollbarSize: scrollbarSize,
            horizontalScrollbarSize: scrollbarSize,
            alwaysConsumeMouseWheel: false
          },
          ...(hideLineNumbers ? { lineNumbers: 'off' } : {}),
          ...(monacoEditorOptions || {})
        }}
        editorDidMount={initialize}
        {...editorProps}
      />
    </div>
  );
};

const codeDialogStyles = makeStyles({
  root: {
    '& .MuiDialogContent-root': {
      overflow: 'hidden'
    }
  }
});

const CodeDialog = ({
  code,
  language,
  hideLineNumbers,
  t,
  enqueueSnackbar,
  monacoEditorOptions,
  editorProps,
  subtitle,
  ...props
}) => {
  const classes = codeDialogStyles();

  // the width of the code (of the longest line), in number of characters
  const codeWidth = Math.max(...getLines(code).map(line => line.length));

  // copies the specified text to the clipboard
  const copyToClipboard = async text => {
    await navigator.clipboard.writeText(text);
    enqueueSnackbar(t('copied-to-clipboard'), { variant: 'info', autoHideDuration: 2000 });
  };

  // remove props which would lead to a warning when passed along to 'CustomModal'
  const { tReady, closeSnackbar, ...customModalProps } = props;
  return (
    <CustomModal
      className={classes.root}
      // adjust the width of the dialog based on the width of the code
      maxWidth={codeWidth > 110 ? 'lg' : codeWidth > 60 ? 'md' : 'sm'}
      fullWidth
      layout="veezoo"
      content={
        <Fragment>
          {subtitle}
          <CodeBlock
            code={code}
            language={language}
            monacoEditorOptions={monacoEditorOptions}
            editorProps={editorProps}
            hideLineNumbers={hideLineNumbers}
          />
        </Fragment>
      }
      closeLabel={t('close')}
      confirmLabel={t('copy-action')}
      confirmIcon={<FileCopyIcon />}
      onConfirm={async () => {
        await copyToClipboard(code);
        // return false to keep the dialog open
        return false;
      }}
      {...customModalProps}
    />
  );
};

export default withTranslation('veezoo')(withSnackbar(CodeDialog));
