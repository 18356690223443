import { baseURL } from 'config/baseURL';
import { headersGET } from 'store/utils/HTTPheaders';
import { handleUnauthorized } from 'store/utils/authFetch';

import { CHANGE_GRAPH_CLASS_REQUEST, CHANGE_GRAPH_CLASS_SUCCESS, CHANGE_GRAPH_CLASS_FAILURE } from './graphItemClass';
import { CHANGE_LITERAL_REQUEST, CHANGE_LITERAL_SUCCESS, CHANGE_LITERAL_FAILURE } from './literalInfo';
import { CHANGE_MEASURE_REQUEST, CHANGE_MEASURE_SUCCESS, CHANGE_MEASURE_FAILURE } from './measureInfo';

import {
  CHANGE_CLASS_ENTITY_FAILURE,
  CHANGE_CLASS_ENTITY_REQUEST,
  CHANGE_CLASS_ENTITY_SUCCESS
} from './graphDetailedEntitiesInfo';

export const GRAPH_REQUEST = 'GRAPH_REQUEST';
export const GRAPH_LAYOUT_REQUEST = 'GRAPH_LAYOUT_REQUEST';
export const GRAPH_SUCCESS = 'GRAPH_SUCCESS';
export const GRAPH_LAYOUT_SUCCESS = 'GRAPH_LAYOUT_SUCCESS';
export const GRAPH_FAILURE = 'GRAPH_FAILURE';
export const GRAPH_LAYOUT_FAILURE = 'GRAPH_LAYOUT_FAILURE';

export const UPDATE_NODE = 'UPDATE_NODE';
export const UPDATE_ENTITY_SIDEBAR_UNIQUE_VALUE = 'UPDATE_ENTITY_SIDEBAR_UNIQUE_VALUE';
export const SET_ENTITIES_SIDEBAR = 'SET_ENTITIES_SIDEBAR';

export const fetchGraph = () => {
  return dispatch => {
    dispatch({ type: GRAPH_REQUEST });

    fetch(baseURL + 'knowledge-graph/graph', {
      headers: headersGET,
      credentials: 'include'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleUnauthorized(dispatch);
        } else {
          dispatch({
            type: GRAPH_FAILURE
          });
          throw Error(response.statusText);
        }
      })
      .then(result =>
        dispatch({
          type: GRAPH_SUCCESS,
          ...result
        })
      )
      .catch(error => console.log('ERROR', error));
  };
};

export const fetchGraphLayout = () => {
  return dispatch => {
    dispatch({ type: GRAPH_LAYOUT_REQUEST });

    fetch(baseURL + 'knowledge-graph/layout', {
      headers: headersGET,
      credentials: 'include'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleUnauthorized(dispatch);
        } else {
          dispatch({
            type: GRAPH_LAYOUT_FAILURE
          });
          throw Error(response.statusText);
        }
      })
      .then(result =>
        dispatch({
          type: GRAPH_LAYOUT_SUCCESS,
          ...result
        })
      )
      .catch(error => console.log('ERROR', error));
  };
};

export const updateNode = node => dispatch => dispatch({ type: UPDATE_NODE, node });

export const setEntitiesSidebar = node => dispatch => dispatch({ type: SET_ENTITIES_SIDEBAR, node });

export const getSidebarNodes = (nodes, sidebarNodeLayouts) => {
  return sidebarNodeLayouts
    .map(snl => {
      const node = nodes.find(node => node.uri === snl.uri);
      if (node) {
        const children = snl.children.map(c => ({ ...nodes.find(n => n.uri === c.uri), ...c }));
        return { ...node, children };
      } else {
        return null;
      }
    })
    .filter(n => n !== null);
};

export const updateEntitySidebarUniqueValue = node => dispatch =>
  dispatch({ type: UPDATE_ENTITY_SIDEBAR_UNIQUE_VALUE, node });

const updateGraphNodes = (newNode, nodes) => {
  return nodes?.map(node => {
    if (node?.uri === newNode.uri) {
      return {
        ...node,
        ...newNode,
        // this is needed because otherwise we don't overwrite the description from the old node if we removed it
        description: newNode.description || ''
      };
    }
    return node;
  });
};

const entitiesSidebarInitialState = {
  name: '',
  uri: null,
  uniqueId: '',
  inputField: '',
  searchTerm: '',
  uniqueValues: [],
  uniqueValuesTotalCount: 0,
  isOpen: false,
  isFetching: false,
  forcedOpenUri: null,
  preventFetch: false
};

export const initialGraphState = {
  isLoadingGraph: false,
  isLoadingGraphLayout: false,
  updatesLoading: [],
  updatesFailed: [],
  nodes: [],
  edges: [],
  visualNodeLayouts: [],
  sidebarNodeLayouts: [],
  entitiesSidebar: entitiesSidebarInitialState
};

export const graph = (state = initialGraphState, action) => {
  switch (action.type) {
    case GRAPH_REQUEST:
      return { ...state, isLoadingGraph: true };

    case GRAPH_LAYOUT_REQUEST:
      return { ...state, isLoadingGraphLayout: true };

    case GRAPH_FAILURE:
      return { ...state, isLoadingGraph: false };

    case GRAPH_LAYOUT_FAILURE:
      return { ...state, isLoadingGraphLayout: false };

    case GRAPH_SUCCESS: {
      return {
        ...state,
        nodes: action.nodes,
        edges: action.edges,
        sidebarNodeLayouts: action.sidebarNodeLayouts,
        isLoadingGraph: false
      };
    }

    case GRAPH_LAYOUT_SUCCESS: {
      return {
        ...state,
        visualNodeLayouts: action.visualNodeLayouts,
        isLoadingGraphLayout: false
      };
    }

    case CHANGE_GRAPH_CLASS_REQUEST:
    case CHANGE_CLASS_ENTITY_REQUEST:
    case CHANGE_LITERAL_REQUEST:
    case CHANGE_MEASURE_REQUEST: {
      const newLoadingUpdates = [...state.updatesLoading, action.property];
      return { ...state, updatesLoading: newLoadingUpdates };
    }

    case CHANGE_GRAPH_CLASS_SUCCESS: {
      const newNodes = updateGraphNodes(action.class, state.nodes);
      const newLoadingUpdates = state.updatesLoading.filter(item => item !== action.property);
      const newFailedUpdates = state.updatesFailed.filter(item => item !== action.property);

      return {
        ...state,
        nodes: newNodes,
        updatesLoading: newLoadingUpdates,
        updatesFailed: newFailedUpdates
      };
    }

    case CHANGE_GRAPH_CLASS_FAILURE:
    case CHANGE_CLASS_ENTITY_FAILURE:
    case CHANGE_LITERAL_FAILURE:
    case CHANGE_MEASURE_FAILURE: {
      const newLoadingUpdates = state.updatesLoading.filter(item => item !== action.property);
      const newFailedUpdates = [...state.updatesFailed, action.property];

      return {
        ...state,
        updatesLoading: newLoadingUpdates,
        updatesFailed: newFailedUpdates
      };
    }

    case CHANGE_LITERAL_SUCCESS: {
      const newNodes = updateGraphNodes(action.literal, state.nodes);
      const newLoadingUpdates = state.updatesLoading.filter(item => item !== action.property);
      const newFailedUpdates = state.updatesFailed.filter(item => item !== action.property);

      return {
        ...state,
        nodes: newNodes,
        updatesLoading: newLoadingUpdates,
        updatesFailed: newFailedUpdates
      };
    }

    case CHANGE_MEASURE_SUCCESS: {
      const newNodes = updateGraphNodes(action.measure, state.nodes);
      const newLoadingUpdates = state.updatesLoading.filter(item => item !== action.property);
      const newFailedUpdates = state.updatesFailed.filter(item => item !== action.property);

      return {
        ...state,
        nodes: newNodes,
        updatesLoading: newLoadingUpdates,
        updatesFailed: newFailedUpdates
      };
    }

    case CHANGE_CLASS_ENTITY_SUCCESS: {
      const newUniqueValues = updateGraphNodes(action.entity, state.entitiesSidebar.uniqueValues);
      const newLoadingUpdates = state.updatesLoading.filter(item => item !== action.property);
      const newFailedUpdates = state.updatesFailed.filter(item => item !== action.property);

      return {
        ...state,
        entitiesSidebar: {
          ...state.entitiesSidebar,
          uniqueValues: newUniqueValues
        },
        updatesLoading: newLoadingUpdates,
        updatesFailed: newFailedUpdates
      };
    }

    case UPDATE_NODE: {
      const updatedGraphNodes = updateGraphNodes(action.node, state.nodes);

      return { ...state, nodes: updatedGraphNodes };
    }

    case SET_ENTITIES_SIDEBAR: {
      // When closing the EntitiesSidebar, the data has to be reset.
      if (action.node.hasOwnProperty('isOpen') && action.node.isOpen === false) {
        return { ...state, entitiesSidebar: entitiesSidebarInitialState };
      }

      // Is first fetch.
      if (action.node.isOpen && action.node.uri !== state.entitiesSidebar.uri) {
        return {
          ...state,
          entitiesSidebar: {
            ...entitiesSidebarInitialState,
            ...action.node
          }
        };
      }

      return {
        ...state,
        entitiesSidebar: {
          ...state.entitiesSidebar,
          ...action.node
        }
      };
    }

    case UPDATE_ENTITY_SIDEBAR_UNIQUE_VALUE: {
      const uniqueValues = updateGraphNodes(action.node, state.entitiesSidebar.uniqueValues);
      return { ...state, entitiesSidebar: { ...state.entitiesSidebar, uniqueValues } };
    }

    default:
      return state;
  }
};
