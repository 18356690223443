import GlobeIcon from 'svg/knowledgeGraph/globe.svg';
import globeIcon from 'svg/knowledgeGraph/globe.svg?raw';
import BooleanIcon from 'svg/knowledgeGraph/boolean.svg';
import booleanIcon from 'svg/knowledgeGraph/boolean.svg?raw';
import NumberIcon from 'svg/knowledgeGraph/number.svg';
import numberIcon from 'svg/knowledgeGraph/number.svg?raw';
import CalendarIcon from 'svg/knowledgeGraph/calendar.svg';
import calendarIcon from 'svg/knowledgeGraph/calendar.svg?raw';
import MoneyIcon from 'svg/knowledgeGraph/money.svg';
import moneyIcon from 'svg/knowledgeGraph/money.svg?raw';
import PersonIcon from 'svg/knowledgeGraph/person.svg';
import personIcon from 'svg/knowledgeGraph/person.svg?raw';
import TextIcon from 'svg/knowledgeGraph/text.svg';
import textIcon from 'svg/knowledgeGraph/text.svg?raw';
import { Category, CategoryOutlined } from '@material-ui/icons';

export const OntologyEntity = 'meta:KB_Entity';
export const OntologyClass = 'meta:KB_Class';
export const OntologyDate = 'meta:Date';
export const OntologyBoolean = 'xsd:boolean';

export const availableGroups = {
  'xsd:integer': {
    icon: numberIcon,
    componentIcon: NumberIcon,
    color: '#FFA34C',
    translationKey: 'integer'
  },
  'xsd:double': {
    icon: numberIcon,
    componentIcon: NumberIcon,
    color: '#FFA34C',
    translationKey: 'decimal'
  },
  'xsd:long': {
    icon: numberIcon,
    componentIcon: NumberIcon,
    color: '#FFA34C',
    translationKey: 'integer'
  },
  'xsd:string': {
    icon: textIcon,
    componentIcon: TextIcon,
    color: '#5CACE7',
    translationKey: 'text'
  },
  'xsd:boolean': {
    icon: booleanIcon,
    componentIcon: BooleanIcon,
    color: '#4ca356',
    translationKey: 'boolean'
  },
  'meta:Date': {
    icon: calendarIcon,
    componentIcon: CalendarIcon,
    color: '#E36B67',
    translationKey: 'date'
  },
  'onto:Location': {
    icon: globeIcon,
    componentIcon: GlobeIcon,
    translationKey: 'location'
  },
  'onto:Money': {
    icon: moneyIcon,
    componentIcon: MoneyIcon,
    color: '#FFA34C',
    translationKey: 'money'
  },
  'onto:Customer': {
    icon: personIcon,
    componentIcon: PersonIcon,
    translationKey: 'customer'
  },
  'meta:KB_Entity': {
    color: '#172e73',
    componentIcon: props => <CategoryOutlined {...props} />
  },
  'meta:KB_Class': {
    componentIcon: props => <Category {...props} />
  },
  none: null
};

export const hasCustomGroup = group => (availableGroups[group] ? group : 'none');
