import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/tooltips/InfoTooltip';
import Button from 'components/buttons/Button';

import useMediaQuery from 'utils/mediaQueries';

const ShareButton = ({ id, isSharedWithOthers, showModal, tooltipText, t }) => {
  const isMobile = useMediaQuery();
  return (
    <InfoTooltip text={tooltipText}>
      <Button
        data-test="shareButton"
        className="button-secondary"
        onClick={() => showModal(id)}
        showOnlyIcon={isMobile}
        text={isSharedWithOthers ? t('sharing.update') : t('sharing.share')}
      >
        <span className="icon-shared-board" />
      </Button>
    </InfoTooltip>
  );
};

export default withTranslation('veezoo')(ShareButton);

ShareButton.propTypes = {
  id: PropTypes.string, // the id of the shareable object, e.g. boardId or customerSelectionId
  tooltipText: PropTypes.string, // translated text for the tooltip for a shareable object, e.g. t("tooltip.share-board")
  isSharedWithOthers: PropTypes.bool,
  getPermissions: PropTypes.func, // the API call to get the shared permissions for this object
  showModal: PropTypes.func,
  dispatch: PropTypes.func,
  t: PropTypes.func
};
