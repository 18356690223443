import { createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { extendCustomersOfTheDay } from 'store/modules/customers-of-the-day';
import { openCustomerSelectionView } from 'store/modules/customer-selections';
import BaseMessage from 'components/message/BaseMessage';
import Button from 'components/buttons/Button';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import CustomerRow from 'components/customers/CustomerRow';

import styles from './customers-of-the-day.scss';
import footerStyle from '../message/answer-message-footer.scss';
import answerStyle from '../message/answer-message.scss';
import answerExpl from '../shared/answer-explanation.scss';
import PrintButton from '../buttons/PrintButton';

export const CustomersOfTheDay = ({
  t,
  customersList,
  extendCustomersList,
  displayMoreCustomers,
  isLoading,
  isLoadingExtendCustomersList,
  meta,
  openCustomerSelectionView,
  timestamp
}) => {
  const codMessageRef = createRef();

  const customFooter = (
    <aside className={footerStyle.buttonsContainer}>
      <ul className={footerStyle.list}>
        <li className={footerStyle.item}>
          <InfoTooltip text={t('tooltip.extend-customers-of-the-day')}>
            <Button
              data-test="codShowMore"
              data-cod-tutorial="show-more"
              disabled={isLoadingExtendCustomersList}
              text={isLoadingExtendCustomersList ? t('loading') : t('customers-of-the-day.show-more-button')}
              onClick={() => displayMoreCustomers()}
              className={`${'button-secondary'}`}
            >
              <span className="icon-dots-horizontal" />
            </Button>
          </InfoTooltip>
        </li>
        <li className={footerStyle.item}>
          <a
            className="button-secondary"
            data-cod-tutorial="feedback"
            href={meta.htmlSupport}
            rel="noopener noreferrer"
          >
            <span className="icon-support" />
            <span className="text">{t('sidebar.feedback')}</span>
          </a>
        </li>
        <li className={footerStyle.item}>
          <InfoTooltip text={t('tooltip.configure-customer-selections-in-cod')}>
            <Button
              data-cod-tutorial="configure"
              text={t('customers-of-the-day.configure')}
              onClick={() => openCustomerSelectionView()}
              className={`${'button-secondary'}`}
            >
              <span className="icon-gear" />
            </Button>
          </InfoTooltip>
        </li>
        <li className={footerStyle.item}>
          <PrintButton targetRef={codMessageRef} />
        </li>
      </ul>
    </aside>
  );

  const content = (
    <>
      <header data-cod-tutorial="whole-message">
        <span className={styles.header}>
          <span className="icon-star-message icon-message-layout" />
          <span className={`${answerStyle.messageTitleWithIcon} ${answerExpl.answerTitle}`}>
            {t('customers-of-the-day.title')}
          </span>
        </span>
        <p className={styles.description}>{t('customers-of-the-day.description')}</p>
      </header>

      <div className={answerStyle.messageAnswer}>
        {isLoading ? (
          <span data-test="contentLoading">{t('loading')}</span>
        ) : (
          <table data-test="customersOfTheDayTable" className={styles.codTable}>
            <thead>
              <tr>
                <th className={`${styles.nameHeader} ${styles.col}`}>{t('customers-of-the-day.name')}</th>
                <th className={`${styles.filtersHeader} ${styles.col}`}>{t('customers-of-the-day.filters')}</th>
              </tr>
            </thead>

            <tbody>
              {customersList &&
                customersList.map((customer, index) => {
                  return (
                    <CustomerRow
                      key={customer.name + index}
                      name={customer.name}
                      url={customer.uri}
                      referencedSelections={customer.referencedSelections}
                    />
                  );
                })}

              {extendCustomersList &&
                extendCustomersList.map((customer, index) => {
                  return (
                    <CustomerRow
                      key={customer.name + index}
                      name={customer.name}
                      url={customer.uri}
                      referencedSelections={customer.referencedSelections}
                    />
                  );
                })}

              {isLoadingExtendCustomersList && (
                <tr>
                  <td colSpan="3">
                    <div className={styles.displayMoreCustomers}>{t('loading')}</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );

  return (
    <span ref={codMessageRef}>
      <BaseMessage content={content} enableStandardFooter={false} customFooter={customFooter} timestamp={timestamp} />
    </span>
  );
};

export const mapStateToProps = state => ({
  isLoadingExtendCustomersList: state.customersOfTheDay.isLoadingExtendCustomersList,
  meta: state.knowledgeGraphMeta.meta,
  extendCustomersList: state.customersOfTheDay.extendCustomersList
});

export const mapDispatchToProps = dispatch => ({
  displayMoreCustomers: () => dispatch(extendCustomersOfTheDay()),
  openCustomerSelectionView: () => dispatch(openCustomerSelectionView())
});

export default withTranslation('veezoo', { withRef: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomersOfTheDay)
);

CustomersOfTheDay.propTypes = {
  t: PropTypes.func,
  extendCustomersList: PropTypes.array.isRequired,
  customersList: PropTypes.array,
  isLoadingExtendCustomersList: PropTypes.bool,
  isLoading: PropTypes.bool,
  displayMoreCustomers: PropTypes.func.isRequired,
  openCustomerSelectionView: PropTypes.func.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number])
};
