import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Button from 'components/v2/Button';

import styles from './user-message.scss';
import { showOrHideMessage } from 'store/modules/chat-messages';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core';

const hiddenMessageButtonStyle = makeStyles({
  root: {
    color: 'var(--font-color)',
    border: 'solid 1px var(--border-color)',
    background: 'white',
    fontWeight: '400',
    fontSize: 14
  }
});

export function HiddenMessage({ dispatch, hiddenMessageIds, t }) {
  const classes = hiddenMessageButtonStyle();
  const answerMessageIds = hiddenMessageIds.answerMessageIds;

  return (
    <div className={styles.wrapper}>
      <div className={styles.hiddenMessageContainer}>
        <Button
          label={
            answerMessageIds.length > 1
              ? t('hide.unhide-answers', { count: answerMessageIds.length })
              : t('hide.unhide-answer')
          }
          classes={classes}
          onClick={() => {
            [...hiddenMessageIds.answerMessageIds, ...hiddenMessageIds.localMessageIds].forEach(id =>
              dispatch(showOrHideMessage(false, id))
            );
          }}
          icon={<VisibilityIcon />}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ chatMessages: state.chatMessages });

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(HiddenMessage));
