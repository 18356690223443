import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const LabelButton = ({ text, color, icon: Icon, onClick, ...rest }) => {
  const styles = useStyles({ color, Icon });

  const hasIcon = useMemo(() => Icon && cloneElement(Icon, { className: `${styles.icon}` }), [Icon]);
  return (
    <div className={styles.container} onClick={onClick}>
      {hasIcon}
      <span className={styles.text} {...rest}>
        {text}
      </span>
    </div>
  );
};

export default LabelButton;

LabelButton.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func
};

LabelButton.defaultProps = {
  onClick: () => {}
};
