import services from 'services';

export const NUMBER_FORMATS_REQUEST = 'NUMBER_FORMATS_REQUEST';
export const NUMBER_FORMATS_SUCCESS = 'NUMBER_FORMATS_SUCCESS';
export const NUMBER_FORMATS_FAILURE = 'NUMBER_FORMATS_FAILURE';

export const fetchNumberFormats = () => async dispatch => {
  dispatch({ type: NUMBER_FORMATS_REQUEST });

  const response = await services.getNumberFormats();
  if (!response.success) {
    return dispatch({ type: NUMBER_FORMATS_FAILURE });
  } else {
    return dispatch({
      type: NUMBER_FORMATS_SUCCESS,
      data: response.data
    });
  }
};

// reducer
export const defaultLocalizationState = {
  numberFormats: []
};

export function localization(state = defaultLocalizationState, action) {
  switch (action.type) {
    case NUMBER_FORMATS_SUCCESS:
      return {
        ...state,
        numberFormats: action.data.data
      };
    default:
      return state;
  }
}
