import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.modules.scss';

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    margin: '0 20px 20px 0',
    float: 'left'
  }
});

const DataSourceLoader = () => {
  const classes = useStyles();
  return (
    <div className={styles.loaderContainer}>
      {[...Array(6)].map((_, index) => (
        <Skeleton key={index} classes={classes} variant="rect" width={185} height={135} />
      ))}
      <div style={{ clear: 'both' }} />
    </div>
  );
};

const BlockGrid = ({ items, loading, ...props }) => {
  return (
    <div className={styles.container} {...props}>
      {loading ? (
        <DataSourceLoader />
      ) : (
        items.map((item, index) => (
          <div key={`block_grid_${index}`} className={styles.block} onClick={item.onClick}>
            {item.image ? <img alt={item.name} src={item.image} /> : item.name // If no logo is given, fall back to name
            }
          </div>
        ))
      )}
    </div>
  );
};

export default BlockGrid;

BlockGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool
};

BlockGrid.defaultProps = {
  items: [],
  loading: false
};
