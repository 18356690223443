// Important to notice that in the EntitiesSidebar, we use the "onEntered" and "onExit" functions.
// The onEntered happens AFTER the component renders, and the onExit happens BEFORE it closes.
// This guarantees that in-between effects (such as the display of the InfoPanel) only happens when the sidebar is
// open and settled in the viewport, without interference of transition effects. Yes, we had a bug in its display because of the Sidebar's 200ms transition effect.

import { useState, cloneElement, memo } from 'react';
import withEmbedded from 'root/hocs/withEmbedded';
import Drawer from 'components/Drawer';

import useStyles from './styles';

const BodyContentSidebar = ({ children, open, onClose, isEmbedded }) => {
  const styles = useStyles();

  const [hasEntered, setHasEntered] = useState(false);

  const onEntered = () => setHasEntered(true);
  const onExit = () => setHasEntered(false);

  const childrenWithProps = cloneElement(children, { onClose, hasEntered });

  // Backdrop is implemented separately, because Drawer only has backdrop when its variant is "temporary", the same
  // property that sets the position to "fixed" and attaches the drawer to the edge of the viewport.
  return (
    <>
      {open && !isEmbedded && <div onClick={onClose} className={styles.backdrop} />}
      <Drawer
        open={open}
        onClose={onClose}
        transitionDuration={300}
        anchor="left"
        paperClasses={styles.paper}
        SlideProps={{ onEntered, onExit }}
      >
        {childrenWithProps}
      </Drawer>
    </>
  );
};

export default withEmbedded(memo(BodyContentSidebar));
