import InfoTooltip from 'components/tooltips/InfoTooltip';
import useMediaQuery from 'utils/mediaQueries';

import styles from './styles.scss';

export const ConceptNotInDataSpan = ({ children, contentState, entityKey, offsetKey }) => {
  const isMobile = useMediaQuery();

  const description = contentState.getEntity(entityKey).getData().description; // eslint-disable-line
  return (
    <InfoTooltip text={description} open={!isMobile} className={styles.inputTooltip}>
      <span data-offset-key={offsetKey} className={styles.notInData}>
        {children}
      </span>
    </InfoTooltip>
  );
};
