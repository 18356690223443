import { useState, useEffect, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Input from 'components/v2/Input';
import Title from 'components/v2/Typography/Title';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import SmartFixButton from 'components/SmartFix/SmartFixButton';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import CloseIcon from 'svg/v2/close.svg';

import { setIsEditingTitle } from 'store/modules/nodeEditor';

import handleKeyDown from 'utils/handleKeyDown';
import { trackEvent } from 'utils/eventTracking';

import EditIcon from '../EditIcon';
import styles from '../styles.scss';

const NodeTitle = ({
  name,
  uri,
  subtitleIcon: SubtitleIcon,
  subtitleText,
  hasWritePermission,
  updateLabel,
  updatesLoading,
  updatesFailed,
  isEditingTitle,
  hasFetched,
  setIsExpanded,
  hasBadName,
  suggestedNames,
  dispatch,
  t
}) => {
  const hasNameSuggestion = suggestedNames?.length > 0;
  // only consider the first suggestion for now
  const suggestedName = suggestedNames?.[0];

  const [labelInputValue, setLabelInputValue] = useState('');

  const loading = useMemo(() => updatesLoading.some(item => item === 'label'), [updatesLoading]);
  const failedLoading = updatesFailed.some(item => item === 'label');

  // Setting the "value" variable outside of the state is required because of React's event pooling. Otherwise, event.target would return null even when there's a value in it. Check the answer in this link:
  // https://stackoverflow.com/questions/44708518/event-target-is-null-inside-functional-setstate
  const handleInputChange = event => {
    const value = event.target.value;
    setLabelInputValue(value);
  };

  const editName = () => {
    trackEvent('Info Panel Name Input Clicked', { name, uri });
    dispatch(setIsEditingTitle(true));
    setIsExpanded(true);
  };

  const saveLabel = () => {
    if (labelInputValue && labelInputValue.trim() !== '' && labelInputValue !== name) {
      if (hasBadName) {
        // the user edited a name that was marked as bad
        trackEvent('Info Panel Bad Name Edited', { oldName: name, newName: labelInputValue });
      }
      updateLabel(labelInputValue);
    }
    dispatch(setIsEditingTitle(false));
  };

  const resetLabel = () => {
    dispatch(setIsEditingTitle(false));
    setLabelInputValue(name);
  };

  const acceptNameSuggestion = e => {
    // stop propagating the click event as otherwise the NodeTitle root div will catch the click as well
    e.stopPropagation();
    trackEvent('Info Panel Bad Name Suggestion Accepted', { oldName: name, acceptedName: suggestedName });
    updateLabel(suggestedName);
  };

  // the initial value of the input is usually empty. This fills the input as soon as we get "name"'s value
  useEffect(() => {
    setLabelInputValue(name);
  }, [name]);

  const clearInputValue = () => setLabelInputValue('');

  return (
    <div
      className={clsx(
        styles.section,
        hasWritePermission ? styles.allowEditing : '',
        isEditingTitle ? styles.isEditing : ''
      )}
      onClick={() => (hasWritePermission && hasFetched && !loading ? editName() : null)}
    >
      {isEditingTitle ? (
        <div className={styles.horizontalPadding}>
          <ClickAwayListener onClickAway={saveLabel}>
            <Input
              autoFocus
              layout="title"
              value={labelInputValue}
              onChange={handleInputChange}
              onKeyDown={event => handleKeyDown(event, saveLabel, resetLabel)}
              endIcon={<CloseIcon className={styles.closeIcon} onClick={clearInputValue} />}
              data-test="KnowledgeGraphConceptNameInput"
            />
          </ClickAwayListener>
        </div>
      ) : (
        <>
          <div
            className={clsx(styles.flexSpaceBetween, styles.horizontalPadding)}
            data-test="KnowledgeGraphConceptName"
          >
            {hasWritePermission && hasBadName ? (
              <InfoTooltip text={t('smart-fixes.bad-name-tooltip')}>
                <div className={styles.titleContainer}>
                  <Title className={styles.titleWithSuggestion} data-test="TitleWithBadLabelIndicator">
                    {name}
                  </Title>
                </div>
              </InfoTooltip>
            ) : (
              <Title>{name}</Title>
            )}
            {hasWritePermission && hasFetched && <EditIcon loading={loading} warning={failedLoading} />}
          </div>

          {hasWritePermission && hasNameSuggestion && (
            <div className={styles.horizontalPadding}>
              <SmartFixButton newName={suggestedName} onClick={acceptNameSuggestion} />
            </div>
          )}
        </>
      )}

      {!!subtitleText && (
        <div className={styles.horizontalPadding}>
          <div className={styles.subtitle}>
            <SubtitleIcon className={styles.icon} />
            <span>{subtitleText}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission,
  updatesLoading: state.graph.updatesLoading,
  updatesFailed: state.graph.updatesFailed,
  isEditingTitle: state.nodeEditor.isEditingTitle
});

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(NodeTitle)));
