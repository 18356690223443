import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { getSimilarCustomer } from 'store/modules/customers-of-the-day';
import QuestionPill from 'components/pills/QuestionPill';

import commonStyles from './customers-of-the-day.scss';
import styles from './customer-row.scss';
import InfoTooltip from 'components/tooltips/InfoTooltip';

const propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  url: PropTypes.string,
  referencedSelections: PropTypes.array,
  findSimilar: PropTypes.func.isRequired
};

export class CustomerRow extends Component {
  renderReferencedSelections(referencedSelections) {
    const filtersWrap =
      referencedSelections !== undefined
        ? referencedSelections.map((selection, index) => (
            <InfoTooltip place="right" text={this.props.t('tooltip.customer-selection-pill')} key={index}>
              <div className={styles.pill}>
                <QuestionPill
                  additionalClassName={styles.selection}
                  iconClassName="icon-people-group-message"
                  suggestion={{
                    inputOffset: 0,
                    passageInfos: [],
                    text: selection.name
                  }}
                  onClick={() => this.props.findSimilar(selection.customerSelectionId)}
                />
              </div>
            </InfoTooltip>
          ))
        : [];
    return <div className={styles.filters}>{filtersWrap}</div>;
  }

  render() {
    const { name, url, referencedSelections, t } = this.props;

    let nameColumn = name;
    if (url) {
      nameColumn = (
        <a
          data-cod-tutorial="crm-link"
          href={url}
          title={t('customers-of-the-day.link')}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      );
    }

    return (
      <tr>
        <td className={`${styles.name} ${commonStyles.col}`} data-test="codName">
          {nameColumn}
        </td>

        <td className={commonStyles.col} data-cod-tutorial="criteria">
          {this.renderReferencedSelections(referencedSelections)}
        </td>
      </tr>
    );
  }
}

CustomerRow.propTypes = propTypes;

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    findSimilar: recipe => dispatch(getSimilarCustomer(recipe))
  };
}

export default withTranslation('veezoo')(
  connect(
    null,
    mapDispatchToProps
  )(CustomerRow)
);
