import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    pointerEvents: ({ allowBackgroundInteraction }) => (allowBackgroundInteraction ? 'none' : 'auto')
  },
  paper: {
    pointerEvents: 'auto',
    borderRadius: 6,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 12
  }
});

export const useButtonContainerStyles = makeStyles({
  popoverButtonContainer: {
    maxHeight: '100%'
  }
});

export const useBackdropStyles = makeStyles({
  backdrop: {
    zIndex: 3,
    color: '#fff'
  }
});
