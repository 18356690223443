import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useAutocompleteTooltipStyles, useTooltipStyles } from 'components/tooltips/Q2QTooltip/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Paper } from '@material-ui/core';
import MarkdownHtml from 'studio/components/MarkdownHtml/MarkdownHtml';

const Q2QTooltip = forwardRef(({ children, title, description, placement, tooltipForAutocomplete, ...rest }, ref) => {
  const tooltipClasses = tooltipForAutocomplete ? useAutocompleteTooltipStyles() : useTooltipStyles();

  /* The description set in the span is html sanitized by the backend */
  const tooltipContent = (
    <Paper elevation={5} className={tooltipClasses.paper}>
      <h4>{title}</h4>
      <MarkdownHtml html={description} />
    </Paper>
  );

  return (
    <Tooltip
      ref={ref}
      title={tooltipContent}
      placement={placement}
      classes={{ tooltip: tooltipClasses.tooltip }}
      interactive
      {...rest}
    >
      {children}
    </Tooltip>
  );
});

export default Q2QTooltip;

Q2QTooltip.propTypes = {
  children: PropTypes.any,
  tooltipForAutocomplete: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.string
};

Q2QTooltip.defaultProps = {
  title: '',
  description: '',
  placement: 'top'
};
