import { useRef, memo } from 'react';
import { connect } from 'react-redux';

import NodeContent from 'components/NodeEditorPopover/NodeContent';
import PopoverPosition from 'components/Popover/PopoverPosition';

const InfoPanelPopover = ({ nodeEditor, PopoverProps, ContentProps }) => {
  const updatePositionRef = useRef(null);
  const { isEditingTitle, isEditingDescription, isEditingSynonyms } = nodeEditor;

  return (
    <PopoverPosition
      updatePositionRef={updatePositionRef}
      isEditing={isEditingTitle || isEditingDescription || isEditingSynonyms}
      {...PopoverProps}
    >
      <NodeContent updatePositionRef={updatePositionRef} {...ContentProps} />
    </PopoverPosition>
  );
};

const mapStateToProps = state => ({ nodeEditor: state.nodeEditor });

export default connect(mapStateToProps)(memo(InfoPanelPopover));
