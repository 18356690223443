import { baseURL } from 'config/baseURL';
import { headersPOST } from '../utils/HTTPheaders';
import { authFetch } from '../utils/authFetch';
import { url } from './../urls';

export const EXCEPTION_REQUEST = 'EXCEPTION_REQUEST';
export const EXCEPTION_SUCCESS = 'EXCEPTION_SUCCESS';

export function captureException(data) {
  return dispatch => {
    dispatch({
      type: EXCEPTION_REQUEST,
      payload: data
    });

    return authFetch(
      baseURL + url.EXCEPTION,
      {
        method: 'POST',
        headers: headersPOST,
        credentials: 'include',
        body: JSON.stringify(data)
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: EXCEPTION_SUCCESS,
            payload: data
          });
          throw Error(response.statusText);
        }
      })
      .then(payload => {
        return null;
      })
      .catch(function(error) {
        return console.log('ERROR', error);
      });
  };
}

export const initException = {
  exceptions: [],
  isCaptchingException: false
};

export function exception(state = initException, action) {
  switch (action.type) {
    case EXCEPTION_REQUEST: {
      return {
        ...state,
        isCaptchingException: true
      };
    }

    default:
      return state;
  }
}
