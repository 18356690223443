import { makeStyles } from '@material-ui/core';

const borderRadius = 2;

export default makeStyles({
  root: {
    width: ({ width }) => width || '100%'
  },
  inputRoot: {
    border: 'solid thin var(--light-gray)',
    borderRadius,
    '& > input': {
      padding: '0 !important',
      boxSizing: 'border-box'
    }
  },
  borderWithError: {
    border: 'solid thin red'
  },
  inputUnderline: {
    '&&.MuiInput-underline::before': {
      borderBottom: 'solid 0px transparent'
    },
    '&&.MuiInput-underline::after': {
      borderBottom: 'solid 0px transparent'
    }
  },
  labelRoot: {
    fontSize: '20px',
    '&&+.MuiInput-formControl': {
      marginTop: '22px'
    }
  },
  adornmentRoot: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  formHelperTextRoot: {
    fontSize: '12px'
  },
  menuProps: {
    '&& .MuiMenuItem-root': {
      fontSize: '14px'
    }
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.18)'
  },
  selectRoot: {
    color: 'var(--primary-color)',
    fontFamily: 'lexend',
    fontSize: 14,
    padding: '8px 12px',
    paddingRight: '34px !important',
    lineHeight: '17px'
  },
  arrowIcon: {
    top: 'initial',
    width: '13.5px',
    height: '13.5px',
    marginRight: 10,
    fill: 'var(--primary-color)',
    justifyContent: 'flex-start',
    alignItems: 'end',
    '& > path': {
      fill: 'var(--primary-color)'
    }
  }
});
