import { dateTimeToObject, getWeekOfTheYear, formatDateToWeekString } from 'utils/datetimeUtils';
import { datetimeViews, dateTypes } from 'config/constants';

export const selectableViews = {
  day_month_year: {
    value: datetimeViews.day_month_year,
    views: [dateTypes.date, dateTypes.month, dateTypes.year],
    outputFormat: (datetime, allowTime) => {
      const date = dateTimeToObject(datetime);
      let payloadString = `${date.year}-${date.month}-${date.day}`;
      let readableString = `${date.day}/${date.month}/${date.year}`;
      if (allowTime) {
        payloadString += ` ${date.hour}:${date.minute}`;
        readableString += ` at ${date.hour}:${date.minute}`;
      }
      return { payloadString, readableString };
    }
  },
  month_year: {
    value: datetimeViews.month_year,
    views: [dateTypes.month, dateTypes.year],
    outputFormat: datetime => {
      const date = dateTimeToObject(datetime);
      return { payloadString: `${date.year}-${date.month}`, readableString: `${date.month}/${date.year}` };
    }
  },
  year: {
    value: datetimeViews.year,
    views: [dateTypes.year],
    outputFormat: datetime => {
      const date = dateTimeToObject(datetime);
      return { payloadString: `${date.year}`, readableString: `${date.year}` };
    }
  },
  week: {
    value: datetimeViews.week,
    views: [dateTypes.week],
    outputFormat: datetime => {
      const weekOfTheYear = getWeekOfTheYear(datetime);
      return {
        payloadString: formatDateToWeekString(weekOfTheYear.year, weekOfTheYear.week),
        readableString: formatDateToWeekString(weekOfTheYear.year, weekOfTheYear.week)
      };
    }
  },
  hour_minute: {
    value: datetimeViews.hour_minute,
    views: [dateTypes.hour, dateTypes.minute],
    outputFormat: datetime => {
      const date = dateTimeToObject(datetime);
      return {
        payloadString: `${date.hour}:${date.minute}`,
        readableString: `${date.hour}:${date.minute}`
      };
    }
  }
};
