export const OPEN_CUSTOMER_SELECTION_VIEW = 'OPEN_CUSTOMER_SELECTION_VIEW';
export const STOP_CUSTOMER_SELECTION_VIEW = 'STOP_CUSTOMER_SELECTION_VIEW';

export function openCustomerSelectionView() {
  return dispatch => {
    dispatch({
      type: OPEN_CUSTOMER_SELECTION_VIEW
    });
  };
}

export function stopCustomerSelectionView() {
  return dispatch => {
    dispatch({
      type: STOP_CUSTOMER_SELECTION_VIEW
    });
  };
}

export const initCustomerSelectionsState = {
  isOpenCustomerSelectionRoute: false
};

export function customerSelections(state = initCustomerSelectionsState, action) {
  switch (action.type) {
    case OPEN_CUSTOMER_SELECTION_VIEW:
      return {
        ...state,
        isOpenCustomerSelectionRoute: true
      };

    case STOP_CUSTOMER_SELECTION_VIEW:
      return {
        ...state,
        isOpenCustomerSelectionRoute: false
      };

    default:
      return state;
  }
}
