// The purpose of this class is to supply all the info needed to the KnowledgeGraphSidebar component in the autocomplete
// this should  be refactored such that also the non-autocomplete sidebar uses this wrapper

import KnowledgeGraphSidebar from 'components/KnowledgeGraphSidebar/index';
import { fetchDataForClass } from 'store/modules/chat-messages';
import { tutorialNames } from 'config/constants';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setEntitiesSidebar } from 'store/modules/graph/graph';
import { searchGraph } from 'store/modules/graph/graphSearchResults';
import { Fragment, memo } from 'react';
import BodyContentSidebar from 'components/BodyContentSidebar';
import EntitiesSidebar from 'components/EntitiesSidebar';

const ContentSidebar = memo(({ isOpen, onClose }) => (
  <BodyContentSidebar open={isOpen} onClose={onClose}>
    <EntitiesSidebar />
  </BodyContentSidebar>
));

function KnowledgeGraphSidebarWrapper({ t, graph, graphSearchResults, dispatch }) {
  const { nodes, sidebarNodeLayouts, isLoadingGraph } = graph;
  let searchGraphTimeout = null;

  const handleShowData = classUri => {
    dispatch(fetchDataForClass(classUri, t));
    dispatch(setEntitiesSidebar({ isOpen: false }));
  };

  const handleSearch = e => {
    if (searchGraphTimeout) {
      clearTimeout(searchGraphTimeout);
    }
    // Delay search by 500ms
    searchGraphTimeout = setTimeout(
      (query, searchGraph, dispatch) => dispatch(searchGraph(query, 0)),
      500,
      e.target.value,
      searchGraph,
      dispatch
    );
  };

  const kgSidebar = (
    <KnowledgeGraphSidebar
      anchor="left"
      loading={isLoadingGraph}
      nodes={nodes}
      // disable suggested names in embedded mode
      suggestedNames={{}}
      sidebarNodeLayouts={sidebarNodeLayouts}
      graphSearchResults={graphSearchResults}
      t={t}
      handleSearch={handleSearch}
      open={true}
      isEmbedded={true} // we only render this in embedded mode
      showData={handleShowData}
    />
  );
  return (
    <Fragment>
      {kgSidebar}
      <ContentSidebar
        isOpen={graph.entitiesSidebar.isOpen}
        onClose={() => dispatch(setEntitiesSidebar({ isOpen: false }))}
      />
    </Fragment>
  );
}

const mapStateToProps = state => ({
  graph: state.graph,
  graphSearchResults: state.graphSearchResults,
  meta: state.knowledgeGraphMeta.meta,
  isOpenCustomerSelectionRoute: state.customerSelections.isOpenCustomerSelectionRoute,
  hasUncompletedTutorial: state.tutorial.uncompletedTutorials.some(tut => tut.id === tutorialNames.OVERVIEW_TUTORIAL),
  hasFetchedUser: state.user.hasFetchedUser
});

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(KnowledgeGraphSidebarWrapper)));
