import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { connect } from 'react-redux';

import { updateLiteral } from 'store/modules/graph/literalInfo';
import { updateMeasure } from 'store/modules/graph/measureInfo';
import { updateClass } from 'store/modules/graph/graphItemClass';
import { updateEntity } from 'store/modules/graph/graphDetailedEntitiesInfo';
import { clearBadNameProblem } from 'store/modules/recipes';

import NodeTitle from './NodeTitle';
import NodeDescription from './NodeDescription';
import NodeSynonyms from './NodeSynonyms';
import NodeUniqueValues from './NodeUniqueValues';
import JumpToStudio from 'components/buttons/JumpToStudio';

import { trackEvent } from 'utils/eventTracking';

import DoubleArrowDown from 'svg/double_arrow_down.svg';

import services from 'services';

import { graphNodeTypes } from 'config/constants';

import styles from '../styles.scss';

const NodeContent = ({
  node,
  uniqueId,
  nodeType,
  subtitleIcon: SubtitleIcon,
  subtitleText,
  updatePositionRef,
  updateNode,
  expandFromOutside,
  expandedByDefault,
  suggestionsForBadNames,
  language,
  dispatch
}) => {
  const { CLASS, ENTITY, LITERAL, MEASURE } = graphNodeTypes;

  const nodeUri = useMemo(() => node.uri, [node.uri]);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => setIsExpanded(true);

  const hasBadName = nodeUri in suggestionsForBadNames;
  const suggestedNames = suggestionsForBadNames?.[nodeUri];

  /**
   * The correct fetch / update function depending on whether the concept is a class, entity, literal or measure.
   */
  const [fetchConcept, updateConcept] = useMemo(() => {
    switch (nodeType) {
      case CLASS:
        return [services.fetchClass, updateClass];
      case ENTITY:
        return [services.getEntity, updateEntity];
      case LITERAL:
        return [services.fetchLiteral, updateLiteral];
      case MEASURE:
        return [services.fetchMeasure, updateMeasure];
      default:
        return null;
    }
  }, [nodeType]);

  /**
   * Fetches the concept (a class, entity, literal or measure) represented by this node, mounts
   * the state payload using the initialNodeState as a boilerplate and updates this node's state
   * in Redux.
   */
  const fetchNodeState = useCallback(async () => {
    const response = await fetchConcept(nodeUri);
    const concept = response.data;

    // abort if concept is undefined (i.e. the fetch failed)
    if (!concept) return;

    const newNode = {
      uri: nodeUri,
      type: nodeType || null,
      name: concept.name || node.name,
      description: concept.description,
      synonyms: concept.synonyms,
      dataType: concept.dataType,
      hasFetched: true,
      uniqueValues: [],
      uniqueValuesTotalCount: 0,
      file: concept.file,
      vklQualifiedIdentifier: concept.vklQualifiedIdentifier
    };

    if (nodeType === CLASS) {
      const response = await services.getEntitiesByClass({ classUri: nodeUri, start: 0, limit: 10 });
      newNode.uniqueValues = response.data.results || [];
      newNode.uniqueValuesTotalCount = response.data.totalCount || 0;
    }

    dispatch(updateNode(newNode));
  }, [node, nodeType]);

  /** Updates name in redux state and saves on the API. */
  const updateName = name => {
    dispatch(updateConcept(nodeUri, 'name', name));
    const newNode = { ...node, name: name };
    dispatch(updateNode(newNode));
    // remove any name problem (without issuing a recipe request; any resulting new recipes will only be shown upon
    // page refresh or KG change)
    dispatch(clearBadNameProblem(nodeUri, language));
  };

  /** Updates description in redux state and saves on the API. */
  const updateDescription = description => {
    dispatch(updateConcept(nodeUri, 'description', description || null))
      // we need to wait for the rendered HTML of the new description from the backend
      .then(concept => {
        // the concept is undefined if the update failed
        if (concept) {
          const newNode = { ...node, description: concept.description };
          dispatch(updateNode(newNode));
        }
      });
  };

  /** Adds synonym to redux state before sending to the API. */
  const addSynonym = synonym => {
    const existingSynonyms = node.synonyms || [];
    const newSynonyms = [...existingSynonyms, synonym].sort();
    const newNode = { ...node, synonyms: newSynonyms };
    dispatch(updateNode(newNode));
  };

  /** Removes synonym from redux state before sending to the API. */
  const removeSynonym = synonym => {
    const newSynonyms = node.synonyms?.filter(item => item !== synonym);
    const newNode = { ...node, synonyms: newSynonyms };
    dispatch(updateNode(newNode));
  };

  /** Saves the synonym changes to the API. */
  const updateSynonyms = synonyms => {
    dispatch(updateConcept(nodeUri, 'synonyms', synonyms));
  };

  /**
   * Everytime the popover data is updated, we recalculate its position in the viewport.
   * By doing that, we prevent the popover from growing bigger than the screen.
   */
  useEffect(() => {
    if (updatePositionRef.current) {
      updatePositionRef.current.updatePosition();
    }
  }, [node?.synonyms, node?.uniqueValues, isExpanded, updatePositionRef.current]);

  /** If we still haven't fetched information for this Node, we fetch it. */
  useEffect(() => {
    if (!node.hasFetched) {
      fetchNodeState();
    }
  }, [node?.hasFetched]);

  /**
   * We can expand the InfoPanel from outside. By calling a callback function in the "expandFromOutside" prop,
   * the first parameter will be the "setIsExpanded" state function declared at the beginning of this component. That way
   * we can assign the return to a receiver function on the parent and expand this panel from there.
   */
  useEffect(() => {
    if (expandFromOutside) {
      expandFromOutside(setIsExpanded);
    }
  }, [expandFromOutside]);

  /**
   * We can determine if the node will be expanded by default from outside.
   * If we set the "expanded" prop to true, the node will automatically
   * be expanded when rendered.
   */
  useEffect(() => {
    if (expandedByDefault) {
      setIsExpanded(true);
    }
  }, [expandedByDefault]);

  useEffect(() => {
    if (!expandedByDefault && isExpanded) {
      trackEvent('Info Panel Expanded', { nodeName: node?.name, uri: nodeUri });
    }
  }, [expandedByDefault, isExpanded]);

  return (
    <div className={styles.formContainer} id="knowledge-graph-popover">
      <NodeTitle
        name={node.name}
        uri={nodeUri}
        hasBadName={hasBadName}
        suggestedNames={suggestedNames}
        subtitleIcon={SubtitleIcon}
        subtitleText={subtitleText}
        updateLabel={updateName}
        hasFetched={node.hasFetched}
        setIsExpanded={setIsExpanded}
      />
      {isExpanded && <hr className={styles.divisionLine} />}
      <NodeDescription
        name={node.name}
        uri={nodeUri}
        description={node.description}
        updateDescription={updateDescription}
        hasFetched={node.hasFetched}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      {isExpanded ? (
        <>
          <hr className={styles.divisionLine} />
          <NodeSynonyms
            name={node.name}
            uri={nodeUri}
            synonyms={node.synonyms}
            addSynonym={addSynonym}
            updateSynonyms={updateSynonyms}
            removeSynonym={removeSynonym}
            hasFetched={node.hasFetched}
          />
          <hr className={styles.divisionLine} />
          {nodeType === graphNodeTypes.CLASS && node.hasFetched && <NodeUniqueValues uniqueId={uniqueId} node={node} />}
          {!!node.file && (
            <JumpToStudio filePath={node.file.path} vklQualifiedIdentifier={node.vklQualifiedIdentifier} />
          )}
        </>
      ) : (
        <button className={styles.expandButton} onClick={handleExpand}>
          <DoubleArrowDown className={styles.doubleArrowIcon} />
        </button>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  language: state.user.language,
  suggestionsForBadNames: state.recipes.suggestionsForBadNames
});

export default connect(mapStateToProps)(memo(NodeContent));
