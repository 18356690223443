import { baseURL } from 'config/baseURL';
import { headersPOST } from './../utils/HTTPheaders';
import { authFetch } from './../utils/authFetch';
import { USER_SUCCESS } from './user';
import { tutorialNames } from 'config/constants';

export const RUN_OVERVIEW_TUTORIAL = 'RUN_OVERVIEW_TUTORIAL';
export const COMPLETE_OVERVIEW_TUTORIAL = 'COMPLETE_OVERVIEW_TUTORIAL';

export const RUN_KNOWLEDGE_GRAPH_TUTORIAL = 'RUN_KNOWLEDGE_GRAPH_TUTORIAL';
export const COMPLETE_KNOWLEDGE_GRAPH_TUTORIAL = 'COMPLETE_KNOWLEDGE_GRAPH_TUTORIALL';

export const RUN_WHAT_CAN_I_ASK_TUTORIAL = 'RUN_WHAT_CAN_I_ASK_TUTORIAL';
export const COMPLETE_WHAT_CAN_I_ASK_TUTORIAL = 'COMPLETE_WHAT_CAN_I_ASK_TUTORIALL';

export const SHOW_CREATE_ACCOUNT_MODAL = 'SHOW_CREATE_ACCOUNT_MODAL';
export const COMPLETE_FIRST_ACCESS_TUTORIAL = 'COMPLETE_FIRST_ACCESS_TUTORIAL';

export const TUTORIAL_FINISHED_REQUEST = 'TUTORIAL_FINISHED_REQUEST';
export const TUTORIAL_FINISHED_FAIL = 'TUTORIAL_FINISHED_FAIL';

export function runOverviewTutorial() {
  return {
    type: RUN_OVERVIEW_TUTORIAL
  };
}

export function runWhatCanIAskTutorial() {
  return {
    type: RUN_WHAT_CAN_I_ASK_TUTORIAL
  };
}

export function completeWhatCanIAskTutorial() {
  return {
    type: COMPLETE_WHAT_CAN_I_ASK_TUTORIAL
  };
}

export function runKnowledgeGraphTutorial() {
  return {
    type: RUN_KNOWLEDGE_GRAPH_TUTORIAL
  };
}

export function completeKnowledgeGraphTutorial() {
  return {
    type: COMPLETE_KNOWLEDGE_GRAPH_TUTORIAL
  };
}

export function showCreateAccountModal(createAccountModalTriggerType) {
  return {
    type: SHOW_CREATE_ACCOUNT_MODAL,
    createAccountModalTriggerType: createAccountModalTriggerType
  };
}

export function completeOverviewTutorial() {
  return {
    type: COMPLETE_OVERVIEW_TUTORIAL
  };
}

export const completeFirstAccessTutorial = () => {
  return dispatch => {
    dispatch(completeOverviewTutorial());

    dispatch({ type: COMPLETE_FIRST_ACCESS_TUTORIAL });
  };
};

export const finishedTutorial = (tutorialId, isCompleted) => {
  return dispatch => {
    const newTutorial = { id: tutorialId, name: tutorialId, isUncompleted: false };
    dispatch({ type: TUTORIAL_FINISHED_REQUEST, data: newTutorial });

    authFetch(
      baseURL + 'tutorial',
      {
        method: 'POST',
        headers: headersPOST,
        body: JSON.stringify({
          tutorialId: tutorialId,
          isCompleted: isCompleted
        }),
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          console.log('finished tutorial is a success response');
        } else {
          console.log('finished tutorial is a BAD response');
          dispatch({ type: TUTORIAL_FINISHED_FAIL });
        }
      })
      .then(response => {})
      .catch(error => console.log('ERROR', error));
  };
};

// describe a reducer
export const defaultTutorialState = {
  runOverviewTutorial: false,
  runWhatCanIAskTutorial: false,
  runKnowledgeGraphTutorial: false,
  // See the comment in CreateAccountModal as to why we choose to put this together with the tutorial code.
  showCreateAccountModal: false,
  createAccountModalTriggerType: undefined,
  hasFinishedFirstAccessTutorial: false,
  tutorials: [],
  uncompletedTutorials: []
};

export function tutorial(state = defaultTutorialState, action) {
  switch (action.type) {
    case RUN_OVERVIEW_TUTORIAL:
      return {
        ...state,
        runOverviewTutorial: true
      };

    case RUN_KNOWLEDGE_GRAPH_TUTORIAL:
      return {
        ...state,
        runKnowledgeGraphTutorial: true
      };

    case RUN_WHAT_CAN_I_ASK_TUTORIAL:
      return {
        ...state,
        runWhatCanIAskTutorial: true
      };

    case SHOW_CREATE_ACCOUNT_MODAL:
      return {
        ...state,
        showCreateAccountModal: true,
        createAccountModalTriggerType: action.createAccountModalTriggerType
      };

    case COMPLETE_OVERVIEW_TUTORIAL:
      return {
        ...state,
        runOverviewTutorial: false
      };

    case COMPLETE_KNOWLEDGE_GRAPH_TUTORIAL:
      return {
        ...state,
        runKnowledgeGraphTutorial: false
      };

    case COMPLETE_WHAT_CAN_I_ASK_TUTORIAL:
      return {
        ...state,
        runWhatCanIAskTutorial: false
      };

    case COMPLETE_FIRST_ACCESS_TUTORIAL:
      return {
        ...state,
        hasFinishedFirstAccessTutorial: true
      };

    case USER_SUCCESS: {
      const { tutorials } = action.data;
      return {
        ...state,
        tutorials: tutorials,
        uncompletedTutorials: tutorials && tutorials.filter(tut => tut.isUncompleted)
      };
    }

    case TUTORIAL_FINISHED_REQUEST: {
      const tutorials = [...state.tutorials.filter(tut => tut.id !== action.data.id), action.data];
      const createAccountModalClosed = action.data.name === tutorialNames.CREATE_ACCOUNT_MODAL;
      return {
        ...state,
        // stop the signup tutorial (if it just finished and it's running)
        showCreateAccountModal: !createAccountModalClosed && state.showCreateAccountModal,
        createAccountModalTriggerType: createAccountModalClosed ? undefined : state.createAccountModalTriggerType,
        tutorials: tutorials,
        uncompletedTutorials: tutorials && tutorials.filter(tut => tut.isUncompleted)
      };
    }

    default:
      return state;
  }
}
