import React, { forwardRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import UITextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import UIInputAdornment from '@material-ui/core/InputAdornment';

import { useSignupStyles, useVeezooStyles, useDefaultStyles } from './Select.styles';

// TODO: When InfoTooltip is refactored to version 3, we should replace this import.
import InfoTooltip from 'components/tooltips/InfoTooltip';
import ArrowIcon from 'svg/v2/chevron_down.svg';

import useMediaQuery from 'utils/mediaQueries';
import { layouts } from 'config/constants';

const Select = forwardRef(
  (
    {
      name,
      label,
      options,
      startIcon,
      description,
      value,
      onChange,
      displayEmpty,
      noBorders,
      disabled,
      highlighted, // font gets a light-blue color, we use it on Signup when Language already exists, giving a blue color to the language.
      layout,
      shrink,
      required,
      tooltip,
      placeholder,
      defaultValue,
      'data-test': dataTest,
      SelectProps,
      title,
      ...props
    },
    ref
  ) => {
    const customProps = useMemo(() => {
      const fields = {
        useStyles: useDefaultStyles,
        variant: 'outlined',
        margin: 'dense',
        autoComplete: ''
      };

      if (layout === layouts.signup) {
        fields.useStyles = useSignupStyles;
        fields.variant = 'standard';
        fields.margin = 'none';
        fields.autoComplete = 'new-password';
      }

      if (layout === layouts.veezoo) {
        fields.useStyles = useVeezooStyles;
        fields.variant = 'outlined';
        fields.margin = 'none';
        fields.autoComplete = 'new-password';
      }

      return fields;
    }, [layout, useSignupStyles, useVeezooStyles, useDefaultStyles, shrink]);

    const shouldShrink = useMemo(() => !!placeholder || layout !== layouts.signup || shrink, [
      placeholder,
      layout,
      shrink,
      label
    ]);

    const formattedOptions = useMemo(() => (displayEmpty ? [{ label: placeholder, value: '' }, ...options] : options), [
      placeholder,
      displayEmpty,
      options
    ]);

    const renderValue = useCallback(
      value => {
        if (!value || value === '') return <span className={classes.labelForEmptyValue}>{placeholder}</span>;
        const currentOptions = formattedOptions.find(option => option.value === value);
        if (currentOptions) return currentOptions.label;
        return '';
      },
      [formattedOptions, placeholder]
    );

    const isMobile = useMediaQuery();
    const classes = customProps.useStyles({ isMobile, disabled, highlighted, noBorders, tooltip });

    const setAdornment = icon => <UIInputAdornment position="start">{icon}</UIInputAdornment>;

    const iconFontSize = useMemo(() => {
      if (layout === layouts.signup) return '1.35rem';
      return '0.85rem';
    }, [layout]);

    const buildHelperText = useMemo(() => {
      const text = description ? <span>{description}</span> : <></>;
      const space = description && tooltip ? <>&nbsp;</> : '';
      const infoTooltip = tooltip ? (
        <InfoTooltip layout={layout} text={tooltip} size={iconFontSize} clickableWhenMobile={isMobile} />
      ) : (
        <></>
      );
      return (
        <>
          {infoTooltip}
          {space}
          {text}
        </>
      );
    }, [isMobile, layout, description, tooltip, iconFontSize]);

    return (
      <UITextField
        select
        fullWidth
        {...(label
          ? {
              label: <>{`${label} ${required ? '*' : ''}`}</>
            }
          : {})}
        classes={{ root: classes.root }}
        InputProps={{
          startAdornment: startIcon ? setAdornment(startIcon) : null,
          classes: {
            root: classes.inputRoot,
            focused: classes.inputFocused,
            input: classes.input,
            underline: classes.inputUnderline,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled
          }
        }}
        SelectProps={{
          classes: { icon: classes.arrowIcon },
          IconComponent: ArrowIcon,
          ...(displayEmpty ? { displayEmpty } : {}),
          renderValue,
          MenuProps: {
            classes: { list: classes.menuProps },
            PopoverClasses: { root: 'select-popover' },
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
          },
          SelectDisplayProps: { 'data-test': dataTest },
          ...SelectProps
        }}
        name={name}
        helperText={buildHelperText}
        disabled={disabled}
        variant={customProps.variant}
        margin={customProps.margin}
        inputProps={{ ref, title }}
        InputLabelProps={{
          classes: { root: classes.labelRoot, shrink: classes.shrink },
          ...(shouldShrink ? { shrink: shouldShrink } : {})
        }}
        FormHelperTextProps={{
          classes: { root: classes.formHelperTextRoot, contained: classes.formHelperTextContained }
        }}
        autoComplete={customProps.autoComplete}
        // If value is undefined, the component is uncontrolled and defaultValue is used (We use this mode on forms).
        // Otherwise, component is controlled and "value" and "onChange" should be provided by props.
        {...(value === undefined ? { defaultValue } : { value, onChange })}
        {...props}
      >
        {formattedOptions.map(({ label, value, disabled: optionDisabled }, index) => (
          <MenuItem
            key={`item_${index}`}
            value={value}
            disabled={optionDisabled}
            classes={{ root: !value ? classes.menuItemUnselected : '' }}
          >
            {label}
          </MenuItem>
        ))}
      </UITextField>
    );
  }
);

export default Select;

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool
    })
  ),
  displayEmpty: PropTypes.bool,
  noBorders: PropTypes.bool,
  startIcon: PropTypes.any,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  shrink: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  'data-test': PropTypes.string,
  SelectProps: PropTypes.object,
  title: PropTypes.string
};

Select.defaultProps = {
  name: 'select',
  startIcon: null,
  label: null,
  description: '',
  value: undefined,
  onChange: undefined,
  options: [],
  displayEmpty: false,
  noBorders: false,
  highlighted: false,
  disabled: false,
  layout: layouts.default,
  shrink: false,
  required: false,
  tooltip: null,
  placeholder: <>&nbsp;</>,
  defaultValue: '',
  SelectProps: {},
  title: 'Select component'
};
