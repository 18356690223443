export function initializeGemeindenMap(Highcharts) {
  Highcharts.maps['countries/ch-zh-gemeinden/ch-zh-gemeinden-all'] = {
    type: 'FeatureCollection',
    name: 'correct_encoding',
    crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::2056' } },
    features: [
      {
        type: 'Feature',
        properties: { cat: 20, gemeinde_B: 26, name: 'Dorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692481.712569054216146, 1268735.037730293115601, 526.697499999994761],
              [2692484.381270075216889, 1268648.682638850063086, 522.98124999999709],
              [2692479.751273055560887, 1268646.92889443365857, 523.211249999993015],
              [2691941.970226710196584, 1268597.009281780570745, 539.118749999994179],
              [2691706.463206954766065, 1268391.117861586390063, 480.91874999999709],
              [2691414.953587836585939, 1268135.731069140601903, 510.056249999994179],
              [2691150.588504293467849, 1268275.103421522304416, 503.9375],
              [2691073.862302388530225, 1268212.179761444218457, 507.652499999996508],
              [2691070.31479941541329, 1268229.124756189063191, 507.429999999993015],
              [2691034.536054682452232, 1268277.789767186855897, 507.861250000001746],
              [2690471.0066596288234, 1268370.003585055237636, 459.301250000004075],
              [2689738.190096145030111, 1268959.791772980475798, 436.01875000000291],
              [2689708.744978532660753, 1269034.242805979214609, 427.091249999997672],
              [2689884.017608884256333, 1269156.961795755429193, 436.886249999995925],
              [2690121.276041928678751, 1269876.16120990132913, 412.623749999998836],
              [2691123.027060364373028, 1271073.715591358020902, 489.297500000000582],
              [2691060.903925874270499, 1271320.130668259924278, 441.166249999994761],
              [2691101.966431942768395, 1271344.794428996741772, 441.597500000003492],
              [2691484.95762238278985, 1271431.75556238158606, 436.797500000000582],
              [2691495.17941115424037, 1271356.841560165164992, 477.678750000006403],
              [2691811.437919973861426, 1271374.967357303481549, 459.133749999993597],
              [2691789.460434840992093, 1271342.293634955771267, 460.277499999996508],
              [2691814.719529907219112, 1270599.228016343666241, 486.417499999995925],
              [2692089.658160002436489, 1270457.445287548471242, 477.101250000006985],
              [2692262.242010358721018, 1270108.679216376738623, 484.565000000002328],
              [2692261.119509987533092, 1270100.824215625878423, 484.537500000005821],
              [2692181.385492041707039, 1269918.91621981933713, 464.282500000001164],
              [2692329.654306863900274, 1269673.047584483632818, 469.076249999998254],
              [2692105.547720870934427, 1269197.958023989805952, 538.544999999998254],
              [2692481.712569054216146, 1268735.037730293115601, 526.697499999994761]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 25, gemeinde_B: 31, name: 'Henggart', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692479.751273055560887, 1268646.92889443365857, 523.211249999993015],
              [2692484.381270075216889, 1268648.682638850063086, 522.98124999999709],
              [2692481.712569054216146, 1268735.037730293115601, 526.697499999994761],
              [2693001.806444447953254, 1269667.753152158576995, 441.11250000000291],
              [2693007.702695179730654, 1269668.70940341684036, 441.163750000006985],
              [2693587.41127603314817, 1269654.481580632505938, 427.942500000004657],
              [2694301.168920263648033, 1269457.20058207353577, 438.330000000001746],
              [2694582.043801634106785, 1269569.737859480082989, 429.167499999995925],
              [2694747.645072597078979, 1269293.646642692387104, 430.626250000001164],
              [2694837.408943722955883, 1269257.666863786522299, 439.707500000004075],
              [2694920.30536847980693, 1269201.763433480169624, 452.839999999996508],
              [2694779.597727476619184, 1268773.523172558285296, 442.287500000005821],
              [2694775.718980360776186, 1268745.855677683372051, 442.507500000006985],
              [2694766.552729146555066, 1268751.424425662728027, 442.422500000000582],
              [2694664.120242350269109, 1268865.006952880183235, 443.497499999997672],
              [2694592.863960367161781, 1268864.459395281272009, 441.133749999993597],
              [2694499.964031873270869, 1268857.119528579991311, 446.603749999994761],
              [2694222.543896381277591, 1268114.905527334427461, 436.551250000004075],
              [2694127.085258268285543, 1267975.636983818607405, 445.07499999999709],
              [2694102.350243090651929, 1267937.693205965915695, 443.945000000006985],
              [2694109.400253599975258, 1267918.891975020756945, 444.733749999999418],
              [2694184.548778876196593, 1267740.564058327581733, 427.688750000001164],
              [2694014.653822099324316, 1267639.249140005558729, 431.07499999999709],
              [2694008.630069937091321, 1267604.611635820940137, 430.914999999993597],
              [2693452.966917302459478, 1267372.35899418941699, 476.509999999994761],
              [2693441.919488342478871, 1267566.057877198792994, 481.898749999993015],
              [2693233.661587100476027, 1268225.959647863171995, 451.707500000004075],
              [2692476.873775995336473, 1268644.183899896452203, 523.436249999998836],
              [2692479.751273055560887, 1268646.92889443365857, 523.211249999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 74, gemeinde_B: 97, name: 'Rümlang', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2683877.517331295646727, 1258175.98378990450874, 419.742499999993015],
              [2684116.274849978741258, 1257925.172568200388923, 421.001250000001164],
              [2683445.534847039263695, 1256613.286313015269116, 421.223750000004657],
              [2683655.957356127444655, 1256547.348825600231066, 421.770000000004075],
              [2683513.388577796518803, 1255782.628768863389269, 419.677500000005239],
              [2683991.772381944116205, 1255237.251356026390567, 423.482499999998254],
              [2684065.472346773836762, 1255142.538788770325482, 420.727499999993597],
              [2684180.352351010777056, 1255003.862543389899656, 420.967499999998836],
              [2684200.657342046499252, 1254896.315025498857722, 420.26249999999709],
              [2684323.068634185474366, 1254654.978849214501679, 423.403749999997672],
              [2684518.832360910251737, 1254366.490050189197063, 421.471250000002328],
              [2684250.58885659603402, 1253916.896755289752036, 440.508749999993597],
              [2682667.66549192648381, 1254057.587499891873449, 471.337499999994179],
              [2681931.871413531247526, 1253950.636903651989996, 446.623749999998836],
              [2681490.112635262776166, 1254014.870609926758334, 444.779999999998836],
              [2681359.516423551365733, 1253960.125682953745127, 447.828750000000582],
              [2680848.4337695739232, 1254018.816656718729064, 436.337499999994179],
              [2680213.3550705765374, 1254301.805515768472105, 440.753750000003492],
              [2680217.256318537052721, 1254324.203012102982029, 440.59375],
              [2680078.509465241339058, 1255509.585480706999078, 490.682499999995343],
              [2680073.706965055782348, 1255519.971730553777888, 490.672500000000582],
              [2680160.695763950701803, 1255689.869321638951078, 494.381250000005821],
              [2680699.740395195316523, 1256263.931129505857825, 465.48750000000291],
              [2680893.279274435713887, 1256522.650118995225057, 475.3125],
              [2680785.460278894286603, 1256985.384668025420979, 456.462499999994179],
              [2681838.731046873610467, 1257593.061256509972736, 418.53125],
              [2681844.826049117837101, 1257596.838760614860803, 418.69999999999709],
              [2682073.188567980658263, 1257592.698791684349999, 419.990000000005239],
              [2682677.856038977857679, 1257532.861226633423939, 417.326249999998254],
              [2683042.662327511236072, 1257814.920044350204989, 420.037500000005821],
              [2683877.517331295646727, 1258175.98378990450874, 419.742499999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 57, gemeinde_B: 72, name: 'Winkel', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2684116.274849978741258, 1257925.172568200388923, 421.001250000001164],
              [2683877.517331295646727, 1258175.98378990450874, 419.742499999993015],
              [2683259.011093115434051, 1258825.717578405514359, 421.10624999999709],
              [2683495.213568448554724, 1259050.573780371574685, 419.047500000000582],
              [2683246.542333982884884, 1259230.925065191695467, 420.422500000000582],
              [2683053.141066984273493, 1259854.883792409673333, 419.267500000001746],
              [2682971.609954411629587, 1260232.629053036682308, 429.902499999996508],
              [2682983.701203108765185, 1260238.330300446134061, 429.79374999999709],
              [2682758.491331559140235, 1260935.553050108486786, 439.854999999995925],
              [2682865.132417263928801, 1261078.005243273219094, 427.142500000001746],
              [2683404.721236310899258, 1261301.996614223578945, 432.063750000001164],
              [2683404.144985103048384, 1261303.919111996190622, 431.971250000002328],
              [2683899.585789028555155, 1261393.066850031726062, 493.346250000002328],
              [2684326.671964451670647, 1261451.265453252475709, 487.272500000006403],
              [2684374.001984110102057, 1261460.597988953348249, 488.742499999993015],
              [2684896.426819890271872, 1261903.347501256968826, 571.577499999999418],
              [2684624.795863802544773, 1262571.58323636231944, 498.511249999995925],
              [2684992.494218046311289, 1262822.428570248186588, 563.69999999999709],
              [2684959.894214538857341, 1262875.662315078312531, 563.462499999994179],
              [2685209.431978695560247, 1262780.879049418494105, 583.472500000003492],
              [2685264.060665655881166, 1262710.838930108584464, 578.587499999994179],
              [2685385.545553827658296, 1262454.602466434240341, 569.897500000006403],
              [2685316.633178448304534, 1262273.93269752478227, 579.507500000006985],
              [2685078.320500096771866, 1261483.382362509844825, 566.046249999999418],
              [2685518.403118005022407, 1260801.75881336745806, 574.651249999995343],
              [2685532.901834610849619, 1260718.056250095367432, 572.077499999999418],
              [2685462.908024148084223, 1260527.934887342853472, 567.507500000006985],
              [2685444.858084847219288, 1260443.784999395254999, 572.172500000000582],
              [2685587.051556960679591, 1260096.459476780612022, 550.746249999996508],
              [2685628.281559685245156, 1260050.629480394534767, 550.916249999994761],
              [2685643.109010819345713, 1259874.566887499531731, 547.15625],
              [2685715.919070470612496, 1259835.981996024027467, 551.657500000001164],
              [2686034.481490914244205, 1259499.428087763488293, 545.270000000004075],
              [2685994.687760188709944, 1259445.214373740367591, 546.779999999998836],
              [2685981.492757846135646, 1259439.269369629910216, 546.61250000000291],
              [2685594.289716422557831, 1258701.457117139128968, 505.477499999993597],
              [2684428.747556373476982, 1258850.252953875809908, 436.636249999995925],
              [2684753.323691637720913, 1258156.333979003829882, 427.582500000004075],
              [2684116.274849978741258, 1257925.172568200388923, 421.001250000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 64, gemeinde_B: 87, name: 'Hüttikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2671516.860907634254545, 1255855.14116170280613, 415.056249999994179],
              [2671734.647158185951412, 1255990.642410084372386, 414.946249999993597],
              [2672190.821335684508085, 1255387.943203369621187, 448.039999999993597],
              [2672204.927582410164177, 1255307.651946181431413, 447.771250000005239],
              [2672123.639223292469978, 1254785.885037568630651, 576.173750000001746],
              [2672414.670228003989905, 1254834.273318805731833, 556.713749999995343],
              [2672674.166136841289699, 1254539.937521761283278, 627.493749999994179],
              [2672792.38982965471223, 1254461.44616001448594, 622.898749999993015],
              [2672481.787404872011393, 1254282.053952174494043, 531.376250000001164],
              [2672455.4011364961043, 1254243.335167711600661, 529.962499999994179],
              [2672377.92369384272024, 1254253.527777591953054, 534.516250000000582],
              [2671589.115559631492943, 1254261.260348530020565, 485.58125000000291],
              [2671318.98829887714237, 1254221.595805210759863, 504.542499999995925],
              [2671312.950798047240824, 1254220.029553753091022, 504.482499999998254],
              [2671312.487051822710782, 1254224.010810929350555, 504.778749999997672],
              [2671354.219536163378507, 1254263.209530906286091, 503.517500000001746],
              [2671283.014738948084414, 1254789.554920790018514, 519.473750000004657],
              [2671130.208393475506455, 1255750.248640856472775, 414.222500000003492],
              [2671452.740909162443131, 1255821.738665430340916, 415.221250000002328],
              [2671464.092153661884367, 1255825.968654879834503, 414.782500000001164],
              [2671516.860907634254545, 1255855.14116170280613, 415.056249999994179]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 71, gemeinde_B: 94, name: 'Otelfingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2671734.647158185951412, 1255990.642410084372386, 414.946249999993597],
              [2671516.860907634254545, 1255855.14116170280613, 415.056249999994179],
              [2671439.098657514434308, 1256025.805386786581948, 434.682499999995343],
              [2671416.148658738937229, 1256097.627890181029215, 434.797500000000582],
              [2671218.194769545458257, 1256520.655134399887174, 424.052500000005239],
              [2670302.591339644044638, 1257196.232013457687572, 449.850000000005821],
              [2669904.759815245401114, 1257744.971376832574606, 526.751250000001164],
              [2669899.937163119670004, 1257829.101089245639741, 514.816250000003492],
              [2669925.379661057610065, 1257844.992334960959852, 514.632500000006985],
              [2670584.224228610284626, 1258356.492626107763499, 578.172500000000582],
              [2670519.637797779869288, 1258587.153537440346554, 564.059999999997672],
              [2670489.177852710708976, 1258727.507393938489258, 568.399999999994179],
              [2670446.0614870400168, 1259386.083049630746245, 853.508749999993597],
              [2670820.906333356630057, 1259411.617745641153306, 840.932499999995343],
              [2671959.144134585745633, 1259443.110777825815603, 862.921249999999418],
              [2671673.46543904999271, 1258219.012658134801313, 476.163750000006985],
              [2672579.102890245150775, 1257747.563654451165348, 569.307499999995343],
              [2672881.437966363038868, 1257327.957676849327981, 477.294999999998254],
              [2673057.861794979777187, 1257425.199072625953704, 483.29374999999709],
              [2673895.473898703698069, 1256806.622053115395829, 451.679999999993015],
              [2673881.891403234563768, 1256800.594561853678897, 452.04374999999709],
              [2673447.346111786086112, 1256704.142769872443751, 429.628750000003492],
              [2673446.774862213991582, 1256702.30902067059651, 429.662500000005821],
              [2673448.962360955309123, 1256696.784018215257674, 429.5625],
              [2673705.139914977364242, 1256655.291614979039878, 433.587499999994179],
              [2673854.2397275948897, 1256193.365005261730403, 418.848750000004657],
              [2673965.173485214356333, 1256170.553767439909279, 419.36250000000291],
              [2673787.128480183891952, 1256006.961259699193761, 419.095000000001164],
              [2673243.588462754618376, 1256090.811237232759595, 418.128750000003492],
              [2672627.293459803331643, 1256079.166242493083701, 418.342499999998836],
              [2672300.288417769130319, 1256100.238669149577618, 415.292499999995925],
              [2671861.149691929109395, 1256065.153722225455567, 417.497499999997672],
              [2671734.647158185951412, 1255990.642410084372386, 414.946249999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 67, gemeinde_B: 90, name: 'Niederhasli', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2678150.088439147919416, 1260376.89489375683479, 412.888749999998254],
              [2678150.504683492705226, 1260531.617384466109797, 412.453750000000582],
              [2678149.702188103925437, 1260563.799893415067345, 412.8125],
              [2678553.825904346071184, 1260432.244821937987581, 409.910000000003492],
              [2679148.894693412352353, 1260437.63738487358205, 412.514999999999418],
              [2679645.80021399958059, 1260257.677099115913734, 452.402499999996508],
              [2680047.747363820672035, 1259668.11393127264455, 425.028749999997672],
              [2679970.46873487951234, 1259428.75290687312372, 434.432499999995343],
              [2679967.116235417779535, 1259418.944157856749371, 434.476250000006985],
              [2679948.569895986001939, 1259412.137740915874019, 427.580000000001746],
              [2680228.401242735795677, 1258939.571662449743599, 434.837499999994179],
              [2680427.418688898906112, 1258718.5328063077759, 430.528749999997672],
              [2680419.59494255296886, 1258702.176563126500696, 430.816250000003492],
              [2680422.896192261017859, 1258698.736562493024394, 430.791249999994761],
              [2680357.382919328752905, 1258480.864952468080446, 467.671249999999418],
              [2681064.922479680273682, 1257712.394111863104627, 433.184999999997672],
              [2681081.836299469694495, 1257578.992990432074293, 438.552500000005239],
              [2681340.65602145716548, 1258067.203722012229264, 416.9375],
              [2681450.898529542610049, 1258171.374986057169735, 417.485000000000582],
              [2681726.957275118678808, 1258370.611224724911153, 416.952499999999418],
              [2681844.826049117837101, 1257596.838760614860803, 418.69999999999709],
              [2681838.731046873610467, 1257593.061256509972736, 418.53125],
              [2680785.460278894286603, 1256985.384668025420979, 456.462499999994179],
              [2680893.279274435713887, 1256522.650118995225057, 475.3125],
              [2680699.740395195316523, 1256263.931129505857825, 465.48750000000291],
              [2680160.695763950701803, 1255689.869321638951078, 494.381250000005821],
              [2680073.706965055782348, 1255519.971730553777888, 490.672500000000582],
              [2679648.652166713494807, 1255794.955868093296885, 506.618749999994179],
              [2679649.612167207989842, 1255802.945869078161195, 506.65625],
              [2679639.610900606960058, 1255852.135838826419786, 505.382500000006985],
              [2679567.93842637212947, 1255989.557139820419252, 507.438750000001164],
              [2679573.092127995099872, 1256466.895804374245927, 503.705000000001746],
              [2679352.534697034396231, 1256519.19843840226531, 509.232499999998254],
              [2679308.15712104504928, 1256702.107049328740686, 503.397500000006403],
              [2679137.257205806206912, 1256498.922209101263434, 510.095000000001164],
              [2678626.646573120262474, 1257106.75354295107536, 461.561249999998836],
              [2678320.248055601026863, 1257127.66648403275758, 479.809999999997672],
              [2678016.101629648823291, 1257248.581161879003048, 466.423750000001746],
              [2677296.479327096603811, 1257021.959043689304963, 482.307499999995343],
              [2677170.9969168510288, 1257284.916717084124684, 489.381250000005821],
              [2677157.358180290088058, 1257324.76924295630306, 490.436249999998836],
              [2677186.233162554912269, 1257334.520459210500121, 489.036250000004657],
              [2677157.253221920225769, 1257401.460571783827618, 493.66874999999709],
              [2677849.682286070194095, 1258619.762565543176606, 420.573749999995925],
              [2677974.44468114245683, 1259724.788626187480986, 412.377500000002328],
              [2677976.687195930164307, 1259747.016154072247446, 413.522500000006403],
              [2678095.242182659450918, 1260059.136129808844998, 412.417499999995925],
              [2678150.088439147919416, 1260376.89489375683479, 412.888749999998254]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 78, gemeinde_B: 101, name: 'Steinmaur', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2678149.702188103925437, 1260563.799893415067345, 412.8125],
              [2678150.504683492705226, 1260531.617384466109797, 412.453750000000582],
              [2678150.088439147919416, 1260376.89489375683479, 412.888749999998254],
              [2678147.909692015498877, 1260374.651149153942242, 413.11250000000291],
              [2678108.46966049913317, 1260347.148590463213623, 410.697499999994761],
              [2677358.30217748740688, 1260291.284884928027168, 412.542499999995925],
              [2677341.552174594718963, 1260293.479879804886878, 412.330000000001746],
              [2677304.64967525517568, 1260301.199881759472191, 412.407500000001164],
              [2676822.754776367451996, 1260234.526329685468227, 420.597500000003492],
              [2676706.383514940273017, 1260280.708810666110367, 419.794999999998254],
              [2676676.633518155198544, 1260287.928817306412384, 420.066250000003492],
              [2675993.050869322847575, 1259758.213396966923028, 506.15625],
              [2675904.810744238551706, 1259894.94316479912959, 496.508749999993597],
              [2673350.953316312748939, 1259495.26418447541073, 797.054999999993015],
              [2673281.30071139940992, 1259509.37273871852085, 788.955000000001746],
              [2673478.471214491408318, 1259904.73271556594409, 632.83125000000291],
              [2674392.404231857508421, 1260835.18771033291705, 477.354999999995925],
              [2674554.304383682552725, 1260995.460495290346444, 489.057499999995343],
              [2674907.396781397052109, 1261334.020298424409702, 480.806249999994179],
              [2674942.99807421118021, 1261500.35163016221486, 484.115000000005239],
              [2674604.257126822136343, 1262252.744578217621893, 605.383749999993597],
              [2673981.278789518866688, 1262685.809129874221981, 638.213749999995343],
              [2674133.334592832252383, 1263522.54829114372842, 603.240000000005239],
              [2674131.292093459982425, 1263532.330792507855222, 603.291249999994761],
              [2674772.035030393861234, 1262990.411733217304572, 539.297500000000582],
              [2674800.408794815186411, 1262994.308009880362079, 540.395000000004075],
              [2674865.030017926357687, 1262965.280457294778898, 538.241250000006403],
              [2675147.928850476164371, 1262920.788106420310214, 544.410000000003492],
              [2675968.593161406461149, 1263153.186790367588401, 490.089999999996508],
              [2676513.933383842464536, 1262759.230944456066936, 506.907500000001164],
              [2676776.924963484983891, 1262531.466557429870591, 532.273749999993015],
              [2677317.958006738685071, 1262086.251461113803089, 476.947499999994761],
              [2677345.614230181090534, 1261875.52140854508616, 474.860000000000582],
              [2677263.756762120407075, 1261803.72521954565309, 477.402499999996508],
              [2677181.170016436837614, 1261056.416781893465668, 438.98750000000291],
              [2678292.160902813076973, 1260817.202326885424554, 409.983749999999418],
              [2678184.507192438002676, 1260727.673652354395017, 413.127500000002328],
              [2678150.740933721419424, 1260577.532385286176577, 412.472500000003492],
              [2678149.702188103925437, 1260563.799893415067345, 412.8125]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 13, gemeinde_B: 13, name: 'Stallikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2680996.693128890357912, 1237922.454304670216516, 868.598750000004657],
              [2680806.109609182924032, 1237763.483481205767021, 886.63749999999709],
              [2679179.495770893059671, 1238543.680232409620658, 592.047500000000582],
              [2678865.564679114613682, 1238032.585525173926726, 604.592499999998836],
              [2678570.558815013151616, 1238076.098547434434295, 731.573749999995925],
              [2678563.793813792522997, 1238092.469795623328537, 731.48750000000291],
              [2678522.764041045214981, 1238446.871470906538889, 749.145000000004075],
              [2678522.765217337757349, 1238539.690086409682408, 743.438750000001164],
              [2678549.616477601230145, 1238620.580105738481507, 744.202499999999418],
              [2678843.548293482977897, 1238886.113840077072382, 690.892500000001746],
              [2678910.999977656640112, 1238981.123389759333804, 724.422500000000582],
              [2678971.629893438424915, 1238997.503233046969399, 717.832500000004075],
              [2678851.356315173208714, 1239948.683761071180925, 634.532500000001164],
              [2678976.591562625486404, 1240518.951721755089238, 653.563750000001164],
              [2678991.801517150830477, 1240550.531637858133763, 650.028749999997672],
              [2679005.721520532853901, 1240576.931644089752808, 650.276249999995343],
              [2679642.406659851782024, 1240689.227081516757607, 563.728749999994761],
              [2679374.39022981794551, 1240737.38531150855124, 646.733749999999418],
              [2678983.493369236588478, 1241463.492953643668443, 599.917499999995925],
              [2679146.336397813633084, 1241799.408929798053578, 640.657500000001164],
              [2679034.686229252722114, 1241956.60612335219048, 627.736250000001746],
              [2679022.418761521577835, 1241971.18743344466202, 630.246249999996508],
              [2679007.694990683346987, 1241960.106145181693137, 628.648749999993015],
              [2679006.581241166684777, 1241968.593646212713793, 628.6875],
              [2679006.827489747200161, 1241972.873643640661612, 628.577499999999418],
              [2679009.431242635473609, 1241987.28489909437485, 628.798750000001746],
              [2678984.736172957811505, 1242105.048522490076721, 623.434999999997672],
              [2679007.883811324369162, 1242282.802529783453792, 634.122499999997672],
              [2679167.615912244655192, 1242643.523042962187901, 603.087499999994179],
              [2678844.636196405161172, 1243330.968584761722013, 625.422500000000582],
              [2678959.155565605964512, 1243397.757416158448905, 576.48750000000291],
              [2677977.113390927668661, 1244491.095554938772693, 602.673750000001746],
              [2678008.290364556014538, 1244542.39207904599607, 561.847500000003492],
              [2677873.220376233570278, 1244659.997105121379718, 562.882500000006985],
              [2677851.360825843643397, 1244891.786873761098832, 500.860000000000582],
              [2678289.021415818016976, 1245278.296530093299225, 643.042499999995925],
              [2679208.575941405259073, 1245176.461504829814658, 798.764999999999418],
              [2679589.183110405690968, 1244831.838187137153, 869.316250000003492],
              [2679926.726974766235799, 1244496.832321003079414, 781.070000000006985],
              [2679997.507201405242085, 1244031.011480260873213, 798.48124999999709],
              [2680164.590646476484835, 1243688.038406571606174, 774.69999999999709],
              [2680333.623481064569205, 1243230.460261710453779, 800.315000000002328],
              [2680354.172024354804307, 1243127.439878068864346, 784.322499999994761],
              [2680379.679687242489308, 1243082.065177061595023, 796.867499999993015],
              [2680349.792177413590252, 1242361.987843681359664, 699.598750000004657],
              [2680480.765311570838094, 1241584.123997504357249, 748.39375000000291],
              [2680695.931042877957225, 1240992.26907855970785, 804.5625],
              [2680716.344805495347828, 1240946.12660041032359, 805.508749999993597],
              [2680715.342221128754318, 1240858.84269376215525, 798.996249999996508],
              [2680717.823616401758045, 1240713.374208736466244, 810.202499999999418],
              [2680957.418334949761629, 1240359.837427777703851, 788.162500000005821],
              [2680916.549920200835913, 1239666.604284500004724, 814.067500000004657],
              [2680941.978184927720577, 1238973.612130938097835, 776.576249999998254],
              [2680982.783263435121626, 1238797.728521189186722, 782.577499999999418],
              [2680981.652012962847948, 1238792.192270271712914, 782.542499999995925],
              [2680996.693128890357912, 1237922.454304670216516, 868.598750000004657]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 93, gemeinde_B: 136, name: 'Langnau a.A.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2680806.109609182924032, 1237763.483481205767021, 886.63749999999709],
              [2680996.693128890357912, 1237922.454304670216516, 868.598750000004657],
              [2681629.062449913471937, 1238224.212207479635254, 622.698749999995925],
              [2681632.521205846685916, 1238221.87721824622713, 623.152499999996508],
              [2682570.626501781865954, 1239425.320669449632987, 452.600000000005821],
              [2682588.439002707600594, 1239418.965670757228509, 452.657500000001164],
              [2683480.372844752389938, 1238646.692065443145111, 459.032500000001164],
              [2683487.730343363247812, 1238637.362062683794647, 458.919999999998254],
              [2684063.134301593527198, 1236436.856161043047905, 474.412500000005821],
              [2684072.598035838454962, 1236411.028631865279749, 473.195000000006985],
              [2684147.980548179708421, 1236356.247402399079874, 474.08125000000291],
              [2683012.221041508950293, 1235031.100158240413293, 801.291249999994761],
              [2682733.572033787146211, 1235615.921989467227831, 878.017500000001746],
              [2682290.562776445411146, 1235720.782328634290025, 839.587499999994179],
              [2682259.794383872300386, 1235761.262984, 867.152499999996508],
              [2682056.216662389226258, 1235661.00883399927989, 850.377500000002328],
              [2682041.407922839280218, 1235643.718853315338492, 851.202499999999418],
              [2682030.186670519877225, 1235622.728849083650857, 851.038750000006985],
              [2681926.594297123607248, 1235491.128045148449019, 764.633749999993597],
              [2681901.248055937234312, 1235523.925562530290335, 765.345000000001164],
              [2681868.839262179099023, 1235555.075483911670744, 762.014999999999418],
              [2681840.899318861309439, 1235580.084338841028512, 766.417499999995925],
              [2681820.040500247851014, 1235605.504214299144223, 761.157500000001164],
              [2681748.60182428592816, 1235630.073102312162519, 766.952499999999418],
              [2681549.68290095590055, 1235972.550296121276915, 753.848750000004657],
              [2681460.06450138380751, 1236301.319695660611615, 780.982499999998254],
              [2681462.48449590196833, 1236325.089685917599127, 780.557499999995343],
              [2681345.897326724603772, 1236225.511753887403756, 709.828750000000582],
              [2680961.512845716904849, 1236163.59646516176872, 750.317500000004657],
              [2680606.252316275145859, 1236316.7830067621544, 709.88749999999709],
              [2680604.972456604707986, 1236344.203264603856951, 720.722500000003492],
              [2681137.748167893383652, 1237280.65686041652225, 871.39375000000291],
              [2681136.438224090728909, 1237323.396964280167595, 875.73124999999709],
              [2681090.097516438923776, 1237435.044419528217986, 821.211249999993015],
              [2680806.109609182924032, 1237763.483481205767021, 886.63749999999709]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 99, gemeinde_B: 152, name: 'Herrliberg', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2691968.520584524143487, 1240652.810393522726372, 755.228749999994761],
              [2691973.054313191678375, 1240639.772854616865516, 753.604999999995925],
              [2692067.973109173122793, 1240426.42293177684769, 756.972500000003492],
              [2692520.99546258803457, 1239616.947398673277348, 840.03125],
              [2692409.1767742424272, 1239657.246264317305759, 844.85624999999709],
              [2692200.179760432336479, 1239287.20363544812426, 787.212499999994179],
              [2692092.698416342027485, 1239283.855968706309795, 780.212499999994179],
              [2691890.159853567369282, 1239283.180063103092834, 794.669999999998254],
              [2691636.423387173563242, 1239234.560928987339139, 778.570000000006985],
              [2690988.460579105187207, 1237975.439603342441842, 566.037500000005821],
              [2688639.567216801922768, 1237543.11957055144012, 407.181249999994179],
              [2688615.398448540829122, 1237558.750787971075624, 405.806249999994179],
              [2688408.855976595077664, 1237913.26084530656226, 408.095000000001164],
              [2688326.505946123506874, 1238013.66079223738052, 405.830000000001746],
              [2688332.20598271721974, 1238029.510858698282391, 408.617499999993015],
              [2688063.15219341404736, 1238338.082044448237866, 405.811249999998836],
              [2688037.805955353192985, 1238362.160816757939756, 406.740000000005239],
              [2687751.755940757226199, 1238698.710797859355807, 405.830000000001746],
              [2687754.134708852972835, 1238708.809580795932561, 407.210000000006403],
              [2687746.60594070609659, 1238719.110798018984497, 405.830000000001746],
              [2687741.855981759727001, 1238730.810872820438817, 408.967499999998836],
              [2687715.968440416734666, 1238757.303298336220905, 405.830000000001746],
              [2688572.721754258498549, 1239201.439535820158198, 562.607499999998254],
              [2688314.489141121506691, 1239434.213088685879484, 554.222500000003492],
              [2688323.91360837733373, 1239840.202124720904976, 513.588749999995343],
              [2690108.789879645686597, 1240196.77916324022226, 703.866250000006403],
              [2690217.475915876217186, 1240403.553775569191203, 687.488750000004075],
              [2690439.898262038826942, 1240540.810992610873654, 675.547500000000582],
              [2691968.520584524143487, 1240652.810393522726372, 755.228749999994761]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 121, gemeinde_B: 195, name: 'Maur', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692067.973109173122793, 1240426.42293177684769, 756.972500000003492],
              [2691973.054313191678375, 1240639.772854616865516, 753.604999999995925],
              [2691968.520584524143487, 1240652.810393522726372, 755.228749999994761],
              [2692078.278374708257616, 1241008.415920545114204, 777.111250000001746],
              [2691827.988337086047977, 1242320.747365946881473, 679.76249999999709],
              [2691296.686472448520362, 1242797.301038833102211, 728.601250000006985],
              [2691282.370216459501535, 1242815.977278644684702, 728.163750000006985],
              [2691275.518970618024468, 1242811.924786327872425, 728.48750000000291],
              [2691269.472697340883315, 1242940.184746139449999, 726.729999999995925],
              [2691253.986523539293557, 1243146.331137796398252, 732.538750000006985],
              [2690338.530410547275096, 1244450.105408990755677, 649.01875000000291],
              [2689826.377290084958076, 1244948.956574794603512, 697.478749999994761],
              [2689726.648325589485466, 1245030.386188042350113, 681.267500000001746],
              [2689685.631831684149802, 1245402.879499893635511, 662.756250000005821],
              [2689602.843156493268907, 1245495.490889599081129, 668.542499999995925],
              [2689601.270659509114921, 1245500.889645208604634, 668.773749999993015],
              [2689440.057791830506176, 1246116.555237264139578, 640.966249999997672],
              [2689388.837823312729597, 1246136.157796222018078, 643.417499999995925],
              [2689372.74906678404659, 1246146.140284865628928, 642.9375],
              [2689376.151566731277853, 1246149.246534719597548, 642.929999999993015],
              [2689395.147820051759481, 1246141.351540182717144, 643.162500000005821],
              [2689782.003452446311712, 1246354.302907321602106, 595.759999999994761],
              [2689922.518473869655281, 1246164.102940746815875, 597.248749999998836],
              [2689981.143452575430274, 1246100.509149790275842, 595.570000000006985],
              [2690198.751040068920702, 1245821.698050615843385, 602.006250000005821],
              [2690214.043436570093036, 1245831.1716119046323, 594.119999999995343],
              [2690234.779826768673956, 1245845.076866866089404, 604.76249999999709],
              [2690345.940950882621109, 1245936.286636111559346, 595.082500000004075],
              [2690426.986017424613237, 1245864.078004470095038, 600.061249999998836],
              [2690482.139755965210497, 1245826.221731831785291, 599.134999999994761],
              [2691007.358739169780165, 1246105.499873148743063, 521.392500000001746],
              [2691229.843008009716868, 1245892.092059551505372, 560.59375],
              [2692045.327615555375814, 1245939.906559352530167, 435.287500000005821],
              [2692065.082614616490901, 1245916.985307090217248, 435.201249999998254],
              [2692093.330117895733565, 1245882.472812238615006, 435.428750000006403],
              [2692104.275111855007708, 1245873.140300990315154, 434.962499999994179],
              [2692322.998873914126307, 1245568.946566264843568, 435.711249999993015],
              [2692668.735117228236049, 1245187.502794580999762, 434.945000000006985],
              [2692799.842627046164125, 1244961.262808020925149, 435.588749999995343],
              [2693008.871371093206108, 1244729.446541454643011, 434.98124999999709],
              [2693063.372631415259093, 1244701.16530892951414, 435.722500000003492],
              [2693098.242623049765825, 1244688.447793056257069, 435.063750000001164],
              [2693157.330136382952332, 1244678.232816063566133, 436.029999999998836],
              [2693469.400123505853117, 1244600.739036542596295, 434.827499999999418],
              [2693475.665137892123312, 1244533.692811868386343, 435.910000000003492],
              [2693613.662626147270203, 1244301.139036079868674, 434.916249999994761],
              [2693660.325138074345887, 1244206.387805970851332, 435.782500000001164],
              [2693676.853875620756298, 1244160.016532737296075, 434.827499999999418],
              [2693684.652646174654365, 1244135.455319566885009, 436.376250000001164],
              [2693686.0138757256791, 1244103.422782258829102, 434.827499999999418],
              [2693696.210138957481831, 1244054.422805569134653, 435.820000000006985],
              [2693723.963876117952168, 1244003.094031397951767, 434.827499999999418],
              [2693772.848887603729963, 1243967.042800976429135, 435.660000000003492],
              [2693785.225129530299455, 1243946.439035975374281, 435.039999999993597],
              [2693865.07138883927837, 1243895.834050942910835, 435.684999999997672],
              [2694022.192629079334438, 1243640.190278226975352, 434.827499999999418],
              [2694155.510142112150788, 1243411.982797367731109, 435.711249999993015],
              [2694176.340132775716484, 1243386.882779910927638, 434.990000000005239],
              [2694183.662643465679139, 1243380.717799035366625, 435.792499999995925],
              [2694208.087630948517472, 1243331.955275555606931, 434.827499999999418],
              [2694239.336393889039755, 1243136.921546617988497, 435.778749999997672],
              [2694355.282632992137223, 1242975.395273439120501, 434.867499999993015],
              [2694365.121396786067635, 1242954.454047985142097, 435.902499999996508],
              [2694407.281384787522256, 1242942.042775469366461, 434.964999999996508],
              [2694418.863894855603576, 1242846.01154256449081, 435.714999999996508],
              [2694432.53764060465619, 1242834.879034552723169, 435.383749999993597],
              [2694436.817645164672285, 1242829.507792657474056, 435.725000000005821],
              [2694481.420136740896851, 1242715.292775640729815, 435.053750000006403],
              [2694609.338898472022265, 1242219.412790078902617, 435.836249999993015],
              [2694632.256385339889675, 1242210.495265912497416, 434.827499999999418],
              [2694888.346404759213328, 1241924.376543834339827, 436.102499999993597],
              [2695077.950139694847167, 1241898.860263127135113, 434.827499999999418],
              [2695151.856406218372285, 1241950.540292066987604, 436.022500000006403],
              [2695166.081424095202237, 1241878.172823368804529, 437.360000000000582],
              [2695170.698917185422033, 1241871.177810783032328, 436.835000000006403],
              [2695173.680169600993395, 1241862.052815002156422, 437.014999999999418],
              [2694752.351766549050808, 1241602.378445376176387, 463.517500000001746],
              [2694730.124045311938971, 1241651.765547493705526, 446.826249999998254],
              [2694628.10164196183905, 1241646.209473513299599, 454.202499999999418],
              [2692067.973109173122793, 1240426.42293177684769, 756.972500000003492]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 100, gemeinde_B: 153, name: 'Hombrechtikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2701154.197315759025514, 1232362.445748325670138, 406.039999999993597],
              [2701154.197335777804255, 1232369.864533862331882, 407.537500000005821],
              [2701141.654837468639016, 1232371.019537070998922, 407.672500000000582],
              [2701141.234852756373584, 1232378.219564219703898, 408.816250000003492],
              [2701142.13486260548234, 1232381.730831696186215, 409.552500000005239],
              [2701104.029860182665288, 1232509.122079113731161, 409.399999999994179],
              [2701097.378610318526626, 1232521.147079568356276, 409.414999999993597],
              [2701084.232360437512398, 1232513.847079929430038, 409.432499999995343],
              [2701010.253609978593886, 1232476.745830016909167, 409.447499999994761],
              [2700997.598669255618006, 1232508.487185560632497, 413.891250000000582],
              [2700997.023669063579291, 1232508.250935228075832, 413.877500000002328],
              [2700982.780184130650014, 1232539.07515560882166, 433.71875],
              [2700979.495181140024215, 1232545.410150417825207, 433.497499999997672],
              [2700963.372685184236616, 1232536.512657783227041, 433.811249999998836],
              [2700880.786462307907641, 1232494.05895691527985, 435.898749999993015],
              [2700834.327708040829748, 1232475.791449988028035, 435.61250000000291],
              [2700801.335206326562911, 1232463.865197407081723, 435.507500000006985],
              [2700744.730224362108856, 1232445.281480196397752, 436.897500000006403],
              [2700713.331459633074701, 1232439.332704574335366, 435.817500000004657],
              [2700692.467693786602467, 1232437.107676808256656, 434.646250000005239],
              [2700671.510179542005062, 1232436.933901907876134, 433.595000000001164],
              [2700652.900175006128848, 1232437.437644188525155, 433.26875000000291],
              [2700636.032672624103725, 1232440.378890281543136, 433.102499999993597],
              [2700632.577672421466559, 1232442.941390006802976, 433.089999999996508],
              [2700630.648923419881612, 1232446.517641840036958, 433.166249999994761],
              [2700624.671453763730824, 1232474.857696018880233, 435.442500000004657],
              [2700616.800204590428621, 1232473.662697612075135, 435.509999999994761],
              [2700612.25895191077143, 1232474.432692935457453, 435.3125],
              [2700612.002704995218664, 1232477.35019843862392, 435.54374999999709],
              [2700611.24771849764511, 1232479.370222417404875, 436.554999999993015],
              [2700608.516495355404913, 1232482.332770122680813, 438.567500000004657],
              [2700613.056669089011848, 1232506.824328406946734, 451.570000000006985],
              [2700561.627902246546, 1232516.870549550279975, 450.347500000003492],
              [2700546.182900771033019, 1232528.750547314062715, 450.248749999998836],
              [2700532.022896202281117, 1232543.065539595670998, 449.917499999995925],
              [2700513.211645674426109, 1232567.274289222434163, 449.892500000001746],
              [2700509.38913750089705, 1232591.171775010181591, 449.283750000002328],
              [2700504.676611339207739, 1232605.724228812614456, 447.328750000000582],
              [2700484.595309525728226, 1232640.930387576576322, 443.464999999996508],
              [2700465.837791268248111, 1232676.239105832064524, 442.11250000000291],
              [2700463.9077761718072, 1232686.507829171372578, 440.983749999999418],
              [2700464.246514734812081, 1232692.020308927632868, 440.127500000002328],
              [2700467.015256795100868, 1232703.445294897770509, 439.53125],
              [2700468.429006972815841, 1232715.101545291021466, 439.54374999999709],
              [2700467.046510278712958, 1232723.620301265036687, 439.792499999995925],
              [2700462.541516627185047, 1232733.585312695940956, 440.271250000005239],
              [2700455.941528930328786, 1232742.339084723498672, 441.197499999994761],
              [2700437.064069027546793, 1232758.795406352961436, 444.213749999995343],
              [2700429.812838183250278, 1232766.662940547568724, 445.653749999997672],
              [2700426.364101839717478, 1232774.439214903628454, 446.678750000006403],
              [2700425.140361421275884, 1232782.770482007879764, 447.397500000006403],
              [2700427.142867246177047, 1232799.276742463698611, 447.832500000004075],
              [2700425.315356657840312, 1232809.724223799537867, 447.041249999994761],
              [2700420.897843271959573, 1232821.310450237011537, 446.042499999995925],
              [2700415.246579924598336, 1232832.912926762597635, 445.047500000000582],
              [2700405.04156432999298, 1232850.199149417923763, 443.88749999999709],
              [2700404.540309097617865, 1232862.46164025249891, 443.496249999996508],
              [2700400.365308184642345, 1232873.046638803323731, 443.431249999994179],
              [2700404.764051761478186, 1232890.780377490445971, 442.947499999994761],
              [2700427.342825795058161, 1232970.87792048510164, 444.732499999998254],
              [2700436.430330065079033, 1232994.736678129760548, 445.046249999999418],
              [2700448.599087174981833, 1233018.230440735584125, 445.570000000006985],
              [2700464.676596757490188, 1233043.221707693999633, 446.276249999995343],
              [2700472.222854621708393, 1233052.871721612056717, 446.860000000000582],
              [2700464.180353238712996, 1233059.516719358973205, 446.76249999999709],
              [2700430.910338654182851, 1233067.644194139167666, 445.695000000006985],
              [2700414.510337691288441, 1233075.572942789178342, 445.634999999994761],
              [2700384.416594190057367, 1233079.867954897228628, 446.14375000000291],
              [2700331.221595853101462, 1233089.226708874106407, 446.307499999995343],
              [2700330.554102700669318, 1233107.94297121395357, 446.821249999993597],
              [2700299.174098741263151, 1233099.995464668143541, 446.547500000000582],
              [2700303.470428876578808, 1233175.034357561264187, 452.547500000000582],
              [2700218.484198076184839, 1233171.901893145637587, 454.048750000001746],
              [2700209.725390339735895, 1233144.291790523333475, 449.729999999995925],
              [2700143.185408576857299, 1233136.355574019718915, 451.145000000004075],
              [2700117.640414898749441, 1233131.604335660347715, 451.63749999999709],
              [2700056.155441392213106, 1233111.2293836241588, 453.667499999995925],
              [2700034.775445403531194, 1233106.813141086837277, 453.983749999999418],
              [2700024.087952670641243, 1233108.795654205372557, 454.536250000004657],
              [2700016.887953749857843, 1233111.960656279698014, 454.622499999997672],
              [2699978.21170902531594, 1233139.48316661035642, 455.047500000000582],
              [2699975.132960280403495, 1233137.168168872129172, 455.14375000000291],
              [2699960.659211406484246, 1233133.255671091377735, 455.238750000004075],
              [2699977.774252136237919, 1233168.41824346082285, 458.278749999997672],
              [2699988.643006016034633, 1233174.695750207174569, 458.561249999998836],
              [2699985.354253529105335, 1233179.715745903784409, 458.377500000002328],
              [2699989.82425650767982, 1233182.993251144653186, 458.597500000003492],
              [2699992.399258083663881, 1233185.434503918746486, 458.713749999995343],
              [2699976.405534933321178, 1233185.442051899619401, 460.73750000000291],
              [2699946.469319044612348, 1233186.470863055670634, 463.316250000003492],
              [2699922.64056828757748, 1233182.567112107295543, 463.277499999996508],
              [2699890.400575269944966, 1233178.700875061331317, 463.82499999999709],
              [2699868.830578703898937, 1233178.050881553208455, 464.098750000004657],
              [2699868.362200354691595, 1233231.11154235387221, 491.952499999999418],
              [2699804.63470572559163, 1233291.304053731728345, 492.407500000001164],
              [2699804.088455785065889, 1233293.884053875925019, 492.412500000005821],
              [2699756.314725754316896, 1233342.651590782450512, 493.948749999995925],
              [2699711.955996497999877, 1233420.791629303945228, 495.541249999994761],
              [2699697.632254237309098, 1233453.97289367695339, 496.133749999993597],
              [2699639.172268603462726, 1233426.744170079240575, 497.257500000006985],
              [2699635.47602904913947, 1233435.655438809422776, 498.04374999999709],
              [2699634.39228655397892, 1233452.369202331406996, 498.607499999998254],
              [2699634.521041619125754, 1233469.284211511723697, 498.98750000000291],
              [2699634.107295278459787, 1233482.472968158079311, 499.26249999999709],
              [2699632.493551356252283, 1233495.60797913488932, 499.720000000001164],
              [2699632.056055350229144, 1233505.637986342422664, 500.020000000004075],
              [2699632.619809807743877, 1233515.627994358772412, 500.353749999994761],
              [2699634.466062306892127, 1233525.462998864706606, 500.539999999993597],
              [2699637.621063440106809, 1233534.903000914258882, 500.622499999997672],
              [2699633.738566631451249, 1233536.44800668139942, 500.865000000005239],
              [2699655.322347298264503, 1233593.751811360241845, 503.147500000006403],
              [2699658.764850216917694, 1233602.029316562460735, 503.363750000004075],
              [2699662.926099909003824, 1233609.839316019788384, 503.337499999994179],
              [2699634.704865846317261, 1233598.2468447831925, 504.554999999993015],
              [2699566.618668922223151, 1233569.103190199704841, 508.589999999996508],
              [2699515.896204013377428, 1233548.689503385685384, 511.26249999999709],
              [2699492.171216436196119, 1233541.248275875579566, 512.213749999995343],
              [2699387.046236060094088, 1233512.378312582382932, 513.772500000006403],
              [2699384.164986493997276, 1233511.89831341104582, 513.807499999995343],
              [2699381.244987063109875, 1233512.027064477559179, 513.852499999993597],
              [2699378.416237965226173, 1233512.757066142512485, 513.922500000000582],
              [2699375.799988935235888, 1233514.060817937832326, 513.997499999997672],
              [2699373.512490090448409, 1233515.877070061396807, 514.086249999993015],
              [2699371.651240687817335, 1233518.130821178900078, 514.132500000006985],
              [2699370.298742256127298, 1233520.720824024872854, 514.251250000001164],
              [2699369.512493810616434, 1233523.534576836274937, 514.368749999994179],
              [2699367.760007418692112, 1233551.283351360820234, 515.391250000000582],
              [2699366.026263327803463, 1233565.212112052133307, 515.836249999993015],
              [2699361.480025168508291, 1233590.219633469823748, 516.728749999994761],
              [2699360.666281258221716, 1233598.610894407145679, 517.186249999998836],
              [2699361.396290030796081, 1233607.010910079116002, 517.84375],
              [2699363.65004810038954, 1233615.134674473199993, 518.447499999994761],
              [2699367.347555208019912, 1233622.70968711655587, 518.977499999993597],
              [2699391.948820872697979, 1233665.135964928893372, 520.132500000006985],
              [2699389.102569888811558, 1233666.713463260559365, 520.061249999998836],
              [2699393.876329140737653, 1233673.889729688642547, 520.751250000001164],
              [2699408.137575913686305, 1233689.590973824029788, 520.497499999997672],
              [2699410.537577541545033, 1233694.973476729122922, 520.617499999993015],
              [2699411.125078927259892, 1233703.867229280527681, 520.721250000002328],
              [2699410.395081399474293, 1233712.855983783956617, 520.907500000001164],
              [2699407.757581165060401, 1233730.668483613757417, 520.892500000001746],
              [2699403.73132239561528, 1233751.315968311857432, 520.238750000004075],
              [2699402.405076016671956, 1233748.063474744791165, 520.511249999995925],
              [2699399.20508099906147, 1233746.008483649464324, 520.88749999999709],
              [2699387.372596062254161, 1233746.868510697968304, 522.027499999996508],
              [2699351.480133155826479, 1233751.584827455691993, 524.839999999996508],
              [2699338.04764528432861, 1233754.102349332766607, 525.761249999995925],
              [2699316.742657139431685, 1233759.496120921568945, 526.66874999999709],
              [2699333.181334426160902, 1233825.469741942360997, 521.202499999999418],
              [2699288.526349902153015, 1233836.413520494010299, 522.401249999995343],
              [2699174.90264371689409, 1233840.042350826086476, 525.785000000003492],
              [2699114.263906390406191, 1233838.433624605415389, 526.787500000005821],
              [2699100.322711721993983, 1233906.016224089544266, 530.952499999999418],
              [2699097.522716601379216, 1233913.546232914086431, 531.321249999993597],
              [2699094.928968403488398, 1233916.184986206004396, 531.458750000005239],
              [2699088.791468299925327, 1233918.143736165948212, 531.45625000000291],
              [2699076.817704898305237, 1233918.451212572865188, 530.461249999993015],
              [2699074.827702211216092, 1233919.4487078350503, 530.261249999995925],
              [2699075.48020230140537, 1233920.85745800496079, 530.267500000001746],
              [2699097.903951423242688, 1233924.222456014016643, 530.182499999995343],
              [2699116.286449284292758, 1233927.948701860615984, 530.006250000005821],
              [2699134.488940112292767, 1233932.453685214975849, 529.302500000005239],
              [2699154.882680481765419, 1233938.622417722363025, 528.5625],
              [2699143.475182749330997, 1233945.21367205795832, 528.742499999993015],
              [2699143.522683765273541, 1233947.658673896454275, 528.818750000005821],
              [2699143.045183643698692, 1233948.922423700569198, 528.809999999997672],
              [2699141.836434126831591, 1233950.392424604389817, 528.847500000003492],
              [2699140.167685774620622, 1233951.304927578661591, 528.972500000003492],
              [2699130.508950504940003, 1233958.762454075273126, 530.086249999993015],
              [2699111.856477758381516, 1233976.298753153532743, 532.147500000006403],
              [2699104.077730400487781, 1233982.523758085211739, 532.352499999993597],
              [2699095.636479118838906, 1233987.937506033340469, 532.263749999998254],
              [2699086.815224801190197, 1233992.642498587956652, 531.947499999994761],
              [2699053.060227953828871, 1234008.548755077179521, 532.213749999995343],
              [2699045.22648219531402, 1234011.875012826407328, 532.538750000006985],
              [2699037.151487049646676, 1234014.210021656937897, 532.910000000003492],
              [2699028.783991967327893, 1234015.472530596889555, 533.286250000004657],
              [2699020.325244516134262, 1234015.466285319533199, 533.485000000000582],
              [2698998.069003590382636, 1234012.832551904954016, 534.184999999997672],
              [2698990.000252013094723, 1234013.192549266619608, 534.073749999995925],
              [2698986.622754286509007, 1234017.677553435089067, 534.247499999997672],
              [2698987.8440080313012, 1234023.281310133635998, 534.527499999996508],
              [2698989.162763375788927, 1234032.395069719292223, 534.927500000005239],
              [2698991.221517661120743, 1234041.281327398726717, 535.247499999997672],
              [2698995.184021434281021, 1234049.668834124458954, 535.527499999996508],
              [2699000.659026694484055, 1234057.072593454271555, 535.917499999995925],
              [2698995.815275305416435, 1234056.153841078514233, 535.817500000004657],
              [2698991.522776553872973, 1234058.701343416702002, 535.914999999993597],
              [2698982.340290515683591, 1234077.933868673630059, 536.971250000002328],
              [2698977.851548340637237, 1234085.093882771441713, 537.5625],
              [2698971.860306596849114, 1234090.866397660691291, 538.1875],
              [2698958.88407458178699, 1234099.53393005230464, 539.548750000001746],
              [2698938.434091777540743, 1234114.773961265105754, 540.857499999998254],
              [2698918.677866643760353, 1234130.630256125237793, 542.741250000006403],
              [2698842.217903099022806, 1234188.216573299840093, 545.546249999999418],
              [2698818.800388845615089, 1234205.439048607135192, 544.497499999997672],
              [2698830.155448252800852, 1234244.156654590507969, 548.947499999994761],
              [2698858.895463773049414, 1234268.985431894427165, 550.087499999994179],
              [2698856.617959780618548, 1234275.617924905382097, 549.789999999993597],
              [2698855.730456604156643, 1234282.575419351924211, 549.552500000005239],
              [2698859.216708692256361, 1234282.686672991374508, 549.70625000000291],
              [2698858.7829565173015, 1234299.09791931277141, 549.54374999999709],
              [2698856.30795850045979, 1234315.250423067249358, 549.695000000006985],
              [2698851.430457635782659, 1234335.397921862546355, 549.634999999994761],
              [2698837.217976856976748, 1234378.221706874901429, 551.091249999997672],
              [2698833.404242991004139, 1234394.807985886698589, 552.306249999994179],
              [2698832.373025062028319, 1234418.170543291606009, 554.714999999996508],
              [2698835.306814605370164, 1234450.240614023292437, 557.681249999994179],
              [2698838.394332739990205, 1234472.475646503269672, 559.039999999993597],
              [2698842.834344279486686, 1234494.766917204251513, 559.902499999996508],
              [2698846.939361637458205, 1234519.634448323398829, 561.202499999999418],
              [2698847.498115988913924, 1234525.880706133553758, 561.528749999997672],
              [2698847.11937096202746, 1234532.139465074287727, 561.902499999996508],
              [2698845.811876259278506, 1234538.274474601494148, 562.301250000004075],
              [2698843.606881324667484, 1234544.144483741372824, 562.683749999996508],
              [2698839.658136636018753, 1234552.710743390256539, 563.086249999993015],
              [2698837.999389647971839, 1234557.819498847005889, 563.313750000001164],
              [2698837.628143555019051, 1234563.177005878882483, 563.607499999998254],
              [2698838.566896428354084, 1234568.465761034050956, 563.822499999994761],
              [2698840.758151249494404, 1234573.368269632803276, 564.182499999995343],
              [2698852.88816174864769, 1234593.495788293890655, 564.960000000006403],
              [2698858.283167022280395, 1234608.052047747652978, 565.351250000006985],
              [2698859.89817063882947, 1234616.684554243693128, 565.621249999996508],
              [2698861.563171356450766, 1234621.187055534915999, 565.673750000001746],
              [2698864.649421426001936, 1234624.863305634586141, 565.676250000004075],
              [2698866.619421581272036, 1234626.249555889517069, 565.686249999998836],
              [2698871.118171403650194, 1234627.920805487548932, 565.66874999999709],
              [2698873.516920100431889, 1234628.153303118422627, 565.568750000005821],
              [2698876.076921598054469, 1234633.087055782321841, 565.678750000006403],
              [2698847.393183872569352, 1234630.770828254055232, 566.626250000001164],
              [2698827.716942904051393, 1234631.670844791224226, 567.322499999994761],
              [2698777.098226573318243, 1234628.86965585523285, 569.896250000005239],
              [2698751.143246302846819, 1234633.117191624362022, 571.401249999995343],
              [2698733.498254423029721, 1234637.279706545639783, 572.027499999996508],
              [2698692.638280574698001, 1234640.768504086881876, 574.028749999997672],
              [2698679.829539024736732, 1234639.559769415063784, 574.67500000000291],
              [2698656.744546046480536, 1234639.976032444974408, 575.223750000004657],
              [2698631.505799231119454, 1234638.051038666861132, 575.486250000001746],
              [2698615.19955817097798, 1234634.418554914649576, 576.172500000000582],
              [2698610.899503329768777, 1234634.915957299759611, 572.058749999996508],
              [2698592.715712822973728, 1234682.165886061033234, 569.035000000003492],
              [2698587.038211423903704, 1234680.277133668772876, 568.934999999997672],
              [2698576.620685485191643, 1234707.469587981933728, 566.997499999997672],
              [2698574.215693230740726, 1234700.380851765396073, 567.58125000000291],
              [2698572.775694560725242, 1234698.252104143844917, 567.682499999995343],
              [2698570.945697853341699, 1234696.448360025882721, 567.931249999994179],
              [2698568.795699609909207, 1234695.038363189436495, 568.065000000002328],
              [2698566.411950144451112, 1234694.080864188028499, 568.107499999998254],
              [2698563.88569971639663, 1234693.6108634758275, 568.077499999999418],
              [2698561.315698885824531, 1234693.645862051052973, 568.017500000001746],
              [2698558.803197940345854, 1234694.18711042474024, 567.948749999995925],
              [2698518.075687047559768, 1234708.578342083841562, 567.16874999999709],
              [2698512.609439378604293, 1234711.018346379045397, 567.348750000004657],
              [2698500.558178125880659, 1234722.635826730402187, 566.514999999999418],
              [2698478.473148786462843, 1234750.179525231709704, 564.332500000004075],
              [2698446.515615083742887, 1234787.748216288397089, 561.83125000000291],
              [2698436.361853059381247, 1234797.888195184059441, 560.9375],
              [2698430.879348811227828, 1234805.055687821703032, 560.623749999998836],
              [2698427.376850828994066, 1234813.798191599547863, 560.778749999997672],
              [2698426.408108586445451, 1234823.165705546736717, 561.36250000000291],
              [2698426.985626163892448, 1234839.843237062683329, 562.682499999995343],
              [2698426.663130836561322, 1234846.920745476149023, 563.033750000002328],
              [2698425.233133796602488, 1234853.858250865945593, 563.257500000006985],
              [2698422.73313404712826, 1234860.485751444008201, 563.278749999997672],
              [2698419.224380937404931, 1234866.639496051706374, 563.048750000001746],
              [2698411.404372434597462, 1234878.191981194308028, 562.417499999995925],
              [2698400.255619063973427, 1234900.454475686885417, 562.17500000000291],
              [2698396.295617967378348, 1234911.346973933978006, 562.096250000002328],
              [2698390.095609831158072, 1234923.128209703601897, 561.491250000006403],
              [2698382.114349115174264, 1234931.921940867323428, 560.693750000005821],
              [2698372.284337895922363, 1234938.584421154111624, 559.860000000000582],
              [2698327.534282651729882, 1234961.709323884919286, 555.751250000001164],
              [2698294.925514301750809, 1234974.351792016997933, 554.402499999996508],
              [2698270.826764828059822, 1234979.983043532352895, 554.463749999995343],
              [2698249.559280094690621, 1234982.151821245905012, 555.630000000004657],
              [2698230.913045529276133, 1234982.123099177144468, 556.806249999994179],
              [2698211.630548955406994, 1234980.968105701496825, 557.08125000000291],
              [2698207.474294795654714, 1234981.618098380975425, 556.772500000006403],
              [2698203.470539357047528, 1234982.906838774681091, 556.367499999993015],
              [2698199.715533206239343, 1234984.80182791268453, 555.908750000002328],
              [2698196.751775083597749, 1234987.153063512640074, 555.301250000004075],
              [2698194.470518100541085, 1234990.169301150599495, 554.778749999997672],
              [2698192.920511079952121, 1234994.034288694616407, 554.252500000002328],
              [2698192.490505134221166, 1234998.174278145423159, 553.806249999994179],
              [2698192.94049079483375, 1235009.619252684060484, 552.728749999994761],
              [2698191.782980109099299, 1235024.92423382634297, 551.927500000005239],
              [2698188.469231103081256, 1235024.197985662380233, 552.005000000004657],
              [2698188.741730542387813, 1235019.656734602758661, 551.962499999994179],
              [2698184.886715556494892, 1235019.359207952627912, 550.839999999996508],
              [2698181.192971985787153, 1235008.800469384063035, 551.326249999998254],
              [2698177.361722293775529, 1235006.009219984523952, 551.352499999993597],
              [2698176.115472379606217, 1235001.664220112143084, 551.360000000000582],
              [2698173.890474249608815, 1234997.364223453216255, 551.502500000002328],
              [2698168.471724602393806, 1234995.174224173184484, 551.533750000002328],
              [2698162.786725831683725, 1234989.369226427283138, 551.631250000005821],
              [2698158.055478622671217, 1234983.832981445360929, 551.845000000001164],
              [2698153.072978831361979, 1234983.030481908470392, 551.865000000005239],
              [2698145.137983375694603, 1234984.366740204161033, 552.213749999995343],
              [2698140.092983501031995, 1234981.687990501523018, 552.227499999993597],
              [2698131.637987739406526, 1234983.381748274667189, 552.553750000006403],
              [2698126.971739732660353, 1234981.75050190417096, 552.707500000004075],
              [2698116.297990351915359, 1234983.34425326064229, 552.763749999998254],
              [2698101.599242636468261, 1234986.336757688783109, 552.948749999995925],
              [2698098.099242378026247, 1234988.458007328212261, 552.932499999995343],
              [2698095.554243629332632, 1234991.526759643340483, 553.028749999997672],
              [2698087.371745094656944, 1234990.528012428898364, 553.146250000005239],
              [2698082.195494301617146, 1234989.079261109232903, 553.091249999997672],
              [2698076.27174855908379, 1234984.463018774054945, 553.416249999994761],
              [2698070.59174752375111, 1234983.141767043387517, 553.34375],
              [2698070.490502101369202, 1234979.809275168692693, 553.6875],
              [2698068.670500649604946, 1234976.044272576225922, 553.580000000001746],
              [2698062.691754352301359, 1234975.789279307704419, 553.863750000004075],
              [2698057.866753813810647, 1234973.200528422836214, 553.827499999999418],
              [2698054.700502577703446, 1234969.998026251792908, 553.73750000000291],
              [2698049.914253918919712, 1234969.874278745846823, 553.842499999998836],
              [2698046.956754666287452, 1234967.169280116911978, 553.901249999995343],
              [2698044.20925453491509, 1234968.874279954005033, 553.89375000000291],
              [2698038.703006579075009, 1234970.389283740893006, 554.052500000005239],
              [2698033.565507730934769, 1234976.363035971531644, 554.14375000000291],
              [2698030.396760106086731, 1234974.325540252728388, 554.32499999999709],
              [2698027.880508243106306, 1234975.376787, 554.1875],
              [2698024.650512129534036, 1234974.470543997827917, 554.482499999998254],
              [2698019.105513244401664, 1234979.310546159278601, 554.571249999993597],
              [2698013.646761351730675, 1234978.565542890457436, 554.433749999996508],
              [2698010.500511594116688, 1234979.730543402489275, 554.455000000001746],
              [2698008.061761616263539, 1234979.159293482312933, 554.458750000005239],
              [2698002.643012432847172, 1234981.129295084392652, 554.524999999994179],
              [2698001.326761552598327, 1234979.940543523756787, 554.460000000006403],
              [2697999.866762033198029, 1234980.126794419484213, 554.497499999997672],
              [2697994.829266859684139, 1234984.535553193185478, 554.865000000005239],
              [2697990.289264627266675, 1234986.204299330012873, 554.701249999998254],
              [2697985.598014919087291, 1234988.86179998354055, 554.727499999993597],
              [2697984.61301673669368, 1234990.988053267356008, 554.865000000005239],
              [2697982.776767729315907, 1234991.821805091341957, 554.941250000003492],
              [2697978.228017989546061, 1234990.704305640887469, 554.964999999996508],
              [2697970.511767082847655, 1234992.221804201835766, 554.903749999997672],
              [2697961.200518149882555, 1234995.635556350229308, 554.992499999993015],
              [2697953.058021512813866, 1234997.886812546290457, 555.252500000002328],
              [2697954.761780576780438, 1235009.41932881437242, 555.932499999995343],
              [2697867.803050034213811, 1235021.60311555955559, 557.473750000004657],
              [2697879.414366746321321, 1235105.535735319368541, 562.479999999995925],
              [2697880.256873508449644, 1235108.706997402245179, 562.98750000000291],
              [2697881.92813006369397, 1235111.530759095679969, 563.478749999994761],
              [2697884.301885527092963, 1235113.798268822953105, 563.88749999999709],
              [2697891.388144425582141, 1235117.410784587496892, 564.55000000000291],
              [2697894.906900288537145, 1235120.484545003855601, 564.98750000000291],
              [2697897.526907104998827, 1235124.354557160055265, 565.497499999997672],
              [2697899.075664720498025, 1235128.763320771977305, 566.068750000005821],
              [2697904.591975326184183, 1235161.180929181631655, 570.619999999995343],
              [2697909.582013098988682, 1235183.673496732488275, 573.455000000001746],
              [2697921.627028333954513, 1235225.081024135230109, 574.589999999996508],
              [2697925.512024919502437, 1235240.608518140623346, 574.330000000001746],
              [2697928.650766170583665, 1235257.693502660142258, 573.669999999998254],
              [2697931.61200699582696, 1235263.265986272133887, 572.977499999993597],
              [2697926.150761047843844, 1235267.05099367746152, 573.287500000005821],
              [2697787.687091665808111, 1235302.258641089312732, 579.477499999993597],
              [2697787.153343352954835, 1235304.389894139021635, 579.604999999995925],
              [2697753.025849625933915, 1235303.65490610897541, 580.108749999999418],
              [2697730.479602191597223, 1235301.509911166736856, 580.322499999994761],
              [2697724.168357162736356, 1235310.804920291993767, 580.702499999999418],
              [2697755.065858924295753, 1235328.409922945080325, 580.806249999994179],
              [2697770.930856208316982, 1235338.369917856762186, 580.587499999994179],
              [2697811.847111913841218, 1235378.101177580887452, 580.978749999994761],
              [2698097.500587210059166, 1235640.423183920327574, 560.073749999995925],
              [2698337.523167771752924, 1235644.24832244287245, 565.907500000001164],
              [2698498.251833726186305, 1235817.37442094925791, 559.452499999999418],
              [2698673.118167832493782, 1235877.462067823624238, 565.610000000000582],
              [2698982.462754814885557, 1236037.147576445713639, 534.336249999993015],
              [2698986.815268858801574, 1236041.881351434858516, 535.386249999995925],
              [2699338.178800279740244, 1236008.485954217147082, 518.686249999998836],
              [2699493.48527172813192, 1236197.710097641916946, 535.164999999993597],
              [2699882.363660968374461, 1236168.695696777198464, 507.791249999994761],
              [2700266.427756174001843, 1236329.122555212350562, 533.332500000004075],
              [2700659.417585390154272, 1236298.652242905227467, 520.208750000005239],
              [2700941.447693467140198, 1236203.188678288599476, 528.054999999993015],
              [2700915.487701723352075, 1236200.239943475695327, 528.695000000006985],
              [2700956.202443429734558, 1236098.781982370652258, 509.332500000004075],
              [2700951.664941958617419, 1236097.695729830767959, 509.226250000006985],
              [2701464.246719237416983, 1235846.054153439588845, 548.217499999998836],
              [2701576.086632526014, 1235819.32149678375572, 541.636249999995925],
              [2701814.48993547540158, 1235313.421942968852818, 508.011249999995925],
              [2701821.837431797757745, 1235283.171935984864831, 507.729999999995925],
              [2701906.293916651979089, 1234976.756097661796957, 525.19999999999709],
              [2702599.242169836070389, 1234271.048946172231808, 487.516250000000582],
              [2703384.545103775337338, 1233851.918444242095575, 519.233749999999418],
              [2703405.110076687298715, 1233800.264645389979705, 517.196249999993597],
              [2702754.77608092688024, 1232901.883253825595602, 406.11250000000291],
              [2702742.832327041309327, 1232882.400746976025403, 405.830000000001746],
              [2702238.846094228792936, 1232506.62953235511668, 407.442500000004657],
              [2701783.107318565249443, 1232449.230743841966614, 405.830000000001746],
              [2701522.738586308434606, 1232359.407028712565079, 407.328750000000582],
              [2701520.931066242046654, 1232357.134493195451796, 405.830000000001746],
              [2701444.912341124843806, 1232300.179538004565984, 407.740000000005239],
              [2701368.606064819265157, 1232272.704492419026792, 405.823749999995925],
              [2701309.74983708653599, 1232336.725783366942778, 407.529999999998836],
              [2701167.277313302271068, 1232356.746993707260117, 405.847500000003492],
              [2701154.197315759025514, 1232362.445748325670138, 406.039999999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 105, gemeinde_B: 158, name: 'Stäfa', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2697724.168357162736356, 1235310.804920291993767, 580.702499999999418],
              [2697730.479602191597223, 1235301.509911166736856, 580.322499999994761],
              [2697753.025849625933915, 1235303.65490610897541, 580.108749999999418],
              [2697787.153343352954835, 1235304.389894139021635, 579.604999999995925],
              [2697787.687091665808111, 1235302.258641089312732, 579.477499999993597],
              [2697926.150761047843844, 1235267.05099367746152, 573.287500000005821],
              [2697931.61200699582696, 1235263.265986272133887, 572.977499999993597],
              [2697928.650766170583665, 1235257.693502660142258, 573.669999999998254],
              [2697925.512024919502437, 1235240.608518140623346, 574.330000000001746],
              [2697921.627028333954513, 1235225.081024135230109, 574.589999999996508],
              [2697909.582013098988682, 1235183.673496732488275, 573.455000000001746],
              [2697904.591975326184183, 1235161.180929181631655, 570.619999999995343],
              [2697899.075664720498025, 1235128.763320771977305, 566.068750000005821],
              [2697897.526907104998827, 1235124.354557160055265, 565.497499999997672],
              [2697894.906900288537145, 1235120.484545003855601, 564.98750000000291],
              [2697891.388144425582141, 1235117.410784587496892, 564.55000000000291],
              [2697884.301885527092963, 1235113.798268822953105, 563.88749999999709],
              [2697881.92813006369397, 1235111.530759095679969, 563.478749999994761],
              [2697880.256873508449644, 1235108.706997402245179, 562.98750000000291],
              [2697879.414366746321321, 1235105.535735319368541, 562.479999999995925],
              [2697867.803050034213811, 1235021.60311555955559, 557.473750000004657],
              [2697954.761780576780438, 1235009.41932881437242, 555.932499999995343],
              [2697953.058021512813866, 1234997.886812546290457, 555.252500000002328],
              [2697961.200518149882555, 1234995.635556350229308, 554.992499999993015],
              [2697970.511767082847655, 1234992.221804201835766, 554.903749999997672],
              [2697978.228017989546061, 1234990.704305640887469, 554.964999999996508],
              [2697982.776767729315907, 1234991.821805091341957, 554.941250000003492],
              [2697984.61301673669368, 1234990.988053267356008, 554.865000000005239],
              [2697985.598014919087291, 1234988.86179998354055, 554.727499999993597],
              [2697990.289264627266675, 1234986.204299330012873, 554.701249999998254],
              [2697994.829266859684139, 1234984.535553193185478, 554.865000000005239],
              [2697999.866762033198029, 1234980.126794419484213, 554.497499999997672],
              [2698001.326761552598327, 1234979.940543523756787, 554.460000000006403],
              [2698002.643012432847172, 1234981.129295084392652, 554.524999999994179],
              [2698008.061761616263539, 1234979.159293482312933, 554.458750000005239],
              [2698010.500511594116688, 1234979.730543402489275, 554.455000000001746],
              [2698013.646761351730675, 1234978.565542890457436, 554.433749999996508],
              [2698019.105513244401664, 1234979.310546159278601, 554.571249999993597],
              [2698024.650512129534036, 1234974.470543997827917, 554.482499999998254],
              [2698027.880508243106306, 1234975.376787, 554.1875],
              [2698030.396760106086731, 1234974.325540252728388, 554.32499999999709],
              [2698033.565507730934769, 1234976.363035971531644, 554.14375000000291],
              [2698038.703006579075009, 1234970.389283740893006, 554.052500000005239],
              [2698044.20925453491509, 1234968.874279954005033, 553.89375000000291],
              [2698046.956754666287452, 1234967.169280116911978, 553.901249999995343],
              [2698049.914253918919712, 1234969.874278745846823, 553.842499999998836],
              [2698054.700502577703446, 1234969.998026251792908, 553.73750000000291],
              [2698057.866753813810647, 1234973.200528422836214, 553.827499999999418],
              [2698062.691754352301359, 1234975.789279307704419, 553.863750000004075],
              [2698068.670500649604946, 1234976.044272576225922, 553.580000000001746],
              [2698070.490502101369202, 1234979.809275168692693, 553.6875],
              [2698070.59174752375111, 1234983.141767043387517, 553.34375],
              [2698076.27174855908379, 1234984.463018774054945, 553.416249999994761],
              [2698082.195494301617146, 1234989.079261109232903, 553.091249999997672],
              [2698087.371745094656944, 1234990.528012428898364, 553.146250000005239],
              [2698095.554243629332632, 1234991.526759643340483, 553.028749999997672],
              [2698098.099242378026247, 1234988.458007328212261, 552.932499999995343],
              [2698101.599242636468261, 1234986.336757688783109, 552.948749999995925],
              [2698116.297990351915359, 1234983.34425326064229, 552.763749999998254],
              [2698126.971739732660353, 1234981.75050190417096, 552.707500000004075],
              [2698131.637987739406526, 1234983.381748274667189, 552.553750000006403],
              [2698140.092983501031995, 1234981.687990501523018, 552.227499999993597],
              [2698145.137983375694603, 1234984.366740204161033, 552.213749999995343],
              [2698153.072978831361979, 1234983.030481908470392, 551.865000000005239],
              [2698158.055478622671217, 1234983.832981445360929, 551.845000000001164],
              [2698162.786725831683725, 1234989.369226427283138, 551.631250000005821],
              [2698168.471724602393806, 1234995.174224173184484, 551.533750000002328],
              [2698173.890474249608815, 1234997.364223453216255, 551.502500000002328],
              [2698176.115472379606217, 1235001.664220112143084, 551.360000000000582],
              [2698177.361722293775529, 1235006.009219984523952, 551.352499999993597],
              [2698181.192971985787153, 1235008.800469384063035, 551.326249999998254],
              [2698184.886715556494892, 1235019.359207952627912, 550.839999999996508],
              [2698188.741730542387813, 1235019.656734602758661, 551.962499999994179],
              [2698188.469231103081256, 1235024.197985662380233, 552.005000000004657],
              [2698191.782980109099299, 1235024.92423382634297, 551.927500000005239],
              [2698192.94049079483375, 1235009.619252684060484, 552.728749999994761],
              [2698192.490505134221166, 1234998.174278145423159, 553.806249999994179],
              [2698192.920511079952121, 1234994.034288694616407, 554.252500000002328],
              [2698194.470518100541085, 1234990.169301150599495, 554.778749999997672],
              [2698196.751775083597749, 1234987.153063512640074, 555.301250000004075],
              [2698199.715533206239343, 1234984.80182791268453, 555.908750000002328],
              [2698203.470539357047528, 1234982.906838774681091, 556.367499999993015],
              [2698207.474294795654714, 1234981.618098380975425, 556.772500000006403],
              [2698211.630548955406994, 1234980.968105701496825, 557.08125000000291],
              [2698230.913045529276133, 1234982.123099177144468, 556.806249999994179],
              [2698249.559280094690621, 1234982.151821245905012, 555.630000000004657],
              [2698270.826764828059822, 1234979.983043532352895, 554.463749999995343],
              [2698294.925514301750809, 1234974.351792016997933, 554.402499999996508],
              [2698327.534282651729882, 1234961.709323884919286, 555.751250000001164],
              [2698372.284337895922363, 1234938.584421154111624, 559.860000000000582],
              [2698382.114349115174264, 1234931.921940867323428, 560.693750000005821],
              [2698390.095609831158072, 1234923.128209703601897, 561.491250000006403],
              [2698396.295617967378348, 1234911.346973933978006, 562.096250000002328],
              [2698400.255619063973427, 1234900.454475686885417, 562.17500000000291],
              [2698411.404372434597462, 1234878.191981194308028, 562.417499999995925],
              [2698419.224380937404931, 1234866.639496051706374, 563.048750000001746],
              [2698422.73313404712826, 1234860.485751444008201, 563.278749999997672],
              [2698425.233133796602488, 1234853.858250865945593, 563.257500000006985],
              [2698426.663130836561322, 1234846.920745476149023, 563.033750000002328],
              [2698426.985626163892448, 1234839.843237062683329, 562.682499999995343],
              [2698426.408108586445451, 1234823.165705546736717, 561.36250000000291],
              [2698427.376850828994066, 1234813.798191599547863, 560.778749999997672],
              [2698430.879348811227828, 1234805.055687821703032, 560.623749999998836],
              [2698436.361853059381247, 1234797.888195184059441, 560.9375],
              [2698446.515615083742887, 1234787.748216288397089, 561.83125000000291],
              [2698478.473148786462843, 1234750.179525231709704, 564.332500000004075],
              [2698500.558178125880659, 1234722.635826730402187, 566.514999999999418],
              [2698512.609439378604293, 1234711.018346379045397, 567.348750000004657],
              [2698518.075687047559768, 1234708.578342083841562, 567.16874999999709],
              [2698558.803197940345854, 1234694.18711042474024, 567.948749999995925],
              [2698561.315698885824531, 1234693.645862051052973, 568.017500000001746],
              [2698563.88569971639663, 1234693.6108634758275, 568.077499999999418],
              [2698566.411950144451112, 1234694.080864188028499, 568.107499999998254],
              [2698568.795699609909207, 1234695.038363189436495, 568.065000000002328],
              [2698570.945697853341699, 1234696.448360025882721, 567.931249999994179],
              [2698572.775694560725242, 1234698.252104143844917, 567.682499999995343],
              [2698574.215693230740726, 1234700.380851765396073, 567.58125000000291],
              [2698576.620685485191643, 1234707.469587981933728, 566.997499999997672],
              [2698587.038211423903704, 1234680.277133668772876, 568.934999999997672],
              [2698592.715712822973728, 1234682.165886061033234, 569.035000000003492],
              [2698610.899503329768777, 1234634.915957299759611, 572.058749999996508],
              [2698615.19955817097798, 1234634.418554914649576, 576.172500000000582],
              [2698631.505799231119454, 1234638.051038666861132, 575.486250000001746],
              [2698656.744546046480536, 1234639.976032444974408, 575.223750000004657],
              [2698679.829539024736732, 1234639.559769415063784, 574.67500000000291],
              [2698692.638280574698001, 1234640.768504086881876, 574.028749999997672],
              [2698733.498254423029721, 1234637.279706545639783, 572.027499999996508],
              [2698751.143246302846819, 1234633.117191624362022, 571.401249999995343],
              [2698777.098226573318243, 1234628.86965585523285, 569.896250000005239],
              [2698827.716942904051393, 1234631.670844791224226, 567.322499999994761],
              [2698847.393183872569352, 1234630.770828254055232, 566.626250000001164],
              [2698876.076921598054469, 1234633.087055782321841, 565.678750000006403],
              [2698873.516920100431889, 1234628.153303118422627, 565.568750000005821],
              [2698871.118171403650194, 1234627.920805487548932, 565.66874999999709],
              [2698866.619421581272036, 1234626.249555889517069, 565.686249999998836],
              [2698864.649421426001936, 1234624.863305634586141, 565.676250000004075],
              [2698861.563171356450766, 1234621.187055534915999, 565.673750000001746],
              [2698859.89817063882947, 1234616.684554243693128, 565.621249999996508],
              [2698858.283167022280395, 1234608.052047747652978, 565.351250000006985],
              [2698852.88816174864769, 1234593.495788293890655, 564.960000000006403],
              [2698840.758151249494404, 1234573.368269632803276, 564.182499999995343],
              [2698838.566896428354084, 1234568.465761034050956, 563.822499999994761],
              [2698837.628143555019051, 1234563.177005878882483, 563.607499999998254],
              [2698837.999389647971839, 1234557.819498847005889, 563.313750000001164],
              [2698839.658136636018753, 1234552.710743390256539, 563.086249999993015],
              [2698843.606881324667484, 1234544.144483741372824, 562.683749999996508],
              [2698845.811876259278506, 1234538.274474601494148, 562.301250000004075],
              [2698847.11937096202746, 1234532.139465074287727, 561.902499999996508],
              [2698847.498115988913924, 1234525.880706133553758, 561.528749999997672],
              [2698846.939361637458205, 1234519.634448323398829, 561.202499999999418],
              [2698842.834344279486686, 1234494.766917204251513, 559.902499999996508],
              [2698838.394332739990205, 1234472.475646503269672, 559.039999999993597],
              [2698835.306814605370164, 1234450.240614023292437, 557.681249999994179],
              [2698832.373025062028319, 1234418.170543291606009, 554.714999999996508],
              [2698833.404242991004139, 1234394.807985886698589, 552.306249999994179],
              [2698837.217976856976748, 1234378.221706874901429, 551.091249999997672],
              [2698851.430457635782659, 1234335.397921862546355, 549.634999999994761],
              [2698856.30795850045979, 1234315.250423067249358, 549.695000000006985],
              [2698858.7829565173015, 1234299.09791931277141, 549.54374999999709],
              [2698859.216708692256361, 1234282.686672991374508, 549.70625000000291],
              [2698855.730456604156643, 1234282.575419351924211, 549.552500000005239],
              [2698856.617959780618548, 1234275.617924905382097, 549.789999999993597],
              [2698858.895463773049414, 1234268.985431894427165, 550.087499999994179],
              [2698830.155448252800852, 1234244.156654590507969, 548.947499999994761],
              [2698818.800388845615089, 1234205.439048607135192, 544.497499999997672],
              [2698842.217903099022806, 1234188.216573299840093, 545.546249999999418],
              [2698918.677866643760353, 1234130.630256125237793, 542.741250000006403],
              [2698938.434091777540743, 1234114.773961265105754, 540.857499999998254],
              [2698958.88407458178699, 1234099.53393005230464, 539.548750000001746],
              [2698971.860306596849114, 1234090.866397660691291, 538.1875],
              [2698977.851548340637237, 1234085.093882771441713, 537.5625],
              [2698982.340290515683591, 1234077.933868673630059, 536.971250000002328],
              [2698991.522776553872973, 1234058.701343416702002, 535.914999999993597],
              [2698995.815275305416435, 1234056.153841078514233, 535.817500000004657],
              [2699000.659026694484055, 1234057.072593454271555, 535.917499999995925],
              [2698995.184021434281021, 1234049.668834124458954, 535.527499999996508],
              [2698991.221517661120743, 1234041.281327398726717, 535.247499999997672],
              [2698989.162763375788927, 1234032.395069719292223, 534.927500000005239],
              [2698987.8440080313012, 1234023.281310133635998, 534.527499999996508],
              [2698986.622754286509007, 1234017.677553435089067, 534.247499999997672],
              [2698990.000252013094723, 1234013.192549266619608, 534.073749999995925],
              [2698998.069003590382636, 1234012.832551904954016, 534.184999999997672],
              [2699020.325244516134262, 1234015.466285319533199, 533.485000000000582],
              [2699028.783991967327893, 1234015.472530596889555, 533.286250000004657],
              [2699037.151487049646676, 1234014.210021656937897, 532.910000000003492],
              [2699045.22648219531402, 1234011.875012826407328, 532.538750000006985],
              [2699053.060227953828871, 1234008.548755077179521, 532.213749999995343],
              [2699086.815224801190197, 1233992.642498587956652, 531.947499999994761],
              [2699095.636479118838906, 1233987.937506033340469, 532.263749999998254],
              [2699104.077730400487781, 1233982.523758085211739, 532.352499999993597],
              [2699111.856477758381516, 1233976.298753153532743, 532.147500000006403],
              [2699130.508950504940003, 1233958.762454075273126, 530.086249999993015],
              [2699140.167685774620622, 1233951.304927578661591, 528.972500000003492],
              [2699141.836434126831591, 1233950.392424604389817, 528.847500000003492],
              [2699143.045183643698692, 1233948.922423700569198, 528.809999999997672],
              [2699143.522683765273541, 1233947.658673896454275, 528.818750000005821],
              [2699143.475182749330997, 1233945.21367205795832, 528.742499999993015],
              [2699154.882680481765419, 1233938.622417722363025, 528.5625],
              [2699134.488940112292767, 1233932.453685214975849, 529.302500000005239],
              [2699116.286449284292758, 1233927.948701860615984, 530.006250000005821],
              [2699097.903951423242688, 1233924.222456014016643, 530.182499999995343],
              [2699075.48020230140537, 1233920.85745800496079, 530.267500000001746],
              [2699074.827702211216092, 1233919.4487078350503, 530.261249999995925],
              [2699076.817704898305237, 1233918.451212572865188, 530.461249999993015],
              [2699088.791468299925327, 1233918.143736165948212, 531.45625000000291],
              [2699094.928968403488398, 1233916.184986206004396, 531.458750000005239],
              [2699097.522716601379216, 1233913.546232914086431, 531.321249999993597],
              [2699100.322711721993983, 1233906.016224089544266, 530.952499999999418],
              [2699114.263906390406191, 1233838.433624605415389, 526.787500000005821],
              [2699174.90264371689409, 1233840.042350826086476, 525.785000000003492],
              [2699288.526349902153015, 1233836.413520494010299, 522.401249999995343],
              [2699333.181334426160902, 1233825.469741942360997, 521.202499999999418],
              [2699316.742657139431685, 1233759.496120921568945, 526.66874999999709],
              [2699338.04764528432861, 1233754.102349332766607, 525.761249999995925],
              [2699351.480133155826479, 1233751.584827455691993, 524.839999999996508],
              [2699387.372596062254161, 1233746.868510697968304, 522.027499999996508],
              [2699399.20508099906147, 1233746.008483649464324, 520.88749999999709],
              [2699402.405076016671956, 1233748.063474744791165, 520.511249999995925],
              [2699403.73132239561528, 1233751.315968311857432, 520.238750000004075],
              [2699407.757581165060401, 1233730.668483613757417, 520.892500000001746],
              [2699410.395081399474293, 1233712.855983783956617, 520.907500000001164],
              [2699411.125078927259892, 1233703.867229280527681, 520.721250000002328],
              [2699410.537577541545033, 1233694.973476729122922, 520.617499999993015],
              [2699408.137575913686305, 1233689.590973824029788, 520.497499999997672],
              [2699393.876329140737653, 1233673.889729688642547, 520.751250000001164],
              [2699389.102569888811558, 1233666.713463260559365, 520.061249999998836],
              [2699391.948820872697979, 1233665.135964928893372, 520.132500000006985],
              [2699367.347555208019912, 1233622.70968711655587, 518.977499999993597],
              [2699363.65004810038954, 1233615.134674473199993, 518.447499999994761],
              [2699361.396290030796081, 1233607.010910079116002, 517.84375],
              [2699360.666281258221716, 1233598.610894407145679, 517.186249999998836],
              [2699361.480025168508291, 1233590.219633469823748, 516.728749999994761],
              [2699366.026263327803463, 1233565.212112052133307, 515.836249999993015],
              [2699367.760007418692112, 1233551.283351360820234, 515.391250000000582],
              [2699369.512493810616434, 1233523.534576836274937, 514.368749999994179],
              [2699370.298742256127298, 1233520.720824024872854, 514.251250000001164],
              [2699371.651240687817335, 1233518.130821178900078, 514.132500000006985],
              [2699373.512490090448409, 1233515.877070061396807, 514.086249999993015],
              [2699375.799988935235888, 1233514.060817937832326, 513.997499999997672],
              [2699378.416237965226173, 1233512.757066142512485, 513.922500000000582],
              [2699381.244987063109875, 1233512.027064477559179, 513.852499999993597],
              [2699384.164986493997276, 1233511.89831341104582, 513.807499999995343],
              [2699387.046236060094088, 1233512.378312582382932, 513.772500000006403],
              [2699492.171216436196119, 1233541.248275875579566, 512.213749999995343],
              [2699515.896204013377428, 1233548.689503385685384, 511.26249999999709],
              [2699566.618668922223151, 1233569.103190199704841, 508.589999999996508],
              [2699634.704865846317261, 1233598.2468447831925, 504.554999999993015],
              [2699662.926099909003824, 1233609.839316019788384, 503.337499999994179],
              [2699658.764850216917694, 1233602.029316562460735, 503.363750000004075],
              [2699655.322347298264503, 1233593.751811360241845, 503.147500000006403],
              [2699633.738566631451249, 1233536.44800668139942, 500.865000000005239],
              [2699637.621063440106809, 1233534.903000914258882, 500.622499999997672],
              [2699634.466062306892127, 1233525.462998864706606, 500.539999999993597],
              [2699632.619809807743877, 1233515.627994358772412, 500.353749999994761],
              [2699632.056055350229144, 1233505.637986342422664, 500.020000000004075],
              [2699632.493551356252283, 1233495.60797913488932, 499.720000000001164],
              [2699634.107295278459787, 1233482.472968158079311, 499.26249999999709],
              [2699634.521041619125754, 1233469.284211511723697, 498.98750000000291],
              [2699634.39228655397892, 1233452.369202331406996, 498.607499999998254],
              [2699635.47602904913947, 1233435.655438809422776, 498.04374999999709],
              [2699639.172268603462726, 1233426.744170079240575, 497.257500000006985],
              [2699697.632254237309098, 1233453.97289367695339, 496.133749999993597],
              [2699711.955996497999877, 1233420.791629303945228, 495.541249999994761],
              [2699756.314725754316896, 1233342.651590782450512, 493.948749999995925],
              [2699804.088455785065889, 1233293.884053875925019, 492.412500000005821],
              [2699804.63470572559163, 1233291.304053731728345, 492.407500000001164],
              [2699868.362200354691595, 1233231.11154235387221, 491.952499999999418],
              [2699868.830578703898937, 1233178.050881553208455, 464.098750000004657],
              [2699890.400575269944966, 1233178.700875061331317, 463.82499999999709],
              [2699922.64056828757748, 1233182.567112107295543, 463.277499999996508],
              [2699946.469319044612348, 1233186.470863055670634, 463.316250000003492],
              [2699976.405534933321178, 1233185.442051899619401, 460.73750000000291],
              [2699992.399258083663881, 1233185.434503918746486, 458.713749999995343],
              [2699989.82425650767982, 1233182.993251144653186, 458.597500000003492],
              [2699985.354253529105335, 1233179.715745903784409, 458.377500000002328],
              [2699988.643006016034633, 1233174.695750207174569, 458.561249999998836],
              [2699977.774252136237919, 1233168.41824346082285, 458.278749999997672],
              [2699960.659211406484246, 1233133.255671091377735, 455.238750000004075],
              [2699975.132960280403495, 1233137.168168872129172, 455.14375000000291],
              [2699978.21170902531594, 1233139.48316661035642, 455.047500000000582],
              [2700016.887953749857843, 1233111.960656279698014, 454.622499999997672],
              [2700024.087952670641243, 1233108.795654205372557, 454.536250000004657],
              [2700034.775445403531194, 1233106.813141086837277, 453.983749999999418],
              [2700056.155441392213106, 1233111.2293836241588, 453.667499999995925],
              [2700117.640414898749441, 1233131.604335660347715, 451.63749999999709],
              [2700143.185408576857299, 1233136.355574019718915, 451.145000000004075],
              [2700209.725390339735895, 1233144.291790523333475, 449.729999999995925],
              [2700218.484198076184839, 1233171.901893145637587, 454.048750000001746],
              [2700303.470428876578808, 1233175.034357561264187, 452.547500000000582],
              [2700299.174098741263151, 1233099.995464668143541, 446.547500000000582],
              [2700330.554102700669318, 1233107.94297121395357, 446.821249999993597],
              [2700331.221595853101462, 1233089.226708874106407, 446.307499999995343],
              [2700384.416594190057367, 1233079.867954897228628, 446.14375000000291],
              [2700414.510337691288441, 1233075.572942789178342, 445.634999999994761],
              [2700430.910338654182851, 1233067.644194139167666, 445.695000000006985],
              [2700464.180353238712996, 1233059.516719358973205, 446.76249999999709],
              [2700472.222854621708393, 1233052.871721612056717, 446.860000000000582],
              [2700464.676596757490188, 1233043.221707693999633, 446.276249999995343],
              [2700448.599087174981833, 1233018.230440735584125, 445.570000000006985],
              [2700436.430330065079033, 1232994.736678129760548, 445.046249999999418],
              [2700427.342825795058161, 1232970.87792048510164, 444.732499999998254],
              [2700404.764051761478186, 1232890.780377490445971, 442.947499999994761],
              [2700400.365308184642345, 1232873.046638803323731, 443.431249999994179],
              [2700404.540309097617865, 1232862.46164025249891, 443.496249999996508],
              [2700405.04156432999298, 1232850.199149417923763, 443.88749999999709],
              [2700415.246579924598336, 1232832.912926762597635, 445.047500000000582],
              [2700420.897843271959573, 1232821.310450237011537, 446.042499999995925],
              [2700425.315356657840312, 1232809.724223799537867, 447.041249999994761],
              [2700427.142867246177047, 1232799.276742463698611, 447.832500000004075],
              [2700425.140361421275884, 1232782.770482007879764, 447.397500000006403],
              [2700426.364101839717478, 1232774.439214903628454, 446.678750000006403],
              [2700429.812838183250278, 1232766.662940547568724, 445.653749999997672],
              [2700437.064069027546793, 1232758.795406352961436, 444.213749999995343],
              [2700455.941528930328786, 1232742.339084723498672, 441.197499999994761],
              [2700462.541516627185047, 1232733.585312695940956, 440.271250000005239],
              [2700467.046510278712958, 1232723.620301265036687, 439.792499999995925],
              [2700468.429006972815841, 1232715.101545291021466, 439.54374999999709],
              [2700467.015256795100868, 1232703.445294897770509, 439.53125],
              [2700464.246514734812081, 1232692.020308927632868, 440.127500000002328],
              [2700463.9077761718072, 1232686.507829171372578, 440.983749999999418],
              [2700465.837791268248111, 1232676.239105832064524, 442.11250000000291],
              [2700484.595309525728226, 1232640.930387576576322, 443.464999999996508],
              [2700504.676611339207739, 1232605.724228812614456, 447.328750000000582],
              [2700509.38913750089705, 1232591.171775010181591, 449.283750000002328],
              [2700513.211645674426109, 1232567.274289222434163, 449.892500000001746],
              [2700532.022896202281117, 1232543.065539595670998, 449.917499999995925],
              [2700546.182900771033019, 1232528.750547314062715, 450.248749999998836],
              [2700561.627902246546, 1232516.870549550279975, 450.347500000003492],
              [2700613.056669089011848, 1232506.824328406946734, 451.570000000006985],
              [2700608.516495355404913, 1232482.332770122680813, 438.567500000004657],
              [2700611.24771849764511, 1232479.370222417404875, 436.554999999993015],
              [2700612.002704995218664, 1232477.35019843862392, 435.54374999999709],
              [2700612.25895191077143, 1232474.432692935457453, 435.3125],
              [2700616.800204590428621, 1232473.662697612075135, 435.509999999994761],
              [2700624.671453763730824, 1232474.857696018880233, 435.442500000004657],
              [2700630.648923419881612, 1232446.517641840036958, 433.166249999994761],
              [2700632.577672421466559, 1232442.941390006802976, 433.089999999996508],
              [2700636.032672624103725, 1232440.378890281543136, 433.102499999993597],
              [2700652.900175006128848, 1232437.437644188525155, 433.26875000000291],
              [2700671.510179542005062, 1232436.933901907876134, 433.595000000001164],
              [2700692.467693786602467, 1232437.107676808256656, 434.646250000005239],
              [2700713.331459633074701, 1232439.332704574335366, 435.817500000004657],
              [2700744.730224362108856, 1232445.281480196397752, 436.897500000006403],
              [2700801.335206326562911, 1232463.865197407081723, 435.507500000006985],
              [2700834.327708040829748, 1232475.791449988028035, 435.61250000000291],
              [2700880.786462307907641, 1232494.05895691527985, 435.898749999993015],
              [2700963.372685184236616, 1232536.512657783227041, 433.811249999998836],
              [2700979.495181140024215, 1232545.410150417825207, 433.497499999997672],
              [2700982.780184130650014, 1232539.07515560882166, 433.71875],
              [2700997.023669063579291, 1232508.250935228075832, 413.877500000002328],
              [2700997.598669255618006, 1232508.487185560632497, 413.891250000000582],
              [2701010.253609978593886, 1232476.745830016909167, 409.447499999994761],
              [2701084.232360437512398, 1232513.847079929430038, 409.432499999995343],
              [2701097.378610318526626, 1232521.147079568356276, 409.414999999993597],
              [2701104.029860182665288, 1232509.122079113731161, 409.399999999994179],
              [2701142.13486260548234, 1232381.730831696186215, 409.552500000005239],
              [2701141.234852756373584, 1232378.219564219703898, 408.816250000003492],
              [2701141.654837468639016, 1232371.019537070998922, 407.672500000000582],
              [2701154.197335777804255, 1232369.864533862331882, 407.537500000005821],
              [2701154.197315759025514, 1232362.445748325670138, 406.039999999993597],
              [2701151.956066021230072, 1232364.310748841846362, 406.061249999998836],
              [2701148.156065802555531, 1232364.360748522914946, 406.047500000000582],
              [2701146.106065787374973, 1232363.110748509643599, 406.047500000000582],
              [2701146.064812878146768, 1232361.351993344724178, 405.830000000001746],
              [2701142.982315976172686, 1232361.603248883504421, 406.063750000001164],
              [2701139.55606846883893, 1232362.39200336439535, 406.252500000002328],
              [2701135.399814972188324, 1232362.381997230928391, 405.993749999994179],
              [2701129.084813827648759, 1232363.278245313558728, 405.912500000005821],
              [2701132.03107040328905, 1232367.188256956171244, 406.402499999996508],
              [2701130.186071072239429, 1232369.673258194932714, 406.453750000000582],
              [2701126.514814922586083, 1232368.086997341131791, 405.996249999996508],
              [2701121.719816552475095, 1232368.158250303007662, 406.121249999996508],
              [2701117.406068332958966, 1232367.233253524173051, 406.257500000006985],
              [2701116.051067422144115, 1232366.068251918070018, 406.190000000002328],
              [2701113.651066746562719, 1232367.307000775588676, 406.141250000000582],
              [2701111.093567942269146, 1232367.13450293848291, 406.232499999998254],
              [2701106.457318438217044, 1232367.869503890862688, 406.272500000006403],
              [2701103.182317387778312, 1232367.114502078387886, 406.196249999993597],
              [2701101.109817873220891, 1232365.117002948885784, 406.233749999999418],
              [2701096.87481799907982, 1232365.022003249265254, 406.246249999996508],
              [2701096.162318095099181, 1232362.613253404852003, 406.253750000003492],
              [2701092.249818526674062, 1232361.69075423059985, 406.288750000006985],
              [2701086.089818336535245, 1232363.99825401743874, 406.278749999997672],
              [2701081.772319552022964, 1232364.734506237786263, 406.372499999997672],
              [2701076.912322418298572, 1232359.327011355664581, 406.589999999996508],
              [2701073.716070569586009, 1232359.15200812369585, 406.453750000000582],
              [2701070.437320286408067, 1232360.305757694179192, 406.434999999997672],
              [2701068.52606883412227, 1232357.72825511964038, 406.327499999999418],
              [2701064.288568861782551, 1232358.464505249401554, 406.332500000004075],
              [2701062.358569695614278, 1232362.775756797986105, 406.396250000005239],
              [2701060.841072257142514, 1232362.772011354099959, 406.588749999995343],
              [2701062.212319396901876, 1232358.127006230177358, 406.373749999998836],
              [2701059.576069489587098, 1232357.788256430067122, 406.382500000006985],
              [2701052.78107139794156, 1232358.767009935341775, 406.529999999998836],
              [2701052.051069054286927, 1232362.500755817862228, 406.354999999995925],
              [2701040.788567163050175, 1232360.562002639984712, 406.221250000002328],
              [2701036.472319029271603, 1232360.883256010944024, 406.363750000004075],
              [2701036.198568342253566, 1232359.847004794050008, 406.3125],
              [2701030.556077783927321, 1232360.010771615430713, 407.022500000006403],
              [2701031.906061857007444, 1232347.660743270535022, 405.830000000001746],
              [2701023.806061787065119, 1232346.710743265226483, 405.830000000001746],
              [2701023.706062235403806, 1232347.310744065791368, 405.863750000004075],
              [2701018.756061858031899, 1232346.760743473190814, 405.838749999995343],
              [2701018.559820643626153, 1232352.42950910050422, 406.496249999996508],
              [2700999.329813769552857, 1232354.894497248111293, 405.994999999995343],
              [2700997.969815044663846, 1232355.555749532999471, 406.091249999997672],
              [2700995.411064854823053, 1232355.880749237956479, 406.078750000000582],
              [2700994.693562825210392, 1232355.380745657719672, 405.927500000005239],
              [2700992.374814357142895, 1232356.039498411817476, 406.04374999999709],
              [2700990.298562770243734, 1232355.120745615102351, 405.926250000004075],
              [2700989.257314763497561, 1232356.19699918734841, 406.076249999998254],
              [2700984.222314601298422, 1232356.515748977428302, 406.067500000004657],
              [2700982.627312934026122, 1232355.184496042551473, 405.943750000005821],
              [2700980.788563819136471, 1232355.428247643401846, 406.011249999995925],
              [2700979.912311740685254, 1232354.346993957879022, 405.85624999999709],
              [2700978.643564368132502, 1232355.448248647386208, 406.053750000006403],
              [2700965.048580654896796, 1232354.474527728511021, 407.28125],
              [2700963.452311943285167, 1232353.973244584165514, 405.882500000006985],
              [2700957.617315248120576, 1232354.455750542227179, 406.133749999993597],
              [2700954.10356688965112, 1232353.368253495777026, 406.258749999993597],
              [2700945.077313132118434, 1232352.181996979052201, 405.983749999999418],
              [2700942.52356785396114, 1232350.764505369588733, 406.338749999995343],
              [2700943.326066109351814, 1232349.355752252275124, 406.207500000004075],
              [2700940.451070861890912, 1232349.015760721405968, 406.565000000002328],
              [2700937.098564952146262, 1232347.595750289037824, 406.125],
              [2700935.664810996968299, 1232345.765743284951895, 405.830000000001746],
              [2700931.271060957107693, 1232345.173243282595649, 405.830000000001746],
              [2700928.151060961186886, 1232346.410743351094425, 405.832500000004075],
              [2700926.783563788514584, 1232349.728248416446149, 406.044999999998254],
              [2700924.463562096003443, 1232350.469495456432924, 405.919999999998254],
              [2700920.541065836790949, 1232353.033252171939239, 406.202499999999418],
              [2700916.627312893979251, 1232352.524497019359842, 405.985000000000582],
              [2700914.872311176266521, 1232351.358243986498564, 405.857499999998254],
              [2700913.348561629652977, 1232353.096994828665629, 405.892500000001746],
              [2700909.352311109658331, 1232353.336993982782587, 405.85624999999709],
              [2700909.664828903973103, 1232355.910775535507128, 407.1875],
              [2700901.914810725022107, 1232355.641993430443108, 405.832500000004075],
              [2700897.193563687615097, 1232358.036998784402385, 406.057499999995343],
              [2700894.631077813450247, 1232359.857023886404932, 407.116250000006403],
              [2700886.882318805437535, 1232358.675758029567078, 406.447499999994761],
              [2700885.367318992037326, 1232357.758258380694315, 406.462499999994179],
              [2700879.931071418337524, 1232358.32576277339831, 406.647500000006403],
              [2700877.062312069348991, 1232355.41199622489512, 405.94999999999709],
              [2700875.858570979442447, 1232357.402012056903914, 406.617499999993015],
              [2700875.066069602500647, 1232354.909509605262429, 406.514999999999418],
              [2700868.113568774890155, 1232354.892008246388286, 406.457500000004075],
              [2700867.152317810337991, 1232355.885756563628092, 406.386249999995925],
              [2700864.194815061520785, 1232355.96075173583813, 406.182499999995343],
              [2700861.706066909246147, 1232360.188255086308345, 406.322499999994761],
              [2700852.67981652636081, 1232358.670754549093544, 406.30000000000291],
              [2700848.769814123399556, 1232356.917000331217423, 406.122499999997672],
              [2700846.611063401214778, 1232357.326999091776088, 406.070000000006985],
              [2700842.54106015805155, 1232355.15824338956736, 405.830000000001746],
              [2700839.106060128193349, 1232354.650743387639523, 405.830000000001746],
              [2700837.898560598026961, 1232357.885744267143309, 405.866250000006403],
              [2700838.613561492878944, 1232359.299495849292725, 405.932499999995343],
              [2700836.134810867253691, 1232359.956994789652526, 405.88749999999709],
              [2700827.089818449690938, 1232365.247008429374546, 406.461249999993015],
              [2700822.132310738321394, 1232366.313244845252484, 405.88749999999709],
              [2700820.849812481552362, 1232367.720747971674427, 406.01875000000291],
              [2700820.524819494225085, 1232369.962010427145287, 406.54374999999709],
              [2700818.442319425754249, 1232371.617010344751179, 406.539999999993597],
              [2700813.803563802503049, 1232373.01700047031045, 406.122499999997672],
              [2700805.481061924714595, 1232377.063247306970879, 405.98750000000291],
              [2700795.323560995049775, 1232380.440745854750276, 405.92500000000291],
              [2700792.764812191016972, 1232380.93199802772142, 406.016250000000582],
              [2700787.411062713246793, 1232380.918249033624306, 406.058749999996508],
              [2700782.46106318384409, 1232379.328249931801111, 406.097500000003492],
              [2700779.19231033558026, 1232376.248244909569621, 405.886249999995925],
              [2700770.724810425657779, 1232375.230745201697573, 405.898749999993015],
              [2700764.814810891170055, 1232374.053246112307534, 405.9375],
              [2700763.376061446964741, 1232374.133247120771557, 405.979999999995925],
              [2700753.951060677412897, 1232372.199495889246464, 405.928750000006403],
              [2700750.274810811970383, 1232372.605746186105534, 405.941250000003492],
              [2700744.924810378812253, 1232370.848245495930314, 405.912500000005821],
              [2700741.969811222981662, 1232370.341997034847736, 405.977499999993597],
              [2700738.377309218980372, 1232368.75574353011325, 405.830000000001746],
              [2700733.347310693003237, 1232367.248246213421226, 405.943750000005821],
              [2700730.471060467883945, 1232366.991995859891176, 405.928750000006403],
              [2700724.408559462986887, 1232363.324494139058515, 405.857499999998254],
              [2700721.614810073748231, 1232362.071995259495452, 405.904999999998836],
              [2700718.419809360057116, 1232361.31699404027313, 405.853749999994761],
              [2700714.104810521937907, 1232361.554496177705005, 405.943750000005821],
              [2700707.228558941744268, 1232362.69949348946102, 405.830000000001746],
              [2700705.068560992833227, 1232363.440747173270211, 405.985000000000582],
              [2700703.072308904491365, 1232363.186993500450626, 405.830000000001746],
              [2700699.473558872938156, 1232363.758243500255048, 405.830000000001746],
              [2700695.559810591395944, 1232363.416996610583737, 405.961249999993015],
              [2700692.68106191791594, 1232363.990749010816216, 406.0625],
              [2700689.00606101565063, 1232363.565747475018725, 405.997499999997672],
              [2700687.011060513090342, 1232362.730746602406725, 405.961249999993015],
              [2700683.494811467360705, 1232362.638248355826363, 406.035000000003492],
              [2700680.702309388201684, 1232361.220744704362005, 405.881250000005821],
              [2700679.262310796882957, 1232361.381997218355536, 405.98750000000291],
              [2700678.066059898585081, 1232360.465745643246919, 405.921249999999418],
              [2700674.553559500724077, 1232359.460744986310601, 405.89375000000291],
              [2700651.9485599999316, 1232355.58449620520696, 405.946249999993597],
              [2700648.832311160396785, 1232355.743248313432559, 406.035000000003492],
              [2700648.517309637274593, 1232353.91574560222216, 405.921249999999418],
              [2700644.041058644652367, 1232354.070743915159255, 405.850000000005821],
              [2700644.037312434986234, 1232355.398250649450347, 406.133749999993597],
              [2700635.409809300675988, 1232354.545745223062113, 405.904999999998836],
              [2700633.331061386503279, 1232354.873248962685466, 406.0625],
              [2700633.254809935111552, 1232353.710746374214068, 405.953750000000582],
              [2700631.416060034651309, 1232353.871996583882719, 405.962499999994179],
              [2700631.573561337310821, 1232354.868248902028427, 406.059999999997672],
              [2700625.418560916557908, 1232355.433248253073543, 406.032500000001164],
              [2700621.983559214044362, 1232354.678245288319886, 405.907500000001164],
              [2700612.47606044774875, 1232353.906997627113014, 406.006250000005821],
              [2700610.63981051556766, 1232353.238247769186273, 406.01249999999709],
              [2700605.207310669589788, 1232352.89199812640436, 406.027499999996508],
              [2700601.689810786396265, 1232353.298248398816213, 406.038750000006985],
              [2700600.412308687809855, 1232352.879494686610997, 405.882500000006985],
              [2700587.947310446295887, 1232352.515748006524518, 406.022500000006403],
              [2700574.608559675514698, 1232350.074496834073216, 405.973750000004657],
              [2700563.032309290952981, 1232345.893246309133247, 405.952499999999418],
              [2700561.196060376707464, 1232345.556998256593943, 406.035000000003492],
              [2700559.601059074513614, 1232344.390745969023556, 405.938750000001164],
              [2700554.011058807838708, 1232342.799495570128784, 405.922500000000582],
              [2700551.373559987172484, 1232343.040747709572315, 406.01249999999709],
              [2700545.143558996263891, 1232341.780746035976335, 405.942500000004657],
              [2700538.192309002857655, 1232341.264496157178655, 405.947499999994761],
              [2700531.239809641148895, 1232341.329497403232381, 406.0],
              [2700528.68480768147856, 1232340.824493966531008, 405.854999999995925],
              [2700520.292310058604926, 1232341.135748321423307, 406.038750000006985],
              [2700516.538559941574931, 1232340.461998174432665, 406.032500000001164],
              [2700516.541057405527681, 1232339.381993661168963, 405.842499999998836],
              [2700514.703557691071182, 1232339.626994202844799, 405.865000000005239],
              [2700514.699809827376157, 1232340.705747995525599, 406.024999999994179],
              [2700507.271057205740362, 1232339.77449345914647, 405.833750000005239],
              [2700505.352309376467019, 1232340.100747343152761, 405.997499999997672],
              [2700504.713557131588459, 1232339.601993372663856, 405.830000000001746],
              [2700500.717309366445988, 1232339.840747400419787, 406.0],
              [2700491.609809854533523, 1232339.235748409060761, 406.042499999995925],
              [2700489.612309183459729, 1232338.733247247291729, 405.993749999994179],
              [2700483.379809396341443, 1232338.799497722880915, 406.013749999998254],
              [2700476.668559670913965, 1232338.201998315984383, 406.038750000006985],
              [2700473.953558127395809, 1232337.446995614562184, 405.92500000000291],
              [2700473.152309018187225, 1232338.109497217927128, 405.992499999993015],
              [2700468.359809362795204, 1232337.515747892204672, 406.021250000005239],
              [2700468.3635573098436, 1232336.104494248051196, 405.867499999993015],
              [2700463.169806794263422, 1232336.008243416668847, 405.832500000004075],
              [2700463.244809514842927, 1232337.503248255234212, 406.036250000004657],
              [2700461.24730938160792, 1232337.664498044643551, 406.027499999996508],
              [2700457.572309164330363, 1232337.073247712804005, 406.013749999998254],
              [2700455.892309533432126, 1232337.816998402355239, 406.042499999995925],
              [2700450.537308115977794, 1232338.051995979156345, 405.940000000002328],
              [2700446.059809812344611, 1232339.036999069619924, 406.070000000006985],
              [2700441.583556666970253, 1232339.274493568344042, 405.837499999994179],
              [2700434.706057003699243, 1232341.165744294412434, 405.867499999993015],
              [2700430.86855647014454, 1232341.736993412720039, 405.830000000001746],
              [2700419.52230980573222, 1232341.625749509781599, 406.087499999994179],
              [2700418.486056960187852, 1232340.543244465952739, 405.875],
              [2700413.156058362685144, 1232339.52574704005383, 405.983749999999418],
              [2700411.171058211941272, 1232339.601996801327914, 405.973750000004657],
              [2700409.743557516485453, 1232338.764495582086965, 405.922500000000582],
              [2700406.40980730112642, 1232338.336995260091498, 405.908750000002328],
              [2700401.247309560421854, 1232338.818249352509156, 406.08125000000291],
              [2700392.911058567930013, 1232338.373247723095119, 406.01249999999709],
              [2700379.736056012567133, 1232336.245743382023647, 405.830000000001746],
              [2700375.368557509500533, 1232336.314496116479859, 405.945000000006985],
              [2700365.923556974157691, 1232334.533245300641283, 405.911250000004657],
              [2700361.794808556325734, 1232334.351998169906437, 406.032500000001164],
              [2700359.891062546987087, 1232333.679505276260898, 406.332500000004075],
              [2700360.613559149671346, 1232330.018249197863042, 406.077499999999418],
              [2700360.7848094496876, 1232324.439499690430239, 406.100000000005821],
              [2700358.962307082489133, 1232322.684495499823242, 405.923750000001746],
              [2700355.0710588847287, 1232322.67074876325205, 406.061249999998836],
              [2700352.214806452393532, 1232321.910744488006458, 405.881250000005821],
              [2700349.832307485863566, 1232322.319496366428211, 405.960000000006403],
              [2700347.372311419341713, 1232321.560753374360502, 406.256250000005821],
              [2700343.719810869079083, 1232321.798252462642267, 406.217499999998836],
              [2700341.736055776011199, 1232321.041993454797193, 405.837499999994179],
              [2700333.398557502310723, 1232321.096996655222028, 405.972500000003492],
              [2700330.938563492149115, 1232320.338257308583707, 406.422500000000582],
              [2700331.104805830866098, 1232316.92574368417263, 405.848750000004657],
              [2700331.823557457420975, 1232315.34574655094184, 405.970000000001164],
              [2700331.747305606026202, 1232313.845743254292756, 405.83125000000291],
              [2700323.648555514402688, 1232313.734493218362331, 405.830000000001746],
              [2700321.978555500973016, 1232314.645743230590597, 405.830000000001746],
              [2700321.973557303659618, 1232317.393246450694278, 405.964999999996508],
              [2700321.176055494230241, 1232318.973243265878409, 405.830000000001746],
              [2700320.381055484991521, 1232319.469493271084502, 405.830000000001746],
              [2700319.648561482783407, 1232327.462003994733095, 406.279999999998836],
              [2700318.574813846033067, 1232329.142008216120303, 406.457500000004075],
              [2700312.856068234890699, 1232328.760766089893878, 406.789999999993597],
              [2700313.058558919932693, 1232327.52199955843389, 406.092499999998836],
              [2700311.314813880249858, 1232326.100758367450908, 406.464999999996508],
              [2700311.496056155767292, 1232315.858244575094432, 405.886249999995925],
              [2700309.908555391710252, 1232315.519493242027238, 405.830000000001746],
              [2700309.349805386736989, 1232316.600743247196078, 405.830000000001746],
              [2700309.742305387742817, 1232318.600743269314989, 405.830000000001746],
              [2700308.781057247426361, 1232322.844496613368392, 405.970000000001164],
              [2700309.252311141695827, 1232325.094503529369831, 406.261249999995925],
              [2700307.18480885354802, 1232326.335749522782862, 406.091249999997672],
              [2700305.757305317092687, 1232325.830743268597871, 405.827499999999418],
              [2700303.929808638524264, 1232326.408249196363613, 406.077499999999418],
              [2700301.8673056489788, 1232325.568243914516643, 405.854999999995925],
              [2700297.97480735136196, 1232326.38699699984863, 405.985000000000582],
              [2700284.719805665314198, 1232323.926994211506099, 405.867499999993015],
              [2700271.303556995932013, 1232322.880746776005253, 405.976250000006985],
              [2700269.241055825725198, 1232322.208244732115418, 405.889999999999418],
              [2700268.687305069062859, 1232321.039493381744251, 405.833750000005239],
              [2700264.717308706603944, 1232321.193249908275902, 406.108749999999418],
              [2700264.481054984498769, 1232320.193243296584114, 405.830000000001746],
              [2700261.859806596767157, 1232320.433246194152161, 405.952499999999418],
              [2700260.587308587040752, 1232321.678249761927873, 406.102499999993597],
              [2700245.978557804599404, 1232321.378248607041314, 406.053750000006403],
              [2700238.83355580503121, 1232320.520745169837028, 405.908750000002328],
              [2700228.909808651544154, 1232319.987000378081575, 406.128750000003492],
              [2700226.291056944057345, 1232319.478247383842245, 406.002500000002328],
              [2700227.557315265294164, 1232321.230762143852189, 406.625],
              [2700227.158569033723325, 1232322.06326884124428, 406.907500000001164],
              [2700225.808565114624798, 1232321.974511914420873, 406.615000000005239],
              [2700223.906055652536452, 1232320.469495141645893, 405.907500000001164],
              [2700219.776055449154228, 1232321.204494855832309, 405.895000000004075],
              [2700211.676056413445622, 1232321.675746694440022, 405.972500000003492],
              [2700206.594806934706867, 1232321.491997703211382, 406.014999999999418],
              [2700204.377305261325091, 1232320.276994758984074, 405.891250000000582],
              [2700205.306054455693811, 1232311.960743240779266, 405.830000000001746],
              [2700200.006054407916963, 1232311.360743241384625, 405.830000000001746],
              [2700198.806072067469358, 1232321.660774681251496, 407.153749999997672],
              [2700197.656088825315237, 1232325.260804473422468, 408.410000000003492],
              [2700186.956079534720629, 1232325.410788155626506, 407.721250000002328],
              [2700187.406054292339832, 1232313.360743266064674, 405.830000000001746],
              [2700182.822308224625885, 1232313.242000308353454, 406.127500000002328],
              [2700182.886054754722863, 1232306.836994100594893, 405.867499999993015],
              [2700179.561055511701852, 1232302.245745454449207, 405.926250000004075],
              [2700173.528554174117744, 1232301.225743166403845, 405.830000000001746],
              [2700170.262304228264838, 1232302.135743323713541, 405.836249999993015],
              [2700167.58230630774051, 1232301.830747053725645, 405.993749999994179],
              [2700167.49480455275625, 1232301.038243937538937, 405.86250000000291],
              [2700164.321057446300983, 1232299.944499111967161, 406.08125000000291],
              [2700164.323554657865316, 1232298.945744159631431, 405.872499999997672],
              [2700162.734803861472756, 1232298.939492773730308, 405.813750000001164],
              [2700162.653556680306792, 1232299.939497782383114, 406.024999999994179],
              [2700157.969805837143213, 1232299.839496361557394, 405.964999999996508],
              [2700152.176054685376585, 1232298.570744398515671, 405.882500000006985],
              [2700148.761054718401283, 1232298.558244517538697, 405.88749999999709],
              [2700146.698555517941713, 1232297.718245964264497, 405.948749999995925],
              [2700140.348553594201803, 1232297.196992644807324, 405.808749999996508],
              [2700137.253554231487215, 1232296.436993825016543, 405.858749999999418],
              [2700131.933555335272104, 1232296.418245865730569, 405.945000000006985],
              [2700126.218555083498359, 1232295.56574550899677, 405.929999999993015],
              [2700124.54980668798089, 1232295.976998389000073, 406.051250000004075],
              [2700118.358555814716965, 1232295.039496925892308, 405.990000000005239],
              [2700111.452305853366852, 1232294.599497101968154, 405.997499999997672],
              [2700109.227309289388359, 1232295.174503241665661, 406.256250000005821],
              [2700106.132304186932743, 1232294.414494227617979, 405.876250000001164],
              [2700104.543559013865888, 1232294.908252829918638, 406.238750000004075],
              [2700103.672303650062531, 1232294.073243315098807, 405.837499999994179],
              [2700096.604806788265705, 1232294.21449899999425, 406.077499999999418],
              [2700095.812303476501256, 1232293.545743133174255, 405.830000000001746],
              [2700090.334806749131531, 1232293.110749025363475, 406.078750000000582],
              [2700088.192303845658898, 1232292.354493897641078, 405.86250000000291],
              [2700084.936053380835801, 1232292.759493130724877, 405.830000000001746],
              [2700082.556053360458463, 1232291.918243122519925, 405.830000000001746],
              [2700077.938554253894836, 1232291.80699478671886, 405.899999999994179],
              [2700074.537303288001567, 1232291.724493123358116, 405.830000000001746],
              [2700073.503555195871741, 1232292.053246533498168, 405.973750000004657],
              [2700062.471054147928953, 1232290.516994841396809, 405.902499999996508],
              [2700059.296053151134402, 1232289.589493112172931, 405.830000000001746],
              [2700056.913561187684536, 1232290.247007422847673, 406.433749999996508],
              [2700051.596053082961589, 1232289.063243112992495, 405.830000000001746],
              [2700049.529814843088388, 1232289.805764028802514, 406.712499999994179],
              [2700048.65855317376554, 1232288.553243316244334, 405.838749999995343],
              [2700047.94355408474803, 1232288.884494949132204, 405.907500000001164],
              [2700040.083555549848825, 1232288.524497676407918, 406.022500000006403],
              [2700039.762311103288084, 1232290.354507548501715, 406.438750000001164],
              [2700037.142308426089585, 1232290.013252840843052, 406.240000000005239],
              [2700037.546052990015596, 1232287.265743157127872, 405.832500000004075],
              [2700035.1648029377684, 1232286.591993092326447, 405.830000000001746],
              [2700033.654806693084538, 1232287.585749795194715, 406.11250000000291],
              [2700023.33730825362727, 1232285.468252715887502, 406.236250000001746],
              [2700021.276052812114358, 1232283.795743080787361, 405.830000000001746],
              [2700018.736052790191025, 1232283.786993084708229, 405.830000000001746],
              [2700017.806052898522466, 1232284.91074329498224, 405.838749999995343],
              [2700017.284806142561138, 1232293.441999139031395, 406.082500000004075],
              [2700011.251068119890988, 1232293.170770489377901, 406.983749999999418],
              [2700011.756058632861823, 1232284.310753566678613, 406.272500000006403],
              [2700015.00605327449739, 1232280.660743970656767, 405.868749999994179],
              [2700015.1060527600348, 1232279.610743046738207, 405.830000000001746],
              [2700006.006052677053958, 1232278.710743047762662, 405.830000000001746],
              [2700005.406065595336258, 1232293.310766109265387, 406.798750000001746],
              [2699994.456055254675448, 1232292.810747929150239, 406.03125],
              [2699991.156055625062436, 1232291.410748624475673, 406.061249999998836],
              [2699988.256053682416677, 1232289.310745210619643, 405.917499999995925],
              [2699985.856054397299886, 1232286.710746489698067, 405.972500000003492],
              [2699983.506054176017642, 1232282.360746101941913, 405.957500000004075],
              [2699980.156062439084053, 1232270.810760725289583, 406.578750000000582],
              [2699990.156055709347129, 1232259.510748520959169, 406.067500000004657],
              [2699990.916052547749132, 1232260.623242907226086, 405.830000000001746],
              [2699992.111052558757365, 1232259.045742892194539, 405.830000000001746],
              [2699994.729802581015974, 1232259.388242892222479, 405.830000000001746],
              [2699994.892302584368736, 1232257.973242884967476, 405.830000000001746],
              [2699991.636052553541958, 1232258.378242884529755, 405.830000000001746],
              [2699991.559802555479109, 1232256.79574287426658, 405.830000000001746],
              [2699989.177302533295006, 1232257.203242883319035, 405.830000000001746],
              [2699980.981057963799685, 1232265.16950271371752, 406.242499999993015],
              [2699977.724804996978492, 1232265.824497505091131, 406.022500000006403],
              [2699970.254804146010429, 1232268.713246146216989, 405.963749999995343],
              [2699965.646053836680949, 1232269.946995682315901, 405.943750000005821],
              [2699964.053555555641651, 1232272.273248777724802, 406.073749999995925],
              [2699958.96606061514467, 1232274.753257863223553, 406.45625000000291],
              [2699946.576053679920733, 1232276.793245770968497, 405.945000000006985],
              [2699939.187302510254085, 1232278.433243828592822, 405.86250000000291],
              [2699935.689809735864401, 1232280.502006732625887, 406.40625],
              [2699930.368553033098578, 1232280.900744920130819, 405.907500000001164],
              [2699927.89230616344139, 1232280.533250517677516, 406.14375000000291],
              [2699923.056065597105771, 1232274.410767287248746, 406.853749999994761],
              [2699921.106064180843532, 1232268.860764760524035, 406.748749999998836],
              [2699920.706053268630058, 1232258.060745308874175, 405.931249999994179],
              [2699921.206058629322797, 1232254.060754775535315, 406.332500000004075],
              [2699923.10605960059911, 1232248.110756422858685, 406.403749999997672],
              [2699925.156056080944836, 1232244.210750111378729, 406.138749999998254],
              [2699928.606057667639107, 1232241.210752835730091, 406.255000000004657],
              [2699940.606054007541388, 1232234.310746081639081, 405.972500000003492],
              [2699939.664805066771805, 1232233.01699797809124, 406.052500000005239],
              [2699940.401052102446556, 1232231.719492692034692, 405.830000000001746],
              [2699938.892303808126599, 1232231.7982457424514, 405.958750000005239],
              [2699932.931052402593195, 1232234.691993369255215, 405.857499999998254],
              [2699932.689803200308233, 1232235.940744797699153, 405.917499999995925],
              [2699931.578552791383117, 1232235.853244091151282, 405.88749999999709],
              [2699928.636052628513426, 1232237.591993864858523, 405.877500000002328],
              [2699927.602305123582482, 1232238.67074831831269, 406.065000000002328],
              [2699924.856052631512284, 1232233.910743900341913, 405.880000000004657],
              [2699915.756055450998247, 1232234.510749058099464, 406.097500000003492],
              [2699914.503554102964699, 1232237.293246714863926, 405.997499999997672],
              [2699914.333554101642221, 1232242.621996754547581, 405.997499999997672],
              [2699913.616051858756691, 1232243.701992796966806, 405.830000000001746],
              [2699914.164803565479815, 1232247.368245846824721, 405.957500000004075],
              [2699913.843554760795087, 1232249.031997986603528, 406.047500000000582],
              [2699914.394805201794952, 1232251.11699877330102, 406.080000000001746],
              [2699913.993553645443171, 1232252.696996035287157, 405.963749999995343],
              [2699914.388553765136749, 1232253.780746249016374, 405.972500000003492],
              [2699914.068553811404854, 1232254.779496347997338, 405.976250000006985],
              [2699915.44355939514935, 1232256.443256248952821, 406.39375000000291],
              [2699913.432310743257403, 1232258.755758696701378, 406.496249999996508],
              [2699912.078554361592978, 1232257.436997374054044, 406.01875000000291],
              [2699911.59980452246964, 1232258.10199767537415, 406.03125],
              [2699905.008554597385228, 1232259.078247923636809, 406.041249999994761],
              [2699900.243552953004837, 1232259.061995080206543, 405.921249999999418],
              [2699898.972304323688149, 1232259.723247544607148, 406.024999999994179],
              [2699899.406068036798388, 1232267.410771943163127, 407.052500000005239],
              [2699896.606068748515099, 1232267.460773253347725, 407.107499999998254],
              [2699896.031053696293384, 1232261.379496492445469, 405.979999999995925],
              [2699896.669808874838054, 1232259.549505659611896, 406.367499999993015],
              [2699895.884802080225199, 1232255.299493578262627, 405.858749999999418],
              [2699893.26230195723474, 1232256.62324341177009, 405.851250000006985],
              [2699892.621053215581924, 1232259.451995680108666, 405.946249999993597],
              [2699891.743560099042952, 1232260.864507923368365, 406.462499999994179],
              [2699889.043558723758906, 1232261.105755524942651, 406.361250000001746],
              [2699888.486061736941338, 1232262.019510891288519, 406.587499999994179],
              [2699883.723552487324923, 1232261.419494546949863, 405.897500000006403],
              [2699882.611053977627307, 1232262.081997215980664, 406.009999999994761],
              [2699876.734804443549365, 1232262.061998130287975, 406.048750000001746],
              [2699875.704803415574133, 1232261.225746321491897, 405.972500000003492],
              [2699872.368552167899907, 1232261.796994163189083, 405.881250000005821],
              [2699870.936054272111505, 1232263.124497934943065, 406.039999999993597],
              [2699867.521051440387964, 1232263.529492965433747, 405.830000000001746],
              [2699868.106053597293794, 1232264.360746792983264, 405.991250000006403],
              [2699871.256069916300476, 1232279.010775841074064, 407.212499999994179],
              [2699864.906053777784109, 1232280.510747308610007, 406.007500000006985],
              [2699866.744811800774187, 1232283.41201154445298, 406.607499999998254],
              [2699863.978552368469536, 1232286.000744861317798, 405.902499999996508],
              [2699860.481051370501518, 1232287.738243158441037, 405.830000000001746],
              [2699859.763552913442254, 1232289.484495926881209, 405.946249999993597],
              [2699853.643553674221039, 1232291.960747401462868, 406.007500000006985],
              [2699851.151060891337693, 1232291.588260253891349, 406.55000000000291],
              [2699848.106056680902839, 1232285.56075277342461, 406.236250000001746],
              [2699845.30605373904109, 1232276.460747519973665, 406.017500000001746],
              [2699844.906054168473929, 1232270.360748242586851, 406.05000000000291],
              [2699845.306058746296912, 1232265.260756313567981, 406.392500000001746],
              [2699847.056055828463286, 1232258.310751044889912, 406.172500000000582],
              [2699855.53855133196339, 1232260.156992938835174, 405.830000000001746],
              [2699855.14730256376788, 1232257.990745121147484, 405.922500000000582],
              [2699852.052302370779216, 1232257.146994818001986, 405.910000000003492],
              [2699849.987302251160145, 1232257.056994636775926, 405.902499999996508],
              [2699849.429802311584353, 1232257.886994759785011, 405.907500000001164],
              [2699847.686051297001541, 1232256.79824298247695, 405.832500000004075],
              [2699842.203552049584687, 1232258.528244410408661, 405.892500000001746],
              [2699840.774801169056445, 1232257.940742871724069, 405.827499999999418],
              [2699838.868550951126963, 1232258.683242517290637, 405.8125],
              [2699838.071053112391382, 1232259.846996385604143, 405.975000000005821],
              [2699836.482301379553974, 1232260.258243335643783, 405.846250000002328],
              [2699836.396053128875792, 1232263.838246475672349, 405.977499999993597],
              [2699838.056063, 1232265.903263985877857, 406.716249999997672],
              [2699838.856068140361458, 1232283.410773245617747, 407.101250000006985],
              [2699837.60606317454949, 1232286.960764484945685, 406.729999999995925],
              [2699835.644803666975349, 1232287.025747637497261, 406.01875000000291],
              [2699833.106063601095229, 1232287.110765313263983, 406.764999999999418],
              [2699828.006051751319319, 1232266.860744189471006, 405.880000000004657],
              [2699823.506051660515368, 1232267.860744110308588, 405.876250000001164],
              [2699825.589808363467455, 1232276.234506047330797, 406.377500000002328],
              [2699823.421051305253059, 1232278.366993567207828, 405.850000000005821],
              [2699821.668553843162954, 1232281.274498122744262, 406.041249999994761],
              [2699809.738551081623882, 1232290.310743497451767, 405.842499999998836],
              [2699810.208560841623694, 1232291.065760819707066, 406.573749999995925],
              [2699801.05605296837166, 1232298.210747049655765, 405.990000000005239],
              [2699804.332310135941952, 1232302.824509758967906, 406.524999999994179],
              [2699803.591052288655192, 1232306.111995872110128, 405.9375],
              [2699801.124801879748702, 1232308.10199520480819, 405.908750000002328],
              [2699795.958566527348012, 1232314.293271347647533, 407.009999999994761],
              [2699792.664815930649638, 1232315.935770351905376, 406.967499999998836],
              [2699790.627302568405867, 1232315.643246658612043, 405.967499999998836],
              [2699785.781053323764354, 1232317.209498097188771, 406.027499999996508],
              [2699781.009808435104787, 1232320.190757271600887, 406.413750000006985],
              [2699770.757300651632249, 1232325.151993666077033, 405.837499999994179],
              [2699768.052304998040199, 1232327.224501440767199, 406.164999999993597],
              [2699746.749804034363478, 1232337.893250168301165, 406.107499999998254],
              [2699744.804802115540951, 1232336.831996785243973, 405.964999999996508],
              [2699744.456050830893219, 1232332.010744468541816, 405.868749999994179],
              [2699741.006052935030311, 1232332.260748261818662, 406.028749999997672],
              [2699741.154803119134158, 1232334.138248598668724, 406.042499999995925],
              [2699738.068559762556106, 1232334.184510445455089, 406.542499999995925],
              [2699737.22356531303376, 1232322.354520213557407, 406.958750000005239],
              [2699738.369801030028611, 1232321.459494833135977, 405.88749999999709],
              [2699738.373551614582539, 1232319.710745850112289, 405.931249999994179],
              [2699737.184801138471812, 1232318.706995014334098, 405.896250000005239],
              [2699735.279800419695675, 1232318.366993768140674, 405.84375],
              [2699734.087301660794765, 1232319.113245996879414, 405.9375],
              [2699729.641050421167165, 1232318.846993866143748, 405.847500000003492],
              [2699724.954800143837929, 1232319.74699345859699, 405.830000000001746],
              [2699723.673552298918366, 1232324.573247344233096, 405.992499999993015],
              [2699719.134802523069084, 1232330.303247869480401, 406.01249999999709],
              [2699713.333553270902485, 1232333.03074930422008, 406.072499999994761],
              [2699709.439800800289959, 1232334.183245001127943, 405.889999999999418],
              [2699703.558550311718136, 1232336.661994252353907, 405.857499999998254],
              [2699701.556068505626172, 1232338.660776602802798, 407.222500000003492],
              [2699695.906056846957654, 1232335.010755968978629, 406.352499999993597],
              [2699692.497307486366481, 1232335.369507152121514, 406.402499999996508],
              [2699689.508553941268474, 1232334.864500902825966, 406.138749999998254],
              [2699677.121053909882903, 1232335.738251059316099, 406.145000000004075],
              [2699674.739799922332168, 1232335.395744018722326, 405.847500000003492],
              [2699674.022303983569145, 1232336.477001243503764, 406.152499999996508],
              [2699671.799802312161773, 1232336.301998313283548, 406.028749999997672],
              [2699667.351054606493562, 1232337.203252465464175, 406.203750000000582],
              [2699665.843550240155309, 1232336.698244733735919, 405.877500000002328],
              [2699663.381049585994333, 1232337.189493618207052, 405.830000000001746],
              [2699663.349802637007087, 1232338.438249040860683, 406.058749999996508],
              [2699648.406053567770869, 1232340.010750957531855, 406.138749999998254],
              [2699646.956052522640675, 1232336.460749093210325, 406.061249999998836],
              [2699645.906053663697094, 1232336.060751133365557, 406.147500000006403],
              [2699645.006052522454411, 1232336.860749133629724, 406.0625],
              [2699642.806049402337521, 1232337.160743621876463, 405.830000000001746],
              [2699642.963552636560053, 1232338.950749381212518, 406.072499999994761],
              [2699636.221051959320903, 1232339.676998296519741, 406.026249999995343],
              [2699634.472306027077138, 1232340.587005559587851, 406.332500000004075],
              [2699630.104804204311222, 1232340.738252385519445, 406.198749999995925],
              [2699628.677299808245152, 1232339.650744594633579, 405.869999999995343],
              [2699627.247299928683788, 1232340.229494837578386, 405.880000000004657],
              [2699626.132301366887987, 1232341.556997423060238, 405.988750000004075],
              [2699626.196055121719837, 1232342.304504091385752, 406.270000000004075],
              [2699623.533553862012923, 1232342.579501906177029, 406.177500000005239],
              [2699623.353550159372389, 1232341.54824532312341, 405.899999999994179],
              [2699621.846050045453012, 1232341.293245140230283, 405.892500000001746],
              [2699620.971050737891346, 1232341.705746387597173, 405.945000000006985],
              [2699619.144805375486612, 1232341.699504648800939, 406.29374999999709],
              [2699619.069799186196178, 1232340.034493656596169, 405.830000000001746],
              [2699616.288550511933863, 1232340.690746053121984, 405.931249999994179],
              [2699614.038554859347641, 1232343.558253841474652, 406.258749999993597],
              [2699586.262305045034736, 1232346.163254640065134, 406.291249999994761],
              [2699585.78980092285201, 1232344.999497315846384, 405.982499999998254],
              [2699575.861052516382188, 1232346.380750322714448, 406.108749999999418],
              [2699570.937317192088813, 1232346.779526472790167, 407.212499999994179],
              [2699567.603553009219468, 1232346.268251326400787, 406.151249999995343],
              [2699563.871050772722811, 1232347.005747427698225, 405.986250000001746],
              [2699562.836050762794912, 1232347.834497432457283, 405.986250000001746],
              [2699564.701063666958362, 1232355.19577038125135, 406.952499999999418],
              [2699565.356071824673563, 1232363.010784925660118, 407.563750000001164],
              [2699563.856070810928941, 1232363.260783151024953, 407.488750000004075],
              [2699562.906058314722031, 1232353.160760890692472, 406.552500000005239],
              [2699551.378568380605429, 1232354.072028967784718, 407.315000000002328],
              [2699546.229806031100452, 1232353.689507110742852, 406.392500000001746],
              [2699540.82855870295316, 1232354.170761939138174, 406.596250000002328],
              [2699538.527301093097776, 1232353.496998464921489, 406.027499999996508],
              [2699533.842299418989569, 1232353.896995564224198, 405.904999999998836],
              [2699529.652316167484969, 1232355.790775401517749, 407.163750000006985],
              [2699526.029813185334206, 1232356.077020165976137, 406.942500000004657],
              [2699519.22979880310595, 1232355.179494722513482, 405.868749999994179],
              [2699514.463556529488415, 1232356.07825852651149, 406.451249999998254],
              [2699512.243548225145787, 1232354.821993801742792, 405.830000000001746],
              [2699510.972298211883754, 1232355.066993801156059, 405.830000000001746],
              [2699511.706064356025308, 1232357.210772487567738, 407.039999999993597],
              [2699512.106084347236902, 1232367.060808076988906, 408.538750000006985],
              [2699495.629806791432202, 1232368.385759403696284, 406.483749999999418],
              [2699490.539799061138183, 1232367.071995740989223, 405.907500000001164],
              [2699471.797299526631832, 1232368.83824689174071, 405.955000000001746],
              [2699469.969803493469954, 1232369.582003969233483, 406.253750000003492],
              [2699467.747308023739606, 1232369.32451205374673, 406.595000000001164],
              [2699464.491050608456135, 1232369.895748946582898, 406.041249999994761],
              [2699462.032301338389516, 1232368.722000266658142, 406.097500000003492],
              [2699457.196048846468329, 1232364.958245888119563, 405.913750000006985],
              [2699454.661051208153367, 1232362.284500099718571, 406.092499999998836],
              [2699453.731065986212343, 1232360.407026348635554, 407.201249999998254],
              [2699453.436066900379956, 1232354.60202793055214, 407.270000000004075],
              [2699455.559797718189657, 1232351.045743789756671, 405.830000000001746],
              [2699456.358547724317759, 1232348.466993774753064, 405.830000000001746],
              [2699455.888547722250223, 1232345.63324374333024, 405.830000000001746],
              [2699452.614799079019576, 1232338.435746153350919, 405.933749999996508],
              [2699452.449816849082708, 1232335.200777690857649, 407.266250000000582],
              [2699453.767305991612375, 1232335.300758379977196, 406.451249999998254],
              [2699452.996049867942929, 1232324.888247429626063, 405.992499999993015],
              [2699451.908562661614269, 1232324.554520176025108, 406.952499999999418],
              [2699451.784811093471944, 1232322.109517366858199, 406.835000000006403],
              [2699454.037298546172678, 1232320.478245031088591, 405.892500000001746],
              [2699453.088549271691591, 1232318.809496314963326, 405.947499999994761],
              [2699445.389798835385591, 1232317.450745661742985, 405.919999999998254],
              [2699438.961047960910946, 1232315.845744196558371, 405.858749999999418],
              [2699437.45104909921065, 1232316.840746246278286, 405.945000000006985],
              [2699436.418551574926823, 1232316.753250665729865, 406.131250000005821],
              [2699435.151048677973449, 1232315.666995530482382, 405.914999999993597],
              [2699422.451047432143241, 1232313.540743508376181, 405.830000000001746],
              [2699395.699798044282943, 1232310.70074499957263, 405.89375000000291],
              [2699391.652298321481794, 1232309.770745558198541, 405.917499999995925],
              [2699389.587298122234643, 1232310.013245231704786, 405.903749999997672],
              [2699388.079797491896898, 1232309.341994135640562, 405.857499999998254],
              [2699385.854800737462938, 1232310.166999944252893, 406.102499999993597],
              [2699382.284798738081008, 1232308.905746440170333, 405.955000000001746],
              [2699380.067298238631338, 1232306.149495561141521, 405.91874999999709],
              [2699378.956047309562564, 1232306.14574393699877, 405.850000000005821],
              [2699376.568551138509065, 1232308.553250789176673, 406.138749999998254],
              [2699370.537297834642231, 1232306.865745005896315, 405.895000000004075],
              [2699368.724811568390578, 1232309.283269456354901, 406.926250000004075],
              [2699363.722307688556612, 1232309.295762646477669, 406.638749999998254],
              [2699357.833546855021268, 1232307.07199347531423, 405.830000000001746],
              [2699355.68604786740616, 1232308.563245323253796, 405.907500000001164],
              [2699349.551047544926405, 1232318.201994927600026, 405.88749999999709],
              [2699347.636048556771129, 1232322.443246798589826, 405.964999999996508],
              [2699339.447300249710679, 1232327.494499975815415, 406.097500000003492],
              [2699333.726049996446818, 1232329.473249635426328, 406.082500000004075],
              [2699326.021047212183475, 1232331.111994822276756, 405.878750000003492],
              [2699325.383547323290259, 1232331.775745040271431, 405.88749999999709],
              [2699326.67480726307258, 1232340.947012761840597, 406.632500000006985],
              [2699322.599813743494451, 1232341.543274348368868, 407.121249999996508],
              [2699318.12855994142592, 1232321.618267495650798, 406.838749999995343],
              [2699318.57979866489768, 1232320.676997443195432, 405.992499999993015],
              [2699318.26854879502207, 1232317.678247656440362, 406.002500000002328],
              [2699316.846050650347024, 1232314.758250953862444, 406.142500000001746],
              [2699316.534800664056093, 1232311.843250954290852, 406.14375000000291],
              [2699315.734807225875556, 1232311.819512623129413, 406.636249999995925],
              [2699314.252299896907061, 1232305.748249577591196, 406.087499999994179],
              [2699315.357299223542213, 1232305.34324836358428, 406.036250000004657],
              [2699315.281048021744937, 1232304.176996216643602, 405.946249999993597],
              [2699313.537298974581063, 1232302.671997919213027, 406.01875000000291],
              [2699311.711053556296974, 1232302.915756102418527, 406.363750000004075],
              [2699308.376049479003996, 1232302.488248899346218, 406.059999999997672],
              [2699307.104804448317736, 1232303.149507763795555, 406.433749999996508],
              [2699301.866048736032099, 1232302.299497690983117, 406.008749999993597],
              [2699299.562297231052071, 1232302.624495055992156, 405.897500000006403],
              [2699298.44854648783803, 1232303.953243762254715, 405.842499999998836],
              [2699299.309799578972161, 1232309.368249283870682, 406.073749999995925],
              [2699301.569814644753933, 1232314.780776064144447, 407.202499999999418],
              [2699303.718559676781297, 1232327.765767314704135, 406.828750000000582],
              [2699301.091050271876156, 1232330.193250661483034, 406.125],
              [2699298.389797329436988, 1232330.683245482388884, 405.90625],
              [2699296.876053350046277, 1232333.010756224626675, 406.358749999999418],
              [2699290.918548080138862, 1232334.65574697079137, 405.967499999998836],
              [2699288.608570454642177, 1232336.934536787448451, 407.647500000006403],
              [2699281.956062195356935, 1232337.86077222879976, 407.032500000001164],
              [2699274.311058911029249, 1232337.47451651096344, 406.791249999994761],
              [2699270.034796689637005, 1232334.999494852498174, 405.877500000002328],
              [2699267.019796032924205, 1232334.239493717439473, 405.830000000001746],
              [2699259.63480166438967, 1232334.380753849633038, 406.257500000006985],
              [2699258.647311952896416, 1232336.684522161493078, 407.029999999998836],
              [2699251.25604912173003, 1232336.310749481897801, 406.072499999994761],
              [2699249.06605129968375, 1232338.508253407664597, 406.23750000000291],
              [2699248.103552174754441, 1232342.91825501807034, 406.303750000006403],
              [2699247.227300801314414, 1232344.248252599965781, 406.201249999998254],
              [2699238.174803050700575, 1232344.133256744360551, 406.376250000001164],
              [2699234.844809488859028, 1232342.289518219185993, 406.861250000001746],
              [2699224.207302345894277, 1232341.420755688566715, 406.332500000004075],
              [2699220.002302306238562, 1232339.573255681199953, 406.332500000004075],
              [2699213.733550650067627, 1232337.719502819469199, 406.212499999994179],
              [2699210.721048791427165, 1232335.87699955352582, 406.07499999999709],
              [2699208.259800069499761, 1232335.70200186339207, 406.172500000000582],
              [2699203.028550157789141, 1232331.520752066513523, 406.182499999995343],
              [2699198.18855004850775, 1232329.838251933455467, 406.177500000005239],
              [2699189.943551041651517, 1232323.814503785921261, 406.257500000006985],
              [2699185.267299667932093, 1232319.884501388063654, 406.157500000001164],
              [2699183.051050850190222, 1232317.045753499493003, 406.247499999997672],
              [2699174.484797775279731, 1232312.768248136853799, 406.022500000006403],
              [2699167.817303763702512, 1232311.99575887969695, 406.476250000006985],
              [2699164.558561345562339, 1232313.234522427432239, 407.047500000000582],
              [2699162.018560124095529, 1232312.642020293977112, 406.957500000004075],
              [2699160.036057909019291, 1232311.553266370203346, 406.792499999995925],
              [2699159.804800707381219, 1232308.554503554245457, 406.252500000002328],
              [2699155.283548319712281, 1232306.290749361971393, 406.076249999998254],
              [2699141.32730409456417, 1232297.998259781394154, 406.51875000000291],
              [2699138.707301340997219, 1232297.822004926623777, 406.313750000001164],
              [2699137.521049580536783, 1232295.569501801161095, 406.182499999995343],
              [2699134.03105041384697, 1232293.809503325959668, 406.247499999997672],
              [2699133.558544978033751, 1232292.058243662584573, 405.839999999996508],
              [2699129.988548048771918, 1232290.963249156717211, 406.072499999994761],
              [2699128.799798502586782, 1232289.794499977258965, 406.107499999998254],
              [2699124.752298900391906, 1232289.030750742647797, 406.139999999999418],
              [2699114.518546876497567, 1232284.831997278379276, 405.994999999995343],
              [2699110.706048141699284, 1232285.484499595826492, 406.092499999998836],
              [2699108.484797674696892, 1232284.39449878805317, 406.058749999996508],
              [2699106.506045356858522, 1232281.80574468546547, 405.886249999995925],
              [2699103.091048192698509, 1232282.044499776093289, 406.101250000006985],
              [2699101.03229602612555, 1232279.20574593404308, 405.940000000002328],
              [2699095.406062666792423, 1232279.510775614995509, 407.192500000004657],
              [2699100.501044557895511, 1232269.651993257692084, 405.830000000001746],
              [2699098.356059700250626, 1232268.620770192937925, 406.967499999998836],
              [2699101.006045312620699, 1232264.460744547890499, 405.886249999995925],
              [2699100.352295425254852, 1232263.214494741288945, 405.895000000004075],
              [2699082.178544695023447, 1232259.320743711432442, 405.852499999993597],
              [2699078.526047111488879, 1232259.474498068680987, 406.036250000004657],
              [2699076.782297080848366, 1232257.886998025001958, 406.035000000003492],
              [2699076.951044633984566, 1232253.473243635846302, 405.851250000006985],
              [2699074.409796075895429, 1232253.381996239768341, 405.961249999993015],
              [2699068.14229430584237, 1232251.278243178967386, 405.832500000004075],
              [2699066.15729448851198, 1232251.354493536986411, 405.847500000003492],
              [2699066.074795787688345, 1232252.436995857395232, 405.945000000006985],
              [2699063.054797489196062, 1232253.841998948482797, 406.07499999999709],
              [2699060.514794768765569, 1232253.58324414677918, 405.872499999997672],
              [2699059.162294557318091, 1232254.911993804387748, 405.857499999998254],
              [2699059.00604858668521, 1232256.81075098644942, 406.160000000003492],
              [2699055.906059319619089, 1232256.560770113952458, 406.967499999998836],
              [2699055.956048325635493, 1232255.360750562511384, 406.142500000001746],
              [2699054.856063777580857, 1232255.110778039321303, 407.302500000005239],
              [2699040.472307448741049, 1232254.750767017249018, 406.837499999994179],
              [2699039.793544019106776, 1232252.59574313974008, 405.830000000001746],
              [2699037.251045112032443, 1232253.336995129939169, 405.913750000006985],
              [2699034.25604781601578, 1232253.060749988537282, 406.118749999994179],
              [2699029.156058596912771, 1232253.310769236413762, 406.931249999994179],
              [2699027.906044478993863, 1232242.06199406972155, 405.872499999997672],
              [2699025.456045740749687, 1232241.210746339056641, 405.96875],
              [2699017.056046382989734, 1232241.710747621487826, 406.022500000006403],
              [2699014.329793793149292, 1232241.349493054905906, 405.830000000001746],
              [2699016.934810339473188, 1232248.52077248855494, 407.070000000006985],
              [2699015.893551964778453, 1232252.513257655780762, 406.442500000004657],
              [2699011.919798997696489, 1232254.49825246585533, 406.222500000003492],
              [2698999.523547950200737, 1232258.619500837987289, 406.152499999996508],
              [2698993.487296847160906, 1232259.598248983267695, 406.073749999995925],
              [2698978.709805158898234, 1232263.960764035349712, 406.707500000004075],
              [2698972.806067466270179, 1232266.66078603034839, 407.634999999994761],
              [2698971.406043299939483, 1232261.210743047762662, 405.822499999994761],
              [2698969.906043386086822, 1232261.66074323351495, 405.830000000001746],
              [2698970.606063233222812, 1232264.360778521746397, 407.318750000005821],
              [2698961.764809402171522, 1232267.882021890720353, 407.037500000005821],
              [2698958.05979328090325, 1232266.220743275480345, 405.830000000001746],
              [2698953.844797822646797, 1232269.620751444483176, 406.173750000001746],
              [2698950.824793415144086, 1232270.609493667958304, 405.845000000001164],
              [2698950.173561863601208, 1232272.498276489553973, 407.229999999995925],
              [2698947.506048613227904, 1232273.560752991586924, 406.23750000000291],
              [2698950.556067727506161, 1232282.660786991706118, 407.669999999998254],
              [2698948.606065911240876, 1232283.360783802345395, 407.535000000003492],
              [2698947.706060821190476, 1232280.810774748213589, 407.153749999997672],
              [2698945.356053490191698, 1232281.610761757940054, 406.604999999995925],
              [2698946.406063522677869, 1232284.760779611533508, 407.357499999998254],
              [2698933.656058476306498, 1232289.660770890768617, 406.98750000000291],
              [2698930.006057881750166, 1232280.310769805684686, 406.945000000006985],
              [2698930.306055470369756, 1232279.110765504417941, 406.763749999998254],
              [2698932.504800343886018, 1232277.470756340073422, 406.377500000002328],
              [2698932.708548880182207, 1232276.625753728207201, 406.267500000001746],
              [2698931.92104340903461, 1232273.958243995904922, 405.857499999998254],
              [2698929.939793176017702, 1232271.869493591133505, 405.841249999997672],
              [2698930.071046608500183, 1232276.845749731874093, 406.098750000004657],
              [2698928.756044246256351, 1232278.010745568899438, 405.922500000000582],
              [2698924.997313133906573, 1232281.097029231488705, 407.342499999998836],
              [2698917.766042915172875, 1232283.735743425088003, 405.830000000001746],
              [2698912.837293718475848, 1232286.716994959395379, 405.89375000000291],
              [2698909.8598050349392, 1232287.288265131879598, 406.744999999995343],
              [2698905.106043119449168, 1232277.36074394127354, 405.853749999994761],
              [2698903.356043020263314, 1232278.310743802925572, 405.847500000003492],
              [2698906.934804775286466, 1232286.103264707140625, 406.727499999993597],
              [2698902.829792828764766, 1232287.765743549447507, 405.833750000005239],
              [2698902.823555251117796, 1232290.513265665620565, 406.766250000000582],
              [2698899.884805875364691, 1232291.335766820935532, 406.815000000002328],
              [2698894.00856128660962, 1232291.482026540674269, 407.225000000005821],
              [2698889.479793888051063, 1232292.881995700066909, 405.922500000000582],
              [2698888.92104779696092, 1232294.295752664562315, 406.216249999997672],
              [2698886.124796504620463, 1232296.605750437593088, 406.121249999996508],
              [2698872.709795334842056, 1232300.151998606044799, 406.042499999995925],
              [2698867.703543259762228, 1232301.966995005728677, 405.889999999999418],
              [2698862.541044410783798, 1232303.03199714422226, 405.979999999995925],
              [2698855.944794949144125, 1232305.424498238135129, 406.024999999994179],
              [2698855.78854364855215, 1232304.674495924031362, 405.927500000005239],
              [2698852.769792325329036, 1232305.329493620432913, 405.830000000001746],
              [2698853.293551022652537, 1232307.178259091917425, 406.482499999998254],
              [2698847.129801199305803, 1232309.0932595229242, 406.5],
              [2698845.061043186113238, 1232308.218245295109227, 405.899999999994179],
              [2698832.268542203586549, 1232312.754493797197938, 405.835000000006403],
              [2698826.468542782589793, 1232314.399494940415025, 405.882500000006985],
              [2698825.833542080130428, 1232314.064493693644181, 405.830000000001746],
              [2698824.403542067389935, 1232314.641993701225147, 405.830000000001746],
              [2698825.329805696383119, 1232318.217017952818424, 406.852499999993597],
              [2698820.006060494109988, 1232319.86077658017166, 407.216249999997672],
              [2698819.306050979997963, 1232317.760759657947347, 406.502500000002328],
              [2698756.856049498077482, 1232336.610758197959512, 406.433749999996508],
              [2698733.156046633608639, 1232340.91075352509506, 406.235000000000582],
              [2698712.90605049720034, 1232342.060760741587728, 406.538750000006985],
              [2698700.906056, 1232341.610770725877956, 406.960000000006403],
              [2698700.007305691018701, 1232349.278270247625187, 406.9375],
              [2698698.557292025070637, 1232349.516995976911858, 405.912500000005821],
              [2698697.598544015549123, 1232352.094499544007704, 406.0625],
              [2698695.769800859503448, 1232352.917011755285785, 406.577499999999418],
              [2698647.059798608068377, 1232344.529508473118767, 406.441250000003492],
              [2698646.642290662974119, 1232343.424494337989017, 405.845000000001164],
              [2698641.086040594615042, 1232342.323244303232059, 405.84375],
              [2698640.771042974665761, 1232343.445748545229435, 406.022500000006403],
              [2698636.449791871476918, 1232342.701996644726023, 405.942500000004657],
              [2698636.721040557138622, 1232341.808244297048077, 405.84375],
              [2698633.596047854982316, 1232341.14200732531026, 406.39375000000291],
              [2698640.306044243741781, 1232317.610750593245029, 406.117499999993015],
              [2698608.901040234137326, 1232309.608243903843686, 405.837499999994179],
              [2698607.40729914419353, 1232311.978259791852906, 406.507500000006985],
              [2698605.579798761289567, 1232312.472009145887569, 406.479999999995925],
              [2698603.676048745401204, 1232311.715759140672162, 406.479999999995925],
              [2698603.464793247636408, 1232308.223249340895563, 406.067500000004657],
              [2698598.706042956095189, 1232307.010748896980658, 406.048750000001746],
              [2698596.456051343120635, 1232307.310763854533434, 406.679999999993015],
              [2698572.006042602937669, 1232300.110748637933284, 406.039999999993597],
              [2698568.156044831499457, 1232298.160752649186179, 406.210000000006403],
              [2698562.419791352935135, 1232297.000746539561078, 405.952499999999418],
              [2698545.992289573419839, 1232292.613243607804179, 405.830000000001746],
              [2698544.583543724380434, 1232293.52450102288276, 406.142500000001746],
              [2698542.407298050355166, 1232293.003258745884523, 406.46875],
              [2698542.104790405370295, 1232291.26699514198117, 405.895000000004075],
              [2698538.931039746385068, 1232290.423244016245008, 405.847500000003492],
              [2698537.711043761111796, 1232291.878251187968999, 406.149999999994179],
              [2698534.876040624920279, 1232293.074495662236586, 405.916249999994761],
              [2698533.296040177345276, 1232289.488244860665873, 405.883749999993597],
              [2698530.36103943688795, 1232288.061993582174182, 405.830000000001746],
              [2698528.456044245511293, 1232289.660752180498093, 406.192500000004657],
              [2698527.306046816054732, 1232291.910756789380684, 406.386249999995925],
              [2698530.406056063715369, 1232292.760773198446259, 407.078750000000582],
              [2698527.056048524565995, 1232304.110759940231219, 406.514999999999418],
              [2698516.938549333717674, 1232301.109511513728648, 406.582500000004075],
              [2698517.074790347367525, 1232299.841995514230803, 405.907500000001164],
              [2698505.966040131170303, 1232296.971995285246521, 405.898749999993015],
              [2698502.713539185933769, 1232295.461993647506461, 405.830000000001746],
              [2698501.044791318941861, 1232295.705747468629852, 405.991250000006403],
              [2698498.586039630230516, 1232294.948244500206783, 405.866250000006403],
              [2698497.976047664880753, 1232295.484508813591674, 406.470000000001164],
              [2698495.706050940789282, 1232294.810764670372009, 406.717499999998836],
              [2698499.256049979943782, 1232278.510762763209641, 406.642500000001746],
              [2698498.856046299450099, 1232277.360756210051477, 406.366250000006403],
              [2698497.806047704070807, 1232276.660758716985583, 406.472500000003492],
              [2698496.456041132099926, 1232276.760747054358944, 405.979999999995925],
              [2698495.55604768358171, 1232277.610758730676025, 406.472500000003492],
              [2698495.456050693523139, 1232279.710764103801921, 406.698749999995925],
              [2698493.556042585521936, 1232287.460749775869772, 406.091249999997672],
              [2698487.706039663869888, 1232304.210744817275554, 405.876250000001164],
              [2698479.506039692088962, 1232300.860744968056679, 405.883749999993597],
              [2698479.056051141116768, 1232299.560765338130295, 406.743749999994179],
              [2698480.656052868813276, 1232294.160768338246271, 406.872499999997672],
              [2698484.656039022374898, 1232295.410743650514632, 405.830000000001746],
              [2698487.056043592747301, 1232287.760751677444205, 406.171249999999418],
              [2698482.606050295289606, 1232286.360763658769429, 406.677500000005239],
              [2698485.626043733209372, 1232277.268251856556162, 406.182499999995343],
              [2698487.827289656735957, 1232276.256994563620538, 405.875],
              [2698488.629794626962394, 1232272.763253366341814, 406.247499999997672],
              [2698487.956050478387624, 1232272.660763785010204, 406.6875],
              [2698490.90604121889919, 1232263.060747183393687, 405.990000000005239],
              [2698482.352295656688511, 1232260.599505194462836, 406.328750000000582],
              [2698477.704789672512561, 1232257.901994598563761, 405.882500000006985],
              [2698472.942290629260242, 1232257.135746368207037, 405.957500000004075],
              [2698469.769789270590991, 1232255.459493991918862, 405.857499999998254],
              [2698467.706039033364505, 1232255.285743605811149, 405.841249999997672],
              [2698467.119798898231238, 1232256.21701116906479, 406.582500000004075],
              [2698463.258538845460862, 1232255.354493339080364, 405.830000000001746],
              [2698461.896040195599198, 1232261.344495816621929, 405.932499999995343],
              [2698458.801039869431406, 1232260.834495283430442, 405.910000000003492],
              [2698452.057293436489999, 1232258.229501716094092, 406.182499999995343],
              [2698451.423543631099164, 1232257.478252071188763, 406.197499999994761],
              [2698453.456042401958257, 1232251.360749803250656, 406.103749999994761],
              [2698453.267290471121669, 1232249.323246343526989, 405.958750000005239],
              [2698451.841039194259793, 1232248.153244087239727, 405.863750000004075],
              [2698449.617292833514512, 1232248.062000600388274, 406.138749999998254],
              [2698447.319789152592421, 1232246.055744076846167, 405.863750000004075],
              [2698446.049788924865425, 1232245.800743686966598, 405.847500000003492],
              [2698444.226039507891983, 1232244.545744746457785, 405.892500000001746],
              [2698434.072290667332709, 1232240.34699693415314, 405.986250000001746],
              [2698433.454794223885983, 1232241.143253272166476, 406.253750000003492],
              [2698431.406048397999257, 1232239.860760728130117, 406.568750000005821],
              [2698432.056043796706945, 1232237.260752506554127, 406.222500000003492],
              [2698431.106042074970901, 1232235.660749441245571, 406.09375],
              [2698429.898544141557068, 1232235.324503140058368, 406.25],
              [2698429.95728855393827, 1232233.588243178324774, 405.830000000001746],
              [2698426.543540919665247, 1232233.325747442198917, 406.009999999994761],
              [2698425.849795372225344, 1232234.195755381835625, 406.345000000001164],
              [2698424.568545610643923, 1232233.838255828944966, 406.363750000004075],
              [2698421.941039111465216, 1232232.060744296526536, 405.877500000002328],
              [2698418.767290549818426, 1232231.3844968972262, 405.98750000000291],
              [2698414.801038535777479, 1232229.45449336245656, 405.838749999995343],
              [2698411.942288459278643, 1232229.94449326954782, 405.835000000006403],
              [2698410.908538698218763, 1232230.690743723185733, 405.853749999994761],
              [2698411.006044739857316, 1232233.060754493810236, 406.307499999995343],
              [2698408.906044049654156, 1232245.960753410123289, 406.257500000006985],
              [2698410.156057407148182, 1232252.810777214588597, 407.259999999994761],
              [2698404.256038931664079, 1232251.760744428960606, 405.876250000001164],
              [2698404.056041676551104, 1232252.91074931784533, 406.082500000004075],
              [2698398.506054008379579, 1232251.960771344136447, 407.01249999999709],
              [2698402.55604679370299, 1232237.86075832741335, 406.467499999998836],
              [2698404.956052673514932, 1232232.160768697736785, 406.907500000001164],
              [2698405.006050894968212, 1232229.510765508050099, 406.773749999993015],
              [2698403.656039930414408, 1232227.860746017890051, 405.951249999998254],
              [2698389.556045948527753, 1232225.010756916599348, 406.412500000005821],
              [2698389.803538910113275, 1232223.123244381509721, 405.883749999993597],
              [2698387.263538205996156, 1232223.030743166338652, 405.832500000004075],
              [2698385.513538222759962, 1232224.606993242399767, 405.835000000006403],
              [2698381.538538484368473, 1232226.675743791041896, 405.857499999998254],
              [2698380.109788108617067, 1232226.586993141099811, 405.830000000001746],
              [2698378.516042237635702, 1232228.830750524764881, 406.141250000000582],
              [2698379.41605630563572, 1232233.348275585332885, 407.197499999994761],
              [2698376.456069918349385, 1232254.810800040373579, 408.222500000003492],
              [2698367.756061968859285, 1232253.810786030255258, 407.631250000005821],
              [2698367.906050070654601, 1232250.610764833167195, 406.73750000000291],
              [2698369.739788040984422, 1232248.369493374601007, 405.832500000004075],
              [2698367.038540394511074, 1232249.193247616756707, 406.011249999995925],
              [2698361.797289834357798, 1232249.34074669983238, 405.972500000003492],
              [2698357.511045884806663, 1232248.743257539113984, 406.429999999993015],
              [2698356.904799797106534, 1232228.440764331026003, 406.723750000004657],
              [2698358.032290006522089, 1232228.176996890222654, 405.98750000000291],
              [2698358.206038527190685, 1232221.348244197433814, 405.876250000001164],
              [2698351.299789696931839, 1232220.575746387243271, 405.96875],
              [2698350.111037923488766, 1232219.405743239214644, 405.836249999993015],
              [2698347.728539965581149, 1232219.646996916970238, 405.991250000006403],
              [2698345.516040930524468, 1232214.975748627912253, 406.065000000002328],
              [2698342.103537803050131, 1232213.881993109593168, 405.832500000004075],
              [2698340.19728971645236, 1232214.624496549367905, 405.977499999993597],
              [2698338.611037739086896, 1232213.536993053741753, 405.830000000001746],
              [2698332.573541560210288, 1232214.764499961165711, 406.121249999996508],
              [2698331.862287677358836, 1232213.263243047287688, 405.830000000001746],
              [2698328.049791154451668, 1232214.165749302133918, 406.09375],
              [2698319.952288136351854, 1232213.389494063099846, 405.872499999997672],
              [2698316.218542179092765, 1232214.208251325879246, 406.178750000006403],
              [2698314.156037517823279, 1232213.618243058910593, 405.830000000001746],
              [2698311.612287495285273, 1232214.775743064237759, 405.830000000001746],
              [2698311.132287490181625, 1232216.18949307827279, 405.830000000001746],
              [2698313.636047945357859, 1232220.50076167518273, 406.613750000004075],
              [2698315.183551615569741, 1232232.14576828549616, 406.888749999998254],
              [2698313.24104099906981, 1232232.685749426251277, 406.092499999998836],
              [2698313.156038500834256, 1232234.933245001826435, 405.904999999998836],
              [2698312.439793188124895, 1232235.763253360055387, 406.257500000006985],
              [2698310.21479171840474, 1232237.005750792566687, 406.148749999993015],
              [2698306.721042537130415, 1232236.993252302985638, 406.212499999994179],
              [2698305.656041046138853, 1232235.210749654797837, 406.101250000006985],
              [2698304.356037511955947, 1232226.710743312723935, 405.836249999993015],
              [2698301.806046008132398, 1232227.110758475027978, 406.476250000006985],
              [2698301.056048799306154, 1232219.060763384681195, 406.686249999998836],
              [2698296.994787712581456, 1232218.305743716657162, 405.85624999999709],
              [2698287.06728744180873, 1232219.520743409404531, 405.842499999998836],
              [2698285.397287424653769, 1232220.598243414191529, 405.842499999998836],
              [2698285.313537424895912, 1232222.845743437297642, 405.842499999998836],
              [2698286.52229502145201, 1232224.759506954345852, 406.412500000005821],
              [2698288.688552259467542, 1232240.613269924651831, 406.955000000001746],
              [2698276.616039236541837, 1232242.135746970539913, 405.985000000000582],
              [2698273.48855262203142, 1232243.893270849715918, 406.992499999993015],
              [2698264.737289078999311, 1232245.273246907163411, 405.98124999999709],
              [2698264.383537696674466, 1232244.674494444392622, 405.877500000002328],
              [2698257.394789265003055, 1232245.566997354850173, 406.0],
              [2698255.423539728857577, 1232244.468248204793781, 406.036250000004657],
              [2698255.206038195639849, 1232241.92824546364136, 405.921249999999418],
              [2698252.137287870980799, 1232242.291994935134426, 405.898749999993015],
              [2698254.949792779516429, 1232269.655753853265196, 406.266250000000582],
              [2698248.138540736865252, 1232270.707000344060361, 406.117499999993015],
              [2698243.756043392699212, 1232270.1107551404275, 406.320000000006985],
              [2698233.206038806587458, 1232265.310747102368623, 405.982499999998254],
              [2698223.306040118448436, 1232258.860749545972794, 406.087499999994179],
              [2698217.656038203276694, 1232254.310746194329113, 405.947499999994761],
              [2698211.556040981784463, 1232247.26075116917491, 406.160000000003492],
              [2698208.156039303634316, 1232240.360748188802972, 406.036250000004657],
              [2698205.606038987636566, 1232229.810747573152184, 406.013749999998254],
              [2698204.756048197858036, 1232224.3107639325317, 406.70625000000291],
              [2698225.356040348764509, 1232221.560749598080292, 406.102499999993597],
              [2698237.206037041265517, 1232224.110743548953906, 405.846250000002328],
              [2698243.756036917213351, 1232226.110743238357827, 405.832500000004075],
              [2698246.003537235315889, 1232225.958243770990521, 405.854999999995925],
              [2698245.687287099659443, 1232225.040743523044512, 405.845000000001164],
              [2698243.07103766174987, 1232223.366994547657669, 405.888749999998254],
              [2698238.94353838916868, 1232222.519495906773955, 405.946249999993597],
              [2698237.754786832723767, 1232221.599493145477027, 405.830000000001746],
              [2698229.50103675853461, 1232219.655743130017072, 405.830000000001746],
              [2698228.547289928421378, 1232220.068248790688813, 406.068750000005821],
              [2698225.537289368920028, 1232217.060747820883989, 406.028749999997672],
              [2698222.3735390920192, 1232211.136997324647382, 406.009999999994761],
              [2698221.249791742768139, 1232210.830752066103742, 406.210000000006403],
              [2698220.661043871194124, 1232208.883255839115009, 406.369999999995343],
              [2698221.587288305163383, 1232207.553245906718075, 405.951249999998254],
              [2698219.242294355295599, 1232204.197006697999313, 406.407500000001164],
              [2698218.006043083034456, 1232200.110754410503432, 406.3125],
              [2698188.411037393845618, 1232201.609494779258966, 405.904999999998836],
              [2698179.673536632210016, 1232202.911993576446548, 405.853749999994761],
              [2698177.60728707909584, 1232204.236994416220114, 405.888749999998254],
              [2698174.747293359600008, 1232204.643255642848089, 406.36250000000291],
              [2698174.352289097383618, 1232203.975748056778684, 406.042499999995925],
              [2698172.683538496959955, 1232204.470747031504288, 405.998749999998836],
              [2698172.6822888138704, 1232205.219497590791434, 406.022500000006403],
              [2698168.947287316434085, 1232206.621994997374713, 405.912500000005821],
              [2698163.624788132030517, 1232207.686996550997719, 405.977499999993597],
              [2698157.347286145202816, 1232209.913243135903031, 405.832500000004075],
              [2698153.773537210654467, 1232210.484495094977319, 405.914999999993597],
              [2698148.528535602148622, 1232212.298242327524349, 405.797500000000582],
              [2698146.461038691457361, 1232213.706997877219692, 406.03125],
              [2698123.023537463974208, 1232220.620746132917702, 405.955000000001746],
              [2698112.136035702191293, 1232224.830743208061904, 405.830000000001746],
              [2698112.65354133117944, 1232225.922003225423396, 406.252500000002328],
              [2698110.806040515657514, 1232226.660751812858507, 406.192500000004657],
              [2698108.759792908094823, 1232229.315756124211475, 406.373749999998836],
              [2698104.974786318838596, 1232231.966994487913325, 405.881250000005821],
              [2698102.426035914104432, 1232235.455743831116706, 405.852499999993597],
              [2698100.592287275474519, 1232239.0307463160716, 405.95625000000291],
              [2698100.663552129175514, 1232242.444522787118331, 407.072499999994761],
              [2698098.883536894805729, 1232242.139495685463771, 405.928750000006403],
              [2698084.756041601765901, 1232260.510754458606243, 406.292499999995925],
              [2698098.706057224422693, 1232279.910782200051472, 407.457500000004075],
              [2698093.806049128994346, 1232283.610767908627167, 406.852499999993597],
              [2698088.406037669163197, 1232275.860747532453388, 405.994999999995343],
              [2698090.156035489402711, 1232274.510743613587692, 405.830000000001746],
              [2698085.95603864826262, 1232268.81074925349094, 406.070000000006985],
              [2698084.106041860766709, 1232270.210755007807165, 406.3125],
              [2698082.006042240187526, 1232267.41075569903478, 406.342499999998836],
              [2698083.756036134902388, 1232265.910744786029682, 405.882500000006985],
              [2698082.406035938765854, 1232264.060744448797777, 405.868749999994179],
              [2698079.206036558840424, 1232266.960745625663549, 405.917499999995925],
              [2698079.166035227477551, 1232270.184493280714378, 405.817500000004657],
              [2698081.698541103396565, 1232273.690753737231717, 406.257500000006985],
              [2698082.646043641027063, 1232276.525758255273104, 406.447499999994761],
              [2698082.159787362441421, 1232281.103247132152319, 405.976250000006985],
              [2698080.96103623509407, 1232284.68074517394416, 405.892500000001746],
              [2698078.729787662159652, 1232288.33699778537266, 406.001250000001164],
              [2698067.49853617651388, 1232304.286995455855504, 405.897500000006403],
              [2698063.279787068720907, 1232309.185747151030228, 405.967499999998836],
              [2698060.814788010902703, 1232311.091998884920031, 406.039999999993597],
              [2698058.191037187352777, 1232312.498247479088604, 405.979999999995925],
              [2698055.728537133429199, 1232313.156997419893742, 405.977499999993597],
              [2698054.53603620827198, 1232313.984495799988508, 405.908750000002328],
              [2698053.341037361416966, 1232315.646997885312885, 405.996249999996508],
              [2698052.064789294265211, 1232318.639501373749226, 406.142500000001746],
              [2698048.723538133781403, 1232321.459499386139214, 406.057499999995343],
              [2698046.417286912444979, 1232322.78449726710096, 405.967499999998836],
              [2698044.188537459354848, 1232325.440748291322961, 406.009999999994761],
              [2698042.838536913506687, 1232325.519497339613736, 405.970000000001164],
              [2698041.728535806760192, 1232324.68324538320303, 405.88749999999709],
              [2698038.86978579685092, 1232324.839495418360457, 405.888749999998254],
              [2698036.96228621294722, 1232326.081996198976412, 405.921249999999418],
              [2698035.208536894991994, 1232329.406997469021007, 405.973750000004657],
              [2698031.62979431450367, 1232332.05951075698249, 406.533750000002328],
              [2698025.907285994850099, 1232334.53824606211856, 405.912500000005821],
              [2698025.287294770590961, 1232335.983261706773192, 406.572499999994761],
              [2698023.843542560003698, 1232336.687007802305743, 406.407500000001164],
              [2698020.26353639177978, 1232337.26699688564986, 405.946249999993597],
              [2698014.302294405177236, 1232340.410761272534728, 406.552500000005239],
              [2698007.467290616128594, 1232343.052004664437845, 406.272500000006403],
              [2698002.618538526818156, 1232345.867001044563949, 406.118749999994179],
              [2698002.018542079254985, 1232347.323257392505184, 406.386249999995925],
              [2697989.226046435534954, 1232353.558265416417271, 406.722500000003492],
              [2697987.121034857351333, 1232352.475744823226705, 405.853749999994761],
              [2697981.477284492459148, 1232355.203244285658002, 405.830000000001746],
              [2697980.204784480389208, 1232356.198244292521849, 405.830000000001746],
              [2697979.926039002370089, 1232358.089502363931388, 406.169999999998254],
              [2697975.439784601796418, 1232360.275744624435902, 405.842499999998836],
              [2697971.223534831311554, 1232360.331995096523315, 405.86250000000291],
              [2697967.726034896913916, 1232361.90199528913945, 405.869999999995343],
              [2697965.736034347675741, 1232364.059494358021766, 405.830000000001746],
              [2697965.622301860712469, 1232365.060775549849495, 407.146250000005239],
              [2697962.884786483366042, 1232366.394498231355101, 405.992499999993015],
              [2697961.603534310124815, 1232366.04449437558651, 405.830000000001746],
              [2697959.697284459136426, 1232366.286994678899646, 405.842499999998836],
              [2697958.661038442049176, 1232367.865751795703545, 406.142500000001746],
              [2697956.277299909852445, 1232369.190772265894338, 407.006250000005821],
              [2697954.76978424936533, 1232368.685744402231649, 405.830000000001746],
              [2697950.554785304702818, 1232371.41949637955986, 405.912500000005821],
              [2697948.172285752370954, 1232371.826997214229777, 405.947499999994761],
              [2697948.873547465074807, 1232373.223268067231402, 406.827499999999418],
              [2697944.752297177910805, 1232375.232017643284053, 406.808749999996508],
              [2697943.483534143771976, 1232373.559494440676644, 405.830000000001746],
              [2697942.131035462487489, 1232375.054496825439855, 405.929999999993015],
              [2697940.779784734826535, 1232375.71574555314146, 405.876250000001164],
              [2697941.316048525739461, 1232376.905770114157349, 406.912500000005821],
              [2697932.652294687926769, 1232381.094513459363952, 406.630000000004657],
              [2697930.844786026515067, 1232380.011998046422377, 405.979999999995925],
              [2697928.142284005414695, 1232381.250744511606172, 405.830000000001746],
              [2697928.22103590099141, 1232382.000747890910134, 405.972500000003492],
              [2697919.559784360229969, 1232384.635745309991762, 405.86250000000291],
              [2697919.002290989272296, 1232385.717007130617276, 406.361250000001746],
              [2697915.983534093480557, 1232386.539494906784967, 405.845000000001164],
              [2697913.359786299988627, 1232388.195748890051618, 406.01249999999709],
              [2697910.738535278476775, 1232388.519497119355947, 405.9375],
              [2697905.493534148670733, 1232390.583245215239003, 405.85624999999709],
              [2697904.378534668590873, 1232392.078246173448861, 405.896250000005239],
              [2697902.867284837178886, 1232393.155746502568945, 405.910000000003492],
              [2697899.452284441329539, 1232393.393245860701427, 405.882500000006985],
              [2697897.464787781238556, 1232394.969501849962398, 406.134999999994761],
              [2697895.954783712513745, 1232395.213244632119313, 405.830000000001746],
              [2697893.491033689118922, 1232396.538244642084464, 405.830000000001746],
              [2697893.807292371988297, 1232398.345760114490986, 406.482499999998254],
              [2697891.827288761734962, 1232399.217003725701943, 406.212499999994179],
              [2697890.153533976059407, 1232397.858245216310024, 405.853749999994761],
              [2697883.793533835094422, 1232401.250745095778257, 405.847500000003492],
              [2697883.874788239598274, 1232402.718252952443436, 406.178750000006403],
              [2697882.048541267868131, 1232403.522008382948115, 406.407500000001164],
              [2697880.853534006979316, 1232402.489495463436469, 405.86250000000291],
              [2697873.143533552996814, 1232405.876994812395424, 405.833750000005239],
              [2697873.683541239704937, 1232407.205758506199345, 406.411250000004657],
              [2697871.604794430546463, 1232408.120764226652682, 406.652499999996508],
              [2697868.454785623587668, 1232408.109498591395095, 405.992499999993015],
              [2697851.52728332253173, 1232415.213244828162715, 405.83125000000291],
              [2697849.226035845931619, 1232416.796999373706058, 406.022500000006403],
              [2697847.552286031655967, 1232417.030749735422432, 406.037500000005821],
              [2697838.651035018730909, 1232420.99824811052531, 405.967499999998836],
              [2697833.404783840291202, 1232423.976996122393757, 405.882500000006985],
              [2697834.747306666802615, 1232427.313286778749898, 407.597500000003492],
              [2697831.80729227187112, 1232428.135761199984699, 406.517500000001746],
              [2697831.572283124551177, 1232426.969494903460145, 405.830000000001746],
              [2697830.302283463533968, 1232426.464495515683666, 405.85624999999709],
              [2697829.591033108532429, 1232425.04699488542974, 405.830000000001746],
              [2697825.93353558331728, 1232427.531999377999455, 406.01875000000291],
              [2697804.241033042781055, 1232434.535745269851759, 405.842499999998836],
              [2697802.332284823525697, 1232436.278248478658497, 405.977499999993597],
              [2697800.90228292811662, 1232436.273245136486366, 405.836249999993015],
              [2697793.352283111773431, 1232439.994495606981218, 405.854999999995925],
              [2697792.394788390491158, 1232441.823255037888885, 406.252500000002328],
              [2697790.013533079996705, 1232441.815745627507567, 405.854999999995925],
              [2697781.9860327411443, 1232445.284495180472732, 405.835000000006403],
              [2697777.769783401861787, 1232448.934496452333406, 405.88749999999709],
              [2697774.03353263437748, 1232450.836995165562257, 405.832500000004075],
              [2697768.387282916810364, 1232455.148245795397088, 405.857499999998254],
              [2697760.118532474152744, 1232459.949495183303952, 405.830000000001746],
              [2697761.949790970422328, 1232462.024510309565812, 406.467499999998836],
              [2697759.406037785112858, 1232464.2607546960935, 406.229999999995925],
              [2697767.856046041008085, 1232474.710769349243492, 406.845000000001164],
              [2697765.862284182105213, 1232476.346998278051615, 405.955000000001746],
              [2697763.956041397061199, 1232477.910761173116043, 406.498749999998836],
              [2697757.122291672509164, 1232469.434511699713767, 406.523749999993015],
              [2697755.501033894252032, 1232466.011997838737443, 405.940000000002328],
              [2697754.151034346781671, 1232466.008248670492321, 405.975000000005821],
              [2697751.36853247648105, 1232467.33074539899826, 405.836249999993015],
              [2697750.809783951379359, 1232468.578248038189486, 405.947499999994761],
              [2697748.428532448597252, 1232468.569495406700298, 405.836249999993015],
              [2697746.361032896209508, 1232469.811996245291084, 405.871249999996508],
              [2697742.937287085223943, 1232474.297003801912069, 406.188750000001164],
              [2697741.269782584626228, 1232474.206995804095641, 405.851250000006985],
              [2697740.233533785678446, 1232476.201997986994684, 405.942500000004657],
              [2697738.722284519579262, 1232477.279499328229576, 405.998749999998836],
              [2697737.531032513361424, 1232477.6082457753364, 405.848750000004657],
              [2697736.733532257378101, 1232478.855745343724266, 405.830000000001746],
              [2697737.106035820674151, 1232479.660751688061282, 406.097500000003492],
              [2697733.142283854540437, 1232482.995748283341527, 405.952499999999418],
              [2697731.329782209359109, 1232481.084495367947966, 405.830000000001746],
              [2697727.744782540015876, 1232486.568246061215177, 405.857499999998254],
              [2697723.847286044619977, 1232490.053252395940945, 406.123749999998836],
              [2697720.509782142471522, 1232491.20699550351128, 405.832500000004075],
              [2697720.268534515984356, 1232492.621999756898731, 406.011249999995925],
              [2697723.99105198867619, 1232496.882030856097117, 407.322499999994761],
              [2697722.081050457898527, 1232498.79077817639336, 407.208750000005239],
              [2697713.574784438125789, 1232503.758249814389274, 406.009999999994761],
              [2697707.769785232841969, 1232507.900751358363777, 406.073749999995925],
              [2697703.954781955108047, 1232509.386995599837974, 405.830000000001746],
              [2697702.599782573524863, 1232511.546996740624309, 405.877500000002328],
              [2697700.771031942218542, 1232512.706995652755722, 405.83125000000291],
              [2697698.782284534536302, 1232514.948250319808722, 406.027499999996508],
              [2697681.456046791747212, 1232527.910772553179413, 406.961249999993015],
              [2697680.306031754706055, 1232526.710745773511007, 405.83125000000291],
              [2697677.006035730242729, 1232529.160752926953137, 406.132500000006985],
              [2697677.306038043927401, 1232529.610757051734254, 406.306249999994179],
              [2697675.556038892827928, 1232531.010758604854345, 406.371249999996508],
              [2697675.10603569727391, 1232530.510752911213785, 406.131250000005821],
              [2697673.306031674146652, 1232530.160745772300288, 405.830000000001746],
              [2697672.856031670700759, 1232530.560745774069801, 405.830000000001746],
              [2697680.606038154102862, 1232541.110757289687172, 406.3125],
              [2697676.303542551118881, 1232544.508265228010714, 406.646250000005239],
              [2697673.22729089576751, 1232545.588262331439182, 406.523749999993015],
              [2697672.037290701176971, 1232545.168262, 406.509999999994761],
              [2697669.04103873251006, 1232535.33200845727697, 406.363750000004075],
              [2697669.678532287012786, 1232534.750746963778511, 405.878750000003492],
              [2697667.858531623147428, 1232531.829495788086206, 405.830000000001746],
              [2697667.139781616162509, 1232533.49324580328539, 405.830000000001746],
              [2697668.549790486693382, 1232542.658261661184952, 406.496249999996508],
              [2697668.617291731759906, 1232548.070763919036835, 406.589999999996508],
              [2697666.387289552949369, 1232550.978260097326711, 406.427500000005239],
              [2697665.996046926826239, 1232552.649523260304704, 406.982499999998254],
              [2697661.616049414500594, 1232556.108277790714055, 407.172500000000582],
              [2697661.216041250154376, 1232555.623263246146962, 406.558749999996508],
              [2697651.914781473111361, 1232561.088246026076376, 405.830000000001746],
              [2697650.326031458098441, 1232561.331996026914567, 405.830000000001746],
              [2697646.268531568814069, 1232564.898246318567544, 405.841249999997672],
              [2697644.838531406596303, 1232565.476996063254774, 405.830000000001746],
              [2697643.959782312624156, 1232567.639497709926218, 405.898749999993015],
              [2697639.18728158576414, 1232571.785746524576098, 405.847500000003492],
              [2697640.169791005086154, 1232573.045763298403472, 406.554999999993015],
              [2697628.908537808805704, 1232581.93950786604546, 406.322499999994761],
              [2697629.397290804889053, 1232582.662013204302639, 406.547500000000582],
              [2697627.251033871434629, 1232584.069500894518569, 406.027499999996508],
              [2697624.553539365064353, 1232582.894510714802891, 406.442500000004657],
              [2697616.714783860370517, 1232572.958250957075506, 406.033750000002328],
              [2697616.637281652074307, 1232571.708247009897605, 405.867499999993015],
              [2697615.369781157467514, 1232570.288246135227382, 405.83125000000291],
              [2697610.956032962072641, 1232572.760749446228147, 405.970000000001164],
              [2697609.244785107206553, 1232575.847003323026001, 406.132500000006985],
              [2697604.868537444621325, 1232579.662007592618465, 406.311249999998836],
              [2697597.55603170953691, 1232583.634497521212325, 405.884999999994761],
              [2697593.107281386386603, 1232584.701997030293569, 405.863750000004075],
              [2697592.71478094952181, 1232582.534496244043112, 405.83125000000291],
              [2697588.978531266096979, 1232584.186996881151572, 405.857499999998254],
              [2697585.954781221691519, 1232587.508246875368059, 405.85624999999709],
              [2697585.632280917372555, 1232589.505746364127845, 405.833750000005239],
              [2697586.363552984315902, 1232591.305785676231608, 407.492499999993015],
              [2697578.237282761372626, 1232594.643249811138958, 405.977499999993597],
              [2697574.023538572713733, 1232597.293260257691145, 406.417499999995925],
              [2697568.142289934214205, 1232600.020762803964317, 406.523749999993015],
              [2697566.951042898930609, 1232599.933268096297979, 406.747499999997672],
              [2697565.527280684094876, 1232597.596996340667829, 405.830000000001746],
              [2697564.094784230925143, 1232598.758252687985078, 406.097500000003492],
              [2697565.991047176998109, 1232602.19952575629577, 407.070000000006985],
              [2697562.903531675226986, 1232603.850748197175562, 405.90625],
              [2697560.994780643843114, 1232600.413246366195381, 405.830000000001746],
              [2697559.08728062780574, 1232601.405746373813599, 405.830000000001746],
              [2697560.351030652411282, 1232604.324496422894299, 405.83125000000291],
              [2697561.856031516566873, 1232604.410747937159613, 405.895000000004075],
              [2697569.949799735564739, 1232620.190780403325334, 407.259999999994761],
              [2697548.442296164575964, 1232630.854524481575936, 407.006250000005821],
              [2697545.156031058635563, 1232624.410747566726059, 405.872499999997672],
              [2697544.106043368577957, 1232624.910769522888586, 406.798750000001746],
              [2697541.356046786066145, 1232619.460775607498363, 407.057499999995343],
              [2697545.554793696384877, 1232617.098270016023889, 406.822499999994761],
              [2697549.197283557150513, 1232621.607001926517114, 406.057499999995343],
              [2697551.02603088086471, 1232620.530747116543353, 405.854999999995925],
              [2697546.98853211151436, 1232615.270749330986291, 405.94999999999709],
              [2697545.402280498761684, 1232614.515746483812109, 405.830000000001746],
              [2697543.046043907757849, 1232616.223270425572991, 406.839999999996508],
              [2697541.206038556527346, 1232612.810760893160477, 406.438750000001164],
              [2697538.126042037270963, 1232614.354517160216346, 406.702499999999418],
              [2697536.592280421406031, 1232612.736996467225254, 405.830000000001746],
              [2697533.409785111434758, 1232615.723254904616624, 406.184999999997672],
              [2697528.481033191084862, 1232618.620751581620425, 406.04374999999709],
              [2697526.574781959876418, 1232618.864499427611008, 405.952499999999418],
              [2697524.13229140965268, 1232618.00201629800722, 406.664999999993597],
              [2697521.656039809808135, 1232613.160763450432569, 406.546249999999418],
              [2697502.456045501865447, 1232623.060773993376642, 406.98750000000291],
              [2697501.856041128281504, 1232621.860766191966832, 406.658750000002328],
              [2697508.906036768108606, 1232618.260758281918243, 406.326249999998254],
              [2697507.406044783536345, 1232615.210772566264495, 406.929999999993015],
              [2697507.456032186746597, 1232613.860750101273879, 405.982499999998254],
              [2697505.862281225156039, 1232613.463248412124813, 405.911250000004657],
              [2697498.868531262967736, 1232616.436998612945899, 405.91874999999709],
              [2697496.306030059698969, 1232616.160746505483985, 405.830000000001746],
              [2697495.306037064176053, 1232616.710759013192728, 406.357499999998254],
              [2697493.806031583808362, 1232613.96074924711138, 405.946249999993597],
              [2697494.956030080094934, 1232613.360746552003548, 405.832500000004075],
              [2697494.401032170280814, 1232612.139500272925943, 405.990000000005239],
              [2697495.307280051056296, 1232611.344496470410377, 405.830000000001746],
              [2697494.041030041407794, 1232609.009496451355517, 405.830000000001746],
              [2697492.691030925139785, 1232609.420748055679724, 405.897500000006403],
              [2697480.856029921676964, 1232616.710746522294357, 405.830000000001746],
              [2697481.256035175174475, 1232617.510755885858089, 406.225000000005821],
              [2697479.372288649436086, 1232618.535762113519013, 406.48750000000291],
              [2697478.857280201744288, 1232617.616997056407854, 405.852499999993597],
              [2697471.624780815094709, 1232620.923248306382447, 405.903749999997672],
              [2697460.33603526558727, 1232627.213256474817172, 406.246249999996508],
              [2697460.306034502573311, 1232628.910755119286478, 406.188750000001164],
              [2697471.506038253195584, 1232647.260761777870357, 406.463749999995343],
              [2697464.356029763352126, 1232651.460746797267348, 405.830000000001746],
              [2697472.856041169725358, 1232665.410767108900473, 406.682499999995343],
              [2697460.856030955910683, 1232670.610749145504087, 405.922500000000582],
              [2697444.74728147406131, 1232676.042000378482044, 405.972500000003492],
              [2697443.86853410769254, 1232678.370755108539015, 406.171249999999418],
              [2697439.999789936002344, 1232680.764515582937747, 406.61250000000291],
              [2697420.204797269776464, 1232687.324529024772346, 407.177500000005239],
              [2697415.106033797375858, 1232687.014505096711218, 406.167499999995925],
              [2697412.884783742949367, 1232686.507005030289292, 406.164999999993597],
              [2697407.006032744888216, 1232679.560753293102607, 406.09375],
              [2697397.556029753759503, 1232677.110748094739392, 405.875],
              [2697333.956028563436121, 1232687.510747098829597, 405.828750000000582],
              [2697332.056029328145087, 1232690.360748511506245, 405.88749999999709],
              [2697331.95604381384328, 1232693.260774366324767, 406.977499999993597],
              [2697330.206042535137385, 1232694.71077212668024, 406.882500000006985],
              [2697325.80604103486985, 1232694.410769522888586, 406.772500000006403],
              [2697325.156044080853462, 1232704.560775049030781, 407.002500000002328],
              [2697320.85604231711477, 1232704.310771972406656, 406.872499999997672],
              [2697321.506039815489203, 1232694.160767418099567, 406.683749999996508],
              [2697320.211035449523479, 1232692.683259644079953, 406.35624999999709],
              [2697313.861038053408265, 1232691.329514378216118, 406.556249999994179],
              [2697311.086036348715425, 1232689.653261365136132, 406.429999999993015],
              [2697304.733537388965487, 1232690.048263328382745, 406.51249999999709],
              [2697302.987286310177296, 1232689.543261425336823, 406.432499999995343],
              [2697301.086035644635558, 1232687.370760259917006, 406.383749999993597],
              [2697293.226033497601748, 1232687.427006559679285, 406.227499999993597],
              [2697290.524786166846752, 1232688.000761357834563, 406.429999999993015],
              [2697288.696033758111298, 1232688.99325711117126, 406.25],
              [2697271.633528953883797, 1232685.104498780565336, 405.899999999994179],
              [2697267.027281003072858, 1232685.920752526959404, 406.057499999995343],
              [2697260.999777792952955, 1232682.735746871680021, 405.820000000006985],
              [2697256.313531973399222, 1232683.219504402019083, 406.13749999999709],
              [2697255.444778641220182, 1232681.134498463012278, 405.88749999999709],
              [2697251.396031447686255, 1232680.620753523893654, 406.101250000006985],
              [2697249.889778724871576, 1232679.949498689733446, 405.897500000006403],
              [2697246.868532619904727, 1232681.688255693530664, 406.192500000004657],
              [2697245.441031890921295, 1232681.017004419583827, 406.138749999998254],
              [2697242.274777928832918, 1232676.00949736032635, 405.842499999998836],
              [2697242.363527765497565, 1232671.679497024975717, 405.830000000001746],
              [2697240.466027748771012, 1232668.424497010186315, 405.830000000001746],
              [2697238.572279394138604, 1232662.921999918064103, 405.955000000001746],
              [2697238.654778613708913, 1232661.174498518230394, 405.896250000005239],
              [2697236.757277982309461, 1232657.835747396107763, 405.850000000005821],
              [2697230.56352834450081, 1232657.648248136974871, 405.881250000005821],
              [2697228.657278991769999, 1232658.058249325724319, 405.931249999994179],
              [2697226.429778735619038, 1232659.965748923830688, 405.913750000006985],
              [2697226.83853417262435, 1232661.387008621823043, 406.322499999994761],
              [2697220.773531392216682, 1232662.493253773543984, 406.117499999993015],
              [2697220.314777918159962, 1232660.694497567601502, 405.85624999999709],
              [2697206.494779454544187, 1232662.395750552183017, 405.98124999999709],
              [2697205.538527434691787, 1232663.890746983001009, 405.830000000001746],
              [2697206.156027539167553, 1232665.160747167887166, 405.837499999994179],
              [2697205.106030355673283, 1232669.410752236610278, 406.05000000000291],
              [2697202.356030430644751, 1232669.9607524194289, 406.057499999995343],
              [2697199.956033198628575, 1232666.310757371364161, 406.267500000001746],
              [2697199.42977737961337, 1232661.788246964802966, 405.830000000001746],
              [2697198.397277371492237, 1232661.950746968155727, 405.830000000001746],
              [2697196.167281021829695, 1232665.108253533486277, 406.10624999999709],
              [2697193.069778434932232, 1232665.679498980054632, 405.913750000006985],
              [2697192.511031818576157, 1232666.844505037646741, 406.16874999999709],
              [2697190.923532836139202, 1232666.838256871327758, 406.246249999996508],
              [2697189.972278639674187, 1232665.835749400779605, 405.931249999994179],
              [2697185.047277734149247, 1232666.818247864721343, 405.866250000006403],
              [2697170.354777300264686, 1232668.683247354114428, 405.84375],
              [2697162.332277097739279, 1232670.070747129386291, 405.833750000005239],
              [2697161.852280697319657, 1232671.568253570469096, 406.104999999995925],
              [2697160.34352863766253, 1232671.813249925384298, 405.951249999998254],
              [2697159.94852727279067, 1232670.895747493486851, 405.848750000004657],
              [2697150.018533013761044, 1232673.109507913934067, 406.287500000005821],
              [2697147.796027013566345, 1232672.685747248120606, 405.837499999994179],
              [2697146.047277681529522, 1232673.179498468991369, 405.888749999998254],
              [2697145.33103250619024, 1232674.175757088465616, 406.252500000002328],
              [2697141.121030440554023, 1232674.827003485523164, 406.100000000005821],
              [2697138.819776833057404, 1232674.403247082373127, 405.830000000001746],
              [2697133.338527082465589, 1232675.633247626246884, 405.852499999993597],
              [2697131.513526768423617, 1232675.210747095290571, 405.830000000001746],
              [2697130.558535694144666, 1232675.790763031924143, 406.502500000002328],
              [2697129.294776748865843, 1232673.204497080063447, 405.830000000001746],
              [2697127.227276731282473, 1232674.113247083965689, 405.830000000001746],
              [2697126.90603572782129, 1232676.010763155529276, 406.507500000006985],
              [2697124.206026701256633, 1232676.910747108748183, 405.830000000001746],
              [2697126.606033267453313, 1232683.81075883586891, 406.322499999994761],
              [2697124.756028249394149, 1232684.460749917663634, 405.946249999993597],
              [2697121.506026678252965, 1232675.110747098922729, 405.830000000001746],
              [2697119.556026661768556, 1232674.41074708616361, 405.830000000001746],
              [2697115.92103626113385, 1232675.968264279188588, 406.554999999993015],
              [2697115.078527918085456, 1232674.488249400164932, 405.927500000005239],
              [2697111.738527737557888, 1232676.890749158803374, 405.916249999994761],
              [2697103.554777693469077, 1232679.610749244689941, 405.91874999999709],
              [2697102.519778534770012, 1232680.607000762363896, 405.982499999998254],
              [2697099.262276461813599, 1232681.928247128147632, 405.828750000000582],
              [2697097.669778621289879, 1232683.588251022389159, 405.992499999993015],
              [2697088.769777512177825, 1232686.888249215204269, 405.914999999993597],
              [2697082.807277422398329, 1232690.448249186621979, 405.912500000005821],
              [2697075.656027854420245, 1232693.005750096170232, 405.94999999999709],
              [2697076.038542673457414, 1232694.983276535989717, 407.065000000002328],
              [2697073.506042020395398, 1232696.160775413736701, 407.017500000001746],
              [2697072.206034003291279, 1232692.460761109134182, 406.414999999993597],
              [2697069.941029099281877, 1232692.153252396732569, 406.047500000000582],
              [2697063.108526994939893, 1232693.961998775601387, 405.89375000000291],
              [2697062.149778747465461, 1232696.873251937329769, 406.026249999995343],
              [2697060.161027018912137, 1232698.531998897204176, 405.897500000006403],
              [2697055.551027625333518, 1232700.764500076649711, 405.946249999993597],
              [2697051.81103213923052, 1232704.414508217712864, 406.288750000006985],
              [2697046.801030883099884, 1232707.728256087051705, 406.197499999994761],
              [2697042.827277921140194, 1232709.630750895245001, 405.977499999993597],
              [2697041.161025948356837, 1232709.37449739431031, 405.830000000001746],
              [2697039.808525966014713, 1232710.369497461710125, 405.832500000004075],
              [2697040.513525940477848, 1232712.385747417807579, 405.830000000001746],
              [2697041.006025944836438, 1232712.910747418180108, 405.830000000001746],
              [2697045.456030584406108, 1232712.210755620384589, 406.176250000004075],
              [2697048.306031940039247, 1232712.810757992323488, 406.276249999995343],
              [2697050.656032822560519, 1232713.910759543301538, 406.341249999997672],
              [2697053.306035472080112, 1232715.910764236468822, 406.538750000006985],
              [2697056.10603873571381, 1232718.810770031996071, 406.782500000001164],
              [2697067.756040910258889, 1232734.860773861873895, 406.938750000001164],
              [2697060.973532727453858, 1232739.323259414173663, 406.327499999999418],
              [2697060.073526156600565, 1232738.835747710661963, 405.833750000005239],
              [2697058.004776089917868, 1232740.744497637264431, 405.830000000001746],
              [2697058.363532404880971, 1232741.540758901275694, 406.304999999993015],
              [2697057.186030515003949, 1232742.644505559001118, 406.163750000006985],
              [2697056.57227607537061, 1232742.071997645078227, 405.830000000001746],
              [2697051.403526028618217, 1232745.718247676733881, 405.830000000001746],
              [2697052.206031747162342, 1232747.310757882427424, 406.259999999994761],
              [2697050.601028892211616, 1232747.812002817867324, 406.046249999999418],
              [2697049.813526097219437, 1232746.461997836828232, 405.836249999993015],
              [2697045.762276011519134, 1232747.696997753577307, 405.832500000004075],
              [2697046.228535049129277, 1232749.175763880833983, 406.51249999999709],
              [2697033.45603581238538, 1232753.160765482811257, 406.578750000000582],
              [2697032.006027014926076, 1232750.960749801481143, 405.917499999995925],
              [2697030.056027215439826, 1232746.310750153614208, 405.933749999996508],
              [2697029.256041010376066, 1232741.310774721903726, 406.972500000003492],
              [2697030.606030697468668, 1232728.560756200924516, 406.195000000006985],
              [2697029.906034890096635, 1232727.960763689130545, 406.511249999995925],
              [2697028.956042572390288, 1232727.960777398431674, 407.089999999996508],
              [2697028.206036470830441, 1232725.210766506614164, 406.631250000005821],
              [2697029.806030657608062, 1232724.610756109235808, 406.192500000004657],
              [2697030.50602827174589, 1232723.410751830553636, 406.01249999999709],
              [2697029.869781024754047, 1232716.580756695941091, 406.220000000001164],
              [2697028.129779782611877, 1232713.577004479244351, 406.127500000002328],
              [2697026.22603039490059, 1232712.820755594875664, 406.17500000000291],
              [2697025.116028408985585, 1232711.818252064986154, 406.026249999995343],
              [2697022.573531009722501, 1232712.642006754176691, 406.223750000004657],
              [2697018.761027255561203, 1232713.128250120207667, 405.943750000005821],
              [2697012.486026452388614, 1232714.605748802889138, 405.88749999999709],
              [2697005.413527648895979, 1232716.995751076377928, 405.982499999998254],
              [2697002.872275684960186, 1232717.236997608561069, 405.836249999993015],
              [2697003.203528561163694, 1232719.114502756856382, 406.052500000005239],
              [2697000.167282649781555, 1232719.989510111743584, 406.36250000000291],
              [2696999.217278191819787, 1232718.557002157904208, 406.027499999996508],
              [2696991.34977873461321, 1232721.610753283137456, 406.073749999995925],
              [2696980.546025397721678, 1232724.155747526790947, 405.830000000001746],
              [2696980.979783405549824, 1232726.068261820357293, 406.432499999995343],
              [2696972.506031087599695, 1232729.460757854860276, 406.263749999998254],
              [2696973.056027803570032, 1232734.010752022266388, 406.016250000000582],
              [2696972.262275737710297, 1232734.299498354783282, 405.861250000001746],
              [2696971.406025810167193, 1232734.610748506616801, 405.867499999993015],
              [2696973.156028381548822, 1232739.460753102088347, 406.059999999997672],
              [2696935.806025054771453, 1232756.060747916111723, 405.835000000006403],
              [2696942.15603445423767, 1232770.860764712095261, 406.538750000006985],
              [2696929.901029013097286, 1232776.434505246579647, 406.13749999999709],
              [2696927.756027631927282, 1232777.410752825671807, 406.035000000003492],
              [2696924.856025963556021, 1232777.210749889258295, 405.911250000004657],
              [2696922.456028796732426, 1232775.26075497479178, 406.126250000001164],
              [2696911.206025531049818, 1232752.060749139171094, 405.88749999999709],
              [2696880.156028636731207, 1232759.31075524399057, 406.142500000001746],
              [2696878.956027762964368, 1232760.510753712849692, 406.077499999999418],
              [2696878.956027513835579, 1232761.410753275733441, 406.058749999996508],
              [2696880.206026312429458, 1232762.66075112298131, 405.967499999998836],
              [2696886.306029042229056, 1232762.0607558877673, 406.16874999999709],
              [2696892.806026144884527, 1232762.160750610521063, 405.946249999993597],
              [2696897.506029705982655, 1232764.110756910871714, 406.211249999993015],
              [2696907.856033957097679, 1232801.710764635587111, 406.524999999994179],
              [2696844.556030740961432, 1232822.06076011294499, 406.326249999998254],
              [2696834.056027631275356, 1232800.910754551179707, 406.098750000004657],
              [2696828.606033393647522, 1232803.51076494413428, 406.536250000004657],
              [2696822.956036565359682, 1232792.160770600195974, 406.778749999997672],
              [2696855.506024262867868, 1232769.060747921932489, 405.830000000001746],
              [2696856.356024272274226, 1232769.51074793189764, 405.830000000001746],
              [2696857.35602428112179, 1232769.260747929336503, 405.830000000001746],
              [2696857.856024285778403, 1232768.560747916810215, 405.830000000001746],
              [2696857.406024279072881, 1232767.110747913364321, 405.830000000001746],
              [2696856.706024273764342, 1232766.810747908661142, 405.830000000001746],
              [2696855.756024265661836, 1232767.110747910570353, 405.830000000001746],
              [2696842.498524143826216, 1232776.539497988997027, 405.830000000001746],
              [2696839.972274119965732, 1232776.383247988764197, 405.830000000001746],
              [2696839.079774113371968, 1232778.969498007092625, 405.830000000001746],
              [2696821.306040852330625, 1232791.610778269357979, 407.102499999993597],
              [2696815.006024390459061, 1232792.535749010741711, 405.867499999993015],
              [2696808.964773835614324, 1232795.511998151428998, 405.830000000001746],
              [2696808.611029445659369, 1232797.649508179398254, 406.252500000002328],
              [2696805.577278221491724, 1232799.30325606232509, 406.162500000005821],
              [2696804.909773798659444, 1232798.246998175978661, 405.830000000001746],
              [2696802.843523796647787, 1232798.821998208761215, 405.83125000000291],
              [2696803.078524747863412, 1232800.571999910287559, 405.902499999996508],
              [2696805.84727632952854, 1232802.263252712320536, 406.020000000004075],
              [2696806.903534276876599, 1232804.228266885271296, 406.617499999993015],
              [2696800.043527291156352, 1232808.805754569591954, 406.096250000002328],
              [2696795.032283636275679, 1232812.785766001790762, 406.577499999999418],
              [2696791.056023572105914, 1232814.56074812845327, 405.822499999994761],
              [2696786.129774025641382, 1232817.418249038979411, 405.860000000000582],
              [2696784.063523774500936, 1232817.910748631460592, 405.842499999998836],
              [2696780.087274102494121, 1232820.645749307703227, 405.869999999995343],
              [2696777.856030125636607, 1232823.710760116809979, 406.32499999999709],
              [2696783.506044167093933, 1232834.310785172507167, 407.378750000003492],
              [2696791.556043073069304, 1232850.860783228185028, 407.291249999994761],
              [2696767.956038059666753, 1232857.110774720786139, 406.929999999993015],
              [2696763.806023417972028, 1232844.610748552484438, 405.830000000001746],
              [2696759.95603699516505, 1232845.960772860562429, 406.854999999995925],
              [2696755.183526463806629, 1232830.979504018556327, 406.065000000002328],
              [2696755.612275771331042, 1232829.887002763105556, 406.01249999999709],
              [2696753.638524312060326, 1232824.550750140100718, 405.903749999997672],
              [2696751.597273414954543, 1232814.300748495385051, 405.837499999994179],
              [2696749.909776189364493, 1232814.539503472158685, 406.047500000000582],
              [2696749.411027499008924, 1232812.984505800297484, 406.146250000005239],
              [2696749.854774861596525, 1232812.213251083623618, 405.947499999994761],
              [2696749.70477336877957, 1232807.715748382732272, 405.835000000006403],
              [2696748.197273421566933, 1232807.29449850320816, 405.839999999996508],
              [2696736.514773181639612, 1232812.166998302098364, 405.830000000001746],
              [2696736.622278579045087, 1232813.34825793816708, 406.236250000001746],
              [2696734.004774438682944, 1232814.398250603815541, 405.926250000004075],
              [2696733.177273152396083, 1232813.571998313535005, 405.830000000001746],
              [2696729.919773172587156, 1232814.309498409274966, 405.833750000005239],
              [2696724.039773734286427, 1232816.538249524775892, 405.880000000004657],
              [2696722.607273056171834, 1232817.781998350983486, 405.830000000001746],
              [2696726.008524828590453, 1232824.123251514043659, 405.961249999993015],
              [2696727.111026662401855, 1232828.040754804620519, 406.098750000004657],
              [2696725.508524822071195, 1232830.70200157025829, 405.961249999993015],
              [2696723.212276909034699, 1232832.190755339805037, 406.119999999995343],
              [2696719.397273452952504, 1232833.676999252988026, 405.86250000000291],
              [2696711.288523776922375, 1232838.39575000340119, 405.892500000001746],
              [2696706.599773602560163, 1232840.128249775385484, 405.882500000006985],
              [2696703.657276249025017, 1232842.033254569163546, 406.083750000005239],
              [2696701.911023610737175, 1232841.94324988592416, 405.886249999995925],
              [2696699.446023870725185, 1232843.600750401150435, 405.907500000001164],
              [2696699.558541928883642, 1232845.033282646210864, 407.267500000001746],
              [2696697.936024917755276, 1232844.594502306077629, 405.98750000000291],
              [2696684.822286783717573, 1232850.295773742720485, 406.889999999999418],
              [2696683.154774318449199, 1232849.957001524977386, 405.952499999999418],
              [2696673.934772890992463, 1232854.338249166030437, 405.851250000006985],
              [2696674.15602567885071, 1232855.260754146613181, 406.061249999998836],
              [2696673.50603100284934, 1232856.310763662215322, 406.462499999994179],
              [2696674.356041601859033, 1232857.910782573278993, 407.259999999994761],
              [2696672.706036007031798, 1232858.960772634018213, 406.839999999996508],
              [2696675.756044300273061, 1232863.960787425749004, 407.462499999994179],
              [2696673.85604355391115, 1232865.110786131350324, 407.407500000001164],
              [2696668.806037451140583, 1232858.160775267286226, 406.951249999998254],
              [2696668.756027291994542, 1232855.660757109988481, 406.186249999998836],
              [2696667.956024312414229, 1232855.360751810716465, 405.962499999994179],
              [2696666.711022541392595, 1232853.896998662967235, 405.830000000001746],
              [2696664.879772527143359, 1232856.139498679665849, 405.830000000001746],
              [2696665.262278338894248, 1232862.552009098231792, 406.267500000001746],
              [2696656.991022384725511, 1232869.019498658599332, 405.82499999999709],
              [2696655.39477267023176, 1232872.760749224806204, 405.847500000003492],
              [2696656.027272439561784, 1232873.845748816849664, 405.830000000001746],
              [2696657.206030535046011, 1232873.560763245215639, 406.438750000001164],
              [2696658.856036659795791, 1232875.310774160316214, 406.898749999993015],
              [2696657.806023851502687, 1232876.360751332715154, 405.934999999997672],
              [2696654.899773591663688, 1232881.587000956293195, 405.917499999995925],
              [2696649.963525336701423, 1232887.815754201030359, 406.052500000005239],
              [2696650.492290743626654, 1232888.988281710306183, 407.212499999994179],
              [2696645.418543587438762, 1232895.264536912785843, 407.429999999993015],
              [2696642.881025006063282, 1232894.61950379027985, 406.032500000001164],
              [2696639.853532779030502, 1232899.60576775087975, 406.619999999995343],
              [2696638.499778963625431, 1232901.18326097750105, 406.333750000005239],
              [2696638.256026273593307, 1232902.710756189888343, 406.131250000005821],
              [2696636.256029524840415, 1232905.060762053122744, 406.377500000002328],
              [2696631.75603436306119, 1232908.160770788090304, 406.744999999995343],
              [2696630.356044112239033, 1232907.010788196930662, 407.479999999995925],
              [2696635.156024601310492, 1232901.360753250308335, 406.007500000006985],
              [2696635.886022949591279, 1232898.592000269796699, 405.882500000006985],
              [2696635.176025270018727, 1232896.590754395583645, 406.057499999995343],
              [2696632.313528031576425, 1232898.163259392837062, 406.267500000001746],
              [2696628.53228818718344, 1232904.865777634084225, 407.035000000003492],
              [2696621.506030949298292, 1232913.660764913074672, 406.494999999995343],
              [2696622.506036051549017, 1232922.110774071188644, 406.878750000003492],
              [2696611.906033810693771, 1232932.910770335700363, 406.717499999998836],
              [2696614.106038413476199, 1232934.960778529755771, 407.0625],
              [2696608.606033797841519, 1232940.91077043232508, 406.71875],
              [2696605.656032690312713, 1232938.26076848921366, 406.63749999999709],
              [2696596.802279472816736, 1232947.875762964133173, 406.401249999995343],
              [2696593.566021857317537, 1232947.693249428644776, 405.830000000001746],
              [2696591.592271740082651, 1232950.036999270087108, 405.822499999994761],
              [2696592.249790980480611, 1232952.704533628420904, 407.271250000005239],
              [2696584.353521822020411, 1232959.57449961011298, 405.833750000005239],
              [2696583.508523126132786, 1232962.210751973325387, 405.932499999995343],
              [2696574.93602787470445, 1232971.167010665172711, 406.296249999999418],
              [2696572.426021663937718, 1232970.214499608613551, 405.830000000001746],
              [2696577.506039594765753, 1232984.26078165625222, 407.177500000005239],
              [2696575.356039342936128, 1232990.210781297180802, 407.160000000003492],
              [2696545.356049239169806, 1233013.11079964786768, 407.926250000004075],
              [2696545.60605008713901, 1233016.610801186179742, 407.990000000005239],
              [2696535.647293078247458, 1233004.23328873119317, 407.46875],
              [2696521.106031122151762, 1232986.160767471883446, 406.577499999999418],
              [2696518.356031794566661, 1232985.160768707981333, 406.630000000004657],
              [2696516.506024575326592, 1232982.960755826206878, 406.087499999994179],
              [2696515.842271151021123, 1232979.089499699184671, 405.830000000001746],
              [2696513.12852202123031, 1232981.853251324733719, 405.897500000006403],
              [2696513.374774810858071, 1232984.188256312161684, 406.107499999998254],
              [2696508.686022428795695, 1232987.37575216521509, 405.931249999994179],
              [2696507.288524406030774, 1232989.467005740152672, 406.08125000000291],
              [2696498.56977188680321, 1232995.672001431230456, 405.897500000006403],
              [2696498.158521618228406, 1232996.674500970635563, 405.877500000002328],
              [2696494.868523214943707, 1232998.855753887211904, 406.0],
              [2696485.902272798120975, 1233006.980753364274278, 405.975000000005821],
              [2696480.473522070329636, 1233010.838252184679732, 405.923750000001746],
              [2696475.867274730466306, 1233013.274507036199793, 406.127500000002328],
              [2696473.318520759698004, 1233013.199499983107671, 405.830000000001746],
              [2696471.754775010980666, 1233015.542007619282231, 406.151249999995343],
              [2696464.353520710486919, 1233019.155750094680116, 405.832500000004075],
              [2696459.088520761113614, 1233023.263250304153189, 405.839999999996508],
              [2696459.392278513405472, 1233024.288264153059572, 406.423750000001746],
              [2696453.606026154942811, 1233029.160760069265962, 406.25],
              [2696448.606020733714104, 1233028.810750470031053, 405.845000000001164],
              [2696441.956024154089391, 1233036.36075675720349, 406.107499999998254],
              [2696440.356023560278118, 1233039.410755742806941, 406.063750000001164],
              [2696439.906022273935378, 1233043.410753491800278, 405.967499999998836],
              [2696441.356023814529181, 1233050.61075627990067, 406.082500000004075],
              [2696438.149773386307061, 1233053.642005588160828, 406.052500000005239],
              [2696437.289770408999175, 1233052.878250276902691, 405.828750000000582],
              [2696433.671020390000194, 1233056.144500335911289, 405.830000000001746],
              [2696434.44852341664955, 1233057.142005735542625, 406.057499999995343],
              [2696422.444773072842509, 1233068.845755421789363, 406.039999999993597],
              [2696421.579770279116929, 1233068.035750434035435, 405.830000000001746],
              [2696418.453520251438022, 1233071.300750461639836, 405.830000000001746],
              [2696419.148522112518549, 1233072.155753788538277, 405.970000000001164],
              [2696416.006023345980793, 1233075.310756066814065, 406.065000000002328],
              [2696408.306020160671324, 1233067.710750437574461, 405.830000000001746],
              [2696406.30602151947096, 1233067.860752903390676, 405.933749999996508],
              [2696402.992271918337792, 1233069.603253685636446, 405.966249999997672],
              [2696402.333526874892414, 1233073.694512572139502, 406.339999999996508],
              [2696406.527275946456939, 1233078.352010894101113, 406.267500000001746],
              [2696408.418528819456697, 1233081.265766011551023, 406.482499999998254],
              [2696408.829778854735196, 1233082.767016077414155, 406.485000000000582],
              [2696407.92477161064744, 1233084.689503180095926, 405.940000000002328],
              [2696407.512273217085749, 1233090.030756089370698, 406.061249999998836],
              [2696403.317271218635142, 1233097.973252664320171, 405.913750000006985],
              [2696403.89227250078693, 1233098.554504946572706, 406.009999999994761],
              [2696400.766022173222154, 1233104.824504463467747, 405.98750000000291],
              [2696392.539771879091859, 1233118.203254190506414, 405.971250000002328],
              [2696387.76852103555575, 1233125.14575282484293, 405.911250000004657],
              [2696384.559772566892207, 1233130.832005659583956, 406.028749999997672],
              [2696381.467270728666335, 1233133.210752451559529, 405.892500000001746],
              [2696368.056035036686808, 1233156.260778413387015, 406.979999999995925],
              [2696363.756029207725078, 1233154.910768058849499, 406.54374999999709],
              [2696361.1560288509354, 1233154.860767471138388, 406.51875000000291],
              [2696356.347269881051034, 1233155.545751536963508, 405.846250000002328],
              [2696354.291019913274795, 1233158.057001647306606, 405.850000000005821],
              [2696346.887273145373911, 1233171.517007650341839, 406.098750000004657],
              [2696340.058520158287138, 1233181.720752521418035, 405.878750000003492],
              [2696336.768519911915064, 1233188.408252188470215, 405.86250000000291],
              [2696334.958520029205829, 1233190.667002443224192, 405.872499999997672],
              [2696334.464773157145828, 1233194.340758070349693, 406.108749999999418],
              [2696332.902269874233752, 1233195.848252244060859, 405.86250000000291],
              [2696329.611019413452595, 1233202.368251532083377, 405.830000000001746],
              [2696328.95227073226124, 1233205.708253929857165, 405.929999999993015],
              [2696327.636019824072719, 1233206.880752337165177, 405.86250000000291],
              [2696327.307271794881672, 1233210.970755901420489, 406.011249999995925],
              [2696325.086025920696557, 1233211.645763309439644, 406.323749999995925],
              [2696323.68852352257818, 1233209.815759033430368, 406.14375000000291],
              [2696320.152270684950054, 1233208.409504014300182, 405.932499999995343],
              [2696314.394771034363657, 1233210.264504745136946, 405.962499999994179],
              [2696309.95352031243965, 1233215.454503564164042, 405.911250000004657],
              [2696308.224769779480994, 1233220.96700269728899, 405.872499999997672],
              [2696303.70101952040568, 1233229.410752377239987, 405.85624999999709],
              [2696303.371022932697088, 1233232.082008497556672, 406.113750000004075],
              [2696299.339769260957837, 1233240.107002076692879, 405.839999999996508],
              [2696298.598520052153617, 1233243.280753524741158, 405.899999999994179],
              [2696293.004770629573613, 1233253.563254728447646, 405.947499999994761],
              [2696286.012269517406821, 1233267.857002985430881, 405.868749999994179],
              [2696284.366020428482443, 1233271.868254673900083, 405.938750000001164],
              [2696285.168528781272471, 1233274.472019601613283, 406.567500000004657],
              [2696282.638518869876862, 1233276.630751963704824, 405.822499999994761],
              [2696279.429769184906036, 1233283.067002632888034, 405.848750000004657],
              [2696279.677269637119025, 1233284.568253447534516, 405.882500000006985],
              [2696278.0185192883946, 1233287.57450287276879, 405.857499999998254],
              [2696278.556022877339274, 1233290.110759301343933, 406.127500000002328],
              [2696293.663535948842764, 1233298.083282473031431, 407.102499999993597],
              [2696297.683523606043309, 1233305.032010415801778, 406.169999999998254],
              [2696296.367272696923465, 1233307.957008844008669, 406.102499999993597],
              [2696297.372278747614473, 1233309.002019634703174, 406.557499999995343],
              [2696262.837277023587376, 1233373.653267670189962, 406.452499999999418],
              [2696264.939778501633555, 1233374.989520291564986, 406.5625],
              [2696261.321018714923412, 1233377.588252888293937, 405.827499999999418],
              [2696261.732270293403417, 1233379.339505711337551, 405.946249999993597],
              [2696257.20727134635672, 1233389.034507748438045, 406.028749999997672],
              [2696255.644769191276282, 1233391.12575394148007, 405.867499999993015],
              [2696256.877270195167512, 1233393.625755738699809, 405.942500000004657],
              [2696254.657270175870508, 1233395.802005761535838, 405.942500000004657],
              [2696255.067273032385856, 1233397.469510865630582, 406.157500000001164],
              [2696252.024772324133664, 1233398.482009661383927, 406.10624999999709],
              [2696250.049769018776715, 1233402.994503830326721, 405.858749999999418],
              [2696251.858521141577512, 1233404.740757605526596, 406.017500000001746],
              [2696251.941019034478813, 1233406.575753860641271, 405.858749999999418],
              [2696253.256029265467077, 1233407.489522127434611, 406.628750000003492],
              [2696253.092276376672089, 1233410.160766985965893, 406.411250000004657],
              [2696260.903530644252896, 1233414.054524509701878, 406.727499999993597],
              [2696254.158523064106703, 1233425.593261181376874, 406.161250000004657],
              [2696251.362271197140217, 1233425.603257895214483, 406.022500000006403],
              [2696250.786021591164172, 1233427.692008619662374, 406.052500000005239],
              [2696246.756019811145961, 1233430.959505535895005, 405.921249999999418],
              [2696246.837273345328867, 1233433.213261870900169, 406.1875],
              [2696244.534771298989654, 1233431.635758239543065, 406.035000000003492],
              [2696240.752271797508001, 1233430.313259172951803, 406.07499999999709],
              [2696239.518521338701248, 1233427.980758353602141, 406.041249999994761],
              [2696236.147271757014096, 1233426.490759149659425, 406.07499999999709],
              [2696234.996020718012005, 1233423.408257289091125, 405.997499999997672],
              [2696230.719773337244987, 1233420.919512013439089, 406.197499999994761],
              [2696229.241022941656411, 1233417.669511305633932, 406.16874999999709],
              [2696224.55227225413546, 1233417.269510145066306, 406.119999999995343],
              [2696222.168521304614842, 1233416.275758478557691, 406.05000000000291],
              [2696210.89977184869349, 1233420.904509677784517, 406.098750000004657],
              [2696204.402270958293229, 1233422.095758206676692, 406.036250000004657],
              [2696200.618524093180895, 1233427.199513907544315, 406.274999999994179],
              [2696191.078521751333028, 1233431.572009918279946, 406.104999999995925],
              [2696183.593519589398056, 1233435.769506215583533, 405.947499999994761],
              [2696179.317269802559167, 1233435.784506666474044, 405.966249999997672],
              [2696176.767270990181714, 1233435.125758818350732, 406.057499999995343],
              [2696174.711024883203208, 1233437.553265833761543, 406.352499999993597],
              [2696175.944775573909283, 1233440.302017071517184, 406.403749999997672],
              [2696178.246022541541606, 1233443.214511642232537, 406.173750000001746],
              [2696178.903522399719805, 1233446.217011403292418, 406.162500000005821],
              [2696175.284770904574543, 1233449.650758818024769, 406.052500000005239],
              [2696176.271019719541073, 1233452.900756711373106, 405.962499999994179],
              [2696172.48851962108165, 1233451.49575657886453, 405.957500000004075],
              [2696170.761019055265933, 1233452.670755608705804, 405.916249999994761],
              [2696169.691019657999277, 1233455.177006732672453, 405.962499999994179],
              [2696167.059770117048174, 1233456.35450760088861, 405.998749999998836],
              [2696164.016020854003727, 1233456.782008964801207, 406.056249999994179],
              [2696161.632270481437445, 1233455.623258335748687, 406.029999999998836],
              [2696160.068517814856023, 1233455.794503598241135, 405.830000000001746],
              [2696159.246022133622319, 1233459.887011364800856, 406.15625],
              [2696156.778521450702101, 1233458.643260173266754, 406.10624999999709],
              [2696155.544775817543268, 1233459.23201800417155, 406.436249999998836],
              [2696150.938521081581712, 1233465.589509661309421, 406.082500000004075],
              [2696150.444769414607435, 1233469.5970067337621, 405.957500000004075],
              [2696148.963521441444755, 1233472.773260404821485, 406.111250000001746],
              [2696149.374772189185023, 1233476.193261767970398, 406.167499999995925],
              [2696139.587273130193353, 1233481.149513650685549, 406.244999999995343],
              [2696138.517272274009883, 1233483.239512152504176, 406.181249999994179],
              [2696133.993524024728686, 1233485.508265369804576, 406.316250000003492],
              [2696129.223522041924298, 1233484.524511898634955, 406.169999999998254],
              [2696128.729772333055735, 1233487.029512450564653, 406.192500000004657],
              [2696132.019774189218879, 1233488.519515721825883, 406.330000000001746],
              [2696134.4860216723755, 1233489.012011189945042, 406.138749999998254],
              [2696138.928521961439401, 1233484.323261594166979, 406.157500000001164],
              [2696142.712271649390459, 1233481.72326095146127, 406.131250000005821],
              [2696148.551021935418248, 1233479.783261350123212, 406.148749999993015],
              [2696151.429771559778601, 1233478.354510626057163, 406.118749999994179],
              [2696154.472272499930114, 1233478.510762258898467, 406.1875],
              [2696164.341027764137834, 1233484.484521549660712, 406.577499999999418],
              [2696166.396029987838119, 1233486.229525504168123, 406.743749999994179],
              [2696162.694780018180609, 1233489.580775651149452, 406.748749999998836],
              [2696151.262279318645597, 1233495.878274637507275, 406.703750000000582],
              [2696150.441025577485561, 1233493.212017946876585, 406.422500000000582],
              [2696142.051024061162025, 1233497.41326540033333, 406.313750000001164],
              [2696144.271027844399214, 1233499.15702214371413, 406.597500000003492],
              [2696140.323527324944735, 1233502.759521316038445, 406.561249999998836],
              [2696139.089778224937618, 1233504.432022958761081, 406.630000000004657],
              [2696136.293525466229767, 1233504.442018070956692, 406.423750000001746],
              [2696131.358524738810956, 1233507.297016880707815, 406.372499999997672],
              [2696129.219772134907544, 1233506.219512246316299, 406.177500000005239],
              [2696124.861022010911256, 1233509.489512131083757, 406.171249999999418],
              [2696125.189774102065712, 1233511.990765879163519, 406.328750000000582],
              [2696125.024779806379229, 1233514.745776093564928, 406.758749999993597],
              [2696120.501027957070619, 1233517.848272891016677, 406.622499999997672],
              [2696114.743531022686511, 1233519.704528482398018, 406.857499999998254],
              [2696110.959775912109762, 1233519.217019410803914, 406.475000000005821],
              [2696109.973522721324116, 1233517.5520137057174, 406.235000000000582],
              [2696110.056022225413471, 1233513.795762785477564, 406.197499999994761],
              [2696108.659772428218275, 1233507.959513127338141, 406.213749999995343],
              [2696108.659772345796227, 1233505.289512955583632, 406.207500000004075],
              [2696107.837272938340902, 1233503.289514010772109, 406.252500000002328],
              [2696104.54852229449898, 1233503.969512918032706, 406.20625000000291],
              [2696103.561022698879242, 1233505.474513671593741, 406.23750000000291],
              [2696102.32726983493194, 1233504.894508568337187, 406.022500000006403],
              [2696096.32352240011096, 1233508.087013279553503, 406.220000000001164],
              [2696091.306023119483143, 1233509.773264655843377, 406.277499999996508],
              [2696091.552272490691394, 1233511.858263548463583, 406.229999999995925],
              [2696088.838523244019598, 1233514.287014964036644, 406.288750000006985],
              [2696086.536022611893713, 1233512.793263853760436, 406.242499999993015],
              [2696079.791021222248673, 1233516.905761514324695, 406.142500000001746],
              [2696078.146017708349973, 1233516.994505266658962, 405.878750000003492],
              [2696076.50102178985253, 1233519.587012608768418, 406.1875],
              [2696071.566017032135278, 1233522.274504210799932, 405.832500000004075],
              [2696072.388522811233997, 1233526.277014557272196, 406.267500000001746],
              [2696068.687272775452584, 1233528.960764579707757, 406.267500000001746],
              [2696065.562270293477923, 1233527.219510180642828, 406.082500000004075],
              [2696061.778520311228931, 1233528.818260284140706, 406.086249999993015],
              [2696057.501020587049425, 1233531.669510872568935, 406.110000000000582],
              [2696053.964772277977318, 1233530.764513948466629, 406.240000000005239],
              [2696046.23351846029982, 1233533.460757274413481, 405.957500000004075],
              [2696038.913516568951309, 1233536.658254038076848, 405.820000000006985],
              [2696035.459768910426646, 1233535.835758271394297, 405.998749999998836],
              [2696032.911022637039423, 1233535.760764973703772, 406.28125],
              [2696033.8972676852718, 1233538.678256129147485, 405.907500000001164],
              [2696031.182268854696304, 1233539.188258274458349, 405.997499999997672],
              [2696031.73102287389338, 1233541.13451546151191, 406.30000000000291],
              [2696051.989778855349869, 1233554.880775939906016, 406.73750000000291],
              [2696072.619790456723422, 1233586.020796603290364, 407.598750000004657],
              [2696084.269803409930319, 1233611.25081977294758, 408.567500000004657],
              [2696087.571064510382712, 1233633.830839756410569, 409.402499999996508],
              [2696096.854832360055298, 1233671.150871831690893, 410.742499999993015],
              [2696097.437332497444004, 1233670.980872063664719, 410.752500000002328],
              [2696100.666085659060627, 1233683.480877763358876, 410.988750000004075],
              [2696099.851088385097682, 1233688.368382695829496, 411.195000000006985],
              [2696104.834844266530126, 1233696.113393195671961, 411.634999999994761],
              [2696109.103597139939666, 1233704.98089833301492, 411.848750000004657],
              [2696116.038619548082352, 1233740.024688577046618, 413.533750000002328],
              [2696132.60243907943368, 1233851.721063553122804, 418.764999999999418],
              [2696137.202438569627702, 1233860.116062644170597, 418.723750000004657],
              [2696136.8936862279661, 1233860.504808462224901, 418.547500000000582],
              [2696139.848687566816807, 1233864.232310838997364, 418.646250000005239],
              [2696176.64999708533287, 1233926.696167154470459, 423.107499999998254],
              [2696188.343765382654965, 1233945.397449836367741, 424.478749999994761],
              [2696199.395026101265103, 1233957.919968917034566, 425.278749999997672],
              [2696208.672519317828119, 1233966.673706710804254, 424.761249999995925],
              [2696218.370034117717296, 1233974.858733071014285, 425.869999999995343],
              [2696232.006298440974206, 1233989.205008573597297, 426.940000000002328],
              [2696232.486306010279804, 1233988.757522089174017, 427.509999999994761],
              [2696242.712578, 1234000.413811321603134, 429.160000000003492],
              [2696252.830093700904399, 1234015.831339354161173, 430.336249999993015],
              [2696260.55135365948081, 1234024.121357090072706, 431.08125000000291],
              [2696290.156410118564963, 1234049.418957726331428, 435.315000000002328],
              [2696288.507661197800189, 1234050.492709689307958, 435.397500000006403],
              [2696293.980136578436941, 1234058.578915664227679, 433.538750000006985],
              [2696300.007677724584937, 1234073.222739244811237, 436.634999999994761],
              [2696301.652702390681952, 1234079.272783359512687, 438.492499999993015],
              [2696301.642714650370181, 1234086.417805335950106, 439.416249999994761],
              [2696299.83396763401106, 1234093.68906076066196, 439.642500000001746],
              [2696299.597737386822701, 1234098.009096115129068, 441.131250000005821],
              [2696300.150245174299926, 1234100.479110047919676, 441.717499999998836],
              [2696302.66525780595839, 1234103.956632619025186, 442.667499999995925],
              [2696305.555273341014981, 1234110.261660390300676, 443.836249999993015],
              [2696305.415288087911904, 1234121.086686848197132, 444.947499999994761],
              [2696306.705313925165683, 1234136.42923315661028, 446.89375000000291],
              [2696308.340319231152534, 1234139.432992637855932, 447.292499999995925],
              [2696309.96535207470879, 1234151.836801436729729, 449.766250000000582],
              [2696313.007860615383834, 1234153.645566668128595, 450.407500000001164],
              [2696314.016624512616545, 1234157.795591522706673, 451.453750000000582],
              [2696313.821636221371591, 1234162.756862502079457, 452.336249999993015],
              [2696316.32039209920913, 1234165.585623, 452.777499999996508],
              [2696317.156653570011258, 1234169.834393525263295, 453.641250000000582],
              [2696322.405407686717808, 1234173.730650828918442, 453.947499999994761],
              [2696326.289202347863466, 1234189.958230741322041, 457.309999999997672],
              [2696328.380467434413731, 1234193.205757699673995, 458.445000000006985],
              [2696333.017997041810304, 1234202.540810624370351, 460.672500000000582],
              [2696333.4055160228163, 1234207.977094600908458, 462.102499999993597],
              [2696332.224286930635571, 1234215.889632075559348, 463.678750000006403],
              [2696335.49055162537843, 1234229.113408412085846, 464.783750000002328],
              [2696343.13059453619644, 1234245.540985119296238, 468.011249999995925],
              [2696365.192065584007651, 1234262.393880018265918, 484.648749999993015],
              [2696372.572077409829944, 1234266.873901051934808, 485.533750000002328],
              [2696382.674489411525428, 1234274.674993643537164, 478.896250000005239],
              [2696388.47455592174083, 1234281.305112485075369, 483.902499999996508],
              [2696393.623313541058451, 1234283.25762602314353, 484.472500000003492],
              [2696394.889573310036212, 1234282.746393455658108, 485.207500000004075],
              [2696407.35336526716128, 1234299.172718372894451, 488.358749999999418],
              [2696422.883399624377489, 1234309.510279593756422, 490.934999999997672],
              [2696428.770904965233058, 1234312.735289053991437, 491.332500000004075],
              [2696433.78966764267534, 1234314.46281164418906, 492.283750000002328],
              [2696439.528432719875127, 1234318.916588521096855, 493.414999999993597],
              [2696449.224704447202384, 1234320.560377181274816, 495.04374999999709],
              [2696454.827212520409375, 1234323.500391532666981, 495.647500000006403],
              [2696457.853466867469251, 1234325.872899263864383, 495.972500000003492],
              [2696463.222230978310108, 1234327.799174402374774, 497.03125],
              [2696468.543491802643985, 1234333.237943702144548, 497.842499999998836],
              [2696477.398526760749519, 1234339.990506080677733, 500.46875],
              [2696478.024768517352641, 1234346.30049140099436, 499.847500000003492],
              [2696481.684783507138491, 1234351.51426817686297, 500.973750000004657],
              [2696486.274793097749352, 1234354.73553526122123, 501.692500000004657],
              [2696489.873560892883688, 1234364.523067086702213, 503.029999999998836],
              [2696492.157313121948391, 1234365.591821035603061, 503.196249999993597],
              [2696494.659817215055227, 1234370.093078346224502, 503.502500000002328],
              [2696500.541096711996943, 1234395.421881218440831, 505.720000000001164],
              [2696501.807374734431505, 1234403.523181361146271, 507.830000000001746],
              [2696503.656127025838941, 1234409.375685481121764, 508.001250000001164],
              [2696504.173643642570823, 1234414.670715223997831, 509.252500000002328],
              [2696506.858645362779498, 1234418.536968285217881, 509.380000000004657],
              [2696504.777406754437834, 1234423.021988734370098, 510.240000000005239],
              [2696504.761157981585711, 1234427.626990985125303, 510.332500000004075],
              [2696503.826164509635419, 1234432.910752719966695, 510.82499999999709],
              [2696504.679925268981606, 1234436.737021974055097, 511.634999999994761],
              [2696508.251183587592095, 1234440.032036804128438, 512.258749999993597],
              [2696505.829947219230235, 1234446.680811289232224, 513.287500000005821],
              [2696504.503714823629707, 1234453.555842857109383, 514.615000000005239],
              [2696506.412476760335267, 1234457.309614190133289, 515.51249999999709],
              [2696503.399987640790641, 1234463.542133776238188, 516.335000000006403],
              [2696503.627603372558951, 1234478.268590782303363, 525.052500000005239],
              [2696504.588879932649434, 1234483.79863830213435, 527.052500000005239],
              [2696507.243896419648081, 1234493.894917826866731, 528.292499999995925],
              [2696511.678910824004561, 1234504.474943595007062, 529.373749999998836],
              [2696514.703912731260061, 1234507.197446971433237, 529.514999999999418],
              [2696517.932672212366015, 1234508.128713854122907, 530.226250000006985],
              [2696518.955171377863735, 1234510.618712367257103, 530.162500000005821],
              [2696522.746429732535034, 1234514.014977261191234, 530.788750000006985],
              [2696526.282680667005479, 1234517.982478904770687, 530.85624999999709],
              [2696530.913941259030253, 1234519.349997756304219, 531.649999999994179],
              [2696533.493956984486431, 1234525.238775877980515, 532.832500000004075],
              [2696534.80646549584344, 1234527.208791082492098, 533.472500000003492],
              [2696540.492724873125553, 1234526.12755771400407, 534.173750000001746],
              [2696544.685237549245358, 1234525.988830282585695, 535.125],
              [2696548.842741533182561, 1234528.230087328469381, 535.421249999999418],
              [2696554.559003695379943, 1234529.186358968028799, 536.332500000004075],
              [2696558.155259247869253, 1234531.17261883802712, 536.747499999997672],
              [2696561.384018560871482, 1234530.195135409245268, 537.446249999993597],
              [2696565.792773873545229, 1234532.178894827142358, 537.842499999998836],
              [2696572.410281700547785, 1234533.758908698102459, 538.426250000004075],
              [2696577.081538559868932, 1234534.318920862628147, 538.938750000001164],
              [2696582.562800905667245, 1234530.506442772923037, 539.863750000004075],
              [2696584.994051314424723, 1234530.548943454632536, 539.892500000001746],
              [2696589.995304278098047, 1234534.827698688255623, 540.111250000001746],
              [2696594.071557709947228, 1234534.210204731440172, 540.366250000006403],
              [2696600.619068589061499, 1234538.368974087294191, 541.179999999993015],
              [2696600.842822641599923, 1234545.096481392160058, 541.485000000000582],
              [2696601.629080466460437, 1234548.917745400452986, 542.073749999995925],
              [2696605.524083166383207, 1234551.426500172587112, 542.273749999993015],
              [2696605.471612474881113, 1234559.889052653918043, 544.48124999999709],
              [2696606.06787537317723, 1234568.025325787253678, 545.452499999999418],
              [2696607.716631681192666, 1234572.086587066529319, 545.926250000004075],
              [2696610.116656156722456, 1234581.150380861479789, 547.767500000001746],
              [2696608.959192438051105, 1234593.504195868503302, 550.501250000001164],
              [2696611.006702502258122, 1234596.22546384180896, 551.257500000006985],
              [2696611.024252156261355, 1234606.441802711924538, 554.997499999997672],
              [2696611.903021003119648, 1234612.100586439482868, 556.416249999994761],
              [2696614.158037923276424, 1234617.338116695173085, 557.688750000001164],
              [2696612.208068137988448, 1234625.840670844307169, 559.966249999997672],
              [2696609.553078978322446, 1234630.415690321708098, 560.785000000003492],
              [2696609.148082587402314, 1234640.541946901241317, 561.057499999995343],
              [2696602.419361704029143, 1234674.335749479942024, 563.257500000006985],
              [2696605.524363269098103, 1234675.435752218822017, 563.372499999997672],
              [2696600.966871176846325, 1234682.970766541082412, 563.972500000003492],
              [2696569.686919114552438, 1234722.389603373594582, 567.61250000000291],
              [2696566.336924430914223, 1234728.865863032173365, 568.016250000000582],
              [2696564.491934014949948, 1234741.124630345962942, 568.740000000005239],
              [2696561.078214895911515, 1234779.379686064086854, 571.070000000006985],
              [2696558.376975130289793, 1234790.214704549871385, 571.84375],
              [2696545.739505071658641, 1234816.244758665328845, 574.111250000001746],
              [2696548.525761825498194, 1234819.47227071179077, 574.617499999993015],
              [2696546.045764153357595, 1234822.561024967581034, 574.794999999998254],
              [2696541.007011534180492, 1234825.65227042674087, 574.602499999993597],
              [2696535.020766350440681, 1234835.442279290175065, 574.971250000002328],
              [2696506.895779018290341, 1234871.177302988478914, 575.952499999999418],
              [2696498.65577959548682, 1234880.547304314095527, 576.003750000003492],
              [2696492.685781009495258, 1234889.957307087723166, 576.116250000006403],
              [2696474.445794578641653, 1234929.537332229316235, 577.15625],
              [2696473.015794675797224, 1234934.147332487860695, 577.164999999993597],
              [2696473.645795478485525, 1234937.997333953157067, 577.225000000005821],
              [2696475.975798892788589, 1234943.097340062493458, 577.479999999995925],
              [2696489.845811367966235, 1234966.427362338174134, 578.407500000001164],
              [2696491.985814031679183, 1234973.977367135463282, 578.60624999999709],
              [2696491.842065486591309, 1234984.352369863772765, 578.716249999997672],
              [2696500.949566648807377, 1234978.221121662063524, 578.794999999998254],
              [2696547.992102973163128, 1235008.357435906073079, 581.48750000000291],
              [2696563.80711259227246, 1235017.547452854923904, 582.197499999994761],
              [2696499.113337222952396, 1235077.919909668620676, 580.348750000004657],
              [2696521.097135005053133, 1235090.691244763322175, 583.927500000005239],
              [2696530.115899009164423, 1235089.122519587399438, 584.973750000004657],
              [2696563.670934422872961, 1235120.905082520563155, 587.610000000000582],
              [2696538.952147277537733, 1235147.130016970681027, 584.836249999993015],
              [2696511.242169946432114, 1235197.743808746803552, 586.571249999993597],
              [2696478.687140409834683, 1235210.350006813881919, 584.377500000002328],
              [2696462.82587779359892, 1235221.036234731320292, 583.442500000004657],
              [2696432.299645053688437, 1235267.120016850763932, 584.772500000006403],
              [2696416.210915679112077, 1235302.476304532960057, 586.342499999998836],
              [2696397.817168358713388, 1235329.961310072103515, 586.5625],
              [2696400.827224188484251, 1235354.901410172460601, 590.766250000000582],
              [2696391.328436717856675, 1235359.59509341721423, 587.952499999999418],
              [2696388.66967822285369, 1235355.163828232092783, 587.315000000002328],
              [2696351.91217204509303, 1235375.031318256631494, 586.884999999994761],
              [2696391.384909408632666, 1235464.661743092117831, 604.732499999998254],
              [2696398.676191389560699, 1235478.913050315342844, 607.134999999994761],
              [2696400.901194234844297, 1235485.551805440336466, 607.347500000003492],
              [2696377.662447397131473, 1235493.394311746582389, 607.608749999999418],
              [2696382.886216229293495, 1235505.029345455113798, 609.022500000006403],
              [2696390.223723240662366, 1235509.929357883986086, 609.54374999999709],
              [2696397.504981132224202, 1235515.410621901974082, 610.131250000005821],
              [2696404.324987674131989, 1235521.323133518220857, 610.617499999993015],
              [2696410.7074950565584, 1235527.678146650781855, 611.167499999995925],
              [2696419.292503280565143, 1235537.438161277677864, 611.778749999997672],
              [2696427.555011465679854, 1235547.758175854803994, 612.38749999999709],
              [2696434.482519168406725, 1235557.223189593758434, 612.961249999993015],
              [2696441.030023647937924, 1235567.289447574876249, 613.292499999995925],
              [2696445.383774534333497, 1235575.136949155945331, 613.354999999995925],
              [2696449.853771469555795, 1235584.648193678352982, 613.119999999995343],
              [2696453.60751856258139, 1235594.446938505396247, 612.897500000006403],
              [2696486.587457787711173, 1235688.728080148110166, 608.288750000006985],
              [2696744.280979653354734, 1235499.876413768157363, 590.852499999993597],
              [2696800.718472617212683, 1235499.68889988376759, 590.26875000000291],
              [2696798.498512885067612, 1235589.2727230200544, 593.304999999993015],
              [2697659.922097543254495, 1235275.873654161812738, 580.038750000006985],
              [2697688.060858458280563, 1235300.681173309450969, 580.833750000005239],
              [2697720.287106680683792, 1235308.852419497678056, 580.669999999998254],
              [2697724.168357162736356, 1235310.804920291993767, 580.702499999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 122, gemeinde_B: 196, name: 'Mönchaltorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2695360.360142758116126, 1242448.937768657226115, 434.867499999993015],
              [2695377.140158095397055, 1242434.949045875808224, 436.01249999999709],
              [2695395.90015423996374, 1242415.204038414638489, 435.707500000004075],
              [2695406.107659094035625, 1242409.029046926880255, 436.066250000003492],
              [2695417.651408377103508, 1242404.594045396428555, 436.003750000003492],
              [2695474.747662839479744, 1242391.971552317263559, 436.298750000001746],
              [2695494.618914341554046, 1242383.505304590333253, 436.397500000006403],
              [2695574.627667973283678, 1242332.650309270247817, 436.61250000000291],
              [2695587.388917287811637, 1242320.609057698165998, 436.551250000004075],
              [2695622.372669045813382, 1242276.87655986007303, 436.657500000001164],
              [2695634.193917636293918, 1242266.975307020125911, 436.542499999995925],
              [2695652.667670400813222, 1242258.891561605269089, 436.73750000000291],
              [2695725.473920838907361, 1242250.74406103650108, 436.717499999998836],
              [2695743.251419153530151, 1242246.962807655800134, 436.577499999999418],
              [2695768.696420793887228, 1242235.921560060000047, 436.682499999995343],
              [2695782.641422671731561, 1242225.59656309732236, 436.813750000001164],
              [2695823.451435808558017, 1242177.357835594797507, 437.773749999993015],
              [2695827.635185268707573, 1242175.5690845369827, 437.729999999995925],
              [2695832.646437192335725, 1242170.666587863117456, 437.871249999996508],
              [2695825.665186879690737, 1242162.352837349753827, 437.852499999993597],
              [2695835.085180903784931, 1242152.304076336324215, 437.395000000004075],
              [2695855.693929712753743, 1242142.562823733314872, 437.289999999993597],
              [2695873.182681774254888, 1242130.081577029312029, 437.432499999995343],
              [2695932.316434794105589, 1242078.602830959483981, 437.616250000006403],
              [2695956.850184524431825, 1242055.702829828718677, 437.577499999999418],
              [2695972.388934132177383, 1242037.376578682567924, 437.536250000004657],
              [2695984.732682979665697, 1242019.389076228253543, 437.440000000002328],
              [2695995.990185185801238, 1241991.131579740904272, 437.597500000003492],
              [2696026.106436020229012, 1241947.12033031671308, 437.63749999999709],
              [2696053.100187616422772, 1241909.085332369199023, 437.73750000000291],
              [2696067.163937306497246, 1241897.330331457778811, 437.703750000000582],
              [2696080.85768493777141, 1241888.342826872132719, 437.514999999999418],
              [2696091.322686749510467, 1241878.659079860895872, 437.64375000000291],
              [2696099.432689732406288, 1241867.916584990220144, 437.86250000000291],
              [2696112.711439600214362, 1241845.609084314666688, 437.842499999998836],
              [2696132.197689054068178, 1241809.675332658458501, 437.786250000004657],
              [2696150.033932471647859, 1241818.927820591721684, 437.277499999996508],
              [2696154.672687328420579, 1241817.864079229999334, 437.639999999999418],
              [2696443.781453864648938, 1242023.261605750303715, 438.681249999994179],
              [2696444.320206574164331, 1242026.766610642196611, 438.884999999994761],
              [2696502.302713681012392, 1241945.305371650261804, 439.376250000001164],
              [2696514.037713973782957, 1241953.670372047927231, 439.389999999999418],
              [2696516.640213349834085, 1241956.76787091139704, 439.341249999997672],
              [2696540.410228893626481, 1241967.850398540263996, 440.494999999995343],
              [2696546.736488763708621, 1241971.591666200198233, 441.233749999999418],
              [2696551.985249236691743, 1241977.917934996308759, 442.01875000000291],
              [2696577.187826334498823, 1241992.06057329243049, 447.807499999995343],
              [2696605.647834494709969, 1242005.760587579570711, 448.401249999995343],
              [2696639.677730700932443, 1242026.720400561811402, 440.559999999997672],
              [2696677.12896681483835, 1242039.697875060839579, 439.48750000000291],
              [2696676.088966539129615, 1242041.296624600654468, 439.467499999998836],
              [2696781.02778381574899, 1242077.50299399974756, 444.457500000004075],
              [2696785.401531300973147, 1242084.146739467745647, 444.264999999999418],
              [2696784.649023685138673, 1242095.874225895153359, 443.692500000004657],
              [2696777.204013514332473, 1242120.799207971431315, 442.932499999995343],
              [2696745.73899157345295, 1242187.205419695470482, 441.304999999993015],
              [2696742.516492685303092, 1242193.094171803211793, 441.391250000000582],
              [2696746.336492851376534, 1242196.55417207442224, 441.401249999995343],
              [2696748.232743453234434, 1242194.712923100683838, 441.445000000006985],
              [2696751.030243845190853, 1242194.407923749880865, 441.472500000003492],
              [2696774.720248306635767, 1242203.85293144104071, 441.791249999994761],
              [2696788.58150033140555, 1242205.125434844987467, 441.933749999996508],
              [2696792.10274817654863, 1242203.737930894596502, 441.76875000000291],
              [2696802.849001883063465, 1242205.027937379200011, 442.039999999993597],
              [2696811.919016065541655, 1242210.94421276380308, 443.101250000006985],
              [2696840.770289617124945, 1242236.045504819601774, 444.853749999994761],
              [2696873.872799246571958, 1242262.31427177391015, 445.554999999993015],
              [2696900.431553782895207, 1242286.440529676387087, 445.877500000002328],
              [2696902.446552672889084, 1242289.229277671314776, 445.792499999995925],
              [2696904.210300827398896, 1242292.673024357063696, 445.652499999996508],
              [2696905.901547321584076, 1242299.324268084717914, 445.38749999999709],
              [2696905.969045676290989, 1242303.623015170218423, 445.263749999998254],
              [2696904.21154047222808, 1242322.125506017589942, 444.873749999998836],
              [2696932.6927803568542, 1242325.934237361419946, 444.091249999997672],
              [2697046.122783957514912, 1242309.642991657368839, 444.278749999997672],
              [2697081.950284626334906, 1242298.224242109339684, 444.302500000005239],
              [2697111.596528057474643, 1242284.471729664830491, 443.786250000004657],
              [2697133.982779488433152, 1242283.445481823058799, 443.877500000002328],
              [2697194.074028801638633, 1242310.884229774354026, 443.782500000001164],
              [2697219.145273181609809, 1242318.31421929388307, 443.341249999997672],
              [2697233.722774930298328, 1242323.917972234077752, 443.462499999994179],
              [2697294.35779060004279, 1242351.269249551463872, 444.597500000003492],
              [2697307.536541244480759, 1242351.173000476090237, 444.636249999995925],
              [2697321.006543619092554, 1242347.48425446706824, 444.804999999993015],
              [2697388.622800104785711, 1242318.029264632146806, 445.242499999993015],
              [2697404.035302947741002, 1242309.90426939795725, 445.445000000006985],
              [2697432.315308049786836, 1242291.973027888452634, 445.807499999995343],
              [2697440.542809942737222, 1242285.401781081454828, 445.943750000005821],
              [2697463.687784964218736, 1242316.475486093899235, 444.048750000001746],
              [2697471.017780565656722, 1242320.436728097265586, 443.712499999994179],
              [2697463.186528140679002, 1242335.957974022720009, 443.536250000004657],
              [2697435.864019758533686, 1242418.145460207480937, 442.927500000005239],
              [2697439.715271055698395, 1242419.396712484303862, 443.022500000006403],
              [2697445.070273634977639, 1242416.692966993665323, 443.212499999994179],
              [2697812.190406845882535, 1242559.271950935944915, 452.961249999993015],
              [2697814.111659924034029, 1242562.489456455223262, 453.191250000003492],
              [2697812.987909858580679, 1242572.201956445584074, 453.1875],
              [2697820.45040996838361, 1242573.021956518292427, 453.190000000002328],
              [2697827.735427564475685, 1242582.380738056264818, 454.507500000006985],
              [2697914.811816960107535, 1242590.689736780710518, 464.919999999998254],
              [2697941.323105513583869, 1242596.562305547297001, 467.797500000000582],
              [2697950.044387902133167, 1242600.383613553829491, 470.225000000005821],
              [2697969.810693185310811, 1242614.097462551435456, 474.365000000005239],
              [2697973.289461547508836, 1242615.837495460174978, 475.742499999993015],
              [2697972.128196801524609, 1242611.988718972541392, 474.634999999994761],
              [2697990.220682096201926, 1242583.437441963003948, 473.514999999999418],
              [2697994.9344098768197, 1242563.489901802502573, 471.841249999997672],
              [2698002.770625354722142, 1242540.234839465003461, 469.240000000005239],
              [2698015.690641502849758, 1242541.197368213906884, 470.443750000005821],
              [2698031.096907413564622, 1242540.554896475048736, 471.627500000002328],
              [2698067.438117294106632, 1242497.121073376620188, 468.583750000005239],
              [2698147.170515633653849, 1242378.044638343853876, 460.881250000005821],
              [2698141.280499122105539, 1242370.990858766483143, 459.645000000004075],
              [2698143.307999592740089, 1242366.884609533008188, 459.678750000006403],
              [2698150.977986552286893, 1242344.167085777735338, 458.692500000004657],
              [2698153.627985181752592, 1242337.498333204304799, 458.587499999994179],
              [2698158.427986880764365, 1242328.313336082268506, 458.711249999993015],
              [2698164.309237971436232, 1242319.779587853001431, 458.788750000006985],
              [2698171.049240622203797, 1242312.212092413567007, 458.982499999998254],
              [2698180.612994047347456, 1242303.660848296480253, 459.232499999998254],
              [2698189.35674608591944, 1242297.222101731691509, 459.378750000003492],
              [2698209.405504857655615, 1242284.554616981418803, 460.022500000006403],
              [2698220.394253422971815, 1242276.735864132642746, 459.90625],
              [2698233.575506523251534, 1242265.744619341334328, 460.128750000003492],
              [2698245.765511172823608, 1242253.663377350429073, 460.46875],
              [2698404.5555285718292, 1242066.887153859250247, 461.651249999995343],
              [2698408.996779234148562, 1242061.085904910461977, 461.697499999994761],
              [2698412.508032717276365, 1242055.525911034084857, 461.95625000000291],
              [2698415.415533480700105, 1242057.403412361862138, 462.011249999995925],
              [2698422.799287952017039, 1242045.310920125572011, 462.341249999997672],
              [2698429.719284511171281, 1242035.644663740182295, 462.077499999999418],
              [2698462.12179072573781, 1241997.08217391720973, 462.51875000000291],
              [2698434.494569846894592, 1241557.578920467058197, 483.492499999993015],
              [2698385.129285783041269, 1241404.050910821184516, 462.192500000004657],
              [2698260.02047654800117, 1241301.625805981457233, 457.835000000006403],
              [2698175.605548310559243, 1241281.418435969389975, 463.289999999993597],
              [2697656.711511236615479, 1240580.174174154177308, 442.083750000005239],
              [2697648.755263139959425, 1240582.462927727028728, 442.232499999998254],
              [2697644.250262546818703, 1240576.414176692953333, 442.191250000003492],
              [2697773.405256066471338, 1240476.339161870069802, 441.607499999998254],
              [2697965.552770661190152, 1240340.251683372072875, 442.561249999998836],
              [2698094.612755680456758, 1240218.585403149016201, 441.338749999995343],
              [2698464.229163873009384, 1239281.900671052280813, 452.928750000006403],
              [2697679.767107569146901, 1239114.567728233058006, 486.871249999996508],
              [2697572.354571503587067, 1239068.262665251735598, 484.243749999994179],
              [2697083.715321625117213, 1239460.973032321082428, 447.021250000005239],
              [2697047.681828174740076, 1239474.350993090076372, 466.35624999999709],
              [2696884.395387900527567, 1239690.586906890152022, 452.162500000005821],
              [2696342.477153669577092, 1239738.249093121849, 491.427500000005239],
              [2696108.14210217166692, 1239743.890255261911079, 487.735000000000582],
              [2695966.585900419857353, 1239931.504096574848518, 491.490000000005239],
              [2695935.351793291978538, 1239967.393455878365785, 464.592499999998836],
              [2695904.206803077831864, 1239951.205973885254934, 465.353749999994761],
              [2695657.67846842482686, 1240174.83047732245177, 496.873749999998836],
              [2695558.326998699456453, 1240326.551335704512894, 480.383749999993597],
              [2695513.160768210422248, 1240318.432621585670859, 481.891250000000582],
              [2695512.737011229153723, 1240326.041359103983268, 481.365000000005239],
              [2695508.82326337415725, 1240329.507613075431436, 481.529999999998836],
              [2695503.555763018317521, 1240331.186362549895421, 481.507500000006985],
              [2695567.836153638549149, 1240590.23206646181643, 510.927500000005239],
              [2695730.704056721646339, 1240789.235542078502476, 446.927500000005239],
              [2695852.391520993318409, 1241008.715477675199509, 444.148749999993015],
              [2695659.339069725945592, 1241175.121820333879441, 447.970000000001164],
              [2695682.85267228493467, 1241707.366559464251623, 436.845000000001164],
              [2695408.898937325924635, 1241666.659091005800292, 438.177500000005239],
              [2695173.680169600993395, 1241862.052815002156422, 437.014999999999418],
              [2695170.698917185422033, 1241871.177810783032328, 436.835000000006403],
              [2695166.081424095202237, 1241878.172823368804529, 437.360000000000582],
              [2695151.856406218372285, 1241950.540292066987604, 436.022500000006403],
              [2695348.702642120420933, 1242426.709017522167414, 434.827499999999418],
              [2695360.360142758116126, 1242448.937768657226115, 434.867499999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 124, gemeinde_B: 198, name: 'Uster', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2698462.12179072573781, 1241997.08217391720973, 462.51875000000291],
              [2698429.719284511171281, 1242035.644663740182295, 462.077499999999418],
              [2698422.799287952017039, 1242045.310920125572011, 462.341249999997672],
              [2698415.415533480700105, 1242057.403412361862138, 462.011249999995925],
              [2698412.508032717276365, 1242055.525911034084857, 461.95625000000291],
              [2698408.996779234148562, 1242061.085904910461977, 461.697499999994761],
              [2698404.5555285718292, 1242066.887153859250247, 461.651249999995343],
              [2698245.765511172823608, 1242253.663377350429073, 460.46875],
              [2698233.575506523251534, 1242265.744619341334328, 460.128750000003492],
              [2698220.394253422971815, 1242276.735864132642746, 459.90625],
              [2698209.405504857655615, 1242284.554616981418803, 460.022500000006403],
              [2698189.35674608591944, 1242297.222101731691509, 459.378750000003492],
              [2698180.612994047347456, 1242303.660848296480253, 459.232499999998254],
              [2698171.049240622203797, 1242312.212092413567007, 458.982499999998254],
              [2698164.309237971436232, 1242319.779587853001431, 458.788750000006985],
              [2698158.427986880764365, 1242328.313336082268506, 458.711249999993015],
              [2698153.627985181752592, 1242337.498333204304799, 458.587499999994179],
              [2698150.977986552286893, 1242344.167085777735338, 458.692500000004657],
              [2698143.307999592740089, 1242366.884609533008188, 459.678750000006403],
              [2698141.280499122105539, 1242370.990858766483143, 459.645000000004075],
              [2698147.170515633653849, 1242378.044638343853876, 460.881250000005821],
              [2698067.438117294106632, 1242497.121073376620188, 468.583750000005239],
              [2698031.096907413564622, 1242540.554896475048736, 471.627500000002328],
              [2698015.690641502849758, 1242541.197368213906884, 470.443750000005821],
              [2698002.770625354722142, 1242540.234839465003461, 469.240000000005239],
              [2697994.9344098768197, 1242563.489901802502573, 471.841249999997672],
              [2697990.220682096201926, 1242583.437441963003948, 473.514999999999418],
              [2697972.128196801524609, 1242611.988718972541392, 474.634999999994761],
              [2697973.289461547508836, 1242615.837495460174978, 475.742499999993015],
              [2697969.810693185310811, 1242614.097462551435456, 474.365000000005239],
              [2697950.044387902133167, 1242600.383613553829491, 470.225000000005821],
              [2697941.323105513583869, 1242596.562305547297001, 467.797500000000582],
              [2697914.811816960107535, 1242590.689736780710518, 464.919999999998254],
              [2697827.735427564475685, 1242582.380738056264818, 454.507500000006985],
              [2697820.45040996838361, 1242573.021956518292427, 453.190000000002328],
              [2697812.987909858580679, 1242572.201956445584074, 453.1875],
              [2697814.111659924034029, 1242562.489456455223262, 453.191250000003492],
              [2697812.190406845882535, 1242559.271950935944915, 452.961249999993015],
              [2697445.070273634977639, 1242416.692966993665323, 443.212499999994179],
              [2697439.715271055698395, 1242419.396712484303862, 443.022500000006403],
              [2697435.864019758533686, 1242418.145460207480937, 442.927500000005239],
              [2697463.186528140679002, 1242335.957974022720009, 443.536250000004657],
              [2697471.017780565656722, 1242320.436728097265586, 443.712499999994179],
              [2697463.687784964218736, 1242316.475486093899235, 444.048750000001746],
              [2697440.542809942737222, 1242285.401781081454828, 445.943750000005821],
              [2697432.315308049786836, 1242291.973027888452634, 445.807499999995343],
              [2697404.035302947741002, 1242309.90426939795725, 445.445000000006985],
              [2697388.622800104785711, 1242318.029264632146806, 445.242499999993015],
              [2697321.006543619092554, 1242347.48425446706824, 444.804999999993015],
              [2697307.536541244480759, 1242351.173000476090237, 444.636249999995925],
              [2697294.35779060004279, 1242351.269249551463872, 444.597500000003492],
              [2697233.722774930298328, 1242323.917972234077752, 443.462499999994179],
              [2697219.145273181609809, 1242318.31421929388307, 443.341249999997672],
              [2697194.074028801638633, 1242310.884229774354026, 443.782500000001164],
              [2697133.982779488433152, 1242283.445481823058799, 443.877500000002328],
              [2697111.596528057474643, 1242284.471729664830491, 443.786250000004657],
              [2697081.950284626334906, 1242298.224242109339684, 444.302500000005239],
              [2697046.122783957514912, 1242309.642991657368839, 444.278749999997672],
              [2696932.6927803568542, 1242325.934237361419946, 444.091249999997672],
              [2696904.21154047222808, 1242322.125506017589942, 444.873749999998836],
              [2696905.969045676290989, 1242303.623015170218423, 445.263749999998254],
              [2696905.901547321584076, 1242299.324268084717914, 445.38749999999709],
              [2696904.210300827398896, 1242292.673024357063696, 445.652499999996508],
              [2696902.446552672889084, 1242289.229277671314776, 445.792499999995925],
              [2696900.431553782895207, 1242286.440529676387087, 445.877500000002328],
              [2696873.872799246571958, 1242262.31427177391015, 445.554999999993015],
              [2696840.770289617124945, 1242236.045504819601774, 444.853749999994761],
              [2696811.919016065541655, 1242210.94421276380308, 443.101250000006985],
              [2696802.849001883063465, 1242205.027937379200011, 442.039999999993597],
              [2696792.10274817654863, 1242203.737930894596502, 441.76875000000291],
              [2696788.58150033140555, 1242205.125434844987467, 441.933749999996508],
              [2696774.720248306635767, 1242203.85293144104071, 441.791249999994761],
              [2696751.030243845190853, 1242194.407923749880865, 441.472500000003492],
              [2696748.232743453234434, 1242194.712923100683838, 441.445000000006985],
              [2696746.336492851376534, 1242196.55417207442224, 441.401249999995343],
              [2696742.516492685303092, 1242193.094171803211793, 441.391250000000582],
              [2696745.73899157345295, 1242187.205419695470482, 441.304999999993015],
              [2696777.204013514332473, 1242120.799207971431315, 442.932499999995343],
              [2696784.649023685138673, 1242095.874225895153359, 443.692500000004657],
              [2696785.401531300973147, 1242084.146739467745647, 444.264999999999418],
              [2696781.02778381574899, 1242077.50299399974756, 444.457500000004075],
              [2696676.088966539129615, 1242041.296624600654468, 439.467499999998836],
              [2696677.12896681483835, 1242039.697875060839579, 439.48750000000291],
              [2696639.677730700932443, 1242026.720400561811402, 440.559999999997672],
              [2696605.647834494709969, 1242005.760587579570711, 448.401249999995343],
              [2696577.187826334498823, 1241992.06057329243049, 447.807499999995343],
              [2696551.985249236691743, 1241977.917934996308759, 442.01875000000291],
              [2696546.736488763708621, 1241971.591666200198233, 441.233749999999418],
              [2696540.410228893626481, 1241967.850398540263996, 440.494999999995343],
              [2696516.640213349834085, 1241956.76787091139704, 439.341249999997672],
              [2696514.037713973782957, 1241953.670372047927231, 439.389999999999418],
              [2696502.302713681012392, 1241945.305371650261804, 439.376250000001164],
              [2696444.320206574164331, 1242026.766610642196611, 438.884999999994761],
              [2696443.781453864648938, 1242023.261605750303715, 438.681249999994179],
              [2696154.672687328420579, 1241817.864079229999334, 437.639999999999418],
              [2696150.033932471647859, 1241818.927820591721684, 437.277499999996508],
              [2696132.197689054068178, 1241809.675332658458501, 437.786250000004657],
              [2696112.711439600214362, 1241845.609084314666688, 437.842499999998836],
              [2696099.432689732406288, 1241867.916584990220144, 437.86250000000291],
              [2696091.322686749510467, 1241878.659079860895872, 437.64375000000291],
              [2696080.85768493777141, 1241888.342826872132719, 437.514999999999418],
              [2696067.163937306497246, 1241897.330331457778811, 437.703750000000582],
              [2696053.100187616422772, 1241909.085332369199023, 437.73750000000291],
              [2696026.106436020229012, 1241947.12033031671308, 437.63749999999709],
              [2695995.990185185801238, 1241991.131579740904272, 437.597500000003492],
              [2695984.732682979665697, 1242019.389076228253543, 437.440000000002328],
              [2695972.388934132177383, 1242037.376578682567924, 437.536250000004657],
              [2695956.850184524431825, 1242055.702829828718677, 437.577499999999418],
              [2695932.316434794105589, 1242078.602830959483981, 437.616250000006403],
              [2695873.182681774254888, 1242130.081577029312029, 437.432499999995343],
              [2695855.693929712753743, 1242142.562823733314872, 437.289999999993597],
              [2695835.085180903784931, 1242152.304076336324215, 437.395000000004075],
              [2695825.665186879690737, 1242162.352837349753827, 437.852499999993597],
              [2695832.646437192335725, 1242170.666587863117456, 437.871249999996508],
              [2695827.635185268707573, 1242175.5690845369827, 437.729999999995925],
              [2695823.451435808558017, 1242177.357835594797507, 437.773749999993015],
              [2695782.641422671731561, 1242225.59656309732236, 436.813750000001164],
              [2695768.696420793887228, 1242235.921560060000047, 436.682499999995343],
              [2695743.251419153530151, 1242246.962807655800134, 436.577499999999418],
              [2695725.473920838907361, 1242250.74406103650108, 436.717499999998836],
              [2695652.667670400813222, 1242258.891561605269089, 436.73750000000291],
              [2695634.193917636293918, 1242266.975307020125911, 436.542499999995925],
              [2695622.372669045813382, 1242276.87655986007303, 436.657500000001164],
              [2695587.388917287811637, 1242320.609057698165998, 436.551250000004075],
              [2695574.627667973283678, 1242332.650309270247817, 436.61250000000291],
              [2695494.618914341554046, 1242383.505304590333253, 436.397500000006403],
              [2695474.747662839479744, 1242391.971552317263559, 436.298750000001746],
              [2695417.651408377103508, 1242404.594045396428555, 436.003750000003492],
              [2695406.107659094035625, 1242409.029046926880255, 436.066250000003492],
              [2695395.90015423996374, 1242415.204038414638489, 435.707500000004075],
              [2695377.140158095397055, 1242434.949045875808224, 436.01249999999709],
              [2695360.360142758116126, 1242448.937768657226115, 434.867499999993015],
              [2695388.660149394068867, 1242426.716529922559857, 435.347500000003492],
              [2695392.395142538938671, 1242433.209017558721825, 434.827499999999418],
              [2695392.138906016480178, 1242447.619041978381574, 435.845000000001164],
              [2695490.211393682286143, 1242498.327768498333171, 434.845000000001164],
              [2695508.876400826964527, 1242484.486530913040042, 435.369999999995343],
              [2695671.89764654962346, 1242433.877769895363599, 434.928750000006403],
              [2695908.880154807586223, 1242962.961535384412855, 435.392500000001746],
              [2695907.347648868802935, 1242972.012774802744389, 434.946249999993597],
              [2695925.91640646615997, 1243003.154038437409326, 435.506250000005821],
              [2695688.615146717987955, 1243396.86527859698981, 434.951249999998254],
              [2695622.085153662599623, 1243425.80654254113324, 435.523749999993015],
              [2695556.070145299658179, 1243452.989028870593756, 434.941250000003492],
              [2695492.63515684613958, 1243487.092801110818982, 435.858749999999418],
              [2695286.66639116499573, 1243630.071527739288285, 434.827499999999418],
              [2695256.615150461904705, 1243652.675295241177082, 435.551250000004075],
              [2694994.012640023604035, 1243746.04028186458163, 434.955000000001746],
              [2694687.815144816879183, 1244096.854049074929208, 435.546249999999418],
              [2694619.701384603977203, 1244251.404033210361376, 434.827499999999418],
              [2694606.565145415719599, 1244360.369053962407634, 435.65625],
              [2694590.228884288109839, 1244362.59653417346999, 434.827499999999418],
              [2694595.466399743687361, 1244365.230312024708837, 435.991250000006403],
              [2694591.851386860478669, 1244385.604038995457813, 435.021250000005239],
              [2694588.555143510457128, 1244394.63655115198344, 435.526249999995343],
              [2694551.650133837480098, 1244636.45778650068678, 434.827499999999418],
              [2694570.958892750553787, 1244992.340305489255115, 435.494999999995343],
              [2694532.421383493114263, 1245162.601540971780196, 434.827499999999418],
              [2694489.670154582709074, 1245198.481580174760893, 436.452499999999418],
              [2694517.443884506355971, 1245335.712794629158452, 434.91874999999709],
              [2694517.120142756495625, 1245343.751559620955959, 435.542499999995925],
              [2694493.527636873535812, 1245391.354049832560122, 435.116250000006403],
              [2694487.73014179430902, 1245404.905308954650536, 435.492499999993015],
              [2694478.220139616634697, 1245418.902805300895125, 435.335000000006403],
              [2694470.053891419433057, 1245430.806558818556368, 435.477499999993597],
              [2694466.078890257049352, 1245436.171556830173358, 435.392500000001746],
              [2694992.224421149119735, 1245865.54501111805439, 475.102499999993597],
              [2694724.778209560550749, 1246326.831340229371563, 478.223750000004657],
              [2694701.157831363379955, 1246783.180660713696852, 449.6875],
              [2693905.008997515309602, 1247104.184276204789057, 443.947499999994761],
              [2694012.792822550982237, 1247361.575662638293579, 449.547500000000582],
              [2693564.080257697496563, 1247796.573057172819972, 444.985000000000582],
              [2693582.730274775065482, 1247919.830588965676725, 446.266250000000582],
              [2693746.922753220889717, 1248121.808048758888617, 444.51875000000291],
              [2694551.428140749689192, 1248722.074992358218879, 473.216249999997672],
              [2694557.346889798529446, 1248728.551240584813058, 473.139999999999418],
              [2695341.167005132418126, 1248870.925185929518193, 481.235000000000582],
              [2695413.651997639331967, 1248877.322671019239351, 480.61250000000291],
              [2695454.70450034039095, 1248881.860175160923973, 480.783750000002328],
              [2696618.709818917326629, 1248021.181969679426402, 503.837499999994179],
              [2696701.937301756814122, 1248163.918188493698835, 502.479999999995925],
              [2696796.114815267268568, 1248123.975710580358282, 503.41874999999709],
              [2696928.702494092285633, 1248259.153532223775983, 516.773749999993015],
              [2696997.202404619194567, 1248297.29336966923438, 509.982499999998254],
              [2697426.955811651423573, 1248730.262502663070336, 577.860000000000582],
              [2697550.362144040409476, 1248757.503898862283677, 583.941250000003492],
              [2698411.25659177172929, 1248199.636627566069365, 541.63749999999709],
              [2698867.624631957150996, 1247360.253830438712612, 581.761249999995925],
              [2699323.934245774988085, 1247058.415622323052958, 552.361250000001746],
              [2699463.004520093090832, 1246730.893608481157571, 572.80000000000291],
              [2699465.428268725983799, 1246727.407355923205614, 572.695000000006985],
              [2699403.289565442595631, 1246678.279940694337711, 576.255000000004657],
              [2699283.469362344127148, 1246346.424574641976506, 561.125],
              [2699273.970869805198163, 1245760.46628053765744, 580.432499999995343],
              [2699454.059380302205682, 1245485.029593314509839, 562.297500000000582],
              [2699607.015747281257063, 1245161.47854610811919, 570.921249999999418],
              [2699610.946992548648268, 1245143.554787328699604, 570.5625],
              [2699339.341559374704957, 1244905.522763356566429, 538.318750000005821],
              [2699558.822918256279081, 1244548.024200048530474, 546.282500000001164],
              [2699700.220303601585329, 1244356.350239348597825, 537.558749999996508],
              [2699887.984141081571579, 1244211.921640552580357, 543.947499999994761],
              [2699687.913493264000863, 1244051.197981982724741, 495.558749999996508],
              [2699574.989462947007269, 1243754.808469028677791, 568.345000000001164],
              [2699611.956926737446338, 1243584.734651369275525, 565.592499999998836],
              [2699557.48570349579677, 1243589.094700575107709, 567.648749999993015],
              [2699521.543222573120147, 1243606.943485768511891, 569.11250000000291],
              [2699473.868225581012666, 1243632.510992521187291, 569.382500000006985],
              [2699443.454466251656413, 1243649.523476670263335, 568.711249999993015],
              [2699430.858175349421799, 1243636.284653477137908, 565.654999999998836],
              [2699413.76937065878883, 1243612.205805557547137, 561.568750000005821],
              [2699401.864323250949383, 1243589.169470591237769, 558.023749999993015],
              [2699399.944310131948441, 1243579.331946997204795, 557.041249999994761],
              [2699398.771780408453196, 1243551.605643433518708, 554.8125],
              [2699396.874273068737239, 1243545.866880259243771, 554.263749999998254],
              [2699369.969183343928307, 1243491.726719417609274, 547.557499999995343],
              [2699365.845431360881776, 1243490.100465935887769, 547.412500000005821],
              [2699364.136679810471833, 1243485.640463137533516, 547.297500000000582],
              [2699344.636684744153172, 1243493.32172248698771, 547.684999999997672],
              [2699337.410431209485978, 1243489.411716268397868, 547.426250000004075],
              [2699312.272929519414902, 1243469.341713554225862, 547.321249999993597],
              [2699308.802928248420358, 1243462.176711267791688, 547.228749999994761],
              [2699308.904172115493566, 1243454.179200187092647, 546.76875000000291],
              [2699325.531630808487535, 1243420.875375418923795, 543.654999999998836],
              [2699349.512829801533371, 1243381.040283057605848, 539.807499999995343],
              [2699348.204070488922298, 1243374.359016313450411, 539.110000000000582],
              [2699323.586553225293756, 1243355.491485686274245, 537.836249999993015],
              [2699315.687802402768284, 1243347.228984291898087, 537.78125],
              [2699315.324050818104297, 1243339.850231377407908, 537.662500000005821],
              [2699346.181548071093857, 1243298.561475353548303, 537.428750000006403],
              [2699356.836550898849964, 1243289.45398009265773, 537.631250000005821],
              [2699386.70030156802386, 1243269.152730442583561, 537.654999999998836],
              [2699400.244046829640865, 1243251.33272147225216, 537.287500000005821],
              [2699451.522800561971962, 1243195.717726458096877, 537.521250000005239],
              [2699471.139049730729312, 1243172.322724295547232, 537.441250000003492],
              [2699496.98780459119007, 1243139.751482100691646, 537.782500000001164],
              [2699509.774053877219558, 1243122.206480358028784, 537.717499999998836],
              [2699473.629064869601279, 1243088.209000445436686, 538.572499999994761],
              [2699471.676566063892096, 1243085.034002598142251, 538.663750000006985],
              [2699470.510315353982151, 1243081.141501299571246, 538.611250000001746],
              [2699470.395314767956734, 1243077.395250223111361, 538.567500000004657],
              [2699471.331563930958509, 1243073.413998651085421, 538.503750000003492],
              [2699476.434059974737465, 1243064.698991358047351, 538.202499999999418],
              [2699488.439045981969684, 1243047.813965859822929, 537.142500000001746],
              [2699498.489031633362174, 1243035.978939813794568, 536.057499999995343],
              [2699509.650269045028836, 1243026.616416923701763, 535.103749999994761],
              [2699515.831512768287212, 1243023.088905508629978, 534.627500000002328],
              [2699522.161504772957414, 1243017.820140998112038, 534.022500000006403],
              [2699528.487741914112121, 1243006.215117710875347, 533.052500000005239],
              [2699539.590212457347661, 1242979.711314424872398, 530.833750000005239],
              [2699562.358923432417214, 1242952.443743753014132, 527.88749999999709],
              [2699567.037672721315175, 1242948.406242335680872, 527.830000000001746],
              [2699573.178929092828184, 1242948.097503618570045, 528.302500000005239],
              [2699577.326433141715825, 1242949.431260802317411, 528.602499999993597],
              [2699579.428928432520479, 1242942.711252250242978, 528.247499999997672],
              [2699577.200160450767726, 1242936.533720029518008, 526.901249999995343],
              [2699585.015143287368119, 1242929.869939051568508, 525.607499999998254],
              [2699599.715121987275779, 1242903.036150316009298, 523.997499999997672],
              [2699609.262614629697055, 1242889.749886796344072, 523.4375],
              [2699622.297604911029339, 1242875.931118977256119, 522.697499999994761],
              [2699653.137583191972226, 1242847.443579146405682, 521.042499999995925],
              [2699659.780072884168476, 1242835.888560428982601, 520.263749999998254],
              [2699662.833817387931049, 1242820.411050360416993, 519.848750000004657],
              [2699660.016320728696883, 1242798.536056170007214, 520.101250000006985],
              [2699659.661301523447037, 1242770.138521492714062, 518.661250000004657],
              [2699663.386283051688224, 1242736.493487980216742, 517.272500000006403],
              [2699671.487528633326292, 1242706.473479584325105, 516.933749999996508],
              [2699682.311286698561162, 1242685.203493580687791, 517.528749999997672],
              [2699495.350219236686826, 1242589.518823260674253, 531.371249999996508],
              [2698962.354700403753668, 1242433.064152987208217, 492.892500000001746],
              [2698746.773676591925323, 1242293.627061204286292, 510.042499999995925],
              [2698743.261175089515746, 1242289.9970585454721, 509.932499999995343],
              [2698462.12179072573781, 1241997.08217391720973, 462.51875000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 141, gemeinde_B: 228, name: 'Turbenthal', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2709719.470890909899026, 1258003.002206761389971, 664.9375],
              [2709725.038396031130105, 1258008.193465823773295, 665.309999999997672],
              [2709732.014657272491604, 1257996.180985546670854, 666.132500000006985],
              [2709741.419675718527287, 1257958.421017728280276, 667.483749999999418],
              [2709748.632166429422796, 1257942.443500749999657, 666.789999999993597],
              [2709750.398412361275405, 1257939.044743397273123, 666.48750000000291],
              [2709753.555906244553626, 1257934.248482337221503, 666.032500000001164],
              [2709755.977151811122894, 1257931.2847243193537, 665.702499999999418],
              [2709758.632148546166718, 1257928.534718388458714, 665.458750000005239],
              [2709761.50464513618499, 1257926.010962195694447, 665.203750000000582],
              [2709764.048389534465969, 1257924.147202116902918, 664.787500000005821],
              [2709767.362135213334113, 1257922.444694292265922, 664.464999999996508],
              [2709769.713381167501211, 1257921.638437006622553, 664.163750000006985],
              [2709773.363377260044217, 1257920.948429921641946, 663.871249999996508],
              [2709775.839623533189297, 1257920.840923216426745, 663.59375],
              [2709830.077066922094673, 1257923.049570839852095, 659.357499999998254],
              [2709834.034564201254398, 1257922.929565883008763, 659.152499999996508],
              [2709837.8908102796413, 1257922.030808774754405, 658.858749999999418],
              [2709839.732057578396052, 1257921.298303900985047, 658.657500000001164],
              [2709843.158304562326521, 1257919.300798402400687, 658.431249999994179],
              [2709846.124551623594016, 1257916.662043055286631, 658.211249999993015],
              [2709847.395801389589906, 1257915.134542575804517, 658.192500000004657],
              [2709849.452048573642969, 1257911.739537453977391, 657.982499999998254],
              [2709850.182060165330768, 1257911.993308125296608, 658.837499999994179],
              [2709851.970803013071418, 1257907.294545262120664, 658.307499999995343],
              [2709851.227045706938952, 1257907.049532238161191, 657.76875000000291],
              [2709851.868291314225644, 1257903.83202433376573, 657.443750000005821],
              [2709905.244073523208499, 1257699.739935830235481, 622.851250000006985],
              [2709908.835306830704212, 1257687.217405809555203, 621.615000000005239],
              [2709909.394053232390434, 1257684.46364933764562, 621.348750000004657],
              [2709910.189045622479171, 1257678.903635674854741, 620.786250000004657],
              [2709910.549036941491067, 1257673.296120118582621, 620.145000000004075],
              [2709910.564033404458314, 1257670.487363775493577, 619.883749999993597],
              [2709910.266525054816157, 1257664.863598814932629, 619.267500000001746],
              [2709909.954020919743925, 1257662.063591410405934, 618.962499999994179],
              [2709909.531517393887043, 1257659.279835095629096, 618.702499999999418],
              [2709908.362761894240975, 1257653.769825246185064, 618.297500000000582],
              [2709906.251503619831055, 1257647.098560456885025, 617.688750000001164],
              [2709904.910248626023531, 1257643.853551534935832, 617.321249999993597],
              [2709903.382742358837277, 1257640.69354035332799, 616.860000000000582],
              [2709901.67273627454415, 1257637.627279506297782, 616.412500000005821],
              [2709899.787729966454208, 1257634.666018263902515, 615.948749999995925],
              [2709897.732721338514239, 1257631.818502889946103, 615.313750000001164],
              [2709895.516463488806039, 1257629.097238898510113, 614.736250000001746],
              [2709893.13895714096725, 1257626.503477604594082, 614.270000000004075],
              [2709890.615201521199197, 1257624.05346761085093, 613.857499999998254],
              [2709887.951446982566267, 1257621.754709550878033, 613.524999999994179],
              [2709885.158939783461392, 1257619.615946749225259, 612.996249999996508],
              [2709882.246434194501489, 1257617.643436814658344, 612.586249999993015],
              [2709879.22393198730424, 1257615.843432935187593, 612.426250000004075],
              [2709854.617656541988254, 1257603.173387966118753, 610.571249999993597],
              [2709851.003899858798832, 1257600.667126101907343, 610.08125000000291],
              [2709847.878893791232258, 1257597.5733653139323, 609.636249999995925],
              [2709846.528890454210341, 1257595.83585936948657, 609.391250000000582],
              [2709844.305134061258286, 1257592.04209797270596, 608.921249999999418],
              [2709842.771377047989517, 1257587.920835451222956, 608.404999999998836],
              [2709842.277623353060335, 1257585.775828843703493, 608.132500000006985],
              [2709841.857616269495338, 1257581.397066166624427, 607.610000000000582],
              [2709841.932612037751824, 1257579.197058585938066, 607.297500000000582],
              [2709842.655104462988675, 1257574.858295008772984, 606.73750000000291],
              [2709843.295100968331099, 1257572.752038728212938, 606.478749999994761],
              [2709845.111343593802303, 1257568.744525482645258, 605.932499999995343],
              [2709846.272590226028115, 1257566.874519418925047, 605.682499999995343],
              [2709866.351305952761322, 1257538.726957474136725, 603.132500000006985],
              [2709868.941301247105002, 1257534.193198961671442, 602.782500000001164],
              [2709871.050046145915985, 1257529.418189760530367, 602.403749999997672],
              [2709981.449938595760614, 1257466.957994621479884, 594.358749999999418],
              [2710058.55613822536543, 1257412.2229023729451, 590.567500000004657],
              [2710034.682392405811697, 1257369.12540986482054, 590.897500000006403],
              [2710140.311137426178902, 1257149.935395955340937, 590.426250000004075],
              [2710149.90493390057236, 1257126.99172829836607, 593.846250000002328],
              [2710155.521185737103224, 1257118.695481339935213, 593.976250000006985],
              [2710160.112436322495341, 1257120.702982301358134, 594.014999999999418],
              [2710160.401185442693532, 1257117.711730691138655, 593.94999999999709],
              [2710173.634969856124371, 1257088.641791373025626, 596.476250000006985],
              [2710184.595108759589493, 1257068.699538439977914, 606.714999999996508],
              [2710225.716917789075524, 1257005.458032915368676, 647.922500000000582],
              [2710230.920810762327164, 1256988.389537342591211, 658.466249999997672],
              [2710232.850811281707138, 1256984.829538171645254, 658.502500000002328],
              [2710226.033314644824713, 1256979.795794269535691, 658.757500000006985],
              [2710220.947069657500833, 1256973.544553245883435, 659.132500000006985],
              [2710227.318382462486625, 1256971.984664985677227, 663.759999999994761],
              [2710270.359873278066516, 1256960.606342836050317, 681.482499999998254],
              [2710284.627389818429947, 1256951.531371807213873, 682.6875],
              [2710306.7862778166309, 1256956.250367171829566, 692.845000000001164],
              [2710327.932557016611099, 1256951.199168561259285, 694.976250000006985],
              [2710363.995077972766012, 1256931.851704667322338, 696.482499999998254],
              [2710394.306424080394208, 1256919.59062490076758, 703.538750000006985],
              [2710420.646500784903765, 1256901.724510582396761, 709.167499999995925],
              [2710438.442950814962387, 1256888.914866230683401, 723.902499999996508],
              [2710457.22793797031045, 1256889.41359283705242, 722.933749999996508],
              [2710469.319299784023315, 1256882.938791587250307, 731.167499999995925],
              [2710480.776787018403411, 1256867.172518292907625, 730.212499999994179],
              [2710498.446597847621888, 1256840.895930485567078, 716.238750000004075],
              [2710510.894123646896333, 1256827.999725909903646, 718.127500000002328],
              [2710515.571662075817585, 1256818.568544085836038, 720.95625000000291],
              [2710527.114177527371794, 1256805.307321095140651, 722.082500000004075],
              [2710553.552885636687279, 1256774.179745313245803, 718.962499999994179],
              [2710575.49787355447188, 1256753.925972906639799, 718.046249999999418],
              [2710580.872845806181431, 1256749.187173281796277, 715.993749999994179],
              [2710594.627937292214483, 1256737.84108565445058, 722.725000000005821],
              [2710603.850498291198164, 1256715.242443690542132, 727.212499999994179],
              [2710619.966910985764116, 1256682.166482450906187, 739.191250000003492],
              [2710627.753200319595635, 1256662.576551974751055, 742.082500000004075],
              [2710634.898377740290016, 1256642.561867370503023, 755.157500000001164],
              [2710648.078443331643939, 1256639.38573371944949, 759.978749999994761],
              [2710652.364697422366589, 1256634.640740800416097, 760.274999999994179],
              [2710662.774727580603212, 1256621.507043995894492, 762.486250000001746],
              [2710672.228501127101481, 1256616.172085567610338, 764.211249999993015],
              [2710682.643523621372879, 1256613.027125258930027, 765.857499999998254],
              [2710710.542327780276537, 1256607.165970765287057, 769.817500000004657],
              [2710720.417343810200691, 1256602.733498949091882, 770.98750000000291],
              [2710728.559856861829758, 1256595.594771834323183, 771.940000000002328],
              [2710735.198620062321424, 1256587.381045005051419, 772.904999999998836],
              [2710746.957392130047083, 1256564.881083600223064, 774.517500000001746],
              [2710765.523674388416111, 1256520.846139803063124, 776.872499999997672],
              [2710771.108697616495192, 1256485.366180428303778, 778.577499999999418],
              [2710772.721209583804011, 1256470.802451452240348, 779.457500000004075],
              [2710778.85371458530426, 1256468.472460139077157, 779.818750000005821],
              [2710778.962465725373477, 1256463.447462080745026, 779.902499999996508],
              [2710810.303936241660267, 1256423.342852988047525, 796.121249999996508],
              [2710837.858993723988533, 1256374.4079536779318, 800.323749999995925],
              [2710839.650258207693696, 1256355.647979091620073, 801.388749999998254],
              [2710858.064104085788131, 1256333.958148802630603, 808.433749999996508],
              [2710876.679130606818944, 1256321.533195215510204, 810.365000000005239],
              [2710891.178812494501472, 1256254.148877694038674, 786.895000000004075],
              [2710883.013808461371809, 1256247.406370657961816, 786.607499999998254],
              [2710876.342552807182074, 1256239.122610668418929, 786.198749999995925],
              [2710870.258800112176687, 1256230.49260592693463, 786.007500000006985],
              [2710865.665055327117443, 1256227.878865300677717, 786.397500000006403],
              [2710860.616300974041224, 1256220.432607588125393, 786.082500000004075],
              [2710851.608814286068082, 1256224.218881602631882, 787.07499999999709],
              [2710842.051329980138689, 1256221.817659779917449, 788.243749999994179],
              [2710830.441352763678879, 1256215.388950566295534, 789.9375],
              [2710821.730117692612112, 1256212.218977338867262, 791.048750000001746],
              [2710812.527632187120616, 1256210.811503385659307, 792.128750000003492],
              [2710795.735160456970334, 1256209.554054183652624, 794.233749999999418],
              [2710779.1114406818524, 1256206.902858416549861, 796.482499999998254],
              [2710763.480216256342828, 1256200.666654304368421, 798.38749999999709],
              [2710748.536490536294878, 1256192.526697844732553, 800.196249999993597],
              [2710734.844012567773461, 1256182.576737305149436, 801.837499999994179],
              [2710716.524045573547482, 1256166.11804633657448, 804.29374999999709],
              [2710699.675327586010098, 1256150.286853564670309, 806.67500000000291],
              [2710688.039101071190089, 1256142.293145596981049, 808.421249999999418],
              [2710674.38286719750613, 1256138.135674641933292, 809.627500000002328],
              [2710670.469132270198315, 1256129.949451459338889, 810.743749999994179],
              [2710664.535382202360779, 1256130.530701530864462, 810.746249999996508],
              [2710660.131619819439948, 1256139.238179765408859, 809.838749999995343],
              [2710642.922869250178337, 1256145.896929387934506, 809.818750000005821],
              [2710636.629112397320569, 1256143.723167350515723, 809.321249999993597],
              [2710636.025379275903106, 1256128.710697166854516, 810.566250000003492],
              [2710632.574141625314951, 1256119.423219103831798, 811.48124999999709],
              [2710608.645439783576876, 1256090.294555089902133, 815.0625],
              [2710603.081691489554942, 1256077.50830809189938, 815.195000000006985],
              [2710597.121707223821431, 1256054.503335908288136, 816.36250000000291],
              [2710593.936708054505289, 1256044.888337325537577, 816.427500000005239],
              [2710587.454194114077836, 1256030.948312493506819, 815.407500000001164],
              [2710559.394259845837951, 1255987.342179643223062, 820.289999999993597],
              [2710550.820544157642871, 1255969.700990692945197, 822.83125000000291],
              [2710521.329275045543909, 1255937.284707078244537, 821.458750000005239],
              [2710507.034266583155841, 1255928.022192304721102, 820.852499999993597],
              [2710479.076768750790507, 1255903.339696630137041, 821.047500000000582],
              [2710466.15544796641916, 1255892.787070875521749, 815.842499999998836],
              [2710453.590413247700781, 1255890.41075943922624, 813.297500000000582],
              [2710433.164145209826529, 1255879.509477782994509, 811.992499999993015],
              [2710426.295410430058837, 1255875.483255016850308, 813.123749999998836],
              [2710421.192933101672679, 1255857.450795246521011, 814.802500000005239],
              [2710429.794161159079522, 1255837.853255606722087, 813.172500000000582],
              [2710441.341615411452949, 1255804.191923284903169, 809.782500000001164],
              [2710457.049042413476855, 1255790.590542664984241, 804.377500000002328],
              [2710484.789002747740597, 1255755.442970668431371, 801.416249999994761],
              [2710502.258950324729085, 1255739.572876596124843, 797.527499999996508],
              [2710542.568918500095606, 1255682.150317831663415, 795.128750000003492],
              [2710557.910173339303583, 1255658.834075621096417, 795.466249999997672],
              [2710567.895145798102021, 1255650.735276187537238, 793.422500000000582],
              [2710595.698820776771754, 1255638.727641681674868, 787.854999999995925],
              [2710630.869982691947371, 1255594.302483222912997, 781.315000000002328],
              [2710641.01871282979846, 1255575.049947297899053, 779.837499999994179],
              [2710645.95745537057519, 1255563.211183697218075, 779.28125],
              [2710648.392445280216634, 1255550.56241547409445, 778.533750000002328],
              [2710650.601178405340761, 1255525.971135018393397, 777.286250000004657],
              [2710649.549863733816892, 1255483.567269354127347, 772.517500000001746],
              [2710660.726177686825395, 1255480.907382740406319, 777.220000000001164],
              [2710684.048682858701795, 1255459.778640918666497, 777.572499999994761],
              [2710708.897424918599427, 1255427.518625571625307, 776.95625000000291],
              [2710736.660030035302043, 1255392.197561152279377, 784.672500000000582],
              [2710761.217713895719498, 1255343.052886616205797, 798.197499999994761],
              [2710747.846481734421104, 1255310.791668233694509, 799.528749999997672],
              [2710746.517813357524574, 1255257.16931257606484, 805.547500000000582],
              [2710763.974037104751915, 1255249.45801523886621, 803.589999999996508],
              [2710787.037740064784884, 1255237.439180699642748, 800.092499999998836],
              [2710820.593914935365319, 1255208.917795646702871, 794.511249999995925],
              [2710828.863894478417933, 1255189.977758734486997, 792.992499999993015],
              [2710849.198796956799924, 1255173.30383448000066, 785.777499999996508],
              [2710876.553755347616971, 1255156.375009427778423, 782.676250000004075],
              [2710894.0849813753739, 1255146.13746612961404, 780.88749999999709],
              [2710907.824991710484028, 1255134.766233924776316, 781.632500000006985],
              [2710913.549974968191236, 1255134.372453979449347, 780.391250000000582],
              [2710924.021233757492155, 1255132.281219260534272, 781.026249999995343],
              [2710929.840015597641468, 1255129.73377564875409, 783.366250000006403],
              [2710945.291312985587865, 1255116.227609207155183, 786.839999999996508],
              [2710954.978815573267639, 1255112.476363462395966, 787.01875000000291],
              [2711016.487718909978867, 1255066.031633418984711, 798.243749999994179],
              [2711037.799007471650839, 1255044.10420095641166, 801.058749999996508],
              [2711050.609028349164873, 1255032.4529874837026, 802.58125000000291],
              [2711063.899031069129705, 1255024.834241791861132, 802.764999999999418],
              [2711078.459041174035519, 1255020.375509231118485, 803.491250000006403],
              [2711118.830287196207792, 1255014.688000843627378, 803.147500000006403],
              [2711151.119072129018605, 1255015.006811933126301, 805.681249999994179],
              [2711152.706574953626841, 1255014.684316904516891, 805.88749999999709],
              [2711155.77783241821453, 1255013.661830051802099, 806.433749999996508],
              [2711158.642843037843704, 1255012.156848807120696, 807.212499999994179],
              [2711161.227855225559324, 1255010.209370361641049, 808.107499999998254],
              [2711163.464113205205649, 1255007.870634430553764, 808.692500000004657],
              [2711164.434115612879395, 1255006.57313865958713, 808.868749999994179],
              [2711172.761619961820543, 1255010.434396191267297, 809.178750000006403],
              [2711178.709123423788697, 1255009.818152147112414, 809.426250000004075],
              [2711196.970370064955205, 1255020.989395803539082, 809.15625],
              [2711209.784124765079468, 1255022.529403784312308, 809.486250000001746],
              [2711235.162871721666306, 1255020.170647559454665, 809.229999999995925],
              [2711250.93411187781021, 1255019.376879581483081, 808.485000000000582],
              [2711266.66660749912262, 1255022.034371358342469, 808.142500000001746],
              [2711282.636608345899731, 1255027.758122460916638, 808.184999999997672],
              [2711310.352863887324929, 1255038.385631628567353, 808.558749999996508],
              [2711322.867868597619236, 1255040.808139650849625, 808.889999999999418],
              [2711335.67162628332153, 1255039.634402891620994, 809.440000000002328],
              [2711363.50414737733081, 1255033.085689404979348, 810.958750000005239],
              [2711379.494142547249794, 1255027.748180239461362, 810.582500000004075],
              [2711396.536625681445003, 1255021.424399663694203, 809.318750000005821],
              [2711420.057853465899825, 1255015.401859386824071, 807.652499999996508],
              [2711444.771584390662611, 1255010.274324669502676, 806.216249999997672],
              [2711460.870330744422972, 1255008.253067667363212, 805.927500000005239],
              [2711473.321585841942579, 1255009.1593263593968, 806.287500000005821],
              [2711495.645329397637397, 1255015.839314335724339, 805.785000000003492],
              [2711517.012825126759708, 1255019.243056155741215, 805.443750000005821],
              [2711516.319060909561813, 1255023.698030992178246, 804.397500000006403],
              [2711548.689066684804857, 1255024.16179027268663, 804.782500000001164],
              [2711549.654074510093778, 1255019.730554070090875, 805.357499999998254],
              [2711555.952823414001614, 1255019.803051930386573, 805.26875000000291],
              [2711555.964074111077935, 1255017.129303127992898, 805.320000000006985],
              [2711604.899119774345309, 1255009.930632600095123, 808.621249999996508],
              [2711635.84915765048936, 1255001.790698774857447, 811.371249999996508],
              [2711656.56417944887653, 1254993.603236705530435, 812.94999999999709],
              [2711677.346687926910818, 1254979.593250893289223, 813.547500000000582],
              [2711708.947848550509661, 1254947.529340731678531, 806.926250000004075],
              [2711724.14780901838094, 1254929.804269801126793, 803.996249999996508],
              [2711743.410297330934554, 1254906.655498105101287, 803.111250000001746],
              [2711745.659049257170409, 1254898.63800133112818, 803.25],
              [2711776.658997289836407, 1254879.262907834956422, 799.384999999994761],
              [2711797.006447173655033, 1254867.857818081974983, 795.669999999998254],
              [2711819.45515269273892, 1254859.163988319225609, 792.367499999993015],
              [2711851.193919171579182, 1254850.251516473479569, 793.541249999994761],
              [2711873.138928779866546, 1254843.394032760290429, 794.221250000002328],
              [2711894.40015110000968, 1254841.725232970435172, 792.157500000001164],
              [2711933.416351091582328, 1254844.33389309537597, 788.428750000006403],
              [2712004.426266310270876, 1254852.17124066571705, 782.102499999993597],
              [2712021.005003453698009, 1254855.443717408692464, 781.136249999995925],
              [2712041.872399213723838, 1254863.949781969655305, 773.440000000002328],
              [2712028.256188742816448, 1254852.667352329241112, 776.365000000005239],
              [2712025.783687734976411, 1254849.13985056639649, 776.29374999999709],
              [2712058.038675502408296, 1254865.541078165872023, 775.354999999995925],
              [2712072.693676354363561, 1254868.57232928276062, 775.399999999994179],
              [2712084.238675811793655, 1254867.752327978378162, 775.346250000002328],
              [2712095.396176558919251, 1254864.798578919377178, 775.38749999999709],
              [2712109.409924580715597, 1254856.847324880538508, 775.225000000005821],
              [2712130.32741599297151, 1254841.392308793729171, 774.567500000004657],
              [2712137.677411902695894, 1254838.352301270468161, 774.257500000006985],
              [2712145.746159314643592, 1254838.863546459702775, 774.057499999995343],
              [2712154.143655782565475, 1254841.948539990698919, 773.787500000005821],
              [2712163.046151611022651, 1254847.697282407199964, 773.470000000001164],
              [2712206.86113747023046, 1254881.093506555538625, 772.377500000002328],
              [2712235.343614022713155, 1254898.315964395413175, 770.618749999994179],
              [2712254.427360629662871, 1254906.637207939755172, 770.346250000002328],
              [2712274.668609137646854, 1254910.662204764317721, 770.212499999994179],
              [2712300.746112757362425, 1254912.110960446065292, 770.447499999994761],
              [2712332.646119175944477, 1254912.930970906279981, 770.881250000005821],
              [2712388.628626697231084, 1254916.250982665689662, 771.367499999993015],
              [2712432.368627905845642, 1254917.189733538078144, 771.403749999997672],
              [2712571.647377581335604, 1254903.907228684518486, 771.212499999994179],
              [2712586.981123018544167, 1254904.61097016138956, 770.858749999999418],
              [2712602.044869883451611, 1254907.503464197507128, 770.610000000000582],
              [2712632.033611019607633, 1254915.163447723723948, 769.922500000000582],
              [2712706.882342577446252, 1254933.142163122538477, 768.477499999993597],
              [2712706.813590876292437, 1254926.700910010375082, 768.352499999993597],
              [2712704.132339271716774, 1254921.9621571758762, 768.23750000000291],
              [2712704.568577975966036, 1254912.362136991461739, 767.40625],
              [2712702.967315574642271, 1254902.945864912355319, 766.496249999996508],
              [2712698.569805133156478, 1254894.354596402961761, 765.733749999999418],
              [2712691.866046118084341, 1254887.252080508740619, 765.078750000000582],
              [2712667.556019396986812, 1254863.023283480200917, 763.142500000001746],
              [2712664.344765246845782, 1254855.962026111315936, 762.841249999997672],
              [2712664.629755699075758, 1254846.818259041523561, 762.138749999998254],
              [2712668.422248418442905, 1254838.253245893167332, 761.598750000004657],
              [2712674.948496061377227, 1254831.750741428462788, 761.417499999995925],
              [2712737.654732650145888, 1254787.645715172868222, 760.35624999999709],
              [2712735.272233956027776, 1254784.440717516466975, 760.455000000001746],
              [2712737.073486401233822, 1254776.868221681565046, 760.632500000006985],
              [2712715.124774209689349, 1254750.829538933699951, 763.4375],
              [2712690.032253067474812, 1254726.994501829613, 761.912500000005821],
              [2712681.99847713438794, 1254716.275705925887451, 760.014999999999418],
              [2712638.403321866411716, 1254684.006681492552161, 748.649999999994179],
              [2712616.343183507677168, 1254626.901436033193022, 738.501250000001164],
              [2712606.675613556057215, 1254613.400062126573175, 733.368749999994179],
              [2712608.946859412826598, 1254609.976304663112387, 733.061249999998836],
              [2712594.200475894380361, 1254582.311068017501384, 723.259999999994761],
              [2712596.487965355161577, 1254576.757299201097339, 722.482499999998254],
              [2712589.904149437323213, 1254557.462182267103344, 717.642500000001746],
              [2712591.244120016694069, 1254548.242129952413961, 715.477499999993597],
              [2712582.354022186715156, 1254533.730706595350057, 708.29374999999709],
              [2712584.381503145210445, 1254528.173172711860389, 706.891250000000582],
              [2712580.781484221108258, 1254524.583139219786972, 705.503750000003492],
              [2712571.200196395162493, 1254515.031822295626625, 702.732499999998254],
              [2712568.962681963574141, 1254509.839296703925356, 701.673750000001746],
              [2712565.420208938419819, 1254514.666844675317407, 703.661250000004657],
              [2712551.532828674651682, 1254520.780807361938059, 712.48124999999709],
              [2712512.792885124217719, 1254489.759658041177318, 716.67500000000291],
              [2712476.429158804938197, 1254502.662201196420938, 718.457500000004075],
              [2712465.08551310421899, 1254526.004886727314442, 726.141250000000582],
              [2712446.639352029655129, 1254543.4887951312121, 732.702499999999418],
              [2712429.34024554817006, 1254519.710663306061178, 706.503750000003492],
              [2712406.048637947998941, 1254487.611279523465782, 680.227499999993597],
              [2712399.493534048553556, 1254478.57984536816366, 672.592499999998836],
              [2712390.99978494271636, 1254482.727347235195339, 672.667499999995925],
              [2712382.371045814361423, 1254483.096116735832766, 673.476250000006985],
              [2712376.832297863438725, 1254481.084870476508513, 673.632500000006985],
              [2712370.022304306272417, 1254481.364882088033482, 674.113750000004075],
              [2712364.971062986180186, 1254482.346147618023679, 674.757500000006985],
              [2712359.218563685193658, 1254477.938648946816102, 674.815000000002328],
              [2712354.983572836965322, 1254474.421165230451152, 675.492499999993015],
              [2712345.281074836384505, 1254478.419919082662091, 675.649999999994179],
              [2712338.458575844299048, 1254480.503671067766845, 675.73124999999709],
              [2712331.507335701491684, 1254478.569938699482009, 676.463749999995343],
              [2712326.957340177148581, 1254476.172446716343984, 676.797500000000582],
              [2712326.202333722729236, 1254475.774935295339674, 676.323749999995925],
              [2712323.762335950508714, 1254474.624939291039482, 676.490000000005239],
              [2712310.587338869459927, 1254476.259944825666025, 676.71875],
              [2712303.96859152475372, 1254476.412449711933732, 676.921249999999418],
              [2712291.414845747407526, 1254476.703707528533414, 677.244999999995343],
              [2712282.96984751150012, 1254477.064960878109559, 677.383749999993597],
              [2712277.604850985575467, 1254469.758717079181224, 677.645000000004075],
              [2712275.336102265398949, 1254464.078719328623265, 677.741250000006403],
              [2712266.83235528646037, 1254464.228724903892726, 677.972500000003492],
              [2712256.529856528155506, 1254467.002477417932823, 678.07499999999709],
              [2712255.443606750573963, 1254466.837477835826576, 678.092499999998836],
              [2712246.98861019872129, 1254465.55373414978385, 678.354999999995925],
              [2712234.744861819781363, 1254469.174987392500043, 678.48750000000291],
              [2712222.518615243490785, 1254473.486243841471151, 678.752500000002328],
              [2712219.531117221340537, 1254473.804997431579977, 678.901249999995343],
              [2712213.074870341923088, 1254474.495003136573359, 679.13749999999709],
              [2712198.288622809108347, 1254476.135007923934609, 679.335000000006403],
              [2712192.492376531474292, 1254486.360014812089503, 679.615000000005239],
              [2712188.649876966141164, 1254490.200015734648332, 679.651249999995343],
              [2712180.562380397226661, 1254492.485022067092359, 679.912500000005821],
              [2712169.441136370413005, 1254489.393782898783684, 680.363750000004075],
              [2712163.254888593219221, 1254491.218787031713873, 680.533750000002328],
              [2712159.682392647489905, 1254502.687544477637857, 680.836249999993015],
              [2712158.487399068195373, 1254506.407555937767029, 681.309999999997672],
              [2712158.261150321457535, 1254506.840058176312596, 681.402499999996508],
              [2712154.619904395192862, 1254513.820065590087324, 681.70625000000291],
              [2712145.776157713960856, 1254519.322571777040139, 681.960000000006403],
              [2712136.242411246988922, 1254522.335078330943361, 682.229999999995925],
              [2712127.714915177319199, 1254529.887585633201525, 682.528749999997672],
              [2712119.016171287745237, 1254533.551346746040508, 682.98750000000291],
              [2712109.892428133171052, 1254542.797609248431399, 683.501250000001164],
              [2712104.379940282087773, 1254552.10513106896542, 684.401249999995343],
              [2712103.493689130526036, 1254552.6651290578302, 684.317500000004657],
              [2712093.76244243606925, 1254558.820135256275535, 684.571249999993597],
              [2712076.262449348345399, 1254563.670148047618568, 685.098750000004657],
              [2712057.841212107799947, 1254563.251421158667654, 686.057499999995343],
              [2712043.31746887601912, 1254569.050183617509902, 686.571249999993597],
              [2712037.467472814023495, 1254570.03144077770412, 686.867499999993015],
              [2712033.05747422715649, 1254572.040193423395976, 686.976250000006985],
              [2712028.552475928328931, 1254574.09019659133628, 687.10624999999709],
              [2712026.449977171607316, 1254575.672698868438601, 687.19999999999709],
              [2712025.732478994410485, 1254576.212702132994309, 687.335000000006403],
              [2712013.939993153791875, 1254587.240227714646608, 688.389999999999418],
              [2712003.667503225617111, 1254593.41774593363516, 689.142500000001746],
              [2712003.291256956290454, 1254600.316502655157819, 689.417499999995925],
              [2712000.425007846672088, 1254603.076504355063662, 689.486250000001746],
              [2711995.117514428682625, 1254605.779016202781349, 689.976250000006985],
              [2711985.263786820229143, 1254609.309056240366772, 691.634999999994761],
              [2711983.826295426115394, 1254614.995321622816846, 692.270000000004075],
              [2711969.500061924103647, 1254620.742851357907057, 693.5],
              [2711960.437564371153712, 1254623.706605971092358, 693.690000000002328],
              [2711943.172579302452505, 1254624.39288292825222, 694.807499999995343],
              [2711936.550082073081285, 1254623.284138008020818, 695.01875000000291],
              [2711919.518839427735656, 1254630.524151610210538, 695.578750000000582],
              [2711910.776341046206653, 1254634.674154773587361, 695.707500000004075],
              [2711891.532601020298898, 1254635.385422987630591, 696.462499999994179],
              [2711876.896368091460317, 1254632.815453636692837, 697.735000000000582],
              [2711864.208875732962042, 1254630.147967494558543, 698.311249999998836],
              [2711857.793878712691367, 1254627.380472910124809, 698.537500000005821],
              [2711851.668881423771381, 1254627.696727894246578, 698.743749999994179],
              [2711848.573882970493287, 1254629.849230758147314, 698.861250000001746],
              [2711845.892634137999266, 1254631.712982918601483, 698.94999999999709],
              [2711833.096394373103976, 1254631.623001428088173, 699.717499999998836],
              [2711813.250173536129296, 1254637.138053787173703, 701.886249999995925],
              [2711810.872675557620823, 1254637.040557435713708, 702.037500000005821],
              [2711801.977678447961807, 1254636.671812791842967, 702.259999999994761],
              [2711785.587688170839101, 1254632.629330431576818, 702.993749999994179],
              [2711778.47644974431023, 1254633.150601176777855, 703.853749999994761],
              [2711773.890206382609904, 1254633.485613081604242, 704.347500000003492],
              [2711769.657713995780796, 1254635.150626728544012, 704.912500000005821],
              [2711763.315216735936701, 1254631.173131706193089, 705.121249999996508],
              [2711754.902728410437703, 1254625.596902574645355, 705.990000000005239],
              [2711743.162734720855951, 1254628.891914136474952, 706.467499999998836],
              [2711735.88273528823629, 1254633.321915407897905, 706.517500000001746],
              [2711735.665235253516585, 1254633.103165342006832, 706.514999999999418],
              [2711727.687740023247898, 1254625.056923915166408, 706.875],
              [2711722.222743439953774, 1254620.174430057872087, 707.132500000006985],
              [2711713.196518741548061, 1254614.775725128827617, 709.005000000004657],
              [2711702.44036818575114, 1254616.409651935100555, 716.338749999995343],
              [2711699.826629538554698, 1254615.612172149354592, 717.177500000005239],
              [2711698.919133397750556, 1254611.819678969914094, 717.462499999994179],
              [2711699.714135905262083, 1254607.097183327423409, 717.646250000005239],
              [2711698.815386333502829, 1254605.399684086441994, 717.678750000006403],
              [2711698.481636975426227, 1254604.767185226781294, 717.726250000006985],
              [2711690.062894925940782, 1254603.278449553530663, 718.321249999993597],
              [2711686.311655242927372, 1254602.398467950290069, 719.085000000006403],
              [2711675.924161995295435, 1254601.050980198895559, 719.59375],
              [2711669.611666770651937, 1254602.738488873001188, 719.952499999999418],
              [2711664.507921072188765, 1254598.465996591839939, 720.274999999994179],
              [2711663.422924964688718, 1254592.652253444539383, 720.5625],
              [2711651.282935779541731, 1254591.469772954471409, 721.372499999997672],
              [2711646.424196707084775, 1254584.937292385846376, 722.182499999995343],
              [2711639.307948906905949, 1254580.902296433690935, 722.352499999993597],
              [2711638.427948776632547, 1254578.246046191314235, 722.34375],
              [2711635.112954394891858, 1254576.326056228019297, 722.761249999995925],
              [2711632.545454338658601, 1254579.122306240256876, 722.759999999994761],
              [2711624.972956292331219, 1254578.081059898249805, 722.912500000005821],
              [2711619.650464805308729, 1254566.884824997046962, 723.544999999998254],
              [2711611.497984643559903, 1254565.589860409032553, 725.014999999999418],
              [2711608.705505701247603, 1254563.43114784010686, 726.568750000005821],
              [2711566.053131706546992, 1254611.763873402727768, 735.897500000006403],
              [2711526.166948880534619, 1254646.981494289590046, 740.891250000000582],
              [2711505.788236057385802, 1254629.602810617536306, 743.652499999996508],
              [2711406.297038266900927, 1254570.255405254196376, 747.61250000000291],
              [2711367.252080213278532, 1254552.869230591924861, 750.747499999997672],
              [2711350.860786996781826, 1254525.306653913110495, 747.582500000004075],
              [2711323.84449536120519, 1254489.716580223990604, 744.546249999999418],
              [2711320.014495067298412, 1254482.661579701351002, 744.528749999997672],
              [2711314.335734711494297, 1254481.821561464341357, 743.772500000006403],
              [2711311.068235527724028, 1254479.510312969563529, 743.836249999993015],
              [2711309.433241695165634, 1254474.365323896287009, 744.292499999995925],
              [2711299.964493175037205, 1254472.075326760299504, 744.412500000005821],
              [2711297.740745348390192, 1254467.231580599676818, 744.57499999999709],
              [2711294.347007781732827, 1254468.109102792805061, 745.494999999995343],
              [2711282.477014533244073, 1254465.886615088908002, 746.006250000005821],
              [2711275.258272598031908, 1254460.616629537194967, 746.608749999999418],
              [2711273.999522426631302, 1254460.256629260722548, 746.597500000003492],
              [2711196.082480825483799, 1254596.872447690693662, 780.467499999998836],
              [2711194.209980997722596, 1254601.533698122482747, 780.482499999998254],
              [2711207.534979094751179, 1254606.812444434035569, 780.326249999998254],
              [2711181.899047984741628, 1254669.390512602636591, 803.85624999999709],
              [2711168.204255444463342, 1254705.139632111648098, 819.161250000004657],
              [2711148.551816494669765, 1254703.044741143472493, 823.684999999997672],
              [2711096.068221781402826, 1254705.602518702391535, 835.196249999993597],
              [2711077.289497386198491, 1254704.648814766667783, 837.107499999998254],
              [2711068.398263106588274, 1254699.740092897089198, 838.277499999996508],
              [2711059.163271597120911, 1254689.852608104934916, 838.914999999993597],
              [2711026.927053968422115, 1254663.90891621937044, 841.342499999998836],
              [2710981.34077163040638, 1254619.1851094677113, 839.017500000001746],
              [2710959.759523767512292, 1254584.655113382264972, 839.202499999999418],
              [2710956.864526161924005, 1254574.857617562403902, 839.382500000006985],
              [2710954.58201854582876, 1254563.581353917019442, 838.823749999995925],
              [2710937.09447620715946, 1254531.075028708670288, 835.725000000005821],
              [2710927.328195957001299, 1254518.367475062143058, 833.507500000006985],
              [2710911.761895343661308, 1254497.388635280076414, 829.796249999999418],
              [2710907.63063370808959, 1254488.124864594778046, 828.943750000005821],
              [2710889.814387199468911, 1254471.499871083535254, 829.223750000004657],
              [2710880.291895379312336, 1254468.123635847121477, 829.838749999995343],
              [2710863.899408333934844, 1254463.559909314848483, 830.815000000002328],
              [2710850.099399785976857, 1254455.163644413230941, 830.202499999999418],
              [2710840.265641277190298, 1254442.88487940793857, 829.587499999994179],
              [2710832.1006342722103, 1254429.467366996454075, 829.08125000000291],
              [2710830.223119983449578, 1254413.968591414624825, 828.029999999998836],
              [2710832.209330554120243, 1254394.903520977590233, 825.119999999995343],
              [2710831.131758037023246, 1254329.603391099022701, 819.773749999993015],
              [2710827.730540212243795, 1254317.868448186665773, 822.149999999994179],
              [2710828.315543767996132, 1254313.172204409260303, 822.411250000004657],
              [2710856.490562730934471, 1254286.443486782489344, 823.772500000006403],
              [2710900.994344786740839, 1254237.894791552331299, 826.077499999999418],
              [2710908.633100227452815, 1254231.032300864346325, 826.46875],
              [2710917.276850058697164, 1254225.391050201607868, 826.445000000006985],
              [2710932.168088009115309, 1254216.692278193775564, 825.537500000005821],
              [2710947.41682301601395, 1254208.946000955067575, 824.412500000005821],
              [2710970.43556882115081, 1254197.582242607604712, 824.073749999995925],
              [2710986.299324565567076, 1254181.738502049585804, 824.476250000006985],
              [2711007.200589169282466, 1254193.592277533607557, 825.526249999995343],
              [2711082.19946715189144, 1254166.887502036755905, 834.861250000001746],
              [2711113.549507078249007, 1254153.318821713794023, 837.76249999999709],
              [2711183.354644266422838, 1254128.100312667200342, 847.779999999998836],
              [2711203.903439794667065, 1254115.746642638463527, 851.107499999998254],
              [2711239.012218680232763, 1254088.021692326525226, 853.188750000001164],
              [2711241.195968023966998, 1254086.896691068075597, 853.13749999999709],
              [2711257.73590721283108, 1254078.372832433087751, 848.634999999994761],
              [2711283.019555972423404, 1254068.511401740834117, 841.142500000001746],
              [2711310.874486005865037, 1254059.251276498660445, 835.951249999998254],
              [2711341.313223802950233, 1254042.998753612395376, 835.01249999999709],
              [2711357.735719002317637, 1254038.388744497671723, 834.63749999999709],
              [2711361.989359267055988, 1254006.839799073524773, 826.547500000000582],
              [2711368.20415175659582, 1253977.185680091613904, 811.252500000002328],
              [2711368.358983436133713, 1253958.970381058286875, 798.852499999993597],
              [2711358.447729876730591, 1253957.244125011609867, 798.602499999993597],
              [2711348.640226232353598, 1253955.005368808750063, 798.346250000002328],
              [2711338.961471742019057, 1253952.261611090041697, 798.027499999996508],
              [2711334.355219799093902, 1253950.681607753038406, 797.889999999999418],
              [2711329.821467499248683, 1253948.900353777687997, 797.726250000006985],
              [2711325.372714963741601, 1253946.92159938509576, 797.544999999998254],
              [2711321.015212805941701, 1253944.747845652280375, 797.391250000000582],
              [2711316.757711207959801, 1253942.382842906983569, 797.278749999997672],
              [2711312.608958678785712, 1253939.832838500384241, 797.097500000003492],
              [2711306.812705731019378, 1253935.835333385039121, 796.88749999999709],
              [2711301.232703671790659, 1253931.540329823736101, 796.742499999993015],
              [2711295.886454769875854, 1253926.95908186188899, 796.830000000001746],
              [2711290.786455769557506, 1253922.10533371893689, 796.910000000003492],
              [2711285.947707066778094, 1253916.990336081478745, 797.011249999995925],
              [2711281.383960432838649, 1253911.629092116607353, 797.264999999999418],
              [2711263.947726198006421, 1253889.6241202771198, 798.447499999994761],
              [2711256.401481264736503, 1253879.849129340378568, 798.830000000001746],
              [2711248.248980552889407, 1253870.572878177976236, 798.787500000005821],
              [2711243.955226529855281, 1253866.134121095761657, 798.496249999996508],
              [2711235.035228296648711, 1253857.532874364638701, 798.63749999999709],
              [2711230.415230662561953, 1253853.372878642054275, 798.817500000004657],
              [2711220.795237070415169, 1253845.500390181550756, 799.301250000004075],
              [2711210.700245059560984, 1253838.246654559858143, 799.902499999996508],
              [2711193.116504776291549, 1253827.422922160709277, 800.639999999999418],
              [2711196.062748770229518, 1253822.76916134567, 800.193750000005821],
              [2711183.98149715969339, 1253813.94915870972909, 800.089999999996508],
              [2711167.823996180668473, 1253796.057907180394977, 800.037500000005821],
              [2711156.906474134884775, 1253779.72786811622791, 798.426250000004075],
              [2711147.345197955612093, 1253763.077821673126891, 796.508749999993597],
              [2711145.546435935888439, 1253758.265300311380997, 795.625],
              [2711172.671408266294748, 1253756.121500346809626, 793.552500000005239],
              [2711172.61885324632749, 1253721.932652146555483, 789.497499999997672],
              [2711163.123864624649286, 1253713.497672499390319, 790.347500000003492],
              [2711159.31136181904003, 1253693.772667324636132, 790.145000000004075],
              [2711140.438899718225002, 1253695.585235191509128, 792.961249999993015],
              [2711140.050150904338807, 1253685.225237139966339, 793.048750000001746],
              [2711147.298911229241639, 1253659.982754850527272, 793.80000000000291],
              [2711151.093919046223164, 1253649.141518449410796, 794.371249999996508],
              [2711144.212672699708492, 1253646.282775090541691, 794.648749999993015],
              [2711147.126428005751222, 1253639.805284323170781, 795.036250000004657],
              [2711149.98893246660009, 1253634.264042062684894, 795.361250000001746],
              [2711152.753937095403671, 1253629.661550125805661, 795.698749999995925],
              [2711157.463944590184838, 1253623.130313184810802, 796.244999999995343],
              [2711153.217693279962987, 1253625.191561021609232, 796.153749999997672],
              [2711148.243948117364198, 1253623.775319730862975, 796.516250000000582],
              [2711142.218962060287595, 1253618.617844585329294, 797.551250000004075],
              [2711139.280218916945159, 1253614.576606775866821, 798.059999999997672],
              [2711133.287731948308647, 1253605.442879946203902, 799.027499999996508],
              [2711119.660264611709863, 1253589.746688085841015, 801.451249999998254],
              [2711105.246533913072199, 1253571.757972497260198, 802.891250000000582],
              [2711120.217890444677323, 1253547.349410733440891, 810.722500000003492],
              [2711130.382964905817062, 1253518.503292121225968, 816.196249999993597],
              [2711149.332522628828883, 1253402.395004781428725, 783.578750000000582],
              [2711152.836279998533428, 1253401.991267751436681, 784.117499999993015],
              [2711154.547532554250211, 1253401.557522232178599, 784.303750000006403],
              [2711157.82003125268966, 1253400.241269802441821, 784.203750000000582],
              [2711159.355031651910394, 1253399.370020449860021, 784.23124999999709],
              [2711162.16378498962149, 1253397.23627625242807, 784.473750000004657],
              [2711163.413787996396422, 1253395.990031534805894, 784.693750000005821],
              [2711165.558795801829547, 1253393.188795275054872, 785.266250000000582],
              [2711166.435047291684896, 1253391.65629787161015, 785.375],
              [2711167.762545856181532, 1253388.388795237056911, 785.267500000001746],
              [2711168.202550411690027, 1253386.678803281858563, 785.602499999993597],
              [2711168.618805781472474, 1253383.176312740426511, 785.997499999997672],
              [2711168.591307678259909, 1253381.411316083045676, 786.13749999999709],
              [2711167.426327070221305, 1253367.078850305173546, 787.567500000004657],
              [2711167.397580770310014, 1253364.073856824543327, 787.839999999996508],
              [2711167.670085831545293, 1253361.080115748569369, 788.212499999994179],
              [2711168.240090914536268, 1253358.128874706104398, 788.586249999993015],
              [2711169.102597271557897, 1253355.250135918380693, 789.053750000006403],
              [2711170.250105078332126, 1253352.471399695845321, 789.627500000002328],
              [2711171.668861429672688, 1253349.821410882752389, 790.09375],
              [2711172.742615333292633, 1253348.200167749309912, 790.380000000004657],
              [2711175.288872467819601, 1253345.265180283226073, 790.902499999996508],
              [2711178.301380864810199, 1253342.81144505715929, 791.517500000001746],
              [2711182.701391323469579, 1253340.06646343995817, 792.282500000001164],
              [2711185.262647081166506, 1253338.078973550116643, 792.703750000000582],
              [2711187.613903228659183, 1253335.84648435562849, 793.153749999997672],
              [2711189.730158711317927, 1253333.390243974747136, 793.554999999993015],
              [2711191.590164493769407, 1253330.735254140337929, 793.978749999994761],
              [2711195.45893346099183, 1253323.760287563316524, 795.371249999996508],
              [2711198.075192885007709, 1253319.814054148737341, 796.0625],
              [2711201.005201943218708, 1253316.096570081776008, 796.726250000006985],
              [2711206.226464214734733, 1253310.007841601967812, 797.623749999998836],
              [2711208.833971912506968, 1253306.415355115896091, 798.1875],
              [2711210.830227714963257, 1253302.449115287978202, 798.61250000000291],
              [2711212.162735881749541, 1253298.21412967890501, 799.212499999994179],
              [2711212.567740552127361, 1253296.030387914739549, 799.556249999994179],
              [2711214.475272045005113, 1253276.602943426230922, 801.873749999998836],
              [2711215.312774755526334, 1253275.432948203291744, 802.072499999994761],
              [2711215.929025768302381, 1253275.051699962234125, 802.146250000005239],
              [2711216.865277583245188, 1253274.817953150486574, 802.278749999997672],
              [2711217.824029277544469, 1253274.921706134686247, 802.402499999996508],
              [2711218.487780103459954, 1253275.215457584243268, 802.462499999994179],
              [2711219.050280859228224, 1253275.671708913519979, 802.517500000001746],
              [2711219.580281988717616, 1253276.47796091157943, 802.600000000005821],
              [2711222.559025477617979, 1253256.111698950175196, 802.116250000006403],
              [2711224.696525346953422, 1253244.840448473813012, 802.103749999994761],
              [2711228.235277701169252, 1253231.736702329711989, 802.272500000006403],
              [2711234.442789670545608, 1253212.690473086666316, 803.146250000005239],
              [2711246.931574097834527, 1253180.533033261075616, 805.666249999994761],
              [2711252.140341918449849, 1253164.504314464749768, 806.972500000003492],
              [2711254.980358664877713, 1253151.305593875236809, 808.202499999999418],
              [2711256.665374695323408, 1253138.028122049290687, 809.381250000005821],
              [2711257.9591438123025, 1253120.520655634813011, 810.787500000005821],
              [2711259.729157129768282, 1253107.4081789937336, 811.766250000000582],
              [2711262.84416897688061, 1253096.065699736587703, 812.634999999994761],
              [2711267.359182274434716, 1253084.369472996797413, 813.608749999999418],
              [2711274.189176807180047, 1253087.420713143888861, 813.197499999994761],
              [2711281.346695498097688, 1253074.019495858810842, 814.565000000002328],
              [2711287.611708333250135, 1253066.403268306981772, 815.502500000002328],
              [2711302.039235783275217, 1253050.24456629017368, 817.506250000005821],
              [2711309.759246726054698, 1253040.530835309065878, 818.302500000005239],
              [2711316.534261559136212, 1253030.013361235847697, 819.386249999995925],
              [2711321.489273506216705, 1253020.03963211667724, 820.259999999994761],
              [2711324.668045402038842, 1253009.68592067877762, 821.868749999994179],
              [2711326.169306340627372, 1252997.992189845070243, 822.672500000000582],
              [2711325.850565910339355, 1252986.750956640113145, 823.377500000002328],
              [2711323.541826936416328, 1252975.648476087255403, 824.192500000004657],
              [2711319.021837381180376, 1252964.733494570944458, 824.967499999998836],
              [2711270.364406725857407, 1252887.491117822937667, 830.13749999999709],
              [2711260.379419043194503, 1252871.282389721134678, 831.057499999995343],
              [2711251.625692917965353, 1252854.681182066677138, 832.827499999999418],
              [2711244.045709264464676, 1252837.384961012285203, 834.041249999994761],
              [2711237.27446866594255, 1252819.643727609654889, 834.742499999993015],
              [2711234.280726456083357, 1252809.471241347724572, 835.320000000006985],
              [2711231.973232745192945, 1252799.297502414090559, 835.786250000004657],
              [2711230.169492962770164, 1252789.002520417794585, 836.541249999994761],
              [2711229.439500772859901, 1252778.768784127896652, 837.117499999993015],
              [2711228.12580464920029, 1252718.530128720682114, 841.087499999994179],
              [2711227.243308096192777, 1252710.733884733403102, 841.342499999998836],
              [2711225.364561712369323, 1252702.863891075830907, 841.611250000001746],
              [2711222.235813304781914, 1252695.566393877379596, 841.732499999998254],
              [2711217.929566082078964, 1252688.618898820132017, 841.942500000004657],
              [2711212.562068194150925, 1252682.523902644170448, 842.104999999995925],
              [2711225.062042326666415, 1252673.721356216818094, 840.182499999995343],
              [2711226.464538094587624, 1252668.411348578287289, 839.868749999994179],
              [2711239.574398014228791, 1252643.761099341325462, 829.529999999998836],
              [2711249.974043407011777, 1252597.490469455253333, 803.388749999998254],
              [2711254.853804378770292, 1252532.021294426172972, 785.770000000004075],
              [2711256.782375059556216, 1252479.270975601160899, 772.554999999993015],
              [2711257.606115675996989, 1252474.137208863859996, 771.86250000000291],
              [2711252.189803429879248, 1252462.473348472733051, 767.282500000001164],
              [2711250.828476214781404, 1252451.160711435601115, 761.595000000001164],
              [2711253.549666917417198, 1252435.058105980511755, 757.222500000003492],
              [2711249.259608729742467, 1252417.866752670146525, 752.940000000002328],
              [2711242.633327782619745, 1252402.041697760578245, 750.667499999995925],
              [2711240.810796100646257, 1252390.495391470380127, 748.335000000006403],
              [2711243.533260756637901, 1252381.266578583279625, 745.727499999993597],
              [2711240.095751847140491, 1252376.546562817180529, 745.07499999999709],
              [2711238.450739099178463, 1252370.596540171187371, 744.13749999999709],
              [2711230.87821495719254, 1252363.845247492659837, 742.367499999993015],
              [2711225.338211207184941, 1252361.058990952791646, 742.097500000003492],
              [2711209.78820142801851, 1252359.006474026013166, 741.395000000004075],
              [2711202.080684582237154, 1252352.556444280315191, 740.162500000005821],
              [2711199.039441032335162, 1252345.127705692313612, 740.641250000000582],
              [2711194.016922567039728, 1252343.666423067450523, 739.286250000004657],
              [2711188.188177173491567, 1252337.191431308630854, 739.632500000006985],
              [2711183.026905092410743, 1252332.756392234703526, 738.011249999995925],
              [2711170.891880543902516, 1252333.563849057769403, 736.216249999997672],
              [2711149.776844235602766, 1252323.373785123927519, 733.565000000002328],
              [2711144.700573195703328, 1252311.276247778441757, 732.020000000004075],
              [2711134.7367625371553, 1252294.911140261916444, 727.561249999998836],
              [2711122.980494135990739, 1252295.803607980022207, 726.21875],
              [2711102.659218233078718, 1252274.131062295986339, 724.332500000004075],
              [2711099.467938131652772, 1252260.401008806191385, 722.117499999993015],
              [2711082.612892626784742, 1252231.172178162261844, 718.782500000001164],
              [2711079.942852274514735, 1252216.857106478419155, 715.811249999998836],
              [2711074.701586285140365, 1252210.868328183190897, 714.638749999998254],
              [2711067.206544753629714, 1252199.655754584120587, 711.586249999993015],
              [2711060.525264094118029, 1252185.604450196027756, 709.333750000005239],
              [2711051.797750197350979, 1252164.963175502140075, 708.318750000005821],
              [2711043.587721400428563, 1252136.895624266704544, 706.205000000001746],
              [2711016.603928737808019, 1252108.733048948692158, 703.089999999996508],
              [2711010.182662908919156, 1252086.299270743038505, 701.929999999993015],
              [2711011.322649128269404, 1252061.849245935911313, 700.912500000005821],
              [2711019.977626945823431, 1252039.045456046005711, 699.267500000001746],
              [2711018.658860156312585, 1252019.931676048086956, 698.03125],
              [2711015.643853736110032, 1252012.940414650598541, 697.561249999998836],
              [2711015.87509178603068, 1251998.285393247613683, 696.679999999993015],
              [2711009.301339388824999, 1251986.755389011697844, 696.509999999994761],
              [2711011.477586403489113, 1251980.755383574636653, 696.287500000005821],
              [2711008.387585325632244, 1251974.089131652377546, 696.211249999993015],
              [2711008.423829154577106, 1251963.236620564479381, 695.756250000005821],
              [2710991.216313254553825, 1251936.850342462770641, 694.602499999993597],
              [2710972.7225101897493, 1251920.843998648924753, 690.711249999993015],
              [2710955.281247538980097, 1251911.470226546283811, 689.797500000000582],
              [2710946.079990982078016, 1251901.216465016361326, 689.323749999995925],
              [2710943.072481495328248, 1251888.418948102975264, 688.627500000002328],
              [2710932.416219932027161, 1251868.190177598968148, 687.786250000004657],
              [2710920.869952368084341, 1251853.21514655649662, 686.503750000003492],
              [2710914.818695277906954, 1251849.09013409097679, 685.98750000000291],
              [2710903.882420788984746, 1251829.783840686315671, 684.193750000005821],
              [2710901.023627798072994, 1251826.523764486191794, 681.027499999996508],
              [2710888.402367643546313, 1251815.981246676994488, 680.292499999995925],
              [2710874.001099554821849, 1251795.2974646857474, 678.973750000004657],
              [2710872.492342243902385, 1251789.026201677275822, 678.436249999998836],
              [2710862.102339178789407, 1251783.078696431359276, 678.221250000002328],
              [2710851.003584238234907, 1251769.763687777565792, 677.868749999994179],
              [2710833.094828932546079, 1251757.201178665040061, 677.496249999996508],
              [2710816.319820454344153, 1251736.652413794305176, 676.888749999998254],
              [2710799.821065932512283, 1251725.959906049538404, 676.572499999994761],
              [2710792.931080584414303, 1251721.029932143166661, 677.660000000003492],
              [2710784.136045450344682, 1251702.919869827106595, 675.078750000000582],
              [2710772.527288539335132, 1251687.444857665803283, 674.58125000000291],
              [2710760.317281998693943, 1251666.669846094911918, 674.111250000001746],
              [2710755.484765387605876, 1251649.643566532991827, 672.891250000000582],
              [2710749.468510555103421, 1251636.848557958612218, 672.541249999994761],
              [2710744.002260345034301, 1251626.88605758571066, 672.53125],
              [2710739.668500812258571, 1251612.772290599066764, 671.832500000004075],
              [2710722.127240869682282, 1251587.5247730857227, 671.117499999993015],
              [2710714.072237501852214, 1251580.279767218511552, 670.877500000002328],
              [2710703.647233016323298, 1251567.522259368561208, 670.557499999995343],
              [2710696.497248800937086, 1251563.038537475746125, 671.728749999994761],
              [2710692.438479265198112, 1251563.154752939008176, 670.292499999995925],
              [2710689.525994615629315, 1251558.97478018165566, 671.427500000005239],
              [2710684.563493259716779, 1251553.47977783344686, 671.332500000004075],
              [2710671.435969193000346, 1251528.059735155431554, 669.571249999993597],
              [2710662.977194078732282, 1251500.718440464232117, 667.727499999993597],
              [2710655.692190976813436, 1251492.629685037769377, 667.506250000005821],
              [2710646.03717531543225, 1251478.22090732306242, 666.361250000001746],
              [2710647.062177821528167, 1251474.662161701824516, 666.544999999998254],
              [2710641.113398540765047, 1251449.323359581874683, 664.391250000000582],
              [2710642.737145327497274, 1251437.559603694127873, 664.152499999996508],
              [2710640.41839065682143, 1251422.224595255684108, 663.809999999997672],
              [2710640.749643441289663, 1251412.493350068572909, 664.014999999999418],
              [2710635.138375757727772, 1251393.269568597897887, 662.716249999997672],
              [2710636.244618286378682, 1251373.144555052276701, 662.163750000006985],
              [2710638.888372071553022, 1251369.259561641141772, 662.440000000002328],
              [2710636.138369104359299, 1251356.27330628153868, 662.223750000004657],
              [2710637.189580173231661, 1251329.774486877955496, 659.351250000006985],
              [2710637.054581887554377, 1251323.894489834085107, 659.477499999993597],
              [2710632.582073030527681, 1251312.544474092777818, 658.828750000000582],
              [2710640.300811911001801, 1251276.410703707486391, 658.0],
              [2710639.832058519590646, 1251260.278197492705658, 657.75],
              [2710634.012063879985362, 1251254.555707070743665, 658.151249999995343],
              [2710631.173314907588065, 1251253.793208952527493, 658.229999999995925],
              [2710622.857057350222021, 1251250.735695722978562, 657.681249999994179],
              [2710594.14203863311559, 1251223.290662895655259, 656.330000000001746],
              [2710592.207028811331838, 1251219.008145470404997, 655.607499999998254],
              [2710595.465798485092819, 1251215.994430236751214, 657.054999999993015],
              [2710591.168263239786029, 1251208.786867754999548, 654.460000000006403],
              [2710587.768266275990754, 1251204.855623174691573, 654.6875],
              [2710583.66951998276636, 1251203.448129830881953, 654.964999999996508],
              [2710580.905764094553888, 1251203.521869462681934, 654.533750000002328],
              [2710576.362005082890391, 1251203.379353597061709, 653.873749999998836],
              [2710575.014492097776383, 1251203.775580611545593, 652.917499999995925],
              [2710575.518261206336319, 1251205.009364494122565, 654.326249999998254],
              [2709612.031651888974011, 1251420.727503397269174, 628.787500000005821],
              [2709512.656758983619511, 1251394.521445694379508, 636.796249999999418],
              [2709453.182844636030495, 1251480.23240629513748, 624.717499999998836],
              [2709393.87178774503991, 1251480.921500811586156, 639.042499999995925],
              [2709372.72409434709698, 1251568.876158833503723, 624.777499999996508],
              [2709294.181765978224576, 1251598.59146609576419, 637.537500000005821],
              [2709295.335371498018503, 1251626.713709667092189, 626.861250000001746],
              [2709248.34800091991201, 1251675.118941434193403, 636.472500000003492],
              [2709060.475106251426041, 1252132.442000006325543, 607.5],
              [2709044.611353235784918, 1252139.780745114199817, 607.292499999995925],
              [2709026.802611037623137, 1252136.097009357763454, 607.886249999995925],
              [2708811.131295822095126, 1252050.178147359751165, 603.270000000004075],
              [2708743.870130323804915, 1251960.734548170352355, 609.58125000000291],
              [2708688.486242771148682, 1251929.906804372556508, 599.461249999993015],
              [2708683.991359649226069, 1251912.43201220780611, 608.108749999999418],
              [2708626.241234426852316, 1251904.246790654491633, 598.902499999996508],
              [2708600.421257701702416, 1251910.778082742821425, 600.648749999993015],
              [2706686.590821892023087, 1252405.432355427416041, 570.162500000005821],
              [2707612.091984232421964, 1252681.029139288933948, 748.022500000006403],
              [2708058.834593073930591, 1253063.654598262161016, 663.029999999998836],
              [2708053.805847510695457, 1253070.730856390902773, 663.363750000004075],
              [2707804.048131710849702, 1253087.281728205736727, 647.645000000004075],
              [2707886.725565196014941, 1253320.958839531987906, 735.197499999994761],
              [2707839.888040809892118, 1253346.257547728484496, 733.446249999993597],
              [2707757.408065157942474, 1253308.161342908162624, 735.34375],
              [2707599.58298436878249, 1253359.147453976096585, 729.542499999995925],
              [2707323.673206041101366, 1253319.081606729421765, 746.286250000004657],
              [2706670.771162745542824, 1253263.555474561406299, 595.479999999995925],
              [2706660.991149528417736, 1253268.556701233610511, 594.508749999993597],
              [2706689.19177819089964, 1253359.949074730742723, 641.116250000006403],
              [2706644.55836479132995, 1253720.907448200741783, 573.412500000005821],
              [2706727.95592489419505, 1253709.245053596794605, 577.79374999999709],
              [2706456.756495069246739, 1253949.804831917164847, 620.357499999998254],
              [2706346.176922951824963, 1253945.315864454489201, 559.453750000000582],
              [2706381.212211058940738, 1253933.952628707746044, 580.802500000005239],
              [2706366.801941583864391, 1253866.283396420767531, 560.816250000003492],
              [2706255.809409049805254, 1253823.792090191040188, 558.5],
              [2706194.318193421699107, 1253760.292152282781899, 561.104999999995925],
              [2705894.23056048899889, 1254434.383178583811969, 551.517500000001746],
              [2705825.990453653503209, 1255305.016748421825469, 543.662500000005821],
              [2705052.822861928958446, 1255326.944100680062547, 537.517500000001746],
              [2705013.892862020991743, 1255308.314101458759978, 537.557499999995343],
              [2705172.039952428080142, 1255879.405354524496943, 692.845000000001164],
              [2705422.219000663142651, 1256101.199893780518323, 621.842499999998836],
              [2706236.429898464120924, 1257097.561237171525136, 780.48750000000291],
              [2706450.226265638135374, 1256960.298938625026494, 788.917499999995925],
              [2706756.471017095725983, 1256914.414733473677188, 770.102499999993597],
              [2706824.737578366883099, 1257185.266543196281418, 793.111250000001746],
              [2707205.367229536641389, 1257430.863410860998556, 766.787500000005821],
              [2707266.22148274211213, 1257449.980560376308858, 804.009999999994761],
              [2707196.933891849126667, 1256450.887160598533228, 612.033750000002328],
              [2708203.395424266345799, 1256778.829879323253408, 724.477499999993597],
              [2708629.169571207370609, 1256283.629050621530041, 845.798750000001746],
              [2708929.192354110069573, 1256758.43884104420431, 681.5],
              [2709060.275784280151129, 1257268.424255375983194, 750.125],
              [2709265.209981658961624, 1257281.191567023517564, 690.576249999998254],
              [2709279.966330143623054, 1257293.429242590209469, 697.837499999994179],
              [2709334.682428283151239, 1257298.44147015013732, 686.557499999995343],
              [2709353.261555164121091, 1257347.402143004350364, 714.383749999993597],
              [2709719.470890909899026, 1258003.002206761389971, 664.9375]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 155, gemeinde_B: 261, name: 'Zürich', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2685056.899665075354278, 1244517.820845373440534, 405.850000000005821],
              [2684280.855908020865172, 1243932.560840493999422, 405.830000000001746],
              [2683453.84715990209952, 1243308.684602553956211, 406.53125],
              [2682978.613194745965302, 1242782.354007015004754, 486.431249999994179],
              [2682963.058172441087663, 1242738.802715911529958, 484.727499999993597],
              [2682963.570673879701644, 1242725.897718423046172, 484.837499999994179],
              [2682477.386662320233881, 1242450.645994564983994, 465.096250000002328],
              [2682037.855489970650524, 1242068.861142032081261, 471.412500000005821],
              [2681642.817552935797721, 1242001.319095006445423, 438.044999999998254],
              [2680480.765311570838094, 1241584.123997504357249, 748.39375000000291],
              [2680349.792177413590252, 1242361.987843681359664, 699.598750000004657],
              [2680379.679687242489308, 1243082.065177061595023, 796.867499999993015],
              [2680354.172024354804307, 1243127.439878068864346, 784.322499999994761],
              [2680333.623481064569205, 1243230.460261710453779, 800.315000000002328],
              [2680164.590646476484835, 1243688.038406571606174, 774.69999999999709],
              [2679997.507201405242085, 1244031.011480260873213, 798.48124999999709],
              [2679926.726974766235799, 1244496.832321003079414, 781.070000000006985],
              [2679589.183110405690968, 1244831.838187137153, 869.316250000003492],
              [2679208.575941405259073, 1245176.461504829814658, 798.764999999999418],
              [2679230.217197945341468, 1245224.274017007788643, 799.243749999994179],
              [2678317.683095566462725, 1246040.58858641423285, 676.321249999993597],
              [2677990.489729879423976, 1246141.978059988934547, 706.477499999993597],
              [2677952.999659385532141, 1246157.900430429028347, 701.057499999995343],
              [2677464.473312615416944, 1246837.994200470857322, 597.167499999995925],
              [2677911.621589692309499, 1247524.348319066688418, 560.078750000000582],
              [2677078.851100046187639, 1247923.283507922431454, 619.886249999995925],
              [2676466.042191042099148, 1247974.171977674588561, 608.167499999995925],
              [2676413.320943128317595, 1247994.54823312186636, 608.383749999993597],
              [2676224.701864533592016, 1248202.557627740548924, 583.041249999994761],
              [2676325.234398704953492, 1248209.006439129821956, 585.598750000004657],
              [2677636.706245636101812, 1249389.165378622943535, 436.764999999999418],
              [2677663.8450026079081, 1249377.937891004839912, 437.285000000003492],
              [2677477.584586850833148, 1249868.153374282410368, 405.1875],
              [2677513.155937967821956, 1249850.821061899885535, 413.005000000004657],
              [2678009.994479937944561, 1249741.858165153302252, 396.528749999997672],
              [2677983.605742821004242, 1249794.408190016169101, 397.546249999999418],
              [2677678.781909178942442, 1250751.23804720188491, 391.291249999994761],
              [2678441.089433184359223, 1250859.970580216031522, 392.639999999999418],
              [2678466.026932918000966, 1250858.869329300243407, 392.602499999993597],
              [2678348.303213233593851, 1251676.736723368288949, 491.872499999997672],
              [2677686.637641202658415, 1251901.071922017028555, 448.072499999994761],
              [2677802.669757917523384, 1252367.863541230559349, 515.1875],
              [2677950.334676380269229, 1252381.164636159781367, 508.740000000005239],
              [2678366.115081989206374, 1252641.29788640444167, 539.807499999995343],
              [2678346.255078002810478, 1252668.015379705699161, 539.517500000001746],
              [2679347.116641266271472, 1252923.367366201477125, 466.172500000000582],
              [2678933.663787654135376, 1253530.267970859073102, 439.147500000006403],
              [2678929.785049729980528, 1253587.06049395352602, 440.086249999993015],
              [2678932.407537018414587, 1253787.147972034057602, 439.104999999995925],
              [2678981.278799424879253, 1253850.89799484773539, 440.029999999998836],
              [2679002.62003837665543, 1253885.177974175428972, 439.160000000003492],
              [2680089.762547366321087, 1254276.901724591385573, 439.052500000005239],
              [2680213.3550705765374, 1254301.805515768472105, 440.753750000003492],
              [2680848.4337695739232, 1254018.816656718729064, 436.337499999994179],
              [2681359.516423551365733, 1253960.125682953745127, 447.828750000000582],
              [2681490.112635262776166, 1254014.870609926758334, 444.779999999998836],
              [2681931.871413531247526, 1253950.636903651989996, 446.623749999998836],
              [2682667.66549192648381, 1254057.587499891873449, 471.337499999994179],
              [2684250.58885659603402, 1253916.896755289752036, 440.508749999993597],
              [2684178.669932617340237, 1252819.452675073640421, 427.182499999995343],
              [2684836.551155125256628, 1252342.976358479587361, 424.588749999995343],
              [2685093.239920884836465, 1252334.682632965734228, 425.61250000000291],
              [2685108.529906381387264, 1252338.130105975084007, 424.490000000005239],
              [2685216.227420385926962, 1252406.527630512602627, 425.48750000000291],
              [2685237.459905953612179, 1252454.348853943636641, 424.367499999993015],
              [2685346.386166252195835, 1252623.441372526576743, 425.082500000004075],
              [2685397.283622733317316, 1252658.79504168103449, 421.712499999994179],
              [2685650.022417947184294, 1252755.40012147789821, 424.997499999997672],
              [2685661.79118230054155, 1252506.710145525401458, 426.082500000004075],
              [2685652.271145161241293, 1252506.037577228620648, 423.244999999995343],
              [2685643.722470378037542, 1251939.593960979254916, 428.998749999998836],
              [2685631.671179799363017, 1251790.685135099804029, 425.896250000005239],
              [2686458.116197782102972, 1251523.551401291042566, 426.672500000000582],
              [2686846.530042750760913, 1251385.310317815747112, 433.649999999994179],
              [2687138.126214717049152, 1251331.672668751562014, 427.473750000004657],
              [2687449.691231478005648, 1251220.781443062704057, 428.527499999996508],
              [2687444.632481184788048, 1251221.480192626127973, 428.508749999993597],
              [2687320.61121328920126, 1251123.491411067079753, 427.228749999994761],
              [2687400.288889627438039, 1250198.87922504148446, 440.618749999994179],
              [2687400.807637839112431, 1250178.589221558300778, 440.48124999999709],
              [2687390.427638988941908, 1250159.720473683904856, 440.576249999998254],
              [2687261.085103026591241, 1250231.006660671904683, 437.926250000004075],
              [2686407.646612032316625, 1249320.608189134858549, 553.967499999998836],
              [2686417.054155502468348, 1249238.368267839308828, 557.282500000001164],
              [2686573.926988802850246, 1249473.064083628123626, 487.036250000004657],
              [2686706.205414765514433, 1249200.684527930803597, 557.723750000004657],
              [2687164.464273891411722, 1248907.637214467162266, 565.633749999993597],
              [2687206.134245063643903, 1248907.182160660158843, 563.392500000001746],
              [2686906.444626693613827, 1248740.022865551523864, 592.838749999995343],
              [2686907.890877676662058, 1248736.822867288952693, 592.912500000005821],
              [2687105.208365031518042, 1248671.232838640920818, 591.752500000002328],
              [2687674.454186951741576, 1247632.721817351877689, 653.929999999993015],
              [2689052.85832234332338, 1246891.322693543508649, 586.571249999993597],
              [2689055.909572546835989, 1246887.561443800339475, 586.583750000005239],
              [2689047.760796638671309, 1246880.637646606657654, 584.617499999993015],
              [2688843.286532017402351, 1246294.532736774999648, 640.846250000002328],
              [2689141.184060870669782, 1246276.100281630875543, 642.732499999998254],
              [2689245.390281576663256, 1246242.093975108349696, 640.391250000000582],
              [2689372.74906678404659, 1246146.140284865628928, 642.9375],
              [2689388.837823312729597, 1246136.157796222018078, 643.417499999995925],
              [2689440.057791830506176, 1246116.555237264139578, 640.966249999997672],
              [2689601.270659509114921, 1245500.889645208604634, 668.773749999993015],
              [2689602.843156493268907, 1245495.490889599081129, 668.542499999995925],
              [2689597.36690743919462, 1245487.589641354046762, 668.619999999995343],
              [2689241.335525252390653, 1245430.555658989353105, 658.9375],
              [2689137.430601060390472, 1245466.874550399836153, 664.82499999999709],
              [2687964.61174421524629, 1245086.113348355982453, 562.537500000005821],
              [2686711.412189398426563, 1245303.665656291879714, 502.167499999995925],
              [2686540.150014400482178, 1245074.698752208380029, 527.162500000005821],
              [2685056.899665075354278, 1244517.820845373440534, 405.850000000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 159, gemeinde_B: 295, name: 'Horgen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2686901.019683887716383, 1236131.768277733819559, 405.830000000001746],
              [2686955.860934366006404, 1236176.893278072820976, 405.830000000001746],
              [2687005.860934837255627, 1236101.893277469323948, 405.830000000001746],
              [2686951.574684362392873, 1236063.208277175668627, 405.830000000001746],
              [2686962.455939205829054, 1236045.760785649297759, 406.192500000004657],
              [2686957.705953703261912, 1236042.960812027333304, 407.303750000006403],
              [2686958.505954316351563, 1236041.610813119681552, 407.350000000005821],
              [2686963.655935487709939, 1236043.51077885273844, 405.907500000001164],
              [2686967.205934737343341, 1236036.660777374869213, 405.847500000003492],
              [2686964.855939249508083, 1236034.360785586293787, 406.193750000005821],
              [2686968.705939529463649, 1236027.610785974888131, 406.212499999994179],
              [2686968.755948559381068, 1236024.11080233938992, 406.902499999996508],
              [2686972.555949054192752, 1236017.61080312053673, 406.9375],
              [2686977.205939542967826, 1236018.060785779030994, 406.207500000004075],
              [2686980.855935523286462, 1236014.160778380464762, 405.897500000006403],
              [2686985.555937726050615, 1236006.460782238049433, 406.0625],
              [2686996.40594090288505, 1235984.010787633247674, 406.297500000000582],
              [2686999.955938990227878, 1235979.410784068051726, 406.148749999993015],
              [2687001.755935327615589, 1235978.060777374077588, 405.867499999993015],
              [2687000.105944735463709, 1235977.2107944781892, 406.587499999994179],
              [2687001.05594240501523, 1235975.510790214175358, 406.408750000002328],
              [2687002.705937036778778, 1235976.310780448839068, 405.997499999997672],
              [2687004.705940785352141, 1235972.360787185374647, 406.282500000001164],
              [2687007.605934892781079, 1235973.260776444571093, 405.830000000001746],
              [2687008.80593490274623, 1235972.86077643930912, 405.830000000001746],
              [2687009.655936642084271, 1235971.910779579775408, 405.962499999994179],
              [2687009.905935483518988, 1235970.260777460411191, 405.873749999998836],
              [2687009.10594023950398, 1235968.810786089859903, 406.23750000000291],
              [2687009.455939292907715, 1235965.760784342885017, 406.164999999993597],
              [2687018.25593499513343, 1235944.260776210576296, 405.830000000001746],
              [2687028.15593917760998, 1235925.26078348280862, 406.142500000001746],
              [2687030.955943278502673, 1235918.460790820186958, 406.453750000000582],
              [2687034.755936167668551, 1235911.710777787026018, 405.907500000001164],
              [2687033.355942306574434, 1235910.860788947204128, 406.377500000002328],
              [2687034.405947465915233, 1235908.71079828008078, 406.771250000005239],
              [2687037.705935179255903, 1235910.56077593844384, 405.830000000001746],
              [2687045.55593553185463, 1235902.160776371136308, 405.851250000006985],
              [2687075.148435620591044, 1235884.20452589681372, 405.837499999994179],
              [2687082.200943555217236, 1235880.682040148414671, 406.438750000001164],
              [2687088.355941550806165, 1235880.010786398081109, 406.28125],
              [2687112.705938434228301, 1235867.660780234262347, 406.026249999995343],
              [2687124.905937041621655, 1235856.960777407279238, 405.911250000004657],
              [2687134.755937544163316, 1235843.710778048960492, 405.942500000004657],
              [2687139.25594364060089, 1235832.760788943618536, 406.404999999998836],
              [2687139.905944070313126, 1235832.310789711773396, 406.4375],
              [2687151.555936225689948, 1235836.910775316879153, 405.830000000001746],
              [2687153.955936247482896, 1235830.860775268869475, 405.830000000001746],
              [2687142.305943261831999, 1235826.26078815292567, 406.373749999998836],
              [2687143.42968631722033, 1235824.03952550701797, 405.842499999998836],
              [2687142.554693246260285, 1235822.543288094224408, 406.372499999997672],
              [2687144.374686163850129, 1235819.984525179024786, 405.830000000001746],
              [2687142.542197159025818, 1235815.748295134166256, 406.671249999999418],
              [2687144.760938100051135, 1235813.938278636196628, 405.977499999993597],
              [2687144.353444315958768, 1235807.887039875146002, 406.452499999999418],
              [2687144.50094492547214, 1235802.253290921915323, 406.498749999998836],
              [2687144.88969702180475, 1235797.61454469920136, 406.658750000002328],
              [2687145.833436938002706, 1235797.620776369934902, 405.88749999999709],
              [2687146.679686438292265, 1235790.583275394514203, 405.848750000004657],
              [2687145.905942239798605, 1235789.168285922845826, 406.292499999995925],
              [2687146.927192252594978, 1235783.954535880126059, 406.292499999995925],
              [2687150.474694299977273, 1235771.297039429889992, 406.446249999993597],
              [2687151.732197945006192, 1235764.842045970726758, 406.723750000004657],
              [2687154.51968742068857, 1235761.803276797058061, 405.917499999995925],
              [2687157.155948076862842, 1235754.860796041786671, 406.729999999995925],
              [2687162.177195901051164, 1235748.674541951622814, 406.559999999997672],
              [2687168.088449211325496, 1235740.397047788370401, 406.808749999996508],
              [2687175.114695548079908, 1235733.265790960984305, 406.523749999993015],
              [2687183.403438655659556, 1235726.09452825691551, 405.991250000006403],
              [2687189.585943487472832, 1235719.415786864934489, 406.35624999999709],
              [2687197.845944105647504, 1235711.318287778645754, 406.397500000006403],
              [2687200.659694458823651, 1235705.618288332829252, 406.422500000000582],
              [2687202.849686726927757, 1235698.40327419876121, 405.830000000001746],
              [2687205.824692059773952, 1235684.548283708281815, 406.235000000000582],
              [2687207.242194868158549, 1235681.434538760455325, 406.448749999995925],
              [2687208.054687072522938, 1235677.78327457094565, 405.852499999993597],
              [2687208.125938124023378, 1235674.73702644649893, 405.932499999995343],
              [2687207.393442599102855, 1235672.305784562602639, 406.274999999994179],
              [2687207.369688624050468, 1235671.005777334328741, 405.971250000002328],
              [2687195.64969781273976, 1235671.137044205795974, 406.681249999994179],
              [2687195.305948071647435, 1235670.510794675443321, 406.701249999998254],
              [2687195.355947436299175, 1235665.360793479485437, 406.652499999996508],
              [2687194.65594000229612, 1235655.710779915563762, 406.085000000006403],
              [2687204.055936751421541, 1235655.76077386084944, 405.830000000001746],
              [2687210.105937010608613, 1235629.41077399952337, 405.845000000001164],
              [2687201.455950134433806, 1235626.310797941870987, 406.853749999994761],
              [2687203.655936756171286, 1235620.160773574607447, 405.830000000001746],
              [2687192.005939909722656, 1235616.210779458284378, 406.078750000000582],
              [2687191.605947056785226, 1235617.310792441247031, 406.625],
              [2687174.325950076337904, 1235610.600798152852803, 406.867499999993015],
              [2687178.407200363464653, 1235596.879548485623673, 406.886249999995925],
              [2687187.755937504116446, 1235600.610775028821081, 405.897500000006403],
              [2687188.355950337834656, 1235599.060798296472058, 406.877500000002328],
              [2687199.455936724320054, 1235602.960773440543562, 405.830000000001746],
              [2687200.785936738364398, 1235602.555773438885808, 405.830000000001746],
              [2687201.497187120839953, 1235600.737024107482284, 405.858749999999418],
              [2687200.474693050142378, 1235599.718284874921665, 406.3125],
              [2687200.793442236725241, 1235598.704533386277035, 406.25],
              [2687201.055944970808923, 1235597.867038331693038, 406.458750000005239],
              [2687203.15718679362908, 1235597.598273454932496, 405.832500000004075],
              [2687202.308444424998015, 1235593.883287291275337, 406.416249999994761],
              [2687214.455957651138306, 1235556.660810773959383, 407.417499999995925],
              [2687215.805954899638891, 1235556.610805755713955, 407.20625000000291],
              [2687216.705938791390508, 1235555.560776506084949, 405.975000000005821],
              [2687216.555938857141882, 1235554.210776612628251, 405.979999999995925],
              [2687215.505955372005701, 1235553.360806591575965, 407.242499999993015],
              [2687223.655957136303186, 1235528.210809445241466, 407.371249999996508],
              [2687224.805952093563974, 1235528.260800269898027, 406.985000000000582],
              [2687225.755938636139035, 1235527.460775834042579, 405.95625000000291],
              [2687225.955936985090375, 1235526.210772824240848, 405.830000000001746],
              [2687225.155956627801061, 1235525.110808472149074, 407.33125000000291],
              [2687226.705940707121044, 1235523.06077953800559, 406.113750000004075],
              [2687228.855942725203931, 1235521.060783144785091, 406.266250000000582],
              [2687231.305946460925043, 1235519.510789870284498, 406.55000000000291],
              [2687241.955948078539222, 1235514.210792579920962, 406.666249999994761],
              [2687242.65593713754788, 1235514.96077273087576, 405.830000000001746],
              [2687244.355951162520796, 1235512.210798119194806, 406.899999999994179],
              [2687251.205960514955223, 1235514.360814996762201, 407.610000000000582],
              [2687250.455950134433806, 1235515.910796188283712, 406.817500000004657],
              [2687250.805957533884794, 1235517.910809622146189, 407.382500000006985],
              [2687252.605958499480039, 1235519.060811356874183, 407.455000000001746],
              [2687253.855957201682031, 1235518.960808974690735, 407.354999999995925],
              [2687254.905952987261117, 1235518.310801314655691, 407.032500000001164],
              [2687260.005966077558696, 1235509.960824900306761, 408.028749999997672],
              [2687261.455950496718287, 1235510.860796620603651, 406.837499999994179],
              [2687264.35594815434888, 1235506.110792276449502, 406.65625],
              [2687262.85596523899585, 1235505.210823289118707, 407.962499999994179],
              [2687267.905954157933593, 1235496.760803034761921, 407.11250000000291],
              [2687267.60595419164747, 1235494.510803073877469, 407.115000000005239],
              [2687266.455950759351254, 1235493.560796865494922, 406.853749999994761],
              [2687265.005944840610027, 1235493.360786147648469, 406.402499999996508],
              [2687263.655944892205298, 1235494.010786272352561, 406.407500000001164],
              [2687262.755951315630227, 1235495.310797947458923, 406.898749999993015],
              [2687258.355940986890346, 1235491.060779242776334, 406.11250000000291],
              [2687257.855940148234367, 1235489.310777717269957, 406.048750000001746],
              [2687258.205938562750816, 1235488.260774825001135, 405.927500000005239],
              [2687264.10594676900655, 1235479.21078953682445, 406.55000000000291],
              [2687265.355938517022878, 1235480.110774556407705, 405.91874999999709],
              [2687267.605937376152724, 1235476.71077241981402, 405.830000000001746],
              [2687265.005937352310866, 1235475.010772404493764, 405.830000000001746],
              [2687264.005942758638412, 1235476.210782238747925, 406.243749999994179],
              [2687253.705944123677909, 1235469.060784830478951, 406.354999999995925],
              [2687258.85595181491226, 1235462.760798637988046, 406.938750000001164],
              [2687269.455937785096467, 1235470.760773084592074, 405.860000000000582],
              [2687278.055948093533516, 1235460.760791552951559, 406.641250000000582],
              [2687279.155940852593631, 1235461.010778407100588, 406.087499999994179],
              [2687280.20593772130087, 1235460.460772698977962, 405.847500000003492],
              [2687280.555937496945262, 1235459.36077227932401, 405.830000000001746],
              [2687280.155940404627472, 1235458.310777557780966, 406.052500000005239],
              [2687313.855953386519104, 1235420.510800223099068, 407.020000000004075],
              [2687318.80593785084784, 1235425.060771993361413, 405.830000000001746],
              [2687329.005948827136308, 1235415.460791655117646, 406.661250000004657],
              [2687330.20593934552744, 1235416.41077443654649, 405.936249999998836],
              [2687331.805938641075045, 1235416.960773140424863, 405.881250000005821],
              [2687333.505948097445071, 1235416.9607902711723, 406.602499999993597],
              [2687336.055954143870622, 1235415.610801180824637, 407.0625],
              [2687337.455944926012307, 1235412.710784412920475, 406.357499999998254],
              [2687337.255938020069152, 1235410.560771871823817, 405.830000000001746],
              [2687336.105952592566609, 1235408.710798299172893, 406.943750000005821],
              [2687345.155947878491133, 1235400.260789530817419, 406.577499999999418],
              [2687347.205938111525029, 1235401.310771796386689, 405.830000000001746],
              [2687348.605938257649541, 1235401.060772030381486, 405.839999999996508],
              [2687349.655941962730139, 1235400.310778725426644, 406.122499999997672],
              [2687354.355941222049296, 1235401.16077730874531, 406.0625],
              [2687354.805953449569643, 1235400.210799477761611, 406.996249999996508],
              [2687354.355949159245938, 1235399.710791695397347, 406.66874999999709],
              [2687350.205943997483701, 1235399.160782404709607, 406.277499999996508],
              [2687348.30594316450879, 1235397.46078090602532, 406.214999999996508],
              [2687349.055938131175935, 1235396.710771757178009, 405.830000000001746],
              [2687341.782204465474933, 1235389.610801448114216, 407.082500000004075],
              [2687346.137203900609165, 1235385.162050309125334, 407.036250000004657],
              [2687353.392188170924783, 1235392.310771723277867, 405.830000000001746],
              [2687388.455952208954841, 1235359.26079632085748, 406.877500000002328],
              [2687390.505940280854702, 1235360.110774660948664, 405.964999999996508],
              [2687392.505938531830907, 1235359.310771447140723, 405.830000000001746],
              [2687391.788438524585217, 1235358.339521435089409, 405.830000000001746],
              [2687390.757190282456577, 1235358.747024643002078, 405.964999999996508],
              [2687389.487195099703968, 1235358.490783399902284, 406.333750000005239],
              [2687385.877211221028119, 1235354.389562672236934, 407.567500000004657],
              [2687392.199725159443915, 1235348.067087786504999, 408.627500000002328],
              [2687396.299688567407429, 1235352.150771386455745, 405.830000000001746],
              [2687396.697188571561128, 1235353.065771394176409, 405.830000000001746],
              [2687396.064688565209508, 1235353.973271401133388, 405.830000000001746],
              [2687396.855938573833555, 1235355.010771407280117, 405.830000000001746],
              [2687397.605938581284136, 1235352.660771393449977, 405.830000000001746],
              [2687396.855943353846669, 1235351.060780047206208, 406.195000000006985],
              [2687405.583440127782524, 1235342.565773976966739, 405.942500000004657],
              [2687421.800938806496561, 1235326.782021177699789, 405.830000000001746],
              [2687423.385944742709398, 1235325.879531916230917, 406.282500000001164],
              [2687427.074688852299005, 1235331.339521215064451, 405.830000000001746],
              [2687437.155938941985369, 1235324.810771158663556, 405.830000000001746],
              [2687434.855940136127174, 1235321.210773327853531, 405.922500000000582],
              [2687444.15593900764361, 1235315.160771075868979, 405.830000000001746],
              [2687442.905946296174079, 1235313.21078430255875, 406.38749999999709],
              [2687460.655940681695938, 1235301.610773731488734, 405.946249999993597],
              [2687461.95593917183578, 1235303.510770980734378, 405.830000000001746],
              [2687469.205939418170601, 1235299.010771267814562, 405.84375],
              [2687464.705949298106134, 1235292.160789203597233, 406.601250000006985],
              [2687474.818443024996668, 1235284.805777592817321, 406.115000000005239],
              [2687484.505957141518593, 1235277.760802975390106, 407.186249999998836],
              [2687492.005950693506747, 1235280.91079118475318, 406.688750000001164],
              [2687497.655947583727539, 1235282.510785467457026, 406.447499999994761],
              [2687503.505945607554168, 1235283.56078179134056, 406.292499999995925],
              [2687509.405939602758735, 1235284.060770816868171, 405.830000000001746],
              [2687515.955944196786731, 1235283.910779028665274, 406.176250000004075],
              [2687520.455939702689648, 1235283.360770807601511, 405.830000000001746],
              [2687527.005939763039351, 1235281.910770789487287, 405.830000000001746],
              [2687532.755944774951786, 1235280.010779763804749, 406.208750000005239],
              [2687538.405956774484366, 1235277.610801415750757, 407.121249999996508],
              [2687543.585939909331501, 1235283.317020795773715, 405.830000000001746],
              [2687545.907189934048802, 1235281.395770783070475, 405.830000000001746],
              [2687544.705946878995746, 1235279.860783381620422, 406.361250000001746],
              [2687547.855954947881401, 1235277.110797932837158, 406.975000000005821],
              [2687548.655940169934183, 1235277.710771135054529, 405.846250000002328],
              [2687550.205939971841872, 1235277.91077074687928, 405.830000000001746],
              [2687551.505941588897258, 1235276.910773650044575, 405.952499999999418],
              [2687551.555950757116079, 1235275.210790256503969, 406.652499999996508],
              [2687551.155958543531597, 1235274.360804383875802, 407.247499999997672],
              [2687554.355944171082228, 1235271.81077824276872, 406.147500000006403],
              [2687555.747190024703741, 1235273.380770714720711, 405.830000000001746],
              [2687558.025940044317394, 1235271.318270700052381, 405.830000000001746],
              [2687553.955963417422026, 1235266.110813103849068, 407.617499999993015],
              [2687558.855958980042487, 1235260.860804924974218, 407.274999999994179],
              [2687562.90595056861639, 1235254.560789555311203, 406.630000000004657],
              [2687566.305940127465874, 1235245.960770490113646, 405.830000000001746],
              [2687567.455954411998391, 1235241.760796339949593, 406.919999999998254],
              [2687568.097211114130914, 1235236.508308432297781, 407.431249999994179],
              [2687570.793440171517432, 1235235.860770413186401, 405.830000000001746],
              [2687573.16719434922561, 1235232.478277924703434, 406.147500000006403],
              [2687576.320940189994872, 1235231.398270312696695, 405.827499999999418],
              [2687581.105941085144877, 1235228.810771836200729, 405.892500000001746],
              [2687577.672195442952216, 1235222.478279743110761, 406.227499999993597],
              [2687581.31846765615046, 1235221.174569947645068, 407.921249999999418],
              [2687583.043456606101245, 1235223.968299900880083, 407.076249999998254],
              [2687714.005942858755589, 1235200.160772599745542, 405.936249999998836],
              [2687712.455961692146957, 1235191.510806703008711, 407.375],
              [2687806.455967171583325, 1235145.510814668610692, 407.727499999993597],
              [2687806.955949374940246, 1235147.610782428644598, 406.368749999994179],
              [2687808.605949555989355, 1235146.760782716795802, 406.381250000005821],
              [2687808.015952777583152, 1235144.339538543485105, 406.627500000002328],
              [2687809.197192339226604, 1235142.327019597869366, 405.830000000001746],
              [2687808.424717666115612, 1235138.447065479354933, 407.763749999998254],
              [2687822.855967227835208, 1235131.560814378783107, 407.720000000001164],
              [2687848.805963354185224, 1235118.310806816676632, 407.40625],
              [2687855.905954649206251, 1235133.71079105627723, 406.73750000000291],
              [2687856.755942764226347, 1235149.16076963301748, 405.830000000001746],
              [2687857.905942772980779, 1235150.060769639909267, 405.830000000001746],
              [2687882.852212229277939, 1235142.304554416332394, 407.297500000000582],
              [2687883.955962040927261, 1235141.960804056609049, 407.282500000001164],
              [2687886.105943029280752, 1235148.160769611829892, 405.830000000001746],
              [2687895.205950004979968, 1235145.460782086709514, 406.35624999999709],
              [2687901.455945624038577, 1235141.410774010233581, 406.017500000001746],
              [2687920.455952444579452, 1235141.210786051349714, 406.524999999994179],
              [2687926.364701175130904, 1235132.133283572038636, 406.423750000001746],
              [2687928.58345039235428, 1235131.607032116735354, 406.36250000000291],
              [2687930.48095162352547, 1235127.889534277608618, 406.455000000001746],
              [2687931.905950651504099, 1235126.240782483946532, 406.380000000004657],
              [2687931.238452188670635, 1235124.644535263068974, 406.497499999997672],
              [2687952.605959302280098, 1235091.960797523148358, 407.024999999994179],
              [2687955.605943670496345, 1235094.210769162280485, 405.830000000001746],
              [2687964.655950958374888, 1235083.760782134719193, 406.380000000004657],
              [2687976.055955525953323, 1235059.610790017293766, 406.720000000001164],
              [2687991.947194007690996, 1235052.228268819861114, 405.830000000001746],
              [2687990.497206740546972, 1235048.247041875962168, 406.802500000005239],
              [2687993.092194805387408, 1235047.05702020204626, 405.889999999999418],
              [2687992.563454483170062, 1235045.705787733895704, 406.628750000003492],
              [2687995.387197105214, 1235044.41952431271784, 406.063750000001164],
              [2688001.294694182928652, 1235020.755768718430772, 405.836249999993015],
              [2688002.874709594529122, 1235016.788296580314636, 407.011249999995925],
              [2688022.855951214209199, 1235002.060780931962654, 406.357499999998254],
              [2688038.955948722548783, 1234990.810776062542573, 406.15625],
              [2688048.455950087402016, 1234985.26077833166346, 406.253750000003492],
              [2688082.955961772240698, 1234969.760798791889101, 407.121249999996508],
              [2688087.555944888852537, 1234979.010768211446702, 405.830000000001746],
              [2688107.255945067852736, 1234969.460768126649782, 405.830000000001746],
              [2688106.28469571378082, 1234967.519519296707585, 405.880000000004657],
              [2688108.45719508221373, 1234962.978268078761175, 405.830000000001746],
              [2688108.925945546012372, 1234959.418268874520436, 405.865000000005239],
              [2688108.365957254078239, 1234956.265790071105585, 406.758749999993597],
              [2688128.339700210373849, 1234945.695776893058792, 406.207500000004075],
              [2688131.512203044258058, 1234945.052031963597983, 406.421249999999418],
              [2688135.478452652692795, 1234944.993281190982088, 406.388749999998254],
              [2688172.057205864228308, 1234946.372036405373365, 406.608749999999418],
              [2688186.868445788510144, 1234951.948267965344712, 405.830000000001746],
              [2688227.855946163646877, 1234930.960767782991752, 405.830000000001746],
              [2688226.64969839900732, 1234925.745771805290133, 406.001250000001164],
              [2688231.357196262571961, 1234921.205767828272656, 405.835000000006403],
              [2688233.329699935857207, 1234917.19577440735884, 406.113750000004075],
              [2688236.805951147805899, 1234914.910776524106041, 406.203750000000582],
              [2688239.524696271400899, 1234918.603267686441541, 405.830000000001746],
              [2688241.030946283135563, 1234918.19701768248342, 405.830000000001746],
              [2688240.193457355722785, 1234914.228287709411234, 406.67500000000291],
              [2688244.105963325127959, 1234911.610798430629075, 407.127500000002328],
              [2688245.987205670215189, 1234914.085784560767934, 406.542499999995925],
              [2688246.02969632903114, 1234917.978267681319267, 405.830000000001746],
              [2688248.85594635643065, 1234917.86076767067425, 405.830000000001746],
              [2688251.305946378037333, 1234916.660767659312114, 405.830000000001746],
              [2688246.655964525416493, 1234909.910800548270345, 407.217499999998836],
              [2688252.755966811440885, 1234906.860804557800293, 407.38749999999709],
              [2688255.205946411937475, 1234914.710767647717148, 405.830000000001746],
              [2688260.25595204718411, 1234912.260777746560052, 406.256250000005821],
              [2688268.905946532730013, 1234921.110767694655806, 405.830000000001746],
              [2688270.455946546979249, 1234921.810767698567361, 405.830000000001746],
              [2688272.105948497541249, 1234921.61077119782567, 405.977499999993597],
              [2688292.505946748889983, 1234911.660767609253526, 405.830000000001746],
              [2688292.255952220410109, 1234911.110777518711984, 406.247499999997672],
              [2688323.55594896664843, 1234896.060770977055654, 405.977499999993597],
              [2688324.755947797559202, 1234895.160768832778558, 405.88749999999709],
              [2688329.155950934626162, 1234888.510774388210848, 406.123749999998836],
              [2688336.955948696471751, 1234882.110770149622113, 405.947499999994761],
              [2688340.455950499512255, 1234877.210773318773136, 406.082500000004075],
              [2688345.855952633544803, 1234871.860777041874826, 406.241250000006403],
              [2688351.85594927938655, 1234867.210770833771676, 405.98124999999709],
              [2688354.405950381886214, 1234865.910772778559476, 406.063750000001164],
              [2688377.155949408188462, 1234858.210770570673048, 405.973750000004657],
              [2688383.555947878863662, 1234857.360767684178427, 405.852499999993597],
              [2688391.705949394032359, 1234852.860770261380821, 405.962499999994179],
              [2688396.155957367271185, 1234849.460784588707611, 406.567500000004657],
              [2688402.155948653351516, 1234845.760768680600449, 405.898749999993015],
              [2688411.655947838444263, 1234842.510767023311928, 405.830000000001746],
              [2688413.155948606319726, 1234840.460768369259313, 405.88749999999709],
              [2688413.105971343815327, 1234837.910809499677271, 407.621249999996508],
              [2688426.505956810433418, 1234833.860782944131643, 406.503750000003492],
              [2688437.255949089303613, 1234829.810768758645281, 405.907500000001164],
              [2688444.655948139261454, 1234825.910766881890595, 405.830000000001746],
              [2688446.005948152858764, 1234826.310766886454076, 405.830000000001746],
              [2688446.705948158632964, 1234825.360766879050061, 405.830000000001746],
              [2688446.555948158726096, 1234824.6607668728102, 405.830000000001746],
              [2688445.955948152113706, 1234824.160766863496974, 405.830000000001746],
              [2688445.055948146153241, 1234824.210766867268831, 405.830000000001746],
              [2688443.805973805021495, 1234821.860813316889107, 407.787500000005821],
              [2688446.205948155838996, 1234820.610766839468852, 405.830000000001746],
              [2688445.155949064064771, 1234818.260768480133265, 405.899999999994179],
              [2688493.105957684572786, 1234796.960783109767362, 406.523749999993015],
              [2688499.805967056192458, 1234794.610799937043339, 407.233749999999418],
              [2688502.855948672629893, 1234801.160766665823758, 405.830000000001746],
              [2688533.855948955286294, 1234786.810766542330384, 405.830000000001746],
              [2688538.934699, 1234784.264516525203362, 405.830000000001746],
              [2688532.305960780009627, 1234776.610787892015651, 406.732499999998254],
              [2688535.255959724076092, 1234774.810785913374275, 406.649999999994179],
              [2688536.955950442701578, 1234777.410769110079855, 405.941250000003492],
              [2688543.755949046462774, 1234773.210766432574019, 405.830000000001746],
              [2688542.105969084426761, 1234770.610802700743079, 407.358749999999418],
              [2688546.955965405330062, 1234767.61079593654722, 407.07499999999709],
              [2688561.155952841509134, 1234771.410772998817265, 406.107499999998254],
              [2688590.055950043722987, 1234751.210767284967005, 405.873749999998836],
              [2688587.705956645775586, 1234748.010779244359583, 406.378750000003492],
              [2688592.558449688367546, 1234744.46076654526405, 405.845000000001164],
              [2688588.055966064333916, 1234738.510796198854223, 407.096250000002328],
              [2688588.505962083581835, 1234738.260788990417495, 406.792499999995925],
              [2688587.90095848729834, 1234737.312032479560003, 406.51875000000291],
              [2688591.654710472561419, 1234734.772035992937163, 406.667499999995925],
              [2688592.205953918863088, 1234735.610774128930643, 406.167499999995925],
              [2688602.555969313252717, 1234728.310801750514656, 407.333750000005239],
              [2688606.505949622485787, 1234732.360766087658703, 405.830000000001746],
              [2688607.455949630588293, 1234731.560766076901928, 405.830000000001746],
              [2688596.055972290225327, 1234719.460807168856263, 407.565000000002328],
              [2688599.355972222983837, 1234716.91080697090365, 407.557499999995343],
              [2688607.105954189784825, 1234725.060774280223995, 406.177500000005239],
              [2688651.855967259500176, 1234693.410796916112304, 407.142500000001746],
              [2688744.555973163340241, 1234632.210805538110435, 407.527499999996508],
              [2688749.655950933694839, 1234640.510765311075374, 405.830000000001746],
              [2688750.405950940679759, 1234640.060765313683078, 405.830000000001746],
              [2688749.20595093164593, 1234637.910765292821452, 405.830000000001746],
              [2688825.055952067952603, 1234601.710765782510862, 405.863750000004075],
              [2688867.055962670128793, 1234580.310784084023908, 406.642500000001746],
              [2688867.455954979173839, 1234581.060770177049562, 406.056249999994179],
              [2688901.005952317267656, 1234564.36076466110535, 405.830000000001746],
              [2688899.50596874114126, 1234561.410794365685433, 407.082500000004075],
              [2688959.35597870638594, 1234533.660811150446534, 407.80000000000291],
              [2689000.505979488138109, 1234527.110811833990738, 407.83125000000291],
              [2689000.605977833271027, 1234527.710808835690841, 407.705000000001746],
              [2689006.055972664151341, 1234527.910799404140562, 407.307499999995343],
              [2689007.855958323460072, 1234537.060773523757234, 406.213749999995343],
              [2689027.005959791131318, 1234532.860775828361511, 406.3125],
              [2689024.555979641154408, 1234523.710811677388847, 407.826249999998254],
              [2689072.505975531879812, 1234510.460803331341594, 407.479999999995925],
              [2689073.105953879654408, 1234512.410764195257798, 405.830000000001746],
              [2689075.473477873019874, 1234511.627057534642518, 407.65625],
              [2689076.034704001620412, 1234513.707014384912327, 405.837499999994179],
              [2689089.605954782105982, 1234515.160765576874837, 405.88749999999709],
              [2689110.477204215247184, 1234513.617014194605872, 405.830000000001746],
              [2689111.737204323057085, 1234512.367014364106581, 405.837499999994179],
              [2689112.367219440639019, 1234510.032041661441326, 406.988750000004075],
              [2689115.06972181238234, 1234509.353295902954414, 407.167499999995925],
              [2689117.85970428166911, 1234512.420764187350869, 405.830000000001746],
              [2689120.687204304151237, 1234513.028264184249565, 405.830000000001746],
              [2689130.619707216974348, 1234512.455769283231348, 406.044999999998254],
              [2689139.205959065817297, 1234511.960772479884326, 406.179999999993015],
              [2689193.405954957008362, 1234513.460764162242413, 405.830000000001746],
              [2689203.505957922432572, 1234509.010769318556413, 406.048750000001746],
              [2689215.705964531749487, 1234504.41078102379106, 406.54374999999709],
              [2689231.305955303367227, 1234499.910764045314863, 405.830000000001746],
              [2689248.00595955690369, 1234496.460771430283785, 406.142500000001746],
              [2689263.805958453100175, 1234494.560769151663408, 406.047500000000582],
              [2689265.155964732635766, 1234493.210780477151275, 406.524999999994179],
              [2689266.605955623090267, 1234494.610763994976878, 405.830000000001746],
              [2689272.170955671928823, 1234490.085763959679753, 405.830000000001746],
              [2689277.305958445183933, 1234485.910768839763477, 406.037500000005821],
              [2689279.205956671852618, 1234486.36076561617665, 405.901249999995343],
              [2689283.255959548987448, 1234485.560770740266889, 406.117499999993015],
              [2689289.855962335597724, 1234480.560775625286624, 406.32499999999709],
              [2689305.35597387002781, 1234467.310796102741733, 407.192500000004657],
              [2689310.255956020671874, 1234470.310763790737838, 405.830000000001746],
              [2689317.255956085864455, 1234463.260763731785119, 405.830000000001746],
              [2689314.905956704169512, 1234460.910764866042882, 405.878750000003492],
              [2689310.15595600521192, 1234458.610763669479638, 405.828750000000582],
              [2689309.255963138770312, 1234459.610776577843353, 406.372499999997672],
              [2689297.105966234579682, 1234448.360782276373357, 406.616250000006403],
              [2689311.25595982093364, 1234416.460770182777196, 406.117499999993015],
              [2689319.20596981048584, 1234400.160787967266515, 406.872499999997672],
              [2689333.105956245679408, 1234408.960763295181096, 405.830000000001746],
              [2689337.005956280976534, 1234402.810763246379793, 405.830000000001746],
              [2689316.705970644950867, 1234389.710789421806112, 406.9375],
              [2689320.505960192997009, 1234384.260770428692922, 406.138749999998254],
              [2689326.30596722336486, 1234377.410782974213362, 406.669999999998254],
              [2689347.655956379137933, 1234388.860763133270666, 405.830000000001746],
              [2689350.317215928342193, 1234383.92703029117547, 406.554999999993015],
              [2689351.512206517159939, 1234384.17326326854527, 405.837499999994179],
              [2689352.92596053564921, 1234381.939520491287112, 406.142500000001746],
              [2689354.490956542547792, 1234382.190763249062002, 405.837499999994179],
              [2689356.872217925731093, 1234377.593283738242462, 406.702499999999418],
              [2689361.60595651017502, 1234380.4607630581595, 405.830000000001746],
              [2689367.75595656549558, 1234375.960763019509614, 405.830000000001746],
              [2689367.155967343598604, 1234375.010782496305183, 406.651249999995343],
              [2689395.555956822354347, 1234354.410762839950621, 405.830000000001746],
              [2689385.755970641039312, 1234342.210787867894396, 406.888749999998254],
              [2689395.255969283636659, 1234336.010785210411996, 406.778749999997672],
              [2689403.705956895370036, 1234348.910762793617323, 405.830000000001746],
              [2689429.805962438229471, 1234334.410772250732407, 406.233749999999418],
              [2689441.264713182114065, 1234327.355773353250697, 406.282500000001164],
              [2689434.565958741586655, 1234317.559515352360904, 405.948749999995925],
              [2689433.405961538199335, 1234315.260770404944196, 406.162500000005821],
              [2689433.205958451610059, 1234313.160764811560512, 405.927500000005239],
              [2689434.50596281606704, 1234308.660772637696937, 406.258749999993597],
              [2689439.655957476701587, 1234305.360762882046402, 405.848750000004657],
              [2689440.414707847870886, 1234304.25326352682896, 405.876250000001164],
              [2689440.07722142059356, 1234300.670788024552166, 406.910000000003492],
              [2689451.915973188821226, 1234292.739540954353288, 407.036250000004657],
              [2689455.364720167126507, 1234291.254535422194749, 406.803750000006403],
              [2689456.76096111536026, 1234292.159519059583545, 406.113750000004075],
              [2689464.705958544742316, 1234290.31076427269727, 405.912500000005821],
              [2689465.655957468319684, 1234291.710762316826731, 405.830000000001746],
              [2689466.755957479588687, 1234290.910762316547334, 405.830000000001746],
              [2689466.105958887841552, 1234290.010764854261652, 405.9375],
              [2689466.905957479961216, 1234289.41076230071485, 405.830000000001746],
              [2689460.405966881196946, 1234280.460779312299564, 406.55000000000291],
              [2689460.255970099009573, 1234279.260785117512569, 406.794999999998254],
              [2689471.005969574209303, 1234271.910783933242783, 406.747499999997672],
              [2689473.562207543756813, 1234275.798262194730341, 405.830000000001746],
              [2689478.34095872240141, 1234274.398264227900654, 405.916249999994761],
              [2689479.285968550480902, 1234271.335781932808459, 406.663750000006985],
              [2689483.269707882776856, 1234269.80826259078458, 405.848750000004657],
              [2689514.544709333684295, 1234249.250764519674703, 405.9375],
              [2689520.049707970116287, 1234249.7907619709149, 405.830000000001746],
              [2689524.205959026236087, 1234246.319513782858849, 405.907500000001164],
              [2689528.753460118081421, 1234244.008265658048913, 405.98750000000291],
              [2689530.789709416683763, 1234243.845764353638515, 405.932499999995343],
              [2689536.043458118569106, 1234240.293261887971312, 405.830000000001746],
              [2689535.339708109386265, 1234239.049511882476509, 405.830000000001746],
              [2689531.029708235990256, 1234240.698262196499854, 405.842499999998836],
              [2689530.404709544032812, 1234239.703264553099871, 405.942500000004657],
              [2689530.722214685752988, 1234237.135773821733892, 406.333750000005239],
              [2689557.455959825310856, 1234220.960764459799975, 405.945000000006985],
              [2689568.805958419572562, 1234215.06076168268919, 405.830000000001746],
              [2689573.605960483662784, 1234213.560765313450247, 405.983749999999418],
              [2689578.605962285306305, 1234213.610768493264914, 406.117499999993015],
              [2689583.105958581902087, 1234215.160761735169217, 405.832500000004075],
              [2689585.655958568211645, 1234216.810761688044295, 405.830000000001746],
              [2689587.855958590749651, 1234218.960761709604412, 405.830000000001746],
              [2689591.105958850122988, 1234216.910762103274465, 405.847500000003492],
              [2689587.105968093965203, 1234209.810778805287555, 406.553750000006403],
              [2689592.205958630889654, 1234206.960761608555913, 405.830000000001746],
              [2689591.255962268915027, 1234204.710768173914403, 406.107499999998254],
              [2689592.055961144622415, 1234202.760766114108264, 406.021250000005239],
              [2689594.005962098017335, 1234200.110767784994096, 406.092499999998836],
              [2689596.455961151048541, 1234198.110766016412526, 406.01875000000291],
              [2689605.505960610229522, 1234193.860764851793647, 405.971250000002328],
              [2689615.755959142930806, 1234192.360762023599818, 405.852499999993597],
              [2689619.805959706194699, 1234191.110762959346175, 405.892500000001746],
              [2689623.505959131289274, 1234189.01076184026897, 405.846250000002328],
              [2689626.355960357934237, 1234186.360763990553096, 405.9375],
              [2689635.30596039025113, 1234181.110763855744153, 405.933749999996508],
              [2689641.60595957795158, 1234177.710762253263965, 405.867499999993015],
              [2689649.805962098762393, 1234174.610766642959788, 406.053750000006403],
              [2689652.255962087307125, 1234174.860766588244587, 406.051250000004075],
              [2689656.255959758069366, 1234176.260762317571789, 405.871249999996508],
              [2689659.805959642864764, 1234176.210762054193765, 405.860000000000582],
              [2689670.605959758162498, 1234172.41076204739511, 405.861250000001746],
              [2689672.855959664098918, 1234169.960761822992936, 405.852499999993597],
              [2689673.705963086336851, 1234166.960767968324944, 406.11250000000291],
              [2689684.605961232911795, 1234164.560764416586608, 405.963749999995343],
              [2689688.205961377825588, 1234164.410764621105045, 405.972500000003492],
              [2689695.755960723385215, 1234166.260763331316411, 405.917499999995925],
              [2689698.355961010791361, 1234166.460763800889254, 405.9375],
              [2689704.455960406456143, 1234165.660762608516961, 405.88749999999709],
              [2689705.605960304383188, 1234165.010762396967039, 405.878750000003492],
              [2689706.405960933305323, 1234163.910763515159488, 405.926250000004075],
              [2689706.605960544198751, 1234162.610762793105096, 405.896250000005239],
              [2689706.30595967033878, 1234161.360761208459735, 405.830000000001746],
              [2689705.455959661863744, 1234160.360761207062751, 405.830000000001746],
              [2689704.40595965180546, 1234159.86076119961217, 405.830000000001746],
              [2689700.955962265376002, 1234159.910765973851085, 406.03125],
              [2689699.955971471499652, 1234156.610782590229064, 406.732499999998254],
              [2689740.205970506649464, 1234144.66078007593751, 406.631250000005821],
              [2689741.105966540984809, 1234147.610772923799232, 406.328750000000582],
              [2689741.905962898861617, 1234148.660766346380115, 406.051250000004075],
              [2689743.455962404143065, 1234149.560765436151996, 406.01249999999709],
              [2689745.255961632821709, 1234149.560764010529965, 405.952499999999418],
              [2689755.455961183644831, 1234143.410762977553532, 405.911250000004657],
              [2689801.305961682461202, 1234130.06076301378198, 405.917499999995925],
              [2689803.005962879862636, 1234130.010765138315037, 406.007500000006985],
              [2689805.755961853079498, 1234131.260763255413622, 405.927500000005239],
              [2689808.555962995626032, 1234131.460765274707228, 406.01249999999709],
              [2689812.955960636027157, 1234130.310760930879042, 405.830000000001746],
              [2689819.655963406898081, 1234127.610765803139657, 406.036250000004657],
              [2689821.105960712768137, 1234126.060760898282751, 405.830000000001746],
              [2689820.755969316698611, 1234123.060776409227401, 406.485000000000582],
              [2689832.105962011963129, 1234120.66076301666908, 405.921249999999418],
              [2689858.605962514877319, 1234114.110763431526721, 405.941250000003492],
              [2689867.955962957814336, 1234115.060764088761061, 405.96875],
              [2689875.755961205810308, 1234113.010760776465759, 405.830000000001746],
              [2689876.405961210373789, 1234112.26076076598838, 405.830000000001746],
              [2689876.505961919203401, 1234111.410762034356594, 405.883749999993597],
              [2689875.614713637623936, 1234110.334515139460564, 406.014999999999418],
              [2689876.20346551714465, 1234108.713268510065973, 406.157500000001164],
              [2689882.305961463600397, 1234108.2107610902749, 405.845000000001164],
              [2689888.755963115487248, 1234105.460763943148777, 405.966249999997672],
              [2689899.45596267003566, 1234102.160762932617217, 405.92500000000291],
              [2689902.905963455792516, 1234101.960764294257388, 405.982499999998254],
              [2689908.855961508117616, 1234100.760760665172711, 405.830000000001746],
              [2689910.905963265802711, 1234098.960763793671504, 405.962499999994179],
              [2689933.605963984038681, 1234092.710764655843377, 406.001250000001164],
              [2689939.455964017659426, 1234091.96076461696066, 406.0],
              [2689950.787211245857179, 1234089.567009402206168, 405.78125],
              [2689956.608461938332766, 1234089.262010556412861, 405.830000000001746],
              [2689957.590964085422456, 1234086.009514390490949, 405.992499999993015],
              [2689959.39221347682178, 1234085.929513259790838, 405.945000000006985],
              [2689964.558464621659368, 1234087.182015259750187, 406.028749999997672],
              [2689973.622212453279644, 1234092.864511241903529, 405.857499999998254],
              [2689992.555963409598917, 1234099.610762709518895, 405.917499999995925],
              [2689994.855962280649692, 1234099.860760636860505, 405.830000000001746],
              [2689998.609715042635798, 1234098.0157655403018, 406.037500000005821],
              [2690000.005963607691228, 1234098.757012935355306, 405.927500000005239],
              [2690001.114712336100638, 1234099.345760624390095, 405.830000000001746],
              [2690004.652212398592383, 1234099.107010687002912, 405.832500000004075],
              [2690005.905962377786636, 1234097.760760613018647, 405.830000000001746],
              [2690006.655964060686529, 1234095.9107636208646, 405.957500000004075],
              [2689998.205984533764422, 1234065.160800447221845, 407.520000000004075],
              [2689999.704721880611032, 1234064.244527576956898, 406.556249999994179],
              [2690001.298469545319676, 1234063.817023331997916, 406.377500000002328],
              [2689999.149713514838368, 1234055.837012419709936, 405.919999999998254],
              [2690442.18808652786538, 1233046.556069760816172, 471.964999999996508],
              [2690437.528060799930245, 1233038.594773404765874, 470.01249999999709],
              [2690007.33521975344047, 1232480.022664428222924, 539.486250000001746],
              [2689750.669672859832644, 1232348.479182429611683, 498.088749999995343],
              [2689731.593463740311563, 1232250.633005519397557, 501.213749999995343],
              [2689248.598076561931521, 1230882.133058971026912, 662.51249999999709],
              [2689506.497943690046668, 1230534.131558384513482, 652.110000000000582],
              [2690176.712141864001751, 1229637.701085408218205, 685.467499999998836],
              [2689971.759349718689919, 1229466.114313156576827, 663.463749999995343],
              [2690039.754377712495625, 1229243.201858761720359, 665.514999999999418],
              [2690027.704366438090801, 1229170.803087841253728, 664.66874999999709],
              [2690026.234367968980223, 1229149.863090353552252, 664.786250000004657],
              [2689842.382308836095035, 1229123.248887480702251, 698.535000000003492],
              [2689518.51059243362397, 1229032.25180597952567, 663.388749999998254],
              [2689446.399705343879759, 1228594.188704692060128, 691.091249999997672],
              [2689148.179468375630677, 1228267.341033385135233, 578.139999999999418],
              [2687949.389251448214054, 1228481.463172171730548, 562.728749999994761],
              [2687921.888003895990551, 1228481.513177206506953, 562.941250000003492],
              [2687587.940323140937835, 1229615.161621311213821, 549.473750000004657],
              [2686662.850188822951168, 1229487.592647254699841, 540.039999999993597],
              [2686652.321444481145591, 1229497.466407826403156, 540.482499999998254],
              [2686239.711285108700395, 1230365.013637794414535, 528.676250000004075],
              [2686238.951286374591291, 1230368.776140137109905, 528.773749999993015],
              [2686238.398785303346813, 1230372.574888260103762, 528.692500000004657],
              [2686444.272477173712105, 1230696.281032203231007, 524.072499999994761],
              [2685092.801799613982439, 1232250.160175910685211, 856.642500000001746],
              [2685084.978031269274652, 1232272.680143233854324, 855.247499999997672],
              [2684946.54817851446569, 1232611.966671060072258, 866.73750000000291],
              [2684739.387932031415403, 1232943.663735626731068, 848.128750000003492],
              [2684387.371069609653205, 1233223.627411772031337, 897.551250000004075],
              [2684371.151072307024151, 1233259.043667872902006, 897.782500000001164],
              [2683915.323392428457737, 1233635.449612789554521, 884.63749999999709],
              [2683729.603707373142242, 1233929.026448135264218, 909.10624999999709],
              [2683473.330795710440725, 1234317.628213500371203, 877.852499999993597],
              [2683168.290021671913564, 1234661.317848346661776, 914.892500000001746],
              [2683012.221041508950293, 1235031.100158240413293, 801.291249999994761],
              [2684147.980548179708421, 1236356.247402399079874, 474.08125000000291],
              [2684422.555580915417522, 1236358.424956853035837, 476.375],
              [2684897.429482805076987, 1235646.036467338446528, 487.626250000001164],
              [2685043.203035062644631, 1235773.477131916675717, 568.177500000005239],
              [2686008.737456104718149, 1235340.315824838122353, 618.628750000003492],
              [2686901.019683887716383, 1236131.768277733819559, 405.830000000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 102, gemeinde_B: 155, name: 'Männedorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2696486.587457787711173, 1235688.728080148110166, 608.288750000006985],
              [2696453.60751856258139, 1235594.446938505396247, 612.897500000006403],
              [2696449.853771469555795, 1235584.648193678352982, 613.119999999995343],
              [2696445.383774534333497, 1235575.136949155945331, 613.354999999995925],
              [2696441.030023647937924, 1235567.289447574876249, 613.292499999995925],
              [2696434.482519168406725, 1235557.223189593758434, 612.961249999993015],
              [2696427.555011465679854, 1235547.758175854803994, 612.38749999999709],
              [2696419.292503280565143, 1235537.438161277677864, 611.778749999997672],
              [2696410.7074950565584, 1235527.678146650781855, 611.167499999995925],
              [2696404.324987674131989, 1235521.323133518220857, 610.617499999993015],
              [2696397.504981132224202, 1235515.410621901974082, 610.131250000005821],
              [2696390.223723240662366, 1235509.929357883986086, 609.54374999999709],
              [2696382.886216229293495, 1235505.029345455113798, 609.022500000006403],
              [2696377.662447397131473, 1235493.394311746582389, 607.608749999999418],
              [2696400.901194234844297, 1235485.551805440336466, 607.347500000003492],
              [2696398.676191389560699, 1235478.913050315342844, 607.134999999994761],
              [2696391.384909408632666, 1235464.661743092117831, 604.732499999998254],
              [2696351.91217204509303, 1235375.031318256631494, 586.884999999994761],
              [2696388.66967822285369, 1235355.163828232092783, 587.315000000002328],
              [2696391.328436717856675, 1235359.59509341721423, 587.952499999999418],
              [2696400.827224188484251, 1235354.901410172460601, 590.766250000000582],
              [2696397.817168358713388, 1235329.961310072103515, 586.5625],
              [2696416.210915679112077, 1235302.476304532960057, 586.342499999998836],
              [2696432.299645053688437, 1235267.120016850763932, 584.772500000006403],
              [2696462.82587779359892, 1235221.036234731320292, 583.442500000004657],
              [2696478.687140409834683, 1235210.350006813881919, 584.377500000002328],
              [2696511.242169946432114, 1235197.743808746803552, 586.571249999993597],
              [2696538.952147277537733, 1235147.130016970681027, 584.836249999993015],
              [2696563.670934422872961, 1235120.905082520563155, 587.610000000000582],
              [2696530.115899009164423, 1235089.122519587399438, 584.973750000004657],
              [2696521.097135005053133, 1235090.691244763322175, 583.927500000005239],
              [2696499.113337222952396, 1235077.919909668620676, 580.348750000004657],
              [2696563.80711259227246, 1235017.547452854923904, 582.197499999994761],
              [2696547.992102973163128, 1235008.357435906073079, 581.48750000000291],
              [2696500.949566648807377, 1234978.221121662063524, 578.794999999998254],
              [2696491.842065486591309, 1234984.352369863772765, 578.716249999997672],
              [2696491.985814031679183, 1234973.977367135463282, 578.60624999999709],
              [2696489.845811367966235, 1234966.427362338174134, 578.407500000001164],
              [2696475.975798892788589, 1234943.097340062493458, 577.479999999995925],
              [2696473.645795478485525, 1234937.997333953157067, 577.225000000005821],
              [2696473.015794675797224, 1234934.147332487860695, 577.164999999993597],
              [2696474.445794578641653, 1234929.537332229316235, 577.15625],
              [2696492.685781009495258, 1234889.957307087723166, 576.116250000006403],
              [2696498.65577959548682, 1234880.547304314095527, 576.003750000003492],
              [2696506.895779018290341, 1234871.177302988478914, 575.952499999999418],
              [2696535.020766350440681, 1234835.442279290175065, 574.971250000002328],
              [2696541.007011534180492, 1234825.65227042674087, 574.602499999993597],
              [2696546.045764153357595, 1234822.561024967581034, 574.794999999998254],
              [2696548.525761825498194, 1234819.47227071179077, 574.617499999993015],
              [2696545.739505071658641, 1234816.244758665328845, 574.111250000001746],
              [2696558.376975130289793, 1234790.214704549871385, 571.84375],
              [2696561.078214895911515, 1234779.379686064086854, 571.070000000006985],
              [2696564.491934014949948, 1234741.124630345962942, 568.740000000005239],
              [2696566.336924430914223, 1234728.865863032173365, 568.016250000000582],
              [2696569.686919114552438, 1234722.389603373594582, 567.61250000000291],
              [2696600.966871176846325, 1234682.970766541082412, 563.972500000003492],
              [2696605.524363269098103, 1234675.435752218822017, 563.372499999997672],
              [2696602.419361704029143, 1234674.335749479942024, 563.257500000006985],
              [2696609.148082587402314, 1234640.541946901241317, 561.057499999995343],
              [2696609.553078978322446, 1234630.415690321708098, 560.785000000003492],
              [2696612.208068137988448, 1234625.840670844307169, 559.966249999997672],
              [2696614.158037923276424, 1234617.338116695173085, 557.688750000001164],
              [2696611.903021003119648, 1234612.100586439482868, 556.416249999994761],
              [2696611.024252156261355, 1234606.441802711924538, 554.997499999997672],
              [2696611.006702502258122, 1234596.22546384180896, 551.257500000006985],
              [2696608.959192438051105, 1234593.504195868503302, 550.501250000001164],
              [2696610.116656156722456, 1234581.150380861479789, 547.767500000001746],
              [2696607.716631681192666, 1234572.086587066529319, 545.926250000004075],
              [2696606.06787537317723, 1234568.025325787253678, 545.452499999999418],
              [2696605.471612474881113, 1234559.889052653918043, 544.48124999999709],
              [2696605.524083166383207, 1234551.426500172587112, 542.273749999993015],
              [2696601.629080466460437, 1234548.917745400452986, 542.073749999995925],
              [2696600.842822641599923, 1234545.096481392160058, 541.485000000000582],
              [2696600.619068589061499, 1234538.368974087294191, 541.179999999993015],
              [2696594.071557709947228, 1234534.210204731440172, 540.366250000006403],
              [2696589.995304278098047, 1234534.827698688255623, 540.111250000001746],
              [2696584.994051314424723, 1234530.548943454632536, 539.892500000001746],
              [2696582.562800905667245, 1234530.506442772923037, 539.863750000004075],
              [2696577.081538559868932, 1234534.318920862628147, 538.938750000001164],
              [2696572.410281700547785, 1234533.758908698102459, 538.426250000004075],
              [2696565.792773873545229, 1234532.178894827142358, 537.842499999998836],
              [2696561.384018560871482, 1234530.195135409245268, 537.446249999993597],
              [2696558.155259247869253, 1234531.17261883802712, 536.747499999997672],
              [2696554.559003695379943, 1234529.186358968028799, 536.332500000004075],
              [2696548.842741533182561, 1234528.230087328469381, 535.421249999999418],
              [2696544.685237549245358, 1234525.988830282585695, 535.125],
              [2696540.492724873125553, 1234526.12755771400407, 534.173750000001746],
              [2696534.80646549584344, 1234527.208791082492098, 533.472500000003492],
              [2696533.493956984486431, 1234525.238775877980515, 532.832500000004075],
              [2696530.913941259030253, 1234519.349997756304219, 531.649999999994179],
              [2696526.282680667005479, 1234517.982478904770687, 530.85624999999709],
              [2696522.746429732535034, 1234514.014977261191234, 530.788750000006985],
              [2696518.955171377863735, 1234510.618712367257103, 530.162500000005821],
              [2696517.932672212366015, 1234508.128713854122907, 530.226250000006985],
              [2696514.703912731260061, 1234507.197446971433237, 529.514999999999418],
              [2696511.678910824004561, 1234504.474943595007062, 529.373749999998836],
              [2696507.243896419648081, 1234493.894917826866731, 528.292499999995925],
              [2696504.588879932649434, 1234483.79863830213435, 527.052500000005239],
              [2696503.627603372558951, 1234478.268590782303363, 525.052500000005239],
              [2696503.399987640790641, 1234463.542133776238188, 516.335000000006403],
              [2696506.412476760335267, 1234457.309614190133289, 515.51249999999709],
              [2696504.503714823629707, 1234453.555842857109383, 514.615000000005239],
              [2696505.829947219230235, 1234446.680811289232224, 513.287500000005821],
              [2696508.251183587592095, 1234440.032036804128438, 512.258749999993597],
              [2696504.679925268981606, 1234436.737021974055097, 511.634999999994761],
              [2696503.826164509635419, 1234432.910752719966695, 510.82499999999709],
              [2696504.761157981585711, 1234427.626990985125303, 510.332500000004075],
              [2696504.777406754437834, 1234423.021988734370098, 510.240000000005239],
              [2696506.858645362779498, 1234418.536968285217881, 509.380000000004657],
              [2696504.173643642570823, 1234414.670715223997831, 509.252500000002328],
              [2696503.656127025838941, 1234409.375685481121764, 508.001250000001164],
              [2696501.807374734431505, 1234403.523181361146271, 507.830000000001746],
              [2696500.541096711996943, 1234395.421881218440831, 505.720000000001164],
              [2696494.659817215055227, 1234370.093078346224502, 503.502500000002328],
              [2696492.157313121948391, 1234365.591821035603061, 503.196249999993597],
              [2696489.873560892883688, 1234364.523067086702213, 503.029999999998836],
              [2696486.274793097749352, 1234354.73553526122123, 501.692500000004657],
              [2696481.684783507138491, 1234351.51426817686297, 500.973750000004657],
              [2696478.024768517352641, 1234346.30049140099436, 499.847500000003492],
              [2696477.398526760749519, 1234339.990506080677733, 500.46875],
              [2696468.543491802643985, 1234333.237943702144548, 497.842499999998836],
              [2696463.222230978310108, 1234327.799174402374774, 497.03125],
              [2696457.853466867469251, 1234325.872899263864383, 495.972500000003492],
              [2696454.827212520409375, 1234323.500391532666981, 495.647500000006403],
              [2696449.224704447202384, 1234320.560377181274816, 495.04374999999709],
              [2696439.528432719875127, 1234318.916588521096855, 493.414999999993597],
              [2696433.78966764267534, 1234314.46281164418906, 492.283750000002328],
              [2696428.770904965233058, 1234312.735289053991437, 491.332500000004075],
              [2696422.883399624377489, 1234309.510279593756422, 490.934999999997672],
              [2696407.35336526716128, 1234299.172718372894451, 488.358749999999418],
              [2696394.889573310036212, 1234282.746393455658108, 485.207500000004075],
              [2696393.623313541058451, 1234283.25762602314353, 484.472500000003492],
              [2696388.47455592174083, 1234281.305112485075369, 483.902499999996508],
              [2696382.674489411525428, 1234274.674993643537164, 478.896250000005239],
              [2696372.572077409829944, 1234266.873901051934808, 485.533750000002328],
              [2696365.192065584007651, 1234262.393880018265918, 484.648749999993015],
              [2696343.13059453619644, 1234245.540985119296238, 468.011249999995925],
              [2696335.49055162537843, 1234229.113408412085846, 464.783750000002328],
              [2696332.224286930635571, 1234215.889632075559348, 463.678750000006403],
              [2696333.4055160228163, 1234207.977094600908458, 462.102499999993597],
              [2696333.017997041810304, 1234202.540810624370351, 460.672500000000582],
              [2696328.380467434413731, 1234193.205757699673995, 458.445000000006985],
              [2696326.289202347863466, 1234189.958230741322041, 457.309999999997672],
              [2696322.405407686717808, 1234173.730650828918442, 453.947499999994761],
              [2696317.156653570011258, 1234169.834393525263295, 453.641250000000582],
              [2696316.32039209920913, 1234165.585623, 452.777499999996508],
              [2696313.821636221371591, 1234162.756862502079457, 452.336249999993015],
              [2696314.016624512616545, 1234157.795591522706673, 451.453750000000582],
              [2696313.007860615383834, 1234153.645566668128595, 450.407500000001164],
              [2696309.96535207470879, 1234151.836801436729729, 449.766250000000582],
              [2696308.340319231152534, 1234139.432992637855932, 447.292499999995925],
              [2696306.705313925165683, 1234136.42923315661028, 446.89375000000291],
              [2696305.415288087911904, 1234121.086686848197132, 444.947499999994761],
              [2696305.555273341014981, 1234110.261660390300676, 443.836249999993015],
              [2696302.66525780595839, 1234103.956632619025186, 442.667499999995925],
              [2696300.150245174299926, 1234100.479110047919676, 441.717499999998836],
              [2696299.597737386822701, 1234098.009096115129068, 441.131250000005821],
              [2696299.83396763401106, 1234093.68906076066196, 439.642500000001746],
              [2696301.642714650370181, 1234086.417805335950106, 439.416249999994761],
              [2696301.652702390681952, 1234079.272783359512687, 438.492499999993015],
              [2696300.007677724584937, 1234073.222739244811237, 436.634999999994761],
              [2696293.980136578436941, 1234058.578915664227679, 433.538750000006985],
              [2696288.507661197800189, 1234050.492709689307958, 435.397500000006403],
              [2696290.156410118564963, 1234049.418957726331428, 435.315000000002328],
              [2696260.55135365948081, 1234024.121357090072706, 431.08125000000291],
              [2696252.830093700904399, 1234015.831339354161173, 430.336249999993015],
              [2696242.712578, 1234000.413811321603134, 429.160000000003492],
              [2696232.486306010279804, 1233988.757522089174017, 427.509999999994761],
              [2696232.006298440974206, 1233989.205008573597297, 426.940000000002328],
              [2696218.370034117717296, 1233974.858733071014285, 425.869999999995343],
              [2696208.672519317828119, 1233966.673706710804254, 424.761249999995925],
              [2696199.395026101265103, 1233957.919968917034566, 425.278749999997672],
              [2696188.343765382654965, 1233945.397449836367741, 424.478749999994761],
              [2696176.64999708533287, 1233926.696167154470459, 423.107499999998254],
              [2696139.848687566816807, 1233864.232310838997364, 418.646250000005239],
              [2696136.8936862279661, 1233860.504808462224901, 418.547500000000582],
              [2696137.202438569627702, 1233860.116062644170597, 418.723750000004657],
              [2696132.60243907943368, 1233851.721063553122804, 418.764999999999418],
              [2696116.038619548082352, 1233740.024688577046618, 413.533750000002328],
              [2696109.103597139939666, 1233704.98089833301492, 411.848750000004657],
              [2696104.834844266530126, 1233696.113393195671961, 411.634999999994761],
              [2696099.851088385097682, 1233688.368382695829496, 411.195000000006985],
              [2696100.666085659060627, 1233683.480877763358876, 410.988750000004075],
              [2696097.437332497444004, 1233670.980872063664719, 410.752500000002328],
              [2696096.854832360055298, 1233671.150871831690893, 410.742499999993015],
              [2696087.571064510382712, 1233633.830839756410569, 409.402499999996508],
              [2696084.269803409930319, 1233611.25081977294758, 408.567500000004657],
              [2696072.619790456723422, 1233586.020796603290364, 407.598750000004657],
              [2696051.989778855349869, 1233554.880775939906016, 406.73750000000291],
              [2696031.73102287389338, 1233541.13451546151191, 406.30000000000291],
              [2696032.052272211760283, 1233544.313264295225963, 406.25],
              [2696032.842274008318782, 1233546.359517516568303, 406.384999999994761],
              [2696032.114771247841418, 1233553.077012646477669, 406.177500000005239],
              [2696039.706024561543018, 1233564.410768542438745, 406.422500000000582],
              [2696036.756017122417688, 1233567.110755324596539, 405.863750000004075],
              [2696058.50603358168155, 1233581.910784509265795, 407.089999999996508],
              [2695972.493516074493527, 1233537.259504245594144, 405.827499999999418],
              [2695922.914788134396076, 1233631.720795295201242, 407.526249999995343],
              [2695910.203515521716326, 1233628.092005046084523, 405.830000000001746],
              [2695737.587284063920379, 1233677.367041449062526, 407.346250000002328],
              [2695432.456011013127863, 1233805.060756306629628, 405.817500000004657],
              [2695117.094776899088174, 1234020.913291744422168, 407.235000000000582],
              [2695058.306007743347436, 1234066.660758793586865, 405.830000000001746],
              [2695060.656026737764478, 1234069.410792796174064, 407.26249999999709],
              [2695051.70600768411532, 1234061.710758751491085, 405.830000000001746],
              [2695026.999775035772473, 1234097.330790537176654, 407.157500000001164],
              [2694997.156007175799459, 1234121.653259247541428, 405.830000000001746],
              [2694986.732260426040739, 1234131.330765320220962, 406.082500000004075],
              [2694984.108507188502699, 1234132.480759577592835, 405.839999999996508],
              [2694965.287281170021743, 1234153.717053021071479, 407.663750000006985],
              [2694774.693505159113556, 1234193.759509893832728, 405.830000000001746],
              [2694771.742281213868409, 1234200.15205668262206, 407.80000000000291],
              [2694721.356004656758159, 1234277.760760569712147, 405.830000000001746],
              [2694656.683526328299195, 1234287.125800559297204, 407.511249999995925],
              [2694643.528503929264843, 1234371.174511340679601, 405.830000000001746],
              [2694564.656025362201035, 1234520.460802336689085, 407.506250000005821],
              [2694535.627252889797091, 1234493.819512289948761, 405.827499999999418],
              [2694499.406021192204207, 1234549.26079617626965, 407.236250000001746],
              [2694151.205999418161809, 1234673.210763889830559, 405.830000000001746],
              [2694155.356020464096218, 1234707.810801880434155, 407.41874999999709],
              [2693883.689746967982501, 1234838.009515283862129, 405.830000000001746],
              [2693896.068518234882504, 1234845.217053322121501, 407.429999999993015],
              [2693827.92974611138925, 1234862.413264867849648, 405.803750000006403],
              [2694936.216462265700102, 1235964.228834530338645, 534.654999999998836],
              [2695211.379247632808983, 1236539.067839276744053, 650.309999999997672],
              [2695754.67249609157443, 1236501.150676127756014, 611.916249999994761],
              [2695755.866246026009321, 1236501.573175985366106, 611.910000000003492],
              [2695928.096496271900833, 1236367.163618592778221, 630.603749999994761],
              [2696397.644935560412705, 1235842.59054436092265, 606.705000000001746],
              [2696436.586221474222839, 1235793.60935710882768, 609.371249999996508],
              [2696484.588687694165856, 1235705.585544453468174, 606.777499999996508],
              [2696486.587457787711173, 1235688.728080148110166, 608.288750000006985]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: null, gemeinde_B: null, name: null, kanton_KUE: null, kanton_NAM: null },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2675447.203107912559062, 1251229.892992203822359, 388.822499999994761],
              [2675510.504425655119121, 1251369.143119043204933, 394.053750000006403],
              [2675533.073104817885906, 1251264.022985282586887, 388.524999999994179],
              [2675512.403113672509789, 1251257.503002132754773, 389.227499999993597],
              [2675447.203107912559062, 1251229.892992203822359, 388.822499999994761]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 1, gemeinde_B: 1, name: 'Aeugst a.A.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2680492.067825687583536, 1235035.224134394899011, 652.8125],
              [2680380.10896470118314, 1235028.807683761231601, 644.36250000000291],
              [2679773.223788609728217, 1234841.123623814666644, 631.395000000004075],
              [2679572.430229338351637, 1234918.911479901056737, 646.353749999994761],
              [2679180.495276578702033, 1234590.735527497483417, 553.714999999996508],
              [2678238.081240847706795, 1235234.803780165035278, 532.466249999997672],
              [2678532.199277188163251, 1235889.809567284537479, 670.557499999995343],
              [2678511.318038809113204, 1235911.28208950557746, 671.48124999999709],
              [2678206.920251894276589, 1236418.952826159074903, 649.600000000005821],
              [2678220.000393175520003, 1236807.244341115700081, 660.541249999994761],
              [2678253.852732824161649, 1237280.315301049500704, 648.091249999997672],
              [2678549.087911285459995, 1237308.555622454034165, 661.595000000001164],
              [2678466.987876119557768, 1237480.806812122231349, 658.964999999996508],
              [2678110.703893282916397, 1237560.008893341291696, 641.3125],
              [2678583.931331491097808, 1238031.796076762489974, 732.832500000004075],
              [2678576.581313420087099, 1238052.564793979516253, 731.442500000004657],
              [2678572.391321099363267, 1238071.66480853036046, 732.042499999995925],
              [2678570.558815013151616, 1238076.098547434434295, 731.573749999995925],
              [2678865.564679114613682, 1238032.585525173926726, 604.592499999998836],
              [2679179.495770893059671, 1238543.680232409620658, 592.047500000000582],
              [2680806.109609182924032, 1237763.483481205767021, 886.63749999999709],
              [2681090.097516438923776, 1237435.044419528217986, 821.211249999993015],
              [2681136.438224090728909, 1237323.396964280167595, 875.73124999999709],
              [2681137.748167893383652, 1237280.65686041652225, 871.39375000000291],
              [2680604.972456604707986, 1236344.203264603856951, 720.722500000003492],
              [2680401.010227418504655, 1236308.39647313579917, 645.351250000006985],
              [2680403.767728966660798, 1236293.456475715152919, 645.467499999998836],
              [2680584.223972449079156, 1235439.032698049210012, 644.752500000002328],
              [2680492.067825687583536, 1235035.224134394899011, 652.8125]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 12, gemeinde_B: 12, name: 'Rifferswil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2680380.10896470118314, 1235028.807683761231601, 644.36250000000291],
              [2680492.067825687583536, 1235035.224134394899011, 652.8125],
              [2680611.859166665934026, 1234931.228046629112214, 659.703750000000582],
              [2681378.443616500124335, 1234079.525759112089872, 616.432499999995343],
              [2681565.926254070363939, 1233981.673504811245948, 626.842499999998836],
              [2681595.142500158399343, 1233944.508496470516548, 626.509999999994761],
              [2681610.613753617741168, 1233921.276002116268501, 626.759999999994761],
              [2681398.090816396288574, 1233803.613956460496411, 593.26875000000291],
              [2681448.01099710771814, 1233155.616777737624943, 607.136249999995925],
              [2681404.039476952049881, 1232608.980028594378382, 586.339999999996508],
              [2681712.55452116811648, 1232409.530099740019068, 589.442500000004657],
              [2681972.825766114983708, 1232457.732584986137226, 588.80000000000291],
              [2682051.500775055959821, 1232435.660099189728498, 589.411250000004657],
              [2682058.22827502572909, 1232440.170099035836756, 589.402499999996508],
              [2682144.780910273548216, 1232308.182842144975439, 599.729999999995925],
              [2681819.580856372136623, 1232133.358999415999278, 595.896250000005239],
              [2681603.882180912420154, 1232099.197890188079327, 601.853749999994761],
              [2681357.284222585614771, 1231472.408301912713796, 566.747499999997672],
              [2680256.042018909007311, 1232205.038877432700247, 590.691250000003492],
              [2680252.777017553336918, 1232213.713875135406852, 590.589999999996508],
              [2679143.606068663299084, 1232380.747164136962965, 518.271250000005239],
              [2678842.75791024370119, 1232965.438261940144002, 564.337499999994179],
              [2678447.78260929742828, 1233228.290221350267529, 541.407500000001164],
              [2678525.94523893436417, 1233918.849011457525194, 648.196249999993597],
              [2678278.852634278591722, 1234024.455076888669282, 640.35624999999709],
              [2678255.962654159404337, 1234036.80511417076923, 641.921249999999418],
              [2678836.837869153823704, 1234149.236745566595346, 657.947499999994761],
              [2679771.141672013793141, 1234059.418275026371703, 564.397500000006403],
              [2680180.28204142767936, 1234517.657698145136237, 592.557499999995343],
              [2680288.930715654511005, 1234586.507557516917586, 586.600000000005821],
              [2680333.35076598636806, 1234656.606399551732466, 590.445000000006985],
              [2680380.10896470118314, 1235028.807683761231601, 644.36250000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 24, gemeinde_B: 30, name: 'Andelfingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694688.264101347886026, 1272608.442380155669525, 357.392500000001746],
              [2694848.842855294700712, 1272501.374884207500145, 357.591249999997672],
              [2694812.829968120437115, 1272194.938974651508033, 422.757500000006985],
              [2694827.592467219103128, 1272180.007722601760179, 422.678750000006403],
              [2694989.592575361020863, 1272018.215417833300307, 430.725000000005821],
              [2695226.906196158844978, 1271828.460173714673147, 420.801250000004075],
              [2695498.845073917414993, 1271801.159154182299972, 430.247499999997672],
              [2695551.963804740924388, 1271765.885367590002716, 428.76249999999709],
              [2695581.628811754286289, 1271746.339129815809429, 429.270000000004075],
              [2695602.075056350789964, 1271736.994119413197041, 428.847500000003492],
              [2695703.171072120778263, 1271770.476186409592628, 411.113750000004075],
              [2695685.822755310684443, 1271225.050729770911857, 443.779999999998836],
              [2694922.146301159169525, 1271328.500368243083358, 428.929999999993015],
              [2694780.587710017338395, 1271571.865665944758803, 441.032500000001164],
              [2693715.743599101435393, 1271450.80501745454967, 414.509999999994761],
              [2693498.086333442013711, 1270901.499199345707893, 432.378750000003492],
              [2693062.206140577793121, 1271017.042601641034707, 418.092499999998836],
              [2692764.679795032832772, 1271054.95743042556569, 411.063750000001164],
              [2692659.601172292605042, 1271075.642667808104306, 420.777499999996508],
              [2692503.255992230959237, 1271288.724839181872085, 407.247499999997672],
              [2691789.460434840992093, 1271342.293634955771267, 460.277499999996508],
              [2691811.437919973861426, 1271374.967357303481549, 459.133749999993597],
              [2691495.17941115424037, 1271356.841560165164992, 477.678750000006403],
              [2691484.95762238278985, 1271431.75556238158606, 436.797500000000582],
              [2691101.966431942768395, 1271344.794428996741772, 441.597500000003492],
              [2691060.903925874270499, 1271320.130668259924278, 441.166249999994761],
              [2690977.118930396623909, 1271316.24192815204151, 441.571249999993597],
              [2690911.812674241606146, 1271342.024418165208772, 441.152499999996508],
              [2690529.132788322400302, 1271287.703386425971985, 450.103749999994761],
              [2690492.130280887242407, 1271441.771054087672383, 354.556249999994179],
              [2690497.80028037307784, 1271447.707303092582151, 354.513749999998254],
              [2690564.895288106054068, 1271506.768566882237792, 355.0625],
              [2690362.672701972071081, 1271880.988412751117721, 348.645000000004075],
              [2691151.077721354551613, 1271597.460934924660251, 349.642500000001746],
              [2691165.87396953580901, 1271614.232181461062282, 349.496249999996508],
              [2692075.51402345392853, 1273665.859783197520301, 353.092499999998836],
              [2692113.067773499526083, 1273680.452282825019211, 353.073749999995925],
              [2693516.657820357009768, 1272538.143589990213513, 355.753750000003492],
              [2693539.465317650698125, 1272529.447334574302658, 355.535000000003492],
              [2693680.182820347137749, 1272494.542337181745097, 355.653749999997672],
              [2693712.255320043303072, 1272490.794836111133918, 355.611250000001746],
              [2694688.264101347886026, 1272608.442380155669525, 357.392500000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 30, gemeinde_B: 37, name: 'Ossingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694848.842855294700712, 1272501.374884207500145, 357.591249999997672],
              [2694688.264101347886026, 1272608.442380155669525, 357.392500000001746],
              [2695036.121236320585012, 1273726.001517807831988, 424.009999999994761],
              [2695155.446007889229804, 1274009.618596307933331, 406.692500000004657],
              [2695135.107261080294847, 1274076.456103115109727, 406.948749999995925],
              [2695129.407247520983219, 1274112.322328466922045, 405.929999999993015],
              [2694478.616221082396805, 1274470.656505985185504, 423.26875000000291],
              [2693993.538626319728792, 1274651.916340685449541, 416.44999999999709],
              [2694043.81369246263057, 1274803.363963568815961, 421.41874999999709],
              [2693835.300926675088704, 1275754.083483004476875, 401.48750000000291],
              [2693989.395973924547434, 1275745.751067884033546, 404.95625000000291],
              [2693946.557184444740415, 1275895.362246722681448, 402.003750000003492],
              [2694681.426072924863547, 1275873.556240533711389, 411.96875],
              [2694875.682297258637846, 1275751.574938671430573, 409.895000000004075],
              [2695056.506063621491194, 1275679.761215233942494, 411.005000000004657],
              [2695107.498258772306144, 1276699.031722460640594, 482.330000000001746],
              [2695321.625510832294822, 1276735.848759821383283, 463.453750000000582],
              [2695674.34069935977459, 1276770.437852106522769, 477.402499999996508],
              [2695748.540567114017904, 1276777.71260616579093, 467.372499999997672],
              [2695744.46684059407562, 1276729.238899193704128, 469.145000000004075],
              [2695748.653084385674447, 1276725.535137607017532, 468.673750000001746],
              [2695751.668134036939591, 1276607.320228208554909, 472.412500000005821],
              [2696114.383818945847452, 1275790.785419655730948, 429.526249999995343],
              [2696577.582863570190966, 1275539.137202813988551, 451.378750000003492],
              [2696694.176463683601469, 1275369.565672601573169, 440.002500000002328],
              [2697060.184023855254054, 1275363.998276852304116, 444.264999999999418],
              [2697059.26777348248288, 1275366.875776205211878, 444.23750000000291],
              [2697701.515713731758296, 1275888.779080412350595, 476.877500000002328],
              [2697964.199356683529913, 1275988.357629103120416, 468.630000000004657],
              [2698159.925655659288168, 1276029.571465935558081, 472.160000000003492],
              [2698504.030542315449566, 1276000.447500419337302, 463.382500000006985],
              [2698833.015615680720657, 1275939.720128561602905, 468.63749999999709],
              [2698545.903031752444804, 1275337.391223882324994, 462.542499999995925],
              [2698435.466793647501618, 1275529.179999672807753, 463.523749999993015],
              [2698281.321744315326214, 1275349.312410099897534, 459.931249999994179],
              [2698270.375555635429919, 1275207.367521790787578, 464.542499999995925],
              [2698257.619074866641313, 1274767.834593371720985, 447.207500000004075],
              [2698276.85697618778795, 1274572.215329236816615, 477.333750000005239],
              [2697853.636346048209816, 1274269.015425200341269, 430.294999999998254],
              [2698017.072991175111383, 1273610.967392824124545, 459.854999999995925],
              [2698500.088034013286233, 1272112.470154509181157, 368.8125],
              [2697826.763008084148169, 1271615.79386341245845, 367.267500000001746],
              [2697788.718008782248944, 1271627.953865376068279, 367.34375],
              [2696843.076719206757843, 1272511.563813943881541, 364.967499999998836],
              [2696839.842969545163214, 1272510.146314608165994, 364.994999999995343],
              [2696189.789196565747261, 1272384.116281255846843, 363.658750000002328],
              [2696738.979184842668474, 1272874.310005067847669, 362.452499999999418],
              [2696740.322935859672725, 1272894.771257082000375, 362.528749999997672],
              [2696334.012920470442623, 1273465.187489270698279, 361.630000000004657],
              [2696318.965421209577471, 1273473.04624092602171, 361.695000000006985],
              [2696303.244170018006116, 1273479.452489010756835, 361.615000000005239],
              [2696286.987921845167875, 1273484.349992659408599, 361.76249999999709],
              [2694848.842855294700712, 1272501.374884207500145, 357.591249999997672]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 16, gemeinde_B: 22, name: 'Benken', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2690121.580973568372428, 1277676.05614938121289, 407.604999999995925],
              [2690100.074718154035509, 1277718.57614, 407.208750000005239],
              [2690050.182226607576013, 1277783.061157188843936, 407.88749999999709],
              [2689984.668366331607103, 1278001.124704242451116, 399.548750000001746],
              [2689990.07337000221014, 1278021.772211184725165, 399.82499999999709],
              [2689986.390867489390075, 1278027.623456589644775, 399.636249999995925],
              [2690017.479569914750755, 1278057.142117490293458, 395.997499999997672],
              [2690258.651057029608637, 1279086.086314926855266, 413.889999999999418],
              [2689777.070061984006315, 1279476.669303442817181, 433.633749999993597],
              [2689778.931308288592845, 1279484.504296559374779, 433.351250000006985],
              [2690086.089067525230348, 1279718.264777733944356, 452.851250000006985],
              [2690131.132812862284482, 1279709.962268083589152, 452.462499999994179],
              [2690230.889086176175624, 1279706.864809753373265, 454.160000000003492],
              [2690221.686481183860451, 1279676.345863405382261, 446.182499999995343],
              [2690691.724122293293476, 1279809.143619469134137, 456.558749999996508],
              [2691427.451193099841475, 1280270.022641801275313, 518.3125],
              [2691614.494985132943839, 1280262.333966167643666, 521.342499999998836],
              [2691712.389872717205435, 1280328.128755042329431, 512.73124999999709],
              [2692504.656465395353734, 1280276.476876351982355, 538.029999999998836],
              [2692551.258073205593973, 1279671.286285065580159, 565.087499999994179],
              [2692297.566596602555364, 1278346.964777515036985, 453.36250000000291],
              [2692287.747898701112717, 1278286.94112409115769, 457.317500000004657],
              [2692409.511617992073298, 1278211.894813943887129, 454.896250000005239],
              [2692137.893578800372779, 1277708.596311050700024, 414.208750000005239],
              [2691539.598505823407322, 1277597.002434542868286, 409.082500000004075],
              [2691650.69353660242632, 1277408.98998834262602, 411.337499999994179],
              [2691534.092270214110613, 1277337.256209225626662, 410.172500000000582],
              [2690962.900928421877325, 1277638.57355052116327, 403.600000000005821],
              [2690497.907357050571591, 1277423.545139737194404, 417.48750000000291],
              [2690121.580973568372428, 1277676.05614938121289, 407.604999999995925]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 31, gemeinde_B: 38, name: 'Rheinau', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2689986.390867489390075, 1278027.623456589644775, 399.636249999995925],
              [2689990.07337000221014, 1278021.772211184725165, 399.82499999999709],
              [2689984.668366331607103, 1278001.124704242451116, 399.548750000001746],
              [2690050.182226607576013, 1277783.061157188843936, 407.88749999999709],
              [2690100.074718154035509, 1277718.57614, 407.208750000005239],
              [2690121.580973568372428, 1277676.05614938121289, 407.604999999995925],
              [2690045.368437844328582, 1277629.364833605941385, 404.938750000001164],
              [2690193.382642374373972, 1276963.178173266351223, 439.38749999999709],
              [2689462.488149658311158, 1276774.388048242777586, 383.376250000001164],
              [2689154.458329533226788, 1276393.498386071529239, 397.267500000001746],
              [2688882.853274567518383, 1276232.918286613421515, 393.257500000006985],
              [2688829.657966539729387, 1276072.371460817288607, 369.816250000003492],
              [2688872.977950794156641, 1276052.766430574469268, 368.588749999995343],
              [2689379.364277208223939, 1275723.022812659153715, 374.082500000004075],
              [2689514.322911718394607, 1275372.053842382505536, 365.197499999994761],
              [2689316.205456092022359, 1275082.687675982015207, 368.692500000004657],
              [2689178.734177723061293, 1274968.697624329710379, 366.615000000005239],
              [2688572.302968880161643, 1274674.630208330927417, 370.123749999998836],
              [2688649.759157269261777, 1274352.795089640654624, 365.371249999996508],
              [2688761.495791740715504, 1274262.453304439550266, 394.601250000006985],
              [2689031.539387953933328, 1274341.240513812983409, 382.707500000004075],
              [2689060.499397874809802, 1274352.326781935757026, 383.445000000006985],
              [2689080.121900049038231, 1274290.439285173080862, 383.596250000002328],
              [2689075.670637501869351, 1274085.713010206120089, 382.638749999998254],
              [2688743.599539753049612, 1273765.39204692421481, 394.44999999999709],
              [2688775.694160158745944, 1273349.742585339117795, 365.490000000005239],
              [2688439.578022131230682, 1273119.732797585660592, 374.23124999999709],
              [2687856.907909122761339, 1273136.865096038440242, 365.977499999993597],
              [2688055.918290345463902, 1273562.508307783165947, 394.948749999995925],
              [2687884.958282875362784, 1273601.398297009989619, 394.493749999994179],
              [2687576.288891358301044, 1274018.387106887064874, 345.728749999994761],
              [2687590.646391542628407, 1274039.558357172645628, 345.735000000000582],
              [2687624.761391610372812, 1274100.799607240362093, 345.721250000002328],
              [2687653.383892220910639, 1274164.790858431486413, 345.752500000002328],
              [2687676.2951416564174, 1274231.042107526445761, 345.697499999994761],
              [2687053.481399301439524, 1276243.485895875841379, 346.697499999994761],
              [2687044.145148114766926, 1276356.162144617177546, 346.615000000005239],
              [2687004.108907838352025, 1276924.174667620332912, 347.395000000004075],
              [2686984.946406587492675, 1276990.488416057312861, 347.3125],
              [2686999.25894531654194, 1277639.870993262389675, 350.278749999997672],
              [2687390.772692427970469, 1277907.562233954202384, 349.830000000001746],
              [2687461.27394795184955, 1278037.758494212524965, 350.212499999994179],
              [2687471.461443109903485, 1278073.528485249262303, 349.837499999994179],
              [2687478.482695885933936, 1278110.052240625023842, 350.046249999999418],
              [2687482.282693580724299, 1278147.04973651538603, 349.868749999994179],
              [2687525.822696064133197, 1278364.472242137184367, 350.037500000005821],
              [2687661.006485629826784, 1278597.553566020680591, 352.982499999998254],
              [2687831.822728436440229, 1278628.376050179591402, 352.33125000000291],
              [2687892.347732215654105, 1278604.831056164344773, 352.582500000004075],
              [2687950.430229946505278, 1278575.778550824383274, 352.373749999998836],
              [2688221.627734296955168, 1278302.818552838638425, 352.536250000004657],
              [2688250.303982590325177, 1278244.549798777792603, 352.38749999999709],
              [2688289.620239573530853, 1278130.228560402058065, 352.89375000000291],
              [2688313.65523368306458, 1278020.816048195818439, 352.427500000005239],
              [2688326.437737885862589, 1277909.527305028401315, 352.73750000000291],
              [2688322.853983945213258, 1277778.13354673422873, 352.436249999998836],
              [2688315.051486912649125, 1277741.099802124546841, 352.666249999994761],
              [2688272.653982462827116, 1277636.087293657939881, 352.350000000005821],
              [2688252.556482150219381, 1277604.018543132115155, 352.337499999994179],
              [2687933.166482800152153, 1277418.827297787880525, 352.57499999999709],
              [2687699.990227650851011, 1277427.199791723396629, 352.322499999994761],
              [2687541.050308849196881, 1277090.397443691501394, 358.610000000000582],
              [2687871.805316071026027, 1276919.552450844319537, 358.955000000001746],
              [2687955.65531467506662, 1276926.032447, 358.797500000000582],
              [2688039.350317948963493, 1276934.273701908066869, 358.996249999996508],
              [2688206.127816067542881, 1276956.024946004385129, 358.751250000001164],
              [2688591.397822520229965, 1277049.887452884577215, 359.009999999994761],
              [2688729.146570145152509, 1277116.021196828689426, 358.746249999996508],
              [2688794.58407441386953, 1277155.528704108670354, 359.032500000001164],
              [2688858.969070865772665, 1277201.161196834174916, 358.723750000004657],
              [2689026.430328588467091, 1277367.742459969129413, 359.213749999995343],
              [2689325.316573924385011, 1278076.311202046461403, 358.692500000004657],
              [2689334.075329568702728, 1278098.33996263355948, 359.117499999993015],
              [2689383.962825126945972, 1278198.47745433379896, 358.751250000001164],
              [2689440.565325669944286, 1278170.448704273672774, 358.757500000006985],
              [2689465.082825608085841, 1278163.847453724825755, 358.73750000000291],
              [2689986.390867489390075, 1278027.623456589644775, 399.636249999995925]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 36, gemeinde_B: 51, name: 'Bachenbülach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2681709.060095345135778, 1261325.881848672172055, 441.707500000004075],
              [2681655.997508436441422, 1261482.627938767662272, 435.053750000006403],
              [2681816.698718840721995, 1261589.955362885026261, 431.88749999999709],
              [2682019.786258112173527, 1261722.9941837254446, 434.766250000000582],
              [2682530.539819445461035, 1262072.623825552640483, 419.880000000004657],
              [2682567.686162734869868, 1262087.275249095866457, 427.032500000001164],
              [2683276.936026468407363, 1262537.112486354308203, 416.056249999994179],
              [2683712.664761139079928, 1262790.664781907107681, 510.59375],
              [2684313.753190585412085, 1263299.419177545700222, 485.493749999994179],
              [2684956.184218316338956, 1262901.252322483807802, 563.756250000005821],
              [2684957.934212784282863, 1262881.782311925431713, 563.330000000001746],
              [2684959.894214538857341, 1262875.662315078312531, 563.462499999994179],
              [2684992.494218046311289, 1262822.428570248186588, 563.69999999999709],
              [2684624.795863802544773, 1262571.58323636231944, 498.511249999995925],
              [2684896.426819890271872, 1261903.347501256968826, 571.577499999999418],
              [2684374.001984110102057, 1261460.597988953348249, 488.742499999993015],
              [2684326.671964451670647, 1261451.265453252475709, 487.272500000006403],
              [2683899.585789028555155, 1261393.066850031726062, 493.346250000002328],
              [2683404.144985103048384, 1261303.919111996190622, 431.971250000002328],
              [2683404.721236310899258, 1261301.996614223578945, 432.063750000001164],
              [2682865.132417263928801, 1261078.005243273219094, 427.142500000001746],
              [2682758.491331559140235, 1260935.553050108486786, 439.854999999995925],
              [2682983.701203108765185, 1260238.330300446134061, 429.79374999999709],
              [2682971.609954411629587, 1260232.629053036682308, 429.902499999996508],
              [2682874.162464804947376, 1260214.541573982685804, 430.772500000006403],
              [2682733.963701228611171, 1260198.910301069961861, 429.830000000001746],
              [2682345.007610035128891, 1260411.92560599418357, 442.342499999998836],
              [2682088.056357672903687, 1260622.026858245488256, 442.357499999998254],
              [2682137.865059983450919, 1261135.316773060010746, 438.660000000003492],
              [2681746.233856225851923, 1261295.52061803243123, 442.517500000001746],
              [2681709.060095345135778, 1261325.881848672172055, 441.707500000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 45, gemeinde_B: 60, name: 'Höri', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2681655.997508436441422, 1261482.627938767662272, 435.053750000006403],
              [2681709.060095345135778, 1261325.881848672172055, 441.707500000004075],
              [2681691.547603750601411, 1261294.030614401213825, 442.367499999993015],
              [2681424.280011259019375, 1261112.19544491590932, 435.433749999996508],
              [2680817.958492069039494, 1261652.522457363316789, 415.123749999998836],
              [2680784.798486862331629, 1261688.411198513116688, 414.746249999996508],
              [2680692.526057058013976, 1261632.252580954926088, 420.227499999993597],
              [2680368.883499301970005, 1261545.503727818373591, 415.997499999997672],
              [2680130.893548830412328, 1261562.196324862074107, 419.992499999993015],
              [2679456.083396990783513, 1261553.612302141264081, 408.735000000000582],
              [2679426.309671174734831, 1261518.872347681317478, 410.625],
              [2679244.733398957643658, 1261446.894808564335108, 409.032500000001164],
              [2679037.284657001495361, 1261335.446076278109103, 409.797500000000582],
              [2678988.115899196825922, 1261314.53731232159771, 409.227499999993597],
              [2678938.892152006737888, 1261452.238569609588012, 409.482499999998254],
              [2678932.900899281725287, 1261467.827314736088738, 409.276249999995343],
              [2678683.582147888373584, 1262154.367322298698127, 409.358749999999418],
              [2678712.147164083551615, 1262419.427354483865201, 410.598750000004657],
              [2678860.719651072751731, 1262480.44482799945399, 409.488750000004075],
              [2679010.825914441607893, 1262563.954851215239614, 410.419999999998254],
              [2679262.266631932929158, 1263324.502450331812724, 465.756250000005821],
              [2679372.038568489719182, 1263822.737644993234426, 422.108749999999418],
              [2679445.588605223689228, 1263879.643963155569509, 424.897500000006403],
              [2679763.91252821451053, 1263695.435530429473147, 438.032500000001164],
              [2680465.142109165899456, 1263081.242226908449084, 405.146250000005239],
              [2680471.114610858261585, 1263071.217229891568422, 405.272500000006403],
              [2681323.567100285086781, 1263155.130946249701083, 403.869999999995343],
              [2681468.599797987379134, 1262924.522561598336324, 419.003750000003492],
              [2681485.42854624800384, 1262914.747557963943109, 418.857499999998254],
              [2681481.056048677302897, 1262902.546312477206811, 419.047500000000582],
              [2681530.882295658811927, 1262816.017555201426148, 418.777499999996508],
              [2681361.436127573717386, 1262516.277708748122677, 425.20625000000291],
              [2681151.756033257115632, 1262389.26878495584242, 418.08125000000291],
              [2681340.297338389325887, 1261685.002628541085869, 422.181249999994179],
              [2681346.046072538010776, 1261620.746348257176578, 420.953750000000582],
              [2681397.142334294505417, 1261582.566368983825669, 421.822499999994761],
              [2681655.997508436441422, 1261482.627938767662272, 435.053750000006403]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 43, gemeinde_B: 58, name: 'Glattfelden', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2679452.897548392415047, 1270571.487027643015608, 343.332500000004075],
              [2679681.092558928765357, 1270590.954544268781319, 344.011249999995925],
              [2679713.333802694454789, 1270587.314532001735643, 343.509999999994761],
              [2679839.375057673081756, 1270557.193289293907583, 343.818750000005821],
              [2679858.392561702989042, 1270550.312046563718468, 344.118749999994179],
              [2680030.172554752323776, 1270478.643280375050381, 343.477499999993597],
              [2679802.585444059688598, 1270185.034015719313174, 373.691250000003492],
              [2679460.514024921692908, 1270129.41495167161338, 360.832500000004075],
              [2679354.284103310666978, 1270095.526350969681516, 366.960000000006403],
              [2679171.682804407086223, 1270081.647511446615681, 363.292499999995925],
              [2679036.001577365677804, 1270073.337556849932298, 365.153749999997672],
              [2678900.841293194331229, 1270211.997023418080062, 343.251250000001164],
              [2679355.007551788818091, 1270536.475785250542685, 343.652499999996508],
              [2679452.897548392415047, 1270571.487027643015608, 343.332500000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 46, gemeinde_B: 61, name: 'Hüntwangen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2679839.375057673081756, 1270557.193289293907583, 343.818750000005821],
              [2679713.333802694454789, 1270587.314532001735643, 343.509999999994761],
              [2679681.092558928765357, 1270590.954544268781319, 344.011249999995925],
              [2679452.897548392415047, 1270571.487027643015608, 343.332500000004075],
              [2679192.064295197837055, 1271042.302973287412897, 381.927500000005239],
              [2678988.305214438121766, 1271320.967353822430596, 356.475000000005821],
              [2678881.227770905476063, 1271274.023711834801361, 360.910000000003492],
              [2678880.987768271472305, 1271277.848706887569278, 360.70625000000291],
              [2678484.083866286091506, 1272940.074421855155379, 542.820000000006985],
              [2678674.508509477134794, 1273206.633744938531891, 515.029999999998836],
              [2679671.415008034091443, 1272616.865575043018907, 436.747499999997672],
              [2679721.854040615726262, 1272482.026106758974493, 458.547500000000582],
              [2679784.704031739383936, 1272475.116088715614751, 457.8125],
              [2679994.745790559798479, 1272431.228434648597613, 400.388749999998254],
              [2680257.670685081742704, 1272396.529480773489922, 392.0625],
              [2680684.410738913342357, 1272330.793324630707502, 395.928750000006403],
              [2680846.704216425772756, 1272237.12280732463114, 374.79374999999709],
              [2680967.983226095326245, 1271976.009542756248266, 394.740000000005239],
              [2680969.048225735779852, 1271968.100791990291327, 394.711249999993015],
              [2680878.472012917045504, 1271601.399610552703962, 397.632500000006985],
              [2680921.880706467200071, 1271519.819502766476944, 393.246249999996508],
              [2681165.459528704173863, 1271281.530882770661265, 398.647500000006403],
              [2681014.731919427867979, 1270687.103174612158909, 390.307499999995343],
              [2681002.333168255630881, 1270671.405672485008836, 390.225000000005821],
              [2680821.631946058478206, 1270575.863227067980915, 392.488750000004075],
              [2680318.685655319597572, 1270583.231908761896193, 389.681249999994179],
              [2679839.375057673081756, 1270557.193289293907583, 343.818750000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 60, gemeinde_B: 83, name: 'Buchs', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2677170.9969168510288, 1257284.916717084124684, 489.381250000005821],
              [2677296.479327096603811, 1257021.959043689304963, 482.307499999995343],
              [2677297.344317087903619, 1256952.466524234274402, 481.528749999997672],
              [2677106.81185883609578, 1256880.81660559005104, 484.922500000000582],
              [2676710.994933848734945, 1256334.336592727806419, 432.817500000004657],
              [2676376.25617434643209, 1256356.872831192566082, 432.327499999999418],
              [2676169.996209364384413, 1256382.12290087621659, 435.202499999999418],
              [2675820.039796062279493, 1256058.84134784899652, 422.756250000005821],
              [2675741.298589723184705, 1255717.420178191270679, 426.201249999998254],
              [2675581.938584654126316, 1255735.791421694913879, 425.923750000001746],
              [2673988.132230618968606, 1255894.720005976967514, 418.98124999999709],
              [2674000.748491154052317, 1255950.39002606458962, 419.794999999998254],
              [2673719.402226919308305, 1255932.898754106136039, 418.88749999999709],
              [2673720.010985095053911, 1255937.008769524749368, 419.524999999994179],
              [2673787.128480183891952, 1256006.961259699193761, 419.095000000001164],
              [2673965.173485214356333, 1256170.553767439909279, 419.36250000000291],
              [2673854.2397275948897, 1256193.365005261730403, 418.848750000004657],
              [2673705.139914977364242, 1256655.291614979039878, 433.587499999994179],
              [2673448.962360955309123, 1256696.784018215257674, 429.5625],
              [2673446.774862213991582, 1256702.30902067059651, 429.662500000005821],
              [2673447.346111786086112, 1256704.142769872443751, 429.628750000003492],
              [2673881.891403234563768, 1256800.594561853678897, 452.04374999999709],
              [2673895.473898703698069, 1256806.622053115395829, 451.679999999993015],
              [2674024.110929438844323, 1258127.777141417143866, 609.982499999998254],
              [2674086.028500118292868, 1258089.381022622110322, 615.429999999993015],
              [2674390.637116090860218, 1257999.509511328535154, 604.667499999995925],
              [2674489.23614205699414, 1258028.02752907271497, 626.07499999999709],
              [2674742.365166879724711, 1257948.8742890432477, 647.217499999998836],
              [2675077.241278273053467, 1257829.756517837289721, 636.061249999998836],
              [2675151.478834323585033, 1257825.786621317500249, 640.346250000002328],
              [2675280.491292194463313, 1257920.915289910743013, 636.931249999994179],
              [2676359.23966399952769, 1257934.429704283131287, 509.272500000006403],
              [2676373.064736318774521, 1257832.107338726753369, 514.877500000002328],
              [2676472.175865141674876, 1257663.955857360502705, 505.373749999998836],
              [2676801.096059580333531, 1257751.60121623496525, 520.192500000004657],
              [2677170.9969168510288, 1257284.916717084124684, 489.381250000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 68, gemeinde_B: 91, name: 'Niederweningen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2670820.906333356630057, 1259411.617745641153306, 840.932499999995343],
              [2670446.0614870400168, 1259386.083049630746245, 853.508749999993597],
              [2670391.220233612693846, 1259380.534294928656891, 853.317500000004657],
              [2670328.250239555723965, 1259371.684308233205229, 853.872499999997672],
              [2670241.993874176871032, 1259361.229092004708946, 844.941250000003492],
              [2669693.870292552281171, 1259309.014429791364819, 858.931249999994179],
              [2669615.016744528431445, 1259743.805071871727705, 678.571249999993597],
              [2669591.531865992583334, 1259851.412804890889674, 688.139999999999418],
              [2669295.908478541299701, 1261152.913761849747971, 520.563750000001164],
              [2669308.008486614562571, 1261153.063776961993426, 521.1875],
              [2669593.223185985349119, 1261397.25195115339011, 497.330000000001746],
              [2669651.024648001417518, 1261569.07235523709096, 513.933749999996508],
              [2669639.654653990641236, 1261565.75236684223637, 514.413750000006985],
              [2669406.033279727678746, 1261525.493384862085804, 504.85624999999709],
              [2669306.113388909958303, 1261588.264845568453893, 513.522500000006403],
              [2669244.913308603689075, 1261713.195945235900581, 507.26875000000291],
              [2669292.423329467419535, 1261747.775984313571826, 508.867499999993015],
              [2669501.777420704253018, 1262558.656756848096848, 437.326249999998254],
              [2669902.098728527314961, 1262724.265610994771123, 441.566250000003492],
              [2670025.138729095458984, 1262685.695609416346997, 441.516250000000582],
              [2670461.931632528547198, 1262678.143868691520765, 472.822499999994761],
              [2670948.345898470375687, 1263508.532226411392912, 610.837499999994179],
              [2671207.842664294410497, 1264591.438335972838104, 553.083750000005239],
              [2671757.047249917406589, 1264333.866282658884302, 520.132500000006985],
              [2671803.897316180635244, 1264258.796406792011112, 525.274999999994179],
              [2671611.396198945119977, 1262831.499022025847808, 633.663750000006985],
              [2671422.568866668269038, 1261853.089586776914075, 451.207500000004075],
              [2670666.176908610854298, 1260007.479107633465901, 690.247499999997672],
              [2670633.426882554776967, 1260017.610309242270887, 688.242499999993015],
              [2670820.906333356630057, 1259411.617745641153306, 840.932499999995343]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 75, gemeinde_B: 98, name: 'Schleinikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2671803.897316180635244, 1264258.796406792011112, 525.274999999994179],
              [2671898.033755466341972, 1264323.944265190511942, 540.003750000003492],
              [2672184.796394719276577, 1264196.588271852582693, 550.630000000004657],
              [2672236.776179196778685, 1264140.054110669996589, 533.725000000005821],
              [2672583.756199292372912, 1262737.812746134586632, 632.65625],
              [2672497.038934411481023, 1261569.039691995363683, 455.664999999993597],
              [2672521.948909337166697, 1260252.264497654745355, 551.368749999994179],
              [2672916.251138902734965, 1259497.262308394769207, 822.832500000004075],
              [2672782.861427196767181, 1259474.712857582839206, 845.542499999995925],
              [2672693.211486419197172, 1259461.261722413590178, 850.296249999999418],
              [2672448.05012435792014, 1259471.832768992288038, 841.878750000003492],
              [2672000.844172229524702, 1259451.139597776345909, 865.807499999995343],
              [2671959.144134585745633, 1259443.110777825815603, 862.921249999999418],
              [2670820.906333356630057, 1259411.617745641153306, 840.932499999995343],
              [2670633.426882554776967, 1260017.610309242270887, 688.242499999993015],
              [2670666.176908610854298, 1260007.479107633465901, 690.247499999997672],
              [2671422.568866668269038, 1261853.089586776914075, 451.207500000004075],
              [2671611.396198945119977, 1262831.499022025847808, 633.663750000006985],
              [2671803.897316180635244, 1264258.796406792011112, 525.274999999994179]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 72, gemeinde_B: 95, name: 'Regensberg', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2672782.861427196767181, 1259474.712857582839206, 845.542499999995925],
              [2672916.251138902734965, 1259497.262308394769207, 822.832500000004075],
              [2673281.30071139940992, 1259509.37273871852085, 788.955000000001746],
              [2673350.953316312748939, 1259495.26418447541073, 797.054999999993015],
              [2675904.810744238551706, 1259894.94316479912959, 496.508749999993597],
              [2675993.050869322847575, 1259758.213396966923028, 506.15625],
              [2675931.433730951976031, 1259734.574078573612496, 534.326249999998254],
              [2675919.304451647680253, 1259511.960580460261554, 493.176250000004075],
              [2675437.684674620162696, 1258749.954604694619775, 608.179999999993015],
              [2675424.550924164708704, 1258734.789603980490938, 608.157500000001164],
              [2675337.56600925931707, 1258683.512265676632524, 614.867499999993015],
              [2675159.920654417015612, 1258981.335355629678816, 587.432499999995343],
              [2674441.875008810777217, 1258181.964002195047215, 635.227499999993597],
              [2674457.039873192552477, 1258155.218745961785316, 624.641250000000582],
              [2674482.308729659300297, 1258116.590195389231667, 632.911250000004657],
              [2674489.23614205699414, 1258028.02752907271497, 626.07499999999709],
              [2674390.637116090860218, 1257999.509511328535154, 604.667499999995925],
              [2674086.028500118292868, 1258089.381022622110322, 615.429999999993015],
              [2674024.110929438844323, 1258127.777141417143866, 609.982499999998254],
              [2673634.983281021472067, 1259370.955356731778011, 793.923750000001746],
              [2673560.912034868262708, 1259375.136616436298937, 794.321249999993597],
              [2673471.624485864769667, 1259392.297777025029063, 790.613750000004075],
              [2672782.861427196767181, 1259474.712857582839206, 845.542499999995925]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 84, gemeinde_B: 115, name: 'Gossau', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2703645.046678397804499, 1239281.34657270857133, 543.327499999999418],
              [2703687.78289689309895, 1239215.486515169031918, 540.941250000003492],
              [2703914.880473718512803, 1238837.690392487915233, 546.457500000004075],
              [2703882.600501834880561, 1238832.910442987224087, 548.580000000001746],
              [2703604.291619160212576, 1238900.481464246055111, 538.940000000002328],
              [2703537.311792534310371, 1238806.98177228984423, 551.917499999995925],
              [2703034.410329879261553, 1238544.483902564505115, 536.497499999997672],
              [2703063.409022904001176, 1238300.385048149619251, 532.217499999998836],
              [2703473.172892309725285, 1238143.871499762171879, 540.76249999999709],
              [2703346.335222685709596, 1237983.886199760716408, 528.223750000004657],
              [2703092.623155212495476, 1237415.620716111036018, 560.684999999997672],
              [2702566.467516154516488, 1237017.892088901950046, 513.452499999999418],
              [2702108.629748806823045, 1237488.044128079898655, 493.876250000001164],
              [2702051.76760874222964, 1237670.291020619450137, 520.811249999998836],
              [2701655.673563115764409, 1237771.201754089444876, 499.052500000005239],
              [2700919.419686667155474, 1238373.367799499537796, 490.202499999999418],
              [2700907.252215160056949, 1238409.614100843435153, 492.345000000001164],
              [2700517.07059815665707, 1238675.964706925675273, 465.1875],
              [2699992.890435877256095, 1238778.01317805564031, 453.429999999993015],
              [2699685.009151487611234, 1238840.015622803475708, 451.082500000004075],
              [2699699.436702258884907, 1238664.595711526460946, 454.873749999998836],
              [2699170.625384323764592, 1238833.284351325593889, 450.176250000004075],
              [2698245.778632935602218, 1238500.785703655099496, 507.09375],
              [2698241.123523850459605, 1238290.330506510101259, 498.897500000006403],
              [2698242.998527701944113, 1238276.833013218129054, 499.184999999997672],
              [2697383.647033340297639, 1238838.193848226685077, 481.517500000001746],
              [2697572.354571503587067, 1239068.262665251735598, 484.243749999994179],
              [2697679.767107569146901, 1239114.567728233058006, 486.871249999996508],
              [2698464.229163873009384, 1239281.900671052280813, 452.928750000006403],
              [2698094.612755680456758, 1240218.585403149016201, 441.338749999995343],
              [2697965.552770661190152, 1240340.251683372072875, 442.561249999998836],
              [2697773.405256066471338, 1240476.339161870069802, 441.607499999998254],
              [2697644.250262546818703, 1240576.414176692953333, 442.191250000003492],
              [2697648.755263139959425, 1240582.462927727028728, 442.232499999998254],
              [2697656.711511236615479, 1240580.174174154177308, 442.083750000005239],
              [2698175.605548310559243, 1241281.418435969389975, 463.289999999993597],
              [2698260.02047654800117, 1241301.625805981457233, 457.835000000006403],
              [2698385.129285783041269, 1241404.050910821184516, 462.192500000004657],
              [2698434.494569846894592, 1241557.578920467058197, 483.492499999993015],
              [2698462.12179072573781, 1241997.08217391720973, 462.51875000000291],
              [2698743.261175089515746, 1242289.9970585454721, 509.932499999995343],
              [2698746.773676591925323, 1242293.627061204286292, 510.042499999995925],
              [2698962.354700403753668, 1242433.064152987208217, 492.892500000001746],
              [2699495.350219236686826, 1242589.518823260674253, 531.371249999996508],
              [2699682.311286698561162, 1242685.203493580687791, 517.528749999997672],
              [2700167.167873196769506, 1242623.947835043072701, 542.326249999998254],
              [2700238.970422405283898, 1242500.717920165741816, 545.946249999993597],
              [2700766.533997382968664, 1242091.526341640623286, 532.372499999997672],
              [2701089.27939943689853, 1241708.774548510322347, 562.161250000004657],
              [2700986.050256597343832, 1241635.695098570315167, 532.86250000000291],
              [2701567.122011415660381, 1241430.43473457521759, 570.092499999998836],
              [2701629.78069000178948, 1241446.26710600219667, 564.697499999994761],
              [2701843.990791489835829, 1241355.759781167609617, 572.083750000005239],
              [2701941.351766949053854, 1241020.875535905128345, 551.476250000006985],
              [2702363.853308534249663, 1240646.04229173460044, 572.86250000000291],
              [2702527.562855368945748, 1239976.528974168933928, 538.871249999996508],
              [2702869.710469749756157, 1239716.720417457167059, 547.101250000006985],
              [2703264.750370775815099, 1239654.777732530841604, 539.372499999997672],
              [2703599.806689201388508, 1239345.125343554420397, 544.173750000001746],
              [2703645.046678397804499, 1239281.34657270857133, 543.327499999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 86, gemeinde_B: 117, name: 'Hinwil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2703914.880473718512803, 1238837.690392487915233, 546.457500000004075],
              [2703687.78289689309895, 1239215.486515169031918, 540.941250000003492],
              [2703645.046678397804499, 1239281.34657270857133, 543.327499999999418],
              [2703759.306629260536283, 1239424.935234667500481, 539.570000000006985],
              [2704003.930502309463918, 1239792.86045194696635, 548.529999999998836],
              [2703515.247956085950136, 1240234.985385190462694, 545.526249999995343],
              [2703479.798010331112891, 1240324.394233318511397, 549.602499999993597],
              [2703608.880307592451572, 1240982.385127547197044, 534.395000000004075],
              [2704289.694253776688129, 1241698.522969686659053, 548.429999999993015],
              [2704958.060826733708382, 1241024.9622708321549, 571.845000000001164],
              [2705162.843150092288852, 1240823.951950761955231, 558.520000000004075],
              [2705630.779652582481503, 1240861.857388447504491, 576.861250000001746],
              [2705634.374650223180652, 1240862.052384193055332, 576.682499999995343],
              [2705638.078401159960777, 1240864.72863579634577, 576.748749999998836],
              [2706117.064982405863702, 1241647.998935732757673, 693.721250000002328],
              [2706062.754495135042816, 1241946.514327738201246, 657.607499999998254],
              [2706234.164298522286117, 1242707.411200952949002, 735.639999999999418],
              [2706264.495537856128067, 1242681.649930812884122, 734.8125],
              [2706327.654328611679375, 1242660.68000098108314, 737.763749999998254],
              [2706369.13427911978215, 1242666.239912178600207, 734.042499999995925],
              [2706928.744383938144892, 1242322.504790210397914, 833.85624999999709],
              [2706954.108003778848797, 1242203.469557037344202, 824.16874999999709],
              [2707160.773230117280036, 1242096.421199380187318, 840.678750000006403],
              [2707397.799000092316419, 1241983.417840947629884, 804.792499999995925],
              [2708145.029289116151631, 1241790.831783911911771, 917.771250000005239],
              [2708156.669291995000094, 1241781.411788430763409, 917.967499999998836],
              [2708188.739273335319012, 1241759.031753960764036, 916.539999999993597],
              [2708273.168100924231112, 1241724.470637468388304, 922.161250000004657],
              [2708880.555536109954119, 1241614.963000175077468, 916.496249999996508],
              [2709025.82563442690298, 1241602.706918390700594, 923.557499999995343],
              [2709065.645582044031471, 1241576.258074144599959, 919.627500000002328],
              [2709609.96271760109812, 1241600.021819581510499, 1076.59375],
              [2709622.10646380437538, 1241618.823062803829089, 1076.2937499999971],
              [2709625.101464466191828, 1241615.119313774164766, 1076.337499999994179],
              [2710042.30313794221729, 1241132.311881004599854, 922.351250000006985],
              [2711238.007345756050199, 1241004.266998810693622, 770.167499999995925],
              [2709990.554290698841214, 1240715.937954067485407, 915.23750000000291],
              [2709676.220997573807836, 1239927.982204599305987, 1041.732499999998254],
              [2709571.99745678063482, 1239701.400372332660481, 965.01249999999709],
              [2709551.456599983386695, 1239268.020755816716701, 1086.42125],
              [2709852.092196449637413, 1237443.424527288647369, 668.443750000005821],
              [2709256.294002546928823, 1237703.748970926040784, 802.544999999998254],
              [2709027.529035958927125, 1237846.729038857156411, 805.304999999993015],
              [2708894.457598949316889, 1237964.691215762402862, 791.647500000006403],
              [2708819.517807191703469, 1238115.751586795551702, 807.142500000001746],
              [2707595.118517784867436, 1238104.247515744296834, 583.527499999996508],
              [2707136.894366649445146, 1237639.945563170593232, 554.207500000004075],
              [2706918.337288292124867, 1238091.422566975466907, 585.677500000005239],
              [2706700.67064610728994, 1238448.529383623274043, 556.797500000000582],
              [2706601.328270787838846, 1238465.732106145005673, 566.136249999995925],
              [2706295.901752562727779, 1238430.340388534590602, 546.503750000003492],
              [2706104.965565146878362, 1238484.914253786206245, 551.318750000005821],
              [2705626.314205907750875, 1238555.52282158844173, 543.627500000002328],
              [2705522.355550354812294, 1238446.771739642135799, 550.733749999999418],
              [2705511.26804953860119, 1238433.641738280421123, 550.682499999995343],
              [2705406.185325840488076, 1238312.858843468362466, 534.147500000006403],
              [2705131.370429061818868, 1238125.152779773809016, 542.053750000006403],
              [2705093.215426073875278, 1238129.30527533730492, 541.865000000005239],
              [2704894.662664429284632, 1237470.218559353379533, 522.5625],
              [2704852.70265543833375, 1237452.896044119959697, 521.928750000006403],
              [2704713.318983860313892, 1237549.194936764426529, 527.882500000006985],
              [2704473.033849531784654, 1237569.558454113313928, 518.089999999996508],
              [2703914.880473718512803, 1238837.690392487915233, 546.457500000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 85, gemeinde_B: 116, name: 'Grüningen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2698205.996003468986601, 1237961.626717403996736, 497.38749999999709],
              [2698324.547345890663564, 1238131.003132018260658, 504.23750000000291],
              [2698264.287276396062225, 1238243.001760113053024, 499.068750000005821],
              [2698242.998527701944113, 1238276.833013218129054, 499.184999999997672],
              [2698241.123523850459605, 1238290.330506510101259, 498.897500000006403],
              [2698245.778632935602218, 1238500.785703655099496, 507.09375],
              [2699170.625384323764592, 1238833.284351325593889, 450.176250000004075],
              [2699699.436702258884907, 1238664.595711526460946, 454.873749999998836],
              [2699685.009151487611234, 1238840.015622803475708, 451.082500000004075],
              [2699992.890435877256095, 1238778.01317805564031, 453.429999999993015],
              [2700517.07059815665707, 1238675.964706925675273, 465.1875],
              [2700907.252215160056949, 1238409.614100843435153, 492.345000000001164],
              [2700919.419686667155474, 1238373.367799499537796, 490.202499999999418],
              [2701655.673563115764409, 1237771.201754089444876, 499.052500000005239],
              [2702051.76760874222964, 1237670.291020619450137, 520.811249999998836],
              [2702108.629748806823045, 1237488.044128079898655, 493.876250000001164],
              [2702566.467516154516488, 1237017.892088901950046, 513.452499999999418],
              [2702218.026125398464501, 1236635.423092033714056, 503.222500000003492],
              [2701576.086632526014, 1235819.32149678375572, 541.636249999995925],
              [2701464.246719237416983, 1235846.054153439588845, 548.217499999998836],
              [2700951.664941958617419, 1236097.695729830767959, 509.226250000006985],
              [2700956.202443429734558, 1236098.781982370652258, 509.332500000004075],
              [2700915.487701723352075, 1236200.239943475695327, 528.695000000006985],
              [2700941.447693467140198, 1236203.188678288599476, 528.054999999993015],
              [2700659.417585390154272, 1236298.652242905227467, 520.208750000005239],
              [2700266.427756174001843, 1236329.122555212350562, 533.332500000004075],
              [2699882.363660968374461, 1236168.695696777198464, 507.791249999994761],
              [2699493.48527172813192, 1236197.710097641916946, 535.164999999993597],
              [2699338.178800279740244, 1236008.485954217147082, 518.686249999998836],
              [2698986.815268858801574, 1236041.881351434858516, 535.386249999995925],
              [2698982.462754814885557, 1236037.147576445713639, 534.336249999993015],
              [2698947.983985797967762, 1236088.127543807495385, 532.940000000002328],
              [2698656.132893194444478, 1236358.356583680026233, 545.016250000000582],
              [2697854.132321366574615, 1237520.314341223565862, 502.767500000001746],
              [2697854.142321035731584, 1237521.134340636897832, 502.742499999993015],
              [2698188.526179351378232, 1237580.299528206232935, 510.607499999998254],
              [2698205.996003468986601, 1237961.626717403996736, 497.38749999999709]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 118, gemeinde_B: 192, name: 'Egg', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2698242.998527701944113, 1238276.833013218129054, 499.184999999997672],
              [2698264.287276396062225, 1238243.001760113053024, 499.068750000005821],
              [2698324.547345890663564, 1238131.003132018260658, 504.23750000000291],
              [2698205.996003468986601, 1237961.626717403996736, 497.38749999999709],
              [2697571.52463007485494, 1238163.442761099897325, 488.628750000003492],
              [2697335.27477487642318, 1238108.329274366376922, 499.710000000006403],
              [2696876.447084022220224, 1237958.12144000432454, 485.713749999995343],
              [2696277.863514635246247, 1237600.955521739786491, 499.792499999995925],
              [2695966.34834723547101, 1236950.999961711931974, 581.647500000006403],
              [2695062.672909152228385, 1236810.712687785038725, 643.792499999995925],
              [2694183.976410440634936, 1237787.087023248663172, 720.422500000000582],
              [2694181.600159016437829, 1237786.338270734995604, 720.317500000004657],
              [2693296.304251646157354, 1238665.48117824154906, 841.926250000004075],
              [2693140.905337036587298, 1238896.267140332143754, 829.676250000004075],
              [2693015.371761836577207, 1239178.432464255951345, 843.087499999994179],
              [2692750.884209308773279, 1239546.282384159043431, 839.472500000003492],
              [2692581.713022826239467, 1239574.926254767458886, 844.513749999998254],
              [2692525.944206836633384, 1239620.359888192731887, 839.588749999995343],
              [2692520.99546258803457, 1239616.947398673277348, 840.03125],
              [2692067.973109173122793, 1240426.42293177684769, 756.972500000003492],
              [2694628.10164196183905, 1241646.209473513299599, 454.202499999999418],
              [2694730.124045311938971, 1241651.765547493705526, 446.826249999998254],
              [2694752.351766549050808, 1241602.378445376176387, 463.517500000001746],
              [2695173.680169600993395, 1241862.052815002156422, 437.014999999999418],
              [2695408.898937325924635, 1241666.659091005800292, 438.177500000005239],
              [2695682.85267228493467, 1241707.366559464251623, 436.845000000001164],
              [2695659.339069725945592, 1241175.121820333879441, 447.970000000001164],
              [2695852.391520993318409, 1241008.715477675199509, 444.148749999993015],
              [2695730.704056721646339, 1240789.235542078502476, 446.927500000005239],
              [2695567.836153638549149, 1240590.23206646181643, 510.927500000005239],
              [2695503.555763018317521, 1240331.186362549895421, 481.507500000006985],
              [2695508.82326337415725, 1240329.507613075431436, 481.529999999998836],
              [2695512.737011229153723, 1240326.041359103983268, 481.365000000005239],
              [2695513.160768210422248, 1240318.432621585670859, 481.891250000000582],
              [2695558.326998699456453, 1240326.551335704512894, 480.383749999993597],
              [2695657.67846842482686, 1240174.83047732245177, 496.873749999998836],
              [2695904.206803077831864, 1239951.205973885254934, 465.353749999994761],
              [2695935.351793291978538, 1239967.393455878365785, 464.592499999998836],
              [2695966.585900419857353, 1239931.504096574848518, 491.490000000005239],
              [2696108.14210217166692, 1239743.890255261911079, 487.735000000000582],
              [2696342.477153669577092, 1239738.249093121849, 491.427500000005239],
              [2696884.395387900527567, 1239690.586906890152022, 452.162500000005821],
              [2697047.681828174740076, 1239474.350993090076372, 466.35624999999709],
              [2697083.715321625117213, 1239460.973032321082428, 447.021250000005239],
              [2697572.354571503587067, 1239068.262665251735598, 484.243749999994179],
              [2697383.647033340297639, 1238838.193848226685077, 481.517500000001746],
              [2698242.998527701944113, 1238276.833013218129054, 499.184999999997672]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 117, gemeinde_B: 191, name: 'Dübendorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2691667.51271138433367, 1250123.191778533859178, 442.928750000006403],
              [2691544.967763052787632, 1249880.656872709980235, 446.934999999997672],
              [2691540.626510855741799, 1249873.604368718108162, 446.771250000005239],
              [2691483.781518238596618, 1249804.236882573226467, 447.372499999997672],
              [2691236.737721297889948, 1249431.840548037318513, 443.983749999999418],
              [2691323.159089308232069, 1249144.77700891206041, 452.872499999997672],
              [2690897.05259345844388, 1248524.330312811303884, 434.502500000002328],
              [2690887.330094351898879, 1248527.674064645310864, 434.577499999999418],
              [2690222.123834140598774, 1248557.58530808519572, 434.285000000003492],
              [2688988.814446706324816, 1248010.69144447054714, 481.842499999998836],
              [2689055.909572546835989, 1246887.561443800339475, 586.583750000005239],
              [2689052.85832234332338, 1246891.322693543508649, 586.571249999993597],
              [2687674.454186951741576, 1247632.721817351877689, 653.929999999993015],
              [2687105.208365031518042, 1248671.232838640920818, 591.752500000002328],
              [2686907.890877676662058, 1248736.822867288952693, 592.912500000005821],
              [2686906.444626693613827, 1248740.022865551523864, 592.838749999995343],
              [2687206.134245063643903, 1248907.182160660158843, 563.392500000001746],
              [2687164.464273891411722, 1248907.637214467162266, 565.633749999993597],
              [2686706.205414765514433, 1249200.684527930803597, 557.723750000004657],
              [2686573.926988802850246, 1249473.064083628123626, 487.036250000004657],
              [2686417.054155502468348, 1249238.368267839308828, 557.282500000001164],
              [2686407.646612032316625, 1249320.608189134858549, 553.967499999998836],
              [2687261.085103026591241, 1250231.006660671904683, 437.926250000004075],
              [2687390.427638988941908, 1250159.720473683904856, 440.576249999998254],
              [2687400.807637839112431, 1250178.589221558300778, 440.48124999999709],
              [2687400.288889627438039, 1250198.87922504148446, 440.618749999994179],
              [2687320.61121328920126, 1251123.491411067079753, 427.228749999994761],
              [2687444.632481184788048, 1251221.480192626127973, 428.508749999993597],
              [2687449.54373498307541, 1251217.773949469905347, 428.794999999998254],
              [2687502.681192611344159, 1251228.843870829558, 425.521250000005239],
              [2687604.452556766103953, 1251188.496578265912831, 434.163750000006985],
              [2687661.641195571050048, 1251135.005122645292431, 425.631250000005821],
              [2688423.19881228171289, 1251205.934073948534206, 433.988750000004075],
              [2688467.861527041532099, 1251331.558218061923981, 450.335000000006403],
              [2689082.517529844306409, 1251392.352754464838654, 431.042499999995925],
              [2689084.171280389651656, 1251371.574005250353366, 431.082500000004075],
              [2689090.918758049607277, 1251291.27896348037757, 429.373749999998836],
              [2689440.948808344081044, 1251335.214049799134955, 432.952499999999418],
              [2689596.607547227293253, 1251407.887777335010469, 431.994999999995343],
              [2689844.897558234166354, 1251544.445294308010489, 432.65625],
              [2689872.058812459930778, 1251569.124051786959171, 432.958750000005239],
              [2689916.898796508088708, 1251618.279022237751633, 431.713749999995343],
              [2691667.51271138433367, 1250123.191778533859178, 442.928750000006403]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 125, gemeinde_B: 199, name: 'Volketswil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2691540.626510855741799, 1249873.604368718108162, 446.771250000005239],
              [2691544.967763052787632, 1249880.656872709980235, 446.934999999997672],
              [2691667.51271138433367, 1250123.191778533859178, 442.928750000006403],
              [2691681.791458392515779, 1250175.286773299099877, 442.692500000004657],
              [2692533.449573303107172, 1250920.191634930670261, 488.679999999993015],
              [2692724.295671331696212, 1251115.095106329768896, 477.017500000001746],
              [2692735.060726252384484, 1251244.483957427553833, 481.172500000000582],
              [2692816.424463465344161, 1251221.218932328512892, 480.138749999998254],
              [2692851.131069220602512, 1251458.239582002628595, 507.0625],
              [2692873.657199655193835, 1251875.705618024803698, 497.997499999997672],
              [2692906.419770639389753, 1252140.976999427424744, 503.352499999993597],
              [2692907.240994454361498, 1252252.681952838320285, 501.371249999996508],
              [2692981.34595948504284, 1252278.120637882733718, 498.662500000005821],
              [2693084.714763491880149, 1252358.978235044050962, 502.66874999999709],
              [2693096.533510368783027, 1252362.161979145137593, 502.422500000000582],
              [2693154.906021275091916, 1252361.693247833056375, 503.19999999999709],
              [2693189.424758954904974, 1252308.704474139725789, 502.23750000000291],
              [2693474.499940271023661, 1251814.7572933556512, 515.711249999993015],
              [2693955.999727323651314, 1251842.664396350737661, 499.202499999999418],
              [2694436.742609787732363, 1251464.201327829388902, 527.698749999995925],
              [2694499.142599686980247, 1251505.406308593694121, 526.882500000006985],
              [2694877.206454468891025, 1251332.182739038718864, 534.464999999996508],
              [2695133.070071238558739, 1250872.641236868686974, 524.17500000000291],
              [2695862.413017150945961, 1250499.999525541439652, 557.162500000005821],
              [2696447.077909094747156, 1250024.905561742838472, 548.482499999998254],
              [2696443.156749721150845, 1250280.110728698316962, 555.318750000005821],
              [2696425.665419018827379, 1250387.481834211619571, 549.257500000006985],
              [2696534.704235986340791, 1250371.671952856006101, 554.203750000000582],
              [2696714.500095023307949, 1250375.131241576746106, 524.602499999993597],
              [2696716.56009763572365, 1250372.70124624366872, 524.797500000000582],
              [2697016.502521426416934, 1250159.093599894084036, 518.80000000000291],
              [2697151.885126480367035, 1250132.293786722701043, 526.589999999996508],
              [2697572.002485959790647, 1250023.418522923719138, 515.660000000003492],
              [2697380.877064281608909, 1248843.41875979071483, 578.103749999994761],
              [2697421.223308249842376, 1248747.029996857978404, 577.610000000000582],
              [2697426.955811651423573, 1248730.262502663070336, 577.860000000000582],
              [2696997.202404619194567, 1248297.29336966923438, 509.982499999998254],
              [2696928.702494092285633, 1248259.153532223775983, 516.773749999993015],
              [2696796.114815267268568, 1248123.975710580358282, 503.41874999999709],
              [2696701.937301756814122, 1248163.918188493698835, 502.479999999995925],
              [2696618.709818917326629, 1248021.181969679426402, 503.837499999994179],
              [2695454.70450034039095, 1248881.860175160923973, 480.783750000002328],
              [2695413.651997639331967, 1248877.322671019239351, 480.61250000000291],
              [2695341.167005132418126, 1248870.925185929518193, 481.235000000000582],
              [2694557.346889798529446, 1248728.551240584813058, 473.139999999999418],
              [2694551.428140749689192, 1248722.074992358218879, 473.216249999997672],
              [2693746.922753220889717, 1248121.808048758888617, 444.51875000000291],
              [2693582.730274775065482, 1247919.830588965676725, 446.266250000000582],
              [2693564.080257697496563, 1247796.573057172819972, 444.985000000000582],
              [2692845.655200315639377, 1248344.715470927534625, 441.182499999995343],
              [2692428.025150696281344, 1248914.037893362808973, 437.741250000006403],
              [2692220.034010777249932, 1249733.006854941137135, 446.257500000006985],
              [2691955.552714229561388, 1249751.811775121372193, 442.923750000001746],
              [2691540.626510855741799, 1249873.604368718108162, 446.771250000005239]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 111, gemeinde_B: 176, name: 'Lindau', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2693096.533510368783027, 1252362.161979145137593, 502.422500000000582],
              [2693084.714763491880149, 1252358.978235044050962, 502.66874999999709],
              [2692981.34595948504284, 1252278.120637882733718, 498.662500000005821],
              [2692907.240994454361498, 1252252.681952838320285, 501.371249999996508],
              [2692636.369812681339681, 1252503.407085304846987, 506.770000000004075],
              [2692184.367095549125224, 1252695.631700226804242, 490.688750000001164],
              [2692042.868406943511218, 1252886.979317059507594, 495.463749999995343],
              [2692054.518405356444418, 1252888.850563935935497, 495.333750000005239],
              [2692061.943409765139222, 1252912.370572078274563, 495.662500000005821],
              [2692240.761959744617343, 1253086.650205134414136, 480.35624999999709],
              [2692130.383422838058323, 1253276.034348277142271, 496.60624999999709],
              [2691915.084535100497305, 1253440.56410263502039, 486.338749999995343],
              [2691923.612183418124914, 1254021.738129349891096, 497.595000000001164],
              [2691878.925768892746419, 1254274.397832048824057, 485.157500000001164],
              [2692026.309900412335992, 1254576.326029661344364, 513.979999999995925],
              [2692216.117929928470403, 1255100.425749418558553, 553.977499999993597],
              [2692265.389869373291731, 1255528.514727933565155, 511.446249999993597],
              [2692410.550383985042572, 1255755.796918446430936, 550.333750000005239],
              [2692512.990201123990119, 1255867.976583046140149, 536.386249999995925],
              [2692964.626786386128515, 1256126.889688953524455, 561.384999999994761],
              [2692979.036787325982004, 1256106.609690113225952, 561.442500000004657],
              [2692990.326779746916145, 1256085.529675764963031, 560.857499999998254],
              [2693524.103141457308084, 1256558.072373258182779, 568.832500000004075],
              [2693080.032205644063652, 1257379.712967552244663, 593.052500000005239],
              [2693083.295955456327647, 1257380.457967131398618, 593.035000000003492],
              [2693086.938455616589636, 1257380.22671733982861, 593.04374999999709],
              [2693734.738264906685799, 1257889.636359495809302, 578.005000000004657],
              [2693917.208290023729205, 1258098.306403647409752, 579.73750000000291],
              [2693961.428270049393177, 1258143.206366630503908, 578.186249999998836],
              [2694556.552445273846388, 1258163.002346902387217, 515.318750000005821],
              [2694557.819947289768606, 1258158.893600514391437, 515.470000000001164],
              [2695281.517881209030747, 1258225.045847435714677, 453.278749999997672],
              [2695378.64551367610693, 1258094.428586022928357, 463.197499999994761],
              [2695457.252890111412853, 1258166.105859878938645, 453.817500000004657],
              [2695637.98045776411891, 1257696.428475457243621, 458.773749999993015],
              [2695354.883012994658202, 1257446.652107810135931, 557.466249999997672],
              [2695701.49795841705054, 1257159.678470378741622, 458.76249999999709],
              [2695551.594297630246729, 1256426.892378688324243, 465.588749999995343],
              [2695850.538565249182284, 1256227.194563161348924, 504.381250000005821],
              [2695853.076062493957579, 1256224.665808070451021, 504.171249999999418],
              [2695956.220922757871449, 1255988.902823454234749, 587.778749999997672],
              [2695353.439446712844074, 1255084.511390832485631, 476.958750000005239],
              [2695288.781137740239501, 1255141.052195270080119, 510.291249999994761],
              [2695269.261140993330628, 1255118.5722013595514, 510.552500000005239],
              [2695244.121125036850572, 1255090.782172541134059, 509.368749999994179],
              [2694649.27760288445279, 1255374.842602823860943, 527.082500000004075],
              [2694634.257603607606143, 1255374.753854450304061, 527.149999999994179],
              [2694518.518764332868159, 1255316.712443589698523, 520.503750000003492],
              [2693944.861346709076315, 1254952.460101903416216, 527.213749999995343],
              [2693751.711299595423043, 1254418.598764359485358, 523.804999999993015],
              [2693819.350132091902196, 1254203.908911006059498, 529.98124999999709],
              [2693836.30502414656803, 1254023.692461986793205, 521.798750000001746],
              [2693785.600143897812814, 1253994.950180985499173, 530.898749999993015],
              [2693561.299719342961907, 1253096.571902555646375, 498.951249999998254],
              [2693563.919721884652972, 1253093.411907100817189, 499.141250000000582],
              [2693555.139717321377248, 1253058.483148613013327, 498.802500000005239],
              [2693478.311042380984873, 1252825.823284511687234, 504.539999999993597],
              [2693044.355947957374156, 1252803.609370974591002, 497.75],
              [2693061.934949443209916, 1252569.871076574083418, 516.772500000006403],
              [2693015.663495686836541, 1252465.070705087855458, 501.380000000004657],
              [2693096.013510889839381, 1252369.460730177117512, 502.462499999994179],
              [2693096.533510368783027, 1252362.161979145137593, 502.422500000000582]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 135, gemeinde_B: 221, name: 'Hettlingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2695032.182522519025952, 1265470.521511604776606, 426.554999999993015],
              [2694827.387496320530772, 1265452.045216938713565, 424.721250000002328],
              [2694821.066247318405658, 1265450.352718862472102, 424.801250000004075],
              [2694822.949991059955209, 1265466.871457478497177, 424.327499999999418],
              [2694825.392510025762022, 1265555.028993032639846, 425.759999999994761],
              [2694831.894940450787544, 1265697.515116435475647, 420.505000000004657],
              [2694809.033854350913316, 1265908.997919684043154, 432.902499999996508],
              [2694312.389930038247257, 1266540.440113681834191, 420.101250000006985],
              [2694331.360120560973883, 1267190.535469520604238, 434.5],
              [2694007.9625388327986, 1267436.000327064888552, 428.559999999997672],
              [2694008.630069937091321, 1267604.611635820940137, 430.914999999993597],
              [2694014.653822099324316, 1267639.249140005558729, 431.07499999999709],
              [2694184.548778876196593, 1267740.564058327581733, 427.688750000001164],
              [2694109.400253599975258, 1267918.891975020756945, 444.733749999999418],
              [2694102.350243090651929, 1267937.693205965915695, 443.945000000006985],
              [2694127.085258268285543, 1267975.636983818607405, 445.07499999999709],
              [2694222.543896381277591, 1268114.905527334427461, 436.551250000004075],
              [2694499.964031873270869, 1268857.119528579991311, 446.603749999994761],
              [2694592.863960367161781, 1268864.459395281272009, 441.133749999993597],
              [2694664.120242350269109, 1268865.006952880183235, 443.497499999997672],
              [2694766.552729146555066, 1268751.424425662728027, 442.422500000000582],
              [2694775.718980360776186, 1268745.855677683372051, 442.507500000006985],
              [2695488.834376349579543, 1268454.897639967035502, 471.850000000005821],
              [2695838.532939408440143, 1268435.016039595473558, 457.483749999999418],
              [2695847.194188534747809, 1268428.171037767315283, 457.411250000004657],
              [2695876.914192548487335, 1268305.702293436042964, 457.688750000001164],
              [2695937.179185459855944, 1268300.853529236279428, 457.108749999999418],
              [2696122.469277919735759, 1268258.512445151340216, 463.936249999998836],
              [2696595.454077120404691, 1267731.950812885537744, 448.441250000003492],
              [2696481.095323319081217, 1267641.518307178514078, 448.23750000000291],
              [2697188.2810949976556, 1267128.283452857751399, 505.764999999999418],
              [2697099.187490360811353, 1267078.222470424603671, 516.776249999995343],
              [2696719.191529881209135, 1266665.903214196208864, 444.76875000000291],
              [2696423.611432439647615, 1266530.256789724109694, 437.642500000001746],
              [2696340.749026362784207, 1266329.935711536323652, 444.773749999993015],
              [2695750.845066966488957, 1266206.657837047241628, 429.414999999993597],
              [2695748.937579139135778, 1266175.897859142627567, 430.333750000005239],
              [2695178.058766598813236, 1265957.827752493089065, 426.016250000000582],
              [2695180.280033880379051, 1265684.640281750354916, 427.3125],
              [2695032.182522519025952, 1265470.521511604776606, 426.554999999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 142, gemeinde_B: 230, name: 'Winterthur', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694821.066247318405658, 1265450.352718862472102, 424.801250000004075],
              [2694827.387496320530772, 1265452.045216938713565, 424.721250000002328],
              [2695032.182522519025952, 1265470.521511604776606, 426.554999999993015],
              [2695055.095018269028515, 1265453.486503248102963, 426.217499999998836],
              [2694893.711408644448966, 1264390.93175413669087, 436.90625],
              [2694985.160125325899571, 1264404.320441512856632, 434.32499999999709],
              [2694959.521382498089224, 1264358.051704701269045, 434.883749999993597],
              [2695170.710470302030444, 1264193.49981867778115, 460.222500000003492],
              [2695188.309230261482298, 1264211.799836770398542, 460.961249999993015],
              [2695409.392836114391685, 1264465.087070831563324, 449.923750000001746],
              [2696451.223043267149478, 1264377.637429941445589, 464.752500000002328],
              [2697339.172636459115893, 1264190.99395192344673, 527.498749999998836],
              [2697687.696736612357199, 1264850.384807621128857, 459.559999999997672],
              [2697689.766737465746701, 1264848.717309127096087, 459.622499999997672],
              [2698180.37156573869288, 1265400.448241558391601, 446.363750000004075],
              [2698323.222829570062459, 1265392.587014141492546, 447.297500000000582],
              [2698936.055210937280208, 1266790.595332055352628, 531.778749999997672],
              [2699100.888387808576226, 1267195.559286183677614, 488.647500000006403],
              [2699167.712165829725564, 1267129.911835381761193, 490.695000000006985],
              [2699223.744621761143208, 1267156.15300406049937, 487.345000000001164],
              [2699381.357219420839101, 1267068.650678353849798, 494.541249999994761],
              [2700667.411788274999708, 1266792.08486463688314, 461.23124999999709],
              [2700669.649283519480377, 1266788.503605902427807, 460.873749999998836],
              [2700737.03681561909616, 1266679.751162065658718, 463.222500000003492],
              [2700793.043019782751799, 1266500.854825858492404, 459.746249999996508],
              [2701260.928338286932558, 1265818.694139881059527, 483.196249999993597],
              [2701408.469494183082134, 1265707.901464847847819, 476.042499999995925],
              [2700863.98339770687744, 1265338.00175091996789, 487.943750000005821],
              [2700090.269236289896071, 1264431.424758372129872, 457.71875],
              [2700313.219292087014765, 1264040.991102034691721, 461.721250000002328],
              [2700254.131745173595846, 1263885.691016345284879, 458.248749999998836],
              [2700448.175523871555924, 1263910.63106515374966, 460.252500000002328],
              [2700634.410505667328835, 1263591.728525584563613, 458.742499999993015],
              [2701137.855667005758733, 1263565.051308943424374, 470.431249999994179],
              [2701222.629402014892548, 1263613.098780585685745, 469.246249999996508],
              [2701235.455652747768909, 1263619.742531737312675, 469.291249999994761],
              [2702065.075140177737921, 1263303.223849800415337, 523.727499999993597],
              [2702314.527465135790408, 1263357.786027969326824, 510.448749999995925],
              [2702317.602469676639885, 1263346.049786014482379, 510.785000000003492],
              [2702360.63494881382212, 1263271.093496545450762, 509.190000000002328],
              [2702287.599040696397424, 1262968.880364280659705, 534.770000000004075],
              [2701894.254677945747972, 1262933.531761526595801, 489.33125000000291],
              [2701947.084698587656021, 1262829.951796856243163, 490.828750000000582],
              [2700982.045587044674903, 1262192.176153543638065, 464.537500000005821],
              [2701225.779595567379147, 1261578.128877523588017, 577.152499999996508],
              [2701786.429809959139675, 1261350.241986811161041, 499.244999999995343],
              [2701957.948079124093056, 1261060.194622536888346, 556.557499999995343],
              [2702534.936604382004589, 1261044.31170324399136, 539.266250000000582],
              [2703209.523064827080816, 1260923.823317641858011, 554.368749999994179],
              [2703208.554270214401186, 1260727.051984874298796, 551.038750000006985],
              [2703314.484315111301839, 1260750.448313905159011, 554.292499999995925],
              [2703209.072003440931439, 1260413.577402478549629, 568.421249999999418],
              [2703140.625722252763808, 1260392.203597448766232, 566.157500000001164],
              [2703162.530747169163078, 1260262.109890449792147, 567.992499999993015],
              [2703181.507148733828217, 1259619.78741187765263, 672.471250000002328],
              [2703330.869567292742431, 1259234.555001346161589, 573.04374999999709],
              [2702283.30020559579134, 1258350.288910484174266, 528.315000000002328],
              [2701759.454997607506812, 1257942.194791431771591, 513.211249999993015],
              [2701654.335116943810135, 1257886.503758555743843, 522.216249999997672],
              [2701274.319924385286868, 1257745.878416964318603, 508.134999999994761],
              [2701162.068817290011793, 1257663.539926660247147, 518.913750000006985],
              [2700966.718636638019234, 1257592.552103365305811, 505.561249999998836],
              [2700527.660234977956861, 1257427.526490572141483, 531.996249999996508],
              [2700531.769696338567883, 1257331.964265270391479, 491.666249999994761],
              [2699741.558296258561313, 1257518.930260918103158, 481.054999999993015],
              [2699710.228297560941428, 1257537.070264054462314, 481.177500000005239],
              [2699175.02569336257875, 1258389.60509383562021, 473.801250000004075],
              [2699164.635695637203753, 1258388.827598156407475, 473.979999999995925],
              [2698734.21435901382938, 1257554.129941107006744, 467.792499999995925],
              [2697293.584166461601853, 1257560.904618357075378, 454.413750000006985],
              [2696694.991280617192388, 1257411.689724159659818, 614.054999999993015],
              [2696456.453244239091873, 1257497.050004787510261, 573.888749999998254],
              [2696512.151048128958791, 1257026.730550899636, 596.716249999997672],
              [2696469.413257847074419, 1256977.166273207869381, 574.889999999999418],
              [2696399.16974576190114, 1256684.569204184226692, 592.872499999997672],
              [2696266.250160633120686, 1256610.878937063738704, 530.071249999993597],
              [2696295.999987839255482, 1256291.070892182178795, 611.202499999999418],
              [2696258.539976950734854, 1256219.172122397460043, 610.417499999995925],
              [2695853.076062493957579, 1256224.665808070451021, 504.171249999999418],
              [2695850.538565249182284, 1256227.194563161348924, 504.381250000005821],
              [2695551.594297630246729, 1256426.892378688324243, 465.588749999995343],
              [2695701.49795841705054, 1257159.678470378741622, 458.76249999999709],
              [2695354.883012994658202, 1257446.652107810135931, 557.466249999997672],
              [2695637.98045776411891, 1257696.428475457243621, 458.773749999993015],
              [2695457.252890111412853, 1258166.105859878938645, 453.817500000004657],
              [2695378.64551367610693, 1258094.428586022928357, 463.197499999994761],
              [2695281.517881209030747, 1258225.045847435714677, 453.278749999997672],
              [2694557.819947289768606, 1258158.893600514391437, 515.470000000001164],
              [2694679.712972248904407, 1258239.153307426022366, 555.020000000004075],
              [2695097.750081492122263, 1258458.221337416674942, 525.151249999995343],
              [2694784.175541257020086, 1258710.473436441272497, 560.147500000006403],
              [2694787.956104681827128, 1258836.142183719901368, 508.297500000000582],
              [2693895.156528379768133, 1259811.809025750262663, 635.633749999993597],
              [2693056.757769143208861, 1259771.842988599324599, 541.147500000006403],
              [2692579.623356032185256, 1260137.337829705793411, 586.05000000000291],
              [2692461.845473052933812, 1260426.728383013047278, 557.14375000000291],
              [2692077.510116191580892, 1260657.433784668333828, 625.228749999994761],
              [2691872.321940979920328, 1261370.316307508153841, 574.233749999999418],
              [2691902.464702738914639, 1261456.24553901469335, 594.0625],
              [2692009.218428632710129, 1261674.491946843685582, 497.336249999993015],
              [2691922.053449882660061, 1261806.618239023257047, 499.022500000006403],
              [2691902.494129523169249, 1262339.198445632588118, 455.788750000006985],
              [2691814.451045693829656, 1262822.723427931545302, 506.402499999996508],
              [2692082.050801589153707, 1263549.389429595321417, 392.899999999994179],
              [2692709.057119174860418, 1263588.103293973719701, 397.607499999998254],
              [2692752.917104238178581, 1263614.893266003578901, 396.447499999994761],
              [2693045.097274165600538, 1263741.608574635814875, 409.122499999997672],
              [2693091.104622849728912, 1263749.978295775596052, 397.634999999994761],
              [2693506.292303399648517, 1264281.255922460462898, 505.611250000001746],
              [2694406.22622576681897, 1265362.360185758210719, 423.463749999995343],
              [2694817.380003117024899, 1265438.852729469304904, 425.241250000006403],
              [2694821.066247318405658, 1265450.352718862472102, 424.801250000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 3, gemeinde_B: 3, name: 'Bonstetten', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2676751.031250324100256, 1243150.798735305434093, 631.787500000005821],
              [2676835.229743810370564, 1243159.08700739685446, 611.78125],
              [2676840.839743403252214, 1243149.757006389088929, 611.743749999994179],
              [2676911.958533863537014, 1243090.68332895427011, 614.811249999998836],
              [2677622.597394919488579, 1242901.386199103901163, 525.752500000002328],
              [2677847.672425456345081, 1242371.197495120344684, 527.908750000002328],
              [2677998.212401845492423, 1242415.321198662277311, 525.946249999993597],
              [2678357.268794742878526, 1242350.605204572668299, 536.696249999993597],
              [2679018.334996287245303, 1241904.166154556209221, 629.070000000006985],
              [2679003.983738356735557, 1241958.697390958666801, 628.472500000003492],
              [2679007.694990683346987, 1241960.106145181693137, 628.648749999993015],
              [2679022.418761521577835, 1241971.18743344466202, 630.246249999996508],
              [2679034.686229252722114, 1241956.60612335219048, 627.736250000001746],
              [2679146.336397813633084, 1241799.408929798053578, 640.657500000001164],
              [2678983.493369236588478, 1241463.492953643668443, 599.917499999995925],
              [2679374.39022981794551, 1240737.38531150855124, 646.733749999999418],
              [2679642.406659851782024, 1240689.227081516757607, 563.728749999994761],
              [2679005.721520532853901, 1240576.931644089752808, 650.276249999995343],
              [2678991.801517150830477, 1240550.531637858133763, 650.028749999997672],
              [2678976.591562625486404, 1240518.951721755089238, 653.563750000001164],
              [2678851.356315173208714, 1239948.683761071180925, 634.532500000001164],
              [2678971.629893438424915, 1238997.503233046969399, 717.832500000004075],
              [2678095.519769784528762, 1239544.141979749081656, 612.428750000006403],
              [2677796.92405769880861, 1239690.359232622198761, 654.442500000004657],
              [2677505.626346098259091, 1239945.243852444924414, 638.351250000006985],
              [2677292.49019439984113, 1240051.23279105569236, 646.207500000004075],
              [2677248.017668212763965, 1240076.918994110543281, 644.223750000004657],
              [2676536.089584862347692, 1240512.300622469745576, 502.57499999999709],
              [2675810.603065483737737, 1241034.393711902899668, 579.46875],
              [2675776.822527109179646, 1241263.837535800877959, 634.854999999995925],
              [2675881.732586161699146, 1241321.955143417231739, 639.337499999994179],
              [2676333.734714362770319, 1241821.239448649808764, 609.975000000005821],
              [2676340.002782712690532, 1242169.340507165063173, 654.142500000001746],
              [2676451.272684671916068, 1242275.550323763629422, 646.40625],
              [2676726.355299367569387, 1242805.714286266360432, 655.029999999998836],
              [2676826.758723102044314, 1243077.306181949563324, 629.592499999998836],
              [2676775.340016160160303, 1243129.627513801911846, 632.991250000006403],
              [2676751.031250324100256, 1243150.798735305434093, 631.787500000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 144, gemeinde_B: 241, name: 'Aesch ZH', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2676835.229743810370564, 1243159.08700739685446, 611.78125],
              [2676751.031250324100256, 1243150.798735305434093, 631.787500000005821],
              [2676068.919210403226316, 1242543.134599081706256, 668.259999999994761],
              [2675003.625496991444379, 1242233.827367643127218, 574.947499999994761],
              [2674756.487110821995884, 1242219.411799913737923, 603.528749999997672],
              [2674574.940855054650456, 1242197.160543358419091, 603.26249999999709],
              [2673852.103448806796223, 1242052.654483888763934, 611.304999999993015],
              [2673854.517200770787895, 1242075.738237765850499, 611.45625000000291],
              [2673896.106372050009668, 1243378.10170801798813, 546.797500000000582],
              [2673891.626378915738314, 1243389.201721040531993, 547.337499999994179],
              [2673924.50113973300904, 1243921.502530208788812, 528.662500000005821],
              [2674288.860492729116231, 1244040.714897510129958, 575.347500000003492],
              [2674439.73017993895337, 1244026.675560946809128, 550.822499999994761],
              [2674489.190397149417549, 1243998.495964229805395, 567.703750000000582],
              [2674621.67151478305459, 1244165.925716483267024, 557.267500000001746],
              [2674996.046655140351504, 1244283.864720677956939, 567.848750000004657],
              [2675015.582903695758432, 1244275.885967443697155, 567.717499999998836],
              [2675553.620973772369325, 1244395.444691254524514, 514.307499999995343],
              [2675614.12879091873765, 1244192.705277810804546, 538.922500000000582],
              [2675633.738758694380522, 1244174.565217233262956, 536.397500000006403],
              [2675645.068776169791818, 1244160.09524933481589, 537.746249999996508],
              [2675757.118316164240241, 1244179.643141572130844, 501.867499999993015],
              [2675757.060103522147983, 1244435.625393784604967, 543.667499999995925],
              [2675786.420056040398777, 1244434.325304791331291, 539.947499999994761],
              [2676164.665163913276047, 1244111.021743379766122, 547.98124999999709],
              [2676260.433871322311461, 1244092.191661886172369, 544.582500000004075],
              [2676278.543873402290046, 1244087.130415295017883, 544.727499999993597],
              [2676443.130557239055634, 1243827.589964515995234, 578.277499999996508],
              [2676527.950277924537659, 1243795.438193463487551, 556.496249999996508],
              [2676816.421066354028881, 1243225.850893277674913, 617.434999999997672],
              [2676835.229743810370564, 1243159.08700739685446, 611.78125]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 61, gemeinde_B: 84, name: 'Dällikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673890.60477091697976, 1254172.428518034517765, 617.164999999993597],
              [2673802.331087675876915, 1254191.137396008009091, 622.464999999996508],
              [2673740.351041119080037, 1254211.578560296911746, 618.897500000006403],
              [2673729.861042419448495, 1254211.176063003949821, 619.009999999994761],
              [2673651.432581434026361, 1255244.825666542397812, 446.583750000005239],
              [2673644.872590309474617, 1255246.578183382051066, 447.28125],
              [2673988.132230618968606, 1255894.720005976967514, 418.98124999999709],
              [2675581.938584654126316, 1255735.791421694913879, 425.923750000001746],
              [2675635.297334447968751, 1255575.531418928876519, 425.865000000005239],
              [2676414.977458719629794, 1254543.544128583976999, 434.927500000005239],
              [2676410.021205038763583, 1254529.915371657814831, 434.645000000004075],
              [2676414.577455533202738, 1254524.906622462673113, 434.679999999993015],
              [2675889.170577607117593, 1253538.056379568297416, 580.618749999994179],
              [2675859.826852086465806, 1253551.955176287563518, 582.551250000004075],
              [2675782.954308866988868, 1253600.883847727207467, 579.266250000000582],
              [2674737.961009728722274, 1253845.744721622904763, 615.411250000004657],
              [2674313.7383206570521, 1253983.781878859037533, 601.11250000000291],
              [2674087.64869825495407, 1254089.33884527022019, 630.788750000006985],
              [2673890.60477091697976, 1254172.428518034517765, 617.164999999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 149, gemeinde_B: 246, name: 'Oetwil a.d.L.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673729.861042419448495, 1254211.176063003949821, 619.009999999994761],
              [2673740.351041119080037, 1254211.578560296911746, 618.897500000006403],
              [2673802.331087675876915, 1254191.137396008009091, 622.464999999996508],
              [2673890.60477091697976, 1254172.428518034517765, 617.164999999993597],
              [2673787.288183738943189, 1253664.31178135285154, 493.432499999995343],
              [2673775.439438129309565, 1253669.739289899822325, 493.785000000003492],
              [2673774.165687156375498, 1253664.139288041042164, 493.710000000006403],
              [2673462.131787849590182, 1253591.466512979241088, 482.316250000003492],
              [2673152.048267967998981, 1253446.526950627099723, 500.538750000006985],
              [2672711.345475930720568, 1252766.714051812654361, 380.392500000001746],
              [2672620.266722894273698, 1252909.574048716109246, 380.21875],
              [2672157.264224580023438, 1253233.670312029542401, 380.664999999993597],
              [2671993.437967647332698, 1253291.39280209178105, 380.232499999998254],
              [2671963.407976575195789, 1253300.901569484034553, 380.951249999998254],
              [2671751.562964137643576, 1253422.972800469724461, 380.121249999996508],
              [2671722.907971061300486, 1253434.495314073981717, 380.682499999995343],
              [2671683.884214004036039, 1253444.962801497895271, 380.15625],
              [2671649.970465280115604, 1253449.649054497946054, 380.278749999997672],
              [2671632.869213601574302, 1253450.534051612950861, 380.158750000002328],
              [2671600.784214463084936, 1253449.962803755654022, 380.247499999997672],
              [2671293.967972458340228, 1253457.316573895514011, 381.077499999999418],
              [2671271.011709258425981, 1253461.140299394726753, 380.058749999996508],
              [2670816.524223292246461, 1253601.582834432134405, 381.463749999995343],
              [2670695.515456791501492, 1253678.256555857369676, 380.252500000002328],
              [2670619.540465041063726, 1253897.957824448356405, 380.955000000001746],
              [2670616.745458314660937, 1253915.102811928605661, 380.429999999993015],
              [2670798.108402834273875, 1254093.173650061246008, 415.15625],
              [2671082.705792506691068, 1253977.578435746720061, 406.304999999993015],
              [2671133.710888843517751, 1254030.142367182765156, 413.817500000004657],
              [2671234.870850905310363, 1253995.564793496392667, 410.772500000006403],
              [2671312.950798047240824, 1254220.029553753091022, 504.482499999998254],
              [2671318.98829887714237, 1254221.595805210759863, 504.542499999995925],
              [2671589.115559631492943, 1254261.260348530020565, 485.58125000000291],
              [2672377.92369384272024, 1254253.527777591953054, 534.516250000000582],
              [2672455.4011364961043, 1254243.335167711600661, 529.962499999994179],
              [2672481.787404872011393, 1254282.053952174494043, 531.376250000001164],
              [2672792.38982965471223, 1254461.44616001448594, 622.898749999993015],
              [2672933.014768876601011, 1254411.294791355263442, 618.002500000002328],
              [2673047.403689188882709, 1254369.943858581827953, 631.186249999998836],
              [2673506.257262998726219, 1254302.983514460967854, 616.947499999994761],
              [2673573.831081174779683, 1254261.48864046880044, 622.197499999994761],
              [2673729.861042419448495, 1254211.176063003949821, 619.009999999994761]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 2, gemeinde_B: 2, name: 'Affoltern a.A.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673825.721559311728925, 1238537.965931988554075, 463.84375],
              [2674244.576798630412668, 1238776.760120493592694, 482.17500000000291],
              [2674355.446260163094848, 1239037.915371282724664, 440.117499999993015],
              [2674883.067559211980551, 1239320.295274294447154, 540.901249999995343],
              [2674910.635055150836706, 1239338.357766346540302, 540.559999999997672],
              [2675920.837983048520982, 1238381.46996199991554, 475.693750000005821],
              [2676455.136878177989274, 1238217.425218309508637, 486.533750000002328],
              [2676563.424327739048749, 1238052.78012126381509, 482.522500000006403],
              [2677032.289625951554626, 1238334.825475237099454, 602.322499999994761],
              [2677238.965675292070955, 1238225.081348317908123, 586.541249999994761],
              [2678510.805233580991626, 1238544.260116766672581, 744.711249999993015],
              [2678522.765217337757349, 1238539.690086409682408, 743.438750000001164],
              [2678522.764041045214981, 1238446.871470906538889, 749.145000000004075],
              [2678563.793813792522997, 1238092.469795623328537, 731.48750000000291],
              [2678570.558815013151616, 1238076.098547434434295, 731.573749999995925],
              [2678572.391321099363267, 1238071.66480853036046, 732.042499999995925],
              [2678576.581313420087099, 1238052.564793979516253, 731.442500000004657],
              [2678583.931331491097808, 1238031.796076762489974, 732.832500000004075],
              [2678110.703893282916397, 1237560.008893341291696, 641.3125],
              [2678466.987876119557768, 1237480.806812122231349, 658.964999999996508],
              [2678549.087911285459995, 1237308.555622454034165, 661.595000000001164],
              [2678253.852732824161649, 1237280.315301049500704, 648.091249999997672],
              [2678220.000393175520003, 1236807.244341115700081, 660.541249999994761],
              [2678206.920251894276589, 1236418.952826159074903, 649.600000000005821],
              [2678511.318038809113204, 1235911.28208950557746, 671.48124999999709],
              [2678532.199277188163251, 1235889.809567284537479, 670.557499999995343],
              [2678238.081240847706795, 1235234.803780165035278, 532.466249999997672],
              [2677935.47740074666217, 1235370.854872325900942, 525.756250000005821],
              [2677625.744075390510261, 1235223.695659262128174, 558.958750000005239],
              [2677371.512479654978961, 1235377.221279793418944, 532.377500000002328],
              [2677367.438728381413966, 1235383.865027613006532, 532.282500000001164],
              [2677350.079982287250459, 1235402.488785378169268, 532.601250000006985],
              [2676588.222300521563739, 1235749.365970243001357, 519.173750000001746],
              [2676496.32233814895153, 1235645.236040506046265, 522.17500000000291],
              [2676170.709386516362429, 1235521.842712399084121, 487.354999999995925],
              [2676119.074398782104254, 1235522.57273608003743, 488.351250000006985],
              [2676031.360595390200615, 1235672.072640735423192, 484.276249999995343],
              [2675801.305702599696815, 1236006.662846737774089, 492.811249999998836],
              [2675064.8479492245242, 1236971.203652301570401, 473.721250000002328],
              [2674713.994672157336026, 1236941.745784864062443, 510.847500000003492],
              [2674699.07354731252417, 1237008.269767710706219, 520.61250000000291],
              [2674238.749311815481633, 1238054.283887908561155, 483.191250000003492],
              [2674192.480664753355086, 1238259.2140818904154, 491.259999999994761],
              [2674203.141888581681997, 1238351.731534037273377, 489.212499999994179],
              [2674193.101900862995535, 1238468.311558209359646, 490.181249999994179],
              [2673827.134058751631528, 1238535.860930894268677, 463.798750000001746],
              [2673825.721559311728925, 1238537.965931988554075, 463.84375]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 4, gemeinde_B: 4, name: 'Hausen a.A.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2686238.398785303346813, 1230372.574888260103762, 528.692500000004657],
              [2685248.373647837433964, 1230464.391926944255829, 614.827499999999418],
              [2684927.367295331321657, 1230471.386748890858144, 607.292499999995925],
              [2684463.981219178531319, 1230855.994580369442701, 621.104999999995925],
              [2684023.828309726435691, 1230710.046345516573638, 590.11250000000291],
              [2683752.827414753381163, 1230699.649496944388375, 617.648749999993015],
              [2683678.497401445172727, 1230712.561974727315828, 616.702499999999418],
              [2683714.556197297293693, 1230845.334558847825974, 620.191250000003492],
              [2683233.06856488250196, 1231439.270587109494954, 610.517500000001746],
              [2683390.950895498506725, 1231574.057776735397056, 597.342499999998836],
              [2683626.519674970768392, 1231552.799074503593147, 599.373749999998836],
              [2682161.233261366374791, 1232561.477573133306578, 588.252500000002328],
              [2682133.832031871192157, 1232576.630111373029649, 589.858749999999418],
              [2682051.500775055959821, 1232435.660099189728498, 589.411250000004657],
              [2681972.825766114983708, 1232457.732584986137226, 588.80000000000291],
              [2681712.55452116811648, 1232409.530099740019068, 589.442500000004657],
              [2681404.039476952049881, 1232608.980028594378382, 586.339999999996508],
              [2681448.01099710771814, 1233155.616777737624943, 607.136249999995925],
              [2681398.090816396288574, 1233803.613956460496411, 593.26875000000291],
              [2681610.613753617741168, 1233921.276002116268501, 626.759999999994761],
              [2681595.142500158399343, 1233944.508496470516548, 626.509999999994761],
              [2681565.926254070363939, 1233981.673504811245948, 626.842499999998836],
              [2681378.443616500124335, 1234079.525759112089872, 616.432499999995343],
              [2680611.859166665934026, 1234931.228046629112214, 659.703750000000582],
              [2680492.067825687583536, 1235035.224134394899011, 652.8125],
              [2680584.223972449079156, 1235439.032698049210012, 644.752500000002328],
              [2680403.767728966660798, 1236293.456475715152919, 645.467499999998836],
              [2680401.010227418504655, 1236308.39647313579917, 645.351250000006985],
              [2680604.972456604707986, 1236344.203264603856951, 720.722500000003492],
              [2680606.252316275145859, 1236316.7830067621544, 709.88749999999709],
              [2680961.512845716904849, 1236163.59646516176872, 750.317500000004657],
              [2681345.897326724603772, 1236225.511753887403756, 709.828750000000582],
              [2681462.48449590196833, 1236325.089685917599127, 780.557499999995343],
              [2681460.06450138380751, 1236301.319695660611615, 780.982499999998254],
              [2681549.68290095590055, 1235972.550296121276915, 753.848750000004657],
              [2681748.60182428592816, 1235630.073102312162519, 766.952499999999418],
              [2681820.040500247851014, 1235605.504214299144223, 761.157500000001164],
              [2681840.899318861309439, 1235580.084338841028512, 766.417499999995925],
              [2681868.839262179099023, 1235555.075483911670744, 762.014999999999418],
              [2681901.248055937234312, 1235523.925562530290335, 765.345000000001164],
              [2681926.594297123607248, 1235491.128045148449019, 764.633749999993597],
              [2682030.186670519877225, 1235622.728849083650857, 851.038750000006985],
              [2682041.407922839280218, 1235643.718853315338492, 851.202499999999418],
              [2682056.216662389226258, 1235661.00883399927989, 850.377500000002328],
              [2682259.794383872300386, 1235761.262984, 867.152499999996508],
              [2682290.562776445411146, 1235720.782328634290025, 839.587499999994179],
              [2682733.572033787146211, 1235615.921989467227831, 878.017500000001746],
              [2683012.221041508950293, 1235031.100158240413293, 801.291249999994761],
              [2683168.290021671913564, 1234661.317848346661776, 914.892500000001746],
              [2683473.330795710440725, 1234317.628213500371203, 877.852499999993597],
              [2683729.603707373142242, 1233929.026448135264218, 909.10624999999709],
              [2683915.323392428457737, 1233635.449612789554521, 884.63749999999709],
              [2684371.151072307024151, 1233259.043667872902006, 897.782500000001164],
              [2684387.371069609653205, 1233223.627411772031337, 897.551250000004075],
              [2684739.387932031415403, 1232943.663735626731068, 848.128750000003492],
              [2684946.54817851446569, 1232611.966671060072258, 866.73750000000291],
              [2685084.978031269274652, 1232272.680143233854324, 855.247499999997672],
              [2685092.801799613982439, 1232250.160175910685211, 856.642500000001746],
              [2686444.272477173712105, 1230696.281032203231007, 524.072499999994761],
              [2686238.398785303346813, 1230372.574888260103762, 528.692500000004657]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 5, gemeinde_B: 5, name: 'Hedingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2674910.635055150836706, 1239338.357766346540302, 540.559999999997672],
              [2675261.793542584404349, 1240707.572106540901586, 617.132500000006985],
              [2675810.603065483737737, 1241034.393711902899668, 579.46875],
              [2676536.089584862347692, 1240512.300622469745576, 502.57499999999709],
              [2677248.017668212763965, 1240076.918994110543281, 644.223750000004657],
              [2677292.49019439984113, 1240051.23279105569236, 646.207500000004075],
              [2677505.626346098259091, 1239945.243852444924414, 638.351250000006985],
              [2677796.92405769880861, 1239690.359232622198761, 654.442500000004657],
              [2678095.519769784528762, 1239544.141979749081656, 612.428750000006403],
              [2678971.629893438424915, 1238997.503233046969399, 717.832500000004075],
              [2678910.999977656640112, 1238981.123389759333804, 724.422500000000582],
              [2678843.548293482977897, 1238886.113840077072382, 690.892500000001746],
              [2678549.616477601230145, 1238620.580105738481507, 744.202499999999418],
              [2678522.765217337757349, 1238539.690086409682408, 743.438750000001164],
              [2678510.805233580991626, 1238544.260116766672581, 744.711249999993015],
              [2677238.965675292070955, 1238225.081348317908123, 586.541249999994761],
              [2677032.289625951554626, 1238334.825475237099454, 602.322499999994761],
              [2676563.424327739048749, 1238052.78012126381509, 482.522500000006403],
              [2676455.136878177989274, 1238217.425218309508637, 486.533750000002328],
              [2675920.837983048520982, 1238381.46996199991554, 475.693750000005821],
              [2674910.635055150836706, 1239338.357766346540302, 540.559999999997672]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 6, gemeinde_B: 6, name: 'Kappel a.A.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2683678.497401445172727, 1230712.561974727315828, 616.702499999999418],
              [2683144.696614080108702, 1230544.254303562920541, 556.702499999999418],
              [2682944.329259033780545, 1230638.310822826810181, 568.038750000006985],
              [2682851.234210602939129, 1230707.52948758425191, 564.402499999996508],
              [2682849.782961070071906, 1230708.421988479793072, 564.440000000002328],
              [2682749.307174724061042, 1230200.950554187176749, 504.024999999994179],
              [2681670.834364376962185, 1230413.304791099391878, 577.354999999995925],
              [2680840.1723045501858, 1229753.625825187657028, 515.648749999993015],
              [2680500.380092467181385, 1229533.207605332368985, 538.166249999994761],
              [2680204.881204123608768, 1229577.411109608132392, 527.747499999997672],
              [2680176.898704742547125, 1229568.204861230915412, 527.820000000006985],
              [2679594.786645507439971, 1229414.874643235933036, 465.767500000001746],
              [2679582.194178378675133, 1229407.283453466836363, 468.318750000005821],
              [2679318.356599705293775, 1230161.02207187213935, 462.460000000006403],
              [2679294.997876306530088, 1230231.660871649626642, 464.537500000005821],
              [2679290.969121199566871, 1230249.539612560067326, 464.146250000005239],
              [2679297.429117663297802, 1230244.648355921497568, 463.867499999993015],
              [2679576.188478806056082, 1230348.010719237616286, 510.901249999995343],
              [2679545.654704834800214, 1230508.640677697490901, 509.077499999999418],
              [2679632.316058196127415, 1230602.944615941029042, 516.996249999996508],
              [2679738.199598953593522, 1231171.070486947195604, 500.743749999994179],
              [2679360.393764819949865, 1231789.155012566130608, 533.240000000005239],
              [2679059.102248806972057, 1232178.72078573377803, 512.934999999997672],
              [2679143.606068663299084, 1232380.747164136962965, 518.271250000005239],
              [2680252.777017553336918, 1232213.713875135406852, 590.589999999996508],
              [2680256.042018909007311, 1232205.038877432700247, 590.691250000003492],
              [2681357.284222585614771, 1231472.408301912713796, 566.747499999997672],
              [2681603.882180912420154, 1232099.197890188079327, 601.853749999994761],
              [2681819.580856372136623, 1232133.358999415999278, 595.896250000005239],
              [2682144.780910273548216, 1232308.182842144975439, 599.729999999995925],
              [2682058.22827502572909, 1232440.170099035836756, 589.402499999996508],
              [2682051.500775055959821, 1232435.660099189728498, 589.411250000004657],
              [2682133.832031871192157, 1232576.630111373029649, 589.858749999999418],
              [2682161.233261366374791, 1232561.477573133306578, 588.252500000002328],
              [2683626.519674970768392, 1231552.799074503593147, 599.373749999998836],
              [2683390.950895498506725, 1231574.057776735397056, 597.342499999998836],
              [2683233.06856488250196, 1231439.270587109494954, 610.517500000001746],
              [2683714.556197297293693, 1230845.334558847825974, 620.191250000003492],
              [2683678.497401445172727, 1230712.561974727315828, 616.702499999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 7, gemeinde_B: 7, name: 'Knonau', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2679582.194178378675133, 1229407.283453466836363, 468.318750000005821],
              [2679450.191937946714461, 1229315.057679475052282, 488.493749999994179],
              [2678370.998830355703831, 1229336.416588771622628, 442.321249999993597],
              [2678423.768850015476346, 1229370.826624119887128, 443.804999999993015],
              [2678135.998781333677471, 1229676.157756343949586, 438.707500000004075],
              [2677364.756581037770957, 1229925.422072298126295, 462.542499999995925],
              [2676330.893564864061773, 1230621.83739960170351, 423.26249999999709],
              [2676345.433647352736443, 1230761.927552249049768, 429.664999999993597],
              [2676333.81362423254177, 1230848.317510704975575, 427.878750000003492],
              [2676311.123650745488703, 1230923.787560538621619, 429.957500000004075],
              [2676376.25347103504464, 1231048.235980031779036, 415.948749999995925],
              [2676358.95349450269714, 1231073.42602369771339, 417.785000000003492],
              [2676080.897185842506588, 1231632.020925416843966, 413.4375],
              [2676053.553487060591578, 1231691.046020678244531, 417.440000000002328],
              [2675877.144666071981192, 1231855.333394430344924, 412.05000000000291],
              [2675729.975375284906477, 1232362.302208153065294, 467.328750000000582],
              [2675637.505108285695314, 1232551.730469615431502, 446.636249999995925],
              [2675665.356425809673965, 1232714.870595084503293, 451.871249999996508],
              [2675655.598878047894686, 1232999.719259872101247, 448.169999999998254],
              [2675756.920332272537053, 1233045.757134933024645, 463.977499999993597],
              [2675847.928841074928641, 1233103.584189128130674, 445.149999999994179],
              [2676033.437734188046306, 1232776.315696381730959, 456.127500000002328],
              [2676215.510115677025169, 1232887.841725716833025, 446.777499999996508],
              [2676453.970167530700564, 1232377.919312050566077, 450.61250000000291],
              [2676845.584262125194073, 1232376.539938654983416, 477.067500000004657],
              [2677212.753919546026736, 1231989.456798103405163, 450.177500000005239],
              [2677532.82554467022419, 1232014.812481510918587, 479.027499999996508],
              [2678040.847482164856046, 1231392.367683494230732, 435.007500000006985],
              [2678976.07648483524099, 1230692.889373095240444, 453.851250000006985],
              [2678977.696477893274277, 1230683.526860267156735, 453.3125],
              [2678988.910230172332376, 1230663.408114046324044, 453.479999999995925],
              [2679128.052971940021962, 1230594.577303327387199, 472.076249999998254],
              [2679283.065359638538212, 1230332.147092349128798, 463.259999999994761],
              [2679290.969121199566871, 1230249.539612560067326, 464.146250000005239],
              [2679294.997876306530088, 1230231.660871649626642, 464.537500000005821],
              [2679318.356599705293775, 1230161.02207187213935, 462.460000000006403],
              [2679582.194178378675133, 1229407.283453466836363, 468.318750000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 8, gemeinde_B: 8, name: 'Maschwanden', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2676345.433647352736443, 1230761.927552249049768, 429.664999999993597],
              [2676191.84247320285067, 1230725.467694125836715, 435.672500000000582],
              [2675530.894455974921584, 1230795.204254436306655, 395.92500000000291],
              [2675503.885717079043388, 1230785.839275250444189, 396.807499999995343],
              [2674455.549359329976141, 1230731.486593464622274, 389.128750000003492],
              [2674088.235580851789564, 1231888.940306326607242, 387.182499999995343],
              [2674057.401832914911211, 1231974.337811335688457, 387.366250000006403],
              [2673853.655570753850043, 1232969.78780027735047, 386.577499999999418],
              [2673845.355572330765426, 1232986.537803462706506, 386.70625000000291],
              [2673904.175908222794533, 1233218.937175531173125, 412.872499999997672],
              [2673861.853377752006054, 1233292.737120544537902, 410.527499999996508],
              [2673884.585881125181913, 1233305.457126503344625, 410.774999999994179],
              [2673977.717106075957417, 1233356.427079023560509, 408.756250000005821],
              [2675059.530323483981192, 1233306.043384498916566, 463.851250000006985],
              [2675444.922586584929377, 1233439.289191340096295, 445.115000000005239],
              [2675585.311245761346072, 1233279.697769775986671, 437.938750000001164],
              [2675655.598878047894686, 1232999.719259872101247, 448.169999999998254],
              [2675665.356425809673965, 1232714.870595084503293, 451.871249999996508],
              [2675637.505108285695314, 1232551.730469615431502, 446.636249999995925],
              [2675729.975375284906477, 1232362.302208153065294, 467.328750000000582],
              [2675877.144666071981192, 1231855.333394430344924, 412.05000000000291],
              [2676053.553487060591578, 1231691.046020678244531, 417.440000000002328],
              [2676080.897185842506588, 1231632.020925416843966, 413.4375],
              [2676358.95349450269714, 1231073.42602369771339, 417.785000000003492],
              [2676376.25347103504464, 1231048.235980031779036, 415.948749999995925],
              [2676311.123650745488703, 1230923.787560538621619, 429.957500000004075],
              [2676333.81362423254177, 1230848.317510704975575, 427.878750000003492],
              [2676345.433647352736443, 1230761.927552249049768, 429.664999999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 10, gemeinde_B: 10, name: 'Obfelden', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673845.355572330765426, 1232986.537803462706506, 386.70625000000291],
              [2673800.805574311874807, 1233062.23780845827423, 386.892500000001746],
              [2673774.105569583829492, 1233159.487800941104069, 386.54374999999709],
              [2673745.206824610475451, 1233307.837811920559034, 386.958750000005239],
              [2673049.191799406893551, 1234079.324032818665728, 385.476250000006985],
              [2673012.770551861263812, 1234136.847788420272991, 385.693750000005821],
              [2672836.785523633938283, 1235601.34775079251267, 383.639999999999418],
              [2672849.023026681505144, 1235649.307756636990234, 383.871249999996508],
              [2672860.650526479817927, 1235697.240256462944672, 383.848750000004657],
              [2673342.320865214336663, 1235861.019628247246146, 409.976250000006985],
              [2673571.678241750691086, 1235930.18189599947073, 400.179999999993015],
              [2674876.44228730769828, 1236419.385989160742611, 519.663750000006985],
              [2674823.399611620232463, 1236770.183168689254671, 506.033750000002328],
              [2674700.939738403074443, 1236840.674656774615869, 516.017500000001746],
              [2674706.953417077660561, 1236920.687025379389524, 510.457500000004075],
              [2674713.994672157336026, 1236941.745784864062443, 510.847500000003492],
              [2675064.8479492245242, 1236971.203652301570401, 473.721250000002328],
              [2675801.305702599696815, 1236006.662846737774089, 492.811249999998836],
              [2676031.360595390200615, 1235672.072640735423192, 484.276249999995343],
              [2676119.074398782104254, 1235522.57273608003743, 488.351250000006985],
              [2676116.101898943539709, 1235519.897736415732652, 488.366250000006403],
              [2675916.189248030073941, 1235157.724958152277395, 476.788750000006985],
              [2675538.660509191919118, 1235057.56248517986387, 477.961249999993015],
              [2675337.370136145036668, 1234891.214298286475241, 449.087499999994179],
              [2675203.650270515587181, 1234779.33704799390398, 459.647500000006403],
              [2675233.910285286605358, 1234726.547074218280613, 460.772500000006403],
              [2675199.806168784853071, 1234118.318892226554453, 432.255000000004657],
              [2675595.024933088570833, 1233632.232657184824347, 433.063750000001164],
              [2675444.922586584929377, 1233439.289191340096295, 445.115000000005239],
              [2675059.530323483981192, 1233306.043384498916566, 463.851250000006985],
              [2673977.717106075957417, 1233356.427079023560509, 408.756250000005821],
              [2673884.585881125181913, 1233305.457126503344625, 410.774999999994179],
              [2673861.853377752006054, 1233292.737120544537902, 410.527499999996508],
              [2673904.175908222794533, 1233218.937175531173125, 412.872499999997672],
              [2673845.355572330765426, 1232986.537803462706506, 386.70625000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 11, gemeinde_B: 11, name: 'Ottenbach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2672860.650526479817927, 1235697.240256462944672, 383.848750000004657],
              [2672806.208010343369097, 1236329.728982490021735, 382.638749999998254],
              [2671920.292980320751667, 1238038.848954701097682, 380.919999999998254],
              [2673825.721559311728925, 1238537.965931988554075, 463.84375],
              [2673827.134058751631528, 1238535.860930894268677, 463.798750000001746],
              [2674193.101900862995535, 1238468.311558209359646, 490.181249999994179],
              [2674203.141888581681997, 1238351.731534037273377, 489.212499999994179],
              [2674192.480664753355086, 1238259.2140818904154, 491.259999999994761],
              [2674238.749311815481633, 1238054.283887908561155, 483.191250000003492],
              [2674699.07354731252417, 1237008.269767710706219, 520.61250000000291],
              [2674713.994672157336026, 1236941.745784864062443, 510.847500000003492],
              [2674706.953417077660561, 1236920.687025379389524, 510.457500000004075],
              [2674700.939738403074443, 1236840.674656774615869, 516.017500000001746],
              [2674823.399611620232463, 1236770.183168689254671, 506.033750000002328],
              [2674876.44228730769828, 1236419.385989160742611, 519.663750000006985],
              [2673571.678241750691086, 1235930.18189599947073, 400.179999999993015],
              [2673342.320865214336663, 1235861.019628247246146, 409.976250000006985],
              [2672860.650526479817927, 1235697.240256462944672, 383.848750000004657]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 9, gemeinde_B: 9, name: 'Mettmenstetten', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2680380.10896470118314, 1235028.807683761231601, 644.36250000000291],
              [2680333.35076598636806, 1234656.606399551732466, 590.445000000006985],
              [2680288.930715654511005, 1234586.507557516917586, 586.600000000005821],
              [2680180.28204142767936, 1234517.657698145136237, 592.557499999995343],
              [2679771.141672013793141, 1234059.418275026371703, 564.397500000006403],
              [2678836.837869153823704, 1234149.236745566595346, 657.947499999994761],
              [2678255.962654159404337, 1234036.80511417076923, 641.921249999999418],
              [2678278.852634278591722, 1234024.455076888669282, 640.35624999999709],
              [2678525.94523893436417, 1233918.849011457525194, 648.196249999993597],
              [2678447.78260929742828, 1233228.290221350267529, 541.407500000001164],
              [2678842.75791024370119, 1232965.438261940144002, 564.337499999994179],
              [2679143.606068663299084, 1232380.747164136962965, 518.271250000005239],
              [2679059.102248806972057, 1232178.72078573377803, 512.934999999997672],
              [2679360.393764819949865, 1231789.155012566130608, 533.240000000005239],
              [2679738.199598953593522, 1231171.070486947195604, 500.743749999994179],
              [2679632.316058196127415, 1230602.944615941029042, 516.996249999996508],
              [2679545.654704834800214, 1230508.640677697490901, 509.077499999999418],
              [2679576.188478806056082, 1230348.010719237616286, 510.901249999995343],
              [2679297.429117663297802, 1230244.648355921497568, 463.867499999993015],
              [2679290.969121199566871, 1230249.539612560067326, 464.146250000005239],
              [2679283.065359638538212, 1230332.147092349128798, 463.259999999994761],
              [2679128.052971940021962, 1230594.577303327387199, 472.076249999998254],
              [2678988.910230172332376, 1230663.408114046324044, 453.479999999995925],
              [2678977.696477893274277, 1230683.526860267156735, 453.3125],
              [2678976.07648483524099, 1230692.889373095240444, 453.851250000006985],
              [2678040.847482164856046, 1231392.367683494230732, 435.007500000006985],
              [2677532.82554467022419, 1232014.812481510918587, 479.027499999996508],
              [2677212.753919546026736, 1231989.456798103405163, 450.177500000005239],
              [2676845.584262125194073, 1232376.539938654983416, 477.067500000004657],
              [2676453.970167530700564, 1232377.919312050566077, 450.61250000000291],
              [2676215.510115677025169, 1232887.841725716833025, 446.777499999996508],
              [2676033.437734188046306, 1232776.315696381730959, 456.127500000002328],
              [2675847.928841074928641, 1233103.584189128130674, 445.149999999994179],
              [2675756.920332272537053, 1233045.757134933024645, 463.977499999993597],
              [2675655.598878047894686, 1232999.719259872101247, 448.169999999998254],
              [2675585.311245761346072, 1233279.697769775986671, 437.938750000001164],
              [2675444.922586584929377, 1233439.289191340096295, 445.115000000005239],
              [2675595.024933088570833, 1233632.232657184824347, 433.063750000001164],
              [2675199.806168784853071, 1234118.318892226554453, 432.255000000004657],
              [2675233.910285286605358, 1234726.547074218280613, 460.772500000006403],
              [2675203.650270515587181, 1234779.33704799390398, 459.647500000006403],
              [2675337.370136145036668, 1234891.214298286475241, 449.087499999994179],
              [2675538.660509191919118, 1235057.56248517986387, 477.961249999993015],
              [2675916.189248030073941, 1235157.724958152277395, 476.788750000006985],
              [2676116.101898943539709, 1235519.897736415732652, 488.366250000006403],
              [2676119.074398782104254, 1235522.57273608003743, 488.351250000006985],
              [2676170.709386516362429, 1235521.842712399084121, 487.354999999995925],
              [2676496.32233814895153, 1235645.236040506046265, 522.17500000000291],
              [2676588.222300521563739, 1235749.365970243001357, 519.173750000001746],
              [2677350.079982287250459, 1235402.488785378169268, 532.601250000006985],
              [2677367.438728381413966, 1235383.865027613006532, 532.282500000001164],
              [2677371.512479654978961, 1235377.221279793418944, 532.377500000002328],
              [2677625.744075390510261, 1235223.695659262128174, 558.958750000005239],
              [2677935.47740074666217, 1235370.854872325900942, 525.756250000005821],
              [2678238.081240847706795, 1235234.803780165035278, 532.466249999997672],
              [2679180.495276578702033, 1234590.735527497483417, 553.714999999996508],
              [2679572.430229338351637, 1234918.911479901056737, 646.353749999994761],
              [2679773.223788609728217, 1234841.123623814666644, 631.395000000004075],
              [2680380.10896470118314, 1235028.807683761231601, 644.36250000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 17, gemeinde_B: 23, name: 'Berg a.I.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2684957.146342108491808, 1268593.600763803813607, 343.383749999993597],
              [2684965.406341987196356, 1268616.217013620072976, 343.369999999995343],
              [2685081.442596049048007, 1269032.587022545048967, 343.622499999997672],
              [2685105.962593391537666, 1269380.832019763300195, 343.412500000005821],
              [2685144.508845809381455, 1269595.624525282299146, 343.580000000001746],
              [2685247.147594708949327, 1269856.445773623650894, 343.441250000003492],
              [2685251.355099358595908, 1269866.09703231533058, 343.794999999998254],
              [2685276.636345983482897, 1269912.224525969242677, 343.522500000006403],
              [2685568.983000219799578, 1269915.382776997284964, 374.309999999997672],
              [2685922.695423116441816, 1269874.391377071850002, 368.181249999994179],
              [2686139.186808084603399, 1269835.036625326378271, 378.372499999997672],
              [2686465.067885788157582, 1269786.125048258341849, 364.983749999999418],
              [2686486.411849222145975, 1269775.894196038832888, 381.29374999999709],
              [2686556.32435638550669, 1269779.791708303848282, 381.796249999999418],
              [2686626.199227855540812, 1269813.256467697676271, 371.923750000001746],
              [2687193.504371472634375, 1269633.064224961679429, 382.532500000001164],
              [2687342.280543055851012, 1269669.49157676496543, 376.447499999994761],
              [2687652.668240387924016, 1269611.99318880122155, 391.3125],
              [2687773.978027682751417, 1269540.934040251653641, 374.994999999995343],
              [2687787.715530559420586, 1269506.76654512132518, 375.205000000001746],
              [2688366.860802157782018, 1269260.455789022613317, 395.542499999995925],
              [2688692.565981709863991, 1269111.578616058453918, 409.007500000006985],
              [2688195.264923337381333, 1268461.502725226804614, 423.952499999999418],
              [2688140.457419841084629, 1267243.260030526667833, 519.057499999995343],
              [2687631.874478398822248, 1266976.732613982632756, 676.662500000005821],
              [2687212.676767784170806, 1266946.50973332580179, 661.032500000001164],
              [2687193.138000797014683, 1266971.94095259392634, 659.756250000005821],
              [2687111.648091697134078, 1267083.514875293709338, 666.792499999995925],
              [2687036.289212702773511, 1267244.329639483708888, 657.020000000004075],
              [2687012.570541882421821, 1267330.112288567936048, 663.098750000004657],
              [2686807.721720901317894, 1267474.799663835670799, 657.897500000006403],
              [2686121.233127159997821, 1267822.876227880362421, 670.602499999993597],
              [2684957.146342108491808, 1268593.600763803813607, 343.383749999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 19, gemeinde_B: 25, name: 'Dachsen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2689383.962825126945972, 1278198.47745433379896, 358.751250000001164],
              [2689415.177828209009022, 1278278.337460224982351, 358.96875],
              [2689427.764075218699872, 1278560.829956586007029, 358.740000000005239],
              [2689411.176577819045633, 1278647.067462350474671, 358.94999999999709],
              [2688822.811569254845381, 1279313.044960399158299, 358.672500000000582],
              [2688713.316571813542396, 1279399.968717523850501, 358.936249999998836],
              [2688373.689065251499414, 1279622.097462131641805, 358.648749999993015],
              [2688329.957818924915045, 1279652.411219913279638, 358.95625000000291],
              [2688121.155313775409013, 1279811.126214675605297, 358.695000000006985],
              [2687901.151567825581878, 1280191.849978546146303, 359.147500000006403],
              [2687878.854061857797205, 1280323.602468688040972, 358.708750000005239],
              [2687886.019063428975642, 1280471.439972641179338, 358.827499999999418],
              [2688484.848947756458074, 1280715.794592721154913, 444.952499999999418],
              [2688837.763846308458596, 1280839.874397112987936, 436.962499999994179],
              [2688903.553899641148746, 1280863.039496095618233, 440.978749999994761],
              [2689183.970893021672964, 1279971.11353418440558, 402.166249999994761],
              [2689778.931308288592845, 1279484.504296559374779, 433.351250000006985],
              [2689777.070061984006315, 1279476.669303442817181, 433.633749999993597],
              [2690258.651057029608637, 1279086.086314926855266, 413.889999999999418],
              [2690017.479569914750755, 1278057.142117490293458, 395.997499999997672],
              [2689986.390867489390075, 1278027.623456589644775, 399.636249999995925],
              [2689465.082825608085841, 1278163.847453724825755, 358.73750000000291],
              [2689440.565325669944286, 1278170.448704273672774, 358.757500000006985],
              [2689383.962825126945972, 1278198.47745433379896, 358.751250000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 21, gemeinde_B: 27, name: 'Feuerthalen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2689611.22825795924291, 1283093.91079981951043, 391.669999999998254],
              [2689990.413246018346399, 1283187.859521913109347, 390.513749999998254],
              [2690421.237015340011567, 1283333.070802174741402, 391.702499999999418],
              [2690504.414504525251687, 1283310.150780347641557, 390.82499999999709],
              [2690551.345761282835156, 1283296.50454212166369, 391.307499999995343],
              [2690576.027004886418581, 1283285.42577964020893, 390.804999999993015],
              [2690589.71576155629009, 1283278.588291846681386, 391.302500000005239],
              [2691037.13325674738735, 1282944.583272706950083, 390.634999999994761],
              [2691056.003267562482506, 1282928.708292513387278, 391.443750000005821],
              [2691091.927006396465003, 1282895.340770743554458, 390.571249999993597],
              [2691183.118276556953788, 1282806.034556218422949, 392.039999999993597],
              [2691240.188260768074542, 1282756.113275334239006, 390.802500000005239],
              [2691271.423286780249327, 1282731.834573274012655, 392.756250000005821],
              [2691573.830760349519551, 1282519.90326711256057, 390.546249999999418],
              [2692088.403277391567826, 1282380.434539312496781, 391.497499999997672],
              [2692200.304517549462616, 1282357.119518904481083, 390.677500000005239],
              [2692237.137031164951622, 1282347.327043626923114, 391.684999999997672],
              [2692273.688267929945141, 1282336.535768226254731, 390.657500000001164],
              [2692415.259525258094072, 1282224.494528657989576, 391.117499999993015],
              [2692328.400238834321499, 1281501.164437358267605, 539.990000000005239],
              [2691731.370479630306363, 1281525.959900474408641, 558.787500000005821],
              [2690900.109115594998002, 1281879.233460216317326, 550.89375000000291],
              [2690762.737888167612255, 1281970.439756561303511, 552.735000000000582],
              [2690183.663965017069131, 1282298.623199091525748, 540.100000000005821],
              [2690224.286522935610265, 1282642.595968921203166, 449.423750000001746],
              [2689673.519269727170467, 1282867.078944121254608, 468.623749999998836],
              [2689611.22825795924291, 1283093.91079981951043, 391.669999999998254]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 22, gemeinde_B: 28, name: 'Flaach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2685276.636345983482897, 1269912.224525969242677, 343.522500000006403],
              [2685293.47509595612064, 1269935.899525853572413, 343.511249999995925],
              [2685312.217596648260951, 1269958.097027027048171, 343.553750000006403],
              [2685354.89134613564238, 1269997.455775724956766, 343.490000000005239],
              [2685429.587597790174186, 1270041.973278051009402, 343.573749999995925],
              [2685457.112596875522286, 1270052.650776, 343.48750000000291],
              [2685823.113852527923882, 1270137.867031804751605, 343.70625000000291],
              [2685886.426350032445043, 1270285.157027282984927, 343.48124999999709],
              [2685878.516354973427951, 1270374.365787280024961, 343.866250000006403],
              [2685743.508849425241351, 1270696.053281260887161, 343.528749999997672],
              [2685643.972605404444039, 1271271.068298106081784, 344.058749999996508],
              [2685648.59010204533115, 1271322.408292132429779, 343.80000000000291],
              [2686785.972620799206197, 1272279.257067401893437, 344.585000000006403],
              [2686815.523869942873716, 1272295.007065481506288, 344.502500000002328],
              [2686923.866402030456811, 1272196.332123099593446, 346.888749999998254],
              [2687247.040141268167645, 1272593.567101129097864, 345.883749999993597],
              [2688281.022708634845912, 1272536.018461010418832, 350.408750000002328],
              [2688514.715198380872607, 1272558.115938572213054, 349.48750000000291],
              [2688722.73270416399464, 1272588.980946477269754, 349.804999999993015],
              [2688736.738952569663525, 1272589.414693299913779, 349.67500000000291],
              [2688782.912954830098897, 1272656.091413102345541, 368.873749999998836],
              [2688776.912906874436885, 1272643.54257374862209, 365.222500000003492],
              [2688938.103116998914629, 1272608.03171235579066, 381.131250000005821],
              [2689122.228941677138209, 1272354.662165530957282, 348.610000000000582],
              [2689453.307755764573812, 1272205.962278754916042, 353.288750000006985],
              [2689540.803975810762495, 1272108.032220984343439, 350.953750000000582],
              [2689669.886490885633975, 1272116.092247150372714, 352.023749999993015],
              [2690018.807731106411666, 1272180.618474239017814, 351.072499999994761],
              [2690088.668987968470901, 1272180.312235936289653, 351.552500000005239],
              [2690179.851447791792452, 1272015.105908680707216, 348.438750000001164],
              [2690362.672701972071081, 1271880.988412751117721, 348.645000000004075],
              [2690564.895288106054068, 1271506.768566882237792, 355.0625],
              [2690497.80028037307784, 1271447.707303092582151, 354.513749999998254],
              [2690492.130280887242407, 1271441.771054087672383, 354.556249999994179],
              [2690412.174019818659872, 1271416.137284555006772, 353.76249999999709],
              [2689697.301630760543048, 1271323.620000797789544, 362.633749999993597],
              [2689380.565321068279445, 1271049.247216510120779, 453.453750000000582],
              [2688944.337623808067292, 1270503.784352768445387, 438.741250000006403],
              [2688885.201850593555719, 1270167.947913163108751, 379.858749999999418],
              [2688543.592360647395253, 1269537.586361981229857, 418.94999999999709],
              [2688366.860802157782018, 1269260.455789022613317, 395.542499999995925],
              [2687787.715530559420586, 1269506.76654512132518, 375.205000000001746],
              [2687773.978027682751417, 1269540.934040251653641, 374.994999999995343],
              [2687652.668240387924016, 1269611.99318880122155, 391.3125],
              [2687342.280543055851012, 1269669.49157676496543, 376.447499999994761],
              [2687193.504371472634375, 1269633.064224961679429, 382.532500000001164],
              [2686626.199227855540812, 1269813.256467697676271, 371.923750000001746],
              [2686556.32435638550669, 1269779.791708303848282, 381.796249999999418],
              [2686486.411849222145975, 1269775.894196038832888, 381.29374999999709],
              [2686465.067885788157582, 1269786.125048258341849, 364.983749999999418],
              [2686139.186808084603399, 1269835.036625326378271, 378.372499999997672],
              [2685922.695423116441816, 1269874.391377071850002, 368.181249999994179],
              [2685568.983000219799578, 1269915.382776997284964, 374.309999999997672],
              [2685276.636345983482897, 1269912.224525969242677, 343.522500000006403]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 23, gemeinde_B: 29, name: 'Flurlingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2689013.474373595789075, 1281306.326793171931058, 381.787500000005821],
              [2689041.106874088291079, 1281321.253043764038011, 381.807499999995343],
              [2689211.240623465273529, 1281522.786791460122913, 381.654999999998836],
              [2689244.058137458749115, 1281864.464319898979738, 382.707500000004075],
              [2689235.288136802613735, 1281889.63431901531294, 382.663750000006985],
              [2688994.924388319253922, 1282809.759333168854937, 382.95625000000291],
              [2688994.185637240298092, 1282830.178081316640601, 382.875],
              [2688997.713141212239861, 1282870.858089037705213, 383.176250000004075],
              [2689001.953137798700482, 1282890.844332722015679, 382.913750000006985],
              [2689059.648141321260482, 1282997.66308926185593, 383.147500000006403],
              [2689074.038140520919114, 1283012.168087634723634, 383.077499999999418],
              [2689291.441033093258739, 1283045.453821020200849, 412.83125000000291],
              [2689611.22825795924291, 1283093.91079981951043, 391.669999999998254],
              [2689673.519269727170467, 1282867.078944121254608, 468.623749999998836],
              [2690224.286522935610265, 1282642.595968921203166, 449.423750000001746],
              [2690183.663965017069131, 1282298.623199091525748, 540.100000000005821],
              [2690762.737888167612255, 1281970.439756561303511, 552.735000000000582],
              [2690900.109115594998002, 1281879.233460216317326, 550.89375000000291],
              [2691731.370479630306363, 1281525.959900474408641, 558.787500000005821],
              [2691336.105681499000639, 1281413.972785384627059, 574.471250000002328],
              [2691007.421788431238383, 1281311.761274500284344, 563.911250000004657],
              [2690534.68932787142694, 1281333.488859419478104, 567.346250000002328],
              [2690076.130388163030148, 1281306.247264666715637, 553.342499999998836],
              [2689470.943038435652852, 1281266.736467684153467, 470.16874999999709],
              [2689196.645735196303576, 1281160.601840764284134, 485.366250000006403],
              [2689013.474373595789075, 1281306.326793171931058, 381.787500000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 26, gemeinde_B: 32, name: 'Humlikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694837.408943722955883, 1269257.666863786522299, 439.707500000004075],
              [2694747.645072597078979, 1269293.646642692387104, 430.626250000001164],
              [2694582.043801634106785, 1269569.737859480082989, 429.167499999995925],
              [2694301.168920263648033, 1269457.20058207353577, 438.330000000001746],
              [2693587.41127603314817, 1269654.481580632505938, 427.942500000004657],
              [2693007.702695179730654, 1269668.70940341684036, 441.163750000006985],
              [2693001.806444447953254, 1269667.753152158576995, 441.11250000000291],
              [2692481.712569054216146, 1268735.037730293115601, 526.697499999994761],
              [2692105.547720870934427, 1269197.958023989805952, 538.544999999998254],
              [2692329.654306863900274, 1269673.047584483632818, 469.076249999998254],
              [2692181.385492041707039, 1269918.91621981933713, 464.282500000001164],
              [2692261.119509987533092, 1270100.824215625878423, 484.537500000005821],
              [2692262.242010358721018, 1270108.679216376738623, 484.565000000002328],
              [2692089.658160002436489, 1270457.445287548471242, 477.101250000006985],
              [2691814.719529907219112, 1270599.228016343666241, 486.417499999995925],
              [2691789.460434840992093, 1271342.293634955771267, 460.277499999996508],
              [2692503.255992230959237, 1271288.724839181872085, 407.247499999997672],
              [2692659.601172292605042, 1271075.642667808104306, 420.777499999996508],
              [2692764.679795032832772, 1271054.95743042556569, 411.063750000001164],
              [2693062.206140577793121, 1271017.042601641034707, 418.092499999998836],
              [2693820.341506297234446, 1270384.314507970586419, 445.207500000004075],
              [2693921.532578532584012, 1270217.929176595527679, 431.6875],
              [2693929.208915998693556, 1270083.426836611935869, 438.292499999995925],
              [2694449.810026076156646, 1270022.845318746287376, 427.341249999997672],
              [2694613.195065748877823, 1270040.306639131624252, 430.222500000003492],
              [2694626.988812101539224, 1270065.816632385831326, 429.9375],
              [2695044.025429667904973, 1269918.016050552483648, 457.382500000006985],
              [2695051.967851422727108, 1269796.615905189653859, 451.467499999998836],
              [2695118.420393981505185, 1269791.644732265500352, 454.630000000004657],
              [2694945.773914254270494, 1269467.174309485126287, 437.408750000002328],
              [2694934.233986441046, 1269434.958192300517112, 442.866250000006403],
              [2694864.046421799343079, 1269353.765573816373944, 438.035000000003492],
              [2694837.408943722955883, 1269257.666863786522299, 439.707500000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 27, gemeinde_B: 33, name: 'Kleinandelfingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694688.264101347886026, 1272608.442380155669525, 357.392500000001746],
              [2693712.255320043303072, 1272490.794836111133918, 355.611250000001746],
              [2693680.182820347137749, 1272494.542337181745097, 355.653749999997672],
              [2693539.465317650698125, 1272529.447334574302658, 355.535000000003492],
              [2693516.657820357009768, 1272538.143589990213513, 355.753750000003492],
              [2692113.067773499526083, 1273680.452282825019211, 353.073749999995925],
              [2692075.51402345392853, 1273665.859783197520301, 353.092499999998836],
              [2691165.87396953580901, 1271614.232181461062282, 349.496249999996508],
              [2691151.077721354551613, 1271597.460934924660251, 349.642500000001746],
              [2690362.672701972071081, 1271880.988412751117721, 348.645000000004075],
              [2690179.851447791792452, 1272015.105908680707216, 348.438750000001164],
              [2690088.668987968470901, 1272180.312235936289653, 351.552500000005239],
              [2690018.807731106411666, 1272180.618474239017814, 351.072499999994761],
              [2689669.886490885633975, 1272116.092247150372714, 352.023749999993015],
              [2689540.803975810762495, 1272108.032220984343439, 350.953750000000582],
              [2689453.307755764573812, 1272205.962278754916042, 353.288750000006985],
              [2689122.228941677138209, 1272354.662165530957282, 348.610000000000582],
              [2688938.103116998914629, 1272608.03171235579066, 381.131250000005821],
              [2688776.912906874436885, 1272643.54257374862209, 365.222500000003492],
              [2688782.912954830098897, 1272656.091413102345541, 368.873749999998836],
              [2690302.697366912383586, 1272823.105121514527127, 418.267500000001746],
              [2690364.020899483002722, 1272962.219717438798398, 401.697499999994761],
              [2690676.467285882681608, 1273486.853720139479265, 411.863750000004075],
              [2692118.998325442895293, 1274111.340810194378719, 394.928750000006403],
              [2692369.554699889849871, 1274439.004789666971192, 404.202499999999418],
              [2692391.152096324600279, 1274708.889599388698116, 396.345000000001164],
              [2692281.47593248821795, 1275226.778515485581011, 402.961249999993015],
              [2692296.810872477013618, 1275357.382154889171943, 398.404999999998836],
              [2692366.208375332411379, 1275358.239659039536491, 398.57499999999709],
              [2692328.001222500577569, 1276129.416561127174646, 424.932499999995343],
              [2692437.11244428390637, 1276098.121506507974118, 422.716249999997672],
              [2692634.306265766732395, 1276193.179136621300131, 427.994999999995343],
              [2693098.562303702346981, 1276207.711234931601211, 411.610000000000582],
              [2693423.982455164659768, 1276210.432760321768001, 422.847500000003492],
              [2693737.657162607181817, 1275956.897210262250155, 400.493749999994179],
              [2693946.557184444740415, 1275895.362246722681448, 402.003750000003492],
              [2693989.395973924547434, 1275745.751067884033546, 404.95625000000291],
              [2693835.300926675088704, 1275754.083483004476875, 401.48750000000291],
              [2694043.81369246263057, 1274803.363963568815961, 421.41874999999709],
              [2693993.538626319728792, 1274651.916340685449541, 416.44999999999709],
              [2694478.616221082396805, 1274470.656505985185504, 423.26875000000291],
              [2695129.407247520983219, 1274112.322328466922045, 405.929999999993015],
              [2695135.107261080294847, 1274076.456103115109727, 406.948749999995925],
              [2695155.446007889229804, 1274009.618596307933331, 406.692500000004657],
              [2695036.121236320585012, 1273726.001517807831988, 424.009999999994761],
              [2694688.264101347886026, 1272608.442380155669525, 357.392500000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 28, gemeinde_B: 34, name: 'Laufen-Uhwiesen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692328.400238834321499, 1281501.164437358267605, 539.990000000005239],
              [2692544.322927752044052, 1281115.05978056974709, 554.104999999995925],
              [2692948.702780560124665, 1280884.924494641367346, 542.587499999994179],
              [2693011.540387013927102, 1280667.407189115183428, 550.587499999994179],
              [2693164.741468844003975, 1280278.264368280768394, 537.710000000006403],
              [2693049.125392025802284, 1280201.323442391585559, 550.922500000000582],
              [2692993.81416550045833, 1280203.985987371765077, 552.75],
              [2692504.656465395353734, 1280276.476876351982355, 538.029999999998836],
              [2691712.389872717205435, 1280328.128755042329431, 512.73124999999709],
              [2691614.494985132943839, 1280262.333966167643666, 521.342499999998836],
              [2691427.451193099841475, 1280270.022641801275313, 518.3125],
              [2690691.724122293293476, 1279809.143619469134137, 456.558749999996508],
              [2690221.686481183860451, 1279676.345863405382261, 446.182499999995343],
              [2690230.889086176175624, 1279706.864809753373265, 454.160000000003492],
              [2690131.132812862284482, 1279709.962268083589152, 452.462499999994179],
              [2690086.089067525230348, 1279718.264777733944356, 452.851250000006985],
              [2689778.931308288592845, 1279484.504296559374779, 433.351250000006985],
              [2689183.970893021672964, 1279971.11353418440558, 402.166249999994761],
              [2688903.553899641148746, 1280863.039496095618233, 440.978749999994761],
              [2688837.763846308458596, 1280839.874397112987936, 436.962499999994179],
              [2688484.848947756458074, 1280715.794592721154913, 444.952499999999418],
              [2687886.019063428975642, 1280471.439972641179338, 358.827499999999418],
              [2687878.854061857797205, 1280323.602468688040972, 358.708750000005239],
              [2687901.151567825581878, 1280191.849978546146303, 359.147500000006403],
              [2688121.155313775409013, 1279811.126214675605297, 358.695000000006985],
              [2688329.957818924915045, 1279652.411219913279638, 358.95625000000291],
              [2688203.068412301130593, 1279566.493583595845848, 404.292499999995925],
              [2687691.578537092078477, 1280432.945083927828819, 414.1875],
              [2687944.835315038450062, 1280808.668727320851758, 358.922500000000582],
              [2687991.202816355973482, 1281102.189981302712113, 359.001250000001164],
              [2688024.089063205290586, 1281217.79247576626949, 358.743749999994179],
              [2688041.110319002065808, 1281254.154986657667905, 359.176250000004075],
              [2688291.069064280483872, 1281536.85122609953396, 358.669999999998254],
              [2688330.610558463726193, 1281493.621683502802625, 377.266250000000582],
              [2688375.754257406573743, 1281483.845336877042428, 373.34375],
              [2688560.240616636350751, 1281202.370536642847583, 381.547500000000582],
              [2688578.030615232419223, 1281188.416783611522987, 381.428750000006403],
              [2688597.470617173239589, 1281176.873036839533597, 381.563750000001164],
              [2688651.690615865401924, 1281159.629283373476937, 381.428750000006403],
              [2689013.474373595789075, 1281306.326793171931058, 381.787500000005821],
              [2689196.645735196303576, 1281160.601840764284134, 485.366250000006403],
              [2689470.943038435652852, 1281266.736467684153467, 470.16874999999709],
              [2690076.130388163030148, 1281306.247264666715637, 553.342499999998836],
              [2690534.68932787142694, 1281333.488859419478104, 567.346250000002328],
              [2691007.421788431238383, 1281311.761274500284344, 563.911250000004657],
              [2691336.105681499000639, 1281413.972785384627059, 574.471250000002328],
              [2691731.370479630306363, 1281525.959900474408641, 558.787500000005821],
              [2692328.400238834321499, 1281501.164437358267605, 539.990000000005239]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 29, gemeinde_B: 35, name: 'Marthalen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2686815.523869942873716, 1272295.007065481506288, 344.502500000002328],
              [2687008.798878357280046, 1273411.164586534257978, 345.057499999995343],
              [2687065.610126415267587, 1273499.562082707416266, 344.877500000002328],
              [2687576.288891358301044, 1274018.387106887064874, 345.728749999994761],
              [2687884.958282875362784, 1273601.398297009989619, 394.493749999994179],
              [2688055.918290345463902, 1273562.508307783165947, 394.948749999995925],
              [2687856.907909122761339, 1273136.865096038440242, 365.977499999993597],
              [2688439.578022131230682, 1273119.732797585660592, 374.23124999999709],
              [2688775.694160158745944, 1273349.742585339117795, 365.490000000005239],
              [2688743.599539753049612, 1273765.39204692421481, 394.44999999999709],
              [2689075.670637501869351, 1274085.713010206120089, 382.638749999998254],
              [2689080.121900049038231, 1274290.439285173080862, 383.596250000002328],
              [2689060.499397874809802, 1274352.326781935757026, 383.445000000006985],
              [2689031.539387953933328, 1274341.240513812983409, 382.707500000004075],
              [2688761.495791740715504, 1274262.453304439550266, 394.601250000006985],
              [2688649.759157269261777, 1274352.795089640654624, 365.371249999996508],
              [2688572.302968880161643, 1274674.630208330927417, 370.123749999998836],
              [2689178.734177723061293, 1274968.697624329710379, 366.615000000005239],
              [2689316.205456092022359, 1275082.687675982015207, 368.692500000004657],
              [2689514.322911718394607, 1275372.053842382505536, 365.197499999994761],
              [2689379.364277208223939, 1275723.022812659153715, 374.082500000004075],
              [2688872.977950794156641, 1276052.766430574469268, 368.588749999995343],
              [2688829.657966539729387, 1276072.371460817288607, 369.816250000003492],
              [2688882.853274567518383, 1276232.918286613421515, 393.257500000006985],
              [2689154.458329533226788, 1276393.498386071529239, 397.267500000001746],
              [2689462.488149658311158, 1276774.388048242777586, 383.376250000001164],
              [2690193.382642374373972, 1276963.178173266351223, 439.38749999999709],
              [2690045.368437844328582, 1277629.364833605941385, 404.938750000001164],
              [2690121.580973568372428, 1277676.05614938121289, 407.604999999995925],
              [2690497.907357050571591, 1277423.545139737194404, 417.48750000000291],
              [2690962.900928421877325, 1277638.57355052116327, 403.600000000005821],
              [2691534.092270214110613, 1277337.256209225626662, 410.172500000000582],
              [2692097.753693007864058, 1276740.892765765544027, 422.873749999998836],
              [2692219.453619698993862, 1276623.523876297986135, 417.227499999993597],
              [2692218.872399353887886, 1276578.120181042701006, 419.475000000005821],
              [2692328.058631769847125, 1276481.402645616326481, 418.063750000001164],
              [2692114.898776201996952, 1276432.954167417716235, 429.162500000005821],
              [2692047.464944140985608, 1276299.504014877602458, 422.985000000000582],
              [2692230.176222030539066, 1276187.374062497168779, 424.967499999998836],
              [2692325.731220846064389, 1276131.947808113880455, 424.808749999996508],
              [2692328.001222500577569, 1276129.416561127174646, 424.932499999995343],
              [2692366.208375332411379, 1275358.239659039536491, 398.57499999999709],
              [2692296.810872477013618, 1275357.382154889171943, 398.404999999998836],
              [2692281.47593248821795, 1275226.778515485581011, 402.961249999993015],
              [2692391.152096324600279, 1274708.889599388698116, 396.345000000001164],
              [2692369.554699889849871, 1274439.004789666971192, 404.202499999999418],
              [2692118.998325442895293, 1274111.340810194378719, 394.928750000006403],
              [2690676.467285882681608, 1273486.853720139479265, 411.863750000004075],
              [2690364.020899483002722, 1272962.219717438798398, 401.697499999994761],
              [2690302.697366912383586, 1272823.105121514527127, 418.267500000001746],
              [2688782.912954830098897, 1272656.091413102345541, 368.873749999998836],
              [2688736.738952569663525, 1272589.414693299913779, 349.67500000000291],
              [2688722.73270416399464, 1272588.980946477269754, 349.804999999993015],
              [2688514.715198380872607, 1272558.115938572213054, 349.48750000000291],
              [2688281.022708634845912, 1272536.018461010418832, 350.408750000002328],
              [2687247.040141268167645, 1272593.567101129097864, 345.883749999993597],
              [2686923.866402030456811, 1272196.332123099593446, 346.888749999998254],
              [2686815.523869942873716, 1272295.007065481506288, 344.502500000002328]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 15, gemeinde_B: 21, name: 'Adlikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694848.842855294700712, 1272501.374884207500145, 357.591249999997672],
              [2696286.987921845167875, 1273484.349992659408599, 361.76249999999709],
              [2696303.244170018006116, 1273479.452489010756835, 361.615000000005239],
              [2696318.965421209577471, 1273473.04624092602171, 361.695000000006985],
              [2696334.012920470442623, 1273465.187489270698279, 361.630000000004657],
              [2696740.322935859672725, 1272894.771257082000375, 362.528749999997672],
              [2696738.979184842668474, 1272874.310005067847669, 362.452499999999418],
              [2696189.789196565747261, 1272384.116281255846843, 363.658750000002328],
              [2696839.842969545163214, 1272510.146314608165994, 364.994999999995343],
              [2696219.481152871623635, 1271539.542574264341965, 416.839999999996508],
              [2696478.698707408737391, 1271274.098917816765606, 420.76249999999709],
              [2696569.628657482564449, 1271253.368824241450056, 416.938750000001164],
              [2696599.14416317269206, 1271096.769752235384658, 454.996249999996508],
              [2696629.822925667278469, 1271083.559774511959404, 455.913750000006985],
              [2696720.320290221832693, 1271066.899523661937565, 445.647500000006403],
              [2697488.595920010004193, 1270229.064407645724714, 492.427500000005239],
              [2697004.686653481796384, 1269925.793465907918289, 453.936249999998836],
              [2697002.015403935220093, 1269928.793466817820445, 453.972500000003492],
              [2696952.837897147517651, 1269949.985955461161211, 453.498749999998836],
              [2696673.08813376352191, 1270023.400146018015221, 471.526249999995343],
              [2696193.900432039517909, 1270480.187288688961416, 456.707500000004075],
              [2695977.38179777096957, 1270406.400004942668602, 465.592499999998836],
              [2695863.200511989649385, 1270578.914942923467606, 462.986250000001746],
              [2695515.107642912771553, 1270811.011772105237469, 435.417499999995925],
              [2695577.377999535761774, 1270084.039920701878145, 462.253750000003492],
              [2695356.642866376787424, 1270130.910930188139901, 452.376250000001164],
              [2695313.394236399792135, 1269808.92739889677614, 461.457500000004075],
              [2695118.420393981505185, 1269791.644732265500352, 454.630000000004657],
              [2695051.967851422727108, 1269796.615905189653859, 451.467499999998836],
              [2695044.025429667904973, 1269918.016050552483648, 457.382500000006985],
              [2694626.988812101539224, 1270065.816632385831326, 429.9375],
              [2694613.195065748877823, 1270040.306639131624252, 430.222500000003492],
              [2694449.810026076156646, 1270022.845318746287376, 427.341249999997672],
              [2693929.208915998693556, 1270083.426836611935869, 438.292499999995925],
              [2693921.532578532584012, 1270217.929176595527679, 431.6875],
              [2693820.341506297234446, 1270384.314507970586419, 445.207500000004075],
              [2693062.206140577793121, 1271017.042601641034707, 418.092499999998836],
              [2693498.086333442013711, 1270901.499199345707893, 432.378750000003492],
              [2693715.743599101435393, 1271450.80501745454967, 414.509999999994761],
              [2694780.587710017338395, 1271571.865665944758803, 441.032500000001164],
              [2694922.146301159169525, 1271328.500368243083358, 428.929999999993015],
              [2695685.822755310684443, 1271225.050729770911857, 443.779999999998836],
              [2695703.171072120778263, 1271770.476186409592628, 411.113750000004075],
              [2695602.075056350789964, 1271736.994119413197041, 428.847500000003492],
              [2695581.628811754286289, 1271746.339129815809429, 429.270000000004075],
              [2695551.963804740924388, 1271765.885367590002716, 428.76249999999709],
              [2695498.845073917414993, 1271801.159154182299972, 430.247499999997672],
              [2695226.906196158844978, 1271828.460173714673147, 420.801250000004075],
              [2694989.592575361020863, 1272018.215417833300307, 430.725000000005821],
              [2694827.592467219103128, 1272180.007722601760179, 422.678750000006403],
              [2694812.829968120437115, 1272194.938974651508033, 422.757500000006985],
              [2694848.842855294700712, 1272501.374884207500145, 357.591249999997672]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 32, gemeinde_B: 39, name: 'Thalheim a.d.Th.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2698500.088034013286233, 1272112.470154509181157, 368.8125],
              [2699531.434322685468942, 1272190.803960123565048, 371.078750000000582],
              [2699500.255584086291492, 1271853.003978845896199, 371.945000000006985],
              [2699512.405568107031286, 1271817.203949112445116, 370.738750000004075],
              [2699606.144427597988397, 1271786.330397941404954, 378.88749999999709],
              [2699650.81318209413439, 1271727.087905001826584, 379.195000000006985],
              [2699650.954425929114223, 1271495.075391865801066, 378.727499999993597],
              [2699783.679449182469398, 1270774.444176587508991, 380.369999999995343],
              [2699664.440687707625329, 1270708.125406936276704, 379.583750000005239],
              [2699701.290691486559808, 1270659.434162884019315, 379.842499999998836],
              [2699622.248307139612734, 1270252.345622439403087, 388.552500000005239],
              [2700078.579199087340385, 1269326.992236843565479, 455.052500000005239],
              [2699941.126679522916675, 1269365.209704, 453.690000000002328],
              [2699694.894258770160377, 1269335.334853111999109, 459.8125],
              [2699244.980312671046704, 1269689.793256133561954, 445.451249999998254],
              [2698871.709235376678407, 1269828.236080361763015, 458.688750000001164],
              [2698415.444097847677767, 1269984.214588392293081, 448.707500000004075],
              [2698334.286613863892853, 1270081.537120128283277, 449.972500000003492],
              [2698332.116611840669066, 1270091.367116558598354, 449.822499999994761],
              [2698077.921011023689061, 1269696.999557324685156, 498.779999999998836],
              [2698045.276009236462414, 1269708.133304824819788, 498.672500000000582],
              [2698024.244781265035272, 1269765.27334620594047, 500.346250000002328],
              [2697964.78598351823166, 1269853.804510791553184, 496.808749999996508],
              [2697872.01854121312499, 1269948.317119406769052, 501.222500000003492],
              [2697783.499647324904799, 1270011.658107962692156, 490.479999999995925],
              [2697551.804736994206905, 1270169.563278692774475, 497.412500000005821],
              [2697488.595920010004193, 1270229.064407645724714, 492.427500000005239],
              [2696720.320290221832693, 1271066.899523661937565, 445.647500000006403],
              [2696629.822925667278469, 1271083.559774511959404, 455.913750000006985],
              [2696599.14416317269206, 1271096.769752235384658, 454.996249999996508],
              [2696569.628657482564449, 1271253.368824241450056, 416.938750000001164],
              [2696478.698707408737391, 1271274.098917816765606, 420.76249999999709],
              [2696219.481152871623635, 1271539.542574264341965, 416.839999999996508],
              [2696839.842969545163214, 1272510.146314608165994, 364.994999999995343],
              [2696843.076719206757843, 1272511.563813943881541, 364.967499999998836],
              [2697788.718008782248944, 1271627.953865376068279, 367.34375],
              [2697826.763008084148169, 1271615.79386341245845, 367.267500000001746],
              [2698500.088034013286233, 1272112.470154509181157, 368.8125]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 33, gemeinde_B: 40, name: 'Trüllikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2693049.125392025802284, 1280201.323442391585559, 550.922500000000582],
              [2693072.071787510532886, 1279838.864958587801084, 561.908750000002328],
              [2693286.997737355064601, 1279348.388139865593985, 538.982499999998254],
              [2693484.112874645274132, 1279107.937138098524883, 549.191250000003492],
              [2693764.801056402735412, 1278846.762323282659054, 505.95625000000291],
              [2693873.384889193810523, 1278450.047470657620579, 512.117499999993015],
              [2694378.932066988665611, 1278244.494360196404159, 487.342499999998836],
              [2694668.889638606458902, 1278160.161986349150538, 492.517500000001746],
              [2694912.290699996054173, 1277966.267879941500723, 478.076249999998254],
              [2695008.759545160457492, 1277893.428053657058626, 485.182499999995343],
              [2695846.001858117990196, 1277859.513940572272986, 470.413750000006985],
              [2695890.011895404197276, 1277716.6440073272679, 473.1875],
              [2696005.820626297034323, 1277727.462719841627404, 471.657500000001164],
              [2696078.839382302947342, 1277577.36647807364352, 472.05000000000291],
              [2696107.133128066547215, 1277515.876469106646255, 471.707500000004075],
              [2696014.029493964277208, 1277243.147932563442737, 480.508749999993597],
              [2695920.889310546219349, 1276942.360092299059033, 466.748749999998836],
              [2695956.590706421993673, 1276671.122858658432961, 477.711249999993015],
              [2695748.653084385674447, 1276725.535137607017532, 468.673750000001746],
              [2695744.46684059407562, 1276729.238899193704128, 469.145000000004075],
              [2695748.540567114017904, 1276777.71260616579093, 467.372499999997672],
              [2695674.34069935977459, 1276770.437852106522769, 477.402499999996508],
              [2695321.625510832294822, 1276735.848759821383283, 463.453750000000582],
              [2695107.498258772306144, 1276699.031722460640594, 482.330000000001746],
              [2695056.506063621491194, 1275679.761215233942494, 411.005000000004657],
              [2694875.682297258637846, 1275751.574938671430573, 409.895000000004075],
              [2694681.426072924863547, 1275873.556240533711389, 411.96875],
              [2693946.557184444740415, 1275895.362246722681448, 402.003750000003492],
              [2693737.657162607181817, 1275956.897210262250155, 400.493749999994179],
              [2693423.982455164659768, 1276210.432760321768001, 422.847500000003492],
              [2693098.562303702346981, 1276207.711234931601211, 411.610000000000582],
              [2692634.306265766732395, 1276193.179136621300131, 427.994999999995343],
              [2692437.11244428390637, 1276098.121506507974118, 422.716249999997672],
              [2692328.001222500577569, 1276129.416561127174646, 424.932499999995343],
              [2692325.731220846064389, 1276131.947808113880455, 424.808749999996508],
              [2692230.176222030539066, 1276187.374062497168779, 424.967499999998836],
              [2692047.464944140985608, 1276299.504014877602458, 422.985000000000582],
              [2692114.898776201996952, 1276432.954167417716235, 429.162500000005821],
              [2692328.058631769847125, 1276481.402645616326481, 418.063750000001164],
              [2692218.872399353887886, 1276578.120181042701006, 419.475000000005821],
              [2692219.453619698993862, 1276623.523876297986135, 417.227499999993597],
              [2692097.753693007864058, 1276740.892765765544027, 422.873749999998836],
              [2691534.092270214110613, 1277337.256209225626662, 410.172500000000582],
              [2691650.69353660242632, 1277408.98998834262602, 411.337499999994179],
              [2691539.598505823407322, 1277597.002434542868286, 409.082500000004075],
              [2692137.893578800372779, 1277708.596311050700024, 414.208750000005239],
              [2692409.511617992073298, 1278211.894813943887129, 454.896250000005239],
              [2692287.747898701112717, 1278286.94112409115769, 457.317500000004657],
              [2692297.566596602555364, 1278346.964777515036985, 453.36250000000291],
              [2692551.258073205593973, 1279671.286285065580159, 565.087499999994179],
              [2692504.656465395353734, 1280276.476876351982355, 538.029999999998836],
              [2692993.81416550045833, 1280203.985987371765077, 552.75],
              [2693049.125392025802284, 1280201.323442391585559, 550.922500000000582]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 34, gemeinde_B: 41, name: 'Truttikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2696078.839382302947342, 1277577.36647807364352, 472.05000000000291],
              [2696086.500635646749288, 1277581.102734150830656, 472.296249999999418],
              [2696556.686639998108149, 1277670.368521908763796, 453.433749999996508],
              [2696594.936653892043978, 1277661.988546792883426, 454.451249999998254],
              [2696635.175389780197293, 1277725.117270555114374, 453.360000000000582],
              [2697123.270468311849982, 1277627.872405552072451, 458.902499999996508],
              [2697447.767912942450494, 1277576.228546766331419, 454.492499999993015],
              [2697645.884237705729902, 1277609.106181319104508, 459.966249999997672],
              [2698030.130302791018039, 1277604.084583052434027, 445.78125],
              [2698046.860360815189779, 1277382.817187678534538, 450.123749999998836],
              [2698337.191573465242982, 1277262.638362381607294, 447.101250000006985],
              [2698159.925655659288168, 1276029.571465935558081, 472.160000000003492],
              [2697964.199356683529913, 1275988.357629103120416, 468.630000000004657],
              [2697701.515713731758296, 1275888.779080412350595, 476.877500000002328],
              [2697059.26777348248288, 1275366.875776205211878, 444.23750000000291],
              [2697060.184023855254054, 1275363.998276852304116, 444.264999999999418],
              [2696694.176463683601469, 1275369.565672601573169, 440.002500000002328],
              [2696577.582863570190966, 1275539.137202813988551, 451.378750000003492],
              [2696114.383818945847452, 1275790.785419655730948, 429.526249999995343],
              [2695751.668134036939591, 1276607.320228208554909, 472.412500000005821],
              [2695748.653084385674447, 1276725.535137607017532, 468.673750000001746],
              [2695956.590706421993673, 1276671.122858658432961, 477.711249999993015],
              [2695920.889310546219349, 1276942.360092299059033, 466.748749999998836],
              [2696014.029493964277208, 1277243.147932563442737, 480.508749999993597],
              [2696107.133128066547215, 1277515.876469106646255, 471.707500000004075],
              [2696078.839382302947342, 1277577.36647807364352, 472.05000000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 38, gemeinde_B: 53, name: 'Bülach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2681957.359714129939675, 1268355.024942972464487, 412.322499999994761],
              [2682525.249102639965713, 1268261.600973101099953, 557.251250000001164],
              [2682635.377913980744779, 1268084.49358356022276, 561.865000000005239],
              [2682874.196579128969461, 1267922.983417257666588, 555.11250000000291],
              [2683153.2066944045946, 1267635.90362348780036, 563.711249999993015],
              [2683723.225552341435105, 1267224.158970037009567, 475.445000000006985],
              [2683703.445372226182371, 1267143.729882849846035, 461.623749999998836],
              [2683782.482431662734598, 1266348.288886193884537, 523.712499999994179],
              [2684159.908506214153022, 1265916.833545935805887, 509.90625],
              [2684383.683763998560607, 1265497.606517789652571, 529.48750000000291],
              [2684579.297296373639256, 1264949.788601935375482, 512.608749999999418],
              [2684631.87909125443548, 1264447.954610862070695, 554.347500000003492],
              [2684772.557702963706106, 1264210.660597427515313, 543.607499999998254],
              [2685040.39782502129674, 1264199.465818606549874, 552.722500000003492],
              [2685219.757380141876638, 1264147.511235963786021, 518.463749999995343],
              [2685426.613975383806974, 1263989.679372183047235, 544.73124999999709],
              [2685610.33763504261151, 1263705.627946967724711, 537.639999999999418],
              [2685365.970799811184406, 1262805.989178196992725, 588.771250000005239],
              [2685264.060665655881166, 1262710.838930108584464, 578.587499999994179],
              [2685209.431978695560247, 1262780.879049418494105, 583.472500000003492],
              [2684959.894214538857341, 1262875.662315078312531, 563.462499999994179],
              [2684957.934212784282863, 1262881.782311925431713, 563.330000000001746],
              [2684956.184218316338956, 1262901.252322483807802, 563.756250000005821],
              [2684313.753190585412085, 1263299.419177545700222, 485.493749999994179],
              [2683712.664761139079928, 1262790.664781907107681, 510.59375],
              [2683276.936026468407363, 1262537.112486354308203, 416.056249999994179],
              [2682567.686162734869868, 1262087.275249095866457, 427.032500000001164],
              [2682530.539819445461035, 1262072.623825552640483, 419.880000000004657],
              [2682019.786258112173527, 1261722.9941837254446, 434.766250000000582],
              [2681816.698718840721995, 1261589.955362885026261, 431.88749999999709],
              [2681655.997508436441422, 1261482.627938767662272, 435.053750000006403],
              [2681397.142334294505417, 1261582.566368983825669, 421.822499999994761],
              [2681346.046072538010776, 1261620.746348257176578, 420.953750000000582],
              [2681340.297338389325887, 1261685.002628541085869, 422.181249999994179],
              [2681151.756033257115632, 1262389.26878495584242, 418.08125000000291],
              [2681361.436127573717386, 1262516.277708748122677, 425.20625000000291],
              [2681530.882295658811927, 1262816.017555201426148, 418.777499999996508],
              [2681481.056048677302897, 1262902.546312477206811, 419.047500000000582],
              [2681485.42854624800384, 1262914.747557963943109, 418.857499999998254],
              [2681468.599797987379134, 1262924.522561598336324, 419.003750000003492],
              [2681802.130819576326758, 1263487.002133469562978, 401.182499999995343],
              [2681888.867090124636889, 1263654.947171841748059, 402.710000000006403],
              [2681748.907047336921096, 1264010.880847156746313, 399.517500000001746],
              [2681559.31833500508219, 1264122.587171800434589, 402.552500000005239],
              [2681517.473332536406815, 1264139.562168027507141, 402.391250000000582],
              [2681335.329505007714033, 1264278.117027108324692, 396.542499999995925],
              [2681654.229449364356697, 1265564.841928194509819, 392.068750000005821],
              [2681591.868216938804835, 1265681.186963118612766, 393.467499999998836],
              [2681515.588175813667476, 1265984.574389802291989, 390.35624999999709],
              [2681476.963226089254022, 1266159.960736138280481, 394.261249999995925],
              [2681179.044373556505889, 1266522.01305172755383, 386.563750000001164],
              [2681483.205585169605911, 1266627.952975591178983, 383.40625],
              [2682064.742327602114528, 1267027.328892393270507, 420.955000000001746],
              [2682076.793435560073704, 1267222.82987760216929, 410.01249999999709],
              [2682094.879825179465115, 1267280.051389522850513, 420.752500000002328],
              [2681957.359714129939675, 1268355.024942972464487, 412.322499999994761]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 40, gemeinde_B: 55, name: 'Eglisau', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2683723.225552341435105, 1267224.158970037009567, 475.445000000006985],
              [2683153.2066944045946, 1267635.90362348780036, 563.711249999993015],
              [2682874.196579128969461, 1267922.983417257666588, 555.11250000000291],
              [2682635.377913980744779, 1268084.49358356022276, 561.865000000005239],
              [2682525.249102639965713, 1268261.600973101099953, 557.251250000001164],
              [2681957.359714129939675, 1268355.024942972464487, 412.322499999994761],
              [2681830.409538326784968, 1268356.457115201512352, 398.868749999994179],
              [2681411.10869798483327, 1268060.109139107400551, 430.726250000006985],
              [2681187.283772927243263, 1268962.07164191105403, 533.082500000004075],
              [2681220.21480037085712, 1269216.054975520586595, 515.897500000006403],
              [2681119.494883412495255, 1269378.313885549316183, 522.392500000001746],
              [2680993.024699287954718, 1269317.106041316874325, 508.298750000001746],
              [2680834.576135646551847, 1269428.977646452840418, 522.815000000002328],
              [2679985.258108590263873, 1269467.237922260304913, 482.839999999996508],
              [2679938.908131616190076, 1269451.359216417185962, 484.65625],
              [2679902.948127241339535, 1269444.39045883435756, 484.347500000003492],
              [2679865.281529188621789, 1269897.334808001760393, 457.493749999994179],
              [2679752.770508055575192, 1269943.753992118407041, 475.270000000004075],
              [2679660.719212615396827, 1269926.955158103024587, 471.83125000000291],
              [2679622.689259654842317, 1269922.115247506415471, 475.497499999997672],
              [2679479.819129524752498, 1269891.046254601329565, 465.55000000000291],
              [2678992.653021566104144, 1269843.519031780073419, 476.923750000001746],
              [2678639.456295179668814, 1270121.504530392121524, 343.558749999996508],
              [2678660.546297070104629, 1270120.442033649422228, 343.692500000004657],
              [2678900.841293194331229, 1270211.997023418080062, 343.251250000001164],
              [2679036.001577365677804, 1270073.337556849932298, 365.153749999997672],
              [2679171.682804407086223, 1270081.647511446615681, 363.292499999995925],
              [2679354.284103310666978, 1270095.526350969681516, 366.960000000006403],
              [2679460.514024921692908, 1270129.41495167161338, 360.832500000004075],
              [2679802.585444059688598, 1270185.034015719313174, 373.691250000003492],
              [2680030.172554752323776, 1270478.643280375050381, 343.477499999993597],
              [2679858.392561702989042, 1270550.312046563718468, 344.118749999994179],
              [2679839.375057673081756, 1270557.193289293907583, 343.818750000005821],
              [2680318.685655319597572, 1270583.231908761896193, 389.681249999994179],
              [2680821.631946058478206, 1270575.863227067980915, 392.488750000004075],
              [2681002.333168255630881, 1270671.405672485008836, 390.225000000005821],
              [2681014.731919427867979, 1270687.103174612158909, 390.307499999995343],
              [2681165.459528704173863, 1271281.530882770661265, 398.647500000006403],
              [2680921.880706467200071, 1271519.819502766476944, 393.246249999996508],
              [2680878.472012917045504, 1271601.399610552703962, 397.632500000006985],
              [2680969.048225735779852, 1271968.100791990291327, 394.711249999993015],
              [2681076.169571164064109, 1272016.080970428884029, 402.0],
              [2681098.040798293426633, 1272019.630926986690611, 400.221250000002328],
              [2681851.627186834346503, 1271952.929924381431192, 410.377500000002328],
              [2682393.32472836971283, 1271356.87623795052059, 413.182499999995343],
              [2682408.904727017506957, 1271350.656235087895766, 413.067500000004657],
              [2682479.914727981202304, 1271331.764985491055995, 413.091249999997672],
              [2683158.186094380915165, 1271955.162697671446949, 421.578750000000582],
              [2683360.227368182502687, 1272017.488989322911948, 423.264999999999418],
              [2683422.061041404027492, 1271928.082593275932595, 417.318750000005821],
              [2683410.568567178677768, 1271927.263891866663471, 419.307499999995343],
              [2682708.018475237302482, 1271425.624977166531608, 412.722500000003492],
              [2682596.642251922748983, 1271317.565028324490413, 414.851250000006985],
              [2682548.487153369933367, 1271136.528592419577762, 407.297500000000582],
              [2682604.979527556337416, 1270455.228196704061702, 493.741250000006403],
              [2682607.167028831783682, 1270452.456949031213298, 493.837499999994179],
              [2682668.606308644171804, 1270262.159343771636486, 438.373749999998836],
              [2682561.724132175557315, 1269958.707450330723077, 463.341249999997672],
              [2683352.575804444029927, 1269851.525725697400048, 495.176250000004075],
              [2683054.268824448343366, 1269334.130764224100858, 343.171249999999418],
              [2683112.335078761912882, 1269281.164521071361378, 343.467499999998836],
              [2683243.962577604688704, 1269157.224516047630459, 343.297500000000582],
              [2683493.012580397538841, 1268803.977015023352578, 343.35624999999709],
              [2683507.681329964660108, 1268778.060763803077862, 343.313750000001164],
              [2683514.527583715505898, 1268764.837020638398826, 343.597500000003492],
              [2683527.227579785976559, 1268737.903262894367799, 343.287500000005821],
              [2683533.072581607382745, 1268724.20701612229459, 343.423750000001746],
              [2683596.802581137511879, 1268569.590763168176636, 343.346250000002328],
              [2683601.636339267715812, 1268557.352028225315735, 343.967499999998836],
              [2683617.341331103816628, 1268507.14326234254986, 343.330000000001746],
              [2683688.736334808170795, 1268140.794515545945615, 343.563750000001164],
              [2683690.902581590227783, 1268079.557009045733139, 343.313750000001164],
              [2683690.232585246674716, 1268019.770765465451404, 343.59375],
              [2683740.181331169325858, 1267821.387005642754957, 343.246249999996508],
              [2683758.148832619190216, 1267791.972007875097916, 343.346250000002328],
              [2683961.291333430912346, 1267537.16325454111211, 343.282500000001164],
              [2683949.912585181184113, 1267328.069506453350186, 343.41874999999709],
              [2683965.24258387926966, 1267239.730753143085167, 343.307499999995343],
              [2683723.225552341435105, 1267224.158970037009567, 475.445000000006985]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 42, gemeinde_B: 57, name: 'Freienstein-Teufen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2683961.291333430912346, 1267537.16325454111211, 343.282500000001164],
              [2683975.092583916615695, 1267520.014505119295791, 343.311249999998836],
              [2684000.193833369296044, 1267494.499503534985706, 343.253750000003492],
              [2684210.272588599007577, 1267456.838259945157915, 343.529999999998836],
              [2684310.27508617984131, 1267508.85575433075428, 343.286250000004657],
              [2684448.721339243929833, 1267690.439509341493249, 343.443750000005821],
              [2684732.670089762657881, 1268201.44450986222364, 343.327499999999418],
              [2684888.083842742722481, 1268454.992014992283657, 343.470000000001164],
              [2684914.076341396663338, 1268499.674512424739078, 343.352499999993597],
              [2684947.522592301014811, 1268569.609514134470373, 343.403749999997672],
              [2684957.146342108491808, 1268593.600763803813607, 343.383749999993597],
              [2686121.233127159997821, 1267822.876227880362421, 670.602499999993597],
              [2686807.721720901317894, 1267474.799663835670799, 657.897500000006403],
              [2687012.570541882421821, 1267330.112288567936048, 663.098750000004657],
              [2687036.289212702773511, 1267244.329639483708888, 657.020000000004075],
              [2687111.648091697134078, 1267083.514875293709338, 666.792499999995925],
              [2687193.138000797014683, 1266971.94095259392634, 659.756250000005821],
              [2687212.676767784170806, 1266946.50973332580179, 661.032500000001164],
              [2687297.564213314093649, 1266785.057127668755129, 656.776249999995343],
              [2687956.359677576459944, 1266023.011711940634996, 691.483749999999418],
              [2688376.508215490262955, 1265615.932551437756047, 674.827499999999418],
              [2687929.037950533442199, 1264251.227603029925376, 368.886249999995925],
              [2687922.227949625812471, 1264254.767601487692446, 368.821249999993597],
              [2687844.289203472901136, 1264257.378859845222905, 369.163750000006985],
              [2686703.821616495493799, 1264944.172471395693719, 363.247499999997672],
              [2686610.41161990351975, 1264898.464978815987706, 363.565000000002328],
              [2686518.191616526339203, 1264839.249973498051986, 363.36250000000291],
              [2686459.86153370840475, 1264803.242320202291012, 357.063750000001164],
              [2686034.097764118108898, 1264931.081041188910604, 355.827499999999418],
              [2685279.219842205289751, 1266500.761358366813511, 419.776249999995343],
              [2684714.077628450002521, 1266476.174569721566513, 346.264999999999418],
              [2683987.307583830785006, 1267200.372002445627004, 343.289999999993597],
              [2683971.601334372535348, 1267226.068253868957981, 343.341249999997672],
              [2683965.24258387926966, 1267239.730753143085167, 343.307499999995343],
              [2683949.912585181184113, 1267328.069506453350186, 343.41874999999709],
              [2683961.291333430912346, 1267537.16325454111211, 343.282500000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 43, gemeinde_B: 58, name: 'Glattfelden', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2677166.416138139553368, 1269558.434251069556922, 332.242499999993015],
              [2677219.972389440517873, 1269575.33425286761485, 332.3125],
              [2677280.13238843344152, 1269601.226750276517123, 332.19999999999709],
              [2677736.691149209626019, 1269908.634266222361475, 332.777499999996508],
              [2677793.458783762063831, 1269956.13702021795325, 343.176250000004075],
              [2678572.78129566134885, 1270132.355782384984195, 343.636249999995925],
              [2678639.456295179668814, 1270121.504530392121524, 343.558749999996508],
              [2678992.653021566104144, 1269843.519031780073419, 476.923750000001746],
              [2679479.819129524752498, 1269891.046254601329565, 465.55000000000291],
              [2679622.689259654842317, 1269922.115247506415471, 475.497499999997672],
              [2679660.719212615396827, 1269926.955158103024587, 471.83125000000291],
              [2679752.770508055575192, 1269943.753992118407041, 475.270000000004075],
              [2679865.281529188621789, 1269897.334808001760393, 457.493749999994179],
              [2679902.948127241339535, 1269444.39045883435756, 484.347500000003492],
              [2679938.908131616190076, 1269451.359216417185962, 484.65625],
              [2679985.258108590263873, 1269467.237922260304913, 482.839999999996508],
              [2680834.576135646551847, 1269428.977646452840418, 522.815000000002328],
              [2680993.024699287954718, 1269317.106041316874325, 508.298750000001746],
              [2681119.494883412495255, 1269378.313885549316183, 522.392500000001746],
              [2681220.21480037085712, 1269216.054975520586595, 515.897500000006403],
              [2681187.283772927243263, 1268962.07164191105403, 533.082500000004075],
              [2681411.10869798483327, 1268060.109139107400551, 430.726250000006985],
              [2681830.409538326784968, 1268356.457115201512352, 398.868749999994179],
              [2681957.359714129939675, 1268355.024942972464487, 412.322499999994761],
              [2682094.879825179465115, 1267280.051389522850513, 420.752500000002328],
              [2682076.793435560073704, 1267222.82987760216929, 410.01249999999709],
              [2682064.742327602114528, 1267027.328892393270507, 420.955000000001746],
              [2681483.205585169605911, 1266627.952975591178983, 383.40625],
              [2681179.044373556505889, 1266522.01305172755383, 386.563750000001164],
              [2681127.737456555478275, 1266466.522896070266142, 431.557499999995343],
              [2681063.254970439244062, 1266449.13792312797159, 432.67500000000291],
              [2680976.696115043014288, 1266518.251477504149079, 424.610000000000582],
              [2680186.945313326083124, 1265820.341077455552295, 459.787500000005821],
              [2679921.011348873842508, 1266336.219434349099174, 443.436249999998836],
              [2679843.342543539591134, 1266504.594333293614909, 439.222500000003492],
              [2679368.5839305492118, 1266954.085854057688266, 450.182499999995343],
              [2678291.025321444496512, 1267682.968788926722482, 365.110000000000582],
              [2676580.609674165025353, 1267996.392323671840131, 510.102499999993597],
              [2677333.642323936335742, 1268497.62523288000375, 424.111250000001746],
              [2677409.181164066772908, 1268592.935402742121369, 431.05000000000291],
              [2677488.193595179356635, 1268687.501521926373243, 425.651249999995343],
              [2677166.416138139553368, 1269558.434251069556922, 332.242499999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 47, gemeinde_B: 62, name: 'Kloten', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2687065.425483994651586, 1254530.046155048534274, 467.28125],
              [2687024.696815819479525, 1254643.250057672150433, 473.568750000005821],
              [2686267.086487768217921, 1254806.631968769943342, 449.072499999994761],
              [2686081.170444345567375, 1254861.444853941211477, 465.023749999993015],
              [2685326.046241664793342, 1254894.002782295690849, 430.927500000005239],
              [2685209.801259839907289, 1255072.437819547951221, 432.408750000002328],
              [2684252.673591498751193, 1255069.295025093713775, 420.1875],
              [2684180.352351010777056, 1255003.862543389899656, 420.967499999998836],
              [2684065.472346773836762, 1255142.538788770325482, 420.727499999993597],
              [2683991.772381944116205, 1255237.251356026390567, 423.482499999998254],
              [2683513.388577796518803, 1255782.628768863389269, 419.677500000005239],
              [2683655.957356127444655, 1256547.348825600231066, 421.770000000004075],
              [2683445.534847039263695, 1256613.286313015269116, 421.223750000004657],
              [2684116.274849978741258, 1257925.172568200388923, 421.001250000001164],
              [2684753.323691637720913, 1258156.333979003829882, 427.582500000004075],
              [2684428.747556373476982, 1258850.252953875809908, 436.636249999995925],
              [2685594.289716422557831, 1258701.457117139128968, 505.477499999993597],
              [2685981.492757846135646, 1259439.269369629910216, 546.61250000000291],
              [2686147.859123948495835, 1259353.438329965109006, 555.345000000001164],
              [2687273.969731491990387, 1258079.497103928588331, 505.205000000001746],
              [2688028.713157328311354, 1258457.345050496514887, 575.227499999993597],
              [2687993.983126621227711, 1258763.602498187450692, 572.923750000001746],
              [2687993.947028648573905, 1259141.857783251209185, 584.533750000002328],
              [2688336.212470723316073, 1259608.877539444947615, 522.596250000002328],
              [2688571.58167408592999, 1259434.320868983399123, 556.953750000000582],
              [2689072.821980516891927, 1258812.999112100573257, 484.597500000003492],
              [2689438.917039706371725, 1258845.050266164587811, 583.985000000000582],
              [2689438.367041640449315, 1258830.669019563356414, 584.132500000006985],
              [2689471.211866916157305, 1258358.277441589161754, 570.787500000005821],
              [2689483.100972347892821, 1258090.611842348473147, 597.83125000000291],
              [2689714.635783525183797, 1257811.357736124424264, 583.227499999993597],
              [2690016.29344770219177, 1257740.015530049568042, 595.427500000005239],
              [2689724.718525518197566, 1257229.568375832866877, 506.488750000004075],
              [2689511.456241252366453, 1257059.187525150133297, 523.087499999994179],
              [2688929.391624102368951, 1257235.041994087630883, 552.767500000001746],
              [2688225.134143955539912, 1256471.873485740739852, 459.553750000006403],
              [2688203.501639148686081, 1256420.829726813361049, 459.202499999999418],
              [2688048.325399994850159, 1256354.712249083677307, 460.147500000006403],
              [2688038.523946013767272, 1255654.308117100968957, 444.57499999999709],
              [2687938.888249122072011, 1255147.760382274864241, 486.847500000003492],
              [2687878.430664136074483, 1255020.307725786697119, 480.407500000001164],
              [2687834.936942060478032, 1254918.692777029238641, 482.571249999993597],
              [2687515.466813099570572, 1254818.320044872583821, 472.977499999993597],
              [2687336.598201203159988, 1254579.720300323795527, 483.662500000005821],
              [2687321.876918806694448, 1254546.97774062701501, 481.198749999995925],
              [2687065.425483994651586, 1254530.046155048534274, 467.28125]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 51, gemeinde_B: 66, name: 'Opfikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2684180.352351010777056, 1255003.862543389899656, 420.967499999998836],
              [2684252.673591498751193, 1255069.295025093713775, 420.1875],
              [2685209.801259839907289, 1255072.437819547951221, 432.408750000002328],
              [2685326.046241664793342, 1254894.002782295690849, 430.927500000005239],
              [2686081.170444345567375, 1254861.444853941211477, 465.023749999993015],
              [2686267.086487768217921, 1254806.631968769943342, 449.072499999994761],
              [2687024.696815819479525, 1254643.250057672150433, 473.568750000005821],
              [2687065.425483994651586, 1254530.046155048534274, 467.28125],
              [2687055.2453159885481, 1254184.275842502247542, 454.442500000004657],
              [2686646.04062489233911, 1253152.535159248160198, 478.351250000006985],
              [2685662.089898164384067, 1253075.626337612746283, 423.48124999999709],
              [2685652.640018097590655, 1253057.576558788539842, 432.672500000000582],
              [2685640.648657413199544, 1252916.057603638619184, 424.201249999998254],
              [2685655.678706954233348, 1252778.107693491503596, 427.98124999999709],
              [2685650.022417947184294, 1252755.40012147789821, 424.997499999997672],
              [2685397.283622733317316, 1252658.79504168103449, 421.712499999994179],
              [2685346.386166252195835, 1252623.441372526576743, 425.082500000004075],
              [2685237.459905953612179, 1252454.348853943636641, 424.367499999993015],
              [2685216.227420385926962, 1252406.527630512602627, 425.48750000000291],
              [2685108.529906381387264, 1252338.130105975084007, 424.490000000005239],
              [2685093.239920884836465, 1252334.682632965734228, 425.61250000000291],
              [2684836.551155125256628, 1252342.976358479587361, 424.588749999995343],
              [2684178.669932617340237, 1252819.452675073640421, 427.182499999995343],
              [2684250.58885659603402, 1253916.896755289752036, 440.508749999993597],
              [2684518.832360910251737, 1254366.490050189197063, 421.471250000002328],
              [2684323.068634185474366, 1254654.978849214501679, 423.403749999997672],
              [2684200.657342046499252, 1254896.315025498857722, 420.26249999999709],
              [2684180.352351010777056, 1255003.862543389899656, 420.967499999998836]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 52, gemeinde_B: 67, name: 'Rafz', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2681014.14768574340269, 1276265.877032936317846, 545.962499999994179],
              [2681065.941626612097025, 1276511.094895429676399, 560.642500000001746],
              [2682156.563915131147951, 1276266.515718216542155, 543.337499999994179],
              [2682644.878111920319498, 1276192.24044156447053, 481.096250000002328],
              [2682688.478996173944324, 1275648.699602206936106, 549.077499999999418],
              [2682899.195206089410931, 1275706.123272535391152, 545.803750000006403],
              [2683005.09522107988596, 1275737.447048740461469, 546.861250000001746],
              [2684450.67267768830061, 1274892.714576686033979, 446.297500000000582],
              [2684525.354767980985343, 1274608.231150925857946, 510.697499999994761],
              [2685179.871286966837943, 1274282.170543464599177, 434.952499999999418],
              [2684863.898586349096149, 1273870.176264350768179, 515.634999999994761],
              [2684473.409570994786918, 1272655.482167719863355, 399.683749999996508],
              [2684056.248686761595309, 1272481.467859697761014, 428.042499999995925],
              [2684001.754809184931219, 1272071.925117685692385, 418.277499999996508],
              [2683977.679883962962776, 1272075.896508445963264, 424.036250000004657],
              [2683485.748533207457513, 1271921.18257670593448, 416.64375000000291],
              [2683422.061041404027492, 1271928.082593275932595, 417.318750000005821],
              [2683360.227368182502687, 1272017.488989322911948, 423.264999999999418],
              [2683158.186094380915165, 1271955.162697671446949, 421.578750000000582],
              [2683154.903594659175724, 1271959.440198291325942, 421.602499999993597],
              [2681688.533430708106607, 1273758.167431125184521, 410.061249999998836],
              [2681476.079596924595535, 1274616.419642996508628, 500.118749999994179],
              [2681481.944604711607099, 1274653.653407954145223, 500.714999999996508],
              [2681403.145742192864418, 1274882.431949535617605, 492.11250000000291],
              [2681014.14768574340269, 1276265.877032936317846, 545.962499999994179]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 55, gemeinde_B: 70, name: 'Wasterkingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2678881.227770905476063, 1271274.023711834801361, 360.910000000003492],
              [2678415.470485376659781, 1271021.461622723378241, 377.804999999993015],
              [2678378.105473234783858, 1271018.767850348493084, 376.888749999998254],
              [2678303.639235625043511, 1271036.192875131033361, 377.897500000006403],
              [2677357.320404227823019, 1270904.176485284697264, 372.197499999994761],
              [2677191.398058035410941, 1271317.133032668847591, 384.248749999998836],
              [2676868.83303957246244, 1271353.940503284102306, 383.029999999998836],
              [2676644.540675592143089, 1272887.859274989925325, 587.934999999997672],
              [2677082.38168007414788, 1272900.898798572132364, 568.44999999999709],
              [2677448.495676991995424, 1273083.16301040397957, 587.258749999993597],
              [2678136.249458845704794, 1273848.981943313265219, 492.217499999998836],
              [2678650.129770710133016, 1273226.963766945293173, 515.921249999999418],
              [2678674.508509477134794, 1273206.633744938531891, 515.029999999998836],
              [2678484.083866286091506, 1272940.074421855155379, 542.820000000006985],
              [2678880.987768271472305, 1271277.848706887569278, 360.70625000000291],
              [2678881.227770905476063, 1271274.023711834801361, 360.910000000003492]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 56, gemeinde_B: 71, name: 'Wil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2678136.249458845704794, 1273848.981943313265219, 492.217499999998836],
              [2678640.872715410776436, 1274309.166002443758771, 453.607499999998254],
              [2678825.464053693693131, 1274267.364915768383071, 460.297500000000582],
              [2678930.151482164859772, 1274347.687279002508149, 454.682499999995343],
              [2680420.518015088513494, 1274641.89528803457506, 475.386249999995925],
              [2680921.022785776294768, 1276180.790973264724016, 553.76249999999709],
              [2681014.14768574340269, 1276265.877032936317846, 545.962499999994179],
              [2681403.145742192864418, 1274882.431949535617605, 492.11250000000291],
              [2681481.944604711607099, 1274653.653407954145223, 500.714999999996508],
              [2681476.079596924595535, 1274616.419642996508628, 500.118749999994179],
              [2681688.533430708106607, 1273758.167431125184521, 410.061249999998836],
              [2683154.903594659175724, 1271959.440198291325942, 421.602499999993597],
              [2683158.186094380915165, 1271955.162697671446949, 421.578750000000582],
              [2682479.914727981202304, 1271331.764985491055995, 413.091249999997672],
              [2682408.904727017506957, 1271350.656235087895766, 413.067500000004657],
              [2682393.32472836971283, 1271356.87623795052059, 413.182499999995343],
              [2681851.627186834346503, 1271952.929924381431192, 410.377500000002328],
              [2681098.040798293426633, 1272019.630926986690611, 400.221250000002328],
              [2681076.169571164064109, 1272016.080970428884029, 402.0],
              [2680969.048225735779852, 1271968.100791990291327, 394.711249999993015],
              [2680967.983226095326245, 1271976.009542756248266, 394.740000000005239],
              [2680846.704216425772756, 1272237.12280732463114, 374.79374999999709],
              [2680684.410738913342357, 1272330.793324630707502, 395.928750000006403],
              [2680257.670685081742704, 1272396.529480773489922, 392.0625],
              [2679994.745790559798479, 1272431.228434648597613, 400.388749999998254],
              [2679784.704031739383936, 1272475.116088715614751, 457.8125],
              [2679721.854040615726262, 1272482.026106758974493, 458.547500000000582],
              [2679671.415008034091443, 1272616.865575043018907, 436.747499999997672],
              [2678674.508509477134794, 1273206.633744938531891, 515.029999999998836],
              [2678650.129770710133016, 1273226.963766945293173, 515.921249999999418],
              [2678136.249458845704794, 1273848.981943313265219, 492.217499999998836]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 58, gemeinde_B: 81, name: 'Bachs', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2672584.08570986893028, 1265168.609347258927301, 594.455000000001746],
              [2672680.101983001921326, 1265192.945639127166942, 596.167499999995925],
              [2672457.925033497624099, 1265922.045573436189443, 541.720000000001164],
              [2672995.712034594267607, 1266338.885992443654686, 404.628750000003492],
              [2673683.503915243782103, 1266588.372053243685514, 550.89375000000291],
              [2674620.398002936504781, 1266094.906416221288964, 576.323749999995925],
              [2674837.079189505893737, 1265820.780037719057873, 571.167499999995925],
              [2674974.066863546147943, 1265661.869112166110426, 584.585000000006403],
              [2675142.124019318725914, 1265676.042206681799144, 557.622499999997672],
              [2675189.21902268845588, 1265697.843462212476879, 557.841249999997672],
              [2675444.770912119187415, 1265743.735916703473777, 607.358749999999418],
              [2675422.272071881219745, 1265661.175745515385643, 600.357499999998254],
              [2675535.090999198611826, 1265487.774826019769534, 614.036250000004657],
              [2675640.081579164136201, 1265238.509803380817175, 561.801250000004075],
              [2675652.496576397679746, 1265208.14229751820676, 561.573749999995925],
              [2675702.69163588155061, 1265127.842407886171713, 566.151249999995343],
              [2676244.378640924580395, 1264186.128950187005103, 527.149999999994179],
              [2676463.033815369475633, 1263726.481769492849708, 540.497499999997672],
              [2676568.771151618566364, 1263530.30395620316267, 527.677500000005239],
              [2676753.032444578595459, 1263531.40903316764161, 530.850000000005821],
              [2676821.05243824608624, 1263538.927769810892642, 530.297500000000582],
              [2676948.794608699157834, 1263316.963393693557009, 504.596250000002328],
              [2677044.632249377202243, 1263211.101155587239191, 515.431249999994179],
              [2676606.534628060646355, 1262850.973432554863393, 506.381250000005821],
              [2676536.804651825223118, 1262768.005977954249829, 508.285000000003492],
              [2676513.933383842464536, 1262759.230944456066936, 506.907500000001164],
              [2675968.593161406461149, 1263153.186790367588401, 490.089999999996508],
              [2675147.928850476164371, 1262920.788106420310214, 544.410000000003492],
              [2674865.030017926357687, 1262965.280457294778898, 538.241250000006403],
              [2674800.408794815186411, 1262994.308009880362079, 540.395000000004075],
              [2674772.035030393861234, 1262990.411733217304572, 539.297500000000582],
              [2674131.292093459982425, 1263532.330792507855222, 603.291249999994761],
              [2673815.614857016131282, 1264056.760056059807539, 624.183749999996508],
              [2673681.602466757874936, 1264302.584020578768104, 632.892500000001746],
              [2672508.155654886737466, 1264993.496742567280307, 590.228749999994761],
              [2672584.08570986893028, 1265168.609347258927301, 594.455000000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 65, gemeinde_B: 88, name: 'Neerach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2676513.933383842464536, 1262759.230944456066936, 506.907500000001164],
              [2676536.804651825223118, 1262768.005977954249829, 508.285000000003492],
              [2676606.534628060646355, 1262850.973432554863393, 506.381250000005821],
              [2677044.632249377202243, 1263211.101155587239191, 515.431249999994179],
              [2677081.86600263370201, 1263250.15241133607924, 515.652499999996508],
              [2677667.973600807599723, 1263963.793987483950332, 425.847500000003492],
              [2678060.763505406677723, 1264048.843801643699408, 418.171249999999418],
              [2678089.731004624627531, 1264057.758799731265754, 418.089999999996508],
              [2678234.073569707106799, 1264100.833920017816126, 423.029999999998836],
              [2678795.954747502692044, 1264078.357524055754766, 417.035000000003492],
              [2679195.384870217647403, 1264647.657752600498497, 426.256250000005821],
              [2679200.728609696496278, 1264657.165232817176729, 425.438750000001164],
              [2679205.572361258789897, 1264665.738985741278157, 425.556249999994179],
              [2679380.056143905036151, 1264156.017789416480809, 427.942500000004657],
              [2679445.588605223689228, 1263879.643963155569509, 424.897500000006403],
              [2679372.038568489719182, 1263822.737644993234426, 422.108749999999418],
              [2679262.266631932929158, 1263324.502450331812724, 465.756250000005821],
              [2679010.825914441607893, 1262563.954851215239614, 410.419999999998254],
              [2678860.719651072751731, 1262480.44482799945399, 409.488750000004075],
              [2678712.147164083551615, 1262419.427354483865201, 410.598750000004657],
              [2678683.582147888373584, 1262154.367322298698127, 409.358749999999418],
              [2678932.900899281725287, 1261467.827314736088738, 409.276249999995343],
              [2678938.892152006737888, 1261452.238569609588012, 409.482499999998254],
              [2678988.115899196825922, 1261314.53731232159771, 409.227499999993597],
              [2678974.339650347828865, 1261313.52606470673345, 409.326249999998254],
              [2678935.859646275639534, 1261299.253557605436072, 409.037500000005821],
              [2678513.062147317454219, 1261049.069814734859392, 409.408750000002328],
              [2678502.245891651138663, 1261047.243554279441014, 408.977499999993597],
              [2678395.292204672005028, 1260900.919923123670742, 413.929999999993015],
              [2678292.160902813076973, 1260817.202326885424554, 409.983749999999418],
              [2677181.170016436837614, 1261056.416781893465668, 438.98750000000291],
              [2677263.756762120407075, 1261803.72521954565309, 477.402499999996508],
              [2677345.614230181090534, 1261875.52140854508616, 474.860000000000582],
              [2677317.958006738685071, 1262086.251461113803089, 476.947499999994761],
              [2676776.924963484983891, 1262531.466557429870591, 532.273749999993015],
              [2676513.933383842464536, 1262759.230944456066936, 506.907500000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 69, gemeinde_B: 92, name: 'Oberglatt', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2682971.609954411629587, 1260232.629053036682308, 429.902499999996508],
              [2683053.141066984273493, 1259854.883792409673333, 419.267500000001746],
              [2683246.542333982884884, 1259230.925065191695467, 420.422500000000582],
              [2683495.213568448554724, 1259050.573780371574685, 419.047500000000582],
              [2683259.011093115434051, 1258825.717578405514359, 421.10624999999709],
              [2683877.517331295646727, 1258175.98378990450874, 419.742499999993015],
              [2683042.662327511236072, 1257814.920044350204989, 420.037500000005821],
              [2682677.856038977857679, 1257532.861226633423939, 417.326249999998254],
              [2682073.188567980658263, 1257592.698791684349999, 419.990000000005239],
              [2681844.826049117837101, 1257596.838760614860803, 418.69999999999709],
              [2681726.957275118678808, 1258370.611224724911153, 416.952499999999418],
              [2681450.898529542610049, 1258171.374986057169735, 417.485000000000582],
              [2681340.65602145716548, 1258067.203722012229264, 416.9375],
              [2681081.836299469694495, 1257578.992990432074293, 438.552500000005239],
              [2681064.922479680273682, 1257712.394111863104627, 433.184999999997672],
              [2680357.382919328752905, 1258480.864952468080446, 467.671249999999418],
              [2680422.896192261017859, 1258698.736562493024394, 430.791249999994761],
              [2680419.59494255296886, 1258702.176563126500696, 430.816250000003492],
              [2680427.418688898906112, 1258718.5328063077759, 430.528749999997672],
              [2680228.401242735795677, 1258939.571662449743599, 434.837499999994179],
              [2679948.569895986001939, 1259412.137740915874019, 427.580000000001746],
              [2679967.116235417779535, 1259418.944157856749371, 434.476250000006985],
              [2679970.46873487951234, 1259428.75290687312372, 434.432499999995343],
              [2680047.747363820672035, 1259668.11393127264455, 425.028749999997672],
              [2680308.918653069995344, 1259749.409000699641183, 427.872499999997672],
              [2680623.938473956193775, 1260063.513663146179169, 413.826249999998254],
              [2680653.279901512898505, 1260202.930245650466532, 427.51249999999709],
              [2680653.079899756703526, 1260222.210242544766515, 427.377500000002328],
              [2680678.196160029619932, 1260244.314011484617367, 428.152499999996508],
              [2680879.073614543303847, 1260473.458924939390272, 424.502500000002328],
              [2681322.981131399516016, 1260831.680201683193445, 425.490000000005239],
              [2681568.706320962868631, 1260850.538051392417401, 439.922500000000582],
              [2681424.280011259019375, 1261112.19544491590932, 435.433749999996508],
              [2681691.547603750601411, 1261294.030614401213825, 442.367499999993015],
              [2681709.060095345135778, 1261325.881848672172055, 441.707500000004075],
              [2681746.233856225851923, 1261295.52061803243123, 442.517500000001746],
              [2682137.865059983450919, 1261135.316773060010746, 438.660000000003492],
              [2682088.056357672903687, 1260622.026858245488256, 442.357499999998254],
              [2682345.007610035128891, 1260411.92560599418357, 442.342499999998836],
              [2682733.963701228611171, 1260198.910301069961861, 429.830000000001746],
              [2682874.162464804947376, 1260214.541573982685804, 430.772500000006403],
              [2682971.609954411629587, 1260232.629053036682308, 429.902499999996508]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 70, gemeinde_B: 93, name: 'Oberweningen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2671898.033755466341972, 1264323.944265190511942, 540.003750000003492],
              [2672023.087054217699915, 1264547.865907197585329, 504.592499999998836],
              [2672584.08570986893028, 1265168.609347258927301, 594.455000000001746],
              [2672508.155654886737466, 1264993.496742567280307, 590.228749999994761],
              [2673681.602466757874936, 1264302.584020578768104, 632.892500000001746],
              [2673815.614857016131282, 1264056.760056059807539, 624.183749999996508],
              [2673623.97744076885283, 1264120.135220510885119, 630.921249999999418],
              [2673005.544023045804352, 1264117.778495607897639, 559.888749999998254],
              [2673421.715404676739126, 1262730.367336626630276, 667.317500000004657],
              [2673175.845253284089267, 1261257.742306335596368, 460.506250000005821],
              [2673371.110592402517796, 1260622.47668772097677, 486.811249999998836],
              [2673476.481796703767031, 1260589.176598846213892, 483.15625],
              [2673417.581255643162876, 1259940.722795367473736, 636.108749999999418],
              [2673478.471214491408318, 1259904.73271556594409, 632.83125000000291],
              [2673281.30071139940992, 1259509.37273871852085, 788.955000000001746],
              [2672916.251138902734965, 1259497.262308394769207, 822.832500000004075],
              [2672521.948909337166697, 1260252.264497654745355, 551.368749999994179],
              [2672497.038934411481023, 1261569.039691995363683, 455.664999999993597],
              [2672583.756199292372912, 1262737.812746134586632, 632.65625],
              [2672236.776179196778685, 1264140.054110669996589, 533.725000000005821],
              [2672184.796394719276577, 1264196.588271852582693, 550.630000000004657],
              [2671898.033755466341972, 1264323.944265190511942, 540.003750000003492]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 62, gemeinde_B: 85, name: 'Dänikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2671734.647158185951412, 1255990.642410084372386, 414.946249999993597],
              [2671861.149691929109395, 1256065.153722225455567, 417.497499999997672],
              [2672300.288417769130319, 1256100.238669149577618, 415.292499999995925],
              [2672627.293459803331643, 1256079.166242493083701, 418.342499999998836],
              [2673243.588462754618376, 1256090.811237232759595, 418.128750000003492],
              [2673787.128480183891952, 1256006.961259699193761, 419.095000000001164],
              [2673720.010985095053911, 1255937.008769524749368, 419.524999999994179],
              [2673719.402226919308305, 1255932.898754106136039, 418.88749999999709],
              [2674000.748491154052317, 1255950.39002606458962, 419.794999999998254],
              [2673988.132230618968606, 1255894.720005976967514, 418.98124999999709],
              [2673644.872590309474617, 1255246.578183382051066, 447.28125],
              [2673651.432581434026361, 1255244.825666542397812, 446.583750000005239],
              [2673729.861042419448495, 1254211.176063003949821, 619.009999999994761],
              [2673573.831081174779683, 1254261.48864046880044, 622.197499999994761],
              [2673506.257262998726219, 1254302.983514460967854, 616.947499999994761],
              [2673047.403689188882709, 1254369.943858581827953, 631.186249999998836],
              [2672933.014768876601011, 1254411.294791355263442, 618.002500000002328],
              [2672792.38982965471223, 1254461.44616001448594, 622.898749999993015],
              [2672674.166136841289699, 1254539.937521761283278, 627.493749999994179],
              [2672414.670228003989905, 1254834.273318805731833, 556.713749999995343],
              [2672123.639223292469978, 1254785.885037568630651, 576.173750000001746],
              [2672204.927582410164177, 1255307.651946181431413, 447.771250000005239],
              [2672190.821335684508085, 1255387.943203369621187, 448.039999999993597],
              [2671734.647158185951412, 1255990.642410084372386, 414.946249999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 73, gemeinde_B: 96, name: 'Regensdorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2675889.170577607117593, 1253538.056379568297416, 580.618749999994179],
              [2676414.577455533202738, 1254524.906622462673113, 434.679999999993015],
              [2676410.021205038763583, 1254529.915371657814831, 434.645000000004075],
              [2676414.977458719629794, 1254543.544128583976999, 434.927500000005239],
              [2675635.297334447968751, 1255575.531418928876519, 425.865000000005239],
              [2675581.938584654126316, 1255735.791421694913879, 425.923750000001746],
              [2675741.298589723184705, 1255717.420178191270679, 426.201249999998254],
              [2675820.039796062279493, 1256058.84134784899652, 422.756250000005821],
              [2676169.996209364384413, 1256382.12290087621659, 435.202499999999418],
              [2676376.25617434643209, 1256356.872831192566082, 432.327499999999418],
              [2676710.994933848734945, 1256334.336592727806419, 432.817500000004657],
              [2677106.81185883609578, 1256880.81660559005104, 484.922500000000582],
              [2677297.344317087903619, 1256952.466524234274402, 481.528749999997672],
              [2677296.479327096603811, 1257021.959043689304963, 482.307499999995343],
              [2678016.101629648823291, 1257248.581161879003048, 466.423750000001746],
              [2678320.248055601026863, 1257127.66648403275758, 479.809999999997672],
              [2678626.646573120262474, 1257106.75354295107536, 461.561249999998836],
              [2679137.257205806206912, 1256498.922209101263434, 510.095000000001164],
              [2679308.15712104504928, 1256702.107049328740686, 503.397500000006403],
              [2679352.534697034396231, 1256519.19843840226531, 509.232499999998254],
              [2679573.092127995099872, 1256466.895804374245927, 503.705000000001746],
              [2679567.93842637212947, 1255989.557139820419252, 507.438750000001164],
              [2679639.610900606960058, 1255852.135838826419786, 505.382500000006985],
              [2679649.612167207989842, 1255802.945869078161195, 506.65625],
              [2679648.652166713494807, 1255794.955868093296885, 506.618749999994179],
              [2680073.706965055782348, 1255519.971730553777888, 490.672500000000582],
              [2680078.509465241339058, 1255509.585480706999078, 490.682499999995343],
              [2680217.256318537052721, 1254324.203012102982029, 440.59375],
              [2680213.3550705765374, 1254301.805515768472105, 440.753750000003492],
              [2680089.762547366321087, 1254276.901724591385573, 439.052500000005239],
              [2679002.62003837665543, 1253885.177974175428972, 439.160000000003492],
              [2678981.278799424879253, 1253850.89799484773539, 440.029999999998836],
              [2678932.407537018414587, 1253787.147972034057602, 439.104999999995925],
              [2678929.785049729980528, 1253587.06049395352602, 440.086249999993015],
              [2678933.663787654135376, 1253530.267970859073102, 439.147500000006403],
              [2679347.116641266271472, 1252923.367366201477125, 466.172500000000582],
              [2678346.255078002810478, 1252668.015379705699161, 539.517500000001746],
              [2678218.562451258301735, 1252634.432645788416266, 529.8125],
              [2677960.156429763883352, 1252642.344327806727961, 547.753750000003492],
              [2677868.229973988607526, 1252461.507693940773606, 531.882500000006985],
              [2677811.369984495919198, 1252446.09021462360397, 532.747499999997672],
              [2677781.718729607295245, 1252441.086456100689247, 532.395000000004075],
              [2677430.536592460703105, 1252522.497142119333148, 560.858749999999418],
              [2677259.441564602311701, 1252507.714593845419586, 558.857499999998254],
              [2677121.914019195828587, 1252514.810762252891436, 555.462499999994179],
              [2676931.787241162732244, 1252723.075868129963055, 611.693750000005821],
              [2676672.87089103506878, 1252665.708186676492915, 604.179999999993015],
              [2676554.024704034905881, 1252714.029557995731011, 609.195000000006985],
              [2676541.973413893487304, 1252761.850733816158026, 606.089999999996508],
              [2676558.359665834810585, 1252798.488237489014864, 606.225000000005821],
              [2676365.405020373407751, 1253212.59282176848501, 536.850000000005821],
              [2675889.170577607117593, 1253538.056379568297416, 580.618749999994179]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 76, gemeinde_B: 99, name: 'Schöfflisdorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673478.471214491408318, 1259904.73271556594409, 632.83125000000291],
              [2673417.581255643162876, 1259940.722795367473736, 636.108749999999418],
              [2673476.481796703767031, 1260589.176598846213892, 483.15625],
              [2673371.110592402517796, 1260622.47668772097677, 486.811249999998836],
              [2673175.845253284089267, 1261257.742306335596368, 460.506250000005821],
              [2673421.715404676739126, 1262730.367336626630276, 667.317500000004657],
              [2673005.544023045804352, 1264117.778495607897639, 559.888749999998254],
              [2673623.97744076885283, 1264120.135220510885119, 630.921249999999418],
              [2673815.614857016131282, 1264056.760056059807539, 624.183749999996508],
              [2674131.292093459982425, 1263532.330792507855222, 603.291249999994761],
              [2674133.334592832252383, 1263522.54829114372842, 603.240000000005239],
              [2673981.278789518866688, 1262685.809129874221981, 638.213749999995343],
              [2674604.257126822136343, 1262252.744578217621893, 605.383749999993597],
              [2674942.99807421118021, 1261500.35163016221486, 484.115000000005239],
              [2674907.396781397052109, 1261334.020298424409702, 480.806249999994179],
              [2674554.304383682552725, 1260995.460495290346444, 489.057499999995343],
              [2674392.404231857508421, 1260835.18771033291705, 477.354999999995925],
              [2673478.471214491408318, 1259904.73271556594409, 632.83125000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 79, gemeinde_B: 102, name: 'Weiach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673683.503915243782103, 1266588.372053243685514, 550.89375000000291],
              [2673660.618911049794406, 1266627.619546243455261, 550.588749999995343],
              [2673540.02769972011447, 1266790.559624280082062, 553.722500000003492],
              [2673622.899227826856077, 1268260.909164589131251, 380.297500000000582],
              [2674123.145297380164266, 1268882.086318387417123, 365.912500000005821],
              [2674275.497365093789995, 1269039.217995668295771, 332.122499999997672],
              [2674498.814870517700911, 1268939.138002013787627, 332.412500000005821],
              [2674567.024866912513971, 1268920.886744047049433, 332.091249999997672],
              [2674636.512367726303637, 1268908.360494482563809, 332.113750000004075],
              [2674706.801117789931595, 1268901.644243544898927, 332.077499999999418],
              [2674847.836121862754226, 1268905.787999249994755, 332.3125],
              [2675200.879870649427176, 1268975.432992305839434, 332.013749999998254],
              [2675232.0011249743402, 1268984.966750133084133, 332.332500000004075],
              [2675292.951121890451759, 1269007.791743553709239, 332.057499999995343],
              [2675920.992381908930838, 1269305.495505543425679, 332.477499999993597],
              [2676055.6661271690391, 1269380.790495139313862, 332.032500000001164],
              [2676128.882379157468677, 1269413.506748074898496, 332.145000000004075],
              [2676264.558628843165934, 1269450.679245776962489, 332.042499999995925],
              [2676323.417389896698296, 1269468.469265992753208, 332.867499999993015],
              [2676559.444881384726614, 1269575.76174719678238, 332.071249999993597],
              [2676962.752389615867287, 1269535.290506652323529, 332.475000000005821],
              [2677028.247385968919843, 1269535.501748797018081, 332.153749999997672],
              [2677166.416138139553368, 1269558.434251069556922, 332.242499999993015],
              [2677488.193595179356635, 1268687.501521926373243, 425.651249999995343],
              [2677409.181164066772908, 1268592.935402742121369, 431.05000000000291],
              [2677333.642323936335742, 1268497.62523288000375, 424.111250000001746],
              [2676580.609674165025353, 1267996.392323671840131, 510.102499999993597],
              [2676568.36467791069299, 1267994.494830991141498, 510.403749999997672],
              [2676460.199655898381025, 1267903.192290694685653, 508.785000000003492],
              [2676565.831385015975684, 1267383.786939142970368, 545.897500000006403],
              [2676328.426357677206397, 1266952.052023957949132, 446.852499999993597],
              [2676088.008427893742919, 1266242.170936563285068, 607.943750000005821],
              [2675993.178543771617115, 1266175.491157250711694, 617.047500000000582],
              [2675803.379552464466542, 1266098.249454661505297, 598.475000000005821],
              [2675728.959872944280505, 1265781.827558800345287, 623.46875],
              [2675444.770912119187415, 1265743.735916703473777, 607.358749999999418],
              [2675189.21902268845588, 1265697.843462212476879, 557.841249999997672],
              [2675142.124019318725914, 1265676.042206681799144, 557.622499999997672],
              [2674974.066863546147943, 1265661.869112166110426, 584.585000000006403],
              [2674837.079189505893737, 1265820.780037719057873, 571.167499999995925],
              [2674620.398002936504781, 1266094.906416221288964, 576.323749999995925],
              [2673683.503915243782103, 1266588.372053243685514, 550.89375000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 81, gemeinde_B: 112, name: 'Bubikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2705197.388428582809865, 1234214.306411569705233, 486.115000000005239],
              [2704250.634047750383615, 1234030.596271226648241, 532.953750000000582],
              [2704040.501390647608787, 1234009.039747803006321, 521.433749999996508],
              [2704040.727644746657461, 1234007.59600502345711, 521.738750000004075],
              [2703405.110076687298715, 1233800.264645389979705, 517.196249999993597],
              [2703384.545103775337338, 1233851.918444242095575, 519.233749999999418],
              [2702599.242169836070389, 1234271.048946172231808, 487.516250000000582],
              [2701906.293916651979089, 1234976.756097661796957, 525.19999999999709],
              [2701821.837431797757745, 1235283.171935984864831, 507.729999999995925],
              [2701814.48993547540158, 1235313.421942968852818, 508.011249999995925],
              [2701576.086632526014, 1235819.32149678375572, 541.636249999995925],
              [2702218.026125398464501, 1236635.423092033714056, 503.222500000003492],
              [2702566.467516154516488, 1237017.892088901950046, 513.452499999999418],
              [2703092.623155212495476, 1237415.620716111036018, 560.684999999997672],
              [2703346.335222685709596, 1237983.886199760716408, 528.223750000004657],
              [2703473.172892309725285, 1238143.871499762171879, 540.76249999999709],
              [2703063.409022904001176, 1238300.385048149619251, 532.217499999998836],
              [2703034.410329879261553, 1238544.483902564505115, 536.497499999997672],
              [2703537.311792534310371, 1238806.98177228984423, 551.917499999995925],
              [2703604.291619160212576, 1238900.481464246055111, 538.940000000002328],
              [2703882.600501834880561, 1238832.910442987224087, 548.580000000001746],
              [2703914.880473718512803, 1238837.690392487915233, 546.457500000004075],
              [2704473.033849531784654, 1237569.558454113313928, 518.089999999996508],
              [2704713.318983860313892, 1237549.194936764426529, 527.882500000006985],
              [2704852.70265543833375, 1237452.896044119959697, 521.928750000006403],
              [2704894.662664429284632, 1237470.218559353379533, 522.5625],
              [2704964.181512887589633, 1237346.523730785120279, 529.823749999995925],
              [2704927.464857545681298, 1236533.316756219603121, 499.690000000002328],
              [2705275.849783410318196, 1236070.660363756585866, 493.88749999999709],
              [2705280.549872155766934, 1236044.060520133003592, 500.479999999995925],
              [2705552.155966393649578, 1235681.867736218962818, 488.678750000006403],
              [2705843.484770318493247, 1235627.805325245950371, 492.452499999999418],
              [2705763.217033200897276, 1235540.572407591855153, 474.901249999995343],
              [2705411.30237585818395, 1235295.336766441352665, 500.632500000006985],
              [2705103.75850521074608, 1234648.740302917780355, 491.896250000005239],
              [2705150.206111314240843, 1234356.87548625911586, 499.741250000006403],
              [2705197.388428582809865, 1234214.306411569705233, 486.115000000005239]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 83, gemeinde_B: 114, name: 'Fischenthal', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2713652.8366559734568, 1248312.478679636958987, 992.113750000004075],
              [2714015.503696704749018, 1248185.50815556710586, 866.131250000005821],
              [2714960.030797357205302, 1245874.797692106571048, 743.962499999994179],
              [2715501.877468975726515, 1245946.869667118880898, 774.207500000004075],
              [2715502.567467174492776, 1245947.759663961827755, 774.07499999999709],
              [2716043.62758434843272, 1245193.853594781598076, 1147.7937499999971],
              [2716135.133729623164982, 1244274.803700847318396, 1048.508749999993597],
              [2716485.158309279475361, 1242783.491970903705806, 1291.380000000004657],
              [2716745.003115905448794, 1242301.73067817511037, 1185.36],
              [2716663.255495074205101, 1241612.383885214105248, 1268.037500000005821],
              [2716558.09774462506175, 1241427.367198721505702, 1249.92500000000291],
              [2716175.43831343902275, 1240719.219790257047862, 835.054999999993015],
              [2715055.450227243825793, 1241279.008809773949906, 793.563750000001164],
              [2714149.766102837398648, 1241353.417871462181211, 1225.76625],
              [2713704.064766321796924, 1241391.009922983823344, 944.927500000005239],
              [2713665.763578877784312, 1241268.242220810614526, 1041.350000000005821],
              [2713639.78105224436149, 1241274.792175306007266, 1039.436249999998836],
              [2713419.124923674855381, 1241395.136148989899084, 1048.710000000006403],
              [2713166.881046780385077, 1241342.999685269314796, 1039.791249999994761],
              [2712844.250023200176656, 1241446.567596866516396, 1056.95375],
              [2711597.457157994620502, 1241053.875409370288253, 755.910000000003492],
              [2711238.007345756050199, 1241004.266998810693622, 770.167499999995925],
              [2710042.30313794221729, 1241132.311881004599854, 922.351250000006985],
              [2709625.101464466191828, 1241615.119313774164766, 1076.337499999994179],
              [2709622.10646380437538, 1241618.823062803829089, 1076.2937499999971],
              [2710132.96921048220247, 1241993.266580878989771, 909.142500000001746],
              [2711328.0950603983365, 1241810.459885908989236, 785.889999999999418],
              [2711388.580602684989572, 1241886.713338718749583, 825.764999999999418],
              [2711666.469103678595275, 1242437.807901324471459, 807.085000000006403],
              [2711272.798349740449339, 1242621.298788988031447, 844.132500000006985],
              [2711239.699050289113075, 1242677.559074148070067, 803.692500000004657],
              [2711186.958377648610622, 1242723.128842522390187, 846.303750000006403],
              [2711233.340329948812723, 1242923.721630453597754, 805.891250000000582],
              [2711330.103161755017936, 1243054.875963563332334, 830.217499999998836],
              [2711324.230339603498578, 1243108.100397632224485, 806.492499999993015],
              [2710894.856028395239264, 1244447.989803783129901, 1042.14375000000291],
              [2711017.742090344894677, 1245351.275371143594384, 751.702499999999418],
              [2711400.912393437698483, 1245119.589344220468774, 865.653749999997672],
              [2711533.655954726040363, 1246518.912120523164049, 667.488750000004075],
              [2712576.211852489970624, 1247053.236183170927688, 732.423750000001746],
              [2712959.225838104262948, 1247590.248500231187791, 933.047500000000582],
              [2713031.487075018230826, 1247782.428775722626597, 840.127500000002328],
              [2713437.15525518823415, 1248169.976871027611196, 1073.1725],
              [2713652.8366559734568, 1248312.478679636958987, 992.113750000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 87, gemeinde_B: 118, name: 'Rüti', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2709500.818863940890878, 1234553.301788138924167, 607.252500000002328],
              [2708641.849549176637083, 1233965.002367020584643, 473.842499999998836],
              [2708635.159549173898995, 1233964.683617140632123, 473.847500000003492],
              [2708429.38314754422754, 1233775.930853037163615, 462.782500000001164],
              [2708129.509644338861108, 1233553.812539495993406, 481.26875000000291],
              [2707598.056596687529236, 1233497.781586953205988, 441.11250000000291],
              [2706966.218037839978933, 1234318.68819170515053, 455.757500000006985],
              [2706958.611786216497421, 1234309.441938894800842, 455.642500000001746],
              [2706464.439503448549658, 1233948.676076672971249, 472.118749999994179],
              [2706440.181874101981521, 1233872.508348720613867, 462.537500000005821],
              [2706228.724399795290083, 1233837.994647462852299, 464.602499999993597],
              [2706198.373266297858208, 1233848.542353228200227, 473.273749999993015],
              [2705744.924389098770916, 1233937.979638377670199, 464.17500000000291],
              [2705248.708470358978957, 1234151.761483657406643, 489.178750000006403],
              [2705213.577172841411084, 1234190.321400903631002, 485.67500000000291],
              [2705197.388428582809865, 1234214.306411569705233, 486.115000000005239],
              [2705150.206111314240843, 1234356.87548625911586, 499.741250000006403],
              [2705103.75850521074608, 1234648.740302917780355, 491.896250000005239],
              [2705411.30237585818395, 1235295.336766441352665, 500.632500000006985],
              [2705763.217033200897276, 1235540.572407591855153, 474.901249999995343],
              [2705885.042030478361994, 1235522.023650347953662, 474.604999999995925],
              [2705933.814543983899057, 1235506.003673105034977, 475.570000000006985],
              [2706827.163114667870104, 1235349.932089193956926, 461.58125000000291],
              [2706852.681961943395436, 1235366.049760186811909, 468.776249999995343],
              [2706901.444447204004973, 1235360.164733287878335, 467.646250000005239],
              [2706934.709450696129352, 1235363.529738849727437, 467.880000000004657],
              [2706937.823114890605211, 1235349.450837578391656, 461.514999999999418],
              [2707166.839535844977945, 1235823.363638978917152, 474.026249999995343],
              [2707146.705731352325529, 1235832.413543476723135, 470.002500000002328],
              [2707155.273279958404601, 1235988.549880431732163, 473.602499999993597],
              [2707159.652027420699596, 1235997.043625963386148, 473.411250000004657],
              [2708525.18604231486097, 1236346.975319830700755, 491.971250000002328],
              [2708714.459121138323098, 1236411.196334195090458, 534.628750000003492],
              [2708945.791622929740697, 1236363.907582055544481, 534.5625],
              [2709887.195713509339839, 1236522.05941199930385, 558.90625],
              [2710582.65464536100626, 1236696.700967871118337, 571.703750000000582],
              [2710973.725511480122805, 1236448.176223650341853, 635.182499999995343],
              [2710761.47368820104748, 1235834.023345467168838, 777.443750000005821],
              [2710213.773060029838234, 1235362.838813193142414, 639.502500000002328],
              [2709959.751376932486892, 1235101.163056995952502, 607.782500000001164],
              [2709855.250628404784948, 1234847.536435106303543, 644.901249999995343],
              [2709559.073593243025243, 1234693.756313968682662, 587.205000000001746],
              [2709500.818863940890878, 1234553.301788138924167, 607.252500000002328]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 89, gemeinde_B: 120, name: 'Wald', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2716175.43831343902275, 1240719.219790257047862, 835.054999999993015],
              [2715452.103893326595426, 1240584.292369671631604, 1153.052500000005239],
              [2714942.937956493813545, 1240469.253252, 1085.289999999993597],
              [2714784.680707804858685, 1240373.978695399593562, 1103.972500000003492],
              [2714766.163130516186357, 1240346.947310524992645, 1098.337499999994179],
              [2714537.280095451977104, 1239765.483254902996123, 876.158750000002328],
              [2714495.345056830439717, 1239786.805689156753942, 873.383749999993597],
              [2714454.12765683606267, 1239505.776793992612511, 972.434999999997672],
              [2714234.319927867501974, 1239019.149209137540311, 864.258749999993597],
              [2714231.041179288178682, 1239020.03921173652634, 864.367499999993015],
              [2713093.988202687352896, 1238567.616220521740615, 739.008749999993597],
              [2713869.527005585841835, 1237622.844074414111674, 1017.217499999998836],
              [2713874.737022619694471, 1237645.824104406638071, 1018.460000000006403],
              [2714153.91771517554298, 1237138.595000309171155, 793.697499999994761],
              [2713187.875991217326373, 1235417.31231354479678, 576.36250000000291],
              [2712752.272275250405073, 1235288.444562908960506, 671.149999999994179],
              [2712139.222019956912845, 1235084.686629744246602, 653.006250000005821],
              [2712160.769103145692497, 1235051.92090071644634, 622.315000000002328],
              [2711894.640615679323673, 1234980.867614839924499, 641.898749999993015],
              [2711503.213035557884723, 1234823.91123227076605, 636.389999999999418],
              [2711469.700539043173194, 1234812.983739033108577, 636.679999999993015],
              [2711254.421208766289055, 1234794.053979235235602, 594.142500000001746],
              [2711126.739821193739772, 1234965.728430147049949, 676.242499999993015],
              [2710546.712842718698084, 1234777.619667197111994, 623.126250000001164],
              [2710123.213133455254138, 1234665.455185038968921, 644.997499999997672],
              [2709500.818863940890878, 1234553.301788138924167, 607.252500000002328],
              [2709559.073593243025243, 1234693.756313968682662, 587.205000000001746],
              [2709855.250628404784948, 1234847.536435106303543, 644.901249999995343],
              [2709959.751376932486892, 1235101.163056995952502, 607.782500000001164],
              [2710213.773060029838234, 1235362.838813193142414, 639.502500000002328],
              [2710761.47368820104748, 1235834.023345467168838, 777.443750000005821],
              [2710973.725511480122805, 1236448.176223650341853, 635.182499999995343],
              [2710582.65464536100626, 1236696.700967871118337, 571.703750000000582],
              [2709887.195713509339839, 1236522.05941199930385, 558.90625],
              [2709852.092196449637413, 1237443.424527288647369, 668.443750000005821],
              [2709551.456599983386695, 1239268.020755816716701, 1086.42125],
              [2709571.99745678063482, 1239701.400372332660481, 965.01249999999709],
              [2709676.220997573807836, 1239927.982204599305987, 1041.732499999998254],
              [2709990.554290698841214, 1240715.937954067485407, 915.23750000000291],
              [2711238.007345756050199, 1241004.266998810693622, 770.167499999995925],
              [2711597.457157994620502, 1241053.875409370288253, 755.910000000003492],
              [2712844.250023200176656, 1241446.567596866516396, 1056.95375],
              [2713166.881046780385077, 1241342.999685269314796, 1039.791249999994761],
              [2713419.124923674855381, 1241395.136148989899084, 1048.710000000006403],
              [2713639.78105224436149, 1241274.792175306007266, 1039.436249999998836],
              [2713665.763578877784312, 1241268.242220810614526, 1041.350000000005821],
              [2713704.064766321796924, 1241391.009922983823344, 944.927500000005239],
              [2714149.766102837398648, 1241353.417871462181211, 1225.76625],
              [2715055.450227243825793, 1241279.008809773949906, 793.563750000001164],
              [2716175.43831343902275, 1240719.219790257047862, 835.054999999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 88, gemeinde_B: 119, name: 'Seegräben', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2701296.624059786554426, 1245087.38147470052354, 536.649999999994179],
              [2701319.294060344342142, 1244102.97146446746774, 536.649999999994179],
              [2701506.801583094056696, 1243526.383994868258014, 538.17500000000291],
              [2701460.740331261884421, 1243508.460242366418242, 538.077499999999418],
              [2701332.780631680041552, 1243261.674529132898897, 560.647500000006403],
              [2700715.5611699996516, 1243384.03827117360197, 507.942500000004657],
              [2700590.77419492136687, 1243393.107962819514796, 547.341249999997672],
              [2700540.266653988976032, 1243435.304141140077263, 544.322499999994761],
              [2700457.319223036058247, 1243367.405515694757923, 549.563750000001164],
              [2700355.544049536809325, 1243407.402707855449989, 536.662500000005821],
              [2700202.08174725016579, 1243059.849311097059399, 551.597500000003492],
              [2700145.956741386093199, 1243025.781801431439817, 551.207500000004075],
              [2700087.005507993046194, 1243000.583082150667906, 552.503750000003492],
              [2699991.879094847943634, 1243019.939042394515127, 540.365000000005239],
              [2699580.754238828551024, 1243244.789311536587775, 551.524999999994179],
              [2699549.664091689977795, 1243386.631550159770995, 540.523749999993015],
              [2699499.360366347711533, 1243416.781595748150721, 542.417499999995925],
              [2699505.345365933142602, 1243417.652844894677401, 542.381250000005821],
              [2699557.48570349579677, 1243589.094700575107709, 567.648749999993015],
              [2699611.956926737446338, 1243584.734651369275525, 565.592499999998836],
              [2699574.989462947007269, 1243754.808469028677791, 568.345000000001164],
              [2699687.913493264000863, 1244051.197981982724741, 495.558749999996508],
              [2699887.984141081571579, 1244211.921640552580357, 543.947499999994761],
              [2699700.220303601585329, 1244356.350239348597825, 537.558749999996508],
              [2699558.822918256279081, 1244548.024200048530474, 546.282500000001164],
              [2699339.341559374704957, 1244905.522763356566429, 538.318750000005821],
              [2699610.946992548648268, 1245143.554787328699604, 570.5625],
              [2699700.839599078986794, 1245189.507476945407689, 578.466249999997672],
              [2700336.204370914958417, 1245432.40455643995665, 560.792499999995925],
              [2700417.973239545244724, 1245480.301017804071307, 569.602499999993597],
              [2700431.824487325269729, 1245490.534763637697324, 569.423750000001746],
              [2700575.401568703353405, 1245628.227761812973768, 537.95625000000291],
              [2700663.231558043509722, 1245648.010241076815873, 537.082500000004075],
              [2701296.624059786554426, 1245087.38147470052354, 536.649999999994179]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 90, gemeinde_B: 121, name: 'Wetzikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2700145.956741386093199, 1243025.781801431439817, 551.207500000004075],
              [2700202.08174725016579, 1243059.849311097059399, 551.597500000003492],
              [2700355.544049536809325, 1243407.402707855449989, 536.662500000005821],
              [2700457.319223036058247, 1243367.405515694757923, 549.563750000001164],
              [2700540.266653988976032, 1243435.304141140077263, 544.322499999994761],
              [2700590.77419492136687, 1243393.107962819514796, 547.341249999997672],
              [2700715.5611699996516, 1243384.03827117360197, 507.942500000004657],
              [2701332.780631680041552, 1243261.674529132898897, 560.647500000006403],
              [2701460.740331261884421, 1243508.460242366418242, 538.077499999999418],
              [2701506.801583094056696, 1243526.383994868258014, 538.17500000000291],
              [2701319.294060344342142, 1244102.97146446746774, 536.649999999994179],
              [2701296.624059786554426, 1245087.38147470052354, 536.649999999994179],
              [2702050.892818740569055, 1244419.782717530382797, 536.649999999994179],
              [2702197.592825922649354, 1244341.185226412955672, 537.057499999995343],
              [2703071.855505627114326, 1243988.171774596907198, 549.695000000006985],
              [2703174.989209417253733, 1244078.429191002622247, 546.160000000003492],
              [2703314.493103107903153, 1244080.264444183092564, 556.75],
              [2703325.000550756696612, 1244072.886850554030389, 552.837499999994179],
              [2703552.326849170029163, 1243922.825680220965296, 556.240000000005239],
              [2704454.358791688457131, 1244030.78314418066293, 606.978749999994761],
              [2704463.572463055606931, 1244022.728003949858248, 601.117499999993015],
              [2705072.228841290343553, 1244117.57794131198898, 703.020000000004075],
              [2705052.847745091188699, 1243894.628212107811123, 714.473750000004657],
              [2705424.980167611502111, 1243334.419586528092623, 615.382500000006985],
              [2706202.564340004697442, 1242778.081276414683089, 738.757500000006985],
              [2706224.14304674603045, 1242723.621198314707726, 735.520000000004075],
              [2706234.164298522286117, 1242707.411200952949002, 735.639999999999418],
              [2706062.754495135042816, 1241946.514327738201246, 657.607499999998254],
              [2706117.064982405863702, 1241647.998935732757673, 693.721250000002328],
              [2705638.078401159960777, 1240864.72863579634577, 576.748749999998836],
              [2705634.374650223180652, 1240862.052384193055332, 576.682499999995343],
              [2705630.779652582481503, 1240861.857388447504491, 576.861250000001746],
              [2705162.843150092288852, 1240823.951950761955231, 558.520000000004075],
              [2704958.060826733708382, 1241024.9622708321549, 571.845000000001164],
              [2704289.694253776688129, 1241698.522969686659053, 548.429999999993015],
              [2703608.880307592451572, 1240982.385127547197044, 534.395000000004075],
              [2703479.798010331112891, 1240324.394233318511397, 549.602499999993597],
              [2703515.247956085950136, 1240234.985385190462694, 545.526249999995343],
              [2704003.930502309463918, 1239792.86045194696635, 548.529999999998836],
              [2703759.306629260536283, 1239424.935234667500481, 539.570000000006985],
              [2703645.046678397804499, 1239281.34657270857133, 543.327499999999418],
              [2703599.806689201388508, 1239345.125343554420397, 544.173750000001746],
              [2703264.750370775815099, 1239654.777732530841604, 539.372499999997672],
              [2702869.710469749756157, 1239716.720417457167059, 547.101250000006985],
              [2702527.562855368945748, 1239976.528974168933928, 538.871249999996508],
              [2702363.853308534249663, 1240646.04229173460044, 572.86250000000291],
              [2701941.351766949053854, 1241020.875535905128345, 551.476250000006985],
              [2701843.990791489835829, 1241355.759781167609617, 572.083750000005239],
              [2701629.78069000178948, 1241446.26710600219667, 564.697499999994761],
              [2701567.122011415660381, 1241430.43473457521759, 570.092499999998836],
              [2700986.050256597343832, 1241635.695098570315167, 532.86250000000291],
              [2701089.27939943689853, 1241708.774548510322347, 562.161250000004657],
              [2700766.533997382968664, 1242091.526341640623286, 532.372499999997672],
              [2700238.970422405283898, 1242500.717920165741816, 545.946249999993597],
              [2700167.167873196769506, 1242623.947835043072701, 542.326249999998254],
              [2700184.974149269983172, 1242679.109131914563477, 544.264999999999418],
              [2700224.32285343715921, 1242748.685299846576527, 540.798750000001746],
              [2700145.956741386093199, 1243025.781801431439817, 551.207500000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 92, gemeinde_B: 135, name: 'Kilchberg', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2683453.84715990209952, 1243308.684602553956211, 406.53125],
              [2683481.505901030264795, 1243295.910835684277117, 405.830000000001746],
              [2683486.6559121995233, 1243249.610855725128204, 406.683749999996508],
              [2683495.255901169031858, 1243245.910835282644257, 405.830000000001746],
              [2683490.95842427900061, 1243190.893377324333414, 407.607499999998254],
              [2683510.855900116730481, 1243158.760832348372787, 405.736250000001746],
              [2683686.255921779666096, 1242854.310866581276059, 407.272500000006403],
              [2683824.955904299858958, 1242675.710830653319135, 405.830000000001746],
              [2683826.855925037059933, 1242626.160868236562237, 407.419999999998254],
              [2683831.405904029961675, 1242627.660829641623423, 405.803750000006403],
              [2683827.755917394999415, 1242621.460854171775281, 406.832500000004075],
              [2683845.955904514994472, 1242588.460829954128712, 405.830000000001746],
              [2683824.655928319320083, 1242567.210873799398541, 407.672500000000582],
              [2683878.955904862377793, 1242416.010828579543158, 405.830000000001746],
              [2683882.555939327925444, 1242398.710891565540805, 408.473750000004657],
              [2683893.855905002448708, 1242402.360828467644751, 405.830000000001746],
              [2683932.70594681520015, 1242277.060903418809175, 409.011249999995925],
              [2683942.35590547695756, 1242272.260827420046553, 405.830000000001746],
              [2684117.555932354647666, 1241888.710870475741103, 407.763749999998254],
              [2684156.15590750426054, 1241905.760824443539605, 405.830000000001746],
              [2684267.155931088607758, 1241531.460862598381937, 407.554999999993015],
              [2684359.307159202639014, 1241405.308319895528257, 405.808749999996508],
              [2684322.305931929498911, 1241371.360861846478656, 407.577499999999418],
              [2684330.405909227207303, 1241376.810820192098618, 405.830000000001746],
              [2684357.255928260274231, 1241346.81085433322005, 407.271250000005239],
              [2684399.155909863300622, 1241315.060819679871202, 405.830000000001746],
              [2684476.155929297674447, 1241183.110852841055021, 407.264999999999418],
              [2684494.105913666076958, 1241157.810823707142845, 406.052500000005239],
              [2684499.613414045423269, 1241145.247074199840426, 406.077499999999418],
              [2683648.793413613922894, 1240548.256872375728562, 502.648749999993015],
              [2683292.725727030076087, 1240462.110286785988137, 488.603749999994761],
              [2683249.864309330005199, 1240751.04123309883289, 475.753750000003492],
              [2683018.174682762473822, 1241358.729428707621992, 504.669999999998254],
              [2682963.570673879701644, 1242725.897718423046172, 484.837499999994179],
              [2682963.058172441087663, 1242738.802715911529958, 484.727499999993597],
              [2682978.613194745965302, 1242782.354007015004754, 486.431249999994179],
              [2683453.84715990209952, 1243308.684602553956211, 406.53125]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 94, gemeinde_B: 137, name: 'Oberrieden', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2686329.053427959792316, 1237456.009537720587105, 405.80000000000291],
              [2686491.372207896783948, 1237101.084586457116529, 407.970000000001164],
              [2686545.505930425599217, 1236808.560783099615946, 405.82499999999709],
              [2686664.255952658131719, 1236382.760817913105711, 407.433749999996508],
              [2686753.39093251619488, 1236285.382029004860669, 405.830000000001746],
              [2686770.30595441814512, 1236265.810818352038041, 407.492499999993015],
              [2686798.055918918456882, 1236237.510753149632365, 404.758749999993597],
              [2686859.85594969522208, 1236169.660807453794405, 407.067500000004657],
              [2686901.019683887716383, 1236131.768277733819559, 405.830000000001746],
              [2686008.737456104718149, 1235340.315824838122353, 618.628750000003492],
              [2685043.203035062644631, 1235773.477131916675717, 568.177500000005239],
              [2684897.429482805076987, 1235646.036467338446528, 487.626250000001164],
              [2684422.555580915417522, 1236358.424956853035837, 476.375],
              [2685242.010750668123364, 1236800.512532062828541, 584.537500000005821],
              [2686329.053427959792316, 1237456.009537720587105, 405.80000000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 95, gemeinde_B: 138, name: 'Richterswil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694828.542269253637642, 1230666.430754270404577, 406.777499999996508],
              [2694833.067267111968249, 1230664.032000346342102, 406.61250000000291],
              [2694842.833528621587902, 1230661.779520728159696, 407.475000000005821],
              [2694896.906007098499686, 1230631.810731142293662, 405.8125],
              [2695256.106024394743145, 1230498.360755018191412, 406.871249999996508],
              [2695288.077260671649128, 1230482.443229864817113, 405.813750000001164],
              [2695294.974772016517818, 1230476.724499951582402, 406.664999999993597],
              [2695573.134763437323272, 1230292.129478524671867, 405.82499999999709],
              [2695569.956030063331127, 1230288.360758212162182, 407.08125000000291],
              [2695598.006013738457114, 1230254.660728338174522, 405.830000000001746],
              [2695595.356037149205804, 1230252.81077012559399, 407.597500000003492],
              [2695605.348513177130371, 1230249.656977171543986, 405.782500000001164],
              [2695767.496036370750517, 1229888.495762824779376, 407.413750000006985],
              [2695920.956016777083278, 1229788.310724534094334, 405.830000000001746],
              [2695986.156041069887578, 1229799.710766861913726, 407.617499999993015],
              [2696033.53226782521233, 1229668.366973543073982, 405.830000000001746],
              [2696029.061042860150337, 1229659.477018157020211, 407.720000000001164],
              [2696180.986018527764827, 1229359.465719769941643, 405.776249999995343],
              [2696391.506045122630894, 1229139.810761830769479, 407.632500000006985],
              [2696562.31227227859199, 1228816.998215640196577, 405.788750000006985],
              [2696564.604785775765777, 1228816.033239608630538, 406.803750000006403],
              [2696577.426023051142693, 1228804.405716659734026, 405.836249999993015],
              [2696338.232280815951526, 1228499.831706149503589, 500.756250000005821],
              [2695870.375262826681137, 1228692.209099539089948, 443.240000000005239],
              [2694409.563206017948687, 1226888.364354827674106, 666.684999999997672],
              [2694171.061840728390962, 1226607.887901680078357, 658.220000000001164],
              [2694151.929337613750249, 1226569.83539610914886, 658.003750000003492],
              [2694139.10310560837388, 1226548.407928231870756, 659.377500000002328],
              [2694128.993079633451998, 1226517.709131774026901, 657.423750000001746],
              [2694101.936931607779115, 1226458.153063464909792, 665.161250000004657],
              [2694045.979337286669761, 1226482.535397066734731, 658.089999999996508],
              [2693939.540603469591588, 1226729.847931897733361, 659.432499999995343],
              [2693588.623081748373806, 1226698.375401656143367, 658.162500000005821],
              [2693236.958080972544849, 1226551.819157271413133, 658.475000000005821],
              [2693039.16276481654495, 1226744.704582678386942, 729.410000000003492],
              [2692833.761745892930776, 1227455.090277389157563, 652.479999999995925],
              [2693162.495274526067078, 1227967.697380151133984, 635.383749999993597],
              [2693188.700639892369509, 1228294.931786870118231, 663.026249999995343],
              [2694619.556881250813603, 1230375.154795427573845, 472.028749999997672],
              [2694638.555755021516234, 1230429.893766767345369, 481.365000000005239],
              [2694828.542269253637642, 1230666.430754270404577, 406.777499999996508]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 96, gemeinde_B: 139, name: 'Rüschlikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2684499.613414045423269, 1241145.247074199840426, 406.077499999999418],
              [2684501.814663348253816, 1241139.44832284655422, 406.022500000006403],
              [2684510.95592973055318, 1241112.510852445382625, 407.272500000006403],
              [2684513.805910957511514, 1241107.360817994689569, 405.830000000001746],
              [2684520.10593234281987, 1240943.060855622868985, 407.462499999994179],
              [2684545.880911227315664, 1240729.365814743796363, 405.820000000006985],
              [2684607.305952677503228, 1240523.06088777561672, 408.952499999999418],
              [2684643.653412306681275, 1240495.963313100626692, 405.830000000001746],
              [2684670.205941836349666, 1240308.11086505651474, 408.072499999994761],
              [2684795.005913666449487, 1239946.36080838739872, 405.817500000004657],
              [2684906.355938940308988, 1239683.910850466461852, 407.672500000000582],
              [2684922.155915052164346, 1239672.160806462867185, 405.830000000001746],
              [2684911.477173540275544, 1239652.919571987818927, 406.48750000000291],
              [2684914.747165809385478, 1239651.475807795999572, 405.892500000001746],
              [2683775.335122691933066, 1238916.916442702291533, 537.767500000001746],
              [2683480.372844752389938, 1238646.692065443145111, 459.032500000001164],
              [2682588.439002707600594, 1239418.965670757228509, 452.657500000001164],
              [2682814.976696480996907, 1239653.38206504099071, 563.552500000005239],
              [2683292.725727030076087, 1240462.110286785988137, 488.603749999994761],
              [2683648.793413613922894, 1240548.256872375728562, 502.648749999993015],
              [2684499.613414045423269, 1241145.247074199840426, 406.077499999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 97, gemeinde_B: 141, name: 'Thalwil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2684914.747165809385478, 1239651.475807795999572, 405.892500000001746],
              [2684916.839665125589818, 1239648.118306490359828, 405.838749999995343],
              [2684947.139680503401905, 1239623.053333862917498, 406.996249999996508],
              [2685018.605915470514446, 1239544.41080452920869, 405.792499999995925],
              [2685170.055944954510778, 1239302.310853777686134, 407.942500000004657],
              [2685336.173418937716633, 1239120.225801958935335, 405.830000000001746],
              [2685325.875952127855271, 1239111.114612621953711, 408.380000000004657],
              [2685344.770919083617628, 1239110.82330199936405, 405.835000000006403],
              [2685360.30594322597608, 1239094.16084565478377, 407.673750000001746],
              [2685523.455920687876642, 1238888.06080005527474, 405.830000000001746],
              [2685510.655952991452068, 1238849.060858868062496, 408.3125],
              [2685561.94217174500227, 1238808.62830066261813, 405.882500000006985],
              [2685564.078435637056828, 1238804.779575926950201, 406.945000000006985],
              [2685648.105921873822808, 1238673.810798317193985, 405.830000000001746],
              [2685657.448440272826701, 1238660.025831592502072, 407.232499999998254],
              [2685728.255922622513026, 1238572.910797490971163, 405.830000000001746],
              [2685752.74219325883314, 1238529.403334328904748, 407.392500000001746],
              [2685904.755924311932176, 1238223.660794659052044, 405.830000000001746],
              [2685895.55594793939963, 1238219.410837825853378, 407.645000000004075],
              [2686110.755926266312599, 1237877.160791845759377, 405.830000000001746],
              [2686098.855951987672597, 1237860.760838739573956, 407.806249999994179],
              [2686154.75592659926042, 1237757.560790703399107, 405.822499999994761],
              [2686269.305956005118787, 1237488.810840038815513, 407.98750000000291],
              [2686329.053427959792316, 1237456.009537720587105, 405.80000000000291],
              [2685242.010750668123364, 1236800.512532062828541, 584.537500000005821],
              [2684422.555580915417522, 1236358.424956853035837, 476.375],
              [2684147.980548179708421, 1236356.247402399079874, 474.08125000000291],
              [2684072.598035838454962, 1236411.028631865279749, 473.195000000006985],
              [2684063.134301593527198, 1236436.856161043047905, 474.412500000005821],
              [2683487.730343363247812, 1238637.362062683794647, 458.919999999998254],
              [2683480.372844752389938, 1238646.692065443145111, 459.032500000001164],
              [2683775.335122691933066, 1238916.916442702291533, 537.767500000001746],
              [2684914.747165809385478, 1239651.475807795999572, 405.892500000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 98, gemeinde_B: 151, name: 'Erlenbach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2687715.968440416734666, 1238757.303298336220905, 405.830000000001746],
              [2687698.412210422568023, 1238789.314585267566144, 407.369999999995343],
              [2687694.675940219312906, 1238788.580798587063327, 405.830000000001746],
              [2687662.75594879547134, 1238927.860815901774913, 406.509999999994761],
              [2687649.000939579680562, 1238949.693299560574815, 405.816250000003492],
              [2687571.184705979190767, 1239197.064582776045427, 407.127500000002328],
              [2687480.514687913935632, 1239291.133302232716233, 405.8125],
              [2687140.755958606489003, 1239654.110848647775128, 407.634999999994761],
              [2687136.855934953317046, 1239644.060805556364357, 405.830000000001746],
              [2687134.455940458923578, 1239644.11081562563777, 406.252500000002328],
              [2687142.755935503635556, 1239616.910806233296171, 405.867499999993015],
              [2687132.364686651621014, 1239626.03330857353285, 405.962499999994179],
              [2687130.973434904590249, 1239630.09080544160679, 405.830000000001746],
              [2687116.038443040568382, 1239647.58082066103816, 406.462499999994179],
              [2687110.098434711340815, 1239648.984555604401976, 405.830000000001746],
              [2687082.605947135481983, 1239670.910828876309097, 406.798750000001746],
              [2687029.355933960992843, 1239734.510806306265295, 405.830000000001746],
              [2687016.155953750479966, 1239770.060842876089737, 407.352499999993597],
              [2686801.828431366942823, 1240210.202059410745278, 405.798750000001746],
              [2686799.965933572035283, 1240212.20956347999163, 405.96875],
              [2686804.923432880081236, 1240213.598312149289995, 405.912500000005821],
              [2689298.481845705304295, 1240641.39822215330787, 664.134999999994761],
              [2689235.174320903606713, 1240836.858181355986744, 662.318750000005821],
              [2689257.033076758496463, 1240843.808191516902298, 662.741250000006403],
              [2689314.323053928092122, 1240865.540648848749697, 660.941250000003492],
              [2689525.707006949931383, 1240935.558512935880572, 676.172500000000582],
              [2689925.850644859019667, 1241017.002050010487437, 667.20625000000291],
              [2690213.625922264996916, 1240402.451287242118269, 687.978749999994761],
              [2690217.475915876217186, 1240403.553775569191203, 687.488750000004075],
              [2690108.789879645686597, 1240196.77916324022226, 703.866250000006403],
              [2688323.91360837733373, 1239840.202124720904976, 513.588749999995343],
              [2688314.489141121506691, 1239434.213088685879484, 554.222500000003492],
              [2688572.721754258498549, 1239201.439535820158198, 562.607499999998254],
              [2687715.968440416734666, 1238757.303298336220905, 405.830000000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 101, gemeinde_B: 154, name: 'Küsnacht', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2686799.965933572035283, 1240212.20956347999163, 405.96875],
              [2686794.217186803463846, 1240219.387069531250745, 406.220000000001164],
              [2686730.312181109562516, 1240278.56581072579138, 405.830000000001746],
              [2686726.555953046306968, 1240293.710850896080956, 407.509999999994761],
              [2686651.855930389370769, 1240340.010811232496053, 405.830000000001746],
              [2686590.405954009387642, 1240448.860856255050749, 407.681249999994179],
              [2686566.405929745174944, 1240465.555812556063756, 405.842499999998836],
              [2686567.050938121043146, 1240469.563327842159197, 406.482499999998254],
              [2686513.693429082632065, 1240555.102062984136865, 405.830000000001746],
              [2686509.055941165890545, 1240565.210835179081187, 406.757500000006985],
              [2686477.755928754806519, 1240573.360813140170649, 405.830000000001746],
              [2686491.705945430789143, 1240585.510843427851796, 407.096250000002328],
              [2686474.355928716249764, 1240603.960813388461247, 405.830000000001746],
              [2686467.188449859619141, 1240617.430852186167613, 407.452499999999418],
              [2686434.305928336922079, 1240668.510813912376761, 405.830000000001746],
              [2686389.30595070682466, 1240737.110856056911871, 407.573749999995925],
              [2686249.455926595255733, 1240934.810816081240773, 405.830000000001746],
              [2686256.705957094673067, 1240939.610871668672189, 408.158750000002328],
              [2686234.109676450956613, 1240965.003316324902698, 405.830000000001746],
              [2686218.122211219742894, 1241009.010880427900702, 408.502500000002328],
              [2686214.03217625617981, 1241012.365816706558689, 405.830000000001746],
              [2686208.355966156348586, 1241032.160889795981348, 408.88749999999709],
              [2686130.055924704298377, 1241275.110817505046725, 405.774999999994179],
              [2686156.855953341815621, 1241470.11087100720033, 407.952499999999418],
              [2686141.005925462581217, 1241467.310820342740044, 405.830000000001746],
              [2686166.555951375514269, 1241670.260868960991502, 407.80000000000291],
              [2686144.705925449728966, 1241619.510821548290551, 405.830000000001746],
              [2686014.155939268413931, 1242054.610852680867538, 406.98750000000291],
              [2685971.305923741776496, 1242126.660825632512569, 405.830000000001746],
              [2685962.40594079438597, 1242164.860857271123677, 407.142500000001746],
              [2685941.305923458188772, 1242175.160826020408422, 405.830000000001746],
              [2685949.355955236591399, 1242193.460884126136079, 408.257500000006985],
              [2685936.555923412553966, 1242184.710826100548729, 405.830000000001746],
              [2685910.305950028356165, 1242266.410875884350389, 407.88749999999709],
              [2685863.138422717805952, 1242301.575827054679394, 405.830000000001746],
              [2685829.755943966563791, 1242361.560866971034557, 407.48124999999709],
              [2685809.555922213941813, 1242379.11082768603228, 405.830000000001746],
              [2685688.405942216515541, 1242855.760870353318751, 407.45625000000291],
              [2685665.583420765586197, 1242896.54958184203133, 405.830000000001746],
              [2685645.389679822139442, 1242945.769599165534601, 406.538750000006985],
              [2685640.098420522641391, 1242944.937082230346277, 405.830000000001746],
              [2685631.29217058327049, 1242962.992082640295848, 405.841249999997672],
              [2685637.455945087131113, 1242966.410877398448065, 407.713749999995343],
              [2685636.244670479092747, 1242969.762082425644621, 405.830000000001746],
              [2687995.622853160835803, 1243319.680349664296955, 647.096250000002328],
              [2687993.220348233822733, 1243308.106590591371059, 646.722500000003492],
              [2688037.872868808917701, 1243289.49162672762759, 648.244999999995343],
              [2688030.526340642943978, 1243185.846118405461311, 627.023749999993015],
              [2688065.165092424955219, 1243173.283620632952079, 627.123749999998836],
              [2688062.74381914967671, 1243161.696078111650422, 625.350000000005821],
              [2688287.353903230745345, 1243039.674974243855104, 631.53125],
              [2688530.415032303892076, 1242892.072246037656441, 622.056249999994179],
              [2688605.585053882095963, 1242400.368751713540405, 528.3125],
              [2690069.992521456908435, 1241945.213426705915481, 619.647500000006403],
              [2690225.472282306291163, 1241953.107988878618926, 601.302500000005239],
              [2690400.855715194251388, 1242507.568435202818364, 672.072499999994761],
              [2690393.829463626258075, 1242517.479682679288089, 671.961249999993015],
              [2690401.874462618492544, 1242529.583430800586939, 671.876250000001164],
              [2690873.947317166719586, 1242430.791560259182006, 698.297500000000582],
              [2691059.944737431127578, 1242386.930159959942102, 692.029999999998836],
              [2691224.425256362184882, 1242779.899852141737938, 731.261249999995925],
              [2691275.518970618024468, 1242811.924786327872425, 728.48750000000291],
              [2691282.370216459501535, 1242815.977278644684702, 728.163750000006985],
              [2691296.686472448520362, 1242797.301038833102211, 728.601250000006985],
              [2691827.988337086047977, 1242320.747365946881473, 679.76249999999709],
              [2692078.278374708257616, 1241008.415920545114204, 777.111250000001746],
              [2691968.520584524143487, 1240652.810393522726372, 755.228749999994761],
              [2690439.898262038826942, 1240540.810992610873654, 675.547500000000582],
              [2690217.475915876217186, 1240403.553775569191203, 687.488750000004075],
              [2690213.625922264996916, 1240402.451287242118269, 687.978749999994761],
              [2689925.850644859019667, 1241017.002050010487437, 667.20625000000291],
              [2689525.707006949931383, 1240935.558512935880572, 676.172500000000582],
              [2689314.323053928092122, 1240865.540648848749697, 660.941250000003492],
              [2689257.033076758496463, 1240843.808191516902298, 662.741250000006403],
              [2689235.174320903606713, 1240836.858181355986744, 662.318750000005821],
              [2689298.481845705304295, 1240641.39822215330787, 664.134999999994761],
              [2686804.923432880081236, 1240213.598312149289995, 405.912500000005821],
              [2686799.965933572035283, 1240212.20956347999163, 405.96875]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 103, gemeinde_B: 156, name: 'Meilen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692998.275992755312473, 1235206.408275408204645, 406.121249999996508],
              [2692944.255988417658955, 1235229.410768685629591, 405.830000000001746],
              [2692829.551006610970944, 1235298.940803886856884, 407.287500000005821],
              [2692594.998485253658146, 1235325.009519559098408, 405.830000000001746],
              [2692585.756005011964589, 1235339.760805386118591, 407.333750000005239],
              [2692567.398484131786972, 1235375.293268419103697, 405.764999999999418],
              [2692466.306009743362665, 1235554.660817701369524, 407.778749999997672],
              [2692438.280983787961304, 1235516.109521119855344, 405.830000000001746],
              [2692114.598503794055432, 1235530.72331260656938, 407.567500000004657],
              [2692055.192230253946036, 1235512.547021034872159, 405.822499999994761],
              [2692046.902252127416432, 1235526.687060693278909, 407.48750000000291],
              [2691931.727229269221425, 1235541.304521541344002, 405.832500000004075],
              [2691930.956026581581682, 1235570.760857042390853, 409.422500000000582],
              [2691926.955979187041521, 1235555.260771593078971, 405.830000000001746],
              [2691924.406010062899441, 1235555.810827268986031, 408.173750000001746],
              [2691921.90597914531827, 1235541.660771481925622, 405.830000000001746],
              [2691686.709744144231081, 1235727.037053971551359, 407.132500000006985],
              [2691500.974725275300443, 1235848.845774059416726, 405.830000000001746],
              [2691496.555999234784395, 1235893.560817714082077, 407.652499999996508],
              [2691448.605974796228111, 1235884.06077435426414, 405.830000000001746],
              [2691440.706001562997699, 1235891.010822820477188, 407.867499999993015],
              [2691343.455973853357136, 1235873.310774301644415, 405.830000000001746],
              [2691338.706007358618081, 1235899.5108350336086, 408.377500000002328],
              [2691338.705974174197763, 1235868.160774911753833, 405.857499999998254],
              [2691316.655988630373031, 1235863.160801311489195, 406.970000000001164],
              [2691311.748473573941737, 1235857.043274176074192, 405.830000000001746],
              [2691301.904743332415819, 1235885.469560220371932, 407.337499999994179],
              [2691240.655972841195762, 1235837.910773874726146, 405.822499999994761],
              [2691000.255996211431921, 1235884.760820408817381, 407.76249999999709],
              [2690818.673468835651875, 1235911.22327422676608, 405.807499999995343],
              [2690566.772248948458582, 1236205.384585282765329, 408.274999999994179],
              [2690476.385965439956635, 1236215.163276336388662, 405.789999999993597],
              [2690343.805999275762588, 1236246.460839923471212, 408.453750000000582],
              [2690340.809714747127146, 1236227.394527425058186, 405.830000000001746],
              [2690321.695976277813315, 1236236.093298656167462, 406.720000000001164],
              [2690055.154712051153183, 1236276.83702770085074, 405.821249999993597],
              [2689969.507233221083879, 1236369.52206817897968, 407.492499999993015],
              [2689947.353461198043078, 1236389.262028895784169, 405.832500000004075],
              [2689943.224732744973153, 1236402.979568044655025, 407.475000000005821],
              [2689589.205957871396095, 1236646.060780983883888, 405.830000000001746],
              [2689585.305979969445616, 1236657.360821132548153, 407.514999999999418],
              [2689454.10595664056018, 1236712.460781554691494, 405.830000000001746],
              [2689457.455984067637473, 1236718.610831198981032, 407.916249999994761],
              [2689451.204706644173712, 1236720.273281673667952, 405.832500000004075],
              [2689162.205977585166693, 1236921.860826106742024, 407.630000000004657],
              [2689002.005952486768365, 1237022.860784162534401, 405.830000000001746],
              [2689017.905989110004157, 1237042.760850418824703, 408.610000000000582],
              [2688811.680950654670596, 1237325.244536563986912, 405.827499999999418],
              [2688716.805983686819673, 1237420.860848838463426, 408.412500000005821],
              [2688645.655949145555496, 1237487.960787962190807, 405.830000000001746],
              [2688639.567216801922768, 1237543.11957055144012, 407.181249999994179],
              [2690988.460579105187207, 1237975.439603342441842, 566.037500000005821],
              [2691636.423387173563242, 1239234.560928987339139, 778.570000000006985],
              [2691890.159853567369282, 1239283.180063103092834, 794.669999999998254],
              [2692092.698416342027485, 1239283.855968706309795, 780.212499999994179],
              [2692200.179760432336479, 1239287.20363544812426, 787.212499999994179],
              [2692409.1767742424272, 1239657.246264317305759, 844.85624999999709],
              [2692520.99546258803457, 1239616.947398673277348, 840.03125],
              [2692525.944206836633384, 1239620.359888192731887, 839.588749999995343],
              [2692581.713022826239467, 1239574.926254767458886, 844.513749999998254],
              [2692750.884209308773279, 1239546.282384159043431, 839.472500000003492],
              [2693015.371761836577207, 1239178.432464255951345, 843.087499999994179],
              [2693140.905337036587298, 1238896.267140332143754, 829.676250000004075],
              [2693296.304251646157354, 1238665.48117824154906, 841.926250000004075],
              [2694181.600159016437829, 1237786.338270734995604, 720.317500000004657],
              [2692998.275992755312473, 1235206.408275408204645, 406.121249999996508]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 106, gemeinde_B: 159, name: 'Uetikon a.S.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2693827.92974611138925, 1234862.413264867849648, 405.803750000006403],
              [2693558.306011534761637, 1234977.760797978378832, 407.15625],
              [2693556.755993993021548, 1234972.210766453528777, 405.830000000001746],
              [2693236.756004725582898, 1235087.360791988437995, 406.86250000000291],
              [2693183.205990597605705, 1235110.110767662758008, 405.830000000001746],
              [2693188.156013109255582, 1235126.21080818050541, 407.53125],
              [2693095.255989792756736, 1235165.11076813028194, 405.830000000001746],
              [2692998.275992755312473, 1235206.408275408204645, 406.121249999996508],
              [2694181.600159016437829, 1237786.338270734995604, 720.317500000004657],
              [2694183.976410440634936, 1237787.087023248663172, 720.422500000000582],
              [2695062.672909152228385, 1236810.712687785038725, 643.792499999995925],
              [2695095.242894041817635, 1236792.375159615650773, 642.617499999993015],
              [2695163.124255520757288, 1236594.40910537680611, 650.957500000004075],
              [2695211.379247632808983, 1236539.067839276744053, 650.309999999997672],
              [2694936.216462265700102, 1235964.228834530338645, 534.654999999998836],
              [2693827.92974611138925, 1234862.413264867849648, 405.803750000006403]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 107, gemeinde_B: 160, name: 'Zumikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2691275.518970618024468, 1242811.924786327872425, 728.48750000000291],
              [2691224.425256362184882, 1242779.899852141737938, 731.261249999995925],
              [2691059.944737431127578, 1242386.930159959942102, 692.029999999998836],
              [2690873.947317166719586, 1242430.791560259182006, 698.297500000000582],
              [2690401.874462618492544, 1242529.583430800586939, 671.876250000001164],
              [2690393.829463626258075, 1242517.479682679288089, 671.961249999993015],
              [2690400.855715194251388, 1242507.568435202818364, 672.072499999994761],
              [2690225.472282306291163, 1241953.107988878618926, 601.302500000005239],
              [2690069.992521456908435, 1241945.213426705915481, 619.647500000006403],
              [2688605.585053882095963, 1242400.368751713540405, 528.3125],
              [2688530.415032303892076, 1242892.072246037656441, 622.056249999994179],
              [2688287.353903230745345, 1243039.674974243855104, 631.53125],
              [2688062.74381914967671, 1243161.696078111650422, 625.350000000005821],
              [2688065.165092424955219, 1243173.283620632952079, 627.123749999998836],
              [2688030.526340642943978, 1243185.846118405461311, 627.023749999993015],
              [2688037.872868808917701, 1243289.49162672762759, 648.244999999995343],
              [2687993.220348233822733, 1243308.106590591371059, 646.722500000003492],
              [2687995.622853160835803, 1243319.680349664296955, 647.096250000002328],
              [2688315.391793086659163, 1243524.55444037145935, 661.25],
              [2688965.735018152277917, 1244211.960976077476516, 620.565000000002328],
              [2689638.705952381249517, 1244841.365168722346425, 691.01249999999709],
              [2689726.648325589485466, 1245030.386188042350113, 681.267500000001746],
              [2689826.377290084958076, 1244948.956574794603512, 697.478749999994761],
              [2690338.530410547275096, 1244450.105408990755677, 649.01875000000291],
              [2691253.986523539293557, 1243146.331137796398252, 732.538750000006985],
              [2691269.472697340883315, 1242940.184746139449999, 726.729999999995925],
              [2691275.518970618024468, 1242811.924786327872425, 728.48750000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 108, gemeinde_B: 161, name: 'Zollikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2685636.244670479092747, 1242969.762082425644621, 405.830000000001746],
              [2685633.505949586164206, 1242977.010885793715715, 408.061249999998836],
              [2685575.505919481161982, 1243173.560833348426968, 405.80000000000291],
              [2685575.00595138175413, 1243174.710891750408337, 408.243749999994179],
              [2685377.605917404405773, 1243580.010836306959391, 405.786250000004657],
              [2685352.305943840183318, 1243669.210885902633891, 407.83125000000291],
              [2685254.655916721560061, 1243962.710840368643403, 405.827499999999418],
              [2685259.105938831344247, 1244082.160881810355932, 407.521250000005239],
              [2685248.455916663631797, 1244080.71084136236459, 405.830000000001746],
              [2685113.105929590296, 1244460.010870549594983, 406.922500000000582],
              [2685097.26341518945992, 1244473.180844529066235, 405.830000000001746],
              [2685074.605921809561551, 1244508.560857339529321, 406.353749999994761],
              [2685074.105914972256869, 1244508.160844815196469, 405.830000000001746],
              [2685058.805920800194144, 1244514.910855813883245, 406.287500000005821],
              [2685056.899665075354278, 1244517.820845373440534, 405.850000000005821],
              [2686540.150014400482178, 1245074.698752208380029, 527.162500000005821],
              [2686711.412189398426563, 1245303.665656291879714, 502.167499999995925],
              [2687964.61174421524629, 1245086.113348355982453, 562.537500000005821],
              [2689137.430601060390472, 1245466.874550399836153, 664.82499999999709],
              [2689241.335525252390653, 1245430.555658989353105, 658.9375],
              [2689597.36690743919462, 1245487.589641354046762, 668.619999999995343],
              [2689602.843156493268907, 1245495.490889599081129, 668.542499999995925],
              [2689685.631831684149802, 1245402.879499893635511, 662.756250000005821],
              [2689726.648325589485466, 1245030.386188042350113, 681.267500000001746],
              [2689638.705952381249517, 1244841.365168722346425, 691.01249999999709],
              [2688965.735018152277917, 1244211.960976077476516, 620.565000000002328],
              [2688315.391793086659163, 1243524.55444037145935, 661.25],
              [2687995.622853160835803, 1243319.680349664296955, 647.096250000002328],
              [2685636.244670479092747, 1242969.762082425644621, 405.830000000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 112, gemeinde_B: 177, name: 'Pfäffikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2705072.228841290343553, 1244117.57794131198898, 703.020000000004075],
              [2704463.572463055606931, 1244022.728003949858248, 601.117499999993015],
              [2704454.358791688457131, 1244030.78314418066293, 606.978749999994761],
              [2703552.326849170029163, 1243922.825680220965296, 556.240000000005239],
              [2703325.000550756696612, 1244072.886850554030389, 552.837499999994179],
              [2703314.493103107903153, 1244080.264444183092564, 556.75],
              [2703174.989209417253733, 1244078.429191002622247, 546.160000000003492],
              [2703071.855505627114326, 1243988.171774596907198, 549.695000000006985],
              [2702197.592825922649354, 1244341.185226412955672, 537.057499999995343],
              [2702050.892818740569055, 1244419.782717530382797, 536.649999999994179],
              [2701296.624059786554426, 1245087.38147470052354, 536.649999999994179],
              [2700663.231558043509722, 1245648.010241076815873, 537.082500000004075],
              [2700575.401568703353405, 1245628.227761812973768, 537.95625000000291],
              [2700431.824487325269729, 1245490.534763637697324, 569.423750000001746],
              [2700417.973239545244724, 1245480.301017804071307, 569.602499999993597],
              [2700336.204370914958417, 1245432.40455643995665, 560.792499999995925],
              [2699700.839599078986794, 1245189.507476945407689, 578.466249999997672],
              [2699610.946992548648268, 1245143.554787328699604, 570.5625],
              [2699607.015747281257063, 1245161.47854610811919, 570.921249999999418],
              [2699454.059380302205682, 1245485.029593314509839, 562.297500000000582],
              [2699273.970869805198163, 1245760.46628053765744, 580.432499999995343],
              [2699283.469362344127148, 1246346.424574641976506, 561.125],
              [2699403.289565442595631, 1246678.279940694337711, 576.255000000004657],
              [2699465.428268725983799, 1246727.407355923205614, 572.695000000006985],
              [2699463.004520093090832, 1246730.893608481157571, 572.80000000000291],
              [2699482.820767766796052, 1246743.033603997668251, 572.607499999998254],
              [2699531.082031057216227, 1246716.926126482896507, 573.558749999996508],
              [2699686.446627552155405, 1246773.981648915680125, 543.16874999999709],
              [2699647.482883451972157, 1246824.674160900525749, 543.646250000005239],
              [2699846.385356061160564, 1247504.484114940278232, 541.433749999996508],
              [2699833.702923059929162, 1247564.024236255325377, 546.467499999998836],
              [2699818.147809566929936, 1247827.502785541582853, 537.98124999999709],
              [2700164.966613220982254, 1247734.630373547319323, 541.696249999993597],
              [2700243.99032320221886, 1247762.859050288097933, 538.630000000004657],
              [2700766.280485203024, 1248862.611842227866873, 550.323749999995925],
              [2701009.7083351877518, 1248943.20246627740562, 576.292499999995925],
              [2701587.261292318347842, 1248660.727019847137854, 609.919999999998254],
              [2701678.027510923799127, 1248605.744460723362863, 607.483749999999418],
              [2702747.987977764569223, 1249293.685019279364496, 734.567500000004657],
              [2702859.096777177415788, 1249417.251356457127258, 738.127500000002328],
              [2703116.221457542851567, 1249341.504525765776634, 713.98750000000291],
              [2703657.628240303136408, 1249149.471711102640256, 753.071249999993597],
              [2703672.169461203273386, 1249130.916658355854452, 750.884999999994761],
              [2703700.788223461713642, 1249094.500428913161159, 751.763749999998254],
              [2703923.284145147074014, 1249926.133333869744092, 820.173750000001746],
              [2704032.718117381446064, 1250832.50775541854091, 743.507500000006985],
              [2704035.891868537757546, 1250842.232757539954036, 743.589999999996508],
              [2704038.234368202276528, 1250846.789006941253319, 743.5625],
              [2704094.445553163066506, 1250876.61388936615549, 738.653749999997672],
              [2704184.978159924969077, 1250829.162827154155821, 746.496249999996508],
              [2704637.330035560298711, 1250912.414198752958328, 699.507500000006985],
              [2705224.610209883190691, 1250957.951728852698579, 804.747499999997672],
              [2705880.235017808154225, 1250399.47412650147453, 696.803750000006403],
              [2705881.96125509776175, 1250384.50410356419161, 695.857499999998254],
              [2705801.746721360366791, 1249516.662425216520205, 730.559999999997672],
              [2705663.097925510723144, 1249476.043596718227491, 727.3125],
              [2705624.599186629056931, 1249474.261117593152449, 728.182499999995343],
              [2705512.830906933639199, 1249506.189730037702248, 670.372499999997672],
              [2704797.097579325083643, 1249491.487735423957929, 795.54374999999709],
              [2704638.795983768999577, 1249243.315869092475623, 770.023749999993015],
              [2704318.581211493816227, 1249276.92003599996679, 787.366250000006403],
              [2703749.918279048521072, 1248825.990522878244519, 755.841249999997672],
              [2703119.763729847036302, 1248034.961864084936678, 603.752500000002328],
              [2703074.289139890111983, 1247798.16509558307007, 634.372499999997672],
              [2703069.305388865061104, 1247803.305093934526667, 634.301250000004075],
              [2703070.053906923159957, 1247501.410925359698012, 616.994999999995343],
              [2703521.929254943039268, 1247081.337780950358137, 642.478749999994761],
              [2703590.969025516416878, 1246840.231116448529065, 625.304999999993015],
              [2703594.815276605077088, 1246841.091118308715522, 625.382500000006985],
              [2703600.533037332817912, 1246534.044079760555178, 644.80000000000291],
              [2703550.181702929083258, 1246532.426430311053991, 638.561249999998836],
              [2703584.049277283251286, 1246227.405308269895613, 644.061249999998836],
              [2703564.939268503338099, 1246218.675292961765081, 643.426250000004075],
              [2703517.210515520069748, 1246211.84403872769326, 643.252500000002328],
              [2703873.960235020145774, 1245487.198249141685665, 715.092499999998836],
              [2704118.696388742420822, 1244640.087082402314991, 614.544999999998254],
              [2704486.50739257177338, 1244688.61761133861728, 688.895000000004075],
              [2704684.708922903519124, 1244525.171852695289999, 709.532500000001164],
              [2705060.169901776593179, 1244135.506354938261211, 688.941250000003492],
              [2705072.228841290343553, 1244117.57794131198898, 703.020000000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 110, gemeinde_B: 173, name: 'Hittnau', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2704486.50739257177338, 1244688.61761133861728, 688.895000000004075],
              [2704118.696388742420822, 1244640.087082402314991, 614.544999999998254],
              [2703873.960235020145774, 1245487.198249141685665, 715.092499999998836],
              [2703517.210515520069748, 1246211.84403872769326, 643.252500000002328],
              [2703564.939268503338099, 1246218.675292961765081, 643.426250000004075],
              [2703584.049277283251286, 1246227.405308269895613, 644.061249999998836],
              [2703550.181702929083258, 1246532.426430311053991, 638.561249999998836],
              [2703600.533037332817912, 1246534.044079760555178, 644.80000000000291],
              [2703594.815276605077088, 1246841.091118308715522, 625.382500000006985],
              [2703590.969025516416878, 1246840.231116448529065, 625.304999999993015],
              [2703521.929254943039268, 1247081.337780950358137, 642.478749999994761],
              [2703070.053906923159957, 1247501.410925359698012, 616.994999999995343],
              [2703069.305388865061104, 1247803.305093934526667, 634.301250000004075],
              [2703074.289139890111983, 1247798.16509558307007, 634.372499999997672],
              [2703119.763729847036302, 1248034.961864084936678, 603.752500000002328],
              [2703749.918279048521072, 1248825.990522878244519, 755.841249999997672],
              [2704318.581211493816227, 1249276.92003599996679, 787.366250000006403],
              [2704638.795983768999577, 1249243.315869092475623, 770.023749999993015],
              [2704797.097579325083643, 1249491.487735423957929, 795.54374999999709],
              [2705512.830906933639199, 1249506.189730037702248, 670.372499999997672],
              [2705624.599186629056931, 1249474.261117593152449, 728.182499999995343],
              [2705663.097925510723144, 1249476.043596718227491, 727.3125],
              [2705961.424001595005393, 1249045.004794217646122, 621.221250000002328],
              [2706310.908055380918086, 1248501.14129582978785, 736.1875],
              [2706276.70057088881731, 1248466.261323902290314, 737.377500000002328],
              [2706312.749231377616525, 1248261.123660514131188, 730.6875],
              [2706569.143781651742756, 1247432.185064595891163, 789.75],
              [2706778.056665479205549, 1247325.511016763281077, 725.243749999994179],
              [2706909.804237238597125, 1247335.899890817701817, 730.414999999993597],
              [2707063.673108725808561, 1246978.412317875772715, 831.912500000005821],
              [2707122.741834379266948, 1247008.021023249952123, 830.03125],
              [2707126.54308567661792, 1247005.499775397358462, 830.122499999997672],
              [2707121.25684876088053, 1246982.061048415955156, 831.098750000004657],
              [2707116.964327128604054, 1246894.746008692542091, 829.498749999998836],
              [2706958.968030496034771, 1246502.3968946414534, 918.929999999993015],
              [2707043.42100647976622, 1246529.959267552709207, 861.419999999998254],
              [2707003.111314394976944, 1246365.461062896531075, 884.302500000005239],
              [2706877.888049078639597, 1245906.647200202103704, 827.707500000004075],
              [2706844.16939539462328, 1245812.917370735201985, 834.892500000001746],
              [2706188.872685627546161, 1245487.37031916086562, 801.610000000000582],
              [2705696.009556338656694, 1244906.935178158571944, 848.332500000004075],
              [2705604.889510510955006, 1244873.491349054966122, 845.047500000000582],
              [2704430.177282654680312, 1244853.513669376261532, 680.778749999997672],
              [2704486.50739257177338, 1244688.61761133861728, 688.895000000004075]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 113, gemeinde_B: 178, name: 'Russikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2704038.234368202276528, 1250846.789006941253319, 743.5625],
              [2704035.891868537757546, 1250842.232757539954036, 743.589999999996508],
              [2704032.718117381446064, 1250832.50775541854091, 743.507500000006985],
              [2703923.284145147074014, 1249926.133333869744092, 820.173750000001746],
              [2703700.788223461713642, 1249094.500428913161159, 751.763749999998254],
              [2703672.169461203273386, 1249130.916658355854452, 750.884999999994761],
              [2703657.628240303136408, 1249149.471711102640256, 753.071249999993597],
              [2703116.221457542851567, 1249341.504525765776634, 713.98750000000291],
              [2702859.096777177415788, 1249417.251356457127258, 738.127500000002328],
              [2702747.987977764569223, 1249293.685019279364496, 734.567500000004657],
              [2701678.027510923799127, 1248605.744460723362863, 607.483749999999418],
              [2701587.261292318347842, 1248660.727019847137854, 609.919999999998254],
              [2701009.7083351877518, 1248943.20246627740562, 576.292499999995925],
              [2700251.216826384421438, 1249462.848274076124653, 557.625],
              [2700130.986028805375099, 1249802.630344531964511, 591.632500000006985],
              [2700062.16314876684919, 1250108.398415808100253, 563.232499999998254],
              [2699924.911255185026675, 1250633.239516923902556, 608.811249999998836],
              [2699147.340586721897125, 1251282.024587617255747, 559.4375],
              [2699256.66734684817493, 1252138.871765591669828, 597.622499999997672],
              [2699283.578587194439024, 1252135.22299749776721, 596.872499999997672],
              [2699922.287700415588915, 1252534.29739242605865, 623.486250000001746],
              [2699924.157700125128031, 1252533.269891834584996, 623.462499999994179],
              [2699927.04020398715511, 1252522.237398590659723, 623.748749999998836],
              [2699781.29005622677505, 1252195.959631575969979, 612.8125],
              [2700462.068797557614744, 1252107.228100373176858, 705.086249999993015],
              [2700455.093785270117223, 1252034.295577402226627, 704.172500000000582],
              [2700456.326286059804261, 1252029.551828722003847, 704.229999999995925],
              [2700496.004952740389854, 1251880.336675475118682, 697.943750000005821],
              [2700816.530197918880731, 1251952.809609202668071, 715.9375],
              [2700837.092690395656973, 1251960.739595205755904, 715.351250000006985],
              [2701257.124093736987561, 1252232.003613379318267, 726.347500000003492],
              [2701406.316423812881112, 1252450.120806579710916, 713.475000000005821],
              [2701727.447901326231658, 1252841.012462585465983, 730.122499999997672],
              [2703014.810428479686379, 1253579.08299432718195, 544.207500000004075],
              [2703025.166667546145618, 1253569.406724351458251, 543.382500000006985],
              [2703201.312627899926156, 1252354.932180422591045, 614.809999999997672],
              [2703278.006493638269603, 1252121.233633315889165, 623.358749999999418],
              [2703226.611253154929727, 1252070.083202504785731, 605.477499999993597],
              [2703682.425324663054198, 1251567.127511749509722, 628.98124999999709],
              [2704038.234368202276528, 1250846.789006941253319, 743.5625]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: null, gemeinde_B: null, name: null, kanton_KUE: null, kanton_NAM: null },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692045.327615555375814, 1245939.906559352530167, 435.287500000005821],
              [2692029.016366648487747, 1245959.18781180260703, 435.382500000006985],
              [2691979.088863347191364, 1246023.387807275168598, 435.169999999998254],
              [2691969.811369221657515, 1246034.709068208932877, 435.622499999997672],
              [2691947.297611220274121, 1246064.171554335393012, 435.032500000001164],
              [2691872.142618911806494, 1246148.675320402020589, 435.672500000000582],
              [2691820.965107390657067, 1246222.460301050916314, 434.837499999994179],
              [2691740.376369853504002, 1246384.675326582277194, 435.845000000001164],
              [2691685.502606214489788, 1246544.957804208854213, 434.853749999994761],
              [2691694.455117559526116, 1246629.371575444703922, 435.710000000006403],
              [2691727.568856155499816, 1246927.081556809600443, 434.827499999999418],
              [2691720.273868404794484, 1246934.902829284546897, 435.76249999999709],
              [2691897.540106240194291, 1247611.467810125555843, 434.726250000006985],
              [2691899.94260634528473, 1247612.524060285883024, 434.732499999998254],
              [2692288.936375737655908, 1247735.17283977009356, 435.923750000001746],
              [2692539.863868569023907, 1247649.801571521442384, 435.196249999993597],
              [2692561.776376125402749, 1247623.751584629761055, 435.752500000002328],
              [2692695.155120156239718, 1247523.282820515800267, 435.201249999998254],
              [2692752.596375756897032, 1247463.612829132704064, 435.582500000004075],
              [2692938.2288723946549, 1247318.606568432645872, 435.190000000002328],
              [2693101.593879119027406, 1247005.066574919968843, 435.573749999995925],
              [2693099.106377745978534, 1246999.657822416629642, 435.471250000002328],
              [2693103.882628948893398, 1246985.625324395485222, 435.558749999996508],
              [2693131.803869765717536, 1246953.820306948851794, 434.842499999998836],
              [2693312.645138828083873, 1246764.51283661625348, 436.149999999994179],
              [2693396.002622184343636, 1246651.040303940651938, 434.827499999999418],
              [2693430.458896941971034, 1246659.175348291872069, 436.676250000004075],
              [2693402.982622254174203, 1246641.212803860427812, 434.827499999999418],
              [2693611.183895396068692, 1246569.131591484416276, 436.426250000004075],
              [2693834.001379601191729, 1246463.191557960119098, 435.067500000004657],
              [2694136.223892390262336, 1246098.174072495661676, 435.807499999995343],
              [2694222.237633217591792, 1245788.81905157933943, 435.044999999998254],
              [2694353.482643057592213, 1245626.122815600596368, 435.690000000002328],
              [2694425.671387067064643, 1245507.766552421497181, 435.182499999995343],
              [2694439.127641404978931, 1245486.676559842657298, 435.5],
              [2694451.547637844458222, 1245459.571552929934114, 435.221250000002328],
              [2694466.078890257049352, 1245436.171556830173358, 435.392500000001746],
              [2694470.053891419433057, 1245430.806558818556368, 435.477499999993597],
              [2694478.220139616634697, 1245418.902805300895125, 435.335000000006403],
              [2694487.73014179430902, 1245404.905308954650536, 435.492499999993015],
              [2694493.527636873535812, 1245391.354049832560122, 435.116250000006403],
              [2694517.120142756495625, 1245343.751559620955959, 435.542499999995925],
              [2694517.443884506355971, 1245335.712794629158452, 434.91874999999709],
              [2694489.670154582709074, 1245198.481580174760893, 436.452499999999418],
              [2694532.421383493114263, 1245162.601540971780196, 434.827499999999418],
              [2694570.958892750553787, 1244992.340305489255115, 435.494999999995343],
              [2694551.650133837480098, 1244636.45778650068678, 434.827499999999418],
              [2694588.555143510457128, 1244394.63655115198344, 435.526249999995343],
              [2694591.851386860478669, 1244385.604038995457813, 435.021250000005239],
              [2694595.466399743687361, 1244365.230312024708837, 435.991250000006403],
              [2694590.228884288109839, 1244362.59653417346999, 434.827499999999418],
              [2694606.565145415719599, 1244360.369053962407634, 435.65625],
              [2694619.701384603977203, 1244251.404033210361376, 434.827499999999418],
              [2694687.815144816879183, 1244096.854049074929208, 435.546249999999418],
              [2694994.012640023604035, 1243746.04028186458163, 434.955000000001746],
              [2695256.615150461904705, 1243652.675295241177082, 435.551250000004075],
              [2695286.66639116499573, 1243630.071527739288285, 434.827499999999418],
              [2695492.63515684613958, 1243487.092801110818982, 435.858749999999418],
              [2695556.070145299658179, 1243452.989028870593756, 434.941250000003492],
              [2695622.085153662599623, 1243425.80654254113324, 435.523749999993015],
              [2695688.615146717987955, 1243396.86527859698981, 434.951249999998254],
              [2695925.91640646615997, 1243003.154038437409326, 435.506250000005821],
              [2695907.347648868802935, 1242972.012774802744389, 434.946249999993597],
              [2695908.880154807586223, 1242962.961535384412855, 435.392500000001746],
              [2695671.89764654962346, 1242433.877769895363599, 434.928750000006403],
              [2695508.876400826964527, 1242484.486530913040042, 435.369999999995343],
              [2695490.211393682286143, 1242498.327768498333171, 434.845000000001164],
              [2695392.138906016480178, 1242447.619041978381574, 435.845000000001164],
              [2695392.395142538938671, 1242433.209017558721825, 434.827499999999418],
              [2695388.660149394068867, 1242426.716529922559857, 435.347500000003492],
              [2695360.360142758116126, 1242448.937768657226115, 434.867499999993015],
              [2695348.702642120420933, 1242426.709017522167414, 434.827499999999418],
              [2695151.856406218372285, 1241950.540292066987604, 436.022500000006403],
              [2695077.950139694847167, 1241898.860263127135113, 434.827499999999418],
              [2694888.346404759213328, 1241924.376543834339827, 436.102499999993597],
              [2694632.256385339889675, 1242210.495265912497416, 434.827499999999418],
              [2694609.338898472022265, 1242219.412790078902617, 435.836249999993015],
              [2694481.420136740896851, 1242715.292775640729815, 435.053750000006403],
              [2694436.817645164672285, 1242829.507792657474056, 435.725000000005821],
              [2694432.53764060465619, 1242834.879034552723169, 435.383749999993597],
              [2694418.863894855603576, 1242846.01154256449081, 435.714999999996508],
              [2694407.281384787522256, 1242942.042775469366461, 434.964999999996508],
              [2694365.121396786067635, 1242954.454047985142097, 435.902499999996508],
              [2694355.282632992137223, 1242975.395273439120501, 434.867499999993015],
              [2694239.336393889039755, 1243136.921546617988497, 435.778749999997672],
              [2694208.087630948517472, 1243331.955275555606931, 434.827499999999418],
              [2694183.662643465679139, 1243380.717799035366625, 435.792499999995925],
              [2694176.340132775716484, 1243386.882779910927638, 434.990000000005239],
              [2694155.510142112150788, 1243411.982797367731109, 435.711249999993015],
              [2694022.192629079334438, 1243640.190278226975352, 434.827499999999418],
              [2693865.07138883927837, 1243895.834050942910835, 435.684999999997672],
              [2693785.225129530299455, 1243946.439035975374281, 435.039999999993597],
              [2693772.848887603729963, 1243967.042800976429135, 435.660000000003492],
              [2693723.963876117952168, 1244003.094031397951767, 434.827499999999418],
              [2693696.210138957481831, 1244054.422805569134653, 435.820000000006985],
              [2693686.0138757256791, 1244103.422782258829102, 434.827499999999418],
              [2693684.652646174654365, 1244135.455319566885009, 436.376250000001164],
              [2693676.853875620756298, 1244160.016532737296075, 434.827499999999418],
              [2693660.325138074345887, 1244206.387805970851332, 435.782500000001164],
              [2693613.662626147270203, 1244301.139036079868674, 434.916249999994761],
              [2693475.665137892123312, 1244533.692811868386343, 435.910000000003492],
              [2693469.400123505853117, 1244600.739036542596295, 434.827499999999418],
              [2693157.330136382952332, 1244678.232816063566133, 436.029999999998836],
              [2693098.242623049765825, 1244688.447793056257069, 435.063750000001164],
              [2693063.372631415259093, 1244701.16530892951414, 435.722500000003492],
              [2693008.871371093206108, 1244729.446541454643011, 434.98124999999709],
              [2692799.842627046164125, 1244961.262808020925149, 435.588749999995343],
              [2692668.735117228236049, 1245187.502794580999762, 434.945000000006985],
              [2692322.998873914126307, 1245568.946566264843568, 435.711249999993015],
              [2692104.275111855007708, 1245873.140300990315154, 434.962499999994179],
              [2692093.330117895733565, 1245882.472812238615006, 435.428750000006403],
              [2692065.082614616490901, 1245916.985307090217248, 435.201249999998254],
              [2692045.327615555375814, 1245939.906559352530167, 435.287500000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 119, gemeinde_B: 193, name: 'Fällanden', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2691899.94260634528473, 1247612.524060285883024, 434.732499999998254],
              [2691897.540106240194291, 1247611.467810125555843, 434.726250000006985],
              [2691720.273868404794484, 1246934.902829284546897, 435.76249999999709],
              [2691727.568856155499816, 1246927.081556809600443, 434.827499999999418],
              [2691694.455117559526116, 1246629.371575444703922, 435.710000000006403],
              [2691685.502606214489788, 1246544.957804208854213, 434.853749999994761],
              [2691740.376369853504002, 1246384.675326582277194, 435.845000000001164],
              [2691820.965107390657067, 1246222.460301050916314, 434.837499999994179],
              [2691872.142618911806494, 1246148.675320402020589, 435.672500000000582],
              [2691947.297611220274121, 1246064.171554335393012, 435.032500000001164],
              [2691969.811369221657515, 1246034.709068208932877, 435.622499999997672],
              [2691979.088863347191364, 1246023.387807275168598, 435.169999999998254],
              [2692029.016366648487747, 1245959.18781180260703, 435.382500000006985],
              [2692045.327615555375814, 1245939.906559352530167, 435.287500000005821],
              [2691229.843008009716868, 1245892.092059551505372, 560.59375],
              [2691007.358739169780165, 1246105.499873148743063, 521.392500000001746],
              [2690482.139755965210497, 1245826.221731831785291, 599.134999999994761],
              [2690426.986017424613237, 1245864.078004470095038, 600.061249999998836],
              [2690345.940950882621109, 1245936.286636111559346, 595.082500000004075],
              [2690234.779826768673956, 1245845.076866866089404, 604.76249999999709],
              [2690214.043436570093036, 1245831.1716119046323, 594.119999999995343],
              [2690198.751040068920702, 1245821.698050615843385, 602.006250000005821],
              [2689981.143452575430274, 1246100.509149790275842, 595.570000000006985],
              [2689922.518473869655281, 1246164.102940746815875, 597.248749999998836],
              [2689782.003452446311712, 1246354.302907321602106, 595.759999999994761],
              [2689395.147820051759481, 1246141.351540182717144, 643.162500000005821],
              [2689376.151566731277853, 1246149.246534719597548, 642.929999999993015],
              [2689372.74906678404659, 1246146.140284865628928, 642.9375],
              [2689245.390281576663256, 1246242.093975108349696, 640.391250000000582],
              [2689141.184060870669782, 1246276.100281630875543, 642.732499999998254],
              [2688843.286532017402351, 1246294.532736774999648, 640.846250000002328],
              [2689047.760796638671309, 1246880.637646606657654, 584.617499999993015],
              [2689055.909572546835989, 1246887.561443800339475, 586.583750000005239],
              [2688988.814446706324816, 1248010.69144447054714, 481.842499999998836],
              [2690222.123834140598774, 1248557.58530808519572, 434.285000000003492],
              [2690887.330094351898879, 1248527.674064645310864, 434.577499999999418],
              [2690897.05259345844388, 1248524.330312811303884, 434.502500000002328],
              [2690911.915093191899359, 1248518.686562007060274, 434.471250000002328],
              [2691014.752595653757453, 1248463.410314175765961, 434.582500000004075],
              [2691034.922593630850315, 1248448.629059994360432, 434.413750000006985],
              [2691495.273853427264839, 1248006.407815700396895, 434.813750000001164],
              [2691899.94260634528473, 1247612.524060285883024, 434.732499999998254]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 120, gemeinde_B: 194, name: 'Greifensee', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694466.078890257049352, 1245436.171556830173358, 435.392500000001746],
              [2694451.547637844458222, 1245459.571552929934114, 435.221250000002328],
              [2694439.127641404978931, 1245486.676559842657298, 435.5],
              [2694425.671387067064643, 1245507.766552421497181, 435.182499999995343],
              [2694353.482643057592213, 1245626.122815600596368, 435.690000000002328],
              [2694222.237633217591792, 1245788.81905157933943, 435.044999999998254],
              [2694136.223892390262336, 1246098.174072495661676, 435.807499999995343],
              [2693834.001379601191729, 1246463.191557960119098, 435.067500000004657],
              [2693611.183895396068692, 1246569.131591484416276, 436.426250000004075],
              [2693402.982622254174203, 1246641.212803860427812, 434.827499999999418],
              [2693430.458896941971034, 1246659.175348291872069, 436.676250000004075],
              [2693396.002622184343636, 1246651.040303940651938, 434.827499999999418],
              [2693312.645138828083873, 1246764.51283661625348, 436.149999999994179],
              [2693131.803869765717536, 1246953.820306948851794, 434.842499999998836],
              [2693103.882628948893398, 1246985.625324395485222, 435.558749999996508],
              [2693099.106377745978534, 1246999.657822416629642, 435.471250000002328],
              [2693101.593879119027406, 1247005.066574919968843, 435.573749999995925],
              [2692938.2288723946549, 1247318.606568432645872, 435.190000000002328],
              [2692752.596375756897032, 1247463.612829132704064, 435.582500000004075],
              [2692695.155120156239718, 1247523.282820515800267, 435.201249999998254],
              [2692952.81401053769514, 1247931.513074602931738, 445.654999999998836],
              [2692845.655200315639377, 1248344.715470927534625, 441.182499999995343],
              [2693564.080257697496563, 1247796.573057172819972, 444.985000000000582],
              [2694012.792822550982237, 1247361.575662638293579, 449.547500000000582],
              [2693905.008997515309602, 1247104.184276204789057, 443.947499999994761],
              [2694701.157831363379955, 1246783.180660713696852, 449.6875],
              [2694724.778209560550749, 1246326.831340229371563, 478.223750000004657],
              [2694992.224421149119735, 1245865.54501111805439, 475.102499999993597],
              [2694466.078890257049352, 1245436.171556830173358, 435.392500000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 123, gemeinde_B: 197, name: 'Schwerzenbach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692695.155120156239718, 1247523.282820515800267, 435.201249999998254],
              [2692561.776376125402749, 1247623.751584629761055, 435.752500000002328],
              [2692539.863868569023907, 1247649.801571521442384, 435.196249999993597],
              [2692288.936375737655908, 1247735.17283977009356, 435.923750000001746],
              [2691899.94260634528473, 1247612.524060285883024, 434.732499999998254],
              [2691495.273853427264839, 1248006.407815700396895, 434.813750000001164],
              [2691034.922593630850315, 1248448.629059994360432, 434.413750000006985],
              [2691014.752595653757453, 1248463.410314175765961, 434.582500000004075],
              [2690911.915093191899359, 1248518.686562007060274, 434.471250000002328],
              [2690897.05259345844388, 1248524.330312811303884, 434.502500000002328],
              [2691323.159089308232069, 1249144.77700891206041, 452.872499999997672],
              [2691236.737721297889948, 1249431.840548037318513, 443.983749999999418],
              [2691483.781518238596618, 1249804.236882573226467, 447.372499999997672],
              [2691540.626510855741799, 1249873.604368718108162, 446.771250000005239],
              [2691955.552714229561388, 1249751.811775121372193, 442.923750000001746],
              [2692220.034010777249932, 1249733.006854941137135, 446.257500000006985],
              [2692428.025150696281344, 1248914.037893362808973, 437.741250000006403],
              [2692845.655200315639377, 1248344.715470927534625, 441.182499999995343],
              [2692952.81401053769514, 1247931.513074602931738, 445.654999999998836],
              [2692695.155120156239718, 1247523.282820515800267, 435.201249999998254]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 109, gemeinde_B: 172, name: 'Fehraltorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2699463.004520093090832, 1246730.893608481157571, 572.80000000000291],
              [2699323.934245774988085, 1247058.415622323052958, 552.361250000001746],
              [2698867.624631957150996, 1247360.253830438712612, 581.761249999995925],
              [2698411.25659177172929, 1248199.636627566069365, 541.63749999999709],
              [2697550.362144040409476, 1248757.503898862283677, 583.941250000003492],
              [2697426.955811651423573, 1248730.262502663070336, 577.860000000000582],
              [2697421.223308249842376, 1248747.029996857978404, 577.610000000000582],
              [2697380.877064281608909, 1248843.41875979071483, 578.103749999994761],
              [2697572.002485959790647, 1250023.418522923719138, 515.660000000003492],
              [2697552.806218771729618, 1250189.009744012728333, 514.38749999999709],
              [2697822.406267749611288, 1250494.932330176699907, 517.850000000005821],
              [2697571.928721857257187, 1250925.118506925413385, 514.619999999995343],
              [2698163.476441874634475, 1251325.977646520128474, 530.663750000006985],
              [2699147.340586721897125, 1251282.024587617255747, 559.4375],
              [2699924.911255185026675, 1250633.239516923902556, 608.811249999998836],
              [2700062.16314876684919, 1250108.398415808100253, 563.232499999998254],
              [2700130.986028805375099, 1249802.630344531964511, 591.632500000006985],
              [2700251.216826384421438, 1249462.848274076124653, 557.625],
              [2701009.7083351877518, 1248943.20246627740562, 576.292499999995925],
              [2700766.280485203024, 1248862.611842227866873, 550.323749999995925],
              [2700243.99032320221886, 1247762.859050288097933, 538.630000000004657],
              [2700164.966613220982254, 1247734.630373547319323, 541.696249999993597],
              [2699818.147809566929936, 1247827.502785541582853, 537.98124999999709],
              [2699833.702923059929162, 1247564.024236255325377, 546.467499999998836],
              [2699846.385356061160564, 1247504.484114940278232, 541.433749999996508],
              [2699647.482883451972157, 1246824.674160900525749, 543.646250000005239],
              [2699686.446627552155405, 1246773.981648915680125, 543.16874999999709],
              [2699531.082031057216227, 1246716.926126482896507, 573.558749999996508],
              [2699482.820767766796052, 1246743.033603997668251, 572.607499999998254],
              [2699463.004520093090832, 1246730.893608481157571, 572.80000000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 126, gemeinde_B: 200, name: 'Wangen-Brüttisellen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2691667.51271138433367, 1250123.191778533859178, 442.928750000006403],
              [2689916.898796508088708, 1251618.279022237751633, 431.713749999995343],
              [2689872.058812459930778, 1251569.124051786959171, 432.958750000005239],
              [2689844.897558234166354, 1251544.445294308010489, 432.65625],
              [2689821.577555404510349, 1251582.935289892135188, 432.458750000005239],
              [2689434.198850720655173, 1252583.922888799803331, 436.212499999994179],
              [2689436.828796962741762, 1252592.531540308380499, 432.117499999993015],
              [2689505.179033778142184, 1253590.165732346009463, 450.119999999995343],
              [2689478.418904608115554, 1253775.915497637353837, 440.309999999997672],
              [2689754.632678818888962, 1253908.578038264997303, 441.952499999999418],
              [2690427.560354875400662, 1253562.503345327218994, 454.832500000004075],
              [2690587.385676183737814, 1253560.265180603368208, 479.132500000006985],
              [2690842.897980963345617, 1253492.402317708590999, 464.096250000002328],
              [2691284.689352602232248, 1253283.320029785623774, 472.986250000001746],
              [2691279.818083006888628, 1253237.061243596021086, 471.501250000001164],
              [2691308.389339614659548, 1253208.656254857545719, 471.979999999995925],
              [2691867.378540259320289, 1252939.852064585313201, 505.721250000002328],
              [2692042.868406943511218, 1252886.979317059507594, 495.463749999995343],
              [2692184.367095549125224, 1252695.631700226804242, 490.688750000001164],
              [2692636.369812681339681, 1252503.407085304846987, 506.770000000004075],
              [2692907.240994454361498, 1252252.681952838320285, 501.371249999996508],
              [2692906.419770639389753, 1252140.976999427424744, 503.352499999993597],
              [2692873.657199655193835, 1251875.705618024803698, 497.997499999997672],
              [2692851.131069220602512, 1251458.239582002628595, 507.0625],
              [2692816.424463465344161, 1251221.218932328512892, 480.138749999998254],
              [2692735.060726252384484, 1251244.483957427553833, 481.172500000000582],
              [2692724.295671331696212, 1251115.095106329768896, 477.017500000001746],
              [2692533.449573303107172, 1250920.191634930670261, 488.679999999993015],
              [2691681.791458392515779, 1250175.286773299099877, 442.692500000004657],
              [2691667.51271138433367, 1250123.191778533859178, 442.928750000006403]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 127, gemeinde_B: 211, name: 'Altikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2699531.434322685468942, 1272190.803960123565048, 371.078750000000582],
              [2699581.278065544553101, 1272188.250196243869141, 370.51249999999709],
              [2702866.154423367697746, 1271168.171584300231189, 376.51249999999709],
              [2702600.439407824072987, 1271131.485309821553528, 375.517500000001746],
              [2702885.378173317760229, 1270763.40658070310019, 376.48750000000291],
              [2702787.493156572338194, 1270261.220297767547891, 375.287500000005821],
              [2702991.46942005539313, 1269883.382816153811291, 376.157500000001164],
              [2703204.778171589132398, 1269818.840315113775432, 376.13749999999709],
              [2703202.868166343774647, 1269812.829055561451241, 375.747499999997672],
              [2702577.521221511997283, 1269041.491319228429347, 417.55000000000291],
              [2701409.643105053342879, 1269212.607495489530265, 465.720000000001164],
              [2701148.603029678575695, 1269274.009863544721156, 460.282500000001164],
              [2701157.864187265746295, 1269369.222195739857852, 453.366250000006403],
              [2700722.676850664429367, 1269201.570000343956053, 465.913750000006985],
              [2700078.579199087340385, 1269326.992236843565479, 455.052500000005239],
              [2699622.248307139612734, 1270252.345622439403087, 388.552500000005239],
              [2699701.290691486559808, 1270659.434162884019315, 379.842499999998836],
              [2699664.440687707625329, 1270708.125406936276704, 379.583750000005239],
              [2699783.679449182469398, 1270774.444176587508991, 380.369999999995343],
              [2699650.954425929114223, 1271495.075391865801066, 378.727499999993597],
              [2699650.81318209413439, 1271727.087905001826584, 379.195000000006985],
              [2699606.144427597988397, 1271786.330397941404954, 378.88749999999709],
              [2699512.405568107031286, 1271817.203949112445116, 370.738750000004075],
              [2699500.255584086291492, 1271853.003978845896199, 371.945000000006985],
              [2699531.434322685468942, 1272190.803960123565048, 371.078750000000582]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 129, gemeinde_B: 214, name: 'Dägerlen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2698077.921011023689061, 1269696.999557324685156, 498.779999999998836],
              [2698154.894806032069027, 1269635.062137613072991, 502.097500000003492],
              [2698311.231003148015589, 1269480.432035984005779, 497.993749999994179],
              [2698337.923562454991043, 1269311.40464233700186, 502.422500000000582],
              [2698345.064549950417131, 1268308.430401503574103, 482.677500000005239],
              [2698185.595958764664829, 1267778.466939950827509, 494.722500000003492],
              [2698558.070593333803117, 1267174.096258447272703, 466.970000000001164],
              [2698286.239267559722066, 1266905.794872486498207, 461.479999999995925],
              [2698133.486863510683179, 1266803.341299831401557, 468.802500000005239],
              [2697976.499211258720607, 1266794.869774281512946, 457.48124999999709],
              [2697188.2810949976556, 1267128.283452857751399, 505.764999999999418],
              [2696481.095323319081217, 1267641.518307178514078, 448.23750000000291],
              [2696595.454077120404691, 1267731.950812885537744, 448.441250000003492],
              [2696122.469277919735759, 1268258.512445151340216, 463.936249999998836],
              [2695937.179185459855944, 1268300.853529236279428, 457.108749999999418],
              [2695876.914192548487335, 1268305.702293436042964, 457.688750000001164],
              [2695847.194188534747809, 1268428.171037767315283, 457.411250000004657],
              [2695838.532939408440143, 1268435.016039595473558, 457.483749999999418],
              [2695488.834376349579543, 1268454.897639967035502, 471.850000000005821],
              [2694775.718980360776186, 1268745.855677683372051, 442.507500000006985],
              [2694779.597727476619184, 1268773.523172558285296, 442.287500000005821],
              [2694920.30536847980693, 1269201.763433480169624, 452.839999999996508],
              [2694837.408943722955883, 1269257.666863786522299, 439.707500000004075],
              [2694864.046421799343079, 1269353.765573816373944, 438.035000000003492],
              [2694934.233986441046, 1269434.958192300517112, 442.866250000006403],
              [2694945.773914254270494, 1269467.174309485126287, 437.408750000002328],
              [2695118.420393981505185, 1269791.644732265500352, 454.630000000004657],
              [2695313.394236399792135, 1269808.92739889677614, 461.457500000004075],
              [2695356.642866376787424, 1270130.910930188139901, 452.376250000001164],
              [2695577.377999535761774, 1270084.039920701878145, 462.253750000003492],
              [2695515.107642912771553, 1270811.011772105237469, 435.417499999995925],
              [2695863.200511989649385, 1270578.914942923467606, 462.986250000001746],
              [2695977.38179777096957, 1270406.400004942668602, 465.592499999998836],
              [2696193.900432039517909, 1270480.187288688961416, 456.707500000004075],
              [2696673.08813376352191, 1270023.400146018015221, 471.526249999995343],
              [2696952.837897147517651, 1269949.985955461161211, 453.498749999998836],
              [2697002.015403935220093, 1269928.793466817820445, 453.972500000003492],
              [2697004.686653481796384, 1269925.793465907918289, 453.936249999998836],
              [2697488.595920010004193, 1270229.064407645724714, 492.427500000005239],
              [2697551.804736994206905, 1270169.563278692774475, 497.412500000005821],
              [2697783.499647324904799, 1270011.658107962692156, 490.479999999995925],
              [2697872.01854121312499, 1269948.317119406769052, 501.222500000003492],
              [2697964.78598351823166, 1269853.804510791553184, 496.808749999996508],
              [2698024.244781265035272, 1269765.27334620594047, 500.346250000002328],
              [2698045.276009236462414, 1269708.133304824819788, 498.672500000000582],
              [2698077.921011023689061, 1269696.999557324685156, 498.779999999998836]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 131, gemeinde_B: 216, name: 'Dinhard', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2698558.070593333803117, 1267174.096258447272703, 466.970000000001164],
              [2698185.595958764664829, 1267778.466939950827509, 494.722500000003492],
              [2698345.064549950417131, 1268308.430401503574103, 482.677500000005239],
              [2698337.923562454991043, 1269311.40464233700186, 502.422500000000582],
              [2698311.231003148015589, 1269480.432035984005779, 497.993749999994179],
              [2698154.894806032069027, 1269635.062137613072991, 502.097500000003492],
              [2698077.921011023689061, 1269696.999557324685156, 498.779999999998836],
              [2698332.116611840669066, 1270091.367116558598354, 449.822499999994761],
              [2698334.286613863892853, 1270081.537120128283277, 449.972500000003492],
              [2698415.444097847677767, 1269984.214588392293081, 448.707500000004075],
              [2698871.709235376678407, 1269828.236080361763015, 458.688750000001164],
              [2699244.980312671046704, 1269689.793256133561954, 445.451249999998254],
              [2699694.894258770160377, 1269335.334853111999109, 459.8125],
              [2699941.126679522916675, 1269365.209704, 453.690000000002328],
              [2700078.579199087340385, 1269326.992236843565479, 455.052500000005239],
              [2700722.676850664429367, 1269201.570000343956053, 465.913750000006985],
              [2701157.864187265746295, 1269369.222195739857852, 453.366250000006403],
              [2701148.603029678575695, 1269274.009863544721156, 460.282500000001164],
              [2701158.117022138554603, 1269106.586553873261437, 478.404999999998836],
              [2700847.831256321165711, 1268587.147658313624561, 421.333750000005239],
              [2700956.741246261168271, 1268561.160137869184837, 420.505000000004657],
              [2701102.88007794180885, 1268322.036532120080665, 426.507500000006985],
              [2701384.47872744128108, 1267709.80758881336078, 418.782500000001164],
              [2700973.880058340262622, 1267598.500242250971496, 425.115000000005239],
              [2700943.281296442262828, 1267536.158970559947193, 424.244999999995343],
              [2701246.06247977539897, 1267544.806344, 419.048750000001746],
              [2701285.109065874945372, 1266797.400698445271701, 444.136249999995925],
              [2700667.411788274999708, 1266792.08486463688314, 461.23124999999709],
              [2699381.357219420839101, 1267068.650678353849798, 494.541249999994761],
              [2699223.744621761143208, 1267156.15300406049937, 487.345000000001164],
              [2699167.712165829725564, 1267129.911835381761193, 490.695000000006985],
              [2699100.888387808576226, 1267195.559286183677614, 488.647500000006403],
              [2698793.980691266711801, 1267278.971433938946575, 474.142500000001746],
              [2698853.487174877896905, 1267215.915609003044665, 491.627500000002328],
              [2698558.070593333803117, 1267174.096258447272703, 466.970000000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 132, gemeinde_B: 218, name: 'Ellikon a.d.Th.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2703204.778171589132398, 1269818.840315113775432, 376.13749999999709],
              [2704697.796981316059828, 1269863.334150734823197, 379.653749999997672],
              [2704733.989475362934172, 1270044.181640810333192, 379.192500000004657],
              [2705354.378235895652324, 1269882.829148958902806, 379.583750000005239],
              [2705389.0069989124313, 1269585.3916696368251, 380.522500000006403],
              [2705399.398248568177223, 1269240.254166090860963, 380.482499999998254],
              [2706279.645853847265244, 1269060.951841403264552, 387.740000000005239],
              [2706023.178835788276047, 1268723.156464815139771, 423.676250000004075],
              [2705485.086133590899408, 1268404.008605172624812, 409.017500000001746],
              [2705322.426125993952155, 1268333.049843519693241, 408.559999999997672],
              [2705233.306139952037483, 1268344.069870374631137, 409.657500000001164],
              [2704677.554841357283294, 1268358.763541697058827, 406.416249999994761],
              [2704696.094880741089582, 1268208.343611489981413, 409.330000000001746],
              [2704692.203610182739794, 1267906.382321725599468, 407.796249999999418],
              [2704720.432360987644643, 1267857.241072299424559, 407.836249999993015],
              [2704712.584862010553479, 1267852.766074244864285, 407.917499999995925],
              [2704703.042357856407762, 1267857.462316920049489, 407.615000000005239],
              [2704601.392373077105731, 1267843.676096071489155, 408.815000000002328],
              [2704141.97601761855185, 1267875.637162764556706, 401.272500000006403],
              [2703607.583805397618562, 1267674.696442056447268, 423.063750000001164],
              [2703510.682506693992764, 1267722.108855749480426, 419.502500000002328],
              [2703501.008760295342654, 1267726.147612484637648, 419.777499999996508],
              [2703486.996259540785104, 1267730.723861397011206, 419.73124999999709],
              [2703219.319793935865164, 1268128.867227842099965, 403.850000000005821],
              [2702800.48374417796731, 1268794.653854503296316, 419.082500000004075],
              [2702583.712466445751488, 1269034.345059839077294, 417.167499999995925],
              [2702577.521221511997283, 1269041.491319228429347, 417.55000000000291],
              [2703202.868166343774647, 1269812.829055561451241, 375.747499999997672],
              [2703204.778171589132398, 1269818.840315113775432, 376.13749999999709]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 134, gemeinde_B: 220, name: 'Hagenbuch', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2708089.110263169277459, 1265281.207717372570187, 527.822499999994761],
              [2708576.629137312993407, 1265035.610427726991475, 536.587499999994179],
              [2708821.347762277815491, 1265281.146450509782881, 527.138749999998254],
              [2709470.503660449758172, 1265014.881796473404393, 592.929999999993015],
              [2709887.644158139359206, 1264718.164183937013149, 536.996249999996508],
              [2709930.174171379767358, 1264737.014206981519237, 537.9375],
              [2709802.936549330130219, 1264306.938986114459112, 529.026249999995343],
              [2709826.729264551308006, 1263917.195617239922285, 544.886249999995925],
              [2709750.097323496360332, 1262341.485613988013938, 493.89375000000291],
              [2709725.797326533123851, 1262349.970619989093393, 494.13749999999709],
              [2709549.481282766908407, 1262400.690993372583762, 509.509999999994761],
              [2709105.606259775348008, 1262266.494709702441469, 508.169999999998254],
              [2708418.906319121830165, 1262152.463578776922077, 513.117499999993015],
              [2708314.963359156623483, 1263058.57350326445885, 664.173750000001746],
              [2708115.9038787917234, 1263094.209697073092684, 610.323749999995925],
              [2707648.719555178191513, 1262913.25467150635086, 660.871249999996508],
              [2707190.521313744597137, 1263002.237101025180891, 606.397500000006403],
              [2707038.325677636545151, 1262858.166957051260397, 652.052500000005239],
              [2706626.445023216772825, 1263073.299792245030403, 511.211249999993015],
              [2706255.338979425374418, 1263240.6536745862104, 619.602499999993597],
              [2706333.277857770677656, 1263324.802654915023595, 629.052500000005239],
              [2706397.287471715826541, 1263345.44195830845274, 600.341249999997672],
              [2706474.336881048511714, 1263724.223148771794513, 649.197499999994761],
              [2706141.643366266041994, 1264793.603840701747686, 574.23124999999709],
              [2706162.594612900633365, 1264799.606334230164066, 573.962499999994179],
              [2706417.075969535857439, 1264815.077770933741704, 581.642500000001746],
              [2707036.268013433553278, 1264955.078186726896092, 547.258749999993597],
              [2707284.859278010204434, 1264994.444458052748814, 548.122499999997672],
              [2707580.134089880157262, 1264782.861610712716356, 533.923750000001746],
              [2707731.022995024453849, 1264705.210635839030147, 545.282500000001164],
              [2707854.572967542335391, 1264818.428084996063262, 543.142500000001746],
              [2707902.091725536622107, 1264891.223099167924374, 543.695000000006985],
              [2707913.361723047681153, 1264915.439344712067395, 543.501250000001164],
              [2708058.681506058666855, 1265251.127704890444875, 527.321249999993597],
              [2708060.441543821711093, 1265255.217772845877334, 530.115000000005239],
              [2708089.110263169277459, 1265281.207717372570187, 527.822499999994761]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 130, gemeinde_B: 215, name: 'Dättlikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2690527.058141259942204, 1264070.420413421466947, 381.755000000004657],
              [2688821.773029099684209, 1264074.251483189873397, 374.309999999997672],
              [2687929.037950533442199, 1264251.227603029925376, 368.886249999995925],
              [2688376.508215490262955, 1265615.932551437756047, 674.827499999999418],
              [2688378.60823554219678, 1265631.333838776219636, 676.354999999995925],
              [2688543.200715566053987, 1265599.945046861190349, 674.649999999994179],
              [2688875.163349390029907, 1265740.245287772500888, 684.48750000000291],
              [2690035.335751186590642, 1264776.491500971373171, 580.618749999994179],
              [2690096.388325784821063, 1264720.236636753659695, 586.233749999999418],
              [2690527.058141259942204, 1264070.420413421466947, 381.755000000004657]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 137, gemeinde_B: 224, name: 'Pfungen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2688821.773029099684209, 1264074.251483189873397, 374.309999999997672],
              [2690527.058141259942204, 1264070.420413421466947, 381.755000000004657],
              [2690553.804390590637922, 1264097.760411980561912, 381.6875],
              [2690635.579477235674858, 1264128.483070842456073, 388.220000000001164],
              [2690733.831961847376078, 1264126.398040825966746, 386.986250000001746],
              [2691509.128308807965368, 1263749.776953923981637, 393.83125000000291],
              [2692011.098296782001853, 1263557.004421960562468, 392.582500000004075],
              [2692082.050801589153707, 1263549.389429595321417, 392.899999999994179],
              [2691814.451045693829656, 1262822.723427931545302, 506.402499999996508],
              [2691902.494129523169249, 1262339.198445632588118, 455.788750000006985],
              [2691922.053449882660061, 1261806.618239023257047, 499.022500000006403],
              [2692009.218428632710129, 1261674.491946843685582, 497.336249999993015],
              [2691902.464702738914639, 1261456.24553901469335, 594.0625],
              [2691152.039048632606864, 1261662.302906251512468, 640.047500000000582],
              [2690624.090750609058887, 1261808.976451316848397, 579.948749999995925],
              [2690262.896049027796835, 1261938.250761442119256, 602.98750000000291],
              [2690301.592031294945627, 1262271.841520868008956, 582.598750000004657],
              [2690293.638417829759419, 1262562.813026310876012, 592.994999999995343],
              [2690232.669600684661418, 1262600.942904358264059, 587.948749999995925],
              [2690221.850894575472921, 1262631.922985945595428, 591.297500000000582],
              [2689815.495926364324987, 1262375.043244290631264, 498.985000000000582],
              [2689792.35608237888664, 1262369.134782638866454, 510.876250000001164],
              [2689769.0872454168275, 1262449.507123445626348, 504.279999999998836],
              [2689567.978223118931055, 1262871.96018771501258, 578.883749999993597],
              [2689559.599469266366214, 1262884.880180939566344, 578.598750000004657],
              [2689416.140870933420956, 1262918.271714873844758, 590.283750000002328],
              [2689241.673136113677174, 1263009.162536145653576, 572.570000000006985],
              [2688962.783548182807863, 1263225.915807096520439, 604.235000000000582],
              [2689343.879348705057055, 1263699.546600701287389, 379.270000000004075],
              [2688964.754310615360737, 1263958.410288330633193, 376.617499999993015],
              [2688798.513142433017492, 1264053.851693151518703, 382.961249999993015],
              [2688821.773029099684209, 1264074.251483189873397, 374.309999999997672]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 138, gemeinde_B: 225, name: 'Rickenbach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2701408.469494183082134, 1265707.901464847847819, 476.042499999995925],
              [2701260.928338286932558, 1265818.694139881059527, 483.196249999993597],
              [2700793.043019782751799, 1266500.854825858492404, 459.746249999996508],
              [2700737.03681561909616, 1266679.751162065658718, 463.222500000003492],
              [2700669.649283519480377, 1266788.503605902427807, 460.873749999998836],
              [2700667.411788274999708, 1266792.08486463688314, 461.23124999999709],
              [2701285.109065874945372, 1266797.400698445271701, 444.136249999995925],
              [2701246.06247977539897, 1267544.806344, 419.048750000001746],
              [2700943.281296442262828, 1267536.158970559947193, 424.244999999995343],
              [2700973.880058340262622, 1267598.500242250971496, 425.115000000005239],
              [2701384.47872744128108, 1267709.80758881336078, 418.782500000001164],
              [2701102.88007794180885, 1268322.036532120080665, 426.507500000006985],
              [2700956.741246261168271, 1268561.160137869184837, 420.505000000004657],
              [2700847.831256321165711, 1268587.147658313624561, 421.333750000005239],
              [2701158.117022138554603, 1269106.586553873261437, 478.404999999998836],
              [2701148.603029678575695, 1269274.009863544721156, 460.282500000001164],
              [2701409.643105053342879, 1269212.607495489530265, 465.720000000001164],
              [2702577.521221511997283, 1269041.491319228429347, 417.55000000000291],
              [2702583.712466445751488, 1269034.345059839077294, 417.167499999995925],
              [2702800.48374417796731, 1268794.653854503296316, 419.082500000004075],
              [2703219.319793935865164, 1268128.867227842099965, 403.850000000005821],
              [2703486.996259540785104, 1267730.723861397011206, 419.73124999999709],
              [2703477.81625183718279, 1267690.34509721910581, 419.162500000005821],
              [2702997.204107920639217, 1267140.306997124338523, 446.037500000005821],
              [2702879.184040417894721, 1266993.479375338880345, 441.085000000006403],
              [2703128.049096130765975, 1266728.27571957372129, 445.053750000006403],
              [2702874.810331155080348, 1266698.044446665327996, 444.119999999995343],
              [2701457.543301071505994, 1265721.334067449206486, 480.257500000006985],
              [2701408.469494183082134, 1265707.901464847847819, 476.042499999995925]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 143, gemeinde_B: 231, name: 'Zell', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2699741.558296258561313, 1257518.930260918103158, 481.054999999993015],
              [2700531.769696338567883, 1257331.964265270391479, 491.666249999994761],
              [2700527.660234977956861, 1257427.526490572141483, 531.996249999996508],
              [2700966.718636638019234, 1257592.552103365305811, 505.561249999998836],
              [2701162.068817290011793, 1257663.539926660247147, 518.913750000006985],
              [2701274.319924385286868, 1257745.878416964318603, 508.134999999994761],
              [2701654.335116943810135, 1257886.503758555743843, 522.216249999997672],
              [2701759.454997607506812, 1257942.194791431771591, 513.211249999993015],
              [2702283.30020559579134, 1258350.288910484174266, 528.315000000002328],
              [2702288.572708137333393, 1258340.861414860701188, 528.5],
              [2702293.277697644196451, 1258335.19889576244168, 527.712499999994179],
              [2702476.396238348446786, 1258057.749516707845032, 605.233749999999418],
              [2702671.471619930118322, 1257989.030444879783317, 540.238750000004075],
              [2704176.782594455871731, 1257921.723415716085583, 611.48750000000291],
              [2704181.073841207660735, 1257918.618409724207595, 611.241250000006403],
              [2704842.427104179747403, 1257521.12950666854158, 760.318750000005821],
              [2705075.026830841787159, 1257620.107760268263519, 739.717499999998836],
              [2705292.412167535629123, 1257657.544609354343265, 764.492499999993015],
              [2705291.657164746429771, 1257655.719604350393638, 764.286250000004657],
              [2705313.407173912972212, 1257647.404620047891513, 764.941250000003492],
              [2705337.903412760235369, 1257635.212099111406133, 764.082500000004075],
              [2705619.96728626312688, 1257385.04855869919993, 772.913750000006985],
              [2705990.32082710089162, 1257342.605671864002943, 756.931249999994179],
              [2706236.429898464120924, 1257097.561237171525136, 780.48750000000291],
              [2705422.219000663142651, 1256101.199893780518323, 621.842499999998836],
              [2705172.039952428080142, 1255879.405354524496943, 692.845000000001164],
              [2705013.892862020991743, 1255308.314101458759978, 537.557499999995343],
              [2704803.815313211176544, 1255048.050265502650291, 534.102499999993597],
              [2704529.697611678857356, 1254454.678395504364744, 612.347500000003492],
              [2704486.437642951030284, 1254425.748452267609537, 614.716249999997672],
              [2704446.238005314953625, 1254680.28811372234486, 548.698749999995925],
              [2704338.566107156220824, 1254660.316695840097964, 593.592499999998836],
              [2704174.332398464903235, 1254644.738023621961474, 596.823749999995925],
              [2703695.168496609665453, 1254967.855265857418999, 585.973750000004657],
              [2703237.413867639377713, 1254783.194691175827757, 614.063750000001164],
              [2703032.785028787795454, 1254775.234536206815392, 607.63749999999709],
              [2702978.370086084119976, 1254804.31589090381749, 611.964999999996508],
              [2702956.921318745240569, 1254809.754610291216522, 610.692500000004657],
              [2703132.884223222732544, 1254456.220328797586262, 640.676250000004075],
              [2702940.992752132471651, 1254229.391436080215499, 531.132500000006985],
              [2702522.642646828666329, 1254729.911260774591938, 523.642500000001746],
              [2702416.141676062718034, 1254701.344265238614753, 544.577499999999418],
              [2701937.427542922552675, 1255247.374841215787455, 516.38749999999709],
              [2701485.379206264857203, 1255429.551847807131708, 547.669999999998254],
              [2701586.365012926515192, 1255671.729798796819523, 514.448749999995925],
              [2701913.557900760322809, 1256256.705497646704316, 543.163750000006985],
              [2701936.059886451344937, 1256418.66082140034996, 504.726250000006985],
              [2701900.126126323826611, 1256459.892054285155609, 504.0],
              [2701782.601447850931436, 1256517.196387276286259, 528.122499999997672],
              [2701533.718586175702512, 1256637.543241010513157, 501.302500000005239],
              [2701265.695250852499157, 1256615.797744870884344, 532.529999999998836],
              [2701298.853584484662861, 1256654.354492807528004, 501.367499999993015],
              [2700646.15715657081455, 1256916.905436955625191, 488.588749999995343],
              [2699741.558296258561313, 1257518.930260918103158, 481.054999999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 114, gemeinde_B: 180, name: 'Weisslingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2700646.15715657081455, 1256916.905436955625191, 488.588749999995343],
              [2701298.853584484662861, 1256654.354492807528004, 501.367499999993015],
              [2701265.695250852499157, 1256615.797744870884344, 532.529999999998836],
              [2701533.718586175702512, 1256637.543241010513157, 501.302500000005239],
              [2701782.601447850931436, 1256517.196387276286259, 528.122499999997672],
              [2701900.126126323826611, 1256459.892054285155609, 504.0],
              [2701936.059886451344937, 1256418.66082140034996, 504.726250000006985],
              [2701913.557900760322809, 1256256.705497646704316, 543.163750000006985],
              [2701586.365012926515192, 1255671.729798796819523, 514.448749999995925],
              [2701485.379206264857203, 1255429.551847807131708, 547.669999999998254],
              [2701937.427542922552675, 1255247.374841215787455, 516.38749999999709],
              [2702416.141676062718034, 1254701.344265238614753, 544.577499999999418],
              [2702522.642646828666329, 1254729.911260774591938, 523.642500000001746],
              [2702940.992752132471651, 1254229.391436080215499, 531.132500000006985],
              [2702943.42275195941329, 1254227.618935701902956, 531.117499999993015],
              [2703014.810428479686379, 1253579.08299432718195, 544.207500000004075],
              [2701727.447901326231658, 1252841.012462585465983, 730.122499999997672],
              [2701406.316423812881112, 1252450.120806579710916, 713.475000000005821],
              [2701257.124093736987561, 1252232.003613379318267, 726.347500000003492],
              [2700837.092690395656973, 1251960.739595205755904, 715.351250000006985],
              [2700816.530197918880731, 1251952.809609202668071, 715.9375],
              [2700496.004952740389854, 1251880.336675475118682, 697.943750000005821],
              [2700456.326286059804261, 1252029.551828722003847, 704.229999999995925],
              [2700455.093785270117223, 1252034.295577402226627, 704.172500000000582],
              [2700462.068797557614744, 1252107.228100373176858, 705.086249999993015],
              [2699781.29005622677505, 1252195.959631575969979, 612.8125],
              [2699927.04020398715511, 1252522.237398590659723, 623.748749999998836],
              [2699924.157700125128031, 1252533.269891834584996, 623.462499999994179],
              [2699922.287700415588915, 1252534.29739242605865, 623.486250000001746],
              [2699283.578587194439024, 1252135.22299749776721, 596.872499999997672],
              [2699256.66734684817493, 1252138.871765591669828, 597.622499999997672],
              [2699147.894135113339871, 1252650.546496329130605, 638.121249999996508],
              [2699159.531635494437069, 1252684.51024715648964, 638.138749999998254],
              [2698734.949961636215448, 1253370.317000253126025, 606.770000000004075],
              [2698826.129394362214953, 1254818.069501341553405, 657.958750000005239],
              [2698728.140267950482666, 1255016.421325067523867, 629.808749999996508],
              [2698765.13574390951544, 1255219.610938437050208, 665.505000000004657],
              [2700068.626381341833621, 1255471.093791783088818, 524.583750000005239],
              [2700646.15715657081455, 1256916.905436955625191, 488.588749999995343]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 145, gemeinde_B: 242, name: 'Birmensdorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673896.106372050009668, 1243378.10170801798813, 546.797500000000582],
              [2673517.088269919157028, 1243564.07292714738287, 597.720000000001164],
              [2673173.326792342588305, 1243955.196265332167968, 580.301250000004075],
              [2672995.577776522841305, 1244571.944365692092106, 657.371249999996508],
              [2673274.792479034513235, 1245218.425060926936567, 633.850000000005821],
              [2673282.321231034118682, 1245240.812564759515226, 633.998749999998836],
              [2673324.731219720561057, 1245335.487543733092025, 633.072499999994761],
              [2673184.468783541582525, 1245433.871417879126966, 638.207500000004075],
              [2673037.196040033362806, 1246315.005977434339002, 619.363750000004075],
              [2673236.840580336749554, 1246652.996366634499282, 583.267500000001746],
              [2673721.645107367541641, 1246629.270634008105844, 448.354999999995925],
              [2674023.381093516480178, 1246685.569971529301256, 525.032500000001164],
              [2674531.294081300962716, 1246846.329789035022259, 465.188750000001164],
              [2675284.031789734959602, 1246944.80641421652399, 480.817500000004657],
              [2675316.879228226374835, 1246956.418798959814012, 476.003750000003492],
              [2676937.021501835435629, 1246055.869411252206191, 554.139999999999418],
              [2676925.622748287394643, 1246037.119404710130766, 553.875],
              [2677464.813470095861703, 1246039.324483340373263, 609.365000000005239],
              [2677891.82062160409987, 1245296.216317177051678, 581.899999999994179],
              [2677935.141170882154256, 1245426.716086747124791, 624.4375],
              [2678059.131874673068523, 1245399.061320131877437, 581.977499999993597],
              [2678289.021415818016976, 1245278.296530093299225, 643.042499999995925],
              [2677851.360825843643397, 1244891.786873761098832, 500.860000000000582],
              [2677873.220376233570278, 1244659.997105121379718, 562.882500000006985],
              [2677272.933967574499547, 1244866.771826685639098, 551.142500000001746],
              [2676992.562843008432537, 1244800.135815176647156, 561.13749999999709],
              [2676900.82529305992648, 1244593.283222152385861, 557.341249999997672],
              [2676898.266018393915147, 1244399.590995828388259, 516.604999999995925],
              [2676878.102627276442945, 1244168.859160083346069, 544.48124999999709],
              [2677004.312500179745257, 1244069.633920294232666, 534.497499999997672],
              [2677225.49503546115011, 1243406.163973659742624, 537.021250000005239],
              [2677317.821182845160365, 1243181.453778961440548, 528.971250000002328],
              [2676835.229743810370564, 1243159.08700739685446, 611.78125],
              [2676816.421066354028881, 1243225.850893277674913, 617.434999999997672],
              [2676527.950277924537659, 1243795.438193463487551, 556.496249999996508],
              [2676443.130557239055634, 1243827.589964515995234, 578.277499999996508],
              [2676278.543873402290046, 1244087.130415295017883, 544.727499999993597],
              [2676260.433871322311461, 1244092.191661886172369, 544.582500000004075],
              [2676164.665163913276047, 1244111.021743379766122, 547.98124999999709],
              [2675786.420056040398777, 1244434.325304791331291, 539.947499999994761],
              [2675757.060103522147983, 1244435.625393784604967, 543.667499999995925],
              [2675757.118316164240241, 1244179.643141572130844, 501.867499999993015],
              [2675645.068776169791818, 1244160.09524933481589, 537.746249999996508],
              [2675633.738758694380522, 1244174.565217233262956, 536.397500000006403],
              [2675614.12879091873765, 1244192.705277810804546, 538.922500000000582],
              [2675553.620973772369325, 1244395.444691254524514, 514.307499999995343],
              [2675015.582903695758432, 1244275.885967443697155, 567.717499999998836],
              [2674996.046655140351504, 1244283.864720677956939, 567.848750000004657],
              [2674621.67151478305459, 1244165.925716483267024, 557.267500000001746],
              [2674489.190397149417549, 1243998.495964229805395, 567.703750000000582],
              [2674439.73017993895337, 1244026.675560946809128, 550.822499999994761],
              [2674288.860492729116231, 1244040.714897510129958, 575.347500000003492],
              [2673924.50113973300904, 1243921.502530208788812, 528.662500000005821],
              [2673891.626378915738314, 1243389.201721040531993, 547.337499999994179],
              [2673896.106372050009668, 1243378.10170801798813, 546.797500000000582]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 146, gemeinde_B: 243, name: 'Dietikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2672576.051206301432103, 1247549.102880944032222, 437.44999999999709],
              [2672545.662448300048709, 1247547.499116497579962, 436.847500000003492],
              [2672460.435057610273361, 1247609.155573514522985, 445.458750000005239],
              [2672287.862023142166436, 1249697.644592270953581, 403.84375],
              [2671285.842241702601314, 1249788.563622817397118, 519.572499999994761],
              [2671013.186992166563869, 1250323.97691457089968, 500.272500000006403],
              [2670965.244570933748037, 1250337.170814009848982, 506.486250000001746],
              [2670947.44702146621421, 1250338.64447125676088, 502.625],
              [2669793.822685402818024, 1250577.503109060460702, 653.889999999999418],
              [2670767.517439281567931, 1250865.277912373421714, 437.557499999995343],
              [2670872.095002596732229, 1250719.815528393257409, 442.4375],
              [2671034.726599156856537, 1250619.849927069852129, 469.466249999997672],
              [2671402.842079269234091, 1251029.985974391689524, 408.889999999999418],
              [2671449.051833926234394, 1251949.683019252959639, 389.667499999995925],
              [2671390.715624164789915, 1251977.584346262970939, 392.858749999999418],
              [2671173.395616904832423, 1252144.570587602676824, 392.442500000004657],
              [2671838.169257854111493, 1252456.687873657327145, 383.462499999994179],
              [2671931.93801546562463, 1252603.269137641880661, 383.998749999998836],
              [2671927.35798931773752, 1252880.721590702654794, 381.962499999994179],
              [2671655.070521256886423, 1253258.794158408418298, 384.652499999996508],
              [2671600.784214463084936, 1253449.962803755654022, 380.247499999997672],
              [2671632.869213601574302, 1253450.534051612950861, 380.158750000002328],
              [2671649.970465280115604, 1253449.649054497946054, 380.278749999997672],
              [2671683.884214004036039, 1253444.962801497895271, 380.15625],
              [2671722.907971061300486, 1253434.495314073981717, 380.682499999995343],
              [2671751.562964137643576, 1253422.972800469724461, 380.121249999996508],
              [2671963.407976575195789, 1253300.901569484034553, 380.951249999998254],
              [2671993.437967647332698, 1253291.39280209178105, 380.232499999998254],
              [2672157.264224580023438, 1253233.670312029542401, 380.664999999993597],
              [2672620.266722894273698, 1252909.574048716109246, 380.21875],
              [2672711.345475930720568, 1252766.714051812654361, 380.392500000001746],
              [2673286.3342375010252, 1251561.94280455680564, 380.88749999999709],
              [2673296.229236951563507, 1251535.226553147193044, 380.837499999994179],
              [2673306.897985263727605, 1251266.86404765676707, 380.692500000004657],
              [2673261.12174778431654, 1251151.441570974886417, 381.697499999994761],
              [2673255.360497065354139, 1251141.414069639518857, 381.645000000004075],
              [2673118.396797934081405, 1250821.470414791954681, 385.701249999998254],
              [2673224.535541810560971, 1250580.49289959599264, 385.146250000005239],
              [2673401.344308558385819, 1250457.284177118213847, 386.332500000004075],
              [2673445.364297091495246, 1250440.225404747528955, 385.407500000001164],
              [2673520.990556665230542, 1250420.462921311380342, 386.103749999994761],
              [2673733.574300775304437, 1250406.022906648926437, 385.501250000001164],
              [2673788.173074889462441, 1250469.394201482646167, 387.347500000003492],
              [2674191.624313306994736, 1250528.21792362886481, 386.17500000000291],
              [2674254.025597172323614, 1250555.246736266417429, 388.773749999993015],
              [2675125.201826354023069, 1250632.754183588549495, 386.568750000005821],
              [2674962.321821077726781, 1250446.785424876958132, 386.26249999999709],
              [2674561.214495651423931, 1249954.563254208769649, 400.122499999997672],
              [2674561.11574286269024, 1249946.411998921073973, 399.904999999998836],
              [2674375.496933808550239, 1249983.683141797315329, 395.431249999994179],
              [2673752.392046663444489, 1249699.410861343378201, 404.65625],
              [2673519.528148427139968, 1249874.618088922463357, 393.253750000003492],
              [2672950.195510047022253, 1247850.998755947686732, 578.085000000006403],
              [2672576.051206301432103, 1247549.102880944032222, 437.44999999999709]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 147, gemeinde_B: 244, name: 'Geroldswil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673296.229236951563507, 1251535.226553147193044, 380.837499999994179],
              [2673286.3342375010252, 1251561.94280455680564, 380.88749999999709],
              [2672711.345475930720568, 1252766.714051812654361, 380.392500000001746],
              [2673152.048267967998981, 1253446.526950627099723, 500.538750000006985],
              [2673462.131787849590182, 1253591.466512979241088, 482.316250000003492],
              [2673774.165687156375498, 1253664.139288041042164, 493.710000000006403],
              [2673782.859435293357819, 1253662.088034339947626, 493.557499999995343],
              [2674163.023078372702003, 1253084.233916767640039, 582.352499999993597],
              [2673994.710829451680183, 1252388.573441507527605, 407.10624999999709],
              [2673564.080530924722552, 1252049.859135498525575, 384.103749999994761],
              [2673613.890545286238194, 1251896.759160406654701, 385.1875],
              [2673885.723037103191018, 1251625.94538839859888, 384.361250000001746],
              [2673643.508048522751778, 1251506.367912829620764, 385.411250000004657],
              [2673296.229236951563507, 1251535.226553147193044, 380.837499999994179]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 152, gemeinde_B: 249, name: 'Unterengstringen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2676541.973413893487304, 1252761.850733816158026, 606.089999999996508],
              [2676554.024704034905881, 1252714.029557995731011, 609.195000000006985],
              [2676672.87089103506878, 1252665.708186676492915, 604.179999999993015],
              [2676931.787241162732244, 1252723.075868129963055, 611.693750000005821],
              [2677121.914019195828587, 1252514.810762252891436, 555.462499999994179],
              [2677259.441564602311701, 1252507.714593845419586, 558.857499999998254],
              [2676460.746874092146754, 1251381.930506995180622, 389.402499999996508],
              [2676361.236896816175431, 1251320.513050594832748, 391.233749999999418],
              [2675923.569373955018818, 1251287.078014821745455, 389.751250000001164],
              [2675848.721886538434774, 1251333.238039966439828, 390.78125],
              [2675821.161851635202765, 1251366.249225419480354, 388.086249999993015],
              [2675125.201826354023069, 1250632.754183588549495, 386.568750000005821],
              [2674254.025597172323614, 1250555.246736266417429, 388.773749999993015],
              [2674191.624313306994736, 1250528.21792362886481, 386.17500000000291],
              [2673788.173074889462441, 1250469.394201482646167, 387.347500000003492],
              [2673733.574300775304437, 1250406.022906648926437, 385.501250000001164],
              [2673520.990556665230542, 1250420.462921311380342, 386.103749999994761],
              [2673445.364297091495246, 1250440.225404747528955, 385.407500000001164],
              [2673401.344308558385819, 1250457.284177118213847, 386.332500000004075],
              [2673224.535541810560971, 1250580.49289959599264, 385.146250000005239],
              [2673118.396797934081405, 1250821.470414791954681, 385.701249999998254],
              [2673255.360497065354139, 1251141.414069639518857, 381.645000000004075],
              [2674964.34849096275866, 1251526.647469979012385, 418.982499999998254],
              [2675223.289562480989844, 1251766.245883314637467, 404.908750000002328],
              [2675546.408562061842531, 1251957.352596559328958, 424.104999999995925],
              [2675924.920956024434417, 1252063.813642386812717, 415.587499999994179],
              [2675984.365983794908971, 1252158.716194117441773, 417.70625000000291],
              [2676541.973413893487304, 1252761.850733816158026, 606.089999999996508]
            ],
            [
              [2675447.203107912559062, 1251229.892992203822359, 388.822499999994761],
              [2675512.403113672509789, 1251257.503002132754773, 389.227499999993597],
              [2675533.073104817885906, 1251264.022985282586887, 388.524999999994179],
              [2675510.504425655119121, 1251369.143119043204933, 394.053750000006403],
              [2675447.203107912559062, 1251229.892992203822359, 388.822499999994761]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 153, gemeinde_B: 250, name: 'Urdorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673236.840580336749554, 1246652.996366634499282, 583.267500000001746],
              [2673153.353263261727989, 1246850.157963261008263, 597.639999999999418],
              [2672576.051206301432103, 1247549.102880944032222, 437.44999999999709],
              [2672950.195510047022253, 1247850.998755947686732, 578.085000000006403],
              [2673519.528148427139968, 1249874.618088922463357, 393.253750000003492],
              [2673752.392046663444489, 1249699.410861343378201, 404.65625],
              [2674375.496933808550239, 1249983.683141797315329, 395.431249999994179],
              [2674561.11574286269024, 1249946.411998921073973, 399.904999999998836],
              [2676224.701864533592016, 1248202.557627740548924, 583.041249999994761],
              [2675288.980454917997122, 1246971.448756182100624, 474.212499999994179],
              [2675313.035485105589032, 1246967.475061973091215, 476.542499999995925],
              [2675313.926726639736444, 1246959.847546085948125, 475.882500000006985],
              [2675318.054232495371252, 1246956.835056902840734, 476.335000000006403],
              [2675316.879228226374835, 1246956.418798959814012, 476.003750000003492],
              [2675284.031789734959602, 1246944.80641421652399, 480.817500000004657],
              [2674531.294081300962716, 1246846.329789035022259, 465.188750000001164],
              [2674023.381093516480178, 1246685.569971529301256, 525.032500000001164],
              [2673721.645107367541641, 1246629.270634008105844, 448.354999999995925],
              [2673236.840580336749554, 1246652.996366634499282, 583.267500000001746]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 54, gemeinde_B: 69, name: 'Wallisellen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2687444.632481184788048, 1251221.480192626127973, 428.508749999993597],
              [2687449.691231478005648, 1251220.781443062704057, 428.527499999996508],
              [2687138.126214717049152, 1251331.672668751562014, 427.473750000004657],
              [2686846.530042750760913, 1251385.310317815747112, 433.649999999994179],
              [2686458.116197782102972, 1251523.551401291042566, 426.672500000000582],
              [2685631.671179799363017, 1251790.685135099804029, 425.896250000005239],
              [2685643.722470378037542, 1251939.593960979254916, 428.998749999998836],
              [2685652.271145161241293, 1252506.037577228620648, 423.244999999995343],
              [2685661.79118230054155, 1252506.710145525401458, 426.082500000004075],
              [2685650.022417947184294, 1252755.40012147789821, 424.997499999997672],
              [2685655.678706954233348, 1252778.107693491503596, 427.98124999999709],
              [2685640.648657413199544, 1252916.057603638619184, 424.201249999998254],
              [2685652.640018097590655, 1253057.576558788539842, 432.672500000000582],
              [2685662.089898164384067, 1253075.626337612746283, 423.48124999999709],
              [2686646.04062489233911, 1253152.535159248160198, 478.351250000006985],
              [2687055.2453159885481, 1254184.275842502247542, 454.442500000004657],
              [2687065.425483994651586, 1254530.046155048534274, 467.28125],
              [2687321.876918806694448, 1254546.97774062701501, 481.198749999995925],
              [2687362.516920531634241, 1254531.256492845481262, 481.297500000000582],
              [2687387.789416525512934, 1254521.792734887916595, 480.971250000002328],
              [2687721.964483928401023, 1254449.262851641047746, 485.847500000003492],
              [2687784.266661714296788, 1254278.219755902187899, 461.197499999994761],
              [2687759.408111287280917, 1254113.671371944947168, 476.447499999994761],
              [2688195.272906455211341, 1252909.242225477704778, 460.448749999995925],
              [2688220.16542764659971, 1252865.297263506567106, 462.044999999998254],
              [2688341.551423849072307, 1252542.491792242275551, 442.587499999994179],
              [2688391.837667579762638, 1252555.093029937706888, 442.072499999994761],
              [2688384.181445012334734, 1252598.599330827128142, 444.171249999999418],
              [2689082.517529844306409, 1251392.352754464838654, 431.042499999995925],
              [2688467.861527041532099, 1251331.558218061923981, 450.335000000006403],
              [2688423.19881228171289, 1251205.934073948534206, 433.988750000004075],
              [2687661.641195571050048, 1251135.005122645292431, 425.631250000005821],
              [2687604.452556766103953, 1251188.496578265912831, 434.163750000006985],
              [2687502.681192611344159, 1251228.843870829558, 425.521250000005239],
              [2687449.54373498307541, 1251217.773949469905347, 428.794999999998254],
              [2687444.632481184788048, 1251221.480192626127973, 428.508749999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 156, gemeinde_B: 292, name: 'Stammheim', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2698337.191573465242982, 1277262.638362381607294, 447.101250000006985],
              [2698384.235298265703022, 1277418.834566517500207, 445.177500000005239],
              [2698697.114145325031132, 1277557.174740852322429, 452.23750000000291],
              [2699390.063817221671343, 1278089.032878983300179, 427.132500000006985],
              [2699475.246349030639976, 1278133.740436433115974, 429.458750000005239],
              [2700450.8811907581985, 1278431.198880882700905, 416.927500000005239],
              [2700583.965085992589593, 1278557.822896660072729, 427.710000000006403],
              [2700731.324959185440093, 1278701.225162264425308, 418.119999999995343],
              [2700960.121251611970365, 1278847.336487553548068, 421.139999999999418],
              [2701176.321225746069103, 1279142.175188856897876, 419.0625],
              [2701218.208731345366687, 1279147.96519847237505, 419.452499999999418],
              [2701208.337476334534585, 1279435.973941938718781, 419.091249999997672],
              [2701252.009981273673475, 1279468.013950544409454, 419.431249999994179],
              [2701257.08747966401279, 1279476.25144756748341, 419.307499999995343],
              [2701254.147562778089195, 1279768.954102936666459, 425.53125],
              [2701312.118739421013743, 1279948.822718668729067, 420.009999999994761],
              [2701392.015231144614518, 1280285.584414601093158, 438.033750000002328],
              [2702253.109166919719428, 1280255.609739933162928, 451.286250000004657],
              [2702649.138902032747865, 1280177.916745914611965, 431.227499999993597],
              [2702546.576354, 1279893.770407068310305, 427.708750000005239],
              [2702643.177691270597279, 1278847.168888226151466, 620.681249999994179],
              [2702658.237691273447126, 1278842.017637791810557, 620.666249999994761],
              [2702687.176450235769153, 1278835.167653424898162, 621.306249999994179],
              [2703344.10986020276323, 1278611.722011952660978, 595.302500000005239],
              [2703724.540235961321741, 1278495.998938962584361, 622.932499999995343],
              [2704275.383296501357108, 1278265.730162431718782, 571.057499999995343],
              [2704267.514606459066272, 1278194.249021362746134, 575.526249999995343],
              [2704336.208154080435634, 1277766.651145129930228, 560.389999999999418],
              [2704171.673360143788159, 1277017.632766598137096, 575.86250000000291],
              [2703313.766878915950656, 1276101.576319135492668, 466.213749999995343],
              [2703303.346843557897955, 1275896.613752770936117, 463.58125000000291],
              [2703332.344485342502594, 1275796.810260274447501, 474.126250000001164],
              [2703336.870203437283635, 1274888.809280379908159, 434.452499999999418],
              [2702695.008177931420505, 1274430.671404343098402, 470.302500000005239],
              [2702669.324297634884715, 1274170.042414353694767, 460.59375],
              [2702665.599299483466893, 1274144.134917546762154, 460.733749999999418],
              [2702561.497571563348174, 1273255.842570346081629, 518.412500000005821],
              [2701529.207200787495822, 1273401.49691550526768, 491.567500000004657],
              [2701478.906058584339917, 1273479.879614410223439, 499.667499999995925],
              [2701447.520585975144058, 1274078.942506513325498, 464.376250000001164],
              [2701337.024702550843358, 1274095.861929544247687, 491.868749999994179],
              [2700809.453121431637555, 1274418.817586774006486, 467.521250000005239],
              [2700572.062207998707891, 1274431.23820814373903, 492.896250000005239],
              [2700113.27813219698146, 1274655.721372050000355, 468.865000000005239],
              [2699641.164526619482785, 1274918.756648719776422, 480.058749999996508],
              [2699063.300580187700689, 1274825.860048218164593, 465.772500000006403],
              [2698979.918064150027931, 1274869.885020753368735, 464.633749999993597],
              [2698963.309381978120655, 1274935.247646340867504, 469.73750000000291],
              [2698545.903031752444804, 1275337.391223882324994, 462.542499999995925],
              [2698833.015615680720657, 1275939.720128561602905, 468.63749999999709],
              [2698504.030542315449566, 1276000.447500419337302, 463.382500000006985],
              [2698159.925655659288168, 1276029.571465935558081, 472.160000000003492],
              [2698337.191573465242982, 1277262.638362381607294, 447.101250000006985]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 157, gemeinde_B: 293, name: 'Wädenswil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694171.061840728390962, 1226607.887901680078357, 658.220000000001164],
              [2695048.32759232679382, 1224362.540292661171407, 1185.884999999994761],
              [2694150.940588734578341, 1224190.936211708700284, 1225.09375],
              [2693992.720283532049507, 1224099.609423617366701, 1202.313750000001164],
              [2693789.524371212348342, 1223928.113779767882079, 1228.242499999993015],
              [2692716.922669273801148, 1224032.185796915786341, 1101.352499999993597],
              [2692650.885815400164574, 1225093.035821283934638, 676.83125000000291],
              [2689900.836319642141461, 1225335.738309813197702, 623.11250000000291],
              [2690164.566161438822746, 1226070.115528989117593, 610.827499999999418],
              [2689148.179468375630677, 1228267.341033385135233, 578.139999999999418],
              [2689446.399705343879759, 1228594.188704692060128, 691.091249999997672],
              [2689518.51059243362397, 1229032.25180597952567, 663.388749999998254],
              [2689842.382308836095035, 1229123.248887480702251, 698.535000000003492],
              [2690026.234367968980223, 1229149.863090353552252, 664.786250000004657],
              [2690027.704366438090801, 1229170.803087841253728, 664.66874999999709],
              [2690039.754377712495625, 1229243.201858761720359, 665.514999999999418],
              [2689971.759349718689919, 1229466.114313156576827, 663.463749999995343],
              [2690176.712141864001751, 1229637.701085408218205, 685.467499999998836],
              [2689506.497943690046668, 1230534.131558384513482, 652.110000000000582],
              [2689248.598076561931521, 1230882.133058971026912, 662.51249999999709],
              [2689731.593463740311563, 1232250.633005519397557, 501.213749999995343],
              [2689750.669672859832644, 1232348.479182429611683, 498.088749999995343],
              [2690007.33521975344047, 1232480.022664428222924, 539.486250000001746],
              [2690437.528060799930245, 1233038.594773404765874, 470.01249999999709],
              [2690442.18808652786538, 1233046.556069760816172, 471.964999999996508],
              [2689999.149713514838368, 1234055.837012419709936, 405.919999999998254],
              [2690001.298469545319676, 1234063.817023331997916, 406.377500000002328],
              [2690003.058476739563048, 1234063.309536282671615, 406.923750000001746],
              [2690069.740962789859623, 1234088.705760225653648, 405.817500000004657],
              [2690209.12723082723096, 1234022.194539898307994, 407.092499999998836],
              [2690223.65596460364759, 1234016.86076034954749, 405.848750000004657],
              [2690222.755980495363474, 1234002.910788908600807, 407.057499999995343],
              [2690224.107215146068484, 1233981.57826101500541, 405.888749999998254],
              [2690510.20598173700273, 1233884.810785397188738, 406.952499999999418],
              [2690517.355969076044858, 1233884.360762437805533, 405.985000000000582],
              [2690527.155993319582194, 1233879.360805950826034, 407.821249999993597],
              [2690534.355967187788337, 1233890.910758805461228, 405.830000000001746],
              [2690809.405992244370282, 1233937.860799834365025, 407.547500000000582],
              [2690815.205969693139195, 1233938.010759090306237, 405.830000000001746],
              [2690909.898487963248044, 1234114.600791957229376, 407.157500000001164],
              [2691186.437222965527326, 1234135.795760553795844, 405.830000000001746],
              [2691188.467240714468062, 1234132.909542461391538, 407.176250000004075],
              [2691226.559723332058638, 1234122.782010430237278, 405.830000000001746],
              [2691226.03349252184853, 1234120.047044989885762, 407.287500000005821],
              [2691269.035973801277578, 1234106.873260440537706, 405.836249999993015],
              [2691327.910993583034724, 1234085.788294922793284, 407.297500000000582],
              [2691333.60722430376336, 1234086.119510108837858, 405.830000000001746],
              [2691340.082243052311242, 1234081.747043726732954, 407.248749999998836],
              [2691440.592227813322097, 1234040.01201427471824, 406.022500000006403],
              [2691461.905996170360595, 1234032.560796917648986, 407.401249999995343],
              [2691513.319726415444165, 1234012.89451033109799, 405.866250000006403],
              [2691518.00350218359381, 1234008.295806608395651, 407.818750000005821],
              [2691553.843476339709014, 1233994.414509366033599, 405.832500000004075],
              [2691738.955998002551496, 1233885.910794382449239, 407.347500000003492],
              [2691818.255978723522276, 1233855.810758130857721, 405.830000000001746],
              [2691841.105994512327015, 1233819.860785858007148, 407.011249999995925],
              [2691858.169728858396411, 1233778.274507059482858, 405.811249999998836],
              [2691841.505998835433275, 1233643.26079212478362, 407.335000000006403],
              [2691874.405980041250587, 1233666.560757972998545, 405.88749999999709],
              [2691869.805988094769418, 1233660.160772476578131, 406.501250000001164],
              [2691883.262229939457029, 1233621.419507253682241, 405.872499999997672],
              [2692122.954745036084205, 1233384.687028442742303, 406.848750000004657],
              [2692136.00598172005266, 1233378.902004243107513, 405.830000000001746],
              [2692282.445999982766807, 1233280.364533821353689, 407.11250000000291],
              [2692275.709732811897993, 1233203.132002412108704, 405.813750000001164],
              [2692349.856004276778549, 1233084.710788754513487, 407.38749999999709],
              [2692441.399734213016927, 1233002.835750505095348, 405.802500000005239],
              [2692625.606002670247108, 1232535.16077664704062, 407.065000000002328],
              [2692710.455987135414034, 1232517.71074721775949, 405.830000000001746],
              [2692714.105993245728314, 1232506.810758035862818, 406.289999999993597],
              [2692773.055987884290516, 1232450.760746966581792, 405.842499999998836],
              [2692760.906004938762635, 1232433.81077763531357, 407.142500000001746],
              [2692809.555988436099142, 1232418.810747082578018, 405.858749999999418],
              [2692813.106016003992409, 1232386.110796218970791, 407.943750000005821],
              [2692816.355988127645105, 1232388.010746151208878, 405.830000000001746],
              [2693006.706017368007451, 1232223.210794085869566, 407.911250000004657],
              [2693378.105993118137121, 1232014.110742714488879, 405.817500000004657],
              [2693389.606015012133867, 1231995.210781610803679, 407.466249999997672],
              [2693406.255993545521051, 1231975.810742696514353, 405.830000000001746],
              [2693641.256009096745402, 1231782.410765063483268, 406.842499999998836],
              [2693768.355996930040419, 1231536.960739097325131, 405.830000000001746],
              [2693726.956016464158893, 1231552.710774896433577, 407.336249999993015],
              [2693922.305998381227255, 1231304.810737204039469, 405.830000000001746],
              [2693954.799772996455431, 1231276.764530493644997, 407.66874999999709],
              [2693955.675998871680349, 1231278.00198730523698, 405.84375],
              [2694047.35601173248142, 1231215.910758293932304, 406.752500000002328],
              [2694080.755999836605042, 1231193.21073627169244, 405.830000000001746],
              [2694173.20601816708222, 1231117.360766910016537, 407.151249999995343],
              [2694174.056000696960837, 1231118.760735650081187, 405.830000000001746],
              [2694521.898520383052528, 1230939.12451363238506, 407.077499999999418],
              [2694589.356004515197128, 1230821.810733156278729, 405.830000000001746],
              [2694586.606030453462154, 1230818.310779541032389, 407.791249999994761],
              [2694639.006005083676428, 1230794.010733129223809, 405.838749999995343],
              [2694726.586020021233708, 1230730.738257857738063, 406.90625],
              [2694828.542269253637642, 1230666.430754270404577, 406.777499999996508],
              [2694638.555755021516234, 1230429.893766767345369, 481.365000000005239],
              [2694619.556881250813603, 1230375.154795427573845, 472.028749999997672],
              [2693188.700639892369509, 1228294.931786870118231, 663.026249999995343],
              [2693162.495274526067078, 1227967.697380151133984, 635.383749999993597],
              [2692833.761745892930776, 1227455.090277389157563, 652.479999999995925],
              [2693039.16276481654495, 1226744.704582678386942, 729.410000000003492],
              [2693236.958080972544849, 1226551.819157271413133, 658.475000000005821],
              [2693588.623081748373806, 1226698.375401656143367, 658.162500000005821],
              [2693939.540603469591588, 1226729.847931897733361, 659.432499999995343],
              [2694045.979337286669761, 1226482.535397066734731, 658.089999999996508],
              [2694101.936931607779115, 1226458.153063464909792, 665.161250000004657],
              [2694128.993079633451998, 1226517.709131774026901, 657.423750000001746],
              [2694139.10310560837388, 1226548.407928231870756, 659.377500000002328],
              [2694151.929337613750249, 1226569.83539610914886, 658.003750000003492],
              [2694171.061840728390962, 1226607.887901680078357, 658.220000000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 158, gemeinde_B: 294, name: 'Elgg', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2709725.797326533123851, 1262349.970619989093393, 494.13749999999709],
              [2709624.185127479024231, 1261475.363651765976101, 516.422500000000582],
              [2709612.282541989348829, 1261134.407245442271233, 510.111250000001746],
              [2709587.582016874104738, 1260754.247288732090965, 563.657500000001164],
              [2709917.07802224252373, 1260558.685591900954023, 545.30000000000291],
              [2709961.599302551709116, 1260396.373143333243206, 547.494999999995343],
              [2709353.051499793305993, 1260098.98859734274447, 618.001250000001164],
              [2709013.053230968303978, 1259475.973472570534796, 561.490000000005239],
              [2708725.78099444322288, 1259224.21769740851596, 581.225000000005821],
              [2708851.021032017190009, 1259168.137761115795001, 583.886249999995925],
              [2708995.179719399195164, 1259109.958895178511739, 579.122499999997672],
              [2709719.470890909899026, 1258003.002206761389971, 664.9375],
              [2709353.261555164121091, 1257347.402143004350364, 714.383749999993597],
              [2709334.682428283151239, 1257298.44147015013732, 686.557499999995343],
              [2709279.966330143623054, 1257293.429242590209469, 697.837499999994179],
              [2709265.209981658961624, 1257281.191567023517564, 690.576249999998254],
              [2709060.275784280151129, 1257268.424255375983194, 750.125],
              [2708929.192354110069573, 1256758.43884104420431, 681.5],
              [2708629.169571207370609, 1256283.629050621530041, 845.798750000001746],
              [2708203.395424266345799, 1256778.829879323253408, 724.477499999993597],
              [2707196.933891849126667, 1256450.887160598533228, 612.033750000002328],
              [2707266.22148274211213, 1257449.980560376308858, 804.009999999994761],
              [2706989.95245299907401, 1257728.476061684312299, 876.306249999994179],
              [2706664.027174535673112, 1258519.757352672051638, 670.657500000001164],
              [2705989.879563168156892, 1258552.670665057841688, 755.923750000001746],
              [2705835.699396200943738, 1259101.003131938399747, 650.877500000002328],
              [2705647.461195618845522, 1259234.37662595207803, 691.892500000001746],
              [2705001.934840149711818, 1259937.316713025793433, 591.817500000004657],
              [2704015.756729064043611, 1260686.660642628092319, 547.258749999993597],
              [2703994.158773869741708, 1261224.023577626794577, 513.389999999999418],
              [2704353.415065692272037, 1261255.780900658108294, 609.248749999998836],
              [2704328.806400670204312, 1261818.10505583952181, 522.565000000002328],
              [2704697.180823272559792, 1262061.96876408229582, 572.298750000001746],
              [2704865.184459008276463, 1262118.41730487300083, 563.647500000006403],
              [2704738.543484872207046, 1262459.168053943896666, 491.303750000006403],
              [2704678.601972284726799, 1263174.557345051784068, 564.827499999999418],
              [2705656.496601856313646, 1263392.420161747839302, 629.291249999994761],
              [2705876.136577414814383, 1263108.681606049416587, 534.419999999998254],
              [2706255.338979425374418, 1263240.6536745862104, 619.602499999993597],
              [2706626.445023216772825, 1263073.299792245030403, 511.211249999993015],
              [2707038.325677636545151, 1262858.166957051260397, 652.052500000005239],
              [2707190.521313744597137, 1263002.237101025180891, 606.397500000006403],
              [2707648.719555178191513, 1262913.25467150635086, 660.871249999996508],
              [2708115.9038787917234, 1263094.209697073092684, 610.323749999995925],
              [2708314.963359156623483, 1263058.57350326445885, 664.173750000001746],
              [2708418.906319121830165, 1262152.463578776922077, 513.117499999993015],
              [2709105.606259775348008, 1262266.494709702441469, 508.169999999998254],
              [2709549.481282766908407, 1262400.690993372583762, 509.509999999994761],
              [2709725.797326533123851, 1262349.970619989093393, 494.13749999999709]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 160, gemeinde_B: 296, name: 'Illnau-Effretikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2699741.558296258561313, 1257518.930260918103158, 481.054999999993015],
              [2700646.15715657081455, 1256916.905436955625191, 488.588749999995343],
              [2700068.626381341833621, 1255471.093791783088818, 524.583750000005239],
              [2698765.13574390951544, 1255219.610938437050208, 665.505000000004657],
              [2698728.140267950482666, 1255016.421325067523867, 629.808749999996508],
              [2698826.129394362214953, 1254818.069501341553405, 657.958750000005239],
              [2698734.949961636215448, 1253370.317000253126025, 606.770000000004075],
              [2699159.531635494437069, 1252684.51024715648964, 638.138749999998254],
              [2699147.894135113339871, 1252650.546496329130605, 638.121249999996508],
              [2699256.66734684817493, 1252138.871765591669828, 597.622499999997672],
              [2699147.340586721897125, 1251282.024587617255747, 559.4375],
              [2698163.476441874634475, 1251325.977646520128474, 530.663750000006985],
              [2697571.928721857257187, 1250925.118506925413385, 514.619999999995343],
              [2697822.406267749611288, 1250494.932330176699907, 517.850000000005821],
              [2697552.806218771729618, 1250189.009744012728333, 514.38749999999709],
              [2697572.002485959790647, 1250023.418522923719138, 515.660000000003492],
              [2697151.885126480367035, 1250132.293786722701043, 526.589999999996508],
              [2697016.502521426416934, 1250159.093599894084036, 518.80000000000291],
              [2696716.56009763572365, 1250372.70124624366872, 524.797500000000582],
              [2696714.500095023307949, 1250375.131241576746106, 524.602499999993597],
              [2696534.704235986340791, 1250371.671952856006101, 554.203750000000582],
              [2696425.665419018827379, 1250387.481834211619571, 549.257500000006985],
              [2696443.156749721150845, 1250280.110728698316962, 555.318750000005821],
              [2696447.077909094747156, 1250024.905561742838472, 548.482499999998254],
              [2695862.413017150945961, 1250499.999525541439652, 557.162500000005821],
              [2695133.070071238558739, 1250872.641236868686974, 524.17500000000291],
              [2694877.206454468891025, 1251332.182739038718864, 534.464999999996508],
              [2694499.142599686980247, 1251505.406308593694121, 526.882500000006985],
              [2694436.742609787732363, 1251464.201327829388902, 527.698749999995925],
              [2693955.999727323651314, 1251842.664396350737661, 499.202499999999418],
              [2693474.499940271023661, 1251814.7572933556512, 515.711249999993015],
              [2693189.424758954904974, 1252308.704474139725789, 502.23750000000291],
              [2693154.906021275091916, 1252361.693247833056375, 503.19999999999709],
              [2693096.533510368783027, 1252362.161979145137593, 502.422500000000582],
              [2693096.013510889839381, 1252369.460730177117512, 502.462499999994179],
              [2693015.663495686836541, 1252465.070705087855458, 501.380000000004657],
              [2693061.934949443209916, 1252569.871076574083418, 516.772500000006403],
              [2693044.355947957374156, 1252803.609370974591002, 497.75],
              [2693478.311042380984873, 1252825.823284511687234, 504.539999999993597],
              [2693555.139717321377248, 1253058.483148613013327, 498.802500000005239],
              [2693563.919721884652972, 1253093.411907100817189, 499.141250000000582],
              [2693561.299719342961907, 1253096.571902555646375, 498.951249999998254],
              [2693785.600143897812814, 1253994.950180985499173, 530.898749999993015],
              [2693836.30502414656803, 1254023.692461986793205, 521.798750000001746],
              [2693819.350132091902196, 1254203.908911006059498, 529.98124999999709],
              [2693751.711299595423043, 1254418.598764359485358, 523.804999999993015],
              [2693944.861346709076315, 1254952.460101903416216, 527.213749999995343],
              [2694518.518764332868159, 1255316.712443589698523, 520.503750000003492],
              [2694634.257603607606143, 1255374.753854450304061, 527.149999999994179],
              [2694649.27760288445279, 1255374.842602823860943, 527.082500000004075],
              [2695244.121125036850572, 1255090.782172541134059, 509.368749999994179],
              [2695269.261140993330628, 1255118.5722013595514, 510.552500000005239],
              [2695288.781137740239501, 1255141.052195270080119, 510.291249999994761],
              [2695353.439446712844074, 1255084.511390832485631, 476.958750000005239],
              [2695956.220922757871449, 1255988.902823454234749, 587.778749999997672],
              [2695853.076062493957579, 1256224.665808070451021, 504.171249999999418],
              [2696258.539976950734854, 1256219.172122397460043, 610.417499999995925],
              [2696295.999987839255482, 1256291.070892182178795, 611.202499999999418],
              [2696266.250160633120686, 1256610.878937063738704, 530.071249999993597],
              [2696399.16974576190114, 1256684.569204184226692, 592.872499999997672],
              [2696469.413257847074419, 1256977.166273207869381, 574.889999999999418],
              [2696512.151048128958791, 1257026.730550899636, 596.716249999997672],
              [2696456.453244239091873, 1257497.050004787510261, 573.888749999998254],
              [2696694.991280617192388, 1257411.689724159659818, 614.054999999993015],
              [2697293.584166461601853, 1257560.904618357075378, 454.413750000006985],
              [2698734.21435901382938, 1257554.129941107006744, 467.792499999995925],
              [2699164.635695637203753, 1258388.827598156407475, 473.979999999995925],
              [2699175.02569336257875, 1258389.60509383562021, 473.801250000004075],
              [2699710.228297560941428, 1257537.070264054462314, 481.177500000005239],
              [2699741.558296258561313, 1257518.930260918103158, 481.054999999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 161, gemeinde_B: 297, name: 'Bauma', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2710773.215808405540884, 1251095.723191760480404, 657.600000000005821],
              [2711974.072481513954699, 1250847.252656750148162, 687.493749999994179],
              [2713531.19720898848027, 1249503.981820922810584, 757.565000000002328],
              [2713652.8366559734568, 1248312.478679636958987, 992.113750000004075],
              [2713437.15525518823415, 1248169.976871027611196, 1073.1725],
              [2713031.487075018230826, 1247782.428775722626597, 840.127500000002328],
              [2712959.225838104262948, 1247590.248500231187791, 933.047500000000582],
              [2712576.211852489970624, 1247053.236183170927688, 732.423750000001746],
              [2711533.655954726040363, 1246518.912120523164049, 667.488750000004075],
              [2711400.912393437698483, 1245119.589344220468774, 865.653749999997672],
              [2711017.742090344894677, 1245351.275371143594384, 751.702499999999418],
              [2710804.916553317569196, 1245619.183183438377455, 712.365000000005239],
              [2710653.384205795824528, 1245675.699414130533114, 815.960000000006403],
              [2710254.548152729403228, 1245597.898815169231966, 738.761249999995925],
              [2710043.887758577242494, 1245776.043794786790386, 894.446249999993597],
              [2709769.000868496950716, 1245672.705461277393624, 755.257500000006985],
              [2709769.252125462051481, 1245669.850473537575454, 755.771250000005239],
              [2709772.90587357385084, 1245666.715470045804977, 755.627500000002328],
              [2709353.87627696339041, 1245761.170908026397228, 878.291249999994761],
              [2708812.461935098282993, 1245949.08266811305657, 742.833750000005239],
              [2708496.885762859135866, 1245914.083778370171785, 841.431249999994179],
              [2708256.986475773155689, 1246108.464087400119752, 802.001250000001164],
              [2708247.656681163236499, 1246154.789452432654798, 817.214999999996508],
              [2707948.530867667403072, 1246574.005808512913063, 664.822499999994761],
              [2707126.54308567661792, 1247005.499775397358462, 830.122499999997672],
              [2707122.741834379266948, 1247008.021023249952123, 830.03125],
              [2707063.673108725808561, 1246978.412317875772715, 831.912500000005821],
              [2706909.804237238597125, 1247335.899890817701817, 730.414999999993597],
              [2706778.056665479205549, 1247325.511016763281077, 725.243749999994179],
              [2706569.143781651742756, 1247432.185064595891163, 789.75],
              [2706312.749231377616525, 1248261.123660514131188, 730.6875],
              [2706276.70057088881731, 1248466.261323902290314, 737.377500000002328],
              [2706310.908055380918086, 1248501.14129582978785, 736.1875],
              [2705961.424001595005393, 1249045.004794217646122, 621.221250000002328],
              [2705663.097925510723144, 1249476.043596718227491, 727.3125],
              [2705801.746721360366791, 1249516.662425216520205, 730.559999999997672],
              [2705881.96125509776175, 1250384.50410356419161, 695.857499999998254],
              [2705880.235017808154225, 1250399.47412650147453, 696.803750000006403],
              [2706756.222424541134387, 1250696.314212762285024, 596.216249999997672],
              [2706796.864871514029801, 1250692.335367258870974, 592.244999999995343],
              [2707305.14633553288877, 1250006.340728363022208, 607.611250000001746],
              [2707867.603399128653109, 1249984.60563519061543, 759.873749999998836],
              [2708746.798224885482341, 1250065.595580997411162, 653.503750000003492],
              [2709148.347105199005455, 1250083.770802514161915, 662.721250000002328],
              [2709330.113162055145949, 1250613.143650947371498, 832.992499999993015],
              [2710013.847007971256971, 1251023.038120470941067, 654.662500000005821],
              [2710536.263488358352333, 1250826.686979063088074, 763.304999999993015],
              [2710773.215808405540884, 1251095.723191760480404, 657.600000000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 162, gemeinde_B: 298, name: 'Wiesendangen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2704720.432360987644643, 1267857.241072299424559, 407.836249999993015],
              [2705206.417717828880996, 1266738.645450245123357, 434.011249999995925],
              [2705222.367714449763298, 1266734.645443816669285, 433.748749999998836],
              [2705590.095480328425765, 1265599.766915417276323, 546.082500000004075],
              [2706284.178978222887963, 1265442.666443818481639, 526.761249999995925],
              [2706465.097817630507052, 1265457.795351474778727, 533.242499999993015],
              [2706719.166539506521076, 1265487.694045727839693, 530.940000000002328],
              [2706846.914957332890481, 1265405.463443341199309, 506.197499999994761],
              [2706879.174009198788553, 1265367.715236436808482, 528.553750000006403],
              [2706972.85391775611788, 1265376.973819764098153, 521.695000000006985],
              [2707725.276714773848653, 1265382.998089010594413, 543.0625],
              [2708089.110263169277459, 1265281.207717372570187, 527.822499999994761],
              [2708060.441543821711093, 1265255.217772845877334, 530.115000000005239],
              [2708058.681506058666855, 1265251.127704890444875, 527.321249999993597],
              [2707913.361723047681153, 1264915.439344712067395, 543.501250000001164],
              [2707902.091725536622107, 1264891.223099167924374, 543.695000000006985],
              [2707854.572967542335391, 1264818.428084996063262, 543.142500000001746],
              [2707731.022995024453849, 1264705.210635839030147, 545.282500000001164],
              [2707580.134089880157262, 1264782.861610712716356, 533.923750000001746],
              [2707284.859278010204434, 1264994.444458052748814, 548.122499999997672],
              [2707036.268013433553278, 1264955.078186726896092, 547.258749999993597],
              [2706417.075969535857439, 1264815.077770933741704, 581.642500000001746],
              [2706162.594612900633365, 1264799.606334230164066, 573.962499999994179],
              [2706141.643366266041994, 1264793.603840701747686, 574.23124999999709],
              [2706474.336881048511714, 1263724.223148771794513, 649.197499999994761],
              [2706397.287471715826541, 1263345.44195830845274, 600.341249999997672],
              [2706333.277857770677656, 1263324.802654915023595, 629.052500000005239],
              [2706255.338979425374418, 1263240.6536745862104, 619.602499999993597],
              [2705876.136577414814383, 1263108.681606049416587, 534.419999999998254],
              [2705656.496601856313646, 1263392.420161747839302, 629.291249999994761],
              [2704678.601972284726799, 1263174.557345051784068, 564.827499999999418],
              [2704576.700834019575268, 1263225.226299677509815, 573.235000000000582],
              [2704533.347758498508483, 1263408.715263288002461, 530.452499999999418],
              [2704676.584795275237411, 1263998.719188044779003, 588.877500000002328],
              [2704074.889379493892193, 1263970.339699846692383, 558.482499999998254],
              [2703577.027183558326215, 1263499.222755537834018, 581.577499999999418],
              [2703568.104594166856259, 1263480.475093823857605, 574.923750000001746],
              [2703470.39966951077804, 1263446.758981958730146, 580.628750000003492],
              [2702527.659945650957525, 1263430.268489080946892, 508.820000000006985],
              [2702314.527465135790408, 1263357.786027969326824, 510.448749999995925],
              [2702065.075140177737921, 1263303.223849800415337, 523.727499999993597],
              [2701235.455652747768909, 1263619.742531737312675, 469.291249999994761],
              [2701222.629402014892548, 1263613.098780585685745, 469.246249999996508],
              [2701137.855667005758733, 1263565.051308943424374, 470.431249999994179],
              [2700634.410505667328835, 1263591.728525584563613, 458.742499999993015],
              [2700448.175523871555924, 1263910.63106515374966, 460.252500000002328],
              [2700254.131745173595846, 1263885.691016345284879, 458.248749999998836],
              [2700313.219292087014765, 1264040.991102034691721, 461.721250000002328],
              [2700090.269236289896071, 1264431.424758372129872, 457.71875],
              [2700863.98339770687744, 1265338.00175091996789, 487.943750000005821],
              [2701408.469494183082134, 1265707.901464847847819, 476.042499999995925],
              [2701457.543301071505994, 1265721.334067449206486, 480.257500000006985],
              [2702874.810331155080348, 1266698.044446665327996, 444.119999999995343],
              [2703128.049096130765975, 1266728.27571957372129, 445.053750000006403],
              [2702879.184040417894721, 1266993.479375338880345, 441.085000000006403],
              [2702997.204107920639217, 1267140.306997124338523, 446.037500000005821],
              [2703477.81625183718279, 1267690.34509721910581, 419.162500000005821],
              [2703486.996259540785104, 1267730.723861397011206, 419.73124999999709],
              [2703501.008760295342654, 1267726.147612484637648, 419.777499999996508],
              [2703510.682506693992764, 1267722.108855749480426, 419.502500000002328],
              [2703607.583805397618562, 1267674.696442056447268, 423.063750000001164],
              [2704141.97601761855185, 1267875.637162764556706, 401.272500000006403],
              [2704601.392373077105731, 1267843.676096071489155, 408.815000000002328],
              [2704703.042357856407762, 1267857.462316920049489, 407.615000000005239],
              [2704712.584862010553479, 1267852.766074244864285, 407.917499999995925],
              [2704720.432360987644643, 1267857.241072299424559, 407.836249999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 14, gemeinde_B: 14, name: 'Wettswil a.A.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2677873.220376233570278, 1244659.997105121379718, 562.882500000006985],
              [2678008.290364556014538, 1244542.39207904599607, 561.847500000003492],
              [2677977.113390927668661, 1244491.095554938772693, 602.673750000001746],
              [2678959.155565605964512, 1243397.757416158448905, 576.48750000000291],
              [2678844.636196405161172, 1243330.968584761722013, 625.422500000000582],
              [2679167.615912244655192, 1242643.523042962187901, 603.087499999994179],
              [2679007.883811324369162, 1242282.802529783453792, 634.122499999997672],
              [2678984.736172957811505, 1242105.048522490076721, 623.434999999997672],
              [2679009.431242635473609, 1241987.28489909437485, 628.798750000001746],
              [2679006.827489747200161, 1241972.873643640661612, 628.577499999999418],
              [2679006.581241166684777, 1241968.593646212713793, 628.6875],
              [2679007.694990683346987, 1241960.106145181693137, 628.648749999993015],
              [2679003.983738356735557, 1241958.697390958666801, 628.472500000003492],
              [2679018.334996287245303, 1241904.166154556209221, 629.070000000006985],
              [2678357.268794742878526, 1242350.605204572668299, 536.696249999993597],
              [2677998.212401845492423, 1242415.321198662277311, 525.946249999993597],
              [2677847.672425456345081, 1242371.197495120344684, 527.908750000002328],
              [2677622.597394919488579, 1242901.386199103901163, 525.752500000002328],
              [2676911.958533863537014, 1243090.68332895427011, 614.811249999998836],
              [2676840.839743403252214, 1243149.757006389088929, 611.743749999994179],
              [2676835.229743810370564, 1243159.08700739685446, 611.78125],
              [2677317.821182845160365, 1243181.453778961440548, 528.971250000002328],
              [2677225.49503546115011, 1243406.163973659742624, 537.021250000005239],
              [2677004.312500179745257, 1244069.633920294232666, 534.497499999997672],
              [2676878.102627276442945, 1244168.859160083346069, 544.48124999999709],
              [2676898.266018393915147, 1244399.590995828388259, 516.604999999995925],
              [2676900.82529305992648, 1244593.283222152385861, 557.341249999997672],
              [2676992.562843008432537, 1244800.135815176647156, 561.13749999999709],
              [2677272.933967574499547, 1244866.771826685639098, 551.142500000001746],
              [2677873.220376233570278, 1244659.997105121379718, 562.882500000006985]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 151, gemeinde_B: 248, name: 'Uitikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2679208.575941405259073, 1245176.461504829814658, 798.764999999999418],
              [2678289.021415818016976, 1245278.296530093299225, 643.042499999995925],
              [2678059.131874673068523, 1245399.061320131877437, 581.977499999993597],
              [2677935.141170882154256, 1245426.716086747124791, 624.4375],
              [2677891.82062160409987, 1245296.216317177051678, 581.899999999994179],
              [2677464.813470095861703, 1246039.324483340373263, 609.365000000005239],
              [2676925.622748287394643, 1246037.119404710130766, 553.875],
              [2676937.021501835435629, 1246055.869411252206191, 554.139999999999418],
              [2675316.879228226374835, 1246956.418798959814012, 476.003750000003492],
              [2675318.054232495371252, 1246956.835056902840734, 476.335000000006403],
              [2675313.926726639736444, 1246959.847546085948125, 475.882500000006985],
              [2675313.035485105589032, 1246967.475061973091215, 476.542499999995925],
              [2675288.980454917997122, 1246971.448756182100624, 474.212499999994179],
              [2676224.701864533592016, 1248202.557627740548924, 583.041249999994761],
              [2676413.320943128317595, 1247994.54823312186636, 608.383749999993597],
              [2676466.042191042099148, 1247974.171977674588561, 608.167499999995925],
              [2677078.851100046187639, 1247923.283507922431454, 619.886249999995925],
              [2677911.621589692309499, 1247524.348319066688418, 560.078750000000582],
              [2677464.473312615416944, 1246837.994200470857322, 597.167499999995925],
              [2677952.999659385532141, 1246157.900430429028347, 701.057499999995343],
              [2677990.489729879423976, 1246141.978059988934547, 706.477499999993597],
              [2678317.683095566462725, 1246040.58858641423285, 676.321249999993597],
              [2679230.217197945341468, 1245224.274017007788643, 799.243749999994179],
              [2679208.575941405259073, 1245176.461504829814658, 798.764999999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 18, gemeinde_B: 24, name: 'Buch a.I.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2690096.388325784821063, 1264720.236636753659695, 586.233749999999418],
              [2690035.335751186590642, 1264776.491500971373171, 580.618749999994179],
              [2688875.163349390029907, 1265740.245287772500888, 684.48750000000291],
              [2688543.200715566053987, 1265599.945046861190349, 674.649999999994179],
              [2688378.60823554219678, 1265631.333838776219636, 676.354999999995925],
              [2688376.508215490262955, 1265615.932551437756047, 674.827499999999418],
              [2687956.359677576459944, 1266023.011711940634996, 691.483749999999418],
              [2687297.564213314093649, 1266785.057127668755129, 656.776249999995343],
              [2687212.676767784170806, 1266946.50973332580179, 661.032500000001164],
              [2687631.874478398822248, 1266976.732613982632756, 676.662500000005821],
              [2688140.457419841084629, 1267243.260030526667833, 519.057499999995343],
              [2688195.264923337381333, 1268461.502725226804614, 423.952499999999418],
              [2688692.565981709863991, 1269111.578616058453918, 409.007500000006985],
              [2688701.259672784246504, 1269108.247256372589618, 404.509999999994761],
              [2689738.190096145030111, 1268959.791772980475798, 436.01875000000291],
              [2690471.0066596288234, 1268370.003585055237636, 459.301250000004075],
              [2691034.536054682452232, 1268277.789767186855897, 507.861250000001746],
              [2691070.31479941541329, 1268229.124756189063191, 507.429999999993015],
              [2691073.862302388530225, 1268212.179761444218457, 507.652499999996508],
              [2691076.177326090168208, 1268136.024804454063997, 509.448749999995925],
              [2691156.461046678945422, 1268163.262248663697392, 507.148749999993015],
              [2691223.117563802748919, 1267867.153988229110837, 527.368749999994179],
              [2690917.700859390664846, 1267788.534403204917908, 493.11250000000291],
              [2690900.376137366052717, 1266754.620967872906476, 609.179999999993015],
              [2691430.380954291205853, 1266419.748112718341872, 594.75],
              [2691428.562201751861721, 1266416.219358020927757, 594.558749999996508],
              [2691317.703485266771168, 1266270.73317082086578, 597.207500000004075],
              [2690688.496975308749825, 1265684.709138817619532, 483.0625],
              [2690076.3548100781627, 1264969.537073265528306, 604.080000000001746],
              [2690096.388325784821063, 1264720.236636753659695, 586.233749999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 35, gemeinde_B: 43, name: 'Volken', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2689738.190096145030111, 1268959.791772980475798, 436.01875000000291],
              [2688701.259672784246504, 1269108.247256372589618, 404.509999999994761],
              [2688692.565981709863991, 1269111.578616058453918, 409.007500000006985],
              [2688366.860802157782018, 1269260.455789022613317, 395.542499999995925],
              [2688543.592360647395253, 1269537.586361981229857, 418.94999999999709],
              [2688885.201850593555719, 1270167.947913163108751, 379.858749999999418],
              [2688944.337623808067292, 1270503.784352768445387, 438.741250000006403],
              [2689380.565321068279445, 1271049.247216510120779, 453.453750000000582],
              [2689697.301630760543048, 1271323.620000797789544, 362.633749999993597],
              [2690412.174019818659872, 1271416.137284555006772, 353.76249999999709],
              [2690492.130280887242407, 1271441.771054087672383, 354.556249999994179],
              [2690529.132788322400302, 1271287.703386425971985, 450.103749999994761],
              [2690911.812674241606146, 1271342.024418165208772, 441.152499999996508],
              [2690977.118930396623909, 1271316.24192815204151, 441.571249999993597],
              [2691060.903925874270499, 1271320.130668259924278, 441.166249999994761],
              [2691123.027060364373028, 1271073.715591358020902, 489.297500000000582],
              [2690121.276041928678751, 1269876.16120990132913, 412.623749999998836],
              [2689884.017608884256333, 1269156.961795755429193, 436.886249999995925],
              [2689708.744978532660753, 1269034.242805979214609, 427.091249999997672],
              [2689738.190096145030111, 1268959.791772980475798, 436.01875000000291]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 49, gemeinde_B: 64, name: 'Nürensdorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2692026.309900412335992, 1254576.326029661344364, 513.979999999995925],
              [2691892.8500452991575, 1254557.687547156121582, 525.082500000004075],
              [2691435.932277217973024, 1254816.618318943539634, 505.133749999993597],
              [2691197.164971397724003, 1255075.57868209737353, 520.083750000005239],
              [2691030.344859592616558, 1255259.435982718598098, 511.738750000004075],
              [2690890.40362480096519, 1255380.044764745980501, 513.014999999999418],
              [2690834.873608790338039, 1255476.272237552097067, 511.847500000003492],
              [2690587.860694110859185, 1255661.037734152050689, 480.542499999995925],
              [2690577.002371381502599, 1255925.654770688852295, 513.032500000001164],
              [2690260.742033519316465, 1256331.576661299914122, 487.617499999993015],
              [2689511.456241252366453, 1257059.187525150133297, 523.087499999994179],
              [2689724.718525518197566, 1257229.568375832866877, 506.488750000004075],
              [2690016.29344770219177, 1257740.015530049568042, 595.427500000005239],
              [2689714.635783525183797, 1257811.357736124424264, 583.227499999993597],
              [2689483.100972347892821, 1258090.611842348473147, 597.83125000000291],
              [2689471.211866916157305, 1258358.277441589161754, 570.787500000005821],
              [2689438.367041640449315, 1258830.669019563356414, 584.132500000006985],
              [2689438.917039706371725, 1258845.050266164587811, 583.985000000000582],
              [2689458.140783489216119, 1258864.636504498310387, 583.493749999994179],
              [2689512.97829979006201, 1259033.516535225324333, 584.686249999998836],
              [2689537.668050380423665, 1259079.466075870441273, 565.676250000004075],
              [2689733.70593009935692, 1259106.258020813576877, 594.39375000000291],
              [2690174.907447244971991, 1259371.029954788042232, 519.221250000002328],
              [2690537.902449651155621, 1258618.124741290230304, 614.091249999997672],
              [2690812.263585837092251, 1258603.114525258075446, 605.16874999999709],
              [2691174.932428315281868, 1258440.660934313666075, 611.830000000001746],
              [2691517.012226796709001, 1258539.5880573366303, 596.196249999993597],
              [2691527.812227545771748, 1258537.608058428391814, 596.242499999993015],
              [2691499.832229351624846, 1258513.078062115702778, 596.40625],
              [2691956.549603105988353, 1258155.651565455598757, 586.378750000003492],
              [2692763.692288654390723, 1257654.608130281791091, 599.652499999996508],
              [2693086.938455616589636, 1257380.22671733982861, 593.04374999999709],
              [2693083.295955456327647, 1257380.457967131398618, 593.035000000003492],
              [2693080.032205644063652, 1257379.712967552244663, 593.052500000005239],
              [2693524.103141457308084, 1256558.072373258182779, 568.832500000004075],
              [2692990.326779746916145, 1256085.529675764963031, 560.857499999998254],
              [2692979.036787325982004, 1256106.609690113225952, 561.442500000004657],
              [2692964.626786386128515, 1256126.889688953524455, 561.384999999994761],
              [2692512.990201123990119, 1255867.976583046140149, 536.386249999995925],
              [2692410.550383985042572, 1255755.796918446430936, 550.333750000005239],
              [2692265.389869373291731, 1255528.514727933565155, 511.446249999993597],
              [2692216.117929928470403, 1255100.425749418558553, 553.977499999993597],
              [2692026.309900412335992, 1254576.326029661344364, 513.979999999995925]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 37, gemeinde_B: 52, name: 'Bassersdorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2689511.456241252366453, 1257059.187525150133297, 523.087499999994179],
              [2690260.742033519316465, 1256331.576661299914122, 487.617499999993015],
              [2690577.002371381502599, 1255925.654770688852295, 513.032500000001164],
              [2690587.860694110859185, 1255661.037734152050689, 480.542499999995925],
              [2690834.873608790338039, 1255476.272237552097067, 511.847500000003492],
              [2690890.40362480096519, 1255380.044764745980501, 513.014999999999418],
              [2691030.344859592616558, 1255259.435982718598098, 511.738750000004075],
              [2691197.164971397724003, 1255075.57868209737353, 520.083750000005239],
              [2691435.932277217973024, 1254816.618318943539634, 505.133749999993597],
              [2691892.8500452991575, 1254557.687547156121582, 525.082500000004075],
              [2692026.309900412335992, 1254576.326029661344364, 513.979999999995925],
              [2691878.925768892746419, 1254274.397832048824057, 485.157500000001164],
              [2691923.612183418124914, 1254021.738129349891096, 497.595000000001164],
              [2691915.084535100497305, 1253440.56410263502039, 486.338749999995343],
              [2692130.383422838058323, 1253276.034348277142271, 496.60624999999709],
              [2692240.761959744617343, 1253086.650205134414136, 480.35624999999709],
              [2692061.943409765139222, 1252912.370572078274563, 495.662500000005821],
              [2692054.518405356444418, 1252888.850563935935497, 495.333750000005239],
              [2692042.868406943511218, 1252886.979317059507594, 495.463749999995343],
              [2691867.378540259320289, 1252939.852064585313201, 505.721250000002328],
              [2691308.389339614659548, 1253208.656254857545719, 471.979999999995925],
              [2691279.818083006888628, 1253237.061243596021086, 471.501250000001164],
              [2691284.689352602232248, 1253283.320029785623774, 472.986250000001746],
              [2690842.897980963345617, 1253492.402317708590999, 464.096250000002328],
              [2690587.385676183737814, 1253560.265180603368208, 479.132500000006985],
              [2690427.560354875400662, 1253562.503345327218994, 454.832500000004075],
              [2689754.632678818888962, 1253908.578038264997303, 441.952499999999418],
              [2689436.711838915944099, 1254380.842550966423005, 473.422500000000582],
              [2689227.972997275646776, 1254503.72988794115372, 466.610000000000582],
              [2688902.341869920492172, 1254343.792617863044143, 476.205000000001746],
              [2688306.616773587185889, 1254459.379953456111252, 469.332500000004075],
              [2688294.655524117872119, 1254468.85120475362055, 469.382500000006985],
              [2688039.789466820657253, 1254760.647816987009719, 484.292499999995925],
              [2687860.359438500832766, 1254899.722769792424515, 482.278749999997672],
              [2687834.936942060478032, 1254918.692777029238641, 482.571249999993597],
              [2687878.430664136074483, 1255020.307725786697119, 480.407500000001164],
              [2687938.888249122072011, 1255147.760382274864241, 486.847500000003492],
              [2688038.523946013767272, 1255654.308117100968957, 444.57499999999709],
              [2688048.325399994850159, 1256354.712249083677307, 460.147500000006403],
              [2688203.501639148686081, 1256420.829726813361049, 459.202499999999418],
              [2688225.134143955539912, 1256471.873485740739852, 459.553750000006403],
              [2688929.391624102368951, 1257235.041994087630883, 552.767500000001746],
              [2689511.456241252366453, 1257059.187525150133297, 523.087499999994179]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 39, gemeinde_B: 54, name: 'Dietlikon', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2687834.936942060478032, 1254918.692777029238641, 482.571249999993597],
              [2687860.359438500832766, 1254899.722769792424515, 482.278749999997672],
              [2688039.789466820657253, 1254760.647816987009719, 484.292499999995925],
              [2688294.655524117872119, 1254468.85120475362055, 469.382500000006985],
              [2688306.616773587185889, 1254459.379953456111252, 469.332500000004075],
              [2688902.341869920492172, 1254343.792617863044143, 476.205000000001746],
              [2689227.972997275646776, 1254503.72988794115372, 466.610000000000582],
              [2689436.711838915944099, 1254380.842550966423005, 473.422500000000582],
              [2689754.632678818888962, 1253908.578038264997303, 441.952499999999418],
              [2689478.418904608115554, 1253775.915497637353837, 440.309999999997672],
              [2689505.179033778142184, 1253590.165732346009463, 450.119999999995343],
              [2689436.828796962741762, 1252592.531540308380499, 432.117499999993015],
              [2689434.198850720655173, 1252583.922888799803331, 436.212499999994179],
              [2689821.577555404510349, 1251582.935289892135188, 432.458750000005239],
              [2689844.897558234166354, 1251544.445294308010489, 432.65625],
              [2689596.607547227293253, 1251407.887777335010469, 431.994999999995343],
              [2689440.948808344081044, 1251335.214049799134955, 432.952499999999418],
              [2689090.918758049607277, 1251291.27896348037757, 429.373749999998836],
              [2689084.171280389651656, 1251371.574005250353366, 431.082500000004075],
              [2689082.517529844306409, 1251392.352754464838654, 431.042499999995925],
              [2688384.181445012334734, 1252598.599330827128142, 444.171249999999418],
              [2688391.837667579762638, 1252555.093029937706888, 442.072499999994761],
              [2688341.551423849072307, 1252542.491792242275551, 442.587499999994179],
              [2688220.16542764659971, 1252865.297263506567106, 462.044999999998254],
              [2688195.272906455211341, 1252909.242225477704778, 460.448749999995925],
              [2687759.408111287280917, 1254113.671371944947168, 476.447499999994761],
              [2687784.266661714296788, 1254278.219755902187899, 461.197499999994761],
              [2687721.964483928401023, 1254449.262851641047746, 485.847500000003492],
              [2687387.789416525512934, 1254521.792734887916595, 480.971250000002328],
              [2687362.516920531634241, 1254531.256492845481262, 481.297500000000582],
              [2687321.876918806694448, 1254546.97774062701501, 481.198749999995925],
              [2687336.598201203159988, 1254579.720300323795527, 483.662500000005821],
              [2687515.466813099570572, 1254818.320044872583821, 472.977499999993597],
              [2687834.936942060478032, 1254918.692777029238641, 482.571249999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 48, gemeinde_B: 63, name: 'Lufingen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2685532.901834610849619, 1260718.056250095367432, 572.077499999999418],
              [2685572.611856161616743, 1260745.386289471294731, 573.691250000003492],
              [2685715.970573190134019, 1260762.774975186912343, 571.03125],
              [2685746.155699932947755, 1260742.006459331372753, 580.707500000004075],
              [2687558.032554377801716, 1261034.352913405746222, 434.241250000006403],
              [2687633.037592498585582, 1260834.024230755865574, 437.092499999998836],
              [2688122.212691538501531, 1260508.996901971986517, 444.282500000001164],
              [2688337.314376446418464, 1259895.950195321580395, 477.277499999996508],
              [2688405.541783135849983, 1259835.290021151537076, 470.10624999999709],
              [2688336.212470723316073, 1259608.877539444947615, 522.596250000002328],
              [2687993.947028648573905, 1259141.857783251209185, 584.533750000002328],
              [2687993.983126621227711, 1258763.602498187450692, 572.923750000001746],
              [2688028.713157328311354, 1258457.345050496514887, 575.227499999993597],
              [2687273.969731491990387, 1258079.497103928588331, 505.205000000001746],
              [2686147.859123948495835, 1259353.438329965109006, 555.345000000001164],
              [2685981.492757846135646, 1259439.269369629910216, 546.61250000000291],
              [2685994.687760188709944, 1259445.214373740367591, 546.779999999998836],
              [2686034.481490914244205, 1259499.428087763488293, 545.270000000004075],
              [2685715.919070470612496, 1259835.981996024027467, 551.657500000001164],
              [2685643.109010819345713, 1259874.566887499531731, 547.15625],
              [2685628.281559685245156, 1260050.629480394534767, 550.916249999994761],
              [2685587.051556960679591, 1260096.459476780612022, 550.746249999996508],
              [2685444.858084847219288, 1260443.784999395254999, 572.172500000000582],
              [2685462.908024148084223, 1260527.934887342853472, 567.507500000006985],
              [2685532.901834610849619, 1260718.056250095367432, 572.077499999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 41, gemeinde_B: 56, name: 'Embrach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2687633.037592498585582, 1260834.024230755865574, 437.092499999998836],
              [2687558.032554377801716, 1261034.352913405746222, 434.241250000006403],
              [2685746.155699932947755, 1260742.006459331372753, 580.707500000004075],
              [2685715.970573190134019, 1260762.774975186912343, 571.03125],
              [2685572.611856161616743, 1260745.386289471294731, 573.691250000003492],
              [2685532.901834610849619, 1260718.056250095367432, 572.077499999999418],
              [2685518.403118005022407, 1260801.75881336745806, 574.651249999995343],
              [2685078.320500096771866, 1261483.382362509844825, 566.046249999999418],
              [2685316.633178448304534, 1262273.93269752478227, 579.507500000006985],
              [2685385.545553827658296, 1262454.602466434240341, 569.897500000006403],
              [2685264.060665655881166, 1262710.838930108584464, 578.587499999994179],
              [2685365.970799811184406, 1262805.989178196992725, 588.771250000005239],
              [2685610.33763504261151, 1263705.627946967724711, 537.639999999999418],
              [2686074.562027231790125, 1263883.143236231990159, 395.047500000000582],
              [2686069.702026205603033, 1263896.953234520042315, 394.972500000003492],
              [2686073.912034045439214, 1263913.053249157266691, 395.570000000006985],
              [2685995.556952603626996, 1264171.764351199148223, 389.395000000004075],
              [2686409.037329816259444, 1264483.752547933487222, 417.982499999998254],
              [2686459.86153370840475, 1264803.242320202291012, 357.063750000001164],
              [2686518.191616526339203, 1264839.249973498051986, 363.36250000000291],
              [2686610.41161990351975, 1264898.464978815987706, 363.565000000002328],
              [2686703.821616495493799, 1264944.172471395693719, 363.247499999997672],
              [2687844.289203472901136, 1264257.378859845222905, 369.163750000006985],
              [2687922.227949625812471, 1264254.767601487692446, 368.821249999993597],
              [2687929.037950533442199, 1264251.227603029925376, 368.886249999995925],
              [2688821.773029099684209, 1264074.251483189873397, 374.309999999997672],
              [2688798.513142433017492, 1264053.851693151518703, 382.961249999993015],
              [2688964.754310615360737, 1263958.410288330633193, 376.617499999993015],
              [2689343.879348705057055, 1263699.546600701287389, 379.270000000004075],
              [2688962.783548182807863, 1263225.915807096520439, 604.235000000000582],
              [2689241.673136113677174, 1263009.162536145653576, 572.570000000006985],
              [2689416.140870933420956, 1262918.271714873844758, 590.283750000002328],
              [2689559.599469266366214, 1262884.880180939566344, 578.598750000004657],
              [2689567.978223118931055, 1262871.96018771501258, 578.883749999993597],
              [2689769.0872454168275, 1262449.507123445626348, 504.279999999998836],
              [2689792.35608237888664, 1262369.134782638866454, 510.876250000001164],
              [2689174.889680105727166, 1261518.174313136842102, 594.992499999993015],
              [2687633.037592498585582, 1260834.024230755865574, 437.092499999998836]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 53, gemeinde_B: 68, name: 'Rorbas', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2683965.24258387926966, 1267239.730753143085167, 343.307499999995343],
              [2683971.601334372535348, 1267226.068253868957981, 343.341249999997672],
              [2683987.307583830785006, 1267200.372002445627004, 343.289999999993597],
              [2684714.077628450002521, 1266476.174569721566513, 346.264999999999418],
              [2685279.219842205289751, 1266500.761358366813511, 419.776249999995343],
              [2686034.097764118108898, 1264931.081041188910604, 355.827499999999418],
              [2686459.86153370840475, 1264803.242320202291012, 357.063750000001164],
              [2686409.037329816259444, 1264483.752547933487222, 417.982499999998254],
              [2685995.556952603626996, 1264171.764351199148223, 389.395000000004075],
              [2686073.912034045439214, 1263913.053249157266691, 395.570000000006985],
              [2686069.702026205603033, 1263896.953234520042315, 394.972500000003492],
              [2686074.562027231790125, 1263883.143236231990159, 395.047500000000582],
              [2685610.33763504261151, 1263705.627946967724711, 537.639999999999418],
              [2685426.613975383806974, 1263989.679372183047235, 544.73124999999709],
              [2685219.757380141876638, 1264147.511235963786021, 518.463749999995343],
              [2685040.39782502129674, 1264199.465818606549874, 552.722500000003492],
              [2684772.557702963706106, 1264210.660597427515313, 543.607499999998254],
              [2684631.87909125443548, 1264447.954610862070695, 554.347500000003492],
              [2684579.297296373639256, 1264949.788601935375482, 512.608749999999418],
              [2684383.683763998560607, 1265497.606517789652571, 529.48750000000291],
              [2684159.908506214153022, 1265916.833545935805887, 509.90625],
              [2683782.482431662734598, 1266348.288886193884537, 523.712499999994179],
              [2683703.445372226182371, 1267143.729882849846035, 461.623749999998836],
              [2683723.225552341435105, 1267224.158970037009567, 475.445000000006985],
              [2683965.24258387926966, 1267239.730753143085167, 343.307499999995343]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 44, gemeinde_B: 59, name: 'Hochfelden', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2681179.044373556505889, 1266522.01305172755383, 386.563750000001164],
              [2681476.963226089254022, 1266159.960736138280481, 394.261249999995925],
              [2681515.588175813667476, 1265984.574389802291989, 390.35624999999709],
              [2681591.868216938804835, 1265681.186963118612766, 393.467499999998836],
              [2681654.229449364356697, 1265564.841928194509819, 392.068750000005821],
              [2681335.329505007714033, 1264278.117027108324692, 396.542499999995925],
              [2681517.473332536406815, 1264139.562168027507141, 402.391250000000582],
              [2681559.31833500508219, 1264122.587171800434589, 402.552500000005239],
              [2681748.907047336921096, 1264010.880847156746313, 399.517500000001746],
              [2681888.867090124636889, 1263654.947171841748059, 402.710000000006403],
              [2681802.130819576326758, 1263487.002133469562978, 401.182499999995343],
              [2681468.599797987379134, 1262924.522561598336324, 419.003750000003492],
              [2681323.567100285086781, 1263155.130946249701083, 403.869999999995343],
              [2680471.114610858261585, 1263071.217229891568422, 405.272500000006403],
              [2680465.142109165899456, 1263081.242226908449084, 405.146250000005239],
              [2679763.91252821451053, 1263695.435530429473147, 438.032500000001164],
              [2679445.588605223689228, 1263879.643963155569509, 424.897500000006403],
              [2679380.056143905036151, 1264156.017789416480809, 427.942500000004657],
              [2679205.572361258789897, 1264665.738985741278157, 425.556249999994179],
              [2679222.386108012404293, 1264695.621479603694752, 425.29374999999709],
              [2679583.137012127321213, 1265354.135678086429834, 494.942500000004657],
              [2679699.006252266466618, 1266232.094255878124386, 436.133749999993597],
              [2679921.011348873842508, 1266336.219434349099174, 443.436249999998836],
              [2680186.945313326083124, 1265820.341077455552295, 459.787500000005821],
              [2680976.696115043014288, 1266518.251477504149079, 424.610000000000582],
              [2681063.254970439244062, 1266449.13792312797159, 432.67500000000291],
              [2681127.737456555478275, 1266466.522896070266142, 431.557499999995343],
              [2681179.044373556505889, 1266522.01305172755383, 386.563750000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 50, gemeinde_B: 65, name: 'Oberembrach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2687633.037592498585582, 1260834.024230755865574, 437.092499999998836],
              [2689174.889680105727166, 1261518.174313136842102, 594.992499999993015],
              [2689792.35608237888664, 1262369.134782638866454, 510.876250000001164],
              [2689815.495926364324987, 1262375.043244290631264, 498.985000000000582],
              [2690221.850894575472921, 1262631.922985945595428, 591.297500000000582],
              [2690232.669600684661418, 1262600.942904358264059, 587.948749999995925],
              [2690293.638417829759419, 1262562.813026310876012, 592.994999999995343],
              [2690301.592031294945627, 1262271.841520868008956, 582.598750000004657],
              [2690262.896049027796835, 1261938.250761442119256, 602.98750000000291],
              [2690624.090750609058887, 1261808.976451316848397, 579.948749999995925],
              [2691152.039048632606864, 1261662.302906251512468, 640.047500000000582],
              [2691902.464702738914639, 1261456.24553901469335, 594.0625],
              [2691872.321940979920328, 1261370.316307508153841, 574.233749999999418],
              [2692077.510116191580892, 1260657.433784668333828, 625.228749999994761],
              [2691891.898948111571372, 1260487.685187643393874, 631.627500000002328],
              [2691462.904743277002126, 1259928.975605635438114, 597.53125],
              [2691464.734756954479963, 1259930.655630737310275, 598.567500000004657],
              [2691469.494742935523391, 1259921.815604759845883, 597.498749999998836],
              [2691634.442456622142345, 1259652.088490007445216, 613.547500000000582],
              [2691636.405893068760633, 1259030.67165670148097, 589.73750000000291],
              [2691691.163757894653827, 1258818.538572408026084, 617.36250000000291],
              [2691527.812227545771748, 1258537.608058428391814, 596.242499999993015],
              [2691517.012226796709001, 1258539.5880573366303, 596.196249999993597],
              [2691174.932428315281868, 1258440.660934313666075, 611.830000000001746],
              [2690812.263585837092251, 1258603.114525258075446, 605.16874999999709],
              [2690537.902449651155621, 1258618.124741290230304, 614.091249999997672],
              [2690174.907447244971991, 1259371.029954788042232, 519.221250000002328],
              [2689733.70593009935692, 1259106.258020813576877, 594.39375000000291],
              [2689537.668050380423665, 1259079.466075870441273, 565.676250000004075],
              [2689512.97829979006201, 1259033.516535225324333, 584.686249999998836],
              [2689458.140783489216119, 1258864.636504498310387, 583.493749999994179],
              [2689438.917039706371725, 1258845.050266164587811, 583.985000000000582],
              [2689072.821980516891927, 1258812.999112100573257, 484.597500000003492],
              [2688571.58167408592999, 1259434.320868983399123, 556.953750000000582],
              [2688336.212470723316073, 1259608.877539444947615, 522.596250000002328],
              [2688405.541783135849983, 1259835.290021151537076, 470.10624999999709],
              [2688337.314376446418464, 1259895.950195321580395, 477.277499999996508],
              [2688122.212691538501531, 1260508.996901971986517, 444.282500000001164],
              [2687633.037592498585582, 1260834.024230755865574, 437.092499999998836]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 59, gemeinde_B: 82, name: 'Boppelsen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2671959.144134585745633, 1259443.110777825815603, 862.921249999999418],
              [2672000.844172229524702, 1259451.139597776345909, 865.807499999995343],
              [2672448.05012435792014, 1259471.832768992288038, 841.878750000003492],
              [2672693.211486419197172, 1259461.261722413590178, 850.296249999999418],
              [2672782.861427196767181, 1259474.712857582839206, 845.542499999995925],
              [2673471.624485864769667, 1259392.297777025029063, 790.613750000004075],
              [2673560.912034868262708, 1259375.136616436298937, 794.321249999993597],
              [2673634.983281021472067, 1259370.955356731778011, 793.923750000001746],
              [2674024.110929438844323, 1258127.777141417143866, 609.982499999998254],
              [2673895.473898703698069, 1256806.622053115395829, 451.679999999993015],
              [2673057.861794979777187, 1257425.199072625953704, 483.29374999999709],
              [2672881.437966363038868, 1257327.957676849327981, 477.294999999998254],
              [2672579.102890245150775, 1257747.563654451165348, 569.307499999995343],
              [2671673.46543904999271, 1258219.012658134801313, 476.163750000006985],
              [2671959.144134585745633, 1259443.110777825815603, 862.921249999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 63, gemeinde_B: 86, name: 'Dielsdorf', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2677170.9969168510288, 1257284.916717084124684, 489.381250000005821],
              [2676801.096059580333531, 1257751.60121623496525, 520.192500000004657],
              [2676472.175865141674876, 1257663.955857360502705, 505.373749999998836],
              [2676373.064736318774521, 1257832.107338726753369, 514.877500000002328],
              [2676359.23966399952769, 1257934.429704283131287, 509.272500000006403],
              [2675280.491292194463313, 1257920.915289910743013, 636.931249999994179],
              [2675151.478834323585033, 1257825.786621317500249, 640.346250000002328],
              [2675077.241278273053467, 1257829.756517837289721, 636.061249999998836],
              [2674742.365166879724711, 1257948.8742890432477, 647.217499999998836],
              [2674489.23614205699414, 1258028.02752907271497, 626.07499999999709],
              [2674482.308729659300297, 1258116.590195389231667, 632.911250000004657],
              [2674457.039873192552477, 1258155.218745961785316, 624.641250000000582],
              [2674441.875008810777217, 1258181.964002195047215, 635.227499999993597],
              [2675159.920654417015612, 1258981.335355629678816, 587.432499999995343],
              [2675337.56600925931707, 1258683.512265676632524, 614.867499999993015],
              [2675424.550924164708704, 1258734.789603980490938, 608.157500000001164],
              [2675437.684674620162696, 1258749.954604694619775, 608.179999999993015],
              [2675919.304451647680253, 1259511.960580460261554, 493.176250000004075],
              [2675931.433730951976031, 1259734.574078573612496, 534.326249999998254],
              [2675993.050869322847575, 1259758.213396966923028, 506.15625],
              [2676676.633518155198544, 1260287.928817306412384, 420.066250000003492],
              [2676706.383514940273017, 1260280.708810666110367, 419.794999999998254],
              [2676822.754776367451996, 1260234.526329685468227, 420.597500000003492],
              [2677304.64967525517568, 1260301.199881759472191, 412.407500000001164],
              [2677341.552174594718963, 1260293.479879804886878, 412.330000000001746],
              [2677358.30217748740688, 1260291.284884928027168, 412.542499999995925],
              [2678108.46966049913317, 1260347.148590463213623, 410.697499999994761],
              [2678147.909692015498877, 1260374.651149153942242, 413.11250000000291],
              [2678150.088439147919416, 1260376.89489375683479, 412.888749999998254],
              [2678095.242182659450918, 1260059.136129808844998, 412.417499999995925],
              [2677976.687195930164307, 1259747.016154072247446, 413.522500000006403],
              [2677974.44468114245683, 1259724.788626187480986, 412.377500000002328],
              [2677849.682286070194095, 1258619.762565543176606, 420.573749999995925],
              [2677157.253221920225769, 1257401.460571783827618, 493.66874999999709],
              [2677186.233162554912269, 1257334.520459210500121, 489.036250000004657],
              [2677157.358180290088058, 1257324.76924295630306, 490.436249999998836],
              [2677170.9969168510288, 1257284.916717084124684, 489.381250000005821]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 66, gemeinde_B: 89, name: 'Niederglatt', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2678292.160902813076973, 1260817.202326885424554, 409.983749999999418],
              [2678395.292204672005028, 1260900.919923123670742, 413.929999999993015],
              [2678502.245891651138663, 1261047.243554279441014, 408.977499999993597],
              [2678513.062147317454219, 1261049.069814734859392, 409.408750000002328],
              [2678935.859646275639534, 1261299.253557605436072, 409.037500000005821],
              [2678974.339650347828865, 1261313.52606470673345, 409.326249999998254],
              [2678988.115899196825922, 1261314.53731232159771, 409.227499999993597],
              [2679037.284657001495361, 1261335.446076278109103, 409.797500000000582],
              [2679244.733398957643658, 1261446.894808564335108, 409.032500000001164],
              [2679426.309671174734831, 1261518.872347681317478, 410.625],
              [2679456.083396990783513, 1261553.612302141264081, 408.735000000000582],
              [2680130.893548830412328, 1261562.196324862074107, 419.992499999993015],
              [2680368.883499301970005, 1261545.503727818373591, 415.997499999997672],
              [2680692.526057058013976, 1261632.252580954926088, 420.227499999993597],
              [2680784.798486862331629, 1261688.411198513116688, 414.746249999996508],
              [2680817.958492069039494, 1261652.522457363316789, 415.123749999998836],
              [2681424.280011259019375, 1261112.19544491590932, 435.433749999996508],
              [2681568.706320962868631, 1260850.538051392417401, 439.922500000000582],
              [2681322.981131399516016, 1260831.680201683193445, 425.490000000005239],
              [2680879.073614543303847, 1260473.458924939390272, 424.502500000002328],
              [2680678.196160029619932, 1260244.314011484617367, 428.152499999996508],
              [2680653.079899756703526, 1260222.210242544766515, 427.377500000002328],
              [2680653.279901512898505, 1260202.930245650466532, 427.51249999999709],
              [2680623.938473956193775, 1260063.513663146179169, 413.826249999998254],
              [2680308.918653069995344, 1259749.409000699641183, 427.872499999997672],
              [2680047.747363820672035, 1259668.11393127264455, 425.028749999997672],
              [2679645.80021399958059, 1260257.677099115913734, 452.402499999996508],
              [2679148.894693412352353, 1260437.63738487358205, 412.514999999999418],
              [2678553.825904346071184, 1260432.244821937987581, 409.910000000003492],
              [2678149.702188103925437, 1260563.799893415067345, 412.8125],
              [2678150.740933721419424, 1260577.532385286176577, 412.472500000003492],
              [2678184.507192438002676, 1260727.673652354395017, 413.127500000002328],
              [2678292.160902813076973, 1260817.202326885424554, 409.983749999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 77, gemeinde_B: 100, name: 'Stadel', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2679921.011348873842508, 1266336.219434349099174, 443.436249999998836],
              [2679699.006252266466618, 1266232.094255878124386, 436.133749999993597],
              [2679583.137012127321213, 1265354.135678086429834, 494.942500000004657],
              [2679222.386108012404293, 1264695.621479603694752, 425.29374999999709],
              [2679205.572361258789897, 1264665.738985741278157, 425.556249999994179],
              [2679200.728609696496278, 1264657.165232817176729, 425.438750000001164],
              [2679195.384870217647403, 1264647.657752600498497, 426.256250000005821],
              [2678795.954747502692044, 1264078.357524055754766, 417.035000000003492],
              [2678234.073569707106799, 1264100.833920017816126, 423.029999999998836],
              [2678089.731004624627531, 1264057.758799731265754, 418.089999999996508],
              [2678060.763505406677723, 1264048.843801643699408, 418.171249999999418],
              [2677667.973600807599723, 1263963.793987483950332, 425.847500000003492],
              [2677081.86600263370201, 1263250.15241133607924, 515.652499999996508],
              [2677044.632249377202243, 1263211.101155587239191, 515.431249999994179],
              [2676948.794608699157834, 1263316.963393693557009, 504.596250000002328],
              [2676821.05243824608624, 1263538.927769810892642, 530.297500000000582],
              [2676753.032444578595459, 1263531.40903316764161, 530.850000000005821],
              [2676568.771151618566364, 1263530.30395620316267, 527.677500000005239],
              [2676463.033815369475633, 1263726.481769492849708, 540.497499999997672],
              [2676244.378640924580395, 1264186.128950187005103, 527.149999999994179],
              [2675702.69163588155061, 1265127.842407886171713, 566.151249999995343],
              [2675652.496576397679746, 1265208.14229751820676, 561.573749999995925],
              [2675640.081579164136201, 1265238.509803380817175, 561.801250000004075],
              [2675535.090999198611826, 1265487.774826019769534, 614.036250000004657],
              [2675422.272071881219745, 1265661.175745515385643, 600.357499999998254],
              [2675444.770912119187415, 1265743.735916703473777, 607.358749999999418],
              [2675728.959872944280505, 1265781.827558800345287, 623.46875],
              [2675803.379552464466542, 1266098.249454661505297, 598.475000000005821],
              [2675993.178543771617115, 1266175.491157250711694, 617.047500000000582],
              [2676088.008427893742919, 1266242.170936563285068, 607.943750000005821],
              [2676328.426357677206397, 1266952.052023957949132, 446.852499999993597],
              [2676565.831385015975684, 1267383.786939142970368, 545.897500000006403],
              [2676460.199655898381025, 1267903.192290694685653, 508.785000000003492],
              [2676568.36467791069299, 1267994.494830991141498, 510.403749999997672],
              [2676580.609674165025353, 1267996.392323671840131, 510.102499999993597],
              [2678291.025321444496512, 1267682.968788926722482, 365.110000000000582],
              [2679368.5839305492118, 1266954.085854057688266, 450.182499999995343],
              [2679843.342543539591134, 1266504.594333293614909, 439.222500000003492],
              [2679921.011348873842508, 1266336.219434349099174, 443.436249999998836]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 80, gemeinde_B: 111, name: 'Bäretswil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2711017.742090344894677, 1245351.275371143594384, 751.702499999999418],
              [2710894.856028395239264, 1244447.989803783129901, 1042.14375000000291],
              [2711324.230339603498578, 1243108.100397632224485, 806.492499999993015],
              [2711330.103161755017936, 1243054.875963563332334, 830.217499999998836],
              [2711233.340329948812723, 1242923.721630453597754, 805.891250000000582],
              [2711186.958377648610622, 1242723.128842522390187, 846.303750000006403],
              [2711239.699050289113075, 1242677.559074148070067, 803.692500000004657],
              [2711272.798349740449339, 1242621.298788988031447, 844.132500000006985],
              [2711666.469103678595275, 1242437.807901324471459, 807.085000000006403],
              [2711388.580602684989572, 1241886.713338718749583, 825.764999999999418],
              [2711328.0950603983365, 1241810.459885908989236, 785.889999999999418],
              [2710132.96921048220247, 1241993.266580878989771, 909.142500000001746],
              [2709622.10646380437538, 1241618.823062803829089, 1076.2937499999971],
              [2709609.96271760109812, 1241600.021819581510499, 1076.59375],
              [2709065.645582044031471, 1241576.258074144599959, 919.627500000002328],
              [2709025.82563442690298, 1241602.706918390700594, 923.557499999995343],
              [2708880.555536109954119, 1241614.963000175077468, 916.496249999996508],
              [2708273.168100924231112, 1241724.470637468388304, 922.161250000004657],
              [2708188.739273335319012, 1241759.031753960764036, 916.539999999993597],
              [2708156.669291995000094, 1241781.411788430763409, 917.967499999998836],
              [2708145.029289116151631, 1241790.831783911911771, 917.771250000005239],
              [2707397.799000092316419, 1241983.417840947629884, 804.792499999995925],
              [2707160.773230117280036, 1242096.421199380187318, 840.678750000006403],
              [2706954.108003778848797, 1242203.469557037344202, 824.16874999999709],
              [2706928.744383938144892, 1242322.504790210397914, 833.85624999999709],
              [2706369.13427911978215, 1242666.239912178600207, 734.042499999995925],
              [2706327.654328611679375, 1242660.68000098108314, 737.763749999998254],
              [2706264.495537856128067, 1242681.649930812884122, 734.8125],
              [2706234.164298522286117, 1242707.411200952949002, 735.639999999999418],
              [2706224.14304674603045, 1242723.621198314707726, 735.520000000004075],
              [2706202.564340004697442, 1242778.081276414683089, 738.757500000006985],
              [2705424.980167611502111, 1243334.419586528092623, 615.382500000006985],
              [2705052.847745091188699, 1243894.628212107811123, 714.473750000004657],
              [2705072.228841290343553, 1244117.57794131198898, 703.020000000004075],
              [2705060.169901776593179, 1244135.506354938261211, 688.941250000003492],
              [2704684.708922903519124, 1244525.171852695289999, 709.532500000001164],
              [2704486.50739257177338, 1244688.61761133861728, 688.895000000004075],
              [2704430.177282654680312, 1244853.513669376261532, 680.778749999997672],
              [2705604.889510510955006, 1244873.491349054966122, 845.047500000000582],
              [2705696.009556338656694, 1244906.935178158571944, 848.332500000004075],
              [2706188.872685627546161, 1245487.37031916086562, 801.610000000000582],
              [2706844.16939539462328, 1245812.917370735201985, 834.892500000001746],
              [2706877.888049078639597, 1245906.647200202103704, 827.707500000004075],
              [2707003.111314394976944, 1246365.461062896531075, 884.302500000005239],
              [2707043.42100647976622, 1246529.959267552709207, 861.419999999998254],
              [2706958.968030496034771, 1246502.3968946414534, 918.929999999993015],
              [2707116.964327128604054, 1246894.746008692542091, 829.498749999998836],
              [2707121.25684876088053, 1246982.061048415955156, 831.098750000004657],
              [2707126.54308567661792, 1247005.499775397358462, 830.122499999997672],
              [2707948.530867667403072, 1246574.005808512913063, 664.822499999994761],
              [2708247.656681163236499, 1246154.789452432654798, 817.214999999996508],
              [2708256.986475773155689, 1246108.464087400119752, 802.001250000001164],
              [2708496.885762859135866, 1245914.083778370171785, 841.431249999994179],
              [2708812.461935098282993, 1245949.08266811305657, 742.833750000005239],
              [2709353.87627696339041, 1245761.170908026397228, 878.291249999994761],
              [2709772.90587357385084, 1245666.715470045804977, 755.627500000002328],
              [2709769.252125462051481, 1245669.850473537575454, 755.771250000005239],
              [2709769.000868496950716, 1245672.705461277393624, 755.257500000006985],
              [2710043.887758577242494, 1245776.043794786790386, 894.446249999993597],
              [2710254.548152729403228, 1245597.898815169231966, 738.761249999995925],
              [2710653.384205795824528, 1245675.699414130533114, 815.960000000006403],
              [2710804.916553317569196, 1245619.183183438377455, 712.365000000005239],
              [2711017.742090344894677, 1245351.275371143594384, 751.702499999999418]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 82, gemeinde_B: 113, name: 'Dürnten', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2709887.195713509339839, 1236522.05941199930385, 558.90625],
              [2708945.791622929740697, 1236363.907582055544481, 534.5625],
              [2708714.459121138323098, 1236411.196334195090458, 534.628750000003492],
              [2708525.18604231486097, 1236346.975319830700755, 491.971250000002328],
              [2707159.652027420699596, 1235997.043625963386148, 473.411250000004657],
              [2707155.273279958404601, 1235988.549880431732163, 473.602499999993597],
              [2707146.705731352325529, 1235832.413543476723135, 470.002500000002328],
              [2707166.839535844977945, 1235823.363638978917152, 474.026249999995343],
              [2706937.823114890605211, 1235349.450837578391656, 461.514999999999418],
              [2706934.709450696129352, 1235363.529738849727437, 467.880000000004657],
              [2706901.444447204004973, 1235360.164733287878335, 467.646250000005239],
              [2706852.681961943395436, 1235366.049760186811909, 468.776249999995343],
              [2706827.163114667870104, 1235349.932089193956926, 461.58125000000291],
              [2705933.814543983899057, 1235506.003673105034977, 475.570000000006985],
              [2705885.042030478361994, 1235522.023650347953662, 474.604999999995925],
              [2705763.217033200897276, 1235540.572407591855153, 474.901249999995343],
              [2705843.484770318493247, 1235627.805325245950371, 492.452499999999418],
              [2705552.155966393649578, 1235681.867736218962818, 488.678750000006403],
              [2705280.549872155766934, 1236044.060520133003592, 500.479999999995925],
              [2705275.849783410318196, 1236070.660363756585866, 493.88749999999709],
              [2704927.464857545681298, 1236533.316756219603121, 499.690000000002328],
              [2704964.181512887589633, 1237346.523730785120279, 529.823749999995925],
              [2704894.662664429284632, 1237470.218559353379533, 522.5625],
              [2705093.215426073875278, 1238129.30527533730492, 541.865000000005239],
              [2705131.370429061818868, 1238125.152779773809016, 542.053750000006403],
              [2705406.185325840488076, 1238312.858843468362466, 534.147500000006403],
              [2705511.26804953860119, 1238433.641738280421123, 550.682499999995343],
              [2705522.355550354812294, 1238446.771739642135799, 550.733749999999418],
              [2705626.314205907750875, 1238555.52282158844173, 543.627500000002328],
              [2706104.965565146878362, 1238484.914253786206245, 551.318750000005821],
              [2706295.901752562727779, 1238430.340388534590602, 546.503750000003492],
              [2706601.328270787838846, 1238465.732106145005673, 566.136249999995925],
              [2706700.67064610728994, 1238448.529383623274043, 556.797500000000582],
              [2706918.337288292124867, 1238091.422566975466907, 585.677500000005239],
              [2707136.894366649445146, 1237639.945563170593232, 554.207500000004075],
              [2707595.118517784867436, 1238104.247515744296834, 583.527499999996508],
              [2708819.517807191703469, 1238115.751586795551702, 807.142500000001746],
              [2708894.457598949316889, 1237964.691215762402862, 791.647500000006403],
              [2709027.529035958927125, 1237846.729038857156411, 805.304999999993015],
              [2709256.294002546928823, 1237703.748970926040784, 802.544999999998254],
              [2709852.092196449637413, 1237443.424527288647369, 668.443750000005821],
              [2709887.195713509339839, 1236522.05941199930385, 558.90625]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 91, gemeinde_B: 131, name: 'Adliswil', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2682588.439002707600594, 1239418.965670757228509, 452.657500000001164],
              [2682570.626501781865954, 1239425.320669449632987, 452.600000000005821],
              [2681632.521205846685916, 1238221.87721824622713, 623.152499999996508],
              [2681629.062449913471937, 1238224.212207479635254, 622.698749999995925],
              [2680996.693128890357912, 1237922.454304670216516, 868.598750000004657],
              [2680981.652012962847948, 1238792.192270271712914, 782.542499999995925],
              [2680982.783263435121626, 1238797.728521189186722, 782.577499999999418],
              [2680941.978184927720577, 1238973.612130938097835, 776.576249999998254],
              [2680916.549920200835913, 1239666.604284500004724, 814.067500000004657],
              [2680957.418334949761629, 1240359.837427777703851, 788.162500000005821],
              [2680717.823616401758045, 1240713.374208736466244, 810.202499999999418],
              [2680715.342221128754318, 1240858.84269376215525, 798.996249999996508],
              [2680716.344805495347828, 1240946.12660041032359, 805.508749999993597],
              [2680695.931042877957225, 1240992.26907855970785, 804.5625],
              [2680480.765311570838094, 1241584.123997504357249, 748.39375000000291],
              [2681642.817552935797721, 1242001.319095006445423, 438.044999999998254],
              [2682037.855489970650524, 1242068.861142032081261, 471.412500000005821],
              [2682477.386662320233881, 1242450.645994564983994, 465.096250000002328],
              [2682963.570673879701644, 1242725.897718423046172, 484.837499999994179],
              [2683018.174682762473822, 1241358.729428707621992, 504.669999999998254],
              [2683249.864309330005199, 1240751.04123309883289, 475.753750000003492],
              [2683292.725727030076087, 1240462.110286785988137, 488.603749999994761],
              [2682814.976696480996907, 1239653.38206504099071, 563.552500000005239],
              [2682588.439002707600594, 1239418.965670757228509, 452.657500000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 104, gemeinde_B: 157, name: 'Oetwil a.S.', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2696486.587457787711173, 1235688.728080148110166, 608.288750000006985],
              [2696484.588687694165856, 1235705.585544453468174, 606.777499999996508],
              [2696436.586221474222839, 1235793.60935710882768, 609.371249999996508],
              [2696397.644935560412705, 1235842.59054436092265, 606.705000000001746],
              [2695928.096496271900833, 1236367.163618592778221, 630.603749999994761],
              [2695755.866246026009321, 1236501.573175985366106, 611.910000000003492],
              [2695754.67249609157443, 1236501.150676127756014, 611.916249999994761],
              [2695211.379247632808983, 1236539.067839276744053, 650.309999999997672],
              [2695163.124255520757288, 1236594.40910537680611, 650.957500000004075],
              [2695095.242894041817635, 1236792.375159615650773, 642.617499999993015],
              [2695062.672909152228385, 1236810.712687785038725, 643.792499999995925],
              [2695966.34834723547101, 1236950.999961711931974, 581.647500000006403],
              [2696277.863514635246247, 1237600.955521739786491, 499.792499999995925],
              [2696876.447084022220224, 1237958.12144000432454, 485.713749999995343],
              [2697335.27477487642318, 1238108.329274366376922, 499.710000000006403],
              [2697571.52463007485494, 1238163.442761099897325, 488.628750000003492],
              [2698205.996003468986601, 1237961.626717403996736, 497.38749999999709],
              [2698188.526179351378232, 1237580.299528206232935, 510.607499999998254],
              [2697854.142321035731584, 1237521.134340636897832, 502.742499999993015],
              [2697854.132321366574615, 1237520.314341223565862, 502.767500000001746],
              [2698656.132893194444478, 1236358.356583680026233, 545.016250000000582],
              [2698947.983985797967762, 1236088.127543807495385, 532.940000000002328],
              [2698982.462754814885557, 1236037.147576445713639, 534.336249999993015],
              [2698673.118167832493782, 1235877.462067823624238, 565.610000000000582],
              [2698498.251833726186305, 1235817.37442094925791, 559.452499999999418],
              [2698337.523167771752924, 1235644.24832244287245, 565.907500000001164],
              [2698097.500587210059166, 1235640.423183920327574, 560.073749999995925],
              [2697811.847111913841218, 1235378.101177580887452, 580.978749999994761],
              [2697770.930856208316982, 1235338.369917856762186, 580.587499999994179],
              [2697755.065858924295753, 1235328.409922945080325, 580.806249999994179],
              [2697724.168357162736356, 1235310.804920291993767, 580.702499999999418],
              [2697720.287106680683792, 1235308.852419497678056, 580.669999999998254],
              [2697688.060858458280563, 1235300.681173309450969, 580.833750000005239],
              [2697659.922097543254495, 1235275.873654161812738, 580.038750000006985],
              [2696798.498512885067612, 1235589.2727230200544, 593.304999999993015],
              [2696800.718472617212683, 1235499.68889988376759, 590.26875000000291],
              [2696744.280979653354734, 1235499.876413768157363, 590.852499999993597],
              [2696486.587457787711173, 1235688.728080148110166, 608.288750000006985]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 115, gemeinde_B: 181, name: 'Wila', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2710575.014492097776383, 1251203.775580611545593, 652.917499999995925],
              [2710570.740728852339089, 1251203.246807228541002, 651.945000000006985],
              [2710771.682059586048126, 1251095.774443900445476, 657.688750000001164],
              [2710773.215808405540884, 1251095.723191760480404, 657.600000000005821],
              [2710536.263488358352333, 1250826.686979063088074, 763.304999999993015],
              [2710013.847007971256971, 1251023.038120470941067, 654.662500000005821],
              [2709330.113162055145949, 1250613.143650947371498, 832.992499999993015],
              [2709148.347105199005455, 1250083.770802514161915, 662.721250000002328],
              [2708746.798224885482341, 1250065.595580997411162, 653.503750000003492],
              [2707867.603399128653109, 1249984.60563519061543, 759.873749999998836],
              [2707305.14633553288877, 1250006.340728363022208, 607.611250000001746],
              [2706796.864871514029801, 1250692.335367258870974, 592.244999999995343],
              [2706756.222424541134387, 1250696.314212762285024, 596.216249999997672],
              [2706867.066142980009317, 1250892.756656304001808, 593.774999999994179],
              [2706919.964873693883419, 1251393.592605546116829, 684.996249999996508],
              [2706919.67862437851727, 1251405.575106935109943, 685.047500000000582],
              [2706461.06999458745122, 1251852.505608537932858, 601.720000000001164],
              [2705163.658981242217124, 1252264.888303530868143, 713.503750000003492],
              [2705313.451272498816252, 1252953.814185661962256, 697.833750000005239],
              [2705409.706307836342603, 1253058.421747760847211, 700.35624999999709],
              [2705409.166298323776573, 1253067.601730880094692, 699.649999999994179],
              [2705414.113802191801369, 1253094.607988049043342, 699.932499999995343],
              [2705025.676987031009048, 1253241.629488544538617, 658.338749999995343],
              [2705013.413247812073678, 1253255.404508346691728, 659.153749999997672],
              [2705011.400744399987161, 1253275.057002546265721, 658.902499999996508],
              [2705039.827029934618622, 1253378.969566831598058, 661.517500000001746],
              [2705326.698861543089151, 1253963.2533702054061, 611.548750000001746],
              [2705429.960329113062471, 1254201.878760342020541, 627.622499999997672],
              [2705433.922829500865191, 1254202.416260944679379, 627.647500000006403],
              [2705894.23056048899889, 1254434.383178583811969, 551.517500000001746],
              [2706194.318193421699107, 1253760.292152282781899, 561.104999999995925],
              [2706255.809409049805254, 1253823.792090191040188, 558.5],
              [2706366.801941583864391, 1253866.283396420767531, 560.816250000003492],
              [2706381.212211058940738, 1253933.952628707746044, 580.802500000005239],
              [2706346.176922951824963, 1253945.315864454489201, 559.453750000000582],
              [2706456.756495069246739, 1253949.804831917164847, 620.357499999998254],
              [2706727.95592489419505, 1253709.245053596794605, 577.79374999999709],
              [2706644.55836479132995, 1253720.907448200741783, 573.412500000005821],
              [2706689.19177819089964, 1253359.949074730742723, 641.116250000006403],
              [2706660.991149528417736, 1253268.556701233610511, 594.508749999993597],
              [2706670.771162745542824, 1253263.555474561406299, 595.479999999995925],
              [2707323.673206041101366, 1253319.081606729421765, 746.286250000004657],
              [2707599.58298436878249, 1253359.147453976096585, 729.542499999995925],
              [2707757.408065157942474, 1253308.161342908162624, 735.34375],
              [2707839.888040809892118, 1253346.257547728484496, 733.446249999993597],
              [2707886.725565196014941, 1253320.958839531987906, 735.197499999994761],
              [2707804.048131710849702, 1253087.281728205736727, 647.645000000004075],
              [2708053.805847510695457, 1253070.730856390902773, 663.363750000004075],
              [2708058.834593073930591, 1253063.654598262161016, 663.029999999998836],
              [2707612.091984232421964, 1252681.029139288933948, 748.022500000006403],
              [2706686.590821892023087, 1252405.432355427416041, 570.162500000005821],
              [2708600.421257701702416, 1251910.778082742821425, 600.648749999993015],
              [2708626.241234426852316, 1251904.246790654491633, 598.902499999996508],
              [2708683.991359649226069, 1251912.43201220780611, 608.108749999999418],
              [2708688.486242771148682, 1251929.906804372556508, 599.461249999993015],
              [2708743.870130323804915, 1251960.734548170352355, 609.58125000000291],
              [2708811.131295822095126, 1252050.178147359751165, 603.270000000004075],
              [2709026.802611037623137, 1252136.097009357763454, 607.886249999995925],
              [2709044.611353235784918, 1252139.780745114199817, 607.292499999995925],
              [2709060.475106251426041, 1252132.442000006325543, 607.5],
              [2709248.34800091991201, 1251675.118941434193403, 636.472500000003492],
              [2709295.335371498018503, 1251626.713709667092189, 626.861250000001746],
              [2709294.181765978224576, 1251598.59146609576419, 637.537500000005821],
              [2709372.72409434709698, 1251568.876158833503723, 624.777499999996508],
              [2709393.87178774503991, 1251480.921500811586156, 639.042499999995925],
              [2709453.182844636030495, 1251480.23240629513748, 624.717499999998836],
              [2709512.656758983619511, 1251394.521445694379508, 636.796249999999418],
              [2709612.031651888974011, 1251420.727503397269174, 628.787500000005821],
              [2710575.518261206336319, 1251205.009364494122565, 654.326249999998254],
              [2710575.014492097776383, 1251203.775580611545593, 652.917499999995925]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 116, gemeinde_B: 182, name: 'Wildberg', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2705880.235017808154225, 1250399.47412650147453, 696.803750000006403],
              [2705224.610209883190691, 1250957.951728852698579, 804.747499999997672],
              [2704637.330035560298711, 1250912.414198752958328, 699.507500000006985],
              [2704184.978159924969077, 1250829.162827154155821, 746.496249999996508],
              [2704094.445553163066506, 1250876.61388936615549, 738.653749999997672],
              [2704038.234368202276528, 1250846.789006941253319, 743.5625],
              [2703682.425324663054198, 1251567.127511749509722, 628.98124999999709],
              [2703226.611253154929727, 1252070.083202504785731, 605.477499999993597],
              [2703278.006493638269603, 1252121.233633315889165, 623.358749999999418],
              [2703201.312627899926156, 1252354.932180422591045, 614.809999999997672],
              [2703025.166667546145618, 1253569.406724351458251, 543.382500000006985],
              [2703014.810428479686379, 1253579.08299432718195, 544.207500000004075],
              [2702943.42275195941329, 1254227.618935701902956, 531.117499999993015],
              [2702940.992752132471651, 1254229.391436080215499, 531.132500000006985],
              [2703132.884223222732544, 1254456.220328797586262, 640.676250000004075],
              [2702956.921318745240569, 1254809.754610291216522, 610.692500000004657],
              [2702978.370086084119976, 1254804.31589090381749, 611.964999999996508],
              [2703032.785028787795454, 1254775.234536206815392, 607.63749999999709],
              [2703237.413867639377713, 1254783.194691175827757, 614.063750000001164],
              [2703695.168496609665453, 1254967.855265857418999, 585.973750000004657],
              [2704174.332398464903235, 1254644.738023621961474, 596.823749999995925],
              [2704338.566107156220824, 1254660.316695840097964, 593.592499999998836],
              [2704446.238005314953625, 1254680.28811372234486, 548.698749999995925],
              [2704486.437642951030284, 1254425.748452267609537, 614.716249999997672],
              [2704529.697611678857356, 1254454.678395504364744, 612.347500000003492],
              [2704803.815313211176544, 1255048.050265502650291, 534.102499999993597],
              [2705013.892862020991743, 1255308.314101458759978, 537.557499999995343],
              [2705052.822861928958446, 1255326.944100680062547, 537.517500000001746],
              [2705825.990453653503209, 1255305.016748421825469, 543.662500000005821],
              [2705894.23056048899889, 1254434.383178583811969, 551.517500000001746],
              [2705433.922829500865191, 1254202.416260944679379, 627.647500000006403],
              [2705429.960329113062471, 1254201.878760342020541, 627.622499999997672],
              [2705326.698861543089151, 1253963.2533702054061, 611.548750000001746],
              [2705039.827029934618622, 1253378.969566831598058, 661.517500000001746],
              [2705011.400744399987161, 1253275.057002546265721, 658.902499999996508],
              [2705013.413247812073678, 1253255.404508346691728, 659.153749999997672],
              [2705025.676987031009048, 1253241.629488544538617, 658.338749999995343],
              [2705414.113802191801369, 1253094.607988049043342, 699.932499999995343],
              [2705409.166298323776573, 1253067.601730880094692, 699.649999999994179],
              [2705409.706307836342603, 1253058.421747760847211, 700.35624999999709],
              [2705313.451272498816252, 1252953.814185661962256, 697.833750000005239],
              [2705163.658981242217124, 1252264.888303530868143, 713.503750000003492],
              [2706461.06999458745122, 1251852.505608537932858, 601.720000000001164],
              [2706919.67862437851727, 1251405.575106935109943, 685.047500000000582],
              [2706919.964873693883419, 1251393.592605546116829, 684.996249999996508],
              [2706867.066142980009317, 1250892.756656304001808, 593.774999999994179],
              [2706756.222424541134387, 1250696.314212762285024, 596.216249999997672],
              [2705880.235017808154225, 1250399.47412650147453, 696.803750000006403]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 128, gemeinde_B: 213, name: 'Brütten', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2694557.819947289768606, 1258158.893600514391437, 515.470000000001164],
              [2694556.552445273846388, 1258163.002346902387217, 515.318750000005821],
              [2693961.428270049393177, 1258143.206366630503908, 578.186249999998836],
              [2693917.208290023729205, 1258098.306403647409752, 579.73750000000291],
              [2693734.738264906685799, 1257889.636359495809302, 578.005000000004657],
              [2693086.938455616589636, 1257380.22671733982861, 593.04374999999709],
              [2692763.692288654390723, 1257654.608130281791091, 599.652499999996508],
              [2691956.549603105988353, 1258155.651565455598757, 586.378750000003492],
              [2691499.832229351624846, 1258513.078062115702778, 596.40625],
              [2691527.812227545771748, 1258537.608058428391814, 596.242499999993015],
              [2691691.163757894653827, 1258818.538572408026084, 617.36250000000291],
              [2691636.405893068760633, 1259030.67165670148097, 589.73750000000291],
              [2691634.442456622142345, 1259652.088490007445216, 613.547500000000582],
              [2691469.494742935523391, 1259921.815604759845883, 597.498749999998836],
              [2691464.734756954479963, 1259930.655630737310275, 598.567500000004657],
              [2691462.904743277002126, 1259928.975605635438114, 597.53125],
              [2691891.898948111571372, 1260487.685187643393874, 631.627500000002328],
              [2692077.510116191580892, 1260657.433784668333828, 625.228749999994761],
              [2692461.845473052933812, 1260426.728383013047278, 557.14375000000291],
              [2692579.623356032185256, 1260137.337829705793411, 586.05000000000291],
              [2693056.757769143208861, 1259771.842988599324599, 541.147500000006403],
              [2693895.156528379768133, 1259811.809025750262663, 635.633749999993597],
              [2694787.956104681827128, 1258836.142183719901368, 508.297500000000582],
              [2694784.175541257020086, 1258710.473436441272497, 560.147500000006403],
              [2695097.750081492122263, 1258458.221337416674942, 525.151249999995343],
              [2694679.712972248904407, 1258239.153307426022366, 555.020000000004075],
              [2694557.819947289768606, 1258158.893600514391437, 515.470000000001164]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 136, gemeinde_B: 223, name: 'Neftenbach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2690527.058141259942204, 1264070.420413421466947, 381.755000000004657],
              [2690096.388325784821063, 1264720.236636753659695, 586.233749999999418],
              [2690076.3548100781627, 1264969.537073265528306, 604.080000000001746],
              [2690688.496975308749825, 1265684.709138817619532, 483.0625],
              [2691317.703485266771168, 1266270.73317082086578, 597.207500000004075],
              [2691428.562201751861721, 1266416.219358020927757, 594.558749999996508],
              [2691430.380954291205853, 1266419.748112718341872, 594.75],
              [2690900.376137366052717, 1266754.620967872906476, 609.179999999993015],
              [2690917.700859390664846, 1267788.534403204917908, 493.11250000000291],
              [2691223.117563802748919, 1267867.153988229110837, 527.368749999994179],
              [2691156.461046678945422, 1268163.262248663697392, 507.148749999993015],
              [2691076.177326090168208, 1268136.024804454063997, 509.448749999995925],
              [2691073.862302388530225, 1268212.179761444218457, 507.652499999996508],
              [2691150.588504293467849, 1268275.103421522304416, 503.9375],
              [2691414.953587836585939, 1268135.731069140601903, 510.056249999994179],
              [2691706.463206954766065, 1268391.117861586390063, 480.91874999999709],
              [2691941.970226710196584, 1268597.009281780570745, 539.118749999994179],
              [2692479.751273055560887, 1268646.92889443365857, 523.211249999993015],
              [2692476.873775995336473, 1268644.183899896452203, 523.436249999998836],
              [2693233.661587100476027, 1268225.959647863171995, 451.707500000004075],
              [2693441.919488342478871, 1267566.057877198792994, 481.898749999993015],
              [2693452.966917302459478, 1267372.35899418941699, 476.509999999994761],
              [2694008.630069937091321, 1267604.611635820940137, 430.914999999993597],
              [2694007.9625388327986, 1267436.000327064888552, 428.559999999997672],
              [2694331.360120560973883, 1267190.535469520604238, 434.5],
              [2694312.389930038247257, 1266540.440113681834191, 420.101250000006985],
              [2694809.033854350913316, 1265908.997919684043154, 432.902499999996508],
              [2694831.894940450787544, 1265697.515116435475647, 420.505000000004657],
              [2694825.392510025762022, 1265555.028993032639846, 425.759999999994761],
              [2694822.949991059955209, 1265466.871457478497177, 424.327499999999418],
              [2694821.066247318405658, 1265450.352718862472102, 424.801250000004075],
              [2694817.380003117024899, 1265438.852729469304904, 425.241250000006403],
              [2694406.22622576681897, 1265362.360185758210719, 423.463749999995343],
              [2693506.292303399648517, 1264281.255922460462898, 505.611250000001746],
              [2693091.104622849728912, 1263749.978295775596052, 397.634999999994761],
              [2693045.097274165600538, 1263741.608574635814875, 409.122499999997672],
              [2692752.917104238178581, 1263614.893266003578901, 396.447499999994761],
              [2692709.057119174860418, 1263588.103293973719701, 397.607499999998254],
              [2692082.050801589153707, 1263549.389429595321417, 392.899999999994179],
              [2692011.098296782001853, 1263557.004421960562468, 392.582500000004075],
              [2691509.128308807965368, 1263749.776953923981637, 393.83125000000291],
              [2690733.831961847376078, 1264126.398040825966746, 386.986250000001746],
              [2690635.579477235674858, 1264128.483070842456073, 388.220000000001164],
              [2690553.804390590637922, 1264097.760411980561912, 381.6875],
              [2690527.058141259942204, 1264070.420413421466947, 381.755000000004657]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 139, gemeinde_B: 226, name: 'Schlatt', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2703162.530747169163078, 1260262.109890449792147, 567.992499999993015],
              [2703352.837052452843636, 1260303.482486460125074, 571.941250000003492],
              [2703538.338246290571988, 1260441.046132512623444, 567.588749999995343],
              [2703663.389671720098704, 1260556.181447608629242, 580.548750000001746],
              [2704015.756729064043611, 1260686.660642628092319, 547.258749999993597],
              [2705001.934840149711818, 1259937.316713025793433, 591.817500000004657],
              [2705647.461195618845522, 1259234.37662595207803, 691.892500000001746],
              [2705835.699396200943738, 1259101.003131938399747, 650.877500000002328],
              [2705989.879563168156892, 1258552.670665057841688, 755.923750000001746],
              [2706664.027174535673112, 1258519.757352672051638, 670.657500000001164],
              [2706989.95245299907401, 1257728.476061684312299, 876.306249999994179],
              [2707266.22148274211213, 1257449.980560376308858, 804.009999999994761],
              [2707205.367229536641389, 1257430.863410860998556, 766.787500000005821],
              [2706824.737578366883099, 1257185.266543196281418, 793.111250000001746],
              [2706756.471017095725983, 1256914.414733473677188, 770.102499999993597],
              [2706450.226265638135374, 1256960.298938625026494, 788.917499999995925],
              [2706236.429898464120924, 1257097.561237171525136, 780.48750000000291],
              [2705990.32082710089162, 1257342.605671864002943, 756.931249999994179],
              [2705619.96728626312688, 1257385.04855869919993, 772.913750000006985],
              [2705337.903412760235369, 1257635.212099111406133, 764.082500000004075],
              [2705313.407173912972212, 1257647.404620047891513, 764.941250000003492],
              [2705291.657164746429771, 1257655.719604350393638, 764.286250000004657],
              [2705292.412167535629123, 1257657.544609354343265, 764.492499999993015],
              [2705075.026830841787159, 1257620.107760268263519, 739.717499999998836],
              [2704842.427104179747403, 1257521.12950666854158, 760.318750000005821],
              [2704181.073841207660735, 1257918.618409724207595, 611.241250000006403],
              [2704176.782594455871731, 1257921.723415716085583, 611.48750000000291],
              [2702671.471619930118322, 1257989.030444879783317, 540.238750000004075],
              [2702476.396238348446786, 1258057.749516707845032, 605.233749999999418],
              [2702293.277697644196451, 1258335.19889576244168, 527.712499999994179],
              [2702288.572708137333393, 1258340.861414860701188, 528.5],
              [2702283.30020559579134, 1258350.288910484174266, 528.315000000002328],
              [2703330.869567292742431, 1259234.555001346161589, 573.04374999999709],
              [2703181.507148733828217, 1259619.78741187765263, 672.471250000002328],
              [2703162.530747169163078, 1260262.109890449792147, 567.992499999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 133, gemeinde_B: 219, name: 'Elsau', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2704015.756729064043611, 1260686.660642628092319, 547.258749999993597],
              [2703663.389671720098704, 1260556.181447608629242, 580.548750000001746],
              [2703538.338246290571988, 1260441.046132512623444, 567.588749999995343],
              [2703352.837052452843636, 1260303.482486460125074, 571.941250000003492],
              [2703162.530747169163078, 1260262.109890449792147, 567.992499999993015],
              [2703140.625722252763808, 1260392.203597448766232, 566.157500000001164],
              [2703209.072003440931439, 1260413.577402478549629, 568.421249999999418],
              [2703314.484315111301839, 1260750.448313905159011, 554.292499999995925],
              [2703208.554270214401186, 1260727.051984874298796, 551.038750000006985],
              [2703209.523064827080816, 1260923.823317641858011, 554.368749999994179],
              [2702534.936604382004589, 1261044.31170324399136, 539.266250000000582],
              [2701957.948079124093056, 1261060.194622536888346, 556.557499999995343],
              [2701786.429809959139675, 1261350.241986811161041, 499.244999999995343],
              [2701225.779595567379147, 1261578.128877523588017, 577.152499999996508],
              [2700982.045587044674903, 1262192.176153543638065, 464.537500000005821],
              [2701947.084698587656021, 1262829.951796856243163, 490.828750000000582],
              [2701894.254677945747972, 1262933.531761526595801, 489.33125000000291],
              [2702287.599040696397424, 1262968.880364280659705, 534.770000000004075],
              [2702360.63494881382212, 1263271.093496545450762, 509.190000000002328],
              [2702317.602469676639885, 1263346.049786014482379, 510.785000000003492],
              [2702314.527465135790408, 1263357.786027969326824, 510.448749999995925],
              [2702527.659945650957525, 1263430.268489080946892, 508.820000000006985],
              [2703470.39966951077804, 1263446.758981958730146, 580.628750000003492],
              [2703568.104594166856259, 1263480.475093823857605, 574.923750000001746],
              [2703577.027183558326215, 1263499.222755537834018, 581.577499999999418],
              [2704074.889379493892193, 1263970.339699846692383, 558.482499999998254],
              [2704676.584795275237411, 1263998.719188044779003, 588.877500000002328],
              [2704533.347758498508483, 1263408.715263288002461, 530.452499999999418],
              [2704576.700834019575268, 1263225.226299677509815, 573.235000000000582],
              [2704678.601972284726799, 1263174.557345051784068, 564.827499999999418],
              [2704738.543484872207046, 1262459.168053943896666, 491.303750000006403],
              [2704865.184459008276463, 1262118.41730487300083, 563.647500000006403],
              [2704697.180823272559792, 1262061.96876408229582, 572.298750000001746],
              [2704328.806400670204312, 1261818.10505583952181, 522.565000000002328],
              [2704353.415065692272037, 1261255.780900658108294, 609.248749999998836],
              [2703994.158773869741708, 1261224.023577626794577, 513.389999999999418],
              [2704015.756729064043611, 1260686.660642628092319, 547.258749999993597]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 140, gemeinde_B: 227, name: 'Seuzach', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2695032.182522519025952, 1265470.521511604776606, 426.554999999993015],
              [2695180.280033880379051, 1265684.640281750354916, 427.3125],
              [2695178.058766598813236, 1265957.827752493089065, 426.016250000000582],
              [2695748.937579139135778, 1266175.897859142627567, 430.333750000005239],
              [2695750.845066966488957, 1266206.657837047241628, 429.414999999993597],
              [2696340.749026362784207, 1266329.935711536323652, 444.773749999993015],
              [2696423.611432439647615, 1266530.256789724109694, 437.642500000001746],
              [2696719.191529881209135, 1266665.903214196208864, 444.76875000000291],
              [2697099.187490360811353, 1267078.222470424603671, 516.776249999995343],
              [2697188.2810949976556, 1267128.283452857751399, 505.764999999999418],
              [2697976.499211258720607, 1266794.869774281512946, 457.48124999999709],
              [2698133.486863510683179, 1266803.341299831401557, 468.802500000005239],
              [2698286.239267559722066, 1266905.794872486498207, 461.479999999995925],
              [2698558.070593333803117, 1267174.096258447272703, 466.970000000001164],
              [2698853.487174877896905, 1267215.915609003044665, 491.627500000002328],
              [2698793.980691266711801, 1267278.971433938946575, 474.142500000001746],
              [2699100.888387808576226, 1267195.559286183677614, 488.647500000006403],
              [2698936.055210937280208, 1266790.595332055352628, 531.778749999997672],
              [2698323.222829570062459, 1265392.587014141492546, 447.297500000000582],
              [2698180.37156573869288, 1265400.448241558391601, 446.363750000004075],
              [2697689.766737465746701, 1264848.717309127096087, 459.622499999997672],
              [2697687.696736612357199, 1264850.384807621128857, 459.559999999997672],
              [2697339.172636459115893, 1264190.99395192344673, 527.498749999998836],
              [2696451.223043267149478, 1264377.637429941445589, 464.752500000002328],
              [2695409.392836114391685, 1264465.087070831563324, 449.923750000001746],
              [2695188.309230261482298, 1264211.799836770398542, 460.961249999993015],
              [2695170.710470302030444, 1264193.49981867778115, 460.222500000003492],
              [2694959.521382498089224, 1264358.051704701269045, 434.883749999993597],
              [2694985.160125325899571, 1264404.320441512856632, 434.32499999999709],
              [2694893.711408644448966, 1264390.93175413669087, 436.90625],
              [2695055.095018269028515, 1265453.486503248102963, 426.217499999998836],
              [2695032.182522519025952, 1265470.521511604776606, 426.554999999993015]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 148, gemeinde_B: 245, name: 'Oberengstringen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2677259.441564602311701, 1252507.714593845419586, 558.857499999998254],
              [2677430.536592460703105, 1252522.497142119333148, 560.858749999999418],
              [2677781.718729607295245, 1252441.086456100689247, 532.395000000004075],
              [2677811.369984495919198, 1252446.09021462360397, 532.747499999997672],
              [2677868.229973988607526, 1252461.507693940773606, 531.882500000006985],
              [2677960.156429763883352, 1252642.344327806727961, 547.753750000003492],
              [2678218.562451258301735, 1252634.432645788416266, 529.8125],
              [2678346.255078002810478, 1252668.015379705699161, 539.517500000001746],
              [2678366.115081989206374, 1252641.29788640444167, 539.807499999995343],
              [2677950.334676380269229, 1252381.164636159781367, 508.740000000005239],
              [2677802.669757917523384, 1252367.863541230559349, 515.1875],
              [2677686.637641202658415, 1251901.071922017028555, 448.072499999994761],
              [2678348.303213233593851, 1251676.736723368288949, 491.872499999997672],
              [2678466.026932918000966, 1250858.869329300243407, 392.602499999993597],
              [2678441.089433184359223, 1250859.970580216031522, 392.639999999999418],
              [2677678.781909178942442, 1250751.23804720188491, 391.291249999994761],
              [2677662.304409241769463, 1250753.664297614013776, 391.307499999995343],
              [2676477.015623577870429, 1251369.823005663463846, 389.351250000006985],
              [2676460.746874092146754, 1251381.930506995180622, 389.402499999996508],
              [2677259.441564602311701, 1252507.714593845419586, 558.857499999998254]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 150, gemeinde_B: 247, name: 'Schlieren', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2674561.11574286269024, 1249946.411998921073973, 399.904999999998836],
              [2674561.214495651423931, 1249954.563254208769649, 400.122499999997672],
              [2674962.321821077726781, 1250446.785424876958132, 386.26249999999709],
              [2675125.201826354023069, 1250632.754183588549495, 386.568750000005821],
              [2675821.161851635202765, 1251366.249225419480354, 388.086249999993015],
              [2675848.721886538434774, 1251333.238039966439828, 390.78125],
              [2675923.569373955018818, 1251287.078014821745455, 389.751250000001164],
              [2676361.236896816175431, 1251320.513050594832748, 391.233749999999418],
              [2676460.746874092146754, 1251381.930506995180622, 389.402499999996508],
              [2676477.015623577870429, 1251369.823005663463846, 389.351250000006985],
              [2677662.304409241769463, 1250753.664297614013776, 391.307499999995343],
              [2677678.781909178942442, 1250751.23804720188491, 391.291249999994761],
              [2677983.605742821004242, 1249794.408190016169101, 397.546249999999418],
              [2678009.994479937944561, 1249741.858165153302252, 396.528749999997672],
              [2677513.155937967821956, 1249850.821061899885535, 413.005000000004657],
              [2677477.584586850833148, 1249868.153374282410368, 405.1875],
              [2677663.8450026079081, 1249377.937891004839912, 437.285000000003492],
              [2677636.706245636101812, 1249389.165378622943535, 436.764999999999418],
              [2676325.234398704953492, 1248209.006439129821956, 585.598750000004657],
              [2676224.701864533592016, 1248202.557627740548924, 583.041249999994761],
              [2674561.11574286269024, 1249946.411998921073973, 399.904999999998836]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { cat: 154, gemeinde_B: 251, name: 'Weiningen', kanton_KUE: 'ZH', kanton_NAM: 'Zürich' },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2673296.229236951563507, 1251535.226553147193044, 380.837499999994179],
              [2673643.508048522751778, 1251506.367912829620764, 385.411250000004657],
              [2673885.723037103191018, 1251625.94538839859888, 384.361250000001746],
              [2673613.890545286238194, 1251896.759160406654701, 385.1875],
              [2673564.080530924722552, 1252049.859135498525575, 384.103749999994761],
              [2673994.710829451680183, 1252388.573441507527605, 407.10624999999709],
              [2674163.023078372702003, 1253084.233916767640039, 582.352499999993597],
              [2673782.859435293357819, 1253662.088034339947626, 493.557499999995343],
              [2673774.165687156375498, 1253664.139288041042164, 493.710000000006403],
              [2673775.439438129309565, 1253669.739289899822325, 493.785000000003492],
              [2673787.288183738943189, 1253664.31178135285154, 493.432499999995343],
              [2673890.60477091697976, 1254172.428518034517765, 617.164999999993597],
              [2674087.64869825495407, 1254089.33884527022019, 630.788750000006985],
              [2674313.7383206570521, 1253983.781878859037533, 601.11250000000291],
              [2674737.961009728722274, 1253845.744721622904763, 615.411250000004657],
              [2675782.954308866988868, 1253600.883847727207467, 579.266250000000582],
              [2675859.826852086465806, 1253551.955176287563518, 582.551250000004075],
              [2675889.170577607117593, 1253538.056379568297416, 580.618749999994179],
              [2676365.405020373407751, 1253212.59282176848501, 536.850000000005821],
              [2676558.359665834810585, 1252798.488237489014864, 606.225000000005821],
              [2676541.973413893487304, 1252761.850733816158026, 606.089999999996508],
              [2675984.365983794908971, 1252158.716194117441773, 417.70625000000291],
              [2675924.920956024434417, 1252063.813642386812717, 415.587499999994179],
              [2675546.408562061842531, 1251957.352596559328958, 424.104999999995925],
              [2675223.289562480989844, 1251766.245883314637467, 404.908750000002328],
              [2674964.34849096275866, 1251526.647469979012385, 418.982499999998254],
              [2673255.360497065354139, 1251141.414069639518857, 381.645000000004075],
              [2673261.12174778431654, 1251151.441570974886417, 381.697499999994761],
              [2673306.897985263727605, 1251266.86404765676707, 380.692500000004657],
              [2673296.229236951563507, 1251535.226553147193044, 380.837499999994179]
            ]
          ]
        }
      }
    ]
  };
}
