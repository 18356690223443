import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import UICheckbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    color: 'var(--primary-dark-color)'
  },
  label: {
    fontSize: '1.2rem'
  }
});

const useCheckboxStyles = makeStyles({
  root: {
    padding: '0 9px'
  },
  colorSecondary: {
    color: 'var(--primary-dark-color)',
    '&&.Mui-checked': {
      color: 'var(--primary-dark-color)'
    },
    '&&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)'
    }
  }
});

const Checkbox = ({ label, checked, onChange, dataTest, className, ...props }) => {
  const classes = useStyles();
  const checkboxClasses = useCheckboxStyles();
  return (
    <FormControlLabel
      classes={classes}
      control={
        <div className={className}>
          <UICheckbox classes={checkboxClasses} checked={!!checked} onChange={onChange} {...props} />
        </div>
      }
      label={label}
      data-test={dataTest}
    />
  );
};

export default Checkbox;
