import { Textfit } from 'react-textfit';
import classnames from 'classnames';
import styles from './number-chart.scss';

import useMediaQuery from 'utils/mediaQueries';

const SimpleNumberChart = ({ isCentered, chart }) => {
  const isMobile = useMediaQuery();

  const chartData = JSON.parse(chart);

  const verticalCenterClass = classnames({
    verticalCenter: isCentered
  });

  const verticalCenterCorrectionClass = classnames({
    verticalCenterCorrection: isCentered
  });

  const descriptionClass = classnames(styles.descriptionSimpleNumber, {
    verticalCenterCorrection: isMobile
  });

  return (
    <Textfit mode="single" className={verticalCenterClass} max={isMobile ? 34 : 48} key={Math.random()}>
      <div style={isCentered ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : {}}>
        <div className={verticalCenterCorrectionClass}>{chartData.number}</div>
        {chartData.description && <div className={descriptionClass}>{chartData.description}</div>}
      </div>
    </Textfit>
  );
};

export default SimpleNumberChart;
