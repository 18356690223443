import React, { memo, useContext } from 'react';

import styles from './filter-pill.scss';
import classnames from 'classnames';
import { removeFilterFromAnswer } from '../../store/modules/chat-messages';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Q2QStateContext } from './Q2QStateContext';

const FilterPill = ({
  children,
  isGreyedOut,
  isHighlighted,
  isRemoved,
  selected,
  removeInfo,
  username,
  t,
  dispatch,
  text
}) => {
  const q2qStateContext = useContext(Q2QStateContext);

  const classes = classnames({
    [styles.greyedOutItem]: isGreyedOut,
    [styles.highlightedItem]: isHighlighted,
    [styles.removedItem]: isRemoved,
    [styles.item]: !isHighlighted && !isGreyedOut && !isRemoved,
    [styles.selected]: selected,
    [styles.withRemoveButton]: !!removeInfo
  });

  return (
    <div className={classes} data-testclass="filterPill">
      {children}
      {removeInfo && (
        <span
          className={styles.removeButton}
          data-test="removeFilterPillButton"
          onClick={() => {
            const message = t('modifications.remove-filter') + ': "' + text + '"';
            dispatch(removeFilterFromAnswer(username, message, q2qStateContext.answerData, removeInfo, t));
          }}
        >
          ✕
        </span>
      )}
    </div>
  );
};

const mapStateToProps = state => ({ username: state.user.username });
export default memo(withTranslation('veezoo')(connect(mapStateToProps)(FilterPill)));
