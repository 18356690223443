import { connect } from 'react-redux';
import PassageSpan from './PassageSpan';
import React, { useCallback, useState } from 'react';
import { modifyAnswer } from 'store/modules/chat-messages';

import TemplateFilter from 'components/v2/TemplateFilter/TemplateFilter';

import Popover from '@material-ui/core/Popover';

import styles from './filter-pill-container.scss';
import useStyles from 'styles/JSS/Popover';
import { answerModificationTypes, templateModificationTypes } from 'config/constants';

const Q2QEditableTemplatePassage = ({ answerData, username, passages, editInfo, dispatch }) => {
  const answerId = answerData.answerId;

  const getInterpretationId = () => {
    if (answerData.interpretationId) {
      // single-answer case
      return answerData.interpretationId;
    } else {
      // multi-answer case (e.g., boards): find a suitable interpretation for which we can query the
      // available filter templates together with the computationId on the editInfo
      const pairs = answerData.structuredFiltersMap.find(pairs => pairs.id === editInfo.computationId);
      return pairs.candidateInterpretationPairs.find(pair => pair.candidateId === editInfo.computationId)
        .interpretationId;
    }
  };

  // anchor to display filter edit popup (hidden if null)
  const [anchor, setAnchor] = useState(null);

  const classes = useStyles();

  const handleSpanClick = event => {
    setAnchor(event.currentTarget);
  };

  /**
   * Merges adjacent passages whose candidate IDs are part of the same edit info.
   *
   * This improves the visual result for adjacent editable passages which are otherwise displayed
   * as two separate editable links.
   *
   * @param editableCandidateIds candidate identifiers of the considered edit info
   * @param passages passages to merge
   *
   * @return the merged passages
   */
  const mergeAdjacentEditablePassages = (editableCandidateIds, passages) => {
    let mergedPassages = [];
    let currentlyMerged = [];
    const pushMerged = () => {
      const lastPassage = currentlyMerged[currentlyMerged.length - 1];
      const lastPassageIsWhitespace = lastPassage.type === 'TEXT' && lastPassage.text === ' ';
      if (lastPassageIsWhitespace) {
        // do not merge trailing whitespace
        currentlyMerged.pop();
      }
      mergedPassages.push({
        isMerged: true,
        passages: currentlyMerged
      });
      currentlyMerged = [];
      if (lastPassageIsWhitespace) {
        // put trailing whitespace separately
        mergedPassages.push({
          isMerged: false,
          passage: lastPassage
        });
      }
    };
    passages.forEach(passage => {
      if (editableCandidateIds.find(id => id === passage.id)) {
        // remember to merge this passage with any following editable passages
        currentlyMerged.push(passage);
      } else if (passage.type === 'TEXT' && passage.text === ' ' && currentlyMerged.length > 0) {
        // merge whitespace between editable passages
        currentlyMerged.push(passage);
      } else {
        if (currentlyMerged.length > 0) {
          pushMerged();
        }
        // put a non-merged passage
        mergedPassages.push({
          isMerged: false,
          passage
        });
      }
    });
    if (currentlyMerged.length > 0) {
      // there may be outstandnig merged candidates
      pushMerged();
    }
    return mergedPassages;
  };

  // TODO: underscore highlighting may need to be merged with https://veezoo.atlassian.net/browse/VZN-7654
  const filterPassages = mergeAdjacentEditablePassages(editInfo.appliesToCandidateIds, passages).map(
    (mergedPassage, index) => {
      if (mergedPassage.isMerged) {
        let passages = mergedPassage.passages.map((passage, innerIndex) => {
          return (
            <PassageSpan
              passage={passage}
              key={`template-sub-passage-${index}-${innerIndex}`}
              text={passage.text}
              onClick={handleSpanClick}
            />
          );
        });
        return (
          // make the whole span editable
          <span
            key={`template-passage-${index}`}
            className={styles.passageTerm}
            data-testclass="editableFilterPillSpan"
          >
            {passages}
          </span>
        );
      } else {
        // non-merged passage
        return (
          <PassageSpan
            passage={mergedPassage.passage}
            key={`template-passage-${index}`}
            text={mergedPassage.passage.text}
          />
        );
      }
    }
  );

  const resetOptions = () => setAnchor(null);

  const onClose = () => resetOptions();

  const onConfirm = useCallback(
    async (data, message) => {
      const payload = {
        username,
        answerData,
        message,
        id: editInfo.computationId,
        type: templateModificationTypes.edit.value,
        values: data,
        modificationType: answerModificationTypes.template.value
      };
      await dispatch(modifyAnswer(payload));
      resetOptions();
    },
    [dispatch, answerData, editInfo]
  );

  return (
    <>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        classes={{ paper: classes.paper }}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <TemplateFilter
          onConfirm={onConfirm}
          onCancel={onClose}
          answerId={answerId}
          interpretationId={getInterpretationId()}
          candidateId={editInfo.computationId}
        />
      </Popover>
      {filterPassages}
    </>
  );
};

const mapStateToProps = state => ({ username: state.user.username });

export default connect(mapStateToProps)(Q2QEditableTemplatePassage);
