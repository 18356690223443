import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Input from 'components/Signup/Input';

import styles from './index.module.scss';

const PasswordInput = forwardRef(({ type, ...rest }, ref) => {
  const [show, setShow] = useState(false);
  const Icon = () =>
    show ? (
      <VisibilityOffIcon fontSize="large" color="action" className={styles.icon} onClick={() => setShow(!show)} />
    ) : (
      <VisibilityIcon fontSize="large" color="action" className={styles.icon} onClick={() => setShow(!show)} />
    );

  return <Input ref={ref} label="Password" type={show ? `text` : `password`} endIcon={<Icon />} {...rest} />;
});

PasswordInput.propTypes = {
  type: PropTypes.string
};

export default PasswordInput;
