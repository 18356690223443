import { defaultChartColors } from 'config/constants';

export function initializeHighchartTheme(Highcharts) {
  // extend a chart
  Highcharts.chart = {
    exporting: {
      sourceWidth: 1200,
      sourceHeight: 800,
      libURL: './public/plugins/js/highcharts/',
      buttons: {
        contextButton: {
          symbol: 'menu',
          symbolStrokeWidth: 2,
          x: -42,
          y: 0
        }
      },
      chartOptions: {
        series: {
          dataLabels: {
            enabled: true
          }
        }
      },
      pdfFont: {
        normal: './public/plugins/js/highcharts/NotoSans-Regular.ttf',
        bold: './public/plugins/js/highcharts/NotoSans-Bold.ttf',
        bolditalic: './public/plugins/js/highcharts/NotoSans-BoldItalic.ttf',
        italic: './public/plugins/js/highcharts/NotoSans-Italic.ttf'
      },
      fallbackToExportServer: false,
      error: function() {
        console.log('There was an error while exporting PDF.');
      }
    }
  };
  // apply options
  Highcharts.setOptions(Highcharts.chart);
  // extend a theme
  Highcharts.theme = {
    colors: defaultChartColors,
    chart: {
      style: {
        fontFamily: "'Lexend', sans-serif",
        fontSize: '15px',
        height: '100%' // we need this s.t. the charts expand dynamically within the chat board
      },
      zooming: {
        mouseWheel: {
          enabled: false
        }
      },
      plotBorderColor: '#606063'
    },
    title: {
      style: {
        color: '#333',
        fontSize: '20px',
        fontWeight: 'normal'
      },
      text: null
    },
    subtitle: {
      style: {
        color: '#8798a4',
        fontWeight: 'normal'
      },
      text: null
    },
    xAxis: {
      labels: {
        // Rotate the labels to vertical on the x-axis if the alternative would be to start hiding some
        autoRotation: [0, -45, -90]
      }
    },
    credits: {
      style: {
        color: '#666'
      }
    },
    labels: {
      style: {
        color: '#707073'
      }
    },
    plotOptions: {
      scatter: {
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.point.name; // every scatter has a name that we supply next to the x and the y
          }
        }
      },
      pie: {
        dataLabels: {
          format: '<b>{point.name}</b>: {point.percentage:.2f}%'
        }
      },
      column: {
        dataLabels: {
          enabled: true
        }
      },
      bar: {
        dataLabels: {
          enabled: true
        }
      },
      series: {
        // we need to have this object to make the toggle of labels (sometiems) work again
        // I'm aware this is highly suboptimal code - and doesn't work completly
        // removing this introduced the regression: VZN-6934
        dataLabels: {
          style: {
            fontWeight: 'normal'
          }
        },
        label: {
          style: {
            fontWeight: 'normal'
          }
        }
      }
    },
    drilldown: {
      activeAxisLabelStyle: {
        color: '#666',
        fontWeight: 'normal'
      },
      activeDataLabelStyle: {
        color: '#666',
        fontWeight: 'normal'
      }
    },
    // scroll charts
    rangeSelector: {
      buttonTheme: {
        fill: '#505053',
        stroke: '#000000',
        style: {
          color: '#CCC'
        },
        states: {
          hover: {
            fill: '#707073',
            stroke: '#000000',
            style: {
              color: 'white'
            }
          },
          select: {
            fill: '#000003',
            stroke: '#000000',
            style: {
              color: 'white'
            }
          }
        }
      },
      inputBoxBorderColor: '#505053',
      inputStyle: {
        backgroundColor: '#333',
        color: 'silver'
      },
      labelStyle: {
        color: 'silver'
      }
    },
    navigator: {
      handles: {
        backgroundColor: '#666',
        borderColor: '#AAA'
      },
      outlineColor: '#CCC',
      maskFill: 'rgba(255,255,255,0.1)',
      series: {
        color: '#7798BF',
        lineColor: '#A6C7ED'
      },
      xAxis: {
        gridLineColor: '#505053'
      }
    },
    // special colors for some of the
    legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    background2: '#505053',
    dataLabelsColor: '#222222',
    textColor: '#C0C0C0',
    contrastTextColor: '#F0F0F3',
    maskColor: 'rgba(255,255,255,0.3)'
  };
  // apply the theme
  Highcharts.setOptions(Highcharts.theme);
}
