import styles from './styles.scss';

export const LinkedSpan = ({ children, contentState, entityKey, offsetKey }) => {
  const description = contentState.getEntity(entityKey).getData().description;
  return (
    <span data-offset-key={offsetKey} className={styles.linked}>
      {typeof description === 'undefined' ? <span>{children}</span> : <span title={description}>{children}</span>}
    </span>
  );
};
