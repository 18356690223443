import { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'root/i18n';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import 'url-search-params-polyfill';

import { withSnackbar } from 'notistack';

import Loading from 'components/loaders/infinity-loading/Loading';
import Home from 'components/home/Home';
import LegacyShareModal from 'components/modals/ShareModal/ShareModal';
import { BoardShareModal } from 'components/v3/connectedComponents';
import UpdateLinkedBoardModal from 'components/v3/Modals/UpdateLinkedBoardModal/UpdateLinkedBoardModal';
import { chatUrlEntryPointTypes, possibleStates } from 'config/constants';
import { CustomRoute, RedirectToLogin, RedirectWithParams } from './CustomRoutes';

import Login from 'pages/Login';
import Signup from 'pages/Signup';
import ForgotPassword from 'pages/ForgotPassword';
import RedefinePassword from 'pages/RedefinePassword';
import UserRedirected from 'pages/UserRedirected';

import { messageTypes } from 'store/modules/chat-messages';
import { fetchWidget } from 'store/modules/board-widgets';
import { fetchBoardsList, fetchBoardsView, stopEditBoard } from 'store/modules/board';
import { getCustomerSelections } from 'store/modules/customers-of-the-day';
import { fetchExamples } from 'store/modules/discovery';
import { fetchAllLanguages } from 'store/modules/languages';
import { fetchBadNameProblems } from 'store/modules/recipes';
import { fetchGraph, fetchGraphLayout } from 'store/modules/graph/graph';
import { checkAuthStatus, fetchEnvironment } from 'store/modules/network';
import { fetchLanguages, fetchUserInformation } from 'store/modules/user';
import { fetchKnowledgeGraphs, setCurrentKnowledgeGraph } from 'store/modules/knowledgeGraph';
import { fetchKnowledgeGraphMeta } from 'store/modules/graph/knowledgeGraphMeta';
import { fetchPills } from 'store/modules/pills';

import services from 'services';

import { veezooRoutes } from './routes';
import { constructUrlGivenCurrentQueryParams } from 'components/LinkWithQuery';
import withEmbedded from 'root/hocs/withEmbedded';

const UnitTestComponent = ({ hasFetchedUser, isFetchingUser, isSuperUser, isMobile, enqueueSnackbar }) => {
  if (!hasFetchedUser && isFetchingUser) return <Loading />;
  if (isSuperUser) return <Home isMobile={isMobile} enqueueSnackbar={enqueueSnackbar} showTests />;
  return <RedirectToLogin />;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // init is complete once we start fetching userInformation and authStatus
      initComplete: false,
      trackingSetupDone: false
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchEnvironment());
  }

  updateKGParams = id => {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('id', id);

    const newLocation = location.pathname + '?' + params.toString() + location.hash;
    history.replace(newLocation);
  };

  handleKnowledgeGraphChange = async (kg, language) => {
    const { dispatch, enqueueSnackbar } = this.props;
    const result = await services.switchToKnowledgeGraph(kg.id);
    if (result.success) {
      dispatch(fetchKnowledgeGraphMeta());
      enqueueSnackbar(`Knowledge Graph changed to: ${kg.info[language]?.name || kg.info.default.name}`, {
        variant: 'info',
        autoHideDuration: 1500,
        ...(this.props.isMobile ? { action: <></> } : {}) // Necessary to remove the "x" close button from the snackbar.
      });
    }
  };

  updateWidgetsInBoard = async () => {
    const board = this.props.chatMessages.find(item => item.type === messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE);

    if (board) {
      const widgets = this.props.widgets.filter(item => item.boardId === board.boardId);
      widgets.forEach(widget => this.props.dispatch(fetchWidget(widget.id, board.boardId)));
    }
  };

  componentDidUpdate(prevProps) {
    const {
      language,
      isAuthorized,
      knowledgeGraphs,
      knowledgeGraphMeta,
      location,
      currentKnowledgeGraph,
      dispatch,
      hasUnfinishedSignup,
      isFetchingLanguages,
      hasFetchedLanguages,
      isFetchingRecipes,
      hasFetchedRecipes,
      isSuperUser,
      history
    } = this.props;

    const isNotSignUp = location.pathname !== veezooRoutes.signup && location.pathname !== veezooRoutes.exasolSignup;
    const meta = knowledgeGraphMeta.meta;

    if (isAuthorized && !isFetchingLanguages && !hasFetchedLanguages) {
      dispatch(fetchAllLanguages());
    }

    if (isAuthorized && !knowledgeGraphs.isFetching && !knowledgeGraphs.data) {
      dispatch(fetchKnowledgeGraphs());
    }

    if (isNotSignUp) {
      // initial fetch of knowledge graph meta information
      if (isAuthorized && !knowledgeGraphMeta.isFetching && !knowledgeGraphMeta.isFetched && !meta?.id) {
        dispatch(fetchKnowledgeGraphMeta());
      }

      if (
        isAuthorized &&
        isSuperUser &&
        knowledgeGraphMeta.isFetched &&
        meta.hasWritePermission &&
        !isFetchingRecipes &&
        !hasFetchedRecipes &&
        language
      ) {
        dispatch(fetchBadNameProblems(language));
      }

      // the knowledge graph from the URL
      const urlKgId = new URLSearchParams(location.search).get('id');
      const urlKg = knowledgeGraphs.data?.find(kg => kg.id === urlKgId);

      // if NO KG id parameter set in the URL and still no current KG, take the one from meta (when available)
      if (!currentKnowledgeGraph && !urlKg && knowledgeGraphs.data?.length && meta?.id) {
        const kg = knowledgeGraphs.data.find(item => item.id === meta.id);
        if (kg) {
          this.updateKGParams(kg.id);
          dispatch(setCurrentKnowledgeGraph(kg));
        }
      } else if (
        // else if KG id parameter set in the URL, take it as the current one
        urlKg &&
        (!currentKnowledgeGraph || urlKg.id !== currentKnowledgeGraph.id)
      ) {
        dispatch(setCurrentKnowledgeGraph(urlKg));
        this.handleKnowledgeGraphChange(urlKg, language);
      }

      // When changing Knowledge Graphs or the language, we reset all Veezoo's pages.
      const prevMeta = prevProps.knowledgeGraphMeta.meta;
      // make sure we only check changes not the first time it gets loaded (= undefined)
      const didKnowledgeGraphChange = prevMeta.id !== undefined && prevMeta.id !== meta.id;
      const didLanguageChange =
        prevProps.language !== undefined && prevProps.language !== '' && prevProps.language !== language;

      // Loading language for the first time in the frontend
      if (!prevProps.language && language) {
        i18n.changeLanguage(language);
      }
      if (didKnowledgeGraphChange || didLanguageChange) {
        dispatch(fetchPills(true));
        dispatch(fetchBoardsList());
        dispatch(fetchBoardsView());
        dispatch(getCustomerSelections());
        dispatch(stopEditBoard());
        dispatch(fetchLanguages());
        dispatch(fetchExamples());
        dispatch(fetchGraph());
        dispatch(fetchGraphLayout());
        dispatch(fetchUserInformation());
        dispatch(fetchKnowledgeGraphMeta());

        // The language could still be empty here (this happens if the knowledge graph is changed before the language
        //  is loaded, e.g. if the KG is changed via the URL parameter)
        if (language) {
          i18n.changeLanguage(language);

          if (isSuperUser && meta.hasWritePermission) {
            dispatch(fetchBadNameProblems(language));
          }
        }
      }
    }

    // we just finished resetting the session
    if (!this.state.initComplete) {
      dispatch(checkAuthStatus());
      dispatch(fetchUserInformation());
      this.setState({ initComplete: true });
    }

    if (
      isAuthorized &&
      hasUnfinishedSignup &&
      location.pathname !== veezooRoutes.signup &&
      location.pathname !== veezooRoutes.exasolSignup &&
      location.pathname !== veezooRoutes.userRedirected
    ) {
      history.push(veezooRoutes.signup);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // when the user is not authenticated anymore, reload the page in order to:
    //   - completely reset the application state and start anew
    //   - discard all requests that might still be executing
    if (this.props.isAuthorized && !nextProps.isAuthorized) {
      if (nextProps.redirectToSignup) {
        this.props.history.push(veezooRoutes.signup);
      }
      window.location.reload();
    }

    if (nextProps.isCloud && !nextProps.isAuthorized && !nextState.trackingSetupDone) {
      this.setState({
        trackingSetupDone: true
      });
    }

    // check if the 'authRedirect' parameter is present
    let params = new URLSearchParams(this.props.location.search);
    if (params.get('authRedirect')) {
      // remove it as we don't want to expose it to the user
      params.delete('authRedirect');

      // replace the current entry on the history stack with the new location
      let location = this.props.location.pathname;
      let search = params.toString();
      if (search) {
        location = location + '?' + search;
      }
      this.props.history.replace(location);
    }
  }

  render() {
    const {
      isAuthorized,
      isFetchingAuthentication,
      isFetchingTheme,
      hasFetchedUser,
      isSuperUser,
      isAdmin,
      isFetchingUser
    } = this.props;

    if (!this.state.initComplete || isFetchingAuthentication || isFetchingTheme || (isAuthorized && !hasFetchedUser))
      return <Loading />;

    return (
      <>
        <Switch>
          <CustomRoute
            exact
            path="/"
            requiresAuthorization
            allowsMobileFormat
            isAuthorized={isAuthorized}
            render={() => <RedirectWithParams to={veezooRoutes.chat} />}
          />
          <CustomRoute
            path={veezooRoutes.ask}
            requiresAuthorization
            isAuthorized={isAuthorized}
            allowsMobileFormat
            render={urlParserMatch => (
              <Home
                showChat
                isMobile={this.props.isMobile}
                enqueueSnackbar={this.props.enqueueSnackbar}
                chatEntryPoint={{
                  type: chatUrlEntryPointTypes.ask,
                  question: urlParserMatch.match.params.question
                }}
              />
            )}
          />
          <CustomRoute
            path={veezooRoutes.shared}
            requiresAuthorization
            allowsMobileFormat
            isAuthorized={isAuthorized}
            render={urlParserMatch => (
              <Home
                showChat
                isMobile={this.props.isMobile}
                enqueueSnackbar={this.props.enqueueSnackbar}
                chatEntryPoint={{
                  type: chatUrlEntryPointTypes.sharing,
                  sharedAnswerId: urlParserMatch.match.params.sharedAnswerId
                }}
              />
            )}
          />
          <CustomRoute
            path={veezooRoutes.board}
            requiresAuthorization
            allowsMobileFormat
            isAuthorized={isAuthorized}
            render={urlParserMatch => (
              <Home
                showChat
                isMobile={this.props.isMobile}
                enqueueSnackbar={this.props.enqueueSnackbar}
                chatEntryPoint={{
                  type: chatUrlEntryPointTypes.board,
                  boardId: urlParserMatch.match.params.boardId
                }}
              />
            )}
          />
          <CustomRoute
            path={veezooRoutes.customersOfTheDay}
            requiresAuthorization
            allowsMobileFormat
            isAuthorized={isAuthorized}
            render={() => (
              <Home
                showChat
                isMobile={this.props.isMobile}
                enqueueSnackbar={this.props.enqueueSnackbar}
                chatEntryPoint={{
                  type: chatUrlEntryPointTypes.customersOfTheDay
                }}
              />
            )}
          />
          <CustomRoute
            path={veezooRoutes.chat}
            requiresAuthorization
            isAuthorized={isAuthorized}
            allowsMobileFormat
            render={() => <Home isMobile={this.props.isMobile} enqueueSnackbar={this.props.enqueueSnackbar} showChat />}
          />
          <Redirect
            from={veezooRoutes.knowledgeGraph}
            to={constructUrlGivenCurrentQueryParams(
              veezooRoutes.chat,
              this.props.location.search,
              this.props.isEmbedded ? { showDialogue: possibleStates.knowledgeGraph } : { kgSidebar: true }
            )}
          />
          <CustomRoute
            path={veezooRoutes.discovery + '/:selectedTopic?'}
            requiresAuthorization
            isAuthorized={isAuthorized}
            allowsMobileFormat
            render={urlParserMatch => (
              <Home
                showDiscovery
                isMobile={this.props.isMobile}
                enqueueSnackbar={this.props.enqueueSnackbar}
                selectedDiscoveryTopic={urlParserMatch.match.params.selectedTopic || -1}
              />
            )}
          />
          <CustomRoute
            path={veezooRoutes.tests}
            requiresAuthorization
            isAuthorized={isAuthorized}
            allowsMobileFormat={isAdmin}
            render={() => (
              <UnitTestComponent
                hasFetchedUser={hasFetchedUser}
                isFetchingUser={isFetchingUser}
                isSuperUser={isSuperUser}
                isMobile={this.props.isMobile}
                enqueueSnackbar={this.props.enqueueSnackbar}
              />
            )}
          />
          <CustomRoute
            path={veezooRoutes.settings}
            requiresAuthorization
            isAuthorized={isAuthorized}
            allowsMobileFormat
            render={() => (
              <Home isMobile={this.props.isMobile} enqueueSnackbar={this.props.enqueueSnackbar} showSettings />
            )}
          />
          <CustomRoute
            path={veezooRoutes.login}
            isLoginScreen
            isAuthorized={isAuthorized}
            allowsMobileFormat
            render={() => (
              <Login
                dispatch={this.props.dispatch}
                isLoginUnsuccessful={this.props.isLoginUnsuccessful}
                isAccountBlocked={this.props.isAccountBlocked}
              />
            )}
          />
          <CustomRoute
            path={veezooRoutes.passwordRedefine}
            allowsMobileFormat
            render={urlParserMatch => <RedefinePassword token={urlParserMatch.match.params.token} />}
          />
          <CustomRoute path={veezooRoutes.forgotPassword} allowsMobileFormat render={() => <ForgotPassword />} />
          <CustomRoute path={veezooRoutes.signup} allowsMobileFormat render={() => <Signup />} />
          <CustomRoute path={veezooRoutes.exasolSignup} allowsMobileFormat render={() => <Signup />} />
          <CustomRoute path={veezooRoutes.invitation} allowsMobileFormat render={() => <Signup />} />
          <CustomRoute path={veezooRoutes.userRedirected} allowsMobileFormat render={() => <UserRedirected />} />

          <Redirect to="/" />
        </Switch>
        {<LegacyShareModal open={this.props.displaySharedCustomerSelectionModal} />}
        {<BoardShareModal open={this.props.displaySharedBoardModal} />}
        <UpdateLinkedBoardModal />
      </>
    );
  }
}

const mapStateToProps = state => ({
  isSingleSignOn: state.user.isSingleSignOn,
  isSuperUser: state.user.isSuperUser,
  isAdmin: state.user.isAdmin,
  isFetchingAuthentication: state.network.isFetchingAuthentication,
  hasFetchedUser: state.user.hasFetchedUser,
  isFetchingUser: state.network.isFetchingUser,
  isFetchingTheme: state.network.isFetchingTheme,
  isAuthorized: state.network.isAuthorized,
  redirectToSignup: state.network.redirectToSignup,
  isLoginUnsuccessful: state.network.isLoginUnsuccessful,
  isAccountBlocked: state.network.isAccountBlocked,
  language: state.user.language,
  displaySharedCustomerSelectionModal: state.sharedModal.displaySharedCustomerSelectionModal,
  displaySharedBoardModal: state.sharedModal.displaySharedBoardModal,
  isCloud: state.network.isCloud,
  updateModal: state.board.updateModal,
  hasUnfinishedSignup: state.user.hasUnfinishedSignup,
  knowledgeGraphMeta: state.knowledgeGraphMeta,
  knowledgeGraphs: state.knowledgeGraphs,
  currentKnowledgeGraph: state.knowledgeGraph,
  chatMessages: state.chatMessages,
  isFetchingLanguages: state.languages.fetching,
  hasFetchedLanguages: state.languages.fetched,
  isFetchingRecipes: state.recipes.fetching,
  hasFetchedRecipes: state.recipes.fetched
});

export default withSnackbar(withRouter(connect(mapStateToProps)(withEmbedded(App))));
