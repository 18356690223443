import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toggleFollowUp, untoggleFollowUp } from 'store/modules/followUpState';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import Button from 'components/buttons/Button';

import useMediaQuery from 'utils/mediaQueries';

import styles from '../message/answer-message-footer.scss';

const FollowUpButton = ({
  interpretationId,
  partialAnswerId,
  answerId,
  messageId,
  followUpState,
  dispatch,
  t,
  ...rest
}) => {
  // we only want to pass props from rest that start with the prefix 'data-'
  // otherwise we get warning messages, e.g. for tReady
  const isMobile = useMediaQuery();

  let dataProps = {};
  Object.entries(rest)
    .filter(([k, _]) => k.startsWith('data-'))
    .forEach(([k, v]) => (dataProps[k] = v));

  const followUpButton = (
    <InfoTooltip text={t('tooltip.follow-up')}>
      <Button
        className={`${'button-secondary'} ${styles.followButton}`}
        onClick={() => dispatch(toggleFollowUp(interpretationId, partialAnswerId, answerId, messageId))}
        text="FollowUp"
        showOnlyIcon={isMobile}
        data-test="followUpButton"
        {...dataProps}
      >
        <span className="icon-follow-up" />
      </Button>
    </InfoTooltip>
  );

  const unFollowUpButton = (
    <InfoTooltip text={t('tooltip.unfollow-up')}>
      <Button
        className={`${'button-secondary'} ${styles.unFollow}`}
        onClick={() => dispatch(untoggleFollowUp())}
        text="FollowUp"
        showOnlyIcon={isMobile}
        {...dataProps}
      >
        <span className="icon-follow-up" />
      </Button>
    </InfoTooltip>
  );

  const isSelectedFollowUp =
    followUpState && (messageId === followUpState.messageId && followUpState.interpretationId === interpretationId);

  return isSelectedFollowUp ? unFollowUpButton : followUpButton;
};

export default withTranslation('veezoo')(FollowUpButton);

FollowUpButton.propTypes = {
  interpretationId: PropTypes.string,
  partialAnswerId: PropTypes.string,
  answerId: PropTypes.string,
  messageId: PropTypes.string,
  followUpState: PropTypes.object,
  dispatch: PropTypes.func,
  t: PropTypes.func
};

FollowUpButton.defaultProps = {
  interpretationId: undefined
};
