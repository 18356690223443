import { makeStyles } from '@material-ui/core';

const fontFamily = 'var(--font-family)';

const commonStyles = {
  titleRoot: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeButton: {
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: -5,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderWidth: 0
  }
};

export const useDefaultStyles = makeStyles({
  titleRoot: {
    ...commonStyles.titleRoot,
    padding: '16px 24px',
    fontSize: '1.25rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    flex: '0 0 auto'
  },
  contentRoot: ({ hideButtons }) => ({
    ...(hideButtons ? { paddingBottom: '16px' } : {})
  }),
  closeButton: {
    ...commonStyles.closeButton
  },
  closeIcon: {
    width: 10.5,
    height: 10.5
  }
});

export const useVeezooStyles = makeStyles({
  titleRoot: ({ DialogTitlePropsClasses }) => ({
    ...commonStyles.titleRoot,
    fontFamily,
    fontWeight: '500 !important',
    fontSize: '16px !important',
    ...DialogTitlePropsClasses
  }),
  contentRoot: ({ hideButtons }) => ({
    ...(hideButtons ? { paddingBottom: '24px' } : {})
  }),
  actionsContainer: {
    padding: '16px 24px'
  },
  paper: ({ paper }) => ({
    minWidth: 360,
    boxSizing: 'border-box',
    ...paper
  }),
  closeButton: {
    ...commonStyles.closeButton,
    fontFamily,
    '& path': { fill: 'var(--primary-color)' },
    '&:hover': {
      backgroundColor: 'var(--lightest-gray)'
    }
  },
  closeIcon: {
    width: 11.5,
    height: 11.5
  }
});
