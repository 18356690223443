import { hasCustomGroup } from 'store/utils/knowledgeGraphOntologyIcons';

const calculateNodeSize = size => size * 2.1 + 30;

const getVisualNodeLayout = (node, visualNodeLayouts) => {
  return visualNodeLayouts?.find(n => n.uri === node.uri);
};

export const setEdgeStructure = edges => {
  // first we need to group the edges in buckets by their from - to combination for layouting (1-0 and 0-1 are the same group)
  const edgeGroups = edges.reduce((acc, edge) => {
    // we always start from the smaller node (from or to) to the bigger one
    const key = edge.from < edge.to ? `${edge.from}-${edge.to}` : `${edge.to}-${edge.from}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(edge);
    return acc;
  }, {});

  // now that we have the groups conneting the same nodes, we can go through each group and create the edge structure
  return Object.values(edgeGroups)
    .map(edgeGroup => {
      // if it's odd, we draw the first edge straight
      const hasOddNumberOfEdges = edgeGroup.length % 2 !== 0;

      return edgeGroup.map((edge, index) => {
        const showEdgeName = edge.shouldShowEdgeName;
        const baseCurve = 0.1;
        // the curve strength increases every 2 edges (in the odd case, the first edge is straight)
        const curve = baseCurve + Math.floor((index - (hasOddNumberOfEdges ? 1 : 0)) / 2) * baseCurve;

        // we alternate the curve direction
        let edgeType = index % 2 === 0 ? 'curvedCW' : 'curvedCCW';
        // Because we talk clockwise and counter-clockwise, we might need to flip the edge type otherwise the edges would overlap
        // e.g. if we have one edge from 0->1 and one from 1->0
        const needToFlipEdgeType = edge.from > edge.to;
        if (needToFlipEdgeType) {
          if (edgeType === 'curvedCW') {
            edgeType = 'curvedCCW';
          } else {
            edgeType = 'curvedCW';
          }
        }

        let curvedEdgeSettings = { enabled: edgeGroup.length > 1, type: edgeType, roundness: curve };
        // for the first edge, if we have an odd number of edges, we need to make it straight
        if (index === 0 && hasOddNumberOfEdges) {
          curvedEdgeSettings = { enabled: false };
        }

        return {
          ...edge,
          label: showEdgeName && (edge.link.name || ''),
          arrows: { to: { enabled: showEdgeName, type: 'triangle', scaleFactor: 1 } },
          arrowStrikethrough: true,
          smooth: curvedEdgeSettings
        };
      });
    })
    .flat();
};

export const setNodeStructure = (nodes, visualNodeLayouts) =>
  nodes.map(node => {
    const visualNodeLayout = getVisualNodeLayout(node, visualNodeLayouts);
    return {
      ...node,
      label: node.name || '', // this is the label that will be displayed on the node
      size: calculateNodeSize(visualNodeLayout?.size || 1),
      group: hasCustomGroup(node.ontologyType),
      x: visualNodeLayout?.x,
      y: visualNodeLayout?.y,
      fixed: false
    };
  });

// if we don't get any positions from the backend (e.g. due to a timeout),
// we give our best by using physics to let the nodes spread out
export const shouldUsePhysics = visualNodeLayouts => {
  return visualNodeLayouts.length === 0;
};
