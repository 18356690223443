import { makeStyles } from '@material-ui/core/styles';

const commonStyles = {
  container: {
    display: 'flex',
    flexDirection: ({ direction }) => (direction === 'row' ? 'row' : 'column'),
    justifyContent: ({ justifyContent }) => justifyContent || 'space-between',
    '& > div': {
      '&:first-child': { marginRight: 5 },
      '&:last-child': { marginLeft: 5 }
    }
  }
};

export const useSignupStyles = makeStyles({
  ...commonStyles
});

export const useVeezooStyles = makeStyles({
  ...commonStyles
});

export const useDefaultStyles = makeStyles({
  ...commonStyles
});
