import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'components/v3';

import { layouts } from 'config/constants';

const TextArea = forwardRef(({ layout, rowsMin, rowsMax, ...props }, ref) => {
  const defaultRows = layout === layouts.signup ? 3 : rowsMin;

  return (
    <TextField
      multiline
      minRows={defaultRows}
      maxRows={rowsMax}
      fullWidth
      ref={ref}
      margin="dense"
      layout={layout}
      {...props}
    />
  );
});

export default TextArea;

TextArea.propTypes = {
  layout: PropTypes.string,
  rowsMin: PropTypes.number,
  rowsMax: PropTypes.number
};

TextArea.defaultProps = {
  layout: 'default',
  rowsMin: 5,
  rowsMax: 8
};
