import { PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import { withTranslation } from 'react-i18next';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';

import { messageTypes } from 'store/modules/chat-messages';
import { finishedTutorial } from 'store/modules/tutorial';

import { StarIcon } from '../Icons';
import Title from 'components/tutorial/Title';
import scrollElementIntoVisibleArea from 'utils/scroll-element-into-visible-area';
import { tutorialNames } from 'config/constants';

const tutorialId = tutorialNames.CUSTOMERS_OF_THE_DAY_TUTORIAL;

export class CustomersOfTheDayTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTutorial: false,
      stepIndex: 0 // a controlled tour
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type, index } = tour;

    const element = document.querySelector(tour.step.target);
    scrollElementIntoVisibleArea(element);

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // update state to advance the tour
      this.setState({
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
      });
    }

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
    }
  }

  componentDidUpdate(prevProps) {
    // CustomersOfTheDayTutorial will run only once after message with type VEEZOO_CUSTOMERS_OF_THE_DAY_MESSAGE
    if (
      !this.state.runTutorial &&
      this.props.hasCoDMessage &&
      this.props.uncompletedTutorials.some(tut => tut.id === tutorialId)
    ) {
      this.setState({ runTutorial: true });
    }
  }

  render() {
    const { runTutorial, stepIndex } = this.state;
    const { t, generalOptions } = this.props;

    const steps = [
      // step 0
      {
        target: 'body',
        disableBeacon: true,
        placement: 'center',
        locale: { next: 'Start' },
        title: <Title text={t('tutorial.cod.start-title')} icon={<StarIcon />} />,
        content: t('tutorial.cod.start-desc')
      },
      // step 1
      {
        target: '[data-cod-tutorial="whole-message"]',
        disableBeacon: true, // need to disable icon for every single step
        placement: 'bottom',
        title: t('tutorial.cod.message-title'),
        content: t('tutorial.cod.message-desc')
      },
      // step 2
      {
        target: '[data-cod-tutorial="crm-link"]', // TODO: document.querySelector('[data-cod-tutorial="crm-link"]') doesn't work here
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.cod.crm-link-title'),
        content: t('tutorial.cod.crm-link-desc')
      },
      // step 3
      {
        target: '[data-cod-tutorial="criteria"]',
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.cod.criteria-title'),
        content: t('tutorial.cod.criteria-desc')
      },
      // step 4
      {
        target: '[data-cod-tutorial="show-more"]',
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.cod.show-more-title'),
        content: t('tutorial.cod.show-more-desc')
      },
      // step 5
      {
        target: '[data-cod-tutorial="feedback"]',
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.cod.feedback-title'),
        content: t('tutorial.cod.feedback-desc')
      },
      // step 6
      {
        target: '[data-cod-tutorial="configure"]',
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.cod.configure-title'),
        content: t('tutorial.cod.configure-desc')
      }
    ];

    // IMPORTANT! Correct scrolling to target element doesn't work with upgraded version 2.1.1
    return (
      <Joyride
        {...generalOptions}
        ref="joyride"
        steps={steps}
        stepIndex={stepIndex}
        run={runTutorial} // or some other boolean for when you want to start it
        callback={this.callback}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    hasCoDMessage: state.chatMessages.some(m => m.type === messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_MESSAGE),
    uncompletedTutorials: state.tutorial.uncompletedTutorials
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(CustomersOfTheDayTutorial));
