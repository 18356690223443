export const SET_IS_POPOVER_OPEN = 'IS_POPOVER_OPEN';
export const SET_IS_EDITING_TITLE = 'SET_IS_EDITING_TITLE';
export const SET_IS_EDITING_DESCRIPTION = 'SET_IS_EDITING_DESCRIPTION';
export const SET_IS_EDITING_SYNONYMS = 'SET_IS_EDITING_SYNONYMS';
export const CANCEL_ALL_EDITING = 'CANCEL_ALL_EDITING';

export const setIsPopoverOpen = isPopoverOpen => {
  return {
    type: SET_IS_POPOVER_OPEN,
    isPopoverOpen
  };
};

export const setIsEditingTitle = isEditing => {
  return {
    type: SET_IS_EDITING_TITLE,
    isEditing
  };
};

export const setIsEditingDescription = isEditing => {
  return {
    type: SET_IS_EDITING_DESCRIPTION,
    isEditing
  };
};

export const setIsEditingSynonyms = isEditing => {
  return {
    type: SET_IS_EDITING_SYNONYMS,
    isEditing
  };
};

export const cancelAllEditing = () => ({ type: CANCEL_ALL_EDITING });

export const initState = {
  isPopoverOpen: false,
  isEditingTitle: false,
  isEditingDescription: false,
  isEditingSynonyms: false
};

export function nodeEditor(state = initState, action) {
  switch (action.type) {
    case SET_IS_POPOVER_OPEN: {
      return {
        ...state,
        isPopoverOpen: action.isPopoverOpen
      };
    }

    case SET_IS_EDITING_TITLE: {
      return {
        ...state,
        isEditingTitle: action.isEditing
      };
    }

    case SET_IS_EDITING_DESCRIPTION: {
      return {
        ...state,
        isEditingDescription: action.isEditing
      };
    }

    case SET_IS_EDITING_SYNONYMS: {
      return {
        ...state,
        isEditingSynonyms: action.isEditing
      };
    }

    case CANCEL_ALL_EDITING: {
      return {
        ...state,
        isEditingTitle: false,
        isEditingDescription: false,
        isEditingSynonyms: false
      };
    }

    default:
      return state;
  }
}
