import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import InfoTooltip from 'components/tooltips/InfoTooltip';
import Button from 'components/buttons/Button';
import Icon from 'components/icons/Icon.js';

import styles from './customer-selections-button.scss';

const CustomersOfTheDayButton = ({ tooltipText, handleClick, t, buttonText, ...rest }) => {
  const restProps = omit(rest, ['tReady']);

  const customersOfTheDayButtonStyle = tooltipText ? styles.enabledCustomersOfTheDay : styles.disabledCustomersOfTheDay;

  const customersOfTheDayTooltipText = tooltipText
    ? t('tooltip.remove-from-customers-of-the-day')
    : t('tooltip.add-to-customers-of-the-day');

  return (
    <InfoTooltip text={customersOfTheDayTooltipText}>
      <Button
        text={t(buttonText)}
        className={`${'button-secondary'} ${customersOfTheDayButtonStyle}`}
        onClick={handleClick}
        {...restProps}
      >
        <Icon type="icon-star-message" />
      </Button>
    </InfoTooltip>
  );
};

CustomersOfTheDayButton.propTypes = {
  buttonText: PropTypes.string,
  handleClick: PropTypes.func,
  tooltipText: PropTypes.bool,
  t: PropTypes.func
};

CustomersOfTheDayButton.defaultProps = {
  buttonText: 'customers-of-the-day.title'
};

export default withTranslation('veezoo')(CustomersOfTheDayButton);
