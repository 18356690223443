import {
  CHATMESSAGE_VISUALIZATION_COUNT_REQUEST,
  CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS,
  CHATMESSAGE_VISUALIZATION_COUNT_FAILURE,
  replaceTotalCountPlaceholder
} from './chat-messages';

export const EXPAND_MINI_WIDGET = 'EXPAND_MINI_WIDGET';
export const COLLAPSE_MINI_WIDGET = 'COLLAPSE_MINI_WIDGET';

export const expandMiniWidget = (messageId, widgetId, answer, activeChartIndex, title, explicitlyFromUserExpanded) => {
  return {
    type: EXPAND_MINI_WIDGET,
    widgetId: widgetId,
    answer: answer,
    messageId: messageId,
    activeChartIndex: activeChartIndex,
    title: title,
    explicitlyFromUserExpanded: explicitlyFromUserExpanded
  };
};

export const collapseMiniWidget = messageId => {
  return {
    type: COLLAPSE_MINI_WIDGET,
    messageId: messageId
  };
};

export function expandedMiniWidgets(state = [], action) {
  switch (action.type) {
    case EXPAND_MINI_WIDGET:
      return [...state, { ...action }];

    case COLLAPSE_MINI_WIDGET:
      return state.filter(mw => mw.messageId !== action.messageId);

    case CHATMESSAGE_VISUALIZATION_COUNT_REQUEST: {
      return state.map(item => {
        if (item.answer && item.answer.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              visualizations: item.answer.visualizations.map(visualization => ({
                ...visualization,
                fetchingTotalCount: true
              }))
            }
          };
        }
        return item;
      });
    }

    case CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS: {
      return state.map(item => {
        if (item.answer && item.answer.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              visualizations: item.answer.visualizations.map(visualization => ({
                ...visualization,
                fetchingTotalCount: false,
                summary: replaceTotalCountPlaceholder(visualization.summary, action.rowCount)
              }))
            }
          };
        }
        return item;
      });
    }

    case CHATMESSAGE_VISUALIZATION_COUNT_FAILURE: {
      return state.map(item => {
        if (item.answer && item.answer.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              visualizations: item.answer.visualizations.map(visualization => ({
                ...visualization,
                fetchingTotalCount: false
              }))
            }
          };
        }
        return item;
      });
    }

    default:
      return state;
  }
}
