import axios from 'axios';
import {
  headersGET,
  headersPUT,
  headersPATCH,
  headersPOST,
  headersDELETE,
  headersFilePOST
} from 'store/utils/HTTPheaders';

import { handleUnauthorized, handleUnauthorizedIndirect } from 'store/utils/authFetch';

// Errors still need to be handled due to AXA redirects our 401 and transform them into 404.
export const handleError = (error, dispatch) => {
  const status = error?.response?.status || null;

  if (status === 401) return handleUnauthorized(dispatch);
  if (status === 404) return handleUnauthorizedIndirect(dispatch);
  return error;
};

const successResponse = response => ({ success: true, ...response });

export const get = (endpoint, params = {}) =>
  axios
    .get(endpoint, { headers: headersGET, withCredentials: true, params })
    .then(successResponse)
    .catch(error => error);

// We need the HTTP request to be POST instead of GET, to send a body
export const postWithBlobResponse = (endpoint, body, params = {}) =>
  axios
    .post(endpoint, body, {
      headers: headersPOST,
      withCredentials: true,
      responseType: 'blob',
      params
    })
    .then(successResponse)
    .catch(error => error);

export const put = (endpoint, body, additionalHeaders = {}) =>
  axios
    .put(endpoint, body, { headers: { ...headersPUT, ...additionalHeaders }, withCredentials: true })
    .then(successResponse)
    .catch(error => error);

export const patch = (endpoint, body, additionalHeaders = {}, params = {}) =>
  axios
    .patch(endpoint, body, { headers: { ...headersPATCH, ...additionalHeaders }, withCredentials: true, params })
    .then(successResponse)
    .catch(error => error);

export const post = (endpoint, body, params = {}) =>
  axios
    .post(endpoint, body, { headers: headersPOST, withCredentials: true, params })
    .then(successResponse)
    .catch(error => error);

export const postFile = (endpoint, body) =>
  axios
    .post(endpoint, body, { headers: headersFilePOST, withCredentials: true })
    .then(successResponse)
    .catch(error => error);

export const del = (endpoint, params = {}) =>
  axios
    .delete(endpoint, { headers: headersDELETE, withCredentials: true, params })
    .then(successResponse)
    .catch(error => error);
