import { passageInfoStrategyTypes } from 'config/constants';
import { availableGroups, OntologyEntity } from '../store/utils/knowledgeGraphOntologyIcons';
import { Fragment } from 'react';

import styles from './suggestionParser.scss';

/**
 * Input: A suggestion as specified by the API.
 * Output: An array of spans of different types; plain, marked, linked and only-show.
 */
const parseSuggestion = (suggestion, plainStyle, linkedStyle, linkedDescriptionStyle, onlyShowStyle, iconStyle) => {
  const { LINKED_PASSAGE_INFO, MARKED_PASSAGE_INFO, ONLY_SHOW_TEXT_PASSAGE } = passageInfoStrategyTypes;
  const inputOffset = suggestion.inputOffset || 0;
  const text = ' '.repeat(inputOffset) + suggestion.text; // Fill text so that we don't have to recalculate based on the input offset
  const sortedPassageInfos = suggestion.passageInfos.sort((a, b) => a.offset - b.offset);

  // Interleave plain text and passages
  const passages = [];
  let currentIdx = inputOffset; // Variable storing up to which point the passages cover the text
  sortedPassageInfos.forEach(passageInfo => {
    const initialTextUntilOffset = text.slice(currentIdx, passageInfo.offset);
    currentIdx = passageInfo.offset + passageInfo.length; // Update how much we processed
    const afterOffsetText = text.slice(passageInfo.offset, currentIdx);
    if (initialTextUntilOffset.length > 0) {
      passages.push(initialTextUntilOffset); // Add plain text up to this point since last passage
    }
    if (afterOffsetText.length > 0) {
      passages.push({ ...passageInfo, text: afterOffsetText }); // Add passage info, with the text for it added
    }
  });
  const tailText = text.slice(currentIdx);
  if (tailText.length > 0) {
    passages.push(tailText);
  }

  return passages.map((passage, i) => {
    if (typeof passage === 'string') {
      // Plain text passage
      return (
        <span className={plainStyle} key={`plainStyle_${i}`}>
          {passage}
        </span>
      );
    } else {
      if (passage.type === LINKED_PASSAGE_INFO) {
        const hasDescription = typeof passage.description !== 'undefined';

        const isClass = !hasDescription;
        // A linked passage is always either smth. from our ontology groups or a class (else case).
        const ontologyType = availableGroups[passage.ontologyType] ? passage.ontologyType : 'none';
        const OntologyIconComponent = availableGroups[ontologyType]?.componentIcon;
        const defaultColor = isClass ? 'inherit' : availableGroups[OntologyEntity].color;
        const ontologyColor = availableGroups[ontologyType]?.color || defaultColor;
        const description = passage.description;

        const iconComponent = OntologyIconComponent && <OntologyIconComponent style={{ color: ontologyColor }} />;
        return (
          <Fragment key={`fragmentKey_${i}`}>
            {iconComponent && (
              <span className={iconStyle} key={`linkedStyle_${i}_1`}>
                {iconComponent}
              </span>
            )}
            <span
              style={{ color: ontologyColor }}
              className={linkedStyle}
              data-test="linkedPassageText"
              key={`linkedStyle_${i}_2`}
            >
              {passage.text}
            </span>
            {hasDescription && (
              <span className={linkedDescriptionStyle} key={`linkedStyle_${i}_3`}>
                {description}
              </span>
            )}
          </Fragment>
        );
      } else if (passage.type === MARKED_PASSAGE_INFO) {
        return (
          <span className={linkedStyle} key={`markedStyle_${i}`}>
            {passage.text}
          </span>
        );
      } else if (passage.type === ONLY_SHOW_TEXT_PASSAGE) {
        return (
          <span className={onlyShowStyle} key={`onlyShowStyle_${i}`}>
            {passage.text}
          </span>
        );
      } else {
        // Fall back to plain text passage
        return (
          <span className={plainStyle} key={`plainStyle_${i}`}>
            {passage.text}
          </span>
        );
      }
    }
  });
};

export default parseSuggestion;

export const parseSuggestionWithStyles = suggestion =>
  parseSuggestion(suggestion, styles._plain, styles._linked, styles._linkedDescription, styles._onlyShow, styles._icon);
