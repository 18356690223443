import { memo } from 'react';
import { withTranslation } from 'react-i18next';

import PencilEditIcon from 'svg/v2/pencil_edit.svg';

import styles from './styles.scss';

const SmartFixButton = ({ newName, onClick, t }) => {
  return (
    <div className={styles.button} onClick={onClick} data-test="SmartFixButton">
      <div className={styles.iconContainer}>
        <PencilEditIcon className={styles.icon} />
      </div>
      <div className={styles.textContainer}>
        {t('smart-fixes.rename-action')} <span className={styles.textHighlighted}>{newName}</span>
      </div>
    </div>
  );
};

export default withTranslation('veezoo')(memo(SmartFixButton));
