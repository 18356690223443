import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import styles from './no-mobile-support.scss';

const NoMobileSupport = ({ t }) => (
  <div className={styles.parent}>
    <div className={styles.child}>
      <p className={styles.emoji}>🚧👷🚧</p>
      <h1>{t('mobile-support.under-construction')}</h1>
      <h1>{t('mobile-support.come-back-later')}</h1>
    </div>
  </div>
);

export default withTranslation('veezoo')(NoMobileSupport);

NoMobileSupport.propTypes = { t: PropTypes.func };
