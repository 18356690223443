import React, { useContext } from 'react';

import { passageTypes } from 'config/constants';
import { Q2QStateContext } from './Q2QStateContext';

import PassageSpan from 'components/shared/PassageSpan';

const EditablePassage = ({ passage, selectedPassages, passages, editingPassage }) => {
  const q2qStateContext = useContext(Q2QStateContext);

  const handleClick = ({ currentTarget }) =>
    q2qStateContext.setOptions({
      anchor: currentTarget,
      selectedPassages,
      editingPassage,
      passages,
      highlightedPill: passage.index
    });

  return (
    <PassageSpan
      passage={editingPassage}
      onClick={handleClick}
      text={editingPassage.text}
      testClass="editableFilterPillSpan"
    />
  );
};

// The passages prop should contain all passages in the context (pill or title), and is used to create a local message for the edit
const Q2QEditableEntityPassage = ({ passages, disableEditing }) => {
  return passages.map((passage, index) => {
    if (!disableEditing) {
      if (passage.type === passageTypes.ENTITY) {
        return (
          <EditablePassage
            key={`entity_passage_${index}`}
            passage={passage}
            editingPassage={passage}
            selectedPassages={[passage]}
            passages={passages}
          />
        );
      }

      if (passage.type === passageTypes.ENTITY_UNION) {
        const unionPassages = passage.children.filter(passage => passage.type === passageTypes.ENTITY);
        return (
          <EditablePassage
            key={`entity_passage_child_${index}`}
            passage={passage}
            editingPassage={passage}
            selectedPassages={unionPassages}
            passages={passages}
          />
        );
      }
    }
    if (passage.id) {
      return <PassageSpan key={`entity_passage_${index}`} passage={passage} text={passage.text} />;
    }
    return <span key={`raw_passage_${index}`}>{passage.text}</span>;
  });
};

export default Q2QEditableEntityPassage;
