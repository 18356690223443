import NoResult from 'svg/no-result.svg';
import { withTranslation } from 'react-i18next';

import styles from './search-no-results-panel.scss';

function SearchNoResultsResultsPanel(props) {
  const { t } = props;

  return (
    <div className={styles.noResultText}>
      <p>{t('no-results')}</p>
      <p>
        <NoResult className={styles.logo} />
      </p>
    </div>
  );
}

export default withTranslation('veezoo')(SearchNoResultsResultsPanel);
