import { baseURL } from 'config/baseURL';
import { get, patch } from '../utils.js';

const fetchLiteral = uri => {
  return get(`${baseURL}knowledge-graph/literals/${encodeURIComponent(uri)}`);
};

const updateLiteral = (uri, body) => {
  return patch(`${baseURL}knowledge-graph/literals/${encodeURIComponent(uri)}`, JSON.stringify(body));
};

export default { fetchLiteral, updateLiteral };
