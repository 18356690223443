import React, { useMemo } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import classNames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

// the colors for the different severities
const colors = {
  // taken from class 'MuiAlert-standardInfo'
  info: '#0d3c61',
  // taken from class 'MuiAlert-standardWarning'
  warning: '#663c00',
  // taken from class 'MuiAlert-standardError'
  error: '#611a15',
  // taken from class 'MuiAlert-standardSuccess'
  success: '#1e4620'
};

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    marginLeft: props => (props.inAnswerHeader ? '-25px' : 0),
    marginRight: props => (props.inAnswerHeader ? '-25px' : 0),
    marginTop: props => (props.inAnswerHeader ? '-25px' : 0),
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(2),
    '& p': {
      marginTop: theme.spacing(1),
      marginBottom: 0
    },
    '& *:first-child': {
      marginTop: 0
    },
    '&.expandable': {
      cursor: 'pointer',
      '& .MuiAlert-message': {
        paddingRight: theme.spacing(1)
      }
    },
    '& .MuiAlert-message': {
      width: '100%',
      '& ul': {
        marginTop: theme.spacing(1),
        marginBottom: 0
      },
      '& li:not(:last-child)': {
        marginBottom: theme.spacing(0.5)
      }
    },
    '& .MuiAccordion-root': {
      background: 'none',
      border: 0,
      boxShadow: 'none'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: props => props.color
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
      lineHeight: 'normal'
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0,
      color: props => props.color
    },
    '& .MuiAccordionDetails-root': {
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      display: 'block',
      color: props => props.color
    },
    '& .MuiTableCell-root': {
      color: props => props.color
    }
  }
}));

const Alert = ({
  severity,
  children,
  expandedContent,
  expanded,
  setExpanded,
  variant,
  icon,
  isCloseable,
  inAnswerHeader,
  className
}) => {
  // the classes, using the color according to the severity
  const classes = useStyles({ color: colors[severity], inAnswerHeader: inAnswerHeader });
  const [open, setOpen] = React.useState(true);
  // specifies whether the alert is expandable or not
  const isExpandable = useMemo(() => expanded != null && !!expandedContent && !!setExpanded, [
    expanded,
    expandedContent,
    setExpanded
  ]);

  return (
    <Collapse in={open}>
      <MuiAlert
        severity={severity}
        className={classNames(classes.root, className, isExpandable ? 'expandable' : null)}
        onClick={isExpandable ? _ => setExpanded(!expanded) : null}
        variant={variant}
        icon={icon}
        onClose={isCloseable ? _ => setOpen(false) : null}
      >
        {isExpandable ? (
          <Accordion expanded={expanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span>{children}</span>
            </AccordionSummary>
            <AccordionDetails>{expandedContent}</AccordionDetails>
          </Accordion>
        ) : (
          children
        )}
      </MuiAlert>
    </Collapse>
  );
};

export default Alert;

Alert.defaultProps = {
  severity: 'info'
};
