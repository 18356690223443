import { PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { messageTypes } from 'store/modules/chat-messages';
import { finishedTutorial } from 'store/modules/tutorial';

import { StarIcon } from '../Icons';
import Title from 'components/tutorial/Title';
import scrollElementIntoVisibleArea from 'utils/scroll-element-into-visible-area';
import { tutorialNames } from 'config/constants';

const tutorialId = tutorialNames.FIRST_ANSWER_TUTORIAL;

export class FirstAnswerTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTutorial: false,
      stepIndex: 0 // a controlled tour
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type, index } = tour;

    const element = document.querySelector(tour.step.target);
    scrollElementIntoVisibleArea(element);

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // update state to advance the tour
      this.setState({
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
      });
    }

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));

      // in case if the Answer has save modes, we don't want to display ADD_TO_CUSTOMER_SELECTION_TUTORIAL anymore
      const selector = document.querySelector('[data-display-tutorial] [data-answer-tutorial="save-mode"]');
      if (selector !== null) {
        const isCompletedTutorial = action !== ACTIONS.SKIP;
        this.props.dispatch(finishedTutorial(tutorialNames.ADD_TO_CUSTOMER_SELECTION_TUTORIAL, isCompletedTutorial));
      }
    }
  }

  componentDidUpdate(prevProps) {
    // AnswerTutorial will run only with VEEZOO_ANSWER_MESSAGE message type to be able to explain a lot of functionality, buttons.
    // Otherwise customer can ask question and answer will not consist any proper data.
    if (
      !this.state.runTutorial &&
      this.props.hasAnswerMessage &&
      this.props.hasUserMessage &&
      this.props.isOnPremise && // keep it enabled only for on-premise
      this.props.uncompletedTutorials.some(tutorial => tutorial.id === tutorialId)
    ) {
      this.setState({ runTutorial: true });
    }
  }

  render() {
    const { runTutorial, stepIndex } = this.state;
    const { t, generalOptions } = this.props;

    const steps = [
      // step 0
      {
        target: '[data-answer-tutorial="start"]', // start AnswerTutorial
        disableBeacon: true,
        placement: 'center',
        locale: { next: 'Start' },
        title: <Title text={t('tutorial.answer.start-title')} icon={<StarIcon />} />,
        content: t('tutorial.answer.start-desc')
      },
      // step 1
      {
        target: '[data-display-tutorial] [data-answer-tutorial="title-with-filters"]', // highlight an Answer title with filters
        disableBeacon: true, // need to disable icon for every single step
        placement: 'bottom',
        title: t('tutorial.answer.title-with-filters'),
        content: t('tutorial.answer.title-with-filters-desc')
      },
      // step 2
      {
        target: '[data-display-tutorial] [data-answer-tutorial="visualization"]', // highlight visualization if it's exist for an Answer
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.visualization-title'),
        content: t('tutorial.answer.visualization-desc')
      },
      // step 3
      {
        target: '[data-display-tutorial] [data-answer-tutorial="feedback"]', // highlight feedback button if an element exists in DOM
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.feedback-title'),
        content: t('tutorial.answer.feedback-desc')
      },
      // step 4
      {
        target: '[data-display-tutorial] [data-answer-tutorial="followUp"]', // highlight followUp
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.followUp-title'),
        content: t('tutorial.answer.followUp-desc')
      },
      // step 5
      {
        target: '[data-display-tutorial] [data-answer-tutorial="change-visualization"]', // highlight change visualization if an element exists in DOM
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.change-visualization-title'),
        content: t('tutorial.answer.change-visualization-desc')
      },
      // step 6
      {
        target: '[data-display-tutorial] [data-answer-tutorial="raw-table"]', // highlight raw table presentation if an element exists in DOM
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.raw-table-title'),
        content: t('tutorial.answer.raw-table-desc')
      },
      // step 7
      {
        target: '[data-display-tutorial] [data-answer-tutorial="download-excel"]', // highlight download excel if an element exists in DOM
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.download-excel-title'),
        content: t('tutorial.answer.download-excel-desc')
      },
      // step 8
      {
        target: '[data-display-tutorial] [data-answer-tutorial="add-to-board"]', // highlight add to board if an element exists in DOM
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.add-to-board-title'),
        content: t('tutorial.answer.add-to-board-desc')
      },
      // step 9
      {
        target: '[data-display-tutorial] [data-answer-tutorial="save-mode"]', // highlight save to if an element exists in DOM
        disableBeacon: true,
        placement: 'top',
        title: t('tutorial.answer.save-mode-title'),
        content: t('tutorial.answer.save-mode-desc')
      }
    ];

    // IMPORTANT! Correct scrolling to target element doesn't work with upgraded version 2.1.1
    return (
      <Joyride
        {...generalOptions}
        ref="joyride"
        steps={steps}
        stepIndex={stepIndex}
        run={runTutorial} // or some other boolean for when you want to start it
        callback={this.callback}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    hasAnswerMessage: state.chatMessages.some(message => message.type === messageTypes.VEEZOO_ANSWER_MESSAGE),
    hasUserMessage: state.chatMessages.some(message => message.type === messageTypes.USER),
    uncompletedTutorials: state.tutorial.uncompletedTutorials,
    isOnPremise: state.network.isOnPremise
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(FirstAnswerTutorial));
