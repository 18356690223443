import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import isSameDay from 'date-fns/isSameDay';

import Select from 'components/v2/Select';
import Button from 'components/v2/Button';
import LabelButton from 'components/v2/LabelButton';
import DatetimeComponent from './DatetimeComponent';

import PlusIcon from 'svg/v2/plus.svg';
import IconClose from 'svg/v2/close.svg';

import { dateComputations, dateTypes } from 'config/constants';
import { isValidDate, getAvailableViews, getDateFromYearAndWeek } from 'utils/datetimeUtils';

import { selectableViews } from './datetimefilter.utils';

import useStyles from 'styles/JSS/Popover';

const filterTypes = { single: 'single', range: 'range', multiple: 'multiple' };
const initialDatetimeValue = { allowTime: false, date: null, time: null };

export const DatetimeFilter = ({
  title,
  views,
  onConfirm,
  onCancel,
  updatePositionRef,
  t,
  values: valuesFromProps,
  initialComputation: initialComputationFromProps,
  initialView: initialViewFromProps
}) => {
  const styles = useStyles();

  const translatedDateComputations = Object.values(dateComputations).map(item => ({
    ...item,
    name: t(`date-modifications.${item.name}`)
  }));

  const availableViews = useMemo(() => getAvailableViews(views), [views]);
  const noViewsSpecified = useMemo(() => !views || views.length < 1, [views]);

  const granularitiesAllowsDateComponent = useMemo(() => {
    const { hasYear, hasMonth, hasDate, hasWeek } = availableViews;

    return hasYear || hasMonth || hasDate || hasWeek || noViewsSpecified;
  }, [availableViews, noViewsSpecified]);

  const granularitiesAllowsTimeComponent = useMemo(() => {
    const { hasHour, hasMinute } = availableViews;
    return hasHour || hasMinute || noViewsSpecified;
  }, [availableViews, noViewsSpecified]);

  const formattedValuesFromProps = useMemo(
    () =>
      valuesFromProps.map(value => {
        const year = value.Year ? parseInt(value.Year) : 2000;
        const month = value.Month ? parseInt(value.Month) - 1 : 0;
        const day = value.Day ? parseInt(value.Day) : 1;
        const hour = value.Hour ? parseInt(value.Hour) : 0;
        const minute = value.Minute ? parseInt(value.Minute) : 0;

        const formatted = value.Week
          ? getDateFromYearAndWeek(year, parseInt(value.Week))
          : new Date(year, month, day, hour, minute, 0, 0);

        const newDate = new Date(formatted);
        newDate.setHours(0, 0, 0, 0);

        const newTime = new Date();
        newTime.setHours(hour, minute, 0, 0);

        const allowTime = !!('Hour' in value || 'Minute' in value);

        return { ...initialDatetimeValue, date: newDate, time: newTime, allowTime };
      }),
    [valuesFromProps]
  );

  const initialComputation = Object.values(dateComputations).some(item => item.value === initialComputationFromProps)
    ? initialComputationFromProps
    : dateComputations.IN.value;

  const allowedSelectableViews = useMemo(() => {
    const result = [];
    const { hasYear, hasMonth, hasDate, hasWeek, hasHour, hasMinute } = availableViews;

    if (hasDate || noViewsSpecified) result.push(selectableViews.day_month_year);
    if (hasDate || hasMonth || noViewsSpecified) result.push(selectableViews.month_year);
    if (hasDate || hasMonth || hasYear || noViewsSpecified) result.push(selectableViews.year);
    if (hasDate || hasWeek || noViewsSpecified) result.push(selectableViews.week);
    if (hasHour || hasMinute || noViewsSpecified) result.push(selectableViews.hour_minute);

    return result.map(item => ({
      ...item,
      name: item.views.reduce((total, view) => {
        const viewNameToTranslate = view === dateTypes.date ? dateTypes.day : view;
        return total + `${total.length > 0 ? '/' : ''}${t(viewNameToTranslate)}`;
      }, '')
    }));
  }, [availableViews, noViewsSpecified, t]);

  const initialView = allowedSelectableViews.some(view => view.value === initialViewFromProps)
    ? initialViewFromProps
    : allowedSelectableViews[0].value;

  const [dateComputation, setDateComputation] = useState(initialComputation);
  const [selectedView, setSelectedView] = useState(initialView);
  const [values, setValues] = useState(formattedValuesFromProps);

  const shouldDisplayDateComponent =
    granularitiesAllowsDateComponent && selectedView !== selectableViews.hour_minute.value;

  const shouldDisplayTimeComponent =
    granularitiesAllowsTimeComponent &&
    (selectedView === selectableViews.day_month_year.value || selectedView === selectableViews.hour_minute.value);

  const timeComponentIsMandatory = !shouldDisplayDateComponent;

  const filterType = useMemo(() => {
    if (dateComputation === dateComputations.IN.value) return filterTypes.multiple;
    if (dateComputation === dateComputations.BETWEEN.value) return filterTypes.range;
    return filterTypes.single;
  }, [dateComputation]);

  const hasBetweenTypeError = useMemo(() => {
    if (dateComputation === dateComputations.BETWEEN.value) {
      // For now, if it's just hour, without day, we do not validate.
      if (selectedView === selectableViews.hour_minute.value) return false;

      // We don't validate if one of the fields is not filled.
      if (!values[0].date || !values[1].date) return false;

      if (selectedView === selectableViews.month_year.value) {
        const firstDate = values[0].date;
        const secondDate = values[1].date;
        firstDate.setDate(1);
        secondDate.setDate(1);
        return secondDate < firstDate;
      }

      // If we select day/month/year and the dates are the same, and we should have time, we should validate
      // if the second time is smaller than the first.
      if (
        selectedView === selectableViews.day_month_year.value &&
        isSameDay(values[0].date, values[1].date) &&
        shouldDisplayTimeComponent &&
        values[1].time < values[0].time
      )
        return true;

      return values[1].date < values[0].date;
    }
    return false;
  }, [dateComputation, selectedView, shouldDisplayTimeComponent, values]);

  const allFieldsAreValid = useMemo(() => {
    const datesAreValid = values.every(value => isValidDate(value.date));
    const timesAreValid = values.every(value => !value.allowTime || isValidDate(value.time));
    return (!shouldDisplayDateComponent || datesAreValid) && (!shouldDisplayTimeComponent || timesAreValid);
  }, [values, shouldDisplayDateComponent, shouldDisplayTimeComponent]);

  const handleChangeDateComputation = event => {
    const newComputation = event.target.value;

    // If the new computation is "between", we should add a new default item in the position "1" of the "values" array;
    setValues(prev => {
      const newValues = [...prev];
      if (newComputation === dateComputations.BETWEEN.value) {
        newValues.splice(2);
        newValues[1] = {
          allowTime: timeComponentIsMandatory,
          date: prev[1]?.date || new Date(prev[0].date),
          time: prev[1]?.time || new Date(prev[0].time)
        };
      } else if (newComputation !== dateComputations.IN.value) {
        newValues.splice(1);
      }
      return newValues;
    });

    return setDateComputation(newComputation);
  };

  const handleSetSelectedView = event => {
    const value = event.target.value;
    return setSelectedView(value);
  };

  const handleChange = (field, value, index) =>
    setValues(prev => {
      const newValues = [...prev];
      newValues[index][field] = value;
      return newValues;
    });

  const addNewDateField = () => setValues(prev => [...prev, { ...initialDatetimeValue }]);

  const removeField = index =>
    setValues(prev => {
      const newValues = [...prev];
      newValues.splice(index, 1);
      return newValues;
    });

  const handleAllowTime = (index, displayInput) =>
    setValues(prev => {
      const newValues = [...prev];
      newValues[index].allowTime = timeComponentIsMandatory || displayInput;
      return newValues;
    });

  const handleConfirm = () => {
    if (hasBetweenTypeError || !allFieldsAreValid) return;

    const payload = {
      views: selectableViews[selectedView].views,
      selectedComputation: dateComputation,
      selectedView,
      values: values.map(value => {
        let year = 0,
          month = 0,
          date = 0,
          hour = 0,
          minute = 0;

        if (selectedView !== selectableViews.hour_minute.value) {
          year = value.date.getFullYear();
          month = value.date.getMonth();
          date = value.date.getDate();
        }

        const allowTime = shouldDisplayTimeComponent && (timeComponentIsMandatory || value.allowTime);

        if (allowTime) {
          hour = value.time.getHours();
          minute = value.time.getMinutes();
        }

        const datetime = new Date(year, month, date, hour, minute, 0, 0);

        return {
          datetime,
          outputFormat: selectableViews[selectedView].outputFormat(datetime, allowTime)
        };
      })
    };

    onConfirm(payload);
  };

  useEffect(() => {
    if (updatePositionRef?.current) {
      updatePositionRef.current.updatePosition();
    }
  }, [values, dateComputation, updatePositionRef?.current]);

  const datetimeComponentProps = {
    handleConfirm,
    t,
    views: selectableViews[selectedView].views,
    displayDate: shouldDisplayDateComponent,
    displayTime: shouldDisplayTimeComponent,
    timeIsMandatory: timeComponentIsMandatory
  };

  return (
    <div className={clsx(styles.container, styles.width400)}>
      <div className={clsx(styles.section, styles.title)}>
        {t('date-modifications.modifyDate')} <span className={styles.innerTitle}>{title}</span>
      </div>
      <hr className={styles.hr} />
      <div className={clsx(styles.section, styles.flexContainer)}>
        <div className={clsx(styles.verticalPadding8, styles.flexGrow1, styles.width200)}>
          <Select
            items={translatedDateComputations}
            value={dateComputation}
            onChange={handleChangeDateComputation}
            width="100%"
            title="date-modifications.date-computation"
            additionalInputProps={{ 'data-test': 'selector' }}
          />
        </div>
        <div className={clsx(styles.verticalPadding8, styles.flexGrow1)}>
          <Select
            items={allowedSelectableViews}
            value={selectedView}
            onChange={handleSetSelectedView}
            width="100%"
            title={t('date-modifications.date-format')}
          />
        </div>
      </div>
      <hr className={styles.hr} />
      <div className={styles.datepickerContainer}>
        {filterType === filterTypes.single && (
          <div className={clsx(styles.section, styles.flexContainer)}>
            <div className={clsx(styles.verticalPadding8, styles.flexGrow1)}>
              <DatetimeComponent
                value={values[0]}
                onChange={(field, newValue) => handleChange(field, newValue, 0)}
                setDisplayTimeInput={enabled => handleAllowTime(0, enabled)}
                {...datetimeComponentProps}
              />
            </div>
          </div>
        )}

        {filterType === filterTypes.range && (
          <div className={styles.verticalPadding8}>
            <div className={clsx(styles.section, styles.flexContainer)}>
              <div className={styles.sidedInputFields}>
                <span className={styles.capitalized}>{t('from')}</span>: <br />
                <DatetimeComponent
                  value={values[0]}
                  onChange={(field, newValue) => handleChange(field, newValue, 0)}
                  setDisplayTimeInput={enabled => handleAllowTime(0, enabled)}
                  {...datetimeComponentProps}
                />
              </div>
              <div className={styles.sidedInputFields}>
                <span className={styles.capitalized}>{t('to')}</span>: <br />
                <DatetimeComponent
                  value={values[1]}
                  onChange={(field, newValue) => handleChange(field, newValue, 1)}
                  setDisplayTimeInput={enabled => handleAllowTime(1, enabled)}
                  {...datetimeComponentProps}
                />
              </div>
            </div>
            <div className={styles.section}>
              {hasBetweenTypeError && (
                <span className={styles.error}>{t('date-modifications.error-field-cannot-be-smaller')}</span>
              )}
            </div>
          </div>
        )}

        {filterType === filterTypes.multiple && (
          <>
            {values.map((value, index) => (
              <div key={index} className={clsx(styles.section, styles.flexContainer)}>
                <div className={clsx(styles.verticalPadding8, styles.flexGrow1)}>
                  <DatetimeComponent
                    value={value}
                    onChange={(field, newValue) => handleChange(field, newValue, index)}
                    setDisplayTimeInput={enabled => handleAllowTime(index, enabled)}
                    {...datetimeComponentProps}
                  />
                </div>
                {values.length > 1 && (
                  <div className={styles.verticalPadding8}>
                    <Button
                      noBorderRadius
                      showOnlyIcon
                      bordered
                      icon={<IconClose className={styles.closeIcon} />}
                      onClick={() => removeField(index)}
                      data-test="date-modification-remove-filter-button"
                    />
                  </div>
                )}
              </div>
            ))}
            <div className={styles.addFilter}>
              <LabelButton
                text={t('date-modifications.add-filter')}
                color="rgba(0, 0, 0, 0.54)"
                icon={<PlusIcon />}
                onClick={() => addNewDateField()}
                data-test="date-modification-add-filter-button"
              />
            </div>
          </>
        )}
      </div>
      <div className={clsx(styles.section, styles.flexContainer)}>
        <div className={clsx(styles.verticalPadding8, styles.flexGrow1)}>
          <Button fullWidth noBorderRadius label={t('cancel')} onClick={onCancel} />
        </div>
        <div className={clsx(styles.verticalPadding8, styles.flexGrow1)}>
          <Button
            dark
            fullWidth
            noBorderRadius
            label={t('apply')}
            disabled={hasBetweenTypeError || !allFieldsAreValid}
            onClick={handleConfirm}
            data-test="modification-apply"
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation('veezoo')(DatetimeFilter);

DatetimeFilter.propTypes = {
  title: PropTypes.string,
  views: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  setValues: PropTypes.func,
  values: PropTypes.array,
  initialComputation: PropTypes.string,
  updatePositionRef: PropTypes.object,
  initialView: PropTypes.string
};

DatetimeFilter.defaultProps = {
  title: '',
  views: [],
  values: [initialDatetimeValue],
  initialComputation: 'before',
  initialView: ''
};
