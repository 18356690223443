import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import UIInputAdornment from '@material-ui/core/InputAdornment';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import useMediaQuery from 'utils/mediaQueries';

const Input = forwardRef(
  ({ label, startIcon, endIcon, isRequired, tooltip, shrink, classes: { disabled }, InputProps, ...rest }, ref) => {
    const isMobile = useMediaQuery();

    const useStyles = makeStyles(() => ({
      root: {
        width: '100%'
      },
      input: {
        fontSize: isMobile ? '16px' : '20px'
      },
      labelRoot: {
        fontSize: isMobile ? '16px' : '20px',
        whiteSpace: 'nowrap',
        '&&+.MuiInput-formControl': {
          marginTop: '16px'
        }
      },
      inputUnderline: {
        '&&.MuiInput-underline::before': {
          borderBottom: 'solid thin black'
        }
      },
      formHelperTextRoot: {
        fontSize: '12px'
      },
      disabled: {
        color: 'rgba(0, 0, 0, 0.18)',
        ...disabled
      }
    }));

    const classes = useStyles();

    const setAdornment = (icon, position) => (
      <UIInputAdornment classes={{ root: classes.adornmentRoot }} position={position}>
        {icon}
      </UIInputAdornment>
    );

    return (
      <TextField
        label={
          <>
            {`${label} ${isRequired ? '*' : ''}`}
            {tooltip && <InfoTooltip text={tooltip} clickableWhenMobile={isMobile} />}
          </>
        }
        classes={{ root: classes.root }}
        InputProps={{
          ...InputProps,
          classes: {
            root: classes.inputRoot,
            input: classes.input,
            underline: classes.inputUnderline,
            disabled: classes.disabled
          },
          startAdornment: startIcon ? setAdornment(startIcon, 'start') : null,
          endAdornment: endIcon ? setAdornment(endIcon, 'end') : null
        }}
        inputProps={{ ref: ref }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot
          },
          shrink: shrink
        }}
        FormHelperTextProps={{
          classes: { root: classes.formHelperTextRoot }
        }}
        autoComplete="new-password"
        {...rest}
      />
    );
  }
);

const iconProps = PropTypes.oneOfType([PropTypes.element, PropTypes.string]);

Input.propTypes = {
  label: PropTypes.string,
  startIcon: iconProps,
  endIcon: iconProps,
  classes: PropTypes.object,
  InputProps: PropTypes.object,
  isRequired: PropTypes.bool,
  shrink: PropTypes.bool,
  tooltip: PropTypes.string
};

Input.defaultProps = {
  label: null,
  classes: {},
  isRequired: false
};

Input.displayName = 'Input';

export default Input;
