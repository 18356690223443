import { Textfit } from 'react-textfit';
import classnames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import './chart.scss';
import styles from './number-chart.scss';

export default function NumberComparisonChart(props) {
  const verticalCenterClass = classnames({
    verticalCenter: props.isCentered,
    'comparison-chart': true
  });

  const chart = JSON.parse(props.chart);
  const { numbers } = chart;

  // Setting in the container the flag fluid to true solves overflow problems while printing
  return (
    <div className={verticalCenterClass}>
      <Container className="container-fluid verticalCenterCorrection" fluid={true}>
        <Row bsPrefix="flex-row" className="d-flex align-items-center justify-content-center">
          {numbers.map(numberResult => {
            const { label, delta } = numberResult;
            if (!delta) {
              return (
                <Col xs={8} key={Math.random() + 1} className="text-right col-as-2">
                  <div className={styles.twoNumberStyleComparison}>
                    <div className="p-2">{label}</div>
                  </div>
                </Col>
              );
            } else {
              return (
                <Col xs={4} key={Math.random() + 1}>
                  <div className={styles.twoNumberStyleComparison}>
                    <div className="p-2">vs. {label}</div>
                  </div>
                </Col>
              );
            }
          })}
        </Row>
        <Row bsPrefix="flex-row" className="d-flex align-items-center justify-content-center">
          {numbers.map(numberResult => {
            const { value, delta, isPositive } = numberResult;
            if (!delta) {
              return (
                <Col xs={8} key={Math.random() + 1} className="text-center col-as-2">
                  <div className={styles.border}>
                    <Textfit mode="single" max={48} key={Math.random()}>
                      <span className={styles.printingStyle}>{value}</span>
                    </Textfit>
                  </div>
                </Col>
              );
            } else {
              return (
                <Col xs={4} key={Math.random() + 1}>
                  <div className={isPositive ? styles.positive : styles.negative}>{delta}</div>
                  <Row>
                    <div className={styles.number}>{value}</div>
                  </Row>
                </Col>
              );
            }
          })}
        </Row>
      </Container>
    </div>
  );
}
