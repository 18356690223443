import { baseURL } from 'config/baseURL';
import { get, post, del, patch } from './utils.js';

const createBoard = (boardName, widgets, answer) =>
  post(`${baseURL}boards`, {
    name: boardName,
    widgets: widgets,
    fromAnswer: answer
  });

const getBoards = () => get(`${baseURL}boards`);

const renameBoard = (id, name) => patch(`${baseURL}boards/${id}`, { id, name });

const copyBoard = (id, name) => post(`${baseURL}boards/copy`, { boardId: id, copiedBoardName: name });

const getBoardPreview = id => get(`${baseURL}boards/preview/${id}`);

const publishBoardChanges = id => post(`${baseURL}boards/publish/${id}`, {});

const deleteBoard = id => del(`${baseURL}boards/${id}`);

const setBoardEnabled = (id, isEnabled) => post(`${baseURL}boards/discovery/${id}`, { isEnabled });

const moveBoardView = (id, parentId, aboveId) =>
  post(`${baseURL}boards/view/item/move/${id}`, { parentId: parentId || 'root', aboveId });

const getBoardsView = () => get(`${baseURL}boards/view`);

const createFolder = ({ name, parentId = 'root', aboveId }) =>
  post(`${baseURL}boards/view/folder`, { name, parentId: parentId || 'root', aboveId });

const renameFolder = (id, name) => post(`${baseURL}boards/view/folder/name/${id}`, { name });

const deleteFolder = id => del(`${baseURL}boards/view/folder/${id}`);

const getSharedBoardInfo = boardId => get(`${baseURL}boards/shared/${boardId}`);

const saveSharedBoard = (boardId, data) => post(`${baseURL}boards/shared/${boardId}`, data);

const stopSharingBoard = boardId => del(`${baseURL}boards/shared/${boardId}`);

const getDiscoverableBoards = () => get(`${baseURL}boards/discovery`);

const getSharedBoardSubscribers = boardId => get(`${baseURL}boards/discovery/${boardId}/subscribers`);

const getWidgetsByBoardId = boardId => get(`${baseURL}boards/${boardId}/widgets`);

export default {
  getBoards,
  renameBoard,
  copyBoard,
  getBoardPreview,
  publishBoardChanges,
  getBoardsView,
  createBoard,
  createFolder,
  renameFolder,
  moveBoardView,
  deleteFolder,
  deleteBoard,
  setBoardEnabled,
  getSharedBoardInfo,
  saveSharedBoard,
  stopSharingBoard,
  getDiscoverableBoards,
  getSharedBoardSubscribers,
  getWidgetsByBoardId
};
