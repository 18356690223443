import { baseURL } from 'config/baseURL';
import { headersPUT, headersDELETE } from './../utils/HTTPheaders';
import { authFetch } from './../utils/authFetch';
import { CHATMESSAGE_SET_FEEDBACK } from './../modules/chat-messages';

export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
export const FEEDBACK_FAILURE = 'FEEDBACK_FAILURE';
export const FEEDBACK_COMMENT = 'FEEDBACK_COMMENT';

export const feedbackStates = {
  NONE: 'NONE',
  POS: 'POS',
  NEG: 'NEG'
};

export const giveFeedBack = (answerId, isPositive) => {
  return dispatch => {
    dispatch({ type: FEEDBACK_REQUEST });

    authFetch(
      baseURL + 'feedback/' + answerId,
      {
        method: 'PUT',
        headers: headersPUT,
        body: JSON.stringify({
          isPositive: isPositive,
          sessionId: window.veezoo.sessionId
        }),
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          dispatch({ type: FEEDBACK_SUCCESS });
        } else {
          dispatch({ type: FEEDBACK_FAILURE });
        }
      })
      .catch(error => {
        dispatch({ type: FEEDBACK_FAILURE });
        console.log('ERROR', error);
      });
  };
};

export const giveFeedBackComment = (answerId, comment) => {
  return dispatch => {
    dispatch({ type: FEEDBACK_COMMENT });
    dispatch({ type: FEEDBACK_REQUEST });

    authFetch(
      baseURL + 'feedback/' + answerId,
      {
        method: 'PUT',
        headers: headersPUT,
        body: JSON.stringify({
          isPositive: false,
          text: comment,
          sessionId: window.veezoo.sessionId
        }),
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          dispatch({ type: FEEDBACK_SUCCESS });
        } else {
          dispatch({ type: FEEDBACK_FAILURE });
        }
      })
      .catch(error => {
        dispatch({ type: FEEDBACK_FAILURE });
        console.log('ERROR', error);
      });
  };
};

export const removeFeedback = answerId => {
  return dispatch => {
    dispatch({ type: FEEDBACK_REQUEST });

    authFetch(
      baseURL + 'feedback/' + answerId,
      {
        method: 'DELETE',
        headers: headersDELETE,
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          dispatch({ type: FEEDBACK_SUCCESS });
        } else {
          dispatch({ type: FEEDBACK_FAILURE });
        }
      })
      .catch(error => {
        dispatch({ type: FEEDBACK_FAILURE });
        console.log('ERROR', error);
      });
  };
};

export const setFeedback = (answerId, messageId, questionId, isPositive, wasFollowUp, oldFeedbackState) => {
  return dispatch => {
    /**
     * Optimistically set local feedback state.
     * NOTE: A rollback on network error is currently not implemented.
     */
    dispatch({
      type: CHATMESSAGE_SET_FEEDBACK,
      answerId: answerId,
      messageId: messageId,
      questionId: questionId,
      isPositive: isPositive,
      wasFollowUp: wasFollowUp,
      oldFeedbackState
    });

    switch (oldFeedbackState) {
      case feedbackStates.NONE:
        dispatch(giveFeedBack(answerId, isPositive));
        break;

      case feedbackStates.POS:
        isPositive ? dispatch(removeFeedback(answerId)) : dispatch(giveFeedBack(answerId, isPositive));
        break;

      case feedbackStates.NEG:
        isPositive ? dispatch(giveFeedBack(answerId, isPositive)) : dispatch(removeFeedback(answerId));
        break;
    }
  };
};

const defaultRecentFeedback = {
  wasNegative: false,
  wasFollowUp: false
};

export const recentFeedback = (state = defaultRecentFeedback, action) => {
  switch (action.type) {
    case CHATMESSAGE_SET_FEEDBACK:
      return {
        ...state,
        answerId: action.answerId,
        questionId: action.questionId,
        messageId: action.messageId,
        wasFollowUp: action.wasFollowUp,
        wasNegative: !action.isPositive && action.oldFeedbackState !== feedbackStates.NEG
      };

    case FEEDBACK_COMMENT:
      return defaultRecentFeedback;

    default:
      return state;
  }
};
