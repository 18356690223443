import React, { memo } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import styles from './styles.scss';

const SmartFixIndicator = ({ style, className, t }) => {
  return (
    <InfoTooltip text={t('smart-fixes.available-tooltip')}>
      <div
        className={clsx(styles.indicatorContainer, className)}
        {...(style ? { style } : {})}
        data-test="SmartFixIndicator"
      >
        <div className={styles.indicator}></div>
      </div>
    </InfoTooltip>
  );
};

export default withTranslation('veezoo')(memo(SmartFixIndicator));
