export default function svgNode(color, icon = null, opacity = 1) {
  const svg =
    '<svg xmlns="http://www.w3.org/2000/svg" height="100" width="100">' +
    '<defs>' +
    '<linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">' +
    '<stop offset="0%" styleName="node-color-top" style="stop-color: ' +
    color +
    '; stop-opacity:' +
    opacity +
    '" />' +
    '<stop offset="100%" style="stop-color:' +
    color +
    ';stop-opacity:' +
    opacity +
    '" />' +
    '</linearGradient>' +
    '</defs>' +
    '<circle cx="50" cy="50" r="40" stroke="#bcc7ce" stroke-width="0" fill="url(#grad1)" /> ' +
    (icon ? '<image x="30" y="30" width="40" height="40" href="' + icon + '" />' : '') +
    '</svg>';
  return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
}
