import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper: {
    borderRadius: '5px'
  }
});

const Modal = ({ children, maxWidth, closeModal, open }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={classes}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onBackdropClick={closeModal}
      onEscapeKeyDown={closeModal}
    >
      <Paper>{children}</Paper>
    </Dialog>
  );
};

Modal.propTypes = {
  maxWidth: PropTypes.string,
  closeModal: PropTypes.func,
  open: PropTypes.bool
};

Modal.defaultProps = {
  maxWidth: 'sm',
  open: false
};

export default Modal;
