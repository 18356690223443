import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import UITextField from '@material-ui/core/TextField';
import UIInputAdornment from '@material-ui/core/InputAdornment';

const TextField = forwardRef(
  ({ label, startIcon, endIcon, isRequired, classes: { input }, InputProps, title, ...rest }, ref) => {
    const useStyles = makeStyles(() => ({
      root: {
        width: '100%'
      },
      inputRoot: {
        borderBottom: 'solid 2px var(--primary-light-color)',
        '&&.Mui-error': {
          border: `solid thin red`
        }
      },
      input: {
        ...input,
        fontSize: '14px'
      },
      inputUnderline: {
        '&&&:before': {
          borderBottom: 'none'
        },
        '&&:after': {
          borderBottom: 'none'
        }
      },
      labelRoot: {
        color: 'gray',
        '&&+.MuiInput-formControl': {
          marginTop: label && label !== '' ? '20px' : 0
        },
        '&&.Mui-focused': {
          color: 'gray'
        }
      },
      adornmentRoot: {
        color: 'rgba(0, 0, 0, 0.54)'
      },
      formHelperTextRoot: {
        margin: '4px 15px 4px'
      }
    }));

    const classes = useStyles();

    const setAdornment = (icon, position) => {
      return (
        <UIInputAdornment classes={{ root: classes.adornmentRoot }} position={position}>
          {icon}
        </UIInputAdornment>
      );
    };

    return (
      <div>
        <UITextField
          {...(label ? { label: `${label} ${isRequired ? '*' : ''}` } : {})}
          classes={{ root: classes.root }}
          InputProps={{
            ...InputProps,
            classes: { root: classes.inputRoot, input: classes.input, underline: classes.inputUnderline },
            startAdornment: startIcon ? setAdornment(startIcon, 'start') : null,
            endAdornment: endIcon ? setAdornment(endIcon, 'end') : null
          }}
          inputProps={{
            ref,
            title
          }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot
            },
            shrink: true
          }}
          FormHelperTextProps={{
            classes: { root: classes.formHelperTextRoot }
          }}
          {...rest}
        />
      </div>
    );
  }
);

const iconProps = PropTypes.oneOfType([PropTypes.element, PropTypes.string]);

TextField.propTypes = {
  label: PropTypes.string,
  startIcon: iconProps,
  endIcon: iconProps,
  classes: PropTypes.object,
  InputProps: PropTypes.object,
  isRequired: PropTypes.bool,
  title: PropTypes.string
};

TextField.defaultProps = {
  label: '',
  classes: {},
  isRequired: false,
  title: 'Input Text Field'
};

export default TextField;
