import { makeStyles } from '@material-ui/core/styles';

import { layouts } from 'config/constants';

export const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    padding: '6px 12px',
    backgroundColor: 'rgba(97, 97, 97, 0.95)',
    fontSize: 14,
    fontWeight: 'normal',
    color: 'white',
    letterSpacing: '0.3px'
  }
}));

export const useTooltipContainerStyles = makeStyles(() => ({
  root: {
    display: 'inline-block'
  }
}));

export const useIconStyles = makeStyles(() => ({
  root: {
    fontSize: ({ layout, size }) => {
      if (size) return size;
      if (layout === layouts.signup) return 14;
      if (layout === layouts.veezoo) return 15;
      return 11;
    },
    color: ({ layout }) => (layout === layouts.signup ? 'var(--light-blue)' : 'inherit'),
    verticalAlign: 'sub',
    marginLeft: '4px'
  }
}));
