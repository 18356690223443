export const getErrorMessage = error => (error && error.message) || '';

export const getErrorInfoByFieldId = (errors, fieldId) => {
  const splitFields = fieldId.split('.');

  const result = splitFields.reduce((acc, field) => {
    if (acc) {
      return acc[field];
    }
    return acc;
  }, errors);

  return result;
};

export const validation = {
  required: (field = 'This field', required = true) => ({
    value: required?.value || required,
    message: required?.message || `${field} is required`
  }),
  maxLength: (length = 20) => ({
    value: length?.value || length,
    message: length?.message || `Max length is ${length}`
  }),
  pattern: (field = 'This field', pattern) => ({
    value: new RegExp(pattern.value || pattern),
    message: pattern?.message || `${field} should follow this pattern: ${pattern}`
  }),
  isNumber: (field = 'This field', isNumber = true) => ({
    value: isNumber.value || true,
    message: isNumber.message || `${field} should just contain numbers`
  })
};
