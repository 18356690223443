import React, { forwardRef, useState, memo } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import UIInputAdornment from '@material-ui/core/InputAdornment';

import LoadingIcon from 'svg/v2/loading.svg';

import { useStyles, useSelectStyles, useMenuStyles, useMenuIconStyles } from './SidebarFooterSelect.styles';

import styles from 'components/sidebar/sidebar.scss';
import classNames from 'classnames';

const SidebarFooterSelect = forwardRef(
  ({ startIcon, items, showEmptyOption, minimized, disabled, loading, value, onChange, title, ...rest }, ref) => {
    const classes = useStyles({ minimized });
    const selectClasses = useSelectStyles({ minimized });
    const menuClasses = useMenuStyles();
    const menuIconClasses = useMenuIconStyles();

    const [open, setOpen] = useState(false);

    const setAdornment = (icon, position) => (
      <UIInputAdornment onClick={() => setOpen(true)} classes={{ root: classes.adornmentRoot }} position={position}>
        {icon}
      </UIInputAdornment>
    );

    const selectedIsDevelopmentKg = items?.some(kg => kg.value === value && kg.isDevelopmentKg);

    return (
      <TextField
        select
        value={value}
        onChange={onChange}
        classes={{ root: classes.root }}
        label=""
        InputProps={{
          classes: {
            root: classNames(classes.selectRoot, { [classes.devKgRoot]: selectedIsDevelopmentKg }),
            underline: classes.selectUnderline
          },
          startAdornment: startIcon ? setAdornment(startIcon, 'start') : null
        }}
        inputProps={{ title }}
        SelectProps={{
          classes: selectClasses,
          open: open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
          MenuProps: {
            classes: menuClasses,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'center', horizontal: 'right' },
            transformOrigin: { vertical: 'center', horizontal: 'left' },
            // add the 'select-popover' class for e2e tests
            PopoverClasses: { root: 'select-popover' }
          }
        }}
        InputLabelProps={{
          classes: { root: classes.labelRoot },
          shrink: true
        }}
        disabled={disabled}
        ref={ref}
        title={title}
        {...rest}
      >
        {showEmptyOption && <MenuItem value="">--</MenuItem>}
        {items.map((item, index) => (
          <MenuItem key={`item_${index}`} value={item.value}>
            {loading ? (
              <LoadingIcon className={styles.loadingIcon} />
            ) : (
              <>
                {item.icon && <ListItemIcon classes={menuIconClasses}>{item.icon}</ListItemIcon>}
                <span className="text">{item.name}</span>
              </>
            )}
          </MenuItem>
        ))}
      </TextField>
    );
  }
);

const iconProps = PropTypes.oneOfType([PropTypes.element, PropTypes.string]);

SidebarFooterSelect.propTypes = {
  loading: PropTypes.bool,
  startIcon: iconProps,
  items: PropTypes.array,
  showEmptyOption: PropTypes.bool,
  minimized: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string
};

SidebarFooterSelect.defaultProps = {
  items: [],
  loading: false,
  disabled: false,
  title: ''
};

export default memo(SidebarFooterSelect);
