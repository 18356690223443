import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/Signup/Button';

import { setFinishedSignup } from 'store/modules/user';
import { fetchKnowledgeGraphs } from 'store/modules/knowledgeGraph';

import useMediaQuery from 'utils/mediaQueries';

import styles from './index.module.scss';

const useStyles = makeStyles({
  root: {
    width: ({ isMobile }) => (isMobile ? 60 : 80),
    height: ({ isMobile }) => (isMobile ? 60 : 80),
    color: 'var(--light-blue)'
  }
});

const Completed = ({ dispatch }) => {
  const isMobile = useMediaQuery();
  const iconClasses = useStyles({ isMobile });
  const history = useHistory();

  useEffect(() => {
    dispatch(setFinishedSignup());
    dispatch(fetchKnowledgeGraphs());
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <DoneAllIcon classes={iconClasses} />
      </div>
      <div className={styles.header}>You&apos;re all set!!</div>
      <div className={styles.description}>
        We have finished your registration, and your first Knowledge Graph is ready to use!
        <br />
        Click the button below to start asking question, and welcome to Veezoo!
      </div>
      <div className={styles.centerButtonContainer}>
        <Button onClick={() => history.push('/chat')} size="large" color="dark" data-test="startAskingQuestionsButton">
          Start Asking Questions!
        </Button>
      </div>
    </div>
  );
};

export default connect()(Completed);
