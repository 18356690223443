import { getSharedBoardInfo } from 'store/modules/board';
import { getCustomerSelectionSharedInfo } from './customers-of-the-day';

export const SHOW_SHARED_BOARD_MODAL = 'SHOW_SHARED_BOARD_MODAL';
export const HIDE_SHARED_BOARD_MODAL = 'HIDE_SHARED_BOARD_MODAL';

export const SHOW_SHARED_CUSTOMER_SELECTION_MODAL = 'SHOW_SHARED_CUSTOMER_SELECTION_MODAL';
export const HIDE_SHARED_CUSTOMER_SELECTION_MODAL = 'HIDE_SHARED_CUSTOMER_SELECTION_MODAL';

export const showSharedBoardModal = boardId => dispatch => {
  dispatch(getSharedBoardInfo({ boardId }));
  dispatch({ type: SHOW_SHARED_BOARD_MODAL, id: boardId });
};

export const showSharedCustomerSelectionModal = id => dispatch => {
  dispatch(getCustomerSelectionSharedInfo(id));
  dispatch({ type: SHOW_SHARED_CUSTOMER_SELECTION_MODAL, id });
};

export const hideSharedBoardModal = () => ({ type: HIDE_SHARED_BOARD_MODAL });
export const hideSharedCustomerSelectionModal = () => ({ type: HIDE_SHARED_CUSTOMER_SELECTION_MODAL });

// describe a board / customer selection reducer
export const initState = {
  displaySharedCustomerSelectionModal: false,
  displaySharedBoardModal: false,
  modalForId: undefined
};

export function sharedModal(state = initState, action) {
  switch (action.type) {
    case SHOW_SHARED_BOARD_MODAL:
      return {
        ...state,
        displaySharedBoardModal: true,
        modalForId: action.id
      };

    case HIDE_SHARED_BOARD_MODAL:
      return {
        ...state,
        displaySharedBoardModal: false,
        modalForId: undefined
      };

    case SHOW_SHARED_CUSTOMER_SELECTION_MODAL:
      return {
        ...state,
        displaySharedCustomerSelectionModal: true,
        modalForId: action.id
      };

    case HIDE_SHARED_CUSTOMER_SELECTION_MODAL:
      return {
        ...state,
        displaySharedCustomerSelectionModal: false,
        modalForId: undefined
      };

    default:
      return state;
  }
}
