import { tableColumnTypes } from 'config/constants';

import EntityFilter from './EntityFilter';
import DateFilter from './DateFilter';
import BooleanFilter from './BooleanFilter';
import TemplateFilter from './TemplateFilter';

const ColumnFilter = ({ answer, filterState, handleFilterClose, updatePositionRef }) => {
  return (
    <>
      {filterState.column?.type === tableColumnTypes.DATE_TIME ? (
        <DateFilter
          answer={answer}
          column={filterState.column}
          onClose={handleFilterClose}
          onConfirm={handleFilterClose}
          updatePositionRef={updatePositionRef}
        />
      ) : filterState.column?.type === tableColumnTypes.BOOLEAN ? ( // Add condition for BOOLEAN type
        <BooleanFilter
          answer={answer}
          column={filterState.column}
          booleanCandidateId={filterState.column.identifier}
          onClose={handleFilterClose}
        />
      ) : filterState.column?.type === tableColumnTypes.NUMBER ||
        filterState.column?.type === tableColumnTypes.STRING ? (
        <TemplateFilter
          answer={answer}
          column={filterState.column}
          onClose={handleFilterClose}
          onConfirm={handleFilterClose}
        />
      ) : (
        <EntityFilter
          answer={answer}
          column={filterState.column}
          onConfirm={handleFilterClose}
          onClose={handleFilterClose}
        />
      )}
    </>
  );
};

export default ColumnFilter;
