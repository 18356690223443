import { studioURL } from 'config/baseURL';
import { get } from './utils.js';

const autocompleteEditor = (kgId, prefix) =>
  get(`${studioURL}knowledge-graphs/${encodeURIComponent(kgId)}/editor/autocomplete`, { prefix });

const getDocumentation = keyword => get(`${studioURL}editor/documentation/${encodeURIComponent(keyword)}`);

export default {
  autocompleteEditor,
  getDocumentation
};
