import { makeStyles, createTheme } from '@material-ui/core';

const borderRadius = 2;

export const getDatePickerTheme = ({ fullWidth, isWeek }) =>
  createTheme({
    overrides: {
      MuiPickersMonth: {
        root: { fontSize: 14 }
      },
      MuiPickersYear: {
        root: { fontSize: 14 },
        yearSelected: { fontSize: 18 }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: 'var(--primary-color)'
        }
      },
      MuiFormControl: {
        root: {
          width: () => (fullWidth ? '100%' : 'initial')
        }
      },
      MuiInputLabel: {
        root: {
          fontFamily: 'lexend',
          fontSize: '12px',
          fontWeight: 400
        },
        shrink: {
          transform: 'none !important',
          position: 'static'
        }
      },
      MuiOutlinedInput: {
        root: {
          border: 'solid thin var(--light-gray)',
          borderRadius
        },
        input: {
          color: 'var(--font-color)',
          fontFamily: 'lexend',
          fontSize: 14,
          padding: '8px 12px',
          lineHeight: 20
        },
        adornedEnd: {
          paddingRight: 0
        },
        notchedOutline: {
          borderColor: 'transparent',
          borderRadius,
          top: 0,
          '& > legend': {
            display: 'none'
          }
        }
      },
      MuiInputBase: {
        root: {
          width: '100%',
          marginTop: ({ label }) => (label ? 8 : 'initial'),
          '&$focused': {
            border: 'solid 0px transparent',
            '&>.MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--light-gray) !important',
              borderWidth: '1px !important'
            }
          },
          '&$disabled': {
            color: 'rgba(0, 0, 0, 0.18)'
          }
        }
      },
      MuiInputAdornment: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)'
        }
      },
      MuiFormHelperText: {
        root: {
          fontSize: 12,
          fontWeight: 400
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          padding: 0
        },
        dayLabel: {
          width: 50
        }
      },
      MuiPickersCalendar: {
        week: {
          borderTop: isWeek ? 'solid thin #eeeeee' : 'none',
          '&:hover': {
            backgroundColor: isWeek ? 'rgba(0, 0, 0, 0.04)' : 'transparent'
          }
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: 400,
          minHeight: 350
        },
        pickerViewLandscape: { padding: () => (isWeek ? '8px 8px 8px 16px' : '0 8px') }
      },
      MuiTypography: {
        body1: {
          fontSize: 14
        },
        body2: {
          fontFamily: 'Lexend',
          fontSize: 14,
          fontWeight: 300
        },
        subtitle1: {
          fontSize: 14
        },
        caption: {
          fontSize: 12
        }
      }
    }
  });

export const useCalendarDayStyles = makeStyles({
  day: {
    width: 50,
    height: 40,
    padding: '0',
    margin: '0 2px',
    borderRadius: 4,
    fontFamily: 'Lexend',
    fontSize: 14,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: ({ isWeek }) => (isWeek ? 'transparent' : 'rgba(0, 0, 0, 0.04)')
    }
  },
  daySelected: {
    color: 'white'
  },
  nonCurrentMonthDay: {
    color: '#dddddd'
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    backgroundColor: 'var(--primary-color)'
  },
  firstHighlight: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  endHighlight: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  },
  uniqueDayHighlight: {
    borderRadius: 4
  },
  wrapperContainer: {
    position: 'relative'
  },
  weekLabel: {
    top: '10px',
    left: '-10px',
    color: 'white',
    backgroundColor: 'var(--primary-color)',
    width: 19,
    height: 18,
    border: 'solid thin white',
    cursor: 'pointer',
    display: 'flex',
    position: 'absolute',
    fontSize: '11px',
    alignItems: 'center',
    borderRadius: '50%',
    justifyContent: 'center'
  }
});

export default makeStyles({
  arrow: {
    width: 20,
    height: 20,
    fill: 'var(--primary-color)',
    '& > path': {
      fill: 'var(--primary-color);'
    }
  }
});
