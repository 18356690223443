import { makeStyles } from '@material-ui/core';

const color = 'var(--primary-color)';

export default makeStyles({
  colorSecondary: {
    '&&.Mui-checked': { color },
    '&&.Mui-checked + .MuiSwitch-track': { backgroundColor: color }
  }
});
