import { connect } from 'react-redux';
import clsx from 'clsx';

import Title from 'components/Signup/Title';
import Stepper from 'components/Signup/Stepper';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

import ExcelConnector from './ExcelConnector';
import ApplicationConnector from './ApplicationConnector';
import TroubleConnectingMessage from 'components/TroubleConnectingMessage';

import KnowledgeGraphDBConnectionForm from 'forms/KnowledgeGraphDBConnectionForm/KnowledgeGraphDBConnectionForm';

import { layouts } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';
import imageImport from 'images';

import signupStyles from 'pages/Signup/signup.styles.scss';

import { handleError } from 'services/utils';

const CreateKnowledgeGraph = props => {
  const {
    knowledgeGraph,
    setKnowledgeGraph,
    setStep,
    connectors,
    connector,
    languages,
    withLoader,
    loading,
    successMessage,
    errorMessage,
    showBackButton,
    dispatch
  } = props;

  const isMobile = useMediaQuery();

  let additionalButtons = [];
  if (showBackButton) {
    additionalButtons = [
      {
        label: 'Back',
        onClick: () => setStep(step => step - 1)
      }
    ];
  }

  const languagesForSelectComponent = languages.map(item => ({
    label: item.data.name,
    value: item.data.id
  }));

  const postConnection = () => setStep(step => step + 1);

  const signupErrorHandling = payload => handleError(payload, dispatch);

  return (
    <StepperContainer customStepper={<Stepper step={3} totalSteps={4} />}>
      <div className={signupStyles.centerBothDirectionsParent}>
        <>
          {connector.type === 'DATABASE_CONNECTOR' && (
            <div className={signupStyles.centerBothDirectionChild}>
              <Title image={imageImport(connector.id)}>Connect your data</Title>
              <div className={clsx(signupStyles.mt_5, signupStyles.mb_20)}>
                <div className={signupStyles.subtitleInstructions}>
                  Ensure that the following IP address of Veezoo is whitelisted by your database: <b>18.158.77.139</b>
                </div>

                {connector?.docsUrl && (
                  <div className={clsx(signupStyles.mt_5, signupStyles.subtitleInstructions)}>
                    Check our guide on{' '}
                    <a target="_blank" rel="noreferrer" href={connector.docsUrl}>
                      how to connect to {connector.name}.
                    </a>
                  </div>
                )}
              </div>

              <KnowledgeGraphDBConnectionForm
                databases={connectors}
                initialDatabase={connector}
                postConnection={postConnection}
                loading={loading}
                loadingMessage={loading.message}
                successMessage={successMessage}
                errorMessage={errorMessage}
                languages={languagesForSelectComponent}
                isMobile={isMobile}
                additionalButtons={additionalButtons}
                knowledgeGraph={knowledgeGraph}
                setKnowledgeGraph={setKnowledgeGraph}
                layout={layouts.signup}
                withLoader={withLoader}
                handleError={signupErrorHandling}
                disableDatabaseSelect
                showKnowledgeGraphFields
              />
              <TroubleConnectingMessage blue />
            </div>
          )}
          {connector.type === 'APPLICATION_CONNECTOR' && (
            <ApplicationConnector
              languages={languagesForSelectComponent}
              connector={connector}
              setStep={setStep}
              {...props}
            />
          )}
          {connector.type === 'EXCEL_CONNECTOR' && (
            <ExcelConnector languages={languagesForSelectComponent} setStep={setStep} {...props} />
          )}
        </>
      </div>
    </StepperContainer>
  );
};

const mapStateToProps = state => ({ languages: state.languages.data });

export default connect(mapStateToProps)(CreateKnowledgeGraph);
