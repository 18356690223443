import { makeStyles } from '@material-ui/core/styles';

// the maximum number of levels to support
const maxLevels = 20;
// sequence of level indices: 0, 1, 2, ..., maxLevels - 1
const levels = Array(maxLevels)
  .fill()
  .map((element, index) => index);
// the base z-index for sticky tree items of the different levels
const zIndexBase = 1000;
// the height of the tree item
const itemHeight = 26;

/**
 * Returns the CSS to be applied to the sticky tree item's root element, which specifies for each level:
 *   - the correct 'top' given the parent item heights
 *   - the correct 'z-index'
 *
 * Example CSS returned:
 *   {
 *     '& .MuiTreeItem-root .MuiTreeItem-root > .MuiTreeItem-content': { top: 26, zIndex: 99 },
 *     '& .MuiTreeItem-root .MuiTreeItem-root .MuiTreeItem-root > .MuiTreeItem-content': { top: 2 * 26, zIndex: 98, },
 *     '& .MuiTreeItem-root .MuiTreeItem-root .MuiTreeItem-root .MuiTreeItem-root > .MuiTreeItem-content': { top: 3 * 26, zIndex: 97, },
 *     ...
 *   }
 *
 * @param itemHeights array, indexed by level index, allowing to override the tree item height for each level
 */
export const levelsStickyCss = (itemHeights = []) => {
  let top = 0;
  // iterate over the levels starting at the second
  return levels.slice(1).reduce((css, level) => {
    const selector = `& ${'.MuiTreeItem-root '.repeat(level + 1)} > .MuiTreeItem-content`;
    // add the height of the tree item from the previous level
    top += itemHeights[level - 1] || itemHeight;
    return {
      ...css,
      [selector]: {
        top: top,
        zIndex: zIndexBase + maxLevels - level
      }
    };
  }, {});
};

/**
 * Returns the CSS to be applied to the tree item's group element, which specifies the correct indentation for each
 * level.
 *
 * Example CSS returned:
 *   {
 *     '& $content': { paddingLeft: theme.spacing(1.5) },
 *     '& $group $content': { paddingLeft: theme.spacing(2 * 1.5) },
 *     '& $group $group $content': { paddingLeft: theme.spacing(3 * 1.5) },
 *     ...
 *   }
 *
 * This defines two additional CSS classes that can be used to style the tree items:
 * - disabled: to style disabled tree items
 * - selected: to style selected tree items
 * Use the className property on the TreeItem component to apply these styles.
 *
 * @param indentation the indentation
 */
const levelsIndentCss = indentation =>
  // iterate over the levels starting at the second
  levels.slice(1).reduce((css, level) => {
    const selector = `& ${'$group '.repeat(level - 1)} $content`;
    return {
      ...css,
      [selector]: {
        paddingLeft: level * indentation
      }
    };
  }, {});

export default makeStyles(theme => ({
  root: {
    '& .MuiTreeItem-content': {
      position: 'sticky',
      top: 0,
      zIndex: zIndexBase + maxLevels
    },
    ...levelsStickyCss(),
    '& .MuiCollapse-wrapperInner': {
      zIndex: 0
    }
  },
  item: {
    color: theme.palette.text.primary,
    '& > $content': {
      backgroundColor: theme.palette.background.paper
    },
    '& > $content:hover, &.selected > $content': {
      backgroundColor: theme.palette.type === 'light' ? '#f0f0f0' : '#515151',
      '& $secondaryLabel.hideOnHover': {
        display: 'none'
      },
      '& $actions': {
        display: 'block'
      }
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent !important'
    },
    '&.disabled > $content': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none'
    },
    '& .MuiTreeItem-iconContainer': {
      width: 14,
      boxSizing: 'content-box',
      paddingLeft: 4,
      paddingRight: 4,
      marginRight: -2,
      '& svg:not(.MuiCircularProgress-svg)': {
        fontSize: 14
      },
      height: '100%',
      alignItems: 'center'
    }
  },
  content: {
    height: itemHeight
  },
  group: {
    marginLeft: 0,
    ...levelsIndentCss(theme.spacing(1.5))
  },
  expanded: {},
  selected: {},
  label: {
    flexGrow: 1,
    width: 'auto',
    minWidth: 0,
    paddingLeft: 0,
    marginRight: theme.spacing(1)
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    paddingTop: 2,
    paddingBottom: 2
  },
  labelIcon: {
    marginRight: 6,
    color: theme.palette.text.secondary,
    width: 18,
    minWidth: 18,
    height: 18,
    padding: 2
  },
  primaryLabel: {
    fontWeight: 'inherit',
    flexGrow: 1,
    fontSize: 13,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: theme.spacing(1)
  },
  secondaryLabel: {
    color: theme.palette.text.disabled,
    lineHeight: 'unset'
  },
  actions: {
    display: 'none',
    whiteSpace: 'nowrap',
    marginRight: -theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      width: 14,
      height: 14,
      color: theme.palette.text.secondary
    },
    '& .MuiIconButton-root': {
      padding: 2,
      margin: -1
    }
  }
}));
