import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';

import PageLayout from 'layouts/PageLayout';

import useMediaQuery from 'utils/mediaQueries';

import { TextField } from 'components/v3';

import Button from 'components/Signup/Button';
import Title from 'components/Signup/Title';

import { resetPasswordMail } from 'store/modules/user';

import { withTranslation } from 'react-i18next';

import styles from './index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';
import clsx from 'clsx';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

const validationRules = yup.object().shape({
  email: yup
    .string()
    .required('E-mail is required')
    .email('Format should be like: email@domain.com')
});

const defaultValues = {
  email: ''
};

const getErrorMessage = error => {
  return (error && error.message) || '';
};

const ForgotPassword = ({ dispatch, isSendingResetMail, passwordResetMailTried, passwordResetMailSucceeded, t }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const history = useHistory();
  const inputRef = useRef(null);
  const isMobile = useMediaQuery();

  const { handleSubmit, control, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationRules)
  });

  const message = useMemo(() => {
    if (isSubmitted && passwordResetMailTried && !passwordResetMailSucceeded) {
      return t('error-sending-reset-link');
    }
    if (isSubmitted && passwordResetMailSucceeded) {
      return t('email-sent');
    }
    return '';
  }, [isSendingResetMail, passwordResetMailTried, passwordResetMailSucceeded, t, isSubmitted]);

  const onSubmit = useCallback(
    ({ email }) => {
      setIsSubmitted(true);
      dispatch(resetPasswordMail(email));
    },
    [setIsSubmitted]
  );

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const messageStyle = passwordResetMailSucceeded ? styles.correctCredentials : styles.wrongCredentials;

  const messageDiv = <div className={messageStyle}>{message}</div>;

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={signupStyles.mt_40}>
        <div className={signupStyles.formField}>
          <Controller
            as={<TextField layout="signup" ref={inputRef} />}
            name="email"
            control={control}
            label="Email"
            error={Boolean(errors.email)}
            helperText={getErrorMessage(errors.email)}
          />
        </div>
      </div>
      <div className={clsx(signupStyles.mt_40, signupStyles.sendButton)}>
        <Button type="submit" size="large" color="dark">
          Send me a Reset Link
        </Button>
        {messageDiv}
      </div>
    </form>
  );

  return (
    <PageLayout>
      <StepperContainer customStepper={<></>}>
        <div className={signupStyles.loginButton}>
          <Button
            onClick={() => history.push('/signup')}
            size={isMobile ? 'large' : 'fontLarge'}
            color="simple"
            data-test="gotoSignupButton"
          >
            Sign up
          </Button>
        </div>
        <div className={signupStyles.centerBothDirectionsParent}>
          <div className={signupStyles.centerBothDirectionChild}>
            <div>
              <Title>Reset Password</Title>
              <div className={signupStyles.subTitle}>
                Tell us your e-mail, and we will send you a link to reset your password.
              </div>
            </div>
            <div>
              {isSubmitted && passwordResetMailSucceeded // if already successfully submitted, just show the message
                ? messageDiv
                : form}
            </div>
          </div>
        </div>
      </StepperContainer>
    </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    passwordResetMailTried: state.user.passwordResetMailTried,
    passwordResetMailSucceeded: state.user.passwordResetMailSucceeded,
    isSendingResetMail: state.network.isFetchingPasswordResetMail
  };
};

export default memo(withTranslation('veezoo')(connect(mapStateToProps)(ForgotPassword)));
