import { combineReducers } from 'redux';

import { network } from './modules/network';

import { user } from './modules/user';

import { localization } from './modules/localization';

import { passwordReset } from './modules/passwordReset';

import { annotatedQuestion } from './modules/annotatedQuestion';

import { chatMessages } from './modules/chat-messages';

import { discovery } from './modules/discovery';

import { expandedMiniWidgets } from './modules/expandedMiniWidgets';

import { pills } from './modules/pills';

import { modifiedVisualizations } from './modules/visualizations';

import { theme } from './modules/theme';

import { languages } from './modules/languages';

import { recentFeedback } from './modules/recentFeedback';

import { inputAnalysis } from './modules/inputAnalysis';

import { partialAnswers } from './modules/partialAnswers';

import { tutorial } from './modules/tutorial';

import { tests } from './modules/questionTests';

import { followUpState } from './modules/followUpState';

import { board } from './modules/board';

import { recipes } from './modules/recipes';

import { sharedModal } from './modules/sharedModal';

import { boardWidgets } from './modules/board-widgets';

import { customersOfTheDay } from './modules/customers-of-the-day';

import { customerSelections } from './modules/customer-selections';

import { exception } from './modules/exception';

// graph reducers
import { graph } from './modules/graph/graph';

import { knowledgeGraphMeta } from './modules/graph/knowledgeGraphMeta';

import { knowledgeGraphs, knowledgeGraph } from './modules/knowledgeGraph';

import { graphItemClass } from './modules/graph/graphItemClass';

import { literalInfo } from './modules/graph/literalInfo';
import { measureInfo } from './modules/graph/measureInfo';

import { graphSearchResults } from './modules/graph/graphSearchResults';

import { graphItemEntities } from './modules/graph/graphItemEntities';

import { graphDetailedEntitiesInfo } from './modules/graph/graphDetailedEntitiesInfo';
import { sharing } from 'store/modules/sharing';

// component reducers
import { nodeEditor } from './modules/nodeEditor';
import { answerContextMenu } from 'store/modules/answerContextMenu';

const rootReducer = combineReducers({
  // user reducers
  user,
  localization,
  network,
  passwordReset,

  board,
  boardWidgets,
  annotatedQuestion,
  chatMessages,
  discovery,
  expandedMiniWidgets,
  followUpState,
  inputAnalysis,
  answerContextMenu,

  partialAnswers,
  modifiedVisualizations,
  pills,
  recentFeedback,
  theme,
  languages,
  tutorial,
  tests,
  sharedModal,
  sharing,
  customersOfTheDay,
  customerSelections,

  // recipes
  recipes,

  // exceptions
  exception,

  // graph reducers
  graph,
  knowledgeGraphMeta,
  knowledgeGraphs,
  knowledgeGraph,
  graphItemClass,
  literalInfo,
  measureInfo,
  graphSearchResults,
  graphItemEntities,
  graphDetailedEntitiesInfo,

  // component reducers
  nodeEditor
});

export default rootReducer;
