import { useRef, memo } from 'react';
import { connect } from 'react-redux';

import NodeContent from './NodeContent';
import PopoverButton from 'components/Popover/PopoverButton';

import { setIsPopoverOpen } from 'store/modules/nodeEditor';

const NodeEditorPopover = ({ uniqueId, PopoverProps, ContentProps, nodeEditor, dispatch }) => {
  const updatePositionRef = useRef(null);

  // ms to wait before showing the popup, if not already another popup is open
  const initialPopoverDelay = 400;

  // specifies whether the popover is open or not (can be force to be open by the external 'forceOpen' property)
  const { isEditingTitle, isEditingDescription, isEditingSynonyms, isPopoverOpen } = nodeEditor;

  // store the information if a popup is open or not globally and execute defined existing callback (if it exists)
  const onOpened = () => {
    dispatch(setIsPopoverOpen(true));
    if (PopoverProps.onOpened) {
      PopoverProps.onOpened();
    }
  };

  const onClosed = () => {
    dispatch(setIsPopoverOpen(false));
    if (PopoverProps.onClosed) {
      PopoverProps.onClosed();
    }
  };

  return (
    <PopoverButton
      updatePositionRef={updatePositionRef}
      allowBackgroundInteraction
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 35, // align title of popup with item in sidebar
        horizontal: 'left'
      }}
      isEditing={isEditingTitle || isEditingDescription || isEditingSynonyms}
      delay={isPopoverOpen ? 0 : initialPopoverDelay} // only delay the popover if no popover is open yet
      {...PopoverProps}
      // Overwrite callbacks existing in PopoverProps
      onOpened={onOpened}
      onClosed={onClosed}
    >
      <NodeContent updatePositionRef={updatePositionRef} uniqueId={uniqueId} {...ContentProps} />
    </PopoverButton>
  );
};

const mapStateToProps = state => ({ nodeEditor: state.nodeEditor });

export default connect(mapStateToProps)(memo(NodeEditorPopover));
