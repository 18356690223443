import { Component } from 'react';
import { boundMethod } from 'autobind-decorator';
import { MicrophoneIcon, MicrophoneIconDisabled } from './Icons';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { withTranslation } from 'react-i18next';

import styles from './VoiceRecognition.scss';

/**
 * This code is based on the legacy Speech.react.js, which was in turn based on
 * https://www.google.com/intl/en/chrome/demos/speech.html.
 */

class VoiceRecognition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      available: false,
      debug: this.props.debug,
      activated: true,
      running: false,
      valueTab: [],
      expectedValue: '',
      isDisabled: false
    };

    this.detector = this.detector.bind(this);
    this.toggleFeature = this.toggleFeature.bind(this);
    this._setup = this._setup.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.detector();
  }

  componentDidMount() {
    const { available, activated } = this.state;
    if (available && activated) {
      this._setup();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { start, stop, abort, isComponentOnTest, expectedValue } = nextProps;

    if (isComponentOnTest && expectedValue) {
      this.setState({ isComponentOnTest: true, expectedValue: expectedValue, running: true });
    } else if (start && !stop && !abort) {
      this.setState({ running: true, abort: false });
    } else if (start && !stop && abort) {
      this.setState({ abort: true });
    } else if (start && stop && !abort) {
      this.setState({ running: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.running !== nextState.running || this.state.abort !== nextState.abort;
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { running, abort, activated, isComponentOnTest, valueTab, expectedValue } = nextState;
    const [value] = valueTab;

    if (isComponentOnTest && value) {
      this.props.onTestResult(isComponentOnTest && value === expectedValue);
    }
    if (activated) {
      if (!running && !abort) {
        this.recognition.stop();
      } else if (running && !abort) {
        this.recognition.lang = this.props.locale;
        this.recognition.start();
      } else if (running && abort) {
        this.recognition.abort();
      }
    }
  }

  detector() {
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || null;
    const flag = !!window.SpeechRecognition;
    this.setState({ available: flag });
  }

  toggleFeature() {
    this.setState({ activated: !this.state.activated });
  }

  @boundMethod
  toogleVoiceButtonState() {
    if (!this.state.isDisabled) {
      this.setState({
        running: !this.state.running,
        abort: false
      });
    }
  }

  _setup() {
    this.tempValue = [];
    // already initialized ?
    if (this.recognition && true) {
      return;
    }
    const that = this;

    this.recognition = new window.SpeechRecognition();
    this.recognition.lang = this.props.locale;

    this.recognition.interimResults = true;
    this.recognition.onstart = function() {
      if (that.props.debug) {
        console.log('voice recognition', '---'); // eslint-disable-line
        console.log('voice recognition', 'speech: has been activated'); // eslint-disable-line
      }
    };

    this.recognition.onresult = function(event) {
      let interimTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          that.tempValue.push(event.results[i][0].transcript);
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
      if (interimTranscript.length > 0) {
        that.props.onInterimResult(capitalize(interimTranscript));
      }
    };

    const firstChar = /\S/;

    function capitalize(s) {
      return s.replace(firstChar, function(m) {
        return m.toUpperCase();
      });
    }

    this.recognition.onerror = function(event) {
      if (that.props.debug) {
        console.log('voice recognition speech: error', event); // eslint-disable-line
      }
    };

    /*  onend is always called (after voice, after stop, after abort, after timeout) */
    this.recognition.onend = function() {
      const { isComponentOnTest, expectedValue } = that.state;
      const value = that.tempValue[0]; // eslint-disable-line

      if (isComponentOnTest) {
        that.props.onTestResult(isComponentOnTest && value === expectedValue);
      } else {
        if (value && value.length > 0) {
          that.props.onResult(capitalize(value));
        }
        if (that.props.debug) {
          console.log('voice recognition speech: final results: ' + that.tempValue); // eslint-disable-line
          console.log('---'); // eslint-disable-line
        }
      }
      that.setState({
        running: false,
        abort: false,
        valueTab: [],
        isComponentOnTest: false,
        expectedValue: ''
      });

      that.tempValue = [];
    };
  }

  render() {
    let { isDisabled } = this.state;
    let className;
    if (this.state.available && !this.state.running && true) {
      // microphone is ready.
      className = styles.voiceButton;
    } else if (this.state.available && this.state.running) {
      // microphone is running.
      className = styles.voiceButtonRunning;
    } else {
      // microphone disabled.
      className = styles.voiceButtonDisabled;
      isDisabled = true;
    }

    const button = (
      <button
        type="button"
        className={clsx(className, styles.shouldDisplayVoiceButton)}
        onClick={this.toogleVoiceButtonState}
        title={this.props.t('microphone')}
      >
        {isDisabled ? <MicrophoneIconDisabled /> : <MicrophoneIcon />}
      </button>
    );

    return isDisabled ? '' : button;
  }
}

const mapStateToProps = state => {
  return {
    locale: state.user.language
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(VoiceRecognition));
