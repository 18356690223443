// Check this link for a better understanding of what we're doing here:
// https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/

const downloadFileBlob = (response, fallbackFileName = null) => {
  const { headers, data } = response;

  const disposition = headers['content-disposition'];

  let filename = fallbackFileName || 'download';

  if (disposition && disposition.startsWith('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
};

export default downloadFileBlob;
