import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import UIInputAdornment from '@material-ui/core/InputAdornment';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import ArrowIcon from 'svg/v2/chevron_down.svg';

import useStyles from './styles';

const Select = ({
  label,
  startIcon,
  isRequired,
  endIcon,
  items,
  tooltip,
  showEmptyOption,
  value,
  onChange,
  width,
  error,
  multiple,
  title,
  additionalInputProps = {},
  ...rest
}) => {
  const checkWidth = width ? (typeof width === 'string' ? width : `${width}px`) : '100%';

  const classes = useStyles({ width: checkWidth });

  const setAdornment = (icon, position) => (
    <UIInputAdornment classes={{ root: classes.adornmentRoot }} position={position}>
      {icon}
    </UIInputAdornment>
  );

  return (
    <div>
      <TextField
        select
        value={value}
        onChange={onChange}
        classes={{ root: classes.root }}
        {...(label || tooltip
          ? {
              label: (
                <>
                  {label ? `${label} ${isRequired ? '*' : ''}` : ''}
                  {tooltip && <InfoTooltip text={tooltip} />}
                </>
              )
            }
          : {})}
        InputProps={{
          classes: {
            root: clsx(classes.inputRoot, error ? classes.borderWithError : ''),
            underline: classes.inputUnderline,
            disabled: classes.disabled
          },
          startAdornment: startIcon ? setAdornment(startIcon, 'start') : null
        }}
        inputProps={{
          title,
          ...additionalInputProps
        }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot
          },
          shrink: true
        }}
        FormHelperTextProps={{
          classes: { root: classes.formHelperTextRoot }
        }}
        SelectProps={{
          multiple,
          autoWidth: true,
          classes: { root: classes.selectRoot, icon: classes.arrowIcon },
          MenuProps: {
            classes: { list: classes.menuProps },
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
          },
          IconComponent: ArrowIcon
        }}
        {...rest}
      >
        {showEmptyOption && <MenuItem value="">--</MenuItem>}
        {items.map((item, index) => (
          <MenuItem key={`item_${index}`} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

const iconProps = PropTypes.oneOfType([PropTypes.element, PropTypes.string]);

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  startIcon: iconProps,
  endIcon: iconProps,
  items: PropTypes.array,
  showEmptyOption: PropTypes.bool,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
  classes: PropTypes.object,
  additionalInputProps: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  title: PropTypes.string
};

Select.defaultProps = {
  label: null,
  items: [],
  isRequired: false,
  onChange: () => {},
  multiple: false,
  title: 'Select field'
};

export default Select;
