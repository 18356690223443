import { studioURL } from 'config/baseURL';
import { get, post, put, del } from './utils.js';

const getRoles = () => get(`${studioURL}roles`);

const createRole = (name, description) => post(`${studioURL}roles`, { name, description });

const updateRole = (id, name, description) => put(`${studioURL}roles/${id}`, { name, description });

const deleteRole = id => del(`${studioURL}roles/${id}`);

const getUsersWithRole = roleId => get(`${studioURL}roles/${roleId}/users`);

const assignUsersToRole = (roleId, userIds) => put(`${studioURL}roles/${roleId}/users`, userIds);

const getRolesForKnowledgeGraph = knowledgeGraphId => get(`${studioURL}knowledge-graphs/${knowledgeGraphId}/roles`);

const updateRolesForKnowledgeGraph = (knowledgeGraphId, roleIds) =>
  put(`${studioURL}knowledge-graphs/${knowledgeGraphId}/roles`, roleIds);

export default {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getUsersWithRole,
  assignUsersToRole,
  getRolesForKnowledgeGraph,
  updateRolesForKnowledgeGraph
};
