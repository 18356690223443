/**
 * This component is the context menu that appears when a user clicks somewhere on a plot, e.g. on a column of a column chart.
 * It shows all suggestions for next actions that are available for the clicked data point.
 *
 * This component can also render a submenu e.g. for breakdown options
 *
 * answerContextMenu is a redux state that is used to control the visibility of this component.
 */

import { connect } from 'react-redux';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { addLocalMessage, fetchMessage } from 'store/modules/chat-messages';
import { withTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';
import { hideAnswerContextMenu } from 'store/modules/answerContextMenu';
import { contextMenuHeaderStyles, contextMenuStyles, listMenuItemStyles } from 'components/AnswerContextMenu/styles';
import { trackEvent } from 'utils/eventTracking';

export const contextMenuOptionTypes = {
  // for now we only support breakdowns (the ones below could be used in the future)
  BREAKDOWN_MENU: 'BREAKDOWN_MENU',
  BREAKDOWN_ITEM: 'BREAKDOWN_ITEM',
  POINT_FILTER: 'POINT_FILTER'
  /*SHOW_LIST: 'SHOW_LIST',
  NUMBER_FILTER: 'NUMBER_FILTER',
  DATE_FILTER: 'DATE_FILTER',
  BOOLEAN_FILTER: 'BOOLEAN_FILTER',
  STRING_FILTER: 'STRING_FILTER',
  CLASS_FILTER: 'CLASS_FILTER'*/
};

const AnswerContextMenu = ({ dispatch, t, answerContextMenu, username, showAnswerContextMenu }) => {
  // if the context menu should not be shown, then return null (value is set via mapStateToProps)
  // could also check for it in the parent component and not render this component at all
  if (!showAnswerContextMenu) {
    return null;
  }

  const { referenceTo, referenceToPoint, location, options } = answerContextMenu;

  // if we should show the context menu but there are no options, then return null (later we could show a message that there are no options)
  if (!options || options.length === 0) {
    return null;
  }

  // now we know the context menu should be shown, so we can render it at the location specified in the state
  const classes = contextMenuStyles();
  const classesListItem = listMenuItemStyles();
  const classesHeader = contextMenuHeaderStyles();

  // parentOption is set whenever we render a submenu
  const [parentOption, setParentOption] = useState(null);
  const [menuOptions, setMenuOptions] = useState(options);

  const resetMenuOptions = () => {
    setMenuOptions(options);
    setParentOption(null);
  };

  useEffect(() => {
    resetMenuOptions();
  }, [options]);

  const handleClose = useCallback(() => {
    dispatch(hideAnswerContextMenu());
  }, []);

  const handleDrillUp = useCallback(() => {
    // go back to initial options
    resetMenuOptions();
  }, [options]);

  const handleBreakDownsMenuClick = useCallback(
    option => {
      setParentOption(option);
      setMenuOptions(option.breakdowns);
    },
    [options]
  );

  const handleFollowUpItemClick = useCallback(
    (option, disableBreakdown) => {
      let text = t('follow-up-question-on-click');
      // If multiple, append with a comma, e.g. "Let's look at 2019, City Zürich"
      // The -1 is to remove the last dimension of the point, which is under the current assumptions always the Quantitative value of the point
      // This will need to be changed once we support clicking on points with multiple quantiative values e.g. for scatter plots
      referenceToPoint.slice(0, referenceToPoint.length - 1).forEach((p, idx) => {
        const separator = idx > 0 ? ', ' : ' ';
        // Sometimes the clickable point has no columnName
        // (e.g., for dates, where we don't want to have queries such as "Day of Week Monday", but only "Monday")
        text += separator + (p.columnName ? p.columnName + ' ' : '') + p.value;
      });
      if (!disableBreakdown) {
        text += ' ' + t('group-by') + ' ' + option.text;
        trackEvent('Answer Context Menu Clicked Breakdown', { text });
      } else {
        trackEvent('Answer Context Menu Clicked Filter', { text });
      }
      const localMessage = addLocalMessage(username, text);
      // display the question locally
      dispatch(localMessage);

      const otherParams = {
        referenceTo: referenceTo
      };
      // fetch and display the answer
      dispatch(fetchMessage(text, localMessage.message.id, otherParams, t));
      handleClose();
    },
    [referenceToPoint, referenceTo, t]
  );

  // on click on a drop-down item we want to ask a follow-up question like commented out above
  const handleOptionClick = useCallback(
    option => {
      switch (option.type) {
        case contextMenuOptionTypes.BREAKDOWN_MENU:
          handleBreakDownsMenuClick(option);
          break;
        case contextMenuOptionTypes.BREAKDOWN_ITEM:
          handleFollowUpItemClick(option, false);
          break;
        case contextMenuOptionTypes.POINT_FILTER:
          handleFollowUpItemClick(option, true);
          break;
        default:
          console.log('unknown option type', option.type);
      }
    },
    [referenceToPoint, options]
  );

  const referencePointTextElement = useMemo(
    () =>
      referenceToPoint.slice(0, referenceToPoint.length - 1).map((p, index) => (
        <span key={index}>
          {p.value}
          {index < referenceToPoint.length - 2 && ', '}
        </span>
      )),
    [referenceToPoint]
  );

  const getOptionTextElement = useCallback(
    option => {
      switch (option.type) {
        case contextMenuOptionTypes.BREAKDOWN_MENU:
          return t('breakdown') + ' ...';
        case contextMenuOptionTypes.BREAKDOWN_ITEM:
          return option.text;
        case contextMenuOptionTypes.POINT_FILTER:
          return (
            <span>
              <span>{t('filter-for')}: </span>
              <i>{referencePointTextElement}</i>
            </span>
          );
        default:
          return 'unknown option type';
      }
    },
    [referencePointTextElement]
  );

  const getOptionDataTest = (option, index) => {
    switch (option.type) {
      case contextMenuOptionTypes.BREAKDOWN_ITEM:
        return 'answerContextMenuItem-' + option.text;
      default:
        return 'answerContextMenuItem-' + index;
    }
  };

  return (
    <Menu
      open={true}
      onClose={handleClose}
      classes={classes}
      anchorReference="anchorPosition"
      anchorPosition={{ top: location.clientY, left: location.clientX }}
    >
      <div className={classesHeader.root} data-test="answerContextMenu">
        {// if we are in a submenu show the text of the parent option to be able to navigate back
        // otherwise render the text of the point that was clicked
        parentOption ? (
          <span onClick={handleDrillUp}>
            {t('back') + ' > '} {getOptionTextElement(parentOption)}
          </span>
        ) : (
          referencePointTextElement
        )}
      </div>
      {menuOptions.map((option, index) => (
        // if there are options in the options array, then show an arrow at the end of the text
        <MenuItem
          key={index}
          data-test={getOptionDataTest(option, index)}
          onClick={() => handleOptionClick(option)}
          classes={classesListItem}
        >
          {getOptionTextElement(option)}
        </MenuItem>
      ))}
    </Menu>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { answerId, interpretationId } = ownProps;
  return {
    username: state.user.username,
    answerContextMenu: state.answerContextMenu,
    showAnswerContextMenu:
      state.answerContextMenu &&
      state.answerContextMenu?.referenceTo.answerId === answerId &&
      state.answerContextMenu?.referenceTo.interpretationId === interpretationId
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(AnswerContextMenu)));
