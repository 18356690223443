import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.modules.scss';

const Title = ({ children, image }) => (
  <div className={styles.title}>
    {image && (
      <div className={styles.imgContainer}>
        <img alt="img" src={image} />
      </div>
    )}
    {children}
  </div>
);

Title.propTypes = {
  image: PropTypes.string
};

export default Title;
