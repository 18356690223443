import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import UIInputAdornment from '@material-ui/core/InputAdornment';

import { useDefaultStyles, useTitleStyles, useSearchEntitiesStyles } from './styles';

const iconPosition = {
  start: 'start',
  end: 'end'
};

const Input = forwardRef(({ label, layout, size, isRequired, InputProps, startIcon, endIcon, title, ...rest }, ref) => {
  const useStyles = useMemo(() => {
    if (layout === 'title') return useTitleStyles;
    if (layout === 'knowledge_graph_search') return useSearchEntitiesStyles;
    return useDefaultStyles;
  }, [layout]);

  const styles = useStyles({ size });

  const setAdornment = (icon, position) => {
    return (
      <UIInputAdornment
        classes={{ root: styles.adornmentRoot, positionStart: styles.positionStart, positionEnd: styles.positionEnd }}
        position={position}
      >
        {icon}
      </UIInputAdornment>
    );
  };

  return (
    <TextField
      {...(label ? { label: `${label} ${isRequired ? '*' : ''}` } : {})}
      variant="outlined"
      inputProps={{ ref, title }}
      classes={{ root: styles.root }}
      InputProps={{
        ...InputProps,
        classes: {
          root: `${styles.inputRoot} ${label ? styles.inputLabelMargin : ''}`,
          input: styles.input,
          disabled: styles.disabled,
          notchedOutline: styles.notchedOutline,
          multiline: styles.multiline,
          focused: styles.focused,
          adornedStart: styles.adornedStart,
          adornedEnd: styles.adornedEnd
        },
        startAdornment: startIcon ? setAdornment(startIcon, iconPosition.start) : null,
        endAdornment: endIcon ? setAdornment(endIcon, iconPosition.end) : null
      }}
      InputLabelProps={{
        classes: {
          root: styles.labelRoot,
          shrink: styles.shrink
        },
        shrink: true
      }}
      FormHelperTextProps={{
        classes: { root: styles.formHelperTextRoot }
      }}
      autoComplete="new-password"
      title={title}
      {...rest}
    />
  );
});

const iconProps = PropTypes.oneOfType([PropTypes.element, PropTypes.string]);

Input.propTypes = {
  label: PropTypes.string,
  startIcon: iconProps,
  endIcon: iconProps,
  InputProps: PropTypes.object,
  isRequired: PropTypes.bool,
  textColor: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string
};

Input.defaultProps = {
  label: null,
  isRequired: false,
  layout: 'default',
  size: 'medium',
  title: ''
};

Input.displayName = 'Input';

export default Input;
