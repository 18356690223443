import { studioURL } from 'config/baseURL';
import { get, post } from './utils.js';

const getDeclaredVariables = id => get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/declared-variables`);

const getVariableAssignment = id => get(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/variable-assignment`);

const setVariableAssignment = (id, body) =>
  post(`${studioURL}knowledge-graphs/${encodeURIComponent(id)}/variable-assignment`, body);

export default {
  getDeclaredVariables,
  getVariableAssignment,
  setVariableAssignment
};
