import { baseURL } from 'config/baseURL';
import { get, post, patch, del } from './utils.js';

const getCustomersOfTheDay = () => get(`${baseURL}customers`);

const extendCustomersOfTheDay = () => get(`${baseURL}customers/extend`);

const getCustomerSelections = () => get(`${baseURL}customers/selections`);

const getCustomerSelectionById = id => get(`${baseURL}customers/selections/${id}?sessionId=${window.veezoo.sessionId}`);

const saveCustomerSelection = body => post(`${baseURL}customers/selections`, body);

const editCustomerSelection = (id, body) => patch(`${baseURL}customers/selections/${id}`, body);

const deleteCustomerSelectionById = id => del(`${baseURL}customers/selections/${id}`);

const saveSharedCustomerSelections = (id, body) => post(`${baseURL}customers/selections/shared/${id}`, body);

const updateSharedCustomerSelections = (id, body) => patch(`${baseURL}customers/selections/shared/${id}`, body);

const stopSharingCustomerSelection = id => del(`${baseURL}customers/selections/shared/${id}`);

const acceptOrRejectSharedCustomerSelection = (id, body) =>
  post(`${baseURL}customers/selections/shared/status/${id}`, body);

export default {
  getCustomersOfTheDay,
  extendCustomersOfTheDay,
  getCustomerSelections,
  getCustomerSelectionById,
  saveCustomerSelection,
  editCustomerSelection,
  deleteCustomerSelectionById,
  saveSharedCustomerSelections,
  updateSharedCustomerSelections,
  stopSharingCustomerSelection,
  acceptOrRejectSharedCustomerSelection
};
