import { baseURL } from 'config/baseURL';
import { headersPOST } from './../utils/HTTPheaders';
import { authFetch } from './../utils/authFetch';

import { fetchBoardMessage } from 'store/modules/chat-messages';
import {
  BOARD_META_WIDGETS_FAILURE,
  BOARD_META_WIDGETS_SUCCESS,
  UPDATE_BOARDWIDGETS_SUCCESS,
  ADD_WIDGET_TO_BOARD_SUCCESS,
  updateMetaWidgetsOfBoard
} from 'store/modules/board-widgets';

import services from 'services';
import { handleError } from 'services/utils';

export const BOARDSLIST_REQUEST = 'BOARDSLIST_REQUEST';
export const BOARDSLIST_SUCCESS = 'BOARDSLIST_SUCCESS';
export const BOARDSLIST_FAILURE = 'BOARDSLIST_FAILURE';

export const BOARDSVIEW_REQUEST = 'BOARDSVIEW_REQUEST';
export const BOARDSVIEW_SUCCESS = 'BOARDSVIEW_SUCCESS';
export const BOARDSVIEW_FAILURE = 'BOARDSVIEW_FAILURE';

export const PUBLIC_BOARDS_LIST_REQUEST = 'PUBLIC_BOARDS_LIST_REQUEST';
export const PUBLIC_BOARDS_LIST_SUCCESS = 'PUBLIC_BOARDS_LIST_SUCCESS';
export const PUBLIC_BOARDS_LIST_FAILURE = 'PUBLIC_BOARDS_LIST_FAILURE';

export const ADD_BOARD_REQUEST = 'ADD_BOARD_REQUEST';
export const ADD_BOARD_SUCCESS = 'ADD_BOARD_SUCCESS';
export const ADD_BOARD_FAILURE = 'ADD_BOARD_FAILURE';

export const COPY_BOARD_REQUEST = 'COPY_BOARD_REQUEST';
export const COPY_BOARD_SUCCESS = 'COPY_BOARD_SUCCESS';
export const COPY_BOARD_FAILURE = 'COPY_BOARD_FAILURE';

export const CREATE_AND_MOVE_BOARD_REQUEST = 'CREATE_AND_MOVE_BOARD_REQUEST';
export const CREATE_AND_MOVE_BOARD_SUCCESS = 'CREATE_AND_MOVE_BOARD_SUCCESS';
export const CREATE_AND_MOVE_BOARD_FAILURE = 'CREATE_AND_MOVE_BOARD_FAILURE';

export const CREATE_AND_MOVE_FOLDER_REQUEST = 'CREATE_AND_MOVE_FOLDER_REQUEST';
export const CREATE_AND_MOVE_FOLDER_SUCCESS = 'CREATE_AND_MOVE_FOLDER_SUCCESS';
export const CREATE_AND_MOVE_FOLDER_FAILURE = 'CREATE_AND_MOVE_FOLDER_FAILURE';

export const PUBLISH_BOARD_SUCCESS = 'PUBLISH_BOARD_SUCCESS';
export const EDIT_BOARD_START = 'EDIT_BOARD_START';
export const EDIT_BOARD_UPDATE_STATE = 'EDIT_BOARD_UPDATE_STATE';
export const EDIT_BOARD_END = 'EDIT_BOARD_END';

export const BOARD_REMOVE_REQUEST = 'BOARD_REMOVE_REQUEST';
export const BOARD_REMOVE_SUCCESS = 'BOARD_REMOVE_SUCCESS';
export const BOARD_REMOVE_FAILURE = 'BOARD_REMOVE_FAILURE';

export const FOLDER_REMOVE_REQUEST = 'FOLDER_REMOVE_REQUEST';
export const FOLDER_REMOVE_SUCCESS = 'FOLDER_REMOVE_SUCCESS';
export const FOLDER_REMOVE_FAILURE = 'FOLDER_REMOVE_FAILURE';

export const CHANGE_BOARDNAME_REQUEST = 'CHANGE_BOARDNAME_REQUEST';
export const CHANGE_BOARDNAME_SUCCESS = 'CHANGE_BOARDNAME_SUCCESS';
export const CHANGE_BOARDNAME_FAILURE = 'CHANGE_BOARDNAME_FAILURE';

export const CHANGE_FOLDER_REQUEST = 'CHANGE_FOLDER_REQUEST';
export const CHANGE_FOLDER_SUCCESS = 'CHANGE_FOLDER_SUCCESS';
export const CHANGE_FOLDER_FAILURE = 'CHANGE_FOLDER_FAILURE';

export const BOARDSVIEW_MOVE_REQUEST = 'BOARDSVIEW_MOVE_REQUEST';
export const BOARDSVIEW_MOVE_SUCCESS = 'BOARDSVIEW_MOVE_SUCCESS';
export const BOARDSVIEW_MOVE_FAILURE = 'BOARDSVIEW_MOVE_FAILURE';

export const MAKE_INDEPENDENT_SUCCESS = 'MAKE_INDEPENDENT_SUCCESS';

export const SUBSCRIBE_TO_BOARD_REQUEST = 'SUBSCRIBE_TO_BOARD_REQUEST';
export const SUBSCRIBE_TO_BOARD_SUCESS = 'SUBSCRIBE_TO_BOARD_SUCESS';
export const SUBSCRIBE_TO_BOARD_FAILURE = 'SUBSCRIBE_TO_BOARD_FAILURE';

export const DISABLE_BOARD_REQUEST = 'DISABLE_BOARD_REQUEST';
export const DISABLE_BOARD_SUCCESS = 'DISABLE_BOARD_SUCCESS';
export const DISABLE_BOARD_FAILURE = 'DISABLE_BOARD_FAILURE';

export const SHARE_BOARD_REQUEST = 'SHARE_BOARD_REQUEST';
export const SHARE_BOARD_SUCCESS = 'SHARE_BOARD_SUCCESS';
export const SHARE_BOARD_FAILURE = 'SHARE_BOARD_FAILURE';

export const UNSHARE_BOARD_REQUEST = 'UNSHARE_BOARD_REQUEST';
export const UNSHARE_BOARD_SUCCESS = 'UNSHARE_BOARD_SUCCESS';
export const UNSHARE_BOARD_FAILURE = 'UNSHARE_BOARD_FAILURE';

export const SHARED_BOARD_INFO_REQUEST = 'SHARED_BOARD_INFO_REQUEST';
export const SHARED_BOARD_INFO_SUCCESS = 'SHARED_BOARD_INFO_SUCCESS';
export const SHARED_BOARD_INFO_FAILURE = 'SHARED_BOARD_INFO_FAILURE';

export const UPDATE_FOLLOW_UP_BOARD_REQUEST = 'UPDATE_FOLLOW_UP_BOARD_REQUEST';
export const UPDATE_FOLLOW_UP_BOARD_SUCCESS = 'UPDATE_FOLLOW_UP_BOARD_SUCCESS';
export const UPDATE_FOLLOW_UP_BOARD_FAILURE = 'UPDATE_FOLLOW_UP_BOARD_FAILURE';

export const CLOSE_UPDATE_FOLLOW_UP_BOARD_MODAL = 'CLOSE_UPDATE_FOLLOW_UP_BOARD_MODAL';

export const fetchBoardsList = () => async dispatch => {
  dispatch({ type: BOARDSLIST_REQUEST });

  const result = await services.getBoards();
  handleError(result, dispatch);

  if (!result.success) {
    return dispatch({ type: BOARDSLIST_FAILURE });
  }

  dispatch({
    type: BOARDSLIST_SUCCESS,
    boardsList: result.data
  });
};

export const fetchPublicBoardsList = () => async dispatch => {
  dispatch({ type: PUBLIC_BOARDS_LIST_REQUEST });

  const result = await services.getDiscoverableBoards();
  handleError(result, dispatch);

  if (!result.success) {
    return dispatch({ type: PUBLIC_BOARDS_LIST_FAILURE });
  }

  const formattedResult = result.data.map(board => ({
    ...board,
    // parse date strings as dates
    id: board.boardId,
    name: board.boardName,
    createdOn: new Date(board.createdOn),
    sharedOn: new Date(board.sharedOn)
  }));

  dispatch({
    type: PUBLIC_BOARDS_LIST_SUCCESS,
    publicBoardsList: formattedResult
  });
};

export const fetchBoardsView = () => async dispatch => {
  dispatch({ type: BOARDSVIEW_REQUEST });

  const result = await services.getBoardsView();
  handleError(result, dispatch);

  if (!result.success) {
    return dispatch({ type: BOARDSVIEW_FAILURE });
  }
  dispatch({
    type: BOARDSVIEW_SUCCESS,
    boardsView: result.data
  });
};

export const moveBoardView = (id, parentId, aboveId) => {
  return async dispatch => {
    dispatch({ type: BOARDSVIEW_MOVE_REQUEST, id });

    const result = await services.moveBoardView(id, parentId, aboveId);
    handleError(result, dispatch);

    dispatch({ type: result.success ? BOARDSVIEW_MOVE_SUCCESS : BOARDSVIEW_MOVE_FAILURE, id });

    dispatch(fetchBoardsView());
  };
};

export const addBoardToList = (boardName, widgets, answer, displayBoardInChat = false, t) => {
  return async dispatch => {
    dispatch({ type: ADD_BOARD_REQUEST });

    const result = await services.createBoard(boardName, widgets, answer);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: ADD_BOARD_FAILURE });
      console.log(result?.response?.data || 'There was an error creating board');
      return;
    }

    dispatch({ type: ADD_BOARD_SUCCESS, board: result.data });
    dispatch(fetchBoardsView());

    if (displayBoardInChat) {
      dispatch(fetchBoardMessage(result.data.id, t));
    }

    return result;
  };
};

export const createAndMoveBoard = (
  boardName,
  widgets,
  answer,
  displayBoardInChat = false,
  parentId = null,
  aboveId = null,
  t
) => {
  return async dispatch => {
    dispatch({ type: CREATE_AND_MOVE_BOARD_REQUEST });

    const result = await services.createBoard(boardName, widgets, answer);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: CREATE_AND_MOVE_BOARD_FAILURE });
      console.log(result?.response?.data || 'There was an error creating board');
      return;
    }

    if (parentId) {
      await services.moveBoardView(result.data.id, parentId, aboveId);
    }

    dispatch({ type: CREATE_AND_MOVE_BOARD_SUCCESS, board: result.data });
    dispatch(fetchBoardsView());

    if (displayBoardInChat) {
      dispatch(fetchBoardMessage(result.data.id, t));
    }
  };
};

export const createAndMoveFolder = ({ name, parentId, aboveId = null }) => {
  return async dispatch => {
    dispatch({ type: CREATE_AND_MOVE_FOLDER_REQUEST });

    const result = await services.createFolder({ name, parentId: null, aboveId: null }); // endpoint doesn't support aboveId yet.
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: CREATE_AND_MOVE_FOLDER_FAILURE });
      console.log(result?.response?.data || 'There was an error creating folder');
      return;
    }

    await services.moveBoardView(result.data, parentId, aboveId);

    dispatch({ type: CREATE_AND_MOVE_FOLDER_SUCCESS, board: result.data });
    dispatch(fetchBoardsView());
  };
};

export const changeBoardNamePromise = (id, name, dispatch, willPublishBoard) => {
  dispatch({ type: CHANGE_BOARDNAME_REQUEST, name, id, willPublishBoard });
  return services.renameBoard(id, name).then(result => {
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: CHANGE_BOARDNAME_FAILURE });
      console.log(result?.response?.data || 'There was an error renaming board');
      return;
    }

    dispatch({
      type: CHANGE_BOARDNAME_SUCCESS,
      name: result.data.name,
      id: result.data.id,
      willPublishBoard: willPublishBoard
    });
  });
};

// This one gets called via dropdown and does not automatically publish the board, hence we pass false
export const changeBoardName = (id, name) => {
  return async dispatch => {
    await changeBoardNamePromise(id, name, dispatch, false);
  };
};

export const createBoardCopy = (id, name) => {
  return async dispatch => {
    dispatch({ type: COPY_BOARD_REQUEST });

    return services.copyBoard(id, name).then(result => {
      handleError(result, dispatch);
      if (!result.success) {
        dispatch({ type: COPY_BOARD_FAILURE });
        console.log(result?.response?.data || 'There was an error copying the board');
        return;
      }

      dispatch({ type: COPY_BOARD_SUCCESS, board: result.data });
      dispatch(fetchBoardsView());
    });
  };
};

export const changeFolderName = (id, name) => {
  return async dispatch => {
    dispatch({ type: CHANGE_FOLDER_REQUEST, name, id });

    const result = await services.renameFolder(id, name);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: CHANGE_FOLDER_FAILURE });
      console.log(result?.response?.data || 'There was an error renaming board');
      return;
    }

    dispatch({
      type: CHANGE_FOLDER_SUCCESS,
      name,
      id
    });
  };
};

export function subscribeToBoard(boardId) {
  return async dispatch => {
    dispatch({ type: SUBSCRIBE_TO_BOARD_REQUEST, boardId });

    const result = await services.setBoardEnabled(boardId, true);
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: SUBSCRIBE_TO_BOARD_FAILURE, boardId });
    }

    dispatch({ type: SUBSCRIBE_TO_BOARD_SUCESS, boardId });
    dispatch(fetchBoardsList());
    dispatch(fetchBoardsView());
  };
}

export function unsubscribeFromBoard(boardId) {
  return async dispatch => {
    dispatch({ type: DISABLE_BOARD_REQUEST, boardId });

    const result = await services.setBoardEnabled(boardId, false);
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: DISABLE_BOARD_FAILURE, boardId });
    }

    dispatch({ type: DISABLE_BOARD_SUCCESS, boardId });
    dispatch(fetchBoardsList());
  };
}

export const deleteBoard = id => {
  return async dispatch => {
    dispatch({ type: BOARD_REMOVE_REQUEST, boardId: id });

    const result = await services.deleteBoard(id);
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: BOARD_REMOVE_FAILURE, boardId: id });
    }

    dispatch({ type: BOARD_REMOVE_SUCCESS, boardId: id });
    dispatch(fetchBoardsList());
  };
};

export const deleteFolder = id => {
  return async dispatch => {
    dispatch({ type: FOLDER_REMOVE_REQUEST, id });

    const result = await services.deleteFolder(id);
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: FOLDER_REMOVE_FAILURE, id });
    }

    dispatch({ type: FOLDER_REMOVE_SUCCESS, id });
    dispatch(fetchBoardsView());
  };
};

export function makeIndependent(id) {
  return dispatch => {
    authFetch(
      baseURL + 'boards/make-independent/' + id,
      {
        method: 'POST',
        headers: headersPOST,
        credentials: 'include'
      },
      dispatch
    ).then(response => {
      if (response.ok) {
        dispatch({
          type: MAKE_INDEPENDENT_SUCCESS,
          boardId: id
        });
      } else {
        response.text().then(t => console.log('ERROR', t));
      }
    });
  };
}

export function closeUpdateLinkedBoardModal() {
  return dispatch => {
    dispatch({ type: CLOSE_UPDATE_FOLLOW_UP_BOARD_MODAL });
  };
}

export function rejectBaseBoardUpdates(id) {
  return dispatch => {
    dispatch(closeUpdateLinkedBoardModal());
    dispatch(makeIndependent(id));
  };
}

export function updateFollowUpBoard(id) {
  return dispatch => {
    dispatch(closeUpdateLinkedBoardModal());

    dispatch({
      type: UPDATE_FOLLOW_UP_BOARD_REQUEST,
      id
    });

    authFetch(
      baseURL + 'boards/' + id + '/update',
      {
        method: 'POST',
        headers: headersPOST,
        credentials: 'include'
      },
      dispatch
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          response.text().then(t =>
            dispatch({
              type: UPDATE_FOLLOW_UP_BOARD_FAILURE,
              message: t
            })
          );
        }
      })
      .then(metaWidgets => {
        dispatch({
          type: UPDATE_FOLLOW_UP_BOARD_SUCCESS,
          boardId: id,
          metaWidgets
        });
        dispatch(updateMetaWidgetsOfBoard(id, true, metaWidgets, undefined));
      });
  };
}

export const getSharedBoardInfo = ({ boardId }) => {
  return async dispatch => {
    dispatch({ type: SHARED_BOARD_INFO_REQUEST, boardId });

    const result = await services.getSharedBoardInfo(boardId);
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: SHARED_BOARD_INFO_FAILURE, payload: result, error: true, message: 'final catch' });
    }

    dispatch({
      type: SHARED_BOARD_INFO_SUCCESS,
      board: result.data,
      boardId
    });
  };
};

export const shareBoard = ({ data, boardId }) => {
  return async dispatch => {
    dispatch({ type: SHARE_BOARD_REQUEST });

    const result = await services.saveSharedBoard(boardId, data);
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: SHARE_BOARD_FAILURE, payload: result, error: true, message: 'final catch' });
    }

    dispatch({
      type: SHARE_BOARD_SUCCESS,
      board: result.data,
      boardId
    });
  };
};

export const unshareBoard = boardId => {
  return async dispatch => {
    dispatch({ type: UNSHARE_BOARD_REQUEST });

    const result = await services.stopSharingBoard(boardId);
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: UNSHARE_BOARD_FAILURE, payload: result, error: true, message: 'final catch' });
    }

    dispatch({
      type: UNSHARE_BOARD_SUCCESS,
      board: result.data,
      boardId
    });
  };
};

export function startEditBoard(boardId) {
  return dispatch => {
    dispatch({
      type: EDIT_BOARD_START,
      id: boardId
    });
  };
}

export function updateEditBoardState(currentMetaState) {
  return dispatch => {
    dispatch({
      type: EDIT_BOARD_UPDATE_STATE,
      editingState: currentMetaState
    });
  };
}

export function stopEditBoard() {
  return dispatch => {
    dispatch({
      type: EDIT_BOARD_END
    });
  };
}

export function publishBoardChanges(boardId) {
  return async dispatch => {
    const result = await services.publishBoardChanges(boardId);
    handleError(result, dispatch);

    if (!result.success) {
      const message = result?.response?.data || 'There was an error publishing changes';
      throw Error(message);
    }

    dispatch({
      type: PUBLISH_BOARD_SUCCESS,
      boardId: boardId
    });
  };
}

const updatePropertiesOfBoard = (state, boardId, properties) => {
  const boardIndex = state.boardsList.findIndex(board => board.id === boardId);
  const publicBoardIndex = state.publicBoardsList.findIndex(board => board.id === boardId);

  if (boardIndex === -1 && publicBoardIndex === -1) {
    console.error('Trying to update board that does not exist: ' + boardId);
    return {
      ...state
    };
  }

  const newBoardsList = state.boardsList.map(board => {
    if (board.id === boardId) {
      return { ...board, ...properties };
    }
    return board;
  });

  const newPublicBoardsList = state.publicBoardsList.map(board => {
    if (board.id === boardId) {
      return { ...board, ...properties };
    }
    return board;
  });

  return {
    ...state,
    boardsList: newBoardsList,
    publicBoardsList: newPublicBoardsList
  };
};

function updateBoardsView(boardsView, id, name) {
  const formatViews = views =>
    views.map(view => {
      if (view.id === id) {
        return { ...view, name };
      }
      if (view.children && view.children.length > 0) {
        return { ...view, children: formatViews(view.children) };
      }
      return view;
    });
  return formatViews(boardsView);
}

const findAndRemoveView = (views, id) => {
  return views.reduce((result, view) => {
    if (view.id !== id) {
      if (view.children) {
        view = { ...view, children: findAndRemoveView(view.children) };
      }
      return [...result, view];
    }

    return result;
  }, []);
};

// describe a board reducer
export const initBoardState = {
  loadingPublicBoards: false,
  publicBoardsList: [],
  boardsList: [],
  boardsView: [],
  isCreatingBoard: false,
  recentlyAddedBoard: undefined,
  editingState: [], // current meta answers when in editing mode
  editingBoardId: undefined,
  updateModal: undefined,
  recentlyActiveBoard: undefined,
  isChangingBoardOrFolder: false
};

export function board(state = initBoardState, action) {
  switch (action.type) {
    case BOARDSLIST_REQUEST:
      return {
        ...state
      };

    case BOARDSLIST_SUCCESS:
      return {
        ...state,
        boardsList: action.boardsList
      };

    case PUBLIC_BOARDS_LIST_REQUEST:
      return {
        ...state,
        loadingPublicBoards: true
      };

    case PUBLIC_BOARDS_LIST_SUCCESS:
      return {
        ...state,
        loadingPublicBoards: false,
        publicBoardsList: action.publicBoardsList
      };

    case PUBLIC_BOARDS_LIST_FAILURE:
      return {
        ...state,
        loadingPublicBoards: false
      };

    case ADD_WIDGET_TO_BOARD_SUCCESS:
      return {
        ...state,
        recentlyActiveBoard: action.boardId
      };

    case BOARDSVIEW_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case BOARDSVIEW_SUCCESS:
      return {
        ...state,
        boardsView: action.boardsView,
        isChangingBoardOrFolder: false
      };

    case BOARDSVIEW_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case FOLDER_REMOVE_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case FOLDER_REMOVE_SUCCESS: {
      const boardsView = [...state.boardsView];

      return {
        ...state,
        boardsView: findAndRemoveView(boardsView, action.id),
        isChangingBoardOrFolder: false
      };
    }

    case FOLDER_REMOVE_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case BOARDSVIEW_MOVE_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case BOARDSVIEW_MOVE_SUCCESS:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case BOARDSVIEW_MOVE_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case SUBSCRIBE_TO_BOARD_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case SUBSCRIBE_TO_BOARD_SUCESS: {
      const newPublicBoards = state.publicBoardsList.map(board => {
        if (board.id === action.boardId) {
          return { ...board, isEnabled: true };
        }
        return board;
      });
      return {
        ...state,
        publicBoardsList: newPublicBoards,
        isChangingBoardOrFolder: false
      };
    }

    case DISABLE_BOARD_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case DISABLE_BOARD_SUCCESS: {
      const newPublicBoards = state.publicBoardsList.map(board => {
        if (board.id === action.boardId) {
          return { ...board, isEnabled: false };
        }
        return board;
      });
      return {
        ...state,
        publicBoardsList: newPublicBoards,
        isChangingBoardOrFolder: false
      };
    }

    case BOARD_REMOVE_REQUEST:
      return {
        ...state,
        boardsList: state.boardsList.filter(b => b.id !== action.boardId),
        isChangingBoardOrFolder: true
      };

    case BOARD_REMOVE_SUCCESS:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case SUBSCRIBE_TO_BOARD_FAILURE:
    case DISABLE_BOARD_FAILURE:
    case BOARD_REMOVE_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case EDIT_BOARD_START:
      return {
        ...state,
        editingBoardId: action.id
      };

    case EDIT_BOARD_END:
      return {
        ...state,
        editingState: [],
        editingBoardId: undefined
      };

    case EDIT_BOARD_UPDATE_STATE:
      return {
        ...state,
        editingState: action.editingState
      };

    case COPY_BOARD_REQUEST:
    case ADD_BOARD_REQUEST:
      return {
        ...state,
        isCreatingBoard: true
      };

    case COPY_BOARD_SUCCESS:
    case ADD_BOARD_SUCCESS:
      return {
        ...state,
        isCreatingBoard: false,
        boardsList: [...state.boardsList, action.board],
        recentlyAddedBoard: action.board.id,
        recentlyActiveBoard: action.board.id
      };

    case COPY_BOARD_FAILURE:
    case ADD_BOARD_FAILURE:
      return {
        ...state,
        isCreatingBoard: false
      };

    case CREATE_AND_MOVE_BOARD_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case CREATE_AND_MOVE_BOARD_SUCCESS:
      return {
        ...state,
        boardsList: [...state.boardsList, action.board],
        recentlyAddedBoard: action.board.id,
        recentlyActiveBoard: action.board.id,
        isChangingBoardOrFolder: false
      };

    case CREATE_AND_MOVE_BOARD_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case CREATE_AND_MOVE_FOLDER_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case CREATE_AND_MOVE_FOLDER_SUCCESS:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case CREATE_AND_MOVE_FOLDER_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case CHANGE_BOARDNAME_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case CHANGE_BOARDNAME_SUCCESS: {
      const newState = { ...state, isChangingBoardOrFolder: false };
      return updatePropertiesOfBoard(newState, action.id, { name: action.name, isPublished: action.willPublishBoard });
    }

    case CHANGE_BOARDNAME_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case PUBLISH_BOARD_SUCCESS:
      return updatePropertiesOfBoard(state, action.boardId, { isPublished: true });

    case UPDATE_BOARDWIDGETS_SUCCESS: {
      if (action.willPublishBoard) {
        // nothing to update as the publishing operation will still happen
        return state;
      } else {
        return updatePropertiesOfBoard(state, action.boardId, { isPublished: false });
      }
    }

    case CHANGE_FOLDER_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case CHANGE_FOLDER_SUCCESS:
      return {
        ...state,
        isChangingBoardOrFolder: false,
        boardsView: updateBoardsView(state.boardsView, action.id, action.name)
      };

    case CHANGE_FOLDER_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case BOARD_META_WIDGETS_FAILURE:
    case BOARD_META_WIDGETS_SUCCESS: {
      return {
        ...state,
        updateModal: action.baseBoardChanges
          ? {
              baseBoardChanges: action.baseBoardChanges,
              boardId: action.boardId
            }
          : undefined
      };
    }

    case UNSHARE_BOARD_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case UNSHARE_BOARD_SUCCESS: {
      const updatedProperties = {
        roleIds: [],
        userIds: [],
        isSharedWithOrg: false,
        isEnabledByDefault: false
      };

      const index = state.boardsList.findIndex(board => board.id === action.boardId);
      if (index === -1) {
        console.log('INDEX NOT FOUND', index);
        return state;
      }

      const newBoardsList = state.boardsList.map(board => {
        if (board.id === action.boardId) {
          return { ...board, isSharedWithOthers: false, ...updatedProperties };
        }
        return board;
      });

      return {
        ...state,
        isChangingBoardOrFolder: false,
        boardsList: newBoardsList
      };
    }

    case UNSHARE_BOARD_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case SHARE_BOARD_REQUEST:
      return {
        ...state,
        isChangingBoardOrFolder: true
      };

    case SHARE_BOARD_SUCCESS: {
      const isUnsharing =
        action.board.roleIds.length === 0 && action.board.userIds.length === 0 && !action.board.isSharedWithOrg;

      const updatedProperties = {
        roleIds: action.board.roleIds,
        userIds: action.board.userIds,
        isSharedWithOrg: action.board.isSharedWithOrg,
        isEnabledByDefault: action.board.isEnabledByDefault,
        ...(isUnsharing ? { isSharedWithOthers: false } : {})
      };

      const index = state.boardsList.findIndex(board => board.id === action.boardId);
      if (index === -1) {
        console.log('INDEX NOT FOUND', index);
        return state;
      }

      const newBoardsList = state.boardsList.map(board => {
        if (board.id === action.boardId) {
          return { ...board, isSharedWithOthers: true, ...updatedProperties };
        }
        return board;
      });

      return {
        ...state,
        isChangingBoardOrFolder: false,
        boardsList: newBoardsList
      };
    }

    case SHARE_BOARD_FAILURE:
      return {
        ...state,
        isChangingBoardOrFolder: false
      };

    case SHARED_BOARD_INFO_REQUEST: {
      const newBoardsList = state.boardsList.map(board => {
        if (board.id === action.boardId) {
          return { ...board, fetchingInfo: true };
        }
        return board;
      });

      return {
        ...state,
        boardsList: newBoardsList
      };
    }

    case SHARED_BOARD_INFO_SUCCESS: {
      const updatedProperties = {
        roleIds: action.board.roleIds,
        userIds: action.board.userIds,
        isSharedWithOrg: action.board.isSharedWithOrg,
        isEnabledByDefault: action.board.isEnabledByDefault
      };
      const index = state.boardsList.findIndex(board => board.id === action.boardId);
      if (index === -1) {
        console.log('INDEX NOT FOUND', index);
        return state;
      }

      const newBoardsList = state.boardsList.map(board => {
        if (board.id === action.boardId) {
          return { ...board, fetchingInfo: false, ...updatedProperties };
        }
        return board;
      });

      return {
        ...state,
        boardsList: newBoardsList
      };
    }

    case SHARED_BOARD_INFO_FAILURE: {
      const newBoardsList = state.boardsList.map(board => {
        if (board.id === action.boardId) {
          return { ...board, fetchingInfo: false };
        }
        return board;
      });

      return {
        ...state,
        boardsList: newBoardsList
      };
    }

    case CLOSE_UPDATE_FOLLOW_UP_BOARD_MODAL:
      return {
        ...state,
        updateModal: undefined
      };

    default:
      return state;
  }
}
