import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';

import Title from 'components/Signup/Title';
import Button from 'components/Signup/Button';
import Dropzone from 'components/Signup/Dropzone';
import PageLoader from 'components/Signup/PageLoader';

import { TextField, Select } from 'components/v3';

import { trackEvent } from 'utils/eventTracking';

import { getErrorInfoByFieldId } from 'utils/forms';

import services from 'services';
import { handleError } from 'services/utils';
import { defaultLanguage, layouts } from 'config/constants';

import styles from '../index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';
import TroubleConnectingMessage from 'components/TroubleConnectingMessage';

import useMediaQuery from 'utils/mediaQueries';

const validationRules = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(28, 'Name must contain a maximum of 28 characters.'),
  defaultLanguageId: yup.string().required('Language is required'),
  file: yup.string().required('Excel file is required')
});

const defaultValues = knowledgeGraph => ({
  name: knowledgeGraph?.info?.default?.name || '',
  defaultLanguageId: knowledgeGraph?.defaultLanguageId || defaultLanguage,
  file: ''
});

const ExcelConnector = ({
  dispatch,
  errorMessage,
  knowledgeGraph,
  languages,
  loading,
  setKnowledgeGraph,
  setStep,
  withLoader
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const isMobile = useMediaQuery();

  const formattedLanguages = useMemo(() => languages.map(({ data }) => ({ value: data.id, label: data.name })), [
    languages
  ]);

  const { control, errors, handleSubmit, reset, setValue, trigger } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues(knowledgeGraph),
    resolver: yupResolver(validationRules)
  });

  const onSubmit = useCallback(
    async data => {
      let currentKnowledgeGraph = {};
      let loadingMessage = '';
      if (!knowledgeGraph) {
        const knowledgeGraphResult = await withLoader(
          services.createKnowledgeGraph(data.defaultLanguageId, data.name),
          'Creating new Knowledge Graph...'
        );
        handleError(knowledgeGraphResult, dispatch);
        if (!knowledgeGraphResult.success) {
          const message = knowledgeGraphResult?.response?.data?.data?.message;
          reset(data);
          trackEvent('Knowledge Graph Creation Failed', {
            knowledgeGraph: data,
            ...(message ? { error: { message } } : {})
          });
          errorMessage(message || 'There was an error while creating new Knowledge Graph.');
          return;
        }
        currentKnowledgeGraph = knowledgeGraphResult.data.data;
        setKnowledgeGraph(currentKnowledgeGraph);
        loadingMessage = 'Knowledge Graph successfully created! Processing Excel file...';
      } else {
        currentKnowledgeGraph = knowledgeGraph;
        loadingMessage = 'Processing Excel file...';
      }

      const excelImportResult = await withLoader(
        services.oneStepImport(currentKnowledgeGraph.id, selectedFiles[0].file),
        loadingMessage
      );
      handleError(excelImportResult, dispatch);
      if (!excelImportResult.success) {
        reset(data);
        const message = excelImportResult?.response?.data || 'There was an error while uploading the excel file.';
        trackEvent('File Upload Failed', {
          knowledgeGraph: { id: currentKnowledgeGraph.id },
          file: {
            name: selectedFiles[0].file.name,
            size: selectedFiles[0].file.size,
            type: selectedFiles[0].file.type
          },
          error: { message }
        });
        errorMessage(message);
        return;
      }

      if (!knowledgeGraph) {
        // Load the KG anew with the connection information
        const knowledgeGraphResult = await services.getKnowledgeGraph(currentKnowledgeGraph.id);
        if (!knowledgeGraphResult.success) {
          return;
        }
        setKnowledgeGraph(knowledgeGraphResult.data.data);
      }

      trackEvent('File Uploaded', {
        knowledgeGraph: { id: currentKnowledgeGraph.id },
        file: {
          name: selectedFiles[0].file.name,
          size: selectedFiles[0].file.size,
          type: selectedFiles[0].file.type
        }
      });
      setStep(step => step + 1);
    },
    [dispatch, errorMessage, knowledgeGraph, reset, selectedFiles, setKnowledgeGraph, setStep]
  );

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setValue('file', 'file');
      trigger('file');
    } else {
      setValue('file', '');
    }
  }, [selectedFiles]);

  return (
    <div className={clsx(signupStyles.centerBothDirectionChild, styles.mw550)}>
      <Title>Upload an Excel or CSV file</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={signupStyles.mt_40}>
          {!loading.status && (
            <>
              <div className={signupStyles.sidedFormFields}>
                <div className={clsx(signupStyles.formField, signupStyles.mr_30)}>
                  <Controller
                    shrink
                    as={TextField}
                    name="name"
                    required
                    control={control}
                    label="Knowledge Graph Name"
                    disabled={loading.status || !!knowledgeGraph}
                    layout={layouts.signup}
                    data-test="newKnowledgeGraphName"
                    placeholder="Sales"
                    tooltip="Think of this as a project or dataset name. If your data is for instance about sales data, call it 'Sales'."
                    error={!!getErrorInfoByFieldId(errors, 'name')}
                    description={getErrorInfoByFieldId(errors, 'name')?.message || 'The name of the Knowledge Graph'}
                    highlighted={!!knowledgeGraph}
                  />
                </div>
                <div className={clsx(signupStyles.formField, styles.smaller)}>
                  <Controller
                    as={Select}
                    name="defaultLanguageId"
                    options={formattedLanguages}
                    control={control}
                    label="Language"
                    tooltip="Choose the language you will use to ask questions to Veezoo. This should be the same language as the one your data is in."
                    disabled={loading.status || !!knowledgeGraph}
                    error={!!getErrorInfoByFieldId(errors, 'defaultLanguageId')}
                    description={getErrorInfoByFieldId(errors, 'defaultLanguageId')?.message || 'The default language'}
                    data-test="newKnowledgeGraphDefaultLanguage"
                    highlighted={!!knowledgeGraph}
                    layout={layouts.signup}
                  />
                </div>
              </div>

              <div className={signupStyles.mt_40} data-test="excelFileUploadInput">
                <Controller as={<input type="hidden" />} name="file" control={control} label="File" />
                <Dropzone
                  shouldTrack
                  knowledgeGraph={knowledgeGraph}
                  acceptedFiles={[
                    'text/csv',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    '.csv'
                  ]}
                  dropzoneText={
                    selectedFiles.length < 1 ? 'Drop an Excel/CSV file here or click' : selectedFiles[0].file.name
                  }
                  filesLimit={1}
                  fileObjects={selectedFiles}
                  onAdd={fileObjs => setSelectedFiles(fileObjs)}
                  onDelete={() => setSelectedFiles([])}
                  error={Boolean(errors.file)}
                />
              </div>
            </>
          )}
        </div>
        {!loading.status && (
          <div className={signupStyles.instructions}>
            <ul>
              <li>
                Allowed formats are <b>&quot;.xlsx&quot;</b> and <b>&quot;.csv&quot;</b> files.
              </li>
              <li>
                Your file should have a maximum size of <b>50Mb.</b>{' '}
              </li>
              <li>Your file can have multiple worksheets!</li>
              <li>Make sure to use the first line as a line of Headers, with the column names in it.</li>
              <li>
                In the case of Excels with formulas, we suggest to copy the Excel contents to another file without the
                formulas, while keeping the values.
              </li>
            </ul>
          </div>
        )}
        <div className={signupStyles.mt_25}>
          {!loading.status && (
            <>
              <Button
                type="button"
                size={isMobile ? 'mobile' : ''}
                color="light"
                rootClass={{ marginRight: 10 }}
                disabled={loading}
                data-test="backButton"
                onClick={!loading.status ? () => setStep(step => step - 1) : null}
              >
                Back
              </Button>
              <Button
                type="submit"
                size={isMobile ? 'mobile' : ''}
                color="dark"
                disabled={loading}
                data-test="submitNewKnowledgeGraph"
              >
                Next
              </Button>
            </>
          )}
          {loading.status && <PageLoader message={loading.message} />}
        </div>
      </form>
      <TroubleConnectingMessage blue />
    </div>
  );
};

export default memo(connect()(ExcelConnector));
