import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutlineRounded';
import { veezooUrls } from 'config/constants';

const HelpButton = ({ docsUrl, small, ...props }) => {
  return (
    <Tooltip title="Open the documentation" placement="top">
      <IconButton size="small" href={`${veezooUrls.docs}${docsUrl}`} target="_blank" rel="noreferrer" {...props}>
        <HelpIcon fontSize={small ? 'small' : 'default'} />
      </IconButton>
    </Tooltip>
  );
};

export default HelpButton;
