import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import nameSplit from 'utils/nameSplit';

import styles from './avatar.scss';

const propTypes = {
  username: PropTypes.string,
  avatar: PropTypes.string,
  size: PropTypes.number
};
const defaultProps = {
  size: 22,
  avatar: ''
};

export function Avatar(props) {
  const { size } = props;

  let { username } = props;

  const initials = nameSplit(username);
  const initial = initials.split('');
  const extendedInitialArray = ['W', 'O', 'M', 'U', 'G'];

  const shape = {
    width: size,
    height: size
  };

  const initialsClass = classnames({
    [styles.initials]: true,
    // case if initials are too wide
    'extendend-initials': extendedInitialArray.includes(initial[0]) || extendedInitialArray.includes(initial[1])
  });

  return (
    <div className={styles.avatar} style={shape}>
      <span className={initialsClass}>{initials}</span>
    </div>
  );
}

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

// export needs for unit tests
export const mapStateToProps = state => {
  return {
    username: state.user.username
  };
};

export default connect(mapStateToProps)(Avatar);
