import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUserInformation } from '../../store/modules/user';
import { Modal } from '@material-ui/core';
import YouTubeVideo from './YouTubeVideo';
import { trackEvent } from '../../utils/eventTracking';
import { adminRoutes, studioRoutes, veezooRoutes } from 'components/app/routes';
import Joyride, { EVENTS } from 'react-joyride';
import { GeneralOptions } from '../tutorial/Tutorial';
import { elementAbsent, elementPresent } from '../../utils/domObservers';

const MilestonesChecklist = ({ dispatch, location, history, t, user, graph, kgId, suggestionsForBadNames }) => {
  // the currently opened milestone, i.e. the milestone to show the video of
  const [openMilestone, setOpenMilestone] = useState(null);
  // the currently highlighted milestone, i.e. the milestone to highlight the element of
  const [highlightedMilestone, setHighlightedMilestone] = useState(null);
  // specifies if the checklist should be shown or not
  const [showChecklist, setShowChecklist] = useState(null);
  // Joyride's helper functions
  const [joyrideHelpers, setJoyrideHelpers] = useState(null);

  // the (shorthand) URI of the class to open for the 'EditKnowledgeGraph' and 'EditVkl' milestones (e.g. 'kb:Customer')
  const classUri = useMemo(() => {
    const urisWithSuggestions = Object.keys(suggestionsForBadNames);
    // the top-level nodes are classes
    const classNodes = graph?.sidebarNodeLayouts;
    const classNode =
      // find a class node for which there is a naming suggestion (recipe)...
      classNodes?.find(node => urisWithSuggestions.includes(node.uri)) ||
      // ...or fall back to the first class shown in the KG sidebar
      classNodes?.[0];
    return classNode?.uri;
  }, [graph, suggestionsForBadNames]);

  // the file path of the above class for the editor
  const classFilePath = useMemo(() => {
    // remove the prefix from the (shorthand) class URI
    const classIdentifier = classUri?.split(':')[1];
    return `${kgId}/knowledge-base/classes/${classIdentifier}/class.ttl`;
  }, [graph, kgId, classUri]);

  // opens the specified path in a new window
  const open = useCallback(
    (path, additionalSearchParams) => {
      const params = new URLSearchParams(location.search);
      Object.entries(additionalSearchParams || {}).forEach(([key, value]) => params.set(key, value));
      window.open(path.substring(1) + '?' + params.toString());
    },
    [location]
  );

  // navigates to the specified path
  const navigateTo = useCallback(
    (pathname, hash, kgSidebarIsOpen) => {
      const params = new URLSearchParams(location.search);
      if (kgSidebarIsOpen) {
        params.set('kgSidebar', !!kgSidebarIsOpen);
      }

      const search = `?${params.toString()}`;

      history.push({ pathname, search, hash });
    },
    [location, history]
  );

  // the milestones
  const milestones = useMemo(
    () => [
      {
        id: 'ConnectData',
        action: _ => open(studioRoutes.new)
      },
      {
        id: 'EditKnowledgeGraph',
        // use a small timeout in case a knowledge graph popover is already open, allowing it to close before reopening
        action: _ => window.setTimeout(() => navigateTo(veezooRoutes.chat, `#${classUri}`, true), 100),
        highlight: {
          // wait before highlighting the element to allow the popover slide-in transition to complete
          delay: 400,
          selector: '#knowledge-graph-popover',
          // don't scroll inside the popover (leads to misaligned spotlight)
          options: { disableScrolling: true },
          stepOptions: { placement: 'right' }
        }
      },
      {
        id: 'AskQuestion',
        highlight: {
          selector: '[data-overview-tutorial="message-composer"]',
          stepOptions: { placement: 'top-start' }
        }
      },
      {
        id: 'CreateWidget',
        highlight: {
          selector: '[data-answer-tutorial="add-to-board"]'
        }
      },
      {
        id: 'InviteUsers',
        action: _ => open(adminRoutes.users)
      },
      {
        id: 'EditVkl',
        action: _ => open(studioRoutes.editor, { file: classFilePath })
      }
    ],
    [open, navigateTo, classUri, classFilePath]
  );

  // initially determines if the checklist should be shown or not
  useEffect(() => {
    // show the checklist if the user contains milestone information...
    if (showChecklist == null && user?.milestones) {
      // ...and at least one milestone hasn't been completed yet
      const allMilestonesCompleted = !milestones.some(milestone => !user.milestones.includes(milestone.id));
      setShowChecklist(!allMilestonesCompleted);
    }
  }, [showChecklist, user, milestones]);

  // refreshes the user information
  const refresh = _ => dispatch(fetchUserInformation());

  // initially refreshes the user information and sets up event listeners
  useEffect(() => {
    // initially refresh the user information
    refresh();
    // refresh whenever the window receives focus, only if we actually render the checklist
    if (showChecklist) {
      window.addEventListener('focus', refresh);
    }
    // remove the event listner when the component is unmounted
    return () => window.removeEventListener('focus', refresh);
  }, [showChecklist]);

  // returns a key to be used to get a translation for a milestone (e.g. 'milestones.connect-data.title')
  const key = (milestone, key) => {
    // convert the milestone id from camel case to using hyphens
    const id = milestone?.id.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase());
    return `milestones.${id}.${key}`;
  };

  // the options for Joyride, reusing the ones from the tutorials
  const joyrideOptions = GeneralOptions({}, t);
  // don't allow interaction with the highlighted element
  joyrideOptions.spotlightClicks = false;
  // reduce the tooltip padding
  joyrideOptions.styles.tooltipContent = { padding: '0px' };

  // checks whether the specified milestone is completed or not
  const isCompleted = useCallback(milestone => user.milestones?.includes(milestone.id), [user]);

  // executes the milestone's action and highlights it (if any)
  const executeMilestone = milestone => {
    // execute the action
    milestone.action?.();
    if (milestone.highlight) {
      // wait for the element to highlight to be present in the DOM
      elementPresent(milestone.highlight.selector).then(_ => {
        // highlight the milestone, taking into account its delay (if any)
        milestone?.highlight?.delay > 0
          ? window.setTimeout(_ => setHighlightedMilestone(milestone), milestone.highlight.delay)
          : setHighlightedMilestone(milestone);
        // un-highlight the milestone if element to highlight is removed from the DOM
        elementAbsent(milestone.highlight.selector).then(_ => setHighlightedMilestone(null));
      });
    }
  };

  const renderMilestone = useCallback(
    milestone => {
      const milestoneIsCompleted = isCompleted(milestone);
      const youTubeVideoIdKey = key(milestone, 'youTubeVideoId');
      const hasVideo = t(youTubeVideoIdKey) !== youTubeVideoIdKey;
      return (
        <div className={styles.milestone} key={milestone.id}>
          <div className={milestoneIsCompleted ? styles.checkmark__completed : styles.checkmark} />
          <a
            href="#"
            className={milestoneIsCompleted ? styles.link__completed : styles.link}
            data-test={`milestone-${milestone.id}-${milestoneIsCompleted ? 'completed' : 'uncompleted'}`}
            onClick={event => {
              event.preventDefault();
              trackEvent('Milestone Clicked', {
                milestone: {
                  id: milestone.id,
                  isCompleted: milestoneIsCompleted
                }
              });
              hasVideo ? setOpenMilestone(milestone) : executeMilestone(milestone);
            }}
          >
            {t(key(milestone, 'title'))}
          </a>
        </div>
      );
    },
    [t, user, isCompleted]
  );

  // overrides Joyride's click handlers to close Joyride (this is needed so that the click event can be stopped from
  // propagating, which for the 'EditKnowledgeGraph' milestone causes the knowledge graph popover to close)
  useEffect(() => {
    if (highlightedMilestone && joyrideHelpers) {
      const onClick = close => event => {
        event.stopPropagation();
        close ? joyrideHelpers.close() : null;
      };
      // override click on overlay
      elementPresent('.react-joyride__overlay').then(element => (element.onclick = onClick(true)));
      // override click on tooltip
      elementPresent('.react-joyride__tooltip').then(element => (element.onclick = onClick(false)));
      // override click on close button inside tooltip
      elementPresent('.react-joyride__tooltip button').then(element => (element.onclick = onClick(true)));
    }
  }, [highlightedMilestone, joyrideHelpers]);

  return !showChecklist ? null : (
    <div className={styles.root} data-test="milestonesChecklist">
      <h3>{t('milestones.first-steps')}</h3>

      {milestones.map(milestone => renderMilestone(milestone))}

      <Modal
        className={styles.modal}
        open={!!openMilestone}
        onClose={_ => setOpenMilestone(null)}
        data-test="youTubeVideoModal"
      >
        <div className={styles.video}>
          {!!openMilestone && (
            <YouTubeVideo
              id={t(key(openMilestone, 'youTubeVideoId'))}
              onUnmount={playbackTime => {
                trackEvent('Milestone Video Watched', {
                  milestone: {
                    id: openMilestone.id,
                    isCompleted: isCompleted(openMilestone),
                    videoPlaybackTime: playbackTime
                  }
                });
                executeMilestone(openMilestone);
              }}
            />
          )}
        </div>
      </Modal>

      {!!highlightedMilestone?.highlight && (
        <Joyride
          {...Object.assign(joyrideOptions, highlightedMilestone.highlight.options)}
          steps={[
            {
              target: highlightedMilestone.highlight.selector,
              disableBeacon: true,
              placement: 'top',
              locale: { last: t('close') },
              content: <span data-test="highlightTooltip">{t(key(highlightedMilestone, 'highlightTooltipText'))}</span>,
              ...highlightedMilestone.highlight.stepOptions
            }
          ]}
          run={true}
          getHelpers={setJoyrideHelpers}
          callback={event => {
            if (event.type === EVENTS.TOUR_END) {
              // un-highlight the milestone when Joyride closes
              setHighlightedMilestone(null);
            }
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  graph: state.graph,
  kgId: state.knowledgeGraphMeta.meta.id,
  suggestionsForBadNames: state.recipes.suggestionsForBadNames
});

export default connect(mapStateToProps)(withRouter(MilestonesChecklist));
