import { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import Input from 'components/v2/Input';
import Title from 'components/v2/Typography/Title';

import { DescriptionSkeleton } from './NodeContentSkeleton';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import handleKeyDown from 'utils/handleKeyDown';

import { setIsEditingDescription } from 'store/modules/nodeEditor';

import { trackEvent } from 'utils/eventTracking';

import EditIcon from '../EditIcon';

import styles from '../styles.scss';
import MarkdownHtml from 'studio/components/MarkdownHtml/MarkdownHtml';

const NodeDescription = ({
  name,
  uri,
  hasWritePermission,
  updateDescription,
  description,
  updatesLoading,
  updatesFailed,
  isEditingDescription,
  hasFetched,
  isExpanded,
  setIsExpanded,
  dispatch,
  t
}) => {
  const [descriptionInputValue, setDescriptionInputValue] = useState('');

  const loading = updatesLoading.some(item => item === 'description');
  const failedLoading = updatesFailed.some(item => item === 'description');

  const handleDescriptionInputChange = event => {
    const value = event.target.value;
    setDescriptionInputValue(value);
  };

  const editDescription = () => {
    trackEvent('Info Panel Description Input Clicked', { name, uri, description });
    dispatch(setIsEditingDescription(true));
    setIsExpanded(true);
  };

  const saveDescription = () => {
    if (descriptionInputValue !== description?.markdown) {
      updateDescription(descriptionInputValue);
    }
    dispatch(setIsEditingDescription(false));
  };

  const resetDescription = () => {
    setDescriptionInputValue(description?.markdown);
    dispatch(setIsEditingDescription(false));
  };

  /** Everytime the "description" field is updated in the backend, we update the description input. */
  useEffect(() => {
    setDescriptionInputValue(description?.markdown);
  }, [description]);

  return (
    <div
      className={clsx(
        styles.section,
        hasWritePermission ? styles.allowEditing : '',
        isEditingDescription ? styles.isEditing : ''
      )}
      onClick={() => (hasWritePermission && hasFetched && !loading ? editDescription() : null)}
      data-test="KnowledgeGraphConceptDescription"
    >
      {isExpanded && (
        <div className={clsx(styles.editContainer)}>
          <div className={clsx(styles.subheaderContainer, styles.flexSpaceBetween)}>
            <Title size="small">{t('sidebar.description')}</Title>
            {hasWritePermission && hasFetched && <EditIcon loading={loading} warning={failedLoading} />}
          </div>
        </div>
      )}
      <div className={clsx(isExpanded ? styles.descriptionContainer : '')}>
        <div className={styles.horizontalPadding}>
          {!hasFetched && !description ? (
            <DescriptionSkeleton />
          ) : isEditingDescription ? (
            <ClickAwayListener onClickAway={saveDescription}>
              <Input
                autoFocus
                multiline
                value={descriptionInputValue}
                onChange={handleDescriptionInputChange}
                onKeyDown={event => handleKeyDown(event, saveDescription, resetDescription)}
                data-test="KnowledgeGraphConceptDescriptionInput"
              />
            </ClickAwayListener>
          ) : (
            <div className={styles.flexSpaceBetween}>
              <MarkdownHtml
                className={clsx(styles.description, !description ? styles.noItemsFound : '')}
                /*
                As we update optimistically here, a user can inject unsanitized description here.
                Don't think this is an issue though. On refresh it will anyway be sanitized if it's nasty.
                */
                html={description?.html || t('sidebar.no-description-yet')}
              />
              {hasWritePermission && hasFetched && !isExpanded && (
                <EditIcon loading={loading} warning={failedLoading} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission,
  updatesLoading: state.graph.updatesLoading,
  updatesFailed: state.graph.updatesFailed,
  isEditingDescription: state.nodeEditor.isEditingDescription
});

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(NodeDescription)));
