import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import BrowseBoardsView from './BrowseBoardsView';

import { fetchPublicBoardsList, subscribeToBoard, unsubscribeFromBoard } from 'store/modules/board';

import { trackEvent } from 'utils/eventTracking';

const ConnectedBrowseBoardsView = ({
  setSelectedBoardId,
  goToNextSlide,
  user,
  loadingPublicBoards,
  publicBoardsList,
  dispatch
}) => {
  const { t } = useTranslation();

  const toggleSubscribe = (boardId, isEnabled) => {
    // dispatch events to call the API and update the board sidebar list
    if (isEnabled) {
      trackEvent('Shared Board Subscribed', { boardId });
      dispatch(subscribeToBoard(boardId));
    } else {
      trackEvent('Shared Board Unsubscribed', { boardId });
      dispatch(unsubscribeFromBoard(boardId));
    }
  };

  useEffect(() => {
    dispatch(fetchPublicBoardsList());
  }, []);

  return (
    <BrowseBoardsView
      user={user}
      boards={publicBoardsList}
      isLoading={loadingPublicBoards}
      toggleSubscribe={toggleSubscribe}
      setSelectedBoardId={setSelectedBoardId}
      goToNextSlide={goToNextSlide}
      t={t}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
  loadingPublicBoards: state.board.loadingPublicBoards,
  publicBoardsList: state.board.publicBoardsList
});

export default connect(mapStateToProps)(ConnectedBrowseBoardsView);
