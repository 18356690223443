import { baseURL } from 'config/baseURL';
import { headersPOST } from '../store/utils/HTTPheaders';

export const trackEvent = (eventName, extraData = null) => {
  return fetch(baseURL + 'track', {
    method: 'POST',
    headers: headersPOST,
    body: JSON.stringify({
      eventName: eventName,
      time: Date.now(),
      extraData: extraData || {}
    }),
    credentials: 'include'
  });
};
