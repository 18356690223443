import { alpha, makeStyles } from '@material-ui/core/styles';

export const useSelectStyles = makeStyles({
  icon: {
    width: 20,
    height: 20,
    color: 'white',
    opacity: 0.7,
    fontSize: '20px',
    border: 'none',
    top: 'calc(50% - 10px)',
    right: '5px'
  },
  select: {
    padding: '6px 0 6px 5px',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '& > .MuiListItemIcon-root': {
      display: 'none'
    }
  },
  disabled: {
    color: 'rgba(255, 255, 255, 0.4)'
  }
});

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  selectRoot: {
    cursor: 'pointer',
    borderRadius: 'var(--border-radius-xs)',
    transition: 'background-color 0.2s ease',
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '1.688em',
    letterSpacing: '0.3px',
    border: `solid thin transparent`,
    padding: '0 10px',
    '&:focus': {
      backgroundColor: 'rgba(255,255,255,0.3)'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      boxShadow: 'none'
    },
    '&&.Mui-error': {
      border: `solid thin red`
    }
  },
  devKgRoot: {
    backgroundColor: '#FFA500FF',
    '&:hover': {
      backgroundColor: alpha('#FFA500FF', 0.9)
    },
    '&:focus': {
      backgroundColor: alpha('#FFA500FF', 0.9)
    }
  },
  selectUnderline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  labelRoot: {
    '&&+.MuiInput-formControl': {
      marginTop: '0px'
    }
  },
  adornmentRoot: {
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18
    }
  }
}));

export const useMenuStyles = makeStyles(() => ({
  list: {
    '& > .MuiMenuItem-root': {
      fontSize: '14px',
      color: 'var(--font-color)',
      height: '31px'
    }
  }
}));

export const useMenuIconStyles = makeStyles(() => ({
  root: {
    minWidth: 25
  }
}));
