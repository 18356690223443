import React, { memo, useEffect, useRef } from 'react';
import { withSnackbar } from 'notistack';

import Input from 'components/v2/Input';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';

import useStyles from './styles';
import { Button } from 'components/v3';
import FileCopyIcon from '@material-ui/icons/FileCopyRounded';

const ShareAnswerForm = ({ sharedAnswer, showSocialShareButtons, enqueueSnackbar, t }) => {
  const styles = useStyles();
  const inputRef = useRef();

  useEffect(() => {
    inputRef?.current?.select();
  });

  let sharingUrl = t('sharing.share-answer-modal.failed');
  if (sharedAnswer?.url) {
    sharingUrl = sharedAnswer.url;
  } else if (sharedAnswer?.isLoading) {
    sharingUrl = t('loading');
  }

  const emailShareButton = (
    <span className={styles.sharingElement}>
      <EmailShareButton url={sharingUrl} data-sharing-channel="email" data-test="sharingChannelButton">
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
    </span>
  );

  const socialShareButtons = (
    <>
      <span className={styles.sharingElement}>
        <TwitterShareButton url={sharingUrl} data-sharing-channel="twitter" data-test="sharingChannelButton">
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </span>
      <span className={styles.sharingElement}>
        <FacebookShareButton url={sharingUrl} data-sharing-channel="facebook" data-test="sharingChannelButton">
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </span>
      <span className={styles.sharingElement}>
        <LinkedinShareButton url={sharingUrl} data-sharing-channel="linkedin" data-test="sharingChannelButton">
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
      </span>
      <span className={styles.sharingElement} data-sharing-channel="reddit" data-test="sharingChannelButton">
        <RedditShareButton url={sharingUrl}>
          <RedditIcon size={32} round={true} />
        </RedditShareButton>
      </span>
    </>
  );

  const copyToClipboard = async text => {
    await navigator.clipboard.writeText(text);
    enqueueSnackbar(t('copied-to-clipboard'), { variant: 'info', autoHideDuration: 2000 });
  };

  const copyToClipboardButton = (
    <span className={styles.sharingElement} style={{ float: 'right' }}>
      <Button onClick={() => copyToClipboard(sharingUrl)} layout="veezoo" mode="dark" icon={<FileCopyIcon />}>
        {t('copy-action')}
      </Button>
    </span>
  );

  return (
    <>
      <div data-test="sharedUrlInput" data-shared-answer-id={sharedAnswer?.sharedAnswerId}>
        <Input value={sharingUrl} fullWidth readOnly ref={inputRef} />
      </div>
      <div className={styles.buttonContainer}>
        {showSocialShareButtons ? socialShareButtons : null}
        {emailShareButton}
        {copyToClipboardButton}
      </div>
    </>
  );
};

export default memo(withSnackbar(ShareAnswerForm));
