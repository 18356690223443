import React from 'react';

import UIDrawer from '@material-ui/core/Drawer';

import useStyles from './styles';

const Drawer = ({ open, onClose, children, width, paperClasses, anchor, variant, ...rest }) => {
  const classes = useStyles({ width });

  return (
    <UIDrawer
      variant={variant}
      anchor={anchor}
      open={open}
      onClose={onClose}
      classes={{ root: classes.root, paper: `${paperClasses} ${classes.paper}` }}
      {...rest}
    >
      {children}
    </UIDrawer>
  );
};

export default Drawer;

Drawer.defaultProps = {
  variant: 'persistent',
  width: 300,
  anchor: 'left'
};
