import { makeStyles } from '@material-ui/core';

const primaryColor = 'var(--primary-color)';
const opacity = 0.6;

export default makeStyles({
  container: {
    display: 'inline-block',
    '&:hover > $icon': {
      '& > path': {
        opacity
      }
    },
    '&:hover > $text': {
      opacity
    }
  },
  icon: {
    verticalAlign: 'middle',
    width: 16,
    height: 16,
    '& > path': {
      fill: ({ color }) => color || primaryColor
    }
  },
  text: {
    marginLeft: ({ Icon }) => (Icon ? 5 : 0),
    verticalAlign: 'middle',
    fontSize: 14,
    color: ({ color }) => color || primaryColor,
    cursor: 'pointer'
  }
});
