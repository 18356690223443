import { makeStyles } from '@material-ui/core/styles';

// for the material ui menu we want to disable the padding at the top and bottom of the list
// each list item should have font size 12 px
export const contextMenuStyles = makeStyles(theme => ({
  list: {
    padding: 0
  }
}));

export const listMenuItemStyles = makeStyles(theme => ({
  root: {
    fontSize: '12px'
  }
}));

export const contextMenuHeaderStyles = makeStyles(theme => ({
  root: {
    padding: '5px 20px 5px 20px',
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: '12px',
    background: 'var(--follow-up-color)'
  }
}));
