import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';

import { fetchAnnotatedQuestion } from 'store/modules/annotatedQuestion';
import { toggleFollowUp } from 'store/modules/followUpState';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import Button from 'components/buttons/Button';
import Avatar from 'components/avatar/Avatar';
import Timestamp from 'components/time/Timestamp';

import styles from './user-message.scss';
import messageStyles from './message.scss';

export function UserMessage(props) {
  const { t, chatMessages, id, textAnswer, isFollowUp, timestamp } = props;

  const answerToQuestionMessage = chatMessages.find(msg => msg.localQuestionId && msg.localQuestionId === id);
  const questionId = answerToQuestionMessage ? answerToQuestionMessage.questionId : 'no id at this point';

  let contextMessage;
  if (answerToQuestionMessage !== undefined && answerToQuestionMessage.referenceTo) {
    const { answerId, interpretationId } = answerToQuestionMessage.referenceTo;
    contextMessage = chatMessages.find(
      msg =>
        msg.id &&
        msg.answerId === answerId &&
        ((msg.answer && msg.answer.interpretationId === interpretationId) || // normal answer message
          (msg.interpretationIds && msg.interpretationIds.includes(interpretationId))) // board answer message
    );
  }

  let contextInterpretationId, contextPartialAnswerId, contextAnswerId, contextMessageId;
  if (contextMessage !== undefined) {
    contextInterpretationId = answerToQuestionMessage.referenceTo.interpretationId;
    contextPartialAnswerId = answerToQuestionMessage.referenceTo.partialAnswerId;
    contextAnswerId = answerToQuestionMessage.referenceTo.answerId;
    contextMessageId = contextMessage.id;
  }

  const editButton = (
    <InfoTooltip text={t('tooltip.edit')} placement="left">
      <Button
        text={t('edit')}
        className="link"
        onClick={() => {
          if (contextMessage) {
            // this is true only if it's followUp question
            props.scrollTo(contextMessage.id);
            // use a timeout here so we actually register a scrolling event and then handle
            // correct ordering of first scrolling and then setting the focus on the input box
            // see Chat.js
            setTimeout(() => props.dispatch(fetchAnnotatedQuestion(questionId, textAnswer)), 200);
          } else {
            props.dispatch(fetchAnnotatedQuestion(questionId, textAnswer));
          }

          // TODO: all props are here undefined because of MultiAnswer
          if (contextInterpretationId && contextAnswerId && contextMessageId) {
            props.dispatch(
              toggleFollowUp(contextInterpretationId, contextPartialAnswerId, contextAnswerId, contextMessageId)
            );
          }
        }}
      />
    </InfoTooltip>
  );

  const referenceToText = (
    <div className={styles.referenceToText}>
      <Trans i18nKey="reference-to-text">
        This Question is a FollowUp on
        <span className={styles.linkStyle} onClick={() => props.scrollTo(contextMessage.id)}>
          this Answer
        </span>
      </Trans>
    </div>
  );

  const userIcon = (
    <div className={`${styles.userIcon} ${messageStyles.hideOnSmallScreens}`}>
      <Avatar size={50} />
    </div>
  );

  const userMessage = (
    <>
      <div className={styles.container}>
        <div className={styles.placeholder} />
        <div className={styles.messageUser}>
          <div className={styles.messageBody}>{textAnswer}</div>
          {contextMessage ? referenceToText : ''}
        </div>
        {userIcon}
      </div>
      <div className={styles.footer}>
        {isFollowUp ? 'FollowUp' : null}
        {isFollowUp ? <span className={messageStyles.separator}>•</span> : null}
        {editButton}
        <>
          <span className={messageStyles.separator}>•</span>
          <Timestamp time={timestamp} />
        </>
      </div>
    </>
  );
  /**
   * end helper components.
   */

  return <div className={styles.wrapper}>{userMessage}</div>;
}

const mapStateToProps = state => ({ chatMessages: state.chatMessages });

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(UserMessage));
