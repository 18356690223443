import { baseURL } from 'config/baseURL';
import { get, patch } from '../utils.js';

const fetchClass = uri => {
  return get(`${baseURL}knowledge-graph/classes/${encodeURIComponent(uri)}`);
};

const updateClass = (uri, body) => {
  return patch(`${baseURL}knowledge-graph/classes/${encodeURIComponent(uri)}`, JSON.stringify(body));
};

export default { fetchClass, updateClass };
