import { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Dialog from 'components/v2/Dialog';
import Button from 'components/v2/Button';

import styles from './ConfirmationModal.scss';

const Content = ({ defaultText, children, confirmButtonText, cancelButtonText, onConfirm, onClose, t, ...props }) => {
  const confirmButtonRef = useRef(null);

  useEffect(() => {
    if (open && confirmButtonRef.current) {
      confirmButtonRef.current.focus();
    }
  }, [open, confirmButtonRef.current]);

  // if no children are provided, show a basic paragraph with the given defaultText
  const content = children || <p>{defaultText}</p>;

  return (
    <div className={styles.confirmationModal}>
      {content}
      <div className={styles.buttonsContainer}>
        <Button
          label={cancelButtonText ? cancelButtonText : t('cancel')}
          width="calc(50% - 10px)"
          bordered
          onClick={onClose}
        />
        <Button
          ref={confirmButtonRef}
          label={confirmButtonText ? confirmButtonText : 'OK'}
          dark
          bordered
          width="calc(50% - 10px)"
          onClick={onConfirm}
          data-test="confirmationTooltipConfirm"
        />
      </div>
    </div>
  );
};

const ConfirmationModal = ({ title, open, onClose, ...props }) => (
  <Dialog
    title={title}
    open={open}
    hideCloseIcon
    content={<Content onClose={onClose} {...props} />}
    onClose={onClose}
    {...props}
  />
);

export default withTranslation('veezoo')(ConfirmationModal);

Content.propTypes = {
  defaultText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  defaultText: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ConfirmationModal.defaultProps = {
  open: false
};
