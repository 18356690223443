import PropTypes from 'prop-types';

import BaseMessage from './BaseMessage';

import styles from './info-message.scss';
import footerStyles from './answer-message-footer.scss';
import KnowledgeGraphButton from 'components/buttons/KnowledgeGraphButton';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Icon from '../icons/Icon';
import ChatMessageMarkdownHtml from './ChatMessageMarkdownHtml/ChatMessageMarkdownHtml';

const customFooter = (
  <aside className={footerStyles.buttonsContainer}>
    <ul className={footerStyles.list}>
      <li className={footerStyles.item}>
        <KnowledgeGraphButton />
      </li>
    </ul>
  </aside>
);

const InfoMessage = ({ message, hasKnowledgeGraphSupport, isWarningMessage }) => {
  const showKnowledgeGraphFooter = hasKnowledgeGraphSupport && message.suggestKnowledgeGraph;
  const messageClassName = showKnowledgeGraphFooter ? `${styles.wrapper} ${styles.withFooter}` : styles.wrapper;

  const markdownMessage = <ChatMessageMarkdownHtml html={message.textAnswer} />;
  const answer = (
    <div className={messageClassName}>
      {isWarningMessage ? (
        <Icon data-test="icon-warning" type="icon-warning" />
      ) : (
        message.isError && <ErrorRoundedIcon style={{ fontSize: '2em', color: 'var(--error-red)' }} />
      )}
      {isWarningMessage || message.isError ? <span className={styles.desc}>{markdownMessage}</span> : markdownMessage}
    </div>
  );

  return (
    <div data-test="veezooInfoMessage" key={message.id}>
      <BaseMessage
        key={message.id}
        messageId={message.id}
        showVeezooIcon={message.showVeezooIcon}
        content={answer}
        customFooter={showKnowledgeGraphFooter && customFooter}
        timestamp={message.timestamp}
      />
    </div>
  );
};

export default InfoMessage;

InfoMessage.propTypes = {
  message: PropTypes.object,
  hasKnowledgeGraphSupport: PropTypes.bool,
  isWarningMessage: PropTypes.bool
};
