export const veezooRoutes = {
  ask: '/ask/:question',
  shared: '/shared/:sharedAnswerId',
  board: '/board/:boardId',
  chat: '/chat',
  customersOfTheDay: '/chat/customers-of-the-day',
  knowledgeGraph: '/knowledge-graph',
  discovery: '/discovery',
  login: '/login',
  tests: '/tests',
  settings: '/settings',
  signup: '/signup',
  exasolSignup: '/exasol/signup',
  forgotPassword: '/password/reset',
  passwordRedefine: '/password/reset/:token',
  invitation: '/invitation/:invitationId',
  userRedirected: '/redirected',
  admin: '/admin',
  studio: '/studio'
};

export const adminRoutes = {
  users: `${veezooRoutes.admin}/users`
};

export const studioRoutes = {
  new: `${veezooRoutes.studio}/new`,
  users: `${veezooRoutes.studio}/users`,
  configuration: `${veezooRoutes.studio}/configuration`,
  editor: `${veezooRoutes.studio}/editor`
};
