import { makeStyles } from '@material-ui/core';

export default makeStyles({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    border: 'solid thin var(--light-gray)',
    backgroundColor: 'white',
    padding: '4px 8px',
    lineHeight: '16px',
    fontWeight: 500,
    color: 'var(--primary-color)',
    boxSizing: 'border-box',
    maxWidth: '100%'
  },
  allowHover: {
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  textContainer: {
    width: '100%',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  closeIcon: {
    width: 10,
    height: 10,
    marginLeft: 5,
    cursor: 'pointer',
    '& path': {
      fill: 'var(--primary-color)'
    },
    '&:hover': {
      '&:path': {
        fill: 'red'
      }
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  }
});
