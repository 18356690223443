import { useEffect } from 'react';
import cssVars from 'css-vars-ponyfill';
import { connect } from 'react-redux';
import { loadTheme } from 'store/modules/theme';

const updateCSSVariables = variables => {
  // support IE 11, for modern browser it will invoke setProperty,
  // for example, document.documentElement.style.setProperty(prop, value);
  cssVars({
    variables,
    rootElement: document,
    // by setting this true we observe the dom for style changes and process the new style changes
    // this flag fixed a bunch of ie11 styling bugs introduced with material-ui's dynamic stylings
    // Before the new dynamic stylings weren't processed and the variables not resolved
    watch: true,
    exclude: '[data-cssvarsponyfill="false"]'
  });
};

const ThemeContainer = ({ cssVars: cssVarsFromProps, dispatch }) => {
  useEffect(() => {
    dispatch(loadTheme('default'));
  }, [dispatch]);

  useEffect(() => {
    updateCSSVariables(cssVarsFromProps || {});
  }, [cssVarsFromProps]);

  return null;
};

const mapStateToProps = state => ({
  theme: state.theme,
  cssVars: state.theme.themeDetails.cssVars
});

export default connect(mapStateToProps)(ThemeContainer);
