import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import sharedStyles from 'components/Signup/Signup.scss';
import VeezooLogo from 'svg/veezoo-logo-complete.svg';

const StepperContainer = ({ customStepper, customContainerStyle, children }) => {
  return (
    <>
      <div className={styles.headerRow}>
        <VeezooLogo className={styles.veezooLogoAbsolute} />
        <div className={styles.customStepper}>{customStepper}</div>
      </div>
      <div className={customContainerStyle ? customContainerStyle : sharedStyles.container}>{children}</div>
    </>
  );
};

export default StepperContainer;

StepperContainer.propTypes = {
  customStepper: PropTypes.any,
  customContainerStyle: PropTypes.any
};
