export function initializePlzMap(Highcharts) {
  Highcharts.maps['countries/ch-plz/ch-plz-all'] = {
    type: 'FeatureCollection',
    name: 'switzerland_dissolved',
    crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
    features: [
      {
        type: 'Feature',
        properties: { name: '7165', ortbez27: 'Breil/Brigels' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2721820, 1188516.8],
                [2726332.6, 1180886.8],
                [2723968.7, 1180308.4],
                [2720520, 1181031.5],
                [2717915.2, 1182814.5],
                [2716039.3, 1184965.3],
                [2721820, 1188516.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3066', ortbez27: 'Stettlen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2605359, 1201805.4], [2607122.9, 1203362.2], [2605511.3, 1200401.3], [2605359, 1201805.4]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7166', ortbez27: 'Trun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716039.3, 1184965.3],
                [2717915.2, 1182814.5],
                [2718189.5, 1180031.4],
                [2722304.8, 1179143.4],
                [2721457.2, 1177623.5],
                [2720206.8, 1177735.5],
                [2718399.7, 1177616.1],
                [2720442.8, 1175225.9],
                [2720760.9, 1173451.7],
                [2720610.9, 1172197.8],
                [2719318.9, 1172188.6],
                [2717998, 1172146.7],
                [2716825.8, 1172952.7],
                [2716954.7, 1174219.7],
                [2717114.1, 1175471.7],
                [2716006.2, 1176336.3],
                [2714200.3, 1178226.7],
                [2713523.1, 1180822],
                [2713806.7, 1184036.3],
                [2716039.3, 1184965.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3063', ortbez27: 'Ittigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602947.9, 1205370.8],
                [2603909.4, 1202293.9],
                [2602165.8, 1202464.9],
                [2601161, 1203713.7],
                [2602947.9, 1205370.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7164', ortbez27: 'Dardin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2720520, 1181031.5], [2723968.7, 1180308.4], [2722304.8, 1179143.4], [2720520, 1181031.5]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7153', ortbez27: 'Falera' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737719.9, 1187069.6],
                [2737838.4, 1184117.9],
                [2735227.1, 1184602.4],
                [2734983.6, 1185837.4],
                [2737719.9, 1187069.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7154', ortbez27: 'Ruschein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2735353.9, 1183325.4],
                [2733677, 1182441.4],
                [2732614.5, 1183947.5],
                [2732455.7, 1187621],
                [2733137.9, 1186222.1],
                [2734983.6, 1185837.4],
                [2735227.1, 1184602.4],
                [2735353.9, 1183325.4]
              ]
            ],
            [
              [
                [2729800.8, 1191628.4],
                [2731063, 1193003.3],
                [2732215.1, 1190403.3],
                [2732455.7, 1187621],
                [2729800.8, 1191628.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3067', ortbez27: 'Boll' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610460.5, 1204690.2],
                [2610131.9, 1199492.4],
                [2606446.3, 1198731.4],
                [2605511.3, 1200401.3],
                [2607122.9, 1203362.2],
                [2608625.7, 1205108.3],
                [2610460.5, 1204690.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7151', ortbez27: 'Schluein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737838.4, 1184117.9],
                [2735806.3, 1181657.9],
                [2735353.9, 1183325.4],
                [2735227.1, 1184602.4],
                [2737838.4, 1184117.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3068', ortbez27: 'Utzigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610460.5, 1204690.2],
                [2611718.6, 1203855.5],
                [2613294.2, 1201394.6],
                [2612264.6, 1200067.6],
                [2611802.8, 1198707.8],
                [2610131.9, 1199492.4],
                [2610460.5, 1204690.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7152', ortbez27: 'Sagogn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2739102.6, 1183115.4],
                [2737838.4, 1184117.9],
                [2742320.7, 1187273.9],
                [2742731.5, 1185867.5],
                [2741672.6, 1184524.8],
                [2739102.6, 1183115.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7157', ortbez27: 'Siat' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729939.7, 1187571.1],
                [2729800.8, 1191628.4],
                [2732455.7, 1187621],
                [2732614.5, 1183947.5],
                [2730137.7, 1184137.9],
                [2729939.7, 1187571.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7158', ortbez27: 'Waltensburg/Vuorz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726332.6, 1180886.8],
                [2721820, 1188516.8],
                [2724104.3, 1192955.5],
                [2727106.7, 1182486.5],
                [2729138.1, 1182669.8],
                [2728982.4, 1180910.5],
                [2726527.4, 1179456.8],
                [2726332.6, 1180886.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7156', ortbez27: 'Rueun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729138.1, 1182669.8],
                [2728588.5, 1184207.3],
                [2724104.3, 1192955.5],
                [2727651.9, 1190310.4],
                [2729800.8, 1191628.4],
                [2729939.7, 1187571.1],
                [2730137.7, 1184137.9],
                [2732614.5, 1183947.5],
                [2733677, 1182441.4],
                [2732385.5, 1182045.6],
                [2730332, 1181478.2],
                [2728982.4, 1180910.5],
                [2729138.1, 1182669.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7145', ortbez27: 'Degen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2731878.8, 1173982.3], [2734172.7, 1174854.7], [2733732.2, 1173462.1], [2731878.8, 1173982.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7143', ortbez27: 'Morissen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2733360.4, 1176247.8], [2730413.8, 1177602.9], [2734104.9, 1178522.3], [2733360.4, 1176247.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7144', ortbez27: 'Vella' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2730413.8, 1177602.9],
                [2733360.4, 1176247.8],
                [2734172.7, 1174854.7],
                [2731878.8, 1173982.3],
                [2730808.3, 1175307.6],
                [2729825, 1174530.8],
                [2729410.8, 1176101.1],
                [2730413.8, 1177602.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3049', ortbez27: 'S�riswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592841.2, 1206094.7],
                [2592846.4, 1203913.5],
                [2590831.1, 1204351.3],
                [2591540.6, 1205869.7],
                [2592841.2, 1206094.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7149', ortbez27: 'Vrin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717077.4, 1166446.4],
                [2721116.2, 1168554.4],
                [2728004.1, 1168966.9],
                [2729244.2, 1165035.1],
                [2726025.6, 1160194.5],
                [2723116.6, 1160523.6],
                [2720170.2, 1163530.1],
                [2717649, 1162559.1],
                [2715560.1, 1164604.1],
                [2715995.7, 1165813.9],
                [2717077.4, 1166446.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7147', ortbez27: 'Vignogn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731132.2, 1171755.2],
                [2728555.5, 1174343.3],
                [2729825, 1174530.8],
                [2730808.3, 1175307.6],
                [2731878.8, 1173982.3],
                [2731132.2, 1171755.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7148', ortbez27: 'Lumbrein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729244.2, 1165035.1],
                [2728004.1, 1168966.9],
                [2721116.2, 1168554.4],
                [2720907.1, 1170834.8],
                [2727416.7, 1173527.1],
                [2728555.5, 1174343.3],
                [2731132.2, 1171755.2],
                [2731831.9, 1169801.2],
                [2731441.5, 1168231.4],
                [2729244.2, 1165035.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3053', ortbez27: 'M�nchenbuchsee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599006.4, 1207158.5],
                [2597824.9, 1208525.3],
                [2598618.5, 1209849.4],
                [2599090.5, 1208562.7],
                [2600104.4, 1209517.2],
                [2600782.2, 1210708.8],
                [2602107.8, 1210068.3],
                [2603371.4, 1209736.3],
                [2602434, 1208462.6],
                [2602139.7, 1206838.4],
                [2600146.1, 1206446.9],
                [2599006.4, 1207158.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3054', ortbez27: 'Sch�pfen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596542.7, 1207681.3],
                [2593916, 1207444.4],
                [2591524.5, 1209622.7],
                [2592571.5, 1210757.1],
                [2595026.1, 1211679.1],
                [2597260.8, 1211138.3],
                [2598618.5, 1209849.4],
                [2597824.9, 1208525.3],
                [2596542.7, 1207681.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7202', ortbez27: 'Says' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765692.3, 1193594.9],
                [2764353.8, 1199113.7],
                [2767421, 1199328],
                [2767305.4, 1193870.2],
                [2765692.3, 1193594.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3052', ortbez27: 'Zollikofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602139.7, 1206838.4],
                [2602947.9, 1205370.8],
                [2601161, 1203713.7],
                [2599854.5, 1203998.3],
                [2600146.1, 1206446.9],
                [2602139.7, 1206838.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7205', ortbez27: 'Zizers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2761343.2, 1198740.2],
                [2760337.8, 1201981.2],
                [2761720.8, 1202297.2],
                [2762472.9, 1201274.7],
                [2764447.9, 1201401.4],
                [2764353.8, 1199113.7],
                [2761343.2, 1198740.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7203', ortbez27: 'Trimmis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760213.2, 1196341.6],
                [2761343.2, 1198740.2],
                [2764353.8, 1199113.7],
                [2765692.3, 1193594.9],
                [2763990.4, 1192712.4],
                [2760213.2, 1196341.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7204', ortbez27: 'Untervaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2761343.2, 1198740.2],
                [2760213.2, 1196341.6],
                [2754697.1, 1196120.9],
                [2755970.4, 1199878.8],
                [2756891.2, 1201502.7],
                [2760337.8, 1201981.2],
                [2761343.2, 1198740.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3110', ortbez27: 'M�nsingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610790.8, 1190325.2],
                [2608948.4, 1189335.7],
                [2608290.3, 1190785.1],
                [2607823.7, 1192055.5],
                [2610606.5, 1193463.3],
                [2611829.5, 1192106.8],
                [2610790.8, 1190325.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3097', ortbez27: 'Liebefeld' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2597744.1, 1197317.3],
                [2598742.8, 1199448.8],
                [2599214, 1197497],
                [2598725.1, 1196297.7],
                [2597744.1, 1197317.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3098', ortbez27: 'K�niz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601000.4, 1194350.4],
                [2597731.3, 1195088.9],
                [2595634.8, 1195468.9],
                [2596520.3, 1196614.4],
                [2597744.1, 1197317.3],
                [2598725.1, 1196297.7],
                [2600637, 1195839.3],
                [2601000.4, 1194350.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3096', ortbez27: 'Oberbalm' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595992.3, 1191297.2],
                [2599577.1, 1192631.6],
                [2599321.5, 1189336.5],
                [2598196, 1188499.4],
                [2596306.3, 1189285.1],
                [2595314.3, 1190116],
                [2595992.3, 1191297.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7185', ortbez27: 'Platta' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713031.9, 1164305.3],
                [2712369.1, 1160394.2],
                [2706896.5, 1158051.3],
                [2703322.6, 1158405.5],
                [2700662.8, 1159310.3],
                [2703675.6, 1164512.2],
                [2703281, 1165703.2],
                [2706076.9, 1167879.6],
                [2708435.5, 1169053],
                [2713031.9, 1164305.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3099', ortbez27: 'R�ti b. Riggisberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599965.2, 1175725.5],
                [2601109.7, 1177364.7],
                [2602938.3, 1175953.7],
                [2601811.2, 1172934.1],
                [2599965.2, 1175725.5]
              ]
            ],
            [
              [
                [2604182.8, 1181044.7],
                [2601684.5, 1179046.8],
                [2599115.3, 1183251],
                [2602961.8, 1182818.6],
                [2604182.8, 1181044.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7186', ortbez27: 'Segnas' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705802.5, 1170040.2],
                [2704529.4, 1171025.4],
                [2702499.9, 1173621.5],
                [2706273.1, 1174004.1],
                [2708259.7, 1172546.8],
                [2707031.2, 1170425.8],
                [2705802.5, 1170040.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7184', ortbez27: 'Curaglia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708259.7, 1172546.8],
                [2712264, 1172216.7],
                [2713122.4, 1171189.1],
                [2712375.4, 1170176.4],
                [2712704.4, 1168918.5],
                [2713883.8, 1168331.4],
                [2715087.2, 1168673.9],
                [2716841.8, 1167851.3],
                [2717077.4, 1166446.4],
                [2715995.7, 1165813.9],
                [2715560.1, 1164604.1],
                [2714290.2, 1164584.3],
                [2713031.9, 1164305.3],
                [2708435.5, 1169053],
                [2706076.9, 1167879.6],
                [2703281, 1165703.2],
                [2703588.5, 1166992.2],
                [2704029.9, 1168204.9],
                [2704717.9, 1169384.6],
                [2705802.5, 1170040.2],
                [2707031.2, 1170425.8],
                [2708259.7, 1172546.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3089', ortbez27: 'Hinterfultigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2597321.5, 1185920],
                [2595305.9, 1187119.9],
                [2596306.3, 1189285.1],
                [2598196, 1188499.4],
                [2599982.5, 1187150.8],
                [2597321.5, 1185920]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7189', ortbez27: 'Rueras' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697183.3, 1158984],
                [2695138.8, 1159551.2],
                [2695073.8, 1163642.2],
                [2692921.4, 1165675.9],
                [2694580.1, 1170422.9],
                [2695981.7, 1173203],
                [2700015.2, 1174966.7],
                [2701137.5, 1177856],
                [2702768.2, 1176257.8],
                [2702499.9, 1173621.5],
                [2701519.5, 1171968.3],
                [2701204, 1170198.6],
                [2696608.3, 1166615.5],
                [2697183.3, 1158984]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3087', ortbez27: 'Niedermuhlern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599577.1, 1192631.6],
                [2603429.8, 1190907.8],
                [2603272.5, 1188739.9],
                [2599321.5, 1189336.5],
                [2599577.1, 1192631.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3088', ortbez27: 'Oberb�tschel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2597321.5, 1185920],
                [2599982.5, 1187150.8],
                [2598196, 1188499.4],
                [2599321.5, 1189336.5],
                [2603272.5, 1188739.9],
                [2603000.5, 1187443],
                [2602592.8, 1184905.9],
                [2600361.5, 1185078.6],
                [2597321.5, 1185920]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7188', ortbez27: 'Sedrun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700662.8, 1159310.3],
                [2697183.3, 1158984],
                [2696608.3, 1166615.5],
                [2701204, 1170198.6],
                [2701519.5, 1171968.3],
                [2702499.9, 1173621.5],
                [2704529.4, 1171025.4],
                [2705802.5, 1170040.2],
                [2704717.9, 1169384.6],
                [2704029.9, 1168204.9],
                [2703588.5, 1166992.2],
                [2703281, 1165703.2],
                [2703675.6, 1164512.2],
                [2700662.8, 1159310.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7175', ortbez27: 'Sumvitg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714200.3, 1178226.7],
                [2714015.4, 1176708.4],
                [2713897.7, 1175296.4],
                [2712047.8, 1174437.5],
                [2711536, 1175624.1],
                [2709993.6, 1180254.8],
                [2712751.5, 1185649.1],
                [2713806.7, 1184036.3],
                [2713523.1, 1180822],
                [2714200.3, 1178226.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7176', ortbez27: 'Cumpadials' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712264, 1172216.7],
                [2712047.8, 1174437.5],
                [2713897.7, 1175296.4],
                [2714854.9, 1174302.9],
                [2712264, 1172216.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3082', ortbez27: 'Schlosswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614209.3, 1196270.2],
                [2613827.5, 1193835.7],
                [2611974.7, 1195760.8],
                [2611707.7, 1197168.8],
                [2613288.9, 1197234.4],
                [2614209.3, 1196270.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7182', ortbez27: 'Cavardiras' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2712047.8, 1174437.5], [2712264, 1172216.7], [2708259.7, 1172546.8], [2712047.8, 1174437.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7180', ortbez27: 'Disentis/Must�r' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712047.8, 1174437.5],
                [2708259.7, 1172546.8],
                [2706273.1, 1174004.1],
                [2702499.9, 1173621.5],
                [2702768.2, 1176257.8],
                [2706711.4, 1180612.7],
                [2706170.6, 1183415.4],
                [2709862, 1185791.8],
                [2712751.5, 1185649.1],
                [2709993.6, 1180254.8],
                [2711536, 1175624.1],
                [2712047.8, 1174437.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7233', ortbez27: 'Jenaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2772669.7, 1192693.3],
                [2770516.8, 1193037.4],
                [2771813.6, 1200814],
                [2773223, 1200986.1],
                [2775010.7, 1200655.1],
                [2773271.4, 1197801.4],
                [2774952.4, 1195467.6],
                [2772669.7, 1192693.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3086', ortbez27: 'Zimmerwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603216.9, 1193752.1],
                [2603918.1, 1192430.1],
                [2603429.8, 1190907.8],
                [2599577.1, 1192631.6],
                [2601000.4, 1194350.4],
                [2603216.9, 1193752.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3083', ortbez27: 'Trimstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2611974.7, 1195760.8], [2610606.5, 1193463.3], [2610343.1, 1195893.5], [2611974.7, 1195760.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7231', ortbez27: 'Pragg-Jenaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2769833, 1203324.8],
                [2772291.6, 1202709.1],
                [2773223, 1200986.1],
                [2771813.6, 1200814],
                [2769833, 1203324.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3084', ortbez27: 'Wabern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599214, 1197497],
                [2601126.7, 1198136.7],
                [2602520.1, 1197303.6],
                [2600637, 1195839.3],
                [2598725.1, 1196297.7],
                [2599214, 1197497]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7232', ortbez27: 'Furna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2767411.5, 1200696.2],
                [2769833, 1203324.8],
                [2771813.6, 1200814],
                [2770516.8, 1193037.4],
                [2768695.1, 1192921.7],
                [2767305.4, 1193870.2],
                [2767421, 1199328],
                [2767411.5, 1200696.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7235', ortbez27: 'Fideris' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2774320.4, 1191596.5],
                [2772669.7, 1192693.3],
                [2774952.4, 1195467.6],
                [2773271.4, 1197801.4],
                [2775010.7, 1200655.1],
                [2776698.7, 1198955.1],
                [2777603.3, 1198035],
                [2777789.4, 1193337.2],
                [2774320.4, 1191596.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7226', ortbez27: 'Stels' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2775878.6, 1206732.6],
                [2777674.7, 1206207.6],
                [2774142.1, 1202452.2],
                [2772291.6, 1202709.1],
                [2772725.8, 1203890.5],
                [2772332, 1205161.9],
                [2773789.2, 1205298],
                [2775878.6, 1206732.6]
              ]
            ],
            [
              [
                [2777674.7, 1206207.6],
                [2778035.1, 1209012.9],
                [2778265.2, 1212513.1],
                [2781955.4, 1209950.3],
                [2780960.3, 1207090.6],
                [2777674.7, 1206207.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7223', ortbez27: 'Buchen im Pr�ttigau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2775010.7, 1200655.1], [2773223, 1200986.1], [2774142.1, 1202452.2], [2775010.7, 1200655.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3127', ortbez27: 'M�hlethurnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604446.8, 1184026.5],
                [2604338.8, 1185381],
                [2606215, 1186201.7],
                [2606721.3, 1184629.4],
                [2604446.8, 1184026.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3128', ortbez27: 'R�mligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605040.7, 1188372.1],
                [2606215, 1186201.7],
                [2604338.8, 1185381],
                [2603000.5, 1187443],
                [2603272.5, 1188739.9],
                [2605040.7, 1188372.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7228', ortbez27: 'Schuders' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2771836.9, 1207484.9],
                [2774159.7, 1209376.2],
                [2775268.1, 1211099.8],
                [2778265.2, 1212513.1],
                [2778035.1, 1209012.9],
                [2776672.9, 1208756.7],
                [2775878.6, 1206732.6],
                [2773789.2, 1205298],
                [2772332, 1205161.9],
                [2771836.9, 1207484.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3132', ortbez27: 'Riggisberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602961.8, 1182818.6],
                [2599115.3, 1183251],
                [2600361.5, 1185078.6],
                [2602592.8, 1184905.9],
                [2603000.5, 1187443],
                [2604338.8, 1185381],
                [2604446.8, 1184026.5],
                [2602961.8, 1182818.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7215', ortbez27: 'Fanas' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2775268.1, 1211099.8],
                [2774159.7, 1209376.2],
                [2771836.9, 1207484.9],
                [2769449.2, 1205802.1],
                [2767458.5, 1206086.8],
                [2768508.9, 1209867.1],
                [2775268.1, 1211099.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3122', ortbez27: 'Kehrsatz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2600637, 1195839.3],
                [2602520.1, 1197303.6],
                [2603216.9, 1193752.1],
                [2601000.4, 1194350.4],
                [2600637, 1195839.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7222', ortbez27: 'Lunden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2774142.1, 1202452.2], [2773223, 1200986.1], [2772291.6, 1202709.1], [2774142.1, 1202452.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7220', ortbez27: 'Schiers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2769833, 1203324.8],
                [2769449.2, 1205802.1],
                [2771836.9, 1207484.9],
                [2772332, 1205161.9],
                [2772725.8, 1203890.5],
                [2772291.6, 1202709.1],
                [2769833, 1203324.8]
              ]
            ],
            [
              [
                [2777674.7, 1206207.6],
                [2775878.6, 1206732.6],
                [2776672.9, 1208756.7],
                [2778035.1, 1209012.9],
                [2777674.7, 1206207.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3125', ortbez27: 'Toffen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603429.8, 1190907.8],
                [2603918.1, 1192430.1],
                [2605205.7, 1190802.3],
                [2606328.2, 1189223.8],
                [2605040.7, 1188372.1],
                [2603272.5, 1188739.9],
                [2603429.8, 1190907.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3126', ortbez27: 'Gelterfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2606215, 1186201.7], [2605040.7, 1188372.1], [2606328.2, 1189223.8], [2606215, 1186201.7]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3123', ortbez27: 'Belp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602520.1, 1197303.6],
                [2605376.3, 1197128],
                [2605895.4, 1195378.8],
                [2606381.2, 1192702],
                [2605205.7, 1190802.3],
                [2603918.1, 1192430.1],
                [2603216.9, 1193752.1],
                [2602520.1, 1197303.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3124', ortbez27: 'Belpberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605205.7, 1190802.3],
                [2606381.2, 1192702],
                [2607823.7, 1192055.5],
                [2608290.3, 1190785.1],
                [2606328.2, 1189223.8],
                [2605205.7, 1190802.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7208', ortbez27: 'Malans GR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764330.6, 1210417.5],
                [2766232.4, 1205217],
                [2760939.4, 1204885],
                [2760690.6, 1207006.9],
                [2760221.1, 1208213.7],
                [2763141.4, 1210012.8],
                [2764330.6, 1210417.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3113', ortbez27: 'Rubigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605895.4, 1195378.8],
                [2608945.5, 1196159.5],
                [2610343.1, 1195893.5],
                [2610606.5, 1193463.3],
                [2607823.7, 1192055.5],
                [2606381.2, 1192702],
                [2605895.4, 1195378.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7213', ortbez27: 'Valzeina' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2766232.4, 1205217],
                [2767411.5, 1200696.2],
                [2767421, 1199328],
                [2764353.8, 1199113.7],
                [2764447.9, 1201401.4],
                [2766232.4, 1205217]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3114', ortbez27: 'Wichtrach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612588.5, 1187402.6],
                [2611180.6, 1186642.9],
                [2609927.7, 1186120.7],
                [2608948.4, 1189335.7],
                [2610790.8, 1190325.2],
                [2612588.5, 1187402.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7214', ortbez27: 'Gr�sch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2769833, 1203324.8],
                [2767411.5, 1200696.2],
                [2766232.4, 1205217],
                [2767458.5, 1206086.8],
                [2769449.2, 1205802.1],
                [2769833, 1203324.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3111', ortbez27: 'T�gertschi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2611829.5, 1192106.8], [2612709.5, 1190722], [2610790.8, 1190325.2], [2611829.5, 1192106.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3112', ortbez27: 'Allmendingen b. Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605895.4, 1195378.8],
                [2605376.3, 1197128],
                [2606985.8, 1197251.8],
                [2608945.5, 1196159.5],
                [2605895.4, 1195378.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7212', ortbez27: 'Seewis Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2778265.2, 1212513.1],
                [2775268.1, 1211099.8],
                [2768508.9, 1209867.1],
                [2767458.5, 1206086.8],
                [2766232.4, 1205217],
                [2764330.6, 1210417.5],
                [2765583.4, 1210814.9],
                [2767049.9, 1212576.8],
                [2767687, 1214582.1],
                [2770330.8, 1214934.6],
                [2778265.2, 1212513.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7265', ortbez27: 'Davos Wolfgang' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2786098.5, 1188062.8],
                [2784685.3, 1188357.8],
                [2783386, 1189023],
                [2782158.9, 1190212.8],
                [2782730.5, 1192562],
                [2785872.9, 1192294.1],
                [2786098.5, 1188062.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3115', ortbez27: 'Gerzensee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608948.4, 1189335.7],
                [2609927.7, 1186120.7],
                [2608181.4, 1186581.4],
                [2606215, 1186201.7],
                [2606328.2, 1189223.8],
                [2608290.3, 1190785.1],
                [2608948.4, 1189335.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3116', ortbez27: 'Noflen BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608932.2, 1183877.8],
                [2608840.6, 1181479.1],
                [2606860, 1183330.4],
                [2606721.3, 1184629.4],
                [2606215, 1186201.7],
                [2608181.4, 1186581.4],
                [2609927.7, 1186120.7],
                [2608932.2, 1183877.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7270', ortbez27: 'Davos Platz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2779146, 1187153],
                [2780581.6, 1188437.3],
                [2785827, 1180521.4],
                [2781318.9, 1182817],
                [2779146, 1187153]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3173', ortbez27: 'Oberwangen b. Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594352.2, 1194207.6],
                [2592361.4, 1195561.1],
                [2591281.5, 1197273.5],
                [2594125.5, 1197494.8],
                [2595634.8, 1195468.9],
                [2594352.2, 1194207.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3174', ortbez27: 'Th�rishaus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2591605.4, 1193750.2],
                [2592361.4, 1195561.1],
                [2594352.2, 1194207.6],
                [2595376.9, 1192502.1],
                [2595992.3, 1191297.2],
                [2595314.3, 1190116],
                [2594018.5, 1190368.5],
                [2593272, 1193250.5],
                [2591605.4, 1193750.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3172', ortbez27: 'Niederwangen b. Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2595634.8, 1195468.9], [2594125.5, 1197494.8], [2596520.3, 1196614.4], [2595634.8, 1195468.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3159', ortbez27: 'Riedst�tt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2589751.8, 1182106.6], [2593328.8, 1183035.6], [2592851.4, 1181389.9], [2589751.8, 1182106.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7260', ortbez27: 'Davos Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2792576.4, 1174604.1],
                [2787210, 1173015.4],
                [2785827, 1180521.4],
                [2780581.6, 1188437.3],
                [2779662.5, 1190236.3],
                [2782730.5, 1192562],
                [2782158.9, 1190212.8],
                [2783386, 1189023],
                [2784685.3, 1188357.8],
                [2786098.5, 1188062.8],
                [2791527.8, 1187855.5],
                [2793213, 1182329],
                [2790660.7, 1180775.6],
                [2792576.4, 1174604.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7249', ortbez27: 'Serneus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2780830.4, 1194399.8],
                [2780680.3, 1195816.8],
                [2784125.6, 1197201.8],
                [2784435.4, 1195362.8],
                [2780830.4, 1194399.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7250', ortbez27: 'Klosters' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2782730.5, 1192562],
                [2779662.5, 1190236.3],
                [2779806.2, 1192149.2],
                [2780830.4, 1194399.8],
                [2784435.4, 1195362.8],
                [2793772.2, 1198865.7],
                [2802808.6, 1192208.4],
                [2800291.1, 1188151.8],
                [2793213, 1182329],
                [2791527.8, 1187855.5],
                [2786098.5, 1188062.8],
                [2785872.9, 1192294.1],
                [2782730.5, 1192562]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7247', ortbez27: 'Saas im Pr�ttigau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2785679.1, 1201200.5],
                [2788761.1, 1199916.1],
                [2784125.6, 1197201.8],
                [2780680.3, 1195816.8],
                [2779525.2, 1198355.1],
                [2782576.2, 1200878.8],
                [2785679.1, 1201200.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3153', ortbez27: 'R�schegg Gambach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596000.4, 1182623],
                [2596486.3, 1178772.2],
                [2595221.8, 1177885.9],
                [2592851.4, 1181389.9],
                [2596000.4, 1182623]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3154', ortbez27: 'R�schegg Heubach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601109.7, 1177364.7],
                [2599965.2, 1175725.5],
                [2598698.9, 1176306.9],
                [2597748.7, 1177176.3],
                [2596486.3, 1178772.2],
                [2596000.4, 1182623],
                [2597265.5, 1184498.1],
                [2599115.3, 1183251],
                [2601684.5, 1179046.8],
                [2601109.7, 1177364.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3152', ortbez27: 'Mamishaus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596000.4, 1182623],
                [2594031.6, 1184131.2],
                [2594175.1, 1186405],
                [2595305.9, 1187119.9],
                [2597321.5, 1185920],
                [2597265.5, 1184498.1],
                [2596000.4, 1182623]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3157', ortbez27: 'Milken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594031.6, 1184131.2],
                [2596000.4, 1182623],
                [2592851.4, 1181389.9],
                [2593328.8, 1183035.6],
                [2594031.6, 1184131.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7252', ortbez27: 'Klosters Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2788761.1, 1199916.1],
                [2793772.2, 1198865.7],
                [2784435.4, 1195362.8],
                [2784125.6, 1197201.8],
                [2788761.1, 1199916.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7241', ortbez27: 'Conters im Pr�ttigau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2780830.4, 1194399.8],
                [2779806.2, 1192149.2],
                [2777789.4, 1193337.2],
                [2777603.3, 1198035],
                [2779525.2, 1198355.1],
                [2780680.3, 1195816.8],
                [2780830.4, 1194399.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3158', ortbez27: 'Guggisberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592851.4, 1181389.9],
                [2590929.5, 1175787.1],
                [2589724.4, 1178210],
                [2588866, 1179335],
                [2589298.6, 1180861.5],
                [2589751.8, 1182106.6],
                [2592851.4, 1181389.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3155', ortbez27: 'Helgisried-Rohrbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2600361.5, 1185078.6],
                [2599115.3, 1183251],
                [2597265.5, 1184498.1],
                [2597321.5, 1185920],
                [2600361.5, 1185078.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3156', ortbez27: 'Riffenmatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2590929.5, 1175787.1],
                [2592851.4, 1181389.9],
                [2595221.8, 1177885.9],
                [2596486.3, 1178772.2],
                [2597748.7, 1177176.3],
                [2590929.5, 1175787.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7240', ortbez27: 'K�blis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2782576.2, 1200878.8],
                [2779525.2, 1198355.1],
                [2777603.3, 1198035],
                [2776698.7, 1198955.1],
                [2777971.4, 1199799.8],
                [2779763.4, 1201733.7],
                [2782576.2, 1200878.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3145', ortbez27: 'Niederscherli' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599577.1, 1192631.6],
                [2595992.3, 1191297.2],
                [2595376.9, 1192502.1],
                [2594352.2, 1194207.6],
                [2599577.1, 1192631.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7245', ortbez27: 'Ascharina' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2782576.2, 1200878.8],
                [2779763.4, 1201733.7],
                [2778926.7, 1203324.4],
                [2780431.5, 1204635.9],
                [2782576.2, 1200878.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7246', ortbez27: 'St. Ant�nien' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2785679.1, 1201200.5],
                [2782576.2, 1200878.8],
                [2780431.5, 1204635.9],
                [2777674.7, 1206207.6],
                [2780960.3, 1207090.6],
                [2781955.4, 1209950.3],
                [2785714.5, 1210468.9],
                [2785679.1, 1201200.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3144', ortbez27: 'Gasel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599577.1, 1192631.6],
                [2594352.2, 1194207.6],
                [2595634.8, 1195468.9],
                [2597731.3, 1195088.9],
                [2601000.4, 1194350.4],
                [2599577.1, 1192631.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7243', ortbez27: 'Pany' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2774142.1, 1202452.2],
                [2777674.7, 1206207.6],
                [2780431.5, 1204635.9],
                [2778926.7, 1203324.4],
                [2779763.4, 1201733.7],
                [2777971.4, 1199799.8],
                [2776698.7, 1198955.1],
                [2775010.7, 1200655.1],
                [2774142.1, 1202452.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3150', ortbez27: 'Schwarzenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592320.9, 1187423.1],
                [2594175.1, 1186405],
                [2594031.6, 1184131.2],
                [2593328.8, 1183035.6],
                [2589751.8, 1182106.6],
                [2592320.9, 1187423.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3148', ortbez27: 'Lanzenh�usern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592412.2, 1188852.5],
                [2594018.5, 1190368.5],
                [2595314.3, 1190116],
                [2596306.3, 1189285.1],
                [2595305.9, 1187119.9],
                [2594175.1, 1186405],
                [2592320.9, 1187423.1],
                [2592412.2, 1188852.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3202', ortbez27: 'Frauenkappelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589232.8, 1200240.4],
                [2589997.6, 1201575.5],
                [2591532.5, 1201551.3],
                [2593559.5, 1200244.4],
                [2589969.6, 1199205.3],
                [2589232.8, 1200240.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7302', ortbez27: 'Landquart' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2766232.4, 1205217],
                [2764447.9, 1201401.4],
                [2762472.9, 1201274.7],
                [2761720.8, 1202297.2],
                [2760337.8, 1201981.2],
                [2760939.4, 1204885],
                [2766232.4, 1205217]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3205', ortbez27: 'G�mmenen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2585187.6, 1201193.9], [2587860.9, 1200423.3], [2584213.4, 1197682.4], [2585187.6, 1201193.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3206', ortbez27: 'Rizenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582740.8, 1197491.1],
                [2582494.2, 1199940.4],
                [2581679.6, 1201251.4],
                [2583768.7, 1201479],
                [2585187.6, 1201193.9],
                [2584213.4, 1197682.4],
                [2582740.8, 1197491.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3203', ortbez27: 'M�hleberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586612.1, 1203309.5],
                [2588444.5, 1202436.5],
                [2589997.6, 1201575.5],
                [2589232.8, 1200240.4],
                [2587860.9, 1200423.3],
                [2585187.6, 1201193.9],
                [2586612.1, 1203309.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3204', ortbez27: 'Rossh�usern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589129.5, 1197020.9],
                [2587046.9, 1196049.3],
                [2584404.1, 1196265.6],
                [2584213.4, 1197682.4],
                [2587860.9, 1200423.3],
                [2589232.8, 1200240.4],
                [2589969.6, 1199205.3],
                [2589129.5, 1197020.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3185', ortbez27: 'Schmitten FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584316.8, 1186558.8],
                [2582901, 1191449.5],
                [2585738, 1191329.8],
                [2588116.4, 1189486.7],
                [2587267.3, 1188087.1],
                [2585580.5, 1186411.7],
                [2584316.8, 1186558.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3186', ortbez27: 'D�dingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582901, 1191449.5],
                [2584316.8, 1186558.8],
                [2581370, 1185028.1],
                [2579660, 1186007.7],
                [2577833.1, 1187047.4],
                [2577659.6, 1188675.6],
                [2579020.3, 1191845.5],
                [2580451.9, 1192684.6],
                [2581961.6, 1192431.2],
                [2582901, 1191449.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3183', ortbez27: 'Albligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2592412.2, 1188852.5], [2589662.4, 1189143.6], [2590751.5, 1190315.6], [2592412.2, 1188852.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3184', ortbez27: 'W�nnewil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588116.4, 1189486.7],
                [2585738, 1191329.8],
                [2586314.2, 1192534.4],
                [2587474, 1193615],
                [2589881.1, 1193578.1],
                [2588116.4, 1189486.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7272', ortbez27: 'Davos Clavadel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2782191.5, 1173154.6],
                [2781641.4, 1176277.3],
                [2781325.8, 1180904.1],
                [2781318.9, 1182817],
                [2785827, 1180521.4],
                [2787210, 1173015.4],
                [2782191.5, 1173154.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3177', ortbez27: 'Laupen BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587046.9, 1196049.3],
                [2586215.6, 1194292.1],
                [2583223.2, 1194196.1],
                [2584404.1, 1196265.6],
                [2587046.9, 1196049.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7277', ortbez27: 'Davos Glaris' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2776034.6, 1175355.3],
                [2774423.5, 1181067.8],
                [2774707.2, 1183183.2],
                [2781325.8, 1180904.1],
                [2781641.4, 1176277.3],
                [2778584.7, 1178071.1],
                [2776034.6, 1175355.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3178', ortbez27: 'B�singen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585738, 1191329.8],
                [2582901, 1191449.5],
                [2581961.6, 1192431.2],
                [2583223.2, 1194196.1],
                [2586215.6, 1194292.1],
                [2587474, 1193615],
                [2586314.2, 1192534.4],
                [2585738, 1191329.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7278', ortbez27: 'Davos Monstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2781641.4, 1176277.3],
                [2782191.5, 1173154.6],
                [2779370, 1171756.6],
                [2778065.2, 1171915.3],
                [2776034.6, 1175355.3],
                [2778584.7, 1178071.1],
                [2781641.4, 1176277.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3176', ortbez27: 'Neuenegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589881.1, 1193578.1],
                [2587474, 1193615],
                [2586215.6, 1194292.1],
                [2587046.9, 1196049.3],
                [2589129.5, 1197020.9],
                [2591281.5, 1197273.5],
                [2592361.4, 1195561.1],
                [2591605.4, 1193750.2],
                [2589881.1, 1193578.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7276', ortbez27: 'Davos Frauenkirch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2779146, 1187153],
                [2781318.9, 1182817],
                [2781325.8, 1180904.1],
                [2774707.2, 1183183.2],
                [2779146, 1187153]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3182', ortbez27: 'Ueberstorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588116.4, 1189486.7],
                [2589881.1, 1193578.1],
                [2591605.4, 1193750.2],
                [2593272, 1193250.5],
                [2594018.5, 1190368.5],
                [2592412.2, 1188852.5],
                [2590751.5, 1190315.6],
                [2589662.4, 1189143.6],
                [2588116.4, 1189486.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3179', ortbez27: 'Kriechenwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581529.2, 1194385.1],
                [2582740.8, 1197491.1],
                [2584213.4, 1197682.4],
                [2584404.1, 1196265.6],
                [2583223.2, 1194196.1],
                [2581529.2, 1194385.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3233', ortbez27: 'Tschugg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2573030, 1207097.2], [2571212.3, 1208614.3], [2573890.8, 1208474.6], [2573030, 1207097.2]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3234', ortbez27: 'Vinelz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576375.6, 1208634],
                [2575140.7, 1208390.4],
                [2574893.4, 1210064],
                [2575415.7, 1211205.5],
                [2576384.4, 1210184.8],
                [2576375.6, 1208634]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3232', ortbez27: 'Ins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2577203.7, 1203797.3],
                [2573241.2, 1203009.7],
                [2573030, 1207097.2],
                [2573890.8, 1208474.6],
                [2575140.7, 1208390.4],
                [2576375.6, 1208634],
                [2577080.4, 1206451.4],
                [2577203.7, 1203797.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3237', ortbez27: 'Br�ttelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578739.7, 1207640],
                [2577080.4, 1206451.4],
                [2576375.6, 1208634],
                [2580460.9, 1209698.6],
                [2578739.7, 1207640]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3238', ortbez27: 'Gals' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572889.9, 1210915.3],
                [2571212.3, 1208614.3],
                [2569562.5, 1209506.5],
                [2570789.6, 1210471.1],
                [2572889.9, 1210915.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3235', ortbez27: 'Erlach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571212.3, 1208614.3],
                [2572889.9, 1210915.3],
                [2574133.5, 1211239],
                [2575415.7, 1211205.5],
                [2574893.4, 1210064],
                [2575140.7, 1208390.4],
                [2573890.8, 1208474.6],
                [2571212.3, 1208614.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3236', ortbez27: 'Gampelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573030, 1207097.2],
                [2573241.2, 1203009.7],
                [2571487.8, 1202441.8],
                [2569481.7, 1203468],
                [2569159.8, 1207122],
                [2568886.3, 1208417.9],
                [2569562.5, 1209506.5],
                [2571212.3, 1208614.3],
                [2573030, 1207097.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7320', ortbez27: 'Sargans' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754514.8, 1213270.2],
                [2751263.1, 1213109.7],
                [2751361.2, 1214709],
                [2754520.6, 1215175.6],
                [2754514.8, 1213270.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3225', ortbez27: 'M�ntschemier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2577203.7, 1203797.3], [2577080.4, 1206451.4], [2578885, 1204222.6], [2577203.7, 1203797.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7325', ortbez27: 'Schwendi im Weisstannental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2748362.5, 1202844.9],
                [2746170.4, 1207567],
                [2743864.9, 1208317.5],
                [2746841.9, 1208623.9],
                [2747729.5, 1207306.5],
                [2749320.9, 1203829],
                [2748362.5, 1202844.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3226', ortbez27: 'Treiten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578885, 1204222.6],
                [2577080.4, 1206451.4],
                [2578739.7, 1207640],
                [2580346.2, 1206657.8],
                [2581358.9, 1204968.6],
                [2578885, 1204222.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7326', ortbez27: 'Weisstannen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737688.8, 1207220.1],
                [2741452.7, 1209629.7],
                [2743864.9, 1208317.5],
                [2746170.4, 1207567],
                [2748362.5, 1202844.9],
                [2747731.9, 1201742.8],
                [2738219.4, 1199115.4],
                [2737171.7, 1200419.3],
                [2736946.8, 1202000.7],
                [2737407.2, 1203564.3],
                [2737634.7, 1204807.5],
                [2737688.8, 1207220.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7323', ortbez27: 'Wangs' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747729.5, 1207306.5],
                [2748807.7, 1209946],
                [2750881.9, 1211281],
                [2752821.2, 1211650.5],
                [2752330.4, 1208718.6],
                [2752811.7, 1207007.3],
                [2753737.4, 1205332.6],
                [2752499.7, 1205118.1],
                [2751336.9, 1204389],
                [2749320.9, 1203829],
                [2747729.5, 1207306.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7324', ortbez27: 'Vilters' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2752821.2, 1211650.5], [2755614.6, 1212095.3], [2752330.4, 1208718.6], [2752821.2, 1211650.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7313', ortbez27: 'St. Margrethenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2756840, 1204932.8],
                [2758004.4, 1205752.3],
                [2758740.7, 1204639.4],
                [2756891.2, 1201502.7],
                [2756840, 1204932.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7314', ortbez27: 'Vadura' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2756840, 1204932.8], [2756891.2, 1201502.7], [2755589.4, 1203471.8], [2756840, 1204932.8]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7312', ortbez27: 'Pf�fers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2758004.4, 1205752.3], [2756840, 1204932.8], [2756057.2, 1206473.2], [2758004.4, 1205752.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7317', ortbez27: 'Vas�n' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755970.4, 1199878.8],
                [2751650.7, 1203128.4],
                [2751336.9, 1204389],
                [2752499.7, 1205118.1],
                [2753737.4, 1205332.6],
                [2754851.7, 1206046.4],
                [2755351.8, 1207522.2],
                [2756057.2, 1206473.2],
                [2756840, 1204932.8],
                [2755589.4, 1203471.8],
                [2756891.2, 1201502.7],
                [2755970.4, 1199878.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7315', ortbez27: 'V�ttis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751650.7, 1203128.4],
                [2755970.4, 1199878.8],
                [2754697.1, 1196120.9],
                [2753723.1, 1194712.2],
                [2753220.8, 1192511.2],
                [2747339.3, 1191422.4],
                [2744850.2, 1196445.9],
                [2739364.3, 1196460],
                [2737974.1, 1197867.2],
                [2738219.4, 1199115.4],
                [2747731.9, 1201742.8],
                [2748362.5, 1202844.9],
                [2749320.9, 1203829],
                [2751336.9, 1204389],
                [2751650.7, 1203128.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3216', ortbez27: 'Ried b. Kerzers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578885, 1204222.6],
                [2581679.6, 1201251.4],
                [2582494.2, 1199940.4],
                [2580569.9, 1198643.7],
                [2579159.8, 1199463.9],
                [2578885, 1204222.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7306', ortbez27: 'Fl�sch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754514.8, 1213270.2],
                [2758994.4, 1214714],
                [2757601.2, 1208801.8],
                [2755614.6, 1212095.3],
                [2754514.8, 1213270.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7303', ortbez27: 'Mastrils' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760939.4, 1204885],
                [2760337.8, 1201981.2],
                [2756891.2, 1201502.7],
                [2758740.7, 1204639.4],
                [2760939.4, 1204885]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7304', ortbez27: 'Maienfeld' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764376.8, 1214606.5],
                [2767049.9, 1212576.8],
                [2765583.4, 1210814.9],
                [2764330.6, 1210417.5],
                [2763141.4, 1210012.8],
                [2762381.6, 1211061.5],
                [2761241.3, 1210544.4],
                [2760807.9, 1209349.4],
                [2760221.1, 1208213.7],
                [2760690.6, 1207006.9],
                [2758063.5, 1207063.3],
                [2757601.2, 1208801.8],
                [2758994.4, 1214714],
                [2760550.9, 1214316.1],
                [2761037.1, 1213152.9],
                [2763143.7, 1214310.4],
                [2764376.8, 1214606.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3210', ortbez27: 'Kerzers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581679.6, 1201251.4],
                [2578885, 1204222.6],
                [2581358.9, 1204968.6],
                [2583656.3, 1204924.3],
                [2583558.6, 1202890.7],
                [2583768.7, 1201479],
                [2581679.6, 1201251.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7310', ortbez27: 'Bad Ragaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760939.4, 1204885],
                [2758740.7, 1204639.4],
                [2758004.4, 1205752.3],
                [2756057.2, 1206473.2],
                [2755351.8, 1207522.2],
                [2754851.7, 1206046.4],
                [2753737.4, 1205332.6],
                [2752811.7, 1207007.3],
                [2752330.4, 1208718.6],
                [2755614.6, 1212095.3],
                [2757601.2, 1208801.8],
                [2758063.5, 1207063.3],
                [2760690.6, 1207006.9],
                [2760939.4, 1204885]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3207', ortbez27: 'Wileroltigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586612.1, 1203309.5],
                [2585187.6, 1201193.9],
                [2583768.7, 1201479],
                [2583558.6, 1202890.7],
                [2583656.3, 1204924.3],
                [2585043.1, 1205412.7],
                [2586612.1, 1203309.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7307', ortbez27: 'Jenins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2763141.4, 1210012.8],
                [2760221.1, 1208213.7],
                [2760807.9, 1209349.4],
                [2761241.3, 1210544.4],
                [2762381.6, 1211061.5],
                [2763141.4, 1210012.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3213', ortbez27: 'Liebistorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581961.6, 1192431.2],
                [2580451.9, 1192684.6],
                [2581529.2, 1194385.1],
                [2583223.2, 1194196.1],
                [2581961.6, 1192431.2]
              ]
            ],
            [
              [
                [2580222.5, 1197373.4],
                [2582740.8, 1197491.1],
                [2581529.2, 1194385.1],
                [2580067.6, 1194453],
                [2580222.5, 1197373.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3214', ortbez27: 'Ulmiz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582740.8, 1197491.1],
                [2580222.5, 1197373.4],
                [2580569.9, 1198643.7],
                [2582494.2, 1199940.4],
                [2582740.8, 1197491.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3212', ortbez27: 'Gurmels' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580067.6, 1194453],
                [2581529.2, 1194385.1],
                [2580451.9, 1192684.6],
                [2579020.3, 1191845.5],
                [2578138.7, 1192783.5],
                [2578562.8, 1194898.3],
                [2580067.6, 1194453]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3266', ortbez27: 'Wiler b. Seedorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592571.5, 1210757.1],
                [2591524.5, 1209622.7],
                [2589664.3, 1210588.5],
                [2591615.5, 1212465.8],
                [2592965.4, 1212579.4],
                [2592571.5, 1210757.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3263', ortbez27: 'B�etigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2590788.1, 1217809.8], [2592072, 1218631.4], [2592057.6, 1216322.1], [2590788.1, 1217809.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3264', ortbez27: 'Diessbach b. B�ren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592072, 1218631.4],
                [2594676.5, 1219298.1],
                [2595774.6, 1216232],
                [2594634.9, 1215554.4],
                [2593006.6, 1215255.1],
                [2592057.6, 1216322.1],
                [2592072, 1218631.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3270', ortbez27: 'Aarberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589664.3, 1210588.5],
                [2588797.3, 1209645.9],
                [2586340.4, 1210981.4],
                [2588796, 1212422],
                [2589664.3, 1210588.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3267', ortbez27: 'Seedorf BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588797.3, 1209645.9],
                [2589664.3, 1210588.5],
                [2591524.5, 1209622.7],
                [2593916, 1207444.4],
                [2592841.2, 1206094.7],
                [2591540.6, 1205869.7],
                [2590477, 1208302],
                [2588797.3, 1209645.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3268', ortbez27: 'Lobsigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589326.9, 1206464.1],
                [2588009.8, 1208619.4],
                [2588797.3, 1209645.9],
                [2590477, 1208302],
                [2591540.6, 1205869.7],
                [2589326.9, 1206464.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3257', ortbez27: 'Grossaffoltern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595026.1, 1211679.1],
                [2592571.5, 1210757.1],
                [2592965.4, 1212579.4],
                [2593006.6, 1215255.1],
                [2594634.9, 1215554.4],
                [2596215.6, 1213862.6],
                [2595026.1, 1211679.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3255', ortbez27: 'Rapperswil BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599807.3, 1214642.2],
                [2601239.5, 1214032.9],
                [2601342, 1212621.4],
                [2597260.8, 1211138.3],
                [2595026.1, 1211679.1],
                [2596215.6, 1213862.6],
                [2599807.3, 1214642.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3256', ortbez27: 'Dieterswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2600782.2, 1210708.8],
                [2600104.4, 1209517.2],
                [2599090.5, 1208562.7],
                [2598618.5, 1209849.4],
                [2597260.8, 1211138.3],
                [2601342, 1212621.4],
                [2600782.2, 1210708.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3250', ortbez27: 'Lyss' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589664.3, 1210588.5],
                [2588796, 1212422],
                [2590105.6, 1215938.9],
                [2592057.6, 1216322.1],
                [2593006.6, 1215255.1],
                [2592965.4, 1212579.4],
                [2591615.5, 1212465.8],
                [2589664.3, 1210588.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3253', ortbez27: 'Schnottwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594676.5, 1219298.1],
                [2596134.9, 1219584.9],
                [2597575.6, 1218734],
                [2598255.2, 1216367.5],
                [2595774.6, 1216232],
                [2594676.5, 1219298.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3254', ortbez27: 'Messen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599720.9, 1216759.8],
                [2601795.3, 1217619.8],
                [2602748.2, 1215539.4],
                [2601239.5, 1214032.9],
                [2599807.3, 1214642.2],
                [2599720.9, 1216759.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3251', ortbez27: 'Wengi b. B�ren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596215.6, 1213862.6],
                [2594634.9, 1215554.4],
                [2595774.6, 1216232],
                [2598255.2, 1216367.5],
                [2599720.9, 1216759.8],
                [2599807.3, 1214642.2],
                [2596215.6, 1213862.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3252', ortbez27: 'Worben' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2590105.6, 1215938.9],
                [2588342.2, 1216076.8],
                [2588693.4, 1217628.7],
                [2590788.1, 1217809.8],
                [2590105.6, 1215938.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3297', ortbez27: 'Leuzigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602571.9, 1222511.6],
                [2601285.5, 1222967.6],
                [2599486.8, 1225731.2],
                [2600914.5, 1226171.8],
                [2602884, 1226783.8],
                [2604398.3, 1224652.4],
                [2604155.5, 1222492],
                [2602571.9, 1222511.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3298', ortbez27: 'Oberwil b. B�ren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599231.5, 1219302.1],
                [2597575.6, 1218734],
                [2596134.9, 1219584.9],
                [2596678.5, 1221362.8],
                [2599162.1, 1221273.9],
                [2599231.5, 1219302.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3295', ortbez27: 'R�ti b. B�ren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596166.8, 1222510.4],
                [2597917.1, 1223614.4],
                [2600172.6, 1222318.7],
                [2600591.2, 1220994],
                [2599162.1, 1221273.9],
                [2596678.5, 1221362.8],
                [2596166.8, 1222510.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3296', ortbez27: 'Arch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599486.8, 1225731.2],
                [2601285.5, 1222967.6],
                [2600172.6, 1222318.7],
                [2597917.1, 1223614.4],
                [2599486.8, 1225731.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3302', ortbez27: 'Moosseedorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602434, 1208462.6],
                [2605517, 1205542.5],
                [2602947.9, 1205370.8],
                [2602139.7, 1206838.4],
                [2602434, 1208462.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3293', ortbez27: 'Dotzigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2592801.2, 1220176.6], [2594676.5, 1219298.1], [2592072, 1218631.4], [2592801.2, 1220176.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3294', ortbez27: 'B�ren an der Aare' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592723, 1222477.1],
                [2595423, 1223552.6],
                [2596166.8, 1222510.4],
                [2596678.5, 1221362.8],
                [2596134.9, 1219584.9],
                [2594676.5, 1219298.1],
                [2592801.2, 1220176.6],
                [2591491.5, 1221069.5],
                [2592723, 1222477.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3292', ortbez27: 'Busswil BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2590788.1, 1217809.8], [2592057.6, 1216322.1], [2590105.6, 1215938.9], [2590788.1, 1217809.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3282', ortbez27: 'Bargen BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585466.2, 1207739.2],
                [2582876, 1208847.4],
                [2586340.4, 1210981.4],
                [2588797.3, 1209645.9],
                [2588009.8, 1208619.4],
                [2585466.2, 1207739.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3280', ortbez27: 'Meyriez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2574224.3, 1195789],
                [2573030.8, 1197920.4],
                [2574639, 1199100.9],
                [2576148.8, 1198230.9],
                [2577109.8, 1199036.8],
                [2579159.8, 1199463.9],
                [2580569.9, 1198643.7],
                [2580222.5, 1197373.4],
                [2579101.4, 1198031.9],
                [2577183.3, 1195441.4],
                [2575405.4, 1196278.9],
                [2574224.3, 1195789]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3285', ortbez27: 'Galmiz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2574639, 1199100.9],
                [2577203.7, 1203797.3],
                [2578885, 1204222.6],
                [2579159.8, 1199463.9],
                [2577109.8, 1199036.8],
                [2576148.8, 1198230.9],
                [2574639, 1199100.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3283', ortbez27: 'Niederried b. Kallnach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2583656.3, 1204924.3],
                [2581358.9, 1204968.6],
                [2580346.2, 1206657.8],
                [2581606.5, 1207342.3],
                [2582876, 1208847.4],
                [2585466.2, 1207739.2],
                [2586335.4, 1206703.2],
                [2585043.1, 1205412.7],
                [2583656.3, 1204924.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3273', ortbez27: 'Kappelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587221.4, 1214929.7],
                [2588342.2, 1216076.8],
                [2590105.6, 1215938.9],
                [2588796, 1212422],
                [2586340.4, 1210981.4],
                [2586439.2, 1213430.6],
                [2587221.4, 1214929.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3274', ortbez27: 'Hermrigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586439.2, 1213430.6],
                [2586340.4, 1210981.4],
                [2584830.1, 1213667],
                [2583846.5, 1214626.6],
                [2584720.7, 1215893.9],
                [2587221.4, 1214929.7],
                [2586439.2, 1213430.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3271', ortbez27: 'Radelfingen b. Aarberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586335.4, 1206703.2],
                [2585466.2, 1207739.2],
                [2588009.8, 1208619.4],
                [2589326.9, 1206464.1],
                [2586335.4, 1206703.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3272', ortbez27: 'Epsach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581683.6, 1211025.1],
                [2583013.1, 1212476.2],
                [2582699.8, 1214006.2],
                [2583846.5, 1214626.6],
                [2584830.1, 1213667],
                [2586340.4, 1210981.4],
                [2582876, 1208847.4],
                [2581683.6, 1211025.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7425', ortbez27: 'Masein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751238.5, 1174880.6],
                [2752705.4, 1174635.1],
                [2751232.6, 1173506],
                [2749994.3, 1173904.4],
                [2751238.5, 1174880.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7426', ortbez27: 'Flerden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2749994.3, 1173904.4], [2748629.8, 1174373.2], [2751238.5, 1174880.6], [2749994.3, 1173904.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7423', ortbez27: 'Portein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2746731.7, 1174663.6],
                [2746669.8, 1176111.9],
                [2748191.3, 1178790.4],
                [2750253.6, 1178480.1],
                [2751481.5, 1178053.4],
                [2751774.5, 1176757.9],
                [2751238.5, 1174880.6],
                [2748629.8, 1174373.2],
                [2746731.7, 1174663.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7424', ortbez27: 'Pr�z' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2748191.3, 1178790.4],
                [2748402.7, 1180971.9],
                [2751042.2, 1181887.6],
                [2752308.1, 1179250],
                [2751481.5, 1178053.4],
                [2750253.6, 1178480.1],
                [2748191.3, 1178790.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7430', ortbez27: 'Thusis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754089.9, 1171192.2],
                [2751010, 1170209.3],
                [2750903, 1171720.8],
                [2751232.6, 1173506],
                [2752705.4, 1174635.1],
                [2753888.9, 1172429.4],
                [2754089.9, 1171192.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7427', ortbez27: 'Urmein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2749415, 1170672.8], [2750903, 1171720.8], [2751010, 1170209.3], [2749415, 1170672.8]]],
            [[[2750903, 1171720.8], [2749994.3, 1173904.4], [2751232.6, 1173506], [2750903, 1171720.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7428', ortbez27: 'Tschappina' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744808.5, 1172918.6],
                [2746731.7, 1174663.6],
                [2748629.8, 1174373.2],
                [2749994.3, 1173904.4],
                [2750903, 1171720.8],
                [2749415, 1170672.8],
                [2749765.2, 1169442.7],
                [2747558.1, 1169105.2],
                [2744808.5, 1172918.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7417', ortbez27: 'Paspels' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2752308.1, 1179250],
                [2751042.2, 1181887.6],
                [2752508.2, 1181939.4],
                [2754091.7, 1181115.3],
                [2756145.5, 1179810.8],
                [2754061.1, 1178812.3],
                [2752308.1, 1179250]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7415', ortbez27: 'Rodels' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754061.1, 1178812.3],
                [2754058.8, 1177331.8],
                [2752866.9, 1177724],
                [2751481.5, 1178053.4],
                [2752308.1, 1179250],
                [2754061.1, 1178812.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7416', ortbez27: 'Almens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758680, 1180206],
                [2758180.5, 1178497.8],
                [2754058.8, 1177331.8],
                [2754061.1, 1178812.3],
                [2756145.5, 1179810.8],
                [2758680, 1180206]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3322', ortbez27: 'Urtenen-Sch�nb�hl' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605517, 1205542.5],
                [2602434, 1208462.6],
                [2603371.4, 1209736.3],
                [2605303.5, 1209716.9],
                [2606620.8, 1209721.7],
                [2607789.7, 1208130.6],
                [2605612.4, 1208496],
                [2606066.8, 1207329.1],
                [2607475.2, 1205934.3],
                [2605517, 1205542.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7421', ortbez27: 'Summaprada' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751774.5, 1176757.9],
                [2753476.1, 1176018.2],
                [2752705.4, 1174635.1],
                [2751238.5, 1174880.6],
                [2751774.5, 1176757.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7419', ortbez27: 'Scheid' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755525.3, 1185141],
                [2757233.4, 1185512.1],
                [2757599.3, 1183477.8],
                [2756815.3, 1182475.8],
                [2754937.6, 1182255.7],
                [2754091.7, 1181115.3],
                [2752508.2, 1181939.4],
                [2751929.1, 1183292.1],
                [2755525.3, 1185141]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3325', ortbez27: 'Hettiswil b. Hindelbank' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2610618.6, 1209056.3], [2607475.2, 1205934.3], [2607789.7, 1208130.6], [2610618.6, 1209056.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3326', ortbez27: 'Krauchthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607122.9, 1203362.2],
                [2607475.2, 1205934.3],
                [2610618.6, 1209056.3],
                [2612292.3, 1206368.6],
                [2610460.5, 1204690.2],
                [2608625.7, 1205108.3],
                [2607122.9, 1203362.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3323', ortbez27: 'B�riswil BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607789.7, 1208130.6],
                [2607475.2, 1205934.3],
                [2606066.8, 1207329.1],
                [2605612.4, 1208496],
                [2607789.7, 1208130.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7407', ortbez27: 'Trans' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2756815.3, 1182475.8],
                [2758680, 1180206],
                [2756145.5, 1179810.8],
                [2754091.7, 1181115.3],
                [2754937.6, 1182255.7],
                [2756815.3, 1182475.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3324', ortbez27: 'Hindelbank' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607789.7, 1208130.6],
                [2606620.8, 1209721.7],
                [2607499, 1212349.1],
                [2608741.9, 1211932.7],
                [2610142.5, 1211729.6],
                [2611928.8, 1210472.6],
                [2610618.6, 1209056.3],
                [2607789.7, 1208130.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3313', ortbez27: 'B�ren zum Hof' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604180.1, 1216526.8],
                [2606089.4, 1218287.4],
                [2607558, 1217815.6],
                [2605573.8, 1215416.5],
                [2604180.1, 1216526.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7408', ortbez27: 'Cazis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2753476.1, 1176018.2],
                [2751774.5, 1176757.9],
                [2751481.5, 1178053.4],
                [2752866.9, 1177724],
                [2753476.1, 1176018.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7414', ortbez27: 'F�rstenau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2754058.8, 1177331.8], [2753476.1, 1176018.2], [2752866.9, 1177724], [2754058.8, 1177331.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7411', ortbez27: 'Sils im Domleschg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755978.7, 1170904],
                [2754089.9, 1171192.2],
                [2753888.9, 1172429.4],
                [2752705.4, 1174635.1],
                [2753476.1, 1176018.2],
                [2754917.2, 1174812.4],
                [2757454, 1173566.1],
                [2755978.7, 1170904]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3312', ortbez27: 'Fraubrunnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607558, 1217815.6],
                [2608459.4, 1214356.6],
                [2606732.9, 1214421.1],
                [2605573.8, 1215416.5],
                [2607558, 1217815.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7412', ortbez27: 'Scharans' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758180.5, 1178497.8],
                [2757758.3, 1176057.9],
                [2757454, 1173566.1],
                [2754917.2, 1174812.4],
                [2753476.1, 1176018.2],
                [2754058.8, 1177331.8],
                [2758180.5, 1178497.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3317', ortbez27: 'M�lchi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604180.1, 1216526.8],
                [2602748.2, 1215539.4],
                [2601795.3, 1217619.8],
                [2603186.1, 1218196.8],
                [2603946.9, 1219451.4],
                [2606089.4, 1218287.4],
                [2604180.1, 1216526.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7402', ortbez27: 'Bonaduz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2750317, 1187858.9],
                [2750777.6, 1186040.2],
                [2747527.8, 1185873.6],
                [2748402.7, 1180971.9],
                [2748191.3, 1178790.4],
                [2746884, 1179459],
                [2746700, 1181262.8],
                [2745704.5, 1183882.7],
                [2744498.3, 1185623.7],
                [2745587.4, 1186383.8],
                [2747870.7, 1187831.9],
                [2750317, 1187858.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3315', ortbez27: 'Kr�iligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608134.3, 1222308.1],
                [2607558, 1217815.6],
                [2606089.4, 1218287.4],
                [2603946.9, 1219451.4],
                [2604513.9, 1220773.6],
                [2605862.1, 1220629.4],
                [2606484.6, 1222935.1],
                [2607681.3, 1223739.5],
                [2608134.3, 1222308.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3305', ortbez27: 'Iffwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603918.9, 1214337.4],
                [2604604.1, 1213114.9],
                [2603663.9, 1211596.4],
                [2601342, 1212621.4],
                [2601239.5, 1214032.9],
                [2603918.9, 1214337.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7405', ortbez27: 'Rothenbrunnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2751929.1, 1183292.1], [2752508.2, 1181939.4], [2751042.2, 1181887.6], [2751929.1, 1183292.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3306', ortbez27: 'Etzelkofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601239.5, 1214032.9],
                [2602748.2, 1215539.4],
                [2604180.1, 1216526.8],
                [2603918.9, 1214337.4],
                [2601239.5, 1214032.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3303', ortbez27: 'Jegenstorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601342, 1212621.4],
                [2603663.9, 1211596.4],
                [2604604.1, 1213114.9],
                [2605845.5, 1212626.4],
                [2607499, 1212349.1],
                [2606620.8, 1209721.7],
                [2605303.5, 1209716.9],
                [2603371.4, 1209736.3],
                [2602107.8, 1210068.3],
                [2600782.2, 1210708.8],
                [2601342, 1212621.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7403', ortbez27: 'Rh�z�ns' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751042.2, 1181887.6],
                [2748402.7, 1180971.9],
                [2747527.8, 1185873.6],
                [2750777.6, 1186040.2],
                [2750914.8, 1184731.6],
                [2751929.1, 1183292.1],
                [2751042.2, 1181887.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7404', ortbez27: 'Feldis/Veulden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755525.3, 1185141],
                [2751929.1, 1183292.1],
                [2750914.8, 1184731.6],
                [2750777.6, 1186040.2],
                [2754218, 1186666.6],
                [2755525.3, 1185141]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3309', ortbez27: 'Zauggenried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608459.4, 1214356.6],
                [2609840.5, 1213699.6],
                [2608741.9, 1211932.7],
                [2607499, 1212349.1],
                [2605845.5, 1212626.4],
                [2606732.9, 1214421.1],
                [2608459.4, 1214356.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7457', ortbez27: 'Bivio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2775770.3, 1153447.4],
                [2775605.6, 1146514.7],
                [2771940.6, 1143866.8],
                [2770593.1, 1142908.9],
                [2769646.5, 1142087.2],
                [2768407.5, 1141909.7],
                [2767142, 1141878.8],
                [2765945.3, 1142431.9],
                [2765668.8, 1148233.9],
                [2765987.3, 1151134.4],
                [2767460.3, 1151025.9],
                [2768727.9, 1150898.1],
                [2770001.5, 1150837.3],
                [2771264.7, 1150961.9],
                [2772708.2, 1151105.1],
                [2773405.8, 1152164.5],
                [2773689.8, 1153429.8],
                [2775770.3, 1153447.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7458', ortbez27: 'Mon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2764104.1, 1168089.5], [2758912.8, 1166737.9], [2762060.8, 1170830.2], [2764104.1, 1168089.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3308', ortbez27: 'Grafenried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604604.1, 1213114.9],
                [2603918.9, 1214337.4],
                [2604180.1, 1216526.8],
                [2605573.8, 1215416.5],
                [2606732.9, 1214421.1],
                [2605845.5, 1212626.4],
                [2604604.1, 1213114.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7455', ortbez27: 'Mulegns' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2759858.4, 1151441.4],
                [2760695.3, 1152440.2],
                [2764114.1, 1156179.4],
                [2765476.2, 1156613.1],
                [2768509.6, 1157308.4],
                [2767584.9, 1154767.4],
                [2765987.3, 1151134.4],
                [2765668.8, 1148233.9],
                [2763366.6, 1148695],
                [2759858.4, 1151441.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7456', ortbez27: 'Marmorera' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765987.3, 1151134.4],
                [2767584.9, 1154767.4],
                [2768509.6, 1157308.4],
                [2773124.8, 1157248.8],
                [2773689.8, 1153429.8],
                [2773405.8, 1152164.5],
                [2772708.2, 1151105.1],
                [2771264.7, 1150961.9],
                [2770001.5, 1150837.3],
                [2768727.9, 1150898.1],
                [2767460.3, 1151025.9],
                [2765987.3, 1151134.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3362', ortbez27: 'Nieder�nz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619577.6, 1227712.2],
                [2619621.4, 1226110.5],
                [2618122.3, 1225382.1],
                [2617960.1, 1226837.8],
                [2619577.6, 1227712.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7462', ortbez27: 'Salouf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757683.9, 1161272.8],
                [2757791.8, 1163564.7],
                [2757867.6, 1165933.3],
                [2758912.8, 1166737.9],
                [2764104.1, 1168089.5],
                [2764420.5, 1166491.9],
                [2763848.1, 1164949.2],
                [2760714.6, 1163755.2],
                [2758261.5, 1159414.1],
                [2757683.9, 1161272.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7459', ortbez27: 'Stierva' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758907, 1170277],
                [2762060.8, 1170830.2],
                [2758912.8, 1166737.9],
                [2757025.6, 1168537.7],
                [2758907, 1170277]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3360', ortbez27: 'Herzogenbuchsee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619577.6, 1227712.2],
                [2620583.6, 1228824.8],
                [2622357.4, 1228511.7],
                [2622172.1, 1227078.6],
                [2621180.2, 1225903],
                [2619621.4, 1226110.5],
                [2619577.6, 1227712.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7460', ortbez27: 'Savognin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764792.2, 1163564.8],
                [2767903.6, 1165840.9],
                [2769207.6, 1165308.2],
                [2766114.1, 1162442],
                [2766401.7, 1159896.6],
                [2765476.2, 1156613.1],
                [2764114.1, 1156179.4],
                [2764073.4, 1162224.7],
                [2764792.2, 1163564.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3365', ortbez27: 'Grasswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618341.5, 1223753.5],
                [2619136.2, 1222578.9],
                [2619163.8, 1220365.8],
                [2617681, 1219660],
                [2616091.3, 1220745.7],
                [2616778.6, 1221916],
                [2615342.7, 1222678.6],
                [2615501.6, 1223964.7],
                [2617272.5, 1224430.8],
                [2618341.5, 1223753.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3366', ortbez27: 'Bettenhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619954.4, 1223605.8],
                [2619621.4, 1226110.5],
                [2621180.2, 1225903],
                [2621205.2, 1224036],
                [2619954.4, 1223605.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7450', ortbez27: 'Tiefencastel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2766727.3, 1168072.6],
                [2768588.6, 1169579.7],
                [2769207.6, 1165308.2],
                [2767903.6, 1165840.9],
                [2766727.3, 1168072.6]
              ]
            ],
            [
              [
                [2765255.2, 1170154.5],
                [2766727.3, 1168072.6],
                [2764104.1, 1168089.5],
                [2762060.8, 1170830.2],
                [2765255.2, 1170154.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3363', ortbez27: 'Ober�nz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618122.3, 1225382.1],
                [2619621.4, 1226110.5],
                [2619954.4, 1223605.8],
                [2619136.2, 1222578.9],
                [2618341.5, 1223753.5],
                [2618122.3, 1225382.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7447', ortbez27: 'Am Bach (Avers)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2763366.6, 1148695],
                [2765063.7, 1141520.1],
                [2763845.9, 1141235.3],
                [2763547.7, 1140004.1],
                [2759221.9, 1146117.4],
                [2759858.4, 1151441.4],
                [2763366.6, 1148695]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7448', ortbez27: 'Juf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2763366.6, 1148695],
                [2765668.8, 1148233.9],
                [2765945.3, 1142431.9],
                [2765063.7, 1141520.1],
                [2763366.6, 1148695]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7453', ortbez27: 'Tinizong' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2769207.6, 1165308.2],
                [2774234.4, 1163126],
                [2774755.7, 1160068.9],
                [2766401.7, 1159896.6],
                [2766114.1, 1162442],
                [2769207.6, 1165308.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7454', ortbez27: 'Rona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2766401.7, 1159896.6],
                [2774755.7, 1160068.9],
                [2775533.9, 1158157.3],
                [2773124.8, 1157248.8],
                [2768509.6, 1157308.4],
                [2765476.2, 1156613.1],
                [2766401.7, 1159896.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7451', ortbez27: 'Alvaschein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2762060.8, 1170830.2],
                [2758907, 1170277],
                [2758728.4, 1172838.5],
                [2760937.2, 1173520],
                [2762060.8, 1170830.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7452', ortbez27: 'Cunter' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2766727.3, 1168072.6],
                [2767903.6, 1165840.9],
                [2764792.2, 1163564.8],
                [2763848.1, 1164949.2],
                [2764420.5, 1166491.9],
                [2765861.9, 1166128.4],
                [2766727.3, 1168072.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7440', ortbez27: 'Andeer' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2752951.2, 1165689.3],
                [2757683.9, 1161272.8],
                [2753953.4, 1160708.8],
                [2750819.1, 1156516.3],
                [2745304.4, 1162299],
                [2749428.1, 1163569.1],
                [2750294.7, 1164472.6],
                [2752951.2, 1165689.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7445', ortbez27: 'Innerferrera' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2750630.7, 1154877.2],
                [2757684.1, 1154955.2],
                [2760695.3, 1152440.2],
                [2759858.4, 1151441.4],
                [2758577.4, 1151642.9],
                [2755554.3, 1152881.4],
                [2753269.3, 1151711.5],
                [2752558, 1150669.1],
                [2752578, 1149361.3],
                [2751815.1, 1148353.8],
                [2750569.3, 1148504.3],
                [2749784.7, 1149518.9],
                [2748790.4, 1150325.8],
                [2748290.1, 1151490.8],
                [2747726, 1152862.7],
                [2750630.7, 1154877.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7446', ortbez27: 'Campsut-Cr�t' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2762653.7, 1139048.9],
                [2758280.3, 1137062.3],
                [2755691.9, 1138199.3],
                [2755469.6, 1149828.4],
                [2755554.3, 1152881.4],
                [2758577.4, 1151642.9],
                [2759858.4, 1151441.4],
                [2759221.9, 1146117.4],
                [2763547.7, 1140004.1],
                [2762653.7, 1139048.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7443', ortbez27: 'Pignia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2752951.2, 1165689.3], [2757791.8, 1163564.7], [2757683.9, 1161272.8], [2752951.2, 1165689.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7444', ortbez27: 'Ausserferrera' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757683.9, 1161272.8],
                [2758261.5, 1159414.1],
                [2757684.1, 1154955.2],
                [2750630.7, 1154877.2],
                [2750819.1, 1156516.3],
                [2753953.4, 1160708.8],
                [2757683.9, 1161272.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7433', ortbez27: 'Lohn GR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751325.4, 1166185.7],
                [2752951.2, 1165689.3],
                [2750294.7, 1164472.6],
                [2749428.1, 1163569.1],
                [2745304.4, 1162299],
                [2743738.1, 1162770.6],
                [2742987.8, 1165866.3],
                [2745862.9, 1167217.3],
                [2747558.1, 1169105.2],
                [2749765.2, 1169442.7],
                [2749415, 1170672.8],
                [2751010, 1170209.3],
                [2754089.9, 1171192.2],
                [2753586.6, 1168884.1],
                [2751963.1, 1167951.7],
                [2751325.4, 1166185.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7434', ortbez27: 'Sufers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2743278.6, 1161367.9],
                [2743738.1, 1162770.6],
                [2745304.4, 1162299],
                [2750819.1, 1156516.3],
                [2750630.7, 1154877.2],
                [2747726, 1152862.7],
                [2748422.1, 1156973.9],
                [2743278.6, 1161367.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7431', ortbez27: 'Obermutten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758907, 1170277],
                [2757025.6, 1168537.7],
                [2755978.7, 1170904],
                [2757454, 1173566.1],
                [2758728.4, 1172838.5],
                [2758907, 1170277]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7432', ortbez27: 'Zillis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757791.8, 1163564.7],
                [2752951.2, 1165689.3],
                [2753586.6, 1168884.1],
                [2754089.9, 1171192.2],
                [2755978.7, 1170904],
                [2757025.6, 1168537.7],
                [2758912.8, 1166737.9],
                [2757867.6, 1165933.3],
                [2757791.8, 1163564.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7437', ortbez27: 'Nufenen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2735580.4, 1152221.2],
                [2735334.3, 1157503.5],
                [2738391, 1159931.5],
                [2740683.2, 1154219.1],
                [2735580.4, 1152221.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7438', ortbez27: 'Hinterrhein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726021.8, 1148875.6],
                [2722947.8, 1150311.4],
                [2735334.3, 1157503.5],
                [2735580.4, 1152221.2],
                [2726021.8, 1148875.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7435', ortbez27: 'Spl�gen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747726, 1152862.7],
                [2741697, 1151329.2],
                [2741269.6, 1152774.8],
                [2740683.2, 1154219.1],
                [2743278.6, 1161367.9],
                [2748422.1, 1156973.9],
                [2747726, 1152862.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7436', ortbez27: 'Medels im Rheinwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2740683.2, 1154219.1], [2738391, 1159931.5], [2743278.6, 1161367.9], [2740683.2, 1154219.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7493', ortbez27: 'Schmitten (Albula)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2770567.9, 1177960.8], [2773188.6, 1173454.8], [2770759.9, 1172544.3], [2770567.9, 1177960.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7494', ortbez27: 'Davos Wiesen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2770567.9, 1177960.8],
                [2774423.5, 1181067.8],
                [2776034.6, 1175355.3],
                [2774375.2, 1171820.5],
                [2773188.6, 1173454.8],
                [2770567.9, 1177960.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7492', ortbez27: 'Alvaneu Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2767880.8, 1171546.5],
                [2765965.3, 1175929.1],
                [2766323.3, 1178742.1],
                [2771806.9, 1181846],
                [2770567.9, 1177960.8],
                [2770759.9, 1172544.3],
                [2767880.8, 1171546.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7482', ortbez27: 'Berg�n/Bravuogn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2783570.5, 1163551.6],
                [2783499.5, 1160843.4],
                [2777639.7, 1158904.7],
                [2775533.9, 1158157.3],
                [2774755.7, 1160068.9],
                [2774234.4, 1163126],
                [2775388.4, 1163850.6],
                [2773728, 1167425],
                [2778065.2, 1171915.3],
                [2779370, 1171756.6],
                [2782191.5, 1173154.6],
                [2787210, 1173015.4],
                [2786198, 1171168.1],
                [2785750.2, 1169991.4],
                [2786715.4, 1169003.3],
                [2787659.7, 1166560.9],
                [2785808.7, 1164335.9],
                [2783570.5, 1163551.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7473', ortbez27: 'Alvaneu Bad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2767880.8, 1171546.5], [2770759.9, 1172544.3], [2769689.7, 1170199.5], [2767880.8, 1171546.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7472', ortbez27: 'Surava' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2766727.3, 1168072.6],
                [2765255.2, 1170154.5],
                [2767880.8, 1171546.5],
                [2769689.7, 1170199.5],
                [2768588.6, 1169579.7],
                [2766727.3, 1168072.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3377', ortbez27: 'Walliswil b. Wangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617651.4, 1230455.1],
                [2617963.1, 1231925.7],
                [2619366.6, 1232471.5],
                [2619286.5, 1230605.3],
                [2617651.4, 1230455.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7477', ortbez27: 'Filisur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2776034.6, 1175355.3],
                [2778065.2, 1171915.3],
                [2773728, 1167425],
                [2775388.4, 1163850.6],
                [2774234.4, 1163126],
                [2769207.6, 1165308.2],
                [2768588.6, 1169579.7],
                [2769689.7, 1170199.5],
                [2770759.9, 1172544.3],
                [2773188.6, 1173454.8],
                [2774375.2, 1171820.5],
                [2776034.6, 1175355.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3375', ortbez27: 'Inkwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615774.4, 1227883.6],
                [2618659.1, 1229524.1],
                [2617960.1, 1226837.8],
                [2616749.4, 1226346.7],
                [2615774.4, 1227883.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3380', ortbez27: 'Wangen an der Aare' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2617651.4, 1230455.1], [2615354.3, 1230273.1], [2617963.1, 1231925.7], [2617651.4, 1230455.1]]],
            [
              [
                [2620939.6, 1232310.9],
                [2620937.9, 1230952.7],
                [2619286.5, 1230605.3],
                [2619366.6, 1232471.5],
                [2620939.6, 1232310.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7463', ortbez27: 'Riom' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764104.1, 1168089.5],
                [2766727.3, 1168072.6],
                [2765861.9, 1166128.4],
                [2764420.5, 1166491.9],
                [2764104.1, 1168089.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7464', ortbez27: 'Parsonz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757684.1, 1154955.2],
                [2758261.5, 1159414.1],
                [2760714.6, 1163755.2],
                [2763848.1, 1164949.2],
                [2764792.2, 1163564.8],
                [2764073.4, 1162224.7],
                [2764114.1, 1156179.4],
                [2760695.3, 1152440.2],
                [2757684.1, 1154955.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3367', ortbez27: 'Ochlenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624085.8, 1223896.9],
                [2623015.4, 1220812.3],
                [2621955.8, 1222932.5],
                [2621205.2, 1224036],
                [2621180.2, 1225903],
                [2622172.1, 1227078.6],
                [2622780.9, 1225851.6],
                [2624085.8, 1223896.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3368', ortbez27: 'Bleienbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625398.2, 1226212.8],
                [2624085.8, 1223896.9],
                [2622780.9, 1225851.6],
                [2624934.8, 1227765],
                [2625398.2, 1226212.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3373', ortbez27: 'Heimenhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619577.6, 1227712.2],
                [2617960.1, 1226837.8],
                [2618659.1, 1229524.1],
                [2619286.5, 1230605.3],
                [2620937.9, 1230952.7],
                [2620583.6, 1228824.8],
                [2619577.6, 1227712.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3374', ortbez27: 'Wangenried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615354.3, 1230273.1],
                [2617651.4, 1230455.1],
                [2619286.5, 1230605.3],
                [2618659.1, 1229524.1],
                [2615774.4, 1227883.6],
                [2615354.3, 1230273.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7522', ortbez27: 'La Punt-Chamues-ch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2783499.5, 1160843.4],
                [2783570.5, 1163551.6],
                [2785808.7, 1164335.9],
                [2788584.1, 1163648.1],
                [2793812.5, 1160823.5],
                [2795984.6, 1158951.5],
                [2795792.3, 1153967.9],
                [2795474.9, 1150697.8],
                [2792382.6, 1152565.1],
                [2791349.6, 1156396.6],
                [2788836.2, 1158287.8],
                [2788295.4, 1161281.6],
                [2783499.5, 1160843.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3425', ortbez27: 'Koppigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611499.3, 1219293],
                [2611262.1, 1222001],
                [2612680, 1222574],
                [2613977.5, 1222619.9],
                [2612974.8, 1221289.5],
                [2614172.7, 1220438.6],
                [2611499.3, 1219293]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3426', ortbez27: 'Aefligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2607558, 1217815.6], [2609506.5, 1216253.9], [2608459.4, 1214356.6], [2607558, 1217815.6]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7525', ortbez27: 'S-chanf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2793721.9, 1166358],
                [2801076, 1168134.3],
                [2804121.3, 1165769],
                [2804103.6, 1162657.2],
                [2800110.3, 1158217.4],
                [2795984.6, 1158951.5],
                [2796942.8, 1163544.8],
                [2793721.9, 1166358]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7526', ortbez27: 'Cinuos-chel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2787210, 1173015.4],
                [2792576.4, 1174604.1],
                [2801076, 1168134.3],
                [2793721.9, 1166358],
                [2789295.3, 1166589.4],
                [2787659.7, 1166560.9],
                [2786715.4, 1169003.3],
                [2785750.2, 1169991.4],
                [2786198, 1171168.1],
                [2787210, 1173015.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3423', ortbez27: 'Ersigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610482, 1217278.9],
                [2612586.7, 1217551.4],
                [2613473.7, 1216661.8],
                [2611916.3, 1215053],
                [2610551.8, 1215408.5],
                [2610482, 1217278.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7523', ortbez27: 'Madulain' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2795474.9, 1150697.8],
                [2795792.3, 1153967.9],
                [2798030.5, 1155928],
                [2797513.7, 1150974.1],
                [2795474.9, 1150697.8]
              ]
            ],
            [[[2793812.5, 1160823.5], [2788584.1, 1163648.1], [2789295.3, 1166589.4], [2793812.5, 1160823.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3424', ortbez27: 'Nieder�sch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611499.3, 1219293],
                [2614172.7, 1220438.6],
                [2614586.6, 1217290.3],
                [2613473.7, 1216661.8],
                [2612586.7, 1217551.4],
                [2611499.3, 1219293]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3429', ortbez27: 'H�chstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613977.5, 1222619.9],
                [2615342.7, 1222678.6],
                [2616778.6, 1221916],
                [2616091.3, 1220745.7],
                [2614172.7, 1220438.6],
                [2612974.8, 1221289.5],
                [2613977.5, 1222619.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7524', ortbez27: 'Zuoz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2785808.7, 1164335.9],
                [2787659.7, 1166560.9],
                [2789295.3, 1166589.4],
                [2788584.1, 1163648.1],
                [2785808.7, 1164335.9]
              ]
            ],
            [
              [
                [2800110.3, 1158217.4],
                [2800207.6, 1150731.4],
                [2797513.7, 1150974.1],
                [2798030.5, 1155928],
                [2795792.3, 1153967.9],
                [2795984.6, 1158951.5],
                [2800110.3, 1158217.4]
              ]
            ],
            [
              [
                [2795984.6, 1158951.5],
                [2793812.5, 1160823.5],
                [2789295.3, 1166589.4],
                [2793721.9, 1166358],
                [2796942.8, 1163544.8],
                [2795984.6, 1158951.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7513', ortbez27: 'Silvaplana' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2782734.2, 1150017.4],
                [2785175.1, 1148136.9],
                [2783012, 1142342],
                [2781073, 1146573.4],
                [2779898.5, 1147065.7],
                [2778595.4, 1147926],
                [2777018.6, 1147416.4],
                [2775605.6, 1146514.7],
                [2775770.3, 1153447.4],
                [2777436.5, 1153128.8],
                [2778682, 1151610.7],
                [2779595.8, 1150714.7],
                [2781881.6, 1150950.8],
                [2782734.2, 1150017.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7514', ortbez27: 'Fex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2784263.2, 1137300.4],
                [2780692.6, 1135001.8],
                [2777342.3, 1143633.9],
                [2778322.8, 1144625.6],
                [2778202, 1145919],
                [2777018.6, 1147416.4],
                [2778595.4, 1147926],
                [2779898.5, 1147065.7],
                [2781073, 1146573.4],
                [2783012, 1142342],
                [2784263.2, 1137300.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3427', ortbez27: 'Utzenstorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611262.1, 1222001],
                [2611499.3, 1219293],
                [2612586.7, 1217551.4],
                [2610482, 1217278.9],
                [2609506.5, 1216253.9],
                [2607558, 1217815.6],
                [2608134.3, 1222308.1],
                [2611262.1, 1222001]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3428', ortbez27: 'Wiler b. Utzenstorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611262.1, 1222001],
                [2608134.3, 1222308.1],
                [2607681.3, 1223739.5],
                [2609192.1, 1224281.9],
                [2610406.9, 1223878.9],
                [2611262.1, 1222001]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3417', ortbez27: 'R�egsau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620279.9, 1208620.6],
                [2617572.9, 1207602.6],
                [2616552.2, 1208807.3],
                [2617843, 1209759.9],
                [2620279.9, 1208620.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7517', ortbez27: 'Plaun da Lej' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2771940.6, 1143866.8], [2775605.6, 1146514.7], [2777342.3, 1143633.9], [2771940.6, 1143866.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3418', ortbez27: 'R�egsbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622511.1, 1211748.7],
                [2620279.9, 1208620.6],
                [2617843, 1209759.9],
                [2618954.6, 1211578.5],
                [2620525.1, 1213058.7],
                [2622511.1, 1211748.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3415', ortbez27: 'Hasle b. Burgdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616396, 1203637.6],
                [2614581, 1204099.3],
                [2615595.7, 1205604.9],
                [2613973.9, 1206246.2],
                [2615399.2, 1209538],
                [2616552.2, 1208807.3],
                [2617572.9, 1207602.6],
                [2617143.1, 1205776.9],
                [2616396, 1203637.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7515', ortbez27: 'Sils/Segl Baselgia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2778322.8, 1144625.6],
                [2777342.3, 1143633.9],
                [2775605.6, 1146514.7],
                [2777018.6, 1147416.4],
                [2778202, 1145919],
                [2778322.8, 1144625.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3416', ortbez27: 'Affoltern im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620525.1, 1213058.7],
                [2620209.2, 1214431.9],
                [2622132.6, 1214620.9],
                [2624559.1, 1213661.9],
                [2622511.1, 1211748.7],
                [2620525.1, 1213058.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7516', ortbez27: 'Maloja' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2777342.3, 1143633.9],
                [2780692.6, 1135001.8],
                [2776980.9, 1135988.1],
                [2775443.8, 1129513.4],
                [2772500.5, 1130561.7],
                [2773199.5, 1135955.1],
                [2773388.3, 1141215.1],
                [2770593.1, 1142908.9],
                [2771940.6, 1143866.8],
                [2777342.3, 1143633.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3421', ortbez27: 'Lyssach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612185, 1211708.3],
                [2611928.8, 1210472.6],
                [2610142.5, 1211729.6],
                [2608741.9, 1211932.7],
                [2609840.5, 1213699.6],
                [2610551.8, 1215408.5],
                [2611916.3, 1215053],
                [2612543.5, 1213432.3],
                [2612185, 1211708.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3422', ortbez27: 'Kirchberg BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615815.2, 1214739.8],
                [2612543.5, 1213432.3],
                [2611916.3, 1215053],
                [2613473.7, 1216661.8],
                [2614586.6, 1217290.3],
                [2616002.5, 1216622],
                [2615815.2, 1214739.8]
              ]
            ],
            [
              [
                [2608459.4, 1214356.6],
                [2609506.5, 1216253.9],
                [2610482, 1217278.9],
                [2610551.8, 1215408.5],
                [2609840.5, 1213699.6],
                [2608459.4, 1214356.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7505', ortbez27: 'Celerina/Schlarigna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2780127.6, 1153677.3],
                [2781975.7, 1157546.7],
                [2787605.4, 1153816.6],
                [2788997.3, 1152230.2],
                [2786290.5, 1149555.9],
                [2786793.6, 1152792.1],
                [2780127.6, 1153677.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3419', ortbez27: 'Biembach im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613973.9, 1206246.2],
                [2615595.7, 1205604.9],
                [2614581, 1204099.3],
                [2611718.6, 1203855.5],
                [2613973.9, 1206246.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7503', ortbez27: 'Samedan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2781975.7, 1157546.7],
                [2788836.2, 1158287.8],
                [2791349.6, 1156396.6],
                [2792382.6, 1152565.1],
                [2788997.3, 1152230.2],
                [2787605.4, 1153816.6],
                [2781975.7, 1157546.7]
              ]
            ],
            [
              [
                [2783499.5, 1160843.4],
                [2781975.7, 1157546.7],
                [2780127.6, 1153677.3],
                [2778116.2, 1154251.6],
                [2777639.7, 1158904.7],
                [2783499.5, 1160843.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7504', ortbez27: 'Pontresina' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2785175.1, 1148136.9],
                [2786290.5, 1149555.9],
                [2788997.3, 1152230.2],
                [2792382.6, 1152565.1],
                [2795474.9, 1150697.8],
                [2797513.7, 1150974.1],
                [2800207.6, 1150731.4],
                [2800123.7, 1146946.8],
                [2798582.7, 1143984.1],
                [2793955.1, 1139568.2],
                [2784263.2, 1137300.4],
                [2783012, 1142342],
                [2785175.1, 1148136.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3413', ortbez27: 'Kaltacker' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616098.3, 1212789.4],
                [2615815.2, 1214739.8],
                [2620209.2, 1214431.9],
                [2620525.1, 1213058.7],
                [2618954.6, 1211578.5],
                [2616098.3, 1212789.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3414', ortbez27: 'Oberburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615399.2, 1209538],
                [2613973.9, 1206246.2],
                [2611718.6, 1203855.5],
                [2610460.5, 1204690.2],
                [2612292.3, 1206368.6],
                [2610618.6, 1209056.3],
                [2611928.8, 1210472.6],
                [2613931.6, 1210600],
                [2615399.2, 1209538]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3412', ortbez27: 'Heimiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616098.3, 1212789.4],
                [2618954.6, 1211578.5],
                [2617843, 1209759.9],
                [2616552.2, 1208807.3],
                [2615399.2, 1209538],
                [2616098.3, 1212789.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7502', ortbez27: 'Bever' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2773124.8, 1157248.8],
                [2775533.9, 1158157.3],
                [2777639.7, 1158904.7],
                [2778116.2, 1154251.6],
                [2777436.5, 1153128.8],
                [2775770.3, 1153447.4],
                [2773689.8, 1153429.8],
                [2773124.8, 1157248.8]
              ]
            ],
            [
              [
                [2781975.7, 1157546.7],
                [2783499.5, 1160843.4],
                [2788295.4, 1161281.6],
                [2788836.2, 1158287.8],
                [2781975.7, 1157546.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3400', ortbez27: 'Burgdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612185, 1211708.3],
                [2612543.5, 1213432.3],
                [2615815.2, 1214739.8],
                [2616098.3, 1212789.4],
                [2615399.2, 1209538],
                [2613931.6, 1210600],
                [2611928.8, 1210472.6],
                [2612185, 1211708.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7500', ortbez27: 'St. Moritz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2786290.5, 1149555.9],
                [2785175.1, 1148136.9],
                [2782734.2, 1150017.4],
                [2781881.6, 1150950.8],
                [2779595.8, 1150714.7],
                [2778682, 1151610.7],
                [2777436.5, 1153128.8],
                [2778116.2, 1154251.6],
                [2780127.6, 1153677.3],
                [2786793.6, 1152792.1],
                [2786290.5, 1149555.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7553', ortbez27: 'Tarasp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2817150.5, 1175108.1],
                [2812536.4, 1175527.9],
                [2811146.7, 1179692.1],
                [2812957.3, 1184521.9],
                [2816844.5, 1186042.8],
                [2818162.5, 1185843],
                [2815327.1, 1176999.3],
                [2817150.5, 1175108.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7554', ortbez27: 'Sent' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2814726.3, 1201633.8],
                [2817502.3, 1200854.2],
                [2823292, 1191542.6],
                [2827846.4, 1186426.6],
                [2829564.6, 1182610.7],
                [2826422.5, 1180192.4],
                [2822901.4, 1181433.3],
                [2821508.8, 1187995.5],
                [2814866.1, 1192484.4],
                [2813959.1, 1195088.4],
                [2814726.3, 1201633.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7551', ortbez27: 'Ftan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2809334.3, 1193313.1],
                [2813959.1, 1195088.4],
                [2814866.1, 1192484.4],
                [2813866.9, 1190476.1],
                [2816844.5, 1186042.8],
                [2812957.3, 1184521.9],
                [2809334.3, 1193313.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3457', ortbez27: 'Wasen im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635301.4, 1206384.6],
                [2632900.1, 1205357.6],
                [2629035.7, 1205629.1],
                [2626888.4, 1208677.9],
                [2625767.5, 1211536.9],
                [2627144.5, 1212430.9],
                [2630358.5, 1211851.8],
                [2632653, 1211899.9],
                [2633062.5, 1210623.8],
                [2634572.2, 1209018.2],
                [2635301.4, 1206384.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7557', ortbez27: 'Vn�' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2825597.8, 1193082.2],
                [2823292, 1191542.6],
                [2817502.3, 1200854.2],
                [2819202.4, 1201285],
                [2824065.3, 1198729.2],
                [2825597.8, 1193082.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7558', ortbez27: 'Strada' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2831169.3, 1195149.2],
                [2828123.6, 1188285.8],
                [2825597.8, 1193082.2],
                [2829363.7, 1196463.1],
                [2831169.3, 1195149.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7556', ortbez27: 'Ramosch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2827846.4, 1186426.6],
                [2823292, 1191542.6],
                [2825597.8, 1193082.2],
                [2828123.6, 1188285.8],
                [2827846.4, 1186426.6]
              ]
            ],
            [[[2814726.3, 1201633.8], [2813959.1, 1195088.4], [2813433, 1201765.2], [2814726.3, 1201633.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7545', ortbez27: 'Guarda' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2802808.6, 1192208.4],
                [2807424, 1192263.1],
                [2809293, 1183806.7],
                [2807221.3, 1181344.2],
                [2802808.6, 1192208.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3462', ortbez27: 'Weier im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2627144.5, 1212430.9],
                [2625767.5, 1211536.9],
                [2622511.1, 1211748.7],
                [2624559.1, 1213661.9],
                [2627144.5, 1212430.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7546', ortbez27: 'Ardez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2807424, 1192263.1],
                [2809334.3, 1193313.1],
                [2812957.3, 1184521.9],
                [2811146.7, 1179692.1],
                [2812536.4, 1175527.9],
                [2807131.7, 1178516.6],
                [2807221.3, 1181344.2],
                [2809293, 1183806.7],
                [2807424, 1192263.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7543', ortbez27: 'Lavin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2807131.7, 1178516.6],
                [2804568.8, 1178631.3],
                [2800291.1, 1188151.8],
                [2802808.6, 1192208.4],
                [2807221.3, 1181344.2],
                [2807131.7, 1178516.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7550', ortbez27: 'Scuol' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2826274.4, 1171448.5],
                [2821971.5, 1170954.9],
                [2817150.5, 1175108.1],
                [2815327.1, 1176999.3],
                [2818162.5, 1185843],
                [2816844.5, 1186042.8],
                [2813866.9, 1190476.1],
                [2814866.1, 1192484.4],
                [2821508.8, 1187995.5],
                [2822901.4, 1181433.3],
                [2826422.5, 1180192.4],
                [2827895.7, 1178634.4],
                [2825212.3, 1174748],
                [2826274.4, 1171448.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3453', ortbez27: 'Heimisbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626888.4, 1208677.9],
                [2629035.7, 1205629.1],
                [2627419.7, 1205011.7],
                [2625077.3, 1204704.9],
                [2623994.8, 1205707.4],
                [2624288.1, 1207517.7],
                [2626888.4, 1208677.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7537', ortbez27: 'M�stair' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2833426.3, 1163529],
                [2826783.2, 1169681.7],
                [2830308.5, 1170114.8],
                [2833939.4, 1167375],
                [2833426.3, 1163529]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3454', ortbez27: 'Sumiswald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625767.5, 1211536.9],
                [2626888.4, 1208677.9],
                [2624288.1, 1207517.7],
                [2622722.5, 1207948.8],
                [2620279.9, 1208620.6],
                [2622511.1, 1211748.7],
                [2625767.5, 1211536.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3452', ortbez27: 'Gr�nenmatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620660.5, 1206181.7],
                [2620279.9, 1208620.6],
                [2622722.5, 1207948.8],
                [2624288.1, 1207517.7],
                [2623994.8, 1205707.4],
                [2621821.6, 1205180.3],
                [2620660.5, 1206181.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7535', ortbez27: 'Valchava' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2825729.1, 1166271.7],
                [2826498.6, 1168144.2],
                [2828033.2, 1165628.8],
                [2827216.7, 1164676.9],
                [2825870.9, 1164068.6],
                [2824517.5, 1163407],
                [2825729.1, 1166271.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7536', ortbez27: 'Sta. Maria Val M�stair' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2826783.2, 1169681.7],
                [2833426.3, 1163529],
                [2831295.2, 1157848.6],
                [2828560, 1160041.8],
                [2823451.9, 1160352.8],
                [2822457.8, 1158855.1],
                [2815299.5, 1162139.8],
                [2815914.1, 1166352],
                [2819748.9, 1165677.2],
                [2822958, 1163511.9],
                [2824517.5, 1163407],
                [2825870.9, 1164068.6],
                [2827216.7, 1164676.9],
                [2828033.2, 1165628.8],
                [2826498.6, 1168144.2],
                [2826783.2, 1169681.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7542', ortbez27: 'Susch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2793213, 1182329],
                [2800291.1, 1188151.8],
                [2804568.8, 1178631.3],
                [2796465.2, 1178873.5],
                [2796184.8, 1176452.3],
                [2792576.4, 1174604.1],
                [2790660.7, 1180775.6],
                [2793213, 1182329]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7530', ortbez27: 'Zernez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2812536.4, 1175527.9],
                [2817150.5, 1175108.1],
                [2817492.5, 1171614.6],
                [2814522.7, 1169729],
                [2812746.1, 1166760.3],
                [2804121.3, 1165769],
                [2801076, 1168134.3],
                [2800209.2, 1171783.1],
                [2800204.8, 1173104.5],
                [2799132.4, 1175699],
                [2796184.8, 1176452.3],
                [2796465.2, 1178873.5],
                [2804568.8, 1178631.3],
                [2807131.7, 1178516.6],
                [2812536.4, 1175527.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7527', ortbez27: 'Brail' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2801076, 1168134.3],
                [2792576.4, 1174604.1],
                [2796184.8, 1176452.3],
                [2799132.4, 1175699],
                [2800204.8, 1173104.5],
                [2800209.2, 1171783.1],
                [2801076, 1168134.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3433', ortbez27: 'Schwanden im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2619608.2, 1204956.6], [2620626.7, 1201541.3], [2618685.5, 1202431.8], [2619608.2, 1204956.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3434', ortbez27: 'Landiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620626.7, 1201541.3],
                [2620385.9, 1199858.3],
                [2619793.9, 1198098.1],
                [2617421.8, 1199847.8],
                [2615724, 1200391.6],
                [2616946.4, 1201816.5],
                [2618685.5, 1202431.8],
                [2620626.7, 1201541.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7533', ortbez27: 'Fuldera' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2819748.9, 1165677.2],
                [2823696.2, 1167499],
                [2825729.1, 1166271.7],
                [2824517.5, 1163407],
                [2822958, 1163511.9],
                [2819748.9, 1165677.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7534', ortbez27: 'L�' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2826274.4, 1171448.5],
                [2826783.2, 1169681.7],
                [2826498.6, 1168144.2],
                [2825729.1, 1166271.7],
                [2823696.2, 1167499],
                [2821971.5, 1170954.9],
                [2826274.4, 1171448.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3432', ortbez27: 'L�tzelfl�h-Goldbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617143.1, 1205776.9],
                [2617572.9, 1207602.6],
                [2620279.9, 1208620.6],
                [2620660.5, 1206181.7],
                [2619608.2, 1204956.6],
                [2618685.5, 1202431.8],
                [2616946.4, 1201816.5],
                [2616396, 1203637.6],
                [2617143.1, 1205776.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7532', ortbez27: 'Tschierv' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2817150.5, 1175108.1],
                [2821971.5, 1170954.9],
                [2823696.2, 1167499],
                [2819748.9, 1165677.2],
                [2815914.1, 1166352],
                [2814981.4, 1167421.4],
                [2814522.7, 1169729],
                [2817492.5, 1171614.6],
                [2817150.5, 1175108.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3437', ortbez27: 'R�derswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622525, 1202673.1],
                [2620626.7, 1201541.3],
                [2619608.2, 1204956.6],
                [2620660.5, 1206181.7],
                [2621821.6, 1205180.3],
                [2622525, 1202673.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3438', ortbez27: 'Lauperswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620626.7, 1201541.3],
                [2622525, 1202673.1],
                [2623607.7, 1200863],
                [2620385.9, 1199858.3],
                [2620626.7, 1201541.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3436', ortbez27: 'Zollbr�ck' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623607.7, 1200863],
                [2622525, 1202673.1],
                [2621821.6, 1205180.3],
                [2623994.8, 1205707.4],
                [2625077.3, 1204704.9],
                [2627419.7, 1205011.7],
                [2625857.4, 1201194.6],
                [2623607.7, 1200863]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3473', ortbez27: 'Alchenstorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614586.6, 1217290.3],
                [2614172.7, 1220438.6],
                [2616091.3, 1220745.7],
                [2617681, 1219660],
                [2616576.3, 1217743.8],
                [2616002.5, 1216622],
                [2614586.6, 1217290.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3472', ortbez27: 'Wynigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2621724.3, 1217039.3],
                [2622132.6, 1214620.9],
                [2620209.2, 1214431.9],
                [2615815.2, 1214739.8],
                [2616002.5, 1216622],
                [2616576.3, 1217743.8],
                [2617681, 1219660],
                [2619163.8, 1220365.8],
                [2620381.6, 1220057.9],
                [2621064.7, 1219010.9],
                [2622148.6, 1218376.4],
                [2621724.3, 1217039.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7562', ortbez27: 'Samnaun-Compatsch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2826441.6, 1207864.6],
                [2828062, 1205088.1],
                [2824866.7, 1203370.9],
                [2823214.2, 1205938.1],
                [2826441.6, 1207864.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3475', ortbez27: 'Riedtwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2620381.6, 1220057.9], [2619163.8, 1220365.8], [2619136.2, 1222578.9], [2620381.6, 1220057.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7559', ortbez27: 'Tschlin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2828062, 1205088.1],
                [2829363.7, 1196463.1],
                [2825597.8, 1193082.2],
                [2824065.3, 1198729.2],
                [2824866.7, 1203370.9],
                [2828062, 1205088.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3476', ortbez27: 'Oschwand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2621955.8, 1222932.5],
                [2623015.4, 1220812.3],
                [2621064.7, 1219010.9],
                [2620381.6, 1220057.9],
                [2619136.2, 1222578.9],
                [2619954.4, 1223605.8],
                [2621205.2, 1224036],
                [2621955.8, 1222932.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7560', ortbez27: 'Martina' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2831169.3, 1195149.2],
                [2829363.7, 1196463.1],
                [2828062, 1205088.1],
                [2832338.1, 1203184],
                [2831169.3, 1195149.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3465', ortbez27: 'D�rrenroth' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2627144.5, 1212430.9],
                [2624559.1, 1213661.9],
                [2624259.8, 1216084.9],
                [2626512.7, 1216549.2],
                [2628192.5, 1216380.5],
                [2627144.5, 1212430.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3463', ortbez27: 'H�usernmoos im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2622132.6, 1214620.9], [2624259.8, 1216084.9], [2624559.1, 1213661.9], [2622132.6, 1214620.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7563', ortbez27: 'Samnaun Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2824065.3, 1198729.2],
                [2819202.4, 1201285],
                [2818478.7, 1203716.1],
                [2821255.4, 1208526.9],
                [2824463.3, 1209863.1],
                [2826441.6, 1207864.6],
                [2823214.2, 1205938.1],
                [2824866.7, 1203370.9],
                [2824065.3, 1198729.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3464', ortbez27: 'Schmidigen-M�hleweg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2622132.6, 1214620.9], [2621724.3, 1217039.3], [2624259.8, 1216084.9], [2622132.6, 1214620.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7610', ortbez27: 'Soglio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2762653.7, 1139048.9],
                [2764928, 1136999.6],
                [2763769.1, 1133801.5],
                [2762056.8, 1133885.7],
                [2760240.5, 1132893.9],
                [2759633.5, 1134195.2],
                [2759389.5, 1135422.1],
                [2758280.3, 1137062.3],
                [2762653.7, 1139048.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3513', ortbez27: 'Bigenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616946.4, 1201816.5],
                [2613294.2, 1201394.6],
                [2611718.6, 1203855.5],
                [2614581, 1204099.3],
                [2616396, 1203637.6],
                [2616946.4, 1201816.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3512', ortbez27: 'Walkringen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616946.4, 1201816.5],
                [2615724, 1200391.6],
                [2614964.9, 1199270.4],
                [2613288.9, 1197234.4],
                [2611707.7, 1197168.8],
                [2611802.8, 1198707.8],
                [2612264.6, 1200067.6],
                [2613294.2, 1201394.6],
                [2616946.4, 1201816.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7602', ortbez27: 'Casaccia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2768586.2, 1139620.4],
                [2767142, 1141878.8],
                [2768407.5, 1141909.7],
                [2769646.5, 1142087.2],
                [2770593.1, 1142908.9],
                [2773388.3, 1141215.1],
                [2773199.5, 1135955.1],
                [2770852.9, 1138707.9],
                [2769600.9, 1138856.2],
                [2768586.2, 1139620.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7605', ortbez27: 'Stampa' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2768770.5, 1132794.7],
                [2767481.5, 1132975.4],
                [2763769.1, 1133801.5],
                [2764928, 1136999.6],
                [2767259.2, 1135178.9],
                [2768277.2, 1134123],
                [2768770.5, 1132794.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3506', ortbez27: 'Grossh�chstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613827.5, 1193835.7],
                [2614209.3, 1196270.2],
                [2616532, 1195291.4],
                [2615224.1, 1194230.1],
                [2613827.5, 1193835.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7606', ortbez27: 'Bondo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2768770.5, 1132794.7],
                [2768358.6, 1128743.2],
                [2762710.1, 1130208.5],
                [2760240.5, 1132893.9],
                [2762056.8, 1133885.7],
                [2763769.1, 1133801.5],
                [2767481.5, 1132975.4],
                [2768770.5, 1132794.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3503', ortbez27: 'Gysenstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610606.5, 1193463.3],
                [2611974.7, 1195760.8],
                [2613827.5, 1193835.7],
                [2611829.5, 1192106.8],
                [2610606.5, 1193463.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7603', ortbez27: 'Vicosoprano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2772500.5, 1130561.7],
                [2768358.6, 1128743.2],
                [2768770.5, 1132794.7],
                [2768277.2, 1134123],
                [2767259.2, 1135178.9],
                [2764928, 1136999.6],
                [2762653.7, 1139048.9],
                [2763547.7, 1140004.1],
                [2763845.9, 1141235.3],
                [2765063.7, 1141520.1],
                [2765945.3, 1142431.9],
                [2767142, 1141878.8],
                [2768586.2, 1139620.4],
                [2769600.9, 1138856.2],
                [2770852.9, 1138707.9],
                [2773199.5, 1135955.1],
                [2772500.5, 1130561.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3504', ortbez27: 'Niederh�nigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615724.7, 1189572.4],
                [2614639.5, 1191504.1],
                [2616258.3, 1192629.3],
                [2618702.4, 1192559.3],
                [2619152.4, 1190228.8],
                [2617877.6, 1190136],
                [2615724.7, 1189572.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3510', ortbez27: 'Freimettigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613510.9, 1189586.3],
                [2612588.5, 1187402.6],
                [2610790.8, 1190325.2],
                [2612709.5, 1190722],
                [2611829.5, 1192106.8],
                [2613827.5, 1193835.7],
                [2615224.1, 1194230.1],
                [2616258.3, 1192629.3],
                [2614639.5, 1191504.1],
                [2615724.7, 1189572.4],
                [2613510.9, 1189586.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3508', ortbez27: 'Arni BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616532, 1195291.4],
                [2614209.3, 1196270.2],
                [2613288.9, 1197234.4],
                [2614964.9, 1199270.4],
                [2615724, 1200391.6],
                [2617421.8, 1199847.8],
                [2619793.9, 1198098.1],
                [2617959, 1195600.6],
                [2616532, 1195291.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3553', ortbez27: 'Gohl' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632900.1, 1205357.6],
                [2630589.5, 1201778.1],
                [2628034.1, 1199527.8],
                [2627039.1, 1200731.5],
                [2629035.7, 1205629.1],
                [2632900.1, 1205357.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3551', ortbez27: 'Oberfrittenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629035.7, 1205629.1],
                [2627039.1, 1200731.5],
                [2625857.4, 1201194.6],
                [2627419.7, 1205011.7],
                [2629035.7, 1205629.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3552', ortbez27: 'B�rau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628034.1, 1199527.8],
                [2630589.5, 1201778.1],
                [2631307.4, 1198518.7],
                [2629085.5, 1196798.9],
                [2628034.1, 1199527.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3557', ortbez27: 'Fankhaus (Trub)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638970.4, 1206495.5],
                [2639172.3, 1203682.3],
                [2637608.8, 1200201.3],
                [2635153.3, 1202335.8],
                [2635301.4, 1206384.6],
                [2638206.2, 1207656.5],
                [2638970.4, 1206495.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3555', ortbez27: 'Trubschachen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633941.3, 1195300.9],
                [2631955.9, 1192355.8],
                [2628718.3, 1193619.4],
                [2628033.9, 1195286.8],
                [2629085.5, 1196798.9],
                [2631307.4, 1198518.7],
                [2636789.5, 1199228.1],
                [2632849.6, 1196430.8],
                [2633941.3, 1195300.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3556', ortbez27: 'Trub' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632900.1, 1205357.6],
                [2635301.4, 1206384.6],
                [2635153.3, 1202335.8],
                [2637608.8, 1200201.3],
                [2636789.5, 1199228.1],
                [2631307.4, 1198518.7],
                [2630589.5, 1201778.1],
                [2632900.1, 1205357.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3543', ortbez27: 'Emmenmatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620385.9, 1199858.3],
                [2623607.7, 1200863],
                [2624163.5, 1199708.2],
                [2621342.6, 1198524.3],
                [2619793.9, 1198098.1],
                [2620385.9, 1199858.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3550', ortbez27: 'Langnau im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625857.4, 1201194.6],
                [2627039.1, 1200731.5],
                [2628034.1, 1199527.8],
                [2629085.5, 1196798.9],
                [2628033.9, 1195286.8],
                [2624384.4, 1195877.6],
                [2624163.5, 1199708.2],
                [2623607.7, 1200863],
                [2625857.4, 1201194.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3537', ortbez27: 'Eggiwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629033, 1184765.7],
                [2626090.5, 1188090.7],
                [2623424.1, 1192601.6],
                [2624980.7, 1193643],
                [2628718.3, 1193619.4],
                [2631955.9, 1192355.8],
                [2632962.5, 1188682.5],
                [2629033, 1184765.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3538', ortbez27: 'R�thenbach im Emmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620781.8, 1190605.2],
                [2622210.7, 1194192.3],
                [2623424.1, 1192601.6],
                [2626090.5, 1188090.7],
                [2624732.1, 1187847.4],
                [2623418.8, 1187111.6],
                [2622390.1, 1186364.4],
                [2621363.2, 1188333.4],
                [2620781.8, 1190605.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3535', ortbez27: 'Sch�pbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2621342.6, 1198524.3], [2624163.5, 1199708.2], [2624384.4, 1195877.6], [2621342.6, 1198524.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3536', ortbez27: 'Aeschau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628718.3, 1193619.4],
                [2624980.7, 1193643],
                [2624384.4, 1195877.6],
                [2628033.9, 1195286.8],
                [2628718.3, 1193619.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3533', ortbez27: 'Bowil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620828.4, 1195749.6],
                [2622210.7, 1194192.3],
                [2620781.8, 1190605.2],
                [2619152.4, 1190228.8],
                [2618702.4, 1192559.3],
                [2617959, 1195600.6],
                [2620828.4, 1195749.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3534', ortbez27: 'Signau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622210.7, 1194192.3],
                [2620828.4, 1195749.6],
                [2619793.9, 1198098.1],
                [2621342.6, 1198524.3],
                [2624384.4, 1195877.6],
                [2624980.7, 1193643],
                [2623424.1, 1192601.6],
                [2622210.7, 1194192.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3531', ortbez27: 'Oberthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2619793.9, 1198098.1], [2620828.4, 1195749.6], [2617959, 1195600.6], [2619793.9, 1198098.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3532', ortbez27: 'Z�ziwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617959, 1195600.6],
                [2618702.4, 1192559.3],
                [2616258.3, 1192629.3],
                [2615224.1, 1194230.1],
                [2616532, 1195291.4],
                [2617959, 1195600.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3617', ortbez27: 'Fahrni b. Thun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2615630.6, 1184233.6], [2618098, 1184007.2], [2617654.1, 1181820], [2615630.6, 1184233.6]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3618', ortbez27: 'S�deren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629033, 1184765.7],
                [2629942, 1183500.9],
                [2625911.4, 1183531.2],
                [2622060.2, 1184421.3],
                [2621294.7, 1185523.7],
                [2622390.1, 1186364.4],
                [2623418.8, 1187111.6],
                [2624732.1, 1187847.4],
                [2626090.5, 1188090.7],
                [2629033, 1184765.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3615', ortbez27: 'Heimenschwand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615630.6, 1184233.6],
                [2616803.6, 1186145.8],
                [2617479.7, 1187761.8],
                [2621363.2, 1188333.4],
                [2622390.1, 1186364.4],
                [2621294.7, 1185523.7],
                [2618098, 1184007.2],
                [2615630.6, 1184233.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3616', ortbez27: 'Schwarzenegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622060.2, 1184421.3],
                [2625911.4, 1183531.2],
                [2624268.4, 1181658.6],
                [2623080.7, 1181253.7],
                [2621040.1, 1182066.4],
                [2622060.2, 1184421.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3622', ortbez27: 'Homberg b. Thun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619872.4, 1179039.9],
                [2617946.7, 1179914.8],
                [2617654.1, 1181820],
                [2619586.9, 1181988],
                [2619872.4, 1179039.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3619', ortbez27: 'Eriz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629316.5, 1178918.2],
                [2628287, 1177823.6],
                [2624506.9, 1180402.6],
                [2624268.4, 1181658.6],
                [2625911.4, 1183531.2],
                [2629942, 1183500.9],
                [2632779.5, 1181839.2],
                [2631801.7, 1179013.3],
                [2629316.5, 1178918.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7710', ortbez27: 'Ospizio Bernina' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2795928.6, 1136914.8],
                [2793955.1, 1139568.2],
                [2798582.7, 1143984.1],
                [2800123.7, 1146946.8],
                [2803079.7, 1145216.3],
                [2802212.5, 1141199],
                [2801088.8, 1140452.1],
                [2800486.7, 1139307.9],
                [2798907.5, 1138967.8],
                [2795928.6, 1136914.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3608', ortbez27: 'Thun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2612650, 1177351.2], [2613069.6, 1174330.4], [2611378.7, 1176208.1], [2612650, 1177351.2]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3614', ortbez27: 'Unterlangenegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617654.1, 1181820],
                [2618098, 1184007.2],
                [2621294.7, 1185523.7],
                [2622060.2, 1184421.3],
                [2621040.1, 1182066.4],
                [2619586.9, 1181988],
                [2617654.1, 1181820]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3612', ortbez27: 'Steffisburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613086.6, 1181625.7],
                [2614118.1, 1184109.3],
                [2615630.6, 1184233.6],
                [2617654.1, 1181820],
                [2615979.6, 1179439.2],
                [2614446.7, 1179433.7],
                [2613130.4, 1179719.8],
                [2614150.6, 1180965.6],
                [2613086.6, 1181625.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3600', ortbez27: 'Thun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615345.5, 1177237.3],
                [2612650, 1177351.2],
                [2611746.2, 1178460.1],
                [2613130.4, 1179719.8],
                [2614446.7, 1179433.7],
                [2615979.6, 1179439.2],
                [2616477.3, 1177985.3],
                [2615345.5, 1177237.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3604', ortbez27: 'Thun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2612650, 1177351.2], [2615345.5, 1177237.3], [2614834, 1175901.6], [2612650, 1177351.2]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7745', ortbez27: 'Li Curt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2797367.6, 1132445.5],
                [2803652.5, 1132070.9],
                [2800959.3, 1127746.8],
                [2797150.5, 1129297.9],
                [2797367.6, 1132445.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7746', ortbez27: 'Le Prese' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2802271.8, 1125386.4],
                [2800959.3, 1127746.8],
                [2803652.5, 1132070.9],
                [2805392.3, 1135087.3],
                [2808030.2, 1131461.5],
                [2805399.4, 1128392.9],
                [2804282.2, 1126373.8],
                [2802271.8, 1125386.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7743', ortbez27: 'Brusio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2807994.7, 1125733.3],
                [2804282.2, 1126373.8],
                [2805399.4, 1128392.9],
                [2808030.2, 1131461.5],
                [2809418.1, 1129720.7],
                [2810016.3, 1128612.3],
                [2807994.7, 1125733.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3647', ortbez27: 'Reutigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614807.4, 1173292.8],
                [2614922.3, 1170820.7],
                [2613646.5, 1169800.8],
                [2612987.8, 1168732.7],
                [2610311.7, 1170598],
                [2611645.2, 1173307.9],
                [2613069.6, 1174330.4],
                [2614807.4, 1173292.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7747', ortbez27: 'Viano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2809010.5, 1124621.6],
                [2807994.7, 1125733.3],
                [2810016.3, 1128612.3],
                [2810836.2, 1127630.7],
                [2811057.8, 1126393.1],
                [2810115.5, 1125485.9],
                [2809010.5, 1124621.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3653', ortbez27: 'Oberhofen am Thunersee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2619491.5, 1175162.1], [2616953.9, 1175901.6], [2618371.9, 1177167.5], [2619491.5, 1175162.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7748', ortbez27: 'Campascio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2802271.8, 1125386.4],
                [2804282.2, 1126373.8],
                [2807994.7, 1125733.3],
                [2809010.5, 1124621.6],
                [2808157.8, 1123188.5],
                [2802869.3, 1122039.6],
                [2802271.8, 1125386.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3652', ortbez27: 'Hilterfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616953.9, 1175901.6],
                [2615345.5, 1177237.3],
                [2616477.3, 1177985.3],
                [2618371.9, 1177167.5],
                [2616953.9, 1175901.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7741', ortbez27: 'S. Carlo (Poschiavo)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2807128.2, 1138191.9],
                [2805392.3, 1135087.3],
                [2800486.7, 1139307.9],
                [2801088.8, 1140452.1],
                [2802212.5, 1141199],
                [2807128.2, 1138191.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7742', ortbez27: 'Poschiavo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2797367.6, 1132445.5],
                [2795928.6, 1136914.8],
                [2798907.5, 1138967.8],
                [2800486.7, 1139307.9],
                [2805392.3, 1135087.3],
                [2803652.5, 1132070.9],
                [2797367.6, 1132445.5]
              ]
            ],
            [
              [
                [2807128.2, 1138191.9],
                [2802212.5, 1141199],
                [2803079.7, 1145216.3],
                [2804451.1, 1144699.2],
                [2806988.6, 1145943.1],
                [2809848.6, 1143346.1],
                [2807128.2, 1138191.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3645', ortbez27: 'Gwatt (Thun)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613069.6, 1174330.4],
                [2612650, 1177351.2],
                [2614834, 1175901.6],
                [2615503.5, 1174593.5],
                [2614807.4, 1173292.8],
                [2613069.6, 1174330.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3646', ortbez27: 'Einigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2614807.4, 1173292.8], [2615503.5, 1174593.5], [2617350.6, 1172233.8], [2614807.4, 1173292.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3634', ortbez27: 'Thierachern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609876.9, 1177000.4],
                [2607669.6, 1177708.7],
                [2607487.9, 1179086.3],
                [2608726.4, 1179680.1],
                [2611746.2, 1178460.1],
                [2612650, 1177351.2],
                [2611378.7, 1176208.1],
                [2609876.9, 1177000.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3631', ortbez27: 'H�fen b. Thun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607959, 1175261.6],
                [2609876.9, 1177000.4],
                [2611378.7, 1176208.1],
                [2613069.6, 1174330.4],
                [2611645.2, 1173307.9],
                [2609946.7, 1173982.2],
                [2607959, 1175261.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3632', ortbez27: 'Niederstocken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610311.7, 1170598],
                [2607619.3, 1171565],
                [2607959, 1175261.6],
                [2609946.7, 1173982.2],
                [2611645.2, 1173307.9],
                [2610311.7, 1170598]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3638', ortbez27: 'Pohlern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606983.4, 1176084.9],
                [2607959, 1175261.6],
                [2607619.3, 1171565],
                [2605347.6, 1170997.4],
                [2603290, 1172649.9],
                [2601811.2, 1172934.1],
                [2602938.3, 1175953.7],
                [2606345, 1178284.4],
                [2607669.6, 1177708.7],
                [2606983.4, 1176084.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3635', ortbez27: 'Uebeschi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609876.9, 1177000.4],
                [2607959, 1175261.6],
                [2606983.4, 1176084.9],
                [2607669.6, 1177708.7],
                [2609876.9, 1177000.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3636', ortbez27: 'L�ngenb�hl' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2607669.6, 1177708.7], [2606345, 1178284.4], [2607487.9, 1179086.3], [2607669.6, 1177708.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3625', ortbez27: 'Heiligenschwendi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2621065.6, 1178033.1],
                [2618371.9, 1177167.5],
                [2616477.3, 1177985.3],
                [2619872.4, 1179039.9],
                [2621447.2, 1179241.8],
                [2621065.6, 1178033.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3623', ortbez27: 'Buchen BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619872.4, 1179039.9],
                [2619586.9, 1181988],
                [2621040.1, 1182066.4],
                [2623080.7, 1181253.7],
                [2622294.4, 1180161.5],
                [2621447.2, 1179241.8],
                [2619872.4, 1179039.9]
              ]
            ],
            [[[2624268.4, 1181658.6], [2624506.9, 1180402.6], [2623080.7, 1181253.7], [2624268.4, 1181658.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3624', ortbez27: 'Goldiwil (Thun)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619872.4, 1179039.9],
                [2616477.3, 1177985.3],
                [2615979.6, 1179439.2],
                [2617654.1, 1181820],
                [2617946.7, 1179914.8],
                [2619872.4, 1179039.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3629', ortbez27: 'Kiesen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611107.3, 1183023],
                [2608932.2, 1183877.8],
                [2609927.7, 1186120.7],
                [2611180.6, 1186642.9],
                [2612894, 1185553.3],
                [2614118.1, 1184109.3],
                [2611107.3, 1183023]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3627', ortbez27: 'Heimberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2614118.1, 1184109.3], [2613086.6, 1181625.7], [2611107.3, 1183023], [2614118.1, 1184109.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3628', ortbez27: 'Uttigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611107.3, 1183023],
                [2613086.6, 1181625.7],
                [2608840.6, 1181479.1],
                [2608932.2, 1183877.8],
                [2611107.3, 1183023]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3673', ortbez27: 'Linden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615724.7, 1189572.4],
                [2617877.6, 1190136],
                [2619152.4, 1190228.8],
                [2620781.8, 1190605.2],
                [2621363.2, 1188333.4],
                [2617479.7, 1187761.8],
                [2615724.7, 1189572.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3674', ortbez27: 'Bleiken b. Oberdiessbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616803.6, 1186145.8],
                [2615630.6, 1184233.6],
                [2614118.1, 1184109.3],
                [2615191.2, 1186153.6],
                [2616803.6, 1186145.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3671', ortbez27: 'Brenzikofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615191.2, 1186153.6],
                [2614118.1, 1184109.3],
                [2612894, 1185553.3],
                [2611180.6, 1186642.9],
                [2612588.5, 1187402.6],
                [2615191.2, 1186153.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3672', ortbez27: 'Oberdiessbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617479.7, 1187761.8],
                [2616803.6, 1186145.8],
                [2615191.2, 1186153.6],
                [2612588.5, 1187402.6],
                [2613510.9, 1189586.3],
                [2615724.7, 1189572.4],
                [2617479.7, 1187761.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3665', ortbez27: 'Wattenwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601684.5, 1179046.8],
                [2604182.8, 1181044.7],
                [2606386.5, 1181871.9],
                [2606232.8, 1180405.4],
                [2607487.9, 1179086.3],
                [2606345, 1178284.4],
                [2602938.3, 1175953.7],
                [2601109.7, 1177364.7],
                [2601684.5, 1179046.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3663', ortbez27: 'Gurzelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606386.5, 1181871.9],
                [2608840.6, 1181479.1],
                [2608726.4, 1179680.1],
                [2607487.9, 1179086.3],
                [2606232.8, 1180405.4],
                [2606386.5, 1181871.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3664', ortbez27: 'Burgistein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606386.5, 1181871.9],
                [2604182.8, 1181044.7],
                [2602961.8, 1182818.6],
                [2604446.8, 1184026.5],
                [2606721.3, 1184629.4],
                [2606860, 1183330.4],
                [2606386.5, 1181871.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3657', ortbez27: 'Schwanden (Sigriswil)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624506.9, 1180402.6],
                [2628287, 1177823.6],
                [2626702.8, 1174015.8],
                [2624680.6, 1174727],
                [2621606, 1175579],
                [2620620.5, 1174379.4],
                [2619491.5, 1175162.1],
                [2621065.6, 1178033.1],
                [2621447.2, 1179241.8],
                [2622294.4, 1180161.5],
                [2623080.7, 1181253.7],
                [2624506.9, 1180402.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3658', ortbez27: 'Merligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626702.8, 1174015.8],
                [2624568.6, 1170642.9],
                [2621753.4, 1173062.6],
                [2624680.6, 1174727],
                [2626702.8, 1174015.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3655', ortbez27: 'Sigriswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624680.6, 1174727],
                [2621753.4, 1173062.6],
                [2620620.5, 1174379.4],
                [2621606, 1175579],
                [2624680.6, 1174727]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3656', ortbez27: 'Ringoldswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2621065.6, 1178033.1], [2619491.5, 1175162.1], [2618371.9, 1177167.5], [2621065.6, 1178033.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3661', ortbez27: 'Uetendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608726.4, 1179680.1],
                [2608840.6, 1181479.1],
                [2613086.6, 1181625.7],
                [2614150.6, 1180965.6],
                [2613130.4, 1179719.8],
                [2611746.2, 1178460.1],
                [2608726.4, 1179680.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3662', ortbez27: 'Seftigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2608840.6, 1181479.1], [2606386.5, 1181871.9], [2606860, 1183330.4], [2608840.6, 1181479.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3713', ortbez27: 'Reichenbach im Kandertal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617079.5, 1161060.5],
                [2618021.1, 1164792.6],
                [2620458.2, 1165582.1],
                [2621350.8, 1163857.3],
                [2620051.2, 1163867.2],
                [2619505.7, 1162655.4],
                [2619834.6, 1161219.4],
                [2618824.8, 1159077.4],
                [2617079.5, 1161060.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3714', ortbez27: 'Wengi b. Frutigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618824.8, 1159077.4],
                [2619561.9, 1157461.9],
                [2617471.8, 1157953.3],
                [2615399.4, 1155601.9],
                [2613687.6, 1156176.9],
                [2611692.1, 1159069.2],
                [2613278.6, 1162911.8],
                [2613892.5, 1164301.6],
                [2615042.4, 1164897],
                [2615865.1, 1165873.6],
                [2617739, 1166130.2],
                [2618021.1, 1164792.6],
                [2617079.5, 1161060.5],
                [2618824.8, 1159077.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3711', ortbez27: 'Emdthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617739, 1166130.2],
                [2617840.7, 1168574.8],
                [2619289.5, 1168552],
                [2620458.2, 1165582.1],
                [2618021.1, 1164792.6],
                [2617739, 1166130.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3717', ortbez27: 'Blausee-Mitholz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622396.6, 1152275.5],
                [2615867.8, 1151026.8],
                [2615552.4, 1153832.5],
                [2617372.9, 1153851.7],
                [2620340.4, 1155421.2],
                [2622396.6, 1152275.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3718', ortbez27: 'Kandersteg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628582.8, 1149644.6],
                [2631161.6, 1147613.9],
                [2626029, 1143700.4],
                [2624046, 1142387.3],
                [2622968.9, 1141603.1],
                [2620560.4, 1140690],
                [2615272.7, 1143428.3],
                [2612177.4, 1140157.9],
                [2610653.2, 1140731.9],
                [2614598.3, 1149936.3],
                [2615867.8, 1151026.8],
                [2622396.6, 1152275.5],
                [2628582.8, 1149644.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3715', ortbez27: 'Adelboden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606684.6, 1151626],
                [2607743.7, 1153457.5],
                [2614598.3, 1149936.3],
                [2610653.2, 1140731.9],
                [2608938.5, 1140367.7],
                [2607523.8, 1139994.2],
                [2604439.3, 1143864.9],
                [2603971.2, 1149668.8],
                [2606684.6, 1151626]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3716', ortbez27: 'Kandergrund' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619561.9, 1157461.9],
                [2620340.4, 1155421.2],
                [2617372.9, 1153851.7],
                [2615552.4, 1153832.5],
                [2615399.4, 1155601.9],
                [2617471.8, 1157953.3],
                [2619561.9, 1157461.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3705', ortbez27: 'Faulensee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2620266.6, 1170522.7], [2623680.5, 1167945.4], [2620930, 1168390.5], [2620266.6, 1170522.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3706', ortbez27: 'Leissigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2627172.1, 1167861.2],
                [2628451.8, 1165157.3],
                [2627260.7, 1163642.3],
                [2625075.5, 1164714.7],
                [2623680.5, 1167945.4],
                [2627172.1, 1167861.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3703', ortbez27: 'Aeschiried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625075.5, 1164714.7],
                [2627260.7, 1163642.3],
                [2629069.8, 1160403.3],
                [2623518.2, 1159904],
                [2623998.6, 1162980.7],
                [2622913.8, 1164202.6],
                [2621350.8, 1163857.3],
                [2620458.2, 1165582.1],
                [2619289.5, 1168552],
                [2620930, 1168390.5],
                [2621494, 1167032.7],
                [2625075.5, 1164714.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3704', ortbez27: 'Krattigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623680.5, 1167945.4],
                [2625075.5, 1164714.7],
                [2621494, 1167032.7],
                [2620930, 1168390.5],
                [2623680.5, 1167945.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3707', ortbez27: 'D�rligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628451.8, 1165157.3],
                [2627172.1, 1167861.2],
                [2629690.4, 1168474.8],
                [2630084.5, 1167119.9],
                [2628451.8, 1165157.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3700', ortbez27: 'Spiez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620930, 1168390.5],
                [2619289.5, 1168552],
                [2617840.7, 1168574.8],
                [2614922.3, 1170820.7],
                [2614807.4, 1173292.8],
                [2617350.6, 1172233.8],
                [2619013.3, 1169827.6],
                [2620266.6, 1170522.7],
                [2620930, 1168390.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3722', ortbez27: 'Scharnachtal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623998.6, 1162980.7],
                [2619834.6, 1161219.4],
                [2619505.7, 1162655.4],
                [2620051.2, 1163867.2],
                [2621350.8, 1163857.3],
                [2622913.8, 1164202.6],
                [2623998.6, 1162980.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3725', ortbez27: 'Achseten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615399.4, 1155601.9],
                [2615552.4, 1153832.5],
                [2615867.8, 1151026.8],
                [2614598.3, 1149936.3],
                [2607743.7, 1153457.5],
                [2608368.8, 1156269.3],
                [2610481.1, 1156752.1],
                [2613687.6, 1156176.9],
                [2615399.4, 1155601.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3723', ortbez27: 'Kiental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629069.8, 1160403.3],
                [2629601, 1156628.8],
                [2628710.1, 1155249.9],
                [2629903.1, 1151346.6],
                [2628582.8, 1149644.6],
                [2622396.6, 1152275.5],
                [2620340.4, 1155421.2],
                [2619561.9, 1157461.9],
                [2618824.8, 1159077.4],
                [2619834.6, 1161219.4],
                [2623998.6, 1162980.7],
                [2623518.2, 1159904],
                [2629069.8, 1160403.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3724', ortbez27: 'Ried (Frutigen)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2610481.1, 1156752.1], [2611692.1, 1159069.2], [2613687.6, 1156176.9], [2610481.1, 1156752.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3777', ortbez27: 'Saanenm�ser' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592221.5, 1149625.4],
                [2590939.8, 1149105],
                [2589965.3, 1150228.5],
                [2589178.7, 1151228.6],
                [2588119.8, 1151894.4],
                [2587337.1, 1152894.6],
                [2587625.1, 1155436.8],
                [2588551.7, 1154552.3],
                [2591022.6, 1154357.4],
                [2591712.5, 1152774.8],
                [2592221.5, 1149625.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3778', ortbez27: 'Sch�nried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584613.3, 1155995.9],
                [2587625.1, 1155436.8],
                [2587337.1, 1152894.6],
                [2588119.8, 1151894.4],
                [2589178.7, 1151228.6],
                [2589965.3, 1150228.5],
                [2587858.5, 1149334.4],
                [2585221.5, 1150247],
                [2584613.3, 1155995.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3775', ortbez27: 'Lenk im Simmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607523.8, 1139994.2],
                [2609045.4, 1137658.2],
                [2604742.8, 1135530.8],
                [2603209.2, 1137239.2],
                [2601881.3, 1136256],
                [2600803, 1136934.4],
                [2599072.7, 1136747.4],
                [2595324.7, 1134841.1],
                [2595192.6, 1142020],
                [2596160.1, 1144334.1],
                [2597981.4, 1147965.9],
                [2603971.2, 1149668.8],
                [2604439.3, 1143864.9],
                [2607523.8, 1139994.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3776', ortbez27: 'Oeschseite' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595520.5, 1153425.6],
                [2592221.5, 1149625.4],
                [2591712.5, 1152774.8],
                [2591022.6, 1154357.4],
                [2595520.5, 1153425.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3781', ortbez27: 'Turbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595192.6, 1142020],
                [2591783.7, 1144147.3],
                [2590939.8, 1149105],
                [2592221.5, 1149625.4],
                [2593592.2, 1149033.2],
                [2596160.1, 1144334.1],
                [2595192.6, 1142020]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3782', ortbez27: 'Lauenen b. Gstaad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589288.6, 1141930.5],
                [2589736.6, 1144489],
                [2591783.7, 1144147.3],
                [2595192.6, 1142020],
                [2595324.7, 1134841.1],
                [2593939.5, 1133680.7],
                [2590602.4, 1132621.1],
                [2590195.5, 1136123.6],
                [2589278.4, 1139572.7],
                [2589288.6, 1141930.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3780', ortbez27: 'Gstaad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586037.1, 1144746.1],
                [2587858.5, 1149334.4],
                [2589965.3, 1150228.5],
                [2590939.8, 1149105],
                [2591783.7, 1144147.3],
                [2589736.6, 1144489],
                [2586037.1, 1144746.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3770', ortbez27: 'Zweisimmen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2595520.5, 1153425.6], [2593592.2, 1149033.2], [2592221.5, 1149625.4], [2595520.5, 1153425.6]]],
            [
              [
                [2595520.5, 1153425.6],
                [2591022.6, 1154357.4],
                [2596964.8, 1160636.6],
                [2599795.8, 1160900.1],
                [2601322.7, 1159187.7],
                [2600123.1, 1155493.9],
                [2595971.5, 1155647.2],
                [2595520.5, 1153425.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3773', ortbez27: 'Matten (St. Stephan)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2600685.8, 1153844.2],
                [2606684.6, 1151626],
                [2603971.2, 1149668.8],
                [2597981.4, 1147965.9],
                [2597544.9, 1150739.1],
                [2599145.7, 1151044.1],
                [2598309, 1152672.1],
                [2600685.8, 1153844.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3771', ortbez27: 'Blankenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2598309, 1152672.1],
                [2595520.5, 1153425.6],
                [2595971.5, 1155647.2],
                [2600123.1, 1155493.9],
                [2600685.8, 1153844.2],
                [2598309, 1152672.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3772', ortbez27: 'St. Stephan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2597981.4, 1147965.9],
                [2596160.1, 1144334.1],
                [2593592.2, 1149033.2],
                [2595520.5, 1153425.6],
                [2598309, 1152672.1],
                [2599145.7, 1151044.1],
                [2597544.9, 1150739.1],
                [2597981.4, 1147965.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3762', ortbez27: 'Erlenbach im Simmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610311.7, 1170598],
                [2610445.8, 1167821.9],
                [2610220.6, 1165773.2],
                [2608805.4, 1164314.1],
                [2607431.2, 1164364.6],
                [2602310.4, 1162691.2],
                [2605133, 1166192.4],
                [2605347.6, 1170997.4],
                [2607619.3, 1171565],
                [2610311.7, 1170598]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3765', ortbez27: 'Oberwil im Simmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602310.4, 1162691.2],
                [2599795.8, 1160900.1],
                [2598513.7, 1166229.8],
                [2595345.5, 1167323.5],
                [2595700.7, 1169195.2],
                [2597444.2, 1169234.7],
                [2598941.3, 1171989.8],
                [2600730.9, 1172289.7],
                [2602685.4, 1167599.5],
                [2602310.4, 1162691.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3766', ortbez27: 'Boltigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587625.1, 1155436.8],
                [2589391.4, 1158828.1],
                [2590872.6, 1160559.2],
                [2590691.6, 1162162.4],
                [2590386.6, 1163822.6],
                [2590506.6, 1165167.7],
                [2591734.7, 1165755.8],
                [2591844.7, 1167258],
                [2593099.9, 1167018],
                [2594227, 1166442.9],
                [2595345.5, 1167323.5],
                [2598513.7, 1166229.8],
                [2599795.8, 1160900.1],
                [2596964.8, 1160636.6],
                [2591022.6, 1154357.4],
                [2588551.7, 1154552.3],
                [2587625.1, 1155436.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3763', ortbez27: 'D�rstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2605133, 1166192.4], [2603290, 1172649.9], [2605347.6, 1170997.4], [2605133, 1166192.4]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3764', ortbez27: 'Weissenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605133, 1166192.4],
                [2602310.4, 1162691.2],
                [2602685.4, 1167599.5],
                [2600730.9, 1172289.7],
                [2601811.2, 1172934.1],
                [2603290, 1172649.9],
                [2605133, 1166192.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3753', ortbez27: 'Oey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613892.5, 1164301.6],
                [2610220.6, 1165773.2],
                [2610445.8, 1167821.9],
                [2612987.8, 1168732.7],
                [2615865.1, 1165873.6],
                [2615042.4, 1164897],
                [2613892.5, 1164301.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3752', ortbez27: 'Wimmis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614922.3, 1170820.7],
                [2617840.7, 1168574.8],
                [2617739, 1166130.2],
                [2615865.1, 1165873.6],
                [2612987.8, 1168732.7],
                [2613646.5, 1169800.8],
                [2614922.3, 1170820.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3757', ortbez27: 'Schwenden im Diemtigtal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601322.7, 1159187.7],
                [2608262.8, 1157840.7],
                [2608368.8, 1156269.3],
                [2607743.7, 1153457.5],
                [2606684.6, 1151626],
                [2600685.8, 1153844.2],
                [2600123.1, 1155493.9],
                [2601322.7, 1159187.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3758', ortbez27: 'Latterbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2610445.8, 1167821.9], [2610311.7, 1170598], [2612987.8, 1168732.7], [2610445.8, 1167821.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3755', ortbez27: 'Horboden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613892.5, 1164301.6],
                [2613278.6, 1162911.8],
                [2611692.1, 1159069.2],
                [2610481.1, 1156752.1],
                [2608368.8, 1156269.3],
                [2608262.8, 1157840.7],
                [2607213.5, 1162256.3],
                [2607431.2, 1164364.6],
                [2608805.4, 1164314.1],
                [2610220.6, 1165773.2],
                [2613892.5, 1164301.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3756', ortbez27: 'Zwischenfl�h' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599795.8, 1160900.1],
                [2602310.4, 1162691.2],
                [2607431.2, 1164364.6],
                [2607213.5, 1162256.3],
                [2608262.8, 1157840.7],
                [2601322.7, 1159187.7],
                [2599795.8, 1160900.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3807', ortbez27: 'Iseltwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640376.4, 1170758.8],
                [2638325.4, 1172216.3],
                [2643913.5, 1176035.5],
                [2643992.5, 1169893.2],
                [2641376.3, 1169034.4],
                [2642053.8, 1171090.7],
                [2640376.4, 1170758.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3813', ortbez27: 'Saxeten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2627260.7, 1163642.3],
                [2628451.8, 1165157.3],
                [2630084.5, 1167119.9],
                [2632041.4, 1166123.7],
                [2631794.4, 1163670],
                [2629069.8, 1160403.3],
                [2627260.7, 1163642.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3814', ortbez27: 'Gsteigwiler' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2635988.4, 1167400.6], [2635491.5, 1165832], [2632877.2, 1167736.1], [2635988.4, 1167400.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3812', ortbez27: 'Wilderswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635425, 1164033.9],
                [2631794.4, 1163670],
                [2632041.4, 1166123.7],
                [2630084.5, 1167119.9],
                [2629690.4, 1168474.8],
                [2631112, 1168543.5],
                [2632877.2, 1167736.1],
                [2635491.5, 1165832],
                [2635425, 1164033.9]
              ]
            ],
            [
              [
                [2635988.4, 1167400.6],
                [2632877.2, 1167736.1],
                [2633833.3, 1169108.1],
                [2635070.8, 1169466.4],
                [2635988.4, 1167400.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3800', ortbez27: 'Unterseen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634459.6, 1171523.9],
                [2635070.8, 1169466.4],
                [2633833.3, 1169108.1],
                [2632877.2, 1167736.1],
                [2631112, 1168543.5],
                [2629690.4, 1168474.8],
                [2627783.4, 1170445.9],
                [2630806.4, 1174342.6],
                [2633980.5, 1174271.2],
                [2632357.4, 1172781.4],
                [2634459.6, 1171523.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3806', ortbez27: 'B�nigen b. Interlaken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634459.6, 1171523.9],
                [2638325.4, 1172216.3],
                [2640376.4, 1170758.8],
                [2635988.4, 1167400.6],
                [2635070.8, 1169466.4],
                [2634459.6, 1171523.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3803', ortbez27: 'Beatenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624568.6, 1170642.9],
                [2626702.8, 1174015.8],
                [2628287, 1177823.6],
                [2629316.5, 1178918.2],
                [2631801.7, 1179013.3],
                [2630036.1, 1177051.5],
                [2630806.4, 1174342.6],
                [2627783.4, 1170445.9],
                [2624568.6, 1170642.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3804', ortbez27: 'Habkern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639177.4, 1183438.6],
                [2637119.9, 1176542.4],
                [2635818.7, 1175098.3],
                [2633980.5, 1174271.2],
                [2630806.4, 1174342.6],
                [2630036.1, 1177051.5],
                [2631801.7, 1179013.3],
                [2632779.5, 1181839.2],
                [2639177.4, 1183438.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3792', ortbez27: 'Saanen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586037.1, 1144746.1],
                [2583665.4, 1143822.3],
                [2585221.5, 1150247],
                [2587858.5, 1149334.4],
                [2586037.1, 1144746.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3785', ortbez27: 'Gsteig b. Gstaad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2583350.6, 1131036],
                [2582554.4, 1135296.5],
                [2585338.2, 1138677.9],
                [2589278.4, 1139572.7],
                [2590195.5, 1136123.6],
                [2585751.4, 1131127.3],
                [2583350.6, 1131036]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3783', ortbez27: 'Grund b. Gstaad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589736.6, 1144489],
                [2589288.6, 1141930.5],
                [2582479.9, 1140703.5],
                [2581343.3, 1143421.7],
                [2583665.4, 1143822.3],
                [2586037.1, 1144746.1],
                [2589736.6, 1144489]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3784', ortbez27: 'Feutersoey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581226.1, 1136564.3],
                [2582479.9, 1140703.5],
                [2589288.6, 1141930.5],
                [2589278.4, 1139572.7],
                [2585338.2, 1138677.9],
                [2582554.4, 1135296.5],
                [2581226.1, 1136564.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3825', ortbez27: 'M�rren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629601, 1156628.8],
                [2635087.5, 1162591.6],
                [2636410, 1158637.9],
                [2635687.5, 1157598.9],
                [2635183.5, 1155956.3],
                [2633965, 1155290.2],
                [2632262.9, 1155842.6],
                [2630816.3, 1154684.6],
                [2628710.1, 1155249.9],
                [2629601, 1156628.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3826', ortbez27: 'Gimmelwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629903.1, 1151346.6],
                [2628710.1, 1155249.9],
                [2630816.3, 1154684.6],
                [2632262.9, 1155842.6],
                [2633965, 1155290.2],
                [2634568, 1153072.4],
                [2629903.1, 1151346.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3823', ortbez27: 'Wengen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635425, 1164033.9],
                [2638575.2, 1162613.5],
                [2640324.6, 1159460.6],
                [2641277.3, 1158509.6],
                [2643520.5, 1158778.8],
                [2642925.9, 1156647.1],
                [2641690.8, 1155497],
                [2640342.6, 1154554.9],
                [2637323.8, 1155966.2],
                [2635687.5, 1157598.9],
                [2636410, 1158637.9],
                [2635087.5, 1162591.6],
                [2635425, 1164033.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3824', ortbez27: 'Stechelberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631161.6, 1147613.9],
                [2628582.8, 1149644.6],
                [2629903.1, 1151346.6],
                [2634568, 1153072.4],
                [2633965, 1155290.2],
                [2635183.5, 1155956.3],
                [2635687.5, 1157598.9],
                [2637323.8, 1155966.2],
                [2640342.6, 1154554.9],
                [2640762.7, 1153344.7],
                [2639597.6, 1151019.4],
                [2638010.4, 1149914.3],
                [2632467.6, 1147649.8],
                [2631161.6, 1147613.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3818', ortbez27: 'Grindelwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641487.5, 1167266.4],
                [2641376.3, 1169034.4],
                [2643992.5, 1169893.2],
                [2645798.6, 1169187.8],
                [2648701.7, 1171636.1],
                [2653920.8, 1167542.6],
                [2652616.1, 1165244.8],
                [2653901.4, 1162793.2],
                [2651757.6, 1160725.2],
                [2653203.1, 1156268.9],
                [2651708.8, 1155452],
                [2650372.1, 1155845],
                [2649155.1, 1156266.7],
                [2647892.5, 1156070.9],
                [2646626, 1156494.6],
                [2642925.9, 1156647.1],
                [2643520.5, 1158778.8],
                [2641277.3, 1158509.6],
                [2640324.6, 1159460.6],
                [2638575.2, 1162613.5],
                [2639834.2, 1163616.6],
                [2641388.9, 1163964.6],
                [2642450.6, 1165170.2],
                [2641487.5, 1167266.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3815', ortbez27: 'G�ndlischwand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638575.2, 1162613.5],
                [2635425, 1164033.9],
                [2635491.5, 1165832],
                [2635988.4, 1167400.6],
                [2640376.4, 1170758.8],
                [2642053.8, 1171090.7],
                [2641376.3, 1169034.4],
                [2637418, 1166011.8],
                [2638575.2, 1162613.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3816', ortbez27: 'L�tschental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641487.5, 1167266.4],
                [2642450.6, 1165170.2],
                [2641388.9, 1163964.6],
                [2639834.2, 1163616.6],
                [2638575.2, 1162613.5],
                [2637418, 1166011.8],
                [2641376.3, 1169034.4],
                [2641487.5, 1167266.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3822', ortbez27: 'Isenfluh' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631794.4, 1163670],
                [2635425, 1164033.9],
                [2635087.5, 1162591.6],
                [2629601, 1156628.8],
                [2629069.8, 1160403.3],
                [2631794.4, 1163670]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3863', ortbez27: 'Gadmen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673241.6, 1179959.7],
                [2677211.8, 1179778.7],
                [2677726.9, 1173939.9],
                [2677558.2, 1171441.3],
                [2673586.8, 1171889],
                [2674456.5, 1167459.4],
                [2671545.2, 1165301.2],
                [2666625.4, 1171741],
                [2665384.2, 1171978.9],
                [2663968.7, 1175267.8],
                [2673241.6, 1179959.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3864', ortbez27: 'Guttannen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667113.5, 1156206.9],
                [2664878.6, 1154599.9],
                [2662948.4, 1153596.8],
                [2661343.2, 1153294.7],
                [2657734.9, 1151814.5],
                [2656488.7, 1153196.7],
                [2651708.8, 1155452],
                [2653203.1, 1156268.9],
                [2651757.6, 1160725.2],
                [2653901.4, 1162793.2],
                [2654889.1, 1161936.5],
                [2655905.3, 1161198.8],
                [2656941.6, 1160361.5],
                [2658113.4, 1159889.8],
                [2659370.9, 1159854.9],
                [2660631, 1159797.5],
                [2661752.1, 1160587.6],
                [2661389, 1161794.7],
                [2662097.1, 1162828.9],
                [2662678.1, 1163941.6],
                [2662837.8, 1165222.9],
                [2661991.3, 1166198.8],
                [2661137.6, 1167138.6],
                [2660585.5, 1168261.5],
                [2661001.1, 1169626.3],
                [2662205, 1170011.4],
                [2663323.7, 1170601.4],
                [2664221.8, 1171497],
                [2665384.2, 1171978.9],
                [2666625.4, 1171741],
                [2671545.2, 1165301.2],
                [2671110.3, 1162209.9],
                [2671058.7, 1159583.8],
                [2669852, 1157945.7],
                [2667113.5, 1156206.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3857', ortbez27: 'Unterbach BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649851.2, 1175533],
                [2650036.1, 1177518.8],
                [2652420.4, 1178188.7],
                [2655303.5, 1175905],
                [2654500.7, 1174937.3],
                [2649851.2, 1175533]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3858', ortbez27: 'Hofstetten b. Brienz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2650036.1, 1177518.8],
                [2648013.2, 1178020.9],
                [2648211.4, 1182280.3],
                [2649817.9, 1182181.7],
                [2650770.8, 1180650.6],
                [2650036.1, 1177518.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3855', ortbez27: 'Brienz BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643913.5, 1176035.5],
                [2641620.1, 1177679.4],
                [2641773.6, 1180713.4],
                [2646526.9, 1182096.5],
                [2648211.4, 1182280.3],
                [2648013.2, 1178020.9],
                [2650036.1, 1177518.8],
                [2649851.2, 1175533],
                [2647114.5, 1173207],
                [2648701.7, 1171636.1],
                [2645798.6, 1169187.8],
                [2643992.5, 1169893.2],
                [2643913.5, 1176035.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3856', ortbez27: 'Brienzwiler' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2650770.8, 1180650.6],
                [2652262, 1180282.4],
                [2652420.4, 1178188.7],
                [2650036.1, 1177518.8],
                [2650770.8, 1180650.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3862', ortbez27: 'Innertkirchen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659970.6, 1174578.1],
                [2663968.7, 1175267.8],
                [2665384.2, 1171978.9],
                [2664221.8, 1171497],
                [2663323.7, 1170601.4],
                [2662205, 1170011.4],
                [2661001.1, 1169626.3],
                [2660585.5, 1168261.5],
                [2661137.6, 1167138.6],
                [2661991.3, 1166198.8],
                [2662837.8, 1165222.9],
                [2662678.1, 1163941.6],
                [2662097.1, 1162828.9],
                [2661389, 1161794.7],
                [2661752.1, 1160587.6],
                [2660631, 1159797.5],
                [2659370.9, 1159854.9],
                [2658113.4, 1159889.8],
                [2656941.6, 1160361.5],
                [2655905.3, 1161198.8],
                [2654889.1, 1161936.5],
                [2653901.4, 1162793.2],
                [2652616.1, 1165244.8],
                [2653920.8, 1167542.6],
                [2655563.3, 1167076.3],
                [2659319.6, 1171788],
                [2659970.6, 1174578.1]
              ]
            ],
            [
              [
                [2671114.8, 1182427.5],
                [2673241.6, 1179959.7],
                [2663968.7, 1175267.8],
                [2664631.4, 1178490.1],
                [2667085.4, 1180399.9],
                [2671114.8, 1182427.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3860', ortbez27: 'Meiringen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2654500.7, 1174937.3],
                [2655303.5, 1175905],
                [2652420.4, 1178188.7],
                [2652262, 1180282.4],
                [2654150.6, 1178619.8],
                [2657545.5, 1176100.3],
                [2659970.6, 1174578.1],
                [2659319.6, 1171788],
                [2655563.3, 1167076.3],
                [2653920.8, 1167542.6],
                [2648701.7, 1171636.1],
                [2647114.5, 1173207],
                [2649851.2, 1175533],
                [2654500.7, 1174937.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3853', ortbez27: 'Niederried b. Interlaken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638658.9, 1175013],
                [2636868.2, 1173459.1],
                [2635818.7, 1175098.3],
                [2637119.9, 1176542.4],
                [2638658.9, 1175013]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8001', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682749.8, 1249087.4],
                [2684318.8, 1247925.1],
                [2683439.2, 1246556.1],
                [2681988.6, 1246640.3],
                [2682749.8, 1249087.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3854', ortbez27: 'Oberried am Brienzersee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641620.1, 1177679.4],
                [2638658.9, 1175013],
                [2637119.9, 1176542.4],
                [2639177.4, 1183438.6],
                [2641551.2, 1181996.9],
                [2641773.6, 1180713.4],
                [2641620.1, 1177679.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3852', ortbez27: 'Ringgenberg BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636868.2, 1173459.1],
                [2634459.6, 1171523.9],
                [2632357.4, 1172781.4],
                [2633980.5, 1174271.2],
                [2635818.7, 1175098.3],
                [2636868.2, 1173459.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3905', ortbez27: 'Saas-Almagell' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634545.5, 1096940.1],
                [2635372.6, 1099635.4],
                [2639238.6, 1104459.5],
                [2638728.5, 1106666.8],
                [2644710, 1108551.2],
                [2646177.5, 1105792.5],
                [2644521.5, 1095899.8],
                [2642727.2, 1094103.8],
                [2636495.7, 1094174.8],
                [2634545.5, 1096940.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8005', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2682749.8, 1249087.4], [2680461.2, 1248529.8], [2680547.8, 1249941.7], [2682749.8, 1249087.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3906', ortbez27: 'Saas-Fee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635372.6, 1099635.4],
                [2632457.1, 1103768.9],
                [2632970.2, 1106614.2],
                [2634142.3, 1107914.3],
                [2638472, 1108336.8],
                [2638728.5, 1106666.8],
                [2639238.6, 1104459.5],
                [2635372.6, 1099635.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8006', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2685421.4, 1249753.1], [2684318.8, 1247925.1], [2682749.8, 1249087.4], [2685421.4, 1249753.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3903', ortbez27: 'Mund' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640433, 1133802.7],
                [2642404, 1129651.2],
                [2640385.5, 1128282.2],
                [2639552.8, 1129269.2],
                [2638708.7, 1128257.9],
                [2636522.4, 1127774],
                [2634486.1, 1132270.1],
                [2636236.1, 1140296.6],
                [2638583.1, 1139632.8],
                [2638864.3, 1135888.4],
                [2640433, 1133802.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3904', ortbez27: 'Naters' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643225.3, 1132725.2],
                [2644309.5, 1131217.3],
                [2643561, 1130141.8],
                [2642404, 1129651.2],
                [2640433, 1133802.7],
                [2642967.9, 1134146.7],
                [2643225.3, 1132725.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8004', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2681988.6, 1246640.3], [2680461.2, 1248529.8], [2682749.8, 1249087.4], [2681988.6, 1246640.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3910', ortbez27: 'Saas-Grund' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638728.5, 1106666.8],
                [2638472, 1108336.8],
                [2638527.9, 1110604.7],
                [2643684.3, 1113246.2],
                [2644710, 1108551.2],
                [2638728.5, 1106666.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3907', ortbez27: 'Simplon Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649936.2, 1121701.7],
                [2651208, 1122358.1],
                [2656205.2, 1114926.3],
                [2655455.4, 1111073.1],
                [2651849.3, 1107038.3],
                [2646177.5, 1105792.5],
                [2644710, 1108551.2],
                [2643684.3, 1113246.2],
                [2643061.4, 1114501.6],
                [2642076.5, 1123531.6],
                [2645937.7, 1124508.2],
                [2649936.2, 1121701.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3908', ortbez27: 'Saas-Balen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643061.4, 1114501.6],
                [2643684.3, 1113246.2],
                [2638527.9, 1110604.7],
                [2638472, 1108336.8],
                [2634142.3, 1107914.3],
                [2634207.2, 1109526.5],
                [2637957.8, 1114057.1],
                [2643061.4, 1114501.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3901', ortbez27: 'Rothwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649936.2, 1121701.7],
                [2645937.7, 1124508.2],
                [2644623.3, 1126986.7],
                [2648053.8, 1128206.9],
                [2651277.2, 1129544.1],
                [2654194.1, 1128238.3],
                [2649562.3, 1123585.2],
                [2651208, 1122358.1],
                [2649936.2, 1121701.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3902', ortbez27: 'Glis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642178, 1128086.5],
                [2640385.5, 1128282.2],
                [2642404, 1129651.2],
                [2643561, 1130141.8],
                [2642178, 1128086.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3900', ortbez27: 'Brig' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2645937.7, 1124508.2],
                [2642076.5, 1123531.6],
                [2637976, 1125978.7],
                [2636522.4, 1127774],
                [2638708.7, 1128257.9],
                [2639552.8, 1129269.2],
                [2640385.5, 1128282.2],
                [2642178, 1128086.5],
                [2643561, 1130141.8],
                [2643377.4, 1128505.4],
                [2644623.3, 1126986.7],
                [2645937.7, 1124508.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3937', ortbez27: 'Baltschieder' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2629937.6, 1128578.2], [2634135, 1129856.9], [2633984.2, 1128420], [2629937.6, 1128578.2]]],
            [
              [
                [2634486.1, 1132270.1],
                [2632209.8, 1133957.3],
                [2631729.7, 1138137.8],
                [2635077, 1141084.7],
                [2636236.1, 1140296.6],
                [2634486.1, 1132270.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8032', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684318.8, 1247925.1],
                [2686581.8, 1247242.1],
                [2686791.8, 1245453.1],
                [2685348.8, 1244768.9],
                [2683439.2, 1246556.1],
                [2684318.8, 1247925.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8037', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2682749.8, 1249087.4], [2680547.8, 1249941.7], [2682355.8, 1251212.7], [2682749.8, 1249087.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3938', ortbez27: 'Ausserberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629376.8, 1130267.1],
                [2632209.8, 1133957.3],
                [2634486.1, 1132270.1],
                [2634135, 1129856.9],
                [2629937.6, 1128578.2],
                [2628707.8, 1129151.2],
                [2629376.8, 1130267.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8038', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682024.5, 1242217.1],
                [2682045, 1245343.4],
                [2681988.6, 1246640.3],
                [2683439.2, 1246556.1],
                [2683546.3, 1243469.6],
                [2682024.5, 1242217.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3935', ortbez27: 'B�rchen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631634.8, 1124641.4],
                [2627491.8, 1120636.1],
                [2627920.7, 1127818.4],
                [2630722.1, 1126364.1],
                [2631634.8, 1124641.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3942', ortbez27: 'Raron' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629937.6, 1128578.2],
                [2630722.1, 1126364.1],
                [2627920.7, 1127818.4],
                [2623652.8, 1128439.3],
                [2624265.9, 1129768.7],
                [2625507.1, 1129447.8],
                [2624719, 1132254],
                [2625801.1, 1132994.1],
                [2626919.2, 1134532.3],
                [2627341.2, 1135717.5],
                [2628694.7, 1136569.7],
                [2630156.7, 1137927.8],
                [2631729.7, 1138137.8],
                [2632209.8, 1133957.3],
                [2629376.8, 1130267.1],
                [2628707.8, 1129151.2],
                [2629937.6, 1128578.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3939', ortbez27: 'Eggerberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634486.1, 1132270.1],
                [2636522.4, 1127774],
                [2633984.2, 1128420],
                [2634135, 1129856.9],
                [2634486.1, 1132270.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3940', ortbez27: 'Steg VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624719, 1132254],
                [2624529.3, 1133504.3],
                [2624167.4, 1135279.3],
                [2625326, 1135862.5],
                [2626919.2, 1134532.3],
                [2625801.1, 1132994.1],
                [2624719, 1132254]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3929', ortbez27: 'T�sch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635372.6, 1099635.4],
                [2634545.5, 1096940.1],
                [2631020.1, 1096485.1],
                [2627561.7, 1099803.4],
                [2619538.5, 1101640.6],
                [2632457.1, 1103768.9],
                [2635372.6, 1099635.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3930', ortbez27: 'Visp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630722.1, 1126364.1],
                [2629937.6, 1128578.2],
                [2633984.2, 1128420],
                [2636522.4, 1127774],
                [2637976, 1125978.7],
                [2635581.6, 1126470.8],
                [2634185.9, 1124622.8],
                [2631634.8, 1124641.4],
                [2630722.1, 1126364.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3927', ortbez27: 'Herbriggen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2623277.9, 1112751.2], [2631750, 1107789.3], [2623818.2, 1108450.6], [2623277.9, 1112751.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3928', ortbez27: 'Randa' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631750, 1107789.3],
                [2632970.2, 1106614.2],
                [2632457.1, 1103768.9],
                [2619538.5, 1101640.6],
                [2621120.1, 1106755.9],
                [2623818.2, 1108450.6],
                [2631750, 1107789.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3933', ortbez27: 'Staldenried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638950.8, 1116687.5],
                [2634340.6, 1118509],
                [2633847.5, 1119661.3],
                [2634950.7, 1120253.3],
                [2636220.9, 1120543.6],
                [2638950.8, 1116687.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3934', ortbez27: 'Zeneggen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632737.3, 1123141.6],
                [2631634.8, 1124641.4],
                [2634185.9, 1124622.8],
                [2634042, 1123173.6],
                [2632737.3, 1123141.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3932', ortbez27: 'Visperterminen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642076.5, 1123531.6],
                [2643061.4, 1114501.6],
                [2638950.8, 1116687.5],
                [2636220.9, 1120543.6],
                [2634950.7, 1120253.3],
                [2634042, 1123173.6],
                [2634185.9, 1124622.8],
                [2635581.6, 1126470.8],
                [2637976, 1125978.7],
                [2642076.5, 1123531.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3922', ortbez27: 'Stalden VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634340.6, 1118509],
                [2638950.8, 1116687.5],
                [2643061.4, 1114501.6],
                [2637957.8, 1114057.1],
                [2634207.2, 1109526.5],
                [2632592, 1113775.2],
                [2634340.6, 1118509]
              ]
            ],
            [
              [
                [2631642.3, 1118986.2],
                [2632737.3, 1123141.6],
                [2634042, 1123173.6],
                [2634950.7, 1120253.3],
                [2633847.5, 1119661.3],
                [2631642.3, 1118986.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3919', ortbez27: 'Blatten (L�tschen)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638010.4, 1149914.3],
                [2640549.7, 1146575.1],
                [2635077, 1141084.7],
                [2631729.7, 1138137.8],
                [2630156.7, 1137927.8],
                [2626029, 1143700.4],
                [2631161.6, 1147613.9],
                [2632467.6, 1147649.8],
                [2638010.4, 1149914.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3920', ortbez27: 'Zermatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610641.1, 1093018.1],
                [2613492.9, 1098233.1],
                [2618598.5, 1099108.3],
                [2619538.5, 1101640.6],
                [2627561.7, 1099803.4],
                [2631020.1, 1096485.1],
                [2634545.5, 1096940.1],
                [2636495.7, 1094174.8],
                [2634088.3, 1091595.1],
                [2633088.1, 1085240],
                [2628078.1, 1085256.6],
                [2624050.5, 1087894.6],
                [2621942.1, 1085972.2],
                [2617564.3, 1091724.9],
                [2611701.8, 1091137.7],
                [2610641.1, 1093018.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3925', ortbez27: 'Gr�chen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634340.6, 1118509],
                [2632592, 1113775.2],
                [2630041.8, 1115120.4],
                [2630746.4, 1118082.5],
                [2631642.3, 1118986.2],
                [2633847.5, 1119661.3],
                [2634340.6, 1118509]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3926', ortbez27: 'Embd' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624431.2, 1117176.9],
                [2625061.5, 1119145.8],
                [2627491.8, 1120636.1],
                [2630746.4, 1118082.5],
                [2624431.2, 1117176.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3923', ortbez27: 'T�rbel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632737.3, 1123141.6],
                [2631642.3, 1118986.2],
                [2630746.4, 1118082.5],
                [2627491.8, 1120636.1],
                [2631634.8, 1124641.4],
                [2632737.3, 1123141.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3924', ortbez27: 'St. Niklaus VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623277.9, 1112751.2],
                [2624431.2, 1117176.9],
                [2630746.4, 1118082.5],
                [2630041.8, 1115120.4],
                [2632592, 1113775.2],
                [2634207.2, 1109526.5],
                [2634142.3, 1107914.3],
                [2632970.2, 1106614.2],
                [2631750, 1107789.3],
                [2623277.9, 1112751.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3913', ortbez27: 'Rosswald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647508.7, 1129627.1],
                [2649690.1, 1130844.9],
                [2651277.2, 1129544.1],
                [2648053.8, 1128206.9],
                [2647508.7, 1129627.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8008', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2685348.8, 1244768.9], [2683546.3, 1243469.6], [2683439.2, 1246556.1], [2685348.8, 1244768.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3914', ortbez27: 'Belalp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643541.9, 1136144.9],
                [2642967.9, 1134146.7],
                [2640433, 1133802.7],
                [2638864.3, 1135888.4],
                [2638583.1, 1139632.8],
                [2636236.1, 1140296.6],
                [2635077, 1141084.7],
                [2640549.7, 1146575.1],
                [2642569.7, 1146376.6],
                [2647416.5, 1140418.1],
                [2643541.9, 1136144.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3911', ortbez27: 'Ried-Brig' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2648053.8, 1128206.9],
                [2644623.3, 1126986.7],
                [2643377.4, 1128505.4],
                [2643561, 1130141.8],
                [2644309.5, 1131217.3],
                [2647508.7, 1129627.1],
                [2648053.8, 1128206.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3912', ortbez27: 'Termen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649690.1, 1130844.9],
                [2647508.7, 1129627.1],
                [2644309.5, 1131217.3],
                [2645210.1, 1132296],
                [2646076.5, 1133239.3],
                [2649059.2, 1132672.7],
                [2649690.1, 1130844.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3917', ortbez27: 'Kippel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622968.9, 1141603.1],
                [2624046, 1142387.3],
                [2628694.7, 1136569.7],
                [2627341.2, 1135717.5],
                [2626919.2, 1134532.3],
                [2625326, 1135862.5],
                [2622968.9, 1141603.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3918', ortbez27: 'Wiler (L�tschen)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626029, 1143700.4],
                [2630156.7, 1137927.8],
                [2628694.7, 1136569.7],
                [2624046, 1142387.3],
                [2626029, 1143700.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3916', ortbez27: 'Ferden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619613.2, 1137509.6],
                [2620560.4, 1140690],
                [2622968.9, 1141603.1],
                [2625326, 1135862.5],
                [2624167.4, 1135279.3],
                [2621143.4, 1134594.3],
                [2619613.2, 1137509.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3970', ortbez27: 'Salgesch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611491, 1129095],
                [2612222.4, 1125261.1],
                [2609356.9, 1128552.5],
                [2610606.6, 1132460.6],
                [2612152.4, 1132943.9],
                [2611889.3, 1131708.8],
                [2611602.3, 1130481.6],
                [2611491, 1129095]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3967', ortbez27: 'Vercorin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609895.2, 1122856.4],
                [2607263.9, 1118390.4],
                [2605023.6, 1122144.3],
                [2608007.5, 1124563.2],
                [2609001, 1123799.6],
                [2609895.2, 1122856.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3968', ortbez27: 'Veyras' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2609356.9, 1128552.5], [2607245.9, 1127690.2], [2607516.2, 1130026.3], [2609356.9, 1128552.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3973', ortbez27: 'Venth�ne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607245.9, 1127690.2],
                [2604839.5, 1127047.8],
                [2605722.4, 1128159.9],
                [2605846, 1130058.8],
                [2607516.2, 1130026.3],
                [2607245.9, 1127690.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8057', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682355.8, 1251212.7],
                [2684413.7, 1251059.2],
                [2685421.4, 1249753.1],
                [2682749.8, 1249087.4],
                [2682355.8, 1251212.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3971', ortbez27: 'Chermignon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602733.9, 1124842.5],
                [2601814.4, 1126880.8],
                [2603211.5, 1126978.5],
                [2604839.5, 1127047.8],
                [2604683.5, 1125443.6],
                [2602733.9, 1124842.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8055', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2680461.2, 1248529.8],
                [2681988.6, 1246640.3],
                [2682045, 1245343.4],
                [2680134.8, 1244466.8],
                [2679135, 1245355.7],
                [2678130.4, 1244603.7],
                [2678071.1, 1246294.7],
                [2678014.6, 1247697.2],
                [2680461.2, 1248529.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3972', ortbez27: 'Mi�ge' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610606.6, 1132460.6],
                [2609356.9, 1128552.5],
                [2607516.2, 1130026.3],
                [2608314.3, 1132004.1],
                [2609331.6, 1133912.6],
                [2610606.6, 1132460.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3961', ortbez27: 'Grimentz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613492.9, 1098233.1],
                [2613202.8, 1100533.4],
                [2609562.2, 1105254.1],
                [2608662.1, 1106441.2],
                [2606817, 1109784.3],
                [2606038.2, 1112889.3],
                [2607463.7, 1116085.6],
                [2607263.9, 1118390.4],
                [2609895.2, 1122856.4],
                [2609001, 1123799.6],
                [2609690.9, 1124924.4],
                [2612222.4, 1125261.1],
                [2613765.1, 1123656.3],
                [2615821.8, 1121970.8],
                [2616664.4, 1121007.6],
                [2617045.9, 1115297.4],
                [2618254.5, 1109317.7],
                [2621120.1, 1106755.9],
                [2619538.5, 1101640.6],
                [2618598.5, 1099108.3],
                [2613492.9, 1098233.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3960', ortbez27: 'Sierre' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609356.9, 1128552.5],
                [2612222.4, 1125261.1],
                [2609690.9, 1124924.4],
                [2608007.5, 1124563.2],
                [2605776, 1124727.4],
                [2604683.5, 1125443.6],
                [2604839.5, 1127047.8],
                [2607245.9, 1127690.2],
                [2609356.9, 1128552.5]
              ]
            ],
            [[[2605722.4, 1128159.9], [2604839.5, 1127047.8], [2604102, 1128088.2], [2605722.4, 1128159.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3965', ortbez27: 'Chippis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2609690.9, 1124924.4], [2609001, 1123799.6], [2608007.5, 1124563.2], [2609690.9, 1124924.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8049', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678425.6, 1252823.4],
                [2682355.8, 1251212.7],
                [2680547.8, 1249941.7],
                [2677612, 1250482],
                [2677233.8, 1252391.8],
                [2678425.6, 1252823.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3966', ortbez27: 'R�chy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605776, 1124727.4],
                [2608007.5, 1124563.2],
                [2605023.6, 1122144.3],
                [2603252.2, 1123257.3],
                [2602733.9, 1124842.5],
                [2604683.5, 1125443.6],
                [2605776, 1124727.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8050', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682355.8, 1251212.7],
                [2682588.5, 1252532.3],
                [2684415.8, 1253700.5],
                [2685467.3, 1252812.2],
                [2684413.7, 1251059.2],
                [2682355.8, 1251212.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3963', ortbez27: 'Crans-Montana' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603211.5, 1126978.5],
                [2601814.4, 1126880.8],
                [2604742.8, 1135530.8],
                [2609045.4, 1137658.2],
                [2612042.4, 1137107.5],
                [2610779.3, 1135049.3],
                [2609331.6, 1133912.6],
                [2608314.3, 1132004.1],
                [2607516.2, 1130026.3],
                [2605846, 1130058.8],
                [2605722.4, 1128159.9],
                [2604102, 1128088.2],
                [2604839.5, 1127047.8],
                [2603211.5, 1126978.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8048', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678014.6, 1247697.2],
                [2676304.7, 1248355.7],
                [2677612, 1250482],
                [2680547.8, 1249941.7],
                [2680461.2, 1248529.8],
                [2678014.6, 1247697.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3953', ortbez27: 'Inden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618107.1, 1127662.2],
                [2614209.6, 1129472.6],
                [2611491, 1129095],
                [2611602.3, 1130481.6],
                [2611889.3, 1131708.8],
                [2612152.4, 1132943.9],
                [2610606.6, 1132460.6],
                [2609331.6, 1133912.6],
                [2610779.3, 1135049.3],
                [2612042.4, 1137107.5],
                [2613939.9, 1134512.1],
                [2613134.5, 1132128.8],
                [2614376.1, 1131596.8],
                [2616858.2, 1132203.9],
                [2618985.2, 1128565.8],
                [2618107.1, 1127662.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3954', ortbez27: 'Leukerbad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609045.4, 1137658.2],
                [2607523.8, 1139994.2],
                [2608938.5, 1140367.7],
                [2610653.2, 1140731.9],
                [2612177.4, 1140157.9],
                [2615272.7, 1143428.3],
                [2620560.4, 1140690],
                [2619613.2, 1137509.6],
                [2617940, 1136254.4],
                [2613939.9, 1134512.1],
                [2612042.4, 1137107.5],
                [2609045.4, 1137658.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8053', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686791.8, 1245453.1],
                [2686581.8, 1247242.1],
                [2687938.9, 1247574],
                [2689137.4, 1247041.1],
                [2689651.4, 1245759.5],
                [2688271.4, 1245558.8],
                [2686791.8, 1245453.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8051', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687528.6, 1251374.3],
                [2687288.3, 1249060.6],
                [2685421.4, 1249753.1],
                [2684413.7, 1251059.2],
                [2685467.3, 1252812.2],
                [2687528.6, 1251374.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3952', ortbez27: 'Susten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612222.4, 1125261.1],
                [2611491, 1129095],
                [2614209.6, 1129472.6],
                [2618107.1, 1127662.2],
                [2618503.2, 1126372.1],
                [2615821.8, 1121970.8],
                [2613765.1, 1123656.3],
                [2612222.4, 1125261.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8052', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2682588.5, 1252532.3], [2682014, 1254112.4], [2684415.8, 1253700.5], [2682588.5, 1252532.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3957', ortbez27: 'Erschmatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618985.2, 1128565.8],
                [2619613.2, 1137509.6],
                [2621143.4, 1134594.3],
                [2620605, 1130518.6],
                [2618985.2, 1128565.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8041', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682045, 1245343.4],
                [2682024.5, 1242217.1],
                [2680561.8, 1241736.1],
                [2680134.8, 1244466.8],
                [2682045, 1245343.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3955', ortbez27: 'Albinen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613939.9, 1134512.1],
                [2617940, 1136254.4],
                [2616858.2, 1132203.9],
                [2614376.1, 1131596.8],
                [2613134.5, 1132128.8],
                [2613939.9, 1134512.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3956', ortbez27: 'Guttet-Feschel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616858.2, 1132203.9],
                [2617940, 1136254.4],
                [2619613.2, 1137509.6],
                [2618985.2, 1128565.8],
                [2616858.2, 1132203.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3945', ortbez27: 'Niedergampel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2621368, 1128887.5],
                [2618985.2, 1128565.8],
                [2620605, 1130518.6],
                [2621143.4, 1134594.3],
                [2624167.4, 1135279.3],
                [2624529.3, 1133504.3],
                [2624719, 1132254],
                [2624225.9, 1131023.9],
                [2624265.9, 1129768.7],
                [2623652.8, 1128439.3],
                [2622434.8, 1127926.2],
                [2621368, 1128887.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3946', ortbez27: 'Gruben' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620885.4, 1118000.6],
                [2624431.2, 1117176.9],
                [2623277.9, 1112751.2],
                [2623818.2, 1108450.6],
                [2621120.1, 1106755.9],
                [2618254.5, 1109317.7],
                [2617045.9, 1115297.4],
                [2616664.4, 1121007.6],
                [2615821.8, 1121970.8],
                [2618503.2, 1126372.1],
                [2620456.5, 1125078.3],
                [2620698.6, 1123663.8],
                [2621190.8, 1122497.6],
                [2620860.8, 1121019.8],
                [2620842.1, 1119467.1],
                [2620885.4, 1118000.6]
              ]
            ],
            [
              [
                [2618985.2, 1128565.8],
                [2621368, 1128887.5],
                [2620110.8, 1127149.2],
                [2618503.2, 1126372.1],
                [2618107.1, 1127662.2],
                [2618985.2, 1128565.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8046', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682014, 1254112.4],
                [2682588.5, 1252532.3],
                [2682355.8, 1251212.7],
                [2678425.6, 1252823.4],
                [2679023.5, 1253953.4],
                [2680257.7, 1255372.7],
                [2682014, 1254112.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3943', ortbez27: 'Eischoll' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623652.8, 1128439.3],
                [2627920.7, 1127818.4],
                [2623809.5, 1122859.9],
                [2623593.4, 1126272.4],
                [2622434.8, 1127926.2],
                [2623652.8, 1128439.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3944', ortbez27: 'Unterb�ch VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2627491.8, 1120636.1],
                [2625061.5, 1119145.8],
                [2623809.5, 1122859.9],
                [2627920.7, 1127818.4],
                [2627491.8, 1120636.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8044', ortbez27: 'Z�rich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684318.8, 1247925.1],
                [2685421.4, 1249753.1],
                [2687288.3, 1249060.6],
                [2687938.9, 1247574],
                [2686581.8, 1247242.1],
                [2684318.8, 1247925.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3949', ortbez27: 'Hohtenn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624265.9, 1129768.7],
                [2624225.9, 1131023.9],
                [2624719, 1132254],
                [2625507.1, 1129447.8],
                [2624265.9, 1129768.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3947', ortbez27: 'Ergisch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625061.5, 1119145.8],
                [2624431.2, 1117176.9],
                [2620885.4, 1118000.6],
                [2620842.1, 1119467.1],
                [2620860.8, 1121019.8],
                [2621190.8, 1122497.6],
                [2620698.6, 1123663.8],
                [2620456.5, 1125078.3],
                [2620110.8, 1127149.2],
                [2621368, 1128887.5],
                [2622434.8, 1127926.2],
                [2623593.4, 1126272.4],
                [2623809.5, 1122859.9],
                [2625061.5, 1119145.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3948', ortbez27: 'Unterems' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2620456.5, 1125078.3], [2618503.2, 1126372.1], [2620110.8, 1127149.2], [2620456.5, 1125078.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8102', ortbez27: 'Oberengstringen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2675956.6, 1251456.9], [2677233.8, 1252391.8], [2677612, 1250482], [2675956.6, 1251456.9]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3999', ortbez27: 'Oberwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670609.3, 1152304.6],
                [2667113.5, 1156206.9],
                [2669852, 1157945.7],
                [2671058.7, 1159583.8],
                [2671110.3, 1162209.9],
                [2671545.2, 1165301.2],
                [2674456.5, 1167459.4],
                [2675417.4, 1164616.6],
                [2674842.5, 1162166.2],
                [2676044.9, 1155310],
                [2679797.5, 1153594.8],
                [2677182.2, 1149986.4],
                [2673294.7, 1149854.4],
                [2670609.3, 1152304.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3993', ortbez27: 'Grengiols' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659199.7, 1128768.8],
                [2654194.1, 1128238.3],
                [2651277.2, 1129544.1],
                [2649690.1, 1130844.9],
                [2649059.2, 1132672.7],
                [2648242.1, 1133734.8],
                [2647540.9, 1134864.9],
                [2648585.2, 1135611.2],
                [2650043.9, 1137452.5],
                [2651386.2, 1137462.5],
                [2652907.3, 1138140.1],
                [2654164.6, 1137149.9],
                [2655219.7, 1135664.8],
                [2659199.7, 1128768.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3994', ortbez27: 'Lax' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649266, 1140422.9],
                [2652907.3, 1138140.1],
                [2651386.2, 1137462.5],
                [2650043.9, 1137452.5],
                [2649266, 1140422.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3992', ortbez27: 'Bettmeralp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2650411, 1142212.6],
                [2649266, 1140422.9],
                [2650043.9, 1137452.5],
                [2648585.2, 1135611.2],
                [2646426.8, 1136071.3],
                [2647416.5, 1140418.1],
                [2642569.7, 1146376.6],
                [2644813.3, 1147689.1],
                [2650411, 1142212.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3997', ortbez27: 'Bellwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656964.8, 1144005.7],
                [2655937.8, 1141623.4],
                [2654691.7, 1140625.2],
                [2653462.4, 1146255.9],
                [2655539.6, 1147581.1],
                [2656964.8, 1144005.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3998', ortbez27: 'Reckingen VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667006, 1143618.7],
                [2667445.8, 1141881.4],
                [2665532.2, 1140188.1],
                [2663998.7, 1140388.4],
                [2662283.5, 1141618.5],
                [2662495.5, 1142910.6],
                [2662368.4, 1144223.8],
                [2660057.9, 1145610.6],
                [2659338.1, 1146644],
                [2659218, 1149739.3],
                [2667006, 1143618.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3995', ortbez27: 'M�hlebach (Goms)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657379.9, 1137298],
                [2655219.7, 1135664.8],
                [2654164.6, 1137149.9],
                [2652907.3, 1138140.1],
                [2654691.7, 1140625.2],
                [2655937.8, 1141623.4],
                [2656964.8, 1144005.7],
                [2659275.3, 1140283.7],
                [2661593.4, 1140565.4],
                [2662283.5, 1141618.5],
                [2663998.7, 1140388.4],
                [2659813.2, 1138058.1],
                [2657379.9, 1137298]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3996', ortbez27: 'Binn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659199.7, 1128768.8],
                [2655219.7, 1135664.8],
                [2657379.9, 1137298],
                [2659813.2, 1138058.1],
                [2663998.7, 1140388.4],
                [2665532.2, 1140188.1],
                [2667535.6, 1139140],
                [2667345, 1136703],
                [2663580.3, 1135212.1],
                [2659199.7, 1128768.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3985', ortbez27: 'M�nster VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667451, 1146606],
                [2667006, 1143618.7],
                [2659218, 1149739.3],
                [2657734.9, 1151814.5],
                [2661343.2, 1153294.7],
                [2662948.4, 1153596.8],
                [2665725.7, 1150120.1],
                [2667130.7, 1151228.8],
                [2669269.2, 1150169.4],
                [2670501.4, 1148344.2],
                [2667451, 1146606]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3986', ortbez27: 'Ried-M�rel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2646076.5, 1133239.3], [2645210.1, 1132296], [2644543.8, 1133456.7], [2646076.5, 1133239.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3983', ortbez27: 'Filet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649059.2, 1132672.7],
                [2646076.5, 1133239.3],
                [2646128.8, 1134730.7],
                [2646426.8, 1136071.3],
                [2648585.2, 1135611.2],
                [2647540.9, 1134864.9],
                [2648242.1, 1133734.8],
                [2649059.2, 1132672.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3984', ortbez27: 'Fieschertal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2654691.7, 1140625.2],
                [2652907.3, 1138140.1],
                [2649266, 1140422.9],
                [2650411, 1142212.6],
                [2644813.3, 1147689.1],
                [2642569.7, 1146376.6],
                [2640549.7, 1146575.1],
                [2638010.4, 1149914.3],
                [2639597.6, 1151019.4],
                [2640762.7, 1153344.7],
                [2640342.6, 1154554.9],
                [2641690.8, 1155497],
                [2642925.9, 1156647.1],
                [2646626, 1156494.6],
                [2647892.5, 1156070.9],
                [2649155.1, 1156266.7],
                [2650372.1, 1155845],
                [2651708.8, 1155452],
                [2656488.7, 1153196.7],
                [2657734.9, 1151814.5],
                [2655989.7, 1150164.4],
                [2655539.6, 1147581.1],
                [2653462.4, 1146255.9],
                [2654691.7, 1140625.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3989', ortbez27: 'Biel VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659275.3, 1140283.7],
                [2656964.8, 1144005.7],
                [2655539.6, 1147581.1],
                [2655989.7, 1150164.4],
                [2657734.9, 1151814.5],
                [2659218, 1149739.3],
                [2659338.1, 1146644],
                [2660057.9, 1145610.6],
                [2662368.4, 1144223.8],
                [2662495.5, 1142910.6],
                [2662283.5, 1141618.5],
                [2661593.4, 1140565.4],
                [2659275.3, 1140283.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3987', ortbez27: 'Riederalp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644543.8, 1133456.7],
                [2643225.3, 1132725.2],
                [2642967.9, 1134146.7],
                [2643541.9, 1136144.9],
                [2647416.5, 1140418.1],
                [2646426.8, 1136071.3],
                [2646128.8, 1134730.7],
                [2646076.5, 1133239.3],
                [2644543.8, 1133456.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3988', ortbez27: 'Obergesteln' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670609.3, 1152304.6],
                [2673294.7, 1149854.4],
                [2672869.7, 1147881.1],
                [2672801.7, 1146611],
                [2672770, 1145114.9],
                [2671404.1, 1145072.2],
                [2670418.2, 1144301.3],
                [2669576.6, 1143358.9],
                [2668631.5, 1142485.6],
                [2667445.8, 1141881.4],
                [2667006, 1143618.7],
                [2667451, 1146606],
                [2670501.4, 1148344.2],
                [2669269.2, 1150169.4],
                [2667130.7, 1151228.8],
                [2665725.7, 1150120.1],
                [2662948.4, 1153596.8],
                [2664878.6, 1154599.9],
                [2667113.5, 1156206.9],
                [2670609.3, 1152304.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3977', ortbez27: 'Granges VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602733.9, 1124842.5],
                [2603252.2, 1123257.3],
                [2598820.3, 1121280.7],
                [2600474.6, 1123600.4],
                [2602733.9, 1124842.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3982', ortbez27: 'Bitsch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644309.5, 1131217.3],
                [2643225.3, 1132725.2],
                [2644543.8, 1133456.7],
                [2645210.1, 1132296],
                [2644309.5, 1131217.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3979', ortbez27: 'Gr�ne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605023.6, 1122144.3],
                [2607263.9, 1118390.4],
                [2607463.7, 1116085.6],
                [2602996.5, 1117352.2],
                [2598820.3, 1121280.7],
                [2603252.2, 1123257.3],
                [2605023.6, 1122144.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8127', ortbez27: 'Forch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689026.8, 1240765.4],
                [2689007.2, 1242486.9],
                [2690272.9, 1242118.7],
                [2691362, 1242975.7],
                [2693399, 1241290.6],
                [2692968.1, 1239861.5],
                [2691667.4, 1239298.5],
                [2690284.7, 1240602],
                [2689026.8, 1240765.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8133', ortbez27: 'Esslingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2696618.6, 1239989.6], [2697658.8, 1239222], [2696392.5, 1237630.9], [2696618.6, 1239989.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8134', ortbez27: 'Adliswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683546.3, 1243469.6],
                [2683751.9, 1240734],
                [2682828.7, 1239755.3],
                [2682152.6, 1238651.2],
                [2681071.9, 1240357.8],
                [2680561.8, 1241736.1],
                [2682024.5, 1242217.1],
                [2683546.3, 1243469.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8132', ortbez27: 'Hinteregg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694265.2, 1237938.5],
                [2692968.1, 1239861.5],
                [2693399, 1241290.6],
                [2694953.4, 1243135.2],
                [2695490.8, 1240921.4],
                [2696618.6, 1239989.6],
                [2696392.5, 1237630.9],
                [2695294.7, 1236691.4],
                [2694265.2, 1237938.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8125', ortbez27: 'Zollikerberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689657.7, 1244429.1],
                [2687547.7, 1243121.8],
                [2686791.8, 1245453.1],
                [2688271.4, 1245558.8],
                [2689651.4, 1245759.5],
                [2689657.7, 1244429.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8126', ortbez27: 'Zumikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687547.7, 1243121.8],
                [2689657.7, 1244429.1],
                [2691151.4, 1244443.2],
                [2691362, 1242975.7],
                [2690272.9, 1242118.7],
                [2689007.2, 1242486.9],
                [2687547.7, 1243121.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8123', ortbez27: 'Ebmatingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691944.6, 1246104.3],
                [2691151.4, 1244443.2],
                [2689657.7, 1244429.1],
                [2689651.4, 1245759.5],
                [2690435.4, 1246898.1],
                [2691944.6, 1246104.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8124', ortbez27: 'Maur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693875.6, 1245290.6],
                [2694953.4, 1243135.2],
                [2693399, 1241290.6],
                [2691362, 1242975.7],
                [2691151.4, 1244443.2],
                [2691944.6, 1246104.3],
                [2693875.6, 1245290.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8113', ortbez27: 'Boppelsen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673525.6, 1257311.1],
                [2671843.4, 1258644.9],
                [2672995.8, 1259651.4],
                [2674358.4, 1259684.4],
                [2674569.2, 1258182.1],
                [2673525.6, 1257311.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8114', ortbez27: 'D�nikon ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2674957.6, 1256009.5], [2673747.4, 1255642.4], [2672416.2, 1256251.1], [2674957.6, 1256009.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8112', ortbez27: 'Otelfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672416.2, 1256251.1],
                [2670090.4, 1257786.9],
                [2671843.4, 1258644.9],
                [2673525.6, 1257311.1],
                [2672416.2, 1256251.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8117', ortbez27: 'F�llanden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692349.4, 1247394.9],
                [2691944.6, 1246104.3],
                [2690435.4, 1246898.1],
                [2689137.4, 1247041.1],
                [2687938.9, 1247574],
                [2689274, 1248404.4],
                [2690979, 1248678.4],
                [2692291.2, 1249153.9],
                [2692349.4, 1247394.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8118', ortbez27: 'Pfaffhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2689651.4, 1245759.5], [2689137.4, 1247041.1], [2690435.4, 1246898.1], [2689651.4, 1245759.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8115', ortbez27: 'H�ttikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2671680.5, 1253603.4],
                [2672416.2, 1256251.1],
                [2673747.4, 1255642.4],
                [2673810.1, 1254365.3],
                [2672968.9, 1253026.4],
                [2671680.5, 1253603.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8105', ortbez27: 'Regensdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676283.3, 1256365.7],
                [2677741.7, 1255583.7],
                [2678959.2, 1256752.3],
                [2680257.7, 1255372.7],
                [2679023.5, 1253953.4],
                [2678425.6, 1252823.4],
                [2677233.8, 1252391.8],
                [2675968.9, 1253690.9],
                [2674957.6, 1256009.5],
                [2676283.3, 1256365.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8106', ortbez27: 'Adlikon b. Regensdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2677741.7, 1255583.7],
                [2676283.3, 1256365.7],
                [2677709.4, 1257194.9],
                [2678959.2, 1256752.3],
                [2677741.7, 1255583.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8104', ortbez27: 'Weiningen ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2675956.6, 1251456.9],
                [2672968.9, 1253026.4],
                [2673810.1, 1254365.3],
                [2675968.9, 1253690.9],
                [2677233.8, 1252391.8],
                [2675956.6, 1251456.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8107', ortbez27: 'Buchs ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673525.6, 1257311.1],
                [2674569.2, 1258182.1],
                [2676143.6, 1258016.5],
                [2677709.4, 1257194.9],
                [2676283.3, 1256365.7],
                [2674957.6, 1256009.5],
                [2672416.2, 1256251.1],
                [2673525.6, 1257311.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8108', ortbez27: 'D�llikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2675968.9, 1253690.9],
                [2673810.1, 1254365.3],
                [2673747.4, 1255642.4],
                [2674957.6, 1256009.5],
                [2675968.9, 1253690.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8162', ortbez27: 'Steinmaur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2677208.1, 1263469.2],
                [2678067.5, 1260969.2],
                [2675651.4, 1259978.4],
                [2674739.9, 1261732],
                [2673449.3, 1264606.9],
                [2675768.6, 1263289.1],
                [2676367.8, 1264571.5],
                [2677208.1, 1263469.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8165', ortbez27: 'Schleinikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2671061.7, 1261452.7],
                [2672586.5, 1263158.9],
                [2671883.5, 1264413.6],
                [2673449.3, 1264606.9],
                [2674739.9, 1261732],
                [2675651.4, 1259978.4],
                [2674358.4, 1259684.4],
                [2672995.8, 1259651.4],
                [2671843.4, 1258644.9],
                [2670992.7, 1259562],
                [2671061.7, 1261452.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8166', ortbez27: 'Niederweningen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2669418.9, 1261701.9],
                [2671883.5, 1264413.6],
                [2672586.5, 1263158.9],
                [2671061.7, 1261452.7],
                [2669418.9, 1261701.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8164', ortbez27: 'Bachs' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673449.3, 1264606.9],
                [2672895.2, 1266038],
                [2674108.4, 1266780.5],
                [2675053.1, 1265818.4],
                [2676367.8, 1264571.5],
                [2675768.6, 1263289.1],
                [2673449.3, 1264606.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8153', ortbez27: 'R�mlang' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683958.8, 1258330.2],
                [2683953.7, 1255523],
                [2684415.8, 1253700.5],
                [2682014, 1254112.4],
                [2680257.7, 1255372.7],
                [2681185.1, 1257168.8],
                [2683958.8, 1258330.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8154', ortbez27: 'Oberglatt ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683018.3, 1261102.4],
                [2683958.8, 1258330.2],
                [2681185.1, 1257168.8],
                [2680585.8, 1258429.3],
                [2680029.1, 1259566.4],
                [2681736.8, 1261637.1],
                [2683018.3, 1261102.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8152', ortbez27: 'Glattbrugg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685785.4, 1255145.3],
                [2687915.9, 1255071.7],
                [2687951.9, 1253767.1],
                [2685467.3, 1252812.2],
                [2684415.8, 1253700.5],
                [2683953.7, 1255523],
                [2685785.4, 1255145.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4057', ortbez27: 'Basel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2613994.6, 1269593.3], [2611376.2, 1267295.4], [2611060.9, 1269588.8], [2613994.6, 1269593.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8157', ortbez27: 'Dielsdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2675651.4, 1259978.4],
                [2678067.5, 1260969.2],
                [2677930.8, 1258774.2],
                [2677709.4, 1257194.9],
                [2676143.6, 1258016.5],
                [2674569.2, 1258182.1],
                [2674358.4, 1259684.4],
                [2675651.4, 1259978.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4058', ortbez27: 'Basel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2611376.2, 1267295.4], [2613994.6, 1269593.3], [2614672.2, 1268038.6], [2611376.2, 1267295.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4056', ortbez27: 'Basel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2608805.2, 1268344.5], [2611060.9, 1269588.8], [2611376.2, 1267295.4], [2608805.2, 1268344.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8155', ortbez27: 'Niederhasli' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679414.7, 1261635.4],
                [2680029.1, 1259566.4],
                [2680585.8, 1258429.3],
                [2678959.2, 1256752.3],
                [2677709.4, 1257194.9],
                [2677930.8, 1258774.2],
                [2678067.5, 1260969.2],
                [2679414.7, 1261635.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8156', ortbez27: 'Oberhasli' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681185.1, 1257168.8],
                [2680257.7, 1255372.7],
                [2678959.2, 1256752.3],
                [2680585.8, 1258429.3],
                [2681185.1, 1257168.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4059', ortbez27: 'Basel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610472.7, 1266069.8],
                [2613081.7, 1265136.9],
                [2611835.4, 1263338.1],
                [2610740.9, 1264433],
                [2610472.7, 1266069.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8143', ortbez27: 'Stallikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2680887.3, 1237914.2],
                [2679054, 1239149.6],
                [2679231.4, 1240645.9],
                [2679094.7, 1242117.4],
                [2678130.4, 1244603.7],
                [2679135, 1245355.7],
                [2680134.8, 1244466.8],
                [2680561.8, 1241736.1],
                [2681071.9, 1240357.8],
                [2682152.6, 1238651.2],
                [2680887.3, 1237914.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4053', ortbez27: 'Basel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2613081.7, 1265136.9], [2610472.7, 1266069.8], [2611376.2, 1267295.4], [2613081.7, 1265136.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4054', ortbez27: 'Basel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610472.7, 1266069.8],
                [2608894.8, 1266117.7],
                [2608805.2, 1268344.5],
                [2611376.2, 1267295.4],
                [2610472.7, 1266069.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8135', ortbez27: 'Langnau am Albis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2685783.8, 1233490.1], [2685373, 1231988.6], [2684493.3, 1233344.8], [2685783.8, 1233490.1]]],
            [
              [
                [2683758.4, 1237844.7],
                [2684514.4, 1236534.5],
                [2684950.2, 1235334.6],
                [2684493.3, 1233344.8],
                [2683696.4, 1234317.9],
                [2682989.5, 1235357.5],
                [2681770.5, 1235903.6],
                [2680485, 1236444.8],
                [2680887.3, 1237914.2],
                [2682152.6, 1238651.2],
                [2682828.7, 1239755.3],
                [2684024.6, 1239085.2],
                [2683758.4, 1237844.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4052', ortbez27: 'Basel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2613081.7, 1265136.9], [2611376.2, 1267295.4], [2614672.2, 1268038.6], [2613081.7, 1265136.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8136', ortbez27: 'Gattikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2684514.4, 1236534.5], [2683758.4, 1237844.7], [2685628.4, 1237273.4], [2684514.4, 1236534.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8142', ortbez27: 'Uitikon Waldegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676304.7, 1248355.7],
                [2678014.6, 1247697.2],
                [2678071.1, 1246294.7],
                [2675377.4, 1247125.6],
                [2676304.7, 1248355.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8193', ortbez27: 'Eglisau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683803.9, 1267380],
                [2682038.4, 1268510.1],
                [2680326.7, 1269722.8],
                [2678720.8, 1270276.7],
                [2678926.4, 1271636.6],
                [2681050, 1272123.6],
                [2682715, 1271491.9],
                [2683385.2, 1269693.3],
                [2683803.9, 1267380]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8194', ortbez27: 'H�ntwangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2678926.4, 1271636.6], [2678753.7, 1273361.3], [2681050, 1272123.6], [2678926.4, 1271636.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8192', ortbez27: 'Glattfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2677307.2, 1268533],
                [2678720.8, 1270276.7],
                [2680326.7, 1269722.8],
                [2682038.4, 1268510.1],
                [2681722.5, 1265759.2],
                [2679675.8, 1266015.4],
                [2677307.2, 1268533]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8197', ortbez27: 'Rafz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684885.1, 1273588.6],
                [2682715, 1271491.9],
                [2681050, 1272123.6],
                [2682140.8, 1273103.4],
                [2681539.4, 1274580.7],
                [2681171.3, 1276368.4],
                [2682457, 1276356.6],
                [2683547.9, 1275696.6],
                [2684571.2, 1274863.3],
                [2684885.1, 1273588.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8195', ortbez27: 'Wasterkingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2678753.7, 1273361.3], [2678926.4, 1271636.6], [2676948.8, 1271509.8], [2678753.7, 1273361.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8196', ortbez27: 'Wil ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678753.7, 1273361.3],
                [2681171.3, 1276368.4],
                [2681539.4, 1274580.7],
                [2682140.8, 1273103.4],
                [2681050, 1272123.6],
                [2678753.7, 1273361.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8185', ortbez27: 'Winkel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683958.8, 1258330.2],
                [2683018.3, 1261102.4],
                [2685339, 1262134],
                [2685614.1, 1260872.6],
                [2685802.8, 1259454.8],
                [2683958.8, 1258330.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4102', ortbez27: 'Binningen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610740.9, 1264433],
                [2609521.2, 1263596.2],
                [2608287, 1264969.3],
                [2608894.8, 1266117.7],
                [2610472.7, 1266069.8],
                [2610740.9, 1264433]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: 'null', ortbez27: null },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719263, 1091284.1],
                [2717908.7, 1091637.5],
                [2717637.2, 1092936.5],
                [2718673.3, 1094639.9],
                [2719288.4, 1092816.4],
                [2719263, 1091284.1]
              ]
            ],
            [
              [
                [2581744.2, 1129234.8],
                [2583350.6, 1131036],
                [2585751.4, 1131127.3],
                [2590195.5, 1136123.6],
                [2590602.4, 1132621.1],
                [2593939.5, 1133680.7],
                [2593936.7, 1132264],
                [2594852.3, 1123728.3],
                [2594542.2, 1122132.9],
                [2591164.2, 1120724.6],
                [2590170.5, 1122032.3],
                [2589685.9, 1123648.8],
                [2590896.7, 1124073],
                [2591504.7, 1127566.2],
                [2587743.9, 1129036.3],
                [2581744.2, 1129234.8]
              ]
            ],
            [
              [
                [2753586.6, 1168884.1],
                [2752951.2, 1165689.3],
                [2751325.4, 1166185.7],
                [2751963.1, 1167951.7],
                [2753586.6, 1168884.1]
              ]
            ],
            [
              [
                [2745862.9, 1167217.3],
                [2743872.2, 1171448.6],
                [2744808.5, 1172918.6],
                [2747558.1, 1169105.2],
                [2745862.9, 1167217.3]
              ]
            ],
            [
              [
                [2621753.4, 1173062.6],
                [2624568.6, 1170642.9],
                [2627783.4, 1170445.9],
                [2629690.4, 1168474.8],
                [2627172.1, 1167861.2],
                [2623680.5, 1167945.4],
                [2620266.6, 1170522.7],
                [2619013.3, 1169827.6],
                [2617350.6, 1172233.8],
                [2615503.5, 1174593.5],
                [2614834, 1175901.6],
                [2615345.5, 1177237.3],
                [2616953.9, 1175901.6],
                [2619491.5, 1175162.1],
                [2620620.5, 1174379.4],
                [2621753.4, 1173062.6]
              ]
            ],
            [
              [
                [2638658.9, 1175013],
                [2641620.1, 1177679.4],
                [2643913.5, 1176035.5],
                [2638325.4, 1172216.3],
                [2634459.6, 1171523.9],
                [2636868.2, 1173459.1],
                [2638658.9, 1175013]
              ]
            ],
            [
              [
                [2744611.7, 1180575.5],
                [2743219, 1184073.6],
                [2745704.5, 1183882.7],
                [2746700, 1181262.8],
                [2744611.7, 1180575.5]
              ]
            ],
            [
              [
                [2572889.9, 1210915.3],
                [2573404.7, 1212286.9],
                [2576647.1, 1214536.2],
                [2578181.5, 1215627.3],
                [2581186.6, 1217806.8],
                [2583216, 1219680.9],
                [2582085.7, 1215661.6],
                [2581117.5, 1213701.6],
                [2579987.3, 1212792.2],
                [2577737.1, 1210516.7],
                [2576384.4, 1210184.8],
                [2575415.7, 1211205.5],
                [2574133.5, 1211239],
                [2572889.9, 1210915.3]
              ]
            ],
            [
              [
                [2692085.6, 1285634.4],
                [2694603.5, 1285810.4],
                [2696879.2, 1283413.7],
                [2694896.9, 1283312.2],
                [2692497.4, 1282381.7],
                [2691395.2, 1283239],
                [2691445.1, 1284505.3],
                [2692085.6, 1285634.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8184', ortbez27: 'Bachenb�lach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2685339, 1262134], [2683018.3, 1261102.4], [2683450.6, 1262745.1], [2685339, 1262134]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8187', ortbez27: 'Weiach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673843.5, 1268890.3],
                [2677307.2, 1268533],
                [2676289.6, 1266691.6],
                [2675053.1, 1265818.4],
                [2674108.4, 1266780.5],
                [2673843.5, 1268890.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8175', ortbez27: 'Windlach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2677307.2, 1268533], [2679675.8, 1266015.4], [2676289.6, 1266691.6], [2677307.2, 1268533]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8181', ortbez27: 'H�ri' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681736.8, 1261637.1],
                [2679414.7, 1261635.4],
                [2679937.5, 1263732.6],
                [2681416.5, 1264424.7],
                [2681565.4, 1263129.2],
                [2681736.8, 1261637.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8182', ortbez27: 'Hochfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679675.8, 1266015.4],
                [2681722.5, 1265759.2],
                [2681416.5, 1264424.7],
                [2679937.5, 1263732.6],
                [2679296.5, 1264805.8],
                [2679675.8, 1266015.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8180', ortbez27: 'B�lach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682038.4, 1268510.1],
                [2683803.9, 1267380],
                [2684841, 1266680.6],
                [2686177.6, 1265016.6],
                [2685691.5, 1263860.4],
                [2685339, 1262134],
                [2683450.6, 1262745.1],
                [2683018.3, 1261102.4],
                [2681736.8, 1261637.1],
                [2681565.4, 1263129.2],
                [2681416.5, 1264424.7],
                [2681722.5, 1265759.2],
                [2682038.4, 1268510.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8173', ortbez27: 'Neerach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678067.5, 1260969.2],
                [2677208.1, 1263469.2],
                [2679937.5, 1263732.6],
                [2679414.7, 1261635.4],
                [2678067.5, 1260969.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8174', ortbez27: 'Stadel b. Niederglatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676289.6, 1266691.6],
                [2679675.8, 1266015.4],
                [2679296.5, 1264805.8],
                [2679937.5, 1263732.6],
                [2677208.1, 1263469.2],
                [2676367.8, 1264571.5],
                [2675053.1, 1265818.4],
                [2676289.6, 1266691.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8172', ortbez27: 'Niederglatt ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2679414.7, 1261635.4], [2681736.8, 1261637.1], [2680029.1, 1259566.4], [2679414.7, 1261635.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8225', ortbez27: 'Siblingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681751.2, 1284485],
                [2678228, 1286811.8],
                [2683325.9, 1289301],
                [2683743.3, 1287637.2],
                [2683016.6, 1286336.6],
                [2681751.2, 1284485]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8226', ortbez27: 'Schleitheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678228, 1286811.8],
                [2676534.8, 1286367.4],
                [2677666.3, 1291086.5],
                [2680554.9, 1290597.6],
                [2683325.9, 1289301],
                [2678228, 1286811.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8224', ortbez27: 'L�hningen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2683120.8, 1282963.3], [2681751.2, 1284485], [2683016.6, 1286336.6], [2683120.8, 1282963.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4127', ortbez27: 'Birsfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617301.8, 1265245.6],
                [2613081.7, 1265136.9],
                [2614672.2, 1268038.6],
                [2616181.3, 1268072.7],
                [2617301.8, 1265245.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4133', ortbez27: 'Pratteln' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2621138.6, 1263334.7],
                [2617399.9, 1259677],
                [2617301.8, 1265245.6],
                [2618764.8, 1264826.5],
                [2621138.6, 1263334.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8228', ortbez27: 'Beggingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685422.5, 1293124],
                [2684844.1, 1289938.1],
                [2683325.9, 1289301],
                [2680554.9, 1290597.6],
                [2681157.9, 1291824.2],
                [2685422.5, 1293124]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8217', ortbez27: 'Wilchingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673882, 1282075.8],
                [2677000.9, 1282002.9],
                [2678135.5, 1279794.5],
                [2679327.9, 1278055.2],
                [2677750.8, 1277103.7],
                [2675975.2, 1278883.8],
                [2674798, 1279552.3],
                [2673882, 1282075.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8218', ortbez27: 'Osterfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681833, 1279591.9],
                [2679327.9, 1278055.2],
                [2678135.5, 1279794.5],
                [2679221.4, 1280502.2],
                [2681833, 1279591.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8215', ortbez27: 'Hallau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673882, 1282075.8],
                [2676534.8, 1286367.4],
                [2678242.5, 1283316.1],
                [2677000.9, 1282002.9],
                [2673882, 1282075.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4132', ortbez27: 'Muttenz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615792.9, 1261122.4],
                [2613081.7, 1265136.9],
                [2617301.8, 1265245.6],
                [2617399.9, 1259677],
                [2615792.9, 1261122.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8216', ortbez27: 'Oberhallau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2678242.5, 1283316.1], [2676534.8, 1286367.4], [2678228, 1286811.8], [2678242.5, 1283316.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8222', ortbez27: 'Beringen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683120.8, 1282963.3],
                [2683016.6, 1286336.6],
                [2683743.3, 1287637.2],
                [2686149.3, 1286951.2],
                [2687741.9, 1283443.2],
                [2684580.2, 1280696.7],
                [2683335.6, 1280350.4],
                [2683120.8, 1282963.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8219', ortbez27: 'Trasadingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673882, 1282075.8],
                [2674798, 1279552.3],
                [2673579.2, 1280146.3],
                [2672734, 1281145.1],
                [2673882, 1282075.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4125', ortbez27: 'Riehen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614672.2, 1268038.6],
                [2613994.6, 1269593.3],
                [2614778.9, 1270746.8],
                [2615551.2, 1271847.7],
                [2616852, 1271624.6],
                [2618207.1, 1271924],
                [2617745, 1270629.4],
                [2618522.7, 1269619.8],
                [2617873.5, 1268482.9],
                [2616181.3, 1268072.7],
                [2614672.2, 1268038.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4123', ortbez27: 'Allschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608287, 1264969.3],
                [2607025.3, 1264169.1],
                [2605883.7, 1264847.6],
                [2605006.7, 1265980.6],
                [2608805.2, 1268344.5],
                [2608894.8, 1266117.7],
                [2608287, 1264969.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8207', ortbez27: 'Schaffhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690186.4, 1287749.7],
                [2693376.7, 1288133.5],
                [2696070.1, 1286609.7],
                [2694603.5, 1285810.4],
                [2692085.6, 1285634.4],
                [2690186.4, 1287749.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4114', ortbez27: 'Hofstetten SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607014.3, 1256869.4],
                [2603534.1, 1256424.1],
                [2603748.2, 1257745.8],
                [2603868.4, 1259276.2],
                [2605372.9, 1259529.4],
                [2607464.8, 1259523.5],
                [2607014.3, 1256869.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8213', ortbez27: 'Neunkirch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681751.2, 1284485],
                [2683120.8, 1282963.3],
                [2683335.6, 1280350.4],
                [2681833, 1279591.9],
                [2679221.4, 1280502.2],
                [2678135.5, 1279794.5],
                [2677000.9, 1282002.9],
                [2678242.5, 1283316.1],
                [2681751.2, 1284485]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8214', ortbez27: 'G�chlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2681751.2, 1284485], [2678242.5, 1283316.1], [2678228, 1286811.8], [2681751.2, 1284485]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8212', ortbez27: 'Neuhausen am Rheinfall' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687741.9, 1283443.2],
                [2689692.9, 1283251.6],
                [2689095.1, 1281463.7],
                [2688633.3, 1279662.6],
                [2687773, 1280590.2],
                [2684580.2, 1280696.7],
                [2687741.9, 1283443.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4118', ortbez27: 'Rodersdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2602494.5, 1259039.3], [2601371.1, 1257924.8], [2599658.9, 1260866.3], [2602494.5, 1259039.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4116', ortbez27: 'Metzerlen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601864.3, 1255779.6],
                [2600013.1, 1255212.5],
                [2601371.1, 1257924.8],
                [2602494.5, 1259039.3],
                [2603868.4, 1259276.2],
                [2603748.2, 1257745.8],
                [2603534.1, 1256424.1],
                [2601864.3, 1255779.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8200', ortbez27: 'Schaffhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690186.4, 1287749.7],
                [2692085.6, 1285634.4],
                [2691445.1, 1284505.3],
                [2691395.2, 1283239],
                [2689692.9, 1283251.6],
                [2687741.9, 1283443.2],
                [2686149.3, 1286951.2],
                [2688496.5, 1286842],
                [2687660.9, 1288355],
                [2689531.7, 1289448.8],
                [2690186.4, 1287749.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4106', ortbez27: 'Therwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610830.8, 1262393.5],
                [2609771, 1259530.1],
                [2607464.8, 1259523.5],
                [2606488.1, 1260837.1],
                [2607447, 1261742.6],
                [2610830.8, 1262393.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4103', ortbez27: 'Bottmingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609521.2, 1263596.2],
                [2610740.9, 1264433],
                [2611835.4, 1263338.1],
                [2610830.8, 1262393.5],
                [2609521.2, 1263596.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4104', ortbez27: 'Oberwil BL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609521.2, 1263596.2],
                [2610830.8, 1262393.5],
                [2607447, 1261742.6],
                [2606919.9, 1262887.2],
                [2607025.3, 1264169.1],
                [2608287, 1264969.3],
                [2609521.2, 1263596.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4107', ortbez27: 'Ettingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608817.8, 1256799.8],
                [2607014.3, 1256869.4],
                [2607464.8, 1259523.5],
                [2609771, 1259530.1],
                [2608817.8, 1256799.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4108', ortbez27: 'Witterswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2606488.1, 1260837.1], [2607464.8, 1259523.5], [2605372.9, 1259529.4], [2606488.1, 1260837.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8255', ortbez27: 'Schlattingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697251.4, 1277769.8],
                [2698035, 1281651.2],
                [2702230.1, 1281666.5],
                [2702218.5, 1280410.5],
                [2699731.7, 1278570.7],
                [2697251.4, 1277769.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8261', ortbez27: 'Hemishofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702230.1, 1281666.5],
                [2705891.2, 1284748],
                [2706167.3, 1282271.8],
                [2704921.2, 1281304.8],
                [2704165.1, 1280284.6],
                [2702218.5, 1280410.5],
                [2702230.1, 1281666.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8262', ortbez27: 'Ramsen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705891.2, 1284748],
                [2702230.1, 1281666.5],
                [2701790.5, 1284771.6],
                [2700532.1, 1284933],
                [2700005.2, 1286149.5],
                [2700978.7, 1287004.1],
                [2705891.2, 1284748]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8259', ortbez27: 'Etzwilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704294, 1277383],
                [2703472.4, 1278722.3],
                [2702218.5, 1280410.5],
                [2704165.1, 1280284.6],
                [2706244, 1278957.1],
                [2704294, 1277383]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8260', ortbez27: 'Stein am Rhein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2706167.3, 1282271.8],
                [2708020.7, 1279401.2],
                [2706244, 1278957.1],
                [2704165.1, 1280284.6],
                [2704921.2, 1281304.8],
                [2706167.3, 1282271.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8247', ortbez27: 'Flurlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689095.1, 1281463.7],
                [2689692.9, 1283251.6],
                [2691395.2, 1283239],
                [2691251.2, 1281894.2],
                [2689095.1, 1281463.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8248', ortbez27: 'Uhwiesen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692497.4, 1282381.7],
                [2692585.8, 1280432.8],
                [2691333, 1280173.4],
                [2689904.5, 1279602.2],
                [2688633.3, 1279662.6],
                [2689095.1, 1281463.7],
                [2691251.2, 1281894.2],
                [2692497.4, 1282381.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4153', ortbez27: 'Reinach BL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611835.4, 1263338.1],
                [2612894, 1261823.2],
                [2612785.7, 1260015.9],
                [2609771, 1259530.1],
                [2610830.8, 1262393.5],
                [2611835.4, 1263338.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8253', ortbez27: 'Diessenhofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696321.9, 1280752.6],
                [2694896.9, 1283312.2],
                [2696879.2, 1283413.7],
                [2702230.1, 1281666.5],
                [2698035, 1281651.2],
                [2696321.9, 1280752.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8254', ortbez27: 'Basadingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2697251.4, 1277769.8], [2696321.9, 1280752.6], [2698035, 1281651.2], [2697251.4, 1277769.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8252', ortbez27: 'Schlatt TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695830.9, 1276882.8],
                [2693824.1, 1277795.2],
                [2692470.6, 1278359.3],
                [2692585.8, 1280432.8],
                [2692497.4, 1282381.7],
                [2694896.9, 1283312.2],
                [2696321.9, 1280752.6],
                [2697251.4, 1277769.8],
                [2695830.9, 1276882.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8242', ortbez27: 'Bibern SH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693609.6, 1290507.4],
                [2691425.7, 1291388],
                [2692342.6, 1292866],
                [2693611.9, 1292484.4],
                [2694808.8, 1290905.2],
                [2693609.6, 1290507.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8239', ortbez27: 'D�rflingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2694603.5, 1285810.4], [2696070.1, 1286609.7], [2696879.2, 1283413.7], [2694603.5, 1285810.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8240', ortbez27: 'Thayngen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696070.1, 1286609.7],
                [2693376.7, 1288133.5],
                [2693609.6, 1290507.4],
                [2694808.8, 1290905.2],
                [2696910.8, 1290464.8],
                [2696070.1, 1286609.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4145', ortbez27: 'Gempen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615492.5, 1257848.6],
                [2615325, 1259280.8],
                [2615792.9, 1261122.4],
                [2617399.9, 1259677],
                [2618607.8, 1256954.6],
                [2615492.5, 1257848.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4146', ortbez27: 'Hochwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614207.4, 1257074.1],
                [2615492.5, 1257848.6],
                [2618607.8, 1256954.6],
                [2616143, 1255328.8],
                [2613464.4, 1254259.2],
                [2614207.4, 1257074.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4143', ortbez27: 'Dornach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614207.4, 1257074.1],
                [2612494.5, 1257059.1],
                [2612785.7, 1260015.9],
                [2615325, 1259280.8],
                [2615492.5, 1257848.6],
                [2614207.4, 1257074.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8246', ortbez27: 'Langwiesen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2691395.2, 1283239], [2692497.4, 1282381.7], [2691251.2, 1281894.2], [2691395.2, 1283239]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4144', ortbez27: 'Arlesheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615325, 1259280.8],
                [2612785.7, 1260015.9],
                [2612894, 1261823.2],
                [2615792.9, 1261122.4],
                [2615325, 1259280.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8243', ortbez27: 'Altdorf SH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690744, 1292445.9],
                [2690589.4, 1293726.1],
                [2690774.7, 1295004.3],
                [2691850.9, 1294262.8],
                [2692342.6, 1292866],
                [2690744, 1292445.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8233', ortbez27: 'Bargen SH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2687258.6, 1293423.9], [2685422.5, 1293124], [2684682.3, 1296092.8], [2687258.6, 1293423.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8234', ortbez27: 'Stetten SH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2690186.4, 1287749.7], [2691207.5, 1289357.6], [2693376.7, 1288133.5], [2690186.4, 1287749.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4147', ortbez27: 'Aesch BL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612494.5, 1257059.1],
                [2608817.8, 1256799.8],
                [2609771, 1259530.1],
                [2612785.7, 1260015.9],
                [2612494.5, 1257059.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4148', ortbez27: 'Pfeffingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610560, 1255049.7],
                [2608817.8, 1256799.8],
                [2612494.5, 1257059.1],
                [2612122.3, 1255086.6],
                [2610560, 1255049.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8231', ortbez27: 'Hemmental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684844.1, 1289938.1],
                [2687660.9, 1288355],
                [2688496.5, 1286842],
                [2686149.3, 1286951.2],
                [2683743.3, 1287637.2],
                [2683325.9, 1289301],
                [2684844.1, 1289938.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8232', ortbez27: 'Merishausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685422.5, 1293124],
                [2687258.6, 1293423.9],
                [2689694.3, 1290792.8],
                [2689531.7, 1289448.8],
                [2687660.9, 1288355],
                [2684844.1, 1289938.1],
                [2685422.5, 1293124]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8235', ortbez27: 'Lohn SH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691207.5, 1289357.6],
                [2691425.7, 1291388],
                [2693609.6, 1290507.4],
                [2693376.7, 1288133.5],
                [2691207.5, 1289357.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8236', ortbez27: 'B�ttenhardt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691207.5, 1289357.6],
                [2690186.4, 1287749.7],
                [2689531.7, 1289448.8],
                [2689694.3, 1290792.8],
                [2690744, 1292445.9],
                [2692342.6, 1292866],
                [2691425.7, 1291388],
                [2691207.5, 1289357.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4142', ortbez27: 'M�nchenstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612894, 1261823.2],
                [2611835.4, 1263338.1],
                [2613081.7, 1265136.9],
                [2615792.9, 1261122.4],
                [2612894, 1261823.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8280', ortbez27: 'Kreuzlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729652.1, 1280432.4],
                [2732997.4, 1279943.5],
                [2732523.9, 1276637.6],
                [2730927, 1275691.7],
                [2729430.3, 1276164.3],
                [2727958.4, 1277384.7],
                [2729652.1, 1280432.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8273', ortbez27: 'Triboltingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726108.8, 1280939.7],
                [2725155.5, 1279547.2],
                [2724860.1, 1280834],
                [2724999.5, 1282196.4],
                [2726108.8, 1280939.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8274', ortbez27: 'T�gerwilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729652.1, 1280432.4],
                [2727958.4, 1277384.7],
                [2725795.1, 1275646.2],
                [2724519.6, 1276400.5],
                [2724914.2, 1278301],
                [2725155.5, 1279547.2],
                [2726108.8, 1280939.7],
                [2727752.5, 1280724.4],
                [2729652.1, 1280432.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8272', ortbez27: 'Ermatingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722460.3, 1282462.1],
                [2724999.5, 1282196.4],
                [2724860.1, 1280834],
                [2725155.5, 1279547.2],
                [2724914.2, 1278301],
                [2723642, 1278072.9],
                [2722598, 1278785.8],
                [2722174.6, 1281005.3],
                [2722460.3, 1282462.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8265', ortbez27: 'Mammern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2709610, 1278673.5], [2713490.7, 1280071.3], [2711343, 1277235.6], [2709610, 1278673.5]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8266', ortbez27: 'Steckborn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2713490.7, 1280071.3], [2717497.3, 1282294.2], [2720435.9, 1279608.6], [2713490.7, 1280071.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8264', ortbez27: 'Eschenz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708020.7, 1279401.2],
                [2709610, 1278673.5],
                [2711343, 1277235.6],
                [2710122.1, 1276588.2],
                [2706063.4, 1276253.9],
                [2704294, 1277383],
                [2706244, 1278957.1],
                [2708020.7, 1279401.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8269', ortbez27: 'Fruthwilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2721384, 1278458.6],
                [2720435.9, 1279608.6],
                [2722174.6, 1281005.3],
                [2722598, 1278785.8],
                [2721384, 1278458.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8267', ortbez27: 'Berlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2717497.3, 1282294.2], [2720674.7, 1281854.7], [2720435.9, 1279608.6], [2717497.3, 1282294.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8268', ortbez27: 'Mannenbach-Salenstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720435.9, 1279608.6],
                [2720674.7, 1281854.7],
                [2722460.3, 1282462.1],
                [2722174.6, 1281005.3],
                [2720435.9, 1279608.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8322', ortbez27: 'Madetswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2702831.2, 1249446.8], [2702349.9, 1251212.5], [2704027.6, 1250033.6], [2702831.2, 1249446.8]]],
            [
              [
                [2702349.9, 1251212.5],
                [2701265.5, 1252281.6],
                [2702432.7, 1253403.3],
                [2703330.4, 1252205.2],
                [2702349.9, 1251212.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8320', ortbez27: 'Fehraltorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697655.9, 1250177.3],
                [2698421.5, 1251468.2],
                [2699984.9, 1250958.2],
                [2700845.1, 1249007.1],
                [2699930.7, 1247989.8],
                [2698734.9, 1247621.4],
                [2697511.7, 1248882.9],
                [2697655.9, 1250177.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4225', ortbez27: 'Brislach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606446.8, 1252698.9],
                [2608421.5, 1254938.2],
                [2610560, 1255049.7],
                [2610798.9, 1251706.1],
                [2606798.8, 1251414.2],
                [2606446.8, 1252698.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4226', ortbez27: 'Breitenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606798.8, 1251414.2],
                [2610798.9, 1251706.1],
                [2611206.8, 1250490.6],
                [2609936.2, 1249466],
                [2606798.8, 1251414.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4223', ortbez27: 'Blauen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608421.5, 1254938.2],
                [2605119.3, 1255075.2],
                [2603534.1, 1256424.1],
                [2607014.3, 1256869.4],
                [2608817.8, 1256799.8],
                [2608421.5, 1254938.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4224', ortbez27: 'Nenzlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2608817.8, 1256799.8], [2610560, 1255049.7], [2608421.5, 1254938.2], [2608817.8, 1256799.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4229', ortbez27: 'Beinwil SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610499.4, 1241561.4],
                [2608655.9, 1243986.3],
                [2607705.1, 1245631.1],
                [2610003.6, 1246905.1],
                [2612414.5, 1247780.4],
                [2613679, 1247627],
                [2615414.4, 1247909.5],
                [2616390.4, 1246526],
                [2614584.3, 1243498.5],
                [2612681.7, 1242802],
                [2611703.5, 1242001.8],
                [2610499.4, 1241561.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8314', ortbez27: 'Kyburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698638.6, 1255251.1],
                [2696839.9, 1257551.6],
                [2698367.9, 1258088.7],
                [2699825, 1257673.1],
                [2700466.7, 1255968.5],
                [2698638.6, 1255251.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4227', ortbez27: 'B�sserach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606139.6, 1246963.2],
                [2606007.7, 1248786.4],
                [2605614, 1250936.5],
                [2606798.8, 1251414.2],
                [2609936.2, 1249466],
                [2609278.7, 1248073],
                [2606139.6, 1246963.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8311', ortbez27: 'Br�tten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694639.2, 1258317.2],
                [2692937.4, 1257657.1],
                [2690767.9, 1258712.7],
                [2691957.4, 1260640.3],
                [2693793.7, 1260406.1],
                [2694639.2, 1258317.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4228', ortbez27: 'Erschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606139.6, 1246963.2],
                [2609278.7, 1248073],
                [2610003.6, 1246905.1],
                [2607705.1, 1245631.1],
                [2606139.6, 1246963.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8312', ortbez27: 'Winterberg ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692937.4, 1257657.1],
                [2694639.2, 1258317.2],
                [2695490.8, 1257323.9],
                [2695979.9, 1256034.1],
                [2694836.8, 1255482],
                [2693802.3, 1254770.3],
                [2692937.4, 1257657.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8317', ortbez27: 'Tagelswangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693357.3, 1253009.9],
                [2691983.2, 1254082.1],
                [2692277.7, 1255385.5],
                [2693802.3, 1254770.3],
                [2693357.3, 1253009.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8315', ortbez27: 'Lindau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2693802.3, 1254770.3], [2692277.7, 1255385.5], [2692937.4, 1257657.1], [2693802.3, 1254770.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8305', ortbez27: 'Dietlikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689164.6, 1251546.1],
                [2687951.9, 1253767.1],
                [2689442.2, 1254516.9],
                [2690423.4, 1253732.2],
                [2691111.5, 1252598.8],
                [2689164.6, 1251546.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4222', ortbez27: 'Zwingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2608421.5, 1254938.2], [2606446.8, 1252698.9], [2605119.3, 1255075.2], [2608421.5, 1254938.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8303', ortbez27: 'Bassersdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691983.2, 1254082.1],
                [2690423.4, 1253732.2],
                [2689442.2, 1254516.9],
                [2687951.9, 1253767.1],
                [2687915.9, 1255071.7],
                [2688271.7, 1256565.6],
                [2689608.9, 1257227.5],
                [2691027.4, 1255457.7],
                [2691983.2, 1254082.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8304', ortbez27: 'Wallisellen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687528.6, 1251374.3],
                [2685467.3, 1252812.2],
                [2687951.9, 1253767.1],
                [2689164.6, 1251546.1],
                [2687528.6, 1251374.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8309', ortbez27: 'N�rensdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692277.7, 1255385.5],
                [2691983.2, 1254082.1],
                [2691027.4, 1255457.7],
                [2689608.9, 1257227.5],
                [2690767.9, 1258712.7],
                [2692937.4, 1257657.1],
                [2692277.7, 1255385.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4207', ortbez27: 'Bretzwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617826.2, 1251762.2],
                [2617284.2, 1249944.1],
                [2615414.4, 1247909.5],
                [2614058.3, 1251602.6],
                [2617826.2, 1251762.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8307', ortbez27: 'Effretikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695866.9, 1253535.5],
                [2694579.3, 1251657],
                [2693357.3, 1253009.9],
                [2693802.3, 1254770.3],
                [2694836.8, 1255482],
                [2695979.9, 1256034.1],
                [2695490.8, 1257323.9],
                [2696839.9, 1257551.6],
                [2698638.6, 1255251.1],
                [2697527.1, 1253329.6],
                [2695866.9, 1253535.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4208', ortbez27: 'Nunningen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614058.3, 1251602.6],
                [2615414.4, 1247909.5],
                [2613679, 1247627],
                [2613166.7, 1248853.7],
                [2612445.7, 1249908],
                [2611206.8, 1250490.6],
                [2614058.3, 1251602.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8308', ortbez27: 'Illnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694579.3, 1251657],
                [2695866.9, 1253535.5],
                [2697527.1, 1253329.6],
                [2698638.6, 1255251.1],
                [2699238.6, 1252825.9],
                [2698421.5, 1251468.2],
                [2697655.9, 1250177.3],
                [2696217.6, 1250461],
                [2694579.3, 1251657]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4202', ortbez27: 'Duggingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612122.3, 1255086.6],
                [2612494.5, 1257059.1],
                [2614207.4, 1257074.1],
                [2613464.4, 1254259.2],
                [2612122.3, 1255086.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8302', ortbez27: 'Kloten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683953.7, 1255523],
                [2683958.8, 1258330.2],
                [2685802.8, 1259454.8],
                [2687893.8, 1258616.7],
                [2689520.3, 1258999.7],
                [2690767.9, 1258712.7],
                [2689608.9, 1257227.5],
                [2688271.7, 1256565.6],
                [2687915.9, 1255071.7],
                [2685785.4, 1255145.3],
                [2683953.7, 1255523]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8353', ortbez27: 'Elgg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2706339.5, 1263395.7],
                [2709642.7, 1262174.8],
                [2710852.3, 1260141.3],
                [2709014.4, 1258864.8],
                [2705015.9, 1262674],
                [2706339.5, 1263395.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4206', ortbez27: 'Seewen SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2616143, 1255328.8],
                [2618639.9, 1253277.9],
                [2617826.2, 1251762.2],
                [2614058.3, 1251602.6],
                [2613464.4, 1254259.2],
                [2616143, 1255328.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8354', ortbez27: 'Hofstetten ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705015.9, 1262674],
                [2709014.4, 1258864.8],
                [2709724.5, 1257723.5],
                [2709045.8, 1256571.3],
                [2707346.3, 1257641.3],
                [2706099.5, 1257486.1],
                [2704100.2, 1260839.3],
                [2705015.9, 1262674]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4204', ortbez27: 'Himmelried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613464.4, 1254259.2],
                [2614058.3, 1251602.6],
                [2611206.8, 1250490.6],
                [2610798.9, 1251706.1],
                [2610560, 1255049.7],
                [2612122.3, 1255086.6],
                [2613464.4, 1254259.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8352', ortbez27: 'Elsau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704100.2, 1260839.3],
                [2703282.3, 1259847.3],
                [2701926.1, 1261428.1],
                [2701308.6, 1262607.1],
                [2702396.7, 1263513.2],
                [2704101.6, 1263997.5],
                [2705015.9, 1262674],
                [2704100.2, 1260839.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8357', ortbez27: 'Guntershausen b. Aadorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710743.5, 1258456.3],
                [2710852.3, 1260141.3],
                [2712262.4, 1260601.4],
                [2713087, 1259390.5],
                [2712541.8, 1257994.2],
                [2710743.5, 1258456.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8355', ortbez27: 'Aadorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709642.7, 1262174.8],
                [2711055.9, 1263686.4],
                [2712501.8, 1263140.8],
                [2712262.4, 1260601.4],
                [2710852.3, 1260141.3],
                [2709642.7, 1262174.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8356', ortbez27: 'Ettenhausen TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710852.3, 1260141.3],
                [2710743.5, 1258456.3],
                [2709724.5, 1257723.5],
                [2709014.4, 1258864.8],
                [2710852.3, 1260141.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8345', ortbez27: 'Adetswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2706538.1, 1245870.1], [2706335.6, 1242847], [2704568.1, 1244821.1], [2706538.1, 1245870.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8344', ortbez27: 'B�retswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709702.2, 1241884.8],
                [2706335.6, 1242847],
                [2706538.1, 1245870.1],
                [2707211.5, 1246933.3],
                [2709154.4, 1245774.7],
                [2710889.4, 1245773.2],
                [2710460.3, 1244312.8],
                [2710025, 1243125.8],
                [2709702.2, 1241884.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4247', ortbez27: 'Grindel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606007.7, 1248786.4],
                [2606139.6, 1246963.2],
                [2604289.5, 1246799.1],
                [2603988.4, 1248794.4],
                [2604513.1, 1250013.2],
                [2606007.7, 1248786.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4253', ortbez27: 'Liesberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601685, 1250177.1],
                [2599975.8, 1247937.5],
                [2598038.7, 1247906.2],
                [2596850, 1250426.1],
                [2600199.6, 1250476.9],
                [2601685, 1250177.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8335', ortbez27: 'Hittnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704027.6, 1250033.6],
                [2705677.8, 1249613.8],
                [2706397.7, 1248416.1],
                [2707211.5, 1246933.3],
                [2706538.1, 1245870.1],
                [2704568.1, 1244821.1],
                [2703882.7, 1245920.2],
                [2703301.7, 1248193.6],
                [2702831.2, 1249446.8],
                [2704027.6, 1250033.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4252', ortbez27: 'B�rschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601685, 1250177.1],
                [2603988.4, 1248794.4],
                [2604289.5, 1246799.1],
                [2601577.9, 1246848.5],
                [2599975.8, 1247937.5],
                [2601685, 1250177.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4242', ortbez27: 'Laufen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603988.4, 1248794.4],
                [2601685, 1250177.1],
                [2600199.6, 1250476.9],
                [2600724.9, 1251675.2],
                [2603562.5, 1251527.9],
                [2603560.2, 1253322.4],
                [2606446.8, 1252698.9],
                [2606798.8, 1251414.2],
                [2605614, 1250936.5],
                [2604513.1, 1250013.2],
                [2603988.4, 1248794.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8342', ortbez27: 'Wernetshausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708145.6, 1238370.9],
                [2710007.7, 1240632.8],
                [2709936.7, 1237595.8],
                [2709209.7, 1236575.3],
                [2708145.6, 1238370.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8340', ortbez27: 'Hinwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2706335.6, 1242847],
                [2709702.2, 1241884.8],
                [2710007.7, 1240632.8],
                [2708145.6, 1238370.9],
                [2704979.1, 1237622.3],
                [2703729.2, 1239434],
                [2703940.2, 1241313.3],
                [2706011.8, 1241570.6],
                [2706335.6, 1242847]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4245', ortbez27: 'Kleinl�tzel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2600013.1, 1255212.5],
                [2600724.9, 1251675.2],
                [2600199.6, 1250476.9],
                [2596850, 1250426.1],
                [2595387.1, 1251623.9],
                [2595811.3, 1253275.3],
                [2598735.3, 1255170.5],
                [2600013.1, 1255212.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4246', ortbez27: 'Wahlen b. Laufen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2604513.1, 1250013.2], [2605614, 1250936.5], [2606007.7, 1248786.4], [2604513.1, 1250013.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8330', ortbez27: 'Pf�ffikon ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700845.1, 1249007.1],
                [2702831.2, 1249446.8],
                [2703301.7, 1248193.6],
                [2703882.7, 1245920.2],
                [2702276.8, 1244496.4],
                [2699404.9, 1245781.3],
                [2698058.7, 1246051.4],
                [2699930.7, 1247989.8],
                [2700845.1, 1249007.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4243', ortbez27: 'Dittingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605119.3, 1255075.2],
                [2606446.8, 1252698.9],
                [2603560.2, 1253322.4],
                [2601864.3, 1255779.6],
                [2603534.1, 1256424.1],
                [2605119.3, 1255075.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4244', ortbez27: 'R�schenz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2600724.9, 1251675.2],
                [2600013.1, 1255212.5],
                [2601864.3, 1255779.6],
                [2603560.2, 1253322.4],
                [2603562.5, 1251527.9],
                [2600724.9, 1251675.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4233', ortbez27: 'Meltingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609278.7, 1248073],
                [2609936.2, 1249466],
                [2611206.8, 1250490.6],
                [2612445.7, 1249908],
                [2613166.7, 1248853.7],
                [2612414.5, 1247780.4],
                [2610003.6, 1246905.1],
                [2609278.7, 1248073]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4234', ortbez27: 'Zullwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2613679, 1247627], [2612414.5, 1247780.4], [2613166.7, 1248853.7], [2613679, 1247627]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8331', ortbez27: 'Auslikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704568.1, 1244821.1],
                [2703042.4, 1243465.5],
                [2702276.8, 1244496.4],
                [2703882.7, 1245920.2],
                [2704568.1, 1244821.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8332', ortbez27: 'Russikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702831.2, 1249446.8],
                [2700845.1, 1249007.1],
                [2699984.9, 1250958.2],
                [2698421.5, 1251468.2],
                [2699238.6, 1252825.9],
                [2701265.5, 1252281.6],
                [2702349.9, 1251212.5],
                [2702831.2, 1249446.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8376', ortbez27: 'Au TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712747.1, 1251215.5],
                [2714247.8, 1251941.8],
                [2715388.3, 1254146.6],
                [2717225.6, 1253247.1],
                [2715966.3, 1250843.4],
                [2715290.6, 1249571.8],
                [2713561, 1249738.2],
                [2712747.1, 1251215.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8370', ortbez27: 'Sirnach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716592.7, 1258827.5],
                [2719073.1, 1259015.9],
                [2719887.5, 1258046.8],
                [2718013.3, 1256722.4],
                [2717641.7, 1255432],
                [2715984.4, 1256212.9],
                [2716592.7, 1258827.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8374', ortbez27: 'Oberwangen TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715388.3, 1254146.6],
                [2714247.8, 1251941.8],
                [2712747.1, 1251215.5],
                [2710726.2, 1251602.8],
                [2713409.2, 1254681.5],
                [2713921.2, 1256455.9],
                [2715984.4, 1256212.9],
                [2717641.7, 1255432],
                [2717225.6, 1253247.1],
                [2715388.3, 1254146.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8371', ortbez27: 'Busswil TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718013.3, 1256722.4], [2719887.5, 1258046.8], [2719404, 1256137.3], [2718013.3, 1256722.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8362', ortbez27: 'Balterswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713921.2, 1256455.9],
                [2713409.2, 1254681.5],
                [2712541.8, 1257994.2],
                [2713087, 1259390.5],
                [2714077.9, 1258177.7],
                [2713921.2, 1256455.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8360', ortbez27: 'Eschlikon TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716200.8, 1260552.4],
                [2716592.7, 1258827.5],
                [2715984.4, 1256212.9],
                [2713921.2, 1256455.9],
                [2714077.9, 1258177.7],
                [2713087, 1259390.5],
                [2716200.8, 1260552.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8363', ortbez27: 'Bichelsee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713409.2, 1254681.5],
                [2711002.3, 1254379.2],
                [2709045.8, 1256571.3],
                [2709724.5, 1257723.5],
                [2710743.5, 1258456.3],
                [2712541.8, 1257994.2],
                [2713409.2, 1254681.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8418', ortbez27: 'Schlatt ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703282.3, 1259847.3],
                [2704100.2, 1260839.3],
                [2706099.5, 1257486.1],
                [2704646.3, 1257762.1],
                [2702785.3, 1258142.4],
                [2703282.3, 1259847.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8415', ortbez27: 'Berg am Irchel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688194.1, 1269420.2],
                [2686945.2, 1267584.3],
                [2685860.4, 1268236.1],
                [2685357.7, 1270067.8],
                [2687003.2, 1269938.7],
                [2688194.1, 1269420.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8416', ortbez27: 'Flaach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689642.9, 1272235.2],
                [2688194.1, 1269420.2],
                [2687003.2, 1269938.7],
                [2685357.7, 1270067.8],
                [2686897.2, 1272451.2],
                [2688368.4, 1272692.7],
                [2689642.9, 1272235.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4322', ortbez27: 'Mumpf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634648.1, 1265904],
                [2635458.1, 1267408.7],
                [2637544.7, 1265839.9],
                [2636062.9, 1265149.8],
                [2634648.1, 1265904]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8422', ortbez27: 'Pfungen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690915.2, 1261973.1],
                [2689618.1, 1262850.9],
                [2688903, 1264229.1],
                [2690608.6, 1264225.3],
                [2692163.8, 1263703.1],
                [2690915.2, 1261973.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4325', ortbez27: 'Schupfart' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640728, 1262344.1],
                [2638598.1, 1260394.4],
                [2637031.8, 1261628.1],
                [2638007.3, 1262570.9],
                [2639038.1, 1264341.6],
                [2641117.4, 1263844.9],
                [2640728, 1262344.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8409', ortbez27: 'Winterthur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701308.6, 1262607.1],
                [2701926.1, 1261428.1],
                [2699600, 1261098],
                [2700058.8, 1262657.3],
                [2701308.6, 1262607.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4323', ortbez27: 'Wallbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2635458.1, 1267408.7], [2633144.7, 1269040.6], [2634641.2, 1270634.4], [2635458.1, 1267408.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4324', ortbez27: 'Obermumpf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639038.1, 1264341.6],
                [2638007.3, 1262570.9],
                [2636823.3, 1263705.7],
                [2636062.9, 1265149.8],
                [2637544.7, 1265839.9],
                [2638572.8, 1266556],
                [2639038.1, 1264341.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8408', ortbez27: 'Winterthur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695864.7, 1264570.2],
                [2695295.7, 1261945.6],
                [2693793.7, 1260406.1],
                [2691957.4, 1260640.3],
                [2690915.2, 1261973.1],
                [2692163.8, 1263703.1],
                [2694160.5, 1265367.5],
                [2695864.7, 1264570.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4313', ortbez27: 'M�hlin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629795, 1265015.5],
                [2628131, 1268939.5],
                [2628914, 1271023.6],
                [2634641.2, 1270634.4],
                [2633144.7, 1269040.6],
                [2631226.9, 1265181.3],
                [2629795, 1265015.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8413', ortbez27: 'Neftenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694160.5, 1265367.5],
                [2692163.8, 1263703.1],
                [2690608.6, 1264225.3],
                [2690700.4, 1265799.5],
                [2691193.1, 1267216.1],
                [2694414.8, 1266592.6],
                [2694160.5, 1265367.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4314', ortbez27: 'Zeiningen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631226.9, 1265181.3],
                [2633144.7, 1269040.6],
                [2635458.1, 1267408.7],
                [2634648.1, 1265904],
                [2632064.7, 1263893.5],
                [2631226.9, 1265181.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8414', ortbez27: 'Buch am Irchel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686945.2, 1267584.3],
                [2688194.1, 1269420.2],
                [2689820, 1269115.4],
                [2691050.3, 1268504.7],
                [2691193.1, 1267216.1],
                [2690700.4, 1265799.5],
                [2690608.6, 1264225.3],
                [2688903, 1264229.1],
                [2688472.9, 1265777.3],
                [2686945.2, 1267584.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4312', ortbez27: 'Magden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625681.4, 1265011.5],
                [2629795, 1265015.5],
                [2628930.6, 1262211.7],
                [2627722.5, 1260906.4],
                [2626475.3, 1262453.1],
                [2625885.8, 1263556.3],
                [2624696.7, 1263980.8],
                [2625681.4, 1265011.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8412', ortbez27: 'H�nikon (Neftenbach)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694012.8, 1267779.7],
                [2694414.8, 1266592.6],
                [2691193.1, 1267216.1],
                [2691050.3, 1268504.7],
                [2692298.5, 1268804.7],
                [2694012.8, 1267779.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4317', ortbez27: 'Wegenstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2635213, 1259607.4], [2637031.8, 1261628.1], [2638598.1, 1260394.4], [2635213, 1259607.4]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4315', ortbez27: 'Zuzgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636823.3, 1263705.7],
                [2634833.9, 1263176.5],
                [2632064.7, 1263893.5],
                [2634648.1, 1265904],
                [2636062.9, 1265149.8],
                [2636823.3, 1263705.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4316', ortbez27: 'Hellikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2637031.8, 1261628.1],
                [2635213, 1259607.4],
                [2634395.7, 1261917.3],
                [2634833.9, 1263176.5],
                [2636823.3, 1263705.7],
                [2638007.3, 1262570.9],
                [2637031.8, 1261628.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8400', ortbez27: 'Winterthur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695295.7, 1261945.6],
                [2695864.7, 1264570.2],
                [2697646, 1264463],
                [2700058.8, 1262657.3],
                [2699600, 1261098],
                [2699123.9, 1259094.9],
                [2698367.9, 1258088.7],
                [2696839.9, 1257551.6],
                [2696488.5, 1259748],
                [2696391.9, 1261329.8],
                [2695295.7, 1261945.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4305', ortbez27: 'Olsberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2624491.5, 1265791.1], [2625681.4, 1265011.5], [2624696.7, 1263980.8], [2624491.5, 1265791.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8405', ortbez27: 'Winterthur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2699123.9, 1259094.9],
                [2699600, 1261098],
                [2701926.1, 1261428.1],
                [2703282.3, 1259847.3],
                [2702785.3, 1258142.4],
                [2699825, 1257673.1],
                [2699123.9, 1259094.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8406', ortbez27: 'Winterthur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693793.7, 1260406.1],
                [2695295.7, 1261945.6],
                [2696391.9, 1261329.8],
                [2696488.5, 1259748],
                [2696839.9, 1257551.6],
                [2695490.8, 1257323.9],
                [2694639.2, 1258317.2],
                [2693793.7, 1260406.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4303', ortbez27: 'Kaiseraugst' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620772.2, 1265625.4],
                [2624491.5, 1265791.1],
                [2624696.7, 1263980.8],
                [2623396.9, 1263797],
                [2621864.7, 1264359],
                [2620772.2, 1265625.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8404', ortbez27: 'Reutlingen (Winterthur)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701432.3, 1265814.7],
                [2702396.7, 1263513.2],
                [2701308.6, 1262607.1],
                [2700058.8, 1262657.3],
                [2697646, 1264463],
                [2698725.3, 1266178.9],
                [2699183.9, 1267349.7],
                [2701391.1, 1267459.2],
                [2701432.3, 1265814.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4310', ortbez27: 'Rheinfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624491.5, 1265791.1],
                [2628131, 1268939.5],
                [2629795, 1265015.5],
                [2625681.4, 1265011.5],
                [2624491.5, 1265791.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4302', ortbez27: 'Augst BL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618764.8, 1264826.5],
                [2620772.2, 1265625.4],
                [2621864.7, 1264359],
                [2621138.6, 1263334.7],
                [2618764.8, 1264826.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8450', ortbez27: 'Andelfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692776.9, 1273646.7],
                [2694931.7, 1272657.1],
                [2696266.6, 1272665.9],
                [2696065, 1270553.5],
                [2694473.2, 1270097.2],
                [2693143.9, 1271173.4],
                [2691829.3, 1271024],
                [2690583.4, 1270769.4],
                [2689642.9, 1272235.2],
                [2692776.9, 1273646.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8454', ortbez27: 'Buchberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682715, 1271491.9],
                [2685357.7, 1270067.8],
                [2685860.4, 1268236.1],
                [2684841, 1266680.6],
                [2683803.9, 1267380],
                [2683385.2, 1269693.3],
                [2682715, 1271491.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8451', ortbez27: 'Kleinandelfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694104.2, 1274900.8],
                [2694931.7, 1272657.1],
                [2692776.9, 1273646.7],
                [2692470.5, 1274860.1],
                [2694104.2, 1274900.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8442', ortbez27: 'Hettlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694860, 1268901.6],
                [2696080.3, 1268509.6],
                [2697047.8, 1267565],
                [2694414.8, 1266592.6],
                [2694012.8, 1267779.7],
                [2694860, 1268901.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8444', ortbez27: 'Henggart' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694012.8, 1267779.7],
                [2692298.5, 1268804.7],
                [2693260.4, 1269714.4],
                [2694473.2, 1270097.2],
                [2694860, 1268901.6],
                [2694012.8, 1267779.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8425', ortbez27: 'Oberembrach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688024.4, 1261180.3],
                [2689618.1, 1262850.9],
                [2690915.2, 1261973.1],
                [2691957.4, 1260640.3],
                [2690767.9, 1258712.7],
                [2689520.3, 1258999.7],
                [2687893.8, 1258616.7],
                [2688414.7, 1259760.7],
                [2688024.4, 1261180.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8426', ortbez27: 'Lufingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687893.8, 1258616.7],
                [2685802.8, 1259454.8],
                [2685614.1, 1260872.6],
                [2688024.4, 1261180.3],
                [2688414.7, 1259760.7],
                [2687893.8, 1258616.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8424', ortbez27: 'Embrach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689618.1, 1262850.9],
                [2688024.4, 1261180.3],
                [2685614.1, 1260872.6],
                [2685339, 1262134],
                [2685691.5, 1263860.4],
                [2686177.6, 1265016.6],
                [2688903, 1264229.1],
                [2689618.1, 1262850.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8427', ortbez27: 'Freienstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688903, 1264229.1],
                [2686177.6, 1265016.6],
                [2684841, 1266680.6],
                [2685860.4, 1268236.1],
                [2686945.2, 1267584.3],
                [2688472.9, 1265777.3],
                [2688903, 1264229.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4333', ortbez27: 'M�nchwilen AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640201.6, 1266708.4],
                [2639038.1, 1264341.6],
                [2638572.8, 1266556],
                [2639193.5, 1267737.4],
                [2640201.6, 1266708.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8482', ortbez27: 'Sennhof (Winterthur)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2699123.9, 1259094.9], [2699825, 1257673.1], [2698367.9, 1258088.7], [2699123.9, 1259094.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8479', ortbez27: 'Altikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702342.8, 1271521.9],
                [2703288.6, 1269974.5],
                [2700161.9, 1269482.9],
                [2699614.6, 1272346.8],
                [2702342.8, 1271521.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8486', ortbez27: 'Rikon im T�sstal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704646.3, 1257762.1],
                [2703859.7, 1256245.9],
                [2703236.6, 1254928],
                [2701894.2, 1256648.2],
                [2702785.3, 1258142.4],
                [2704646.3, 1257762.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8483', ortbez27: 'Kollbrunn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700466.7, 1255968.5],
                [2699825, 1257673.1],
                [2702785.3, 1258142.4],
                [2701894.2, 1256648.2],
                [2700466.7, 1255968.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8484', ortbez27: 'Weisslingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2699238.6, 1252825.9],
                [2698638.6, 1255251.1],
                [2700466.7, 1255968.5],
                [2701894.2, 1256648.2],
                [2703236.6, 1254928],
                [2702432.7, 1253403.3],
                [2701265.5, 1252281.6],
                [2699238.6, 1252825.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8474', ortbez27: 'Dinhard' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697571.7, 1270384.9],
                [2700161.9, 1269482.9],
                [2701391.1, 1267459.2],
                [2699183.9, 1267349.7],
                [2698338.6, 1268364.9],
                [2697571.7, 1270384.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8471', ortbez27: 'Oberwil (D�gerlen)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698338.6, 1268364.9],
                [2699183.9, 1267349.7],
                [2697047.8, 1267565],
                [2696080.3, 1268509.6],
                [2694860, 1268901.6],
                [2694473.2, 1270097.2],
                [2696065, 1270553.5],
                [2697571.7, 1270384.9],
                [2698338.6, 1268364.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8472', ortbez27: 'Seuzach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697646, 1264463],
                [2695864.7, 1264570.2],
                [2694160.5, 1265367.5],
                [2694414.8, 1266592.6],
                [2697047.8, 1267565],
                [2699183.9, 1267349.7],
                [2698725.3, 1266178.9],
                [2697646, 1264463]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8477', ortbez27: 'Oberstammheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701531.1, 1274235.1],
                [2701144, 1276199],
                [2702230.3, 1277528.1],
                [2703472.4, 1278722.3],
                [2704294, 1277383],
                [2703449.5, 1275065.8],
                [2701531.1, 1274235.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8478', ortbez27: 'Thalheim an der Thur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700161.9, 1269482.9],
                [2697571.7, 1270384.9],
                [2696065, 1270553.5],
                [2696266.6, 1272665.9],
                [2697678, 1271939.2],
                [2698611.2, 1273124.8],
                [2699614.6, 1272346.8],
                [2700161.9, 1269482.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8475', ortbez27: 'Ossingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698073.8, 1276241.9],
                [2698611.2, 1273124.8],
                [2697678, 1271939.2],
                [2696266.6, 1272665.9],
                [2694931.7, 1272657.1],
                [2694104.2, 1274900.8],
                [2695830.9, 1276882.8],
                [2697251.4, 1277769.8],
                [2698073.8, 1276241.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8476', ortbez27: 'Unterstammheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2699881.7, 1276238.6],
                [2699731.7, 1278570.7],
                [2702218.5, 1280410.5],
                [2703472.4, 1278722.3],
                [2702230.3, 1277528.1],
                [2701144, 1276199],
                [2699881.7, 1276238.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8465', ortbez27: 'Rudolfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693824.1, 1277795.2],
                [2695830.9, 1276882.8],
                [2694104.2, 1274900.8],
                [2692470.5, 1274860.1],
                [2692410.7, 1276286.3],
                [2691732.9, 1277345.5],
                [2692470.6, 1278359.3],
                [2693824.1, 1277795.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8463', ortbez27: 'Benken ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690068, 1278184.2],
                [2689904.5, 1279602.2],
                [2691333, 1280173.4],
                [2692585.8, 1280432.8],
                [2692470.6, 1278359.3],
                [2691732.9, 1277345.5],
                [2690068, 1278184.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8468', ortbez27: 'Guntalingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2701144, 1276199], [2701531.1, 1274235.1], [2699881.7, 1276238.6], [2701144, 1276199]]],
            [
              [
                [2699731.7, 1278570.7],
                [2699881.7, 1276238.6],
                [2698073.8, 1276241.9],
                [2697251.4, 1277769.8],
                [2699731.7, 1278570.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8457', ortbez27: 'Humlikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2694473.2, 1270097.2], [2693260.4, 1269714.4], [2693143.9, 1271173.4], [2694473.2, 1270097.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8458', ortbez27: 'Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691050.3, 1268504.7],
                [2689820, 1269115.4],
                [2690583.4, 1270769.4],
                [2691829.3, 1271024],
                [2693143.9, 1271173.4],
                [2693260.4, 1269714.4],
                [2692298.5, 1268804.7],
                [2691050.3, 1268504.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8455', ortbez27: 'R�dlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685357.7, 1270067.8],
                [2682715, 1271491.9],
                [2684885.1, 1273588.6],
                [2687658.4, 1274174.3],
                [2688368.4, 1272692.7],
                [2686897.2, 1272451.2],
                [2685357.7, 1270067.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8462', ortbez27: 'Rheinau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687658.4, 1274174.3],
                [2687040.4, 1277734.3],
                [2690068, 1278184.2],
                [2689185.7, 1276040.2],
                [2687658.4, 1274174.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8459', ortbez27: 'Volken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688194.1, 1269420.2],
                [2689642.9, 1272235.2],
                [2690583.4, 1270769.4],
                [2689820, 1269115.4],
                [2688194.1, 1269420.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8460', ortbez27: 'Marthalen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690068, 1278184.2],
                [2691732.9, 1277345.5],
                [2692410.7, 1276286.3],
                [2692470.5, 1274860.1],
                [2692776.9, 1273646.7],
                [2689642.9, 1272235.2],
                [2688368.4, 1272692.7],
                [2687658.4, 1274174.3],
                [2689185.7, 1276040.2],
                [2690068, 1278184.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8514', ortbez27: 'Amlikon-Bissegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720985.2, 1266288.4],
                [2719674.8, 1266644.9],
                [2717400.1, 1267367.7],
                [2717720.2, 1269127.1],
                [2718459.5, 1270296.1],
                [2718775.4, 1272066.9],
                [2721296.2, 1270721],
                [2722837.3, 1269902.9],
                [2723021.6, 1267681.3],
                [2721723.6, 1267652.4],
                [2720985.2, 1266288.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8512', ortbez27: 'Wetzikon TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719674.8, 1266644.9],
                [2718569.6, 1265421.6],
                [2716265.9, 1265735.6],
                [2713439.8, 1266748.8],
                [2714356.8, 1269301.4],
                [2716520.3, 1269931.7],
                [2717720.2, 1269127.1],
                [2717400.1, 1267367.7],
                [2719674.8, 1266644.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4417', ortbez27: 'Ziefen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620526.5, 1255357.8],
                [2621513.3, 1253039.8],
                [2619624.4, 1251491.1],
                [2617826.2, 1251762.2],
                [2618639.9, 1253277.9],
                [2620526.5, 1255357.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4418', ortbez27: 'Reigoldswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617826.2, 1251762.2],
                [2619624.4, 1251491.1],
                [2620324.6, 1249312.1],
                [2620544.7, 1246913],
                [2618958, 1246780.1],
                [2617284.2, 1249944.1],
                [2617826.2, 1251762.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4415', ortbez27: 'Lausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2625566.8, 1258530.2], [2625841.8, 1255687.9], [2624291, 1256315.5], [2625566.8, 1258530.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4416', ortbez27: 'Bubendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620526.5, 1255357.8],
                [2622529.9, 1257517],
                [2624291, 1256315.5],
                [2624134.6, 1254702.8],
                [2622774.5, 1252642.6],
                [2621513.3, 1253039.8],
                [2620526.5, 1255357.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8505', ortbez27: 'Pfyn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711191, 1271853.1],
                [2711810.5, 1274664.7],
                [2713345.4, 1274592.6],
                [2716168.3, 1274466.6],
                [2715639.7, 1272362.1],
                [2713847.7, 1272217.6],
                [2711191, 1271853.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4422', ortbez27: 'Arisdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623227.4, 1261047],
                [2623396.9, 1263797],
                [2624696.7, 1263980.8],
                [2625885.8, 1263556.3],
                [2626475.3, 1262453.1],
                [2625166.8, 1260179.5],
                [2623227.4, 1261047]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8506', ortbez27: 'Lanzenneunforn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713345.4, 1274592.6],
                [2711810.5, 1274664.7],
                [2711180.4, 1275910.3],
                [2710122.1, 1276588.2],
                [2711343, 1277235.6],
                [2714521.4, 1276469.7],
                [2713345.4, 1274592.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4410', ortbez27: 'Liestal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624291, 1256315.5],
                [2622529.9, 1257517],
                [2620520.8, 1257821.7],
                [2617399.9, 1259677],
                [2621475.8, 1261074.6],
                [2623227.4, 1261047],
                [2625166.8, 1260179.5],
                [2626698.2, 1259473.1],
                [2625566.8, 1258530.2],
                [2624291, 1256315.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8507', ortbez27: 'H�rhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714521.4, 1276469.7],
                [2711343, 1277235.6],
                [2713490.7, 1280071.3],
                [2720435.9, 1279608.6],
                [2714521.4, 1276469.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8508', ortbez27: 'Homburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719014.9, 1275817.7],
                [2716168.3, 1274466.6],
                [2713345.4, 1274592.6],
                [2714521.4, 1276469.7],
                [2720435.9, 1279608.6],
                [2719014.9, 1275817.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4413', ortbez27: 'B�ren SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618607.8, 1256954.6],
                [2619681.6, 1256286.1],
                [2620526.5, 1255357.8],
                [2618639.9, 1253277.9],
                [2616143, 1255328.8],
                [2618607.8, 1256954.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4414', ortbez27: 'F�llinsdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623396.9, 1263797],
                [2623227.4, 1261047],
                [2621475.8, 1261074.6],
                [2621138.6, 1263334.7],
                [2621864.7, 1264359],
                [2623396.9, 1263797]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8498', ortbez27: 'Gibswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712688.5, 1241574.4],
                [2710007.7, 1240632.8],
                [2709702.2, 1241884.8],
                [2710025, 1243125.8],
                [2710460.3, 1244312.8],
                [2712688.5, 1241574.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4411', ortbez27: 'Seltisberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622529.9, 1257517],
                [2620526.5, 1255357.8],
                [2619681.6, 1256286.1],
                [2620520.8, 1257821.7],
                [2622529.9, 1257517]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8495', ortbez27: 'Schmidr�ti' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710726.2, 1251602.8],
                [2709966.5, 1253150.3],
                [2711002.3, 1254379.2],
                [2713409.2, 1254681.5],
                [2710726.2, 1251602.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4412', ortbez27: 'Nuglar' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620520.8, 1257821.7],
                [2619681.6, 1256286.1],
                [2618607.8, 1256954.6],
                [2617399.9, 1259677],
                [2620520.8, 1257821.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8496', ortbez27: 'Steg im T�sstal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713734.7, 1248473.7],
                [2716129.3, 1245345.8],
                [2716986.3, 1242250.2],
                [2717947, 1241450.3],
                [2716261.2, 1240871.6],
                [2714009.1, 1241514.1],
                [2712688.5, 1241574.4],
                [2710460.3, 1244312.8],
                [2710889.4, 1245773.2],
                [2713734.7, 1248473.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4402', ortbez27: 'Frenkendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2621138.6, 1263334.7], [2621475.8, 1261074.6], [2617399.9, 1259677], [2621138.6, 1263334.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8499', ortbez27: 'Sternenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709131.5, 1250264.8],
                [2710726.2, 1251602.8],
                [2712747.1, 1251215.5],
                [2713561, 1249738.2],
                [2709131.5, 1250264.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8500', ortbez27: 'Frauenfeld' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710022.4, 1266218.5],
                [2709858.7, 1264424.4],
                [2707605.3, 1265548.4],
                [2706772.3, 1267456.8],
                [2706383.5, 1268899.9],
                [2705438.5, 1270038.6],
                [2706248.3, 1271682.4],
                [2707143.9, 1272604.2],
                [2708971.4, 1271075.7],
                [2711191, 1271853.1],
                [2714356.8, 1269301.4],
                [2713439.8, 1266748.8],
                [2710022.4, 1266218.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8489', ortbez27: 'Wildberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705677.8, 1249613.8],
                [2704027.6, 1250033.6],
                [2702349.9, 1251212.5],
                [2703330.4, 1252205.2],
                [2702432.7, 1253403.3],
                [2703236.6, 1254928],
                [2703859.7, 1256245.9],
                [2705036, 1255702],
                [2706082.2, 1255016.2],
                [2705051.3, 1252639],
                [2707031.4, 1251226.5],
                [2707657.2, 1250131.1],
                [2705677.8, 1249613.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8487', ortbez27: 'Zell ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703859.7, 1256245.9],
                [2704646.3, 1257762.1],
                [2706099.5, 1257486.1],
                [2705036, 1255702],
                [2703859.7, 1256245.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8488', ortbez27: 'Turbenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705036, 1255702],
                [2706099.5, 1257486.1],
                [2707346.3, 1257641.3],
                [2709045.8, 1256571.3],
                [2711002.3, 1254379.2],
                [2709966.5, 1253150.3],
                [2707812, 1252550.9],
                [2706082.2, 1255016.2],
                [2705036, 1255702]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8493', ortbez27: 'Saland' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2706397.7, 1248416.1], [2705677.8, 1249613.8], [2707657.2, 1250131.1], [2706397.7, 1248416.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8494', ortbez27: 'Bauma' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713734.7, 1248473.7],
                [2710889.4, 1245773.2],
                [2709154.4, 1245774.7],
                [2707211.5, 1246933.3],
                [2706397.7, 1248416.1],
                [2707657.2, 1250131.1],
                [2709131.5, 1250264.8],
                [2713561, 1249738.2],
                [2713734.7, 1248473.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8492', ortbez27: 'Wila' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705051.3, 1252639],
                [2706082.2, 1255016.2],
                [2707812, 1252550.9],
                [2709966.5, 1253150.3],
                [2710726.2, 1251602.8],
                [2709131.5, 1250264.8],
                [2707657.2, 1250131.1],
                [2707031.4, 1251226.5],
                [2705051.3, 1252639]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8545', ortbez27: 'Rickenbach ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701432.3, 1265814.7],
                [2701391.1, 1267459.2],
                [2700161.9, 1269482.9],
                [2703288.6, 1269974.5],
                [2703213.9, 1266884.3],
                [2701432.3, 1265814.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8546', ortbez27: 'Islikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704796.3, 1268009.2],
                [2706383.5, 1268899.9],
                [2706772.3, 1267456.8],
                [2705359.2, 1266739],
                [2704796.3, 1268009.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8543', ortbez27: 'Bertschikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705015.9, 1262674],
                [2704101.6, 1263997.5],
                [2703414.2, 1265532.5],
                [2703213.9, 1266884.3],
                [2704796.3, 1268009.2],
                [2705359.2, 1266739],
                [2707605.3, 1265548.4],
                [2706339.5, 1263395.7],
                [2705015.9, 1262674]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8544', ortbez27: 'Attikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2703414.2, 1265532.5], [2701432.3, 1265814.7], [2703213.9, 1266884.3], [2703414.2, 1265532.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4450', ortbez27: 'Sissach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628537, 1256007.3],
                [2625841.8, 1255687.9],
                [2627198, 1257469.9],
                [2625566.8, 1258530.2],
                [2626698.2, 1259473.1],
                [2627722.5, 1260906.4],
                [2629800.2, 1259230.4],
                [2628537, 1256007.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4448', ortbez27: 'L�ufelfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633315.8, 1248321.1],
                [2629963.2, 1246290.1],
                [2630303.3, 1249342],
                [2629337.5, 1250446.5],
                [2631784, 1251095.4],
                [2633120, 1250072.4],
                [2633315.8, 1248321.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8547', ortbez27: 'Gachnang' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2707605.3, 1265548.4], [2705359.2, 1266739], [2706772.3, 1267456.8], [2707605.3, 1265548.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8548', ortbez27: 'Ellikon an der Thur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703288.6, 1269974.5],
                [2705438.5, 1270038.6],
                [2706383.5, 1268899.9],
                [2704796.3, 1268009.2],
                [2703213.9, 1266884.3],
                [2703288.6, 1269974.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8537', ortbez27: 'Nussbaumen TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705415.4, 1274510.2],
                [2702747.7, 1272825.9],
                [2703449.5, 1275065.8],
                [2704294, 1277383],
                [2706063.4, 1276253.9],
                [2705415.4, 1274510.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4451', ortbez27: 'Wintersingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630391.9, 1261969.6],
                [2629800.2, 1259230.4],
                [2627722.5, 1260906.4],
                [2628930.6, 1262211.7],
                [2630391.9, 1261969.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8535', ortbez27: 'Herdern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711810.5, 1274664.7],
                [2711191, 1271853.1],
                [2709378.8, 1273152.1],
                [2711180.4, 1275910.3],
                [2711810.5, 1274664.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4452', ortbez27: 'Itingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2625841.8, 1255687.9], [2625566.8, 1258530.2], [2627198, 1257469.9], [2625841.8, 1255687.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4441', ortbez27: 'Th�rnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629495.3, 1254799.9],
                [2628537, 1256007.3],
                [2630468.2, 1256448.6],
                [2630900.3, 1254778],
                [2629495.3, 1254799.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8536', ortbez27: 'H�ttwilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709378.8, 1273152.1],
                [2707143.9, 1272604.2],
                [2705415.4, 1274510.2],
                [2706063.4, 1276253.9],
                [2710122.1, 1276588.2],
                [2711180.4, 1275910.3],
                [2709378.8, 1273152.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8542', ortbez27: 'Wiesendangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701432.3, 1265814.7],
                [2703414.2, 1265532.5],
                [2704101.6, 1263997.5],
                [2702396.7, 1263513.2],
                [2701432.3, 1265814.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4445', ortbez27: 'H�felfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631784, 1251095.4],
                [2630704.4, 1252444],
                [2632560.2, 1253164.6],
                [2633972.8, 1252349.7],
                [2634037.5, 1250966.7],
                [2633120, 1250072.4],
                [2631784, 1251095.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4446', ortbez27: 'Buckten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630704.4, 1252444],
                [2631784, 1251095.4],
                [2629337.5, 1250446.5],
                [2629425.3, 1252434.6],
                [2630704.4, 1252444]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4443', ortbez27: 'Wittinsburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629425.3, 1252434.6],
                [2629495.3, 1254799.9],
                [2630900.3, 1254778],
                [2630704.4, 1252444],
                [2629425.3, 1252434.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4444', ortbez27: 'R�mlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2630704.4, 1252444], [2630900.3, 1254778], [2632560.2, 1253164.6], [2630704.4, 1252444]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4433', ortbez27: 'Ramlinsburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2624134.6, 1254702.8], [2624291, 1256315.5], [2625841.8, 1255687.9], [2624134.6, 1254702.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4434', ortbez27: 'H�lstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626668.8, 1251600.7],
                [2625056.8, 1251464.5],
                [2624134.6, 1254702.8],
                [2625841.8, 1255687.9],
                [2626527, 1252999.7],
                [2626668.8, 1251600.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4431', ortbez27: 'Bennwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625056.8, 1251464.5],
                [2626668.8, 1251600.7],
                [2627151.3, 1250252.7],
                [2626661.5, 1249012.7],
                [2624971.3, 1250178.3],
                [2625056.8, 1251464.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4432', ortbez27: 'Lampenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2622774.5, 1252642.6], [2624134.6, 1254702.8], [2625056.8, 1251464.5], [2622774.5, 1252642.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8532', ortbez27: 'Weiningen TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711191, 1271853.1],
                [2708971.4, 1271075.7],
                [2707143.9, 1272604.2],
                [2709378.8, 1273152.1],
                [2711191, 1271853.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4437', ortbez27: 'Waldenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2626624.5, 1247581.3], [2620544.7, 1246913], [2621905, 1248430.2], [2626624.5, 1247581.3]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4438', ortbez27: 'Langenbruck' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620544.7, 1246913],
                [2626624.5, 1247581.3],
                [2627847, 1245937],
                [2628773.8, 1244649.1],
                [2627279.9, 1243532],
                [2623720, 1243918],
                [2620544.7, 1246913]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8522', ortbez27: 'Aawangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2709642.7, 1262174.8], [2709858.7, 1264424.4], [2711055.9, 1263686.4], [2709642.7, 1262174.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4435', ortbez27: 'Niederdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622774.5, 1252642.6],
                [2625056.8, 1251464.5],
                [2624971.3, 1250178.3],
                [2622461.6, 1250216],
                [2622774.5, 1252642.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4436', ortbez27: 'Oberdorf BL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2621905, 1248430.2],
                [2620544.7, 1246913],
                [2620324.6, 1249312.1],
                [2622461.6, 1250216],
                [2624971.3, 1250178.3],
                [2626661.5, 1249012.7],
                [2626624.5, 1247581.3],
                [2621905, 1248430.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4425', ortbez27: 'Titterten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2620324.6, 1249312.1], [2619624.4, 1251491.1], [2622461.6, 1250216], [2620324.6, 1249312.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8525', ortbez27: 'Niederneunforn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702342.8, 1271521.9],
                [2699614.6, 1272346.8],
                [2701531.1, 1274235.1],
                [2703449.5, 1275065.8],
                [2702747.7, 1272825.9],
                [2702342.8, 1271521.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4426', ortbez27: 'Lauwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617284.2, 1249944.1],
                [2618958, 1246780.1],
                [2618133.4, 1245802.9],
                [2616390.4, 1246526],
                [2615414.4, 1247909.5],
                [2617284.2, 1249944.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8526', ortbez27: 'Oberneunforn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698611.2, 1273124.8],
                [2698073.8, 1276241.9],
                [2699881.7, 1276238.6],
                [2701531.1, 1274235.1],
                [2699614.6, 1272346.8],
                [2698611.2, 1273124.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4423', ortbez27: 'Hersberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2625166.8, 1260179.5],
                [2626475.3, 1262453.1],
                [2627722.5, 1260906.4],
                [2626698.2, 1259473.1],
                [2625166.8, 1260179.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8523', ortbez27: 'Hagenbuch ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709858.7, 1264424.4],
                [2709642.7, 1262174.8],
                [2706339.5, 1263395.7],
                [2707605.3, 1265548.4],
                [2709858.7, 1264424.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4424', ortbez27: 'Arboldswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2619624.4, 1251491.1],
                [2621513.3, 1253039.8],
                [2622774.5, 1252642.6],
                [2622461.6, 1250216],
                [2619624.4, 1251491.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8524', ortbez27: 'Uesslingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2705438.5, 1270038.6],
                [2703288.6, 1269974.5],
                [2702342.8, 1271521.9],
                [2702747.7, 1272825.9],
                [2705415.4, 1274510.2],
                [2707143.9, 1272604.2],
                [2706248.3, 1271682.4],
                [2705438.5, 1270038.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8577', ortbez27: 'Sch�nholzerswilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2730200, 1263046.8],
                [2727078.5, 1262398.3],
                [2726028.9, 1263951.5],
                [2723111.5, 1264768.6],
                [2724133.9, 1265499.5],
                [2725735.1, 1266928.8],
                [2728106.2, 1266085.7],
                [2729975, 1266793],
                [2730983.9, 1265555.6],
                [2730069.3, 1264422],
                [2730200, 1263046.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8575', ortbez27: 'B�rglen TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729975, 1266793],
                [2728106.2, 1266085.7],
                [2727088.6, 1267983.9],
                [2728293.8, 1269488.1],
                [2730401.6, 1268458.1],
                [2731677.4, 1268113.1],
                [2729975, 1266793]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8576', ortbez27: 'Mauren TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2728293.8, 1269488.1], [2731105.7, 1269948.4], [2730401.6, 1268458.1], [2728293.8, 1269488.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8581', ortbez27: 'Schocherswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2738040.4, 1266348.4], [2736192, 1266013.6], [2736425.9, 1267271.4], [2738040.4, 1266348.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8582', ortbez27: 'Dozwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2742129.9, 1269851], [2740671.7, 1270953.7], [2742168.8, 1271842.2], [2742129.9, 1269851]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8580', ortbez27: 'Amriswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2741401.7, 1266237.3],
                [2739983.3, 1265564.1],
                [2738040.4, 1266348.4],
                [2736425.9, 1267271.4],
                [2737293.8, 1268781.8],
                [2737142.9, 1270138],
                [2737231.4, 1272360],
                [2740671.7, 1270953.7],
                [2742129.9, 1269851],
                [2742675.4, 1268440.4],
                [2743223.5, 1266413.9],
                [2741401.7, 1266237.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8570', ortbez27: 'Weinfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727088.6, 1267983.9],
                [2725040.1, 1268836.9],
                [2722837.3, 1269902.9],
                [2725852.7, 1272057.8],
                [2728395.3, 1272206.4],
                [2728293.8, 1269488.1],
                [2727088.6, 1267983.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8573', ortbez27: 'Siegershausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2730927, 1275691.7],
                [2732871.3, 1275130.9],
                [2732317.9, 1274007.8],
                [2730476.6, 1272543.4],
                [2729433.1, 1273605.3],
                [2728281.2, 1274749.6],
                [2729430.3, 1276164.3],
                [2730927, 1275691.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8574', ortbez27: 'Oberhofen TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732317.9, 1274007.8],
                [2732871.3, 1275130.9],
                [2730927, 1275691.7],
                [2732523.9, 1276637.6],
                [2734905.3, 1275943.9],
                [2734092.9, 1274332.9],
                [2732317.9, 1274007.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8572', ortbez27: 'Berg TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728293.8, 1269488.1],
                [2728395.3, 1272206.4],
                [2729433.1, 1273605.3],
                [2730476.6, 1272543.4],
                [2731428.5, 1271351.7],
                [2731105.7, 1269948.4],
                [2728293.8, 1269488.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8561', ortbez27: 'Ottoberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2722837.3, 1269902.9], [2725211.5, 1274227.2], [2725852.7, 1272057.8], [2722837.3, 1269902.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8560', ortbez27: 'M�rstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722837.3, 1269902.9],
                [2721296.2, 1270721],
                [2721586.6, 1272681.8],
                [2720962.1, 1274507.5],
                [2722174.9, 1274957.8],
                [2723410.8, 1275265.5],
                [2725211.5, 1274227.2],
                [2722837.3, 1269902.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4465', ortbez27: 'Hemmiken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2632623.2, 1260513.6], [2634395.7, 1261917.3], [2635213, 1259607.4], [2632623.2, 1260513.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8565', ortbez27: 'Hugelshofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725211.5, 1274227.2],
                [2726831.1, 1274268.8],
                [2728395.3, 1272206.4],
                [2725852.7, 1272057.8],
                [2725211.5, 1274227.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4466', ortbez27: 'Ormalingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632623.2, 1260513.6],
                [2635213, 1259607.4],
                [2634410.8, 1257055.2],
                [2631862, 1258918.5],
                [2632623.2, 1260513.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8566', ortbez27: 'Ellighausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728395.3, 1272206.4],
                [2726831.1, 1274268.8],
                [2725211.5, 1274227.2],
                [2725795.1, 1275646.2],
                [2727958.4, 1277384.7],
                [2729430.3, 1276164.3],
                [2728281.2, 1274749.6],
                [2729433.1, 1273605.3],
                [2728395.3, 1272206.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4463', ortbez27: 'Buus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634395.7, 1261917.3],
                [2632623.2, 1260513.6],
                [2630391.9, 1261969.6],
                [2632064.7, 1263893.5],
                [2634833.9, 1263176.5],
                [2634395.7, 1261917.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4464', ortbez27: 'Maisprach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628930.6, 1262211.7],
                [2629795, 1265015.5],
                [2631226.9, 1265181.3],
                [2632064.7, 1263893.5],
                [2630391.9, 1261969.6],
                [2628930.6, 1262211.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8564', ortbez27: 'Engwilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725795.1, 1275646.2],
                [2725211.5, 1274227.2],
                [2723410.8, 1275265.5],
                [2722174.9, 1274957.8],
                [2721867.7, 1276603.8],
                [2722428.8, 1278280.1],
                [2723642, 1278072.9],
                [2724914.2, 1278301],
                [2724519.6, 1276400.5],
                [2725795.1, 1275646.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4469', ortbez27: 'Anwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639205.4, 1256327.4],
                [2638406.3, 1254988.4],
                [2636576.4, 1255804.8],
                [2637883, 1257078.7],
                [2639205.4, 1256327.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8553', ortbez27: 'H�ttlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718459.5, 1270296.1],
                [2717720.2, 1269127.1],
                [2716520.3, 1269931.7],
                [2714356.8, 1269301.4],
                [2713847.7, 1272217.6],
                [2715639.7, 1272362.1],
                [2716973.7, 1272165.8],
                [2718775.4, 1272066.9],
                [2718459.5, 1270296.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8554', ortbez27: 'Bonau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2721586.6, 1272681.8], [2721296.2, 1270721], [2718775.4, 1272066.9], [2721586.6, 1272681.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4467', ortbez27: 'Rothenfluh' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635213, 1259607.4],
                [2638598.1, 1260394.4],
                [2637883, 1257078.7],
                [2636576.4, 1255804.8],
                [2634410.8, 1257055.2],
                [2635213, 1259607.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4468', ortbez27: 'Kienberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640465.2, 1256848.7],
                [2641059.3, 1255344.5],
                [2641422.7, 1253465.2],
                [2640311.7, 1252327.4],
                [2638406.3, 1254988.4],
                [2639205.4, 1256327.4],
                [2640465.2, 1256848.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8552', ortbez27: 'Felben-Wellhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2711191, 1271853.1], [2713847.7, 1272217.6], [2714356.8, 1269301.4], [2711191, 1271853.1]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4457', ortbez27: 'Diegten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626527, 1252999.7],
                [2629425.3, 1252434.6],
                [2629337.5, 1250446.5],
                [2627151.3, 1250252.7],
                [2626668.8, 1251600.7],
                [2626527, 1252999.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4458', ortbez27: 'Eptingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629963.2, 1246290.1],
                [2627847, 1245937],
                [2626624.5, 1247581.3],
                [2626661.5, 1249012.7],
                [2627151.3, 1250252.7],
                [2629337.5, 1250446.5],
                [2630303.3, 1249342],
                [2629963.2, 1246290.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8558', ortbez27: 'Raperswilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722428.8, 1278280.1],
                [2721867.7, 1276603.8],
                [2719014.9, 1275817.7],
                [2720435.9, 1279608.6],
                [2721384, 1278458.6],
                [2722598, 1278785.8],
                [2723642, 1278072.9],
                [2722428.8, 1278280.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4455', ortbez27: 'Zunzgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628537, 1256007.3],
                [2629495.3, 1254799.9],
                [2626527, 1252999.7],
                [2625841.8, 1255687.9],
                [2628537, 1256007.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8555', ortbez27: 'M�llheim Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719014.9, 1275817.7],
                [2719680.7, 1274308.8],
                [2718775.4, 1272066.9],
                [2716973.7, 1272165.8],
                [2715639.7, 1272362.1],
                [2716168.3, 1274466.6],
                [2719014.9, 1275817.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4456', ortbez27: 'Tenniken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2626527, 1252999.7], [2629495.3, 1254799.9], [2629425.3, 1252434.6], [2626527, 1252999.7]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8556', ortbez27: 'Illhart' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718775.4, 1272066.9],
                [2719680.7, 1274308.8],
                [2719014.9, 1275817.7],
                [2721867.7, 1276603.8],
                [2722174.9, 1274957.8],
                [2720962.1, 1274507.5],
                [2721586.6, 1272681.8],
                [2718775.4, 1272066.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4461', ortbez27: 'B�ckten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2629800.2, 1259230.4], [2630468.2, 1256448.6], [2628537, 1256007.3], [2629800.2, 1259230.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4462', ortbez27: 'Rickenbach BL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629800.2, 1259230.4],
                [2630391.9, 1261969.6],
                [2632623.2, 1260513.6],
                [2631862, 1258918.5],
                [2629800.2, 1259230.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8610', ortbez27: 'Uster' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694953.4, 1243135.2],
                [2693875.6, 1245290.6],
                [2694993.5, 1245923.9],
                [2694224.7, 1247188.4],
                [2695845.5, 1248811.8],
                [2697336.3, 1247085.6],
                [2698058.7, 1246051.4],
                [2699404.9, 1245781.3],
                [2699793.4, 1244220.4],
                [2698127.6, 1242825.8],
                [2696892.4, 1243035.7],
                [2694953.4, 1243135.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8607', ortbez27: 'Aathal-Seegr�ben' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702276.8, 1244496.4],
                [2700250.2, 1242776.5],
                [2699793.4, 1244220.4],
                [2699404.9, 1245781.3],
                [2702276.8, 1244496.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4460', ortbez27: 'Gelterkinden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630468.2, 1256448.6],
                [2629800.2, 1259230.4],
                [2631862, 1258918.5],
                [2634410.8, 1257055.2],
                [2633235.9, 1255430.3],
                [2632560.2, 1253164.6],
                [2630900.3, 1254778],
                [2630468.2, 1256448.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8608', ortbez27: 'Bubikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702750.4, 1237322.3],
                [2703729.2, 1239434],
                [2704979.1, 1237622.3],
                [2705292.5, 1236300.4],
                [2705257.6, 1235024.2],
                [2702392.8, 1234732.3],
                [2701719, 1235859.8],
                [2702750.4, 1237322.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4514', ortbez27: 'Lommiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602083.2, 1232663],
                [2603831.8, 1230505.4],
                [2602058.6, 1229636.2],
                [2601330.8, 1230662.2],
                [2601056.9, 1231916.7],
                [2602083.2, 1232663]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8614', ortbez27: 'Sulzbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2698127.6, 1242825.8], [2699793.4, 1244220.4], [2700250.2, 1242776.5], [2698127.6, 1242825.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4512', ortbez27: 'Bellach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605950.4, 1229206],
                [2605514.5, 1227432.7],
                [2603530.6, 1227965.7],
                [2602058.6, 1229636.2],
                [2603831.8, 1230505.4],
                [2605950.4, 1229206]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8602', ortbez27: 'Wangen b. D�bendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691631.8, 1250282.7],
                [2689164.6, 1251546.1],
                [2691111.5, 1252598.8],
                [2690423.4, 1253732.2],
                [2691983.2, 1254082.1],
                [2693357.3, 1253009.9],
                [2692792.2, 1251404.1],
                [2691631.8, 1250282.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4515', ortbez27: 'Oberdorf SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603831.8, 1230505.4],
                [2602083.2, 1232663],
                [2605946.1, 1234234.6],
                [2605875.3, 1232817.6],
                [2606571, 1230402.9],
                [2605950.4, 1229206],
                [2603831.8, 1230505.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8600', ortbez27: 'D�bendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687288.3, 1249060.6],
                [2687528.6, 1251374.3],
                [2689164.6, 1251546.1],
                [2691631.8, 1250282.7],
                [2692291.2, 1249153.9],
                [2690979, 1248678.4],
                [2689274, 1248404.4],
                [2687938.9, 1247574],
                [2687288.3, 1249060.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8605', ortbez27: 'Gutenswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696217.6, 1250461],
                [2697655.9, 1250177.3],
                [2697511.7, 1248882.9],
                [2695845.5, 1248811.8],
                [2696217.6, 1250461]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8606', ortbez27: 'Greifensee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693875.6, 1245290.6],
                [2691944.6, 1246104.3],
                [2692349.4, 1247394.9],
                [2694376.2, 1248663.7],
                [2695845.5, 1248811.8],
                [2694224.7, 1247188.4],
                [2694993.5, 1245923.9],
                [2693875.6, 1245290.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8604', ortbez27: 'Volketswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693357.3, 1253009.9],
                [2694579.3, 1251657],
                [2696217.6, 1250461],
                [2695845.5, 1248811.8],
                [2694376.2, 1248663.7],
                [2692349.4, 1247394.9],
                [2692291.2, 1249153.9],
                [2691631.8, 1250282.7],
                [2692792.2, 1251404.1],
                [2693357.3, 1253009.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8594', ortbez27: 'G�ttingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2740484.2, 1278942.1],
                [2743168.1, 1277913.7],
                [2742168.8, 1271842.2],
                [2740671.7, 1270953.7],
                [2737231.4, 1272360],
                [2740484.2, 1278942.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8592', ortbez27: 'Uttwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2743168.1, 1277913.7], [2747128.1, 1276396.2], [2742168.8, 1271842.2], [2743168.1, 1277913.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4497', ortbez27: 'R�nenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633972.8, 1252349.7],
                [2632560.2, 1253164.6],
                [2633235.9, 1255430.3],
                [2634990.9, 1254442.9],
                [2635303, 1253199.8],
                [2633972.8, 1252349.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8597', ortbez27: 'Landschlacht' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2736644.7, 1280413.4], [2738844, 1279570.6], [2734905.3, 1275943.9], [2736644.7, 1280413.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8598', ortbez27: 'Bottighofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2734425.1, 1280077.2],
                [2734905.3, 1275943.9],
                [2732523.9, 1276637.6],
                [2732997.4, 1279943.5],
                [2734425.1, 1280077.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4495', ortbez27: 'Zeglingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636493.5, 1250436.5],
                [2634037.5, 1250966.7],
                [2633972.8, 1252349.7],
                [2635303, 1253199.8],
                [2637962.4, 1251750.2],
                [2636493.5, 1250436.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8595', ortbez27: 'Altnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2738844, 1279570.6],
                [2740484.2, 1278942.1],
                [2737231.4, 1272360],
                [2735900.4, 1274469.4],
                [2734905.3, 1275943.9],
                [2738844, 1279570.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8596', ortbez27: 'M�nsterlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2734425.1, 1280077.2], [2736644.7, 1280413.4], [2734905.3, 1275943.9], [2734425.1, 1280077.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8585', ortbez27: 'Herrenhof' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737231.4, 1272360],
                [2737142.9, 1270138],
                [2735483.4, 1271301.4],
                [2733805.5, 1271247.6],
                [2732664.5, 1270708],
                [2731428.5, 1271351.7],
                [2730476.6, 1272543.4],
                [2732317.9, 1274007.8],
                [2734092.9, 1274332.9],
                [2734905.3, 1275943.9],
                [2735900.4, 1274469.4],
                [2737231.4, 1272360]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8586', ortbez27: 'Engishofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2734618.5, 1266463.1],
                [2732637.1, 1267062],
                [2733363.3, 1268938.9],
                [2732664.5, 1270708],
                [2733805.5, 1271247.6],
                [2735483.4, 1271301.4],
                [2737142.9, 1270138],
                [2737293.8, 1268781.8],
                [2736425.9, 1267271.4],
                [2736192, 1266013.6],
                [2734618.5, 1266463.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8583', ortbez27: 'Sulgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2734618.5, 1266463.1], [2733563.9, 1264939.2], [2732637.1, 1267062], [2734618.5, 1266463.1]]],
            [
              [
                [2732637.1, 1267062],
                [2730983.9, 1265555.6],
                [2729975, 1266793],
                [2731677.4, 1268113.1],
                [2733363.3, 1268938.9],
                [2732637.1, 1267062]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4500', ortbez27: 'Solothurn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608307.8, 1227593.1],
                [2605514.5, 1227432.7],
                [2605950.4, 1229206],
                [2606571, 1230402.9],
                [2607958.8, 1230141.8],
                [2608307.8, 1227593.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8584', ortbez27: 'Leimbach TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732664.5, 1270708],
                [2733363.3, 1268938.9],
                [2731677.4, 1268113.1],
                [2730401.6, 1268458.1],
                [2731105.7, 1269948.4],
                [2731428.5, 1271351.7],
                [2732664.5, 1270708]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8590', ortbez27: 'Romanshorn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747128.1, 1276396.2],
                [2751631.5, 1272079.1],
                [2745483.7, 1268453.9],
                [2742675.4, 1268440.4],
                [2742129.9, 1269851],
                [2742168.8, 1271842.2],
                [2747128.1, 1276396.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8588', ortbez27: 'Zihlschlacht' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2738040.4, 1266348.4],
                [2739983.3, 1265564.1],
                [2738820.8, 1263931.2],
                [2737343.7, 1263628.1],
                [2735429.7, 1263406.5],
                [2736192, 1266013.6],
                [2738040.4, 1266348.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4493', ortbez27: 'Wenslingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634410.8, 1257055.2],
                [2636576.4, 1255804.8],
                [2634990.9, 1254442.9],
                [2633235.9, 1255430.3],
                [2634410.8, 1257055.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4494', ortbez27: 'Oltingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2637962.4, 1251750.2],
                [2635303, 1253199.8],
                [2634990.9, 1254442.9],
                [2636576.4, 1255804.8],
                [2638406.3, 1254988.4],
                [2640311.7, 1252327.4],
                [2637962.4, 1251750.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8640', ortbez27: 'Hurden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704443.9, 1230342.9],
                [2703078.6, 1229408.6],
                [2702689.3, 1231217.9],
                [2705662.2, 1232290.5],
                [2704443.9, 1230342.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8645', ortbez27: 'Jona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708872.6, 1233139.6],
                [2708471.6, 1231392],
                [2707797.2, 1229882.8],
                [2706227.6, 1229684.1],
                [2704443.9, 1230342.9],
                [2705662.2, 1232290.5],
                [2702689.3, 1231217.9],
                [2703578.9, 1233975.9],
                [2705257.6, 1235024.2],
                [2706481.2, 1235596.1],
                [2708872.6, 1233139.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8646', ortbez27: 'Wagen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2708471.6, 1231392], [2708872.6, 1233139.6], [2710528.5, 1233201.2], [2708471.6, 1231392]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4543', ortbez27: 'Deitingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612376.6, 1229298.1],
                [2611868, 1231109.5],
                [2613742.3, 1231068.7],
                [2615354.3, 1230273.1],
                [2615774.4, 1227883.6],
                [2612376.6, 1229298.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8633', ortbez27: 'Wolfhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2705257.6, 1235024.2], [2703578.9, 1233975.9], [2702392.8, 1234732.3], [2705257.6, 1235024.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8634', ortbez27: 'Hombrechtikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702392.8, 1234732.3],
                [2699677.7, 1233251.9],
                [2698939.5, 1234434],
                [2697807.6, 1235462.5],
                [2699062.2, 1236186.3],
                [2701719, 1235859.8],
                [2702392.8, 1234732.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8632', ortbez27: 'Tann' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704979.1, 1237622.3],
                [2708145.6, 1238370.9],
                [2709209.7, 1236575.3],
                [2707629.4, 1236218.2],
                [2706481.2, 1235596.1],
                [2705292.5, 1236300.4],
                [2704979.1, 1237622.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4537', ortbez27: 'Wiedlisbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613742.3, 1231068.7],
                [2614237.2, 1234882.9],
                [2615823.7, 1234403.3],
                [2617963.1, 1231925.7],
                [2615354.3, 1230273.1],
                [2613742.3, 1231068.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4538', ortbez27: 'Oberbipp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615823.7, 1234403.3],
                [2617492.8, 1235833.3],
                [2619366.6, 1232471.5],
                [2617963.1, 1231925.7],
                [2615823.7, 1234403.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8637', ortbez27: 'Laupen ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2712162.5, 1235897.2], [2713314, 1237034.4], [2713391.4, 1235612.2], [2712162.5, 1235897.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8638', ortbez27: 'Goldingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713314, 1237034.4],
                [2716261.2, 1240871.6],
                [2717947, 1241450.3],
                [2719209, 1239995],
                [2718367, 1239066.8],
                [2717324.5, 1238255.9],
                [2716510.7, 1237156],
                [2716164.2, 1235418.5],
                [2714581.6, 1234301.9],
                [2713391.4, 1235612.2],
                [2713314, 1237034.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4536', ortbez27: 'Attiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611514.4, 1233529.9],
                [2611102, 1235861.5],
                [2614237.2, 1234882.9],
                [2613742.3, 1231068.7],
                [2611514.4, 1233529.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8636', ortbez27: 'Wald ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716261.2, 1240871.6],
                [2713314, 1237034.4],
                [2712162.5, 1235897.2],
                [2711350.8, 1234915.9],
                [2709587.4, 1234705.1],
                [2709209.7, 1236575.3],
                [2709936.7, 1237595.8],
                [2710007.7, 1240632.8],
                [2712688.5, 1241574.4],
                [2714009.1, 1241514.1],
                [2716261.2, 1240871.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8625', ortbez27: 'Gossau ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701501.4, 1239946.1],
                [2700245.1, 1239008],
                [2698289.6, 1238114.1],
                [2697658.8, 1239222],
                [2696618.6, 1239989.6],
                [2698127.6, 1242825.8],
                [2700250.2, 1242776.5],
                [2701079.3, 1241776.6],
                [2701501.4, 1239946.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4542', ortbez27: 'Luterbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2611868, 1231109.5],
                [2612376.6, 1229298.1],
                [2610566.6, 1228517.9],
                [2610079.3, 1229957.8],
                [2611868, 1231109.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8626', ortbez27: 'Ottikon (Gossau ZH)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703729.2, 1239434],
                [2702750.4, 1237322.3],
                [2700245.1, 1239008],
                [2701501.4, 1239946.1],
                [2703729.2, 1239434]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4539', ortbez27: 'Farnern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617492.8, 1235833.3],
                [2615823.7, 1234403.3],
                [2614237.2, 1234882.9],
                [2611102, 1235861.5],
                [2612820.8, 1236753.7],
                [2615935.5, 1236960.5],
                [2617492.8, 1235833.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8623', ortbez27: 'Wetzikon ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2703042.4, 1243465.5], [2704568.1, 1244821.1], [2706335.6, 1242847], [2703042.4, 1243465.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8630', ortbez27: 'R�ti ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709209.7, 1236575.3],
                [2709587.4, 1234705.1],
                [2708872.6, 1233139.6],
                [2706481.2, 1235596.1],
                [2707629.4, 1236218.2],
                [2709209.7, 1236575.3]
              ]
            ],
            [[[2705292.5, 1236300.4], [2706481.2, 1235596.1], [2705257.6, 1235024.2], [2705292.5, 1236300.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8627', ortbez27: 'Gr�ningen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698289.6, 1238114.1],
                [2700245.1, 1239008],
                [2702750.4, 1237322.3],
                [2701719, 1235859.8],
                [2699062.2, 1236186.3],
                [2698289.6, 1238114.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4528', ortbez27: 'Zuchwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610566.6, 1228517.9],
                [2610625.5, 1227080.4],
                [2608307.8, 1227593.1],
                [2607958.8, 1230141.8],
                [2610079.3, 1229957.8],
                [2610566.6, 1228517.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4533', ortbez27: 'Riedholz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610079.3, 1229957.8],
                [2607958.8, 1230141.8],
                [2608875.6, 1232986],
                [2611514.4, 1233529.9],
                [2611868, 1231109.5],
                [2610079.3, 1229957.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8617', ortbez27: 'M�nchaltorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696618.6, 1239989.6],
                [2695490.8, 1240921.4],
                [2694953.4, 1243135.2],
                [2696892.4, 1243035.7],
                [2698127.6, 1242825.8],
                [2696618.6, 1239989.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4534', ortbez27: 'Flumenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2613742.3, 1231068.7], [2611868, 1231109.5], [2611514.4, 1233529.9], [2613742.3, 1231068.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8618', ortbez27: 'Oetwil am See' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698289.6, 1238114.1],
                [2699062.2, 1236186.3],
                [2697807.6, 1235462.5],
                [2696525.4, 1235722.4],
                [2695294.7, 1236691.4],
                [2696392.5, 1237630.9],
                [2697658.8, 1239222],
                [2698289.6, 1238114.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8615', ortbez27: 'Wermatswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695845.5, 1248811.8],
                [2697511.7, 1248882.9],
                [2698734.9, 1247621.4],
                [2699930.7, 1247989.8],
                [2698058.7, 1246051.4],
                [2697336.3, 1247085.6],
                [2695845.5, 1248811.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4522', ortbez27: 'R�ttenen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605946.1, 1234234.6],
                [2608875.6, 1232986],
                [2607958.8, 1230141.8],
                [2606571, 1230402.9],
                [2605875.3, 1232817.6],
                [2605946.1, 1234234.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8620', ortbez27: 'Wetzikon ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700250.2, 1242776.5],
                [2702276.8, 1244496.4],
                [2703042.4, 1243465.5],
                [2706335.6, 1242847],
                [2706011.8, 1241570.6],
                [2703940.2, 1241313.3],
                [2703729.2, 1239434],
                [2701501.4, 1239946.1],
                [2701079.3, 1241776.6],
                [2700250.2, 1242776.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4525', ortbez27: 'Balm b. G�nsberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609203.8, 1235850.3],
                [2608875.6, 1232986],
                [2605946.1, 1234234.6],
                [2607529.9, 1235636],
                [2609203.8, 1235850.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4524', ortbez27: 'G�nsberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608875.6, 1232986],
                [2609203.8, 1235850.3],
                [2611102, 1235861.5],
                [2611514.4, 1233529.9],
                [2608875.6, 1232986]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4577', ortbez27: 'Hessigkofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602843.2, 1221200.6],
                [2601362.6, 1219585.7],
                [2600591.2, 1220994],
                [2601285.5, 1222967.6],
                [2602843.2, 1221200.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4578', ortbez27: 'Bibern SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2600172.6, 1222318.7], [2601285.5, 1222967.6], [2600591.2, 1220994], [2600172.6, 1222318.7]]],
            [[[2601285.5, 1222967.6], [2602571.9, 1222511.6], [2602843.2, 1221200.6], [2601285.5, 1222967.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4576', ortbez27: 'Tscheppach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604513.9, 1220773.6],
                [2602843.2, 1221200.6],
                [2602571.9, 1222511.6],
                [2604155.5, 1222492],
                [2604513.9, 1220773.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4581', ortbez27: 'K�ttigkofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604155.5, 1222492],
                [2606484.6, 1222935.1],
                [2605862.1, 1220629.4],
                [2604513.9, 1220773.6],
                [2604155.5, 1222492]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4579', ortbez27: 'Gossliwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2599231.5, 1219302.1], [2599162.1, 1221273.9], [2600591.2, 1220994], [2599231.5, 1219302.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4573', ortbez27: 'Lohn-Ammannsegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606484.6, 1222935.1],
                [2605838.3, 1225489.4],
                [2607792.3, 1225618.6],
                [2607681.3, 1223739.5],
                [2606484.6, 1222935.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4574', ortbez27: 'Nennigkofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604398.3, 1224652.4],
                [2602884, 1226783.8],
                [2603530.6, 1227965.7],
                [2605514.5, 1227432.7],
                [2605838.3, 1225489.4],
                [2604398.3, 1224652.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4571', ortbez27: 'L�terkofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606484.6, 1222935.1],
                [2604155.5, 1222492],
                [2604398.3, 1224652.4],
                [2605838.3, 1225489.4],
                [2606484.6, 1222935.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4562', ortbez27: 'Biberist' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2608307.8, 1227593.1],
                [2610625.5, 1227080.4],
                [2612260.3, 1225833.1],
                [2611276.1, 1225002.1],
                [2610406.9, 1223878.9],
                [2609192.1, 1224281.9],
                [2607681.3, 1223739.5],
                [2607792.3, 1225618.6],
                [2605838.3, 1225489.4],
                [2605514.5, 1227432.7],
                [2608307.8, 1227593.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4565', ortbez27: 'Recherswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612680, 1222574],
                [2611262.1, 1222001],
                [2610406.9, 1223878.9],
                [2611276.1, 1225002.1],
                [2612260.3, 1225833.1],
                [2614230.7, 1224404.4],
                [2613977.5, 1222619.9],
                [2612680, 1222574]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4566', ortbez27: 'Oekingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614710.8, 1226645.7],
                [2615514.5, 1225647.9],
                [2615501.6, 1223964.7],
                [2614230.7, 1224404.4],
                [2612260.3, 1225833.1],
                [2614710.8, 1226645.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4553', ortbez27: 'Subingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2614710.8, 1226645.7],
                [2612260.3, 1225833.1],
                [2612376.6, 1229298.1],
                [2615774.4, 1227883.6],
                [2614710.8, 1226645.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4554', ortbez27: 'Etziken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2615514.5, 1225647.9],
                [2614710.8, 1226645.7],
                [2615774.4, 1227883.6],
                [2616749.4, 1226346.7],
                [2615514.5, 1225647.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4552', ortbez27: 'Derendingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612376.6, 1229298.1],
                [2612260.3, 1225833.1],
                [2610625.5, 1227080.4],
                [2610566.6, 1228517.9],
                [2612376.6, 1229298.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4558', ortbez27: 'Heinrichswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2613977.5, 1222619.9],
                [2614230.7, 1224404.4],
                [2615501.6, 1223964.7],
                [2615342.7, 1222678.6],
                [2613977.5, 1222619.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8706', ortbez27: 'Meilen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694265.2, 1237938.5],
                [2692601.5, 1234520.3],
                [2690446.2, 1235317.4],
                [2687819.9, 1237101.6],
                [2689995.9, 1237909.2],
                [2691667.4, 1239298.5],
                [2692968.1, 1239861.5],
                [2694265.2, 1237938.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8703', ortbez27: 'Erlenbach ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2686970.8, 1238373.8], [2686090.6, 1239934], [2688372.2, 1239617.4], [2686970.8, 1238373.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4556', ortbez27: 'Aeschi SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2617960.1, 1226837.8],
                [2618122.3, 1225382.1],
                [2618341.5, 1223753.5],
                [2617272.5, 1224430.8],
                [2615501.6, 1223964.7],
                [2615514.5, 1225647.9],
                [2616749.4, 1226346.7],
                [2617960.1, 1226837.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8704', ortbez27: 'Herrliberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686970.8, 1238373.8],
                [2688372.2, 1239617.4],
                [2689026.8, 1240765.4],
                [2690284.7, 1240602],
                [2691667.4, 1239298.5],
                [2689995.9, 1237909.2],
                [2687819.9, 1237101.6],
                [2686970.8, 1238373.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8707', ortbez27: 'Uetikon am See' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2695294.7, 1236691.4], [2692601.5, 1234520.3], [2694265.2, 1237938.5], [2695294.7, 1236691.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8708', ortbez27: 'M�nnedorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696525.4, 1235722.4],
                [2695253.8, 1232434.1],
                [2693959.4, 1233513.1],
                [2692601.5, 1234520.3],
                [2695294.7, 1236691.4],
                [2696525.4, 1235722.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4613', ortbez27: 'Rickenbach SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2629963.2, 1246290.1], [2632156.4, 1245696.4], [2631723.6, 1242591.9], [2629963.2, 1246290.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4614', ortbez27: 'H�gendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2627847, 1245937],
                [2629963.2, 1246290.1],
                [2631723.6, 1242591.9],
                [2629853.9, 1241975.1],
                [2627279.9, 1243532],
                [2628773.8, 1244649.1],
                [2627847, 1245937]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8702', ortbez27: 'Zollikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686791.8, 1245453.1],
                [2687547.7, 1243121.8],
                [2685320.5, 1241597.2],
                [2684862.5, 1242775.3],
                [2683546.3, 1243469.6],
                [2685348.8, 1244768.9],
                [2686791.8, 1245453.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4600', ortbez27: 'Olten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632156.4, 1245696.4],
                [2636334.4, 1246712.1],
                [2637060.4, 1245379.5],
                [2636322.1, 1242979.4],
                [2634797.5, 1241338.9],
                [2632833.8, 1241658.6],
                [2631723.6, 1242591.9],
                [2632156.4, 1245696.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8700', ortbez27: 'K�snacht ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686090.6, 1239934],
                [2685320.5, 1241597.2],
                [2687547.7, 1243121.8],
                [2689007.2, 1242486.9],
                [2689026.8, 1240765.4],
                [2688372.2, 1239617.4],
                [2686090.6, 1239934]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4585', ortbez27: 'Biezwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599720.9, 1216759.8],
                [2598255.2, 1216367.5],
                [2597575.6, 1218734],
                [2599231.5, 1219302.1],
                [2599720.9, 1216759.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4583', ortbez27: 'M�hledorf SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603946.9, 1219451.4],
                [2601362.6, 1219585.7],
                [2602843.2, 1221200.6],
                [2604513.9, 1220773.6],
                [2603946.9, 1219451.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4584', ortbez27: 'L�terswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601795.3, 1217619.8],
                [2599720.9, 1216759.8],
                [2599231.5, 1219302.1],
                [2600591.2, 1220994],
                [2601362.6, 1219585.7],
                [2601795.3, 1217619.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8737', ortbez27: 'Gommiswald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720736.4, 1232305.7],
                [2719063.6, 1232128.8],
                [2717952.5, 1232785.4],
                [2718976.9, 1234160.5],
                [2720187.1, 1234676.4],
                [2720736.4, 1232305.7]
              ]
            ],
            [[[2722069, 1234403.5], [2723866, 1234636.6], [2723763.1, 1233390.3], [2722069, 1234403.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8738', ortbez27: 'Uetliburg SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720187.1, 1234676.4],
                [2722069, 1234403.5],
                [2723763.1, 1233390.3],
                [2722337.5, 1232820.5],
                [2720736.4, 1232305.7],
                [2720187.1, 1234676.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8735', ortbez27: 'R�eterswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718249.7, 1235760.7],
                [2718976.9, 1234160.5],
                [2717952.5, 1232785.4],
                [2716821.9, 1233536.8],
                [2715228.3, 1232925.6],
                [2714581.6, 1234301.9],
                [2716164.2, 1235418.5],
                [2716510.7, 1237156],
                [2717324.5, 1238255.9],
                [2718367, 1239066.8],
                [2719209, 1239995],
                [2720393.7, 1240575.1],
                [2720285.2, 1238992.5],
                [2718249.7, 1235760.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4588', ortbez27: 'Unterramsern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601362.6, 1219585.7],
                [2603946.9, 1219451.4],
                [2603186.1, 1218196.8],
                [2601795.3, 1217619.8],
                [2601362.6, 1219585.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8739', ortbez27: 'Rieden SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2722337.5, 1232820.5], [2723763.1, 1233390.3], [2726327.1, 1230378], [2722337.5, 1232820.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8730', ortbez27: 'Uznach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719063.6, 1232128.8],
                [2717221.6, 1230057.6],
                [2715865.7, 1229300.2],
                [2715758.4, 1230907.2],
                [2715228.3, 1232925.6],
                [2716821.9, 1233536.8],
                [2717952.5, 1232785.4],
                [2719063.6, 1232128.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8727', ortbez27: 'Walde SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718249.7, 1235760.7],
                [2720285.2, 1238992.5],
                [2721837.8, 1237924.9],
                [2721369.7, 1236637.2],
                [2718249.7, 1235760.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4633', ortbez27: 'Hauenstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2632156.4, 1245696.4], [2629963.2, 1246290.1], [2633315.8, 1248321.1], [2632156.4, 1245696.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8733', ortbez27: 'Eschenbach SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714581.6, 1234301.9],
                [2713323.4, 1232531],
                [2711638.8, 1232435.4],
                [2710528.5, 1233201.2],
                [2711350.8, 1234915.9],
                [2712162.5, 1235897.2],
                [2713391.4, 1235612.2],
                [2714581.6, 1234301.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4634', ortbez27: 'Wisen SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2634037.5, 1250966.7],
                [2636493.5, 1250436.5],
                [2635130.2, 1247970.2],
                [2633315.8, 1248321.1],
                [2633120, 1250072.4],
                [2634037.5, 1250966.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8734', ortbez27: 'Ermenswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711350.8, 1234915.9],
                [2710528.5, 1233201.2],
                [2708872.6, 1233139.6],
                [2709587.4, 1234705.1],
                [2711350.8, 1234915.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4632', ortbez27: 'Trimbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632156.4, 1245696.4],
                [2633315.8, 1248321.1],
                [2635130.2, 1247970.2],
                [2636334.4, 1246712.1],
                [2632156.4, 1245696.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8732', ortbez27: 'Neuhaus SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2713323.4, 1232531], [2714581.6, 1234301.9], [2715228.3, 1232925.6], [2713323.4, 1232531]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8722', ortbez27: 'Kaltbrunn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726327.1, 1230378],
                [2727040.7, 1229074],
                [2725227.8, 1228342.3],
                [2723248.9, 1229898.5],
                [2721355.3, 1228872.4],
                [2717221.6, 1230057.6],
                [2719063.6, 1232128.8],
                [2720736.4, 1232305.7],
                [2722337.5, 1232820.5],
                [2726327.1, 1230378]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4625', ortbez27: 'Oberbuchsiten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623795, 1238485.8],
                [2622094.3, 1239975.9],
                [2623267.8, 1241294.6],
                [2625490.7, 1242303.5],
                [2626302.4, 1240545.7],
                [2626010.1, 1238933.9],
                [2623795, 1238485.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4626', ortbez27: 'Niederbuchsiten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628488.5, 1236779.1],
                [2625634.7, 1236169.4],
                [2623795, 1238485.8],
                [2626010.1, 1238933.9],
                [2628488.5, 1236779.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8726', ortbez27: 'Ricken SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722069, 1234403.5],
                [2720187.1, 1234676.4],
                [2718976.9, 1234160.5],
                [2718249.7, 1235760.7],
                [2721369.7, 1236637.2],
                [2721837.8, 1237924.9],
                [2724398.5, 1236369.2],
                [2722307.3, 1235711.1],
                [2722069, 1234403.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4623', ortbez27: 'Neuendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626010.1, 1238933.9],
                [2626302.4, 1240545.7],
                [2627690.1, 1240364.4],
                [2628488.5, 1236779.1],
                [2626010.1, 1238933.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8723', ortbez27: 'Rufi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727728.9, 1227612.7],
                [2726795.1, 1224752.3],
                [2725454.4, 1225626.4],
                [2720444, 1226906],
                [2721355.3, 1228872.4],
                [2723248.9, 1229898.5],
                [2725227.8, 1228342.3],
                [2727040.7, 1229074],
                [2727728.9, 1227612.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4624', ortbez27: 'H�rkingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630478.3, 1238404.9],
                [2628488.5, 1236779.1],
                [2627690.1, 1240364.4],
                [2629853.9, 1241975.1],
                [2630880.5, 1239986.8],
                [2630478.3, 1238404.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4629', ortbez27: 'Fulenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630478.3, 1238404.9],
                [2631718.3, 1237621.1],
                [2630453.3, 1235305.1],
                [2628488.5, 1236779.1],
                [2630478.3, 1238404.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8713', ortbez27: 'Uerikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2700980.6, 1230632.1], [2698799.4, 1229957.4], [2699677.7, 1233251.9], [2700980.6, 1230632.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8714', ortbez27: 'Feldbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700980.6, 1230632.1],
                [2699677.7, 1233251.9],
                [2702392.8, 1234732.3],
                [2703578.9, 1233975.9],
                [2702689.3, 1231217.9],
                [2700980.6, 1230632.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4628', ortbez27: 'Wolfwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629683.4, 1234075.1],
                [2626380.5, 1234118.6],
                [2624159.4, 1234331.3],
                [2625634.7, 1236169.4],
                [2628488.5, 1236779.1],
                [2630453.3, 1235305.1],
                [2629683.4, 1234075.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8712', ortbez27: 'St�fa' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698799.4, 1229957.4],
                [2695253.8, 1232434.1],
                [2696525.4, 1235722.4],
                [2697807.6, 1235462.5],
                [2698939.5, 1234434],
                [2699677.7, 1233251.9],
                [2698799.4, 1229957.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8717', ortbez27: 'Benken SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717221.6, 1230057.6],
                [2721355.3, 1228872.4],
                [2720444, 1226906],
                [2719101.5, 1225932.6],
                [2716859, 1227072.7],
                [2715536.5, 1227156.4],
                [2715865.7, 1229300.2],
                [2717221.6, 1230057.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4618', ortbez27: 'Boningen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633036.6, 1240353.3],
                [2631718.3, 1237621.1],
                [2630478.3, 1238404.9],
                [2630880.5, 1239986.8],
                [2633036.6, 1240353.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8718', ortbez27: 'Sch�nis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726795.1, 1224752.3],
                [2722957.1, 1221483.3],
                [2722189.7, 1222500.4],
                [2719101.5, 1225932.6],
                [2720444, 1226906],
                [2725454.4, 1225626.4],
                [2726795.1, 1224752.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8715', ortbez27: 'Bollingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711407.1, 1230501.1],
                [2707797.2, 1229882.8],
                [2708471.6, 1231392],
                [2710528.5, 1233201.2],
                [2711638.8, 1232435.4],
                [2711407.1, 1230501.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4616', ortbez27: 'Kappel SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633036.6, 1240353.3],
                [2630880.5, 1239986.8],
                [2629853.9, 1241975.1],
                [2631723.6, 1242591.9],
                [2632833.8, 1241658.6],
                [2633036.6, 1240353.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8716', ortbez27: 'Schmerikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715758.4, 1230907.2],
                [2711407.1, 1230501.1],
                [2711638.8, 1232435.4],
                [2713323.4, 1232531],
                [2715228.3, 1232925.6],
                [2715758.4, 1230907.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4622', ortbez27: 'Egerkingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626302.4, 1240545.7],
                [2625490.7, 1242303.5],
                [2627279.9, 1243532],
                [2629853.9, 1241975.1],
                [2627690.1, 1240364.4],
                [2626302.4, 1240545.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8767', ortbez27: 'Elm' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2735304.1, 1195102.2],
                [2731063, 1193003.3],
                [2729800.8, 1191628.4],
                [2727651.9, 1190310.4],
                [2724104.3, 1192955.5],
                [2724669.8, 1196778.9],
                [2725739.2, 1197582.7],
                [2728315.1, 1200072.4],
                [2736946.8, 1202000.7],
                [2737171.7, 1200419.3],
                [2738219.4, 1199115.4],
                [2737974.1, 1197867.2],
                [2735304.1, 1195102.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8773', ortbez27: 'Haslen GL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723588.6, 1201495.6],
                [2722550.5, 1203958.7],
                [2723577.1, 1205216.3],
                [2726225.5, 1200978.8],
                [2725739.2, 1197582.7],
                [2723588.6, 1201495.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8772', ortbez27: 'Nidfurn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722575, 1205968.6],
                [2723577.1, 1205216.3],
                [2722550.5, 1203958.7],
                [2721290.2, 1204000.5],
                [2722575, 1205968.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8762', ortbez27: 'Sool' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719484.9, 1207375.4],
                [2721908.2, 1209281.3],
                [2724800.2, 1207046],
                [2727259.7, 1208502.4],
                [2728933.8, 1211420.9],
                [2730050.1, 1210771.8],
                [2731258.4, 1210113],
                [2729365.5, 1206308.5],
                [2727710, 1206690.7],
                [2728243.6, 1202535.8],
                [2728315.1, 1200072.4],
                [2725739.2, 1197582.7],
                [2726225.5, 1200978.8],
                [2723577.1, 1205216.3],
                [2722575, 1205968.6],
                [2721331.2, 1206164.6],
                [2719484.9, 1207375.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4665', ortbez27: 'Oftringen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636322.1, 1242979.4],
                [2639771.2, 1241409.9],
                [2640747.6, 1239948.7],
                [2639723.5, 1239163.8],
                [2638391.3, 1238627.3],
                [2636508.4, 1239532.1],
                [2636322.1, 1242979.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8765', ortbez27: 'Engi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728243.6, 1202535.8],
                [2727710, 1206690.7],
                [2729365.5, 1206308.5],
                [2731258.4, 1210113],
                [2733328.7, 1210522.3],
                [2736262.5, 1210010.8],
                [2733982, 1204415.2],
                [2728243.6, 1202535.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8766', ortbez27: 'Matt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736262.5, 1210010.8],
                [2737688.8, 1207220.1],
                [2737634.7, 1204807.5],
                [2737407.2, 1203564.3],
                [2736946.8, 1202000.7],
                [2728315.1, 1200072.4],
                [2728243.6, 1202535.8],
                [2733982, 1204415.2],
                [2736262.5, 1210010.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4663', ortbez27: 'Aarburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636322.1, 1242979.4],
                [2636508.4, 1239532.1],
                [2633036.6, 1240353.3],
                [2632833.8, 1241658.6],
                [2634797.5, 1241338.9],
                [2636322.1, 1242979.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8753', ortbez27: 'Mollis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723294.2, 1215407.8],
                [2723263.6, 1219054.8],
                [2724393.8, 1221020.2],
                [2725920.8, 1220296.9],
                [2727500.7, 1218966],
                [2726939.8, 1214573.9],
                [2724654.9, 1214924.6],
                [2723294.2, 1215407.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8754', ortbez27: 'Netstal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718074.1, 1213353.4],
                [2723294.2, 1215407.8],
                [2724654.9, 1214924.6],
                [2723402.1, 1212906.8],
                [2722142.3, 1211950.6],
                [2720909.6, 1212172.9],
                [2718074.1, 1213353.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8751', ortbez27: 'Urnerboden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707541.9, 1192907.9],
                [2714086.7, 1197762],
                [2715746.9, 1192843.2],
                [2709359.1, 1189020.4],
                [2707725.8, 1188855.4],
                [2707541.9, 1192907.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8752', ortbez27: 'N�fels' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718074.1, 1213353.4],
                [2714776.2, 1212177],
                [2716437.2, 1219855.6],
                [2718281.4, 1219928.5],
                [2719271.5, 1219161.6],
                [2721943.7, 1219252.9],
                [2723263.6, 1219054.8],
                [2723294.2, 1215407.8],
                [2718074.1, 1213353.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4657', ortbez27: 'Dulliken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639378.2, 1244860.1],
                [2639706.3, 1243507.7],
                [2636322.1, 1242979.4],
                [2637060.4, 1245379.5],
                [2639378.2, 1244860.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8757', ortbez27: 'Filzbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729391.5, 1220447.1],
                [2728518.5, 1213709.4],
                [2726939.8, 1214573.9],
                [2727500.7, 1218966],
                [2725920.8, 1220296.9],
                [2729391.5, 1220447.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4658', ortbez27: 'D�niken SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642638.6, 1243010.2],
                [2639706.3, 1243507.7],
                [2639378.2, 1244860.1],
                [2639850.9, 1246543],
                [2642050, 1246420.6],
                [2643387.6, 1244639.3],
                [2642638.6, 1243010.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8758', ortbez27: 'Obstalden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728518.5, 1213709.4],
                [2729391.5, 1220447.1],
                [2731272.5, 1221090.6],
                [2730329.6, 1217884.5],
                [2732515.3, 1213489],
                [2731532.8, 1212573.9],
                [2730125.9, 1212078],
                [2728933.8, 1211420.9],
                [2728518.5, 1213709.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4655', ortbez27: 'Rohr b. Olten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640311.7, 1252327.4],
                [2641364.6, 1248812],
                [2640306.2, 1247800.8],
                [2638841.9, 1250176],
                [2637962.4, 1251750.2],
                [2640311.7, 1252327.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8755', ortbez27: 'Ennenda' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726939.8, 1214573.9],
                [2728518.5, 1213709.4],
                [2728933.8, 1211420.9],
                [2727259.7, 1208502.4],
                [2724579, 1209986.2],
                [2723402.1, 1212906.8],
                [2724654.9, 1214924.6],
                [2726939.8, 1214573.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8756', ortbez27: 'Mitl�di' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724800.2, 1207046],
                [2721908.2, 1209281.3],
                [2724579, 1209986.2],
                [2727259.7, 1208502.4],
                [2724800.2, 1207046]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8750', ortbez27: 'Kl�ntal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711074, 1202211.6],
                [2709300, 1209483.7],
                [2714776.2, 1212177],
                [2718074.1, 1213353.4],
                [2720909.6, 1212172.9],
                [2722142.3, 1211950.6],
                [2723402.1, 1212906.8],
                [2724579, 1209986.2],
                [2721908.2, 1209281.3],
                [2719484.9, 1207375.4],
                [2715259.5, 1202917.9],
                [2711074, 1202211.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4653', ortbez27: 'Oberg�sgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639378.2, 1244860.1],
                [2637060.4, 1245379.5],
                [2637877.3, 1246942.6],
                [2639850.9, 1246543],
                [2639378.2, 1244860.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4654', ortbez27: 'Lostorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636334.4, 1246712.1],
                [2635130.2, 1247970.2],
                [2636493.5, 1250436.5],
                [2637962.4, 1251750.2],
                [2638841.9, 1250176],
                [2640306.2, 1247800.8],
                [2639850.9, 1246543],
                [2637877.3, 1246942.6],
                [2636334.4, 1246712.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8802', ortbez27: 'Kilchberg ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683751.9, 1240734],
                [2683546.3, 1243469.6],
                [2684862.5, 1242775.3],
                [2685320.5, 1241597.2],
                [2683751.9, 1240734]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4652', ortbez27: 'Winznau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2637060.4, 1245379.5], [2636334.4, 1246712.1], [2637877.3, 1246942.6], [2637060.4, 1245379.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8800', ortbez27: 'Thalwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686090.6, 1239934],
                [2686970.8, 1238373.8],
                [2685628.4, 1237273.4],
                [2683758.4, 1237844.7],
                [2684024.6, 1239085.2],
                [2686090.6, 1239934]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8805', ortbez27: 'Richterswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695253.8, 1232434.1],
                [2698799.4, 1229957.4],
                [2696565.2, 1228847.3],
                [2695442.4, 1228207.1],
                [2693945.5, 1229059],
                [2694557.9, 1230350.5],
                [2695253.8, 1232434.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8806', ortbez27: 'B�ch SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696565.2, 1228847.3],
                [2698799.4, 1229957.4],
                [2698649.8, 1227874.5],
                [2697472.6, 1227199.7],
                [2696565.2, 1228847.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4703', ortbez27: 'Kestenholz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622757.9, 1234526.4],
                [2623795, 1238485.8],
                [2625634.7, 1236169.4],
                [2624159.4, 1234331.3],
                [2622757.9, 1234526.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4704', ortbez27: 'Niederbipp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622757.9, 1234526.4],
                [2624159.4, 1234331.3],
                [2623310.6, 1233338.2],
                [2620939.6, 1232310.9],
                [2619366.6, 1232471.5],
                [2617492.8, 1235833.3],
                [2615935.5, 1236960.5],
                [2618088.4, 1237747.5],
                [2622757.9, 1234526.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8803', ortbez27: 'R�schlikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686090.6, 1239934],
                [2684024.6, 1239085.2],
                [2682828.7, 1239755.3],
                [2683751.9, 1240734],
                [2685320.5, 1241597.2],
                [2686090.6, 1239934]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8804', ortbez27: 'Au ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2690377.1, 1233108.4], [2690446.2, 1235317.4], [2692601.5, 1234520.3], [2690377.1, 1233108.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4710', ortbez27: 'Balsthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618088.4, 1237747.5],
                [2617514.4, 1243493.2],
                [2618249.6, 1244533.6],
                [2621655.9, 1242838.7],
                [2623267.8, 1241294.6],
                [2622094.3, 1239975.9],
                [2618088.4, 1237747.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4702', ortbez27: 'Oensingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618088.4, 1237747.5],
                [2622094.3, 1239975.9],
                [2623795, 1238485.8],
                [2622757.9, 1234526.4],
                [2618088.4, 1237747.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8783', ortbez27: 'Linthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709862, 1185791.8],
                [2709359.1, 1189020.4],
                [2715746.9, 1192843.2],
                [2714086.7, 1197762],
                [2716017.5, 1199112.6],
                [2718669.6, 1199442.9],
                [2723018, 1197572.3],
                [2724669.8, 1196778.9],
                [2724104.3, 1192955.5],
                [2721820, 1188516.8],
                [2716039.3, 1184965.3],
                [2713806.7, 1184036.3],
                [2712751.5, 1185649.1],
                [2709862, 1185791.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8784', ortbez27: 'Braunwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716017.5, 1199112.6],
                [2716761.4, 1202263.1],
                [2719125.9, 1202337.5],
                [2720076.2, 1200940.2],
                [2718669.6, 1199442.9],
                [2716017.5, 1199112.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8777', ortbez27: 'Diesbach GL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724669.8, 1196778.9],
                [2723018, 1197572.3],
                [2720076.2, 1200940.2],
                [2719125.9, 1202337.5],
                [2721206.7, 1202083.2],
                [2723588.6, 1201495.6],
                [2725739.2, 1197582.7],
                [2724669.8, 1196778.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8775', ortbez27: 'Luchsingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722550.5, 1203958.7],
                [2723588.6, 1201495.6],
                [2721206.7, 1202083.2],
                [2719125.9, 1202337.5],
                [2716761.4, 1202263.1],
                [2715259.5, 1202917.9],
                [2719484.9, 1207375.4],
                [2721331.2, 1206164.6],
                [2722575, 1205968.6],
                [2721290.2, 1204000.5],
                [2722550.5, 1203958.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8782', ortbez27: 'R�ti GL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718669.6, 1199442.9], [2720076.2, 1200940.2], [2723018, 1197572.3], [2718669.6, 1199442.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8833', ortbez27: 'Samstagern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693945.5, 1229059],
                [2695442.4, 1228207.1],
                [2694617.2, 1227090.3],
                [2694375.8, 1225848.2],
                [2692813.5, 1227149.1],
                [2693945.5, 1229059]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8834', ortbez27: 'Schindellegi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698446.4, 1224918.6],
                [2695119.5, 1224806.2],
                [2694375.8, 1225848.2],
                [2694617.2, 1227090.3],
                [2697472.6, 1227199.7],
                [2698446.4, 1224918.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8832', ortbez27: 'Wollerau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695442.4, 1228207.1],
                [2696565.2, 1228847.3],
                [2697472.6, 1227199.7],
                [2694617.2, 1227090.3],
                [2695442.4, 1228207.1]
              ]
            ],
            [[[2698649.8, 1227874.5], [2698799.4, 1229957.4], [2699851, 1228235.9], [2698649.8, 1227874.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8835', ortbez27: 'Feusisberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698446.4, 1224918.6],
                [2697472.6, 1227199.7],
                [2698649.8, 1227874.5],
                [2699851, 1228235.9],
                [2702189.1, 1227009.6],
                [2701193.8, 1225730.7],
                [2698446.4, 1224918.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8836', ortbez27: 'Bennau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700152.8, 1223222.1],
                [2696116.3, 1221069.1],
                [2694072.5, 1221755.4],
                [2693935, 1224194.4],
                [2695119.5, 1224806.2],
                [2698446.4, 1224918.6],
                [2700152.8, 1223222.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8825', ortbez27: 'H�tten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693935, 1224194.4],
                [2690990.5, 1225910.9],
                [2692813.5, 1227149.1],
                [2694375.8, 1225848.2],
                [2695119.5, 1224806.2],
                [2693935, 1224194.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8824', ortbez27: 'Sch�nenberg ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690370.4, 1229914.1],
                [2693945.5, 1229059],
                [2692813.5, 1227149.1],
                [2690990.5, 1225910.9],
                [2688931.5, 1227233.9],
                [2689461.9, 1228645.3],
                [2690370.4, 1229914.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8815', ortbez27: 'Horgenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686795.3, 1236180.9],
                [2688149.7, 1232679],
                [2685783.8, 1233490.1],
                [2684493.3, 1233344.8],
                [2684950.2, 1235334.6],
                [2686795.3, 1236180.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8816', ortbez27: 'Hirzel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688149.7, 1232679],
                [2689332.3, 1231032],
                [2690370.4, 1229914.1],
                [2689461.9, 1228645.3],
                [2686899.9, 1229637.2],
                [2686726, 1231112.8],
                [2685373, 1231988.6],
                [2685783.8, 1233490.1],
                [2688149.7, 1232679]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4719', ortbez27: 'Ramiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618133.4, 1245802.9],
                [2618249.6, 1244533.6],
                [2617514.4, 1243493.2],
                [2616233.2, 1243556.7],
                [2614584.3, 1243498.5],
                [2616390.4, 1246526],
                [2618133.4, 1245802.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8820', ortbez27: 'W�denswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693945.5, 1229059],
                [2690370.4, 1229914.1],
                [2689332.3, 1231032],
                [2690377.1, 1233108.4],
                [2692601.5, 1234520.3],
                [2693959.4, 1233513.1],
                [2695253.8, 1232434.1],
                [2694557.9, 1230350.5],
                [2693945.5, 1229059]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8810', ortbez27: 'Horgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686795.3, 1236180.9],
                [2687819.9, 1237101.6],
                [2690446.2, 1235317.4],
                [2690377.1, 1233108.4],
                [2689332.3, 1231032],
                [2688149.7, 1232679],
                [2686795.3, 1236180.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8807', ortbez27: 'Freienbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2698799.4, 1229957.4], [2700980.6, 1230632.1], [2699851, 1228235.9], [2698799.4, 1229957.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8808', ortbez27: 'Pf�ffikon SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2699851, 1228235.9],
                [2700980.6, 1230632.1],
                [2702689.3, 1231217.9],
                [2703078.6, 1229408.6],
                [2702189.1, 1227009.6],
                [2699851, 1228235.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4713', ortbez27: 'Matzendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612820.8, 1236753.7],
                [2611703.5, 1242001.8],
                [2612681.7, 1242802],
                [2614298.5, 1242091],
                [2615935.5, 1236960.5],
                [2612820.8, 1236753.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4714', ortbez27: 'Aedermannsdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2612820.8, 1236753.7], [2610499.4, 1241561.4], [2611703.5, 1242001.8], [2612820.8, 1236753.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4712', ortbez27: 'Laupersdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2618088.4, 1237747.5],
                [2615935.5, 1236960.5],
                [2614298.5, 1242091],
                [2614584.3, 1243498.5],
                [2616233.2, 1243556.7],
                [2617514.4, 1243493.2],
                [2618088.4, 1237747.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4717', ortbez27: 'M�mliswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2614584.3, 1243498.5], [2614298.5, 1242091], [2612681.7, 1242802], [2614584.3, 1243498.5]]],
            [
              [
                [2620544.7, 1246913],
                [2623720, 1243918],
                [2621655.9, 1242838.7],
                [2618249.6, 1244533.6],
                [2618133.4, 1245802.9],
                [2618958, 1246780.1],
                [2620544.7, 1246913]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8865', ortbez27: 'Bilten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2719101.5, 1225932.6], [2722189.7, 1222500.4], [2716210.2, 1221673.6], [2719101.5, 1225932.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4718', ortbez27: 'Holderbank SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2623720, 1243918],
                [2627279.9, 1243532],
                [2625490.7, 1242303.5],
                [2623267.8, 1241294.6],
                [2621655.9, 1242838.7],
                [2623720, 1243918]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4715', ortbez27: 'Herbetswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2612820.8, 1236753.7],
                [2611102, 1235861.5],
                [2609203.8, 1235850.3],
                [2609217.4, 1240279.4],
                [2610499.4, 1241561.4],
                [2612820.8, 1236753.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8863', ortbez27: 'Buttikon SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714563.9, 1223794.9],
                [2712444.2, 1224278.4],
                [2714273, 1227018.3],
                [2715536.5, 1227156.4],
                [2715529.4, 1225615.6],
                [2714563.9, 1223794.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4716', ortbez27: 'Welschenrohr' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604312.9, 1237634.6],
                [2605815.1, 1238112.4],
                [2609217.4, 1240279.4],
                [2609203.8, 1235850.3],
                [2607529.9, 1235636],
                [2605946.1, 1234234.6],
                [2602083.2, 1232663],
                [2601158.2, 1233557.8],
                [2599917.2, 1233043.1],
                [2600609.1, 1234837.3],
                [2600387.5, 1236087.6],
                [2601769.9, 1236685],
                [2603052.4, 1236554.1],
                [2604312.9, 1237634.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8864', ortbez27: 'Reichenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719101.5, 1225932.6],
                [2716210.2, 1221673.6],
                [2714563.9, 1223794.9],
                [2715529.4, 1225615.6],
                [2715536.5, 1227156.4],
                [2716859, 1227072.7],
                [2719101.5, 1225932.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8867', ortbez27: 'Niederurnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716210.2, 1221673.6],
                [2722189.7, 1222500.4],
                [2722957.1, 1221483.3],
                [2719271.5, 1219161.6],
                [2718281.4, 1219928.5],
                [2716437.2, 1219855.6],
                [2716210.2, 1221673.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8868', ortbez27: 'Oberurnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724393.8, 1221020.2],
                [2723263.6, 1219054.8],
                [2721943.7, 1219252.9],
                [2719271.5, 1219161.6],
                [2722957.1, 1221483.3],
                [2724393.8, 1221020.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8857', ortbez27: 'Vorderthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708625.5, 1217707.9],
                [2706588, 1219515.9],
                [2706546.9, 1221083.1],
                [2707481.4, 1222561.7],
                [2709500.2, 1223191.9],
                [2714353.2, 1219891.6],
                [2709285.9, 1218910.8],
                [2708625.5, 1217707.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8858', ortbez27: 'Innerthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710022.4, 1214465.6],
                [2708625.5, 1217707.9],
                [2709285.9, 1218910.8],
                [2714353.2, 1219891.6],
                [2716437.2, 1219855.6],
                [2714776.2, 1212177],
                [2709300, 1209483.7],
                [2710022.4, 1214465.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8855', ortbez27: 'Wangen SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707797.2, 1229882.8],
                [2711407.1, 1230501.1],
                [2712075.2, 1227629.3],
                [2709348.6, 1227126.4],
                [2707797.2, 1229882.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8856', ortbez27: 'Tuggen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712075.2, 1227629.3],
                [2711407.1, 1230501.1],
                [2715758.4, 1230907.2],
                [2715865.7, 1229300.2],
                [2715536.5, 1227156.4],
                [2714273, 1227018.3],
                [2712075.2, 1227629.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8862', ortbez27: 'Sch�belbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2712075.2, 1227629.3], [2714273, 1227018.3], [2712444.2, 1224278.4], [2712075.2, 1227629.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8849', ortbez27: 'Alpthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695525.9, 1209523.8],
                [2694919.2, 1212911.5],
                [2696386.5, 1216514.1],
                [2698733.4, 1215451.7],
                [2699295.6, 1213502.7],
                [2697638.8, 1208649.5],
                [2695525.9, 1209523.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8847', ortbez27: 'Egg SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704226.7, 1224020.8],
                [2701548.2, 1222362.6],
                [2700152.8, 1223222.1],
                [2698446.4, 1224918.6],
                [2701193.8, 1225730.7],
                [2702189.1, 1227009.6],
                [2702820.8, 1225777.2],
                [2704226.7, 1224020.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8853', ortbez27: 'Lachen SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707797.2, 1229882.8],
                [2709348.6, 1227126.4],
                [2707579.3, 1227113.4],
                [2706227.6, 1229684.1],
                [2707797.2, 1229882.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8854', ortbez27: 'Galgenen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709348.6, 1227126.4],
                [2712075.2, 1227629.3],
                [2712444.2, 1224278.4],
                [2714563.9, 1223794.9],
                [2716210.2, 1221673.6],
                [2716437.2, 1219855.6],
                [2714353.2, 1219891.6],
                [2709500.2, 1223191.9],
                [2707481.4, 1222561.7],
                [2707579.3, 1227113.4],
                [2709348.6, 1227126.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8852', ortbez27: 'Altendorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703078.6, 1229408.6],
                [2704443.9, 1230342.9],
                [2706227.6, 1229684.1],
                [2707579.3, 1227113.4],
                [2707481.4, 1222561.7],
                [2706546.9, 1221083.1],
                [2704226.7, 1224020.8],
                [2702820.8, 1225777.2],
                [2702189.1, 1227009.6],
                [2703078.6, 1229408.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8841', ortbez27: 'Gross' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702097.1, 1220728.6],
                [2703491.4, 1218129.8],
                [2700953.1, 1215332.6],
                [2699295.6, 1213502.7],
                [2698733.4, 1215451.7],
                [2700224.9, 1219536],
                [2702097.1, 1220728.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8842', ortbez27: 'Unteriberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2699295.6, 1213502.7],
                [2700953.1, 1215332.6],
                [2705559, 1215296.1],
                [2706518.7, 1207237.1],
                [2701828.8, 1205838.4],
                [2701640.5, 1207115.4],
                [2703638.2, 1208859.3],
                [2703478.9, 1212357.3],
                [2699295.6, 1213502.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8840', ortbez27: 'Einsiedeln' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700224.9, 1219536],
                [2698733.4, 1215451.7],
                [2696386.5, 1216514.1],
                [2697889.4, 1219673.4],
                [2696116.3, 1221069.1],
                [2700152.8, 1223222.1],
                [2701548.2, 1222362.6],
                [2702097.1, 1220728.6],
                [2700224.9, 1219536]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8845', ortbez27: 'Studen SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709300, 1209483.7],
                [2706518.7, 1207237.1],
                [2705559, 1215296.1],
                [2710022.4, 1214465.6],
                [2709300, 1209483.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8846', ortbez27: 'Willerzell' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702097.1, 1220728.6],
                [2701548.2, 1222362.6],
                [2704226.7, 1224020.8],
                [2706546.9, 1221083.1],
                [2706588, 1219515.9],
                [2705412.1, 1218690],
                [2703491.4, 1218129.8],
                [2702097.1, 1220728.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8843', ortbez27: 'Oberiberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701640.5, 1207115.4],
                [2697998.8, 1206949.8],
                [2697638.8, 1208649.5],
                [2699295.6, 1213502.7],
                [2703478.9, 1212357.3],
                [2703638.2, 1208859.3],
                [2701640.5, 1207115.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8844', ortbez27: 'Euthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700953.1, 1215332.6],
                [2703491.4, 1218129.8],
                [2705412.1, 1218690],
                [2706588, 1219515.9],
                [2708625.5, 1217707.9],
                [2710022.4, 1214465.6],
                [2705559, 1215296.1],
                [2700953.1, 1215332.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8897', ortbez27: 'Flumserberg Tannenheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2741452.7, 1209629.7],
                [2737688.8, 1207220.1],
                [2736262.5, 1210010.8],
                [2735695.8, 1213919.8],
                [2737630.8, 1214753.8],
                [2740653.2, 1217834.4],
                [2742090.9, 1217647.2],
                [2742094.3, 1215610.1],
                [2741009.7, 1213048.8],
                [2741277, 1210908.6],
                [2741452.7, 1209629.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8896', ortbez27: 'Flumserberg Bergheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2740828, 1220047.9],
                [2743509.1, 1219728],
                [2743732.6, 1216704.5],
                [2742094.3, 1215610.1],
                [2742090.9, 1217647.2],
                [2740653.2, 1217834.4],
                [2740828, 1220047.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4802', ortbez27: 'Strengelbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636508.4, 1239532.1],
                [2638046.9, 1236273.3],
                [2636172.2, 1235432.1],
                [2635620.1, 1238248.9],
                [2636508.4, 1239532.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8902', ortbez27: 'Urdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674641.1, 1250099.4],
                [2676304.7, 1248355.7],
                [2675377.4, 1247125.6],
                [2673316.9, 1246805.9],
                [2672704.1, 1247902.2],
                [2674641.1, 1250099.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4800', ortbez27: 'Zofingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641561, 1236420.9],
                [2638046.9, 1236273.3],
                [2636508.4, 1239532.1],
                [2638391.3, 1238627.3],
                [2639723.5, 1239163.8],
                [2641340.5, 1237921.8],
                [2641561, 1236420.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4805', ortbez27: 'Brittnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638046.9, 1236273.3],
                [2639663.1, 1234022.3],
                [2638739.4, 1233054.9],
                [2637229.4, 1231994.6],
                [2634640.7, 1233020.8],
                [2636172.2, 1235432.1],
                [2638046.9, 1236273.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8889', ortbez27: 'Plons' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2749364.5, 1213086.1], [2745658.3, 1214317.8], [2747564.4, 1215395.4], [2749364.5, 1213086.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4806', ortbez27: 'Wikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639663.1, 1234022.3],
                [2638046.9, 1236273.3],
                [2641561, 1236420.9],
                [2641787.1, 1234840.6],
                [2640889.6, 1233756.3],
                [2639663.1, 1234022.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8890', ortbez27: 'Flums' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2743509.1, 1219728], [2745896.6, 1218008.8], [2743732.6, 1216704.5], [2743509.1, 1219728]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4803', ortbez27: 'Vordemwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635620.1, 1238248.9],
                [2636172.2, 1235432.1],
                [2634640.7, 1233020.8],
                [2632472.8, 1234590.8],
                [2633201.6, 1236844],
                [2635620.1, 1238248.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8887', ortbez27: 'Mels' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2748807.7, 1209946], [2747729.5, 1207306.5], [2746841.9, 1208623.9], [2748807.7, 1209946]]],
            [
              [
                [2755614.6, 1212095.3],
                [2752821.2, 1211650.5],
                [2750881.9, 1211281],
                [2748807.7, 1209946],
                [2749364.5, 1213086.1],
                [2751263.1, 1213109.7],
                [2754514.8, 1213270.2],
                [2755614.6, 1212095.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8888', ortbez27: 'Heiligkreuz (Mels)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751263.1, 1213109.7],
                [2749364.5, 1213086.1],
                [2747564.4, 1215395.4],
                [2749216.9, 1216424.3],
                [2750454, 1216057.6],
                [2751361.2, 1214709],
                [2751263.1, 1213109.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8893', ortbez27: 'Flums Hochwiese' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747564.4, 1215395.4],
                [2746964.7, 1217310.9],
                [2748293.8, 1217808.4],
                [2749216.9, 1216424.3],
                [2747564.4, 1215395.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8894', ortbez27: 'Flumserberg Saxli' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2746964.7, 1217310.9],
                [2747564.4, 1215395.4],
                [2745658.3, 1214317.8],
                [2741277, 1210908.6],
                [2741009.7, 1213048.8],
                [2742094.3, 1215610.1],
                [2743732.6, 1216704.5],
                [2745896.6, 1218008.8],
                [2746964.7, 1217310.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8892', ortbez27: 'Berschis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2749652.3, 1219739.7],
                [2748293.8, 1217808.4],
                [2746964.7, 1217310.9],
                [2745896.6, 1218008.8],
                [2743509.1, 1219728],
                [2745609.5, 1222201.8],
                [2748701.3, 1222677.2],
                [2749652.3, 1219739.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8881', ortbez27: 'Tscherlach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2742779.4, 1222567.8], [2745609.5, 1222201.8], [2743509.1, 1219728], [2742779.4, 1222567.8]]],
            [
              [
                [2737754.3, 1220824.8],
                [2736563, 1223685.6],
                [2741613.8, 1224310.1],
                [2742779.4, 1222567.8],
                [2740828, 1220047.9],
                [2737754.3, 1220824.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8880', ortbez27: 'Walenstadt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2742779.4, 1222567.8], [2743509.1, 1219728], [2740828, 1220047.9], [2742779.4, 1222567.8]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8885', ortbez27: 'Mols' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2740653.2, 1217834.4],
                [2737762.9, 1219189],
                [2737754.3, 1220824.8],
                [2740828, 1220047.9],
                [2740653.2, 1217834.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8886', ortbez27: 'M�dris-Vermol' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2749364.5, 1213086.1],
                [2748807.7, 1209946],
                [2746841.9, 1208623.9],
                [2743864.9, 1208317.5],
                [2741452.7, 1209629.7],
                [2741277, 1210908.6],
                [2745658.3, 1214317.8],
                [2749364.5, 1213086.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8883', ortbez27: 'Quarten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737762.9, 1219189],
                [2735599.4, 1217026.3],
                [2736390.2, 1220568.6],
                [2737754.3, 1220824.8],
                [2737762.9, 1219189]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8884', ortbez27: 'Oberterzen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728933.8, 1211420.9],
                [2730125.9, 1212078],
                [2731532.8, 1212573.9],
                [2732515.3, 1213489],
                [2733244.6, 1215162.1],
                [2735599.4, 1217026.3],
                [2737762.9, 1219189],
                [2740653.2, 1217834.4],
                [2737630.8, 1214753.8],
                [2735695.8, 1213919.8],
                [2736262.5, 1210010.8],
                [2733328.7, 1210522.3],
                [2731258.4, 1210113],
                [2730050.1, 1210771.8],
                [2728933.8, 1211420.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8873', ortbez27: 'Amden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726795.1, 1224752.3],
                [2727728.9, 1227612.7],
                [2733394, 1227416.2],
                [2735662.1, 1227413.4],
                [2735198.3, 1223293.2],
                [2732966.5, 1221784],
                [2731272.5, 1221090.6],
                [2726975.7, 1222667.2],
                [2726795.1, 1224752.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8874', ortbez27: 'M�hlehorn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732966.5, 1221784],
                [2733244.6, 1215162.1],
                [2732515.3, 1213489],
                [2730329.6, 1217884.5],
                [2731272.5, 1221090.6],
                [2732966.5, 1221784]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8872', ortbez27: 'Weesen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731272.5, 1221090.6],
                [2729391.5, 1220447.1],
                [2725920.8, 1220296.9],
                [2724393.8, 1221020.2],
                [2722957.1, 1221483.3],
                [2726795.1, 1224752.3],
                [2726975.7, 1222667.2],
                [2731272.5, 1221090.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8877', ortbez27: 'Murg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2735599.4, 1217026.3],
                [2733244.6, 1215162.1],
                [2732966.5, 1221784],
                [2736390.2, 1220568.6],
                [2735599.4, 1217026.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8878', ortbez27: 'Quinten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736563, 1223685.6],
                [2737754.3, 1220824.8],
                [2736390.2, 1220568.6],
                [2732966.5, 1221784],
                [2735198.3, 1223293.2],
                [2736563, 1223685.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8933', ortbez27: 'Maschwanden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676844.8, 1232719.1],
                [2676032, 1230658.3],
                [2674337.6, 1229346.6],
                [2674115.2, 1230586.1],
                [2674122.6, 1231848.1],
                [2673925.7, 1233137.8],
                [2675554.1, 1233561.4],
                [2676844.8, 1232719.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8934', ortbez27: 'Knonau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678325.9, 1229672.6],
                [2676032, 1230658.3],
                [2676844.8, 1232719.1],
                [2678258.2, 1231445.2],
                [2679490.7, 1231844],
                [2679636.1, 1230440.7],
                [2678325.9, 1229672.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8932', ortbez27: 'Mettmenstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676789.2, 1235906.1],
                [2678297.8, 1235414.5],
                [2678560, 1233418],
                [2679490.7, 1231844],
                [2678258.2, 1231445.2],
                [2676844.8, 1232719.1],
                [2675554.1, 1233561.4],
                [2675415.9, 1235040.7],
                [2676789.2, 1235906.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8919', ortbez27: 'Rottenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2671864.1, 1239942.6],
                [2668087, 1239930.2],
                [2668993.8, 1241795.6],
                [2670006.3, 1242605.1],
                [2671864.1, 1239942.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8926', ortbez27: 'Kappel am Albis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681621.6, 1230553.1],
                [2679636.1, 1230440.7],
                [2679490.7, 1231844],
                [2681358.8, 1232085.7],
                [2683486.4, 1231947.9],
                [2683192.5, 1230703.6],
                [2681621.6, 1230553.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8913', ortbez27: 'Ottenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672929.1, 1235800.9],
                [2671942.9, 1238268.4],
                [2674558.1, 1237820.4],
                [2675708.7, 1236891.8],
                [2672929.1, 1235800.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8914', ortbez27: 'Aeugstertal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2680485, 1236444.8],
                [2681770.5, 1235903.6],
                [2680988.7, 1234842.9],
                [2679509.5, 1235016.8],
                [2678297.8, 1235414.5],
                [2678541.6, 1237399.2],
                [2679054, 1239149.6],
                [2680887.3, 1237914.2],
                [2680485, 1236444.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8911', ortbez27: 'Rifferswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681358.8, 1232085.7],
                [2679490.7, 1231844],
                [2678560, 1233418],
                [2678297.8, 1235414.5],
                [2679509.5, 1235016.8],
                [2680988.7, 1234842.9],
                [2681518.4, 1233698.7],
                [2681358.8, 1232085.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8912', ortbez27: 'Obfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673925.7, 1233137.8],
                [2672929.1, 1235800.9],
                [2675708.7, 1236891.8],
                [2676789.2, 1235906.1],
                [2675415.9, 1235040.7],
                [2675554.1, 1233561.4],
                [2673925.7, 1233137.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8918', ortbez27: 'Unterlunkhofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670869.7, 1243942.1],
                [2673352.2, 1242398.1],
                [2673607.3, 1241011.5],
                [2671864.1, 1239942.6],
                [2670006.3, 1242605.1],
                [2670869.7, 1243942.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8915', ortbez27: 'Hausen am Albis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683486.4, 1231947.9],
                [2681358.8, 1232085.7],
                [2681518.4, 1233698.7],
                [2680988.7, 1234842.9],
                [2681770.5, 1235903.6],
                [2682989.5, 1235357.5],
                [2683696.4, 1234317.9],
                [2684493.3, 1233344.8],
                [2685373, 1231988.6],
                [2683486.4, 1231947.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8916', ortbez27: 'Jonen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2675037.9, 1240063.4],
                [2674558.1, 1237820.4],
                [2671942.9, 1238268.4],
                [2671864.1, 1239942.6],
                [2673607.3, 1241011.5],
                [2675037.9, 1240063.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8905', ortbez27: 'Arni AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2675891.4, 1241186.5],
                [2675037.9, 1240063.4],
                [2673607.3, 1241011.5],
                [2673352.2, 1242398.1],
                [2675227.3, 1242354.8],
                [2675891.4, 1241186.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8906', ortbez27: 'Bonstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676916, 1243311.8],
                [2679094.7, 1242117.4],
                [2679231.4, 1240645.9],
                [2679054, 1239149.6],
                [2675891.4, 1241186.5],
                [2675227.3, 1242354.8],
                [2676916, 1243311.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8903', ortbez27: 'Birmensdorf ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673211.3, 1245140],
                [2673316.9, 1246805.9],
                [2675377.4, 1247125.6],
                [2678071.1, 1246294.7],
                [2678130.4, 1244603.7],
                [2676916, 1243311.8],
                [2675955.9, 1244533.5],
                [2674516.9, 1244180],
                [2673211.3, 1245140]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8904', ortbez27: 'Aesch ZH' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2675227.3, 1242354.8],
                [2673352.2, 1242398.1],
                [2674516.9, 1244180],
                [2675955.9, 1244533.5],
                [2676916, 1243311.8],
                [2675227.3, 1242354.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8909', ortbez27: 'Zwillikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674558.1, 1237820.4],
                [2675037.9, 1240063.4],
                [2676656.8, 1238209],
                [2675708.7, 1236891.8],
                [2674558.1, 1237820.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8910', ortbez27: 'Affoltern am Albis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676656.8, 1238209],
                [2679054, 1239149.6],
                [2678541.6, 1237399.2],
                [2678297.8, 1235414.5],
                [2676789.2, 1235906.1],
                [2675708.7, 1236891.8],
                [2676656.8, 1238209]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8907', ortbez27: 'Wettswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2678130.4, 1244603.7], [2679094.7, 1242117.4], [2676916, 1243311.8], [2678130.4, 1244603.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8908', ortbez27: 'Hedingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679054, 1239149.6],
                [2676656.8, 1238209],
                [2675037.9, 1240063.4],
                [2675891.4, 1241186.5],
                [2679054, 1239149.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4813', ortbez27: 'Uerkheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644053.7, 1238130.9],
                [2641340.5, 1237921.8],
                [2640747.6, 1239948.7],
                [2644196.3, 1240924.4],
                [2645794.3, 1241180.2],
                [2645231.7, 1240021.5],
                [2644053.7, 1238130.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4814', ortbez27: 'Bottenwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642920.2, 1236241.3],
                [2641561, 1236420.9],
                [2641340.5, 1237921.8],
                [2644053.7, 1238130.9],
                [2642920.2, 1236241.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8962', ortbez27: 'Bergdietikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672704.1, 1247902.2],
                [2670167.2, 1248036.2],
                [2670646.6, 1250367.8],
                [2671831.9, 1249964.9],
                [2672704.1, 1247902.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4812', ortbez27: 'M�hlethal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2640747.6, 1239948.7], [2641340.5, 1237921.8], [2639723.5, 1239163.8], [2640747.6, 1239948.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8965', ortbez27: 'Berikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673211.3, 1245140],
                [2670869.7, 1243942.1],
                [2670143.2, 1246536.2],
                [2672704.1, 1247902.2],
                [2673316.9, 1246805.9],
                [2673211.3, 1245140]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8966', ortbez27: 'Oberwil-Lieli' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673352.2, 1242398.1],
                [2670869.7, 1243942.1],
                [2673211.3, 1245140],
                [2674516.9, 1244180],
                [2673352.2, 1242398.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8964', ortbez27: 'Rudolfstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2670167.2, 1248036.2], [2672704.1, 1247902.2], [2670143.2, 1246536.2], [2670167.2, 1248036.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8953', ortbez27: 'Dietikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674641.1, 1250099.4],
                [2672704.1, 1247902.2],
                [2671831.9, 1249964.9],
                [2670646.6, 1250367.8],
                [2671680.5, 1253603.4],
                [2672968.9, 1253026.4],
                [2674641.1, 1250099.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8951', ortbez27: 'Fahrweid' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2672968.9, 1253026.4], [2675956.6, 1251456.9], [2674641.1, 1250099.4], [2672968.9, 1253026.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8952', ortbez27: 'Schlieren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676304.7, 1248355.7],
                [2674641.1, 1250099.4],
                [2675956.6, 1251456.9],
                [2677612, 1250482],
                [2676304.7, 1248355.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8957', ortbez27: 'Spreitenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2669484.3, 1250907.6],
                [2668187.2, 1252337.7],
                [2669579, 1254160.1],
                [2671680.5, 1253603.4],
                [2670646.6, 1250367.8],
                [2669484.3, 1250907.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4856', ortbez27: 'Glash�tten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630453.3, 1235305.1],
                [2632472.8, 1234590.8],
                [2634640.7, 1233020.8],
                [2633239.3, 1232262.6],
                [2632643.3, 1230938.5],
                [2630401.1, 1231855.9],
                [2629583.1, 1232805.4],
                [2629683.4, 1234075.1],
                [2630453.3, 1235305.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8956', ortbez27: 'Killwangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667261.9, 1253533.7],
                [2667870.1, 1255346.8],
                [2669579, 1254160.1],
                [2668187.2, 1252337.7],
                [2667261.9, 1253533.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4853', ortbez27: 'Riken AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632472.8, 1234590.8],
                [2630453.3, 1235305.1],
                [2631718.3, 1237621.1],
                [2633201.6, 1236844],
                [2632472.8, 1234590.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4852', ortbez27: 'Rothrist' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633201.6, 1236844],
                [2631718.3, 1237621.1],
                [2633036.6, 1240353.3],
                [2636508.4, 1239532.1],
                [2635620.1, 1238248.9],
                [2633201.6, 1236844]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8942', ortbez27: 'Oberrieden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685628.4, 1237273.4],
                [2686970.8, 1238373.8],
                [2687819.9, 1237101.6],
                [2686795.3, 1236180.9],
                [2684950.2, 1235334.6],
                [2684514.4, 1236534.5],
                [2685628.4, 1237273.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4900', ortbez27: 'Langenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624934.8, 1227765],
                [2624770.5, 1229831.3],
                [2626934.1, 1232794],
                [2630401.1, 1231855.9],
                [2630368.1, 1230463.4],
                [2628156.2, 1227869.8],
                [2626204.6, 1227676],
                [2624934.8, 1227765]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '8967', ortbez27: 'Widen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668776.2, 1246245.8],
                [2668151.9, 1248041.5],
                [2670167.2, 1248036.2],
                [2670143.2, 1246536.2],
                [2668776.2, 1246245.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9030', ortbez27: 'Abtwil SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2740582.1, 1254487.1],
                [2741575.4, 1256033.8],
                [2743554.8, 1255110.3],
                [2742078.9, 1253653.3],
                [2740582.1, 1254487.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4933', ortbez27: 'R�tschelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2624085.8, 1223896.9], [2625398.2, 1226212.8], [2626991.6, 1225586.3], [2624085.8, 1223896.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4934', ortbez27: 'Madiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626991.6, 1225586.3],
                [2628180.4, 1226072.7],
                [2629635.9, 1225663.9],
                [2630021.7, 1224467.1],
                [2630978.5, 1222169.9],
                [2629209.7, 1221383.6],
                [2627848.9, 1221613],
                [2627080.2, 1222650.2],
                [2625689.5, 1222226.2],
                [2624085.8, 1223896.9],
                [2626991.6, 1225586.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9015', ortbez27: 'St. Gallen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2740158.5, 1251321.8],
                [2740582.1, 1254487.1],
                [2742078.9, 1253653.3],
                [2742454.8, 1251846.1],
                [2740158.5, 1251321.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4932', ortbez27: 'Lotzwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628180.4, 1226072.7],
                [2626991.6, 1225586.3],
                [2625398.2, 1226212.8],
                [2624934.8, 1227765],
                [2626204.6, 1227676],
                [2628156.2, 1227869.8],
                [2628180.4, 1226072.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9016', ortbez27: 'St. Gallen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2749857.1, 1257814.1],
                [2750276.4, 1256456.9],
                [2749523.3, 1255311.1],
                [2747958.8, 1255978.6],
                [2748337.1, 1257779],
                [2749857.1, 1257814.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4922', ortbez27: 'Thunstetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2622172.1, 1227078.6],
                [2622357.4, 1228511.7],
                [2620583.6, 1228824.8],
                [2620937.9, 1230952.7],
                [2622334, 1231071.9],
                [2624770.5, 1229831.3],
                [2624934.8, 1227765],
                [2622780.9, 1225851.6],
                [2622172.1, 1227078.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4923', ortbez27: 'Wynau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626380.5, 1234118.6],
                [2629683.4, 1234075.1],
                [2629583.1, 1232805.4],
                [2626934.1, 1232794],
                [2626380.5, 1234118.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9010', ortbez27: 'St. Gallen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2745147.1, 1256857.1],
                [2748337.1, 1257779],
                [2747958.8, 1255978.6],
                [2746507.4, 1255815.2],
                [2745147.1, 1256857.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4924', ortbez27: 'Obersteckholz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2628180.4, 1226072.7],
                [2628156.2, 1227869.8],
                [2630368.1, 1230463.4],
                [2630797.1, 1227204.3],
                [2629635.9, 1225663.9],
                [2628180.4, 1226072.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4913', ortbez27: 'Bannwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2620939.6, 1232310.9],
                [2623310.6, 1233338.2],
                [2622334, 1231071.9],
                [2620937.9, 1230952.7],
                [2620939.6, 1232310.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4914', ortbez27: 'Roggwil BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2629583.1, 1232805.4], [2630401.1, 1231855.9], [2626934.1, 1232794], [2629583.1, 1232805.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9014', ortbez27: 'St. Gallen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2742454.8, 1251846.1],
                [2742078.9, 1253653.3],
                [2743554.8, 1255110.3],
                [2744256.3, 1253025.5],
                [2742454.8, 1251846.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9011', ortbez27: 'St. Gallen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747958.8, 1255978.6],
                [2749523.3, 1255311.1],
                [2750075.7, 1252774.3],
                [2747867.5, 1252009.1],
                [2746261.3, 1253487.2],
                [2746507.4, 1255815.2],
                [2747958.8, 1255978.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4912', ortbez27: 'Aarwangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626934.1, 1232794],
                [2624770.5, 1229831.3],
                [2622334, 1231071.9],
                [2623310.6, 1233338.2],
                [2624159.4, 1234331.3],
                [2626380.5, 1234118.6],
                [2626934.1, 1232794]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4917', ortbez27: 'Melchnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633615.3, 1225266.6],
                [2630978.5, 1222169.9],
                [2630021.7, 1224467.1],
                [2629635.9, 1225663.9],
                [2630797.1, 1227204.3],
                [2631981.7, 1227786.3],
                [2633615.3, 1225266.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4915', ortbez27: 'St. Urban' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632643.3, 1230938.5],
                [2632165, 1229263.9],
                [2630368.1, 1230463.4],
                [2630401.1, 1231855.9],
                [2632643.3, 1230938.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4916', ortbez27: 'Untersteckholz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631981.7, 1227786.3],
                [2630797.1, 1227204.3],
                [2630368.1, 1230463.4],
                [2632165, 1229263.9],
                [2631981.7, 1227786.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9000', ortbez27: 'St. Gallen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2745147.1, 1256857.1],
                [2746507.4, 1255815.2],
                [2746261.3, 1253487.2],
                [2745754.9, 1252202.5],
                [2744256.3, 1253025.5],
                [2743554.8, 1255110.3],
                [2745147.1, 1256857.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9057', ortbez27: 'Wasserauen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751883.3, 1238750.3],
                [2746501.9, 1233983.4],
                [2744265.4, 1234955.5],
                [2743701.4, 1236182.1],
                [2741974.1, 1239607.5],
                [2742151.1, 1240849.7],
                [2744585.2, 1240447.9],
                [2745932.1, 1240882.1],
                [2751278.3, 1242270.5],
                [2752171.2, 1240882.7],
                [2751883.3, 1238750.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9058', ortbez27: 'Br�lisau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754816, 1239071.6],
                [2754015, 1237737.3],
                [2752131.8, 1236026.2],
                [2750988.4, 1235288.9],
                [2748735.6, 1233859.6],
                [2746501.9, 1233983.4],
                [2751883.3, 1238750.3],
                [2752171.2, 1240882.7],
                [2751278.3, 1242270.5],
                [2754925.2, 1242742.6],
                [2756072.8, 1241726.4],
                [2754816, 1239071.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9055', ortbez27: 'B�hler' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747771.7, 1248718.3],
                [2750740.2, 1251083.9],
                [2752263.5, 1250438.9],
                [2750146.2, 1247975.4],
                [2747771.7, 1248718.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9056', ortbez27: 'Gais' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2750146.2, 1247975.4],
                [2752263.5, 1250438.9],
                [2756304.7, 1251339.5],
                [2755730.5, 1248024.2],
                [2756059.5, 1246269.1],
                [2753959.7, 1246037.3],
                [2750146.2, 1247975.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9062', ortbez27: 'Lustm�hle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744424.4, 1249943.8],
                [2742454.8, 1251846.1],
                [2744256.3, 1253025.5],
                [2745754.9, 1252202.5],
                [2744424.4, 1249943.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9050', ortbez27: 'Appenzell Meistersr�te' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754925.2, 1242742.6],
                [2751278.3, 1242270.5],
                [2745932.1, 1240882.1],
                [2746819.9, 1244582.1],
                [2747064.2, 1245966.3],
                [2748723.2, 1245748.6],
                [2749545.6, 1246827.1],
                [2750146.2, 1247975.4],
                [2753959.7, 1246037.3],
                [2756059.5, 1246269.1],
                [2754925.2, 1242742.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4953', ortbez27: 'Schwarzenbach (Huttwil)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2630908.7, 1215783.2], [2628192.5, 1216380.5], [2629373.7, 1219175.6], [2630908.7, 1215783.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9053', ortbez27: 'Teufen AR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2750740.2, 1251083.9],
                [2747771.7, 1248718.3],
                [2746210.6, 1250360.5],
                [2745754.9, 1252202.5],
                [2746261.3, 1253487.2],
                [2747867.5, 1252009.1],
                [2750075.7, 1252774.3],
                [2750740.2, 1251083.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4954', ortbez27: 'Wyssachen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630908.7, 1215783.2],
                [2630358.5, 1211851.8],
                [2627144.5, 1212430.9],
                [2628192.5, 1216380.5],
                [2630908.7, 1215783.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9054', ortbez27: 'Haslen AI' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747771.7, 1248718.3],
                [2750146.2, 1247975.4],
                [2749545.6, 1246827.1],
                [2748723.2, 1245748.6],
                [2747064.2, 1245966.3],
                [2745323, 1245362.6],
                [2744597.4, 1246818.4],
                [2744424.4, 1249943.8],
                [2746210.6, 1250360.5],
                [2747771.7, 1248718.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4952', ortbez27: 'Eriswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630908.7, 1215783.2],
                [2633516.8, 1214440.7],
                [2633130, 1213097.1],
                [2632653, 1211899.9],
                [2630358.5, 1211851.8],
                [2630908.7, 1215783.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9052', ortbez27: 'Niederteufen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2745754.9, 1252202.5], [2746210.6, 1250360.5], [2744424.4, 1249943.8], [2745754.9, 1252202.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4955', ortbez27: 'Gondiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633615.3, 1225266.6],
                [2633727.8, 1222074.1],
                [2634721.8, 1220477.7],
                [2633076.5, 1219470.1],
                [2631280.1, 1220165.2],
                [2630978.5, 1222169.9],
                [2633615.3, 1225266.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9042', ortbez27: 'Speicher' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2750075.7, 1252774.3],
                [2749523.3, 1255311.1],
                [2750276.4, 1256456.9],
                [2752464.8, 1255799.6],
                [2753510.6, 1254034.3],
                [2752263.5, 1250438.9],
                [2750740.2, 1251083.9],
                [2750075.7, 1252774.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4943', ortbez27: 'Oeschenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624618.5, 1217700.3],
                [2624259.8, 1216084.9],
                [2621724.3, 1217039.3],
                [2622148.6, 1218376.4],
                [2624618.5, 1217700.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9043', ortbez27: 'Trogen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2756304.7, 1251339.5], [2752263.5, 1250438.9], [2753510.6, 1254034.3], [2756304.7, 1251339.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4944', ortbez27: 'Auswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2630978.5, 1222169.9],
                [2631280.1, 1220165.2],
                [2629373.7, 1219175.6],
                [2629209.7, 1221383.6],
                [2630978.5, 1222169.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9044', ortbez27: 'Wald AR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2753510.6, 1254034.3], [2757616.1, 1254736.5], [2756304.7, 1251339.5], [2753510.6, 1254034.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9033', ortbez27: 'Untereggen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751298.7, 1259190.8],
                [2753849.3, 1258981.4],
                [2754279.3, 1257717.5],
                [2751768.9, 1257034.6],
                [2750276.4, 1256456.9],
                [2749857.1, 1257814.1],
                [2751298.7, 1259190.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4950', ortbez27: 'Huttwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2631280.1, 1220165.2],
                [2633076.5, 1219470.1],
                [2633516.8, 1214440.7],
                [2630908.7, 1215783.2],
                [2629373.7, 1219175.6],
                [2631280.1, 1220165.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9034', ortbez27: 'Eggersriet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2750276.4, 1256456.9],
                [2751768.9, 1257034.6],
                [2754279.3, 1257717.5],
                [2755203.7, 1256809.8],
                [2752464.8, 1255799.6],
                [2750276.4, 1256456.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4937', ortbez27: 'Ursenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626747, 1219075.8],
                [2624618.5, 1217700.3],
                [2622148.6, 1218376.4],
                [2621064.7, 1219010.9],
                [2623015.4, 1220812.3],
                [2625689.5, 1222226.2],
                [2626747, 1219075.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9032', ortbez27: 'Engelburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2741575.4, 1256033.8],
                [2742199.4, 1258138],
                [2745160.7, 1258194],
                [2745147.1, 1256857.1],
                [2743554.8, 1255110.3],
                [2741575.4, 1256033.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4938', ortbez27: 'Rohrbachgraben' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2626512.7, 1216549.2],
                [2626747, 1219075.8],
                [2627848.9, 1221613],
                [2629209.7, 1221383.6],
                [2629373.7, 1219175.6],
                [2628192.5, 1216380.5],
                [2626512.7, 1216549.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9038', ortbez27: 'Rehetobel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757018.1, 1255956.6],
                [2757616.1, 1254736.5],
                [2753510.6, 1254034.3],
                [2752464.8, 1255799.6],
                [2755203.7, 1256809.8],
                [2757018.1, 1255956.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4935', ortbez27: 'Leimiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2623015.4, 1220812.3], [2624085.8, 1223896.9], [2625689.5, 1222226.2], [2623015.4, 1220812.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9035', ortbez27: 'Grub AR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2757491.1, 1258828.1], [2757018.1, 1255956.6], [2755203.7, 1256809.8], [2757491.1, 1258828.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4936', ortbez27: 'Kleindietwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2627848.9, 1221613],
                [2626747, 1219075.8],
                [2625689.5, 1222226.2],
                [2627080.2, 1222650.2],
                [2627848.9, 1221613]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '4942', ortbez27: 'Walterswil BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2624259.8, 1216084.9],
                [2624618.5, 1217700.3],
                [2626747, 1219075.8],
                [2626512.7, 1216549.2],
                [2624259.8, 1216084.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9063', ortbez27: 'Stein AR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744424.4, 1249943.8],
                [2744597.4, 1246818.4],
                [2742334.1, 1248863.6],
                [2742454.8, 1251846.1],
                [2744424.4, 1249943.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9064', ortbez27: 'Hundwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2745323, 1245362.6],
                [2743001.4, 1244266.3],
                [2741859.1, 1243588],
                [2740515.4, 1244011.4],
                [2740445.9, 1245925.2],
                [2742334.1, 1248863.6],
                [2744597.4, 1246818.4],
                [2745323, 1245362.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9122', ortbez27: 'Mogelsberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732113.4, 1244975.9],
                [2731659.5, 1243655.6],
                [2727307.9, 1244522.5],
                [2728137.2, 1245725.6],
                [2727254.5, 1247408.5],
                [2730256, 1247668.5],
                [2732113.4, 1244975.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5025', ortbez27: 'Asp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644700.7, 1254221.2],
                [2644663.2, 1255993.2],
                [2648214.3, 1255253.8],
                [2647526, 1253497.4],
                [2644700.7, 1254221.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9125', ortbez27: 'Brunnadern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727487.8, 1242081.1],
                [2727307.9, 1244522.5],
                [2731659.5, 1243655.6],
                [2731871.3, 1241936.1],
                [2730187.1, 1241503.4],
                [2727487.8, 1242081.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5026', ortbez27: 'Densb�ren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644352.6, 1257412.7],
                [2647997.7, 1257571.7],
                [2650031.9, 1256719.7],
                [2648214.3, 1255253.8],
                [2644663.2, 1255993.2],
                [2644352.6, 1257412.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5023', ortbez27: 'Biberstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647766, 1251389.7],
                [2647526, 1253497.4],
                [2649864.3, 1252866.9],
                [2650294.2, 1251638.5],
                [2647766, 1251389.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9123', ortbez27: 'Nassen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731828.8, 1249118.7],
                [2730256, 1247668.5],
                [2727254.5, 1247408.5],
                [2727492.9, 1248936.3],
                [2729437, 1250732.4],
                [2731828.8, 1249118.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5024', ortbez27: 'K�ttigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644700.7, 1254221.2],
                [2647526, 1253497.4],
                [2647766, 1251389.7],
                [2646128.9, 1251233.7],
                [2644700.7, 1254221.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9113', ortbez27: 'Degersheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2730256, 1247668.5],
                [2731828.8, 1249118.7],
                [2732560.5, 1250584],
                [2734091.3, 1250371.9],
                [2734695.1, 1248616.5],
                [2733196, 1245620.4],
                [2732113.4, 1244975.9],
                [2730256, 1247668.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5027', ortbez27: 'Herznach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2648064.6, 1260191.1],
                [2647997.7, 1257571.7],
                [2644352.6, 1257412.7],
                [2644089.8, 1259195.7],
                [2644873.7, 1260537],
                [2648064.6, 1260191.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9112', ortbez27: 'Schachen b. Herisau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737155.2, 1250700.8],
                [2736351.4, 1248129.4],
                [2734695.1, 1248616.5],
                [2734091.3, 1250371.9],
                [2735524, 1250321.9],
                [2737155.2, 1250700.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5018', ortbez27: 'Erlinsbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641422.7, 1253465.2],
                [2644700.7, 1254221.2],
                [2646128.9, 1251233.7],
                [2645166.7, 1250427],
                [2640311.7, 1252327.4],
                [2641422.7, 1253465.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5015', ortbez27: 'Erlinsbach SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643758.8, 1248342.3],
                [2642532, 1247925.9],
                [2641364.6, 1248812],
                [2640311.7, 1252327.4],
                [2645166.7, 1250427],
                [2643758.8, 1248342.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9115', ortbez27: 'Dicken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733983.1, 1242777.9],
                [2731659.5, 1243655.6],
                [2732113.4, 1244975.9],
                [2733631.3, 1244380.1],
                [2733983.1, 1242777.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9116', ortbez27: 'Wolfertswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2731828.8, 1249118.7], [2729437, 1250732.4], [2732560.5, 1250584], [2731828.8, 1249118.7]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9105', ortbez27: 'Sch�nengrund' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737226.5, 1243769.9],
                [2734616.3, 1241351.1],
                [2733983.1, 1242777.9],
                [2733631.3, 1244380.1],
                [2734870.8, 1245293.8],
                [2737226.5, 1243769.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9103', ortbez27: 'Schwellbrunn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736351.4, 1248129.4],
                [2737976.3, 1246949.7],
                [2738843.7, 1245245.1],
                [2737226.5, 1243769.9],
                [2734870.8, 1245293.8],
                [2733631.3, 1244380.1],
                [2732113.4, 1244975.9],
                [2733196, 1245620.4],
                [2734695.1, 1248616.5],
                [2736351.4, 1248129.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9104', ortbez27: 'Waldstatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2738843.7, 1245245.1],
                [2737976.3, 1246949.7],
                [2742334.1, 1248863.6],
                [2740445.9, 1245925.2],
                [2738843.7, 1245245.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9107', ortbez27: 'Urn�sch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733963.2, 1238244.1],
                [2734616.3, 1241351.1],
                [2737226.5, 1243769.9],
                [2738843.7, 1245245.1],
                [2740445.9, 1245925.2],
                [2740515.4, 1244011.4],
                [2741859.1, 1243588],
                [2741999.1, 1242307.9],
                [2742151.1, 1240849.7],
                [2741974.1, 1239607.5],
                [2743701.4, 1236182.1],
                [2741972.6, 1235364],
                [2736038.2, 1236644.2],
                [2733963.2, 1238244.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9108', ortbez27: 'Gonten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744585.2, 1240447.9],
                [2742151.1, 1240849.7],
                [2741999.1, 1242307.9],
                [2741859.1, 1243588],
                [2743001.4, 1244266.3],
                [2745323, 1245362.6],
                [2747064.2, 1245966.3],
                [2746819.9, 1244582.1],
                [2745932.1, 1240882.1],
                [2744585.2, 1240447.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5013', ortbez27: 'Niederg�sgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639850.9, 1246543],
                [2640306.2, 1247800.8],
                [2641364.6, 1248812],
                [2642532, 1247925.9],
                [2642050, 1246420.6],
                [2639850.9, 1246543]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5014', ortbez27: 'Gretzenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2642050, 1246420.6], [2644215.8, 1246321.1], [2643387.6, 1244639.3], [2642050, 1246420.6]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5012', ortbez27: 'Sch�nenwerd' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642532, 1247925.9],
                [2643758.8, 1248342.3],
                [2644215.8, 1246321.1],
                [2642050, 1246420.6],
                [2642532, 1247925.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5000', ortbez27: 'Aarau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647249.6, 1247100.9],
                [2646023.9, 1247510.6],
                [2644215.8, 1246321.1],
                [2643758.8, 1248342.3],
                [2645166.7, 1250427],
                [2646128.9, 1251233.7],
                [2647766, 1251389.7],
                [2647820.3, 1250041.3],
                [2647042.4, 1248342.4],
                [2647249.6, 1247100.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9100', ortbez27: 'Herisau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737976.3, 1246949.7],
                [2736351.4, 1248129.4],
                [2737155.2, 1250700.8],
                [2738554.8, 1251762.2],
                [2740158.5, 1251321.8],
                [2742454.8, 1251846.1],
                [2742334.1, 1248863.6],
                [2737976.3, 1246949.7]
              ]
            ],
            [
              [
                [2734091.3, 1250371.9],
                [2734547.7, 1251759.3],
                [2737155.2, 1250700.8],
                [2735524, 1250321.9],
                [2734091.3, 1250371.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5057', ortbez27: 'Reitnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647344.7, 1233200.1],
                [2643772.5, 1232896.1],
                [2643816.2, 1234332.2],
                [2644071.7, 1235740.7],
                [2646744.7, 1234608.8],
                [2647344.7, 1233200.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5062', ortbez27: 'Oberhof' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644663.2, 1255993.2],
                [2644700.7, 1254221.2],
                [2641422.7, 1253465.2],
                [2641059.3, 1255344.5],
                [2642703.2, 1256215.6],
                [2644663.2, 1255993.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5053', ortbez27: 'Staffelbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644053.7, 1238130.9],
                [2647303.9, 1238345.4],
                [2646773.1, 1236516.2],
                [2646744.7, 1234608.8],
                [2644071.7, 1235740.7],
                [2642920.2, 1236241.3],
                [2644053.7, 1238130.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5054', ortbez27: 'Moosleerau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2646744.7, 1234608.8],
                [2646773.1, 1236516.2],
                [2647303.9, 1238345.4],
                [2649460.1, 1235817.3],
                [2648608.7, 1233216.2],
                [2647344.7, 1233200.1],
                [2646744.7, 1234608.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5042', ortbez27: 'Hirschthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2648978.7, 1241597.8], [2648737.9, 1239464.5], [2645794.3, 1241180.2], [2648978.7, 1241597.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5040', ortbez27: 'Sch�ftland' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2648737.9, 1239464.5],
                [2647303.9, 1238345.4],
                [2644053.7, 1238130.9],
                [2645231.7, 1240021.5],
                [2645794.3, 1241180.2],
                [2648737.9, 1239464.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5046', ortbez27: 'Walde AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651548.9, 1235175.8],
                [2653379.2, 1233318.8],
                [2651998, 1232651.3],
                [2650602.1, 1233484],
                [2649460.1, 1235817.3],
                [2651952.4, 1236934.3],
                [2651548.9, 1235175.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5044', ortbez27: 'Schlossrued' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2650368.1, 1239725.5],
                [2651952.4, 1236934.3],
                [2649460.1, 1235817.3],
                [2647303.9, 1238345.4],
                [2648737.9, 1239464.5],
                [2650368.1, 1239725.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9127', ortbez27: 'St. Peterzell' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2731659.5, 1243655.6], [2733983.1, 1242777.9], [2731871.3, 1241936.1], [2731659.5, 1243655.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5033', ortbez27: 'Buchs AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2650294.2, 1251638.5],
                [2651582.5, 1251462.4],
                [2651212.2, 1249367.7],
                [2648606.8, 1248341.5],
                [2647249.6, 1247100.9],
                [2647042.4, 1248342.4],
                [2647820.3, 1250041.3],
                [2647766, 1251389.7],
                [2650294.2, 1251638.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5034', ortbez27: 'Suhr' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647873.4, 1244579.4],
                [2646920.4, 1245893.5],
                [2647249.6, 1247100.9],
                [2648606.8, 1248341.5],
                [2651212.2, 1249367.7],
                [2651101.3, 1247865],
                [2649479.1, 1246771.6],
                [2647873.4, 1244579.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5037', ortbez27: 'Muhen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2645956.5, 1244097.1],
                [2647873.4, 1244579.4],
                [2648978.7, 1241597.8],
                [2645794.3, 1241180.2],
                [2645702.6, 1242472.1],
                [2645956.5, 1244097.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5035', ortbez27: 'Unterentfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647249.6, 1247100.9],
                [2646920.4, 1245893.5],
                [2644215.8, 1246321.1],
                [2646023.9, 1247510.6],
                [2647249.6, 1247100.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5036', ortbez27: 'Oberentfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2645956.5, 1244097.1],
                [2643387.6, 1244639.3],
                [2644215.8, 1246321.1],
                [2646920.4, 1245893.5],
                [2647873.4, 1244579.4],
                [2645956.5, 1244097.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5082', ortbez27: 'Kaisten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642939.9, 1267407.7],
                [2645104.7, 1267244.8],
                [2648199, 1266490.2],
                [2648232.8, 1264102.5],
                [2645084, 1263726.1],
                [2642954.8, 1265231.9],
                [2642939.9, 1267407.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5079', ortbez27: 'Zeihen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647997.7, 1257571.7],
                [2648064.6, 1260191.1],
                [2649439.4, 1259310],
                [2650031.9, 1256719.7],
                [2647997.7, 1257571.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5080', ortbez27: 'Laufenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2645104.7, 1267244.8], [2647933, 1268253.9], [2648199, 1266490.2], [2645104.7, 1267244.8]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5085', ortbez27: 'Sulz AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651416.1, 1262963.2],
                [2648716.1, 1262716.4],
                [2648232.8, 1264102.5],
                [2648199, 1266490.2],
                [2649678, 1266611.9],
                [2651416.1, 1262963.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5084', ortbez27: 'Rheinsulz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649678, 1266611.9],
                [2648199, 1266490.2],
                [2647933, 1268253.9],
                [2650063.9, 1267872.3],
                [2649678, 1266611.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5073', ortbez27: 'Gipf-Oberfrick' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638598.1, 1260394.4],
                [2640728, 1262344.1],
                [2643423.6, 1261223.3],
                [2644873.7, 1260537],
                [2644089.8, 1259195.7],
                [2641995.6, 1259333.7],
                [2638598.1, 1260394.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5074', ortbez27: 'Eiken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639038.1, 1264341.6],
                [2640201.6, 1266708.4],
                [2642939.9, 1267407.7],
                [2642954.8, 1265231.9],
                [2642460.1, 1263810.2],
                [2641117.4, 1263844.9],
                [2639038.1, 1264341.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5072', ortbez27: 'Oeschgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2642460.1, 1263810.2], [2642954.8, 1265231.9], [2645084, 1263726.1], [2642460.1, 1263810.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5077', ortbez27: 'Elfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2648716.1, 1262716.4], [2651416.1, 1262963.2], [2650259.8, 1261463.6], [2648716.1, 1262716.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5075', ortbez27: 'Hornussen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2648716.1, 1262716.4],
                [2648064.6, 1260191.1],
                [2644873.7, 1260537],
                [2645084, 1263726.1],
                [2648232.8, 1264102.5],
                [2648716.1, 1262716.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5076', ortbez27: 'B�zen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649439.4, 1259310],
                [2648064.6, 1260191.1],
                [2648716.1, 1262716.4],
                [2650259.8, 1261463.6],
                [2649439.4, 1259310]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5063', ortbez27: 'W�lflinswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640465.2, 1256848.7],
                [2641995.6, 1259333.7],
                [2644089.8, 1259195.7],
                [2644352.6, 1257412.7],
                [2644663.2, 1255993.2],
                [2642703.2, 1256215.6],
                [2641059.3, 1255344.5],
                [2640465.2, 1256848.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5064', ortbez27: 'Wittnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2637883, 1257078.7],
                [2638598.1, 1260394.4],
                [2641995.6, 1259333.7],
                [2640465.2, 1256848.7],
                [2639205.4, 1256327.4],
                [2637883, 1257078.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9217', ortbez27: 'Neukirch an der Thur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732245.6, 1264247.8],
                [2731523, 1261680.3],
                [2730200, 1263046.8],
                [2730069.3, 1264422],
                [2730983.9, 1265555.6],
                [2732245.6, 1264247.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5070', ortbez27: 'Frick' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642460.1, 1263810.2],
                [2645084, 1263726.1],
                [2644873.7, 1260537],
                [2643423.6, 1261223.3],
                [2640728, 1262344.1],
                [2641117.4, 1263844.9],
                [2642460.1, 1263810.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9215', ortbez27: 'Sch�nenberg an der Thur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2732245.6, 1264247.8], [2733563.9, 1264939.2], [2733662.1, 1262650.9], [2732245.6, 1264247.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9216', ortbez27: 'Hohentannen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2735429.7, 1263406.5],
                [2733662.1, 1262650.9],
                [2733563.9, 1264939.2],
                [2734618.5, 1266463.1],
                [2736192, 1266013.6],
                [2735429.7, 1263406.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1025', ortbez27: 'St-Sulpice VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2534486.1, 1145413.2],
                [2530222.3, 1145535.9],
                [2531236.2, 1151906.6],
                [2532507.8, 1151966.5],
                [2534610.7, 1152703.9],
                [2534655.5, 1151299.9],
                [2534486.1, 1145413.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9220', ortbez27: 'Bischofszell' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733662.1, 1262650.9],
                [2735429.7, 1263406.5],
                [2737343.7, 1263628.1],
                [2738820.8, 1263931.2],
                [2740868.4, 1263088],
                [2739767.1, 1261994.1],
                [2737295.8, 1262172.1],
                [2736399.6, 1260065.1],
                [2735267.2, 1260620.5],
                [2733517.8, 1260482.5],
                [2733662.1, 1262650.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1026', ortbez27: 'Echandens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530433, 1153082.2],
                [2529726.2, 1154785.6],
                [2530312.3, 1157245.9],
                [2531963.5, 1155000.8],
                [2531600.6, 1153678.4],
                [2530433, 1153082.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1023', ortbez27: 'Crissier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2533995.4, 1154642.6],
                [2533016, 1157016.1],
                [2533744.8, 1158117.6],
                [2535161.8, 1156992.4],
                [2535482.4, 1155088.9],
                [2533995.4, 1154642.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1024', ortbez27: 'Ecublens VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2531236.2, 1151906.6],
                [2530433, 1153082.2],
                [2531600.6, 1153678.4],
                [2531963.5, 1155000.8],
                [2533995.4, 1154642.6],
                [2532507.8, 1151966.5],
                [2531236.2, 1151906.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1030', ortbez27: 'Bussigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2533995.4, 1154642.6],
                [2531963.5, 1155000.8],
                [2530312.3, 1157245.9],
                [2531806.9, 1157466.6],
                [2533016, 1157016.1],
                [2533995.4, 1154642.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9213', ortbez27: 'Hauptwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2737295.8, 1262172.1], [2738711.1, 1260521.2], [2736399.6, 1260065.1], [2737295.8, 1262172.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1027', ortbez27: 'Lonay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2528214.9, 1155138.6],
                [2529726.2, 1154785.6],
                [2530433, 1153082.2],
                [2528610.9, 1152982.8],
                [2528214.9, 1155138.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9214', ortbez27: 'Kradolf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2730983.9, 1265555.6],
                [2732637.1, 1267062],
                [2733563.9, 1264939.2],
                [2732245.6, 1264247.8],
                [2730983.9, 1265555.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1028', ortbez27: 'Pr�verenges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530222.3, 1145535.9],
                [2528610.9, 1152982.8],
                [2530433, 1153082.2],
                [2531236.2, 1151906.6],
                [2530222.3, 1145535.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5112', ortbez27: 'Thalheim AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651814.8, 1253246],
                [2649864.3, 1252866.9],
                [2647526, 1253497.4],
                [2648214.3, 1255253.8],
                [2650031.9, 1256719.7],
                [2651308.8, 1256965.1],
                [2651814.8, 1253246]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9212', ortbez27: 'Arnegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736338.2, 1257486.6],
                [2737701.6, 1257256.3],
                [2737218.9, 1254836],
                [2735898, 1256183.4],
                [2736338.2, 1257486.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1018', ortbez27: 'Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2536748.4, 1154964.2], [2538996.2, 1155159.6], [2539172.8, 1153341.9], [2536748.4, 1154964.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9200', ortbez27: 'Gossau SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733420.1, 1253250.4],
                [2734760.2, 1256764.4],
                [2735898, 1256183.4],
                [2737218.9, 1254836],
                [2740582.1, 1254487.1],
                [2740158.5, 1251321.8],
                [2738554.8, 1251762.2],
                [2737155.2, 1250700.8],
                [2734547.7, 1251759.3],
                [2733420.1, 1253250.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5105', ortbez27: 'Auenstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2654115.5, 1252008.5],
                [2651582.5, 1251462.4],
                [2650294.2, 1251638.5],
                [2649864.3, 1252866.9],
                [2651814.8, 1253246],
                [2654115.5, 1252008.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1022', ortbez27: 'Chavannes-pr�s-Renens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2534610.7, 1152703.9], [2532507.8, 1151966.5], [2533995.4, 1154642.6], [2534610.7, 1152703.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9205', ortbez27: 'Waldkirch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736399.6, 1260065.1],
                [2738711.1, 1260521.2],
                [2739767.1, 1261994.1],
                [2741930.8, 1261864],
                [2742199.4, 1258138],
                [2741575.4, 1256033.8],
                [2740582.1, 1254487.1],
                [2739348.6, 1257829.9],
                [2737701.6, 1257256.3],
                [2736338.2, 1257486.6],
                [2736399.6, 1260065.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5106', ortbez27: 'Veltheim AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2652713.7, 1254865.1],
                [2655443, 1254945.6],
                [2656613.4, 1253257.9],
                [2655349.4, 1252690.3],
                [2654115.5, 1252008.5],
                [2651814.8, 1253246],
                [2652713.7, 1254865.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5103', ortbez27: 'M�riken AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653888.3, 1250593.7],
                [2654115.5, 1252008.5],
                [2655349.4, 1252690.3],
                [2656613.4, 1253257.9],
                [2658198.2, 1253237.4],
                [2658767.8, 1251763.7],
                [2657129.6, 1250896],
                [2655930.4, 1251264.8],
                [2653888.3, 1250593.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1020', ortbez27: 'Renens VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2535878.5, 1153076.1],
                [2534610.7, 1152703.9],
                [2533995.4, 1154642.6],
                [2535482.4, 1155088.9],
                [2535878.5, 1153076.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9203', ortbez27: 'Niederwil SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731267.8, 1255075.2],
                [2732339.2, 1256936.6],
                [2734760.2, 1256764.4],
                [2733420.1, 1253250.4],
                [2731267.8, 1255075.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1009', ortbez27: 'Pully' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541365.9, 1145215.2],
                [2538754.2, 1145290.4],
                [2539635.1, 1151814.8],
                [2541113.2, 1153445.9],
                [2541344.2, 1151730.6],
                [2541365.9, 1145215.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9204', ortbez27: 'Andwil SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2740582.1, 1254487.1],
                [2737218.9, 1254836],
                [2737701.6, 1257256.3],
                [2739348.6, 1257829.9],
                [2740582.1, 1254487.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1010', ortbez27: 'Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541113.2, 1153445.9],
                [2539172.8, 1153341.9],
                [2538996.2, 1155159.6],
                [2540100.9, 1155750.5],
                [2541113.2, 1153445.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1007', ortbez27: 'Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538754.2, 1145290.4],
                [2534486.1, 1145413.2],
                [2534655.5, 1151299.9],
                [2534610.7, 1152703.9],
                [2535878.5, 1153076.1],
                [2537762.1, 1152224.4],
                [2538754.2, 1145290.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5107', ortbez27: 'Schinznach Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655443, 1254945.6],
                [2652713.7, 1254865.1],
                [2651308.8, 1256965.1],
                [2652884.9, 1257807.5],
                [2655598.4, 1257597.1],
                [2655403, 1256351.4],
                [2655443, 1254945.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1008', ortbez27: 'Prilly' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2536748.4, 1154964.2], [2535878.5, 1153076.1], [2535482.4, 1155088.9], [2536748.4, 1154964.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5108', ortbez27: 'Oberflachs' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2652713.7, 1254865.1], [2651814.8, 1253246], [2651308.8, 1256965.1], [2652713.7, 1254865.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1012', ortbez27: 'Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2541113.2, 1153445.9], [2539635.1, 1151814.8], [2539172.8, 1153341.9], [2541113.2, 1153445.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9249', ortbez27: 'Algetshausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725024.4, 1258002.7],
                [2727531.1, 1256558.6],
                [2726951.2, 1255424.3],
                [2725023.7, 1255709.7],
                [2722715.8, 1256634.5],
                [2725024.4, 1258002.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5102', ortbez27: 'Rupperswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651582.5, 1251462.4],
                [2654115.5, 1252008.5],
                [2653888.3, 1250593.7],
                [2652738.6, 1249361.8],
                [2651212.2, 1249367.7],
                [2651582.5, 1251462.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1000', ortbez27: 'Lausanne 25' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2542060.6, 1159301.5],
                [2543941.2, 1159301.1],
                [2544161.7, 1157404.4],
                [2542235.3, 1156562.4],
                [2541422.1, 1157553.9],
                [2542060.6, 1159301.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9247', ortbez27: 'Henau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727078.3, 1258606.9],
                [2728902.5, 1257560.8],
                [2727531.1, 1256558.6],
                [2725024.4, 1258002.7],
                [2727078.3, 1258606.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1005', ortbez27: 'Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2539172.8, 1153341.9], [2539635.1, 1151814.8], [2537762.1, 1152224.4], [2539172.8, 1153341.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9248', ortbez27: 'Bichwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729660.9, 1254554.5],
                [2728720.9, 1252256.7],
                [2725929.4, 1252004],
                [2724953.5, 1252787.9],
                [2726554, 1253736.7],
                [2728202.9, 1254881.7],
                [2729660.9, 1254554.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1006', ortbez27: 'Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2538754.2, 1145290.4], [2537762.1, 1152224.4], [2539635.1, 1151814.8], [2538754.2, 1145290.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1004', ortbez27: 'Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2537762.1, 1152224.4],
                [2535878.5, 1153076.1],
                [2536748.4, 1154964.2],
                [2539172.8, 1153341.9],
                [2537762.1, 1152224.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1058', ortbez27: 'Villars-Tiercelin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2545437.2, 1162810.2], [2544306.9, 1161216.3], [2543705.6, 1165481.9], [2545437.2, 1162810.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1055', ortbez27: 'Froideville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2543941.2, 1159301.1],
                [2542060.6, 1159301.5],
                [2540877.3, 1161498.5],
                [2542593.9, 1162527],
                [2544306.9, 1161216.3],
                [2543941.2, 1159301.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9242', ortbez27: 'Oberuzwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726554, 1253736.7],
                [2725023.7, 1255709.7],
                [2726951.2, 1255424.3],
                [2728202.9, 1254881.7],
                [2726554, 1253736.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1061', ortbez27: 'Villars-Mendraz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546813.5, 1165760.9],
                [2543705.6, 1165481.9],
                [2544196.3, 1167289.2],
                [2545155.5, 1168162.4],
                [2546813.5, 1165760.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1062', ortbez27: 'Sottens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2548662.8, 1166960.1],
                [2546813.5, 1165760.9],
                [2545155.5, 1168162.4],
                [2547307.1, 1167998.2],
                [2548662.8, 1166960.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9240', ortbez27: 'Niederglatt SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2728202.9, 1254881.7], [2729460.3, 1255806], [2729660.9, 1254554.5], [2728202.9, 1254881.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9245', ortbez27: 'Oberb�ren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729447.4, 1259171.8],
                [2731563.6, 1259005.6],
                [2732339.2, 1256936.6],
                [2731267.8, 1255075.2],
                [2729660.9, 1254554.5],
                [2729460.3, 1255806],
                [2728902.5, 1257560.8],
                [2729447.4, 1259171.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1059', ortbez27: 'Peney-le-Jorat' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546813.5, 1165760.9],
                [2546437.9, 1163987.4],
                [2545437.2, 1162810.2],
                [2543705.6, 1165481.9],
                [2546813.5, 1165760.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9246', ortbez27: 'Niederb�ren' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2734760.2, 1256764.4],
                [2732339.2, 1256936.6],
                [2731563.6, 1259005.6],
                [2733517.8, 1260482.5],
                [2735267.2, 1260620.5],
                [2736399.6, 1260065.1],
                [2736338.2, 1257486.6],
                [2735898, 1256183.4],
                [2734760.2, 1256764.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9243', ortbez27: 'Jonschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723598.9, 1252141],
                [2723595.9, 1253444.1],
                [2723527.9, 1254764.9],
                [2725023.7, 1255709.7],
                [2726554, 1253736.7],
                [2724953.5, 1252787.9],
                [2723598.9, 1252141]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9244', ortbez27: 'Niederuzwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728902.5, 1257560.8],
                [2729460.3, 1255806],
                [2728202.9, 1254881.7],
                [2726951.2, 1255424.3],
                [2727531.1, 1256558.6],
                [2728902.5, 1257560.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1047', ortbez27: 'Oppens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541028.7, 1173979.2],
                [2543825.6, 1175684.4],
                [2544331.9, 1174209.7],
                [2543570, 1173071.5],
                [2541028.7, 1173979.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1053', ortbez27: 'Cugy VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2540877.3, 1161498.5],
                [2542060.6, 1159301.5],
                [2538079.1, 1159057.4],
                [2538956.5, 1160370.3],
                [2537832.2, 1161813.8],
                [2539015.6, 1163171.4],
                [2540877.3, 1161498.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1054', ortbez27: 'Morrens VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538079.1, 1159057.4],
                [2536863.9, 1160431.1],
                [2537832.2, 1161813.8],
                [2538956.5, 1160370.3],
                [2538079.1, 1159057.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1052', ortbez27: 'Le Mont-sur-Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538996.2, 1155159.6],
                [2536748.4, 1154964.2],
                [2538079.1, 1159057.4],
                [2542060.6, 1159301.5],
                [2541422.1, 1157553.9],
                [2542235.3, 1156562.4],
                [2540100.9, 1155750.5],
                [2538996.2, 1155159.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1041', ortbez27: 'Dommartin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2539015.6, 1163171.4],
                [2539787.6, 1164859.2],
                [2540432.1, 1166940.4],
                [2542650, 1167357.5],
                [2544196.3, 1167289.2],
                [2543705.6, 1165481.9],
                [2544306.9, 1161216.3],
                [2542593.9, 1162527],
                [2540877.3, 1161498.5],
                [2539015.6, 1163171.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1042', ortbez27: 'Bettens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2536738.3, 1164175.3],
                [2539787.6, 1164859.2],
                [2539015.6, 1163171.4],
                [2537832.2, 1161813.8],
                [2536720, 1162639.6],
                [2534360.6, 1162874.8],
                [2533097.3, 1163560.4],
                [2533283.9, 1165103.3],
                [2534871.7, 1165659.7],
                [2535053, 1164395.7],
                [2536738.3, 1164175.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9225', ortbez27: 'Wilen (Gottshaus)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2738711.1, 1260521.2], [2737295.8, 1262172.1], [2739767.1, 1261994.1], [2738711.1, 1260521.2]]],
            [[[2739767.1, 1261994.1], [2740868.4, 1263088], [2741930.8, 1261864], [2739767.1, 1261994.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1040', ortbez27: 'Villars-le-Terroir' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2536738.3, 1164175.3],
                [2535053, 1164395.7],
                [2534871.7, 1165659.7],
                [2536070.6, 1166481.2],
                [2537731.6, 1166850.6],
                [2537780.8, 1170684.5],
                [2539069.8, 1168986.2],
                [2541173, 1170898.8],
                [2540432.1, 1166940.4],
                [2539787.6, 1164859.2],
                [2536738.3, 1164175.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9223', ortbez27: 'Schweizersholz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731523, 1261680.3],
                [2732245.6, 1264247.8],
                [2733662.1, 1262650.9],
                [2733517.8, 1260482.5],
                [2731523, 1261680.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1045', ortbez27: 'Ogens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546400.6, 1172865.2],
                [2544986, 1171976.6],
                [2543570, 1173071.5],
                [2544331.9, 1174209.7],
                [2546073.5, 1174853],
                [2546400.6, 1172865.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9230', ortbez27: 'Flawil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2734091.3, 1250371.9],
                [2732560.5, 1250584],
                [2729437, 1250732.4],
                [2728720.9, 1252256.7],
                [2729660.9, 1254554.5],
                [2731267.8, 1255075.2],
                [2733420.1, 1253250.4],
                [2734547.7, 1251759.3],
                [2734091.3, 1250371.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1044', ortbez27: 'Fey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2543897.8, 1169308.8],
                [2542650, 1167357.5],
                [2540432.1, 1166940.4],
                [2541173, 1170898.8],
                [2542888.5, 1170848.9],
                [2543897.8, 1169308.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1033', ortbez27: 'Cheseaux-sur-Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2536863.9, 1160431.1],
                [2535161.8, 1156992.4],
                [2533744.8, 1158117.6],
                [2533911.4, 1159399.7],
                [2534656.5, 1160612.2],
                [2536863.9, 1160431.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1031', ortbez27: 'Mex VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2531806.9, 1157466.6],
                [2531985.7, 1160317.9],
                [2533911.4, 1159399.7],
                [2533744.8, 1158117.6],
                [2533016, 1157016.1],
                [2531806.9, 1157466.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1032', ortbez27: 'Romanel-sur-Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538079.1, 1159057.4],
                [2536748.4, 1154964.2],
                [2535482.4, 1155088.9],
                [2535161.8, 1156992.4],
                [2536863.9, 1160431.1],
                [2538079.1, 1159057.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1037', ortbez27: 'Etagni�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2536863.9, 1160431.1],
                [2534656.5, 1160612.2],
                [2533716.5, 1161716.1],
                [2534360.6, 1162874.8],
                [2536720, 1162639.6],
                [2537832.2, 1161813.8],
                [2536863.9, 1160431.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1038', ortbez27: 'Bercher' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2544986, 1171976.6],
                [2543897.8, 1169308.8],
                [2542888.5, 1170848.9],
                [2543570, 1173071.5],
                [2544986, 1171976.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1035', ortbez27: 'Bournens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2533716.5, 1161716.1],
                [2531985.7, 1160317.9],
                [2531712.9, 1162884.4],
                [2533097.3, 1163560.4],
                [2534360.6, 1162874.8],
                [2533716.5, 1161716.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1036', ortbez27: 'Sullens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2533911.4, 1159399.7],
                [2531985.7, 1160317.9],
                [2533716.5, 1161716.1],
                [2534656.5, 1160612.2],
                [2533911.4, 1159399.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1090', ortbez27: 'La Croix (Lutry)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2543978.1, 1151076.6],
                [2541344.2, 1151730.6],
                [2544237.1, 1154349.6],
                [2544887.2, 1152277.8],
                [2543978.1, 1151076.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1088', ortbez27: 'Ropraz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546437.9, 1163987.4],
                [2548194.8, 1164783.3],
                [2548591.4, 1163493.2],
                [2547266.8, 1160379.6],
                [2545437.2, 1162810.2],
                [2546437.9, 1163987.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1091', ortbez27: 'Grandvaux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2545996.7, 1149537.3],
                [2544434.8, 1149209.4],
                [2543978.1, 1151076.6],
                [2544887.2, 1152277.8],
                [2547507.8, 1150935.3],
                [2545996.7, 1149537.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1092', ortbez27: 'Belmont-sur-Lausanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2541344.2, 1151730.6], [2541113.2, 1153445.9], [2544237.1, 1154349.6], [2541344.2, 1151730.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1081', ortbez27: 'Montpreveyres' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2544161.7, 1157404.4],
                [2543941.2, 1159301.1],
                [2544306.9, 1161216.3],
                [2546197.6, 1159691.1],
                [2546719.4, 1158425.3],
                [2545190.8, 1156573],
                [2544161.7, 1157404.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1082', ortbez27: 'Corcelles-le-Jorat' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2544306.9, 1161216.3],
                [2545437.2, 1162810.2],
                [2547266.8, 1160379.6],
                [2546197.6, 1159691.1],
                [2544306.9, 1161216.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1085', ortbez27: 'Vulliens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551209, 1160495.9],
                [2548591.4, 1163493.2],
                [2548194.8, 1164783.3],
                [2550197.9, 1165713.7],
                [2551440.1, 1166276.6],
                [2551803, 1164519.9],
                [2552378.5, 1162733.7],
                [2551209, 1160495.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1083', ortbez27: 'M�zi�res VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2547266.8, 1160379.6],
                [2549270.7, 1160572.9],
                [2548363.1, 1157424.1],
                [2546719.4, 1158425.3],
                [2546197.6, 1159691.1],
                [2547266.8, 1160379.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1084', ortbez27: 'Carrouge VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2549270.7, 1160572.9],
                [2547266.8, 1160379.6],
                [2548591.4, 1163493.2],
                [2551209, 1160495.9],
                [2549270.7, 1160572.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1073', ortbez27: 'Savigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2544887.2, 1152277.8],
                [2544237.1, 1154349.6],
                [2542751.5, 1155227.9],
                [2542235.3, 1156562.4],
                [2544161.7, 1157404.4],
                [2545190.8, 1156573],
                [2546719.4, 1158425.3],
                [2548363.1, 1157424.1],
                [2547352.6, 1155711.4],
                [2544887.2, 1152277.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1071', ortbez27: 'St-Saphorin (Lavaux)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2550273.1, 1142761.8],
                [2548734.6, 1143210.8],
                [2549130.7, 1147805.8],
                [2547881.4, 1148348.1],
                [2549071.8, 1149452.8],
                [2551688.3, 1148588.4],
                [2551486.8, 1147145.8],
                [2550273.1, 1142761.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1072', ortbez27: 'Forel (Lavaux)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551332.1, 1155535.7],
                [2547507.8, 1150935.3],
                [2544887.2, 1152277.8],
                [2547352.6, 1155711.4],
                [2548363.1, 1157424.1],
                [2550376.7, 1156432.2],
                [2551332.1, 1155535.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1077', ortbez27: 'Servion' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2548363.1, 1157424.1],
                [2549270.7, 1160572.9],
                [2551209, 1160495.9],
                [2552200.7, 1159710.4],
                [2551057.8, 1158431],
                [2548363.1, 1157424.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1078', ortbez27: 'Essertes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2550376.7, 1156432.2], [2548363.1, 1157424.1], [2551057.8, 1158431], [2550376.7, 1156432.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1066', ortbez27: 'Epalinges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2542751.5, 1155227.9],
                [2541113.2, 1153445.9],
                [2540100.9, 1155750.5],
                [2542235.3, 1156562.4],
                [2542751.5, 1155227.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9313', ortbez27: 'Muolen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744521.1, 1265123.4],
                [2743260.5, 1263687.2],
                [2740868.4, 1263088],
                [2738820.8, 1263931.2],
                [2739983.3, 1265564.1],
                [2741401.7, 1266237.3],
                [2743223.5, 1266413.9],
                [2744521.1, 1265123.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1063', ortbez27: 'Boulens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2544196.3, 1167289.2],
                [2542650, 1167357.5],
                [2543897.8, 1169308.8],
                [2544986, 1171976.6],
                [2546263, 1170007.8],
                [2548462.8, 1169715.3],
                [2547307.1, 1167998.2],
                [2545155.5, 1168162.4],
                [2544196.3, 1167289.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9314', ortbez27: 'Steinebrunn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744468.2, 1267376.9],
                [2744521.1, 1265123.4],
                [2743223.5, 1266413.9],
                [2742675.4, 1268440.4],
                [2744468.2, 1267376.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1070', ortbez27: 'Puidoux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2548734.6, 1143210.8],
                [2545896.1, 1144039.3],
                [2547881.4, 1148348.1],
                [2549130.7, 1147805.8],
                [2548734.6, 1143210.8]
              ]
            ],
            [
              [
                [2552008.2, 1153076.8],
                [2552918.8, 1151520.9],
                [2551688.3, 1148588.4],
                [2549071.8, 1149452.8],
                [2547881.4, 1148348.1],
                [2547507.8, 1150935.3],
                [2551332.1, 1155535.7],
                [2552008.2, 1153076.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9312', ortbez27: 'H�ggenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2743612.6, 1260842.1],
                [2741930.8, 1261864],
                [2743260.5, 1263687.2],
                [2744177.5, 1262384.4],
                [2743612.6, 1260842.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1068', ortbez27: 'Les Monts-de-Pully' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2541113.2, 1153445.9], [2542751.5, 1155227.9], [2544237.1, 1154349.6], [2541113.2, 1153445.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9315', ortbez27: 'Neukirch (Egnach)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2745498.5, 1263094.4],
                [2744177.5, 1262384.4],
                [2743260.5, 1263687.2],
                [2744521.1, 1265123.4],
                [2744468.2, 1267376.9],
                [2746005.2, 1267317],
                [2747813.8, 1266097.6],
                [2745498.5, 1263094.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1121', ortbez27: 'Bremblens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2529726.2, 1154785.6], [2528214.9, 1155138.6], [2530312.3, 1157245.9], [2529726.2, 1154785.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1122', ortbez27: 'Romanel-sur-Morges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2527936.5, 1157119.7], [2530312.3, 1157245.9], [2528214.9, 1155138.6], [2527936.5, 1157119.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9305', ortbez27: 'Berg SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2748160.6, 1261790.1],
                [2749080.9, 1262803.7],
                [2749789.2, 1261344.7],
                [2748767.9, 1260384.7],
                [2747104.1, 1260249.6],
                [2748160.6, 1261790.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9306', ortbez27: 'Freidorf TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747104.1, 1260249.6],
                [2744820, 1260334],
                [2744177.5, 1262384.4],
                [2745498.5, 1263094.4],
                [2748160.6, 1261790.1],
                [2747104.1, 1260249.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9304', ortbez27: 'Bernhardzell' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744820, 1260334],
                [2745160.7, 1258194],
                [2742199.4, 1258138],
                [2741930.8, 1261864],
                [2743612.6, 1260842.1],
                [2744820, 1260334]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1126', ortbez27: 'Vaux-sur-Morges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2525850.7, 1155424.6],
                [2526583.7, 1153037.8],
                [2524657.1, 1153488.6],
                [2524084.6, 1154678.6],
                [2525850.7, 1155424.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5210', ortbez27: 'Windisch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2659587, 1260087.2], [2659976.7, 1258293.2], [2658249.8, 1259143.2], [2659587, 1260087.2]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1123', ortbez27: 'Aclens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2527866.9, 1158653.2],
                [2530214.2, 1159050.1],
                [2530312.3, 1157245.9],
                [2527936.5, 1157119.7],
                [2527866.9, 1158653.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1124', ortbez27: 'Gollion' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530214.2, 1159050.1],
                [2527866.9, 1158653.2],
                [2528178, 1160870.8],
                [2529811.3, 1161578.3],
                [2530216.9, 1160314.8],
                [2530214.2, 1159050.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1113', ortbez27: 'St-Saphorin-sur-Morges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2526583.7, 1153037.8],
                [2525850.7, 1155424.6],
                [2527936.5, 1157119.7],
                [2528214.9, 1155138.6],
                [2526583.7, 1153037.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9308', ortbez27: 'L�mmenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2744820, 1260334], [2743612.6, 1260842.1], [2744177.5, 1262384.4], [2744820, 1260334]]],
            [[[2740868.4, 1263088], [2743260.5, 1263687.2], [2741930.8, 1261864], [2740868.4, 1263088]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1114', ortbez27: 'Colombier VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2523961, 1157041.3],
                [2525120.1, 1157980.1],
                [2527936.5, 1157119.7],
                [2525850.7, 1155424.6],
                [2524084.6, 1154678.6],
                [2523961, 1157041.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1112', ortbez27: 'Echichens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2526583.7, 1153037.8], [2528214.9, 1155138.6], [2528610.9, 1152982.8], [2526583.7, 1153037.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5212', ortbez27: 'Hausen AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656974.2, 1256109.7],
                [2656975.6, 1259013.6],
                [2658249.8, 1259143.2],
                [2659976.7, 1258293.2],
                [2659525.8, 1257092.8],
                [2656974.2, 1256109.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1117', ortbez27: 'Grancy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2525229.7, 1159444.4],
                [2523806.6, 1159989.5],
                [2523722.2, 1161500.2],
                [2525463.8, 1162500.8],
                [2526477.5, 1160403.9],
                [2525229.7, 1159444.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1115', ortbez27: 'Vullierens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2525120.1, 1157980.1],
                [2525229.7, 1159444.4],
                [2526477.5, 1160403.9],
                [2527866.9, 1158653.2],
                [2527936.5, 1157119.7],
                [2525120.1, 1157980.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1116', ortbez27: 'Cottens VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2525229.7, 1159444.4], [2525120.1, 1157980.1], [2523806.6, 1159989.5], [2525229.7, 1159444.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5200', ortbez27: 'Brugg AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2658217.9, 1260742.6],
                [2659950.3, 1262693.7],
                [2660701.5, 1261450.8],
                [2659587, 1260087.2],
                [2658249.8, 1259143.2],
                [2658217.9, 1260742.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9300', ortbez27: 'Wittenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2748767.9, 1260384.7],
                [2748337.1, 1257779],
                [2745147.1, 1256857.1],
                [2745160.7, 1258194],
                [2744820, 1260334],
                [2747104.1, 1260249.6],
                [2748767.9, 1260384.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1110', ortbez27: 'Morges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530222.3, 1145535.9],
                [2528993.9, 1145298.4],
                [2526232.3, 1151699.2],
                [2526583.7, 1153037.8],
                [2528610.9, 1152982.8],
                [2530222.3, 1145535.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1098', ortbez27: 'Epesses' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2547507.8, 1150935.3], [2547881.4, 1148348.1], [2545996.7, 1149537.3], [2547507.8, 1150935.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1095', ortbez27: 'Lutry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541365.9, 1145215.2],
                [2541344.2, 1151730.6],
                [2543978.1, 1151076.6],
                [2544434.8, 1149209.4],
                [2541365.9, 1145215.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1096', ortbez27: 'Cully' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2545896.1, 1144039.3],
                [2543148.8, 1144841.3],
                [2541365.9, 1145215.2],
                [2544434.8, 1149209.4],
                [2545996.7, 1149537.3],
                [2547881.4, 1148348.1],
                [2545896.1, 1144039.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5242', ortbez27: 'Birr' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660164.9, 1253301.6],
                [2658198.2, 1253237.4],
                [2656613.4, 1253257.9],
                [2655443, 1254945.6],
                [2655403, 1256351.4],
                [2656974.2, 1256109.7],
                [2659525.8, 1257092.8],
                [2660444.8, 1255316],
                [2660164.9, 1253301.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1145', ortbez27: 'Bi�re' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2518109.1, 1154631.4],
                [2517550.7, 1152226.1],
                [2513761.2, 1152877.6],
                [2513533.3, 1154119.2],
                [2510034.2, 1157337.9],
                [2510879.6, 1158750.6],
                [2512708.3, 1160184.9],
                [2517132.8, 1157530.6],
                [2516888.8, 1156044.3],
                [2518109.1, 1154631.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5245', ortbez27: 'Habsburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656975.6, 1259013.6],
                [2656974.2, 1256109.7],
                [2655403, 1256351.4],
                [2655598.4, 1257597.1],
                [2656975.6, 1259013.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1146', ortbez27: 'Mollens VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2512708.3, 1160184.9], [2519507.2, 1158951.4], [2517132.8, 1157530.6], [2512708.3, 1160184.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1143', ortbez27: 'Apples' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2519507.2, 1158951.4],
                [2523010.4, 1157869.4],
                [2523961, 1157041.3],
                [2524084.6, 1154678.6],
                [2522773, 1154535.5],
                [2520433.9, 1154945.7],
                [2519507.2, 1158951.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1144', ortbez27: 'Ballens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2517132.8, 1157530.6],
                [2519507.2, 1158951.4],
                [2520433.9, 1154945.7],
                [2518109.1, 1154631.4],
                [2516888.8, 1156044.3],
                [2517132.8, 1157530.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5244', ortbez27: 'Birrhard' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660164.9, 1253301.6],
                [2660444.8, 1255316],
                [2661845.7, 1255822.2],
                [2663038.1, 1255135.3],
                [2661847.2, 1254166.9],
                [2660164.9, 1253301.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9327', ortbez27: 'T�bach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2751491.5, 1260950.5], [2751606.1, 1262256.2], [2752755.8, 1261390.1], [2751491.5, 1260950.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5234', ortbez27: 'Villigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659950.3, 1262693.7],
                [2657891.2, 1263269.2],
                [2655607.7, 1265135.4],
                [2657994.8, 1266604.4],
                [2659582.1, 1266106.4],
                [2659678.3, 1264256.2],
                [2659950.3, 1262693.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1147', ortbez27: 'Montricher' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2512708.3, 1160184.9],
                [2515150.8, 1164348.6],
                [2520600.9, 1162753.4],
                [2519507.2, 1158951.4],
                [2512708.3, 1160184.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1148', ortbez27: 'Mont-la-Ville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2524718.1, 1163675.2],
                [2525463.8, 1162500.8],
                [2523722.2, 1161500.2],
                [2522116.7, 1162446.3],
                [2520600.9, 1162753.4],
                [2515150.8, 1164348.6],
                [2516627.9, 1165518.5],
                [2516666.3, 1167327.9],
                [2516762.2, 1169347.4],
                [2519846.7, 1169557.7],
                [2522380.2, 1170596.3],
                [2523218.8, 1167930.8],
                [2525505.1, 1169449.9],
                [2526223.1, 1167004.8],
                [2524680.3, 1166107.1],
                [2524718.1, 1163675.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5237', ortbez27: 'M�nthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2654143, 1262246.2], [2651416.1, 1262963.2], [2654297.1, 1264714.6], [2654143, 1262246.2]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1135', ortbez27: 'Denens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2523160.4, 1153001.2],
                [2524657.1, 1153488.6],
                [2526583.7, 1153037.8],
                [2526232.3, 1151699.2],
                [2525076.1, 1150390.3],
                [2523769.3, 1151465.8],
                [2523160.4, 1153001.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9322', ortbez27: 'Egnach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2746005.2, 1267317],
                [2744468.2, 1267376.9],
                [2742675.4, 1268440.4],
                [2745483.7, 1268453.9],
                [2751631.5, 1272079.1],
                [2752663.3, 1271089.9],
                [2747813.8, 1266097.6],
                [2746005.2, 1267317]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5235', ortbez27: 'R�fenach AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657891.2, 1263269.2],
                [2659950.3, 1262693.7],
                [2658217.9, 1260742.6],
                [2656808.7, 1262170.7],
                [2657891.2, 1263269.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1136', ortbez27: 'Bussy-Chardonney' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2524657.1, 1153488.6],
                [2523160.4, 1153001.2],
                [2522773, 1154535.5],
                [2524084.6, 1154678.6],
                [2524657.1, 1153488.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5236', ortbez27: 'Remigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655607.7, 1265135.4],
                [2657891.2, 1263269.2],
                [2656808.7, 1262170.7],
                [2654143, 1262246.2],
                [2654297.1, 1264714.6],
                [2655607.7, 1265135.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1141', ortbez27: 'S�very' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2525120.1, 1157980.1],
                [2523961, 1157041.3],
                [2523010.4, 1157869.4],
                [2523806.6, 1159989.5],
                [2525120.1, 1157980.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9320', ortbez27: 'Frasnacht' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2752663.3, 1271089.9],
                [2755771, 1268765.8],
                [2749080.9, 1262803.7],
                [2748629.2, 1264832.6],
                [2747813.8, 1266097.6],
                [2752663.3, 1271089.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5225', ortbez27: 'B�zberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2654143, 1262246.2],
                [2656808.7, 1262170.7],
                [2656975.6, 1259013.6],
                [2655598.4, 1257597.1],
                [2652884.9, 1257807.5],
                [2651308.8, 1256965.1],
                [2650031.9, 1256719.7],
                [2649439.4, 1259310],
                [2650259.8, 1261463.6],
                [2651416.1, 1262963.2],
                [2654143, 1262246.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1142', ortbez27: 'Pampigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2523010.4, 1157869.4],
                [2519507.2, 1158951.4],
                [2520600.9, 1162753.4],
                [2522116.7, 1162446.3],
                [2523722.2, 1161500.2],
                [2523806.6, 1159989.5],
                [2523010.4, 1157869.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9325', ortbez27: 'Roggwil TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2745498.5, 1263094.4],
                [2747813.8, 1266097.6],
                [2748629.2, 1264832.6],
                [2749080.9, 1262803.7],
                [2748160.6, 1261790.1],
                [2745498.5, 1263094.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9326', ortbez27: 'Horn' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755771, 1268765.8],
                [2757085.8, 1268508.2],
                [2752755.8, 1261390.1],
                [2751606.1, 1262256.2],
                [2755771, 1268765.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5223', ortbez27: 'Riniken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656975.6, 1259013.6],
                [2656808.7, 1262170.7],
                [2658217.9, 1260742.6],
                [2658249.8, 1259143.2],
                [2656975.6, 1259013.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9323', ortbez27: 'Steinach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755771, 1268765.8],
                [2751606.1, 1262256.2],
                [2751491.5, 1260950.5],
                [2749789.2, 1261344.7],
                [2749080.9, 1262803.7],
                [2755771, 1268765.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1131', ortbez27: 'Tolochenaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2528993.9, 1145298.4], [2525076.1, 1150390.3], [2526232.3, 1151699.2], [2528993.9, 1145298.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1185', ortbez27: 'Mont-sur-Rolle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2516578.2, 1146488.3],
                [2514494.9, 1147911.6],
                [2515195.1, 1149707.7],
                [2516227.5, 1148381.6],
                [2517456.2, 1148083.6],
                [2516578.2, 1146488.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1186', ortbez27: 'Essertines-sur-Rolle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2513691.3, 1146552.9],
                [2511301.8, 1148314],
                [2511754.4, 1150199],
                [2513933.8, 1150509.2],
                [2515195.1, 1149707.7],
                [2514494.9, 1147911.6],
                [2513691.3, 1146552.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1184', ortbez27: 'Luins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2510925.5, 1142817.3],
                [2510110.6, 1143944],
                [2510770.2, 1145723.6],
                [2512488, 1143697.3],
                [2510925.5, 1142817.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1189', ortbez27: 'Saubraz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2517550.7, 1152226.1],
                [2515507.6, 1151048.3],
                [2514540.4, 1151882.4],
                [2513761.2, 1152877.6],
                [2517550.7, 1152226.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5274', ortbez27: 'Mettau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653275.6, 1269981],
                [2652664.9, 1268013.1],
                [2650063.9, 1267872.3],
                [2651796.9, 1270671.7],
                [2652758.2, 1271499.3],
                [2653275.6, 1269981]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1188', ortbez27: 'Gimel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2515507.6, 1151048.3],
                [2515195.1, 1149707.7],
                [2513933.8, 1150509.2],
                [2511754.4, 1150199],
                [2510725, 1151503.9],
                [2509220.6, 1149541.4],
                [2508351.5, 1150476.8],
                [2507421.8, 1151361],
                [2506760.5, 1152486.2],
                [2507134.6, 1154073.9],
                [2508016.9, 1155605.2],
                [2510034.2, 1157337.9],
                [2513533.3, 1154119.2],
                [2513761.2, 1152877.6],
                [2514540.4, 1151882.4],
                [2515507.6, 1151048.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5272', ortbez27: 'Gansingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653579.3, 1266617.4],
                [2654297.1, 1264714.6],
                [2651416.1, 1262963.2],
                [2649678, 1266611.9],
                [2650063.9, 1267872.3],
                [2652664.9, 1268013.1],
                [2653579.3, 1266617.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5277', ortbez27: 'Hottwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655162.9, 1267705.6],
                [2655607.7, 1265135.4],
                [2654297.1, 1264714.6],
                [2653579.3, 1266617.4],
                [2655162.9, 1267705.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1175', ortbez27: 'Lavigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2520226.7, 1150670.5],
                [2520611.3, 1151945.9],
                [2523066.2, 1150124.8],
                [2521051.4, 1149590.2],
                [2520226.7, 1150670.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1176', ortbez27: 'St-Livres' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2520226.7, 1150670.5],
                [2517550.7, 1152226.1],
                [2518109.1, 1154631.4],
                [2520611.3, 1151945.9],
                [2520226.7, 1150670.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5276', ortbez27: 'Wil AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655513, 1269385.5],
                [2656476.4, 1268132.3],
                [2655162.9, 1267705.6],
                [2653579.3, 1266617.4],
                [2652664.9, 1268013.1],
                [2653275.6, 1269981],
                [2654283.3, 1270803.4],
                [2655513, 1269385.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1182', ortbez27: 'Gilly' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2512488, 1143697.3],
                [2510770.2, 1145723.6],
                [2511301.8, 1148314],
                [2513691.3, 1146552.9],
                [2513435.7, 1145119.7],
                [2512488, 1143697.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1180', ortbez27: 'Rolle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2517625.2, 1140321.7],
                [2513435.7, 1145119.7],
                [2513691.3, 1146552.9],
                [2514494.9, 1147911.6],
                [2516578.2, 1146488.3],
                [2517625.2, 1140321.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1169', ortbez27: 'Yens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2520433.9, 1154945.7],
                [2522773, 1154535.5],
                [2523160.4, 1153001.2],
                [2520611.3, 1151945.9],
                [2518109.1, 1154631.4],
                [2520433.9, 1154945.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1170', ortbez27: 'Aubonne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2520805.8, 1148334],
                [2518615.5, 1146965],
                [2517456.2, 1148083.6],
                [2517604.8, 1149655],
                [2517550.7, 1152226.1],
                [2520226.7, 1150670.5],
                [2521051.4, 1149590.2],
                [2520805.8, 1148334]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1168', ortbez27: 'Villars-sous-Yens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2523066.2, 1150124.8],
                [2520611.3, 1151945.9],
                [2523160.4, 1153001.2],
                [2523769.3, 1151465.8],
                [2523066.2, 1150124.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1174', ortbez27: 'Montherod' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2517604.8, 1149655],
                [2515195.1, 1149707.7],
                [2515507.6, 1151048.3],
                [2517550.7, 1152226.1],
                [2517604.8, 1149655]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1172', ortbez27: 'Bougy-Villars' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2515195.1, 1149707.7],
                [2517604.8, 1149655],
                [2517456.2, 1148083.6],
                [2516227.5, 1148381.6],
                [2515195.1, 1149707.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1162', ortbez27: 'St-Prex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2528993.9, 1145298.4],
                [2525509.1, 1143171],
                [2522470.1, 1147664.2],
                [2523066.2, 1150124.8],
                [2523769.3, 1151465.8],
                [2525076.1, 1150390.3],
                [2528993.9, 1145298.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9410', ortbez27: 'Heiden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757616.1, 1254736.5],
                [2757018.1, 1255956.6],
                [2757491.1, 1258828.1],
                [2760006.5, 1255746.6],
                [2757616.1, 1254736.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1165', ortbez27: 'Allaman' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2522179.9, 1141138.5], [2519780.6, 1140708.2], [2519609.6, 1146070.9], [2522179.9, 1141138.5]]],
            [[[2520805.8, 1148334], [2519609.6, 1146070.9], [2518615.5, 1146965], [2520805.8, 1148334]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5313', ortbez27: 'Klingnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660455.1, 1270565.4],
                [2658404.7, 1272822.3],
                [2662462.5, 1271921.9],
                [2662876.3, 1270098.1],
                [2660455.1, 1270565.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1166', ortbez27: 'Perroy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2519780.6, 1140708.2],
                [2517625.2, 1140321.7],
                [2516578.2, 1146488.3],
                [2517456.2, 1148083.6],
                [2518615.5, 1146965],
                [2519609.6, 1146070.9],
                [2519780.6, 1140708.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9413', ortbez27: 'Oberegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2756304.7, 1251339.5],
                [2757616.1, 1254736.5],
                [2760006.5, 1255746.6],
                [2761066, 1254570.3],
                [2759936.9, 1251778.9],
                [2756304.7, 1251339.5]
              ]
            ],
            [[[2762848.5, 1253586.6], [2761066, 1254570.3], [2762614.4, 1255292.1], [2762848.5, 1253586.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1163', ortbez27: 'Etoy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2520805.8, 1148334],
                [2521051.4, 1149590.2],
                [2523066.2, 1150124.8],
                [2522470.1, 1147664.2],
                [2520805.8, 1148334]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1164', ortbez27: 'Buchillon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2525509.1, 1143171],
                [2522179.9, 1141138.5],
                [2519609.6, 1146070.9],
                [2520805.8, 1148334],
                [2522470.1, 1147664.2],
                [2525509.1, 1143171]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9411', ortbez27: 'Reute AR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2761066, 1254570.3], [2762848.5, 1253586.6], [2759936.9, 1251778.9], [2761066, 1254570.3]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5312', ortbez27: 'D�ttingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2662113.3, 1266837.2],
                [2659582.1, 1266106.4],
                [2659753.4, 1267941.8],
                [2659016.7, 1269036.8],
                [2660455.1, 1270565.4],
                [2662876.3, 1270098.1],
                [2662113.3, 1266837.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1217', ortbez27: 'Meyrin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2493433, 1119440.4],
                [2492636.6, 1121087.2],
                [2493994.3, 1122665.3],
                [2496946, 1121733.2],
                [2495846, 1120260.1],
                [2493433, 1119440.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5317', ortbez27: 'Hettenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657340, 1271751.5],
                [2657810.7, 1267910.8],
                [2656476.4, 1268132.3],
                [2655513, 1269385.5],
                [2657340, 1271751.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1218', ortbez27: 'Le Grand-Saconnex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2498121, 1119955.6],
                [2496946, 1121733.2],
                [2499087.2, 1122369.5],
                [2499628.7, 1121209.5],
                [2498121, 1119955.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5318', ortbez27: 'Mandach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657994.8, 1266604.4],
                [2655607.7, 1265135.4],
                [2655162.9, 1267705.6],
                [2656476.4, 1268132.3],
                [2657810.7, 1267910.8],
                [2657994.8, 1266604.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9402', ortbez27: 'M�rschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2748337.1, 1257779],
                [2748767.9, 1260384.7],
                [2749789.2, 1261344.7],
                [2751491.5, 1260950.5],
                [2751298.7, 1259190.8],
                [2749857.1, 1257814.1],
                [2748337.1, 1257779]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5315', ortbez27: 'B�ttstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657810.7, 1267910.8],
                [2659016.7, 1269036.8],
                [2659753.4, 1267941.8],
                [2659582.1, 1266106.4],
                [2657994.8, 1266604.4],
                [2657810.7, 1267910.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1216', ortbez27: 'Cointrin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2496946, 1121733.2],
                [2498121, 1119955.6],
                [2496874.7, 1119165.1],
                [2495846, 1120260.1],
                [2496946, 1121733.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5316', ortbez27: 'Leuggern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2658404.7, 1272822.3],
                [2660455.1, 1270565.4],
                [2659016.7, 1269036.8],
                [2657810.7, 1267910.8],
                [2657340, 1271751.5],
                [2658404.7, 1272822.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9400', ortbez27: 'Rorschach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2754192.1, 1260301.4], [2757085.8, 1268508.2], [2756308, 1260773.9], [2754192.1, 1260301.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1222', ortbez27: 'V�senaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2502455.6, 1122015.1], [2506115.9, 1124078.3], [2504645.4, 1121047.1], [2502455.6, 1122015.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5306', ortbez27: 'Tegerfelden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2662113.3, 1266837.2],
                [2662876.3, 1270098.1],
                [2665384.1, 1268614.9],
                [2665045.2, 1267270.9],
                [2662113.3, 1266837.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1219', ortbez27: 'A�re' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2496874.7, 1119165.1], [2498294.2, 1117609], [2496945.2, 1117170.1], [2496874.7, 1119165.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5303', ortbez27: 'W�renlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659582.1, 1266106.4],
                [2662113.3, 1266837.2],
                [2663454.9, 1263058.5],
                [2660855.6, 1263600.1],
                [2659678.3, 1264256.2],
                [2659582.1, 1266106.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9403', ortbez27: 'Goldach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2752755.8, 1261390.1],
                [2757085.8, 1268508.2],
                [2754192.1, 1260301.4],
                [2753849.3, 1258981.4],
                [2751298.7, 1259190.8],
                [2751491.5, 1260950.5],
                [2752755.8, 1261390.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5304', ortbez27: 'Endingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663454.9, 1263058.5],
                [2662113.3, 1266837.2],
                [2665045.2, 1267270.9],
                [2666473.5, 1266719.6],
                [2665610.8, 1264838],
                [2664537.9, 1262226.4],
                [2663454.9, 1263058.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9404', ortbez27: 'Rorschacherberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757085.8, 1268508.2],
                [2758319.9, 1259791],
                [2757491.1, 1258828.1],
                [2755203.7, 1256809.8],
                [2754279.3, 1257717.5],
                [2753849.3, 1258981.4],
                [2754192.1, 1260301.4],
                [2756308, 1260773.9],
                [2757085.8, 1268508.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1207', ortbez27: 'Gen�ve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2500752.9, 1117375.3], [2501926.7, 1120158.9], [2502589, 1117575.8], [2500752.9, 1117375.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1208', ortbez27: 'Gen�ve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2501949.4, 1116021.9], [2500752.9, 1117375.3], [2502589, 1117575.8], [2501949.4, 1116021.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1213', ortbez27: 'Onex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2498637.8, 1116094.6],
                [2496811, 1114678.3],
                [2495679.2, 1116697.5],
                [2496945.2, 1117170.1],
                [2498294.2, 1117609],
                [2498637.8, 1116094.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1214', ortbez27: 'Vernier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2494720.7, 1117827.8],
                [2493433, 1119440.4],
                [2495846, 1120260.1],
                [2496874.7, 1119165.1],
                [2494720.7, 1117827.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1212', ortbez27: 'Grand-Lancy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2499714.8, 1113848.9],
                [2496811, 1114678.3],
                [2498637.8, 1116094.6],
                [2499912.2, 1116257.3],
                [2499714.8, 1113848.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1201', ortbez27: 'Gen�ve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2499175.2, 1118527.4], [2501926.7, 1120158.9], [2500752.9, 1117375.3], [2499175.2, 1118527.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1202', ortbez27: 'Gen�ve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2499628.7, 1121209.5],
                [2501926.7, 1120158.9],
                [2499175.2, 1118527.4],
                [2498121, 1119955.6],
                [2499628.7, 1121209.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5301', ortbez27: 'Siggenthal Station' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2659950.3, 1262693.7], [2659678.3, 1264256.2], [2660855.6, 1263600.1], [2659950.3, 1262693.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1205', ortbez27: 'Gen�ve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2500752.9, 1117375.3],
                [2499912.2, 1116257.3],
                [2498637.8, 1116094.6],
                [2498294.2, 1117609],
                [2499175.2, 1118527.4],
                [2500752.9, 1117375.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1206', ortbez27: 'Gen�ve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2499912.2, 1116257.3],
                [2500752.9, 1117375.3],
                [2501949.4, 1116021.9],
                [2500852, 1115214.2],
                [2499912.2, 1116257.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1203', ortbez27: 'Gen�ve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2496874.7, 1119165.1],
                [2498121, 1119955.6],
                [2499175.2, 1118527.4],
                [2498294.2, 1117609],
                [2496874.7, 1119165.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9442', ortbez27: 'Berneck' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764479, 1256480.1],
                [2765411.7, 1254973.4],
                [2764532.4, 1253876.4],
                [2762848.5, 1253586.6],
                [2762614.4, 1255292.1],
                [2764479, 1256480.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1197', ortbez27: 'Prangins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2511923.3, 1137464.3],
                [2509987.2, 1136035.5],
                [2507607.5, 1139226],
                [2508378.9, 1141320.2],
                [2509546.3, 1140857.2],
                [2510326.3, 1139851],
                [2511923.3, 1137464.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9445', ortbez27: 'Rebstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2759936.9, 1251778.9],
                [2762848.5, 1253586.6],
                [2762020.4, 1251431.2],
                [2761251.2, 1250352.6],
                [2759936.9, 1251778.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1195', ortbez27: 'Bursinel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2515208, 1139888.3],
                [2510925.5, 1142817.3],
                [2512488, 1143697.3],
                [2513435.7, 1145119.7],
                [2517625.2, 1140321.7],
                [2515208, 1139888.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1196', ortbez27: 'Gland' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2515208, 1139888.3],
                [2511923.3, 1137464.3],
                [2510326.3, 1139851],
                [2509546.3, 1140857.2],
                [2508378.9, 1141320.2],
                [2508919.1, 1143333.7],
                [2510110.6, 1143944],
                [2510925.5, 1142817.3],
                [2515208, 1139888.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9443', ortbez27: 'Widnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2767035.2, 1254242.1],
                [2767114.7, 1252932.1],
                [2765988.2, 1250684],
                [2764920, 1251485.8],
                [2764532.4, 1253876.4],
                [2765411.7, 1254973.4],
                [2767035.2, 1254242.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9444', ortbez27: 'Diepoldsau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765988.2, 1250684],
                [2767114.7, 1252932.1],
                [2768077.5, 1251983.6],
                [2768860.9, 1250988.6],
                [2768463.9, 1249755.8],
                [2767399.1, 1248874.8],
                [2765594.3, 1249096.3],
                [2765988.2, 1250684]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1247', ortbez27: 'Ani�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2507999.4, 1126823.2],
                [2508376, 1124346.8],
                [2504504.7, 1126784.9],
                [2505161.4, 1127988.2],
                [2507999.4, 1126823.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9434', ortbez27: 'Au SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765411.7, 1254973.4],
                [2764479, 1256480.1],
                [2765719.1, 1256914.9],
                [2767035.2, 1254242.1],
                [2765411.7, 1254973.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1248', ortbez27: 'Hermance' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2507999.4, 1126823.2],
                [2505161.4, 1127988.2],
                [2506475.2, 1130455.6],
                [2508404.8, 1128674.3],
                [2507999.4, 1126823.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1253', ortbez27: 'Vandoeuvres' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2504107.9, 1118180.9], [2504645.4, 1121047.1], [2505779.8, 1118539.1], [2504107.9, 1118180.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1254', ortbez27: 'Jussy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2511296.2, 1119811.8],
                [2508084, 1120302.1],
                [2508949.7, 1122176.2],
                [2509271.4, 1123421.7],
                [2510443.5, 1122974.9],
                [2511958.4, 1123498.5],
                [2512965.3, 1122757.7],
                [2512648.1, 1121461.3],
                [2511296.2, 1119811.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9437', ortbez27: 'Marbach SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2762020.4, 1251431.2],
                [2763558.4, 1249455.2],
                [2762231.5, 1249524.6],
                [2761251.2, 1250352.6],
                [2762020.4, 1251431.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1252', ortbez27: 'Meinier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2504645.4, 1121047.1],
                [2506115.9, 1124078.3],
                [2508376, 1124346.8],
                [2509271.4, 1123421.7],
                [2508949.7, 1122176.2],
                [2508084, 1120302.1],
                [2507108.6, 1119398.2],
                [2504645.4, 1121047.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9436', ortbez27: 'Balgach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765594.3, 1249096.3],
                [2763558.4, 1249455.2],
                [2762020.4, 1251431.2],
                [2762848.5, 1253586.6],
                [2764532.4, 1253876.4],
                [2764920, 1251485.8],
                [2765988.2, 1250684],
                [2765594.3, 1249096.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1242', ortbez27: 'Satigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2489058.3, 1119891],
                [2492636.6, 1121087.2],
                [2493433, 1119440.4],
                [2494720.7, 1117827.8],
                [2493620.9, 1116788.9],
                [2491439.5, 1117509.3],
                [2488629.1, 1118697.8],
                [2489058.3, 1119891]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9425', ortbez27: 'Thal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758319.9, 1259791],
                [2760274.5, 1261685.1],
                [2761305.4, 1260622.6],
                [2761492.3, 1259232.5],
                [2760107.9, 1259051.5],
                [2758319.9, 1259791]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1239', ortbez27: 'Collex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2498497, 1128330],
                [2500044.4, 1125501.2],
                [2498372.2, 1124261.8],
                [2497079.6, 1126963.8],
                [2498497, 1128330]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1245', ortbez27: 'Collonge-Bellerive' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2506115.9, 1124078.3], [2502455.6, 1122015.1], [2503802.4, 1125497.6], [2506115.9, 1124078.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1246', ortbez27: 'Corsier GE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2503802.4, 1125497.6],
                [2504504.7, 1126784.9],
                [2508376, 1124346.8],
                [2506115.9, 1124078.3],
                [2503802.4, 1125497.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1243', ortbez27: 'Presinge' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2508084, 1120302.1],
                [2511296.2, 1119811.8],
                [2508293.3, 1117919.5],
                [2507108.6, 1119398.2],
                [2508084, 1120302.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5330', ortbez27: 'Bad Zurzach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664012.8, 1273778.3],
                [2666122.2, 1270316.5],
                [2665384.1, 1268614.9],
                [2662876.3, 1270098.1],
                [2662462.5, 1271921.9],
                [2664012.8, 1273778.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1244', ortbez27: 'Choulex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2505779.8, 1118539.1], [2504645.4, 1121047.1], [2507108.6, 1119398.2], [2505779.8, 1118539.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9430', ortbez27: 'St. Margrethen SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764479, 1256480.1],
                [2762766.3, 1258809.3],
                [2767310.1, 1258402.6],
                [2766944.1, 1257168.1],
                [2765719.1, 1256914.9],
                [2764479, 1256480.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1233', ortbez27: 'Bernex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2493620.9, 1116788.9],
                [2494720.7, 1117827.8],
                [2496874.7, 1119165.1],
                [2496945.2, 1117170.1],
                [2495679.2, 1116697.5],
                [2496811, 1114678.3],
                [2495633.7, 1113789.9],
                [2494680.8, 1111932.8],
                [2492759.4, 1112197.9],
                [2491912, 1114355.5],
                [2492884, 1115608.5],
                [2493620.9, 1116788.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9427', ortbez27: 'Wolfhalden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2761492.3, 1259232.5],
                [2760006.5, 1255746.6],
                [2757491.1, 1258828.1],
                [2758319.9, 1259791],
                [2760107.9, 1259051.5],
                [2761492.3, 1259232.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1234', ortbez27: 'Vessy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2503347.8, 1115004.6],
                [2501488.8, 1113443.4],
                [2499714.8, 1113848.9],
                [2500852, 1115214.2],
                [2501949.4, 1116021.9],
                [2503347.8, 1115004.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9428', ortbez27: 'Walzenhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764479, 1256480.1],
                [2762614.4, 1255292.1],
                [2761066, 1254570.3],
                [2760006.5, 1255746.6],
                [2761492.3, 1259232.5],
                [2762766.3, 1258809.3],
                [2764479, 1256480.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1237', ortbez27: 'Avully' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2487091.6, 1113338.5],
                [2488345.3, 1115106.2],
                [2489800, 1115076.6],
                [2490461.3, 1113576.8],
                [2489377.4, 1112944.5],
                [2487091.6, 1113338.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5332', ortbez27: 'Rekingen AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2665384.1, 1268614.9],
                [2666122.2, 1270316.5],
                [2668029.9, 1269307.6],
                [2669310.4, 1268702.6],
                [2668391.4, 1267514.8],
                [2666473.5, 1266719.6],
                [2665045.2, 1267270.9],
                [2665384.1, 1268614.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5322', ortbez27: 'Koblenz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2662462.5, 1271921.9],
                [2658404.7, 1272822.3],
                [2658921.9, 1274330.9],
                [2660132.3, 1273989.5],
                [2661741.6, 1274025.8],
                [2662462.5, 1271921.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9422', ortbez27: 'Staad SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2760274.5, 1261685.1], [2758319.9, 1259791], [2757085.8, 1268508.2], [2760274.5, 1261685.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1236', ortbez27: 'Cartigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2490461.3, 1113576.8],
                [2489800, 1115076.6],
                [2491203.6, 1116083.8],
                [2492884, 1115608.5],
                [2491912, 1114355.5],
                [2490461.3, 1113576.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5325', ortbez27: 'Leibstadt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657340, 1271751.5],
                [2655513, 1269385.5],
                [2654283.3, 1270803.4],
                [2652758.2, 1271499.3],
                [2653899.6, 1272032.3],
                [2656173.4, 1273122],
                [2657340, 1271751.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9473', ortbez27: 'Gams' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754258, 1229519.2],
                [2750355.4, 1229077.2],
                [2749014.7, 1229042.7],
                [2747780.4, 1229369.8],
                [2748735.6, 1233859.6],
                [2754290.6, 1232260.5],
                [2754258, 1229519.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5326', ortbez27: 'Schwaderloch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2654283.3, 1270803.4], [2653275.6, 1269981], [2652758.2, 1271499.3], [2654283.3, 1270803.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1223', ortbez27: 'Cologny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2501926.7, 1120158.9],
                [2502455.6, 1122015.1],
                [2504645.4, 1121047.1],
                [2504107.9, 1118180.9],
                [2502589, 1117575.8],
                [2501926.7, 1120158.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5323', ortbez27: 'Rietheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2661741.6, 1274025.8], [2664012.8, 1273778.3], [2662462.5, 1271921.9], [2661741.6, 1274025.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1224', ortbez27: 'Ch�ne-Bougeries' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2502589, 1117575.8],
                [2504107.9, 1118180.9],
                [2504021.8, 1116342.4],
                [2503347.8, 1115004.6],
                [2501949.4, 1116021.9],
                [2502589, 1117575.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5324', ortbez27: 'Full-Reuenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2658404.7, 1272822.3], [2657340, 1271751.5], [2656173.4, 1273122], [2658404.7, 1272822.3]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9472', ortbez27: 'Grabs' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751588.6, 1224913.3],
                [2748701.3, 1222677.2],
                [2745609.5, 1222201.8],
                [2742779.4, 1222567.8],
                [2744014.6, 1227220.5],
                [2747780.4, 1229369.8],
                [2749014.7, 1229042.7],
                [2750355.4, 1229077.2],
                [2754258, 1229519.2],
                [2755403, 1228907.8],
                [2753887.9, 1227406.7],
                [2752875.7, 1226668.1],
                [2751588.6, 1224913.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9477', ortbez27: 'Tr�bbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757574.8, 1217134.3],
                [2754520.6, 1215175.6],
                [2754183, 1217310.1],
                [2755412.5, 1217833],
                [2757574.8, 1217134.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1227', ortbez27: 'Carouge GE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2500852, 1115214.2], [2499714.8, 1113848.9], [2499912.2, 1116257.3], [2500852, 1115214.2]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9478', ortbez27: 'Azmoos' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2751920, 1220233.2],
                [2754183, 1217310.1],
                [2754520.6, 1215175.6],
                [2751361.2, 1214709],
                [2750454, 1216057.6],
                [2749216.9, 1216424.3],
                [2748293.8, 1217808.4],
                [2749652.3, 1219739.7],
                [2751920, 1220233.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1228', ortbez27: 'Plan-les-Ouates' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2499714.8, 1113848.9],
                [2496743.5, 1112679],
                [2495633.7, 1113789.9],
                [2496811, 1114678.3],
                [2499714.8, 1113848.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9475', ortbez27: 'Sevelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2749652.3, 1219739.7],
                [2748701.3, 1222677.2],
                [2751588.6, 1224913.3],
                [2754077.9, 1224952.2],
                [2756022.9, 1225079.2],
                [2756626.7, 1223976.4],
                [2757763.9, 1220469.8],
                [2754634.8, 1219993.6],
                [2753368.8, 1220692.2],
                [2751920, 1220233.2],
                [2749652.3, 1219739.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1281', ortbez27: 'Russin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2488629.1, 1118697.8],
                [2491439.5, 1117509.3],
                [2491203.6, 1116083.8],
                [2489800, 1115076.6],
                [2488629.1, 1118697.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9476', ortbez27: 'Weite' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755412.5, 1217833],
                [2754634.8, 1219993.6],
                [2757763.9, 1220469.8],
                [2757574.8, 1217134.3],
                [2755412.5, 1217833]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9465', ortbez27: 'Salez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754223.9, 1233720.6],
                [2755606.2, 1235187.5],
                [2757600, 1234812.6],
                [2756959.9, 1233430.4],
                [2756380.2, 1232247.4],
                [2754290.6, 1232260.5],
                [2754223.9, 1233720.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1279', ortbez27: 'Bogis-Bossey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2501787.2, 1132446.5],
                [2500005.1, 1132465.5],
                [2501528.6, 1134289.7],
                [2503224.4, 1135715.2],
                [2503255.6, 1133449.8],
                [2501787.2, 1132446.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9466', ortbez27: 'Sennwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754015, 1237737.3],
                [2754816, 1239071.6],
                [2757485.7, 1238669.2],
                [2758365.7, 1237658.4],
                [2757600, 1234812.6],
                [2755606.2, 1235187.5],
                [2754015, 1237737.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9463', ortbez27: 'Oberriet SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760924.4, 1241581.3],
                [2756072.8, 1241726.4],
                [2754925.2, 1242742.6],
                [2756059.5, 1246269.1],
                [2758928, 1245459.6],
                [2760792.4, 1246163.8],
                [2762629.3, 1243401.3],
                [2760924.4, 1241581.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1285', ortbez27: 'Athenaz (Avusy)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2487541.3, 1111271.9],
                [2489377.4, 1112944.5],
                [2490461.3, 1113576.8],
                [2492759.4, 1112197.9],
                [2490730.3, 1111020],
                [2487541.3, 1111271.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9464', ortbez27: 'R�thi (Rheintal)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2756072.8, 1241726.4],
                [2760924.4, 1241581.3],
                [2759327.4, 1238670.1],
                [2758365.7, 1237658.4],
                [2757485.7, 1238669.2],
                [2754816, 1239071.6],
                [2756072.8, 1241726.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9469', ortbez27: 'Haag (Rheintal)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754258, 1229519.2],
                [2754290.6, 1232260.5],
                [2756380.2, 1232247.4],
                [2756702.3, 1231003.6],
                [2755403, 1228907.8],
                [2754258, 1229519.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1283', ortbez27: 'Dardagny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2489800, 1115076.6],
                [2488345.3, 1115106.2],
                [2488058.5, 1116338.6],
                [2486831.9, 1117055.1],
                [2486723.3, 1118301.4],
                [2487391.5, 1119575.2],
                [2489058.3, 1119891],
                [2488629.1, 1118697.8],
                [2489800, 1115076.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9470', ortbez27: 'Buchs SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2755403, 1228907.8],
                [2756022.9, 1225079.2],
                [2754077.9, 1224952.2],
                [2751588.6, 1224913.3],
                [2752875.7, 1226668.1],
                [2753887.9, 1227406.7],
                [2755403, 1228907.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1284', ortbez27: 'Chancy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2486162.6, 1111204.9], [2486879.4, 1110179.8], [2485667.4, 1109857.1], [2486162.6, 1111204.9]]],
            [[[2487091.6, 1113338.5], [2489377.4, 1112944.5], [2487541.3, 1111271.9], [2487091.6, 1113338.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9467', ortbez27: 'Fr�msen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2752131.8, 1236026.2],
                [2754015, 1237737.3],
                [2755606.2, 1235187.5],
                [2754223.9, 1233720.6],
                [2752131.8, 1236026.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1273', ortbez27: 'Arzier-Le Muids' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2502840.5, 1145287.2],
                [2498372.5, 1147544.1],
                [2497030.4, 1148797.7],
                [2501735.7, 1155835.2],
                [2504529.1, 1154196.8],
                [2501310.1, 1150705.4],
                [2507338.3, 1144467.8],
                [2504711.1, 1143896.5],
                [2502840.5, 1145287.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9468', ortbez27: 'Sax' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2752131.8, 1236026.2],
                [2754223.9, 1233720.6],
                [2754290.6, 1232260.5],
                [2748735.6, 1233859.6],
                [2750988.4, 1235288.9],
                [2752131.8, 1236026.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1274', ortbez27: 'Grens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2504194.6, 1137536],
                [2502290.5, 1138271.9],
                [2503728.7, 1139978.9],
                [2505317, 1139795.8],
                [2505837.2, 1138210.6],
                [2504194.6, 1137536]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1271', ortbez27: 'Givrins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2506665.2, 1141985.1],
                [2503161.5, 1142674],
                [2502840.5, 1145287.2],
                [2504711.1, 1143896.5],
                [2506665.2, 1141985.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1272', ortbez27: 'Genolier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2504711.1, 1143896.5],
                [2507338.3, 1144467.8],
                [2508919.1, 1143333.7],
                [2506665.2, 1141985.1],
                [2504711.1, 1143896.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1277', ortbez27: 'Borex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2503224.4, 1135715.2],
                [2502290.5, 1138271.9],
                [2504194.6, 1137536],
                [2504924.9, 1136495.4],
                [2503224.4, 1135715.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1278', ortbez27: 'La Rippe' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2495220.5, 1143366.5],
                [2497138.4, 1142526.3],
                [2502290.5, 1138271.9],
                [2494366.4, 1141626.4],
                [2495220.5, 1143366.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1275', ortbez27: 'Ch�serex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2497138.4, 1142526.3],
                [2499971.3, 1142695.9],
                [2503728.7, 1139978.9],
                [2502290.5, 1138271.9],
                [2497138.4, 1142526.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9462', ortbez27: 'Montlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2762629.3, 1243401.3],
                [2760792.4, 1246163.8],
                [2762433.2, 1248101.7],
                [2763571.4, 1246606.7],
                [2762629.3, 1243401.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1276', ortbez27: 'Gingins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2503728.7, 1139978.9], [2499971.3, 1142695.9], [2503161.5, 1142674], [2503728.7, 1139978.9]]],
            [
              [
                [2499971.3, 1142695.9],
                [2497138.4, 1142526.3],
                [2495220.5, 1143366.5],
                [2497785.3, 1144619.6],
                [2499971.3, 1142695.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1265', ortbez27: 'La Cure' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2495220.5, 1143366.5],
                [2497030.4, 1148797.7],
                [2498372.5, 1147544.1],
                [2497785.3, 1144619.6],
                [2495220.5, 1143366.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1266', ortbez27: 'Duillier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2506665.2, 1141985.1],
                [2508378.9, 1141320.2],
                [2507607.5, 1139226],
                [2505317, 1139795.8],
                [2506665.2, 1141985.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9450', ortbez27: 'Altst�tten SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760792.4, 1246163.8],
                [2758928, 1245459.6],
                [2758744.2, 1248684],
                [2756059.5, 1246269.1],
                [2755730.5, 1248024.2],
                [2756304.7, 1251339.5],
                [2759936.9, 1251778.9],
                [2761251.2, 1250352.6],
                [2762231.5, 1249524.6],
                [2762433.2, 1248101.7],
                [2760792.4, 1246163.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1264', ortbez27: 'St-Cergue' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2498372.5, 1147544.1],
                [2502840.5, 1145287.2],
                [2503161.5, 1142674],
                [2499971.3, 1142695.9],
                [2497785.3, 1144619.6],
                [2498372.5, 1147544.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1269', ortbez27: 'Bassins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2508621.9, 1146373],
                [2507338.3, 1144467.8],
                [2501310.1, 1150705.4],
                [2504529.1, 1154196.8],
                [2505821.8, 1153349.1],
                [2506760.5, 1152486.2],
                [2505264.8, 1150617.4],
                [2505708.7, 1149426.8],
                [2506485.5, 1148411.1],
                [2507504.1, 1147686],
                [2508621.9, 1146373]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1270', ortbez27: 'Tr�lex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2506665.2, 1141985.1],
                [2505317, 1139795.8],
                [2503728.7, 1139978.9],
                [2503161.5, 1142674],
                [2506665.2, 1141985.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1267', ortbez27: 'Coinsins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2508378.9, 1141320.2], [2506665.2, 1141985.1], [2508919.1, 1143333.7], [2508378.9, 1141320.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1268', ortbez27: 'Burtigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2507338.3, 1144467.8],
                [2508621.9, 1146373],
                [2508719.1, 1148160],
                [2509220.6, 1149541.4],
                [2510045.2, 1148585],
                [2511301.8, 1148314],
                [2510770.2, 1145723.6],
                [2510110.6, 1143944],
                [2508919.1, 1143333.7],
                [2507338.3, 1144467.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9451', ortbez27: 'Kriessern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2763558.4, 1249455.2],
                [2765594.3, 1249096.3],
                [2763571.4, 1246606.7],
                [2762433.2, 1248101.7],
                [2762231.5, 1249524.6],
                [2763558.4, 1249455.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1257', ortbez27: 'La Croix-de-Rozon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2496743.5, 1112679],
                [2499714.8, 1113848.9],
                [2500397.2, 1111913.5],
                [2499453.1, 1111092.2],
                [2498203.3, 1111129.7],
                [2496960, 1111311.9],
                [2496743.5, 1112679]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9452', ortbez27: 'Hinterforst' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2758928, 1245459.6], [2756059.5, 1246269.1], [2758744.2, 1248684], [2758928, 1245459.6]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1258', ortbez27: 'Perly' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2496743.5, 1112679], [2494680.8, 1111932.8], [2495633.7, 1113789.9], [2496743.5, 1112679]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1256', ortbez27: 'Troinex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2501488.8, 1113443.4], [2500397.2, 1111913.5], [2499714.8, 1113848.9], [2501488.8, 1113443.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9506', ortbez27: 'Lommis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718499.2, 1262984.4],
                [2717195.6, 1261432.3],
                [2715804.4, 1263831],
                [2718569.6, 1265421.6],
                [2718499.2, 1262984.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1261', ortbez27: 'Longirod' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2505821.8, 1153349.1], [2507134.6, 1154073.9], [2506760.5, 1152486.2], [2505821.8, 1153349.1]]],
            [
              [
                [2508719.1, 1148160],
                [2508621.9, 1146373],
                [2507504.1, 1147686],
                [2506485.5, 1148411.1],
                [2505708.7, 1149426.8],
                [2505264.8, 1150617.4],
                [2506760.5, 1152486.2],
                [2507421.8, 1151361],
                [2508351.5, 1150476.8],
                [2509220.6, 1149541.4],
                [2508719.1, 1148160]
              ]
            ],
            [
              [
                [2510725, 1151503.9],
                [2511754.4, 1150199],
                [2511301.8, 1148314],
                [2510045.2, 1148585],
                [2509220.6, 1149541.4],
                [2510725, 1151503.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9504', ortbez27: 'Friltschen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2724133.9, 1265499.5], [2723021.6, 1267681.3], [2725735.1, 1266928.8], [2724133.9, 1265499.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1262', ortbez27: 'Eysins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2505837.2, 1138210.6], [2504924.9, 1136495.4], [2504194.6, 1137536], [2505837.2, 1138210.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1260', ortbez27: 'Nyon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2509987.2, 1136035.5],
                [2508811.2, 1135167.7],
                [2504924.9, 1136495.4],
                [2505837.2, 1138210.6],
                [2505317, 1139795.8],
                [2507607.5, 1139226],
                [2509987.2, 1136035.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9507', ortbez27: 'Stettfurt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716265.9, 1265735.6],
                [2715804.4, 1263831],
                [2713727.9, 1263583.9],
                [2713439.8, 1266748.8],
                [2716265.9, 1265735.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5408', ortbez27: 'Ennetbaden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2666073.1, 1258658.8], [2665504.3, 1259928.4], [2666997.7, 1260049.6], [2666073.1, 1258658.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1313', ortbez27: 'Ferreyres' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2526223.1, 1167004.8], [2525505.1, 1169449.9], [2527739.7, 1167735.3], [2526223.1, 1167004.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9508', ortbez27: 'Weingarten-Kalth�usern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2715804.4, 1263831], [2716265.9, 1265735.6], [2718569.6, 1265421.6], [2715804.4, 1263831]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5413', ortbez27: 'Birmenstorf AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659976.7, 1258293.2],
                [2662758.5, 1257960.9],
                [2663161.4, 1256475.3],
                [2663038.1, 1255135.3],
                [2661845.7, 1255822.2],
                [2660444.8, 1255316],
                [2659525.8, 1257092.8],
                [2659976.7, 1258293.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9497', ortbez27: 'Triesenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2763143.7, 1214310.4],
                [2758921.1, 1221486.7],
                [2760730.8, 1225689.6],
                [2761398.3, 1226970.8],
                [2765635.5, 1224716.6],
                [2766719.6, 1222160],
                [2764376.8, 1214606.5],
                [2763143.7, 1214310.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1312', ortbez27: 'Ecl�pens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2528800, 1165553.9],
                [2531217.7, 1167988],
                [2534319.3, 1168360.7],
                [2532550.4, 1166752.1],
                [2531470.2, 1165871],
                [2528800, 1165553.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9498', ortbez27: 'Planken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760175, 1228878.5],
                [2762268.4, 1229496.6],
                [2761398.3, 1226970.8],
                [2759615.2, 1227289.7],
                [2760175, 1228878.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9495', ortbez27: 'Triesen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757574.8, 1217134.3],
                [2757763.9, 1220469.8],
                [2758921.1, 1221486.7],
                [2763143.7, 1214310.4],
                [2761037.1, 1213152.9],
                [2760550.9, 1214316.1],
                [2758994.4, 1214714],
                [2757574.8, 1217134.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5412', ortbez27: 'Gebenstorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659976.7, 1258293.2],
                [2659587, 1260087.2],
                [2660701.5, 1261450.8],
                [2662378.7, 1260212],
                [2662758.5, 1257960.9],
                [2659976.7, 1258293.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1317', ortbez27: 'Orny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2532056.4, 1170696.8], [2531217.7, 1167988], [2529389.7, 1168585.3], [2532056.4, 1170696.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9496', ortbez27: 'Balzers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754520.6, 1215175.6],
                [2757574.8, 1217134.3],
                [2758994.4, 1214714],
                [2754514.8, 1213270.2],
                [2754520.6, 1215175.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1318', ortbez27: 'Pompaples' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2532056.4, 1170696.8],
                [2529389.7, 1168585.3],
                [2528822, 1170116.5],
                [2529838.5, 1170864.4],
                [2532056.4, 1170696.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1315', ortbez27: 'La Sarraz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2531217.7, 1167988],
                [2528800, 1165553.9],
                [2526696.8, 1165451.1],
                [2526223.1, 1167004.8],
                [2527739.7, 1167735.3],
                [2525505.1, 1169449.9],
                [2527824.3, 1169339.1],
                [2528822, 1170116.5],
                [2529389.7, 1168585.3],
                [2531217.7, 1167988]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9502', ortbez27: 'Braunau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723909.6, 1261938.2],
                [2721959.4, 1261038],
                [2720479.9, 1261479.4],
                [2721170, 1263691.7],
                [2723111.5, 1264768.6],
                [2726028.9, 1263951.5],
                [2724971.5, 1263281.6],
                [2723909.6, 1261938.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1316', ortbez27: 'Chevilly' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2526696.8, 1165451.1], [2524680.3, 1166107.1], [2526223.1, 1167004.8], [2526696.8, 1165451.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5400', ortbez27: 'Baden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2665504.3, 1259928.4],
                [2666073.1, 1258658.8],
                [2665845.8, 1257102.9],
                [2664947.8, 1255735.1],
                [2663161.4, 1256475.3],
                [2662758.5, 1257960.9],
                [2662378.7, 1260212],
                [2665504.3, 1259928.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1305', ortbez27: 'Penthalaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2529811.3, 1161578.3], [2530475, 1164322.9], [2531712.9, 1162884.4], [2529811.3, 1161578.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9500', ortbez27: 'Wil SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725024.4, 1258002.7],
                [2722715.8, 1256634.5],
                [2721119, 1257286.9],
                [2719887.5, 1258046.8],
                [2719073.1, 1259015.9],
                [2720949.7, 1259670.9],
                [2721959.4, 1261038],
                [2723741.5, 1259507.7],
                [2725024.4, 1258002.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1306', ortbez27: 'Daillens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2531712.9, 1162884.4],
                [2530475, 1164322.9],
                [2531470.2, 1165871],
                [2532550.4, 1166752.1],
                [2533283.9, 1165103.3],
                [2533097.3, 1163560.4],
                [2531712.9, 1162884.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1303', ortbez27: 'Penthaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2529811.3, 1161578.3],
                [2531712.9, 1162884.4],
                [2531985.7, 1160317.9],
                [2530216.9, 1160314.8],
                [2529811.3, 1161578.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9490', ortbez27: 'Vaduz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758921.1, 1221486.7],
                [2757763.9, 1220469.8],
                [2756626.7, 1223976.4],
                [2760730.8, 1225689.6],
                [2758921.1, 1221486.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1304', ortbez27: 'Cossonay-Ville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2527866.9, 1158653.2],
                [2526477.5, 1160403.9],
                [2525463.8, 1162500.8],
                [2526949, 1162484.8],
                [2526696.8, 1165451.1],
                [2528800, 1165553.9],
                [2530475, 1164322.9],
                [2529811.3, 1161578.3],
                [2528178, 1160870.8],
                [2527866.9, 1158653.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9487', ortbez27: 'Gamprin-Bendern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2758679.6, 1229039.4], [2755403, 1228907.8], [2756702.3, 1231003.6], [2758679.6, 1229039.4]]],
            [[[2756380.2, 1232247.4], [2756959.9, 1233430.4], [2758423.9, 1232819.2], [2756380.2, 1232247.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9488', ortbez27: 'Schellenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760538.5, 1233492.7],
                [2758423.9, 1232819.2],
                [2760352.7, 1235813.1],
                [2761193.9, 1234779.7],
                [2760538.5, 1233492.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9493', ortbez27: 'Mauren FL' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2760538.5, 1233492.7], [2759167.4, 1230736.5], [2758423.9, 1232819.2], [2760538.5, 1233492.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1307', ortbez27: 'Lussery-Villars' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2528800, 1165553.9], [2531470.2, 1165871], [2530475, 1164322.9], [2528800, 1165553.9]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9494', ortbez27: 'Schaan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2756022.9, 1225079.2],
                [2755403, 1228907.8],
                [2758679.6, 1229039.4],
                [2760175, 1228878.5],
                [2759615.2, 1227289.7],
                [2761398.3, 1226970.8],
                [2760730.8, 1225689.6],
                [2756626.7, 1223976.4],
                [2756022.9, 1225079.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1308', ortbez27: 'La Chaux (Cossonay)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2524718.1, 1163675.2],
                [2524680.3, 1166107.1],
                [2526696.8, 1165451.1],
                [2526949, 1162484.8],
                [2525463.8, 1162500.8],
                [2524718.1, 1163675.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9491', ortbez27: 'Ruggell' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758365.7, 1237658.4],
                [2760352.7, 1235813.1],
                [2758423.9, 1232819.2],
                [2756959.9, 1233430.4],
                [2757600, 1234812.6],
                [2758365.7, 1237658.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1297', ortbez27: 'Founex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2507170.6, 1131858.5],
                [2506475.2, 1130455.6],
                [2503051.2, 1131760.7],
                [2501787.2, 1132446.5],
                [2503255.6, 1133449.8],
                [2507170.6, 1131858.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1298', ortbez27: 'C�ligny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2507894.5, 1133318.6],
                [2507170.6, 1131858.5],
                [2503255.6, 1133449.8],
                [2503224.4, 1135715.2],
                [2507894.5, 1133318.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9492', ortbez27: 'Eschen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2758679.6, 1229039.4],
                [2756702.3, 1231003.6],
                [2756380.2, 1232247.4],
                [2758423.9, 1232819.2],
                [2759167.4, 1230736.5],
                [2758679.6, 1229039.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1295', ortbez27: 'Mies' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2505161.4, 1127988.2],
                [2504504.7, 1126784.9],
                [2501509.7, 1128314.8],
                [2500978.2, 1129388.9],
                [2500075.9, 1129188.7],
                [2500445.9, 1130465],
                [2501746.1, 1129559.3],
                [2503420.9, 1129893.5],
                [2505161.4, 1127988.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1296', ortbez27: 'Coppet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2503051.2, 1131760.7],
                [2506475.2, 1130455.6],
                [2505161.4, 1127988.2],
                [2503420.9, 1129893.5],
                [2503051.2, 1131760.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9479', ortbez27: 'Oberschan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2754183, 1217310.1],
                [2751920, 1220233.2],
                [2753368.8, 1220692.2],
                [2754634.8, 1219993.6],
                [2755412.5, 1217833],
                [2754183, 1217310.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1302', ortbez27: 'Vufflens-la-Ville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530312.3, 1157245.9],
                [2530214.2, 1159050.1],
                [2530216.9, 1160314.8],
                [2531985.7, 1160317.9],
                [2531806.9, 1157466.6],
                [2530312.3, 1157245.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9485', ortbez27: 'Nendeln' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2759167.4, 1230736.5],
                [2762268.4, 1229496.6],
                [2760175, 1228878.5],
                [2758679.6, 1229039.4],
                [2759167.4, 1230736.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1299', ortbez27: 'Crans-pr�s-C�ligny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2508811.2, 1135167.7],
                [2507894.5, 1133318.6],
                [2503224.4, 1135715.2],
                [2504924.9, 1136495.4],
                [2508811.2, 1135167.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9486', ortbez27: 'Schaanwald' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2759167.4, 1230736.5], [2760538.5, 1233492.7], [2762268.4, 1229496.6], [2759167.4, 1230736.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1290', ortbez27: 'Versoix' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2498497, 1128330],
                [2498943.7, 1130548.7],
                [2500445.9, 1130465],
                [2500075.9, 1129188.7],
                [2500978.2, 1129388.9],
                [2501509.7, 1128314.8],
                [2504504.7, 1126784.9],
                [2503802.4, 1125497.6],
                [2500044.4, 1125501.2],
                [2498497, 1128330]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1287', ortbez27: 'Laconnex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2491912, 1114355.5], [2492759.4, 1112197.9], [2490461.3, 1113576.8], [2491912, 1114355.5]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1288', ortbez27: 'Aire-la-Ville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2493620.9, 1116788.9],
                [2492884, 1115608.5],
                [2491203.6, 1116083.8],
                [2491439.5, 1117509.3],
                [2493620.9, 1116788.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9535', ortbez27: 'Wilen b. Wil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720728, 1255900.9],
                [2719404, 1256137.3],
                [2719887.5, 1258046.8],
                [2721119, 1257286.9],
                [2720728, 1255900.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1293', ortbez27: 'Bellevue' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2499087.2, 1122369.5],
                [2498372.2, 1124261.8],
                [2500044.4, 1125501.2],
                [2502455.6, 1122015.1],
                [2499628.7, 1121209.5],
                [2499087.2, 1122369.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9536', ortbez27: 'Schwarzenbach SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725023.7, 1255709.7],
                [2723527.9, 1254764.9],
                [2722349.5, 1255434.6],
                [2722715.8, 1256634.5],
                [2725023.7, 1255709.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1294', ortbez27: 'Genthod' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2503802.4, 1125497.6], [2502455.6, 1122015.1], [2500044.4, 1125501.2], [2503802.4, 1125497.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1291', ortbez27: 'Commugny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2498943.7, 1130548.7],
                [2500005.1, 1132465.5],
                [2501787.2, 1132446.5],
                [2503051.2, 1131760.7],
                [2503420.9, 1129893.5],
                [2501746.1, 1129559.3],
                [2500445.9, 1130465],
                [2498943.7, 1130548.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5442', ortbez27: 'Fislisbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663038.1, 1255135.3],
                [2663161.4, 1256475.3],
                [2664947.8, 1255735.1],
                [2663465.5, 1253623.4],
                [2662239, 1252938.3],
                [2661847.2, 1254166.9],
                [2663038.1, 1255135.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9542', ortbez27: 'M�nchwilen TG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716200.8, 1260552.4],
                [2717195.6, 1261432.3],
                [2718042.9, 1260239.6],
                [2719073.1, 1259015.9],
                [2716592.7, 1258827.5],
                [2716200.8, 1260552.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1292', ortbez27: 'Chamb�sy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2502455.6, 1122015.1], [2501926.7, 1120158.9], [2499628.7, 1121209.5], [2502455.6, 1122015.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1345', ortbez27: 'Le Lieu' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2512427.2, 1166486.2],
                [2509112.8, 1164918.7],
                [2506691.3, 1166852],
                [2510435.8, 1170255.6],
                [2512412.8, 1168777.9],
                [2513544.5, 1167348.7],
                [2512427.2, 1166486.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5445', ortbez27: 'Eggenwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668151.9, 1248041.5],
                [2668776.2, 1246245.8],
                [2667260.1, 1246662.9],
                [2666907.2, 1247870],
                [2668151.9, 1248041.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1346', ortbez27: 'Les Bioux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2512427.2, 1166486.2],
                [2515150.8, 1164348.6],
                [2512708.3, 1160184.9],
                [2509112.8, 1164918.7],
                [2512427.2, 1166486.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1343', ortbez27: 'Les Charbonni�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2510435.8, 1170255.6],
                [2513532.5, 1172731.7],
                [2515151.6, 1171676.5],
                [2514460.6, 1168338.5],
                [2513544.5, 1167348.7],
                [2512412.8, 1168777.9],
                [2510435.8, 1170255.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5443', ortbez27: 'Niederrohrdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664279.5, 1251902.7],
                [2663465.5, 1253623.4],
                [2664947.8, 1255735.1],
                [2666124.4, 1255050],
                [2665531.6, 1251633.4],
                [2664279.5, 1251902.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1344', ortbez27: "L'Abbaye" },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2516666.3, 1167327.9],
                [2516627.9, 1165518.5],
                [2515150.8, 1164348.6],
                [2512427.2, 1166486.2],
                [2513544.5, 1167348.7],
                [2514460.6, 1168338.5],
                [2516666.3, 1167327.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9527', ortbez27: 'Niederhelfenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731563.6, 1259005.6],
                [2729447.4, 1259171.8],
                [2731523, 1261680.3],
                [2733517.8, 1260482.5],
                [2731563.6, 1259005.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5444', ortbez27: 'K�nten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667936.1, 1249406.2],
                [2668151.9, 1248041.5],
                [2666907.2, 1247870],
                [2666696.8, 1250174.1],
                [2667936.1, 1249406.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1350', ortbez27: 'Orbe' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2533390.4, 1175741.4],
                [2531990.2, 1172161.8],
                [2529218.5, 1173090.1],
                [2529957.1, 1175751.6],
                [2533390.4, 1175741.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9533', ortbez27: 'Dietschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719404, 1256137.3],
                [2720728, 1255900.9],
                [2722349.5, 1255434.6],
                [2723527.9, 1254764.9],
                [2723595.9, 1253444.1],
                [2723598.9, 1252141],
                [2721948.8, 1250962.2],
                [2719963.7, 1250973.6],
                [2717225.6, 1253247.1],
                [2717641.7, 1255432],
                [2718013.3, 1256722.4],
                [2719404, 1256137.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1347', ortbez27: 'Le Sentier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2503325.9, 1163483.7],
                [2504498.7, 1164703.5],
                [2506691.3, 1166852],
                [2509112.8, 1164918.7],
                [2507656.5, 1163204.4],
                [2507501.3, 1161595.8],
                [2503325.9, 1163483.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9534', ortbez27: 'G�hwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717225.6, 1253247.1],
                [2719963.7, 1250973.6],
                [2718983, 1249617.3],
                [2718037.8, 1248732.2],
                [2715966.3, 1250843.4],
                [2717225.6, 1253247.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1348', ortbez27: 'Le Brassus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2505821.8, 1153349.1],
                [2504529.1, 1154196.8],
                [2501735.7, 1155835.2],
                [2503325.9, 1163483.7],
                [2507501.3, 1161595.8],
                [2510879.6, 1158750.6],
                [2510034.2, 1157337.9],
                [2508016.9, 1155605.2],
                [2507134.6, 1154073.9],
                [2505821.8, 1153349.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5432', ortbez27: 'Neuenhof' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667261.9, 1253533.7],
                [2666124.4, 1255050],
                [2664947.8, 1255735.1],
                [2665845.8, 1257102.9],
                [2667870.1, 1255346.8],
                [2667261.9, 1253533.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1337', ortbez27: 'Vallorbe' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2519534.9, 1176313.7],
                [2523421.7, 1175929.9],
                [2523563.3, 1174652.3],
                [2522579.8, 1173862.2],
                [2521407.2, 1173426.9],
                [2516818.1, 1171029],
                [2515151.6, 1171676.5],
                [2513532.5, 1172731.7],
                [2519534.9, 1176313.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1338', ortbez27: 'Ballaigues' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2519534.9, 1176313.7], [2522013.4, 1178600.4], [2523421.7, 1175929.9], [2519534.9, 1176313.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9532', ortbez27: 'Rickenbach b. Wil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720728, 1255900.9],
                [2721119, 1257286.9],
                [2722715.8, 1256634.5],
                [2722349.5, 1255434.6],
                [2720728, 1255900.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5436', ortbez27: 'W�renlos' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670090.4, 1257786.9],
                [2672416.2, 1256251.1],
                [2671680.5, 1253603.4],
                [2669579, 1254160.1],
                [2667870.1, 1255346.8],
                [2670090.4, 1257786.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1341', ortbez27: 'Orient' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2509112.8, 1164918.7],
                [2512708.3, 1160184.9],
                [2510879.6, 1158750.6],
                [2507501.3, 1161595.8],
                [2507656.5, 1163204.4],
                [2509112.8, 1164918.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5425', ortbez27: 'Schneisingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2669671.5, 1266313.8],
                [2671883.5, 1264413.6],
                [2669418.9, 1261701.9],
                [2667789.8, 1262469.4],
                [2669671.5, 1266313.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1342', ortbez27: 'Le Pont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2516666.3, 1167327.9],
                [2514460.6, 1168338.5],
                [2515151.6, 1171676.5],
                [2516818.1, 1171029],
                [2516762.2, 1169347.4],
                [2516666.3, 1167327.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9525', ortbez27: 'Lenggenwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729251.5, 1261204.4],
                [2729447.4, 1259171.8],
                [2728902.5, 1257560.8],
                [2727078.3, 1258606.9],
                [2726246.9, 1260733.3],
                [2727536.2, 1260811.6],
                [2729251.5, 1261204.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5426', ortbez27: 'Lengnau AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667789.8, 1262469.4],
                [2664537.9, 1262226.4],
                [2665610.8, 1264838],
                [2666473.5, 1266719.6],
                [2668391.4, 1267514.8],
                [2669671.5, 1266313.8],
                [2667789.8, 1262469.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9526', ortbez27: 'Zuckenriet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731523, 1261680.3],
                [2729447.4, 1259171.8],
                [2729251.5, 1261204.4],
                [2730200, 1263046.8],
                [2731523, 1261680.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5423', ortbez27: 'Freienwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2665504.3, 1259928.4],
                [2664537.9, 1262226.4],
                [2667789.8, 1262469.4],
                [2666997.7, 1260049.6],
                [2665504.3, 1259928.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1329', ortbez27: 'Bretonni�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2527000.5, 1172411.3],
                [2525615, 1172844.6],
                [2523563.3, 1174652.3],
                [2523421.7, 1175929.9],
                [2526043.8, 1176423.1],
                [2527486.5, 1175800.5],
                [2527000.5, 1172411.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9524', ortbez27: 'Zuzwil SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724620.6, 1260582.9],
                [2726246.9, 1260733.3],
                [2727078.3, 1258606.9],
                [2725024.4, 1258002.7],
                [2723741.5, 1259507.7],
                [2724620.6, 1260582.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5430', ortbez27: 'Wettingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2665845.8, 1257102.9],
                [2666073.1, 1258658.8],
                [2666997.7, 1260049.6],
                [2668143.1, 1259194.2],
                [2670992.7, 1259562],
                [2671843.4, 1258644.9],
                [2670090.4, 1257786.9],
                [2667870.1, 1255346.8],
                [2665845.8, 1257102.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9514', ortbez27: 'Wuppenau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726246.9, 1260733.3],
                [2724620.6, 1260582.9],
                [2723909.6, 1261938.2],
                [2724971.5, 1263281.6],
                [2726028.9, 1263951.5],
                [2727078.5, 1262398.3],
                [2727536.2, 1260811.6],
                [2726246.9, 1260733.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9512', ortbez27: 'Rossr�ti' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723909.6, 1261938.2],
                [2724620.6, 1260582.9],
                [2723741.5, 1259507.7],
                [2721959.4, 1261038],
                [2723909.6, 1261938.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5417', ortbez27: 'Untersiggenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663454.9, 1263058.5],
                [2662378.7, 1260212],
                [2660701.5, 1261450.8],
                [2659950.3, 1262693.7],
                [2660855.6, 1263600.1],
                [2663454.9, 1263058.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9517', ortbez27: 'Mettlen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2725735.1, 1266928.8], [2727088.6, 1267983.9], [2728106.2, 1266085.7], [2725735.1, 1266928.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5415', ortbez27: 'Nussbaumen AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2662378.7, 1260212], [2664537.9, 1262226.4], [2665504.3, 1259928.4], [2662378.7, 1260212]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9515', ortbez27: 'Hosenruck' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727078.5, 1262398.3],
                [2730200, 1263046.8],
                [2729251.5, 1261204.4],
                [2727536.2, 1260811.6],
                [2727078.5, 1262398.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5416', ortbez27: 'Kirchdorf AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2662378.7, 1260212], [2663454.9, 1263058.5], [2664537.9, 1262226.4], [2662378.7, 1260212]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1321', ortbez27: 'Arnex-sur-Orbe' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2529218.5, 1173090.1],
                [2531990.2, 1172161.8],
                [2532056.4, 1170696.8],
                [2529838.5, 1170864.4],
                [2529218.5, 1173090.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5420', ortbez27: 'Ehrendingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666997.7, 1260049.6],
                [2667789.8, 1262469.4],
                [2669418.9, 1261701.9],
                [2671061.7, 1261452.7],
                [2670992.7, 1259562],
                [2668143.1, 1259194.2],
                [2666997.7, 1260049.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1325', ortbez27: 'Vaulion' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2519846.7, 1169557.7],
                [2516762.2, 1169347.4],
                [2516818.1, 1171029],
                [2521407.2, 1173426.9],
                [2522579.8, 1173862.2],
                [2522622.7, 1172304.1],
                [2522380.2, 1170596.3],
                [2519846.7, 1169557.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1326', ortbez27: 'Juriens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2523218.8, 1167930.8],
                [2522380.2, 1170596.3],
                [2522622.7, 1172304.1],
                [2525442.2, 1170887.8],
                [2525505.1, 1169449.9],
                [2523218.8, 1167930.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1323', ortbez27: 'Romainm�tier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2522622.7, 1172304.1],
                [2525615, 1172844.6],
                [2527000.5, 1172411.3],
                [2528822, 1170116.5],
                [2527824.3, 1169339.1],
                [2525505.1, 1169449.9],
                [2525442.2, 1170887.8],
                [2522622.7, 1172304.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1324', ortbez27: 'Premier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2525615, 1172844.6],
                [2522622.7, 1172304.1],
                [2522579.8, 1173862.2],
                [2523563.3, 1174652.3],
                [2525615, 1172844.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1377', ortbez27: 'Oulens-sous-Echallens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2534319.3, 1168360.7],
                [2534871.7, 1165659.7],
                [2533283.9, 1165103.3],
                [2532550.4, 1166752.1],
                [2534319.3, 1168360.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9562', ortbez27: 'M�rwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723021.6, 1267681.3],
                [2724133.9, 1265499.5],
                [2723111.5, 1264768.6],
                [2721265.5, 1264960.3],
                [2720985.2, 1266288.4],
                [2721723.6, 1267652.4],
                [2723021.6, 1267681.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1376', ortbez27: 'Eclagnens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2534871.7, 1165659.7],
                [2534319.3, 1168360.7],
                [2534758.6, 1170533.4],
                [2536161.5, 1171518.2],
                [2537780.8, 1170684.5],
                [2537731.6, 1166850.6],
                [2536070.6, 1166481.2],
                [2534871.7, 1165659.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9565', ortbez27: 'Oberbussnang' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725735.1, 1266928.8],
                [2723021.6, 1267681.3],
                [2722837.3, 1269902.9],
                [2725040.1, 1268836.9],
                [2727088.6, 1267983.9],
                [2725735.1, 1266928.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5463', ortbez27: 'Wislikofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670998.3, 1267102],
                [2669671.5, 1266313.8],
                [2668391.4, 1267514.8],
                [2669310.4, 1268702.6],
                [2670998.3, 1267102]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5464', ortbez27: 'R�mikon AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2669310.4, 1268702.6], [2671819.6, 1269946.5], [2670998.3, 1267102], [2669310.4, 1268702.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9553', ortbez27: 'Bettwiesen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720479.9, 1261479.4],
                [2718042.9, 1260239.6],
                [2717195.6, 1261432.3],
                [2718499.2, 1262984.4],
                [2720479.9, 1261479.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9554', ortbez27: 'T�gerschen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2720479.9, 1261479.4], [2718499.2, 1262984.4], [2721170, 1263691.7], [2720479.9, 1261479.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5467', ortbez27: 'Fisibach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672895.2, 1266038],
                [2670998.3, 1267102],
                [2671819.6, 1269946.5],
                [2673843.5, 1268890.3],
                [2674108.4, 1266780.5],
                [2672895.2, 1266038]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1373', ortbez27: 'Chavornay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2533390.4, 1175741.4],
                [2534528.5, 1174020.9],
                [2535063.8, 1172458.2],
                [2536161.5, 1171518.2],
                [2534758.6, 1170533.4],
                [2532056.4, 1170696.8],
                [2531990.2, 1172161.8],
                [2533390.4, 1175741.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9552', ortbez27: 'Bronschhofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718042.9, 1260239.6],
                [2720479.9, 1261479.4],
                [2721959.4, 1261038],
                [2720949.7, 1259670.9],
                [2719073.1, 1259015.9],
                [2718042.9, 1260239.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1374', ortbez27: 'Corcelles-sur-Chavornay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2534528.5, 1174020.9],
                [2537689.9, 1174099.3],
                [2538401.9, 1172585.2],
                [2537780.8, 1170684.5],
                [2536161.5, 1171518.2],
                [2535063.8, 1172458.2],
                [2534528.5, 1174020.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1372', ortbez27: 'Bavois' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2534319.3, 1168360.7],
                [2531217.7, 1167988],
                [2532056.4, 1170696.8],
                [2534758.6, 1170533.4],
                [2534319.3, 1168360.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9555', ortbez27: 'Tobel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2721265.5, 1264960.3], [2723111.5, 1264768.6], [2721170, 1263691.7], [2721265.5, 1264960.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9556', ortbez27: 'Affeltrangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718569.6, 1265421.6],
                [2719674.8, 1266644.9],
                [2720985.2, 1266288.4],
                [2721265.5, 1264960.3],
                [2721170, 1263691.7],
                [2718499.2, 1262984.4],
                [2718569.6, 1265421.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9545', ortbez27: 'W�ngi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717195.6, 1261432.3],
                [2716200.8, 1260552.4],
                [2713087, 1259390.5],
                [2712262.4, 1260601.4],
                [2713279.8, 1261932.1],
                [2712501.8, 1263140.8],
                [2713727.9, 1263583.9],
                [2715804.4, 1263831],
                [2717195.6, 1261432.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5462', ortbez27: 'Siglistorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2671883.5, 1264413.6],
                [2669671.5, 1266313.8],
                [2670998.3, 1267102],
                [2672895.2, 1266038],
                [2673449.3, 1264606.9],
                [2671883.5, 1264413.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1353', ortbez27: 'Bofflens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2527000.5, 1172411.3],
                [2527486.5, 1175800.5],
                [2529218.5, 1173090.1],
                [2529838.5, 1170864.4],
                [2528822, 1170116.5],
                [2527000.5, 1172411.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9547', ortbez27: 'Wittenwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2712262.4, 1260601.4], [2712501.8, 1263140.8], [2713279.8, 1261932.1], [2712262.4, 1260601.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9548', ortbez27: 'Matzingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710022.4, 1266218.5],
                [2713439.8, 1266748.8],
                [2713727.9, 1263583.9],
                [2712501.8, 1263140.8],
                [2711055.9, 1263686.4],
                [2709858.7, 1264424.4],
                [2710022.4, 1266218.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5453', ortbez27: 'Remetschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666696.8, 1250174.1],
                [2665531.6, 1251633.4],
                [2668187.2, 1252337.7],
                [2669484.3, 1250907.6],
                [2667936.1, 1249406.2],
                [2666696.8, 1250174.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1354', ortbez27: 'Montcherand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2527486.5, 1175800.5], [2528504.4, 1177280.8], [2529957.1, 1175751.6], [2527486.5, 1175800.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9601', ortbez27: 'L�tisburg Station' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723869.1, 1250298.3],
                [2723956.7, 1248801.6],
                [2722543.1, 1247095.5],
                [2722389.3, 1249753.3],
                [2721948.8, 1250962.2],
                [2723869.1, 1250298.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5454', ortbez27: 'Bellikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670167.2, 1248036.2],
                [2668151.9, 1248041.5],
                [2667936.1, 1249406.2],
                [2669484.3, 1250907.6],
                [2670646.6, 1250367.8],
                [2670167.2, 1248036.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9602', ortbez27: 'M�selbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722389.3, 1249753.3],
                [2720062.2, 1248933.3],
                [2718983, 1249617.3],
                [2719963.7, 1250973.6],
                [2721948.8, 1250962.2],
                [2722389.3, 1249753.3]
              ]
            ],
            [[[2723598.9, 1252141], [2723869.1, 1250298.3], [2721948.8, 1250962.2], [2723598.9, 1252141]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1352', ortbez27: 'Agiez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2527486.5, 1175800.5], [2529957.1, 1175751.6], [2529218.5, 1173090.1], [2527486.5, 1175800.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5452', ortbez27: 'Oberrohrdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666124.4, 1255050],
                [2667261.9, 1253533.7],
                [2668187.2, 1252337.7],
                [2665531.6, 1251633.4],
                [2666124.4, 1255050]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1357', ortbez27: 'Lignerolle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2522013.4, 1178600.4],
                [2524885.9, 1180216],
                [2526319.8, 1179529.2],
                [2526612.3, 1177775.9],
                [2526043.8, 1176423.1],
                [2523421.7, 1175929.9],
                [2522013.4, 1178600.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5505', ortbez27: 'Brunegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2660164.9, 1253301.6], [2658767.8, 1251763.7], [2658198.2, 1253237.4], [2660164.9, 1253301.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1358', ortbez27: 'Valeyres-sous-Rances' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2529957.1, 1175751.6],
                [2528504.4, 1177280.8],
                [2528982.2, 1178812.4],
                [2530988.2, 1180989.9],
                [2531927.4, 1180032.2],
                [2534258.1, 1177258.8],
                [2533390.4, 1175741.4],
                [2529957.1, 1175751.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5506', ortbez27: 'M�genwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660085.3, 1250616.5],
                [2658767.8, 1251763.7],
                [2660164.9, 1253301.6],
                [2661319.7, 1251099.7],
                [2660085.3, 1250616.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1355', ortbez27: "L'Abergement" },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2526319.8, 1179529.2],
                [2528982.2, 1178812.4],
                [2528504.4, 1177280.8],
                [2526612.3, 1177775.9],
                [2526319.8, 1179529.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9606', ortbez27: 'B�tschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723956.7, 1248801.6],
                [2724823.4, 1246277.8],
                [2722172.1, 1245863],
                [2722543.1, 1247095.5],
                [2723956.7, 1248801.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5503', ortbez27: 'Schafisheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653437.1, 1245692.6],
                [2651101.3, 1247865],
                [2651212.2, 1249367.7],
                [2652738.6, 1249361.8],
                [2653887.9, 1248413.5],
                [2654651.2, 1246441.3],
                [2653437.1, 1245692.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1356', ortbez27: 'La Russille' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2528504.4, 1177280.8],
                [2527486.5, 1175800.5],
                [2526043.8, 1176423.1],
                [2526612.3, 1177775.9],
                [2528504.4, 1177280.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5504', ortbez27: 'Othmarsingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2658767.8, 1251763.7],
                [2660085.3, 1250616.5],
                [2657545.5, 1249603],
                [2657129.6, 1250896],
                [2658767.8, 1251763.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1409', ortbez27: 'Chan�az' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546746.6, 1177074.4],
                [2548998.5, 1174745.2],
                [2546073.5, 1174853],
                [2545252.8, 1176290.7],
                [2546746.6, 1177074.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9604', ortbez27: 'L�tisburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727492.9, 1248936.3],
                [2723869.1, 1250298.3],
                [2723598.9, 1252141],
                [2724953.5, 1252787.9],
                [2725929.4, 1252004],
                [2728720.9, 1252256.7],
                [2729437, 1250732.4],
                [2727492.9, 1248936.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1410', ortbez27: 'St-Cierges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546263, 1170007.8],
                [2544986, 1171976.6],
                [2546400.6, 1172865.2],
                [2546073.5, 1174853],
                [2548998.5, 1174745.2],
                [2550066.6, 1176377],
                [2550007.6, 1173333.4],
                [2549417.1, 1171165.5],
                [2548462.8, 1169715.3],
                [2546263, 1170007.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1407', ortbez27: 'Donneloye' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2545252.8, 1176290.7],
                [2546073.5, 1174853],
                [2544331.9, 1174209.7],
                [2543825.6, 1175684.4],
                [2542772.2, 1176992.2],
                [2543987.2, 1177379.8],
                [2546343.8, 1180293.5],
                [2546746.6, 1177074.4],
                [2545252.8, 1176290.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5507', ortbez27: 'Mellingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663465.5, 1253623.4],
                [2664279.5, 1251902.7],
                [2662616.6, 1251238.5],
                [2662239, 1252938.3],
                [2663465.5, 1253623.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1413', ortbez27: 'Orzens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2541028.7, 1173979.2], [2542772.2, 1176992.2], [2543825.6, 1175684.4], [2541028.7, 1173979.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1412', ortbez27: 'Valeyres-sous-Ursins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541107.6, 1177587.1],
                [2542772.2, 1176992.2],
                [2541028.7, 1173979.2],
                [2540321.7, 1175680.9],
                [2538717.8, 1176042.2],
                [2539344.1, 1177326.8],
                [2539785, 1179383.4],
                [2541107.6, 1177587.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1400', ortbez27: 'Cheseaux-Nor�az' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2535456.5, 1180046.5],
                [2537350.3, 1181530.7],
                [2538744.1, 1183168.8],
                [2540158.4, 1183378.4],
                [2543153.2, 1185156.5],
                [2544051.8, 1182468.1],
                [2543000.7, 1181763.2],
                [2541516.4, 1180718.9],
                [2539785, 1179383.4],
                [2538242.1, 1179276.5],
                [2537020.8, 1178826.7],
                [2535456.5, 1180046.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1405', ortbez27: 'Pomy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541107.6, 1177587.1],
                [2539785, 1179383.4],
                [2541516.4, 1180718.9],
                [2542760.2, 1178859.6],
                [2541107.6, 1177587.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1406', ortbez27: 'Cronay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2542760.2, 1178859.6],
                [2544433, 1180564.4],
                [2546343.8, 1180293.5],
                [2543987.2, 1177379.8],
                [2542772.2, 1176992.2],
                [2541107.6, 1177587.1],
                [2542760.2, 1178859.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1404', ortbez27: 'Cuarny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541516.4, 1180718.9],
                [2543000.7, 1181763.2],
                [2544051.8, 1182468.1],
                [2544433, 1180564.4],
                [2542760.2, 1178859.6],
                [2541516.4, 1180718.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9633', ortbez27: 'Hemberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728579.1, 1239335.1],
                [2730187.1, 1241503.4],
                [2731871.3, 1241936.1],
                [2733983.1, 1242777.9],
                [2734616.3, 1241351.1],
                [2733963.2, 1238244.1],
                [2732628.8, 1237836.8],
                [2729682.9, 1238423.2],
                [2728579.1, 1239335.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9631', ortbez27: 'Ulisbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2728579.1, 1239335.1], [2725976.3, 1237818.1], [2725624.5, 1239277.8], [2728579.1, 1239335.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1442', ortbez27: 'Montagny-pr�s-Yverdon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2537503.8, 1183951.9],
                [2538744.1, 1183168.8],
                [2537350.3, 1181530.7],
                [2534751.9, 1181572.4],
                [2534336.3, 1182942.1],
                [2535811.9, 1184369.8],
                [2537503.8, 1183951.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1439', ortbez27: 'Rances' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2528982.2, 1178812.4], [2526319.8, 1179529.2], [2530988.2, 1180989.9], [2528982.2, 1178812.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1445', ortbez27: 'Vuiteboeuf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2532272.4, 1182920.8],
                [2531308.9, 1184663.3],
                [2533294.5, 1185319.9],
                [2533842.4, 1184104.5],
                [2534336.3, 1182942.1],
                [2532272.4, 1182920.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1446', ortbez27: 'Baulmes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2529408.8, 1184281.6],
                [2531308.9, 1184663.3],
                [2532272.4, 1182920.8],
                [2530988.2, 1180989.9],
                [2526319.8, 1179529.2],
                [2524885.9, 1180216],
                [2525755.5, 1182899.2],
                [2525983.4, 1184133.6],
                [2528033.7, 1183821.9],
                [2529408.8, 1184281.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1443', ortbez27: 'Champvent' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530988.2, 1180989.9],
                [2532272.4, 1182920.8],
                [2534336.3, 1182942.1],
                [2534751.9, 1181572.4],
                [2531927.4, 1180032.2],
                [2530988.2, 1180989.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9630', ortbez27: 'Wattwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723866, 1234636.6],
                [2722069, 1234403.5],
                [2722307.3, 1235711.1],
                [2724398.5, 1236369.2],
                [2723866, 1234636.6]
              ]
            ],
            [
              [
                [2724398.5, 1236369.2],
                [2721837.8, 1237924.9],
                [2720285.2, 1238992.5],
                [2720393.7, 1240575.1],
                [2721841.1, 1240237.4],
                [2727487.8, 1242081.1],
                [2730187.1, 1241503.4],
                [2728579.1, 1239335.1],
                [2725624.5, 1239277.8],
                [2725976.3, 1237818.1],
                [2724398.5, 1236369.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1433', ortbez27: 'Suchy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2537689.9, 1174099.3],
                [2534528.5, 1174020.9],
                [2535505.9, 1176614.2],
                [2537037.5, 1176259.9],
                [2537689.9, 1174099.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1434', ortbez27: 'Ependes VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2535505.9, 1176614.2],
                [2534258.1, 1177258.8],
                [2535368.1, 1177888.8],
                [2537020.8, 1178826.7],
                [2537037.5, 1176259.9],
                [2535505.9, 1176614.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1431', ortbez27: 'Vugelles-La Mothe' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2535625.7, 1186253.2],
                [2533294.5, 1185319.9],
                [2534461.7, 1187677.4],
                [2535625.4, 1188765.8],
                [2536356.9, 1187449.9],
                [2535625.7, 1186253.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1432', ortbez27: 'Gressy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538717.8, 1176042.2],
                [2537689.9, 1174099.3],
                [2537037.5, 1176259.9],
                [2537020.8, 1178826.7],
                [2538242.1, 1179276.5],
                [2539785, 1179383.4],
                [2539344.1, 1177326.8],
                [2538717.8, 1176042.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9615', ortbez27: 'Dietfurt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724823.4, 1246277.8],
                [2725079.9, 1244073.8],
                [2721503.4, 1243582.4],
                [2721403.5, 1244876.5],
                [2722172.1, 1245863],
                [2724823.4, 1246277.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1437', ortbez27: 'Susc�vaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2537020.8, 1178826.7], [2535368.1, 1177888.8], [2535456.5, 1180046.5], [2537020.8, 1178826.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1438', ortbez27: 'Mathod' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2531927.4, 1180032.2],
                [2534751.9, 1181572.4],
                [2535456.5, 1180046.5],
                [2535368.1, 1177888.8],
                [2534258.1, 1177258.8],
                [2531927.4, 1180032.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9621', ortbez27: 'Oberhelfenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724823.4, 1246277.8],
                [2727254.5, 1247408.5],
                [2728137.2, 1245725.6],
                [2727307.9, 1244522.5],
                [2725079.9, 1244073.8],
                [2724823.4, 1246277.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5522', ortbez27: 'T�gerig' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2662616.6, 1251238.5],
                [2664279.5, 1251902.7],
                [2665531.6, 1251633.4],
                [2664708.8, 1250363.3],
                [2663659.5, 1248815.8],
                [2662616.6, 1251238.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1435', ortbez27: 'Essert-Pittet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2534528.5, 1174020.9],
                [2533390.4, 1175741.4],
                [2534258.1, 1177258.8],
                [2535505.9, 1176614.2],
                [2534528.5, 1174020.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9622', ortbez27: 'Krinau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725079.9, 1244073.8],
                [2721841.1, 1240237.4],
                [2720393.7, 1240575.1],
                [2720752.3, 1242581.9],
                [2721503.4, 1243582.4],
                [2725079.9, 1244073.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1436', ortbez27: 'Chamblon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2534751.9, 1181572.4], [2537350.3, 1181530.7], [2535456.5, 1180046.5], [2534751.9, 1181572.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1425', ortbez27: 'Onnens VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2544502.8, 1185957.9],
                [2543153.2, 1185156.5],
                [2542828, 1186864.7],
                [2541848.9, 1189780.9],
                [2543476, 1191312.1],
                [2544502.8, 1185957.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9620', ortbez27: 'Lichtensteig' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727487.8, 1242081.1],
                [2721841.1, 1240237.4],
                [2725079.9, 1244073.8],
                [2727307.9, 1244522.5],
                [2727487.8, 1242081.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5525', ortbez27: 'Fischbach-G�slikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664641.9, 1246157.8],
                [2665581.8, 1248029.4],
                [2666907.2, 1247870],
                [2667260.1, 1246662.9],
                [2664641.9, 1246157.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1426', ortbez27: 'Concise' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546903.1, 1186684.6],
                [2544502.8, 1185957.9],
                [2543476, 1191312.1],
                [2544769.7, 1192519.1],
                [2546770.3, 1192392.1],
                [2547848.7, 1191158.8],
                [2549813.3, 1189439.4],
                [2546903.1, 1186684.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1423', ortbez27: 'Fontanezier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2536356.9, 1187449.9],
                [2537378.9, 1189338],
                [2538850, 1189660.4],
                [2538991.8, 1187872.3],
                [2536356.9, 1187449.9]
              ]
            ],
            [
              [
                [2541848.9, 1189780.9],
                [2540422, 1188785.3],
                [2538850, 1189660.4],
                [2539349, 1192875.5],
                [2543476, 1191312.1],
                [2541848.9, 1189780.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1424', ortbez27: 'Champagne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2540422, 1188785.3],
                [2541310.3, 1186714.5],
                [2539309.1, 1186639.4],
                [2538991.8, 1187872.3],
                [2538850, 1189660.4],
                [2540422, 1188785.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9607', ortbez27: 'Mosnang' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720062.2, 1248933.3],
                [2722389.3, 1249753.3],
                [2722543.1, 1247095.5],
                [2722172.1, 1245863],
                [2721403.5, 1244876.5],
                [2719031.1, 1244460.5],
                [2720062.2, 1248933.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5524', ortbez27: 'Niederwil AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663005.5, 1247493.6],
                [2663659.5, 1248815.8],
                [2664708.8, 1250363.3],
                [2665581.8, 1248029.4],
                [2664641.9, 1246157.8],
                [2663005.5, 1247493.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1429', ortbez27: 'Giez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538025.4, 1186085.4],
                [2537503.8, 1183951.9],
                [2535811.9, 1184369.8],
                [2535625.7, 1186253.2],
                [2536356.9, 1187449.9],
                [2538025.4, 1186085.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9608', ortbez27: 'Ganterschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727254.5, 1247408.5],
                [2724823.4, 1246277.8],
                [2723956.7, 1248801.6],
                [2723869.1, 1250298.3],
                [2727492.9, 1248936.3],
                [2727254.5, 1247408.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1430', ortbez27: 'Orges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2533294.5, 1185319.9],
                [2535625.7, 1186253.2],
                [2535811.9, 1184369.8],
                [2534336.3, 1182942.1],
                [2533842.4, 1184104.5],
                [2533294.5, 1185319.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9613', ortbez27: 'M�hlr�ti' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716129.3, 1245345.8],
                [2713734.7, 1248473.7],
                [2713561, 1249738.2],
                [2715290.6, 1249571.8],
                [2715966.3, 1250843.4],
                [2718037.8, 1248732.2],
                [2716129.3, 1245345.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1427', ortbez27: 'Bonvillars' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2540422, 1188785.3],
                [2541848.9, 1189780.9],
                [2542828, 1186864.7],
                [2541310.3, 1186714.5],
                [2540422, 1188785.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9614', ortbez27: 'Libingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719031.1, 1244460.5],
                [2721403.5, 1244876.5],
                [2721503.4, 1243582.4],
                [2720752.3, 1242581.9],
                [2720393.7, 1240575.1],
                [2719209, 1239995],
                [2717947, 1241450.3],
                [2719031.1, 1244460.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1428', ortbez27: 'Provence' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2539349, 1192875.5],
                [2545136.3, 1196483.7],
                [2546798.9, 1193854.2],
                [2546770.3, 1192392.1],
                [2544769.7, 1192519.1],
                [2543476, 1191312.1],
                [2539349, 1192875.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5512', ortbez27: 'Wohlenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2662616.6, 1251238.5],
                [2661319.7, 1251099.7],
                [2660164.9, 1253301.6],
                [2661847.2, 1254166.9],
                [2662239, 1252938.3],
                [2662616.6, 1251238.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1417', ortbez27: 'Essertines-sur-Yverdon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2540433.6, 1172646.9],
                [2538401.9, 1172585.2],
                [2537689.9, 1174099.3],
                [2538717.8, 1176042.2],
                [2540321.7, 1175680.9],
                [2541028.7, 1173979.2],
                [2540433.6, 1172646.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9612', ortbez27: 'Dreien' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716129.3, 1245345.8],
                [2718037.8, 1248732.2],
                [2718983, 1249617.3],
                [2720062.2, 1248933.3],
                [2719031.1, 1244460.5],
                [2717947, 1241450.3],
                [2716986.3, 1242250.2],
                [2716129.3, 1245345.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1418', ortbez27: 'Vuarrens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538401.9, 1172585.2],
                [2540433.6, 1172646.9],
                [2541173, 1170898.8],
                [2539069.8, 1168986.2],
                [2537780.8, 1170684.5],
                [2538401.9, 1172585.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1415', ortbez27: 'Molondin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2548431.9, 1179446.4],
                [2550216.4, 1177731.8],
                [2546746.6, 1177074.4],
                [2546343.8, 1180293.5],
                [2547711.5, 1181266],
                [2548431.9, 1179446.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1416', ortbez27: 'Pailly' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2541173, 1170898.8],
                [2540433.6, 1172646.9],
                [2541028.7, 1173979.2],
                [2543570, 1173071.5],
                [2542888.5, 1170848.9],
                [2541173, 1170898.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1421', ortbez27: 'Grandevent' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538991.8, 1187872.3],
                [2539309.1, 1186639.4],
                [2538025.4, 1186085.4],
                [2536356.9, 1187449.9],
                [2538991.8, 1187872.3]
              ]
            ],
            [[[2535625.4, 1188765.8], [2537378.9, 1189338], [2536356.9, 1187449.9], [2535625.4, 1188765.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1422', ortbez27: 'Grandson' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2543153.2, 1185156.5],
                [2540158.4, 1183378.4],
                [2538744.1, 1183168.8],
                [2537503.8, 1183951.9],
                [2538025.4, 1186085.4],
                [2539309.1, 1186639.4],
                [2541310.3, 1186714.5],
                [2542828, 1186864.7],
                [2543153.2, 1185156.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1473', ortbez27: 'Font' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2549813.3, 1189439.4],
                [2552230.1, 1191727],
                [2554216.8, 1187588.3],
                [2552986.7, 1187066.5],
                [2549813.3, 1189439.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1474', ortbez27: 'Ch�bles FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2549813.3, 1189439.4],
                [2552986.7, 1187066.5],
                [2553184.6, 1184940],
                [2551778.7, 1184452.7],
                [2549813.3, 1189439.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9657', ortbez27: 'Unterwasser' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744014.6, 1227220.5],
                [2742779.4, 1222567.8],
                [2741613.8, 1224310.1],
                [2740610.2, 1229905.1],
                [2737920.9, 1231470.9],
                [2741286, 1232691.5],
                [2742602.8, 1229350],
                [2744014.6, 1227220.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9658', ortbez27: 'Wildhaus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2746501.9, 1233983.4],
                [2748735.6, 1233859.6],
                [2747780.4, 1229369.8],
                [2744014.6, 1227220.5],
                [2742602.8, 1229350],
                [2741286, 1232691.5],
                [2741972.6, 1235364],
                [2743701.4, 1236182.1],
                [2744265.4, 1234955.5],
                [2746501.9, 1233983.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9655', ortbez27: 'Stein SG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2735662.1, 1227413.4],
                [2733394, 1227416.2],
                [2737109.1, 1232627.2],
                [2737920.9, 1231470.9],
                [2736728.8, 1228664.9],
                [2735662.1, 1227413.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9656', ortbez27: 'Alt St. Johann' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2741613.8, 1224310.1],
                [2736563, 1223685.6],
                [2735198.3, 1223293.2],
                [2735662.1, 1227413.4],
                [2736728.8, 1228664.9],
                [2737920.9, 1231470.9],
                [2740610.2, 1229905.1],
                [2741613.8, 1224310.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1475', ortbez27: 'Forel FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558903.1, 1189440.8],
                [2557644.3, 1188700.8],
                [2557359.8, 1189983.8],
                [2554088.7, 1193486.4],
                [2556418, 1195691.5],
                [2559833.2, 1192448.5],
                [2558378.2, 1190716.6],
                [2558903.1, 1189440.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1464', ortbez27: 'Chavannes-le-Ch�ne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2548431.9, 1179446.4],
                [2547711.5, 1181266],
                [2550010.5, 1182823.2],
                [2551140.6, 1181645.4],
                [2550434.4, 1180335.4],
                [2548431.9, 1179446.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9650', ortbez27: 'Nesslau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733905.6, 1233303.6],
                [2737109.1, 1232627.2],
                [2733394, 1227416.2],
                [2727728.9, 1227612.7],
                [2727040.7, 1229074],
                [2729769.2, 1233301.7],
                [2732204.2, 1233588],
                [2732729.5, 1232095.2],
                [2733905.6, 1233303.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1470', ortbez27: 'Estavayer-le-Lac' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555321.8, 1184908.4],
                [2554370.5, 1183476.8],
                [2553184.6, 1184940],
                [2552986.7, 1187066.5],
                [2554216.8, 1187588.3],
                [2552230.1, 1191727],
                [2554088.7, 1193486.4],
                [2557359.8, 1189983.8],
                [2557644.3, 1188700.8],
                [2556473.9, 1187470.4],
                [2554659.8, 1186100.1],
                [2555321.8, 1184908.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1468', ortbez27: 'Cheyres' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546903.1, 1186684.6],
                [2549813.3, 1189439.4],
                [2551778.7, 1184452.7],
                [2550010.5, 1182823.2],
                [2546903.1, 1186684.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9651', ortbez27: 'Ennetb�hl' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2741972.6, 1235364],
                [2741286, 1232691.5],
                [2737920.9, 1231470.9],
                [2737109.1, 1232627.2],
                [2733905.6, 1233303.6],
                [2733141.5, 1235783],
                [2736038.2, 1236644.2],
                [2741972.6, 1235364]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9652', ortbez27: 'Neu St. Johann' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732204.2, 1233588],
                [2733141.5, 1235783],
                [2733905.6, 1233303.6],
                [2732729.5, 1232095.2],
                [2732204.2, 1233588]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9642', ortbez27: 'Ebnat-Kappel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727040.7, 1229074],
                [2726327.1, 1230378],
                [2723763.1, 1233390.3],
                [2723866, 1234636.6],
                [2724398.5, 1236369.2],
                [2725976.3, 1237818.1],
                [2728579.1, 1239335.1],
                [2729682.9, 1238423.2],
                [2732628.8, 1237836.8],
                [2730572.4, 1234695.9],
                [2729769.2, 1233301.7],
                [2727040.7, 1229074]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1462', ortbez27: 'Yvonand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2544502.8, 1185957.9],
                [2546903.1, 1186684.6],
                [2550010.5, 1182823.2],
                [2547711.5, 1181266],
                [2546343.8, 1180293.5],
                [2544433, 1180564.4],
                [2544051.8, 1182468.1],
                [2543153.2, 1185156.5],
                [2544502.8, 1185957.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '9643', ortbez27: 'Krummenau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736038.2, 1236644.2],
                [2733141.5, 1235783],
                [2732204.2, 1233588],
                [2729769.2, 1233301.7],
                [2730572.4, 1234695.9],
                [2732628.8, 1237836.8],
                [2733963.2, 1238244.1],
                [2736038.2, 1236644.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1450', ortbez27: 'Ste-Croix' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2528033.7, 1183821.9],
                [2525983.4, 1184133.6],
                [2527579.4, 1185086.9],
                [2528749.5, 1185612.8],
                [2527899.7, 1187636.9],
                [2529183.6, 1189720],
                [2530587.8, 1190280.4],
                [2530839.5, 1186722.2],
                [2532129, 1186913.5],
                [2533294.5, 1185319.9],
                [2531308.9, 1184663.3],
                [2529408.8, 1184281.6],
                [2528033.7, 1183821.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1453', ortbez27: 'Mauborget' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530587.8, 1190280.4],
                [2533301.5, 1192119.5],
                [2538215.9, 1193531.8],
                [2539349, 1192875.5],
                [2538850, 1189660.4],
                [2537378.9, 1189338],
                [2535625.4, 1188765.8],
                [2534461.7, 1187677.4],
                [2533294.5, 1185319.9],
                [2532129, 1186913.5],
                [2532321.9, 1188149.9],
                [2531993.6, 1189366.6],
                [2530587.8, 1190280.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1454', ortbez27: 'La Vraconnaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2527899.7, 1187636.9],
                [2528749.5, 1185612.8],
                [2527579.4, 1185086.9],
                [2525983.4, 1184133.6],
                [2524843.9, 1188613.3],
                [2529183.6, 1189720],
                [2527899.7, 1187636.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1452', ortbez27: 'Les Rasses' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2530839.5, 1186722.2],
                [2530587.8, 1190280.4],
                [2531993.6, 1189366.6],
                [2532321.9, 1188149.9],
                [2532129, 1186913.5],
                [2530839.5, 1186722.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5600', ortbez27: 'Lenzburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657545.5, 1249603],
                [2657843.7, 1247651.5],
                [2657748.8, 1245699.9],
                [2654651.2, 1246441.3],
                [2653887.9, 1248413.5],
                [2652738.6, 1249361.8],
                [2653888.3, 1250593.7],
                [2655930.4, 1251264.8],
                [2657129.6, 1250896],
                [2657545.5, 1249603]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5605', ortbez27: 'Dottikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2661147.6, 1246437.7],
                [2659669.4, 1247830.7],
                [2660085.3, 1250616.5],
                [2661319.7, 1251099.7],
                [2663005.5, 1247493.6],
                [2661147.6, 1246437.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5606', ortbez27: 'Dintikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2661147.6, 1246437.7],
                [2658501.4, 1244289.4],
                [2657748.8, 1245699.9],
                [2657843.7, 1247651.5],
                [2659669.4, 1247830.7],
                [2661147.6, 1246437.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5604', ortbez27: 'Hendschiken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657545.5, 1249603],
                [2660085.3, 1250616.5],
                [2659669.4, 1247830.7],
                [2657843.7, 1247651.5],
                [2657545.5, 1249603]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1510', ortbez27: 'Moudon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2548194.8, 1164783.3],
                [2548662.8, 1166960.1],
                [2547307.1, 1167998.2],
                [2548462.8, 1169715.3],
                [2549417.1, 1171165.5],
                [2553194.3, 1171555.5],
                [2554121.9, 1170556.4],
                [2554490, 1168768],
                [2553665.8, 1166656.4],
                [2551440.1, 1166276.6],
                [2550197.9, 1165713.7],
                [2548194.8, 1164783.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1489', ortbez27: 'Murist' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554370.5, 1183476.8],
                [2552703.9, 1181054.2],
                [2551140.6, 1181645.4],
                [2550010.5, 1182823.2],
                [2551778.7, 1184452.7],
                [2553184.6, 1184940],
                [2554370.5, 1183476.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1482', ortbez27: 'Cugy FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559426, 1186565.7],
                [2559501.5, 1184044.7],
                [2558751.6, 1182866.6],
                [2557027.9, 1182880.1],
                [2557521, 1186139.4],
                [2559426, 1186565.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1485', ortbez27: 'Nuvilly' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554370.5, 1183476.8],
                [2556178.9, 1181776.3],
                [2555218.7, 1180553.6],
                [2552703.9, 1181054.2],
                [2554370.5, 1183476.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1486', ortbez27: 'Vuissens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2550216.4, 1177731.8],
                [2550066.6, 1176377],
                [2548998.5, 1174745.2],
                [2546746.6, 1177074.4],
                [2550216.4, 1177731.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5634', ortbez27: 'Merenschwand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673925.7, 1233137.8],
                [2670881.9, 1232704.9],
                [2670545.5, 1234065.7],
                [2669461, 1237182.5],
                [2671942.9, 1238268.4],
                [2672929.1, 1235800.9],
                [2673925.7, 1233137.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1483', ortbez27: 'Vesin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557521, 1186139.4],
                [2557027.9, 1182880.1],
                [2555321.8, 1184908.4],
                [2554659.8, 1186100.1],
                [2556473.9, 1187470.4],
                [2557521, 1186139.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1484', ortbez27: 'Aumont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2556178.9, 1181776.3],
                [2554370.5, 1183476.8],
                [2555321.8, 1184908.4],
                [2557027.9, 1182880.1],
                [2556178.9, 1181776.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5632', ortbez27: 'Buttwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664503.3, 1237468.8],
                [2666300.5, 1237089.2],
                [2666908.1, 1234718.3],
                [2665789, 1233829.8],
                [2664951.9, 1235021.2],
                [2664503.3, 1237468.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1537', ortbez27: 'Champtauroz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551060.9, 1178754.8],
                [2550216.4, 1177731.8],
                [2548431.9, 1179446.4],
                [2550434.4, 1180335.4],
                [2551060.9, 1178754.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5637', ortbez27: 'Beinwil (Freiamt)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670881.9, 1232704.9],
                [2667238.1, 1229838.4],
                [2666472, 1231606.9],
                [2666988, 1232867.6],
                [2665789, 1233829.8],
                [2666908.1, 1234718.3],
                [2669154.9, 1233344.8],
                [2670545.5, 1234065.7],
                [2670881.9, 1232704.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1538', ortbez27: 'Treytorrens (Payerne)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2552703.9, 1181054.2],
                [2551060.9, 1178754.8],
                [2550434.4, 1180335.4],
                [2551140.6, 1181645.4],
                [2552703.9, 1181054.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1535', ortbez27: 'Combremont-le-Grand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2553492.7, 1178313.8],
                [2551060.9, 1178754.8],
                [2552703.9, 1181054.2],
                [2555218.7, 1180553.6],
                [2555691.4, 1179386.2],
                [2553492.7, 1178313.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1536', ortbez27: 'Combremont-le-Petit' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2553492.7, 1178313.8],
                [2552589.6, 1176120],
                [2551350.4, 1176335.6],
                [2550066.6, 1176377],
                [2550216.4, 1177731.8],
                [2551060.9, 1178754.8],
                [2553492.7, 1178313.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1541', ortbez27: 'Bussy FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559426, 1186565.7],
                [2557521, 1186139.4],
                [2556473.9, 1187470.4],
                [2557644.3, 1188700.8],
                [2558903.1, 1189440.8],
                [2560460, 1188717.9],
                [2559426, 1186565.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5626', ortbez27: 'Hermetschwil-Staffeln' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666741.4, 1243579.7],
                [2668704.4, 1244233.9],
                [2670006.3, 1242605.1],
                [2668993.8, 1241795.6],
                [2666741.4, 1243579.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5623', ortbez27: 'Boswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666300.5, 1237089.2],
                [2664503.3, 1237468.8],
                [2663799, 1238842.4],
                [2664736.1, 1239675.5],
                [2665160.9, 1240853.9],
                [2664596.5, 1241993.7],
                [2665742.2, 1242501.1],
                [2668087, 1239930.2],
                [2666300.5, 1237089.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1529', ortbez27: 'Cheiry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2552589.6, 1176120], [2553492.7, 1178313.8], [2554188.6, 1176023], [2552589.6, 1176120]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5624', ortbez27: 'B�nzen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668087, 1239930.2],
                [2665742.2, 1242501.1],
                [2666741.4, 1243579.7],
                [2668993.8, 1241795.6],
                [2668087, 1239930.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1530', ortbez27: 'Payerne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561892.1, 1181415.2],
                [2560209.1, 1182043.1],
                [2561138.5, 1183717.9],
                [2559501.5, 1184044.7],
                [2559426, 1186565.7],
                [2560460, 1188717.9],
                [2562834.8, 1190319.4],
                [2564402.4, 1186350.2],
                [2563378.1, 1185544.9],
                [2563418.2, 1182383.8],
                [2561892.1, 1181415.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5630', ortbez27: 'Muri AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2669461, 1237182.5],
                [2670545.5, 1234065.7],
                [2669154.9, 1233344.8],
                [2666908.1, 1234718.3],
                [2666300.5, 1237089.2],
                [2668087, 1239930.2],
                [2668665.7, 1238229.2],
                [2669461, 1237182.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1527', ortbez27: 'Villeneuve FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2556819.8, 1178788.5], [2558076.1, 1178224.9], [2556327.6, 1176050.2], [2556819.8, 1178788.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1528', ortbez27: 'Praratoud' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554188.6, 1176023],
                [2553492.7, 1178313.8],
                [2555691.4, 1179386.2],
                [2556819.8, 1178788.5],
                [2556327.6, 1176050.2],
                [2554188.6, 1176023]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5628', ortbez27: 'Aristau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2671942.9, 1238268.4],
                [2669461, 1237182.5],
                [2668665.7, 1238229.2],
                [2668087, 1239930.2],
                [2671864.1, 1239942.6],
                [2671942.9, 1238268.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1533', ortbez27: 'M�ni�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558751.6, 1182866.6],
                [2560209.1, 1182043.1],
                [2559532.6, 1180753.3],
                [2557218.5, 1180849.8],
                [2556178.9, 1181776.3],
                [2557027.9, 1182880.1],
                [2558751.6, 1182866.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5617', ortbez27: 'Tennwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2658525.8, 1238625.5], [2658136.9, 1240176.9], [2660019, 1239884.3], [2658525.8, 1238625.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1534', ortbez27: 'Sassel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2555218.7, 1180553.6], [2556178.9, 1181776.3], [2557218.5, 1180849.8], [2555218.7, 1180553.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5618', ortbez27: 'Bettwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663799, 1238842.4],
                [2664503.3, 1237468.8],
                [2661852.2, 1237738.1],
                [2661804.4, 1239009.8],
                [2663799, 1238842.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5615', ortbez27: 'Fahrwangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2659688.5, 1235680.7], [2658936.6, 1236986.8], [2660489.4, 1237013.9], [2659688.5, 1235680.7]]],
            [
              [
                [2660489.4, 1237013.9],
                [2660019, 1239884.3],
                [2661804.4, 1239009.8],
                [2661852.2, 1237738.1],
                [2660489.4, 1237013.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1532', ortbez27: 'F�tigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560209.1, 1182043.1],
                [2558751.6, 1182866.6],
                [2559501.5, 1184044.7],
                [2561138.5, 1183717.9],
                [2560209.1, 1182043.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5616', ortbez27: 'Meisterschwanden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2658936.6, 1236986.8],
                [2658525.8, 1238625.5],
                [2660019, 1239884.3],
                [2660489.4, 1237013.9],
                [2658936.6, 1236986.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1521', ortbez27: 'Curtilles' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2556342, 1172847.6],
                [2555304.8, 1171412.8],
                [2554121.9, 1170556.4],
                [2553194.3, 1171555.5],
                [2556342, 1172847.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5621', ortbez27: 'Zufikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670143.2, 1246536.2],
                [2670869.7, 1243942.1],
                [2670006.3, 1242605.1],
                [2668704.4, 1244233.9],
                [2668776.2, 1246245.8],
                [2670143.2, 1246536.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1522', ortbez27: 'Lucens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2553517.2, 1174597.5],
                [2554188.6, 1176023],
                [2556327.6, 1176050.2],
                [2556331.1, 1174225.5],
                [2556342, 1172847.6],
                [2553194.3, 1171555.5],
                [2551278.5, 1172713.1],
                [2553517.2, 1174597.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5622', ortbez27: 'Waltenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2664596.5, 1241993.7], [2662566.7, 1243528.4], [2665742.2, 1242501.1], [2664596.5, 1241993.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5619', ortbez27: 'B�ttikon AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664596.5, 1241993.7],
                [2665160.9, 1240853.9],
                [2664736.1, 1239675.5],
                [2662564.7, 1241476.8],
                [2660731, 1243159.4],
                [2662566.7, 1243528.4],
                [2664596.5, 1241993.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5620', ortbez27: 'Bremgarten AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668776.2, 1246245.8],
                [2668704.4, 1244233.9],
                [2666741.4, 1243579.7],
                [2664641.9, 1246157.8],
                [2667260.1, 1246662.9],
                [2668776.2, 1246245.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1525', ortbez27: 'Seigneux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558408, 1176398.6],
                [2557979.1, 1174759.3],
                [2556331.1, 1174225.5],
                [2556327.6, 1176050.2],
                [2558076.1, 1178224.9],
                [2558408, 1176398.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1526', ortbez27: 'Forel-sur-Lucens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2554188.6, 1176023], [2553517.2, 1174597.5], [2552589.6, 1176120], [2554188.6, 1176023]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5610', ortbez27: 'Wohlen AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664641.9, 1246157.8],
                [2666741.4, 1243579.7],
                [2665742.2, 1242501.1],
                [2662566.7, 1243528.4],
                [2662068.3, 1245466.3],
                [2661147.6, 1246437.7],
                [2663005.5, 1247493.6],
                [2664641.9, 1246157.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1523', ortbez27: 'Granges-pr�s-Marnand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558076.1, 1178224.9],
                [2556819.8, 1178788.5],
                [2555691.4, 1179386.2],
                [2555218.7, 1180553.6],
                [2557218.5, 1180849.8],
                [2559532.6, 1180753.3],
                [2560022.7, 1179339],
                [2558076.1, 1178224.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5607', ortbez27: 'H�gglingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2661319.7, 1251099.7],
                [2662616.6, 1251238.5],
                [2663659.5, 1248815.8],
                [2663005.5, 1247493.6],
                [2661319.7, 1251099.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5608', ortbez27: 'Stetten AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2665531.6, 1251633.4],
                [2666696.8, 1250174.1],
                [2666907.2, 1247870],
                [2665581.8, 1248029.4],
                [2664708.8, 1250363.3],
                [2665531.6, 1251633.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1513', ortbez27: 'Hermenches' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546437.9, 1163987.4],
                [2546813.5, 1165760.9],
                [2548662.8, 1166960.1],
                [2548194.8, 1164783.3],
                [2546437.9, 1163987.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5614', ortbez27: 'Sarmenstorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2661804.4, 1239009.8],
                [2660019, 1239884.3],
                [2660731, 1243159.4],
                [2662564.7, 1241476.8],
                [2664736.1, 1239675.5],
                [2663799, 1238842.4],
                [2661804.4, 1239009.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5612', ortbez27: 'Villmergen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660731, 1243159.4],
                [2658501.4, 1244289.4],
                [2661147.6, 1246437.7],
                [2662068.3, 1245466.3],
                [2662566.7, 1243528.4],
                [2660731, 1243159.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1515', ortbez27: 'Neyruz-sur-Moudon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2553194.3, 1171555.5],
                [2549417.1, 1171165.5],
                [2550007.6, 1173333.4],
                [2550066.6, 1176377],
                [2551350.4, 1176335.6],
                [2552589.6, 1176120],
                [2553517.2, 1174597.5],
                [2551278.5, 1172713.1],
                [2553194.3, 1171555.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1568', ortbez27: 'Portalban' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559570, 1198466],
                [2561212.2, 1200530.8],
                [2564490.2, 1196929.4],
                [2563929.3, 1194831.4],
                [2559570, 1198466]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1562', ortbez27: 'Corcelles-pr�s-Payerne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565728.6, 1188126.5],
                [2564402.4, 1186350.2],
                [2562834.8, 1190319.4],
                [2564294.7, 1190943.1],
                [2565728.6, 1188126.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1565', ortbez27: 'Vallon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2563749.1, 1192809.3], [2565571.7, 1191927.5], [2564294.7, 1190943.1], [2563749.1, 1192809.3]]],
            [[[2560990, 1193779.6], [2563929.3, 1194831.4], [2563749.1, 1192809.3], [2560990, 1193779.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1566', ortbez27: 'St-Aubin FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567039, 1193011.6],
                [2565571.7, 1191927.5],
                [2563749.1, 1192809.3],
                [2563929.3, 1194831.4],
                [2564490.2, 1196929.4],
                [2565705.3, 1196592.6],
                [2567039, 1193011.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1563', ortbez27: 'Dompierre FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2566928, 1188758.4],
                [2565728.6, 1188126.5],
                [2564294.7, 1190943.1],
                [2565571.7, 1191927.5],
                [2566928, 1188758.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5647', ortbez27: 'Oberr�ti' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672970.5, 1226434.2],
                [2674061.2, 1223508.2],
                [2671663.8, 1223896.1],
                [2671783.4, 1225411.3],
                [2672970.5, 1226434.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1564', ortbez27: 'Domdidier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568693.9, 1188048],
                [2566928, 1188758.4],
                [2565571.7, 1191927.5],
                [2569180.7, 1190491],
                [2568693.9, 1188048]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1553', ortbez27: 'Ch�tonnaye' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2561144.4, 1179892.7], [2563157.2, 1178930.7], [2561244.4, 1178381.9], [2561144.4, 1179892.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1554', ortbez27: 'Rossens VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562197.3, 1176151.1],
                [2560470.5, 1174768.5],
                [2560538.5, 1176725.5],
                [2562009.1, 1177390.5],
                [2562197.3, 1176151.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1551', ortbez27: 'Vers-chez-Perrin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2563378.1, 1185544.9], [2564380.2, 1184272.1], [2563418.2, 1182383.8], [2563378.1, 1185544.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1552', ortbez27: 'Trey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560022.7, 1179339],
                [2559532.6, 1180753.3],
                [2560209.1, 1182043.1],
                [2561892.1, 1181415.2],
                [2561144.4, 1179892.7],
                [2560022.7, 1179339]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5642', ortbez27: 'M�hlau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672631.8, 1230486.1],
                [2670881.9, 1232704.9],
                [2673925.7, 1233137.8],
                [2674122.6, 1231848.1],
                [2674115.2, 1230586.1],
                [2672631.8, 1230486.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1555', ortbez27: 'Villarzel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558408, 1176398.6],
                [2558076.1, 1178224.9],
                [2560022.7, 1179339],
                [2561144.4, 1179892.7],
                [2561244.4, 1178381.9],
                [2562009.1, 1177390.5],
                [2560538.5, 1176725.5],
                [2560470.5, 1174768.5],
                [2558408, 1176398.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1545', ortbez27: 'Chevroux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2556418, 1195691.5],
                [2558757.1, 1197443.9],
                [2560990, 1193779.6],
                [2559833.2, 1192448.5],
                [2556418, 1195691.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5645', ortbez27: 'Aettenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2669807, 1223427.3],
                [2670477.4, 1225102.5],
                [2670118.3, 1226593],
                [2671624.1, 1227461.8],
                [2671783.4, 1225411.3],
                [2671663.8, 1223896.1],
                [2669807, 1223427.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5646', ortbez27: 'Abtwil AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670118.3, 1226593],
                [2670477.4, 1225102.5],
                [2669807, 1223427.3],
                [2668857.4, 1224905.5],
                [2668339.2, 1226468.3],
                [2670118.3, 1226593]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1543', ortbez27: 'Grandcour' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562834.8, 1190319.4],
                [2560460, 1188717.9],
                [2558903.1, 1189440.8],
                [2558378.2, 1190716.6],
                [2559833.2, 1192448.5],
                [2560990, 1193779.6],
                [2563749.1, 1192809.3],
                [2564294.7, 1190943.1],
                [2562834.8, 1190319.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5643', ortbez27: 'Sins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667691.1, 1228474.5],
                [2670803.7, 1228442.3],
                [2672631.8, 1230486.1],
                [2672970.5, 1226434.2],
                [2671783.4, 1225411.3],
                [2671624.1, 1227461.8],
                [2670118.3, 1226593],
                [2668339.2, 1226468.3],
                [2667691.1, 1228474.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1544', ortbez27: 'Gletterens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558757.1, 1197443.9],
                [2559570, 1198466],
                [2563929.3, 1194831.4],
                [2560990, 1193779.6],
                [2558757.1, 1197443.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5644', ortbez27: 'Auw' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672631.8, 1230486.1],
                [2670803.7, 1228442.3],
                [2667691.1, 1228474.5],
                [2667238.1, 1229838.4],
                [2670881.9, 1232704.9],
                [2672631.8, 1230486.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1595', ortbez27: 'Faoug' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573030.8, 1197920.4],
                [2574224.3, 1195789],
                [2574557.6, 1194549.3],
                [2572991.9, 1194097.2],
                [2571728.1, 1193946.3],
                [2570807.5, 1194991],
                [2573030.8, 1197920.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1585', ortbez27: 'Salavaux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568000.1, 1196604.2],
                [2569256.8, 1197450.8],
                [2571158.2, 1198901.6],
                [2573030.8, 1197920.4],
                [2570807.5, 1194991],
                [2568770.9, 1194792.8],
                [2568000.1, 1196604.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1586', ortbez27: 'Vallamand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2569256.8, 1197450.8], [2569931.8, 1199427.3], [2571158.2, 1198901.6], [2569256.8, 1197450.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1583', ortbez27: 'Villarepos' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572991.9, 1194097.2],
                [2574267.1, 1192377.5],
                [2571924.1, 1191011.6],
                [2571343.5, 1192453.3],
                [2571728.1, 1193946.3],
                [2572991.9, 1194097.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1584', ortbez27: 'Villars-le-Grand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568770.9, 1194792.8],
                [2567039, 1193011.6],
                [2565705.3, 1196592.6],
                [2568000.1, 1196604.2],
                [2568770.9, 1194792.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1589', ortbez27: 'Chabrey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2564490.2, 1196929.4],
                [2561212.2, 1200530.8],
                [2563548.2, 1202051.2],
                [2566666.9, 1197884],
                [2565705.3, 1196592.6],
                [2564490.2, 1196929.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1587', ortbez27: 'Montmagny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2566666.9, 1197884], [2568000.1, 1196604.2], [2565705.3, 1196592.6], [2566666.9, 1197884]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1588', ortbez27: 'Cudrefin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569256.8, 1197450.8],
                [2568000.1, 1196604.2],
                [2566666.9, 1197884],
                [2563548.2, 1202051.2],
                [2566873.3, 1204201.9],
                [2569481.7, 1203468],
                [2571487.8, 1202441.8],
                [2571210.7, 1200262.7],
                [2569931.8, 1199427.3],
                [2569256.8, 1197450.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5727', ortbez27: 'Oberkulm' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651952.4, 1236934.3],
                [2650368.1, 1239725.5],
                [2652449.9, 1241307.5],
                [2654851.8, 1239142.1],
                [2653023, 1237894.9],
                [2651952.4, 1236934.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1580', ortbez27: 'Avenches' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570454.6, 1189487.1],
                [2568693.9, 1188048],
                [2569180.7, 1190491],
                [2565571.7, 1191927.5],
                [2567039, 1193011.6],
                [2568770.9, 1194792.8],
                [2570807.5, 1194991],
                [2571728.1, 1193946.3],
                [2571343.5, 1192453.3],
                [2571924.1, 1191011.6],
                [2570454.6, 1189487.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5728', ortbez27: 'Gontenschwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653023, 1237894.9],
                [2655052.2, 1235741.7],
                [2653379.2, 1233318.8],
                [2651548.9, 1235175.8],
                [2651952.4, 1236934.3],
                [2653023, 1237894.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1634', ortbez27: 'La Roche FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580586.6, 1169593.4],
                [2578068.3, 1167590.9],
                [2575497.1, 1170782.9],
                [2575247.4, 1174563.6],
                [2580841.2, 1173959.9],
                [2581839.7, 1172974.4],
                [2580586.6, 1169593.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5734', ortbez27: 'Reinach AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653379.2, 1233318.8],
                [2655052.2, 1235741.7],
                [2655730.4, 1236986.5],
                [2657208.2, 1236924.5],
                [2657367.8, 1235282.2],
                [2658223.9, 1233643],
                [2656223.4, 1233148.7],
                [2654390.9, 1232454.2],
                [2653379.2, 1233318.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1632', ortbez27: 'Riaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572911.7, 1164933.4],
                [2568871.7, 1165038.1],
                [2567336.2, 1167016.5],
                [2567482.1, 1168290.4],
                [2571578.2, 1167681.4],
                [2572911.7, 1164933.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5732', ortbez27: 'Zetzwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656208.2, 1238837.6],
                [2655730.4, 1236986.5],
                [2655052.2, 1235741.7],
                [2653023, 1237894.9],
                [2654851.8, 1239142.1],
                [2656208.2, 1238837.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1637', ortbez27: 'Charmey (Gruy�re)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585173.6, 1167150],
                [2585680.8, 1163627.7],
                [2581615.3, 1159425.1],
                [2586356, 1157059.8],
                [2584613.3, 1155995.9],
                [2582349.6, 1153679.9],
                [2581167, 1155201.2],
                [2577797.9, 1153201.1],
                [2576169.4, 1155192.2],
                [2576418.5, 1159451.3],
                [2578298, 1161294.6],
                [2577162.6, 1162458.2],
                [2578199.8, 1164012.7],
                [2579079.9, 1164944.5],
                [2580361, 1166001.3],
                [2581790.3, 1166767.3],
                [2583077.1, 1166718.7],
                [2585173.6, 1167150]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1638', ortbez27: 'Morlon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576158.1, 1164076.1],
                [2574346.4, 1162519.5],
                [2572361.5, 1163476.4],
                [2572911.7, 1164933.4],
                [2576158.1, 1164076.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5722', ortbez27: 'Gr�nichen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651101.3, 1247865],
                [2653437.1, 1245692.6],
                [2653426.2, 1242984.6],
                [2650288, 1242895],
                [2648978.7, 1241597.8],
                [2647873.4, 1244579.4],
                [2649479.1, 1246771.6],
                [2651101.3, 1247865]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1635', ortbez27: 'La Tour-de-Tr�me' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2569098.6, 1161354.1], [2568086.9, 1158474], [2567610.6, 1161154.6], [2569098.6, 1161354.1]]],
            [
              [
                [2572899.3, 1161363.1],
                [2571808, 1159994.6],
                [2569098.6, 1161354.1],
                [2572361.5, 1163476.4],
                [2574346.4, 1162519.5],
                [2572899.3, 1161363.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1636', ortbez27: 'Broc' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2574346.4, 1162519.5],
                [2577162.6, 1162458.2],
                [2578298, 1161294.6],
                [2576418.5, 1159451.3],
                [2572899.3, 1161363.1],
                [2574346.4, 1162519.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1625', ortbez27: 'Maules' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562014.8, 1163134.1],
                [2562325.5, 1165427.1],
                [2562409.7, 1167068.2],
                [2563810.7, 1166624.2],
                [2565001.8, 1165996.6],
                [2567336.2, 1167016.5],
                [2565458.3, 1164408.1],
                [2562014.8, 1163134.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5725', ortbez27: 'Leutwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2655767.2, 1241281.3], [2656208.2, 1238837.6], [2654851.8, 1239142.1], [2655767.2, 1241281.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1626', ortbez27: 'Romanens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567336.2, 1167016.5],
                [2565001.8, 1165996.6],
                [2563810.7, 1166624.2],
                [2562409.7, 1167068.2],
                [2563705.9, 1167885.3],
                [2564855, 1168409.8],
                [2567482.1, 1168290.4],
                [2567336.2, 1167016.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5726', ortbez27: 'Unterkulm' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2648978.7, 1241597.8],
                [2650288, 1242895],
                [2652449.9, 1241307.5],
                [2650368.1, 1239725.5],
                [2648737.9, 1239464.5],
                [2648978.7, 1241597.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1623', ortbez27: 'Semsales' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560199.3, 1159297.2],
                [2564828, 1159520.8],
                [2564074, 1156348.8],
                [2567447.4, 1155198.3],
                [2566492.5, 1153877.6],
                [2558923, 1156936.3],
                [2559068.1, 1158275.9],
                [2560199.3, 1159297.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1624', ortbez27: 'Grattavache' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559141.5, 1160636],
                [2562570.6, 1162008.9],
                [2564828, 1159520.8],
                [2560199.3, 1159297.2],
                [2559141.5, 1160636]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5723', ortbez27: 'Teufenthal AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2650288, 1242895], [2653426.2, 1242984.6], [2652449.9, 1241307.5], [2650288, 1242895]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5724', ortbez27: 'D�rren�sch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2654851.8, 1239142.1],
                [2652449.9, 1241307.5],
                [2653426.2, 1242984.6],
                [2654943.2, 1242511.3],
                [2655767.2, 1241281.3],
                [2654851.8, 1239142.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1630', ortbez27: 'Bulle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567610.6, 1161154.6],
                [2568086.9, 1158474],
                [2567447.4, 1155198.3],
                [2564074, 1156348.8],
                [2564828, 1159520.8],
                [2566247.5, 1160293.7],
                [2569226.7, 1163425.6],
                [2568871.7, 1165038.1],
                [2572911.7, 1164933.4],
                [2572361.5, 1163476.4],
                [2569098.6, 1161354.1],
                [2567610.6, 1161154.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1627', ortbez27: 'Vaulruz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565458.3, 1164408.1],
                [2567336.2, 1167016.5],
                [2568871.7, 1165038.1],
                [2569226.7, 1163425.6],
                [2566247.5, 1160293.7],
                [2564828, 1159520.8],
                [2562570.6, 1162008.9],
                [2562014.8, 1163134.1],
                [2565458.3, 1164408.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5712', ortbez27: 'Beinwil am See' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657208.2, 1236924.5],
                [2658936.6, 1236986.8],
                [2659688.5, 1235680.7],
                [2658223.9, 1233643],
                [2657367.8, 1235282.2],
                [2657208.2, 1236924.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1617', ortbez27: 'Remaufens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2556318.1, 1154774.3],
                [2558923, 1156936.3],
                [2557168, 1151681.1],
                [2555751.5, 1153572],
                [2556318.1, 1154774.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1618', ortbez27: 'Ch�tel-St-Denis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558923, 1156936.3],
                [2566492.5, 1153877.6],
                [2566407.9, 1152458.9],
                [2560592.4, 1153293.5],
                [2561290, 1150457.4],
                [2558396.4, 1151001.1],
                [2557168, 1151681.1],
                [2558923, 1156936.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1615', ortbez27: 'Bossonnens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555751.5, 1153572],
                [2554180.1, 1152258],
                [2554574.6, 1155180.6],
                [2556318.1, 1154774.3],
                [2555751.5, 1153572]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1616', ortbez27: 'Attalens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557168, 1151681.1],
                [2555512.8, 1149408.9],
                [2554015.1, 1149569.9],
                [2552918.8, 1151520.9],
                [2554180.1, 1152258],
                [2555751.5, 1153572],
                [2557168, 1151681.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5706', ortbez27: 'Boniswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2658136.9, 1240176.9], [2656208.2, 1238837.6], [2655767.2, 1241281.3], [2658136.9, 1240176.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1619', ortbez27: 'Les Paccots' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2566407.9, 1152458.9],
                [2566630.4, 1150941.4],
                [2565798.1, 1149209.8],
                [2564703.3, 1146498],
                [2563488, 1149753.3],
                [2561290, 1150457.4],
                [2560592.4, 1153293.5],
                [2566407.9, 1152458.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5703', ortbez27: 'Seon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2654651.2, 1246441.3],
                [2656287, 1243464.8],
                [2654943.2, 1242511.3],
                [2653426.2, 1242984.6],
                [2653437.1, 1245692.6],
                [2654651.2, 1246441.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5704', ortbez27: 'Egliswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656287, 1243464.8],
                [2654651.2, 1246441.3],
                [2657748.8, 1245699.9],
                [2658501.4, 1244289.4],
                [2656287, 1243464.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1609', ortbez27: 'St-Martin FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559068.1, 1158275.9],
                [2558923, 1156936.3],
                [2556016.3, 1157406.7],
                [2556003.2, 1158849.4],
                [2556332.8, 1160401.6],
                [2557964.5, 1161325.1],
                [2559141.5, 1160636],
                [2560199.3, 1159297.2],
                [2559068.1, 1158275.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1610', ortbez27: 'Oron-la-Ville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551332.1, 1155535.7],
                [2550376.7, 1156432.2],
                [2551057.8, 1158431],
                [2552358.3, 1158245.9],
                [2552200.7, 1159710.4],
                [2554231.7, 1159068.4],
                [2554722.5, 1157389.4],
                [2552578.8, 1156958.1],
                [2551332.1, 1155535.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1607', ortbez27: 'Pal�zieux-Village' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2552911.8, 1154752.5],
                [2552008.2, 1153076.8],
                [2551332.1, 1155535.7],
                [2552578.8, 1156958.1],
                [2554722.5, 1157389.4],
                [2554574.6, 1155180.6],
                [2552911.8, 1154752.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5707', ortbez27: 'Seengen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2658501.4, 1244289.4],
                [2660731, 1243159.4],
                [2660019, 1239884.3],
                [2658136.9, 1240176.9],
                [2655767.2, 1241281.3],
                [2654943.2, 1242511.3],
                [2656287, 1243464.8],
                [2658501.4, 1244289.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1608', ortbez27: 'Chapelle (Gl�ne)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554722.5, 1157389.4],
                [2554231.7, 1159068.4],
                [2556003.2, 1158849.4],
                [2556016.3, 1157406.7],
                [2554722.5, 1157389.4]
              ]
            ],
            [[[2555275.3, 1161387], [2554231.7, 1159068.4], [2553641.4, 1160942.5], [2555275.3, 1161387]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5708', ortbez27: 'Birrwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2658525.8, 1238625.5],
                [2658936.6, 1236986.8],
                [2657208.2, 1236924.5],
                [2655730.4, 1236986.5],
                [2656208.2, 1238837.6],
                [2658136.9, 1240176.9],
                [2658525.8, 1238625.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1613', ortbez27: 'Maracon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2556318.1, 1154774.3], [2556016.3, 1157406.7], [2558923, 1156936.3], [2556318.1, 1154774.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1614', ortbez27: 'Granges (Veveyse)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2552918.8, 1151520.9],
                [2552008.2, 1153076.8],
                [2552911.8, 1154752.5],
                [2554574.6, 1155180.6],
                [2554180.1, 1152258],
                [2552918.8, 1151520.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1611', ortbez27: 'Le Cr�t-pr�s-Semsales' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557964.5, 1161325.1],
                [2559360.8, 1162941.4],
                [2562014.8, 1163134.1],
                [2562570.6, 1162008.9],
                [2559141.5, 1160636],
                [2557964.5, 1161325.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1612', ortbez27: 'Ecoteaux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554574.6, 1155180.6],
                [2554722.5, 1157389.4],
                [2556016.3, 1157406.7],
                [2556318.1, 1154774.3],
                [2554574.6, 1155180.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1665', ortbez27: 'Estavannens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576418.5, 1159451.3],
                [2576169.4, 1155192.2],
                [2572558.6, 1155645.9],
                [2573425.5, 1158262.5],
                [2573223.1, 1159514.3],
                [2576418.5, 1159451.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1666', ortbez27: 'Grandvillard' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576864.5, 1151037.1],
                [2574813.2, 1149418.6],
                [2571416.9, 1151730.9],
                [2571636.2, 1153130.6],
                [2569044.8, 1154945.1],
                [2570228.9, 1155896.6],
                [2572558.6, 1155645.9],
                [2576169.4, 1155192.2],
                [2577797.9, 1153201.1],
                [2576864.5, 1151037.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1663', ortbez27: 'Epagny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567447.4, 1155198.3],
                [2568086.9, 1158474],
                [2569466.2, 1158563.1],
                [2571808, 1159994.6],
                [2572899.3, 1161363.1],
                [2576418.5, 1159451.3],
                [2573223.1, 1159514.3],
                [2573425.5, 1158262.5],
                [2570372.4, 1157507.9],
                [2570228.9, 1155896.6],
                [2569044.8, 1154945.1],
                [2567447.4, 1155198.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1669', ortbez27: 'Montbovon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568555.3, 1146372.5],
                [2567685.5, 1144292.3],
                [2566144.3, 1143495.8],
                [2564741.5, 1142795.1],
                [2563711.4, 1144877],
                [2564703.3, 1146498],
                [2565798.1, 1149209.8],
                [2566630.4, 1150941.4],
                [2566407.9, 1152458.9],
                [2566492.5, 1153877.6],
                [2567447.4, 1155198.3],
                [2569044.8, 1154945.1],
                [2571636.2, 1153130.6],
                [2571416.9, 1151730.9],
                [2574813.2, 1149418.6],
                [2571444.3, 1148845],
                [2570144.5, 1147897.9],
                [2568555.3, 1146372.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1670', ortbez27: 'Ursy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2556091.6, 1165817.1],
                [2554172.1, 1164358.7],
                [2551803, 1164519.9],
                [2553665.8, 1166656.4],
                [2555280.1, 1167446.5],
                [2556091.6, 1165817.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1667', ortbez27: 'Enney' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570372.4, 1157507.9],
                [2573425.5, 1158262.5],
                [2572558.6, 1155645.9],
                [2570228.9, 1155896.6],
                [2570372.4, 1157507.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1657', ortbez27: 'Abl�ndschen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587625.1, 1155436.8],
                [2584613.3, 1155995.9],
                [2586356, 1157059.8],
                [2586989.4, 1158169.2],
                [2588193.3, 1159321.9],
                [2589391.4, 1158828.1],
                [2587625.1, 1155436.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1658', ortbez27: 'Rossini�re' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567685.5, 1144292.3],
                [2568555.3, 1146372.5],
                [2570144.5, 1147897.9],
                [2571444.3, 1148845],
                [2574813.2, 1149418.6],
                [2574732.1, 1146548.4],
                [2571962.5, 1144592.3],
                [2567685.5, 1144292.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1656', ortbez27: 'Jaun' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586989.4, 1158169.2],
                [2586356, 1157059.8],
                [2581615.3, 1159425.1],
                [2585680.8, 1163627.7],
                [2590386.6, 1163822.6],
                [2590691.6, 1162162.4],
                [2590872.6, 1160559.2],
                [2589391.4, 1158828.1],
                [2588193.3, 1159321.9],
                [2586989.4, 1158169.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1661', ortbez27: 'Le P�quier-Montbarry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571808, 1159994.6],
                [2569466.2, 1158563.1],
                [2568086.9, 1158474],
                [2569098.6, 1161354.1],
                [2571808, 1159994.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5745', ortbez27: 'Safenwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642638.6, 1243010.2],
                [2644196.3, 1240924.4],
                [2640747.6, 1239948.7],
                [2639771.2, 1241409.9],
                [2642638.6, 1243010.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1659', ortbez27: 'Flendruz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582349.6, 1153679.9],
                [2584613.3, 1155995.9],
                [2585221.5, 1150247],
                [2583665.4, 1143822.3],
                [2581343.3, 1143421.7],
                [2579952, 1147369.5],
                [2576864.5, 1151037.1],
                [2577797.9, 1153201.1],
                [2581167, 1155201.2],
                [2582349.6, 1153679.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5746', ortbez27: 'Walterswil SO' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639706.3, 1243507.7],
                [2642638.6, 1243010.2],
                [2639771.2, 1241409.9],
                [2636322.1, 1242979.4],
                [2639706.3, 1243507.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1660', ortbez27: "Ch�teau-d'Oex" },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2577000.4, 1135761.9],
                [2575153.8, 1140206.7],
                [2573829.7, 1140768.1],
                [2572841.5, 1139988.1],
                [2572022.1, 1139001.2],
                [2571036.9, 1137970.8],
                [2568368.7, 1140624.6],
                [2567685.5, 1144292.3],
                [2571962.5, 1144592.3],
                [2574732.1, 1146548.4],
                [2574813.2, 1149418.6],
                [2576864.5, 1151037.1],
                [2579952, 1147369.5],
                [2581343.3, 1143421.7],
                [2582479.9, 1140703.5],
                [2581226.1, 1136564.3],
                [2577000.4, 1135761.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1649', ortbez27: 'Pont-la-Ville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573796.6, 1171684.4],
                [2573015.6, 1173442.8],
                [2575247.4, 1174563.6],
                [2575497.1, 1170782.9],
                [2573796.6, 1171684.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1647', ortbez27: 'Corbi�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573652.7, 1169383],
                [2578068.3, 1167590.9],
                [2577674, 1166330.8],
                [2573275.8, 1167924.9],
                [2573652.7, 1169383]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1648', ortbez27: 'Hauteville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2575497.1, 1170782.9], [2578068.3, 1167590.9], [2573652.7, 1169383], [2575497.1, 1170782.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1653', ortbez27: 'Ch�tel-sur-Montsalvens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2577162.6, 1162458.2],
                [2574346.4, 1162519.5],
                [2576158.1, 1164076.1],
                [2576850.3, 1165273.8],
                [2578199.8, 1164012.7],
                [2577162.6, 1162458.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5737', ortbez27: 'Menziken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2658223.9, 1233643], [2657166, 1230846.2], [2656223.4, 1233148.7], [2658223.9, 1233643]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1654', ortbez27: 'Cerniat FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580586.6, 1169593.4],
                [2585173.6, 1167150],
                [2583077.1, 1166718.7],
                [2581790.3, 1166767.3],
                [2580361, 1166001.3],
                [2579079.9, 1164944.5],
                [2578199.8, 1164012.7],
                [2576850.3, 1165273.8],
                [2577674, 1166330.8],
                [2578068.3, 1167590.9],
                [2580586.6, 1169593.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1651', ortbez27: 'Villarvolard' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572911.7, 1164933.4],
                [2573275.8, 1167924.9],
                [2577674, 1166330.8],
                [2576850.3, 1165273.8],
                [2576158.1, 1164076.1],
                [2572911.7, 1164933.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5736', ortbez27: 'Burg AG' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655926.8, 1230520.7],
                [2654390.9, 1232454.2],
                [2656223.4, 1233148.7],
                [2657166, 1230846.2],
                [2655926.8, 1230520.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1642', ortbez27: 'Sorens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569624.1, 1170579.1],
                [2571578.2, 1167681.4],
                [2567482.1, 1168290.4],
                [2567373.5, 1170124.6],
                [2569624.1, 1170579.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '5742', ortbez27: 'K�lliken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643387.6, 1244639.3],
                [2645956.5, 1244097.1],
                [2645702.6, 1242472.1],
                [2645794.3, 1241180.2],
                [2644196.3, 1240924.4],
                [2642638.6, 1243010.2],
                [2643387.6, 1244639.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1645', ortbez27: 'Le Bry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2569423.2, 1171888.2], [2573015.6, 1173442.8], [2573796.6, 1171684.4], [2569423.2, 1171888.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1646', ortbez27: 'Echarlens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2573275.8, 1167924.9], [2572911.7, 1164933.4], [2571578.2, 1167681.4], [2573275.8, 1167924.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1643', ortbez27: 'Gumefens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571578.2, 1167681.4],
                [2569624.1, 1170579.1],
                [2573652.7, 1169383],
                [2573275.8, 1167924.9],
                [2571578.2, 1167681.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1644', ortbez27: 'Avry-devant-Pont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573652.7, 1169383],
                [2569624.1, 1170579.1],
                [2569423.2, 1171888.2],
                [2573796.6, 1171684.4],
                [2575497.1, 1170782.9],
                [2573652.7, 1169383]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1697', ortbez27: 'La Joux FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562325.5, 1165427.1],
                [2562014.8, 1163134.1],
                [2559360.8, 1162941.4],
                [2559114.7, 1164173.1],
                [2560895.7, 1164967.6],
                [2562325.5, 1165427.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1695', ortbez27: 'Estavayer-le-Gibloux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568608.6, 1173881.8],
                [2569423.2, 1171888.2],
                [2567318.5, 1172807.6],
                [2567321.4, 1174491.8],
                [2567044.7, 1175965.6],
                [2569898.4, 1174799],
                [2568608.6, 1173881.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1696', ortbez27: 'Vuisternens-en-Ogoz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573015.6, 1173442.8],
                [2569423.2, 1171888.2],
                [2568608.6, 1173881.8],
                [2569898.4, 1174799],
                [2571082.5, 1175232.9],
                [2573015.6, 1173442.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1699', ortbez27: 'Bouloz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554231.7, 1159068.4],
                [2555275.3, 1161387],
                [2556706.4, 1162463.3],
                [2556152.3, 1163874.2],
                [2557583.7, 1164197],
                [2558111.8, 1165333],
                [2559114.7, 1164173.1],
                [2559360.8, 1162941.4],
                [2557964.5, 1161325.1],
                [2556332.8, 1160401.6],
                [2556003.2, 1158849.4],
                [2554231.7, 1159068.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1700', ortbez27: 'Fribourg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579660, 1186007.7],
                [2581370, 1185028.1],
                [2580005.5, 1182703.7],
                [2578894.4, 1181764.8],
                [2577674.9, 1182297.1],
                [2577061.4, 1184383.9],
                [2579660, 1186007.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1689', ortbez27: 'Le Ch�telard-pr�s-Romont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563684.7, 1171216.7],
                [2566068.6, 1171106.9],
                [2567373.5, 1170124.6],
                [2567482.1, 1168290.4],
                [2564855, 1168409.8],
                [2563684.7, 1171216.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1690', ortbez27: 'Villaz-St-Pierre' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563185.4, 1173417.8],
                [2560470.5, 1174768.5],
                [2562197.3, 1176151.1],
                [2565784.4, 1176442.2],
                [2564871.6, 1174301.2],
                [2563185.4, 1173417.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1687', ortbez27: 'Vuisternens-devant-Romont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560843.6, 1168662.2],
                [2562409.7, 1167068.2],
                [2562325.5, 1165427.1],
                [2560895.7, 1164967.6],
                [2560529.9, 1166168.6],
                [2560843.6, 1168662.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1688', ortbez27: 'Sommentier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559153.2, 1166309.6],
                [2560529.9, 1166168.6],
                [2560895.7, 1164967.6],
                [2559114.7, 1164173.1],
                [2558111.8, 1165333],
                [2559153.2, 1166309.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1694', ortbez27: 'Villargiroud' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567373.5, 1170124.6],
                [2566068.6, 1171106.9],
                [2565512.7, 1172390.4],
                [2564871.6, 1174301.2],
                [2565784.4, 1176442.2],
                [2567044.7, 1175965.6],
                [2567321.4, 1174491.8],
                [2567318.5, 1172807.6],
                [2569423.2, 1171888.2],
                [2569624.1, 1170579.1],
                [2567373.5, 1170124.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1691', ortbez27: 'Villarimboud' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565784.4, 1176442.2],
                [2562197.3, 1176151.1],
                [2562009.1, 1177390.5],
                [2561244.4, 1178381.9],
                [2563157.2, 1178930.7],
                [2564903.1, 1178384.4],
                [2565784.4, 1176442.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1692', ortbez27: 'Massonnens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563185.4, 1173417.8],
                [2564871.6, 1174301.2],
                [2565512.7, 1172390.4],
                [2566068.6, 1171106.9],
                [2563684.7, 1171216.7],
                [2563185.4, 1173417.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1681', ortbez27: 'Billens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557411.7, 1171277.1],
                [2559255.6, 1170994.2],
                [2559631.1, 1169561.6],
                [2558405, 1169995.4],
                [2556147.8, 1169767],
                [2557411.7, 1171277.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1682', ortbez27: 'Lovatens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557411.7, 1171277.1],
                [2556147.8, 1169767],
                [2555304.8, 1171412.8],
                [2556342, 1172847.6],
                [2556331.1, 1174225.5],
                [2557979.1, 1174759.3],
                [2558408, 1176398.6],
                [2560470.5, 1174768.5],
                [2558891.1, 1172197.9],
                [2559255.6, 1170994.2],
                [2557411.7, 1171277.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1679', ortbez27: 'Villaraboud' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559153.2, 1166309.6],
                [2558855.1, 1168512.6],
                [2559631.1, 1169561.6],
                [2560843.6, 1168662.2],
                [2560529.9, 1166168.6],
                [2559153.2, 1166309.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1680', ortbez27: 'Romont FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2559631.1, 1169561.6], [2558855.1, 1168512.6], [2558405, 1169995.4], [2559631.1, 1169561.6]]],
            [
              [
                [2562970.7, 1169738.1],
                [2561522.7, 1171265.3],
                [2559631.1, 1169561.6],
                [2559255.6, 1170994.2],
                [2558891.1, 1172197.9],
                [2560470.5, 1174768.5],
                [2563185.4, 1173417.8],
                [2563684.7, 1171216.7],
                [2562970.7, 1169738.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1685', ortbez27: 'Villariaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562409.7, 1167068.2],
                [2560843.6, 1168662.2],
                [2562970.7, 1169738.1],
                [2563705.9, 1167885.3],
                [2562409.7, 1167068.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1686', ortbez27: 'Grangettes-pr�s-Romont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562970.7, 1169738.1],
                [2563684.7, 1171216.7],
                [2564855, 1168409.8],
                [2563705.9, 1167885.3],
                [2562970.7, 1169738.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1683', ortbez27: 'Sarzens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555304.8, 1171412.8],
                [2556147.8, 1169767],
                [2554490, 1168768],
                [2554121.9, 1170556.4],
                [2555304.8, 1171412.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1684', ortbez27: 'M�zi�res FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559631.1, 1169561.6],
                [2561522.7, 1171265.3],
                [2562970.7, 1169738.1],
                [2560843.6, 1168662.2],
                [2559631.1, 1169561.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1673', ortbez27: 'Promasens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551803, 1164519.9],
                [2554172.1, 1164358.7],
                [2555094.2, 1162955.4],
                [2552378.5, 1162733.7],
                [2551803, 1164519.9]
              ]
            ],
            [[[2552200.7, 1159710.4], [2552358.3, 1158245.9], [2551057.8, 1158431], [2552200.7, 1159710.4]]],
            [
              [
                [2553641.4, 1160942.5],
                [2554231.7, 1159068.4],
                [2552200.7, 1159710.4],
                [2551209, 1160495.9],
                [2552378.5, 1162733.7],
                [2553641.4, 1160942.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1674', ortbez27: 'Montet (Gl�ne)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2551803, 1164519.9], [2551440.1, 1166276.6], [2553665.8, 1166656.4], [2551803, 1164519.9]]],
            [[[2553665.8, 1166656.4], [2554490, 1168768], [2555280.1, 1167446.5], [2553665.8, 1166656.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1677', ortbez27: 'Prez-vers-Siviriez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2556091.6, 1165817.1],
                [2557464.6, 1166695.8],
                [2558111.8, 1165333],
                [2557583.7, 1164197],
                [2556152.3, 1163874.2],
                [2556091.6, 1165817.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1678', ortbez27: 'Siviriez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558855.1, 1168512.6],
                [2557464.6, 1166695.8],
                [2556091.6, 1165817.1],
                [2555280.1, 1167446.5],
                [2554490, 1168768],
                [2556147.8, 1169767],
                [2558405, 1169995.4],
                [2558855.1, 1168512.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1675', ortbez27: 'Mossel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555275.3, 1161387],
                [2553641.4, 1160942.5],
                [2552378.5, 1162733.7],
                [2555094.2, 1162955.4],
                [2554172.1, 1164358.7],
                [2556091.6, 1165817.1],
                [2556152.3, 1163874.2],
                [2556706.4, 1162463.3],
                [2555275.3, 1161387]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1676', ortbez27: 'Chavannes-les-Forts' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557464.6, 1166695.8],
                [2558855.1, 1168512.6],
                [2559153.2, 1166309.6],
                [2558111.8, 1165333],
                [2557464.6, 1166695.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1730', ortbez27: 'Ecuvillens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573137.3, 1177280.1],
                [2571878.3, 1177099.7],
                [2571298.6, 1178780.4],
                [2572518.2, 1179814.1],
                [2573882.1, 1178511.4],
                [2573137.3, 1177280.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1727', ortbez27: 'Corpataux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573137.3, 1177280.1],
                [2573882.1, 1178511.4],
                [2575088.7, 1176460.6],
                [2573058.1, 1175055],
                [2573137.3, 1177280.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1728', ortbez27: 'Rossens FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573015.6, 1173442.8],
                [2573058.1, 1175055],
                [2575088.7, 1176460.6],
                [2575247.4, 1174563.6],
                [2573015.6, 1173442.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1733', ortbez27: 'Treyvaux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575247.4, 1174563.6],
                [2575088.7, 1176460.6],
                [2576820.2, 1176647.1],
                [2578264.4, 1177063.9],
                [2580379.9, 1176712.6],
                [2580459.8, 1175172.7],
                [2580841.2, 1173959.9],
                [2575247.4, 1174563.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1734', ortbez27: 'Tentlingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581829.1, 1181066.2],
                [2583587.9, 1180155.5],
                [2581302.7, 1178131.5],
                [2580692.1, 1179320],
                [2581829.1, 1181066.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1731', ortbez27: 'Ependes FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579377.3, 1178229.2],
                [2578264.4, 1177063.9],
                [2576820.2, 1176647.1],
                [2576514.2, 1179241.1],
                [2578938.2, 1179625.1],
                [2579377.3, 1178229.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1732', ortbez27: 'Arconciel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575088.7, 1176460.6],
                [2573882.1, 1178511.4],
                [2576514.2, 1179241.1],
                [2576820.2, 1176647.1],
                [2575088.7, 1176460.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1721', ortbez27: 'Misery' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572259.8, 1188117.4],
                [2570454.6, 1189487.1],
                [2571924.1, 1191011.6],
                [2574267.1, 1192377.5],
                [2574748, 1190886.3],
                [2573507.1, 1189943.6],
                [2574513.2, 1188410.6],
                [2572259.8, 1188117.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1719', ortbez27: 'Br�nisried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585747.5, 1177909.2],
                [2587273.8, 1179859.9],
                [2588866, 1179335],
                [2589724.4, 1178210],
                [2588300.8, 1177663.9],
                [2585747.5, 1177909.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1720', ortbez27: 'Corminboeuf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2575153.5, 1185128.1], [2575313.3, 1183748.9], [2573284.6, 1184828.1], [2575153.5, 1185128.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1725', ortbez27: 'Posieux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576514.2, 1179241.1],
                [2573882.1, 1178511.4],
                [2572518.2, 1179814.1],
                [2574811.1, 1181961.8],
                [2576484.8, 1181844.8],
                [2576514.2, 1179241.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1726', ortbez27: 'Farvagny-le-Petit' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573058.1, 1175055],
                [2573015.6, 1173442.8],
                [2571082.5, 1175232.9],
                [2570568.2, 1176535.2],
                [2571878.3, 1177099.7],
                [2573137.3, 1177280.1],
                [2573058.1, 1175055]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1723', ortbez27: 'Marly' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579377.3, 1178229.2],
                [2578938.2, 1179625.1],
                [2576514.2, 1179241.1],
                [2576484.8, 1181844.8],
                [2577674.9, 1182297.1],
                [2578894.4, 1181764.8],
                [2580005.5, 1182703.7],
                [2581829.1, 1181066.2],
                [2580692.1, 1179320],
                [2579377.3, 1178229.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1724', ortbez27: 'Ferpicloz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581839.7, 1172974.4],
                [2580841.2, 1173959.9],
                [2580459.8, 1175172.7],
                [2580379.9, 1176712.6],
                [2578264.4, 1177063.9],
                [2579377.3, 1178229.2],
                [2580692.1, 1179320],
                [2581302.7, 1178131.5],
                [2581452.3, 1175935.3],
                [2582090.4, 1174858.8],
                [2583140.9, 1174179.4],
                [2581985.3, 1174069.1],
                [2581839.7, 1172974.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1713', ortbez27: 'St. Antoni' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585580.5, 1186411.7],
                [2587267.3, 1188087.1],
                [2588655, 1183897.5],
                [2585219.3, 1184249.8],
                [2585580.5, 1186411.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1714', ortbez27: 'Heitenried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588655, 1183897.5],
                [2587267.3, 1188087.1],
                [2588116.4, 1189486.7],
                [2589662.4, 1189143.6],
                [2592412.2, 1188852.5],
                [2592320.9, 1187423.1],
                [2589751.8, 1182106.6],
                [2588655, 1183897.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1712', ortbez27: 'Tafers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585580.5, 1186411.7],
                [2585219.3, 1184249.8],
                [2585384.3, 1182408.4],
                [2584012.8, 1182318],
                [2581370, 1185028.1],
                [2584316.8, 1186558.8],
                [2585580.5, 1186411.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1717', ortbez27: 'St. Ursen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586908.8, 1181059.1],
                [2583587.9, 1180155.5],
                [2581829.1, 1181066.2],
                [2580005.5, 1182703.7],
                [2581370, 1185028.1],
                [2584012.8, 1182318],
                [2585384.3, 1182408.4],
                [2586630.8, 1182280.4],
                [2586908.8, 1181059.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1718', ortbez27: 'Rechthalten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587273.8, 1179859.9],
                [2585747.5, 1177909.2],
                [2583587.9, 1180155.5],
                [2586908.8, 1181059.1],
                [2587273.8, 1179859.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1715', ortbez27: 'Alterswil FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585219.3, 1184249.8],
                [2588655, 1183897.5],
                [2589751.8, 1182106.6],
                [2589298.6, 1180861.5],
                [2588866, 1179335],
                [2587273.8, 1179859.9],
                [2586908.8, 1181059.1],
                [2586630.8, 1182280.4],
                [2585384.3, 1182408.4],
                [2585219.3, 1184249.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1716', ortbez27: 'Oberschrot' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585680.8, 1163627.7],
                [2585173.6, 1167150],
                [2585898.6, 1169151],
                [2586980.9, 1173864.9],
                [2585747.5, 1177909.2],
                [2588300.8, 1177663.9],
                [2589724.4, 1178210],
                [2590929.5, 1175787.1],
                [2589819.4, 1174376.8],
                [2591776.5, 1172297.3],
                [2591844.7, 1167258],
                [2591734.7, 1165755.8],
                [2590506.6, 1165167.7],
                [2590386.6, 1163822.6],
                [2585680.8, 1163627.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1762', ortbez27: 'Givisiez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2575313.3, 1183748.9], [2575153.5, 1185128.1], [2577061.4, 1184383.9], [2575313.3, 1183748.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1763', ortbez27: 'Granges-Paccot' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576485.8, 1187506.1],
                [2577833.1, 1187047.4],
                [2579660, 1186007.7],
                [2577061.4, 1184383.9],
                [2575153.5, 1185128.1],
                [2575282.7, 1186418.5],
                [2576485.8, 1187506.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1753', ortbez27: 'Matran' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572518.2, 1179814.1],
                [2572166.3, 1181562],
                [2572822.9, 1183611.1],
                [2573284.6, 1184828.1],
                [2575313.3, 1183748.9],
                [2574811.1, 1181961.8],
                [2572518.2, 1179814.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1754', ortbez27: 'Avry-sur-Matran' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572166.3, 1181562],
                [2571018.8, 1180839.5],
                [2569533.3, 1181451.2],
                [2570328.8, 1182736.1],
                [2571635.5, 1184298.2],
                [2572822.9, 1183611.1],
                [2572166.3, 1181562]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1752', ortbez27: 'Villars-sur-Gl�ne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575313.3, 1183748.9],
                [2577061.4, 1184383.9],
                [2577674.9, 1182297.1],
                [2576484.8, 1181844.8],
                [2574811.1, 1181961.8],
                [2575313.3, 1183748.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1757', ortbez27: 'Nor�az' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2566675.2, 1182670],
                [2568618.6, 1185023.8],
                [2570266.3, 1185390.4],
                [2571635.5, 1184298.2],
                [2570328.8, 1182736.1],
                [2569533.3, 1181451.2],
                [2566675.2, 1182670]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1756', ortbez27: 'Onnens FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568279.5, 1179166.4],
                [2566785, 1180307.6],
                [2569533.3, 1181451.2],
                [2571018.8, 1180839.5],
                [2569883.5, 1179653.2],
                [2568279.5, 1179166.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1745', ortbez27: 'Lentigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568279.5, 1179166.4],
                [2566960.5, 1177857.2],
                [2564903.1, 1178384.4],
                [2566785, 1180307.6],
                [2568279.5, 1179166.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1746', ortbez27: 'Prez-vers-Nor�az' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2566675.2, 1182670],
                [2569533.3, 1181451.2],
                [2566785, 1180307.6],
                [2565060, 1180803.3],
                [2565424.1, 1182945.1],
                [2566675.2, 1182670]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1744', ortbez27: 'Ch�nens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2564903.1, 1178384.4], [2566960.5, 1177857.2], [2565784.4, 1176442.2], [2564903.1, 1178384.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1749', ortbez27: 'Middes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563418.2, 1182383.8],
                [2563157.2, 1178930.7],
                [2561144.4, 1179892.7],
                [2561892.1, 1181415.2],
                [2563418.2, 1182383.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1747', ortbez27: 'Corserey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2564903.1, 1178384.4], [2565060, 1180803.3], [2566785, 1180307.6], [2564903.1, 1178384.4]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1748', ortbez27: 'Torny-le-Grand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563157.2, 1178930.7],
                [2563418.2, 1182383.8],
                [2565060, 1180803.3],
                [2564903.1, 1178384.4],
                [2563157.2, 1178930.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1737', ortbez27: 'Plasselb' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585747.5, 1177909.2],
                [2586980.9, 1173864.9],
                [2585898.6, 1169151],
                [2585173.6, 1167150],
                [2580586.6, 1169593.4],
                [2581839.7, 1172974.4],
                [2581985.3, 1174069.1],
                [2583140.9, 1174179.4],
                [2584813.3, 1176358.3],
                [2585747.5, 1177909.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1738', ortbez27: 'Sangernboden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601811.2, 1172934.1],
                [2600730.9, 1172289.7],
                [2598941.3, 1171989.8],
                [2597444.2, 1169234.7],
                [2595700.7, 1169195.2],
                [2595345.5, 1167323.5],
                [2594227, 1166442.9],
                [2593099.9, 1167018],
                [2591844.7, 1167258],
                [2591776.5, 1172297.3],
                [2589819.4, 1174376.8],
                [2590929.5, 1175787.1],
                [2597748.7, 1177176.3],
                [2598698.9, 1176306.9],
                [2599965.2, 1175725.5],
                [2601811.2, 1172934.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1735', ortbez27: 'Giffers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585747.5, 1177909.2],
                [2584813.3, 1176358.3],
                [2581302.7, 1178131.5],
                [2583587.9, 1180155.5],
                [2585747.5, 1177909.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1736', ortbez27: 'St. Silvester' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584813.3, 1176358.3],
                [2583140.9, 1174179.4],
                [2582090.4, 1174858.8],
                [2581452.3, 1175935.3],
                [2581302.7, 1178131.5],
                [2584813.3, 1176358.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1741', ortbez27: 'Cottens FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570568.2, 1176535.2],
                [2566960.5, 1177857.2],
                [2568279.5, 1179166.4],
                [2569883.5, 1179653.2],
                [2571298.6, 1178780.4],
                [2571878.3, 1177099.7],
                [2570568.2, 1176535.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1742', ortbez27: 'Autigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569898.4, 1174799],
                [2567044.7, 1175965.6],
                [2565784.4, 1176442.2],
                [2566960.5, 1177857.2],
                [2570568.2, 1176535.2],
                [2571082.5, 1175232.9],
                [2569898.4, 1174799]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1740', ortbez27: 'Neyruz FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569883.5, 1179653.2],
                [2571018.8, 1180839.5],
                [2572166.3, 1181562],
                [2572518.2, 1179814.1],
                [2571298.6, 1178780.4],
                [2569883.5, 1179653.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1793', ortbez27: 'Jeuss' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2580222.5, 1197373.4], [2580067.6, 1194453], [2578562.8, 1194898.3], [2580222.5, 1197373.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1794', ortbez27: 'Salvenach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580222.5, 1197373.4],
                [2578562.8, 1194898.3],
                [2577183.3, 1195441.4],
                [2579101.4, 1198031.9],
                [2580222.5, 1197373.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1792', ortbez27: 'Cordast' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578138.7, 1192783.5],
                [2579020.3, 1191845.5],
                [2577559.4, 1190729.5],
                [2576297.1, 1191937.9],
                [2578138.7, 1192783.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1797', ortbez27: 'M�nchenwiler' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2575405.4, 1196278.9], [2577183.3, 1195441.4], [2576021.6, 1194537.7], [2575405.4, 1196278.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1795', ortbez27: 'Courlevon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575954.8, 1193281.5],
                [2574267.1, 1192377.5],
                [2572991.9, 1194097.2],
                [2574557.6, 1194549.3],
                [2575954.8, 1193281.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1796', ortbez27: 'Courgevaux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575405.4, 1196278.9],
                [2576021.6, 1194537.7],
                [2575954.8, 1193281.5],
                [2574557.6, 1194549.3],
                [2574224.3, 1195789],
                [2575405.4, 1196278.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1785', ortbez27: 'Cressier FR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578138.7, 1192783.5],
                [2576297.1, 1191937.9],
                [2575954.8, 1193281.5],
                [2576021.6, 1194537.7],
                [2577183.3, 1195441.4],
                [2578562.8, 1194898.3],
                [2578138.7, 1192783.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1786', ortbez27: 'Sugiez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2574639, 1199100.9],
                [2573707, 1200285.4],
                [2573241.2, 1203009.7],
                [2577203.7, 1203797.3],
                [2574639, 1199100.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1783', ortbez27: 'Pensier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576485.8, 1187506.1],
                [2574513.2, 1188410.6],
                [2576666.4, 1189588.5],
                [2577559.4, 1190729.5],
                [2579020.3, 1191845.5],
                [2577659.6, 1188675.6],
                [2576485.8, 1187506.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1784', ortbez27: 'Wallenried' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576297.1, 1191937.9],
                [2577559.4, 1190729.5],
                [2576666.4, 1189588.5],
                [2574513.2, 1188410.6],
                [2573507.1, 1189943.6],
                [2574748, 1190886.3],
                [2574267.1, 1192377.5],
                [2575954.8, 1193281.5],
                [2576297.1, 1191937.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1789', ortbez27: 'Lugnorre' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571487.8, 1202441.8],
                [2573241.2, 1203009.7],
                [2573707, 1200285.4],
                [2571210.7, 1200262.7],
                [2571487.8, 1202441.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1787', ortbez27: 'M�tier (Vully)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2574639, 1199100.9],
                [2573030.8, 1197920.4],
                [2571158.2, 1198901.6],
                [2569931.8, 1199427.3],
                [2571210.7, 1200262.7],
                [2573707, 1200285.4],
                [2574639, 1199100.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1775', ortbez27: 'Grandsivaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565060, 1180803.3],
                [2563418.2, 1182383.8],
                [2564380.2, 1184272.1],
                [2565424.1, 1182945.1],
                [2565060, 1180803.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1776', ortbez27: 'Montagny-la-Ville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565728.6, 1188126.5],
                [2566489.9, 1187037.2],
                [2567138.3, 1185823.2],
                [2564402.4, 1186350.2],
                [2565728.6, 1188126.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1782', ortbez27: 'Cormagens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2577659.6, 1188675.6], [2577833.1, 1187047.4], [2576485.8, 1187506.1], [2577659.6, 1188675.6]]],
            [
              [
                [2573284.6, 1184828.1],
                [2572822.9, 1183611.1],
                [2571635.5, 1184298.2],
                [2571792, 1185665.1],
                [2572259.8, 1188117.4],
                [2574513.2, 1188410.6],
                [2576485.8, 1187506.1],
                [2575282.7, 1186418.5],
                [2575153.5, 1185128.1],
                [2573284.6, 1184828.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1773', ortbez27: 'Russy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570181, 1186668.9],
                [2570266.3, 1185390.4],
                [2568618.6, 1185023.8],
                [2567138.3, 1185823.2],
                [2566489.9, 1187037.2],
                [2565728.6, 1188126.5],
                [2566928, 1188758.4],
                [2568693.9, 1188048],
                [2570454.6, 1189487.1],
                [2570181, 1186668.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1774', ortbez27: 'Cousset' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568618.6, 1185023.8],
                [2566675.2, 1182670],
                [2565424.1, 1182945.1],
                [2564380.2, 1184272.1],
                [2563378.1, 1185544.9],
                [2564402.4, 1186350.2],
                [2567138.3, 1185823.2],
                [2568618.6, 1185023.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1772', ortbez27: 'Grolley' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571792, 1185665.1],
                [2571635.5, 1184298.2],
                [2570266.3, 1185390.4],
                [2570181, 1186668.9],
                [2570454.6, 1189487.1],
                [2572259.8, 1188117.4],
                [2571792, 1185665.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1824', ortbez27: 'Caux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563711.4, 1144877],
                [2564741.5, 1142795.1],
                [2562614.4, 1142264.6],
                [2561368.8, 1141738.2],
                [2560188.4, 1142791.8],
                [2561347.3, 1143931.9],
                [2562879.3, 1143881.2],
                [2563711.4, 1144877]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1815', ortbez27: 'Clarens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557088.4, 1140142.1],
                [2555532.6, 1140814.4],
                [2557496.6, 1144688.7],
                [2558865.3, 1144649.3],
                [2557088.4, 1140142.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1822', ortbez27: 'Chernex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2558865.3, 1144649.3], [2561347.3, 1143931.9], [2560188.4, 1142791.8], [2558865.3, 1144649.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1820', ortbez27: 'Montreux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560188.4, 1142791.8],
                [2561368.8, 1141738.2],
                [2559292.2, 1140928.4],
                [2557088.4, 1140142.1],
                [2558865.3, 1144649.3],
                [2560188.4, 1142791.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1807', ortbez27: 'Blonay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558865.3, 1144649.3],
                [2557496.6, 1144688.7],
                [2555863.1, 1146092.6],
                [2557800.5, 1148646.8],
                [2561290, 1150457.4],
                [2563488, 1149753.3],
                [2564703.3, 1146498],
                [2561932.1, 1147913.8],
                [2560707.7, 1147630.5],
                [2560666.6, 1146351.6],
                [2558865.3, 1144649.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1808', ortbez27: 'Les Monts-de-Corsier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555512.8, 1149408.9],
                [2557168, 1151681.1],
                [2558396.4, 1151001.1],
                [2557800.5, 1148646.8],
                [2555512.8, 1149408.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1814', ortbez27: 'La Tour-de-Peilz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555532.6, 1140814.4],
                [2552642.3, 1142062.4],
                [2555863.1, 1146092.6],
                [2557496.6, 1144688.7],
                [2555532.6, 1140814.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1801', ortbez27: 'Le Mont-P�lerin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2551688.3, 1148588.4], [2552918.8, 1151520.9], [2554015.1, 1149569.9], [2551688.3, 1148588.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1802', ortbez27: 'Corseaux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2552642.3, 1142062.4],
                [2550273.1, 1142761.8],
                [2551486.8, 1147145.8],
                [2554074.7, 1147283.6],
                [2552642.3, 1142062.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1800', ortbez27: 'Vevey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2552642.3, 1142062.4],
                [2554074.7, 1147283.6],
                [2555310.2, 1147488.5],
                [2555863.1, 1146092.6],
                [2552642.3, 1142062.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1805', ortbez27: 'Jongny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2555512.8, 1149408.9], [2555310.2, 1147488.5], [2554015.1, 1149569.9], [2555512.8, 1149408.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1806', ortbez27: 'St-L�gier-La Chi�saz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557800.5, 1148646.8],
                [2555863.1, 1146092.6],
                [2555310.2, 1147488.5],
                [2555512.8, 1149408.9],
                [2557800.5, 1148646.8]
              ]
            ],
            [[[2557800.5, 1148646.8], [2558396.4, 1151001.1], [2561290, 1150457.4], [2557800.5, 1148646.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1803', ortbez27: 'Chardonne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551688.3, 1148588.4],
                [2554015.1, 1149569.9],
                [2555310.2, 1147488.5],
                [2554074.7, 1147283.6],
                [2551486.8, 1147145.8],
                [2551688.3, 1148588.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1856', ortbez27: 'Corbeyrier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561596.7, 1135710.1],
                [2568368.7, 1140624.6],
                [2568179.8, 1137297.7],
                [2565346, 1132988.9],
                [2562475.5, 1132843.6],
                [2562186.1, 1134584.9],
                [2561596.7, 1135710.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1862', ortbez27: 'La Comballaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573761.7, 1135776.2],
                [2573854.7, 1134461.3],
                [2571304, 1134951.1],
                [2571036.9, 1137970.8],
                [2572022.1, 1139001.2],
                [2572841.5, 1139988.1],
                [2573829.7, 1140768.1],
                [2575153.8, 1140206.7],
                [2577000.4, 1135761.9],
                [2575301.8, 1136461.1],
                [2573761.7, 1135776.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1860', ortbez27: 'Aigle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561045.9, 1130730.8],
                [2564613.2, 1131153.8],
                [2568228.5, 1131912.1],
                [2566063.8, 1129069.1],
                [2564403, 1127982.6],
                [2562381.7, 1126903.2],
                [2561045.9, 1130730.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1847', ortbez27: 'Rennaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2559149.5, 1135092], [2560788.1, 1137358.5], [2561596.7, 1135710.1], [2559149.5, 1135092]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1853', ortbez27: 'Yvorne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2564613.2, 1131153.8],
                [2561045.9, 1130730.8],
                [2558965.4, 1132553.4],
                [2562186.1, 1134584.9],
                [2562475.5, 1132843.6],
                [2565346, 1132988.9],
                [2564613.2, 1131153.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1854', ortbez27: 'Leysin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568228.5, 1131912.1],
                [2564613.2, 1131153.8],
                [2565346, 1132988.9],
                [2568179.8, 1137297.7],
                [2568228.5, 1131912.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1852', ortbez27: 'Roche VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558965.4, 1132553.4],
                [2559149.5, 1135092],
                [2561596.7, 1135710.1],
                [2562186.1, 1134584.9],
                [2558965.4, 1132553.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1845', ortbez27: 'Noville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555532.6, 1140814.4],
                [2557088.4, 1140142.1],
                [2560788.1, 1137358.5],
                [2559149.5, 1135092],
                [2557602.2, 1135171.8],
                [2556682.4, 1136188.1],
                [2555470.5, 1138448.7],
                [2552642.3, 1142062.4],
                [2555532.6, 1140814.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1846', ortbez27: 'Chessel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558965.4, 1132553.4],
                [2557562.6, 1133535.3],
                [2557602.2, 1135171.8],
                [2559149.5, 1135092],
                [2558965.4, 1132553.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1844', ortbez27: 'Villeneuve VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560788.1, 1137358.5],
                [2557088.4, 1140142.1],
                [2559292.2, 1140928.4],
                [2561368.8, 1141738.2],
                [2562614.4, 1142264.6],
                [2564741.5, 1142795.1],
                [2566144.3, 1143495.8],
                [2567685.5, 1144292.3],
                [2568368.7, 1140624.6],
                [2561596.7, 1135710.1],
                [2560788.1, 1137358.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1833', ortbez27: 'Les Avants' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2564703.3, 1146498],
                [2563711.4, 1144877],
                [2562879.3, 1143881.2],
                [2561347.3, 1143931.9],
                [2560666.6, 1146351.6],
                [2560707.7, 1147630.5],
                [2561932.1, 1147913.8],
                [2564703.3, 1146498]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1832', ortbez27: 'Chamby' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2558865.3, 1144649.3], [2560666.6, 1146351.6], [2561347.3, 1143931.9], [2558865.3, 1144649.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1890', ortbez27: 'St-Maurice' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565360.3, 1114117.2],
                [2564442.7, 1113240.2],
                [2563152.6, 1113115.5],
                [2565758.2, 1117501.7],
                [2567089.8, 1117727.8],
                [2568592.3, 1116542.9],
                [2565360.3, 1114117.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1893', ortbez27: 'Muraz (Collombey)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555022.8, 1123402.1],
                [2555415.9, 1126846.8],
                [2559903.9, 1128153.9],
                [2559394.3, 1130054.8],
                [2561045.9, 1130730.8],
                [2562381.7, 1126903.2],
                [2559214.8, 1123207.7],
                [2555022.8, 1123402.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1891', ortbez27: 'V�rossaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561993.8, 1113661.7],
                [2561887.7, 1117330.8],
                [2563933.2, 1119491.1],
                [2567025.2, 1119049.4],
                [2567089.8, 1117727.8],
                [2565758.2, 1117501.7],
                [2563152.6, 1113115.5],
                [2561993.8, 1113661.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1892', ortbez27: 'Morcles' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570744.1, 1118776.5],
                [2571710.3, 1116820.1],
                [2570591.5, 1116231.8],
                [2569268.4, 1115480.8],
                [2568592.3, 1116542.9],
                [2567089.8, 1117727.8],
                [2567025.2, 1119049.4],
                [2570744.1, 1118776.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1882', ortbez27: 'Gryon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2577573.5, 1128319.7],
                [2580842.1, 1128223.4],
                [2581216.6, 1126145.8],
                [2580808.5, 1124605.4],
                [2579713.8, 1123944.9],
                [2578132.9, 1125410.9],
                [2572338.9, 1123560.8],
                [2570105.5, 1123778.4],
                [2570821.6, 1125924.9],
                [2571857.6, 1126760.9],
                [2574745.7, 1128902.8],
                [2577573.5, 1128319.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1880', ortbez27: 'Les Plans-sur-Bex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579713.8, 1123944.9],
                [2577926.6, 1121798.4],
                [2576712.3, 1120770.7],
                [2575522.1, 1119233.5],
                [2574213.4, 1117826.9],
                [2573037.7, 1116878.2],
                [2571710.3, 1116820.1],
                [2570744.1, 1118776.5],
                [2567025.2, 1119049.4],
                [2564034.9, 1121531.2],
                [2563968.9, 1123858.7],
                [2564489, 1125072.5],
                [2568318.3, 1124202.5],
                [2568308, 1125462.4],
                [2569704.5, 1126607.4],
                [2570821.6, 1125924.9],
                [2570105.5, 1123778.4],
                [2572338.9, 1123560.8],
                [2578132.9, 1125410.9],
                [2579713.8, 1123944.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1885', ortbez27: 'Chesi�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2569704.5, 1126607.4], [2567914, 1128070.6], [2570047.2, 1129165.8], [2569704.5, 1126607.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1884', ortbez27: 'Villars-sur-Ollon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567914, 1128070.6],
                [2569704.5, 1126607.4],
                [2568308, 1125462.4],
                [2567509.9, 1126695],
                [2566707.7, 1127733.2],
                [2567914, 1128070.6]
              ]
            ],
            [
              [
                [2575705.8, 1131931.2],
                [2577573.5, 1128319.7],
                [2574745.7, 1128902.8],
                [2571857.6, 1126760.9],
                [2570821.6, 1125924.9],
                [2569704.5, 1126607.4],
                [2570047.2, 1129165.8],
                [2569400.5, 1131235.8],
                [2570457.9, 1131981.8],
                [2571844.2, 1131633.7],
                [2573142.8, 1131431.2],
                [2574015.3, 1130491.2],
                [2575705.8, 1131931.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1873', ortbez27: 'Les Crosets' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2551725.8, 1114782.8], [2551305.8, 1116422.4], [2553947.3, 1116813.7], [2551725.8, 1114782.8]]],
            [
              [
                [2561993.8, 1113661.7],
                [2559238.8, 1111793.8],
                [2556816.4, 1114288.3],
                [2557674.4, 1115525.1],
                [2555688, 1116340.8],
                [2553947.3, 1116813.7],
                [2554443.4, 1118069.1],
                [2554969.1, 1119245.9],
                [2556742, 1118656.5],
                [2561887.7, 1117330.8],
                [2561993.8, 1113661.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1874', ortbez27: 'Champ�ry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559238.8, 1111793.8],
                [2553956.1, 1109254.5],
                [2550446.9, 1110027.4],
                [2551725.8, 1114782.8],
                [2553947.3, 1116813.7],
                [2555688, 1116340.8],
                [2557674.4, 1115525.1],
                [2556816.4, 1114288.3],
                [2559238.8, 1111793.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1871', ortbez27: 'Les Giettes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563933.2, 1119491.1],
                [2561887.7, 1117330.8],
                [2561449.1, 1120324.8],
                [2561474.4, 1122280.9],
                [2564034.9, 1121531.2],
                [2563933.2, 1119491.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1872', ortbez27: 'Troistorrents' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2559214.8, 1123207.7],
                [2561474.4, 1122280.9],
                [2561449.1, 1120324.8],
                [2561887.7, 1117330.8],
                [2556742, 1118656.5],
                [2554969.1, 1119245.9],
                [2556111.3, 1119801.3],
                [2557161.7, 1120509.9],
                [2558529.1, 1120591.4],
                [2559068.3, 1121724.8],
                [2559214.8, 1123207.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1875', ortbez27: 'Morgins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2552402.5, 1120155.5],
                [2555022.8, 1123402.1],
                [2559214.8, 1123207.7],
                [2559068.3, 1121724.8],
                [2558529.1, 1120591.4],
                [2557161.7, 1120509.9],
                [2556111.3, 1119801.3],
                [2554969.1, 1119245.9],
                [2554443.4, 1118069.1],
                [2552402.5, 1120155.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1865', ortbez27: 'Les Diablerets' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2583350.6, 1131036],
                [2581744.2, 1129234.8],
                [2580842.1, 1128223.4],
                [2577573.5, 1128319.7],
                [2575705.8, 1131931.2],
                [2577000.4, 1135761.9],
                [2581226.1, 1136564.3],
                [2582554.4, 1135296.5],
                [2583350.6, 1131036]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1866', ortbez27: 'La Forclaz VD' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573854.7, 1134461.3],
                [2575705.8, 1131931.2],
                [2574015.3, 1130491.2],
                [2573142.8, 1131431.2],
                [2571844.2, 1131633.7],
                [2570457.9, 1131981.8],
                [2573854.7, 1134461.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1863', ortbez27: 'Le S�pey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568179.8, 1137297.7],
                [2568368.7, 1140624.6],
                [2571036.9, 1137970.8],
                [2571304, 1134951.1],
                [2573854.7, 1134461.3],
                [2570457.9, 1131981.8],
                [2569400.5, 1131235.8],
                [2568228.5, 1131912.1],
                [2568179.8, 1137297.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1864', ortbez27: "Vers-l'Eglise" },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2577000.4, 1135761.9],
                [2575705.8, 1131931.2],
                [2573854.7, 1134461.3],
                [2573761.7, 1135776.2],
                [2575301.8, 1136461.1],
                [2577000.4, 1135761.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1869', ortbez27: 'Massongex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2567025.2, 1119049.4], [2563933.2, 1119491.1], [2564034.9, 1121531.2], [2567025.2, 1119049.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6017', ortbez27: 'Ruswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2654529.3, 1211077.3],
                [2655172.8, 1212697.1],
                [2656974.6, 1211869.7],
                [2655704.2, 1211511.9],
                [2654529.3, 1211077.3]
              ]
            ],
            [
              [
                [2652497.8, 1218228.6],
                [2655048, 1218247.8],
                [2656390.9, 1215026.1],
                [2655172.8, 1212697.1],
                [2652084.7, 1214424],
                [2651134.6, 1212665.3],
                [2649967.3, 1213340.5],
                [2648822.5, 1214425.5],
                [2652497.8, 1218228.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1870', ortbez27: 'Monthey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551305.8, 1116422.4],
                [2552402.5, 1120155.5],
                [2554443.4, 1118069.1],
                [2553947.3, 1116813.7],
                [2551305.8, 1116422.4]
              ]
            ],
            [
              [
                [2561474.4, 1122280.9],
                [2563307.7, 1125641.5],
                [2564489, 1125072.5],
                [2563968.9, 1123858.7],
                [2564034.9, 1121531.2],
                [2561474.4, 1122280.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1867', ortbez27: 'St-Triphon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562381.7, 1126903.2],
                [2564403, 1127982.6],
                [2566063.8, 1129069.1],
                [2568228.5, 1131912.1],
                [2569400.5, 1131235.8],
                [2570047.2, 1129165.8],
                [2567914, 1128070.6],
                [2566707.7, 1127733.2],
                [2567509.9, 1126695],
                [2568308, 1125462.4],
                [2568318.3, 1124202.5],
                [2564489, 1125072.5],
                [2563307.7, 1125641.5],
                [2562381.7, 1126903.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6018', ortbez27: 'Buttisholz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2650376.7, 1221148.8],
                [2652497.8, 1218228.6],
                [2648822.5, 1214425.5],
                [2647426.2, 1214542.6],
                [2645602.6, 1217646.3],
                [2650376.7, 1221148.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1868', ortbez27: 'Collombey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561474.4, 1122280.9],
                [2559214.8, 1123207.7],
                [2562381.7, 1126903.2],
                [2563307.7, 1125641.5],
                [2561474.4, 1122280.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6015', ortbez27: 'Luzern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2663341.1, 1213489.9], [2664525.3, 1212582.6], [2663333.5, 1212196.5], [2663341.1, 1213489.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6016', ortbez27: 'Hellb�hl' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656390.9, 1215026.1],
                [2660632.6, 1215933.9],
                [2660892.2, 1214164.6],
                [2658203.1, 1212132.3],
                [2656974.6, 1211869.7],
                [2655172.8, 1212697.1],
                [2656390.9, 1215026.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1921', ortbez27: 'Martigny-Croix' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570983.6, 1102753.3],
                [2569222, 1097038.6],
                [2565433, 1103801],
                [2567198.4, 1105681.5],
                [2568352.8, 1106408.3],
                [2570498.1, 1104968.3],
                [2570983.6, 1102753.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1922', ortbez27: 'Salvan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567656, 1109010.4],
                [2564844.2, 1109103.4],
                [2566799.5, 1111511.8],
                [2567676.9, 1110523.8],
                [2567656, 1109010.4]
              ]
            ],
            [
              [
                [2568450.6, 1107660.2],
                [2567130.5, 1107515.2],
                [2568144, 1108181.1],
                [2567656, 1109010.4],
                [2569048.5, 1108775.3],
                [2568450.6, 1107660.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6022', ortbez27: 'Grosswangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2645602.6, 1217646.3],
                [2643070.2, 1220920.1],
                [2645063.1, 1221817.4],
                [2648394.4, 1223220.4],
                [2650376.7, 1221148.8],
                [2645602.6, 1217646.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6019', ortbez27: 'Sigigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655172.8, 1212697.1],
                [2656974.6, 1211869.7],
                [2655704.2, 1211511.9],
                [2654529.3, 1211077.3],
                [2653231.6, 1211204.7],
                [2651134.6, 1212665.3],
                [2652084.7, 1214424],
                [2655172.8, 1212697.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1920', ortbez27: 'Martigny' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570146.3, 1109839.2],
                [2571470.2, 1107905.6],
                [2575179.5, 1108284],
                [2576722.2, 1105837.1],
                [2575017.6, 1104253.2],
                [2573709.2, 1104202],
                [2572850.9, 1105121.8],
                [2572329, 1103980.6],
                [2572604.5, 1102710.1],
                [2570983.6, 1102753.3],
                [2570498.1, 1104968.3],
                [2568352.8, 1106408.3],
                [2568450.6, 1107660.2],
                [2569048.5, 1108775.3],
                [2570146.3, 1109839.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6020', ortbez27: 'Emmenbr�cke' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660892.2, 1214164.6],
                [2660632.6, 1215933.9],
                [2664066, 1216427.7],
                [2666323.1, 1213203.2],
                [2664525.3, 1212582.6],
                [2663341.1, 1213489.9],
                [2660892.2, 1214164.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1925', ortbez27: 'Finhaut' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2564108.7, 1105787.9],
                [2565433, 1103801],
                [2563291.3, 1101370],
                [2562117, 1100215.4],
                [2560862.1, 1101862.3],
                [2556702.5, 1099687.4],
                [2557759, 1102751.6],
                [2564108.7, 1105787.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1926', ortbez27: 'Fully' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571470.2, 1107905.6],
                [2572386.8, 1113447.9],
                [2570591.5, 1116231.8],
                [2571710.3, 1116820.1],
                [2573037.7, 1116878.2],
                [2575051.8, 1116416.2],
                [2577262.8, 1115467.8],
                [2577567.7, 1114171.1],
                [2579010.5, 1111716.4],
                [2577173.9, 1110023.9],
                [2575179.5, 1108284],
                [2571470.2, 1107905.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6010', ortbez27: 'Kriens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660986.4, 1204823.5],
                [2660822.7, 1206966.4],
                [2662848.9, 1209789.4],
                [2665020.6, 1210696.3],
                [2665698.3, 1209015.8],
                [2662593.5, 1204847.6],
                [2662300.3, 1203630],
                [2660986.4, 1204823.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1923', ortbez27: 'Le Tr�tien' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565433, 1103801],
                [2564108.7, 1105787.9],
                [2557759, 1102751.6],
                [2558426.7, 1108321.5],
                [2564844.2, 1109103.4],
                [2567656, 1109010.4],
                [2568144, 1108181.1],
                [2567130.5, 1107515.2],
                [2568450.6, 1107660.2],
                [2568352.8, 1106408.3],
                [2567198.4, 1105681.5],
                [2565433, 1103801]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1913', ortbez27: 'Saillon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579010.5, 1111716.4],
                [2577567.7, 1114171.1],
                [2579814.2, 1115605.9],
                [2580916.6, 1114794.8],
                [2582039.6, 1114095.7],
                [2581493.1, 1112403.4],
                [2579010.5, 1111716.4]
              ]
            ],
            [[[2577262.8, 1115467.8], [2575051.8, 1116416.2], [2576392.9, 1116474.2], [2577262.8, 1115467.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6013', ortbez27: 'Eigenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659297.6, 1208701.3],
                [2660822.7, 1206966.4],
                [2660986.4, 1204823.5],
                [2660212, 1202902.6],
                [2657052.2, 1202716],
                [2659297.6, 1208701.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1914', ortbez27: 'Is�rables' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585498.8, 1113775.8],
                [2589015.2, 1110269.1],
                [2587580.2, 1107236],
                [2585364.8, 1111231.5],
                [2584648.5, 1112509.9],
                [2585498.8, 1113775.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6014', ortbez27: 'Luzern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660942.2, 1210220.7],
                [2658203.1, 1212132.3],
                [2660892.2, 1214164.6],
                [2663341.1, 1213489.9],
                [2663333.5, 1212196.5],
                [2664525.3, 1212582.6],
                [2665020.6, 1210696.3],
                [2662848.9, 1209789.4],
                [2660942.2, 1210220.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1911', ortbez27: 'Mayens-de-Chamoson' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573037.7, 1116878.2],
                [2574213.4, 1117826.9],
                [2575522.1, 1119233.5],
                [2576712.3, 1120770.7],
                [2577926.6, 1121798.4],
                [2581281.1, 1121146.7],
                [2583435.8, 1119723.2],
                [2582358.7, 1117484],
                [2581627.8, 1116278.6],
                [2580439.5, 1116736.2],
                [2579814.2, 1115605.9],
                [2577567.7, 1114171.1],
                [2577262.8, 1115467.8],
                [2576392.9, 1116474.2],
                [2575051.8, 1116416.2],
                [2573037.7, 1116878.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1912', ortbez27: 'Dugny (Leytron)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581627.8, 1116278.6],
                [2583451.4, 1115565.9],
                [2583460.5, 1114271.9],
                [2582039.6, 1114095.7],
                [2580916.6, 1114794.8],
                [2579814.2, 1115605.9],
                [2580439.5, 1116736.2],
                [2581627.8, 1116278.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6012', ortbez27: 'Obernau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660822.7, 1206966.4],
                [2659297.6, 1208701.3],
                [2660942.2, 1210220.7],
                [2662848.9, 1209789.4],
                [2660822.7, 1206966.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1918', ortbez27: 'La Tzoumaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582636.4, 1111178.1],
                [2584648.5, 1112509.9],
                [2585364.8, 1111231.5],
                [2587580.2, 1107236],
                [2587735.4, 1105188.2],
                [2583474.1, 1108145.2],
                [2582636.4, 1111178.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1905', ortbez27: 'Dor�naz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568729.2, 1112117.5],
                [2572386.8, 1113447.9],
                [2571470.2, 1107905.6],
                [2570146.3, 1109839.2],
                [2568729.2, 1112117.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6005', ortbez27: 'St. Niklausen LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668856.1, 1210088.8],
                [2669670.6, 1208448.2],
                [2668242.3, 1207917.6],
                [2667547.7, 1209488.8],
                [2668856.1, 1210088.8]
              ]
            ],
            [
              [
                [2665020.6, 1210696.3],
                [2666481.4, 1211774.5],
                [2667547.7, 1209488.8],
                [2665698.3, 1209015.8],
                [2665020.6, 1210696.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1906', ortbez27: 'Charrat' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2577812.2, 1107129.1],
                [2576722.2, 1105837.1],
                [2575179.5, 1108284],
                [2577173.9, 1110023.9],
                [2577812.2, 1107129.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6006', ortbez27: 'Luzern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2669867.6, 1212320.7],
                [2668856.1, 1210088.8],
                [2667547.7, 1209488.8],
                [2666481.4, 1211774.5],
                [2666323.1, 1213203.2],
                [2668580.6, 1213484.9],
                [2669867.6, 1212320.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1903', ortbez27: 'Collonges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568729.2, 1112117.5],
                [2569268.4, 1115480.8],
                [2570591.5, 1116231.8],
                [2572386.8, 1113447.9],
                [2568729.2, 1112117.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6003', ortbez27: 'Luzern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2664525.3, 1212582.6], [2666481.4, 1211774.5], [2665020.6, 1210696.3], [2664525.3, 1212582.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1904', ortbez27: 'Vernayaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568729.2, 1112117.5],
                [2570146.3, 1109839.2],
                [2569048.5, 1108775.3],
                [2567656, 1109010.4],
                [2567676.9, 1110523.8],
                [2568729.2, 1112117.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6004', ortbez27: 'Luzern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2666481.4, 1211774.5], [2664525.3, 1212582.6], [2666323.1, 1213203.2], [2666481.4, 1211774.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1907', ortbez27: 'Saxon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582636.4, 1111178.1],
                [2583474.1, 1108145.2],
                [2581340.4, 1107114.5],
                [2577812.2, 1107129.1],
                [2577173.9, 1110023.9],
                [2579010.5, 1111716.4],
                [2581493.1, 1112403.4],
                [2582636.4, 1111178.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1908', ortbez27: 'Riddes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584648.5, 1112509.9],
                [2582636.4, 1111178.1],
                [2581493.1, 1112403.4],
                [2582039.6, 1114095.7],
                [2583460.5, 1114271.9],
                [2583451.4, 1115565.9],
                [2585498.8, 1113775.8],
                [2584648.5, 1112509.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1897', ortbez27: 'Les Evouettes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557602.2, 1135171.8],
                [2557562.6, 1133535.3],
                [2555649, 1133399.3],
                [2552581.9, 1134363.5],
                [2555470.5, 1138448.7],
                [2556682.4, 1136188.1],
                [2557602.2, 1135171.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1898', ortbez27: 'St-Gingolph' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2552642.3, 1142062.4],
                [2555470.5, 1138448.7],
                [2552581.9, 1134363.5],
                [2548983, 1133230.4],
                [2552642.3, 1142062.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1895', ortbez27: 'Vionnaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2555415.9, 1126846.8],
                [2557647.6, 1130583.8],
                [2559394.3, 1130054.8],
                [2559903.9, 1128153.9],
                [2555415.9, 1126846.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1896', ortbez27: 'Vouvry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558965.4, 1132553.4],
                [2561045.9, 1130730.8],
                [2559394.3, 1130054.8],
                [2557647.6, 1130583.8],
                [2552401, 1129678.5],
                [2548983, 1133230.4],
                [2552581.9, 1134363.5],
                [2555649, 1133399.3],
                [2557562.6, 1133535.3],
                [2558965.4, 1132553.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1902', ortbez27: 'Evionnaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558426.7, 1108321.5],
                [2553956.1, 1109254.5],
                [2559238.8, 1111793.8],
                [2561993.8, 1113661.7],
                [2563152.6, 1113115.5],
                [2564442.7, 1113240.2],
                [2565360.3, 1114117.2],
                [2568592.3, 1116542.9],
                [2569268.4, 1115480.8],
                [2568729.2, 1112117.5],
                [2567676.9, 1110523.8],
                [2566799.5, 1111511.8],
                [2564844.2, 1109103.4],
                [2558426.7, 1108321.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1899', ortbez27: 'Torgon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2555415.9, 1126846.8], [2552401, 1129678.5], [2557647.6, 1130583.8], [2555415.9, 1126846.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6047', ortbez27: 'Kastanienbaum' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2668225.9, 1204752.7], [2667025.8, 1205912.9], [2668242.3, 1207917.6], [2668225.9, 1204752.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6048', ortbez27: 'Horw' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2667025.8, 1205912.9],
                [2662593.5, 1204847.6],
                [2665698.3, 1209015.8],
                [2667547.7, 1209488.8],
                [2668242.3, 1207917.6],
                [2667025.8, 1205912.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6053', ortbez27: 'Alpnachstad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2666165.9, 1203273.7], [2666114.6, 1201027.4], [2663235.8, 1199913.8], [2666165.9, 1203273.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6052', ortbez27: 'Hergiswil NW' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2660212, 1202902.6], [2660986.4, 1204823.5], [2662300.3, 1203630], [2660212, 1202902.6]]],
            [
              [
                [2667025.8, 1205912.9],
                [2668225.9, 1204752.7],
                [2666165.9, 1203273.7],
                [2662593.5, 1204847.6],
                [2667025.8, 1205912.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1957', ortbez27: 'Ardon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587746.4, 1117019.9],
                [2586155.6, 1115327.2],
                [2584449.4, 1117757],
                [2583435.8, 1119723.2],
                [2581281.1, 1121146.7],
                [2583840.2, 1124068.9],
                [2585110.9, 1124122.3],
                [2585390.2, 1122663.1],
                [2586469.5, 1121525.3],
                [2585733.8, 1120078.6],
                [2586223.8, 1119095.9],
                [2585730.8, 1118808.4],
                [2586758.9, 1118022.7],
                [2587746.4, 1117019.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1958', ortbez27: 'St-L�onard' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2598820.3, 1121280.7],
                [2595783.7, 1121950.8],
                [2596753.6, 1123458.3],
                [2598248.5, 1122766.4],
                [2598898.5, 1124365.2],
                [2600474.6, 1123600.4],
                [2598820.3, 1121280.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6042', ortbez27: 'Dietwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674061.2, 1223508.2],
                [2673932.1, 1221672.9],
                [2671733.9, 1221574.9],
                [2670245.5, 1222227.4],
                [2669807, 1223427.3],
                [2671663.8, 1223896.1],
                [2674061.2, 1223508.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1955', ortbez27: 'N�miaz (Chamoson)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584449.4, 1117757],
                [2586155.6, 1115327.2],
                [2585498.8, 1113775.8],
                [2583451.4, 1115565.9],
                [2581627.8, 1116278.6],
                [2582358.7, 1117484],
                [2583435.8, 1119723.2],
                [2584449.4, 1117757]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1945', ortbez27: 'Petit Vich�res (Liddes)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581747.1, 1091451.2],
                [2579516.4, 1089979.4],
                [2576710.8, 1084885],
                [2576845.1, 1090141.2],
                [2577277.8, 1091459],
                [2577660.4, 1094105.1],
                [2578327.6, 1096309.6],
                [2579912.2, 1095839.7],
                [2581648.6, 1096415.3],
                [2584053.3, 1095482.4],
                [2586521, 1092418.3],
                [2586381.9, 1090117.8],
                [2581747.1, 1091451.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6045', ortbez27: 'Meggen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674015.5, 1212125.3],
                [2669670.6, 1208448.2],
                [2668856.1, 1210088.8],
                [2669867.6, 1212320.7],
                [2672301.5, 1213593.2],
                [2674015.5, 1212125.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1946', ortbez27: 'Bourg-St-Pierre' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576710.8, 1084885],
                [2579516.4, 1089979.4],
                [2581747.1, 1091451.2],
                [2586381.9, 1090117.8],
                [2588883.9, 1085723.9],
                [2585957.5, 1081987],
                [2582817, 1082066.8],
                [2581262.5, 1078898],
                [2577934.1, 1081020.9],
                [2576710.8, 1084885]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1943', ortbez27: 'Praz-de-Fort' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567803.9, 1092331.8],
                [2569551.4, 1094593.7],
                [2577660.4, 1094105.1],
                [2577277.8, 1091459],
                [2576845.1, 1090141.2],
                [2567803.9, 1092331.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6043', ortbez27: 'Adligenswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672301.5, 1213593.2],
                [2669867.6, 1212320.7],
                [2668580.6, 1213484.9],
                [2671362.9, 1215604.2],
                [2672301.5, 1213593.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1944', ortbez27: 'La Fouly VS' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567803.9, 1092331.8],
                [2576845.1, 1090141.2],
                [2576710.8, 1084885],
                [2577934.1, 1081020.9],
                [2573855, 1078816.2],
                [2566724.7, 1091053.8],
                [2567803.9, 1092331.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6044', ortbez27: 'Udligenswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672301.5, 1213593.2],
                [2671362.9, 1215604.2],
                [2672160.9, 1216835],
                [2674620.8, 1217370],
                [2673734, 1215151.6],
                [2672301.5, 1213593.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6033', ortbez27: 'Buchrain' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668358.8, 1218313.4],
                [2669777.9, 1218711.6],
                [2669992.4, 1217467.6],
                [2668316.3, 1215978.8],
                [2668358.8, 1218313.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1950', ortbez27: 'Sion' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2591164.2, 1120724.6],
                [2594542.2, 1122132.9],
                [2595783.7, 1121950.8],
                [2597073.8, 1119288.2],
                [2595151.3, 1117684.6],
                [2593952.6, 1118071.1],
                [2593609.3, 1116455.6],
                [2592271.8, 1117039.3],
                [2590998.1, 1117314.2],
                [2589811.9, 1118767.8],
                [2591164.2, 1120724.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6034', ortbez27: 'Inwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668502.3, 1221888.4],
                [2670245.5, 1222227.4],
                [2671733.9, 1221574.9],
                [2672007.1, 1219067.6],
                [2669777.9, 1218711.6],
                [2668358.8, 1218313.4],
                [2666301.7, 1217807.9],
                [2668502.3, 1221888.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1947', ortbez27: 'Champsec' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585747.6, 1100886.8],
                [2584053.3, 1095482.4],
                [2581648.6, 1096415.3],
                [2583999.3, 1100888.8],
                [2585747.6, 1100886.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1948', ortbez27: 'Fionnay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586381.9, 1090117.8],
                [2586521, 1092418.3],
                [2584053.3, 1095482.4],
                [2585747.6, 1100886.8],
                [2583999.3, 1100888.8],
                [2583216, 1102504.8],
                [2584492.8, 1103224.8],
                [2587735.4, 1105188.2],
                [2590407.5, 1102423.1],
                [2593535.4, 1101135.7],
                [2594690.5, 1096213.8],
                [2599527.4, 1093403.6],
                [2602380.6, 1092702.3],
                [2603908.7, 1089497.1],
                [2595784, 1082908.2],
                [2588883.9, 1085723.9],
                [2586381.9, 1090117.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6032', ortbez27: 'Emmen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666323.1, 1213203.2],
                [2664066, 1216427.7],
                [2666301.7, 1217807.9],
                [2668358.8, 1218313.4],
                [2668316.3, 1215978.8],
                [2666323.1, 1213203.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1937', ortbez27: 'Orsi�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578327.6, 1096309.6],
                [2577660.4, 1094105.1],
                [2569551.4, 1094593.7],
                [2569222, 1097038.6],
                [2571341.2, 1098674.2],
                [2575176.4, 1096273.5],
                [2576271, 1097524.6],
                [2575607.1, 1099044.2],
                [2574806.9, 1100230.4],
                [2576466.2, 1100885.9],
                [2577878, 1101809.4],
                [2578644.1, 1100813.3],
                [2580326.2, 1100310.6],
                [2581648.6, 1096415.3],
                [2579912.2, 1095839.7],
                [2578327.6, 1096309.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6037', ortbez27: 'Root' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672007.1, 1219067.6],
                [2674572.4, 1219686.2],
                [2674620.8, 1217370],
                [2672160.9, 1216835],
                [2669992.4, 1217467.6],
                [2669777.9, 1218711.6],
                [2672007.1, 1219067.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1938', ortbez27: 'Champex-Lac' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571341.2, 1098674.2],
                [2574806.9, 1100230.4],
                [2575607.1, 1099044.2],
                [2576271, 1097524.6],
                [2575176.4, 1096273.5],
                [2571341.2, 1098674.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6038', ortbez27: 'Gisikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2671733.9, 1221574.9],
                [2673932.1, 1221672.9],
                [2674572.4, 1219686.2],
                [2672007.1, 1219067.6],
                [2671733.9, 1221574.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1936', ortbez27: 'Verbier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579913.5, 1105528.8],
                [2581340.4, 1107114.5],
                [2583474.1, 1108145.2],
                [2587735.4, 1105188.2],
                [2584492.8, 1103224.8],
                [2583216, 1102504.8],
                [2580092.4, 1104198],
                [2579913.5, 1105528.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1941', ortbez27: 'Voll�ges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2580092.4, 1104198], [2577214.8, 1104498.5], [2579913.5, 1105528.8], [2580092.4, 1104198]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6036', ortbez27: 'Dierikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2671362.9, 1215604.2], [2669992.4, 1217467.6], [2672160.9, 1216835], [2671362.9, 1215604.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6025', ortbez27: 'Neudorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657425.1, 1226988.1],
                [2659626.1, 1227721.5],
                [2660251.6, 1226254.2],
                [2660430.6, 1223754.6],
                [2657709.4, 1223832.4],
                [2657425.1, 1226988.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1942', ortbez27: 'Levron' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579913.5, 1105528.8],
                [2577214.8, 1104498.5],
                [2576722.2, 1105837.1],
                [2577812.2, 1107129.1],
                [2581340.4, 1107114.5],
                [2579913.5, 1105528.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6026', ortbez27: 'Rain' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2662692, 1223077.2],
                [2664538, 1221664.3],
                [2664289.2, 1218916],
                [2660806.1, 1219430.8],
                [2660228.4, 1220656.3],
                [2662692, 1223077.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6023', ortbez27: 'Rothenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660632.6, 1215933.9],
                [2660258.9, 1217909.2],
                [2660806.1, 1219430.8],
                [2664289.2, 1218916],
                [2666301.7, 1217807.9],
                [2664066, 1216427.7],
                [2660632.6, 1215933.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1929', ortbez27: 'Trient' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567803.9, 1092331.8],
                [2562117, 1100215.4],
                [2563291.3, 1101370],
                [2565433, 1103801],
                [2569222, 1097038.6],
                [2569551.4, 1094593.7],
                [2567803.9, 1092331.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6024', ortbez27: 'Hildisrieden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660228.4, 1220656.3],
                [2657709.4, 1223832.4],
                [2660430.6, 1223754.6],
                [2662692, 1223077.2],
                [2660228.4, 1220656.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6030', ortbez27: 'Ebikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2669992.4, 1217467.6],
                [2671362.9, 1215604.2],
                [2668580.6, 1213484.9],
                [2666323.1, 1213203.2],
                [2668316.3, 1215978.8],
                [2669992.4, 1217467.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1927', ortbez27: 'Chemin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2573709.2, 1104202], [2572329, 1103980.6], [2572850.9, 1105121.8], [2573709.2, 1104202]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6027', ortbez27: 'R�merswil LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663019.7, 1224696.8],
                [2662692, 1223077.2],
                [2660430.6, 1223754.6],
                [2660251.6, 1226254.2],
                [2661714.8, 1227104.8],
                [2663019.7, 1224696.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1933', ortbez27: 'La Garde (Sembrancher)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581065.3, 1102744.9],
                [2580326.2, 1100310.6],
                [2578644.1, 1100813.3],
                [2577878, 1101809.4],
                [2576466.2, 1100885.9],
                [2574806.9, 1100230.4],
                [2575017.6, 1104253.2],
                [2576722.2, 1105837.1],
                [2577214.8, 1104498.5],
                [2580092.4, 1104198],
                [2581065.3, 1102744.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1934', ortbez27: 'Bruson' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581648.6, 1096415.3],
                [2580326.2, 1100310.6],
                [2581065.3, 1102744.9],
                [2580092.4, 1104198],
                [2583216, 1102504.8],
                [2583999.3, 1100888.8],
                [2581648.6, 1096415.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1932', ortbez27: 'Bovernier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575017.6, 1104253.2],
                [2574806.9, 1100230.4],
                [2571341.2, 1098674.2],
                [2569222, 1097038.6],
                [2570983.6, 1102753.3],
                [2572604.5, 1102710.1],
                [2572329, 1103980.6],
                [2573709.2, 1104202],
                [2575017.6, 1104253.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1985', ortbez27: 'La Sage' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2609562.2, 1105254.1],
                [2613202.8, 1100533.4],
                [2613492.9, 1098233.1],
                [2610641.1, 1093018.1],
                [2608413, 1092641.8],
                [2606861.7, 1100822.6],
                [2606467, 1103413.7],
                [2606047.4, 1104739.3],
                [2605476.8, 1105885.6],
                [2606817, 1109784.3],
                [2608662.1, 1106441.2],
                [2609562.2, 1105254.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1986', ortbez27: 'Arolla' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606861.7, 1100822.6],
                [2608413, 1092641.8],
                [2608144.3, 1089626.2],
                [2603908.7, 1089497.1],
                [2602380.6, 1092702.3],
                [2599527.4, 1093403.6],
                [2599708.9, 1100974.4],
                [2606861.7, 1100822.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6086', ortbez27: 'Hasliberg Reuti' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663968.7, 1175267.8],
                [2659970.6, 1174578.1],
                [2657545.5, 1176100.3],
                [2658998.7, 1176938.1],
                [2661218.6, 1180282.1],
                [2664631.4, 1178490.1],
                [2663968.7, 1175267.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1983', ortbez27: 'Lanna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599172.9, 1107214.1],
                [2600646.9, 1108103.4],
                [2601768.2, 1110559.5],
                [2606817, 1109784.3],
                [2605476.8, 1105885.6],
                [2601930.8, 1105125],
                [2599292.9, 1104950.9],
                [2599172.9, 1107214.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1984', ortbez27: 'Les Haud�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599292.9, 1104950.9],
                [2601930.8, 1105125],
                [2605476.8, 1105885.6],
                [2606047.4, 1104739.3],
                [2606467, 1103413.7],
                [2606861.7, 1100822.6],
                [2599708.9, 1100974.4],
                [2599057.9, 1102338.5],
                [2599292.9, 1104950.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6083', ortbez27: 'Hasliberg Hohfluh' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2657713.9, 1180116.1], [2657545.5, 1176100.3], [2654150.6, 1178619.8], [2657713.9, 1180116.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6084', ortbez27: 'Hasliberg Wasserwendi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2657713.9, 1180116.1],
                [2661218.6, 1180282.1],
                [2658998.7, 1176938.1],
                [2657545.5, 1176100.3],
                [2657713.9, 1180116.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6073', ortbez27: 'Fl�eli-Ranft' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663721.5, 1192798.7],
                [2663615.3, 1191115.7],
                [2661567.2, 1190562],
                [2660816.8, 1192708.9],
                [2663721.5, 1192798.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6074', ortbez27: 'Giswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2646122.6, 1187582.9],
                [2648244.1, 1192166.3],
                [2651338.4, 1193878.9],
                [2653013.1, 1193242.9],
                [2656164.3, 1190894.2],
                [2657541.2, 1189685.3],
                [2659349.6, 1185172.9],
                [2656975.6, 1185064],
                [2656313.5, 1184002.7],
                [2654904.9, 1185575.3],
                [2653873.9, 1184643.9],
                [2653275.7, 1183426.9],
                [2651907.1, 1183064],
                [2650654.7, 1183165.2],
                [2649817.9, 1182181.7],
                [2648211.4, 1182280.3],
                [2649093.8, 1184787.2],
                [2646122.6, 1187582.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1987', ortbez27: 'H�r�mence' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599708.9, 1100974.4],
                [2599527.4, 1093403.6],
                [2594690.5, 1096213.8],
                [2593535.4, 1101135.7],
                [2593260.1, 1109476.2],
                [2594294.3, 1112215.8],
                [2596036.3, 1113667.5],
                [2596898.4, 1115841.3],
                [2599172.9, 1107214.1],
                [2599292.9, 1104950.9],
                [2599057.9, 1102338.5],
                [2599708.9, 1100974.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1988', ortbez27: 'Les Collons' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596036.3, 1113667.5],
                [2594294.3, 1112215.8],
                [2594633.4, 1114204.5],
                [2595204.9, 1115425.9],
                [2596898.4, 1115841.3],
                [2596036.3, 1113667.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6072', ortbez27: 'Sachseln' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659349.6, 1185172.9],
                [2657541.2, 1189685.3],
                [2660816.8, 1192708.9],
                [2661567.2, 1190562],
                [2663615.3, 1191115.7],
                [2664616, 1190364.1],
                [2665466.4, 1189438.9],
                [2663374.4, 1183836.1],
                [2660769.1, 1181564.7],
                [2658878.8, 1182470.8],
                [2659349.6, 1185172.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1977', ortbez27: 'Icogne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604742.8, 1135530.8],
                [2601814.4, 1126880.8],
                [2598898.5, 1124365.2],
                [2598184.1, 1125498.5],
                [2599493.7, 1127296],
                [2600664.5, 1129345.1],
                [2598777.3, 1134101],
                [2600803, 1136934.4],
                [2601881.3, 1136256],
                [2603209.2, 1137239.2],
                [2604742.8, 1135530.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1978', ortbez27: 'Lens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601814.4, 1126880.8],
                [2602733.9, 1124842.5],
                [2600474.6, 1123600.4],
                [2598898.5, 1124365.2],
                [2601814.4, 1126880.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6078', ortbez27: 'Lungern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2661218.6, 1180282.1],
                [2657713.9, 1180116.1],
                [2654150.6, 1178619.8],
                [2652262, 1180282.4],
                [2650770.8, 1180650.6],
                [2649817.9, 1182181.7],
                [2650654.7, 1183165.2],
                [2651907.1, 1183064],
                [2653275.7, 1183426.9],
                [2653873.9, 1184643.9],
                [2654904.9, 1185575.3],
                [2656313.5, 1184002.7],
                [2656975.6, 1185064],
                [2659349.6, 1185172.9],
                [2658878.8, 1182470.8],
                [2660769.1, 1181564.7],
                [2661218.6, 1180282.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1976', ortbez27: 'Aven' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586857.6, 1119465.6],
                [2586223.8, 1119095.9],
                [2585733.8, 1120078.6],
                [2586469.5, 1121525.3],
                [2585390.2, 1122663.1],
                [2585110.9, 1124122.3],
                [2583840.2, 1124068.9],
                [2581281.1, 1121146.7],
                [2577926.6, 1121798.4],
                [2579713.8, 1123944.9],
                [2580808.5, 1124605.4],
                [2581216.6, 1126145.8],
                [2580842.1, 1128223.4],
                [2581744.2, 1129234.8],
                [2587743.9, 1129036.3],
                [2591504.7, 1127566.2],
                [2590896.7, 1124073],
                [2589685.9, 1123648.8],
                [2590170.5, 1122032.3],
                [2587826.6, 1120332.9],
                [2586857.6, 1119465.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1981', ortbez27: 'Vex' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595151.3, 1117684.6],
                [2597073.8, 1119288.2],
                [2598088.8, 1116565.3],
                [2596898.4, 1115841.3],
                [2595151.3, 1117684.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1982', ortbez27: 'Euseigne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596898.4, 1115841.3],
                [2598088.8, 1116565.3],
                [2599659.3, 1113242.2],
                [2601338.6, 1111964.4],
                [2601768.2, 1110559.5],
                [2600646.9, 1108103.4],
                [2599172.9, 1107214.1],
                [2596898.4, 1115841.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6066', ortbez27: 'St. Niklausen OW' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668395.6, 1190548.3],
                [2665466.4, 1189438.9],
                [2664616, 1190364.1],
                [2663615.3, 1191115.7],
                [2663721.5, 1192798.7],
                [2664751, 1194251.5],
                [2668229.9, 1192968.3],
                [2668395.6, 1190548.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6063', ortbez27: 'Stalden (Sarnen)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2648244.1, 1192166.3],
                [2648177.4, 1194185.8],
                [2650064.2, 1196007.8],
                [2651338.4, 1193878.9],
                [2648244.1, 1192166.3]
              ]
            ],
            [
              [
                [2651338.4, 1193878.9],
                [2654772.5, 1198430.6],
                [2655638, 1197316.9],
                [2656570.3, 1195011],
                [2660234.3, 1194267.7],
                [2656164.3, 1190894.2],
                [2653013.1, 1193242.9],
                [2651338.4, 1193878.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6064', ortbez27: 'Kerns' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663985.5, 1197244.1],
                [2664780.1, 1198585.1],
                [2668581, 1198118.9],
                [2668229.9, 1192968.3],
                [2664751, 1194251.5],
                [2663721.5, 1192798.7],
                [2662960.6, 1195155.8],
                [2663985.5, 1197244.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1969', ortbez27: 'Eison (St-Martin)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606038.2, 1112889.3],
                [2606817, 1109784.3],
                [2601768.2, 1110559.5],
                [2601338.6, 1111964.4],
                [2599659.3, 1113242.2],
                [2598088.8, 1116565.3],
                [2601534.6, 1114210.2],
                [2603088.4, 1114804.9],
                [2604338.6, 1113124.7],
                [2606038.2, 1112889.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1967', ortbez27: 'Bramois' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2597073.8, 1119288.2], [2595783.7, 1121950.8], [2598820.3, 1121280.7], [2597073.8, 1119288.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6067', ortbez27: 'Melchtal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668008.7, 1188424],
                [2668251.5, 1185020],
                [2663953, 1181979.6],
                [2663374.4, 1183836.1],
                [2665466.4, 1189438.9],
                [2668395.6, 1190548.3],
                [2668008.7, 1188424]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1968', ortbez27: 'Mase' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602996.5, 1117352.2],
                [2603088.4, 1114804.9],
                [2601534.6, 1114210.2],
                [2598088.8, 1116565.3],
                [2602996.5, 1117352.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1973', ortbez27: 'Nax' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607463.7, 1116085.6],
                [2606038.2, 1112889.3],
                [2604338.6, 1113124.7],
                [2603088.4, 1114804.9],
                [2602996.5, 1117352.2],
                [2607463.7, 1116085.6]
              ]
            ],
            [[[2597073.8, 1119288.2], [2598820.3, 1121280.7], [2602996.5, 1117352.2], [2597073.8, 1119288.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6068', ortbez27: 'Melchsee-Frutt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2661218.6, 1180282.1],
                [2660769.1, 1181564.7],
                [2663374.4, 1183836.1],
                [2663953, 1181979.6],
                [2668251.5, 1185020],
                [2671114.8, 1182427.5],
                [2667085.4, 1180399.9],
                [2664631.4, 1178490.1],
                [2661218.6, 1180282.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1974', ortbez27: 'Arbaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594852.3, 1123728.3],
                [2593936.7, 1132264],
                [2596509.3, 1126614.9],
                [2596839.3, 1125092.3],
                [2596753.6, 1123458.3],
                [2594852.3, 1123728.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1971', ortbez27: 'Grimisuat' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594542.2, 1122132.9],
                [2594852.3, 1123728.3],
                [2596753.6, 1123458.3],
                [2595783.7, 1121950.8],
                [2594542.2, 1122132.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6055', ortbez27: 'Alpnach Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655152, 1201779.5],
                [2657052.2, 1202716],
                [2660212, 1202902.6],
                [2662300.3, 1203630],
                [2662593.5, 1204847.6],
                [2666165.9, 1203273.7],
                [2663235.8, 1199913.8],
                [2666114.6, 1201027.4],
                [2664780.1, 1198585.1],
                [2663985.5, 1197244.1],
                [2661346.9, 1196171.9],
                [2660359.2, 1197442.5],
                [2655638, 1197316.9],
                [2654772.5, 1198430.6],
                [2655152, 1201779.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1972', ortbez27: 'Anz�re' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2593936.7, 1132264],
                [2593939.5, 1133680.7],
                [2595324.7, 1134841.1],
                [2599072.7, 1136747.4],
                [2600803, 1136934.4],
                [2598777.3, 1134101],
                [2600664.5, 1129345.1],
                [2599493.7, 1127296],
                [2598182.7, 1127415.2],
                [2596509.3, 1126614.9],
                [2593936.7, 1132264]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1961', ortbez27: 'Vernami�ge' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2598088.8, 1116565.3], [2597073.8, 1119288.2], [2602996.5, 1117352.2], [2598088.8, 1116565.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6056', ortbez27: 'K�giswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2662960.6, 1195155.8], [2661346.9, 1196171.9], [2663985.5, 1197244.1], [2662960.6, 1195155.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6062', ortbez27: 'Wilen (Sarnen)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660234.3, 1194267.7],
                [2660816.8, 1192708.9],
                [2657541.2, 1189685.3],
                [2656164.3, 1190894.2],
                [2660234.3, 1194267.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6060', ortbez27: 'Sarnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2662960.6, 1195155.8],
                [2663721.5, 1192798.7],
                [2660816.8, 1192708.9],
                [2660234.3, 1194267.7],
                [2656570.3, 1195011],
                [2655638, 1197316.9],
                [2660359.2, 1197442.5],
                [2661346.9, 1196171.9],
                [2662960.6, 1195155.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6113', ortbez27: 'Romoos' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638970.4, 1206495.5],
                [2645200.9, 1209861.2],
                [2646622.6, 1208460.7],
                [2645431.9, 1205695.4],
                [2643312.7, 1203508.5],
                [2638970.4, 1206495.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1966', ortbez27: 'Saxonne (Ayent)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2598898.5, 1124365.2],
                [2598248.5, 1122766.4],
                [2596753.6, 1123458.3],
                [2596839.3, 1125092.3],
                [2596509.3, 1126614.9],
                [2598182.7, 1127415.2],
                [2599493.7, 1127296],
                [2598184.1, 1125498.5],
                [2598898.5, 1124365.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6114', ortbez27: 'Steinhuserberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2645200.9, 1209861.2],
                [2644716.2, 1211629.1],
                [2647203.4, 1212472.9],
                [2647560.8, 1209414],
                [2646622.6, 1208460.7],
                [2645200.9, 1209861.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1963', ortbez27: 'V�troz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587746.4, 1117019.9],
                [2586758.9, 1118022.7],
                [2585730.8, 1118808.4],
                [2586223.8, 1119095.9],
                [2586857.6, 1119465.6],
                [2589811.9, 1118767.8],
                [2589665.4, 1115960.9],
                [2588257, 1115715.7],
                [2587746.4, 1117019.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1964', ortbez27: 'Conthey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587826.6, 1120332.9],
                [2590170.5, 1122032.3],
                [2591164.2, 1120724.6],
                [2589811.9, 1118767.8],
                [2586857.6, 1119465.6],
                [2587826.6, 1120332.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6112', ortbez27: 'Doppleschwand' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647399.2, 1204270.3],
                [2645431.9, 1205695.4],
                [2646622.6, 1208460.7],
                [2647597.9, 1207295.1],
                [2648713.9, 1206334.3],
                [2647399.2, 1204270.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2017', ortbez27: 'Boudry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554941.5, 1200445.4],
                [2553620.4, 1199336.4],
                [2549332.8, 1199698.6],
                [2548459.6, 1200898.5],
                [2549296, 1201879.8],
                [2551718, 1201764],
                [2553386.9, 1202364.6],
                [2554941.5, 1200445.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2015', ortbez27: 'Areuse' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561212.2, 1200530.8],
                [2559570, 1198466],
                [2558757.1, 1197443.9],
                [2554941.5, 1200445.4],
                [2561212.2, 1200530.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2016', ortbez27: 'Cortaillod' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554941.5, 1200445.4],
                [2558757.1, 1197443.9],
                [2556418, 1195691.5],
                [2553620.4, 1199336.4],
                [2554941.5, 1200445.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6105', ortbez27: 'Schachen LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2651567.1, 1208980.3], [2653231.6, 1211204.7], [2652588.9, 1207230.8], [2651567.1, 1208980.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2022', ortbez27: 'Bevaix' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554088.7, 1193486.4],
                [2550715.1, 1196494.9],
                [2549332.8, 1199698.6],
                [2553620.4, 1199336.4],
                [2556418, 1195691.5],
                [2554088.7, 1193486.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6106', ortbez27: 'Werthenstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651134.6, 1212665.3],
                [2653231.6, 1211204.7],
                [2651567.1, 1208980.3],
                [2649583.6, 1209690],
                [2651134.6, 1212665.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2019', ortbez27: 'Rochefort' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554411, 1204847.8],
                [2553686.3, 1203825.1],
                [2553386.9, 1202364.6],
                [2551718, 1201764],
                [2549296, 1201879.8],
                [2549151.5, 1205290],
                [2550033, 1207828.7],
                [2551624.1, 1207890.4],
                [2552504.2, 1206371.7],
                [2554411, 1204847.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6103', ortbez27: 'Schwarzenberg LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659297.6, 1208701.3],
                [2657052.2, 1202716],
                [2655152, 1201779.5],
                [2653527.4, 1204454.9],
                [2651518.3, 1206574.4],
                [2652588.9, 1207230.8],
                [2659297.6, 1208701.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6110', ortbez27: 'Fontannen b. Wolhusen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640293.8, 1208976],
                [2645200.9, 1209861.2],
                [2638970.4, 1206495.5],
                [2638206.2, 1207656.5],
                [2640293.8, 1208976]
              ]
            ],
            [
              [
                [2647203.4, 1212472.9],
                [2647426.2, 1214542.6],
                [2648822.5, 1214425.5],
                [2649967.3, 1213340.5],
                [2651134.6, 1212665.3],
                [2649583.6, 1209690],
                [2647597.9, 1207295.1],
                [2646622.6, 1208460.7],
                [2647560.8, 1209414],
                [2647203.4, 1212472.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2013', ortbez27: 'Colombier NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2561212.2, 1200530.8], [2554941.5, 1200445.4], [2555543, 1203461.4], [2561212.2, 1200530.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2014', ortbez27: 'B�le' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554941.5, 1200445.4],
                [2553386.9, 1202364.6],
                [2553686.3, 1203825.1],
                [2555543, 1203461.4],
                [2554941.5, 1200445.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2012', ortbez27: 'Auvernier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2558580.2, 1203893], [2561212.2, 1200530.8], [2555543, 1203461.4], [2558580.2, 1203893]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6102', ortbez27: 'Malters' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660942.2, 1210220.7],
                [2659297.6, 1208701.3],
                [2652588.9, 1207230.8],
                [2653231.6, 1211204.7],
                [2654529.3, 1211077.3],
                [2655704.2, 1211511.9],
                [2656974.6, 1211869.7],
                [2658203.1, 1212132.3],
                [2660942.2, 1210220.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2000', ortbez27: 'Neuch�tel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563548.2, 1202051.2],
                [2561212.2, 1200530.8],
                [2558580.2, 1203893],
                [2559063.1, 1206244.9],
                [2561004.7, 1207389.1],
                [2561494.1, 1208616.9],
                [2563424.2, 1208312],
                [2565633.5, 1206229.3],
                [2566873.3, 1204201.9],
                [2563548.2, 1202051.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1993', ortbez27: 'Veysonnaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2593609.3, 1116455.6], [2591707.8, 1114955.1], [2592271.8, 1117039.3], [2593609.3, 1116455.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1994', ortbez27: 'Aproz (Nendaz)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589811.9, 1118767.8],
                [2590998.1, 1117314.2],
                [2590924, 1115985.9],
                [2589665.4, 1115960.9],
                [2589811.9, 1118767.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1992', ortbez27: 'Les Mayens-de-Sion' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595151.3, 1117684.6],
                [2596898.4, 1115841.3],
                [2595204.9, 1115425.9],
                [2593609.3, 1116455.6],
                [2593952.6, 1118071.1],
                [2595151.3, 1117684.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1997', ortbez27: 'Haute-Nendaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2593260.1, 1109476.2],
                [2593535.4, 1101135.7],
                [2590407.5, 1102423.1],
                [2587735.4, 1105188.2],
                [2587580.2, 1107236],
                [2589015.2, 1110269.1],
                [2585498.8, 1113775.8],
                [2587011, 1114291.2],
                [2588257, 1115715.7],
                [2589665.4, 1115960.9],
                [2589992.3, 1114722.6],
                [2591707.8, 1114955.1],
                [2593609.3, 1116455.6],
                [2595204.9, 1115425.9],
                [2594633.4, 1114204.5],
                [2594294.3, 1112215.8],
                [2593260.1, 1109476.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6145', ortbez27: 'Fischbach LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633727.8, 1222074.1],
                [2636349.8, 1224693.5],
                [2636235, 1221270.7],
                [2634721.8, 1220477.7],
                [2633727.8, 1222074.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6146', ortbez27: 'Grossdietwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633615.3, 1225266.6],
                [2636058.8, 1225993.8],
                [2636349.8, 1224693.5],
                [2633727.8, 1222074.1],
                [2633615.3, 1225266.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '1996', ortbez27: 'Bieudron (Nendaz)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587011, 1114291.2],
                [2585498.8, 1113775.8],
                [2586155.6, 1115327.2],
                [2587746.4, 1117019.9],
                [2588257, 1115715.7],
                [2587011, 1114291.2]
              ]
            ],
            [
              [
                [2591707.8, 1114955.1],
                [2589992.3, 1114722.6],
                [2589665.4, 1115960.9],
                [2590924, 1115985.9],
                [2590998.1, 1117314.2],
                [2592271.8, 1117039.3],
                [2591707.8, 1114955.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6143', ortbez27: 'Ohmstal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641111.6, 1222226.6],
                [2639471.1, 1222261.3],
                [2636349.8, 1224693.5],
                [2639525.5, 1224456.4],
                [2641111.6, 1222226.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6144', ortbez27: 'Zell LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638580.1, 1219906.3],
                [2636999.9, 1218611.9],
                [2636235, 1221270.7],
                [2636349.8, 1224693.5],
                [2639471.1, 1222261.3],
                [2638580.1, 1219906.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6147', ortbez27: 'Altb�ron' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2632165, 1229263.9],
                [2635798.6, 1228248.3],
                [2636058.8, 1225993.8],
                [2633615.3, 1225266.6],
                [2631981.7, 1227786.3],
                [2632165, 1229263.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2053', ortbez27: 'Cernier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558428.2, 1212730.6],
                [2559847.9, 1213441.6],
                [2560199.3, 1211133.9],
                [2558829.9, 1211462.3],
                [2558428.2, 1212730.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2054', ortbez27: 'Ch�zard-St-Martin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560199.3, 1211133.9],
                [2559847.9, 1213441.6],
                [2560201.5, 1216278],
                [2561943.3, 1217796.6],
                [2564071.9, 1217299],
                [2564154.8, 1215215.1],
                [2562247.2, 1214421.1],
                [2562246.3, 1212213.9],
                [2561718.3, 1210732.5],
                [2560199.3, 1211133.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2052', ortbez27: 'La Vue-des-Alpes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558829.9, 1211462.3],
                [2557741.7, 1210545.6],
                [2557433.7, 1211925.9],
                [2553574.5, 1210880.4],
                [2556192.9, 1214515.7],
                [2558286.6, 1215604.4],
                [2560201.5, 1216278],
                [2559847.9, 1213441.6],
                [2558428.2, 1212730.6],
                [2558829.9, 1211462.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6142', ortbez27: 'Gettnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638580.1, 1219906.3],
                [2639471.1, 1222261.3],
                [2641111.6, 1222226.6],
                [2641546.7, 1220490.1],
                [2638580.1, 1219906.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2046', ortbez27: 'Fontaines NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560199.3, 1211133.9],
                [2559907, 1209158.1],
                [2557741.7, 1210545.6],
                [2558829.9, 1211462.3],
                [2560199.3, 1211133.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6130', ortbez27: 'Willisau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641652.4, 1212025.6],
                [2640805.9, 1214064.2],
                [2640730.8, 1215354.2],
                [2640786, 1216607.7],
                [2639540.4, 1216716.1],
                [2638279.3, 1216763.8],
                [2637022.5, 1216671.5],
                [2636999.9, 1218611.9],
                [2638580.1, 1219906.3],
                [2641546.7, 1220490.1],
                [2643070.2, 1220920.1],
                [2645602.6, 1217646.3],
                [2644470.9, 1216341.1],
                [2643512, 1218131.4],
                [2642327.1, 1217617.9],
                [2642597.7, 1213174],
                [2641652.4, 1212025.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2043', ortbez27: 'Boudevilliers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557654.3, 1206348.4],
                [2555838.8, 1208592.1],
                [2557741.7, 1210545.6],
                [2559907, 1209158.1],
                [2559063.1, 1206244.9],
                [2557654.3, 1206348.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6133', ortbez27: 'Hergiswil b. Willisau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638206.2, 1207656.5],
                [2637541.9, 1211496.5],
                [2637022.5, 1216671.5],
                [2638279.3, 1216763.8],
                [2639540.4, 1216716.1],
                [2640786, 1216607.7],
                [2640730.8, 1215354.2],
                [2640805.9, 1214064.2],
                [2641652.4, 1212025.6],
                [2640293.8, 1208976],
                [2638206.2, 1207656.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2034', ortbez27: 'Peseux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2559063.1, 1206244.9], [2558580.2, 1203893], [2557654.3, 1206348.4], [2559063.1, 1206244.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6122', ortbez27: 'Menznau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647426.2, 1214542.6],
                [2647203.4, 1212472.9],
                [2644716.2, 1211629.1],
                [2642597.7, 1213174],
                [2644470.9, 1216341.1],
                [2645602.6, 1217646.3],
                [2647426.2, 1214542.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2035', ortbez27: 'Corcelles NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2557654.3, 1206348.4],
                [2558580.2, 1203893],
                [2555543, 1203461.4],
                [2553686.3, 1203825.1],
                [2554411, 1204847.8],
                [2557654.3, 1206348.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2025', ortbez27: 'Chez-le-Bart' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2554088.7, 1193486.4],
                [2552230.1, 1191727],
                [2549935.8, 1194491.3],
                [2550715.1, 1196494.9],
                [2554088.7, 1193486.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6125', ortbez27: 'Menzberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2645200.9, 1209861.2],
                [2640293.8, 1208976],
                [2641652.4, 1212025.6],
                [2642597.7, 1213174],
                [2644716.2, 1211629.1],
                [2645200.9, 1209861.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6126', ortbez27: 'Daiwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644470.9, 1216341.1],
                [2642597.7, 1213174],
                [2642327.1, 1217617.9],
                [2643512, 1218131.4],
                [2644470.9, 1216341.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2023', ortbez27: 'Gorgier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2550715.1, 1196494.9],
                [2549935.8, 1194491.3],
                [2548399, 1196230.1],
                [2545257, 1199198.8],
                [2547610.6, 1199126.9],
                [2549332.8, 1199698.6],
                [2550715.1, 1196494.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2024', ortbez27: 'St-Aubin-Sauges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2549935.8, 1194491.3],
                [2552230.1, 1191727],
                [2547848.7, 1191158.8],
                [2546770.3, 1192392.1],
                [2546798.9, 1193854.2],
                [2548000.8, 1194209.2],
                [2548399, 1196230.1],
                [2549935.8, 1194491.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2027', ortbez27: 'Montalchez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546798.9, 1193854.2],
                [2545136.3, 1196483.7],
                [2545257, 1199198.8],
                [2548399, 1196230.1],
                [2548000.8, 1194209.2],
                [2546798.9, 1193854.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2028', ortbez27: 'Vaumarcus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2552230.1, 1191727], [2549813.3, 1189439.4], [2547848.7, 1191158.8], [2552230.1, 1191727]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6182', ortbez27: 'Escholzmatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2636789.5, 1199228.1],
                [2637608.8, 1200201.3],
                [2639172.3, 1203682.3],
                [2639297.1, 1200631.3],
                [2642987, 1196830.1],
                [2640583.9, 1194182.9],
                [2638779.1, 1192366],
                [2635816.2, 1196115.5],
                [2633941.3, 1195300.9],
                [2632849.6, 1196430.8],
                [2636789.5, 1199228.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6170', ortbez27: 'Sch�pfheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643312.7, 1203508.5],
                [2649328.3, 1197488.1],
                [2647578.3, 1195720],
                [2642987, 1196830.1],
                [2639297.1, 1200631.3],
                [2639172.3, 1203682.3],
                [2643312.7, 1203508.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6167', ortbez27: 'Bramboden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2639172.3, 1203682.3], [2638970.4, 1206495.5], [2643312.7, 1203508.5], [2639172.3, 1203682.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2073', ortbez27: 'Enges' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569262.4, 1213193.3],
                [2566114.5, 1211412],
                [2565485.4, 1212641.9],
                [2567326.9, 1215290.4],
                [2569512.8, 1214958.4],
                [2569262.4, 1213193.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6173', ortbez27: 'Fl�hli LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640583.9, 1194182.9],
                [2642987, 1196830.1],
                [2647578.3, 1195720],
                [2648177.4, 1194185.8],
                [2648244.1, 1192166.3],
                [2646122.6, 1187582.9],
                [2642925.6, 1190096.4],
                [2640583.9, 1194182.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2074', ortbez27: 'Marin-Epagnier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569481.7, 1203468],
                [2566873.3, 1204201.9],
                [2565633.5, 1206229.3],
                [2567658.3, 1207722.9],
                [2568886.3, 1208417.9],
                [2569159.8, 1207122],
                [2569481.7, 1203468]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6174', ortbez27: 'S�renberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639177.4, 1183438.6],
                [2639911, 1187480.2],
                [2642925.6, 1190096.4],
                [2646122.6, 1187582.9],
                [2649093.8, 1184787.2],
                [2648211.4, 1182280.3],
                [2646526.9, 1182096.5],
                [2641773.6, 1180713.4],
                [2641551.2, 1181996.9],
                [2639177.4, 1183438.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2072', ortbez27: 'St-Blaise' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2566114.5, 1211412],
                [2569262.4, 1213193.3],
                [2567680.3, 1210590.5],
                [2568886.3, 1208417.9],
                [2567658.3, 1207722.9],
                [2565633.5, 1206229.3],
                [2563424.2, 1208312],
                [2563022.2, 1209685.1],
                [2566114.5, 1211412]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6162', ortbez27: 'Rengg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653527.4, 1204454.9],
                [2655152, 1201779.5],
                [2654772.5, 1198430.6],
                [2651338.4, 1193878.9],
                [2650064.2, 1196007.8],
                [2648177.4, 1194185.8],
                [2647578.3, 1195720],
                [2649328.3, 1197488.1],
                [2650390.1, 1198156.9],
                [2651351.2, 1199058],
                [2651359.2, 1200324.2],
                [2651283.7, 1201628.1],
                [2650105.7, 1202089.6],
                [2648803.3, 1202226.7],
                [2647886.8, 1203117.9],
                [2647399.2, 1204270.3],
                [2648713.9, 1206334.3],
                [2651567.1, 1208980.3],
                [2652588.9, 1207230.8],
                [2651518.3, 1206574.4],
                [2653527.4, 1204454.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2065', ortbez27: 'Savagnier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565485.4, 1212641.9],
                [2563022.2, 1209685.1],
                [2561718.3, 1210732.5],
                [2562246.3, 1212213.9],
                [2565485.4, 1212641.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6166', ortbez27: 'Hasle LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643312.7, 1203508.5],
                [2645431.9, 1205695.4],
                [2647399.2, 1204270.3],
                [2647886.8, 1203117.9],
                [2648803.3, 1202226.7],
                [2650105.7, 1202089.6],
                [2651283.7, 1201628.1],
                [2651359.2, 1200324.2],
                [2651351.2, 1199058],
                [2650390.1, 1198156.9],
                [2649328.3, 1197488.1],
                [2643312.7, 1203508.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2063', ortbez27: 'Engollon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561494.1, 1208616.9],
                [2561004.7, 1207389.1],
                [2559063.1, 1206244.9],
                [2559907, 1209158.1],
                [2560199.3, 1211133.9],
                [2561718.3, 1210732.5],
                [2563022.2, 1209685.1],
                [2561494.1, 1208616.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6163', ortbez27: 'Ebnet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647597.9, 1207295.1],
                [2649583.6, 1209690],
                [2651567.1, 1208980.3],
                [2648713.9, 1206334.3],
                [2647597.9, 1207295.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6153', ortbez27: 'Ufhusen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2637022.5, 1216671.5],
                [2633516.8, 1214440.7],
                [2633076.5, 1219470.1],
                [2634721.8, 1220477.7],
                [2636999.9, 1218611.9],
                [2637022.5, 1216671.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2067', ortbez27: 'Chaumont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2561494.1, 1208616.9], [2563022.2, 1209685.1], [2563424.2, 1208312], [2561494.1, 1208616.9]]],
            [[[2563022.2, 1209685.1], [2565485.4, 1212641.9], [2566114.5, 1211412], [2563022.2, 1209685.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6154', ortbez27: 'Hofstatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2633516.8, 1214440.7],
                [2637022.5, 1216671.5],
                [2632653, 1211899.9],
                [2633130, 1213097.1],
                [2633516.8, 1214440.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2057', ortbez27: 'Villiers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2564154.8, 1215215.1],
                [2567327.2, 1217179.4],
                [2567326.9, 1215290.4],
                [2565485.4, 1212641.9],
                [2564154.8, 1215215.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6152', ortbez27: 'H�swil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2636235, 1221270.7], [2636999.9, 1218611.9], [2634721.8, 1220477.7], [2636235, 1221270.7]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2058', ortbez27: 'Le P�quier NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565608.8, 1218660],
                [2568365.1, 1218839.6],
                [2567327.2, 1217179.4],
                [2564154.8, 1215215.1],
                [2564071.9, 1217299],
                [2565608.8, 1218660]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2056', ortbez27: 'Dombresson' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2564154.8, 1215215.1],
                [2565485.4, 1212641.9],
                [2562246.3, 1212213.9],
                [2562247.2, 1214421.1],
                [2564154.8, 1215215.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6156', ortbez27: 'Luthern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2637541.9, 1211496.5],
                [2638206.2, 1207656.5],
                [2635301.4, 1206384.6],
                [2634572.2, 1209018.2],
                [2633062.5, 1210623.8],
                [2632653, 1211899.9],
                [2637022.5, 1216671.5],
                [2637541.9, 1211496.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6210', ortbez27: 'Sursee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2650943.6, 1226888.3],
                [2652475, 1224344.5],
                [2649847.9, 1224382.5],
                [2648877.9, 1225821.8],
                [2649575.2, 1227136.8],
                [2650943.6, 1226888.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6207', ortbez27: 'Nottwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655163.1, 1221073.2],
                [2656140.1, 1220065.4],
                [2655048, 1218247.8],
                [2652497.8, 1218228.6],
                [2650376.7, 1221148.8],
                [2653437.9, 1222853],
                [2655163.1, 1221073.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6208', ortbez27: 'Oberkirch LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653437.9, 1222853],
                [2650376.7, 1221148.8],
                [2648394.4, 1223220.4],
                [2649847.9, 1224382.5],
                [2652475, 1224344.5],
                [2653437.9, 1222853]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2113', ortbez27: 'Boveresse' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2537086.9, 1196754.6], [2536078.4, 1195533.1], [2534723.8, 1196250.2], [2537086.9, 1196754.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6213', ortbez27: 'Knutwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2646245.9, 1226995.9],
                [2646393, 1229668.3],
                [2648460.4, 1229958.2],
                [2649575.2, 1227136.8],
                [2647504.6, 1226458.5],
                [2646245.9, 1226995.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2114', ortbez27: 'Fleurier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2536078.4, 1195533.1],
                [2538215.9, 1193531.8],
                [2533301.5, 1192119.5],
                [2533731.9, 1195017.5],
                [2534723.8, 1196250.2],
                [2536078.4, 1195533.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6214', ortbez27: 'Schenkon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2652705.1, 1227588],
                [2653713.9, 1226520.5],
                [2654467.5, 1224836.6],
                [2653437.9, 1222853],
                [2652475, 1224344.5],
                [2650943.6, 1226888.3],
                [2652705.1, 1227588]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6211', ortbez27: 'Buchs LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644060.9, 1227140.3],
                [2644149.4, 1228544.7],
                [2646393, 1229668.3],
                [2646245.9, 1226995.9],
                [2644060.9, 1227140.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2112', ortbez27: 'M�tiers NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2538215.9, 1193531.8],
                [2536078.4, 1195533.1],
                [2537086.9, 1196754.6],
                [2539137.7, 1195825.3],
                [2538215.9, 1193531.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6212', ortbez27: 'St. Erhard' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649575.2, 1227136.8],
                [2648877.9, 1225821.8],
                [2647681.5, 1225095.2],
                [2647504.6, 1226458.5],
                [2649575.2, 1227136.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2117', ortbez27: 'La C�te-aux-F�es' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2529183.6, 1189720],
                [2524843.9, 1188613.3],
                [2525687.2, 1192214.5],
                [2528242.6, 1192895.3],
                [2529573.1, 1191183.2],
                [2529183.6, 1189720]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2115', ortbez27: 'Buttes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2529183.6, 1189720],
                [2529573.1, 1191183.2],
                [2532156.1, 1194877],
                [2533731.9, 1195017.5],
                [2533301.5, 1192119.5],
                [2530587.8, 1190280.4],
                [2529183.6, 1189720]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2116', ortbez27: 'Mont-de-Buttes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2529573.1, 1191183.2],
                [2528242.6, 1192895.3],
                [2529590.8, 1194735.6],
                [2532156.1, 1194877],
                [2529573.1, 1191183.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2105', ortbez27: 'Travers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2543645.5, 1202821.2],
                [2545257, 1199198.8],
                [2540933.5, 1196718.3],
                [2539174.2, 1201207.6],
                [2541439.2, 1203228.9],
                [2543645.5, 1202821.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6205', ortbez27: 'Eich' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653437.9, 1222853],
                [2654467.5, 1224836.6],
                [2657709.4, 1223832.4],
                [2655163.1, 1221073.2],
                [2653437.9, 1222853]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6206', ortbez27: 'Neuenkirch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656390.9, 1215026.1],
                [2655048, 1218247.8],
                [2660258.9, 1217909.2],
                [2660632.6, 1215933.9],
                [2656390.9, 1215026.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2103', ortbez27: 'Noiraigue' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2545257, 1199198.8],
                [2543645.5, 1202821.2],
                [2546918.6, 1201405.6],
                [2548459.6, 1200898.5],
                [2547610.6, 1199126.9],
                [2545257, 1199198.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6203', ortbez27: 'Sempach Station' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655048, 1218247.8],
                [2656140.1, 1220065.4],
                [2660228.4, 1220656.3],
                [2660806.1, 1219430.8],
                [2660258.9, 1217909.2],
                [2655048, 1218247.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6204', ortbez27: 'Sempach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2656140.1, 1220065.4],
                [2655163.1, 1221073.2],
                [2657709.4, 1223832.4],
                [2660228.4, 1220656.3],
                [2656140.1, 1220065.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2108', ortbez27: 'Couvet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2545136.3, 1196483.7],
                [2539349, 1192875.5],
                [2538215.9, 1193531.8],
                [2539137.7, 1195825.3],
                [2537086.9, 1196754.6],
                [2536827.4, 1198068.5],
                [2537875.7, 1201359.7],
                [2539174.2, 1201207.6],
                [2540933.5, 1196718.3],
                [2545257, 1199198.8],
                [2545136.3, 1196483.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6192', ortbez27: 'Wiggen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2640583.9, 1194182.9],
                [2642925.6, 1190096.4],
                [2639911, 1187480.2],
                [2639434, 1190375.1],
                [2638779.1, 1192366],
                [2640583.9, 1194182.9]
              ]
            ],
            [[[2638779.1, 1192366], [2633941.3, 1195300.9], [2635816.2, 1196115.5], [2638779.1, 1192366]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6197', ortbez27: 'Schangnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2629942, 1183500.9],
                [2629033, 1184765.7],
                [2632962.5, 1188682.5],
                [2635817.2, 1186513.8],
                [2639177.4, 1183438.6],
                [2632779.5, 1181839.2],
                [2629942, 1183500.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6196', ortbez27: 'Marbach LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639911, 1187480.2],
                [2639177.4, 1183438.6],
                [2635817.2, 1186513.8],
                [2632962.5, 1188682.5],
                [2631955.9, 1192355.8],
                [2633941.3, 1195300.9],
                [2638779.1, 1192366],
                [2639434, 1190375.1],
                [2639911, 1187480.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2087', ortbez27: 'Cornaux NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2567680.3, 1210590.5], [2569562.5, 1209506.5], [2568886.3, 1208417.9], [2567680.3, 1210590.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2088', ortbez27: 'Cressier NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567680.3, 1210590.5],
                [2569262.4, 1213193.3],
                [2570789.6, 1210471.1],
                [2569562.5, 1209506.5],
                [2567680.3, 1210590.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6242', ortbez27: 'Wauwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2644060.9, 1227140.3],
                [2646245.9, 1226995.9],
                [2647504.6, 1226458.5],
                [2647681.5, 1225095.2],
                [2644334, 1224982.2],
                [2644060.9, 1227140.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6245', ortbez27: 'Ebersecken' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2638123.7, 1227716],
                [2639154.8, 1226599.9],
                [2639525.5, 1224456.4],
                [2636349.8, 1224693.5],
                [2636058.8, 1225993.8],
                [2635798.6, 1228248.3],
                [2638123.7, 1227716]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6246', ortbez27: 'Altishofen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639920.1, 1229937.4],
                [2640727.3, 1228239.4],
                [2639154.8, 1226599.9],
                [2638123.7, 1227716],
                [2639920.1, 1229937.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6243', ortbez27: 'Egolzwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2644060.9, 1227140.3], [2644334, 1224982.2], [2641212.9, 1226109.1], [2644060.9, 1227140.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2149', ortbez27: 'Champ-du-Moulin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2547610.6, 1199126.9], [2548459.6, 1200898.5], [2549332.8, 1199698.6], [2547610.6, 1199126.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6244', ortbez27: 'Nebikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639154.8, 1226599.9],
                [2640727.3, 1228239.4],
                [2644060.9, 1227140.3],
                [2641212.9, 1226109.1],
                [2639154.8, 1226599.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6233', ortbez27: 'B�ron' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2649575.2, 1227136.8],
                [2648460.4, 1229958.2],
                [2649858.5, 1231267.9],
                [2651295.9, 1229598.6],
                [2649575.2, 1227136.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6234', ortbez27: 'Triengen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2646393, 1229668.3],
                [2647344.7, 1233200.1],
                [2648608.7, 1233216.2],
                [2649460.1, 1235817.3],
                [2650602.1, 1233484],
                [2649858.5, 1231267.9],
                [2648460.4, 1229958.2],
                [2646393, 1229668.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6231', ortbez27: 'Schlierbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2651295.9, 1229598.6],
                [2649858.5, 1231267.9],
                [2650602.1, 1233484],
                [2651998, 1232651.3],
                [2653302.7, 1228729.1],
                [2651295.9, 1229598.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6232', ortbez27: 'Geuensee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2652705.1, 1227588],
                [2650943.6, 1226888.3],
                [2649575.2, 1227136.8],
                [2651295.9, 1229598.6],
                [2653302.7, 1228729.1],
                [2652705.1, 1227588]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6235', ortbez27: 'Winikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2643772.5, 1232896.1],
                [2647344.7, 1233200.1],
                [2646393, 1229668.3],
                [2644978.2, 1231044.9],
                [2642636.8, 1231940.1],
                [2643772.5, 1232896.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2127', ortbez27: 'Les Bayards' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2528679.8, 1198596.6],
                [2534724.4, 1200424.6],
                [2534723.8, 1196250.2],
                [2533731.9, 1195017.5],
                [2532156.1, 1194877],
                [2529590.8, 1194735.6],
                [2528679.8, 1198596.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6217', ortbez27: 'Kottwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2648394.4, 1223220.4],
                [2645063.1, 1221817.4],
                [2644334, 1224982.2],
                [2647681.5, 1225095.2],
                [2648394.4, 1223220.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6218', ortbez27: 'Ettiswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642761.1, 1223507.6],
                [2644334, 1224982.2],
                [2645063.1, 1221817.4],
                [2643070.2, 1220920.1],
                [2642761.1, 1223507.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6215', ortbez27: 'Schwarzenbach LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2659626.1, 1227721.5],
                [2657425.1, 1226988.1],
                [2655926.8, 1230520.7],
                [2657166, 1230846.2],
                [2658223.9, 1233643],
                [2659688.5, 1235680.7],
                [2660584.3, 1233203.5],
                [2659157.8, 1231907],
                [2659242.3, 1230605.3],
                [2659896.7, 1229162.8],
                [2659626.1, 1227721.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6216', ortbez27: 'Mauensee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2647681.5, 1225095.2],
                [2648877.9, 1225821.8],
                [2649847.9, 1224382.5],
                [2648394.4, 1223220.4],
                [2647681.5, 1225095.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6221', ortbez27: 'Rickenbach LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2653713.9, 1226520.5],
                [2652705.1, 1227588],
                [2653302.7, 1228729.1],
                [2651998, 1232651.3],
                [2653379.2, 1233318.8],
                [2654390.9, 1232454.2],
                [2655926.8, 1230520.7],
                [2653713.9, 1226520.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6222', ortbez27: 'Gunzwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2655926.8, 1230520.7],
                [2657425.1, 1226988.1],
                [2657709.4, 1223832.4],
                [2654467.5, 1224836.6],
                [2653713.9, 1226520.5],
                [2655926.8, 1230520.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2126', ortbez27: 'Les Verri�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2526444.9, 1201112.8],
                [2528679.8, 1198596.6],
                [2529590.8, 1194735.6],
                [2528242.6, 1192895.3],
                [2525687.2, 1192214.5],
                [2526444.9, 1201112.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6274', ortbez27: 'Eschenbach LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666301.7, 1217807.9],
                [2664289.2, 1218916],
                [2664538, 1221664.3],
                [2666149.1, 1222453.8],
                [2668502.3, 1221888.4],
                [2666301.7, 1217807.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2124', ortbez27: 'Les Sagnettes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2534723.8, 1196250.2],
                [2534724.4, 1200424.6],
                [2534867.5, 1201682.4],
                [2537875.7, 1201359.7],
                [2536827.4, 1198068.5],
                [2537086.9, 1196754.6],
                [2534723.8, 1196250.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6277', ortbez27: 'Kleinwangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664766.1, 1226092.3],
                [2663520.6, 1227990.7],
                [2664509.4, 1229610.9],
                [2666472, 1231606.9],
                [2667238.1, 1229838.4],
                [2667691.1, 1228474.5],
                [2664766.1, 1226092.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6275', ortbez27: 'Ballwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2670245.5, 1222227.4],
                [2668502.3, 1221888.4],
                [2666149.1, 1222453.8],
                [2665522.5, 1223574],
                [2668857.4, 1224905.5],
                [2669807, 1223427.3],
                [2670245.5, 1222227.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6276', ortbez27: 'Hohenrain' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668857.4, 1224905.5],
                [2665522.5, 1223574],
                [2664766.1, 1226092.3],
                [2667691.1, 1228474.5],
                [2668339.2, 1226468.3],
                [2668857.4, 1224905.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6265', ortbez27: 'Roggliswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635798.6, 1228248.3],
                [2632165, 1229263.9],
                [2632643.3, 1230938.5],
                [2636739.5, 1230733.5],
                [2635798.6, 1228248.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6263', ortbez27: 'Richenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2635798.6, 1228248.3],
                [2636739.5, 1230733.5],
                [2639920.1, 1229937.4],
                [2638123.7, 1227716],
                [2635798.6, 1228248.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6264', ortbez27: 'Pfaffnau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2637229.4, 1231994.6],
                [2636739.5, 1230733.5],
                [2632643.3, 1230938.5],
                [2633239.3, 1232262.6],
                [2634640.7, 1233020.8],
                [2637229.4, 1231994.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6262', ortbez27: 'Langnau b. Reiden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2637229.4, 1231994.6],
                [2638739.4, 1233054.9],
                [2640929.7, 1231940.7],
                [2639920.1, 1229937.4],
                [2636739.5, 1230733.5],
                [2637229.4, 1231994.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6260', ortbez27: 'Reidermoos' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642636.8, 1231940.1],
                [2640929.7, 1231940.7],
                [2638739.4, 1233054.9],
                [2639663.1, 1234022.3],
                [2640889.6, 1233756.3],
                [2641787.1, 1234840.6],
                [2641561, 1236420.9],
                [2642920.2, 1236241.3],
                [2644071.7, 1235740.7],
                [2643816.2, 1234332.2],
                [2643772.5, 1232896.1],
                [2642636.8, 1231940.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6247', ortbez27: 'Sch�tz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2639525.5, 1224456.4],
                [2639154.8, 1226599.9],
                [2641212.9, 1226109.1],
                [2644334, 1224982.2],
                [2642761.1, 1223507.6],
                [2641111.6, 1222226.6],
                [2639525.5, 1224456.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6248', ortbez27: 'Alberswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2641546.7, 1220490.1],
                [2641111.6, 1222226.6],
                [2642761.1, 1223507.6],
                [2643070.2, 1220920.1],
                [2641546.7, 1220490.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6253', ortbez27: 'Uffikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2646393, 1229668.3],
                [2644149.4, 1228544.7],
                [2642940.7, 1229962.9],
                [2644978.2, 1231044.9],
                [2646393, 1229668.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6252', ortbez27: 'Dagmersellen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2642636.8, 1231940.1],
                [2644978.2, 1231044.9],
                [2642940.7, 1229962.9],
                [2644149.4, 1228544.7],
                [2644060.9, 1227140.3],
                [2640727.3, 1228239.4],
                [2639920.1, 1229937.4],
                [2640929.7, 1231940.7],
                [2642636.8, 1231940.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2207', ortbez27: 'Coffrane' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2557654.3, 1206348.4], [2554411, 1204847.8], [2555838.8, 1208592.1], [2557654.3, 1206348.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2208', ortbez27: 'Les Hauts-Geneveys' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2553574.5, 1210880.4],
                [2557433.7, 1211925.9],
                [2557741.7, 1210545.6],
                [2555838.8, 1208592.1],
                [2552839.9, 1209665.9],
                [2553574.5, 1210880.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6300', ortbez27: 'Zug' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683508, 1225224.4],
                [2684214.8, 1223599.9],
                [2684041, 1219849.6],
                [2681306.9, 1220030.6],
                [2682037.4, 1222718.9],
                [2679782.2, 1222163.7],
                [2678567.4, 1224719.7],
                [2678971.4, 1227162.2],
                [2680518.5, 1227238.3],
                [2683508, 1225224.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6289', ortbez27: 'M�swangen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2666988, 1232867.6],
                [2666472, 1231606.9],
                [2664535.3, 1231577.6],
                [2662679.9, 1232003.6],
                [2662591.7, 1234122.6],
                [2664951.9, 1235021.2],
                [2665789, 1233829.8],
                [2666988, 1232867.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2206', ortbez27: 'Les Geneveys-sur-Coffrane' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551624.1, 1207890.4],
                [2552839.9, 1209665.9],
                [2555838.8, 1208592.1],
                [2554411, 1204847.8],
                [2552504.2, 1206371.7],
                [2551624.1, 1207890.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6287', ortbez27: 'Aesch LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660489.4, 1237013.9],
                [2662591.7, 1234122.6],
                [2660584.3, 1233203.5],
                [2659688.5, 1235680.7],
                [2660489.4, 1237013.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6288', ortbez27: 'Schongau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664503.3, 1237468.8],
                [2664951.9, 1235021.2],
                [2662591.7, 1234122.6],
                [2660489.4, 1237013.9],
                [2661852.2, 1237738.1],
                [2664503.3, 1237468.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6294', ortbez27: 'Ermensee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2660584.3, 1233203.5],
                [2662679.9, 1232003.6],
                [2661287.6, 1229701.9],
                [2659896.7, 1229162.8],
                [2659242.3, 1230605.3],
                [2659157.8, 1231907],
                [2660584.3, 1233203.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6280', ortbez27: 'Hochdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2665522.5, 1223574],
                [2666149.1, 1222453.8],
                [2664538, 1221664.3],
                [2662692, 1223077.2],
                [2663019.7, 1224696.8],
                [2664766.1, 1226092.3],
                [2665522.5, 1223574]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6285', ortbez27: 'Hitzkirch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2661287.6, 1229701.9],
                [2661714.8, 1227104.8],
                [2660251.6, 1226254.2],
                [2659626.1, 1227721.5],
                [2659896.7, 1229162.8],
                [2661287.6, 1229701.9]
              ]
            ],
            [
              [
                [2664535.3, 1231577.6],
                [2663258.5, 1230341.3],
                [2661287.6, 1229701.9],
                [2662679.9, 1232003.6],
                [2664535.3, 1231577.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6286', ortbez27: 'Altwis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2660584.3, 1233203.5], [2662591.7, 1234122.6], [2662679.9, 1232003.6], [2660584.3, 1233203.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6283', ortbez27: 'Baldegg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2663520.6, 1227990.7],
                [2664766.1, 1226092.3],
                [2663019.7, 1224696.8],
                [2661714.8, 1227104.8],
                [2663520.6, 1227990.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6284', ortbez27: 'Sulz LU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664509.4, 1229610.9],
                [2663520.6, 1227990.7],
                [2661714.8, 1227104.8],
                [2661287.6, 1229701.9],
                [2663258.5, 1230341.3],
                [2664535.3, 1231577.6],
                [2666472, 1231606.9],
                [2664509.4, 1229610.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6340', ortbez27: 'Baar' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683508, 1225224.4],
                [2680518.5, 1227238.3],
                [2679636.1, 1230440.7],
                [2681621.6, 1230553.1],
                [2683192.5, 1230703.6],
                [2683486.4, 1231947.9],
                [2685373, 1231988.6],
                [2686726, 1231112.8],
                [2686899.9, 1229637.2],
                [2685680.5, 1227192.5],
                [2685100.5, 1225360.7],
                [2683508, 1225224.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6330', ortbez27: 'Cham' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678971.4, 1227162.2],
                [2678567.4, 1224719.7],
                [2675738.5, 1226331.5],
                [2674851.9, 1227555.7],
                [2674337.6, 1229346.6],
                [2676032, 1230658.3],
                [2678325.9, 1229672.6],
                [2678971.4, 1227162.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6333', ortbez27: 'H�nenberg See' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2675738.5, 1226331.5], [2678567.4, 1224719.7], [2675518.9, 1223595.7], [2675738.5, 1226331.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6331', ortbez27: 'H�nenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672970.5, 1226434.2],
                [2672631.8, 1230486.1],
                [2674115.2, 1230586.1],
                [2674337.6, 1229346.6],
                [2674851.9, 1227555.7],
                [2675738.5, 1226331.5],
                [2675518.9, 1223595.7],
                [2674061.2, 1223508.2],
                [2672970.5, 1226434.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6319', ortbez27: 'Allenwinden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2683508, 1225224.4], [2685100.5, 1225360.7], [2684214.8, 1223599.9], [2683508, 1225224.4]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6313', ortbez27: 'Finstersee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690990.5, 1225910.9],
                [2693935, 1224194.4],
                [2690431.2, 1223153.1],
                [2687517.9, 1222953.1],
                [2686122.3, 1222966],
                [2684214.8, 1223599.9],
                [2685100.5, 1225360.7],
                [2685680.5, 1227192.5],
                [2687249.3, 1227208.2],
                [2688931.5, 1227233.9],
                [2690990.5, 1225910.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6314', ortbez27: 'Unter�geri' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686122.3, 1222966],
                [2687517.9, 1222953.1],
                [2690340.9, 1219598.3],
                [2689480.2, 1217968.4],
                [2686569, 1215606.8],
                [2684041, 1219849.6],
                [2684214.8, 1223599.9],
                [2686122.3, 1222966]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6312', ortbez27: 'Steinhausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679636.1, 1230440.7],
                [2680518.5, 1227238.3],
                [2678971.4, 1227162.2],
                [2678325.9, 1229672.6],
                [2679636.1, 1230440.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6317', ortbez27: 'Oberwil b. Zug' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679782.2, 1222163.7],
                [2682037.4, 1222718.9],
                [2681306.9, 1220030.6],
                [2680157, 1218769.5],
                [2679124.3, 1220156.2],
                [2678260.3, 1221106.7],
                [2679782.2, 1222163.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6318', ortbez27: 'Walchwil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684041, 1219849.6],
                [2686569, 1215606.8],
                [2681263.7, 1216689.4],
                [2680125, 1217262.2],
                [2680157, 1218769.5],
                [2681306.9, 1220030.6],
                [2684041, 1219849.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6315', ortbez27: 'Alosen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2689480.2, 1217968.4], [2690340.9, 1219598.3], [2691983.1, 1217795.6], [2689480.2, 1217968.4]]],
            [
              [
                [2690340.9, 1219598.3],
                [2687517.9, 1222953.1],
                [2690431.2, 1223153.1],
                [2693935, 1224194.4],
                [2694072.5, 1221755.4],
                [2690340.9, 1219598.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6370', ortbez27: 'Oberdorf NW' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668581, 1198118.9],
                [2668804.3, 1202174.9],
                [2669784.1, 1203024.2],
                [2672310, 1202958.2],
                [2674126.6, 1200932.3],
                [2672999.5, 1198291.6],
                [2671002.5, 1198936.5],
                [2668581, 1198118.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6373', ortbez27: 'Ennetb�rgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2675301.3, 1207973.1],
                [2677628.3, 1207349.6],
                [2678387.2, 1205712.9],
                [2678182.1, 1204347.8],
                [2672310, 1202958.2],
                [2671305.1, 1205651.6],
                [2672543.5, 1205992.1],
                [2675286.1, 1205926.1],
                [2675301.3, 1207973.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6374', ortbez27: 'Buochs' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672310, 1202958.2],
                [2678182.1, 1204347.8],
                [2675761.1, 1199797.3],
                [2674126.6, 1200932.3],
                [2672310, 1202958.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6372', ortbez27: 'Ennetmoos' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2664780.1, 1198585.1],
                [2666114.6, 1201027.4],
                [2668804.3, 1202174.9],
                [2668581, 1198118.9],
                [2664780.1, 1198585.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6362', ortbez27: 'Stansstad' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668804.3, 1202174.9],
                [2666114.6, 1201027.4],
                [2666165.9, 1203273.7],
                [2668225.9, 1204752.7],
                [2669756.2, 1204549.3],
                [2669784.1, 1203024.2],
                [2668804.3, 1202174.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6365', ortbez27: 'Kehrsiten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2671305.1, 1205651.6],
                [2669756.2, 1204549.3],
                [2668225.9, 1204752.7],
                [2668242.3, 1207917.6],
                [2669670.6, 1208448.2],
                [2671941.4, 1207988.1],
                [2673666.1, 1207773.1],
                [2675301.3, 1207973.1],
                [2675286.1, 1205926.1],
                [2672543.5, 1205992.1],
                [2671305.1, 1205651.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6363', ortbez27: 'Obb�rgen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672310, 1202958.2],
                [2669784.1, 1203024.2],
                [2669756.2, 1204549.3],
                [2671305.1, 1205651.6],
                [2672310, 1202958.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6353', ortbez27: 'Weggis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2677628.3, 1207349.6],
                [2675301.3, 1207973.1],
                [2673666.1, 1207773.1],
                [2671941.4, 1207988.1],
                [2669670.6, 1208448.2],
                [2674015.5, 1212125.3],
                [2676909.3, 1211341.7],
                [2677628.3, 1207349.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6354', ortbez27: 'Vitznau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681830.8, 1208050.7],
                [2678387.2, 1205712.9],
                [2677628.3, 1207349.6],
                [2676909.3, 1211341.7],
                [2681380.1, 1209601.9],
                [2681830.8, 1208050.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6345', ortbez27: 'Neuheim' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689461.9, 1228645.3],
                [2688931.5, 1227233.9],
                [2687249.3, 1227208.2],
                [2685680.5, 1227192.5],
                [2686899.9, 1229637.2],
                [2689461.9, 1228645.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6343', ortbez27: 'Rotkreuz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2678260.3, 1221106.7], [2679124.3, 1220156.2], [2678128.5, 1219309.1], [2678260.3, 1221106.7]]],
            [
              [
                [2676752.4, 1221225.4],
                [2674572.4, 1219686.2],
                [2673932.1, 1221672.9],
                [2674061.2, 1223508.2],
                [2675518.9, 1223595.7],
                [2678567.4, 1224719.7],
                [2679782.2, 1222163.7],
                [2678260.3, 1221106.7],
                [2676752.4, 1221225.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6344', ortbez27: 'Meierskappel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674572.4, 1219686.2],
                [2676752.4, 1221225.4],
                [2678260.3, 1221106.7],
                [2678128.5, 1219309.1],
                [2674620.8, 1217370],
                [2674572.4, 1219686.2]
              ]
            ],
            [[[2679124.3, 1220156.2], [2680157, 1218769.5], [2678128.5, 1219309.1], [2679124.3, 1220156.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6402', ortbez27: 'Merlischachen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2673734, 1215151.6],
                [2675405.3, 1213335.4],
                [2674015.5, 1212125.3],
                [2672301.5, 1213593.2],
                [2673734, 1215151.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6405', ortbez27: 'Immensee' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679396.2, 1213825.8],
                [2678128.5, 1219309.1],
                [2680157, 1218769.5],
                [2680125, 1217262.2],
                [2681263.7, 1216689.4],
                [2679396.2, 1213825.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6403', ortbez27: 'K�ssnacht am Rigi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674620.8, 1217370],
                [2678128.5, 1219309.1],
                [2679396.2, 1213825.8],
                [2678983.5, 1212217.5],
                [2676909.3, 1211341.7],
                [2676560.9, 1212827.8],
                [2675405.3, 1213335.4],
                [2673734, 1215151.6],
                [2674620.8, 1217370]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6404', ortbez27: 'Greppen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2676909.3, 1211341.7],
                [2674015.5, 1212125.3],
                [2675405.3, 1213335.4],
                [2676560.9, 1212827.8],
                [2676909.3, 1211341.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6386', ortbez27: 'Wolfenschiessen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668395.6, 1190548.3],
                [2668229.9, 1192968.3],
                [2670950.6, 1196454.4],
                [2672999.5, 1198291.6],
                [2674546.7, 1197990.3],
                [2675505.6, 1195590.4],
                [2673691.2, 1193519.8],
                [2670860.5, 1193671],
                [2668395.6, 1190548.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6383', ortbez27: 'Wiesenberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674546.7, 1197990.3],
                [2675761.1, 1199797.3],
                [2678224.4, 1195504.6],
                [2675505.6, 1195590.4],
                [2674546.7, 1197990.3]
              ]
            ],
            [
              [
                [2670950.6, 1196454.4],
                [2668229.9, 1192968.3],
                [2668581, 1198118.9],
                [2671002.5, 1198936.5],
                [2672999.5, 1198291.6],
                [2670950.6, 1196454.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2300', ortbez27: 'La Chaux-de-Fonds' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2553063, 1213082.1],
                [2552025, 1213952.5],
                [2549862.8, 1216601.4],
                [2551029, 1219913.2],
                [2556309.6, 1224158.2],
                [2557797, 1221241.1],
                [2558161.6, 1219501.6],
                [2556617.2, 1217114.2],
                [2556192.9, 1214515.7],
                [2553063, 1213082.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6390', ortbez27: 'Engelberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2682470.6, 1181753.7],
                [2677211.8, 1179778.7],
                [2673241.6, 1179959.7],
                [2671114.8, 1182427.5],
                [2668251.5, 1185020],
                [2668008.7, 1188424],
                [2670566.6, 1186653],
                [2674352.4, 1191575.8],
                [2674938.5, 1189597.1],
                [2678798.2, 1189965.2],
                [2684584.7, 1187943.1],
                [2682470.6, 1181753.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6387', ortbez27: 'Oberrickenbach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2674352.4, 1191575.8],
                [2673691.2, 1193519.8],
                [2675505.6, 1195590.4],
                [2678224.4, 1195504.6],
                [2678798.2, 1189965.2],
                [2674938.5, 1189597.1],
                [2674352.4, 1191575.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6388', ortbez27: 'Grafenort' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2668008.7, 1188424],
                [2668395.6, 1190548.3],
                [2670860.5, 1193671],
                [2673691.2, 1193519.8],
                [2674352.4, 1191575.8],
                [2670566.6, 1186653],
                [2668008.7, 1188424]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6377', ortbez27: 'Seelisberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684082.9, 1203907.8],
                [2685505.5, 1204708.8],
                [2688753.2, 1204279.8],
                [2689353.1, 1203036.5],
                [2688549.6, 1199949.9],
                [2685178.4, 1197586.1],
                [2684082.9, 1203907.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6376', ortbez27: 'Emmetten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2678182.1, 1204347.8],
                [2682307.6, 1203907.7],
                [2684082.9, 1203907.8],
                [2685178.4, 1197586.1],
                [2680245.2, 1197052.3],
                [2678224.4, 1195504.6],
                [2675761.1, 1199797.3],
                [2678182.1, 1204347.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6382', ortbez27: 'B�ren NW' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2672999.5, 1198291.6],
                [2674126.6, 1200932.3],
                [2675761.1, 1199797.3],
                [2674546.7, 1197990.3],
                [2672999.5, 1198291.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6433', ortbez27: 'Stoos SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691078.9, 1201896.5],
                [2691966.1, 1204212.7],
                [2694048.6, 1205295],
                [2695941, 1203412.3],
                [2695317, 1201584.3],
                [2691078.9, 1201896.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6434', ortbez27: 'Illgau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697998.8, 1206949.8],
                [2701640.5, 1207115.4],
                [2701828.8, 1205838.4],
                [2700315.8, 1204649.1],
                [2695595.9, 1206118.9],
                [2697998.8, 1206949.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6432', ortbez27: 'Rickenbach b. Schwyz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697998.8, 1206949.8],
                [2695595.9, 1206118.9],
                [2695525.9, 1209523.8],
                [2697638.8, 1208649.5],
                [2697998.8, 1206949.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2338', ortbez27: 'Les Emibois' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2565400.8, 1230167.9],
                [2564676.7, 1232014.9],
                [2564464.2, 1233439],
                [2567280.7, 1232571],
                [2567755.2, 1230852.3],
                [2565400.8, 1230167.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6438', ortbez27: 'Ibach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691167.4, 1205782.2],
                [2690588.2, 1207617.3],
                [2691378.3, 1208694.6],
                [2694048.6, 1205295],
                [2691966.1, 1204212.7],
                [2691167.4, 1205782.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2336', ortbez27: 'Les Bois' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558466.4, 1228536.6],
                [2562025.4, 1227224.3],
                [2562234, 1225622.7],
                [2557797, 1221241.1],
                [2556309.6, 1224158.2],
                [2554751.7, 1224801.5],
                [2558466.4, 1228536.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6436', ortbez27: 'Muotathal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711074, 1202211.6],
                [2715259.5, 1202917.9],
                [2716761.4, 1202263.1],
                [2716017.5, 1199112.6],
                [2714086.7, 1197762],
                [2707541.9, 1192907.9],
                [2702086.2, 1195068],
                [2697362.3, 1196576.2],
                [2698346.8, 1198361.9],
                [2695317, 1201584.3],
                [2695941, 1203412.3],
                [2694048.6, 1205295],
                [2695595.9, 1206118.9],
                [2700315.8, 1204649.1],
                [2701828.8, 1205838.4],
                [2706518.7, 1207237.1],
                [2709300, 1209483.7],
                [2711074, 1202211.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6423', ortbez27: 'Seewen SZ' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690588.2, 1207617.3],
                [2687947, 1207879.5],
                [2688859.1, 1209233.5],
                [2691332.8, 1213005.3],
                [2691378.3, 1208694.6],
                [2690588.2, 1207617.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2340', ortbez27: 'Le Noirmont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2558466.4, 1228536.6],
                [2562519.1, 1231794.9],
                [2564676.7, 1232014.9],
                [2565400.8, 1230167.9],
                [2562025.4, 1227224.3],
                [2558466.4, 1228536.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6424', ortbez27: 'Lauerz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687947, 1207879.5],
                [2685344.7, 1207330.6],
                [2684539.1, 1208676.1],
                [2686839.1, 1211335],
                [2688859.1, 1209233.5],
                [2687947, 1207879.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6430', ortbez27: 'Schwyz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691378.3, 1208694.6],
                [2691332.8, 1213005.3],
                [2694919.2, 1212911.5],
                [2695525.9, 1209523.8],
                [2695595.9, 1206118.9],
                [2694048.6, 1205295],
                [2691378.3, 1208694.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2333', ortbez27: 'La Ferri�re' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562234, 1225622.7],
                [2563841.9, 1223947.4],
                [2561045.4, 1222331.9],
                [2561655.1, 1220662.6],
                [2560030.2, 1219330.1],
                [2558161.6, 1219501.6],
                [2557797, 1221241.1],
                [2562234, 1225622.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6417', ortbez27: 'Sattel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686569, 1215606.8],
                [2689480.2, 1217968.4],
                [2691983.1, 1217795.6],
                [2694919.2, 1212911.5],
                [2691332.8, 1213005.3],
                [2687725.3, 1214677.2],
                [2686569, 1215606.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6418', ortbez27: 'Rothenthurm' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694072.5, 1221755.4],
                [2696116.3, 1221069.1],
                [2697889.4, 1219673.4],
                [2696386.5, 1216514.1],
                [2694919.2, 1212911.5],
                [2691983.1, 1217795.6],
                [2690340.9, 1219598.3],
                [2694072.5, 1221755.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6415', ortbez27: 'Arth' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679396.2, 1213825.8],
                [2681263.7, 1216689.4],
                [2686569, 1215606.8],
                [2685496.5, 1213622.4],
                [2684268.5, 1212433.8],
                [2683401.4, 1211525.6],
                [2682236.3, 1212081.7],
                [2680825.1, 1212036.7],
                [2679396.2, 1213825.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6416', ortbez27: 'Steinerberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686839.1, 1211335],
                [2685496.5, 1213622.4],
                [2686569, 1215606.8],
                [2687725.3, 1214677.2],
                [2686839.1, 1211335]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2322', ortbez27: 'Le Cr�t-du-Locle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2548320.5, 1215775.4],
                [2549862.8, 1216601.4],
                [2552025, 1213952.5],
                [2550510.5, 1212647.6],
                [2548320.5, 1215775.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6422', ortbez27: 'Steinen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688859.1, 1209233.5],
                [2686839.1, 1211335],
                [2687725.3, 1214677.2],
                [2691332.8, 1213005.3],
                [2688859.1, 1209233.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2325', ortbez27: 'Les Planchettes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2551029, 1219913.2],
                [2549862.8, 1216601.4],
                [2548320.5, 1215775.4],
                [2546687.3, 1215803.2],
                [2547081.2, 1218009.6],
                [2551029, 1219913.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6410', ortbez27: 'Goldau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685496.5, 1213622.4],
                [2686839.1, 1211335],
                [2684539.1, 1208676.1],
                [2683135.3, 1208985.7],
                [2681830.8, 1208050.7],
                [2681380.1, 1209601.9],
                [2676909.3, 1211341.7],
                [2678983.5, 1212217.5],
                [2679396.2, 1213825.8],
                [2680825.1, 1212036.7],
                [2682236.3, 1212081.7],
                [2683401.4, 1211525.6],
                [2684268.5, 1212433.8],
                [2685496.5, 1213622.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2314', ortbez27: 'La Sagne NE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2547366.6, 1208388.4],
                [2553063, 1213082.1],
                [2556192.9, 1214515.7],
                [2553574.5, 1210880.4],
                [2552839.9, 1209665.9],
                [2551624.1, 1207890.4],
                [2550033, 1207828.7],
                [2547366.6, 1208388.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6465', ortbez27: 'Untersch�chen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702086.2, 1195068],
                [2707541.9, 1192907.9],
                [2707725.8, 1188855.4],
                [2699207.9, 1185452.1],
                [2696685.5, 1187564.9],
                [2697385.2, 1189399.1],
                [2700024.7, 1189885.6],
                [2702086.2, 1195068]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2318', ortbez27: 'Brot-Plamboz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2543645.5, 1202821.2],
                [2547780.1, 1205758.5],
                [2550033, 1207828.7],
                [2549151.5, 1205290],
                [2549296, 1201879.8],
                [2548459.6, 1200898.5],
                [2546918.6, 1201405.6],
                [2543645.5, 1202821.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6466', ortbez27: 'Bauen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2685178.4, 1197586.1], [2688549.6, 1199949.9], [2688428.1, 1198230], [2685178.4, 1197586.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6463', ortbez27: 'B�rglen UR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693709.5, 1194829.5],
                [2694847.7, 1197179.9],
                [2697362.3, 1196576.2],
                [2695756.6, 1192103],
                [2697385.2, 1189399.1],
                [2693959.9, 1191250.8],
                [2692688.6, 1192000.4],
                [2693709.5, 1194829.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2316', ortbez27: 'Petit-Martel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2547780.1, 1205758.5],
                [2543645.5, 1202821.2],
                [2541439.2, 1203228.9],
                [2545809, 1206938.1],
                [2547366.6, 1208388.4],
                [2550033, 1207828.7],
                [2547780.1, 1205758.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6464', ortbez27: 'Spiringen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697362.3, 1196576.2],
                [2702086.2, 1195068],
                [2700024.7, 1189885.6],
                [2697385.2, 1189399.1],
                [2695756.6, 1192103],
                [2697362.3, 1196576.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6469', ortbez27: 'Haldi b. Schattdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696685.5, 1187564.9],
                [2694945.8, 1187513.1],
                [2693040.3, 1188742.2],
                [2693959.9, 1191250.8],
                [2697385.2, 1189399.1],
                [2696685.5, 1187564.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6467', ortbez27: 'Schattdorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692688.6, 1192000.4],
                [2693959.9, 1191250.8],
                [2693040.3, 1188742.2],
                [2691051.1, 1189350.7],
                [2691132.6, 1191100.7],
                [2692688.6, 1192000.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6468', ortbez27: 'Attinghausen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684584.7, 1187943.1],
                [2684954.3, 1189575.8],
                [2690460.8, 1192703.2],
                [2691132.6, 1191100.7],
                [2691051.1, 1189350.7],
                [2684584.7, 1187943.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6461', ortbez27: 'Isenthal' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684954.3, 1189575.8],
                [2684584.7, 1187943.1],
                [2678798.2, 1189965.2],
                [2678224.4, 1195504.6],
                [2680245.2, 1197052.3],
                [2685178.4, 1197586.1],
                [2688428.1, 1198230],
                [2689143.2, 1197049.3],
                [2686174.4, 1194806.9],
                [2684954.3, 1189575.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2362', ortbez27: 'Montfaucon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569740.2, 1236855.8],
                [2572501.3, 1238803.5],
                [2572727.1, 1237118.5],
                [2574549.7, 1237593.8],
                [2573714.5, 1235644.8],
                [2572848.9, 1234473.4],
                [2569740.2, 1236855.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6462', ortbez27: 'Seedorf UR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689143.2, 1197049.3],
                [2689771.8, 1194391],
                [2690460.8, 1192703.2],
                [2684954.3, 1189575.8],
                [2686174.4, 1194806.9],
                [2689143.2, 1197049.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2360', ortbez27: 'Le B�mont JU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571172.2, 1232386.4],
                [2566871.7, 1235136.5],
                [2569740.2, 1236855.8],
                [2572848.9, 1234473.4],
                [2572882.9, 1233195.7],
                [2571172.2, 1232386.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6460', ortbez27: 'Altdorf UR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693709.5, 1194829.5],
                [2692688.6, 1192000.4],
                [2691132.6, 1191100.7],
                [2690460.8, 1192703.2],
                [2689771.8, 1194391],
                [2692462.5, 1196684.7],
                [2693709.5, 1194829.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2363', ortbez27: 'Les Enfers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2568906.1, 1238343.9], [2572501.3, 1238803.5], [2569740.2, 1236855.8], [2568906.1, 1238343.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2364', ortbez27: 'St-Brais' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575894.6, 1241454.3],
                [2578472.3, 1241550.8],
                [2577734.2, 1238024],
                [2576332.1, 1238115.4],
                [2574549.7, 1237593.8],
                [2572727.1, 1237118.5],
                [2572501.3, 1238803.5],
                [2572963.6, 1240485.6],
                [2574263, 1240470.6],
                [2575894.6, 1241454.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2353', ortbez27: 'Les Pommerats' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562925.5, 1238044.2],
                [2567477.1, 1239152.5],
                [2568906.1, 1238343.9],
                [2569740.2, 1236855.8],
                [2566871.7, 1235136.5],
                [2564464.2, 1233439],
                [2563635.5, 1235885.7],
                [2566002.8, 1236773.8],
                [2562925.5, 1238044.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2354', ortbez27: 'Goumois' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563635.5, 1235885.7],
                [2564464.2, 1233439],
                [2564676.7, 1232014.9],
                [2562519.1, 1231794.9],
                [2563635.5, 1235885.7]
              ]
            ],
            [[[2563635.5, 1235885.7], [2562925.5, 1238044.2], [2566002.8, 1236773.8], [2563635.5, 1235885.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6454', ortbez27: 'Fl�elen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694847.7, 1197179.9],
                [2693709.5, 1194829.5],
                [2692462.5, 1196684.7],
                [2689771.8, 1194391],
                [2689143.2, 1197049.3],
                [2692885.5, 1198156.9],
                [2694847.7, 1197179.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6452', ortbez27: 'Sisikon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691078.9, 1201896.5],
                [2695317, 1201584.3],
                [2698346.8, 1198361.9],
                [2697362.3, 1196576.2],
                [2694847.7, 1197179.9],
                [2692885.5, 1198156.9],
                [2689143.2, 1197049.3],
                [2688428.1, 1198230],
                [2688549.6, 1199949.9],
                [2691078.9, 1201896.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6442', ortbez27: 'Gersau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685505.5, 1204708.8],
                [2684082.9, 1203907.8],
                [2682307.6, 1203907.7],
                [2678182.1, 1204347.8],
                [2678387.2, 1205712.9],
                [2681830.8, 1208050.7],
                [2683135.3, 1208985.7],
                [2684539.1, 1208676.1],
                [2685344.7, 1207330.6],
                [2685505.5, 1204708.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6440', ortbez27: 'Brunnen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688753.2, 1204279.8],
                [2685505.5, 1204708.8],
                [2685344.7, 1207330.6],
                [2687947, 1207879.5],
                [2690588.2, 1207617.3],
                [2691167.4, 1205782.2],
                [2688753.2, 1204279.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2345', ortbez27: 'Le Cerneux-Veusil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2563841.9, 1223947.4],
                [2562234, 1225622.7],
                [2562025.4, 1227224.3],
                [2565400.8, 1230167.9],
                [2567755.2, 1230852.3],
                [2570200.3, 1230327.3],
                [2569485.1, 1228992.4],
                [2566448.2, 1226718.2],
                [2565651.1, 1225271.7],
                [2563841.9, 1223947.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6443', ortbez27: 'Morschach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691966.1, 1204212.7],
                [2691078.9, 1201896.5],
                [2688549.6, 1199949.9],
                [2689353.1, 1203036.5],
                [2688753.2, 1204279.8],
                [2691167.4, 1205782.2],
                [2691966.1, 1204212.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2350', ortbez27: 'Saignel�gier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567755.2, 1230852.3],
                [2567280.7, 1232571],
                [2564464.2, 1233439],
                [2566871.7, 1235136.5],
                [2571172.2, 1232386.4],
                [2570200.3, 1230327.3],
                [2567755.2, 1230852.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2400', ortbez27: 'Le Locle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2546164.5, 1209349.6],
                [2544433.1, 1208263],
                [2543665.2, 1210000.7],
                [2544736.4, 1211689.5],
                [2546687.3, 1215803.2],
                [2548320.5, 1215775.4],
                [2550510.5, 1212647.6],
                [2552025, 1213952.5],
                [2553063, 1213082.1],
                [2547366.6, 1208388.4],
                [2546164.5, 1209349.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6500', ortbez27: 'Bellinzona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2721341.2, 1117174.9],
                [2723567.4, 1119679.6],
                [2727274.4, 1116122.9],
                [2725147, 1115730.2],
                [2723600.9, 1115859.1],
                [2720810.5, 1115344.2],
                [2721341.2, 1117174.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2405', ortbez27: 'La Chaux-du-Milieu' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2540179.8, 1205899.5],
                [2544433.1, 1208263],
                [2546164.5, 1209349.6],
                [2547366.6, 1208388.4],
                [2545809, 1206938.1],
                [2541439.2, 1203228.9],
                [2540179.8, 1205899.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2406', ortbez27: 'Le Brouillet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2526444.9, 1201112.8],
                [2533199.8, 1203658.6],
                [2535232.8, 1204688.3],
                [2537907.6, 1204587.4],
                [2540179.8, 1205899.5],
                [2541439.2, 1203228.9],
                [2539174.2, 1201207.6],
                [2537875.7, 1201359.7],
                [2534867.5, 1201682.4],
                [2534724.4, 1200424.6],
                [2528679.8, 1198596.6],
                [2526444.9, 1201112.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6490', ortbez27: 'Andermatt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684915.4, 1166096.3],
                [2691615.3, 1171056.8],
                [2694580.1, 1170422.9],
                [2692921.4, 1165675.9],
                [2695073.8, 1163642.2],
                [2695138.8, 1159551.2],
                [2692251.1, 1157827.8],
                [2690249.2, 1159358.5],
                [2684915.4, 1166096.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6487', ortbez27: 'G�schenen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684678.9, 1170845],
                [2691615.3, 1171056.8],
                [2684915.4, 1166096.3],
                [2681534.1, 1164469.9],
                [2675417.4, 1164616.6],
                [2674456.5, 1167459.4],
                [2673586.8, 1171889],
                [2677558.2, 1171441.3],
                [2677726.9, 1173939.9],
                [2680189.3, 1174673.4],
                [2684678.9, 1170845]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6493', ortbez27: 'Hospental' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2681534.1, 1164469.9],
                [2684915.4, 1166096.3],
                [2690249.2, 1159358.5],
                [2684430.7, 1160235.6],
                [2681534.1, 1164469.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6491', ortbez27: 'Realp' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684430.7, 1160235.6],
                [2683514.5, 1154791.4],
                [2680950.7, 1154289.8],
                [2679797.5, 1153594.8],
                [2676044.9, 1155310],
                [2674842.5, 1162166.2],
                [2675417.4, 1164616.6],
                [2681534.1, 1164469.9],
                [2684430.7, 1160235.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6482', ortbez27: 'Gurtnellen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695981.7, 1173203],
                [2694580.1, 1170422.9],
                [2691615.3, 1171056.8],
                [2691706.7, 1174357.2],
                [2687978.9, 1175818.6],
                [2683934.4, 1181308.4],
                [2687762.6, 1182331.1],
                [2688889.7, 1183040.9],
                [2690554.3, 1180487.8],
                [2692939.2, 1178439.8],
                [2695981.7, 1173203]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6485', ortbez27: 'Meien' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687978.9, 1175818.6],
                [2684678.9, 1170845],
                [2680189.3, 1174673.4],
                [2677726.9, 1173939.9],
                [2677211.8, 1179778.7],
                [2682470.6, 1181753.7],
                [2683934.4, 1181308.4],
                [2687978.9, 1175818.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6484', ortbez27: 'Wassen UR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684678.9, 1170845],
                [2687978.9, 1175818.6],
                [2691706.7, 1174357.2],
                [2691615.3, 1171056.8],
                [2684678.9, 1170845]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6473', ortbez27: 'Silenen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691945.4, 1183074.9],
                [2694945.8, 1187513.1],
                [2696685.5, 1187564.9],
                [2699207.9, 1185452.1],
                [2694505.8, 1181425.5],
                [2693238.4, 1182573],
                [2691945.4, 1183074.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6474', ortbez27: 'Amsteg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693815.1, 1180312.2],
                [2691945.4, 1183074.9],
                [2693238.4, 1182573],
                [2694505.8, 1181425.5],
                [2693815.1, 1180312.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6472', ortbez27: 'Erstfeld' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2683934.4, 1181308.4],
                [2682470.6, 1181753.7],
                [2684584.7, 1187943.1],
                [2691051.1, 1189350.7],
                [2693040.3, 1188742.2],
                [2694945.8, 1187513.1],
                [2691945.4, 1183074.9],
                [2690741.5, 1182563.9],
                [2688889.7, 1183040.9],
                [2687762.6, 1182331.1],
                [2683934.4, 1181308.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6475', ortbez27: 'Bristen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695981.7, 1173203],
                [2692939.2, 1178439.8],
                [2693815.1, 1180312.2],
                [2694505.8, 1181425.5],
                [2699207.9, 1185452.1],
                [2707725.8, 1188855.4],
                [2709359.1, 1189020.4],
                [2709862, 1185791.8],
                [2706170.6, 1183415.4],
                [2706711.4, 1180612.7],
                [2702768.2, 1176257.8],
                [2701137.5, 1177856],
                [2700015.2, 1174966.7],
                [2695981.7, 1173203]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6476', ortbez27: 'Intschi' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693815.1, 1180312.2],
                [2692939.2, 1178439.8],
                [2690554.3, 1180487.8],
                [2688889.7, 1183040.9],
                [2690741.5, 1182563.9],
                [2691945.4, 1183074.9],
                [2693815.1, 1180312.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6527', ortbez27: 'Lodrino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719657.6, 1129079.9],
                [2719731.3, 1127682.3],
                [2717079.8, 1128208.1],
                [2715659.1, 1129263.4],
                [2718739.4, 1131801.4],
                [2719657.6, 1129079.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6528', ortbez27: 'Camorino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720810.5, 1115344.2],
                [2722352.8, 1114328.2],
                [2723888.7, 1112302.9],
                [2720625.4, 1111714.2],
                [2719134.1, 1113657.8],
                [2720810.5, 1115344.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6533', ortbez27: 'Lumino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724721.6, 1122553.1],
                [2725255.1, 1126455.3],
                [2727446.8, 1119358.4],
                [2724799.3, 1120512.2],
                [2724721.6, 1122553.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6534', ortbez27: 'S. Vittore' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727446.8, 1119358.4],
                [2725255.1, 1126455.3],
                [2725888.4, 1128254.9],
                [2726443.4, 1126961.2],
                [2727060.6, 1125866.7],
                [2727901.1, 1124917.2],
                [2729020, 1124306.6],
                [2730801.5, 1123581.8],
                [2729237.5, 1119842.7],
                [2727446.8, 1119358.4]
              ]
            ],
            [[[2725755.2, 1129917.3], [2725888.4, 1128254.9], [2724674.9, 1128603.9], [2725755.2, 1129917.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6532', ortbez27: 'Castione' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724799.3, 1120512.2],
                [2723567.4, 1119679.6],
                [2722789.5, 1122043.3],
                [2724721.6, 1122553.1],
                [2724799.3, 1120512.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6525', ortbez27: 'Gnosca' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2722789.5, 1122043.3], [2718136.5, 1122284.7], [2721420.5, 1123576.6], [2722789.5, 1122043.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6526', ortbez27: 'Prosito' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719731.3, 1127682.3],
                [2718673.6, 1126218.3],
                [2715195.8, 1123726],
                [2713576.3, 1128738.7],
                [2715659.1, 1129263.4],
                [2717079.8, 1128208.1],
                [2719731.3, 1127682.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6523', ortbez27: 'Preonzo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720952.7, 1125906.3],
                [2721420.5, 1123576.6],
                [2718136.5, 1122284.7],
                [2717180.8, 1120377.5],
                [2715233.8, 1121452.5],
                [2715195.8, 1123726],
                [2718673.6, 1126218.3],
                [2720952.7, 1125906.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6524', ortbez27: 'Moleno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718673.6, 1126218.3], [2719731.3, 1127682.3], [2720952.7, 1125906.3], [2718673.6, 1126218.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6513', ortbez27: 'Monte Carasso' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717180.8, 1120377.5],
                [2721341.2, 1117174.9],
                [2720810.5, 1115344.2],
                [2715784.2, 1118926.6],
                [2715233.8, 1121452.5],
                [2717180.8, 1120377.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6514', ortbez27: 'Sementina' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718002.2, 1115210.6], [2715784.2, 1118926.6], [2720810.5, 1115344.2], [2718002.2, 1115210.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6512', ortbez27: 'Giubiasco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718002.2, 1115210.6], [2720810.5, 1115344.2], [2719134.1, 1113657.8], [2718002.2, 1115210.6]]],
            [[[2720810.5, 1115344.2], [2723600.9, 1115859.1], [2722352.8, 1114328.2], [2720810.5, 1115344.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6517', ortbez27: 'Arbedo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2730651.5, 1115570.5],
                [2727274.4, 1116122.9],
                [2723567.4, 1119679.6],
                [2724799.3, 1120512.2],
                [2727446.8, 1119358.4],
                [2729237.5, 1119842.7],
                [2729344.1, 1118398.8],
                [2730651.5, 1115570.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6518', ortbez27: 'Gorduno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723567.4, 1119679.6],
                [2717180.8, 1120377.5],
                [2718136.5, 1122284.7],
                [2722789.5, 1122043.3],
                [2723567.4, 1119679.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6515', ortbez27: 'Gudo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715784.2, 1118926.6],
                [2718002.2, 1115210.6],
                [2717040.7, 1113727],
                [2714750.8, 1113881.1],
                [2714105.1, 1115651.7],
                [2715784.2, 1118926.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2416', ortbez27: 'Les Brenets' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2546687.3, 1215803.2], [2544736.4, 1211689.5], [2543345.5, 1213367.4], [2546687.3, 1215803.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6516', ortbez27: 'Cugnasco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713589.2, 1113262.2],
                [2712832.8, 1115087.9],
                [2711501.7, 1115754.2],
                [2711188.8, 1117336.5],
                [2714115.1, 1122012.7],
                [2715233.8, 1121452.5],
                [2715784.2, 1118926.6],
                [2714105.1, 1115651.7],
                [2714750.8, 1113881.1],
                [2713589.2, 1113262.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6503', ortbez27: 'Bellinzona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2723567.4, 1119679.6], [2721341.2, 1117174.9], [2717180.8, 1120377.5], [2723567.4, 1119679.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2414', ortbez27: 'Le Cerneux-P�quignot' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2544433.1, 1208263], [2540179.8, 1205899.5], [2543665.2, 1210000.7], [2544433.1, 1208263]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6562', ortbez27: 'Soazza' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732784.1, 1133394.2],
                [2732308, 1134555.9],
                [2733105.4, 1137108],
                [2733623.7, 1138608.8],
                [2738919.6, 1136069.1],
                [2741471.8, 1136950.9],
                [2743367.8, 1134306.6],
                [2742303.1, 1131039.1],
                [2741252.9, 1132929.8],
                [2732784.1, 1133394.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6565', ortbez27: 'S. Bernardino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726961, 1144319.6],
                [2726021.8, 1148875.6],
                [2735580.4, 1152221.2],
                [2740683.2, 1154219.1],
                [2741269.6, 1152774.8],
                [2741697, 1151329.2],
                [2741382.6, 1147370.4],
                [2739083.2, 1145357.6],
                [2732304.9, 1141528.1],
                [2730512.2, 1145555.1],
                [2726961, 1144319.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6563', ortbez27: 'Mesocco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732304.9, 1141528.1],
                [2739083.2, 1145357.6],
                [2741752.8, 1141869.3],
                [2741471.8, 1136950.9],
                [2738919.6, 1136069.1],
                [2733623.7, 1138608.8],
                [2732304.9, 1141528.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6557', ortbez27: 'Cama' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736130, 1125620.7],
                [2737428.6, 1125020.8],
                [2738450.6, 1124252.1],
                [2739637.4, 1123515.1],
                [2737432, 1121755.9],
                [2734845.2, 1125578.3],
                [2732982.8, 1125614.6],
                [2733802.2, 1128432.9],
                [2735184.8, 1126862.4],
                [2736442.1, 1126837.9],
                [2736130, 1125620.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6558', ortbez27: 'Lostallo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2742303.1, 1131039.1],
                [2739751.5, 1125627.8],
                [2736442.1, 1126837.9],
                [2735184.8, 1126862.4],
                [2733802.2, 1128432.9],
                [2732593.3, 1131248.8],
                [2732784.1, 1133394.2],
                [2741252.9, 1132929.8],
                [2742303.1, 1131039.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6556', ortbez27: 'Leggia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737432, 1121755.9],
                [2732172.5, 1124423.6],
                [2732982.8, 1125614.6],
                [2734845.2, 1125578.3],
                [2737432, 1121755.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6545', ortbez27: 'Selma' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2729272.2, 1131929.3], [2732593.3, 1131248.8], [2729483.4, 1130121.7], [2729272.2, 1131929.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6546', ortbez27: 'Cauco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727418.1, 1136360.1],
                [2732308, 1134555.9],
                [2732784.1, 1133394.2],
                [2732593.3, 1131248.8],
                [2729272.2, 1131929.3],
                [2725391.3, 1131733.4],
                [2725619.6, 1133908.4],
                [2727075.6, 1134858.4],
                [2727418.1, 1136360.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6543', ortbez27: 'Arvigo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725755.2, 1129917.3],
                [2725391.3, 1131733.4],
                [2729272.2, 1131929.3],
                [2729483.4, 1130121.7],
                [2729801.5, 1127687.2],
                [2726443.4, 1126961.2],
                [2725888.4, 1128254.9],
                [2725755.2, 1129917.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6544', ortbez27: 'Braggio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2729483.4, 1130121.7], [2732593.3, 1131248.8], [2729801.5, 1127687.2], [2729483.4, 1130121.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6547', ortbez27: 'Augio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2727367.2, 1137691.5],
                [2733105.4, 1137108],
                [2732308, 1134555.9],
                [2727418.1, 1136360.1],
                [2727367.2, 1137691.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6548', ortbez27: 'Rossa' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726805.8, 1138940.5],
                [2726961, 1144319.6],
                [2730512.2, 1145555.1],
                [2732304.9, 1141528.1],
                [2733623.7, 1138608.8],
                [2733105.4, 1137108],
                [2727367.2, 1137691.5],
                [2726805.8, 1138940.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6537', ortbez27: 'Grono' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737432, 1121755.9],
                [2736194, 1119107.5],
                [2730801.5, 1123581.8],
                [2732172.5, 1124423.6],
                [2737432, 1121755.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6538', ortbez27: 'Verdabbio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736442.1, 1126837.9],
                [2739751.5, 1125627.8],
                [2738450.6, 1124252.1],
                [2737428.6, 1125020.8],
                [2736130, 1125620.7],
                [2736442.1, 1126837.9]
              ]
            ],
            [[[2733802.2, 1128432.9], [2732982.8, 1125614.6], [2732593.3, 1131248.8], [2733802.2, 1128432.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6535', ortbez27: 'Roveredo GR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732976.8, 1114721],
                [2730651.5, 1115570.5],
                [2729344.1, 1118398.8],
                [2729237.5, 1119842.7],
                [2730801.5, 1123581.8],
                [2736194, 1119107.5],
                [2732899.8, 1116125.7],
                [2732976.8, 1114721]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6541', ortbez27: 'Sta. Maria in Calanca' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732172.5, 1124423.6],
                [2730446.6, 1126570.9],
                [2729801.5, 1127687.2],
                [2732593.3, 1131248.8],
                [2732982.8, 1125614.6],
                [2732172.5, 1124423.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6542', ortbez27: 'Buseno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2730446.6, 1126570.9],
                [2730801.5, 1123581.8],
                [2729020, 1124306.6],
                [2727901.1, 1124917.2],
                [2727060.6, 1125866.7],
                [2726443.4, 1126961.2],
                [2729801.5, 1127687.2],
                [2730446.6, 1126570.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6540', ortbez27: 'Castaneda' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2730446.6, 1126570.9], [2732172.5, 1124423.6], [2730801.5, 1123581.8], [2730446.6, 1126570.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6593', ortbez27: 'Cadenazzo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715848.7, 1111488.4],
                [2714750.8, 1113881.1],
                [2717040.7, 1113727],
                [2719231.7, 1110866.9],
                [2715848.7, 1111488.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6594', ortbez27: 'Contone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714750.8, 1113881.1],
                [2715848.7, 1111488.4],
                [2714121.9, 1112026.1],
                [2713589.2, 1113262.2],
                [2714750.8, 1113881.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6592', ortbez27: 'S. Antonino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719134.1, 1113657.8],
                [2720625.4, 1111714.2],
                [2719231.7, 1110866.9],
                [2717040.7, 1113727],
                [2718002.2, 1115210.6],
                [2719134.1, 1113657.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6598', ortbez27: 'Tenero' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707174.2, 1112555.6],
                [2708379, 1115059.2],
                [2709787.4, 1114257.6],
                [2708904.9, 1112280],
                [2707174.2, 1112555.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6595', ortbez27: 'Riazzino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712832.8, 1115087.9],
                [2713589.2, 1113262.2],
                [2711682.8, 1112123.7],
                [2711501.7, 1115754.2],
                [2712832.8, 1115087.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6596', ortbez27: 'Gordola' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709387.3, 1118247.1],
                [2711188.8, 1117336.5],
                [2711501.7, 1115754.2],
                [2711682.8, 1112123.7],
                [2708904.9, 1112280],
                [2709787.4, 1114257.6],
                [2708379, 1115059.2],
                [2709540.5, 1115532.4],
                [2709387.3, 1118247.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2502', ortbez27: 'Biel/Bienne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2584690.6, 1221870.5], [2585615.8, 1222747.9], [2586638.1, 1221505], [2584690.6, 1221870.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6583', ortbez27: 'S. Antonio (Val Morobbia)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725147, 1115730.2],
                [2727274.4, 1116122.9],
                [2726907.6, 1113910.9],
                [2726457.9, 1112241.4],
                [2725236, 1111971.7],
                [2724266.3, 1114303.6],
                [2725147, 1115730.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6584', ortbez27: 'Carena' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2732976.8, 1114721],
                [2726858.1, 1109212.1],
                [2725096.7, 1109767],
                [2725975.1, 1110693.6],
                [2726457.9, 1112241.4],
                [2726907.6, 1113910.9],
                [2727274.4, 1116122.9],
                [2730651.5, 1115570.5],
                [2732976.8, 1114721]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6577', ortbez27: 'Ranzo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702877.2, 1107580.5],
                [2700858.4, 1108835.2],
                [2701690.7, 1109798.2],
                [2703109, 1109162.8],
                [2702877.2, 1107580.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6578', ortbez27: 'Caviano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2702877.2, 1107580.5], [2705495.5, 1106137.8], [2704056.7, 1105768.5], [2702877.2, 1107580.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6575', ortbez27: 'Vairano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2706475.8, 1107044.7],
                [2704880.1, 1109433.2],
                [2706664.9, 1110424.5],
                [2707824.3, 1107879.6],
                [2706475.8, 1107044.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6576', ortbez27: 'Gerra (Gambarogno)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704880.1, 1109433.2],
                [2706475.8, 1107044.7],
                [2705495.5, 1106137.8],
                [2702877.2, 1107580.5],
                [2703109, 1109162.8],
                [2704880.1, 1109433.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6582', ortbez27: 'Pianezzo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2726457.9, 1112241.4], [2725975.1, 1110693.6], [2725236, 1111971.7], [2726457.9, 1112241.4]]],
            [
              [
                [2723600.9, 1115859.1],
                [2725147, 1115730.2],
                [2724266.3, 1114303.6],
                [2725236, 1111971.7],
                [2723888.7, 1112302.9],
                [2722352.8, 1114328.2],
                [2723600.9, 1115859.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6579', ortbez27: 'Piazzogna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707824.3, 1107879.6],
                [2706664.9, 1110424.5],
                [2706263.2, 1111614.8],
                [2707174.2, 1112555.6],
                [2710411.3, 1106974.5],
                [2707824.3, 1107879.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6573', ortbez27: 'Magadino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710886.5, 1110020.6],
                [2708904.9, 1112280],
                [2711682.8, 1112123.7],
                [2712539.1, 1111117.9],
                [2710886.5, 1110020.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6574', ortbez27: 'Vira (Gambarogno)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708904.9, 1112280],
                [2710886.5, 1110020.6],
                [2711579.6, 1107427.1],
                [2710411.3, 1106974.5],
                [2707174.2, 1112555.6],
                [2708904.9, 1112280]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6571', ortbez27: 'Indemini' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707824.3, 1107879.6],
                [2710411.3, 1106974.5],
                [2710358.2, 1105404.1],
                [2709930.9, 1104186.4],
                [2706475.8, 1107044.7],
                [2707824.3, 1107879.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6572', ortbez27: 'Quartino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714121.9, 1112026.1],
                [2712539.1, 1111117.9],
                [2711682.8, 1112123.7],
                [2713589.2, 1113262.2],
                [2714121.9, 1112026.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2534', ortbez27: 'Orvin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582006, 1220476],
                [2577326.2, 1221433],
                [2575672.8, 1222303.2],
                [2579155.5, 1224786.6],
                [2582143, 1224896.8],
                [2584189.7, 1225068],
                [2585804.9, 1224118.7],
                [2585615.8, 1222747.9],
                [2584690.6, 1221870.5],
                [2584527.6, 1220600.9],
                [2582006, 1220476]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6616', ortbez27: 'Losone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703336.8, 1113805.5],
                [2701194.8, 1112933.1],
                [2700522.5, 1111866.6],
                [2698758.7, 1112523.4],
                [2698987.5, 1114775.7],
                [2700509.6, 1115516.2],
                [2702085.7, 1116413.1],
                [2703336.8, 1113805.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6622', ortbez27: 'Ronco sopra Ascona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700522.5, 1111866.6],
                [2699616, 1109527.9],
                [2697248.7, 1111737.7],
                [2698758.7, 1112523.4],
                [2700522.5, 1111866.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2520', ortbez27: 'La Neuveville' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576647.1, 1214536.2],
                [2573404.7, 1212286.9],
                [2572604, 1214094.1],
                [2574467, 1215269.3],
                [2576647.1, 1214536.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2525', ortbez27: 'Le Landeron' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2572889.9, 1210915.3],
                [2570789.6, 1210471.1],
                [2569262.4, 1213193.3],
                [2572604, 1214094.1],
                [2573404.7, 1212286.9],
                [2572889.9, 1210915.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2523', ortbez27: 'Ligni�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567327.2, 1217179.4],
                [2568365.1, 1218839.6],
                [2574207.9, 1216916.7],
                [2574467, 1215269.3],
                [2572604, 1214094.1],
                [2569262.4, 1213193.3],
                [2569512.8, 1214958.4],
                [2567326.9, 1215290.4],
                [2567327.2, 1217179.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2513', ortbez27: 'Twann' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580495.5, 1218908.4],
                [2581186.6, 1217806.8],
                [2578181.5, 1215627.3],
                [2577408.2, 1217603.1],
                [2580495.5, 1218908.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6613', ortbez27: 'Porto Ronco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700858.4, 1108835.2],
                [2699616, 1109527.9],
                [2700522.5, 1111866.6],
                [2701690.7, 1109798.2],
                [2700858.4, 1108835.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6614', ortbez27: 'Brissago' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2699616, 1109527.9],
                [2700858.4, 1108835.2],
                [2698715.1, 1106016.9],
                [2693565.1, 1108842.5],
                [2695355, 1111763.3],
                [2697248.7, 1111737.7],
                [2699616, 1109527.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6611', ortbez27: 'Mosogno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691086.1, 1115183],
                [2688751, 1114536.7],
                [2689479.4, 1118802.1],
                [2691048.1, 1117219.7],
                [2693108.1, 1118962.9],
                [2694894.8, 1116684.1],
                [2693984.5, 1115786.6],
                [2691086.1, 1115183]
              ]
            ],
            [
              [
                [2689797.5, 1124054.4],
                [2691479.6, 1123480.6],
                [2692764.7, 1121307.6],
                [2689479.4, 1118802.1],
                [2689797.5, 1124054.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2512', ortbez27: 'T�scherz-Alferm�e' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2583216, 1219680.9],
                [2581186.6, 1217806.8],
                [2580495.5, 1218908.4],
                [2582006, 1220476],
                [2583216, 1219680.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6612', ortbez27: 'Ascona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701690.7, 1109798.2],
                [2700522.5, 1111866.6],
                [2701194.8, 1112933.1],
                [2703336.8, 1113805.5],
                [2706263.2, 1111614.8],
                [2706664.9, 1110424.5],
                [2704880.1, 1109433.2],
                [2703109, 1109162.8],
                [2701690.7, 1109798.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2517', ortbez27: 'Diesse' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2577326.2, 1221433],
                [2577408.2, 1217603.1],
                [2574207.9, 1216916.7],
                [2574023, 1219213.5],
                [2577326.2, 1221433]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2518', ortbez27: 'Nods' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2574207.9, 1216916.7],
                [2568365.1, 1218839.6],
                [2569688, 1219721.2],
                [2570864.9, 1220325.3],
                [2575672.8, 1222303.2],
                [2577326.2, 1221433],
                [2574023, 1219213.5],
                [2574207.9, 1216916.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2515', ortbez27: 'Pr�les' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576647.1, 1214536.2],
                [2574467, 1215269.3],
                [2574207.9, 1216916.7],
                [2577408.2, 1217603.1],
                [2578181.5, 1215627.3],
                [2576647.1, 1214536.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2516', ortbez27: 'Lamboing' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582006, 1220476],
                [2580495.5, 1218908.4],
                [2577408.2, 1217603.1],
                [2577326.2, 1221433],
                [2582006, 1220476]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6600', ortbez27: 'Locarno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2706263.2, 1111614.8],
                [2703336.8, 1113805.5],
                [2704790.9, 1114682.9],
                [2706041.9, 1114145.8],
                [2707174.2, 1112555.6],
                [2706263.2, 1111614.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2505', ortbez27: 'Biel/Bienne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2584527.6, 1220600.9], [2583216, 1219680.9], [2582006, 1220476], [2584527.6, 1220600.9]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6605', ortbez27: 'Locarno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703336.8, 1113805.5],
                [2702085.7, 1116413.1],
                [2704401.4, 1117163.9],
                [2704790.9, 1114682.9],
                [2703336.8, 1113805.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2503', ortbez27: 'Biel/Bienne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586638.1, 1221505],
                [2586496.3, 1219104.2],
                [2585210.9, 1218862],
                [2584527.6, 1220600.9],
                [2584690.6, 1221870.5],
                [2586638.1, 1221505]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2504', ortbez27: 'Biel/Bienne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585804.9, 1224118.7],
                [2589784, 1225528.1],
                [2590398.1, 1223844.8],
                [2589564.1, 1222542.8],
                [2588834.7, 1221098.1],
                [2586638.1, 1221505],
                [2585615.8, 1222747.9],
                [2585804.9, 1224118.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6657', ortbez27: 'Palagnedra' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2695355, 1111763.3], [2693565.1, 1108842.5], [2692313.8, 1112972.6], [2695355, 1111763.3]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6658', ortbez27: 'Borgnone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689984.1, 1112355.2],
                [2688751, 1114536.7],
                [2691086.1, 1115183],
                [2692313.8, 1112972.6],
                [2689984.1, 1112355.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6655', ortbez27: 'Intragna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2695355, 1111763.3],
                [2692313.8, 1112972.6],
                [2691086.1, 1115183],
                [2693984.5, 1115786.6],
                [2694894.8, 1116684.1],
                [2696882.5, 1116173.7],
                [2697813.9, 1115217.7],
                [2698987.5, 1114775.7],
                [2698758.7, 1112523.4],
                [2697248.7, 1111737.7],
                [2695355, 1111763.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6661', ortbez27: 'Auressio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2694894.8, 1116684.1],
                [2693108.1, 1118962.9],
                [2694420.1, 1119209.8],
                [2694010, 1120399.9],
                [2695344.9, 1120802.5],
                [2696457.5, 1119974.3],
                [2697333.8, 1119075.3],
                [2698207.4, 1117997.5],
                [2696882.5, 1116173.7],
                [2694894.8, 1116684.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6662', ortbez27: 'Russo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2693108.1, 1118962.9],
                [2691048.1, 1117219.7],
                [2689479.4, 1118802.1],
                [2692764.7, 1121307.6],
                [2694010, 1120399.9],
                [2694420.1, 1119209.8],
                [2693108.1, 1118962.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6659', ortbez27: 'Moneto' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692313.8, 1112972.6],
                [2693565.1, 1108842.5],
                [2690770.7, 1108611.5],
                [2689984.1, 1112355.2],
                [2692313.8, 1112972.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2560', ortbez27: 'Nidau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2585210.9, 1218862], [2583216, 1219680.9], [2584527.6, 1220600.9], [2585210.9, 1218862]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2565', ortbez27: 'Jens' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587221.4, 1214929.7],
                [2584720.7, 1215893.9],
                [2587120.2, 1217467.9],
                [2588693.4, 1217628.7],
                [2588342.2, 1216076.8],
                [2587221.4, 1214929.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2563', ortbez27: 'Ipsach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2585210.9, 1218862], [2584041.3, 1217388.7], [2583216, 1219680.9], [2585210.9, 1218862]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6647', ortbez27: 'Mergoscia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704499.8, 1118574.1],
                [2704949.5, 1120525.6],
                [2709551.6, 1119616.6],
                [2709387.3, 1118247.1],
                [2707184.4, 1117273.3],
                [2704499.8, 1118574.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2564', ortbez27: 'Bellmund' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587120.2, 1217467.9],
                [2584720.7, 1215893.9],
                [2584041.3, 1217388.7],
                [2585210.9, 1218862],
                [2586496.3, 1219104.2],
                [2587120.2, 1217467.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6648', ortbez27: 'Minusio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2708379, 1115059.2], [2707174.2, 1112555.6], [2706041.9, 1114145.8], [2708379, 1115059.2]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2553', ortbez27: 'Safnern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2591491.5, 1221069.5],
                [2589564.1, 1222542.8],
                [2590398.1, 1223844.8],
                [2591692.5, 1223901.5],
                [2592723, 1222477.1],
                [2591491.5, 1221069.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2554', ortbez27: 'Meinisberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2591692.5, 1223901.5],
                [2593961, 1224639.3],
                [2595423, 1223552.6],
                [2592723, 1222477.1],
                [2591692.5, 1223901.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6653', ortbez27: 'Verscio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700530.4, 1118041.6],
                [2700509.6, 1115516.2],
                [2698987.5, 1114775.7],
                [2697813.9, 1115217.7],
                [2698207.4, 1117997.5],
                [2700530.4, 1118041.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6654', ortbez27: 'Cavigliano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2697813.9, 1115217.7], [2696882.5, 1116173.7], [2698207.4, 1117997.5], [2697813.9, 1115217.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2552', ortbez27: 'Orpund' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588834.7, 1221098.1],
                [2589564.1, 1222542.8],
                [2591491.5, 1221069.5],
                [2591017.3, 1219731],
                [2589809.7, 1220186.2],
                [2588834.7, 1221098.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6652', ortbez27: 'Tegna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2700530.4, 1118041.6], [2702085.7, 1116413.1], [2700509.6, 1115516.2], [2700530.4, 1118041.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2557', ortbez27: 'Studen BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588693.4, 1217628.7],
                [2589809.7, 1220186.2],
                [2591017.3, 1219731],
                [2590788.1, 1217809.8],
                [2588693.4, 1217628.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2555', ortbez27: 'Br�gg BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589809.7, 1220186.2],
                [2588693.4, 1217628.7],
                [2587120.2, 1217467.9],
                [2586496.3, 1219104.2],
                [2586638.1, 1221505],
                [2588834.7, 1221098.1],
                [2589809.7, 1220186.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2556', ortbez27: 'Schwadernau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592801.2, 1220176.6],
                [2592072, 1218631.4],
                [2590788.1, 1217809.8],
                [2591017.3, 1219731],
                [2591491.5, 1221069.5],
                [2592801.2, 1220176.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2545', ortbez27: 'Selzach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602884, 1226783.8],
                [2600914.5, 1226171.8],
                [2598042.4, 1232332.6],
                [2598384.1, 1233623.2],
                [2599917.2, 1233043.1],
                [2601158.2, 1233557.8],
                [2602083.2, 1232663],
                [2601056.9, 1231916.7],
                [2601330.8, 1230662.2],
                [2602058.6, 1229636.2],
                [2603530.6, 1227965.7],
                [2602884, 1226783.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6645', ortbez27: 'Brione sopra Minusio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704499.8, 1118574.1],
                [2707184.4, 1117273.3],
                [2708379, 1115059.2],
                [2706041.9, 1114145.8],
                [2704790.9, 1114682.9],
                [2704401.4, 1117163.9],
                [2704499.8, 1118574.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6646', ortbez27: 'Contra' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707184.4, 1117273.3],
                [2709387.3, 1118247.1],
                [2709540.5, 1115532.4],
                [2708379, 1115059.2],
                [2707184.4, 1117273.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2544', ortbez27: 'Bettlach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2598042.4, 1232332.6],
                [2600914.5, 1226171.8],
                [2599486.8, 1225731.2],
                [2596372.3, 1230088.9],
                [2598042.4, 1232332.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6633', ortbez27: 'Lavertezzo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708541.3, 1131713.1],
                [2712583.6, 1129636.1],
                [2713576.3, 1128738.7],
                [2715195.8, 1123726],
                [2715233.8, 1121452.5],
                [2714115.1, 1122012.7],
                [2708569.8, 1122535.6],
                [2704347.4, 1122595.2],
                [2706812, 1127826.9],
                [2708541.3, 1131713.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6634', ortbez27: 'Brione (Verzasca)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698060.8, 1135144.1],
                [2702148.5, 1131560.8],
                [2706812, 1127826.9],
                [2704347.4, 1122595.2],
                [2702943.4, 1124087.7],
                [2699862.2, 1128429.5],
                [2697267.6, 1129915.9],
                [2695798.8, 1131282.4],
                [2696772.2, 1133874.3],
                [2698060.8, 1135144.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6631', ortbez27: 'Corippo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708569.8, 1122535.6],
                [2709551.6, 1119616.6],
                [2704949.5, 1120525.6],
                [2704347.4, 1122595.2],
                [2708569.8, 1122535.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6632', ortbez27: 'Vogorno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2711188.8, 1117336.5],
                [2709387.3, 1118247.1],
                [2709551.6, 1119616.6],
                [2708569.8, 1122535.6],
                [2714115.1, 1122012.7],
                [2711188.8, 1117336.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2537', ortbez27: 'Vauffelin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2585804.9, 1224118.7], [2589396.1, 1226968.8], [2589784, 1225528.1], [2585804.9, 1224118.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6637', ortbez27: 'Sonogno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2698060.8, 1135144.1],
                [2700305, 1140350.2],
                [2703838.3, 1139056.4],
                [2704861.1, 1136109.4],
                [2703092.1, 1132593.8],
                [2702148.5, 1131560.8],
                [2698060.8, 1135144.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2538', ortbez27: 'Romont BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2591106.9, 1228753.6],
                [2592631.5, 1229781.2],
                [2593486.3, 1226530.4],
                [2589784, 1225528.1],
                [2589396.1, 1226968.8],
                [2591115.4, 1227493.4],
                [2591106.9, 1228753.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2536', ortbez27: 'Plagne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2589396.1, 1226968.8], [2585804.9, 1224118.7], [2586163.7, 1226468.6], [2589396.1, 1226968.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6635', ortbez27: 'Gerra (Verzasca)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703092.1, 1132593.8],
                [2708541.3, 1131713.1],
                [2706812, 1127826.9],
                [2702148.5, 1131560.8],
                [2703092.1, 1132593.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6636', ortbez27: 'Frasco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707084.4, 1137428.2],
                [2708176.6, 1135752.3],
                [2709711, 1133971.8],
                [2708541.3, 1131713.1],
                [2703092.1, 1132593.8],
                [2704861.1, 1136109.4],
                [2703838.3, 1139056.4],
                [2707084.4, 1137428.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2542', ortbez27: 'Pieterlen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2590398.1, 1223844.8],
                [2589784, 1225528.1],
                [2593486.3, 1226530.4],
                [2593961, 1224639.3],
                [2591692.5, 1223901.5],
                [2590398.1, 1223844.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6690', ortbez27: 'Cavergno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2679044.1, 1133176.8],
                [2679160, 1137178.7],
                [2678710.5, 1145017.8],
                [2684515.3, 1146922.8],
                [2685610.2, 1141916.3],
                [2687434.9, 1138472.8],
                [2690779.9, 1137253.3],
                [2690144.9, 1135953.1],
                [2690669.4, 1133806.6],
                [2689383.4, 1133740.2],
                [2688355.7, 1134487.9],
                [2687359.2, 1135285.6],
                [2686320.2, 1135993.3],
                [2685064.9, 1136100.4],
                [2684134.6, 1135265.4],
                [2683654.7, 1134104.9],
                [2684675.5, 1133100.6],
                [2683354.9, 1132348.7],
                [2679044.1, 1133176.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2540', ortbez27: 'Grenchen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592631.5, 1229781.2],
                [2598042.4, 1232332.6],
                [2596372.3, 1230088.9],
                [2599486.8, 1225731.2],
                [2597917.1, 1223614.4],
                [2596166.8, 1222510.4],
                [2595423, 1223552.6],
                [2593961, 1224639.3],
                [2593486.3, 1226530.4],
                [2592631.5, 1229781.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6693', ortbez27: 'Broglio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2696772.2, 1133874.3],
                [2692170, 1138056],
                [2696042.1, 1137683.7],
                [2698060.8, 1135144.1],
                [2696772.2, 1133874.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6694', ortbez27: 'Prato-Sornico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692170, 1138056],
                [2690779.9, 1137253.3],
                [2691071.2, 1139539.9],
                [2693770.1, 1140693],
                [2698209.7, 1143929.7],
                [2700305, 1140350.2],
                [2698060.8, 1135144.1],
                [2696042.1, 1137683.7],
                [2692170, 1138056]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6692', ortbez27: 'Menzonio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2690779.9, 1137253.3],
                [2692170, 1138056],
                [2696772.2, 1133874.3],
                [2695798.8, 1131282.4],
                [2692347.8, 1134585.1],
                [2690669.4, 1133806.6],
                [2690144.9, 1135953.1],
                [2690779.9, 1137253.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6682', ortbez27: 'Linescio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2687343.7, 1131860.2], [2689021.1, 1128480.3], [2686911.6, 1127498], [2687343.7, 1131860.2]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6685', ortbez27: 'Bosco/Gurin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2677269.3, 1128711.3],
                [2679044.1, 1133176.8],
                [2683354.9, 1132348.7],
                [2681955.5, 1128885.5],
                [2679788.9, 1128443.6],
                [2677269.3, 1128711.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6683', ortbez27: 'Niva (Vallemaggia)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2687241, 1124746.2],
                [2684195.1, 1124021.6],
                [2683410.9, 1127915],
                [2681955.5, 1128885.5],
                [2683354.9, 1132348.7],
                [2684675.5, 1133100.6],
                [2687343.7, 1131860.2],
                [2686911.6, 1127498],
                [2686666, 1126145.4],
                [2687241, 1124746.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6684', ortbez27: 'Cimalmotto' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2677269.3, 1128711.3],
                [2679788.9, 1128443.6],
                [2681955.5, 1128885.5],
                [2683410.9, 1127915],
                [2684195.1, 1124021.6],
                [2682632.6, 1123837.2],
                [2679158, 1122120],
                [2677548.9, 1122767.1],
                [2677269.3, 1128711.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6673', ortbez27: 'Maggia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2699862.2, 1128429.5],
                [2702943.4, 1124087.7],
                [2700315.4, 1122249.4],
                [2698418.1, 1121846.4],
                [2696678.8, 1124060.4],
                [2696727.4, 1125972.6],
                [2697949.5, 1126363.8],
                [2699862.2, 1128429.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6674', ortbez27: 'Someo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689797.5, 1124054.4],
                [2687241, 1124746.2],
                [2686666, 1126145.4],
                [2686911.6, 1127498],
                [2689021.1, 1128480.3],
                [2691394.6, 1128509.5],
                [2691176, 1130628],
                [2694445.5, 1130817.8],
                [2695511.8, 1127314.5],
                [2693645.8, 1124934.6],
                [2691479.6, 1123480.6],
                [2689797.5, 1124054.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6672', ortbez27: 'Gordevio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704949.5, 1120525.6],
                [2704499.8, 1118574.1],
                [2700647.2, 1119357.1],
                [2700315.4, 1122249.4],
                [2702943.4, 1124087.7],
                [2704347.4, 1122595.2],
                [2704949.5, 1120525.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2577', ortbez27: 'Finsterhennen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581606.5, 1207342.3],
                [2580346.2, 1206657.8],
                [2578739.7, 1207640],
                [2580460.9, 1209698.6],
                [2581683.6, 1211025.1],
                [2582876, 1208847.4],
                [2581606.5, 1207342.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6677', ortbez27: 'Aurigeno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700315.4, 1122249.4],
                [2700647.2, 1119357.1],
                [2700530.4, 1118041.6],
                [2698207.4, 1117997.5],
                [2697333.8, 1119075.3],
                [2696457.5, 1119974.3],
                [2695344.9, 1120802.5],
                [2696678.8, 1124060.4],
                [2698418.1, 1121846.4],
                [2700315.4, 1122249.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2575', ortbez27: 'Gerolfingen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580460.9, 1209698.6],
                [2579987.3, 1212792.2],
                [2581117.5, 1213701.6],
                [2582085.7, 1215661.6],
                [2582699.8, 1214006.2],
                [2583013.1, 1212476.2],
                [2581683.6, 1211025.1],
                [2580460.9, 1209698.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6678', ortbez27: 'Coglio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691479.6, 1123480.6],
                [2693645.8, 1124934.6],
                [2695511.8, 1127314.5],
                [2694445.5, 1130817.8],
                [2695798.8, 1131282.4],
                [2697267.6, 1129915.9],
                [2699862.2, 1128429.5],
                [2697949.5, 1126363.8],
                [2696727.4, 1125972.6],
                [2696678.8, 1124060.4],
                [2695344.9, 1120802.5],
                [2694010, 1120399.9],
                [2692764.7, 1121307.6],
                [2691479.6, 1123480.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6675', ortbez27: 'Cevio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2689021.1, 1128480.3],
                [2687343.7, 1131860.2],
                [2691176, 1130628],
                [2691394.6, 1128509.5],
                [2689021.1, 1128480.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2576', ortbez27: 'L�scherz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579987.3, 1212792.2],
                [2580460.9, 1209698.6],
                [2576375.6, 1208634],
                [2576384.4, 1210184.8],
                [2577737.1, 1210516.7],
                [2579987.3, 1212792.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6676', ortbez27: 'Bignasco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2692347.8, 1134585.1],
                [2695798.8, 1131282.4],
                [2694445.5, 1130817.8],
                [2691176, 1130628],
                [2687343.7, 1131860.2],
                [2684675.5, 1133100.6],
                [2683654.7, 1134104.9],
                [2684134.6, 1135265.4],
                [2685064.9, 1136100.4],
                [2686320.2, 1135993.3],
                [2687359.2, 1135285.6],
                [2688355.7, 1134487.9],
                [2689383.4, 1133740.2],
                [2690669.4, 1133806.6],
                [2692347.8, 1134585.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6663', ortbez27: 'Spruga' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2688751, 1114536.7],
                [2684483.6, 1119263.8],
                [2686503.9, 1119838.1],
                [2689479.4, 1118802.1],
                [2688751, 1114536.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6664', ortbez27: 'Vergeletto' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2684483.6, 1119263.8],
                [2679158, 1122120],
                [2682632.6, 1123837.2],
                [2684195.1, 1124021.6],
                [2687241, 1124746.2],
                [2689797.5, 1124054.4],
                [2689479.4, 1118802.1],
                [2686503.9, 1119838.1],
                [2684483.6, 1119263.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6670', ortbez27: 'Avegno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2702085.7, 1116413.1],
                [2700530.4, 1118041.6],
                [2700647.2, 1119357.1],
                [2704499.8, 1118574.1],
                [2704401.4, 1117163.9],
                [2702085.7, 1116413.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6721', ortbez27: 'Ludiano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718245, 1141771.1],
                [2714177.5, 1141148.2],
                [2716801.8, 1143515.4],
                [2719659.4, 1146763.8],
                [2718245, 1141771.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6722', ortbez27: 'Corzoneso' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716801.8, 1143515.4],
                [2714177.5, 1141148.2],
                [2711981.5, 1144046.6],
                [2714672.5, 1146670.8],
                [2715746.6, 1145916.9],
                [2716801.8, 1143515.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6719', ortbez27: 'Aquila' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714429.9, 1152715],
                [2721142.8, 1154775.9],
                [2722947.8, 1150311.4],
                [2721465.7, 1149963.7],
                [2717066.1, 1150773.6],
                [2715575.9, 1149626],
                [2714199, 1150111.8],
                [2711911.6, 1151666.3],
                [2714429.9, 1152715]
              ]
            ],
            [
              [
                [2721142.8, 1154775.9],
                [2716230.4, 1157693.3],
                [2719351.9, 1160331.6],
                [2717649, 1162559.1],
                [2720170.2, 1163530.1],
                [2723116.6, 1160523.6],
                [2721142.8, 1154775.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2572', ortbez27: 'Sutz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582085.7, 1215661.6],
                [2583216, 1219680.9],
                [2584041.3, 1217388.7],
                [2584720.7, 1215893.9],
                [2583846.5, 1214626.6],
                [2582699.8, 1214006.2],
                [2582085.7, 1215661.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6720', ortbez27: 'Ghirone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2706896.5, 1158051.3],
                [2712369.1, 1160394.2],
                [2713031.9, 1164305.3],
                [2714290.2, 1164584.3],
                [2715560.1, 1164604.1],
                [2717649, 1162559.1],
                [2719351.9, 1160331.6],
                [2716230.4, 1157693.3],
                [2715325.8, 1155581.1],
                [2707474.2, 1156209.9],
                [2706896.5, 1158051.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6723', ortbez27: 'Prugiasco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714672.5, 1146670.8],
                [2710468.2, 1145826.6],
                [2709729, 1147405.1],
                [2709582.3, 1149709.8],
                [2712804.2, 1149182.6],
                [2714140.4, 1148156.9],
                [2714672.5, 1146670.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6724', ortbez27: 'Ponto Valentino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709582.3, 1149709.8],
                [2709393.5, 1151366.8],
                [2711911.6, 1151666.3],
                [2714199, 1150111.8],
                [2715575.9, 1149626],
                [2714140.4, 1148156.9],
                [2712804.2, 1149182.6],
                [2709582.3, 1149709.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6713', ortbez27: 'Malvaglia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718290.3, 1138806], [2719366.9, 1139478.3], [2719979.4, 1137309], [2718290.3, 1138806]]],
            [
              [
                [2718245, 1141771.1],
                [2719659.4, 1146763.8],
                [2718317.7, 1147787.8],
                [2721465.7, 1149963.7],
                [2722947.8, 1150311.4],
                [2726021.8, 1148875.6],
                [2726961, 1144319.6],
                [2726805.8, 1138940.5],
                [2721246.6, 1140151.7],
                [2719366.9, 1139478.3],
                [2718245, 1141771.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6714', ortbez27: 'Semione' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718290.3, 1138806],
                [2716639.2, 1137573.2],
                [2715595.5, 1138728.3],
                [2714177.5, 1141148.2],
                [2718245, 1141771.1],
                [2719366.9, 1139478.3],
                [2718290.3, 1138806]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6717', ortbez27: 'Torre' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2721465.7, 1149963.7],
                [2718317.7, 1147787.8],
                [2714140.4, 1148156.9],
                [2715575.9, 1149626],
                [2717066.1, 1150773.6],
                [2721465.7, 1149963.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6718', ortbez27: 'Olivone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714429.9, 1152715],
                [2711911.6, 1151666.3],
                [2709393.5, 1151366.8],
                [2707419, 1151601.9],
                [2706627.9, 1152719.1],
                [2705379.4, 1152865.1],
                [2703307.6, 1152755.4],
                [2701001.2, 1153605.8],
                [2703322.6, 1158405.5],
                [2706896.5, 1158051.3],
                [2707474.2, 1156209.9],
                [2715325.8, 1155581.1],
                [2716230.4, 1157693.3],
                [2721142.8, 1154775.9],
                [2714429.9, 1152715]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2615', ortbez27: 'Montagne-de-Sonvilier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2561943.3, 1217796.6],
                [2562103.2, 1219127.8],
                [2561655.1, 1220662.6],
                [2563355.1, 1219844.9],
                [2564554.5, 1220283.9],
                [2565608.8, 1218660],
                [2564071.9, 1217299],
                [2561943.3, 1217796.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6715', ortbez27: 'Dongio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719659.4, 1146763.8],
                [2716801.8, 1143515.4],
                [2715746.6, 1145916.9],
                [2718317.7, 1147787.8],
                [2719659.4, 1146763.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2616', ortbez27: 'Renan BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562103.2, 1219127.8],
                [2561943.3, 1217796.6],
                [2560201.5, 1216278],
                [2558286.6, 1215604.4],
                [2556192.9, 1214515.7],
                [2556617.2, 1217114.2],
                [2558161.6, 1219501.6],
                [2560030.2, 1219330.1],
                [2561655.1, 1220662.6],
                [2562103.2, 1219127.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6716', ortbez27: 'Leontica' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2710468.2, 1145826.6], [2714672.5, 1146670.8], [2711981.5, 1144046.6], [2710468.2, 1145826.6]]],
            [
              [
                [2714140.4, 1148156.9],
                [2718317.7, 1147787.8],
                [2715746.6, 1145916.9],
                [2714672.5, 1146670.8],
                [2714140.4, 1148156.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6705', ortbez27: 'Cresciano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720952.7, 1125906.3],
                [2719731.3, 1127682.3],
                [2719657.6, 1129079.9],
                [2725391.3, 1131733.4],
                [2725755.2, 1129917.3],
                [2724674.9, 1128603.9],
                [2720952.7, 1125906.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6703', ortbez27: 'Osogna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725391.3, 1131733.4],
                [2719657.6, 1129079.9],
                [2718739.4, 1131801.4],
                [2723187, 1133848.2],
                [2725619.6, 1133908.4],
                [2725391.3, 1131733.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2610', ortbez27: 'Les Pontins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567906.5, 1221496.4],
                [2569688, 1219721.2],
                [2568365.1, 1218839.6],
                [2565608.8, 1218660],
                [2564554.5, 1220283.9],
                [2563355.1, 1219844.9],
                [2561655.1, 1220662.6],
                [2561045.4, 1222331.9],
                [2563841.9, 1223947.4],
                [2565651.1, 1225271.7],
                [2566448.2, 1226718.2],
                [2569485.1, 1228992.4],
                [2571682.1, 1228043],
                [2573488.7, 1227731.2],
                [2570960.1, 1225847.6],
                [2569692.8, 1225256.1],
                [2567551.7, 1223858.2],
                [2567906.5, 1221496.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6710', ortbez27: 'Biasca' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716639.2, 1137573.2],
                [2718290.3, 1138806],
                [2719979.4, 1137309],
                [2719366.9, 1139478.3],
                [2721246.6, 1140151.7],
                [2726805.8, 1138940.5],
                [2727367.2, 1137691.5],
                [2727418.1, 1136360.1],
                [2727075.6, 1134858.4],
                [2725619.6, 1133908.4],
                [2723187, 1133848.2],
                [2718739.4, 1131801.4],
                [2716720.9, 1135126.2],
                [2716639.2, 1137573.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2607', ortbez27: 'Cort�bert' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575095.8, 1228018.6],
                [2576041, 1226177.8],
                [2574064.2, 1225496.5],
                [2573488.7, 1227731.2],
                [2575095.8, 1228018.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6707', ortbez27: 'Iragna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718739.4, 1131801.4],
                [2715659.1, 1129263.4],
                [2713576.3, 1128738.7],
                [2712583.6, 1129636.1],
                [2716720.9, 1135126.2],
                [2718739.4, 1131801.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2608', ortbez27: 'Courtelary' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2574064.2, 1225496.5],
                [2576041, 1226177.8],
                [2577111.4, 1225508.9],
                [2579155.5, 1224786.6],
                [2575672.8, 1222303.2],
                [2570864.9, 1220325.3],
                [2570830.5, 1223033.9],
                [2569692.8, 1225256.1],
                [2570960.1, 1225847.6],
                [2573488.7, 1227731.2],
                [2574064.2, 1225496.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2613', ortbez27: 'Villeret' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569688, 1219721.2],
                [2567906.5, 1221496.4],
                [2567551.7, 1223858.2],
                [2569692.8, 1225256.1],
                [2570830.5, 1223033.9],
                [2570864.9, 1220325.3],
                [2569688, 1219721.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6695', ortbez27: 'Piano di Peccia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2685610.2, 1141916.3],
                [2684515.3, 1146922.8],
                [2689084.2, 1146848.9],
                [2693770.1, 1140693],
                [2691071.2, 1139539.9],
                [2690779.9, 1137253.3],
                [2687434.9, 1138472.8],
                [2685610.2, 1141916.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6696', ortbez27: 'Fusio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697960.6, 1146275.2],
                [2698209.7, 1143929.7],
                [2693770.1, 1140693],
                [2689084.2, 1146848.9],
                [2684515.3, 1146922.8],
                [2686092.8, 1149707.1],
                [2691816.4, 1149716.7],
                [2694418.8, 1148738.4],
                [2696331, 1148141.6],
                [2697960.6, 1146275.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6702', ortbez27: 'Claro' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720952.7, 1125906.3],
                [2724674.9, 1128603.9],
                [2725888.4, 1128254.9],
                [2725255.1, 1126455.3],
                [2724721.6, 1122553.1],
                [2722789.5, 1122043.3],
                [2721420.5, 1123576.6],
                [2720952.7, 1125906.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2605', ortbez27: 'Sonceboz-Sombeval' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582486.3, 1228012.2],
                [2582143, 1224896.8],
                [2579155.5, 1224786.6],
                [2578624.3, 1227464.5],
                [2581287.1, 1228672.2],
                [2582486.3, 1228012.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2606', ortbez27: 'Corg�mont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576041, 1226177.8],
                [2575095.8, 1228018.6],
                [2577909, 1228828.9],
                [2578624.3, 1227464.5],
                [2579155.5, 1224786.6],
                [2577111.4, 1225508.9],
                [2576041, 1226177.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2603', ortbez27: 'P�ry' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584189.7, 1225068],
                [2584228.4, 1228238.3],
                [2585280.4, 1228927.8],
                [2586515, 1229139.6],
                [2589272.7, 1229770],
                [2591106.9, 1228753.6],
                [2591115.4, 1227493.4],
                [2589396.1, 1226968.8],
                [2586163.7, 1226468.6],
                [2585804.9, 1224118.7],
                [2584189.7, 1225068]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2604', ortbez27: 'La Heutte' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2582143, 1224896.8],
                [2582486.3, 1228012.2],
                [2584228.4, 1228238.3],
                [2584189.7, 1225068],
                [2582143, 1224896.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6745', ortbez27: 'Giornico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712049.6, 1137623.1],
                [2708176.6, 1135752.3],
                [2707084.4, 1137428.2],
                [2708915.2, 1142560.5],
                [2711229.5, 1140198.4],
                [2712049.6, 1137623.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6746', ortbez27: 'Calonico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2706908.5, 1147039.2],
                [2709729, 1147405.1],
                [2707253.9, 1144577.8],
                [2706590.6, 1145712],
                [2706908.5, 1147039.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6743', ortbez27: 'Bodio TI' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714177.5, 1141148.2],
                [2715595.5, 1138728.3],
                [2714697.4, 1136514.6],
                [2712049.6, 1137623.1],
                [2714177.5, 1141148.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6744', ortbez27: 'Personico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712049.6, 1137623.1],
                [2714697.4, 1136514.6],
                [2716720.9, 1135126.2],
                [2712583.6, 1129636.1],
                [2708541.3, 1131713.1],
                [2709711, 1133971.8],
                [2708176.6, 1135752.3],
                [2712049.6, 1137623.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6749', ortbez27: 'Sobrio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714177.5, 1141148.2],
                [2712049.6, 1137623.1],
                [2711229.5, 1140198.4],
                [2708915.2, 1142560.5],
                [2711981.5, 1144046.6],
                [2714177.5, 1141148.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6747', ortbez27: 'Chironico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700305, 1140350.2],
                [2698209.7, 1143929.7],
                [2697960.6, 1146275.2],
                [2702452.5, 1143231.5],
                [2704337.2, 1147024.6],
                [2705617.4, 1146507.1],
                [2706908.5, 1147039.2],
                [2706590.6, 1145712],
                [2707253.9, 1144577.8],
                [2708086.4, 1143628.7],
                [2708915.2, 1142560.5],
                [2707084.4, 1137428.2],
                [2703838.3, 1139056.4],
                [2700305, 1140350.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6748', ortbez27: 'Anzonico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709729, 1147405.1],
                [2710468.2, 1145826.6],
                [2711981.5, 1144046.6],
                [2708915.2, 1142560.5],
                [2708086.4, 1143628.7],
                [2707253.9, 1144577.8],
                [2709729, 1147405.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6742', ortbez27: 'Pollegio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716720.9, 1135126.2],
                [2714697.4, 1136514.6],
                [2715595.5, 1138728.3],
                [2716639.2, 1137573.2],
                [2716720.9, 1135126.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6777', ortbez27: 'Quinto' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2701001.2, 1153605.8],
                [2700210, 1150419.7],
                [2698120.4, 1151502],
                [2696869, 1152263.2],
                [2698360.5, 1154265.4],
                [2701001.2, 1153605.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6775', ortbez27: 'Ambr�' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2700210, 1150419.7],
                [2696331, 1148141.6],
                [2694418.8, 1148738.4],
                [2696869, 1152263.2],
                [2698120.4, 1151502],
                [2700210, 1150419.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6776', ortbez27: 'Piotta' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697183.3, 1158984],
                [2700662.8, 1159310.3],
                [2703322.6, 1158405.5],
                [2701001.2, 1153605.8],
                [2698360.5, 1154265.4],
                [2696869, 1152263.2],
                [2694418.8, 1148738.4],
                [2691816.4, 1149716.7],
                [2697183.3, 1158984]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6781', ortbez27: 'Bedretto' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2686092.8, 1149707.1],
                [2684515.3, 1146922.8],
                [2678710.5, 1145017.8],
                [2676887.5, 1146515.4],
                [2672770, 1145114.9],
                [2672801.7, 1146611],
                [2672869.7, 1147881.1],
                [2673294.7, 1149854.4],
                [2677182.2, 1149986.4],
                [2679797.5, 1153594.8],
                [2680950.7, 1154289.8],
                [2683514.5, 1154791.4],
                [2686092.8, 1149707.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6780', ortbez27: 'Airolo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2691816.4, 1149716.7],
                [2686092.8, 1149707.1],
                [2683514.5, 1154791.4],
                [2684430.7, 1160235.6],
                [2690249.2, 1159358.5],
                [2692251.1, 1157827.8],
                [2695138.8, 1159551.2],
                [2697183.3, 1158984],
                [2691816.4, 1149716.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6773', ortbez27: 'Prato (Leventina)' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2697960.6, 1146275.2],
                [2700210, 1150419.7],
                [2703552.3, 1149079.9],
                [2701842.2, 1146547.4],
                [2697960.6, 1146275.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6774', ortbez27: 'Dalpe' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703552.3, 1149079.9],
                [2704337.2, 1147024.6],
                [2702452.5, 1143231.5],
                [2697960.6, 1146275.2],
                [2701842.2, 1146547.4],
                [2703552.3, 1149079.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6772', ortbez27: 'Rodi-Fiesso' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2696331, 1148141.6], [2700210, 1150419.7], [2697960.6, 1146275.2], [2696331, 1148141.6]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6760', ortbez27: 'Molare' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2704337.2, 1147024.6],
                [2703552.3, 1149079.9],
                [2704765.9, 1149851.3],
                [2705783.2, 1150597.9],
                [2705379.4, 1152865.1],
                [2706627.9, 1152719.1],
                [2707419, 1151601.9],
                [2709393.5, 1151366.8],
                [2709582.3, 1149709.8],
                [2709729, 1147405.1],
                [2706908.5, 1147039.2],
                [2705617.4, 1146507.1],
                [2704337.2, 1147024.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6763', ortbez27: 'Mairengo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2703307.6, 1152755.4],
                [2705379.4, 1152865.1],
                [2705783.2, 1150597.9],
                [2704765.9, 1149851.3],
                [2703552.3, 1149079.9],
                [2700210, 1150419.7],
                [2701001.2, 1153605.8],
                [2703307.6, 1152755.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6818', ortbez27: 'Melano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2721035.2, 1085452.7],
                [2719501.7, 1085963.2],
                [2718991.2, 1087515.3],
                [2722656.1, 1087676.3],
                [2722800, 1085900.4],
                [2721035.2, 1085452.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6815', ortbez27: 'Melide' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717908.7, 1091637.5],
                [2717870, 1089801.7],
                [2717323.9, 1088289.2],
                [2715892, 1089114.1],
                [2717908.7, 1091637.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6816', ortbez27: 'Bissone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718991.2, 1087515.3],
                [2717323.9, 1088289.2],
                [2717870, 1089801.7],
                [2717908.7, 1091637.5],
                [2719263, 1091284.1],
                [2719660, 1089356.9],
                [2718991.2, 1087515.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6821', ortbez27: 'Rovio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719660, 1089356.9],
                [2722114.8, 1089382],
                [2722656.1, 1087676.3],
                [2718991.2, 1087515.3],
                [2719660, 1089356.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2722', ortbez27: 'Les Reussilles' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573562.2, 1229130.8],
                [2570200.3, 1230327.3],
                [2571172.2, 1232386.4],
                [2572882.9, 1233195.7],
                [2574143.5, 1231998.7],
                [2573562.2, 1229130.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6822', ortbez27: 'Arogno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2719660, 1089356.9], [2719263, 1091284.1], [2722114.8, 1089382], [2719660, 1089356.9]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2720', ortbez27: 'Tramelan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578739.4, 1231991.8],
                [2581287.1, 1228672.2],
                [2578624.3, 1227464.5],
                [2577909, 1228828.9],
                [2575095.8, 1228018.6],
                [2573488.7, 1227731.2],
                [2573562.2, 1229130.8],
                [2574143.5, 1231998.7],
                [2578739.4, 1231991.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6809', ortbez27: 'Medeglia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2719231.7, 1110866.9],
                [2719425.8, 1108239.9],
                [2718362.7, 1107450.5],
                [2716609.7, 1107711.8],
                [2715345.2, 1107699.8],
                [2715848.7, 1111488.4],
                [2719231.7, 1110866.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6810', ortbez27: 'Isone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722687.2, 1109582.2],
                [2719425.8, 1108239.9],
                [2719231.7, 1110866.9],
                [2720625.4, 1111714.2],
                [2723888.7, 1112302.9],
                [2725236, 1111971.7],
                [2725975.1, 1110693.6],
                [2725096.7, 1109767],
                [2722687.2, 1109582.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2723', ortbez27: 'Mont-Tramelan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570200.3, 1230327.3],
                [2573562.2, 1229130.8],
                [2573488.7, 1227731.2],
                [2571682.1, 1228043],
                [2569485.1, 1228992.4],
                [2570200.3, 1230327.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6807', ortbez27: 'Taverne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715805.1, 1101708.4],
                [2713503.9, 1102734.4],
                [2715330.8, 1103414.7],
                [2716567.1, 1102972],
                [2715805.1, 1101708.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2713', ortbez27: 'Bellelay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580639.4, 1234285.8],
                [2577696.2, 1234184.6],
                [2578131.9, 1235539.8],
                [2579643.3, 1236166.9],
                [2580639.4, 1234285.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6808', ortbez27: 'Torricella' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713123.7, 1104966.2],
                [2713503.9, 1102734.4],
                [2712147.1, 1103062.5],
                [2711875.5, 1104576.4],
                [2713123.7, 1104966.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2714', ortbez27: 'Les Genevez JU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578739.4, 1231991.8],
                [2574143.5, 1231998.7],
                [2572882.9, 1233195.7],
                [2572848.9, 1234473.4],
                [2573714.5, 1235644.8],
                [2575655.4, 1234973.5],
                [2577696.2, 1234184.6],
                [2578739.4, 1231991.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6814', ortbez27: 'Lamone' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717097.5, 1100610.2],
                [2716333, 1099012.1],
                [2715081.8, 1100450.9],
                [2715805.1, 1101708.4],
                [2717097.5, 1100610.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2712', ortbez27: 'Le Fuet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580894, 1232124.3],
                [2578739.4, 1231991.8],
                [2577696.2, 1234184.6],
                [2580639.4, 1234285.8],
                [2580894, 1232124.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2717', ortbez27: 'Reb�velier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579250.1, 1238209.5],
                [2582978.1, 1237807],
                [2581208.9, 1236422.6],
                [2579643.3, 1236166.9],
                [2579250.1, 1238209.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2718', ortbez27: 'Lajoux JU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2573714.5, 1235644.8],
                [2574549.7, 1237593.8],
                [2576332.1, 1238115.4],
                [2577734.2, 1238024],
                [2579250.1, 1238209.5],
                [2579643.3, 1236166.9],
                [2578131.9, 1235539.8],
                [2577696.2, 1234184.6],
                [2575655.4, 1234973.5],
                [2573714.5, 1235644.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6802', ortbez27: 'Rivera' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712539.1, 1111117.9],
                [2714121.9, 1112026.1],
                [2715848.7, 1111488.4],
                [2715345.2, 1107699.8],
                [2712871.8, 1107427.1],
                [2711579.6, 1107427.1],
                [2710886.5, 1110020.6],
                [2712539.1, 1111117.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2715', ortbez27: 'Ch�telat' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580639.4, 1234285.8],
                [2579643.3, 1236166.9],
                [2581208.9, 1236422.6],
                [2581873.2, 1234622.9],
                [2580639.4, 1234285.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2716', ortbez27: 'Sornetan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584282.1, 1236895.7],
                [2585061.5, 1234437.5],
                [2581873.2, 1234622.9],
                [2581208.9, 1236422.6],
                [2582978.1, 1237807],
                [2584282.1, 1236895.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6805', ortbez27: 'Mezzovico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716985.4, 1105979.7],
                [2713123.7, 1104966.2],
                [2712871.8, 1107427.1],
                [2715345.2, 1107699.8],
                [2716609.7, 1107711.8],
                [2716985.4, 1105979.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6806', ortbez27: 'Sigirino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713123.7, 1104966.2],
                [2716985.4, 1105979.7],
                [2715330.8, 1103414.7],
                [2713503.9, 1102734.4],
                [2713123.7, 1104966.2]
              ]
            ],
            [
              [
                [2710358.2, 1105404.1],
                [2710411.3, 1106974.5],
                [2711579.6, 1107427.1],
                [2712871.8, 1107427.1],
                [2713123.7, 1104966.2],
                [2711875.5, 1104576.4],
                [2710358.2, 1105404.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6803', ortbez27: 'Camignolo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718362.7, 1107450.5], [2716985.4, 1105979.7], [2716609.7, 1107711.8], [2718362.7, 1107450.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2710', ortbez27: 'Tavannes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2578739.4, 1231991.8], [2580894, 1232124.3], [2581287.1, 1228672.2], [2578739.4, 1231991.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6850', ortbez27: 'Mendrisio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720658.1, 1080923.3],
                [2718506.6, 1078746.9],
                [2718402, 1081087.4],
                [2719685.8, 1082856.9],
                [2721034.5, 1082949.6],
                [2720658.1, 1080923.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6853', ortbez27: 'Ligornetto' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2718402, 1081087.4], [2718506.6, 1078746.9], [2717192.1, 1079940.4], [2718402, 1081087.4]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2745', ortbez27: 'Grandval' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2599666.1, 1238312.3], [2599580.2, 1237045.4], [2598022.8, 1236743.5], [2599666.1, 1238312.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2746', ortbez27: 'Cr�mines' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599580.2, 1237045.4],
                [2599666.1, 1238312.3],
                [2600424.4, 1239356.1],
                [2601730.2, 1238989.4],
                [2601242.8, 1237824.9],
                [2601769.9, 1236685],
                [2600387.5, 1236087.6],
                [2599580.2, 1237045.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2743', ortbez27: 'Eschert' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596577, 1234168.9],
                [2596580.7, 1236995.5],
                [2598022.8, 1236743.5],
                [2599580.2, 1237045.4],
                [2600387.5, 1236087.6],
                [2600609.1, 1234837.3],
                [2599917.2, 1233043.1],
                [2598384.1, 1233623.2],
                [2596577, 1234168.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2744', ortbez27: 'Belprahon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596839.1, 1238740.2],
                [2598765.6, 1239702.4],
                [2599666.1, 1238312.3],
                [2598022.8, 1236743.5],
                [2596580.7, 1236995.5],
                [2596839.1, 1238740.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2747', ortbez27: 'Seehof' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604312.9, 1237634.6],
                [2603052.4, 1236554.1],
                [2601769.9, 1236685],
                [2601242.8, 1237824.9],
                [2601730.2, 1238989.4],
                [2602786.2, 1239719.6],
                [2604020.8, 1239181.3],
                [2609217.4, 1240279.4],
                [2605815.1, 1238112.4],
                [2604312.9, 1237634.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2748', ortbez27: 'Souboz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588283, 1236008.5],
                [2586364.4, 1234991.5],
                [2586501.5, 1233732],
                [2585061.5, 1234437.5],
                [2584282.1, 1236895.7],
                [2586348.3, 1237954.1],
                [2589294.6, 1237451.1],
                [2588283, 1236008.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6832', ortbez27: 'Pedrinate' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2721505.9, 1077245.4], [2723975.8, 1076467.7], [2721575.5, 1075794.7], [2721505.9, 1077245.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2738', ortbez27: 'Court' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592434.3, 1234195],
                [2596577, 1234168.9],
                [2598384.1, 1233623.2],
                [2598042.4, 1232332.6],
                [2592631.5, 1229781.2],
                [2591106.9, 1228753.6],
                [2590297.6, 1233230.3],
                [2592434.3, 1234195]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6837', ortbez27: 'Caneggio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726350, 1081920.7],
                [2723322, 1081344.4],
                [2724105.7, 1083063.1],
                [2725342.3, 1083380.6],
                [2726561, 1083669.3],
                [2726350, 1081920.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6838', ortbez27: 'Cabbio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725342.3, 1083380.6],
                [2724105.7, 1083063.1],
                [2724267.9, 1083566.1],
                [2723059.7, 1083774.7],
                [2722800, 1085900.4],
                [2722656.1, 1087676.3],
                [2725811.6, 1086421.5],
                [2727539.3, 1084674.9],
                [2726561, 1083669.3],
                [2725342.3, 1083380.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2735', ortbez27: 'Malleray' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588893.5, 1233226],
                [2589272.7, 1229770],
                [2586515, 1229139.6],
                [2585602.2, 1231590.3],
                [2586603, 1232414.8],
                [2586501.5, 1233732],
                [2586364.4, 1234991.5],
                [2588283, 1236008.5],
                [2592515, 1235460.6],
                [2592434.3, 1234195],
                [2590297.6, 1233230.3],
                [2588893.5, 1233226]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2736', ortbez27: 'Sorvilier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2590297.6, 1233230.3],
                [2591106.9, 1228753.6],
                [2589272.7, 1229770],
                [2588893.5, 1233226],
                [2590297.6, 1233230.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6835', ortbez27: 'Morbio Superiore' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722474.5, 1079746.2],
                [2723322, 1081344.4],
                [2726350, 1081920.7],
                [2725360.2, 1079637.2],
                [2724034.9, 1079413.3],
                [2722474.5, 1079746.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6825', ortbez27: 'Capolago' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2719322.9, 1084080.9], [2719501.7, 1085963.2], [2721035.2, 1085452.7], [2719322.9, 1084080.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2742', ortbez27: 'Perrefitte' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588283, 1236008.5],
                [2589294.6, 1237451.1],
                [2590883.8, 1237541.5],
                [2593206, 1236777.6],
                [2592515, 1235460.6],
                [2588283, 1236008.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6826', ortbez27: 'Riva San Vitale' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717323.9, 1088289.2],
                [2718991.2, 1087515.3],
                [2719501.7, 1085963.2],
                [2719322.9, 1084080.9],
                [2719685.8, 1082856.9],
                [2717550.3, 1082512.5],
                [2717341.7, 1085987.1],
                [2716145.1, 1086367.9],
                [2717323.9, 1088289.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6823', ortbez27: 'Pugerna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718673.3, 1094639.9],
                [2720030.3, 1096001.2],
                [2722849.7, 1096602],
                [2719288.4, 1092816.4],
                [2718673.3, 1094639.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2740', ortbez27: 'Moutier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596577, 1234168.9],
                [2592434.3, 1234195],
                [2592515, 1235460.6],
                [2593206, 1236777.6],
                [2590883.8, 1237541.5],
                [2593233.5, 1239576.9],
                [2594456.5, 1239967.3],
                [2596839.1, 1238740.2],
                [2596580.7, 1236995.5],
                [2596577, 1234168.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6830', ortbez27: 'Chiasso' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2724153.4, 1077864.5], [2723975.8, 1076467.7], [2721505.9, 1077245.4], [2724153.4, 1077864.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6827', ortbez27: 'Brusino Arsizio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716145.1, 1086367.9],
                [2715060.1, 1085373.1],
                [2715537.3, 1087716.9],
                [2715892, 1089114.1],
                [2717323.9, 1088289.2],
                [2716145.1, 1086367.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6828', ortbez27: 'Balerna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2724153.4, 1077864.5],
                [2721505.9, 1077245.4],
                [2720903, 1078591.4],
                [2722474.5, 1079746.2],
                [2724034.9, 1079413.3],
                [2724153.4, 1077864.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2733', ortbez27: 'Pontenet' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2586501.5, 1233732], [2586603, 1232414.8], [2585061.5, 1234437.5], [2586501.5, 1233732]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2732', ortbez27: 'Saules BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585280.4, 1228927.8],
                [2584228.4, 1228238.3],
                [2582486.3, 1228012.2],
                [2581287.1, 1228672.2],
                [2580894, 1232124.3],
                [2580639.4, 1234285.8],
                [2581873.2, 1234622.9],
                [2585061.5, 1234437.5],
                [2586603, 1232414.8],
                [2585602.2, 1231590.3],
                [2586515, 1229139.6],
                [2585280.4, 1228927.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6874', ortbez27: 'Castel San Pietro' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2723322, 1081344.4],
                [2722474.5, 1079746.2],
                [2720903, 1078591.4],
                [2720658.1, 1080923.3],
                [2722024.4, 1082159.8],
                [2723322, 1081344.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6872', ortbez27: 'Salorino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720658.1, 1080923.3],
                [2721034.5, 1082949.6],
                [2719685.8, 1082856.9],
                [2719322.9, 1084080.9],
                [2721035.2, 1085452.7],
                [2722800, 1085900.4],
                [2723059.7, 1083774.7],
                [2722024.4, 1082159.8],
                [2720658.1, 1080923.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6877', ortbez27: 'Coldrerio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718506.6, 1078746.9], [2720658.1, 1080923.3], [2720903, 1078591.4], [2718506.6, 1078746.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6875', ortbez27: 'Monte' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722024.4, 1082159.8],
                [2723059.7, 1083774.7],
                [2724267.9, 1083566.1],
                [2724105.7, 1083063.1],
                [2723322, 1081344.4],
                [2722024.4, 1082159.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6866', ortbez27: 'Meride' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715597.9, 1083481.9],
                [2715060.1, 1085373.1],
                [2716145.1, 1086367.9],
                [2717341.7, 1085987.1],
                [2717550.3, 1082512.5],
                [2715597.9, 1083481.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6864', ortbez27: 'Arzo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717192.1, 1079940.4],
                [2715597.9, 1083481.9],
                [2717550.3, 1082512.5],
                [2718402, 1081087.4],
                [2717192.1, 1079940.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6855', ortbez27: 'Stabio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717192.1, 1079940.4],
                [2718506.6, 1078746.9],
                [2717252.9, 1078038.3],
                [2716117.5, 1077490],
                [2715317.7, 1078881],
                [2717192.1, 1079940.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2762', ortbez27: 'Roches BE' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595535.2, 1240610.6],
                [2598765.6, 1239702.4],
                [2596839.1, 1238740.2],
                [2594456.5, 1239967.3],
                [2595535.2, 1240610.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6862', ortbez27: 'Rancate' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2717550.3, 1082512.5], [2719685.8, 1082856.9], [2718402, 1081087.4], [2717550.3, 1082512.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6914', ortbez27: 'Carona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714038.9, 1089551.8],
                [2715257.3, 1091168.6],
                [2715563.1, 1093178.9],
                [2716388.6, 1094327.2],
                [2717637.2, 1092936.5],
                [2717908.7, 1091637.5],
                [2715892, 1089114.1],
                [2714038.9, 1089551.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6918', ortbez27: 'Figino' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2715257.3, 1091168.6], [2714038.9, 1089551.8], [2712891, 1090893.4], [2715257.3, 1091168.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2822', ortbez27: 'Courroux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594233.2, 1248080.5],
                [2598038.7, 1247906.2],
                [2595834.2, 1246749.6],
                [2596185.8, 1245529.3],
                [2594689.4, 1244779.9],
                [2594233.2, 1248080.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2807', ortbez27: 'Pleigne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585523.3, 1252723.9],
                [2589770.2, 1254346.3],
                [2590270.7, 1252455.8],
                [2589690.4, 1250220.5],
                [2584770, 1251305.6],
                [2585523.3, 1252723.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2813', ortbez27: 'Ederswiler' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2593929, 1251732.1],
                [2590270.7, 1252455.8],
                [2589770.2, 1254346.3],
                [2591599.4, 1254482.6],
                [2592838, 1254313.6],
                [2593929, 1251732.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2814', ortbez27: 'Roggenburg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2595811.3, 1253275.3],
                [2595387.1, 1251623.9],
                [2593929, 1251732.1],
                [2592838, 1254313.6],
                [2594017.3, 1253899.1],
                [2595811.3, 1253275.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2812', ortbez27: 'Movelier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2593929, 1251732.1],
                [2593234.1, 1250032.6],
                [2589690.4, 1250220.5],
                [2590270.7, 1252455.8],
                [2593929, 1251732.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2802', ortbez27: 'Develier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2585858.1, 1246195],
                [2586003.1, 1247505.2],
                [2588918.5, 1247832.3],
                [2590486.7, 1245102.6],
                [2589381.7, 1244438.1],
                [2587490.3, 1244659.8],
                [2585858.1, 1246195]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2800', ortbez27: 'Del�mont' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594233.2, 1248080.5],
                [2594689.4, 1244779.9],
                [2593646.1, 1242974.7],
                [2592274, 1243259.7],
                [2590486.7, 1245102.6],
                [2588918.5, 1247832.3],
                [2594233.2, 1248080.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6900', ortbez27: 'Lugano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718673.3, 1094639.9],
                [2717637.2, 1092936.5],
                [2716388.6, 1094327.2],
                [2716452.7, 1095639.8],
                [2716990.9, 1096768.9],
                [2717423.8, 1098137.8],
                [2718786.7, 1098982.1],
                [2718737.3, 1096582.6],
                [2718673.3, 1094639.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2805', ortbez27: 'Soyhi�res' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596850, 1250426.1],
                [2598038.7, 1247906.2],
                [2594233.2, 1248080.5],
                [2593234.1, 1250032.6],
                [2593929, 1251732.1],
                [2595387.1, 1251623.9],
                [2596850, 1250426.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2806', ortbez27: 'Mettembert' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589690.4, 1250220.5],
                [2593234.1, 1250032.6],
                [2594233.2, 1248080.5],
                [2588918.5, 1247832.3],
                [2589690.4, 1250220.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2803', ortbez27: 'Bourrignon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584770, 1251305.6],
                [2589690.4, 1250220.5],
                [2588918.5, 1247832.3],
                [2586003.1, 1247505.2],
                [2584109.9, 1249095.4],
                [2584770, 1251305.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6946', ortbez27: 'Ponte Capriasca' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722687.2, 1109582.2],
                [2725096.7, 1109767],
                [2724840.5, 1108302.5],
                [2723492.5, 1108068.9],
                [2722687.2, 1109582.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6949', ortbez27: 'Comano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716333, 1099012.1],
                [2717097.5, 1100610.2],
                [2718859, 1100835.5],
                [2718786.7, 1098982.1],
                [2717423.8, 1098137.8],
                [2716333, 1099012.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6947', ortbez27: 'Vaglio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717961.2, 1103384.7],
                [2718859, 1100835.5],
                [2717097.5, 1100610.2],
                [2715805.1, 1101708.4],
                [2716567.1, 1102972],
                [2717961.2, 1103384.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2853', ortbez27: 'Courfaivre' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589530.7, 1240031.3],
                [2586680.3, 1240534.4],
                [2587490.3, 1244659.8],
                [2589381.7, 1244438.1],
                [2589530.7, 1240031.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6948', ortbez27: 'Porza' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2717423.8, 1098137.8],
                [2716990.9, 1096768.9],
                [2715396.4, 1097767.3],
                [2716333, 1099012.1],
                [2717423.8, 1098137.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6937', ortbez27: 'Breno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2708211.3, 1100238.9],
                [2710014.2, 1102312.4],
                [2712174.3, 1099227.3],
                [2710863.1, 1098290],
                [2708211.3, 1100238.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2854', ortbez27: 'Bassecourt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2587490.3, 1244659.8],
                [2586680.3, 1240534.4],
                [2581409.6, 1240148.7],
                [2583594.9, 1243409.7],
                [2585858.1, 1246195],
                [2587490.3, 1244659.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6938', ortbez27: 'Vezio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710014.2, 1102312.4],
                [2709930.9, 1104186.4],
                [2711875.5, 1104576.4],
                [2712147.1, 1103062.5],
                [2712418.9, 1101731.1],
                [2712824.3, 1100529.6],
                [2712174.3, 1099227.3],
                [2710014.2, 1102312.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2852', ortbez27: 'Court�telle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592274, 1243259.7],
                [2593233.5, 1239576.9],
                [2589530.7, 1240031.3],
                [2589381.7, 1244438.1],
                [2590486.7, 1245102.6],
                [2592274, 1243259.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6936', ortbez27: 'Cademario' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713785.5, 1099720],
                [2714202, 1098169.2],
                [2713143.2, 1097124.5],
                [2710863.1, 1098290],
                [2712174.3, 1099227.3],
                [2713785.5, 1099720]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6939', ortbez27: 'Arosio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2713785.5, 1099720], [2712174.3, 1099227.3], [2712824.3, 1100529.6], [2713785.5, 1099720]]],
            [[[2712418.9, 1101731.1], [2712147.1, 1103062.5], [2713503.9, 1102734.4], [2712418.9, 1101731.1]]],
            [[[2711875.5, 1104576.4], [2709930.9, 1104186.4], [2710358.2, 1105404.1], [2711875.5, 1104576.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6930', ortbez27: 'Bedano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713503.9, 1102734.4],
                [2715805.1, 1101708.4],
                [2715081.8, 1100450.9],
                [2713785.5, 1099720],
                [2712824.3, 1100529.6],
                [2712418.9, 1101731.1],
                [2713503.9, 1102734.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2843', ortbez27: 'Ch�tillon JU' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2593233.5, 1239576.9],
                [2592274, 1243259.7],
                [2593646.1, 1242974.7],
                [2594401.3, 1241286.6],
                [2594456.5, 1239967.3],
                [2593233.5, 1239576.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6927', ortbez27: 'Agra' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714372.9, 1092785.6],
                [2715257.3, 1091168.6],
                [2712891, 1090893.4],
                [2713103.9, 1092478.7],
                [2714372.9, 1092785.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6928', ortbez27: 'Manno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2714202, 1098169.2],
                [2713785.5, 1099720],
                [2715081.8, 1100450.9],
                [2716333, 1099012.1],
                [2715396.4, 1097767.3],
                [2714202, 1098169.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6934', ortbez27: 'Bioggio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2713143.2, 1097124.5], [2714202, 1098169.2], [2714496, 1095912.3], [2713143.2, 1097124.5]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2832', ortbez27: 'Rebeuvelier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2598765.6, 1239702.4],
                [2595535.2, 1240610.6],
                [2597662.7, 1242766.7],
                [2600267, 1242036.7],
                [2600424.4, 1239356.1],
                [2599666.1, 1238312.3],
                [2598765.6, 1239702.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6932', ortbez27: 'Breganzona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716990.9, 1096768.9],
                [2716452.7, 1095639.8],
                [2714496, 1095912.3],
                [2714202, 1098169.2],
                [2715396.4, 1097767.3],
                [2716990.9, 1096768.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6921', ortbez27: 'Vico Morcote' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2715537.3, 1087716.9], [2714038.9, 1089551.8], [2715892, 1089114.1], [2715537.3, 1087716.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2825', ortbez27: 'Courchapoix' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2600267, 1242036.7], [2601577.9, 1246848.5], [2602025.2, 1242639.8], [2600267, 1242036.7]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2826', ortbez27: 'Corban' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603740.4, 1242926.8],
                [2602025.2, 1242639.8],
                [2601577.9, 1246848.5],
                [2604289.5, 1246799.1],
                [2603872.4, 1244585],
                [2603740.4, 1242926.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2823', ortbez27: 'Courcelon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2598038.7, 1247906.2],
                [2599975.8, 1247937.5],
                [2597079.3, 1244568.1],
                [2596185.8, 1245529.3],
                [2595834.2, 1246749.6],
                [2598038.7, 1247906.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6926', ortbez27: 'Montagnola' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715563.1, 1093178.9],
                [2715257.3, 1091168.6],
                [2714372.9, 1092785.6],
                [2713828.8, 1094311.1],
                [2714496, 1095912.3],
                [2716388.6, 1094327.2],
                [2715563.1, 1093178.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2824', ortbez27: 'Vicques' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2597079.3, 1244568.1],
                [2599975.8, 1247937.5],
                [2601577.9, 1246848.5],
                [2600267, 1242036.7],
                [2597662.7, 1242766.7],
                [2597079.3, 1244568.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2829', ortbez27: 'Vermes' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2600267, 1242036.7],
                [2602025.2, 1242639.8],
                [2603740.4, 1242926.8],
                [2606811.2, 1241467.5],
                [2609217.4, 1240279.4],
                [2604020.8, 1239181.3],
                [2602786.2, 1239719.6],
                [2601730.2, 1238989.4],
                [2600424.4, 1239356.1],
                [2600267, 1242036.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6924', ortbez27: 'Sorengo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2714496, 1095912.3], [2716452.7, 1095639.8], [2716388.6, 1094327.2], [2714496, 1095912.3]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2830', ortbez27: 'Courrendlin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594456.5, 1239967.3],
                [2594401.3, 1241286.6],
                [2593646.1, 1242974.7],
                [2594689.4, 1244779.9],
                [2596185.8, 1245529.3],
                [2597079.3, 1244568.1],
                [2597662.7, 1242766.7],
                [2595535.2, 1240610.6],
                [2594456.5, 1239967.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2827', ortbez27: 'Schelten' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2610499.4, 1241561.4],
                [2609217.4, 1240279.4],
                [2606811.2, 1241467.5],
                [2603740.4, 1242926.8],
                [2603872.4, 1244585],
                [2607705.1, 1245631.1],
                [2608655.9, 1243986.3],
                [2610499.4, 1241561.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2828', ortbez27: 'Montsevelier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2604289.5, 1246799.1],
                [2606139.6, 1246963.2],
                [2607705.1, 1245631.1],
                [2603872.4, 1244585],
                [2604289.5, 1246799.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6976', ortbez27: 'Castagnola' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718673.3, 1094639.9], [2718737.3, 1096582.6], [2720030.3, 1096001.2], [2718673.3, 1094639.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6981', ortbez27: 'Bombinasco' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2709542.6, 1093987.6],
                [2707632.5, 1094714.2],
                [2707982.1, 1096252.4],
                [2707025, 1098182.5],
                [2709427.4, 1096184.9],
                [2709542.6, 1093987.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2882', ortbez27: 'St-Ursanne' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576877.2, 1247612.1],
                [2579477.4, 1248160.2],
                [2581542.6, 1246092],
                [2579592.3, 1245241.9],
                [2578472.2, 1244596.8],
                [2576011.9, 1244839.9],
                [2574663.7, 1245439.9],
                [2576877.2, 1247612.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6982', ortbez27: 'Agno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713828.8, 1094311.1],
                [2712235.1, 1094254.9],
                [2712959.5, 1095876.7],
                [2713143.2, 1097124.5],
                [2714496, 1095912.3],
                [2713828.8, 1094311.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6979', ortbez27: 'Br� sopra Lugano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720030.3, 1096001.2],
                [2718737.3, 1096582.6],
                [2720527.9, 1098090.5],
                [2721566.3, 1098935.4],
                [2722849.7, 1096602],
                [2720030.3, 1096001.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2885', ortbez27: 'Epauvillers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578472.2, 1244596.8],
                [2575894.6, 1241454.3],
                [2574263, 1240470.6],
                [2573681.6, 1243864.7],
                [2574663.7, 1245439.9],
                [2576011.9, 1244839.9],
                [2578472.2, 1244596.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2886', ortbez27: 'Epiquerez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569521.1, 1242169.7],
                [2571221, 1242837.3],
                [2573681.6, 1243864.7],
                [2574263, 1240470.6],
                [2572963.6, 1240485.6],
                [2569521.1, 1242169.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2883', ortbez27: 'Montmelon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578472.3, 1241550.8],
                [2575894.6, 1241454.3],
                [2578472.2, 1244596.8],
                [2579592.3, 1245241.9],
                [2581542.6, 1246092],
                [2581351.1, 1244356],
                [2579662.1, 1241001.7],
                [2578472.3, 1241550.8]
              ]
            ],
            [
              [
                [2581542.6, 1246092],
                [2579477.4, 1248160.2],
                [2581028.6, 1249028.3],
                [2584109.9, 1249095.4],
                [2583220, 1247395],
                [2581542.6, 1246092]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2873', ortbez27: 'Saulcy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581409.6, 1240148.7],
                [2579250.1, 1238209.5],
                [2577734.2, 1238024],
                [2578472.3, 1241550.8],
                [2579662.1, 1241001.7],
                [2581409.6, 1240148.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6968', ortbez27: 'Sonvico' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722104.7, 1104899.9],
                [2723204.6, 1104299.6],
                [2725822.2, 1102551.6],
                [2722550.3, 1101401.6],
                [2721095.9, 1100773.1],
                [2720070.1, 1101507],
                [2719459.1, 1103257.3],
                [2720296.9, 1104474.5],
                [2720521.8, 1105710.3],
                [2722104.7, 1104899.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6959', ortbez27: 'Cimadera' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725160.6, 1104625.9],
                [2727382.3, 1104603.4],
                [2726738.5, 1103409.2],
                [2725822.2, 1102551.6],
                [2723204.6, 1104299.6],
                [2724314.9, 1105583.3],
                [2725160.6, 1104625.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6960', ortbez27: 'Odogno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2716985.4, 1105979.7],
                [2718362.7, 1107450.5],
                [2719425.8, 1108239.9],
                [2722687.2, 1109582.2],
                [2723492.5, 1108068.9],
                [2722360.3, 1107368.8],
                [2721255.8, 1106765.9],
                [2720521.8, 1105710.3],
                [2720296.9, 1104474.5],
                [2719459.1, 1103257.3],
                [2717961.2, 1103384.7],
                [2716985.4, 1105979.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6965', ortbez27: 'Cadro' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718786.7, 1098982.1],
                [2718859, 1100835.5],
                [2720070.1, 1101507],
                [2721095.9, 1100773.1],
                [2721566.3, 1098935.4],
                [2720527.9, 1098090.5],
                [2718786.7, 1098982.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2863', ortbez27: 'Undervelier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2586680.3, 1240534.4],
                [2586348.3, 1237954.1],
                [2584282.1, 1236895.7],
                [2582978.1, 1237807],
                [2579250.1, 1238209.5],
                [2581409.6, 1240148.7],
                [2586680.3, 1240534.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6963', ortbez27: 'Pregassona' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2718737.3, 1096582.6], [2718786.7, 1098982.1], [2720527.9, 1098090.5], [2718737.3, 1096582.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2864', ortbez27: 'Soulce' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2589294.6, 1237451.1],
                [2586348.3, 1237954.1],
                [2586680.3, 1240534.4],
                [2589530.7, 1240031.3],
                [2593233.5, 1239576.9],
                [2590883.8, 1237541.5],
                [2589294.6, 1237451.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6953', ortbez27: 'Lugaggia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2718859, 1100835.5],
                [2717961.2, 1103384.7],
                [2719459.1, 1103257.3],
                [2720070.1, 1101507],
                [2718859, 1100835.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6954', ortbez27: 'Bigorio' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2715330.8, 1103414.7],
                [2716985.4, 1105979.7],
                [2717961.2, 1103384.7],
                [2716567.1, 1102972],
                [2715330.8, 1103414.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6951', ortbez27: 'Scareglia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2725160.6, 1104625.9],
                [2724314.9, 1105583.3],
                [2722360.3, 1107368.8],
                [2723492.5, 1108068.9],
                [2724840.5, 1108302.5],
                [2725096.7, 1109767],
                [2726858.1, 1109212.1],
                [2726975.4, 1107444.1],
                [2727547.8, 1106172.4],
                [2727382.3, 1104603.4],
                [2725160.6, 1104625.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2857', ortbez27: 'Montavon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2583220, 1247395],
                [2584109.9, 1249095.4],
                [2586003.1, 1247505.2],
                [2585858.1, 1246195],
                [2583220, 1247395]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6958', ortbez27: 'Corticiasca' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2722104.7, 1104899.9],
                [2720521.8, 1105710.3],
                [2721255.8, 1106765.9],
                [2722360.3, 1107368.8],
                [2724314.9, 1105583.3],
                [2723204.6, 1104299.6],
                [2722104.7, 1104899.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2855', ortbez27: 'Glovelier' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581409.6, 1240148.7],
                [2579662.1, 1241001.7],
                [2581351.1, 1244356],
                [2583594.9, 1243409.7],
                [2581409.6, 1240148.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2856', ortbez27: 'Bo�court' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2583594.9, 1243409.7],
                [2581351.1, 1244356],
                [2581542.6, 1246092],
                [2583220, 1247395],
                [2585858.1, 1246195],
                [2583594.9, 1243409.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7013', ortbez27: 'Domat/Ems' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757233.4, 1185512.1],
                [2755525.3, 1185141],
                [2754218, 1186666.6],
                [2750777.6, 1186040.2],
                [2750317, 1187858.9],
                [2752764.4, 1189648.6],
                [2756258.2, 1190288.6],
                [2757175, 1187458.4],
                [2757233.4, 1185512.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7014', ortbez27: 'Trin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2745587.4, 1186383.8],
                [2745233.3, 1189560.9],
                [2743113.8, 1191349.2],
                [2742687.5, 1192674.3],
                [2739364.3, 1196460],
                [2744850.2, 1196445.9],
                [2747339.3, 1191422.4],
                [2747870.7, 1187831.9],
                [2745587.4, 1186383.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2912', ortbez27: 'R�cl�re' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562585.2, 1247250.1],
                [2564362.7, 1247141.7],
                [2564860.8, 1245786.7],
                [2561929.9, 1245528.5],
                [2559826.9, 1245655.3],
                [2560255.1, 1247974.5],
                [2562585.2, 1247250.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7012', ortbez27: 'Felsberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2753220.8, 1192511.2],
                [2753723.1, 1194712.2],
                [2756258.2, 1190288.6],
                [2752764.4, 1189648.6],
                [2753220.8, 1192511.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2915', ortbez27: 'Bure' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2564240.7, 1254150],
                [2567067.1, 1257102.4],
                [2568305.9, 1256752.6],
                [2568717.2, 1255163.7],
                [2568804.9, 1253537.3],
                [2567006.5, 1252434],
                [2565352.7, 1252129.9],
                [2564240.7, 1254150]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6999', ortbez27: 'Astano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2707025, 1098182.5],
                [2707982.1, 1096252.4],
                [2707632.5, 1094714.2],
                [2706441.7, 1095160.8],
                [2705187.2, 1095380.1],
                [2705305.3, 1096670.5],
                [2707025, 1098182.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2916', ortbez27: 'Fahy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2562312.5, 1250858],
                [2564240.7, 1254150],
                [2565352.7, 1252129.9],
                [2563741.6, 1250767.5],
                [2562312.5, 1250858]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2905', ortbez27: 'Courtedoux' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567006.5, 1252434],
                [2568804.9, 1253537.3],
                [2570714.8, 1251100],
                [2569171.2, 1249564],
                [2567006.5, 1252434]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7000', ortbez27: 'Chur' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2756258.2, 1190288.6],
                [2760213.2, 1196341.6],
                [2763990.4, 1192712.4],
                [2761358.2, 1189111.6],
                [2760729, 1187996.4],
                [2757175, 1187458.4],
                [2756258.2, 1190288.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2906', ortbez27: 'Chevenez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567858.7, 1247133.9],
                [2564860.8, 1245786.7],
                [2564362.7, 1247141.7],
                [2563741.6, 1250767.5],
                [2565352.7, 1252129.9],
                [2567006.5, 1252434],
                [2569171.2, 1249564],
                [2567858.7, 1247133.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2903', ortbez27: 'Villars-sur-Fontenais' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570851, 1246883.1],
                [2571616.3, 1248793.4],
                [2573860.8, 1249493.4],
                [2573456.6, 1246722],
                [2570851, 1246883.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2904', ortbez27: 'Bressaucourt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570851, 1246883.1],
                [2569516.3, 1246581.4],
                [2567858.7, 1247133.9],
                [2569171.2, 1249564],
                [2570714.8, 1251100],
                [2571671.5, 1250192.5],
                [2571616.3, 1248793.4],
                [2570851, 1246883.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6993', ortbez27: 'Iseo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712235.1, 1094254.9],
                [2710955.6, 1095125.4],
                [2710636.9, 1096737.2],
                [2711966, 1096691.1],
                [2712959.5, 1095876.7],
                [2712235.1, 1094254.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6994', ortbez27: 'Aranno' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710636.9, 1096737.2],
                [2710863.1, 1098290],
                [2713143.2, 1097124.5],
                [2711966, 1096691.1],
                [2710636.9, 1096737.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2908', ortbez27: 'Grandfontaine' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2560255.1, 1247974.5],
                [2562312.5, 1250858],
                [2563741.6, 1250767.5],
                [2564362.7, 1247141.7],
                [2562585.2, 1247250.1],
                [2560255.1, 1247974.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6992', ortbez27: 'Cimo' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2713143.2, 1097124.5], [2712959.5, 1095876.7], [2711966, 1096691.1], [2713143.2, 1097124.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2902', ortbez27: 'Fontenais' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571671.5, 1250192.5],
                [2575008.9, 1251845.6],
                [2573860.8, 1249493.4],
                [2571616.3, 1248793.4],
                [2571671.5, 1250192.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6986', ortbez27: 'Miglieglia' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710955.6, 1095125.4],
                [2709542.6, 1093987.6],
                [2709427.4, 1096184.9],
                [2707025, 1098182.5],
                [2708211.3, 1100238.9],
                [2710863.1, 1098290],
                [2710636.9, 1096737.2],
                [2710955.6, 1095125.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2900', ortbez27: 'Porrentruy' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570714.8, 1251100],
                [2568804.9, 1253537.3],
                [2572203.5, 1254664.7],
                [2574511.8, 1254022.9],
                [2575008.9, 1251845.6],
                [2571671.5, 1250192.5],
                [2570714.8, 1251100]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6983', ortbez27: 'Magliaso' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2713103.9, 1092478.7],
                [2712235.1, 1094254.9],
                [2713828.8, 1094311.1],
                [2714372.9, 1092785.6],
                [2713103.9, 1092478.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6984', ortbez27: 'Pura' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2710556.7, 1092180.3],
                [2709542.6, 1093987.6],
                [2710955.6, 1095125.4],
                [2712235.1, 1094254.9],
                [2710556.7, 1092180.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2889', ortbez27: 'Ocourt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2571221, 1242837.3],
                [2569516.3, 1246581.4],
                [2570851, 1246883.1],
                [2573456.6, 1246722],
                [2574663.7, 1245439.9],
                [2573681.6, 1243864.7],
                [2571221, 1242837.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2887', ortbez27: 'Soubey' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567477.1, 1239152.5],
                [2569521.1, 1242169.7],
                [2572963.6, 1240485.6],
                [2572501.3, 1238803.5],
                [2568906.1, 1238343.9],
                [2567477.1, 1239152.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '6987', ortbez27: 'Caslano' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2712891, 1090893.4],
                [2710556.7, 1092180.3],
                [2712235.1, 1094254.9],
                [2713103.9, 1092478.7],
                [2712891, 1090893.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2888', ortbez27: 'Seleute' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2576877.2, 1247612.1], [2574663.7, 1245439.9], [2573456.6, 1246722], [2576877.2, 1247612.1]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2946', ortbez27: 'Mi�court' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580264.4, 1251712.6],
                [2578512.2, 1252899.8],
                [2578502.2, 1254466],
                [2579822.5, 1254875.3],
                [2581504.6, 1254427.6],
                [2580264.4, 1251712.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2943', ortbez27: 'Vendlincourt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580459.6, 1257233.1],
                [2579822.5, 1254875.3],
                [2578502.2, 1254466],
                [2576254, 1255116.1],
                [2575849, 1257228.4],
                [2580459.6, 1257233.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2944', ortbez27: 'Bonfol' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580459.6, 1257233.1],
                [2575849, 1257228.4],
                [2576009, 1259616.6],
                [2579021.4, 1260186.1],
                [2582185.2, 1260555.3],
                [2580459.6, 1257233.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2950', ortbez27: 'Courgenay' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2579477.4, 1248160.2],
                [2576877.2, 1247612.1],
                [2573456.6, 1246722],
                [2573860.8, 1249493.4],
                [2575008.9, 1251845.6],
                [2577195.1, 1251334.6],
                [2577192.9, 1249988.9],
                [2579477.4, 1248160.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2947', ortbez27: 'Charmoille' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2581504.6, 1254427.6],
                [2585523.3, 1252723.9],
                [2584770, 1251305.6],
                [2582714.7, 1251040.6],
                [2580857.5, 1250297.5],
                [2580264.4, 1251712.6],
                [2581504.6, 1254427.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7031', ortbez27: 'Laax GR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2742320.7, 1187273.9],
                [2737838.4, 1184117.9],
                [2737719.9, 1187069.6],
                [2739427.4, 1188336.7],
                [2740789.5, 1188727.7],
                [2742320.7, 1187273.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7032', ortbez27: 'Laax GR 2' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731063, 1193003.3],
                [2735304.1, 1195102.2],
                [2739427.4, 1188336.7],
                [2737719.9, 1187069.6],
                [2734983.6, 1185837.4],
                [2733137.9, 1186222.1],
                [2732455.7, 1187621],
                [2732215.1, 1190403.3],
                [2731063, 1193003.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2942', ortbez27: 'Alle' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2578512.2, 1252899.8],
                [2577195.1, 1251334.6],
                [2575008.9, 1251845.6],
                [2574511.8, 1254022.9],
                [2576254, 1255116.1],
                [2578502.2, 1254466],
                [2578512.2, 1252899.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7026', ortbez27: 'Maladers' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2763990.4, 1192712.4],
                [2763510.9, 1189088.1],
                [2762500.2, 1188113.5],
                [2761358.2, 1189111.6],
                [2763990.4, 1192712.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7023', ortbez27: 'Haldenstein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2753723.1, 1194712.2],
                [2754697.1, 1196120.9],
                [2760213.2, 1196341.6],
                [2756258.2, 1190288.6],
                [2753723.1, 1194712.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7029', ortbez27: 'Peist' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2771829.1, 1187326.1],
                [2769523, 1189126.5],
                [2770516.8, 1193037.4],
                [2772669.7, 1192693.3],
                [2774320.4, 1191596.5],
                [2771829.1, 1187326.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7027', ortbez27: 'Calfreisen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2767234.4, 1192347.4],
                [2766644, 1188889],
                [2764811.7, 1189086.5],
                [2763510.9, 1189088.1],
                [2763990.4, 1192712.4],
                [2765692.3, 1193594.9],
                [2767305.4, 1193870.2],
                [2767234.4, 1192347.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7028', ortbez27: 'Pagig' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2768695.1, 1192921.7],
                [2770516.8, 1193037.4],
                [2769523, 1189126.5],
                [2766644, 1188889],
                [2767234.4, 1192347.4],
                [2767305.4, 1193870.2],
                [2768695.1, 1192921.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2933', ortbez27: 'Lugnez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2576009, 1259616.6],
                [2575849, 1257228.4],
                [2572223.5, 1257320.6],
                [2572653.1, 1259931.3],
                [2576009, 1259616.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7017', ortbez27: 'Flims Dorf' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744498.3, 1185623.7],
                [2742731.5, 1185867.5],
                [2742320.7, 1187273.9],
                [2742114.4, 1188695.4],
                [2740789.5, 1188727.7],
                [2739427.4, 1188336.7],
                [2735304.1, 1195102.2],
                [2737974.1, 1197867.2],
                [2739364.3, 1196460],
                [2742687.5, 1192674.3],
                [2743113.8, 1191349.2],
                [2742403.4, 1189986.9],
                [2743794.8, 1189090.8],
                [2745587.4, 1186383.8],
                [2744498.3, 1185623.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7018', ortbez27: 'Flims Waldhaus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2740789.5, 1188727.7], [2742114.4, 1188695.4], [2742320.7, 1187273.9], [2740789.5, 1188727.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7015', ortbez27: 'Tamins' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2747339.3, 1191422.4],
                [2753220.8, 1192511.2],
                [2752764.4, 1189648.6],
                [2750317, 1187858.9],
                [2747870.7, 1187831.9],
                [2747339.3, 1191422.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2932', ortbez27: 'Coeuve' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2575849, 1257228.4],
                [2576254, 1255116.1],
                [2574511.8, 1254022.9],
                [2572203.5, 1254664.7],
                [2572223.5, 1257320.6],
                [2575849, 1257228.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7016', ortbez27: 'Trin Mulin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2745233.3, 1189560.9], [2745587.4, 1186383.8], [2743794.8, 1189090.8], [2745233.3, 1189560.9]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2922', ortbez27: 'Courchavon' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2568804.9, 1253537.3], [2568717.2, 1255163.7], [2572203.5, 1254664.7], [2568804.9, 1253537.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7019', ortbez27: 'Fidaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2745233.3, 1189560.9],
                [2743794.8, 1189090.8],
                [2742403.4, 1189986.9],
                [2743113.8, 1191349.2],
                [2745233.3, 1189560.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2925', ortbez27: 'Buix' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2570323.9, 1258303.1],
                [2568305.9, 1256752.6],
                [2567067.1, 1257102.4],
                [2566364.4, 1258178.1],
                [2567804.6, 1261515.5],
                [2569058.6, 1261472.3],
                [2570323.9, 1258303.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2926', ortbez27: 'Boncourt' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2567804.6, 1261515.5],
                [2566364.4, 1258178.1],
                [2566145.2, 1259582.8],
                [2566509.5, 1260887.4],
                [2567804.6, 1261515.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2923', ortbez27: 'Courtema�che' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2568717.2, 1255163.7],
                [2568305.9, 1256752.6],
                [2570323.9, 1258303.1],
                [2572223.5, 1257320.6],
                [2572203.5, 1254664.7],
                [2568717.2, 1255163.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7074', ortbez27: 'Malix' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757599.3, 1183477.8],
                [2757233.4, 1185512.1],
                [2757175, 1187458.4],
                [2760729, 1187996.4],
                [2757599.3, 1183477.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2924', ortbez27: 'Montignez' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2569058.6, 1261472.3],
                [2572653.1, 1259931.3],
                [2572223.5, 1257320.6],
                [2570323.9, 1258303.1],
                [2569058.6, 1261472.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7077', ortbez27: 'Valbella' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760244.2, 1180375],
                [2760878.9, 1182405.3],
                [2762071.5, 1181951.2],
                [2763012.5, 1180183.4],
                [2760244.2, 1180375]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7078', ortbez27: 'Lenzerheide/Lai' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765395.9, 1181098.1],
                [2765052.3, 1179232.2],
                [2761700, 1175106.4],
                [2757758.3, 1176057.9],
                [2758180.5, 1178497.8],
                [2758680, 1180206],
                [2760244.2, 1180375],
                [2763012.5, 1180183.4],
                [2764799, 1182384.4],
                [2765395.9, 1181098.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7075', ortbez27: 'Churwalden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2760729, 1187996.4],
                [2763044.2, 1185607.6],
                [2761830.3, 1184693.6],
                [2762270.5, 1183490.9],
                [2760878.9, 1182405.3],
                [2760244.2, 1180375],
                [2758680, 1180206],
                [2756815.3, 1182475.8],
                [2757599.3, 1183477.8],
                [2760729, 1187996.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7076', ortbez27: 'Parpan' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2764799, 1182384.4],
                [2763012.5, 1180183.4],
                [2762071.5, 1181951.2],
                [2760878.9, 1182405.3],
                [2762270.5, 1183490.9],
                [2761830.3, 1184693.6],
                [2763044.2, 1185607.6],
                [2763846, 1184068.3],
                [2764799, 1182384.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7063', ortbez27: 'Praden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2763044.2, 1185607.6], [2762500.2, 1188113.5], [2763510.9, 1189088.1], [2763044.2, 1185607.6]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7064', ortbez27: 'Tschiertschen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2766644, 1188889],
                [2768209.8, 1184959.9],
                [2766643.5, 1181532.5],
                [2765395.9, 1181098.1],
                [2764799, 1182384.4],
                [2763846, 1184068.3],
                [2763044.2, 1185607.6],
                [2763510.9, 1189088.1],
                [2764811.7, 1189086.5],
                [2766644, 1188889]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7057', ortbez27: 'Langwies' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2771829.1, 1187326.1],
                [2774320.4, 1191596.5],
                [2777789.4, 1193337.2],
                [2779806.2, 1192149.2],
                [2779662.5, 1190236.3],
                [2780581.6, 1188437.3],
                [2779146, 1187153],
                [2774707.2, 1183183.2],
                [2773028.3, 1185007.1],
                [2773131, 1186266.9],
                [2771829.1, 1187326.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7056', ortbez27: 'Molinis' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2768209.8, 1184959.9],
                [2766644, 1188889],
                [2769523, 1189126.5],
                [2771829.1, 1187326.1],
                [2768209.8, 1184959.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7062', ortbez27: 'Passugg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2763044.2, 1185607.6],
                [2760729, 1187996.4],
                [2761358.2, 1189111.6],
                [2762500.2, 1188113.5],
                [2763044.2, 1185607.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7050', ortbez27: 'Arosa' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2774423.5, 1181067.8],
                [2770567.9, 1177960.8],
                [2771806.9, 1181846],
                [2766323.3, 1178742.1],
                [2765052.3, 1179232.2],
                [2765395.9, 1181098.1],
                [2766643.5, 1181532.5],
                [2768209.8, 1184959.9],
                [2771829.1, 1187326.1],
                [2773131, 1186266.9],
                [2773028.3, 1185007.1],
                [2774707.2, 1183183.2],
                [2774423.5, 1181067.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2954', ortbez27: 'Asuel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2584109.9, 1249095.4],
                [2581028.6, 1249028.3],
                [2580857.5, 1250297.5],
                [2582714.7, 1251040.6],
                [2584770, 1251305.6],
                [2584109.9, 1249095.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '2952', ortbez27: 'Cornol' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2580857.5, 1250297.5],
                [2581028.6, 1249028.3],
                [2579477.4, 1248160.2],
                [2577192.9, 1249988.9],
                [2577195.1, 1251334.6],
                [2578512.2, 1252899.8],
                [2580264.4, 1251712.6],
                [2580857.5, 1250297.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7106', ortbez27: 'Tenna' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2742067.4, 1177420.2],
                [2741489.9, 1179032.9],
                [2744611.7, 1180575.5],
                [2746700, 1181262.8],
                [2746884, 1179459],
                [2746074.7, 1178489.1],
                [2744900.2, 1178012.6],
                [2742067.4, 1177420.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7104', ortbez27: 'Versam' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2744498.3, 1185623.7],
                [2745704.5, 1183882.7],
                [2743219, 1184073.6],
                [2742731.5, 1185867.5],
                [2744498.3, 1185623.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7109', ortbez27: 'Thalkirch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2737167.2, 1165281.9],
                [2737560.7, 1168968.9],
                [2738448.7, 1171580.8],
                [2740673.2, 1172782.9],
                [2743872.2, 1171448.6],
                [2745862.9, 1167217.3],
                [2742987.8, 1165866.3],
                [2743738.1, 1162770.6],
                [2743278.6, 1161367.9],
                [2738391, 1159931.5],
                [2737167.2, 1165281.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7110', ortbez27: 'Peiden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2734172.7, 1174854.7],
                [2733360.4, 1176247.8],
                [2734786.2, 1176541.9],
                [2735824.3, 1174944.9],
                [2734172.7, 1174854.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3007', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601126.7, 1198136.7],
                [2599214, 1197497],
                [2598742.8, 1199448.8],
                [2600420.1, 1200341.7],
                [2601126.7, 1198136.7]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7107', ortbez27: 'Safien Platz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2746884, 1179459],
                [2748191.3, 1178790.4],
                [2746669.8, 1176111.9],
                [2746731.7, 1174663.6],
                [2744808.5, 1172918.6],
                [2743872.2, 1171448.6],
                [2740673.2, 1172782.9],
                [2741184.3, 1176015],
                [2742067.4, 1177420.2],
                [2744900.2, 1178012.6],
                [2746074.7, 1178489.1],
                [2746884, 1179459]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3014', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602165.8, 1202464.9],
                [2601859.5, 1200344.8],
                [2600420.1, 1200341.7],
                [2599747.4, 1202273.6],
                [2602165.8, 1202464.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3011', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2601126.7, 1198136.7], [2600420.1, 1200341.7], [2601859.5, 1200344.8], [2601126.7, 1198136.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3012', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2598742.8, 1199448.8],
                [2597136, 1202159.6],
                [2599747.4, 1202273.6],
                [2600420.1, 1200341.7],
                [2598742.8, 1199448.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3006', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2601859.5, 1200344.8],
                [2602165.8, 1202464.9],
                [2604319.1, 1198946.8],
                [2602990.7, 1198583],
                [2602520.1, 1197303.6],
                [2601126.7, 1198136.7],
                [2601859.5, 1200344.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3004', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2602165.8, 1202464.9],
                [2599747.4, 1202273.6],
                [2599854.5, 1203998.3],
                [2601161, 1203713.7],
                [2602165.8, 1202464.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7082', ortbez27: 'Vaz/Obervaz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2757454, 1173566.1],
                [2757758.3, 1176057.9],
                [2761700, 1175106.4],
                [2760937.2, 1173520],
                [2758728.4, 1172838.5],
                [2757454, 1173566.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7083', ortbez27: 'Lantsch/Lenz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765052.3, 1179232.2],
                [2766323.3, 1178742.1],
                [2765965.3, 1175929.1],
                [2762060.8, 1170830.2],
                [2760937.2, 1173520],
                [2761700, 1175106.4],
                [2765052.3, 1179232.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7084', ortbez27: 'Brienz/Brinzauls GR' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2765965.3, 1175929.1],
                [2767880.8, 1171546.5],
                [2765255.2, 1170154.5],
                [2762060.8, 1170830.2],
                [2765965.3, 1175929.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7137', ortbez27: 'Flond' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[2732408, 1180478], [2730332, 1181478.2], [2732385.5, 1182045.6], [2732408, 1180478]]]]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7138', ortbez27: 'Surcuolm' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2734104.9, 1178522.3],
                [2730413.8, 1177602.9],
                [2728982.4, 1180910.5],
                [2730332, 1181478.2],
                [2732408, 1180478],
                [2734104.9, 1178522.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7141', ortbez27: 'Luven' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2734104.9, 1178522.3],
                [2732408, 1180478],
                [2733888, 1181009.6],
                [2736007.5, 1178827.8],
                [2734104.9, 1178522.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7142', ortbez27: 'Cumbel' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733360.4, 1176247.8],
                [2734104.9, 1178522.3],
                [2736007.5, 1178827.8],
                [2734786.2, 1176541.9],
                [2733360.4, 1176247.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3045', ortbez27: 'Meikirch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596265.8, 1205644.3],
                [2592841.2, 1206094.7],
                [2593916, 1207444.4],
                [2596542.7, 1207681.3],
                [2596265.8, 1205644.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7130', ortbez27: 'Ilanz' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733677, 1182441.4],
                [2735353.9, 1183325.4],
                [2735806.3, 1181657.9],
                [2736007.5, 1178827.8],
                [2733888, 1181009.6],
                [2732408, 1180478],
                [2732385.5, 1182045.6],
                [2733677, 1182441.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3043', ortbez27: 'Uettligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592846.4, 1203913.5],
                [2592841.2, 1206094.7],
                [2596265.8, 1205644.3],
                [2598050.5, 1204012.3],
                [2597136, 1202159.6],
                [2595224.7, 1203011.2],
                [2592846.4, 1203913.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7127', ortbez27: 'Sevgein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2736007.5, 1178827.8], [2735806.3, 1181657.9], [2737767.2, 1180729.6], [2736007.5, 1178827.8]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3044', ortbez27: 'Innerberg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2591540.6, 1205869.7], [2590831.1, 1204351.3], [2589326.9, 1206464.1], [2591540.6, 1205869.7]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3033', ortbez27: 'Wohlen b. Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2592846.4, 1203913.5],
                [2595224.7, 1203011.2],
                [2594962.8, 1201222.3],
                [2593559.5, 1200244.4],
                [2591532.5, 1201551.3],
                [2592846.4, 1203913.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7128', ortbez27: 'Riein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2742067.4, 1177420.2],
                [2741184.3, 1176015],
                [2736007.5, 1178827.8],
                [2737767.2, 1180729.6],
                [2739919.2, 1180471.6],
                [2741489.9, 1179032.9],
                [2742067.4, 1177420.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3034', ortbez27: 'Murzelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588444.5, 1202436.5],
                [2588012.8, 1205133.5],
                [2590831.1, 1204351.3],
                [2592846.4, 1203913.5],
                [2591532.5, 1201551.3],
                [2589997.6, 1201575.5],
                [2588444.5, 1202436.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7134', ortbez27: 'Obersaxen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2726527.4, 1179456.8],
                [2728982.4, 1180910.5],
                [2730413.8, 1177602.9],
                [2729410.8, 1176101.1],
                [2729825, 1174530.8],
                [2728555.5, 1174343.3],
                [2727416.7, 1173527.1],
                [2720907.1, 1170834.8],
                [2720610.9, 1172197.8],
                [2720760.9, 1173451.7],
                [2720442.8, 1175225.9],
                [2720206.8, 1177735.5],
                [2721457.2, 1177623.5],
                [2722304.8, 1179143.4],
                [2723968.7, 1180308.4],
                [2726527.4, 1179456.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3032', ortbez27: 'Hinterkappelen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2594962.8, 1201222.3], [2595224.7, 1203011.2], [2597136, 1202159.6], [2594962.8, 1201222.3]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7132', ortbez27: 'Vals' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2729244.2, 1165035.1],
                [2731441.5, 1168231.4],
                [2737167.2, 1165281.9],
                [2738391, 1159931.5],
                [2735334.3, 1157503.5],
                [2722947.8, 1150311.4],
                [2721142.8, 1154775.9],
                [2723116.6, 1160523.6],
                [2726025.6, 1160194.5],
                [2729244.2, 1165035.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3037', ortbez27: 'Herrenschwanden' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2599747.4, 1202273.6],
                [2597136, 1202159.6],
                [2598050.5, 1204012.3],
                [2599854.5, 1203998.3],
                [2599747.4, 1202273.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3038', ortbez27: 'Kirchlindach' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2596265.8, 1205644.3],
                [2596542.7, 1207681.3],
                [2597824.9, 1208525.3],
                [2599006.4, 1207158.5],
                [2600146.1, 1206446.9],
                [2599854.5, 1203998.3],
                [2598050.5, 1204012.3],
                [2596265.8, 1205644.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7122', ortbez27: 'Valendas' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2739919.2, 1180471.6],
                [2739102.6, 1183115.4],
                [2741672.6, 1184524.8],
                [2742731.5, 1185867.5],
                [2743219, 1184073.6],
                [2744611.7, 1180575.5],
                [2741489.9, 1179032.9],
                [2739919.2, 1180471.6]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3035', ortbez27: 'Frieswil' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2588012.8, 1205133.5], [2589326.9, 1206464.1], [2590831.1, 1204351.3], [2588012.8, 1205133.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3036', ortbez27: 'Detligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2588012.8, 1205133.5],
                [2588444.5, 1202436.5],
                [2586612.1, 1203309.5],
                [2585043.1, 1205412.7],
                [2586335.4, 1206703.2],
                [2589326.9, 1206464.1],
                [2588012.8, 1205133.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7126', ortbez27: 'Castrisch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2735806.3, 1181657.9],
                [2737838.4, 1184117.9],
                [2739102.6, 1183115.4],
                [2739919.2, 1180471.6],
                [2737767.2, 1180729.6],
                [2735806.3, 1181657.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7113', ortbez27: 'Camuns' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736253.1, 1171201.8],
                [2733732.2, 1173462.1],
                [2734172.7, 1174854.7],
                [2735824.3, 1174944.9],
                [2738448.7, 1171580.8],
                [2737560.7, 1168968.9],
                [2736253.1, 1171201.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3027', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2594683, 1199185.8],
                [2593559.5, 1200244.4],
                [2594962.8, 1201222.3],
                [2597136, 1202159.6],
                [2598742.8, 1199448.8],
                [2594683, 1199185.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7111', ortbez27: 'Pitasch' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2736007.5, 1178827.8],
                [2741184.3, 1176015],
                [2738740.6, 1174667],
                [2735824.3, 1174944.9],
                [2734786.2, 1176541.9],
                [2736007.5, 1178827.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7112', ortbez27: 'Duvin' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2738448.7, 1171580.8],
                [2735824.3, 1174944.9],
                [2738740.6, 1174667],
                [2741184.3, 1176015],
                [2740673.2, 1172782.9],
                [2738448.7, 1171580.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3018', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2597744.1, 1197317.3],
                [2596520.3, 1196614.4],
                [2594125.5, 1197494.8],
                [2594683, 1199185.8],
                [2598742.8, 1199448.8],
                [2597744.1, 1197317.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7115', ortbez27: 'Surcasti' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2733732.2, 1173462.1],
                [2733271.5, 1171392.7],
                [2731831.9, 1169801.2],
                [2731132.2, 1171755.2],
                [2731878.8, 1173982.3],
                [2733732.2, 1173462.1]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7116', ortbez27: 'Tersnaus' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2731831.9, 1169801.2],
                [2733271.5, 1171392.7],
                [2733732.2, 1173462.1],
                [2736253.1, 1171201.8],
                [2737560.7, 1168968.9],
                [2737167.2, 1165281.9],
                [2731441.5, 1168231.4],
                [2731831.9, 1169801.2]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3020', ortbez27: 'Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2593559.5, 1200244.4],
                [2594683, 1199185.8],
                [2594125.5, 1197494.8],
                [2591281.5, 1197273.5],
                [2589129.5, 1197020.9],
                [2589969.6, 1199205.3],
                [2593559.5, 1200244.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7167', ortbez27: 'Zignau' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2720206.8, 1177735.5], [2720442.8, 1175225.9], [2718399.7, 1177616.1], [2720206.8, 1177735.5]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3073', ortbez27: 'G�mligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605376.3, 1197128],
                [2604319.1, 1198946.8],
                [2605511.3, 1200401.3],
                [2606446.3, 1198731.4],
                [2606985.8, 1197251.8],
                [2605376.3, 1197128]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7168', ortbez27: 'Schlans' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720520, 1181031.5],
                [2722304.8, 1179143.4],
                [2718189.5, 1180031.4],
                [2717915.2, 1182814.5],
                [2720520, 1181031.5]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7173', ortbez27: 'Surrein' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2720907.1, 1170834.8],
                [2721116.2, 1168554.4],
                [2717077.4, 1166446.4],
                [2716841.8, 1167851.3],
                [2715087.2, 1168673.9],
                [2713883.8, 1168331.4],
                [2712704.4, 1168918.5],
                [2712375.4, 1170176.4],
                [2713122.4, 1171189.1],
                [2712264, 1172216.7],
                [2714854.9, 1174302.9],
                [2713897.7, 1175296.4],
                [2714015.4, 1176708.4],
                [2716006.2, 1176336.3],
                [2717114.1, 1175471.7],
                [2716954.7, 1174219.7],
                [2716825.8, 1172952.7],
                [2717998, 1172146.7],
                [2719318.9, 1172188.6],
                [2720610.9, 1172197.8],
                [2720907.1, 1170834.8]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3074', ortbez27: 'Muri b. Bern' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2605376.3, 1197128],
                [2602520.1, 1197303.6],
                [2602990.7, 1198583],
                [2604319.1, 1198946.8],
                [2605376.3, 1197128]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7174', ortbez27: 'S. Benedetg' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2714015.4, 1176708.4], [2714200.3, 1178226.7], [2716006.2, 1176336.3], [2714015.4, 1176708.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3072', ortbez27: 'Ostermundigen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2603909.4, 1202293.9],
                [2605359, 1201805.4],
                [2605511.3, 1200401.3],
                [2604319.1, 1198946.8],
                [2602165.8, 1202464.9],
                [2603909.4, 1202293.9]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7162', ortbez27: 'Tavanasa' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [[[2723968.7, 1180308.4], [2726332.6, 1180886.8], [2726527.4, 1179456.8], [2723968.7, 1180308.4]]]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '7159', ortbez27: 'Andiast' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2728588.5, 1184207.3],
                [2729138.1, 1182669.8],
                [2727106.7, 1182486.5],
                [2724104.3, 1192955.5],
                [2728588.5, 1184207.3]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3076', ortbez27: 'Worb' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2606446.3, 1198731.4],
                [2610131.9, 1199492.4],
                [2611802.8, 1198707.8],
                [2611707.7, 1197168.8],
                [2611974.7, 1195760.8],
                [2610343.1, 1195893.5],
                [2608945.5, 1196159.5],
                [2606985.8, 1197251.8],
                [2606446.3, 1198731.4]
              ]
            ]
          ]
        }
      },
      {
        type: 'Feature',
        properties: { name: '3065', ortbez27: 'Bolligen' },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [2607475.2, 1205934.3],
                [2607122.9, 1203362.2],
                [2605359, 1201805.4],
                [2603909.4, 1202293.9],
                [2602947.9, 1205370.8],
                [2605517, 1205542.5],
                [2607475.2, 1205934.3]
              ]
            ]
          ]
        }
      }
    ]
  };
}
